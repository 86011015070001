/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$location', '$state', 'Employee', 'FilterParamsService', 'EntityManager', 'localStorageService',
  'employeeStatus', 'packageTypeService'
];

const SecurityManagerEmployeesController = function(
  $scope, $location, $state, Employee, FilterParamsService, EntityManager, localStorageService,
  employeeStatus, packageTypeService
) {

  const vm = this;

  const entityManager = new EntityManager(Employee);
  const filterParamsService = new FilterParamsService({
    order: ['fullName'],
    filter: ['fullName', 'firstLetter', 'status']
  });

  vm.employeeStatus = employeeStatus;
  vm.filterParams = filterParamsService.getParams();

  vm.loadMoreEmployees = entityManager.loadMore;
  vm.isDisabledLoading = entityManager.isDisabledLoading;

  vm.isOpenedNewEmployeeOptions = false;
  vm.isShownUploadPopup = false;
  vm.isShownUploadResultPopup = false;
  vm.isShownLicensesExceedPopup = false;
  vm.isShownResendInvitePopup = false;

  vm.$onInit = function() {
    Employee.getCount().then(function (data) {
      vm.employeesCount = data.count;
    });
    vm.canCreateEmployee = true;
    vm.canViewEmployees = true;
  };

  vm.resetFilter = function () {
    vm.filterParams.filter = {};
  };

  vm.getEmployees = function () {
    return entityManager.entities;
  };

  vm.addNewEmployee = function() {
    checkPackageRestrictions(goToEmployeeCreation);
  };

  vm.toggleNewEmployeeOptions = function () {
    vm.isOpenedNewEmployeeOptions = !vm.isOpenedNewEmployeeOptions;
  };

  vm.closeNewEmployeeOptions = function () {
    vm.isOpenedNewEmployeeOptions = false;
  };

  vm.openUploadEmployeesPopup = function () {
    vm.closeNewEmployeeOptions();
    checkPackageRestrictions(() => {
      vm.isShownUploadPopup = true;
    });
  };

  vm.closeUploadPopup = function () {
    vm.isShownUploadPopup = false;
  };

  vm.onUploadSuccess = function (importResult) {
    vm.importResult = importResult;
    vm.closeUploadPopup();
    openUploadResultPopup();
  };

  vm.closeUploadResultPopup = function () {
    vm.isShownUploadResultPopup = false;
  };

  vm.getDownloadTemplateUrl = function() {
    return Employee.importTemplateUrl();
  };

  vm.closeLicensesExceedPopup = function () {
    vm.isShownLicensesExceedPopup = false;
  };

  vm.onResendInviteClick = function(employee) {
    vm.popupEmployee = employee;
    vm.openResendInvitePopup();
  };

  vm.openResendInvitePopup = function() {
    vm.isShownResendInvitePopup = true;
  };

  vm.closeResendInvitePopup = function() {
    vm.isShownResendInvitePopup = false;
  };

  const checkPackageRestrictions = function(successCb) {
    Employee.getCount({}, { status: employeeStatus.getActiveStatus() }).then(function(data) {
      const employeesCount = data.count;
      if (packageTypeService.hasFreePackage()) {
        if (employeesCount < packageTypeService.getEmployeesLimitForFreePackage()) {
          successCb();
        } else {
          openLicensesExceedPopup();
        }
      } else if (packageTypeService.hasUnlimitedPackage()) {
        successCb();
      } else {
        if (employeesCount < packageTypeService.getLicensesCount()) {
          successCb();
        } else {
          openLicensesExceedPopup();
        }
      }
    });
  };

  const openUploadResultPopup = function () {
    vm.isShownUploadResultPopup = true;
  };

  const openLicensesExceedPopup = function() {
    vm.isShownLicensesExceedPopup = true;
  };

  const goToEmployeeCreation = function() {
    localStorageService.remove('employeeId');
    $state.go('public.security-manager.employee-registration.step-1');
  };

  const filterEmployees = function() {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    $location.search(urlParams);
    entityManager.load(urlParams);
  };

  $scope.$watch(() => vm.filterParams, _.debounce(filterEmployees, 50), true);

  return vm;

};

angular.module('public.security-manager.hr.employees')
  .controller('SecurityManagerEmployeesController', dependencies.concat(SecurityManagerEmployeesController));
