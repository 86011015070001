const dependencies = [
  '$scope', '$state', '$stateParams', 'Session', 'VerifyAuthCodeService', 'redirectService',
  'sessionService', 'storageService'
];

const SignLoginConfirmController = function(
  $scope, $state, $stateParams, Session, VerifyAuthCodeService, redirectService,
  sessionService, storageService
) {

  const vm = this;

  vm.secretPhoneNumber = null;
  vm.isPhoneNumberWrong = false;
  vm.errorMessage = null;

  vm.verifyAuthCodeService = new VerifyAuthCodeService();

  vm.$onInit = function () {
    if (!$stateParams.profile) {
      $state.go('sign.login');
    } else {
      vm.secretPhoneNumber = $stateParams.profile.secretPhoneNumber;
      vm.verifyAuthCodeService.sendCode({
        userId: $stateParams.profile.id,
        resetCb: () => {
          vm.errorMessage = null;
          vm.isPhoneNumberWrong = false;
        },
        failCb: () => {
          vm.errorMessage = 'Phone number is not valid';
          vm.isPhoneNumberWrong = true;
        }
      });
    }
  };

  vm.login = function () {
    if ($scope.SignLoginConfirmForm.$invalid || vm.isPhoneNumberWrong) {
      return;
    }
    vm.verifyAuthCodeService.confirmUserPhoneCode().then(function(phoneAuth) {
      onLoginSuccess(phoneAuth);
    }).catch(function(response) {
      vm.errorMessage = response.data.error;
    });
  };

  const onLoginSuccess = function (phoneAuth) {
    let session = new Session($stateParams.user);
    session.phoneAuth = phoneAuth;
    session.phoneNumber = phoneAuth.phoneNumber;
    sessionService.createSession(session, () => {
      redirectService.redirectToMain();
    }, function(response) {
      const responseData = response.data;
      if (responseData.package_error) {
        storageService.set('packageError', JSON.stringify(responseData));
        $state.go('sign.payment-message');
      } else {
        redirectService.redirectToMain();
      }
    });
  };

  return vm;

};

angular.module('sign')
  .controller('SignLoginConfirmController', dependencies.concat(SignLoginConfirmController));
