angular.module('public.security-manager.schedule-manager.timeline', [
  'data.booking',
  'data.client',
  'data.resource',
  'popup.booking',
  'shared-components.popup.booking',
  'public.security-manager.schedule-manager.timeline.planning'
]).config(['$stateProvider', $stateProvider => {

  return $stateProvider

    .state('public.security-manager.schedule-manager.timeline', {
      abstract: true,
      url: '/timeline',
      template: require('./template.pug'),
      controller: 'ScheduleManagerTimelineLayoutController',
      controllerAs: 'scheduleManagerTimelineLayoutCtrl'
    })

    .state('public.security-manager.schedule-manager.timeline.booking', {
      url: '/booking',
      template: require('./booking/template.pug'),
      controller: 'ScheduleManagerTimelineBookingController',
      controllerAs: 'scheduleManagerTimelineBookingCtrl',
      params: {
        goToDate: undefined,
        goToShiftId: undefined,
        openShiftType: 1
      }
    })

    .state('public.security-manager.schedule-manager.timeline.employee', {
      url: '/employee',
      template: require('./employee/template.pug'),
      controller: 'ScheduleManagerTimelineEmployeeController',
      controllerAs: 'scheduleManagerTimelineEmployeeCtrl',
      params: {
        goToDate: undefined,
        goToEventId: undefined,
        goToEventType: undefined
      }
    })

    .state('public.security-manager.schedule-manager.timeline.live-feed', {
      url: '/live-feed?{ from, to, type }',
      template: require('./live-feed/template.pug'),
      controller: 'ScheduleManagerTimelineLiveFeedController',
      controllerAs: 'scheduleManagerTimelineLiveFeedCtrl'
    })

}]);
