/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'uploadService', 'employeeStatus', 'User'];

const PopupEmployeeFormController = function($scope, uploadService, employeeStatus, User) {

  const vm = this;

  vm.employeeStatus = employeeStatus;
  vm.withoutStatus = ($scope.withoutStatus === undefined) ? false : $scope.withoutStatus;

  const initEmployee = function() {
    if ($scope.employee.isNew()) {
      vm.employee = $scope.employee;
      $scope.popupFormCtrl.title = 'Add new employee';
      return $scope.popupFormCtrl.saveButtonText = 'Add';
    } else {
      vm.employee = angular.copy($scope.employee);
      $scope.popupFormCtrl.title = 'Edit employee';
      return $scope.popupFormCtrl.saveButtonText = 'Save';
    }
  };

  const onSuccessSubmit = function() {
    let avatarUploaded = vm.employee.user.avatarUploaded;
    let user = new User(vm.employee.user);
    user.avatarUploaded = avatarUploaded;
    return uploadService.saveWithFile(user, 'avatar', user => {
      vm.employee.user = user;
      vm.employee.save().then(function(employee) {
        return $scope.popupCtrl.closePopup(() => $scope.popupCloseCb({employee}));
      });
    });
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) { return initEmployee(); }
  });

  $scope.$on('popup.submit', () => {
    if ($scope.employee.status === employeeStatus.getInactiveStatus() && vm.employee.status === employeeStatus.getActiveStatus()) {
      $scope.checkPackageRestrictions({ successPopupSubmitCb: onSuccessSubmit });
    } else {
      onSuccessSubmit();
    }
  });

  return vm;
};

angular.module('popup.employee').controller('PopupEmployeeFormController',
  dependencies.concat(PopupEmployeeFormController));
