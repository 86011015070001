/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['AppModel'];

const SecurityManagerNotificationPreference = AppModel => {

  class SecurityManagerNotificationPreference extends AppModel {
    static initClass() {
      this.configure({
        url: '/security_managers/{{securityManagerId}}/notification_preferences',
        name: 'preference'
      });
    }

    static batchUpdate(securityManagerId, notificationPreferences) {
      return this.$patch(this.resourceUrl({
        securityManagerId
      }, 'batch_update'), notificationPreferences);
    }
  }

  SecurityManagerNotificationPreference.initClass();

  return SecurityManagerNotificationPreference;

};

angular.module('data.security-manager')
  .factory('SecurityManagerNotificationPreference', dependencies.concat(SecurityManagerNotificationPreference));
