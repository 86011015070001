/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.client').service('clientStatus', function() {

  const STATUS_ACTIVE = 1;
  const STATUS_INACTIVE = 2;
  const STATUS_ISSUED = 3;

  const names = {};
  names[STATUS_ACTIVE] = 'Active';
  names[STATUS_INACTIVE] = 'Inactive';
  names[STATUS_ISSUED] = 'Issued';

  const filterNames = {};
  filterNames[0] = 'All clients';
  filterNames[STATUS_ACTIVE] = 'Active Only';
  filterNames[STATUS_INACTIVE] = 'Inactive Only';

  return {
    getNames() {
      return names;
    },

    getFilterNames() {
      return filterNames;
    },

    getName(status) {
      return names[status];
    },

    isActive(status) {
      return status === STATUS_ACTIVE;
    },

    isInactive(status) {
      return status === STATUS_INACTIVE;
    },

    isIssued(status) {
      return status === STATUS_ISSUED;
    },

    getActiveStatus() {
      return STATUS_ACTIVE;
    },

    getInactiveStatus() {
      return STATUS_INACTIVE;
    }
  };
});
