angular.module('data.resource').service('resourceChargeType', function() {

  const PER_HOUR = 1;
  const PER_UNIT = 2;
  const PER_WORK = 3;

  const FIELD_HOURS = 1;
  const FIELD_CHARGE_RATE = 2;
  const FIELD_PAY_RATE = 4;

  const names = {};
  names[PER_HOUR] = 'Per hour';
  names[PER_UNIT] = 'Per unit';
  names[PER_WORK] = 'Per job';

  const requiredFields = {};
  requiredFields[PER_HOUR] = FIELD_HOURS + FIELD_CHARGE_RATE + FIELD_PAY_RATE;
  requiredFields[PER_UNIT] = FIELD_CHARGE_RATE;
  requiredFields[PER_WORK] = FIELD_CHARGE_RATE + FIELD_PAY_RATE;

  const isRequiredField = (type, field) => requiredFields[type] & field;

  return {
    nameOf(type) {
      return names[type];
    },

    getNames() {
      return names;
    },

    isRequiredHours(type) {
      return isRequiredField(type, FIELD_HOURS);
    },

    isRequiredChargeRate(type) {
      return isRequiredField(type, FIELD_CHARGE_RATE);
    },

    isRequiredPayRate(type) {
      return isRequiredField(type, FIELD_PAY_RATE);
    },

    getPerHourType() {
      return PER_HOUR;
    },

    isPerHour(type) {
      return type === PER_HOUR;
    },

    isPerUnit(type) {
      return type === PER_UNIT;
    }
  };
});
