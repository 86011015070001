/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$location', 'FilterParamsService', 'DocumentType', 'DocumentTypeIcon', 'documentTypeType', 'documentTypeIconService',
  'uploadService', 'confirmService'
];

const SettingsDocumentTypesController = function (
  $location, FilterParamsService, DocumentType, DocumentTypeIcon, documentTypeType, documentTypeIconService,
  uploadService, confirmService
) {

  const vm = this;

  const filterParamsService = new FilterParamsService({
    filter : ['type']
  });

  vm.iconClasses = documentTypeIconService.getIconClasses();
  vm.documentTypeIcon = documentTypeIconService;
  vm.filterParams = filterParamsService.getParams();
  vm.selectedTabId = +vm.filterParams.filter.type || documentTypeType.getTraining();

  vm.isShownAddDocumentTypeForm = false;
  vm.isShownDocumentIconsDropdown = false;

  vm.tabs = [{
    id: documentTypeType.getTraining(),
    label: 'Licenses and trainings'
  }, {
    id: documentTypeType.getEmployeeDocument(),
    label: 'Employee document'
  }, {
    id: documentTypeType.getClientDocument(),
    label: 'Client document'
  }];

  vm.$onInit = function() {
    loadDocumentsByType();
    loadIcons();
  };

  vm.goToTab = function(id) {
    vm.selectedTabId = id;
    vm.filterParams.filter.type = id;
    loadDocumentsByType();
  };

  vm.setAddDocumentTypeMode = function() {
    if (vm.isShownAddDocumentTypeForm) { return; }
    return vm.isShownAddDocumentTypeForm = true;
  };

  vm.turnOffAddDocumentTypeMode = function() {
    vm.isShownAddDocumentTypeForm = false;
    initEditedDocumentType();
  };

  vm.isEditedDocumentType = function(index) {
    return index === vm.editedDocumentTypeId;
  };

  vm.setEditDocumentTypeMode = function(index, documentType) {
    vm.editedDocumentTypeId = index;
    vm.documentType = angular.copy(documentType);
  };

  vm.turnOffEditDocumentTypeMode = () => initEditedDocumentType();

  vm.saveEditedDocumentType = function(index, documentTypeForm) {
    if (documentTypeForm.$invalid) {
      return documentTypeForm.$setSubmitted();
    } else {
      vm.documentType.save().then(() => {
        vm.documentTypes[index] = angular.copy(vm.documentType);
        initEditedDocumentType();
      });
    }
  };

  vm.setDocumentTypeIcon = function (icon) {
    vm.documentType.iconId = icon.id;
    vm.hideIconsDropdown();
  };

  vm.toggleIconsDropdown = () => vm.isShownDocumentIconsDropdown = !vm.isShownDocumentIconsDropdown;

  vm.hideIconsDropdown = () => vm.isShownDocumentIconsDropdown = false;

  vm.onIconUpload = function(documentType, $file) {
    const documentIcon = new DocumentTypeIcon({ iconType: documentTypeIconService.getUploadedType() });
    documentIcon.save().then(function(savedDocumentTypeIcon) {
      savedDocumentTypeIcon.icon = $file;
      uploadService.updateFile(savedDocumentTypeIcon, function(updatedIcon) {
        loadIcons();
        documentType.icon = new DocumentTypeIcon(updatedIcon);
        documentType.iconId = updatedIcon.id;
        vm.uploadedIcon = null;
      });
    });
  };

  vm.removeDocumentType = (documentType) => {
    confirmService.confirmRemove('document type', function () {
      documentType.delete().then(() => {
        _.pull(vm.documentTypes, documentType);
      });
    });
  };

  vm.addDocumentType = function(documentTypeForm) {
    if (documentTypeForm.$invalid) {
      return documentTypeForm.$setSubmitted();
    } else {
      vm.documentType.type = vm.selectedTabId;
      vm.documentType.save().then((documentType) => {
        vm.documentTypes.push(documentType);
        vm.turnOffAddDocumentTypeMode();
      });
    }
  };

  vm.getTableHeaderTitleEntity = function () {
    return vm.selectedTabId == documentTypeType.getClientDocument() ? 'clients' : 'employees';
  };

  vm.getTotalCount = function(documentType) {
    return +documentType.presenceCount || 0;
  };

  const initEditedDocumentType = function () {
    vm.documentType = new DocumentType();
    vm.editedDocumentTypeId = null;
  };

  const loadDocumentsByType = function () {
    const urlParams = filterParamsService.getFilterUrlParams();
    $location.search(urlParams);

    DocumentType.query({ type: vm.selectedTabId}).then((documentTypes) => {
      vm.documentTypes = documentTypes;
    });
  };

  const loadIcons = function() {
    DocumentTypeIcon.query().then(function(icons) {
      vm.icons = icons;
    });
  };

  initEditedDocumentType();

  return vm;

};

angular.module('public.security-manager.settings')
  .controller('SettingsDocumentTypesController', dependencies.concat(SettingsDocumentTypesController));
