/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'ClientManager', 'EntityManager', 'pluralizeService', 'packageTypeService'
];

const ClientShowDetailsManagersController = function (
  $scope, ClientManager, EntityManager, pluralizeService, packageTypeService
) {

  const vm = this;

  vm.isShownManagerPopup = false;
  vm.pluralizeService = pluralizeService;
  vm.editable = $scope.editable;
  vm.isShownClientManagersExceedPopup = false;

  const entityManager = new EntityManager(ClientManager, {
    prepareParams: {
      clientId: $scope.clientId
    },
    params: {
      clientId: $scope.clientId
    }
  });

  vm.removeManager = entityManager.removeEntity;

  vm.$onInit = function() {
    entityManager.loadAll();
  };

  vm.getManagers = () => entityManager.entities;

  vm.showManagerPopup = function (manager) {
    if (!vm.editable) { return; }
    if (!packageTypeService.hasProPackage()) {
      openClientManagersExceedPopup();
    } else {
      vm.popupManager = manager || entityManager.createEntity();
      vm.isShownManagerPopup = true;
    }
  };

  vm.closeManagerPopup = function (savedManager) {
    vm.isShownManagerPopup = false;
    if (savedManager) {
      entityManager.updateEntity(savedManager);
    }
  };

  vm.closeClientManagersExceedPopup = function () {
    vm.isShownClientManagersExceedPopup = false;
  };

  const openClientManagersExceedPopup = function() {
    vm.isShownClientManagersExceedPopup = true;
  };

  return vm;

};

angular.module('public.security-manager.client.show').controller('ClientShowDetailsManagersController',
  dependencies.concat(ClientShowDetailsManagersController));
