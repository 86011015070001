const dependencies = [];

const Notification = () => {

  let SUCCESS = undefined;
  let ERROR = undefined;
  let INFO = undefined;
  let NOTIFICATION_CLASSES = undefined;

  class Notification {
    static initClass() {

      SUCCESS = 'success';
      ERROR = 'error';
      INFO = 'info';

      NOTIFICATION_CLASSES = {};
      NOTIFICATION_CLASSES[SUCCESS] = '';
      NOTIFICATION_CLASSES[ERROR] = 'mod-red';
      NOTIFICATION_CLASSES[INFO] = 'mod-blue';
    }

    constructor(message, notificationType, persistent = false) {
      this.message = message;
      this.notificationType = notificationType;
      this.notificationClass = this._getNotificationClass();
      this.persistent = persistent;
    }

    _getNotificationClass() {
      return NOTIFICATION_CLASSES[this.notificationType];
    }
  }

  Notification.initClass();
  return Notification;
};

angular.module('gccs-ui').factory('Notification', dependencies.concat(Notification));
