/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'userValidation', 'currentUserService', 'notificationService', 'redirectService'
];

const ProfileDetailsChangePasswordController = function(
  $scope, userValidation, currentUserService, notificationService, redirectService
) {

  const vm = this;

  vm.flashErrors = {};

  vm.userValidation = userValidation;

  vm.redirectToMain = () => {
    redirectService.redirectToMain();
  };

  vm.onSubmit = function () {
    if ($scope.ProfileChangePasswordForm.$invalid) {
      return;
    }
    const changedUser = !_.isEqual(vm.user, currentUserService.getCurrentUser());
    if (changedUser) {
      vm.user.save().then(() => {
        vm.user.oldPassword = undefined;
        vm.user.newPassword = undefined;
        vm.user.rePassword = undefined;
        notificationService.notifySuccess('Your profile was successfully updated!');
      }).catch((e) => {
        vm.flashErrors[e.data.field] = e.data.error;
      });
    }
  };

  return vm;
};

angular.module('public.security-manager.profile')
  .controller('ProfileDetailsChangePasswordController', dependencies.concat(ProfileDetailsChangePasswordController));
