const newConfirmService = function() {

  let _isShownPopup = false;
  let _title = null;
  let _question = null;
  let _buttons = null;

  const confirmWithMultipleButtons = function({ title, question, buttons }) {
    _title = title;
    _question = question;
    _buttons = buttons;
    _isShownPopup = true;
  };

  return {
    confirmWithMultipleButtons,

    confirmRemove(removableName, confirmCb, cancelCb) {
      confirmWithMultipleButtons({
        title: 'Confirm remove',
        question: `Do you really want to remove ${removableName}? This action can't be undone.`,
        buttons: [{
          label: 'Cancel',
          type: 'white',
          onClickCb: cancelCb
        }, {
          label: 'Yes',
          type: 'green',
          onClickCb: confirmCb
        }]
      });
    },

    closePopup() {
      _isShownPopup = false;
    },

    getTitle() {
      return _title;
    },

    getQuestion() {
      return _question;
    },

    getButtons() {
      return _buttons;
    },

    getButtonClassName(button) {
      return button.className || (button.className = `mod-${button.type}`);
    },

    isShownPopup() {
      return _isShownPopup;
    }
  };
};

angular.module('shared-components').service('newConfirmService', newConfirmService);
