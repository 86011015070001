/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const DemoRequest = AppModel => {

  class DemoRequest extends AppModel {
    static initClass() {
      this.configure({url : '/demo_requests', name : 'demo_request'});
    }
  }

  DemoRequest.initClass();
  return DemoRequest;
};

angular.module('data.demo-request').factory('DemoRequest', dependencies.concat(DemoRequest));
