/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.reports', [
  'public.security-manager.reports.finance',
  'public.security-manager.reports.hr',
  'popup.report'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.reports', {
      url: '/reports',
      abstract: true,
      template: require('./template.pug'),
      controller: 'ReportsController',
      controllerAs: 'reportsCtrl'
    })

]);
