/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'employeeInvoiceStatus', 'invoiceStatus'];

const PopupInvoiceChangeStatusController = function($scope, employeeInvoiceStatus, invoiceStatus) {

  const vm = this;

  vm.isShownPaidDate = false;

  vm.$onInit = function() {
    initStatuses();
    return initStatusData();
  };

  vm.submitStatusChange = () =>
    $scope.popupCtrl.closePopup(function() {
      $scope.popupCloseCb({
        selectedStatus : vm.selectedStatus,
        paidDate : vm.payDate,
        payNote : vm.payNoteText
      });
      return initStatusData();
    })
  ;

  vm.onStatusSelect = function(status) {
    if (!$scope.isClientPopup) {
      if (employeeInvoiceStatus.withPaidDate(status || vm.selectedStatus)) {
        vm.payDate = moment();
        return vm.isShownPaidDate = true;
      } else {
        return vm.isShownPaidDate = false;
      }
    }
  };

  const initStatuses = function() {
    if ($scope.isClientPopup) {
      vm.invoiceStatuses = invoiceStatus.getPopupNames();
    } else {
      vm.invoiceStatuses = employeeInvoiceStatus.getSecurityManagerNames();
    }
  };

  const initStatusData = function() {
    if ($scope.isClientPopup) {
      return vm.selectedStatus = invoiceStatus.getCreatedStatus();
    } else {
      vm.selectedStatus = employeeInvoiceStatus.getCreatedStatus();
      vm.payDate = null;
      return vm.payNoteText = '';
    }
  };

  return vm;
};

angular.module('popup.invoice').controller('PopupInvoiceChangeStatusController',
  dependencies.concat(PopupInvoiceChangeStatusController));
