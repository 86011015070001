/**
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'Note', 'EntityManager', 'FilterParamsService', 'dateService', 'downloadService'];

const NoteListController = function($scope, Note, EntityManager, FilterParamsService, dateService, downloadService) {

  const vm = this;

  const DATE_FORMAT = `D MMM YYYY, ${dateService.getWesternTimeFormat(true)}`;

  vm.isShownNotePopup = false;

  let entityManager = new EntityManager(Note);

  const filterParamsService = new FilterParamsService({
    filter: ['searchString']
  });

  vm.filterParams = filterParamsService.getParams();

  vm.$onInit = function() {
    const params = {
      clientId: vm.clientId,
      employeeId: vm.employeeId
    };
    entityManager.addOptions({
      params: params,
      queryParams: params
    });
    Note.getCount({}, params).then(data => vm.notesCount = data.count);
  };

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  vm.getInitials = function(user) {
    let initials = user.firstName[0];
    if (user.lastName) {
      initials += user.lastName[0];
    }
    return initials;
  };

  vm.loadMoreNotes = () => entityManager.loadMore();

  vm.getNotes = () => entityManager.entities;

  vm.showNotePopup = function(note) {
    vm.popupNote = note || entityManager.createEntity();
    vm.isShownNotePopup = true;
  };

  vm.closeNotePopup = function(savedNote) {
    vm.isShownNotePopup = false;
    if (savedNote) {
      vm.notesCount++;
      filterNotes();
    }
  };

  vm.getVisibilityText = function(note) {
    if (note.isVisible) {
      if (vm.clientId) {
        return 'Client';
      } else {
        return 'Staff';
      }
    }
  };

  vm.getFormattedCreatedAt = function(note) {
    if (!note.formattedCreatedAt) {
      const createdAtMoment = moment(note.createdAt, dateService.getIsoFullDateFormatWithTz());
      note.formattedCreatedAt = createdAtMoment.format(DATE_FORMAT);
    }
    return note.formattedCreatedAt;
  };

  vm.getFormattedUpdatedAt = function(note) {
    return moment(note.updatedAt, dateService.getIsoFullDateFormat()).format(DATE_FORMAT);
  };

  vm.removeNote = function(note, onSuccessCb) {
    entityManager.removeEntity(note, () => {
      vm.notesCount--;
      if (onSuccessCb) {
        onSuccessCb();
      }
    });
  };

  vm.isShownToggleButton = function(note) {
    if (note.isShownToggleButton === undefined) {
      const noteElement = document.getElementById(`note-preview-text-${note.id}`);
      note.isShownToggleButton = noteElement && noteElement.scrollHeight > noteElement.offsetHeight;
    }
    return note.isShownToggleButton;
  };

  vm.getToggleButtonText = function(note) {
    if (note.isFullyShownText) {
      return 'read less ↑';
    } else {
      return 'read more ↓';
    }
  };

  vm.toggleButton = function(note) {
    note.isFullyShownText = !note.isFullyShownText;
  };

  vm.getAttachmentIcon = function(attachment) {
    return `#file-type-${attachment.fileExtension}`;
  };

  vm.downloadFile = function(attachment) {
    downloadService.downloadWithoutCache(attachment.file, attachment.fileName);
  };

  const filterNotes = function() {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    return entityManager.load(urlParams);
  };

  $scope.$watch(() => vm.filterParams, _.debounce(filterNotes, 50), true);

  return vm;

};

angular.module('public.note').controller('NoteListController', dependencies.concat(NoteListController));
