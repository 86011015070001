/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['dateService', 'timeLineConverter'];

var TimeLineInteractor = function(dateService, timeLineConverter) {

  return (TimeLineInteractor = class TimeLineInteractor {

    constructor(scrollHolder) {
      this.scrollHolder = scrollHolder;
      this.timeLineConverter = timeLineConverter;
      this.daysPerScroll = 3;
      this.dates = [];
      this.intervals = ['12 am', '6 am', '12 pm', '6 pm'];
    }

    initDates(from, to) {
      this.today = dateService.today();
      this.from = moment(from, dateService.getIsoDateFormat());
      this.to = moment(to, dateService.getIsoDateFormat());
      return this._setDatesArray();
    }

    getTitle() {
      return this.from.format('MMM, YYYY');
    }

    isToday(date) {
      return date.format(dateService.getDateFormat()) === this.today.format(dateService.getDateFormat());
    }

    getCurrentTimeOffset() {
      return this.timeLineConverter.getOffsetFromTime(null, this.from);
    }

    getHolderWidth() {
      return this.timeLineConverter.getOffsetForDays(this.dates.length + 0.5);
    }

    scrollToStart() {
      return this.scrollHolder.scrollLeft = 0;
    }

    scrollLeft() {
      return this.scrollHolder.scrollLeft -= this._getScrollDaysWidth();
    }

    scrollRight() {
      return this.scrollHolder.scrollLeft += this._getScrollDaysWidth();
    }

    scrollToToday() {
      return this.scrollHolder.scrollLeft = this.timeLineConverter.getOffsetFromTime(this.today, this.from);
    }

    getHalfIntervals() {
      return _.take(this.intervals, 2);
    }

    _getScrollDaysWidth() {
      return this.timeLineConverter.getDaysWidth(this.daysPerScroll);
    }

    _setDatesArray() {
      let date = this.from.clone();
      const to = this.to.clone().add(1, 'day');
      const epochTime = moment(0);
      return (() => {
        const result = [];
        while (true) {
          date.dayEven = date.diff(epochTime, 'days') % 2;
          this.dates.push(date);
          date = date.clone();
          date.add(1, 'days');
          if (to.diff(date) < 0) { break; } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    }
  });
};

angular.module('public.timeline').factory('TimeLineInteractor', dependencies.concat(TimeLineInteractor));
