const dependencies = [
  '$scope', 'EntityManager', 'BookingShiftComment', 'currentUserService'
];

const EmployeeTimelineShiftModalCommentsController = function(
  $scope, EntityManager, BookingShiftComment, currentUserService
) {

  const vm = this;

  let isSending = false;

  const shiftCommentManager = new EntityManager(BookingShiftComment, {
    prepare: {
      userId: currentUserService.getCurrentProfile().userId
    }
  });

  vm.$onInit = function() {
    shiftCommentManager.addOptions({
      params: {
        bookingId: vm.timelineItem.shift.booking.id,
        shiftId: vm.timelineItem.shift.id
      }
    });
    shiftCommentManager.loadAll();
    createNewComment();
  };

  vm.getComments = () => shiftCommentManager.entities;

  vm.isRemovable = function(comment) {
    return comment.user.id === currentUserService.getCurrentProfile().userId;
  };

  vm.removeComment = function(comment) {
    vm.isShownConfirmPopup = true;
    shiftCommentManager.removeEntity(comment, function() {
      vm.isShownConfirmPopup = false;
    });
  };

  vm.onSubmit = function() {
    let commentText;
    if (vm.newComment.text) {
      commentText = vm.newComment.text.replace(/&nbsp;/g, ' ');
    } else {
      commentText = '';
    }
    if (!$scope.ShiftCommentForm.$valid || !/\S/.test(commentText) || isSending) { return; }
    isSending = true;
    vm.newComment.text = commentText;
    shiftCommentManager.saveEntity(vm.newComment).then(function() {
      isSending = false;
      createNewComment();
    });
  };

  const createNewComment = function() {
    vm.newComment = shiftCommentManager.createEntity();
    vm.newComment.visibleToStaff = true;
    vm.newComment.visibleToClient = false;
  };

  return vm;

};

angular.module('public.employee.timeline')
  .controller('EmployeeTimelineShiftModalCommentsController', dependencies.concat(EmployeeTimelineShiftModalCommentsController));
