/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['$timeout'];

const gccsShowFocus = $timeout => {
  return (scope, element, attrs) => {
    scope.$watch(attrs.gccsShowFocus, newValue =>
        $timeout(() => newValue && element[0].focus())
    )
  };
};

angular.module('shared').directive('gccsShowFocus', dependencies.concat(gccsShowFocus));
