const dependencies = ['$state', '$timeout'];

const SecurityManagerNotificationsTopBarSettingsController = function($state, $timeout) {

  const vm = this;

  vm.toggleSettings = function() {
    vm.isSettingsShown = !vm.isSettingsShown;
  };

  vm.closeSettings = function() {
    vm.isSettingsShown = false;
  };

  vm.goToSettings = function() {
    vm.closeCb();
    $state.go('public.security-manager.profile.notifications');
  };

  vm.onUrgentChange = function() {
    vm.updateManager(vm.onUrgentChangeCb);
  };

  vm.updateManager = function(onSuccess) {
    $timeout(function() {
      vm.securityManager.save().then(function() {
        if (onSuccess) {
          onSuccess();
        }
      });
    });
  };

  return vm;

};

angular.module('public.security-manager.notifications')
  .controller('SecurityManagerNotificationsTopBarSettingsController', dependencies.concat(SecurityManagerNotificationsTopBarSettingsController));
