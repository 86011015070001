/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.employee.registration', []).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.employee-registration', {
      abstract: true,
      url: '/employee/registration',
      template: require('./template.pug'),
      controller: 'EmployeeRegistrationLayoutController',
      controllerAs: 'employeeRegistrationLayoutCtrl'
    })
    .state('public.security-manager.employee-registration.step-1', {
      url: '/step1',
      template: require('./personal-details/template.pug'),
      controller: 'EmployeeRegistrationPersonalDetailsController',
      controllerAs: 'employeeRegistrationPersonalDetailsCtrl'
    })
    .state('public.security-manager.employee-registration.step-2', {
      url: '/step2',
      template: require('./payment-details/template.pug'),
      controller: 'EmployeeRegistrationPaymentDetailsController',
      controllerAs: 'employeeRegistrationPaymentDetailsCtrl'
    })
    .state('public.security-manager.employee-registration.step-3', {
      url: '/step3',
      template: require('./licenses/template.pug'),
      controller: 'EmployeeRegistrationLicensesController',
      controllerAs: 'employeeRegistrationLicensesCtrl'
    })
    .state('public.security-manager.employee-registration.step-4', {
      url: '/step4',
      template: require('./documents/template.pug'),
      controller: 'EmployeeRegistrationDocumentsController',
      controllerAs: 'employeeRegistrationDocumentsCtrl'
    })
    .state('public.security-manager.employee-registration.step-5', {
      url: '/step5',
      template: require('./address-history/template.pug'),
      controller: 'EmployeeRegistrationAddressHistoryController',
      controllerAs: 'employeeRegistrationAddressHistoryCtrl'
    })
    .state('public.security-manager.employee-registration.step-6', {
      url: '/step6',
      template: require('./work-history/template.pug'),
      controller: 'EmployeeRegistrationWorkHistoryController',
      controllerAs: 'employeeRegistrationWorkHistoryCtrl'
    })

]);
