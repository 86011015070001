/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$state', 'Document', 'EmployeeLicense', 'FilterParamsService', 'employeeType',
  'documentTypeType', 'downloadService'
];

const ReportsDocumentTypeItemController = function(
  $scope, $state, Document, EmployeeLicense, FilterParamsService, employeeType,
  documentTypeType, downloadService
) {

  const ALL_USERS = 1;
  const WITH_DOCUMENTS = 2;
  const WITHOUT_DOCUMENTS = 3;

  let isInitialized = false;

  const vm = this;

  vm.filterParams = {
    status: '',
    name: ''
  };
  vm.isShownViewPopup = false;

  vm.initData = function() {
    isInitialized = true;
    vm.subjectEntityNameLowerCase = vm.labels.subjectEntityName.toLowerCase();
    initFilters();
  };

  vm.getExportUrl = function() {
    if (!vm.isDisabledExport()) {
      return vm.data.exportUrl(vm.filterParams);
    }
  };

  vm.isShownExport = function() {
    const type = +vm.type;
    return documentTypeType.isTrainingType(type) || documentTypeType.isEmployeeDocumentType(type);
  };

  vm.isDisabledExport = function() {
    if (!vm.documentTypeUsers) { return; }
    return !vm.documentTypeUsers.length;
  };


  vm.getStatisticsText = function() {
    const status = vm.filterParams.status;
    if (!status) { return; }
    if (+status === ALL_USERS) {
      return `${getTotalUsersCount()} ${vm.subjectEntityNameLowerCase}s`;
    } else {
      return `${vm.documentTypeUsers.length} out of ${getTotalUsersCount()} ${vm.filterNames[status].toLowerCase()}`;
    }
  };

  vm.getTitleText = function() {
    const status = vm.filterParams.status;
    if (!status) { return; }
    if (+status === ALL_USERS) {
      return `${vm.labels.subjectEntityName}s`;
    } else {
      return `${vm.labels.subjectEntityName}s ${vm.filterNames[status].toLowerCase()}`;
    }
  };

  vm.getEmploymentType = function(type) {
    return employeeType.getName(type);
  };

  const getTotalUsersCount = function() {
    return vm.data.presenceCount + vm.data.absenceCount;
  };

  vm.getDocumentEntity = function(documentUser) {
    if (vm.isTrainingType()) {
      return documentUser.license;
    } else {
      return documentUser.document;
    }
  };

  vm.isTrainingType = function() {
    return documentTypeType.isTrainingType(+vm.type);
  };

  vm.isEmployeeDocumentType = function() {
    return documentTypeType.isEmployeeDocumentType(+vm.type);
  };

  vm.isClientDocumentType = function() {
    return documentTypeType.isClientDocumentType(+vm.type);
  };

  vm.isPassed = function(documentUser) {
    if (vm.isTrainingType() && !_.isEmpty(documentUser.license)) {
      return !documentUser.license.expireDate || documentUser.license.isActive;
    } else {
      return false;
    }
  };

  vm.isExpire = function(date) {
    if (date) {
      return moment(date).isBefore(moment());
    } else {
      return false;
    }
  };

  vm.getIssueDate = function(documentUser) {
    if (!vm.getDocumentEntity(documentUser)) { return; }
    if (vm.isTrainingType()) {
      return vm.getDocumentEntity(documentUser).passedDate;
    } else {
      return vm.getDocumentEntity(documentUser).issueDate;
    }
  };

  vm.isShownStatus = function(documentUser) {
    if (!vm.getDocumentEntity(documentUser)) { return; }
    return vm.isPassed(documentUser) || vm.isExpire(vm.getDocumentEntity(documentUser).expireDate);
  };

  vm.getFileIcon = document => {
    return `#file-type-${document.fileExtension}`;
  };

  vm.showViewPopup = function(documentUser) {
    if (vm.isTrainingType()) {
      vm.popupViewLicense = new EmployeeLicense(documentUser.license);
      vm.isShownViewPopup = true;
    } else {
      vm.popupViewDocument = new Document(documentUser.document);
      if (vm.popupViewDocument.isViewable()) {
        vm.isShownViewPopup = true;
      } else {
        downloadService.downloadWithoutCache(vm.popupViewDocument.file, vm.popupViewDocument.fileName);
      }
    }
  };

  vm.closeViewPopup = function () {
    vm.isShownViewPopup = false;
    vm.popupViewLicense = null;
    vm.popupViewDocument = null;
  };

  vm.getDocumentLocation = function(documentUser) {
    if (vm.isTrainingType()) {
      return $state.href('public.security-manager.employee.licenses', { employeeId: documentUser.id });
    } else if (vm.isEmployeeDocumentType()) {
      return $state.href('public.security-manager.employee.documents', { employeeId: documentUser.id });
    } else if (vm.isClientDocumentType()) {
      return $state.href('public.security-manager.client.documents', { clientId: documentUser.id });
    }
  };

  const initFilters = function() {
    vm.filterNames = {
      [ALL_USERS] : `All ${vm.subjectEntityNameLowerCase}s`,
      [WITH_DOCUMENTS] : `With ${vm.labels.entityName.toLowerCase()}`,
      [WITHOUT_DOCUMENTS] : `Without ${vm.labels.entityName.toLowerCase()}`
    };
  };

  const filterEntities = function() {
    if (!isInitialized) { return; }
    vm.data.reportData(vm.filterParams).then(users => {
      vm.documentTypeUsers = users;
    });
  };

  $scope.$watch(() => vm.filterParams, _.debounce(filterEntities, 50) , true);

  return vm;

};

angular.module('public.security-manager.reports.hr')
  .controller('ReportsDocumentTypeItemController', dependencies.concat(ReportsDocumentTypeItemController));
