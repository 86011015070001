/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$filter', 'EntityManager', 'EmployeeWorkHistory', 'dateService', 'employeeType',
  'EmployeeGapChart'
];

const EmployeeShowWorkHistoryController = function(
  $scope, $filter, EntityManager, EmployeeWorkHistory, dateService, employeeType,
  EmployeeGapChart
) {

  const vm = this;

  const LAST_YEAR = 10000;
  const NOW_LABEL = 'Now';

  vm.isShownWorkHistoryPopup = false;
  vm.groupedWorkHistories = {};
  vm.maxDescriptionLength = 125;
  vm.employeeType = employeeType;

  const entityManager = new EntityManager(EmployeeWorkHistory, {
    params: {
      employeeId: $scope.employeeShowLayoutCtrl.employeeId
    }
  });
  const formatFilter = $filter('formatDate');

  vm.$onInit = function() {
    initEmployee().then(() => {
      initAddressHistoryChart();
    });
  };

  vm.removeWorkHistory = workHistory => {
    return entityManager.removeEntity(workHistory, function () {
      vm.employee.workHistoriesCount--;
      return groupWorkHistories();
    });
  };

  vm.showWorkHistoryPopup = function(workHistory) {
    vm.popupWorkHistory = workHistory || entityManager.createEntity();
    return vm.isShownWorkHistoryPopup = true;
  };

  vm.closeWorkHistoryPopup = function(savedWorkHistory) {
    vm.isShownWorkHistoryPopup = false;
    if (savedWorkHistory) {
      vm.employee.workHistoriesCount++;
      entityManager.updateEntity(savedWorkHistory);
      return groupWorkHistories();
    }
  };

  vm.getYears = () => _.reverse(_.sortBy(_.keys(vm.groupedWorkHistories), year => year - 0));

  vm.getYearLabel = function(year) {
    if ((year - 0) === LAST_YEAR) { return NOW_LABEL; } else { return year; }
  };

  vm.getPeriodLabel = function(workHistory) {
    const labelParts = [formatFilter(workHistory.from)];
    if (workHistory.to) {
      labelParts.push(formatFilter(workHistory.to));
    } else {
      labelParts.push(NOW_LABEL);
    }
    return labelParts.join(' - ');
  };

  const initEmployee = function() {
    return $scope.employeeShowLayoutCtrl.loadEmployee().then(employee => vm.employee = employee);
  };

  const initAddressHistoryChart = function() {
    return EmployeeWorkHistory.getGapInfo($scope.employeeShowLayoutCtrl.employeeId).then(function(gapInfo) {
      vm.workHistoryGapChart = new EmployeeGapChart(gapInfo.dateRanges);
    });
  };

  var groupWorkHistories = function() {
    const groupedWorkHistories = {};
    _.each(entityManager.entities, function(workHistory) {
      let year;
      if (workHistory.to) {
        year = new Date(workHistory.to).getFullYear();
      } else {
        year = LAST_YEAR;
      }
      if (!groupedWorkHistories[year]) { groupedWorkHistories[year] = []; }
      return groupedWorkHistories[year].push(workHistory);
    });
    return vm.groupedWorkHistories = groupedWorkHistories;
  };

  entityManager.loadAll().then(groupWorkHistories);

  return vm;

};

angular.module('public.security-manager.employee')
  .controller('EmployeeShowWorkHistoryController', dependencies.concat(EmployeeShowWorkHistoryController));
