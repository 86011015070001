angular.module('shared').factory('EmployeeEventOptions', [
  'employeeEventType', 'EmployeeAvailability', 'EmployeeDayOff', 'EmployeeSickDay',
  function(employeeEventType, EmployeeAvailability, EmployeeDayOff, EmployeeSickDay) {

  const options = [
    {
      type: employeeEventType.getAvailabilityEventType(),
      title: employeeEventType.getName(employeeEventType.getAvailabilityEventType()),
      icon: '#availability'
    },
    {
      type: employeeEventType.getDayOffEventType(),
      title: employeeEventType.getName(employeeEventType.getDayOffEventType()),
      icon: '#day-off'
    },
    {
      type: employeeEventType.getSickDayEventType(),
      title: employeeEventType.getName(employeeEventType.getSickDayEventType()),
      icon: '#sick-day'
    }
  ];

  class EmployeeEventOptions {

    constructor() {
      this.options = options;
      this.selectedOption = null;
    }

    getButtonTitle() {
      if (this.selectedOption) {
        return employeeEventType.getName(this.selectedOption.type).toLowerCase();
      } else {
        return 'event';
      }
    }

    getSelectedOption() {
      return this.selectedOption;
    }

    getSelectedType() {
      if (this.selectedOption) {
        return this.selectedOption.type;
      }
    }

    setSelectedOption(option) {
      if (_.isObject(option)) {
        this.selectedOption = option;
      } else {
        this.selectedOption = _.find(options, ['type', option]);
      }
    }

    resetSelectedOption() {
      this.selectedOption = null;
    }

    getEntity() {
      switch (this.selectedOption.type) {
        case employeeEventType.getAvailabilityEventType():
          return EmployeeAvailability;
        case employeeEventType.getDayOffEventType():
          return EmployeeDayOff;
        case employeeEventType.getSickDayEventType():
          return EmployeeSickDay;
      }
    }
  }

  return EmployeeEventOptions;
}]);
