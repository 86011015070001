const dependencies = ['dateService', 'bookingShiftEmployeeAssignStatus'];

const EmployeeTimelineShiftDecorator = function(dateService, bookingShiftEmployeeAssignStatus) {

  return class EmployeeTimelineShiftDecorator {

    constructor(shift) {
      this.shift = shift;
      this.shiftId = this.shift.id;
      this.clearCachedValues();
    }

    getStartTimeMoment() {
      return this.startTimeMoment || (this.startTimeMoment = moment(this.shift.startTime, dateService.getIsoFullDateFormat()));
    }

    getEndTimeMoment() {
      return this.endTimeMoment || (this.endTimeMoment = moment(this.shift.endTime, dateService.getIsoFullDateFormat()));
    }

    getPeriod() {
      return this.period || (this.period = [
        this.getStartTimeMoment().format(dateService.getTimeFormat()),
        this.getEndTimeMoment().format(dateService.getTimeFormat())
      ].join(' - '));
    }

    clearCachedValues() {
      this.startTimeMoment = false;
      this.endTimeMoment = false;
      this.shiftLengthInHours = false;
      this.shiftDurationMoment = false;
    }

    getShiftLengthInHours() {
      return this.shiftLengthInHours || (this.shiftLengthInHours = this.getEndTimeMoment().diff(this.getStartTimeMoment(), 'hours', true));
    }

    getShiftDurationMoment() {
      return this.shiftDurationMoment || (this.shiftDurationMoment = moment.duration(this.getEndTimeMoment().diff(this.getStartTimeMoment())));
    }

    isInvite() {
      return this.assignStatus === bookingShiftEmployeeAssignStatus.getNewStatus();
    }

  };

};

angular.module('public.timeline')
  .factory('EmployeeTimelineShiftDecorator', dependencies.concat(EmployeeTimelineShiftDecorator));
