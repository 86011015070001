/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'BookingShiftComment', 'EntityManager', 'currentUserService', 'bookingCommentVisibleTo'
];

const InvoiceShiftCommentsController = function (
  $scope, BookingShiftComment, EntityManager, currentUserService, bookingCommentVisibleTo
) {

  const vm = this;

  let isSending = false;
  const commentManager = new EntityManager(BookingShiftComment, {
    prepare: {
      userId: currentUserService.getCurrentProfile().userId
    }
  });

  vm.getVisibleToText = bookingCommentVisibleTo.getVisibleToText;

  vm.markForDelete = comment => {
    comment.isForDelete = true;
  };

  vm.$onChanges = function () {
    commentManager.addOptions({
      params: {
        bookingId: vm.bookingId,
        shiftId: vm.shiftId
      }
    });
    commentManager.loadAll();
    return createNewComment();
  };

  vm.removeComment = comment => {
    return commentManager.removeEntity(
      comment,
      () => {
        return vm.onCommentsCountChange({ count: commentManager.entities.length });
      },
      {},
      false
    );
  };

  vm.getComments = () => commentManager.entities;

  vm.onSubmit = function (InvoiceShiftCommentForm) {
    let commentText;
    if (vm.newComment.text) {
      commentText = vm.newComment.text.replace(/&nbsp;/g, ' ');
    } else {
      commentText = '';
    }
    if (!InvoiceShiftCommentForm.$valid || !/\S/.test(commentText) || isSending) {
      return;
    }
    isSending = true;
    vm.newComment.text = commentText;
    return vm.newComment.save().then(function (comment) {
      commentManager.updateEntity(comment);
      vm.onCommentsCountChange({ count: commentManager.entities.length });
      createNewComment();
      return isSending = false;
    });
  };

  vm.undoDelete = function(comment) {
    comment.isForDelete = false;
  };

  const createNewComment = function () {
    vm.newComment = commentManager.createEntity();
    vm.newComment.visibleToStaff = false;
    return vm.newComment.visibleToClient = false;
  };

  vm.$onDestroy = function() {
    vm.getComments().forEach((commentEntity) => {
      if (commentEntity.isForDelete) {
        vm.removeComment(commentEntity);
      }
    });
  };

  return vm;

};

angular.module('public.invoice')
  .controller('InvoiceShiftCommentsController', dependencies.concat(InvoiceShiftCommentsController));
