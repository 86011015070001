/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'validationService', 'Client'];

const ClientShowDetailsSummaryController = function($scope, validationService, Client) {

  const vm = this;

  vm.validationService = validationService;
  vm.editable = $scope.editable;

  vm.copyClient = () => vm.client = angular.copy($scope.client);

  vm.saveClient = function () {
    vm.client.save().then(() => angular.copy(vm.client, $scope.client));
  };

  vm.getUniqueCodeErrors = function () {
    return [{
      error: 'unique-code',
      message: 'Client code must be unique',
      asyncValidation(code) {
        return Client.validate({ code, except: vm.client.id });
      }
    }];
  };

  $scope.$on('client.show.update', (event, client) => vm.client = angular.copy(client));

  vm.copyClient();

  return vm;

};

angular.module('public.security-manager.client.show')
  .controller('ClientShowDetailsSummaryController', dependencies.concat(ClientShowDetailsSummaryController));
