/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'employeeType'];

const PopupEmployeeWorkHistoryFormController = function($scope, employeeType) {

  const vm = this;

  vm.employeeType = employeeType;

  $scope.popupFormCtrl.isShortPopup = true;

  const initWorkHistory = function() {
    if ($scope.workHistory.isNew()) {
      vm.workHistory = $scope.workHistory;
      $scope.popupFormCtrl.title = 'Add new work';
      return $scope.popupFormCtrl.saveButtonText = 'Add work';
    } else {
      vm.workHistory = angular.copy($scope.workHistory);
      $scope.popupFormCtrl.title = 'Edit work';
      return $scope.popupFormCtrl.saveButtonText = 'Save';
    }
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) { return initWorkHistory(); }
  });

  $scope.$on('popup.submit', () =>
    vm.workHistory.save().then(workHistory =>
      $scope.popupCtrl.closePopup(() => $scope.popupCloseCb({workHistory}))
    )
  );

  return vm;
};

angular.module('popup.employee').controller('PopupEmployeeWorkHistoryFormController',
  dependencies.concat(PopupEmployeeWorkHistoryFormController));
