/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'validationService'];

let formNumber = 0;

const PopupFormController = function($scope, validationService) {

  const vm = this;

  vm.isShortPopup = false;
  vm.formName = `PopupForm${++formNumber}`;
  vm.validationService = validationService;
  vm.disableSaveOnInvalid = false;

  let isFormSubmitted = false;

  vm.onFormSubmit = function() {
    if (isFormSubmitted) { return; }
    vm.onBeforeSubmit();
    if ($scope[vm.formName].$invalid) {
      $scope.$broadcast('popup.error-submit', $scope[vm.formName].$error);
      return;
    }
    isFormSubmitted = true;
    $scope.$broadcast('popup.submit');
  };

  vm.resetForm = function() {
    vm.resetFormSubmitted();
    if ($scope[vm.formName]) {
      $scope[vm.formName].$setPristine();
      $scope[vm.formName].$setUntouched();
    }
  };

  vm.resetFormSubmitted = function () {
    isFormSubmitted = false;
  };

  vm.onBeforeSubmit = function() {
    // empty
  };

  vm.getPopupClass = function() {
    if (vm.popupWidth) {
      return `mod-w${vm.popupWidth}`;
    } else if (vm.isShortPopup) {
      return 'mod-w560';
    } else {
      return 'mod-w1300';
    }
  };

  vm.getSaveButtonClass = function () {
    if (vm.disableSaveOnInvalid && $scope[vm.formName].$invalid) {
      return 'is-disabled';
    }
  };

  $scope.$on('popup.open', () => vm.resetForm());

  return vm;

};

angular.module('popup').controller('PopupFormController', dependencies.concat(PopupFormController));
