/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', '$sce', '$q', 'FileAttachment', 'Upload', 'ClientServiceContactRoot', 'ClientManager', 'sessionService',
  'validationService', 'notificationService', 'currentUserService', 'editorInitializer'
];

const PopupEmailClientInvoiceController = function (
  $scope, $sce, $q, FileAttachment, Upload, ClientServiceContactRoot, ClientManager, sessionService,
  validationService, notificationService, currentUserService, editorInitializer
) {

  const vm = this;

  $scope.popupFormCtrl.title = 'E-mail with invoices';
  $scope.popupFormCtrl.saveButtonText = 'Send e-mail';
  $scope.popupFormCtrl.withoutButtons = true;
  $scope.popupFormCtrl.popupWidth = 970;

  const EDITOR_SELECTOR = '#clientInvoiceEmailEditor';

  vm.signatureEmailHTML = $sce.trustAsHtml(currentUserService.getCurrentProfile().emailSignature);

  vm.removeAttachment = attachment => _.remove(vm.clientInvoiceEmail.attachments, attachment);

  vm.isDisabledForm = function () {
    for (let attachment of Array.from(vm.clientInvoiceEmail.attachments)) {
      if (!attachment.isFinished && !attachment.invoiceVersionId) {
        return true;
      }
    }
    return false;
  };

  vm.loadContacts = function(text) {
    return $q.all([
      getClientServiceContacts(text),
      getClientManagers()
    ]).then(contacts => {
      return _.sortBy(_.uniqBy(_.flatten(contacts), 'email'), 'name');
    });
  };

  const getClientServiceContacts = function(text) {
    return ClientServiceContactRoot.autocomplete({
      clientId: vm.clientInvoiceEmail.clientId,
      serviceId: vm.clientInvoiceEmail.serviceId
    }, {
      'order[name]': 'asc',
      searchString: text
    });
  };

  const getClientManagers = function() {
    return ClientManager.query({}, {
      clientId: vm.clientInvoiceEmail.clientId,
    }).then((data) => {
      return _.map(data, (item) => {
        return {
          email: item.user.email,
          name: item.user.fullName
        }
      })
    });
  };

  vm.onTagAdding = function ($tag) {
    if ($tag.email) {
      $tag.hoverText = `${$tag.name}\n${$tag.email}`;
    } else {
      if (validationService.getEmailRegex().test($tag.name)) {
        $tag.hoverText = $tag.name;
      } else {
        return false;
      }
    }
    return true;
  };

  const addAttachment = function (attachmentFile) {
    const attachment = new FileAttachment({
      fileName: attachmentFile.name,
      fileSize: attachmentFile.size
    });
    if (!vm.clientInvoiceEmail.attachments) { vm.clientInvoiceEmail.attachments = []; }
    vm.clientInvoiceEmail.attachments.push(attachment);
    return Upload.upload({
      url: attachment.getUrl(),
      data: {
        attachment: {
          file: attachmentFile
        }
      },
      headers: {
        'X-Session-Id': sessionService.getSessionId()
      }
    }).then((response => {
      return attachment.id = response.data.id;
    }), (() => Rollbar.log(`Client invoice email attachment upload on ${window.location.href}`)), e => {
      return attachment.progress = parseInt((100.0 * e.loaded) / e.total);
    }).finally(() => attachment.isFinished = true);
  };

  const initClientInvoiceEmail = () => {
    _.each($scope.clientInvoiceEmail.receiverEmails, function(receiver) {
      receiver.hoverText = `${receiver.name}\n${receiver.email}`;
    });
    vm.clientInvoiceEmail = angular.copy($scope.clientInvoiceEmail);
  };

  const showValidationError = function() {
    notificationService.notifyError('Please enter all fields');
    $scope.popupFormCtrl.resetForm();
  };

  const isFormValid = function() {
    return !!vm.clientInvoiceEmail.text;
  };

  $scope.$on('popup.open', function () {
    if ($scope.popupShowCondition) {
      initClientInvoiceEmail();
      editorInitializer.init(EDITOR_SELECTOR);
    }
  });

  $scope.$on('popup.submit', function () {
    vm.clientInvoiceEmail.text = tinymce.activeEditor.getContent();
    if (isFormValid()) {
      vm.clientInvoiceEmail.save();
      notificationService.notifySuccess('E-mail successfully scheduled for delivery');
      $scope.popupCtrl.closePopup(() => $scope.popupCloseCb({ clientInvoiceEmail: vm.clientInvoiceEmail }));
    } else {
      showValidationError();
    }
  });

  $scope.$on('popup.error-submit', () => showValidationError());

  $scope.$watch('attachment', function () {
    if ($scope.attachment) {
      return addAttachment($scope.attachment);
    } else if ($scope[$scope.popupFormCtrl.formName].$error.maxSize) {
      return notificationService.notifyError('You can upload only 25 mb');
    }
  });

  return vm;

};

angular.module('popup.email')
  .controller('PopupEmailClientInvoiceController', dependencies.concat(PopupEmailClientInvoiceController));
