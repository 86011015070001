/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('sign.client.registration', [
  'data.client',
  'data.security-provider',
  'popup.client'
]).config(['$stateProvider', $stateProvider => {

  // return $stateProvider
  //
  //   .state('sign.client-registration', {
  //     abstract: true,
  //     url: '/client/registration/:security_provider_alias',
  //     template: require('./template.pug'),
  //     controller: 'SignClientRegistrationLayoutController',
  //     controllerAs: 'signClientRegistrationLayoutCtrl',
  //     resolve: {
  //       securityProvider: ['$stateParams', '$state', 'SecurityProvider', ($stateParams, $state, SecurityProvider) => {
  //         return SecurityProvider.get($stateParams.security_provider_alias).then(securityProvider => {
  //           return securityProvider;
  //         }).catch(() => $state.go('sign.login'));
  //       }]
  //     }
  //   })
  //   .state('sign.client-registration.account-details', {
  //     url: '/account-details',
  //     template: require('./template.pug'),
  //     controller: 'SignClientRegistrationAccountDetailsController',
  //     controllerAs: 'signClientRegistrationAccountDetailsCtrl'
  //   }).state('sign.client-registration.company-details', {
  //     url: '/company-details',
  //     template: require('./template.pug'),
  //     controller: 'SignClientRegistrationCompanyDetailsController',
  //     controllerAs: 'signClientRegistrationCompanyDetailsCtrl'
  //   }).state('sign.client-registration.locations', {
  //     url: '/locations',
  //     template: require('./template.pug'),
  //     controller: 'SignClientRegistrationLocationManagementController',
  //     controllerAs: 'signClientRegistrationLocationManagementCtrl'
  //   }).state('sign.client-registration.managers', {
  //     url: '/managers',
  //     template: require('./template.pug'),
  //     controller: 'SignClientRegistrationManagerInvitationController',
  //     controllerAs: 'signClientRegistrationManagerInvitationCtrl'
  //   });

}]);
