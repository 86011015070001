const FlexibleTimeLineViewSelector = () => {

  const VIEW_3_DAYS = 1;
  const VIEW_7_DAYS = 2;

  return class FlexibleTimeLineViewSelector {

    constructor() {
      this.viewList = [
        { label: '3 days view', days: 2, hourWidth: 1.264, offsetCount: 3.5, offset: 30, type: VIEW_3_DAYS },
        { label: 'Week view', days: 6, hourWidth: 0.54, offsetCount: 8.5, offset: 34, type: VIEW_7_DAYS }
      ];
      this.viewSelectorOpen = false;
      this.selectView(_.last(this.viewList));
    }

    getCurrentView() {
      return this.currentView;
    }

    closeViewSelector() {
      return this.viewSelectorOpen = false;
    }

    toggleViewSelector() {
      return this.viewSelectorOpen = !this.viewSelectorOpen;
    }

    selectView(view) {
      if (this.currentView === view) {
        return false;
      } else {
        this.currentView = view;
        this.isSevenDays = this.currentView.type === VIEW_7_DAYS;
        return true;
      }
    }

    getCurrentDaysCount() {
      return this.currentView.days;
    }

    selectViewByType(type) {
      if (!type) { return; }
      const view = _.find(this.viewList, v => v.type === type);
      if (view) {
        return this.selectView(view);
      }
    }
  };

}

angular.module('public.timeline').factory('FlexibleTimeLineViewSelector', FlexibleTimeLineViewSelector);
