/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'repeatableBookingInvoicePeriodType', 'dateService', 'dateWeek'
];

const PopupRepeatableBookingRuleStartFromController = function(
  $scope, repeatableBookingInvoicePeriodType, dateService, dateWeek
) {

  const vm = this;

  const MAX_MONTH_DATES = 6;
  const MAX_MONTHS = 6;

  vm.availableDates = [];
  vm.availableMonths = [];
  vm.monthNames = moment.months();

  let startDate;
  let endDate;
  let chosenDate;

  vm.isWeeklyInvoicePeriod = function() {
    return repeatableBookingInvoicePeriodType.isWeeklyType($scope.repeatableBooking.invoicePeriodType);
  };

  vm.chooseDate = function(date) {
    if (vm.isDisabledDate(date)) { return; }
    chosenDate = date;
  };

  vm.isChosenDate = function(date) {
    if (!chosenDate) { return; }
    return chosenDate.isSame(date);
  };

  vm.saveDate = function() {
    $scope.popupCtrl.closePopup(() => {
      $scope.popupCloseCb({ date: chosenDate.format(dateService.getDateFormat()) });
    });
  };

  vm.formatDate = function(date) {
    return date.format(dateService.getDateShortFormat());
  };

  vm.onMonthSelect = function(value) {
    vm.chosenMonth = value.id;
    _.every(value.availableDates, (availableDate) => {
      if (!vm.isDisabledDate(availableDate) && !chosenDate) {
        chosenDate = availableDate;
        return false;
      } else {
        return true;
      }
    });
    vm.availableDates = value.availableDates;
  };

  vm.isDisabledDate = function(date) {
    return date.isBefore();
  };

  const initDates = function() {
    startDate = moment($scope.repeatableBooking.startDate, dateService.getIsoDateFormat());
    endDate = moment($scope.repeatableBooking.endDate, dateService.getIsoDateFormat());
    chosenDate = $scope.chosenDate ? moment($scope.chosenDate, dateService.getDateFormat()) : null;
  };

  const initAvailableDates = function() {
    if (vm.isWeeklyInvoicePeriod()) {
      initWeekAvailableDates();
    } else {
      initMonthAvailableDates();
    }
  };

  const initWeekAvailableDates = function() {
    const monthDate = dateService.today().startOf('month');
    let availableMonths = [];
    for (let i = 0; i < MAX_MONTHS; i++) {
      const lastMonthDate = monthDate.clone().add(1, 'month');
      let availableMonthDates = [];
      let date = monthDate.clone();
      date.day(dateWeek.getName($scope.repeatableBooking.invoicePeriodStart));
      if (date.isBefore(monthDate)) {
        date.add(1, 'week');
      }
      while (date.isBefore(lastMonthDate) && date.isBefore(endDate)) {
        availableMonthDates.push(date.clone());
        date.add(1, 'week');
      }
      if (availableMonthDates.length > 0 && _.last(availableMonthDates).isSameOrAfter()) {
        availableMonths.push({
          id: +monthDate.format('YYYYMM'),
          text: vm.monthNames[monthDate.month()],
          date: monthDate,
          availableDates: availableMonthDates
        });
      }
      monthDate.add(1, 'month');
      if (monthDate.isAfter(endDate)) { break; }
    }
    initSelectedMonth(availableMonths);
    vm.availableMonths = availableMonths;
  };

  const initMonthAvailableDates = function() {
    let availableDates = [];
    let currentStartMonth = dateService.today().startOf('month');
    let startDateStartMonth = startDate.clone().startOf('month');
    let date = startDateStartMonth.isSameOrBefore(currentStartMonth) ? currentStartMonth : startDateStartMonth;
    for (let i = 0; i < MAX_MONTH_DATES; i++) {
      date.add(1, 'month');
      if (date.isAfter(endDate)) { break; }
      const availableDate = date.clone();
      availableDates.push(availableDate);
      if (!chosenDate && !vm.isDisabledDate(date)) {
        chosenDate = availableDate;
      }
    }
    vm.availableDates = availableDates;
  };

  const initSelectedMonth = function(availableMonths) {
    if (chosenDate) {
      const selectedMonth = _.find(availableMonths, (availableMonth) => {
        return _.find(availableMonth.availableDates, (availableDate) => {
          return availableDate.isSame(chosenDate);
        });
      });
      vm.onMonthSelect(selectedMonth);
    } else {
      vm.onMonthSelect(_.first(availableMonths));
    }
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      initDates();
      initAvailableDates();
    }
  });

  return vm;

};

angular.module('popup.repeatable-booking')
  .controller('PopupRepeatableBookingRuleStartFromController',
    dependencies.concat(PopupRepeatableBookingRuleStartFromController)
  );
