/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = ['dateService', 'confirmService'];

const PlanningHeaderDateController = function(dateService, confirmService) {

  const vm = this;

  vm.isShownCalendar = false;
  vm.format = dateService.getDateFormat();
  vm.customData =
    {withoutHeader : true};

  let confirmedFrom = undefined;
  let confirmedTo = undefined;

  vm.$onInit = function() {
    vm.from = vm.planningInteractor.from.clone();
    vm.to = vm.planningInteractor.to.clone();
    return confirmDates();
  };

  vm.getFormattedDates = () => `${vm.planningInteractor.from.format('MMM DD')} - ${vm.planningInteractor.to.format('MMM DD, YYYY')}`;

  vm.toggleCalendar = function() {
    if (vm.isShownCalendar) {
      vm.hideCalendar();
      return vm.planningShiftLoader.loadShifts();
    } else {
      return vm.isShownCalendar = true;
    }
  };

  vm.hideCalendar = function() {
    vm.isShownCalendar = false;
    if (vm.employeeService.selectedShifts.length > 0) {
      return confirmService.confirm(
        'Date change',
        'Shift selection will be cancelled, please use horizontal scroll bar to scroll timeline with selected shifts.',
        'Yes',
        updateDates,
        function() {
          vm.from = confirmedFrom.clone();
          return vm.to = confirmedTo.clone();
      });
    } else {
      return updateDates();
    }
  };

  var updateDates = function() {
    if (vm.planningInteractor.from.isSame(vm.from) && vm.planningInteractor.to.isSame(vm.to)) { return; }
    confirmDates();
    return vm.planningInteractor.updateDates(vm.from, vm.to);
  };

  var confirmDates = function() {
    confirmedFrom = vm.from.clone();
    return confirmedTo = vm.to.clone();
  };

  return vm;
};

angular.module('public.security-manager.schedule-manager.timeline.planning').controller('PlanningHeaderDateController',
  dependencies.concat(PlanningHeaderDateController));
