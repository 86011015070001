/*
 * @author Oleksandr Papka <papkaos>
 */
const gccsPopupSignTerms = popupDirectiveConfig => {
  return popupDirectiveConfig.configure({
    bodyTemplate: require('./template.pug'),
  });
};

angular.module('popup.security-manager').directive('gccsPopupSignTerms', ['popupDirectiveConfig', gccsPopupSignTerms]);
