const dependencies = [];

const ScheduleManagerShiftStatisticsController = function() {

  const vm = this;

  vm.selectFilter = stat => vm.onSelect({filter : vm.shiftStatistics.selectFilter(stat)});

  vm.isVisible = () => vm.shiftStatistics.isVisible();

  vm.$onChanges = function(changes) {
    if (changes.selectedClients || changes.clients) {
      vm.shiftStatistics.setClients(vm.clients);
      vm.shiftStatistics.setSelectedClients(vm.selectedClients);
      vm.shiftStatistics.recalculate();
    }
  };

  return vm;
};

angular
  .module('public.security-manager.schedule-manager.timeline')
  .controller('ScheduleManagerShiftStatisticsController',
    dependencies.concat(ScheduleManagerShiftStatisticsController));
