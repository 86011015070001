/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$state', '$location', '$timeout', 'FilterParamsService', 'EntityManager', 'EmployeeInvoice',
  'Employee', 'dateService', 'selectService', 'employeeInvoiceStatus', 'employeeType', 'employeeInvoiceLabelService',
  'defaultConfig', 'confirmService'
];

const InvoicesEmployeesController = function(
  $scope, $state, $location, $timeout, FilterParamsService, EntityManager, EmployeeInvoice,
  Employee, dateService, selectService, employeeInvoiceStatus, employeeType, employeeInvoiceLabelService,
  defaultConfig, confirmService
) {

  const vm = this;

  const filterParamsService = new FilterParamsService({
    order: ['number'],
    filter: ['period', 'status', 'employeeId', 'numberFilter', 'employmentType']
  });

  const entityManager = new EntityManager(EmployeeInvoice, { loadCbName: 'all' });

  vm.filterParams = filterParamsService.getParams();
  vm.employeeInvoiceStatus = employeeInvoiceStatus;
  vm.employeeType = employeeType;
  vm.loadedEmployees = {};
  vm.selectedInvoiceVersionsIds = [];
  vm.isShownStatusChangePopup = false;
  vm.isLoading = false;
  vm.showQAButtons = !defaultConfig.isProduction; 
  vm.isOpenedQAActionOptions = false;

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  vm.$onInit = function() {
    loadEmployees();
    const locationParams = $location.search();
    if (locationParams.from && locationParams.to) {
      vm.endDateFrom = moment(locationParams.from, dateService.getIsoDateFormat());
      vm.endDateTo = moment(locationParams.to, dateService.getIsoDateFormat());
    } else {
      const today = dateService.today();
      vm.endDateFrom = today.clone().subtract(30, 'days');
      vm.endDateTo = today.clone();
    }
    return vm.employeesLabels = employeeInvoiceLabelService.getEmployeesTexts(+vm.filterParams.filter.employmentType);
  };

  vm.loadMoreInvoices = () => entityManager.loadMore();

  vm.getInvoices = () => entityManager.entities;

  vm.getInvoiceVersions = invoice => {
    return invoice.sortedInvoiceVersions || (invoice.sortedInvoiceVersions = sortInvoiceVersions(invoice.versions));
  };

  vm.filterInvoices = function() {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    if (vm.endDateTo && vm.endDateTo) {
      urlParams.from = moment(vm.endDateFrom, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
      urlParams.to = moment(vm.endDateTo, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
    }
    $location.search(urlParams);
    return entityManager.load(urlParams);
  };

  vm.toggleNumberOrder = () => vm.filterParams.order.number.ascending = !vm.filterParams.order.number.ascending;

  vm.getEmployeeFor = invoice => vm.loadedEmployees[invoice.employeeId];

  vm.employeeSelected = employee => vm.filterParams.filter.employeeId = employee.id;

  vm.updateVersions = function(newVersion) {
    const invoice = _.find(entityManager.entities, invoice => invoice.id === newVersion.invoiceId);
    invoice.versions.push(newVersion);
    invoice.versionsCount++;
    return invoice.sortedInvoiceVersions = sortInvoiceVersions(invoice.versions);
  };

  vm.toggleQAActionOptions = function () {
    vm.isOpenedQAActionOptions = !vm.isOpenedQAActionOptions;
  };
  vm.closeQAActionOptions = function () {
    vm.isOpenedQAActionOptions = false;
  };

  vm.removeInvoice = invoice => entityManager.removeEntity(invoice);

  vm.openChangeStatusPopup = () => vm.isShownStatusChangePopup = true;

  vm.onBookingCheck = function(invoiceVersion) {
    const findElemPosition = vm.selectedInvoiceVersionsIds.indexOf(invoiceVersion.id);
    if (findElemPosition === -1) {
      return vm.selectedInvoiceVersionsIds.push(invoiceVersion.id);
    } else {
      return vm.selectedInvoiceVersionsIds.splice(findElemPosition, 1);
    }
  };

  vm.onChangeMultipleStatus = function(selectedStatus, paidDate, payNote) {
    vm.isShownStatusChangePopup = false;
    if (selectedStatus) {
      setMultipleStatusChange(selectedStatus, paidDate, payNote);
    }
    for (let invoice of Array.from(vm.getInvoices())) {
      for (let invoiceVersion of Array.from(invoice.versions)) {
        invoiceVersion.isChecked = false;
      }
    }
    return vm.selectedInvoiceVersionsIds = [];
  };

  vm.showLoader = () => vm.isLoading = true;

  vm.hideLoader = () => {
    return $timeout(() => vm.isLoading = false);
  };

  vm.removeOpenedPayslips = function() {
    return confirmService.confirm(
      'Remove Opened Payslips?',
      `This will remove all payslips with the status of 'OPEN'`,
      'Yes',
      () => 
        EmployeeInvoice.destroyOpenedInvoices()
        .then(function() {
          $state.reload();
        })
    );
  };

  vm.removeClosedPayslips = function() {
    return confirmService.confirm(
      'Remove Closed Payslips?',
      `This will remove all payslips that does not have a status of 'OPEN'`,
      'Yes',
      () => 
        EmployeeInvoice.destroyClosedPayslips()
        .then(function() {
          $state.reload();
        })
    );
  };

  vm.closeOpenedPayslips = function() {
    let fromDate = moment(vm.endDateFrom, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
    let toDate = moment(vm.endDateTo, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
    return confirmService.confirm(
      'Close Opened Payslips?',
      `This will delete all 'OPEN' payslips for the provided date range (${fromDate} - ${toDate}). If a payslip contains shift data then it will be marked as 'RECEIVED' instead`,
      'Yes',
      () => 
        EmployeeInvoice.closeOpenedInvoices({
          from: fromDate,
          to: toDate
        }).then(function() {
          $state.reload();
        })
    );
  };

  vm.generatePayslipForCurrentDate = function() {
    let fromDate = moment(vm.endDateFrom, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
    let toDate = moment(vm.endDateTo, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
    return confirmService.confirm(
      'Generate Open Payslips?',
      `This will create 'OPEN' payslips for the provided date range (${fromDate} - ${toDate})`,
      'Yes',
      () => 
        EmployeeInvoice.generatePayslips({
          from: fromDate,
          to: toDate
        }).then(function() {
          $state.reload();
        })
    );
  };

  const setMultipleStatusChange = function(selectedStatus, paidDate, payNote) {
    const multipleChanges = {
      ids: vm.selectedInvoiceVersionsIds,
      status: selectedStatus,
      paidDate,
      payNote
    };
    return EmployeeInvoice.bulkStatusChange(multipleChanges).then(() => $state.reload());
  };

  const sortInvoiceVersions = invoiceVersions => _.orderBy(invoiceVersions, ['id'], ['desc']);

  const loadEmployees = () => {
    return Employee.autocomplete({}, { 'order[full_name]': 'asc' }).then(function (employees) {
      _.each(employees, employee => vm.loadedEmployees[employee.id] = employee);
      vm.employees = selectService.toSelectArray(employees, {
        titleField: 'fullName',
        defaultField: 'All employees'
      });
      return vm.selectedEmployee = _.find(vm.employees, employee => {
        return employee.id === (vm.filterParams.filter.employeeId - 0);
      });
    });
  };

  $scope.$watch(() => {
    return vm.filterParams;
  }, _.debounce(vm.filterInvoices, 50), true);

  return vm;

};

angular.module('public.security-manager.invoices')
  .controller('InvoicesEmployeesController', dependencies.concat(InvoicesEmployeesController));
