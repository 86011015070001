/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', '$window', '$timeout', '$state', 'PlanningInteractor', 'PlanningScrollService', 'packageTypeService',
  'PlanningEmployeeService', 'PlanningShiftLoader', 'PlanningTutorialService', 'PlanningShiftDetailsService',
  'cableService', 'currentUserService', 'inactivityService', 'redirectService', 'pluralizeService'

];

const PlanningController = function(
  $scope, $window, $timeout, $state, PlanningInteractor, PlanningScrollService, packageTypeService,
  PlanningEmployeeService, PlanningShiftLoader, PlanningTutorialService, PlanningShiftDetailsService,
  cableService, currentUserService, inactivityService, redirectService, pluralizeService
) {

  const vm = this;

  vm.canViewPage = !currentUserService.getCurrentProfile().isSupervisor() && packageTypeService.hasProPackage();

  if (!vm.canViewPage) { return redirectService.redirectToMain(); }

  vm.planningInteractor = new PlanningInteractor;
  vm.planningShiftDetailsService = new PlanningShiftDetailsService;
  vm.planningScrollService = new PlanningScrollService(vm.planningInteractor);
  vm.planningShiftLoader = new PlanningShiftLoader(vm.planningInteractor);
  vm.employeeService = new PlanningEmployeeService(vm.planningInteractor, vm.planningShiftDetailsService);
  vm.tutorialService = new PlanningTutorialService;

  let intervalId = undefined;

  vm.$onInit = function() {
    initCable();
    updateOnResize();
  };

  vm.getHolderClass = function() {
    if (vm.tutorialService.isActive) {
      return `planning-tutorial planning-tutorial-step-${vm.tutorialService.step}`;
    } else {
      return '';
    }
  };

  vm.pluralizeSelectedShifts = () => pluralizeService.simple(vm.employeeService.selectedShifts.length, 'shift');

  vm.filterChanged = function(filterData) {
    vm.employeeService.selectedShiftsCleared();
    vm.filterData = filterData;
  };

  const updateOnResize = () => {
    return $timeout(function () {
      const hourWidth = document.querySelector('.planning-interval-day-item').getBoundingClientRect().width;
      return vm.planningInteractor.setHourWidth(hourWidth);
    });
  };

  const initCable = function() {
    const bookingTimelineChannel = cableService.createBookingTimelineChannel(
      currentUserService.getCurrentProfile().securityProvider.id
    );
    bookingTimelineChannel.addCallback('shift_employee', data => $scope.$apply(() => vm.employeeService.shiftEmployeeChanged(data)));
    bookingTimelineChannel.addCallback('shift_employee_destroyed', data => $scope.$apply(() => vm.employeeService.shiftEmployeeDestroyed(data)));
    bookingTimelineChannel.addCallback('shift_lock', data => $scope.$apply(() => vm.employeeService.shiftLocked(data)));
    bookingTimelineChannel.addCallback('shift_unlock', data => $scope.$apply(() => vm.employeeService.shiftUnlocked(data)));
    bookingTimelineChannel.addCallback('day_off', data => $scope.$apply(() => vm.employeeService.dayOffUpdated(data)));
    bookingTimelineChannel.addCallback('day_off_destroyed', data => $scope.$apply(() => vm.employeeService.dayOffDestroyed(data)));

    const planningActivityChannel = cableService.createPlanningActivityChannel();
    planningActivityChannel.addCallback('quit', () => $state.go('public.security-manager.schedule-manager.timeline.employee'));
    intervalId = inactivityService.checkActivity(() => planningActivityChannel.channel.perform('refresh'));
    return vm.planningInteractor.beforeQuit(removeEvents);
  };

  const removeEvents = function() {
    clearInterval(intervalId);
    window.onbeforeunload = null;
    $window.removeEventListener('resize', updateOnResize);
    cableService.destroyBookingTimelineChannel();
    return cableService.destroyPlanningActivityChannel();
  };

  $window.addEventListener('resize', updateOnResize);

  $scope.$on('$destroy', removeEvents);

  window.onbeforeunload = () => 'You have unsaved changes';

  return vm;
};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .controller('PlanningController', dependencies.concat(PlanningController));
