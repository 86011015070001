const gccsPlanningHeader = {
  controller: 'PlanningHeaderController',
  controllerAs: 'planningHeaderCtrl',
  template: require('./template.pug'),
  bindings: {
    planningInteractor: '<',
    planningShiftLoader: '<',
    tutorialService: '<',
    employeeService: '<',
    onApplyCb: '&',
    onFilterSelectCb: '&'
  }
};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .component('gccsPlanningHeader', gccsPlanningHeader);
