/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$stateParams', 'landingUiInteractor'];

const LandingIndexController = function($stateParams, landingUiInteractor) {

  const vm = this;

  vm.$onInit = function() {
    if ($stateParams['privacy-policy']) {
      landingUiInteractor.showPrivacyPolicy();
    }
  };

  return vm;

};

angular.module('landing').controller('LandingIndexController', dependencies.concat(LandingIndexController));
