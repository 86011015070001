/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', '$q', 'Employee', 'ClientService', 'EmployeeResource', 'EmployeeBan', 'ClientEmployeeResource', 'Role',
  'ClientTraining', 'resourceChargeType', 'employeeStatus', 'activeStatus', 'selectService', 'BulkRateService', 'rateType'
];

const ClientShowEmployeesController = function(
  $scope, $q, Employee, ClientService, EmployeeResource, EmployeeBan, ClientEmployeeResource, Role,
  ClientTraining, resourceChargeType, employeeStatus, activeStatus, selectService, BulkRateService, rateType
) {

  const vm = this;

  vm.hoveredEmployeeRowId = null;
  vm.resourcesRates = {};
  vm.isMenuDropdownOpened = false;
  vm.openedMenuDropdownCell = {};
  vm.searchEmployee = '';
  vm.checkedEmployees = [];
  vm.editable = true;
  vm.selectedRoles = [];
  vm.rateType = rateType;
  vm.selectedRateType = rateType.getPayRate();

  let selectedResourceId = 0;
  let selectedServiceId = 0;
  let selectedTrainingId = 0;
  let clientRoles = [];
  let resourceRoles = {};

  const bulkRateService = new BulkRateService(function() {
    vm.approvedEmployees = bulkRateService.getApprovedEmployees();
    vm.unapprovedEmployees = bulkRateService.getUnapprovedEmployees();
    vm.employeeResources = bulkRateService.getEmployeeResources();
  });

  vm.$onInit = () => {
    loadEmployees().then(() => loadClientData())
  };

  vm.toggleMenuDropdown = function(employeeId, resourceId) {
    if (vm.openedMenuDropdownCell.employeeId) {
      return vm.closeMenuDropdown();
    } else {
      vm.openedMenuDropdownCell.employeeId = employeeId;
      return vm.openedMenuDropdownCell.resourceId = resourceId;
    }
  };

  vm.closeMenuDropdown = function() {
    vm.openedMenuDropdownCell.employeeId = null;
    return vm.openedMenuDropdownCell.resourceId = null;
  };

  vm.isActiveCell = employeeResource => {
    return (employeeResource.employeeId === vm.openedMenuDropdownCell.employeeId) &&
      (employeeResource.resourceId === vm.openedMenuDropdownCell.resourceId);
  };

  vm.onServiceSelect = service => selectedServiceId = service.id;

  vm.getClientServices = () => {
    return _.filter(vm.clientServices, clientService => {
      return serviceIsSelected(clientService) && serviceHasResource(clientService);
    });
  };

  vm.getServiceResources = clientService => {
    return _.filter(clientService.resources, serviceResource => {
      return resourceIsSelected(serviceResource) && resourceHasTrainings(serviceResource);
    });
  };

  const serviceIsSelected = clientService => (selectedServiceId === 0) || (clientService.id === selectedServiceId);

  const resourceIsSelected = serviceResource => {
    return (selectedResourceId === 0) || (serviceResource.id === selectedResourceId);
  };

  const serviceHasResource = clientService => vm.getServiceResources(clientService).length > 0;

  const resourceHasTrainings = function(serviceResource) {
    if (selectedTrainingId === 0) { return true; }
    return _.find(serviceResource.trainings, training => training.id === selectedTrainingId);
  };

  vm.onResourceSelect = serviceResource => selectedResourceId = serviceResource.id;

  vm.onTrainingSelect = training => selectedTrainingId = training.id;

  vm.onRoleSelect = function(role) {
    if (role.id) {
      vm.selectedRoles = [role];
    } else {
      vm.selectedRoles = clientRoles
    }
  };

  vm.banEmployee = employeeId => bulkRateService.banEmployee($scope.clientShowLayoutCtrl.clientId, employeeId);

  vm.unbanEmployee = employeeId => bulkRateService.unbanEmployee($scope.clientShowLayoutCtrl.clientId, employeeId);

  vm.onCheckEmployee = function(employee) {
    const foundEmployee = vm.checkedEmployees.indexOf(employee.employeeId);
    if (foundEmployee === -1) {
      return vm.checkedEmployees.push(employee.employeeId);
    } else {
      return vm.checkedEmployees.splice(foundEmployee, 1);
    }
  };

  vm.isCheckedEmployee = employeeId => isPresent(vm.checkedEmployees, employeeId);

  vm.needCap = function(index) {
    let approvedEmployees;
    if (vm.searchEmployee) {
      approvedEmployees = _.filter(vm.approvedEmployees, compareFullName);
    } else {
      ({ approvedEmployees } = vm);
    }
    return index === approvedEmployees.length;
  };

  vm.isShownUnapprovedEmployees = function() {
    let unapprovedEmployees;
    if (!vm.unapprovedEmployees) { return false; }
    if (vm.searchEmployee) {
      unapprovedEmployees = _.filter(vm.unapprovedEmployees, compareFullName);
    } else {
      ({ unapprovedEmployees } = vm);
    }
    return unapprovedEmployees.length > 0;
  };

  vm.onApprove = function(approvedEmployeeResource) {
    if (vm.checkedEmployees.length && isPresent(vm.checkedEmployees, approvedEmployeeResource.employeeId)) {
      applyMultiSetRate(approvedEmployeeResource, true);
    } else {
      approvedEmployeeResource.save();
      bulkRateService.applySingleSetRate(approvedEmployeeResource.employeeId);
    }
    vm.uncheckEmployees();
  };

  vm.onSetRate = function(changedEmployeeResource) {
    if (vm.checkedEmployees.length && isPresent(vm.checkedEmployees, changedEmployeeResource.employeeId)) {
      applyMultiSetRate(changedEmployeeResource);
    } else {
      changedEmployeeResource.save();
      bulkRateService.applySingleSetRate(changedEmployeeResource.employeeId);
    }
    vm.uncheckEmployees();
  };

  vm.onUnapprove = function(unapprovedEmployeeResource) {
    if (vm.checkedEmployees.length && isPresent(vm.checkedEmployees, unapprovedEmployeeResource.employeeId)) {
      applyMultiUnapprove(unapprovedEmployeeResource);
    } else {
      unapprovedEmployeeResource.remove().then(function() {
        unapprovedEmployeeResource.payRate = null;
        bulkRateService.applySingleUnapprove(unapprovedEmployeeResource.employeeId);
      });
    }
    vm.uncheckEmployees();
  };

  vm.onRateChange = function(employeeResource, rateChange) {
    if (vm.checkedEmployees.length && isPresent(vm.checkedEmployees, employeeResource.employeeId)) {
      bulkRateService.applyMultiPayRateChange(
        employeeResource,
        rateChange,
        vm.checkedEmployees,
        vm.uncheckEmployees
      );
    }
  };

  const applyMultiSetRate = function(changedEmployeeResource, isApprove) {
    if (isApprove == null) { isApprove = false; }
    bulkRateService.applyMultiSetRate(
      changedEmployeeResource,
      isApprove,
      vm.checkedEmployees,
      vm.uncheckEmployees,
      rateType.getField(vm.selectedRateType)
    );
  };

  const applyMultiUnapprove = unapprovedEmployeeResource => {
    bulkRateService.applyMultiUnapprove(
      unapprovedEmployeeResource.resourceId,
      vm.checkedEmployees
    );
  };

  vm.uncheckEmployees = function() {
    vm.checkedEmployees = [];
    vm.employees.forEach(employee => employee.isChecked = false);
  };

  vm.getEmployeeResources = function(resource) {
    return _.filter(vm.employeeResources[resource.id], function(employeeResource) {
      const employee = employeeResource.employee;
      return employee.fullName.search(new RegExp(vm.searchEmployee, 'i')) !== -1 && (
        vm.selectedRoles.length === 0 ||
        vm.selectedRoles[0] instanceof Role ||
        _.intersectionBy(employee.roles, vm.selectedRoles, 'id').length > 0
      );
    });
  };

  const loadEmployees = () => {
    return Employee.query({
      'order[full_name]': 'asc',
      template: 'bulk_pay_rate',
      status: employeeStatus.getActiveStatus()
    }).then(function (employees) {
      employees.forEach(employee => employee.lowerCaseFullName = employee.fullName.toLowerCase());
      vm.employees = _.orderBy(employees, ['lowerCaseFullName']);
      return bulkRateService.setEmployees(vm.employees);
    });
  };

  const loadClientServices = function() {
    vm.clientServices = [];
    let shownResources = [];
    return ClientService.query({
      activeStatus: activeStatus.getActiveStatus()
    }, {
      clientId: $scope.clientShowLayoutCtrl.clientId
    }).then(function(services) {
      services.forEach(function(service) {
        const filteredResources = service.resources.filter(resource => {
          return !resourceChargeType.isPerUnit(resource.chargeType) && activeStatus.isActive(resource.activeStatus);
        });
        if (filteredResources.length) {
          service.resources = filteredResources;
          shownResources = shownResources.concat(filteredResources);
          return vm.clientServices.push(service);
        }
      });
      vm.clientServicesForSelect = selectService.toSelectArray(services, { defaultField: 'All' });
      vm.clientServicesForSelected = formSelected(vm.clientServicesForSelect, 'Choose service');
      vm.resourcesForSelect = selectService.toSelectArray(shownResources, { defaultField: 'All' });
      vm.resourcesForSelected = formSelected(vm.resourcesForSelect, 'Choose resource');
      return loadTrainings(shownResources);
    });
  };

  const loadClientEmployeeBans = () => {
    return EmployeeBan.query({}, { clientId: $scope.clientShowLayoutCtrl.clientId }).then(function (bannedEmployees) {
      vm.bannedEmployees = _.map(bannedEmployees, 'employeeId');
      return vm.employees.forEach(employee => employee.isBanned = isPresent(vm.bannedEmployees, employee.employeeId));
    });
  };

  const loadApprovedEmployees = () => {
    return Employee.getWithResources({
      clientId: $scope.clientShowLayoutCtrl.clientId
    }).then(function (employeeWithResources) {
      vm.approvedEmployees = [];
      vm.unapprovedEmployees = [];
      vm.approvedEmpoyeesIds = _.map(employeeWithResources.approvedEmployees, 'employeeId');
      vm.employees.forEach(function (employee) {
        employee.hasApprovedResource = isPresent(vm.approvedEmpoyeesIds, employee.employeeId);
        if (employee.hasApprovedResource) {
          return vm.approvedEmployees.push(employee);
        } else {
          return vm.unapprovedEmployees.push(employee);
        }
      });
      bulkRateService.setApprovedEmployees(vm.approvedEmployees);
      return bulkRateService.setUnapprovedEmployees(vm.unapprovedEmployees);
    });
  };

  const loadTrainings = shownResources => {
    return ClientTraining.query({
      'resourceId[]': _.map(shownResources, 'id')
    }, {
      clientId: $scope.clientShowLayoutCtrl.clientId
    }).then(function (trainings) {
      if (trainings.length === 0) { return; }
      _.each(shownResources, resource => {
        return resource.trainings = _.filter(trainings, training => {
          return training.resourceId === resource.id;
        });
      });
      vm.trainingsForSelect = selectService.toSelectArray(trainings, { defaultField: 'All' });
      return vm.trainingsForSelected = formSelected(vm.trainingsForSelect, 'Choose training');
    });
  };

  const getApprovedEmployee = function(employeeResources, resourceId, employee) {
    let approvedEmployee = null;
    if (employeeResources.length) {
      employeeResources.forEach(function(resource) {
        if ((resource.employeeId === employee.employeeId) && (resource.resourceId === resourceId)) {
          return approvedEmployee = resource;
        }
      });
    }
    return approvedEmployee;
  };

  const loadClientEmployeeResources = () => {
    return ClientEmployeeResource.query({}, { clientId: $scope.clientShowLayoutCtrl.clientId });
  };

  const loadClientRoles = function() {
    return Role.query({ clientId: $scope.clientShowLayoutCtrl.clientId }).then(function(roles) {
      clientRoles = roles;
      vm.rolesForSelect = selectService.toSelectArray(roles, { defaultField: 'All' });
      vm.rolesForSelected = formSelected(vm.rolesForSelect, 'Choose role');
    });
  };

  const setResources = function(result) {
    const [employeeResources] = Array.from(result);
    const resourcesIds = [];
    vm.clientServices.forEach(service =>
      service.resources.forEach(function(resource) {
        resourcesIds.push(resource.id);
        vm.resourcesRates[resource.id] = {
          contractorPayRate: resource.contractorPayRate,
          employedPayRate: resource.employedPayRate,
          chargeRate: resource.chargeRate
        };
        resourceRoles[resource.id] = resource.roles;
      })
    );
    return buildResourcesTable(resourcesIds, employeeResources);
  };

  const buildResourcesTable = (resourcesIds, employeeResources) => {
    return resourcesIds.forEach(function (resourceId) {
      vm.employeeResources[resourceId] = [];
      vm.employees.forEach(function (employee) {
        const approvedEmployee = getApprovedEmployee(employeeResources, resourceId, employee);
        return buildResourcesArray(approvedEmployee, resourceId, employee);
      });
      return bulkRateService.setEmployeeResources(vm.employeeResources);
    });
  };

  const buildResourcesArray = function(approvedEmployee, resourceId, employee) {
    let id, payRate, chargeRate, overtimeRate, overtimeMinHours, rateChange, employeeFullName;
    if (approvedEmployee) {
      ({ id, payRate, chargeRate, overtimeRate, overtimeMinHours, rateChange } = approvedEmployee);
      employeeFullName = approvedEmployee.employee.fullName;
    } else {
      id = null;
      payRate = null;
      chargeRate = null;
      overtimeRate = null;
      overtimeMinHours = null;
      rateChange = null;
      employeeFullName = employee.fullName;
    }
    return vm.employeeResources[resourceId].push(new EmployeeResource({
      id, payRate, chargeRate, overtimeRate, overtimeMinHours, rateChange, resourceId,
      employeeId: employee.employeeId,
      isBanned: isPresent(vm.bannedEmployees, employee.employeeId),
      hasApprovedResource: employee.hasApprovedResource,
      resourceRoles: resourceRoles[resourceId],
      employee: {
        fullName: employeeFullName,
        roles: employee.roles,
        employmentType: employee.employmentType
      }
    }));
  };

  const isPresent = (collection, element) => collection.indexOf(element) !== -1;

  const compareFullName = employee => employee.fullName.toLowerCase().indexOf(vm.searchEmployee.toLowerCase()) !== -1;

  const loadClientData = function() {
    vm.employeeResources = {};
    return $q.all([
      loadClientEmployeeResources(),
      loadClientServices(),
      loadClientEmployeeBans(),
      loadClientRoles(),
      loadApprovedEmployees()
    ]).then(result => setResources(result));
  };

  const formSelected = function(collection, defaultText) {
    const selectedCollection = _.cloneDeep(collection);
    selectedCollection[0].text = defaultText;
    return selectedCollection;
  };

  return vm;

};

angular.module('public.security-manager.client.show')
  .controller('ClientShowEmployeesController', dependencies.concat(ClientShowEmployeesController));
