/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'securityManagerAccessLevel', 'securityManagerAccessLevelDescription', 'stringService', 'currentUserService'
];

const ManagerAccessLevelDescriptionController = function(
  $scope, securityManagerAccessLevel, securityManagerAccessLevelDescription, stringService, currentUserService
) {

  const vm = this;

  vm.securityManagerAccessLevel = securityManagerAccessLevel;
  vm.securityManagerAccessLevelDescription = securityManagerAccessLevelDescription;
  vm.activeDropdown = null;

  vm.$onInit = function() {
    vm.isNewManager = !vm.securityManager.id;
    if (vm.isShownViewOnlyFilter()) {
      vm.securityManager.restrictedEntitiesInfo().then(function(data) {
        vm.restrictionEntitiesInfo = data;
      });
    }
  };
  
  vm.getNewLabel = function() {
    if (vm.isNewManager) {
      return 'new ';
    }
  };

  vm.isShownAccessFilter = function() {
    return !vm.securityManagerAccessLevel.isManager(currentUserService.getCurrentProfile().accessLevel) &&
      (vm.securityManagerAccessLevel.isSupervisor(vm.securityManager.accessLevel) ||
      vm.securityManagerAccessLevel.isManager(vm.securityManager.accessLevel))
  };

  vm.isShownViewOnlyFilter = function() {
    const currentProfile = currentUserService.getCurrentProfile();
    return vm.securityManagerAccessLevel.isManager(currentProfile.accessLevel) &&
      vm.securityManager.id === currentProfile.id;
  };

  vm.onAccessFilterApply = function(queryParams, service) {
    _.each(vm.securityManager.restrictedEntities, (restrictedEntity, key) => {
      vm.securityManager.restrictedEntities[key] = queryParams[stringService.toSnakeCase(key)];
    });
    if (_.every(vm.securityManager.restrictedEntities, (restrictedEntity) => {
      return restrictedEntity.length === 0;
    })) {
      service.setData(vm.securityManager);
      service.updateSelectedClients();
    }
  };

  vm.getRestrictedEntitiesCount = function(field) {
    const count = vm.securityManager.restrictedEntities[`${field}Ids`].length;
    if (count === 0) {
      return 'All';
    } else {
      return count;
    }
  };
  
  vm.toggleDropdown = function(field) {
    if (vm.activeDropdown !== field) {
      vm.activeDropdown = field
    } else {
      vm.closeActiveDropdown();
    }
  };
  
  vm.closeActiveDropdown = function() {
    vm.activeDropdown = null;
  };
  
  vm.isActiveDropdown = function(field) {
    return vm.activeDropdown === field;
  };

  return vm;

};

angular.module('shared-components')
  .controller('ManagerAccessLevelDescriptionController', dependencies.concat(ManagerAccessLevelDescriptionController));
