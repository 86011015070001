/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$q', 'SecurityManager', 'User', 'Session', 'currentUserService',
  'sessionService', 'storageService', 'defaultConfig'
];

const SignSecurityProviderStep1Controller = function(
  $scope, $q, SecurityManager, User, Session, currentUserService,
  sessionService, storageService, defaultConfig
) {

  const vm = this;

  $scope.signSecurityProviderLayoutCtrl.isSubmitButtonDisabled = false;

  vm.$onInit = function () {
    initSecurityManager();
  };

  vm.getEmailErrors = () => {
    return [{
      error: 'unique',
      message: `Email already exists. 
        <span class="form-btn sign-form-link mod-sign" style="display: inline-block">
          <a href="/sign/login" class="sign-form-link-val">Try signing in</a>
        </span>`,
      asyncValidation(email) {
        if (email) {
          return User.validate({ email, except: vm.securityManager.userId });
        } else {
          return $q.when(false);
        }
      }
    }];
  };

  const initSecurityManager = function () {
    $scope.signSecurityProviderLayoutCtrl.loadSecurityProvider().then(function(securityProvider) {
      if (securityProvider) {
        vm.securityManager = new SecurityManager(securityProvider.accountOwner);
      } else {
        vm.securityManager = new SecurityManager({ registrationForm: true });
      }
    });
  };

  const onSuccess = (securityManager) => {
    $scope.signSecurityProviderLayoutCtrl.updateSecurityProviderId(securityManager.securityProviderId);
    $scope.signSecurityProviderLayoutCtrl.resetCache();
    $scope.signSecurityProviderLayoutCtrl.goNext();
  };

  $scope.$on('security-provider.registration.submit', () => {
    let loginData = _.pick(vm.securityManager.user, 'email', 'password');
    loginData.registration = true;
    if (!currentUserService.isSecurityManagerLogged()) {
      storageService.remove(defaultConfig.sessionKey)
    }
    vm.securityManager.save().then(function (securityManager) {
      if (currentUserService.isSecurityManagerLogged()) {
        onSuccess(securityManager);
      } else {
        sessionService.simpleLogin(loginData, () => {
          Session.getInstance().then(currentSession => {
            currentUserService.setCurrentUserAndSession(currentSession);
            onSuccess(securityManager);
          });
        });
      }
    });
  });

  return vm;
};

angular.module('sign.security-provider')
  .controller('SignSecurityProviderStep1Controller', dependencies.concat(SignSecurityProviderStep1Controller));
