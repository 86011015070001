require('angular');
require('lodash');
require('angular-ui-router');
require('angular-css');
require('angularjs-rails-resource');
require('angular-local-storage');
require('ng-file-upload');
require('pdfjs-dist/build/pdf.combined');
require('angularjs-pdf');
window.moment = require('moment'); // does not normally define globally
require('angular-datepicker');
require('angucomplete-alt');
require('ngMask');
require('angular-dynamic-number');
require('angular-dnd');
require('angular-drag-and-drop-lists');
window.download = require('downloadjs');
require('printjs');
window.d3 = require('d3');
window['d3-selection-multi'] = require('d3-selection-multi');
window.c3 = require('c3');
require('ng-tags-input');
require('cleave.js/dist/cleave-angular.min');
require('cleave.js/dist/addons/cleave-phone.i18n');
window.webauthnJSON = require('@github/webauthn-json')

const allModules = require.context('../angular', true, /module\.js$/);
allModules.keys().forEach(allModules);
const allRouters = require.context('../angular', true, /router\.js$/);
allRouters.keys().forEach(allRouters);
const restFiles = require.context('../angular', true, /.*\/(?:(?!(module|router)\.js|.*\.pug).)*$/);
restFiles.keys().forEach(restFiles);

require('../assets/sprite/index');
