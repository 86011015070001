const dependencies = [
  '$rootScope', '$q', 'User', 'Session', 'SecurityManager', 'Employee', 'ClientManagerRoot',
  'profileType', 'cableService'
];

const currentUserService = function(
  $rootScope, $q, User, Session, SecurityManager, Employee, ClientManagerRoot,
  profileType, cableService
) {

  let _currentUser = null;
  let _currentSession = null;
  let _currentProfile = null;

  const isSecurityManagerLogged = () => profileType.isSecurityManager(_currentSession);

  const isEmployeeLogged = () => profileType.isEmployee(_currentSession);

  const isClientManagerLogged = () => profileType.isClientManager(_currentSession);

  const isTemporaryProfileLogged = () => profileType.isTemporary(_currentSession);

  const wrapProfile = function(profile) {
    if (isTemporaryProfileLogged()) {
      _currentProfile = profile;
    } else {
      let modelClass;
      if (isSecurityManagerLogged()) {
        modelClass = SecurityManager;
      } else if (isEmployeeLogged()) {
        modelClass = Employee;
      } else if (isClientManagerLogged()) {
        modelClass = ClientManagerRoot;
      }
      _currentProfile = new modelClass(profile);
    }
  };

  return {
    setCurrentUserAndSession(session) {
      _currentSession = session;
      _currentUser = new User(_currentSession.user);
      wrapProfile(_currentSession.profile);
      _currentSession.user = _currentUser;
      _currentSession.profile = _currentProfile;
      cableService.addDelayCb(() => {
        cableService.createSessionChannel(_currentSession.id).setCallbacks('logout', [() => {
          $rootScope.$broadcast('logout');
        }]);
      });
      window.onfocus = function() { // hack to ensure logout if session was destroyed
        Session.getCurrent().catch(function(error) {
          if (error.status !== -1) { // aborted request
            $rootScope.$broadcast('logout');
          }
        });
      };
    },

    updateCurrentUser(user) {
      angular.copy(user, _currentUser);
    },

    updateProfile(profile) {
      angular.copy(profile, _currentProfile);
    },

    resetCurrentUser() {
      _currentSession = null;
      _currentUser = null;
      _currentProfile = null;
    },

    getCurrentSession() {
      return _currentSession;
    },

    getCurrentUser() {
      return _currentUser;
    },

    getCurrentProfile() {
      return _currentProfile;
    },

    getProfileType() {
      return _currentSession.profileType;
    },

    isSecurityManagerLogged,
    isEmployeeLogged,
    isClientManagerLogged
  };
};

angular.module('shared').service('currentUserService', dependencies.concat(currentUserService));
