/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['$scope', 'clientStatus'];

const EmployeeClientApproveClientsController = function($scope, clientStatus) {

  const vm = this;

  vm.clientSearchString = '';
  vm.clientStatus = clientStatus;

  const selectFirstClient = function() {
    if (vm.getApprovedClients().length) {
      vm.selectClient(_.first(vm.getApprovedClients()).id);
    } else if (vm.getUnapprovedClients().length) {
      vm.selectClient(_.first(vm.getUnapprovedClients()).id);
    }
  };

  vm.getApprovedClients = function() {
    return _.filter(vm.clients, (client) => {
      return client.resourceIds.length && includesSearchName(client) && filteredByStatus(client.status);
    });
  };

  vm.getUnapprovedClients = function() {
    return _.filter(vm.clients, (client) => {
      return !client.resourceIds.length && includesSearchName(client) && filteredByStatus(client.status);
    });
  };

  vm.selectClient = function(clientId) {
    vm.selectedClientId = clientId;
  };

  vm.isClientSelected = function(clientId) {
    return vm.selectedClientId === clientId;
  };

  const includesSearchName = function(client) {
    return client.name.search(new RegExp(vm.clientSearchString, "i")) !== -1;
  };

  const filteredByStatus = function(status) {
    if (vm.selectedFilterClientId) {
      return status === vm.selectedFilterClientId
    } else {
      return true;
    }
  };

  $scope.$watch(() => vm.clients, (newVal, oldVal) => {
    if (newVal) {
      selectFirstClient();
    }
  });

  return vm;

};

angular.module('public.security-manager.employee')
  .controller('EmployeeClientApproveClientsController', dependencies.concat(EmployeeClientApproveClientsController));
