/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = [
  'SeriesDecorator', 'timelineSeriesGroupService', 'dateService', 'dSeriesGroupService'
];

const BookingDecorator = (
  SeriesDecorator, timelineSeriesGroupService, dateService, dSeriesGroupService
) => {

  const ISO_FULL_DATE_FORMAT = dateService.getIsoFullDateFormat();

  return class BookingDecorator {

    constructor(clientId, clientLogo, booking) {
      this.clientId = clientId;
      this.clientLogo = clientLogo;
      this.booking = booking;
      this.updateBooking(booking);
      this.id = booking.id;
      this.repeatedFromId = booking.repeatedFrom ? booking.repeatedFrom.id : null;
      this._initName();
      this._decorateSeries();
      this.updateStatisticValues();
    }

    isSameDBooking(dBooking) {
      if (!dBooking) { return false; }
      return dBooking.id === this.id;
    }

    getSeriesForShift(dShift) {
      for (let dSeries of Array.from(this.dSeries)) {
        if (dSeries.hasDShift(dShift)) {
          return dSeries;
        }
      }
    }

    groupSeries() {
      const visibleSeries = _.orderBy(this.dSeries.filter(s => s.visible === true), dSeries => dSeries.id);
      return this.shiftRows = dSeriesGroupService
        .groupDSeries(visibleSeries)
        .map(groupedDShifts => ({ visible: true, dShifts: groupedDShifts }));
    }

    setVisibilityFromStats(currentStatFilter) {
      let visible = false;
      for (let series of Array.from(this.dSeries)) {
        if (series.setVisibilityFromStats(currentStatFilter) && !visible) {
          visible = true;
        }
      }
      return this.visible = visible;
    }

    openShiftFormPopup() {
      return this.isShownShiftFormPopup = true;
    }

    closeShiftFormPopup() {
      return this.isShownShiftFormPopup = false;
    }

    closeShiftTimelinePopups(activeDShift) {
      return Array.from(this.dSeries).map((dSeries) =>
        dSeries.closeShiftTimelinePopups(activeDShift));
    }

    getNotCoveredResourcesCount() {
      return this.notCoveredResourcesCount;
    }

    updateShiftsForSeries(dSeriesToUpdate, shifts) {
      return Array.from(this.dSeries).map((dSeries) => {
        return dSeries.updateShiftsForSeries(dSeriesToUpdate, shifts);
      });
    }

    updateEmployeeShift(employeeShift) {
      return Array.from(this.dSeries).map((dSeries) => {
        return dSeries.updateEmployeeShift(employeeShift);
      });
    }

    removeEmployeeShift(employeeShift) {
      return Array.from(this.dSeries).map((dSeries) => {
        return dSeries.removeEmployeeShift(employeeShift);
      });
    }

    hasEmployeeShift(employeeShift) {
      for (let dSeries of Array.from(this.dSeries)) {
        if (dSeries.hasEmployeeShift(employeeShift)) {
          return true;
        }
      }
      return false;
    }

    hasLockedSeries() {
      for (let dSeries of Array.from(this.dSeries)) {
        if (dSeries.series.lockedBy) {
          return true;
        }
      }
      return false;
    }

    updateBooking(booking, series) {
      _.merge(this.booking, booking);
      if (series) {
        this.booking.series = series;
      }
      this.startTime = moment(booking.times.startTime, ISO_FULL_DATE_FORMAT);
      this.endTime = moment(booking.times.endTime, ISO_FULL_DATE_FORMAT);
      this.bookingDimensions = undefined;
      this.clientDimensions = undefined;
      return this;
    }

    updateStatisticValues() {
      return this.notCoveredResourcesCount = this._getNotCoveredResourcesCount();
    }

    invalidateCache() {
      this.bookingDimensions = undefined;
      this.clientDimensions = undefined;
      return Array.from(this.dSeries).map((dSeries) => {
        return dSeries.invalidateCache();
      });
    }

    clearDimensions() {
      this.clientDimensions = undefined;
      this.bookingDimensions = undefined;
      return Array.from(this.dSeries).map((dSeries) => {
        return Array.from(dSeries.dShifts).map((dShift) => {
          return dShift.shiftDimensions = undefined;
        });
      });
    }

    _getNotCoveredResourcesCount() {
      let total = 0;
      for (let series of Array.from(this.dSeries)) {
        for (let shift of Array.from(series.dShifts)) {
          total += shift.notCoveredResourcesCount;
        }
      }
      return total;
    }

    _decorateSeries() {
      this.dSeries = [];
      Array.from(this.booking.series).map((series) => {
        this.dSeries.push(new SeriesDecorator(this, series, this.updateStatisticValues.bind(this)));
      });
    }

    _initName() {
      if (this.booking.repeatedFrom) {
        const from = moment(this.booking.from, dateService.getIsoDateFormat());
        const to = moment(this.booking.to, dateService.getIsoDateFormat());
        this.name = `Invoice period ${from.format('DD.MM')} - ${to.format('DD.MM')}`;
      } else {
        this.name = this.booking.name;
      }
    }

  };

};

angular.module('public.timeline').factory('BookingDecorator', dependencies.concat(BookingDecorator));
