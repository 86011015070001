/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$element', 'HomeZone', 'validationService', 'lengthUnitOfMeasureService',
  'confirmService', 'packageTypeService'
];

const SettingsHomeZoneController = function(
  $scope, $element, HomeZone, validationService, lengthUnitOfMeasureService,
  confirmService, packageTypeService
) {

  const vm = this;

  vm.homeZones = [];
  vm.validationService = validationService;
  vm.lengthUnitOfMeasureService = lengthUnitOfMeasureService;

  let isFormSubmitted = false;

  vm.$onInit = function() {
    packageTypeService.checkProPackage();
    HomeZone.query().then(function (homeZones) {
      vm.homeZones = homeZones;
      return addHomeZoneIfEmpty();
    });
  };

  vm.onHomeZoneSubmit = function(homeZone) {
    if (isFormSubmitted || getForm().$invalid) { return; }
    isFormSubmitted = true;
    return homeZone.save().then(function() {
      isFormSubmitted = false;
      return vm.disableEditingFor(homeZone);
    });
  };

  vm.addHomeZone = () => vm.homeZones.push(new HomeZone({isEditing : true}));

  vm.editHomeZone = function(homeZone) {
    __guard__(findEditingHomeZone(), x => x.isEditing = false);
    return homeZone.isEditing = true;
  };

  vm.disableEditingFor = function(homeZone) {
    if (homeZone.isNew()) {
      if (vm.homeZones.length > 1) { return _.remove(vm.homeZones, homeZone); }
    } else {
      return homeZone.isEditing = false;
    }
  };

  vm.deleteHomeZone = homeZone =>
    confirmService.confirmRemove('home zone', () =>
      homeZone.delete().then(function() {
        _.remove(vm.homeZones, homeZone);
        return addHomeZoneIfEmpty();
      })
    )
  ;

  vm.getButtonName = function(homeZone) {
    if (homeZone.isNew()) {
      return 'Add Zone';
    } else {
      return 'Save Zone';
    }
  };

  vm.isShownAddButton = () => findEditingHomeZone() === undefined;

  var findEditingHomeZone = () => _.find(vm.homeZones, homeZone => homeZone.isEditing);

  var addHomeZoneIfEmpty = function() {
    if (vm.homeZones.length === 0) { return vm.addHomeZone(); }
  };

  var getForm = () => $element.find('form').data().$formController;

  return vm;
};

angular.module('public.security-manager.settings').controller('SettingsHomeZoneController',
  dependencies.concat(SettingsHomeZoneController));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
