/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.employee').directive('gccsPopupEmployeeForm', ['popupDirectiveConfig', popupDirectiveConfig =>
  popupDirectiveConfig.configure({
    bodyTemplate: require('./template.pug'),
    scope: {
      employee: '=',
      withoutStatus: '<',
      checkPackageRestrictions: '&'
    }
  })
]);
