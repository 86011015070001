const dependencies = [];

const PopupSetRateController = function() {

  const vm = this;

  vm.isShownHint = true;

  vm.setRates = function(isInvalid) {
    if (!isInvalid) {
      vm.onSubmit();
    }
  };

  vm.closeHint = function() {
    vm.isShownHint = false;
  }

  return vm;

};

angular.module('shared-components.popup.employee')
  .controller('PopupSetRateController', dependencies.concat(PopupSetRateController));

