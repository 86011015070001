const gccsManagerClockInProgress = {
  controller: 'ManagerClockInProgressController',
  template: require('./template.pug'),
  bindings: {
    shift: '<',
    shiftEmployee: '<',
    clockIn: '&',
    clockOut: '&',
    editClockInOut: '&'
  }
};

angular.module('shared-components.popup.booking').component('gccsManagerClockInProgress', gccsManagerClockInProgress);
