/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsPopupEmployeeTimelineShiftInfo = {
  template: require('./template.pug'),
  controller: 'PopupEmployeeTimelineShiftInfoController',
  bindings: {
    shiftEmployee: '<',
    unpaidBreakText: '<',
    employeeStatusNames: '<',
    isShownDenyReason: '&',
    isInDeclineProcess: '&'
  }
};

angular.module('popup.employee').component('gccsPopupEmployeeTimelineShiftInfo', gccsPopupEmployeeTimelineShiftInfo);
