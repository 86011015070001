/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [];

const SecurityManagerClientEmailReceiverController = function() {

  const vm = this;

  vm.isShownReceivers = false;

  vm.toggleReceivers = function(receiver) {
    vm.isShownReceivers = !vm.isShownReceivers;
  };

  vm.hideReceivers = function() {
    vm.isShownReceivers = false;
    vm.receiverSearch = '';
  };

  return vm;

};

angular.module('public.security-manager.client.show')
  .controller('SecurityManagerClientEmailReceiverController', dependencies.concat(SecurityManagerClientEmailReceiverController));
