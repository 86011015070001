/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$timeout', 'currentUserService', 'securityManagerStateAccessService',
  'redirectService', 'systemPackagePopupService', 'cableService'
];

const SecurityManagerLayoutController = function(
  $scope, $timeout, currentUserService, securityManagerStateAccessService,
  redirectService, systemPackagePopupService, cableService
) {

  const vm = this;

  const REPEATABLE_BOOKING_SUCCESS_TIME = 5000;

  vm.isLogged = false;
  vm.systemPackagePopupService = systemPackagePopupService;

  if (currentUserService.isSecurityManagerLogged() && securityManagerStateAccessService.canViewCurrentState()) {
    vm.isLogged = true;
  } else {
    redirectService.redirectToMain();
  }

  let isRepeatableBookingSuccess = false;

  vm.$onInit = function() {
    initCableChannel();
  };

  vm.reloadPage = function() {
    window.location.reload();
  };

  vm.isShownRepeatableBookingLoader = function() {
    if (currentUserService.getCurrentProfile()) {
      return currentUserService.getCurrentProfile().isSavingRepeatableBooking;
    } else {
      return false;
    }
  };

  vm.isShownRepeatableBookingSuccess = function() {
    return isRepeatableBookingSuccess;
  };

  vm.closeRepeatableBookingSuccess = function() {
    isRepeatableBookingSuccess = false;
  };

  const initCableChannel = function() {
    const securityManagerChannel = cableService.createSecurityManagerChannel(currentUserService.getCurrentProfile().id);
    securityManagerChannel.addCallback('finish_repeatable_booking', () => {
      $timeout(() => {
        currentUserService.getCurrentProfile().isSavingRepeatableBooking = false;
        isRepeatableBookingSuccess = true;
        $timeout(() => {
          vm.closeRepeatableBookingSuccess();
        }, REPEATABLE_BOOKING_SUCCESS_TIME);
      });
    },100);
    const cleanup = function() {
      securityManagerChannel.destroy();
    };
    window.onunload = cleanup;
    $scope.$on('$destroy', cleanup);
  };

  return vm;

};

angular.module('public.security-manager')
  .controller('SecurityManagerLayoutController', dependencies.concat(SecurityManagerLayoutController));
