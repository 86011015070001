const dependencies = ['$http'];

const downloadService = function($http) {

  const downloadBase64File = function (data) {
    download(`data:${data.mime};base64,${data.content.replace(/\s/g, '')}`, data.filename, data.mime);
  };

  const downloadWithoutCache = function(file, fileName) {
    $http.get(file, { 
      responseType: 'arraybuffer', 
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
    .then(function(response) {
       let blob = new Blob([response.data],{type: response.headers("content-type")});
       download(blob, fileName);
    });
  };

  return {
    downloadBase64File,
    downloadWithoutCache
  }

};

angular.module('shared').service('downloadService', dependencies.concat(downloadService));
