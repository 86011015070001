/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('shared').factory('cacheBusterInterceptor', function() {
  return {
    request: function(config) {
      if (config.url.match(/^https?:\/\/[^\/]*\/api/)) {
        config.url = config.url.replace(/[?|&]timestamp=\d+/, '');
        // Some urls already have '?' attached
        config.url += config.url.indexOf('?') === -1 ? '?' : '&';
        config.url += 'timestamp=' + new Date().getTime();
      }
      return config;
    }
  };
});
