angular.module('public.security-manager.reports.hr', [])
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('public.security-manager.reports.hr', {
        url: '/hr',
        abstract: true,
        template: require('./template.pug'),
        controller: 'ReportsHrController',
        controllerAs: 'reportsHrCtrl'
      })
      .state('public.security-manager.reports.hr.licence', {
        url: '/licence',
        template: require('./licence/template.pug'),
        controller: 'ReportsHrLicenceController',
        controllerAs: 'reportsHrLicenceCtrl'
      })
      .state('public.security-manager.reports.hr.employee-list', {
        url: '/employee-list',
        template: require('./employee-list/template.pug'),
        controller: 'ReportsHrEmployeeListController',
        controllerAs: 'reportsHrEmployeeListCtrl'
      })
      .state('public.security-manager.reports.hr.employee-availability', {
        url: '/employee-availability',
        template: require('./employee-availability/template.pug'),
        controller: 'ReportsHrEmployeeAvailabilityController',
        controllerAs: 'reportsHrEmployeeAvailabilityCtrl'
      })
      .state('public.security-manager.reports.hr.missing-data', {
        url: '/missing-data',
        template: require('./missing-data/template.pug'),
        controller: 'ReportsHrMissingDataController',
        controllerAs: 'reportsHrMissingDataCtrl'
      })
  ]);