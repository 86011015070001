/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').directive('googleAutocomplete', ['googleService', googleService => {
  return {
    restrict : 'A',
    require : '?ngModel',
    link($scope, $el, attrs, ngModel) {

      const element = $el[0];
      const type = attrs['googleAutocomplete'] || 'geocode';

      return googleService.onLoad(function() {
        const autocomplete = new google.maps.places.Autocomplete(element, {types : [type]});
        return autocomplete.addListener('place_changed', function() {
          ngModel.$setViewValue(element.value);
          const event = new CustomEvent('googlePlaceChanged', {detail : element.value});
          return element.dispatchEvent(event);
        });
      });
    }
  }
}]);
