/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = ['$scope', '$element', '$compile', '$parse', 'FormValidator', 'dateService'];

const FormInputHolderController = function($scope, $element, $compile, $parse, FormValidator, dateService) {

  const vm = this;

  const element = $element[0];

  let form = undefined;
  let field = undefined;

  let $form = undefined;
  let $field = undefined;
  let $errorsHolder = undefined;

  let formName = undefined;
  let fieldName = undefined;
  let formController = undefined;
  let formValidator = undefined;
  let formScope = undefined;
  let errorMessages = {};

  const errorsWithoutSubmit = [];

  const getErrorMessage = (error, message) => {
    return `<span
      ${$scope.isNewWrapper ? 'class="input-wrapper__msg"' : ''} 
      ng-show="${formName}.${fieldName}.$error['${error}']" 
      data-error="${error}"
    >
      ${message}
    </span>`;
  };

  // TODO: move additional args to options object
  const addErrorMessage = function(error, messageArg = errorMessages[error], alwaysAdd = false, showWithoutSubmit = false) {
    if (showWithoutSubmit) { errorsWithoutSubmit.push(error); }

    const condition = () => {
      return alwaysAdd || $field.attr(error) || $field.attr(`ng-${error}`) || ($field.attr('type') === error);
    };

    const replaceErrorMessage = function() {
      const errorElement = element.querySelector(`[data-error='${error}']`);
      if (condition()) {
        let message = _.isFunction(messageArg) ? messageArg() : messageArg;
        message += '. ';
        if (errorElement) {
          return errorElement.innerHTML = message;
        } else {
          return $errorsHolder.append($compile(getErrorMessage(error, message))(formScope));
        }
      } else {
        return (errorElement != null ? errorElement.remove() : undefined);
      }
    };

    replaceErrorMessage();
    return $scope.$watch(condition, replaceErrorMessage);
  };

  const initErrorMessages = function() {
    const customMessages = $scope.errorMessages ? $scope.errorMessages() : {};
    return errorMessages = _.defaults(customMessages, {
      required: 'Please fill out this field',
      pattern: 'Wrong format',
      mask: 'Wrong format',
      parse: 'Wrong format',
      number: 'Invalid number',
      'autocomplete-required': 'Please select record',
      minlength() {
        return `Minimum length is ${$field.attr('ng-minlength')}`;
      },
      maxlength() {
        return `Maximum length is ${$field.attr('ng-maxlength')}`;
      },
      max() {
        return `Max value is ${$field.attr('max')}`;
      }
    });
  };

  const addErrorMessages = function() {
    $element.append('<span class="form-msg"></span>');
    if ($scope.isNewWrapper) {
      $errorsHolder = $element;
    } else {
      $errorsHolder = angular.element(element.querySelector('.form-msg'));
    }
    _.each(['required', 'pattern', 'mask', 'number', 'minlength', 'maxlength', 'max'], error => addErrorMessage(error));
    if ($field.attr('min-date')) {
      addErrorMessage('min', function() {
        const minDate = $parse($field.attr('min-date'))(formScope);
        if (minDate) {
          return `Min date is ${minDate.format(dateService.getDateFormat())}`;
        } else if ($field.attr('min-date-error-msg')) {
          return $field.attr('min-date-error-msg');
        } else {
          return 'Wrong min date';
        }
      }, true);
    } else {
      addErrorMessage('min', () => `Min value is ${$field.attr('min')}`);
    }
    if (field.closest('angucomplete-alt')) {
      addErrorMessage('autocomplete-required', errorMessages['autocomplete-required'], true);
    }
    if ($field.attr('ngf-pattern')) {
      addErrorMessage('pattern', 'Unsupported format', true, true);
    }
    if ($field.attr('ngf-max-size')) {
      const maxSize = $field.attr('ngf-max-size').replace(/'/g, '');
      return addErrorMessage('maxSize', `Too big file. Must be less then ${maxSize}`, true, true);
    }
  };

  const addCustomErrors = function() {
    formValidator = new FormValidator(formController);
    return _.each($scope.customErrors(), function(messageData) {
      if (messageData.condition) {
        formValidator.addCustomValidator(fieldName, messageData.error, messageData.condition, messageData.compositeField);
      } else {
        formValidator.addCustomAsyncValidator(
          fieldName,
          messageData.error,
          messageData.asyncValidation,
          messageData.compositeField
        );
      }
      addErrorMessage(messageData.error, messageData.message, true);
      return formController[fieldName].$validate();
    });
  };

  const toggleErrorClasses = function() {
    const errorClassName = $scope.isNewWrapper ? 'input-wrapper__data--error' : 'is-error';
    $scope.$watch(errorCondition, () => $element.toggleClass(errorClassName, errorCondition()));
    $scope.$watch(acceptedCondition, () => $element.toggleClass('is-accepted', acceptedCondition()));
  };

  const errorCondition = () => {
    return formController.$submitted && formController[fieldName] && formController[fieldName].$invalid || hasErrorWithoutSubmit();
  };

  const acceptedCondition = () => {
    return formController[fieldName] && formController[fieldName].$touched && formController[fieldName].$valid && !!formController[fieldName].$viewValue;
  };

  const hasErrorWithoutSubmit = () => _.some(errorsWithoutSubmit, error => formController[fieldName].$error[error]);

  const initFormScope = function() {
    formScope = $scope;
    while ((formScope = formScope.$parent)) {
      if (formScope[formName]) { return; }
    }
  };

  const showFlashError = function() {
    if (!$scope.flashError) { return; }
    addErrorMessage('flash', $scope.flashError, true);
    $scope.flashError = undefined;
    formController[fieldName].$error.flash = true;
    formController[fieldName].$invalid = true;
    return $field.bind('input', function() { // todo via ngModel
      formController[fieldName].$error = {};
      formController[fieldName].$validate();
    });
  };

  const onLoad = function() {
    form = element.closest('[ng-form]') || element.closest('form[name]');
    $form = angular.element(form);
    field = element.querySelector('[name]');
    if (!form || !field) { return; }
    $field = angular.element(field);
    fieldName = $field.attr('name');
    formName = $form.attr('ng-form') || $form.attr('name');
    initFormScope();
    if (!formScope) { return; }
    formController = formScope[formName];
    if (!formController) { return; }
    if (!$scope.withoutErrorMessages) {
      initErrorMessages();
      addErrorMessages();
      if ($scope.customErrors) { addCustomErrors(); }
    }
    toggleErrorClasses();
  };

  $scope.$watch('flashError', showFlashError);
  setTimeout(onLoad, 0);

  return vm;

};

angular.module('form').controller('FormInputHolderController', dependencies.concat(FormInputHolderController));
