/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$http', 'userValidation', 'currentUserService', 'notificationService', 'redirectService', 'sessionService'
];

const profileDetailsTwoFactorAuthController = function(
  $scope, $http, userValidation, currentUserService, notificationService, redirectService, sessionService
) {

  const vm = this;

  vm.otp_populated = false;
  vm.flashErrors = {};
  vm.twoFactorAuthEnabled = false;
  vm.userValidation = userValidation;
  vm.confirmationOtpCode = "";
  vm.webauthnCreateErrorMessage = "";
  vm.secondFactorCodePresent = true;
  vm.webauthnCredentials = [];
  vm.newWebAuthnNickname = "";
  vm.authSessionId = "";
  vm.authCode = {};

  vm.$onInit = function () {
    copyUser();
    resetAuthCode();
    if(vm.user.secondFactorEnabled) {
      loadWebAuthCredentials();
    }
    vm.twoFactorAuthEnabled = vm.user.secondFactorEnabled;
  };

  const copyUser = function () {
    vm.userCopy = angular.copy(vm.user);
  };

  vm.redirectToMain = () => {
    redirectService.redirectToMain();
  };

  vm.onSubmit = function () {
    updateOtpDetails(() => { },
    (error) => {
      console.log("error: ", error.data.error);
    });
  };

  vm.enableSecondFactorAuth = () => {
    if(vm.isOtpVerificationCodeValid()) { 
      vm.user.verification_code = [
        vm.authCode.digit1, 
        vm.authCode.digit2, 
        vm.authCode.digit3,
        vm.authCode.digit4,
        vm.authCode.digit5,
        vm.authCode.digit6
      ].join("");

      updateOtpDetails(() => {
        vm.errorMessage = "";
      },
      (error) => {
        if(error.status == 403)
        {
          vm.authCode = {};
          resetAuthCode();
          vm.errorMessage = "The code is invalid. Please try again";
        }
        else {
          vm.errorMessage = error.data.error;
        }
      });
    }
  };

  vm.confirmDeleteWebAuthnCredential = (credential) => {
    vm.credentialToRemove = credential;
    vm.requiresConfirmation = true;    
  };

  vm.deleteWebAuthnCredential = () => {
    vm.user.deleteWebAuthnCredential(vm.credentialToRemove.externalId).then(() => {
      if(vm.webauthnCredentials.length == 1) {
        vm.webauthnCredentials = [];
      }
      else {
        var index = vm.webauthnCredentials.findIndex(function(item) {
          return item.externalId === vm.credentialToRemove.externalId;
        });
        if (index !== -1) {
          vm.webauthnCredentials.splice(index, 1);
        }
      }
      vm.hideConfirmation();
      notificationService.notifySuccess('Credential has been removed!');
    }, (error) => { 
      vm.hideConfirmation();
      console.log(error);
    });
  };

  vm.addWebAuthnCredential = () => {
    try {
      if(vm.newWebAuthnNickname.length < 5) {
        vm.webauthnCreateErrorMessage = "Please provide a name for the Credential";
        return;
      }
      $http.post("/api/webauthn_credentials/options",null, {
        headers: {
          'X-Session-Id': sessionService.getSessionId(),
          'X-Second-Factor-Auth-Id': vm.authSessionId
        }
      }).then(function (response) {
        vm.webauthnCreateData = { publicKey: response.data };
        vm.authSessionId = response.data.encryptedId;
        registerWebAuthn();
      });
    } catch (error) {
      console.log(error);
    }
  }

  const updateOtpDetails = function (cbSuccess, cbError) {
    vm.user.secondFactorEnabled = vm.twoFactorAuthEnabled;
    vm.user.updateOtp().then(() => {
      vm.otp_populated = false;
      vm.otpQrCode = "";
      vm.otpSecret = "";
      vm.secondFactorCodePresent = false;
      notificationService.notifySuccess('Your profile was successfully updated!');
      cbSuccess();
    }, (error) => { cbError(error); });
  };

  const initOtpDetails = function () {
    vm.user.otpDetails().then((result) => {
      vm.otpQrCode = "data:image/svg+xml;base64," + result.qrcodeBase64;
      vm.otpSecret = result.otpSecret.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      vm.otp_populated = true;
      vm.secondFactorCodePresent = false;
    });
  };

  vm.toggleTwoFactorAuthEnabled = function() {
    vm.twoFactorAuthEnabled = !vm.twoFactorAuthEnabled;
    if(vm.twoFactorAuthEnabled && !vm.user.secondFactorEnabled)
    {
      initOtpDetails();
    }
    else{
      vm.otp_populated = false;
    }
  };

  vm.isOtpVerificationCodeValid = function() {
    return vm.authCode && vm.authCode.digit6
  };

  vm.hideConfirmation = function() {
    vm.requiresConfirmation = false
    vm.credentialToRemove = null;
  };

  const isSecondFactorAuthChanged = function () {
    return vm.twoFactorAuthEnabled !== vm.user.secondFactorEnabled;
  };

  const loadWebAuthCredentials = function () {
    vm.user.getWebAuthnCredentials().then((response) => {
      if(response && response.credentials) {
        vm.webauthnCredentials = response.credentials;
      }
    });
  };

  const resetAuthCode = function() {
    vm.authcode = {};
  }


  const registeredCredentials = function() {
    return getRegistrations().map((reg) => ({
      id: reg.rawId,
      type: reg.type,
    }));
  };

  const registerWebAuthn = function() {
    webauthnJSON.create(vm.webauthnCreateData).then(function(credential) {
      credential.nickname = vm.newWebAuthnNickname;
      $http.post("/api/webauthn_credentials",credential, {
        headers: {
          'X-Session-Id': sessionService.getSessionId(),
          'X-Second-Factor-Auth-Id': vm.authSessionId
        }
      }).then( (response) => {
        console.log(response);
        vm.webauthnCreateErrorMessage = "";
        if (response.status == 200) {
          vm.newWebAuthnNickname = "";
          vm.authSessionId = "";
          vm.webauthnCreateData = {};
          loadWebAuthCredentials();
        } else {
          console.log("Sorry, something wrong happened.");
        }
      });
    },(error) => {
      if(error.name === "InvalidStateError") {
        vm.webauthnCreateErrorMessage = "This authentication credential is already added";
      }
      else {
        console.log(error);
        vm.webauthnCreateErrorMessage = "An error has occured. please try again.";
      }
      vm.newWebAuthnNickname = "";
      $scope.$digest();
    });
  }
  

  return vm;
};

angular.module('public.security-manager.profile')
  .controller('profileDetailsTwoFactorAuthController', dependencies.concat(profileDetailsTwoFactorAuthController));
