/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsPopupEmployeePayrollCreate = popupDirectiveConfig => {
  return popupDirectiveConfig.configure({
    bodyTemplate: require('./template.pug'),
    scope: {
      payroll: '<'
    }
  })
};

angular.module('popup.employee-payroll')
  .directive('gccsPopupEmployeePayrollCreate', ['popupDirectiveConfig', gccsPopupEmployeePayrollCreate]);
