/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('form').directive('gccsFormSeparateInputs', () => {
  return {
    restrict : 'A',
    link : function (scope, element, attr) {

      const BACKSPACE_KEYCODE = 8;

      const inputs = element[0].querySelectorAll('input');

      inputs.forEach(function (input, index, array) {
        input.addEventListener('keyup', function (e) {
          if (e.keyCode === BACKSPACE_KEYCODE && index != 0 && !this.value) {
            array[index - 1].value = '';
            array[index - 1].focus();
          }
        });

        input.addEventListener('input', function () {
          if (this.value) {
            if (this.value.length > 1) {
              this.value = this.value[this.value.length - 1];
              angular.element(this).data().$ngModelController.$setViewValue(this.value);
            }
            if (index !== array.length - 1) {
              array[index + 1].focus();
            }
          } else {
            if (index != 0) {
              array[index - 1].focus();
            }
          }
        });
      });
    }
  };
});
