angular.module('public.security-manager.employee').component('gccsEmployeeClientApproveResource', {
  template: require('./template.pug'),
  controller: 'EmployeeClientApproveResourceController',
  bindings: {
    employee: '<',
    resource: '<',
    employeeResource: '<',
    onResourceApprove: '&',
    onResourceReject: '&'
  }
});
