const gccsInvoiceEmployeeFormInvoiceVersion = {
  controller: 'InvoiceEmployeeFormInvoiceVersionController',
  template: require('./template.pug'),
  bindings: {
    invoice: '<',
    invoiceVersion: '<',
    editable: '<',
    compareWith: '<',
    makeCopy: '<',
    statusEditable: '<',
    paidDateChangeCb: '&',
    preselectedStatus: '<'
  }
};

angular
  .module('public.invoice.employee')
  .component('gccsInvoiceEmployeeFormInvoiceVersion', gccsInvoiceEmployeeFormInvoiceVersion);
