/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$state', '$scope', 'currentUserService', 'cableService', 'redirectService',
  'userAgentService', 'employeeSiblingProfileService'
];

const EmployeeLayoutController = function(
  $state, $scope, currentUserService, cableService, redirectService,
  userAgentService, employeeSiblingProfileService
) {

  const vm = this;

  const INTERVAL = 60 * 10 * 1000;

  vm.isLogged = false;
  vm.userAgentService = userAgentService;

  let isShownMobilePopupFlag = true;

  vm.isShownMobileAppPopup = function () {
    return currentUserService.isEmployeeLogged() && userAgentService.isMobile() && isShownMobilePopupFlag;
  };

  vm.closeMobileAppPopup = function () {
    isShownMobilePopupFlag = false;
  };

  const destroyChannels = (employeeId, intervalId) => {
    return function () {
      cableService.destroyEmployeeTimelineChannel(employeeId);
      cableService.destroyEmployeeActivityChannel(employeeId);
      clearTimeout(intervalId);
    };
  };

  const initCable = function() {
    employeeSiblingProfileService.getSiblingProfiles().then(function(employees) {
      employees.forEach(function(employee) {
        const employeeId = employee.id;
        cableService.createEmployeeTimelineChannel(employeeId);
        cableService.createEmployeeActivityChannel(employeeId);
        const intervalId = setInterval(() => cableService.getEmployeeActivityChannel().channel.updateActive(), INTERVAL);
        window.onunload = destroyChannels(employeeId, intervalId);
        return $scope.$on('$destroy', destroyChannels(employeeId, intervalId));
      });
    });
  };

  if (currentUserService.isEmployeeLogged()) {
    vm.isLogged = true;
    initCable();
  } else {
    redirectService.redirectToMain();
  }

  return vm;
};

angular.module('public.employee').controller('EmployeeLayoutController', dependencies.concat(EmployeeLayoutController));
