/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$q', 'Employee', 'DayOff', 'bookingShiftEmployeeAvailability', 'bookingShiftEmployeeAssignStatus',
  'employeeDayOffRequestStatus', 'employeeStatus', 'resourceChargeType'
];

const EmployeeSidebarEmployeeList = (
  $q, Employee, DayOff, bookingShiftEmployeeAvailability, bookingShiftEmployeeAssignStatus,
  employeeDayOffRequestStatus, employeeStatus, resourceChargeType
) => {

  const INITIAL_EMPLOYEES_LIMIT = 20;

  const ONLY_AVAILABLE = bookingShiftEmployeeAvailability.getOnlyAvailable();
  const ONLY_APPROVED = bookingShiftEmployeeAvailability.getOnlyApproved();
  const APPROVED_AND_AVAILABLE = bookingShiftEmployeeAvailability.getApprovedAndAvailable();

  const BANNED_FOR_CLIENT_STATUS = bookingShiftEmployeeAssignStatus.getBannedForClientStatus();
  const IN_DAY_OFF_STATUS = bookingShiftEmployeeAssignStatus.getInDayOffStatus();
  const IN_SICK_DAY_STATUS = bookingShiftEmployeeAssignStatus.getInSickDayStatus();
  const NOT_ASSIGNED_STATUS = bookingShiftEmployeeAssignStatus.getNotAssignedStatus();
  const IN_AVAILABILITY_STATUS = bookingShiftEmployeeAssignStatus.getInAvailabilityStatus();

  const ACTIVE_STATUS = employeeStatus.getActiveStatus();

  const SEARCH_PARAMS = { status: ACTIVE_STATUS, template: 'sidebar' };

  const LOADING = 1;

  return class EmployeeSidebarEmployeeList {

    constructor(actionsService) {
      this.employeePredicate = this.employeePredicate.bind(this);
      this.actionsService = actionsService;
      this.employeesFromSearch = undefined;
      this.selectedShifts = undefined;
      this.employeeRows = undefined;
      this.shiftsData = {}; // map[shiftId]SidebarData
      this._init();
    }

    setSelectedShifts(shifts) {
      this.selectedShifts = shifts || [];
      this._setActionsServiceShifts();
      const shiftIds = _.map(this.selectedShifts, 'id');
      for (let key of Array.from(_.keys(this.shiftsData))) {
        if (!_.includes(shiftIds, parseInt(key))) {
          delete this.shiftsData[key];
        }
      }
      const shiftsToUpdate = [];
      for (let shift of Array.from(this.selectedShifts)) {
        if (!this.shiftsData[shift.id]) {
          this.shiftsData[shift.id] = LOADING;
          shiftsToUpdate.push(shift);
        }
      }
      const shiftDataPromises = _.map(shiftsToUpdate, shift => this._loadShiftData(shift));
      return $q.all(shiftDataPromises).then(shiftDataArr => {
        if (!((this.selectedShifts != null ? this.selectedShifts.length: undefined) > 0)) { return; }
        for (const shiftData of Array.from(shiftDataArr)) {
          if (_.find(this.selectedShifts, shift => shift.id === shiftData.shiftId)) {
            this._setEmployeeStatuses(shiftData.shiftData);
            this.shiftsData[shiftData.shiftId] = shiftData.shiftData;
          }
        }
        this._setEmployeeRows();
        return this.actionsService.setEmployees(this.employeeRows);
      });
    }

    findShiftEmployee(employeeId, shiftId) {
      const shiftEmployees = this.shiftsData[shiftId] != null ? this.shiftsData[shiftId].shiftEmployees : undefined;
      if (!shiftEmployees) { return; }
      return _.find(shiftEmployees, shiftEmployee => shiftEmployee.employeeId === employeeId);
    }

    _setActionsServiceShifts() {
      return this.actionsService.setShifts(_.map(this.selectedShifts, shift => shift.shift));
    }

    _updateConflictedShifts() {
      if (this.selectedShifts.length === 1) {
        return (() => {
          const result = [];
          for (let shiftData of Array.from(this._getShiftDataValues())) {
            result.push(this._setConflictedShifts(shiftData));
          }
          return result;
        })();
      } else {
        return (() => {
          const result1 = [];
          for (let shiftData of Array.from(this._getShiftDataValues())) {
            result1.push(this._clearConflictedShifts(shiftData));
          }
          return result1;
        })();
      }
    }

    _clearConflictedShifts(shiftData) {
      return Array.from(shiftData.clientEmployees).map((employee) =>
        (employee.conflictedShifts = undefined));
    }

    _getShiftDataValues() {
      return _.filter(_.values(this.shiftsData), data => data !== LOADING);
    }

    _setEmployeeRows() {
      this._updateConflictedShifts();
      const employeeRows = {};
      const perUnit = _.some(this.selectedShifts, dShift => {
        return resourceChargeType.isPerUnit(dShift.shift.resource.chargeType);
      });
      for (let shiftData of Array.from(this._getShiftDataValues())) {
        for (let employee of Array.from(shiftData.clientEmployees)) {
          const employeeRow = employeeRows[employee.id];
          if (employeeRow) {
            if (perUnit) {
              employeeRow.perUnit = true;
            }
            if (employeeRow.availabilityStatus !== employee.availabilityStatus &&
              this._availabilityIsNotPresent(employeeRow.availabilityStatus, employee.availabilityStatus)) {
              employeeRow.differingStatus = true;
            }
          } else {
            employeeRows[employee.id] = _.cloneDeep(employee);
            if (perUnit) {
              employeeRows[employee.id].perUnit = true;
            }
          }
        }
      }
      return this.employeeRows = _.values(employeeRows);
    }

    clearSelectedShifts() {
      this.actionsService.clearShifts();
      this.selectedShifts = undefined;
      return this.shiftsData = {};
    }

    showSearchEmployees() {
      this.employeeRows = this.employeesFromSearch;
      return this.actionsService.setEmployees(this.employeeRows);
    }

    // shift -> Promise({ shiftId, shiftData })
    _loadShiftData(dShift) {
      return Employee.query({
        template: 'sidebar',
        shiftId: dShift.shift.id || dShift.id,
        resourceId: dShift.shift.resource.id,
        clientId: dShift.clientId || dShift.shift.clientId,
        from: dShift.shift.startTime,
        to: dShift.shift.endTime,
        status: ACTIVE_STATUS
      }).then(shiftData => {
        return {
          shiftId: dShift.id,
          shiftData
        };
      });
    }

    searchEmployees(queryParams) {
      return Employee.query(_.assign(SEARCH_PARAMS, queryParams)).then(employees => {
        this.employeesFromSearch = employees;
        return this.showSearchEmployees();
      });
    }

    _setConflictedShifts(shiftData) {
      for (let employee of Array.from(shiftData.conflictedEmployees)) {
        employee.isConflicted = true;
      }
      return (() => {
        const result = [];
        for (let employee of Array.from(shiftData.clientEmployees)) {
          const conflictedShiftEmployees = _.filter(shiftData.conflictedEmployees, shiftEmployee => {
            return shiftEmployee.employeeId === employee.id;
          });
          if (conflictedShiftEmployees.length !== 0) {
            result.push(employee.conflictedShifts = conflictedShiftEmployees);
          } else {
            result.push(employee.conflictedShifts = undefined);
          }
        }
        return result;
      })();
    }

    _init() {
      this._loadInitialEmployees();
      this._initEmployeeFilter();
      return this._initSearch();
    }

    _initSearch() {
      return this.searchQuery = '';
    }

    _initEmployeeFilter() {
      this.bookingShiftEmployeeFilterNames = bookingShiftEmployeeAvailability.getNames();
      this.selectedEmployeeFilter = bookingShiftEmployeeAvailability.getAll();
    }

    _loadInitialEmployees() {
      return Employee.query({
        status: ACTIVE_STATUS,
        limit: INITIAL_EMPLOYEES_LIMIT,
        template: 'sidebar'
      }).then(employees => {
        this.employeesFromSearch = employees;
        this.employeeRows = this.employeesFromSearch;
        return this.actionsService.setEmployees(this.employeeRows);
      });
    }

    shiftEmployeeDestroyed({ shift_employee_id }) {
      for (let shiftData of Array.from(_.values(this.shiftsData))) {
        _.remove(shiftData.conflictedEmployees, conflictedEmployee => shift_employee_id === conflictedEmployee.id);
      }
      this._setActionsServiceShifts();
      return this._setEmployeeRows();
    }

    _setEmployeeStatuses(shiftData) {
      return (() => {
        const result = [];
        for (let employee of Array.from(shiftData.clientEmployees)) {
          let shiftEmployee;
          switch (false) {
            case !employee.isBannedForClient:
              result.push(employee.availabilityStatus = BANNED_FOR_CLIENT_STATUS);
              break;
            case !employee.hasDayOff:
              result.push(employee.availabilityStatus = IN_DAY_OFF_STATUS);
              break;
            case !employee.hasSickDay:
              result.push(employee.availabilityStatus = IN_SICK_DAY_STATUS);
              break;
            case !(shiftEmployee = this._findShiftEmployee(shiftData.shiftEmployees, employee)):
              result.push(employee.availabilityStatus = shiftEmployee.assignStatus);
              break;
            case !employee.hasAvailability:
              result.push(employee.availabilityStatus = IN_AVAILABILITY_STATUS);
              break;
            default:
              result.push(employee.availabilityStatus = NOT_ASSIGNED_STATUS);
          }
        }
        return result;
      })();
    }

    _findShiftEmployee(shiftEmployees, employee) {
      return _.find(shiftEmployees, shiftEmployee => shiftEmployee.employeeId === employee.id);
    }

    _checkAvailability(employee) {
      if (!employee.availabilityStatus) { return true; }
      return employee.isSubContractor === true || employee.availabilityStatus === NOT_ASSIGNED_STATUS || employee.availabilityStatus === IN_AVAILABILITY_STATUS;
    }

    _checkBan(employee) {
      return employee.isBannedForClient;
    }

    _checkApproval(employee) {
      return employee.isApprovedForClient;
    }

    _availabilityIsNotPresent(employeeRowAvailabilityStatus, employeeAvailabilityStatus) {
      return employeeRowAvailabilityStatus !== bookingShiftEmployeeAssignStatus.getInAvailabilityStatus() &&
        employeeAvailabilityStatus !== bookingShiftEmployeeAssignStatus.getInAvailabilityStatus();
    };

    employeePredicate(employee, queryParams) {
      let filterStatus = true;
      if (queryParams['availabilities[]']) {
        switch (+queryParams['availabilities[]'][0]) {
          case ONLY_AVAILABLE:
            filterStatus = this._checkAvailability(employee) && !this._checkBan(employee);
            break;
          case ONLY_APPROVED:
            filterStatus = this._checkApproval(employee);
            break;
          case APPROVED_AND_AVAILABLE:
            filterStatus = this._checkAvailability(employee) && this._checkApproval(employee) && !this._checkBan(employee);
            break;
          default:
            filterStatus = true;
        }
      }
      if (queryParams['roles[]'] && filterStatus) {
        filterStatus = _.some(employee.roles, role => queryParams['roles[]'].indexOf(role.id) !== -1);
      }
      if (queryParams['genders[]'] && filterStatus) {
        filterStatus = queryParams['genders[]'].indexOf(employee.gender) !== -1;
      }
      if (queryParams['trainings[]'] && filterStatus) {
        filterStatus = (employee.passedLicenses != null ? employee.passedLicenses.length: undefined) && _.every(queryParams['trainings[]'], trainingId => _.find(employee.passedLicenses, {trainingId}));
      }
      if (queryParams['home_zones[]'] && filterStatus) {
        filterStatus = _.some(employee.homeZones, homeZone => queryParams['home_zones[]'].indexOf(homeZone.id) !== -1);
      }
      if (queryParams['sites[]'] && filterStatus) {
        filterStatus = _.some(employee.sites, site => queryParams['sites[]'].indexOf(site.id) !== -1) ||
          _.some(employee.locations, location => _.intersection(queryParams['sites[]'], location.siteIds).length > 0);
      }
      if (queryParams['locations[]'] && filterStatus) {
        filterStatus = _.some(employee.sites, site => queryParams['sites[]'].indexOf(site.id) !== -1) ||
          _.some(employee.locations, location => queryParams['locations[]'].indexOf(location.id) !== -1);
      }
      return filterStatus;
    }

    shiftUpdated(dShift) {
      const shiftId = dShift.id;
      const shiftEmployees = dShift.shift.employeesShifts;
      const shiftData = this.shiftsData[shiftId];
      if (shiftData) {
        shiftData.shiftEmployees = shiftEmployees;
        this._setEmployeeStatuses(shiftData);
      }
      if (_.find(this.selectedShifts, shift => shift.id === shiftId)) {
        this._setActionsServiceShifts();
      }
      return this._setEmployeeRows();
    }

    // TODO: change not to reload each time
    dayOffUpdated() {
      this._reloadAllData();
    }

    dayOffDestroyed() {
      this._reloadAllData();
    }

    sickDayUpdated() {
      this._reloadAllData();
    }

    sickDayDestroyed() {
      this._reloadAllData();
    }

    availabilityUpdated() {
      this._reloadAllData();
    }

    availabilityDeleted() {
      this._reloadAllData();
    }

    _reloadAllData() {
      this.shiftsData = {};
      return this.setSelectedShifts(this.selectedShifts);
    }
  };

};

angular
  .module('public.security-manager.schedule-manager.timeline')
  .factory('EmployeeSidebarEmployeeList', dependencies.concat(EmployeeSidebarEmployeeList));
