/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'uploadService', 'userValidation', 'notificationService', 'currentUserService'
];

const EmployeeProfileSettingsPersonalDetailsController = function(
  $scope, uploadService, userValidation, notificationService, currentUserService
) {
  
  const vm = this;

  vm.flashErrors = {};

  vm.getOldPasswordErrors = userValidation.getPasswordErrors;
  vm.getNewPasswordErrors = userValidation.getPasswordErrors;

  vm.getEmailErrors = () => {
    return userValidation.getEmailErrors(
      $scope.employeeProfileSettingsLayoutCtrl.user && $scope.employeeProfileSettingsLayoutCtrl.user.id
    );
  };

  $scope.$on('employee.profile-settings.submit', function() {
    const changedUser = !_.isEqual($scope.employeeProfileSettingsLayoutCtrl.user, currentUserService.getCurrentUser());
    if (changedUser) {
      return uploadService.saveWithFile($scope.employeeProfileSettingsLayoutCtrl.user, 'avatar', function(user) {
        user.oldPassword = undefined;
        user.newPassword = undefined;
        $scope.employeeProfileSettingsLayoutCtrl.updateUser(user);
        notificationService.notifySuccess('Your profile was successfully updated!');
      }, response => {
        return vm.flashErrors[response.data.field] = response.data.error;
      }, function(userSaveResponse) {
        vm.flashErrors[userSaveResponse.data.field] = userSaveResponse.data.error;
        return $scope.employeeProfileSettingsLayoutCtrl.resetFormSubmitted();
      });
    } else {
      return $scope.employeeProfileSettingsLayoutCtrl.resetFormSubmitted();
    }
  });

  return vm;
};

angular.module('public.employee.profile-settings').controller('EmployeeProfileSettingsPersonalDetailsController',
  dependencies.concat(EmployeeProfileSettingsPersonalDetailsController));
