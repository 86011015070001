/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.employee').factory('EmployeeAddressHistory', ['AppModel', function(AppModel) {

  class EmployeeAddressHistory extends AppModel {
    static initClass() {
      this.configure({ url: '/employees/{{employeeId}}/address_histories/{{id}}', name: 'address_history' });
    }

    static getGapInfo(employeeId) {
      return this.$get(this.resourceUrl({ employeeId }, 'gap_info'));
    }
  }

  EmployeeAddressHistory.initClass();
  return EmployeeAddressHistory;
}
]);