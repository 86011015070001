const dependencies = [];

const ToggleButtonController = function() {

  const vm = this;

  vm.onClick = function(e) {
    if (vm.isRestricted) {
      e.preventDefault();
      if (vm.onRestrictCb) { vm.onRestrictCb(); }
    }
  };

  return vm;

};

angular.module('shared-components')
  .controller('ToggleButtonController', dependencies.concat(ToggleButtonController));
