/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$state', 'SecurityProviderSystemPackage', 'VerifyAuthCodeService',
  'phoneNumberService', 'securityProviderChosenPackageOptions', 'SecurityProviderSetting'
];

const SignSecurityProviderStep4Controller = function(
  $scope, $state, SecurityProviderSystemPackage, VerifyAuthCodeService,
  phoneNumberService, securityProviderChosenPackageOptions, SecurityProviderSetting
) {

  const vm = this;

  vm.secretPhoneNumber = null;
  vm.isPolicyAgreed = false;
  vm.isShownSignTermsPopup = false;
  vm.errorMessage = null;
  vm.requiresSmsCode = false;
  vm.country = null;

  vm.verifyAuthCodeService = new VerifyAuthCodeService();

  let securityManager;
  let securityProvider;

  $scope.signSecurityProviderLayoutCtrl.isSubmitButtonDisabled = true;

  vm.$onInit = function () {
    initUser();
  };

  vm.openSignTermsPopup = function () {
    vm.isShownSignTermsPopup = true;
  };

  vm.closeSignTermsPopup = function () {
    vm.isShownSignTermsPopup = false;
  };

  const initUser = function() {
    $scope.signSecurityProviderLayoutCtrl.loadSecurityProvider().then(function(loadedSecurityProvider) {
      securityProvider = loadedSecurityProvider;
      securityManager = loadedSecurityProvider.accountOwner;
      SecurityProviderSetting.get({ securityProviderId: securityProvider.id }).then(function(securityProviderSettings) {
        vm.country = securityProviderSettings.country;
        vm.secretPhoneNumber = phoneNumberService.secretize(securityManager.phoneNumber);
        vm.verifyAuthCodeService.sendCode({
          userId: securityManager.userId,
          phoneNumber: securityManager.phoneNumber,
          countryId: vm.country.id,
          resetCb: () => {
            vm.errorMessage = null;
            vm.requiresSmsCode = true;
          },
          failCb: (error) => {
            if(error.status == 422 && error.data.error == "SMS authentication disabled for country")
            {
              vm.requiresSmsCode = false;
              return
            }
            vm.errorMessage = 'Phone number is not valid';
          }
        });
      });
    }).catch(function() {
      $scope.signSecurityProviderLayoutCtrl.gotoFirstStep();
    });
  };

  const savePackage = function() {
    const chosenPackageOptions = securityProviderChosenPackageOptions.get();
    return new SecurityProviderSystemPackage({
      securityProviderId: securityProvider.id,
      packageType: chosenPackageOptions.packageType,
      licensesCount: chosenPackageOptions.numberOfEmployees
    }).save();
  };

  $scope.$on('security-provider.registration.submit', () => {
    if(vm.requiresSmsCode){
      vm.verifyAuthCodeService.confirmUserPhoneCode().then(function() {
        savePackage().then(function() {
          $state.go('sign.security-provider-success');
        });
      }).catch(function(response) {
        vm.errorMessage = response.data.error;
        $scope.signSecurityProviderLayoutCtrl.resetFormSubmitted();
      });
    }
    else{
      savePackage().then(function() {
        $state.go('sign.security-provider-success');
      });
    }
  });

  $scope.$watch('SecurityProviderForm.$valid', function (newVal) {
    $scope.signSecurityProviderLayoutCtrl.isSubmitButtonDisabled = !newVal;
  });

  return vm;

};

angular.module('sign.security-provider')
  .controller('SignSecurityProviderStep4Controller', dependencies.concat(SignSecurityProviderStep4Controller));
