const gccsClientResourceTable = {
  controller: 'ClientResourceTableController',
  controllerAs: 'clientResourceTableCtrl',
  template: require('./template.pug'),
  bindings: {
    service: '<',
    isEditable: '<',
    isCreatingClient: '<',
    title: '@',
    updateResourceSelect: '&'
  }
};

angular.module('public.security-manager.client.resource').component('gccsClientResourceTable', gccsClientResourceTable);
