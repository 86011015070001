const dependencies = ['$location', 'ExtendedFilterService', 'clientStatus'];

const ExtendedFilterLiveFeedService = ($location, ExtendedFilterService, clientStatus) => {

  const PARAM_CLIENTS = 'client_ids[]';

  return class ExtendedFilterLiveFeedService extends ExtendedFilterService {

    constructor(filterData) {
      super(...arguments);
      this.filterData = filterData;
      this.searchString = '';
      this.clientCategories = this.getClientCategories();
    }

    setFilterType(filterType) {
      this.filterType = filterType;
    }

    initFilter() {
      const searchParams = $location.search();
      this.selectedClients = [].concat(searchParams[PARAM_CLIENTS]); // ensure array
      for (let category of this.categories) {
        if (this.isClientCategory(category)) {
          for (let option of category.options) {
            if (this.selectedClients.includes(option.id + '')) {
              option.isSelected = true;
            }
          }
        }
      }
      this._updateQueryParams();
      this._updateTopPanel();
    }

    isClientCategory(category) {
      return category.params === PARAM_CLIENTS;
    }

    applyFilter(...args) {
      const val = args[0],
        obj = val != null ? val : {},
        val1 = obj.isFirstApply,
        isFirstApply = val1 != null ? val1 : false;
      this.applyFilterCb({
        queryParams: this.queryParams,
        isFirstApply
      });
      this.close();
    }

    getClientCategories() {
      return [
        { title: 'Active clients (A - Z)', status: clientStatus.getActiveStatus() },
        { title: 'Inactive clients (A - Z)', status: clientStatus.getInactiveStatus() }
      ];
    }

    _categories(filterData) {
      let categories = [{
        label: 'Client',
        params: 'client_ids[]',
        top: true,
        type: 'checkbox',
        options: this._clientOptions(filterData),
        children: []
      }];
      return categories;
    }

    _clientOptions(filterData) {
      return _.map(filterData.clients, client => {
        return {
          id: client.id,
          label: client.name,
          tag: true,
          status: client.status
        };
      });
    }

  }

};

angular.module('public.security-manager.schedule-manager.timeline')
  .service('ExtendedFilterLiveFeedService', dependencies.concat(ExtendedFilterLiveFeedService));
