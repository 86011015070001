/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.invoice.employee', [
  'data.employee',
  'data.employee-invoice'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.employee-invoice-form', {
      url: '/employee-invoice-form?{ invoiceId, to, from, employeeId, forApproval, preselectedStatus, allList }',
      template: require('./form/template.pug'),
      controller: 'InvoiceEmployeeInvoiceFormController',
      controllerAs: 'invoiceEmployeeInvoiceFormCtrl'
    })
    .state('public.employee-invoice-history', {
      url: '/employee-invoice-history/:invoiceId/:invoiceVersionId?{ invoicePreview, invoiceReview, allList }',
      template: require('./history/template.pug'),
      controller: 'InvoiceEmployeeHistoryController',
      controllerAs: 'invoiceEmployeeHistoryCtrl'
    })

]);
