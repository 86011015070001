/**
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  'SecurityProviderPayPeriod', 'securityProviderPayPeriodType', 'dateService', 'notificationService',
  'dateWeek', 'securityProviderPayPeriodDate', 'securityProviderPayPeriodMonth', 'EmployeeInvoice', 'defaultConfig'
];

const SettingsPaymentPayPeriodsController = function(
  SecurityProviderPayPeriod, securityProviderPayPeriodType, dateService, notificationService,
  dateWeek, securityProviderPayPeriodDate, securityProviderPayPeriodMonth, EmployeeInvoice, defaultConfig
) {

  const vm = this;

  vm.dateWeek = dateWeek;
  vm.securityProviderPayPeriodType = securityProviderPayPeriodType;
  vm.securityProviderPayPeriodDate = securityProviderPayPeriodDate;
  vm.securityProviderPayPeriodMonth = securityProviderPayPeriodMonth;
  vm.showQAButtons = !defaultConfig.isProduction; 

  let payPeriod;

  vm.$onInit = function() {
    SecurityProviderPayPeriod.query().then(function(payPeriod) {
      vm.payPeriod = payPeriod;
      vm.loadNextPeriod();
    });
  };

  vm.savePayPeriod = function() {
    if (!vm.isCustom() || coversFullMonth()) {
      vm.payPeriod.save().then(function () {
        updateIntervalData();
        notificationService.notifySuccess('Pay period will be changed after end of current');
      });
    } else {
      notificationService.notifyError('Period must cover full month');
    }
  };

  vm.loadNextPeriod = function () {
    vm.payPeriod.nextPeriod().then(function (nextPeriod) {
      formatNextPeriod(nextPeriod);
    });
    updateIntervalData();
  };

  vm.onTypeChange = function() {
    vm.payPeriod.intervals = getDefaultIntervals();
    vm.loadNextPeriod();
  };

  vm.onDateChange = function(interval) {
    if (securityProviderPayPeriodDate.isEnd(interval.type)) {
      interval.to = securityProviderPayPeriodDate.getEndOfMonth();
      vm.onCustomIntervalChange();
    }
  };

  vm.onCustomIntervalChange = function() {
    if (coversFullMonth()) {
      vm.loadNextPeriod();
    } else {
      vm.nextPeriod = null;
    }
  };

  vm.addNewInterval = function() {
    const firstFrom = _.first(vm.payPeriod.intervals).from;
    const lastTo = _.last(vm.payPeriod.intervals).to;
    vm.payPeriod.intervals.push({
      from: (lastTo === securityProviderPayPeriodDate.getEndOfMonth()) ? 1 : lastTo + 1,
      to: (firstFrom === 1) ? securityProviderPayPeriodDate.getEndOfMonth() : firstFrom - 1
    });
    updateIntervalData();
    vm.onCustomIntervalChange();
  };

  vm.getNextPeriod = function() {
    return `${vm.payPeriod.nextPeriod.from} - ${vm.payPeriod.nextPeriod.to}`;
  };

  vm.isCustom = function() {
    return vm.securityProviderPayPeriodType.isCustom(vm.payPeriod.type);
  };

  vm.isWeekly = function() {
    return vm.securityProviderPayPeriodType.isWeekly(vm.payPeriod.type);
  };

  vm.isFortnightly = function() {
    return vm.securityProviderPayPeriodType.isFortnightly(vm.payPeriod.type);
  };

  vm.isMonthly = function() {
    return vm.securityProviderPayPeriodType.isMonthly(vm.payPeriod.type);
  };

  vm.getPreviousWeekDay = function() {
    const firstInterval = vm.payPeriod.intervals[0];
    if (!firstInterval) { return; }
    return vm.dateWeek.getName(vm.dateWeek.getPreviousDay(firstInterval.from));
  };

  vm.removeInterval = function(interval) {
    _.remove(vm.payPeriod.intervals, interval);
    vm.onCustomIntervalChange();
  };

  vm.getMonthName = function (interval) {
    return securityProviderPayPeriodMonth.getName(interval.month);
  };

  vm.generatePayslipsForNextPeriod = function() {
    vm.payPeriod.nextPeriod().then(function (nextPeriod) {
      EmployeeInvoice.generatePayslips(nextPeriod);
    });
  };

  vm.generatePayslipsWithAdditionalDays = function() {
    vm.payPeriod.nextPeriod().then(function (nextPeriod) {
      EmployeeInvoice.generatePayslipsWithAdditionalDays(nextPeriod);
    });
  };

  const updateIntervalData = function() {
    if (securityProviderPayPeriodType.isCustom(vm.payPeriod.type)) {
      for (let i = 0; i < vm.payPeriod.intervals.length; i++) {
        const currentInterval = vm.payPeriod.intervals[i];
        if (currentInterval.to === securityProviderPayPeriodDate.getEndOfMonth()) {
          currentInterval.type = securityProviderPayPeriodDate.getEnd();
        } else {
          currentInterval.type = securityProviderPayPeriodDate.getDate();
        }
        if (currentInterval.from < currentInterval.to) {
          currentInterval.month = securityProviderPayPeriodMonth.getThisMonth();
        } else {
          currentInterval.month = securityProviderPayPeriodMonth.getNextMonth();
        }
      }
    }
  };

  const getDefaultIntervals = function() {
    let intervals;
    switch (vm.payPeriod.type) {
      case securityProviderPayPeriodType.getCustom():
        intervals = [{
          from: 1,
          to: securityProviderPayPeriodDate.getEndOfMonth()
        }];
        break;
      case securityProviderPayPeriodType.getWeekly():
      case securityProviderPayPeriodType.getFortnightly():
        intervals = [{
          from: dateWeek.getMonday()
        }];
        break;
      case securityProviderPayPeriodType.getMonthly():
      default:
        intervals = [];
        break;
    }
    return intervals;
  };

  const formatNextPeriod = function(nextPeriod) {
    const fromMoment = moment(nextPeriod.from, dateService.getIsoDateFormat());
    const toMoment = moment(nextPeriod.to, dateService.getIsoDateFormat());
    let dateFormat;
    if (
      vm.payPeriod.type === securityProviderPayPeriodType.getWeekly() ||
      vm.payPeriod.type === securityProviderPayPeriodType.getFortnightly()
    ) {
      dateFormat = 'dddd, D MMM';
    } else {
      dateFormat = 'D MMM';
    }
    vm.nextPeriod = `${fromMoment.format(dateFormat)} - ${toMoment.format(dateFormat)}`;
  };

  const coversFullMonth = function () {
    let usedDays = [];
    for (let index = 0; index < vm.payPeriod.intervals.length; index++) {
      const interval = vm.payPeriod.intervals[index];
      let day = interval.from;
      let iterations = 0;
      while (iterations <= securityProviderPayPeriodDate.getEndOfMonth()) {
        if (usedDays.includes(day)) { return false; }
        usedDays.push(day);
        if (day === interval.to) { break; }
        day = day === securityProviderPayPeriodDate.getEndOfMonth() ? 1 : day + 1;
        iterations++;
      }
    }
    return usedDays.length === securityProviderPayPeriodDate.getEndOfMonth();
  };

  return vm;

};

angular.module('public.security-manager.settings')
  .controller('SettingsPaymentPayPeriodsController', dependencies.concat(SettingsPaymentPayPeriodsController));
