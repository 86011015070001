const dependencies = ['$filter', 'packageTypeService'];

const packageDescriptionService = function($filter, packageTypeService) {

  const LIMIT_TEXT_LENGTH = 110;

  const getDescriptionOf = (packageDescription) => {
    if (isShownToggleButton(packageDescription) && !packageDescription.isFullyShown) {
      return `${packageDescription.description.slice(0, LIMIT_TEXT_LENGTH)}...`;
    } else {
      return packageDescription.description;
    }
  };

  const toggleShowFull = (packageDescription) => {
    packageDescription.isFullyShown = !packageDescription.isFullyShown;
  };

  const getMoreButtonText = (packageDescription) => {
    if (packageDescription.isFullyShown) {
      return 'show less';
    } else {
      return 'show more'
    }
  };

  const isShownToggleButton = (packageDescription) => {
    return packageDescription.description.length > LIMIT_TEXT_LENGTH;
  };

  const freeDescription = {
    type: packageTypeService.getFreePackage(),
    title: 'Starter plan',
    shortTitle: 'Starter',
    getPrice: function() {
      return 'Free';
    },
    description: 'Staff portal, Staff IOS/Android, Notifications, Schedule manager, Booking timeline, Employee timeline, Day off',
    userRestrictions: `5 employees
      1 client
      1 location`,
    isFullyShown: false
  };

  const getBasicDescription = function({ priceCb } = {}) {
    return {
      type: packageTypeService.getBasicPackage(),
      title: 'Basic plan',
      shortTitle: 'Basic',
      getPrice: function () {
        const price = priceCb ? priceCb() : packageTypeService.getBasicPricePerEmployee();
        return `${$filter('formatPriceFull')(price)}`;
      },
      description: 'Staff portal, Staff IOS/Android, Notifications, Schedule manager, Booking timeline, Employee timeline, Day off, Planning mode, Booking Templates',
      userRestrictions: `Unlimited
      users`,
      isFullyShown: false
    };
  };

  const getProDescription = function({ priceCb } = {}) {
    return {
      type: packageTypeService.getProPackage(),
      title: 'Professional plan',
      shortTitle: 'Professional',
      getPrice: function() {
        const price = priceCb ? priceCb() : packageTypeService.getProPricePerEmployee();
        return `${$filter('formatPriceFull')(price)}`;
      },
      description: 'Availability Manager, Planning Mode, Staff ID Card Manager, Staff Email Manager',
      userRestrictions: `Unlimited
      users`,
      highlightedDescription: true,
      isFullyShown: false
    };
  };

  const allDescriptions = [freeDescription, getBasicDescription(), getProDescription()];

  return {
    getFreeDescription() {
      return freeDescription;
    },

    getBasicDescription,
    getProDescription,
    getDescriptionOf,
    toggleShowFull,
    getMoreButtonText,
    isShownToggleButton,

    getAllDescriptions() {
      return allDescriptions;
    },

    getDescriptionFor(packageType) {
      switch (packageType) {
        case packageTypeService.getFreePackage():
          return freeDescription;
        case packageTypeService.getBasicPackage():
          return getBasicDescription();
        case packageTypeService.getProPackage():
        case packageTypeService.getUnlimitedPackage():
          return getProDescription();
      }
    }
  }

};

angular.module('data.security-provider')
  .service('packageDescriptionService', dependencies.concat(packageDescriptionService));
