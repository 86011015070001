/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = ['EmployeeBan', 'notificationService'];

const BulkRateService = (EmployeeBan, notificationService) => {

  return class BulkRateService {

    constructor(onUpdateCb) {
      this.onUpdateCb = onUpdateCb;
    }

    setEmployeeResources(employeeResources) {
      return this.employeeResources = employeeResources;
    }

    getEmployeeResources() {
      return this.employeeResources;
    }

    setEmployees(employees) {
      return this.employees = employees;
    }

    getApprovedEmployees() {
      return this.approvedEmployees;
    }

    setApprovedEmployees(approvedEmployees) {
      return this.approvedEmployees = approvedEmployees;
    }

    getUnapprovedEmployees() {
      return this.unapprovedEmployees;
    }

    setUnapprovedEmployees(unapprovedEmployees) {
      return this.unapprovedEmployees = unapprovedEmployees;
    }

    applySingleSetRate(employeeId) {
      _.each(this.employeeResources, employeeResources => {
        _.each(employeeResources, employeeResource => {
          if (employeeResource.employeeId === employeeId) {
            employeeResource.hasApprovedResource = true;
          }
        });
        const unapprovedEmployee = _.find(this.unapprovedEmployees, employee => {
          return employee.employeeId === employeeId;
        });
        if (unapprovedEmployee) {
          _.remove(this.unapprovedEmployees, unapprovedEmployee);
          unapprovedEmployee.hasApprovedResource = true;
          this.approvedEmployees.push(unapprovedEmployee);
          this.approvedEmployees = _.orderBy(this.approvedEmployees, ['lowerCaseFullName']);
        }
      });
      this.onUpdateCb();
    }

    applyMultiSetRate(changedEmployeeResource, isApprove, checkedEmployees, uncheckCallback, rateField) {
      if (isApprove) {
        this.employeeResources[changedEmployeeResource.resourceId].forEach(employeeResource => {
          if (checkedEmployees.includes(employeeResource.employeeId)) {
            employeeResource.payRate = changedEmployeeResource.payRate;
            employeeResource.chargeRate = changedEmployeeResource.chargeRate;
            employeeResource.overtimeRate = changedEmployeeResource.overtimeRate;
            employeeResource.overtimeMinHours = changedEmployeeResource.overtimeMinHours;
            employeeResource.save();
            this.applySingleSetRate(employeeResource.employeeId);
          }
        });
      } else {
        this.rearrangeOnMultiSetRate(changedEmployeeResource, checkedEmployees, rateField);
      }
      if (!isApprove && uncheckCallback) {
        uncheckCallback();
      }
      this.onUpdateCb();
    }

    applyMultiPayRateChange(changedEmployeeResource, rateChange, checkedEmployees, uncheckCallback) {
      this.employeeResources[changedEmployeeResource.resourceId].forEach(employeeResource => {
        if (checkedEmployees.includes(employeeResource.employeeId)) {
          if (!employeeResource.id) {
            employeeResource.payRate = rateChange.payRate;
            employeeResource.save();
            this.applySingleSetRate(employeeResource.employeeId);
          } else if (!employeeResource.rateChange) {
            let newRateChange = angular.copy(rateChange);
            newRateChange.id = null;
            newRateChange.employeeResourceId = employeeResource.id;
            newRateChange.save();
            employeeResource.rateChange = newRateChange;
          }
        }
      });
      if (uncheckCallback) {
        uncheckCallback();
      }
      this.onUpdateCb();
    }

    applySingleUnapprove(employeeId) {
      const singleEmployeeResources = [];
      _.each(this.employeeResources, employeeResources => {
        return _.each(employeeResources, employeeResource => {
          if (employeeResource.employeeId === employeeId) {
            return singleEmployeeResources.push(employeeResource);
          }
        });
      });
      const hasApprovedResource = _.some(singleEmployeeResources, resource => resource.payRate !== null);
      if (!hasApprovedResource) {
        _.each(singleEmployeeResources, employeeResource => {
          this.unapproveResource(employeeResource);
          return true;
        });
        const approvedEmployee = _.find(this.approvedEmployees, employee => {
          return employee.employeeId === employeeId;
        });
        if (approvedEmployee) {
          _.remove(this.approvedEmployees, approvedEmployee);
          this.unapproveResource(approvedEmployee);
          this.unapprovedEmployees.push(approvedEmployee);
          this.unapprovedEmployees = _.orderBy(this.unapprovedEmployees, ['lowerCaseFullName']);
        }
      }
      return this.onUpdateCb();
    }

    applyMultiUnapprove(resourceId, checkedEmployees) {
      this.employeeResources[resourceId].forEach(employeeResource => {
        if (checkedEmployees.includes(employeeResource.employeeId) && (employeeResource.payRate !== null)) {
          return employeeResource.remove().then(() => {
            employeeResource.payRate = null;
            return this.rearrangeOnMultiUnapprove(employeeResource.employeeId);
          });
        }
      });
      return this.onUpdateCb();
    }

    rearrangeOnMultiSetRate(changedEmployeeResource, checkedEmployees, rateField) {
      _.each(this.employeeResources, employeeResources => {
        _.each(employeeResources, employeeResource => {
          if (checkedEmployees.includes(employeeResource.employeeId)) {
            employeeResource.hasApprovedResource = true;
            const approvedEmployee = _.find(this.approvedEmployees, employee => {
              return employee.employeeId === employeeResource.employeeId;
            });
            const unapprovedEmployee = _.find(this.unapprovedEmployees, employee => {
              return employee.employeeId === employeeResource.employeeId;
            });
            if (approvedEmployee != null) {
              approvedEmployee.hasApprovedResource = true;
            }
            if (unapprovedEmployee != null) {
              unapprovedEmployee.hasApprovedResource = true;
            }
            if (employeeResource.resourceId === changedEmployeeResource.resourceId) {
              if (employeeResource.id) {
                employeeResource[rateField] = changedEmployeeResource[rateField];
              } else {
                employeeResource.payRate = changedEmployeeResource.payRate;
                employeeResource.chargeRate = changedEmployeeResource.chargeRate;
                employeeResource.overtimeRate = changedEmployeeResource.overtimeRate;
                employeeResource.overtimeMinHours = changedEmployeeResource.overtimeMinHours;
              }
              employeeResource.save()
                .then(() => {
                  const unapprovedEmployee = _.find(this.unapprovedEmployees, function(employee) {
                    return employee.employeeId === employeeResource.employeeId;
                  });
                  if (unapprovedEmployee) {
                    this.approvedEmployees.push(unapprovedEmployee);
                  }
                })
                .catch(function() {
                  notificationService.notifyError(`Employee ${employeeResource.employee.fullName} can not be assigned`);
                });
            }
          }
        });
        this.unapprovedEmployees = _.filter(this.unapprovedEmployees, ['hasApprovedResource', false]);
        this.approvedEmployees = _.orderBy(this.approvedEmployees, ['lowerCaseFullName']);
        this.onUpdateCb();
      });
    }

    rearrangeOnMultiUnapprove(employeeId) {
      const singleEmployeeResources = {};
      _.each(this.employeeResources, employeeResources => {
        return _.each(employeeResources, employeeResource => {
          if (employeeId === employeeResource.employeeId) {
            if (!singleEmployeeResources[employeeResource.employeeId]) {
              singleEmployeeResources[employeeResource.employeeId] = [];
            }
            return singleEmployeeResources[employeeResource.employeeId].push(employeeResource);
          }
        });
      });
      return _.each(singleEmployeeResources, employeeResourceArr => {
        const hasApprovedResource = _.some(employeeResourceArr, resource => {
          return resource.payRate !== null;
        });
        if (!hasApprovedResource) {
          _.each(employeeResourceArr, employeeResource => {
            this.unapproveResource(employeeResource);
            return true;
          });
          const approvedEmployee = _.find(this.approvedEmployees, employee => {
            return employee.employeeId === employeeId;
          });
          if (approvedEmployee) {
            _.remove(this.approvedEmployees, approvedEmployee);
            this.unapproveResource(approvedEmployee);
            this.unapprovedEmployees.push(approvedEmployee);
            this.unapprovedEmployees = _.orderBy(this.unapprovedEmployees, ['lowerCaseFullName']);
            return this.onUpdateCb();
          }
        }
      });
    }

    banEmployee(clientId, employeeId) {
      return new EmployeeBan({ clientId, employeeId }).save().then(() => {
        const bannedEmployee = _.find(this.employees, ['employeeId', employeeId]);
        bannedEmployee.isBanned = true;
        const removedEmployee = _.remove(this.approvedEmployees, bannedEmployee);
        if (removedEmployee.length > 0) {
          this.unapprovedEmployees.push(bannedEmployee);
          this.unapprovedEmployees = _.orderBy(this.unapprovedEmployees, ['lowerCaseFullName']);
        }
        _.each(this.employeeResources, employeeResources => {
          _.each(employeeResources, employeeResource => {
            if (employeeResource.employeeId === employeeId) {
              employeeResource.isBanned = true;
              this.unapproveResource(employeeResource);
            }
          });
        });
        return this.onUpdateCb();
      });
    }

    unbanEmployee(clientId, employeeId) {
      return EmployeeBan.removeByEmployee(clientId, employeeId).then(() => {
        const bannedEmployee = _.find(this.employees, ['employeeId', employeeId]);
        bannedEmployee.isBanned = false;
        _.each(this.employeeResources, employeeResources => {
          return _.each(employeeResources, employeeResource => {
            if (employeeResource.employeeId === employeeId) {
              employeeResource.payRate = null;
              return employeeResource.isBanned = false;
            }
          });
        });
        return this.onUpdateCb();
      });
    }

    unapproveResource(employeeResource) {
      employeeResource.id = null;
      employeeResource.hasApprovedResource = false;
    }

  };

};

angular
  .module('public.security-manager.hr')
  .factory('BulkRateService', dependencies.concat(BulkRateService));
