const dependencies = ['DateRange', 'FormDatePickerRangeRangeSelector'];

const LiveFeedDateRangeSelector = function(DateRange, FormDatePickerRangeRangeSelector) {

  class Next24h extends DateRange {

    getLabel() {
      return 'Next 24h';
    }

    getFrom() {
      return this.getToday();
    }

    getTo() {
      return this.getToday().add(1, 'day');
    }
  }

  class Tomorrow extends DateRange {

    getLabel() {
      return 'Tomorrow';
    }

    getFrom() {
      return this.getToday().add(1, 'day');
    }

    getTo() {
      return this.getToday().add(2, 'days');
    }
  }

  class Next3d extends DateRange {

    getLabel() {
      return 'Next 3 days';
    }

    getFrom() {
      return this.getToday();
    }

    getTo() {
      return this.getToday().add(3, 'days');
    }
  }

  class NextWeek extends DateRange {

    getLabel() {
      return 'Next week';
    }

    getFrom() {
      return this.getToday();
    }

    getTo() {
      return this.getToday().add(1, 'week');
    }
  }

  class LiveFeedRangeSelector extends FormDatePickerRangeRangeSelector {

    constructor(today, from, to, updateFromCb, updateToCb) {
      super(...arguments);
    }

    _initRanges() {
      this.ranges = [
        new Next24h(this.today),
        new Tomorrow(this.today),
        new Next3d(this.today),
        new NextWeek(this.today),
        this.customRange
      ];
    }
  }

  return LiveFeedRangeSelector;
};

angular.module('public.security-manager.schedule-manager.timeline')
  .factory('LiveFeedDateRangeSelector', dependencies.concat(LiveFeedDateRangeSelector));
