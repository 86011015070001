/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('sign.employee.registration', [
  'data.employee',
  'data.security-provider'
]).config(['$stateProvider', $stateProvider => {

  // return $stateProvider
  //
  //   .state('sign.employee-registration', {
  //     url: '/employee/registration/:security_provider_alias',
  //     template: require('./template.pug'),
  //     controller: 'SignEmployeeRegistrationController',
  //     controllerAs: 'signEmployeeRegistrationCtrl',
  //     resolve: {
  //       securityProvider: ['$stateParams', '$state', 'SecurityProvider', ($stateParams, $state, SecurityProvider) => {
  //         return SecurityProvider.get($stateParams.security_provider_alias).then(securityProvider => {
  //           return securityProvider;
  //         }).catch(() => $state.go('sign.login'));
  //       }]
  //     }
  //   });

}]);
