/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  'invoiceCostService', 'dateService', 'confirmService', 'invoiceElementFactory',
  'notificationService', 'currentUserService', 'bookingShiftClockInErrorsService'
];

const InvoiceFormBookingController = function(
  invoiceCostService, dateService, confirmService, invoiceElementFactory,
  notificationService, currentUserService, bookingShiftClockInErrorsService
) {

  const vm = this;

  vm.isShownCalendar = false;
  vm.dateFormat = dateService.getDateFormat();
  vm.customData = {
    isLeft: true,
    withoutHeader: true
  };

  vm.$onInit = function() {
    if (vm.withExplicitPeriod) { initInvoicePeriod(); }
    initInvoiceElements(vm.booking);
    if (vm.compareWith) { initCompareElements(); }
  };

  vm.$onChanges = function({ hideUnassignedShifts }) {
    if (!hideUnassignedShifts) { return; }
    _.each(vm.booking.invoiceElements, function(invoiceElement) {
      invoiceElement.toggleHideUnassignedShifts(hideUnassignedShifts.currentValue);
    });
  };

  vm.toggleCalendar = function() {
    if (vm.isShownCalendar) {
      vm.hideCalendar();
    } else {
      vm.isShownCalendar = true;
    }
  };

  vm.hideCalendar = function() {
    vm.booking.to = vm.to.format(dateService.getIsoDateFormat());
    vm.booking.from = vm.from.format(dateService.getIsoDateFormat());
    vm.isShownCalendar = false;
  };

  vm.getPeriodText = function() {
    if (vm.booking.to && vm.booking.from) {
      return dateService.formatDate(vm.booking.from, vm.dateFormat) +
        '–' +
        dateService.formatDate(vm.booking.to, vm.dateFormat);
    } else {
      return 'Add period';
    }
  };

  vm.getPeriodClasses = function() {
    if (vm.booking.to && vm.booking.from) {
      return [];
    } else {
      return ['mod-ico', 'mod-ico-plus'];
    }
  };

  vm.getInvoiceElements = () => {
    let invoiceElements;
    if (vm.hideUnassignedShifts) {
      invoiceElements = _.filter(vm.booking.invoiceElements, invoiceElement => {
        return !invoiceElement.isUnassignedShift();
      });
    } else {
      invoiceElements = vm.booking.invoiceElements;
    }
    return _.orderBy(invoiceElements, invoiceElement => {
      return invoiceElement.element.startTimeMoment;
    }, vm.startDateOrderDesc ? 'desc' : 'asc');
  };

  vm.removeElement = function(element) {
    if (vm.booking.invoiceElements.length > 1) {
      confirmService.confirmRemove('this', function() {
        _.remove(vm.booking.shifts, element.element);
        _.remove(vm.booking.invoiceElements, element);
      });
    } else if (vm.withExplicitPeriod) {
      notificationService.notifyInfo("Invoice can't be empty");
    } else {
      notificationService.notifyInfo("Booking can't be empty");
    }
  };

  vm.addAdditionalCost = function() {
    const newAdditionalCost = invoiceElementFactory.createEmptyAdditionalCost(vm.editable, vm.hideUnassignedShifts);
    vm.booking.invoiceElements.push(newAdditionalCost);
    vm.booking.shifts.push(newAdditionalCost.element);
  };

  vm.addAdditionalShiftCost = function() {
    const newAdditionalCost = invoiceElementFactory.createEmptyAdditionalShiftCost(vm.editable, vm.hideUnassignedShifts);
    vm.booking.invoiceElements.push(newAdditionalCost);
    vm.booking.shifts.push(newAdditionalCost.element);
  };

  vm.getHighlightClass = (invoiceElement, options) => {
    return { 'is-highlighted': vm.isChanged(invoiceElement, options) };
  };

  vm.getHighlightClassForHolder = function(invoiceElement) {
    if (!vm.compareWith) { return; }
    const previousElement = findElementIn(vm.compareWith, invoiceElement);
    if (previousElement === undefined) {
      return 'is-highlighted-green';
    } else if (previousElement.isRemoved) {
      return 'is-highlighted-red';
    }
  };

  vm.removeShiftEmployee = (invoiceElement, shiftEmployee) => {
    return confirmService.confirmRemove('shift employee', () => invoiceElement.removeShiftEmployee(shiftEmployee));
  };

  vm.onCommentsCountChange = (invoiceElement, count) => invoiceElement.element.commentsCount = count;

  vm.hasClockInError = function(invoiceElement, shiftEmployee) {
    return (isShiftEnded(invoiceElement) && !shiftEmployee.clockInTime) ||
      bookingShiftClockInErrorsService.hasClockInError(shiftEmployee);
  };

  vm.hasClockOutError = function(invoiceElement, shiftEmployee) {
    return (isShiftEnded(invoiceElement) && !shiftEmployee.clockOutTime) ||
      bookingShiftClockInErrorsService.hasClockOutError(shiftEmployee);
  };

  vm.hasClockInOutErrors = function(invoiceElement) {
    return _.some(invoiceElement.getShiftEmployees(), (shiftEmployee) => {
      return vm.hasClockInError(invoiceElement, shiftEmployee) || vm.hasClockOutError(invoiceElement, shiftEmployee);
    });
  };

  vm.getClockPreviewText = function(invoiceElement, clockDayTime) {
    if (isShiftEnded(invoiceElement)) {
      return clockDayTime || 'Missed';
    } else {
      return clockDayTime || 'TBC';
    }
  };

  vm.getClockInPreviewTooltipText = function(invoiceElement, clockDayTime, tooltipText) {
    if (vm.isClockTimeToConfirmed(invoiceElement, clockDayTime)) {
      return 'To be clocked';
    } else {
      return tooltipText;
    }
  };

  vm.isClockTimeEditable = function(invoiceElement, clockTime) {
    return vm.editable && (clockTime || isShiftEnded(invoiceElement));
  };

  vm.isClockTimeToConfirmed = function(invoiceElement, clockDayTime) {
    return !isShiftEnded(invoiceElement) && !clockDayTime;
  };

  vm.isChanged = function(invoiceElement, { field, func, shiftEmployee }) {
    if (vm.compareWith && !invoiceElement.isRemoved) {
      const previousElement = findElementIn(vm.compareWith, invoiceElement);
      if (previousElement) {
        let previousShiftEmployee;
        if (shiftEmployee) {
          previousShiftEmployee = _.find(previousElement.element.employees, elementEmployee => {
            return elementEmployee.id === shiftEmployee.id;
          });
        }
        return compare(previousElement, invoiceElement, previousShiftEmployee, shiftEmployee, field, func);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  vm.hasClockInOutChange = function(invoiceElement) {
    return vm.isChanged(invoiceElement, { field: 'enableClockIn' }) ||
      _.some(invoiceElement.getShiftEmployees(), (shiftEmployee) => {
        return vm.isChanged(invoiceElement, { shiftEmployee: shiftEmployee, field: 'clockInDayTime' }) ||
          vm.isChanged(invoiceElement, { shiftEmployee: shiftEmployee, field: 'clockOutDayTime' });
      });
  };

  vm.isBookingChanged = function(field) {
    return vm.compareWith && vm.booking[field] !== vm.compareWith[field]
  };

  const initInvoicePeriod = function() {
    vm.from = (vm.booking.from && moment(vm.booking.from, dateService.getIsoDateFormat())) || moment();
    vm.to = (vm.booking.to && moment(vm.booking.to, dateService.getIsoDateFormat())) || moment();
  };

  const isShiftEnded = function(invoiceElement) {
    return moment().isAfter(invoiceElement.element.endTimeMoment);
  };

  const compare = function(previousElement, invoiceElement, previousShiftEmployee, shiftEmployee, field, func) {
    if (func) {
      return previousElement[func]() !== invoiceElement[func]();
    } else if (shiftEmployee) {
      if (previousShiftEmployee) {
        return _.at(previousShiftEmployee, field)[0] !== _.at(shiftEmployee, field)[0];
      } else {
        return false;
      }
    } else {
      return previousElement.element[field] !== invoiceElement.element[field];
    }
  };

  const initInvoiceElements = function(booking) {
    booking.invoiceElements = [];
    _.each(booking.shifts, shift => {
      booking.invoiceElements.push(invoiceElementFactory.create(shift, vm.editable, vm.hideUnassignedShifts, vm.isNewInvoice));
    });
  };

  const initCompareElements = function() {
    initInvoiceElements(vm.compareWith);
    _.each(vm.compareWith.invoiceElements, function(compareElement) {
      if (!findElementIn(vm.booking, compareElement)) {
        compareElement.setAsRemoved();
        vm.booking.invoiceElements.push(compareElement);
      }
    });
  };

  const findElementIn = (booking, element) => {
    return _.find(booking.invoiceElements, collectionElement => collectionElement.isSame(element));
  };

  vm.isShownComments = invoiceElement => {
    return vm.editable && currentUserService.isSecurityManagerLogged() && invoiceElement.isShownComments();
  };

  return vm;

};

angular
  .module('public.security-manager.client.show.invoice')
  .controller('InvoiceFormBookingController', dependencies.concat(InvoiceFormBookingController));
