const dependencies = [
  '$scope', '$element', 'BookingShiftEmployee', 'bookingShiftEmployeeAssignStatus',
  'elementService', 'currentUserService', 'resourceChargeType'
];

const PopupBookingShiftTimelineBookingController = function(
  $scope, $element, BookingShiftEmployee, bookingShiftEmployeeAssignStatus,
  elementService, currentUserService, resourceChargeType
) {

  const vm = this;

  const ELEMENT_WIDTH = 242;
  const LEFT_PADDING = 50;
  const RIGHT_PADDING = 49;

  const element = $element[0];

  vm.$onInit = function() {
    fitModalWindow();
    loadShiftEmployees();
    vm.shiftEmployees = [];
    vm.buttonText = vm.editable ? 'Edit shift' : 'Show shift';
  };

  vm.needEmployees = function () {
    return vm.shiftDecorator.shift.approvedEmployees.length < vm.shiftDecorator.shift.requiredEmployeesCount;
  };

  vm.canInviteEmployees = function () {
    return vm.editable && !currentUserService.isClientManagerLogged() && !resourceChargeType.isPerUnit(vm.shiftDecorator.shift.resource.chargeType);
  };

  const loadShiftEmployees = () => {
    return BookingShiftEmployee.query({}, {
      bookingId: vm.shiftDecorator.shift.bookingId,
      shiftId: vm.shiftDecorator.shift.id
    }).then(function (shiftEmployees) {
      vm.shiftEmployees = shiftEmployees;
      initInvitedEmployees();
      initDeclinedAndUnavailable();
      fitModalWindow();
    });
  };

  const initInvitedEmployees = function() {
    vm.invitedEmployees = _.filter(vm.shiftEmployees, shiftEmployee => {
      return bookingShiftEmployeeAssignStatus.countsAsInvited(shiftEmployee.assignStatus);
    });
  };

  const initDeclinedAndUnavailable = function() {
    vm.declinedAndUnavailable = _.filter(vm.shiftEmployees, shiftEmployee => {
      return bookingShiftEmployeeAssignStatus.countsAsUnavailable(shiftEmployee.assignStatus);
    });
  };

  const fitModalWindow = () => {
    setTimeout(function () {
      const timelineShiftElement = element.closest('.timeline-shift');
      if (!timelineShiftElement) { return; }
      const offsetLeft = elementService.getOffsetLeft(timelineShiftElement);
      const shiftModal = element.querySelector('.timeline-shift-modal');
      const leftDiff = offsetLeft - LEFT_PADDING;
      const rightDiff = window.innerWidth - (ELEMENT_WIDTH + offsetLeft + RIGHT_PADDING);
      element.style.top = '0px';
      if ((leftDiff < 0) || (rightDiff < 0)) {
        element.style.position = 'absolute';
        if (leftDiff < 0) {
          element.style.left = -leftDiff + 'px';
        }
        if (rightDiff < 0) {
          element.style.left = rightDiff + 'px';
        }
        element.style.top = '0px';
      }
      const invisibleHeight = (shiftModal.getBoundingClientRect().top + shiftModal.offsetHeight) - window.innerHeight;
      if (invisibleHeight > 0) {
        element.style.position = 'absolute';
        element.style.top = -invisibleHeight + 'px';
      }
    }, 0);
  };

  const updateShiftEmployeesStatuses = function(employeesShifts) {
    if (employeesShifts.length !== vm.shiftEmployees.length) {
      return loadShiftEmployees();
    }
    for (let shiftEmployee of Array.from(vm.shiftEmployees)) {
      let updatedShiftEmployee = _.find(employeesShifts, es => es.id === shiftEmployee.id);
      if (updatedShiftEmployee) {
        shiftEmployee.assignStatus = updatedShiftEmployee.assignStatus;
      } else {
        return loadShiftEmployees();
      }
    }
  };

  $scope.$watchCollection('popupShiftTimelineBookingCtrl.shiftDecorator.shift.employeesShifts', employeesShifts => {
    return updateShiftEmployeesStatuses(employeesShifts);
  });

  return vm;

};

angular.module('popup.booking').controller('PopupBookingShiftTimelineBookingController',
  dependencies.concat(PopupBookingShiftTimelineBookingController));
