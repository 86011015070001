/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$q', 'ClientManager', 'ClientService', 'EntityManager', 'uploadService', 'currentUserService'
];

const SignClientRegistrationManagerInvitationController = function(
  $scope, $q, ClientManager, ClientService, EntityManager, uploadService, currentUserService
) {

  const vm = this;

  const params = { 
    clientId: $scope.signClientRegistrationLayoutCtrl.getClientId()
  };
  
  vm.serviceManager = new EntityManager(ClientService, { params });
  const managerManager = new EntityManager(ClientManager, {
    params,
    withBlank: true,
    queryParams: {
      except: currentUserService.getCurrentProfile().id
    }
  });

  vm.isShownServicePopup = false;
  vm.popupManager = undefined;

  vm.addNewManager = managerManager.addNewEntity;
  vm.removeManager = managerManager.removeEntity;

  vm.getManagers = () => managerManager.entities;

  vm.loadServices = (manager, text) => {
    return ClientService.query({ name: text, limit: 9 }, params).then(function (result) {
      result.push({
        dummyItem: true,
        label: 'Add new service',
        onSelect() {
          vm.popupService = vm.serviceManager.createEntity();
          vm.popupManager = manager;
          return vm.isShownServicePopup = true;
        }
      });
      return result;
    });
  };

  vm.closeServicePopup = function(service) {
    vm.isShownServicePopup = false;
    if (service) {
      if (!vm.popupManager.services) { vm.popupManager.services = []; }
      return vm.popupManager.services.push(service);
    }
  };

  managerManager.loadAll();

  $scope.$on('client.registration.submit', function() {
    const managerPromises = _.map(vm.getManagers(), manager => manager.save());
    return $q.all(managerPromises).then(() => $scope.signClientRegistrationLayoutCtrl.goNext());
  });

  return vm;
};

angular.module('sign.client.registration').controller('SignClientRegistrationManagerInvitationController',
  dependencies.concat(SignClientRegistrationManagerInvitationController));
