/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['$stateParams', 'elementService', '$timeout'];

const employeeTimeLineScrollToEvent = ($stateParams, elementService, $timeout) => {
  return {
    restrict: 'A',
    scope: {
      eventType: '@',
      eventDecorator: '<',
      openEventPopup: '<',
      employee: '<'
    },
    link($scope, $el) {
      if (
        $stateParams.goToEventId &&
        $stateParams.goToEventId === $scope.eventDecorator.id &&
        $stateParams.goToEventType === $scope.eventType
      ) {
        const elementBox = $el[0].closest('.timeline-data-box');
        const scrollContainer = elementBox.closest('.timeline-data');
        scrollContainer.scrollTop = elementService.getOffsetTop(elementBox) - elementService.getOffsetTop(scrollContainer);
        return $timeout(() => $scope.openEventPopup(null, $scope.employee, $scope.eventDecorator, $el[0]));
      }
    }
  };
};

angular.module('public.timeline')
  .directive('employeeTimeLineScrollToEvent', dependencies.concat(employeeTimeLineScrollToEvent));
