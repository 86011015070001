const dependencies = ['$scope'];

const ClientShowDetailsController = function($scope) {

  const vm = this;

  vm.canTestPeopleDataIntegration = function() {
    return $scope.clientShowLayoutCtrl.client &&
      $scope.clientShowLayoutCtrl.client.hasOwnProperty('hasPeopleDataIntegration') &&
      $scope.clientShowLayoutCtrl.client.hasPeopleDataIntegration;
  };

   return vm;

};

angular.module('public.security-manager.client.show')
  .controller('ClientShowDetailsController', dependencies.concat(ClientShowDetailsController));
