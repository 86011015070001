/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$state', '$sce', 'Employee', 'HomeZone', 'Role', 'validationService', 'dateService', 'pluralizeService',
  'employeeGender', 'employeeStartingBasis', 'employeeNationalInsuranceCategory',
  'employeeValidation', 'employeeType', 'employeeStatus', 'userValidation',
  'notificationService', 'employeeHolidayService', 'packageTypeService'
];

const EmployeeShowDetailsController = function(
  $scope, $state, $sce, Employee, HomeZone, Role, validationService, dateService, pluralizeService,
  employeeGender, employeeStartingBasis, employeeNationalInsuranceCategory, 
  employeeValidation, employeeType, employeeStatus, userValidation,
  notificationService, employeeHolidayService, packageTypeService
) {

  const vm = this;

  vm.validationService = validationService;
  vm.pluralizeService = pluralizeService;
  vm.employeeType = employeeType;
  vm.employeeGender = employeeGender;
  vm.employeeStartingBasis = employeeStartingBasis;
  vm.employeeNationalInsuranceCategory = employeeNationalInsuranceCategory;
  vm.expireMinDate = dateService.today();
  vm.employeeIsLoaded = false;
  vm.hasProPackage = packageTypeService.hasProPackage();

  let employeeCopy = undefined;

  vm.getBirthDateErrors = employeeValidation.getBirthDateErrors;

  vm.getEmailErrors = () => userValidation.getEmailErrors(vm.employee.user && vm.employee.user.id);

  vm.copyEmployee = function() {
    if (!employeeCopy) { return; }
    vm.employee = angular.copy(employeeCopy);
    if (!employeeCopy.holidaysLimit) { return; }
    return vm.holidaysLimitHours = employeeCopy.holidaysLimit * employeeHolidayService.getDefaultHolidaysHours();
  };

  vm.saveEmployee = function() {
    if (!employeeType.isSelfEmployed(employeeCopy.employmentType)) {
      vm.employee.additionalHolidayHours = +vm.additionalHolidays * employeeHolidayService.getDefaultHolidaysHours();
    }
    return vm.employee.save().then(function(employee) {
      if (employmentTypeChanged(employee, vm.employee)) {
        notificationService.notifySuccess('Employment type will be changed from new pay period');
      }
      employeeCopy = _.clone(employee);
      vm.copyEmployee();
      $scope.employeeShowLayoutCtrl.updateEmployee(employee);
      return loadEmployeeHolidayData();
    });
  };

  vm.getPayPeriod = () => dateService.formatDateTime(vm.employee.nextPayPeriod);

  vm.pluralizeDays = days => pluralizeService.simple(days, 'day');

  vm.pluralizeHours = hours => pluralizeService.simple(hours, 'hour');

  vm.getHolidayTakenModifier = function() {
    if (vm.tookMoreHolidays()) { return 'mod-red'; }
  };

  vm.getHolidayTakenNotification = function() {
    if (vm.tookMoreHolidays()) { return 'Holiday allowance exceeded'; }
  };

  vm.updateRemainingHolidayHours = () => {
    vm.remainingHolidayHours = vm.remainingHolidayDays * employeeHolidayService.getDefaultHolidaysHours();
  };

  vm.tookMoreHolidays = () => vm.takenHolidays && (vm.takenHolidays.hours > vm.accumulatedHours);

  const getHolidaysLimit = function() {
    let limit = vm.employee.holidaysLimit;
    if ((limit === null) || (limit === undefined)) {
      limit = vm.employee.defaultHolidaysLimit;
    }
    return limit;
  };

  vm.getHolidaysLimitDays = function() {
    const limit = getHolidaysLimit();
    if ((limit === null) || (limit === undefined)) {
      return 'Unlimited';
    } else {
      return vm.pluralizeDays(limit);
    }
  };

  vm.getHolidaysLimitHours = function() {
    const limit = getHolidaysLimit();
    if ((limit === null) || (limit === undefined)) {
      return 'Unlimited';
    } else {
      return vm.pluralizeHours(limit * employeeHolidayService.getDefaultHolidaysHours());
    }
  };

  vm.getUniqueCodeErrors = () => {
    return [{
      error: 'unique-code',
      message: 'Employee code must be unique',
      asyncValidation(code) {
        return Employee.validate({ code, except: vm.employee.id });
      }
    }];
  };

  vm.getFormattedDeactivationDate = function() {
    if (vm.employee != null ? vm.employee.deactivationDate : undefined) {
      return dateService.formatDate(vm.employee.deactivationDate, dateService.getDateFormat());
    }
  };

  vm.isShownDeactivationDate = function() {
    if (!vm.employee) { return; }
    return employeeStatus.isInactive(vm.employee.status) && vm.employee.deactivationDate;
  };

  vm.loadHomeZones = function (text) {
    return HomeZone.autocomplete({}, { 'order[name]': 'asc', searchString: text });
  };

  vm.loadRoles = function(text) {
    return Role.query({ 'order[name]': 'asc', name: text });
  };

  vm.onHomeZonesChange = () => {
    angular.element(document.querySelector('[ng-model="newTag.text"]')).triggerHandler('focus');
    return vm.saveEmployee();
  };

  vm.onRolesChange = () => vm.saveEmployee();

  vm.getHolidaysTitle = function() {
    if (vm.takenHolidays) {
      return 'Holidays and sick days';
    } else {
      return 'Sick days';
    }
  };

  vm.getTakenSickDays = function() {
    if (vm.employee) {
      return Math.floor(vm.employee.takenSickDayHours / employeeHolidayService.getDefaultSickDaysHours());
    }
  };

  const employmentTypeChanged = (oldEmployee, newEmployee) => {
    return newEmployee.futureEmploymentType &&
      (oldEmployee.futureEmploymentType !== newEmployee.futureEmploymentType) &&
      (newEmployee.futureEmploymentType !== newEmployee.employmentType);
  };

  const loadEmployeeHolidayData = function() {
    if (employeeType.isSelfEmployed(vm.employee.employmentType) || employeeType.isSubContractor(vm.employee.employmentType)) { return; }
    return Employee.getHolidaysData($scope.employeeShowLayoutCtrl.employeeId).then(function(holidaysData) {
      vm.takenHolidays = holidaysData.takenHolidays;
      vm.accumulatedHours = holidaysData.accumulatedHours;
      vm.remainingHolidayHours = vm.accumulatedHours - vm.takenHolidays.hours;
      vm.remainingHolidayDays = employeeHolidayService.getRemainingHolidayDays(
        vm.accumulatedHours,
        holidaysData.takenHolidays.hours
      );
      const roundMethod = vm.employee.additionalHolidayHours < 0 ? 'ceil' : 'floor';
      return vm.additionalHolidays = Math[roundMethod](vm.employee.additionalHolidayHours / employeeHolidayService.getDefaultHolidaysHours());
    });
  };
  
  const getFullDateAndTime = date => {
    return moment(date, dateService.getIsoFullDateFormatWithTz()).format(dateService.getFullDateFormat());
  };

  $scope.$on('employee.show.update', function(event, updatedEmployee) {
    employeeCopy = updatedEmployee;
    vm.copyEmployee();
    vm.employeeIsLoaded = true;
  });

  $scope.employeeShowLayoutCtrl.loadEmployee().then(function(loadedEmployee) {
    employeeCopy = loadedEmployee;
    vm.copyEmployee();
    loadEmployeeHolidayData();
    vm.employeeIsLoaded = true;
  });

  vm.hasHumynIntegration = function() {
    return (vm.employee != null && vm.employee.humynSyncInfo != null)
  }

  vm.getHumynStatusText = function() {
    if(vm.hasHumynIntegration())
    {
      let text = "No issues";
      if(vm.employee.humynSyncInfo.hasSyncIssue)
      {
        text = `<b>last synced at:</b> ${getFullDateAndTime(vm.employee.humynSyncInfo.syncTime)},<br/><b>error:</b> ${vm.employee.humynSyncInfo.message}`;
      }
      else if(vm.employee.humynSyncInfo.waitingToSync)
      {
        text = "Waiting to sync. Please check in 5 minutes"
      }
      return $sce.trustAsHtml(text);
    }
  }

  return vm;

};

angular
  .module('public.security-manager.employee')
  .controller('EmployeeShowDetailsController', dependencies.concat(EmployeeShowDetailsController));
