/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').service('bookingRepeatable', function() {

  const REPEATABLE_YES = 1;
  const REPEATABLE_NONE = 2;
  const REPEATABLE_DAILY = 3;
  const REPEATABLE_WEEKLY = 4;
  const REPEATABLE_FORTNIGHT = 5;
  const REPEATABLE_MONTHLY = 6;
  const REPEATABLE_CUSTOM = 7;

  const ALL_TYPES = [
    REPEATABLE_YES, REPEATABLE_NONE, REPEATABLE_DAILY,
    REPEATABLE_WEEKLY, REPEATABLE_FORTNIGHT,
    REPEATABLE_MONTHLY, REPEATABLE_CUSTOM
  ];
  const BOOKING_TYPES = _.without(ALL_TYPES, REPEATABLE_CUSTOM);
  const SHIFT_TYPES = _.without(ALL_TYPES, REPEATABLE_YES);
  const PERIODIC_TYPES = [REPEATABLE_DAILY, REPEATABLE_WEEKLY, REPEATABLE_FORTNIGHT, REPEATABLE_MONTHLY];

  const names = {};
  names[REPEATABLE_YES] = 'Yes';
  names[REPEATABLE_NONE] = 'No';
  names[REPEATABLE_DAILY] = 'Daily';
  names[REPEATABLE_WEEKLY] = 'Weekly';
  names[REPEATABLE_FORTNIGHT] = 'Fortnight (2 weeks)';
  names[REPEATABLE_MONTHLY] = 'Monthly';
  names[REPEATABLE_CUSTOM] = 'Custom';

  const bookingNames = _.pick(names, BOOKING_TYPES);
  const shiftNames = _.pick(names, SHIFT_TYPES);

  return {
    getBookingNames() {
      return bookingNames;
    },
    
    getShiftNames() {
      return shiftNames;
    },

    getName(type) {
      return names[type];
    },

    isNone(type) {
      return type === REPEATABLE_NONE;
    },

    isYes(type) {
      return type === REPEATABLE_YES;
    },

    isPeriodic(type) {
      return PERIODIC_TYPES.indexOf(type) !== -1;
    },

    isCustom(type) {
      return type === REPEATABLE_CUSTOM;
    },

    getDaily() {
      return REPEATABLE_DAILY;
    },

    getWeekly() {
      return REPEATABLE_WEEKLY;
    },

    getFortnight() {
      return REPEATABLE_FORTNIGHT;
    },

    getMonthly() {
      return REPEATABLE_MONTHLY;
    }
  };
});
