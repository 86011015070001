/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['AppModel'];

const SocketAuthToken = AppModel => {

  class SocketAuthToken extends AppModel {
    static initClass() {
      this.configure({
        url : '/users/{{userId}}/socket_auth_token',
        name : 'socket_auth_token',
        rootWrapping : false
      });
    }
  }

  SocketAuthToken.initClass();
  return SocketAuthToken;
};

angular.module('shared').factory('SocketAuthToken', dependencies.concat(SocketAuthToken));
