/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.system-package').directive('gccsPopupSystemPackageBilling', [
  'popupDirectiveConfig',
  popupDirectiveConfig =>
    popupDirectiveConfig.configure({
      bodyTemplate: require('./template.pug'),
      scope: {
        packageType: '<',
        isShownPaymentLoader: '<',
        onSuccessCb: '&'
      }
    })
]);
