/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope'];

const ClientListHeaderController = function($scope) {

  const vm = this;

  vm.headerItems = [{
    name : 'Clients name',
    className : 'mod-title',
    sortableParam : $scope.nameOrderParam
  }
  , {
    name : 'Status',
    className : 'mod-status'
  }
  , {
    name : 'Address',
    className : 'mod-address'
  }
  , {
    name : 'Managers',
    className : 'mod-managers'
  }
  , {
    name : 'Actions',
    className : 'mod-settings'
  }
  ];

  vm.toggleOrder = function(headerItem) {
    if (headerItem.sortableParam !== undefined) {
      return headerItem.sortableParam.ascending = !headerItem.sortableParam.ascending;
    }
  };

  vm.getClassName = function(headerItem) {
    let { className } = headerItem;
    if (headerItem.sortableParam !== undefined) {
      if (headerItem.sortableParam.ascending) {
        className += ' mod-sortable-bottom';
      } else {
        className += ' mod-sortable-top';
      }
    }
    return className;
  };

  return vm;
};

angular.module('public.security-manager.client.list').controller('ClientListHeaderController',
  dependencies.concat(ClientListHeaderController));
