angular.module('app', [
  'gccs-ui', 'shared', 'form', 'public', 'sign', 'landing',
  'confirmation', 'support', 'shared-components'
]).run().config([
  '$urlRouterProvider','$stateProvider', '$locationProvider', '$compileProvider', '$httpProvider',
  function(
    $urlRouterProvider, $stateProvider, $locationProvider, $compileProvider, $httpProvider
  ) {

    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false,
      html5Mode: true
    });

    $compileProvider.debugInfoEnabled(false);
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);

    $httpProvider.interceptors.push('cacheBusterInterceptor');
    $httpProvider.useLegacyPromiseExtensions = false;

    $urlRouterProvider.otherwise('error/404');
  }
]);

// Angular manual bootstraping; IE11 bugfix
angular.element(document).ready(() => {
  angular.bootstrap(document, ['app'], {
    strictDi: true
  });
});
