/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('gccs-ui').directive('gccsMultipleButtonsShiftRule', () => {
  return {
    restrict: 'A',
    controller: 'MultipleButtonsController',
    controllerAs: 'multipleButtonsCtrl',
    template: require('./template.pug'),
    scope: {
      buttons: '<',
      chosenButtons: '=',
      isSingle: '<',
      editable: '<',
      isRequired: '<',
      onChangeCb: '&'
    }
  };
});
