/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').factory('BookingTemplate', ['AppModel', (AppModel) => {

  class BookingTemplate extends AppModel {
    static initClass() {
      this.configure({ url: '/booking_templates/{{id}}', name: 'template' });
    }
  }

  BookingTemplate.initClass();
  return BookingTemplate;
}

]);
