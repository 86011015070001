/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$state', '$sce', '$element', '$timeout', '$scope', 'EmployeeInvoiceVersion', 'SessionToken',
  'dateService', 'employeeInvoiceStatus', 'confirmService', 'employeeType',
  'currentUserService', 'employeeInvoiceLabelService'
];

const InvoiceEmployeeInvoiceVersionController = function(
  $state, $sce, $element, $timeout, $scope, EmployeeInvoiceVersion, SessionToken,
  dateService, employeeInvoiceStatus, confirmService, employeeType,
  currentUserService, employeeInvoiceLabelService,
) {

  const REMOVABLE_VERSIONS_COUNT = 2;

  const vm = this;

  vm.format = dateService.getDateFormat();
  vm.employeeInvoiceStatus = employeeInvoiceStatus;
  vm.isShownPdf = false;
  vm.pdfType = "standard";
  vm.employeeType = employeeType;

  const formatISO = dateService.getIsoDateFormat();

  let _sessionToken;

  vm.$onInit = function () {
    vm.employeesLabels = employeeInvoiceLabelService.getEmployeesTexts(vm.invoice.employmentType);
    initPdfUrl();
  };

  vm.$onChanges = function () {
    initVersionClone();
  };

  vm.getCreatorName = function () {
    return [vm.invoiceVersionClone.creator.firstName, vm.invoiceVersionClone.creator.lastName].join(' ');
  };

  vm.getCreatedAt = function() {
    if (!vm.invoiceVersionClone.createdAtMoment) {
      vm.invoiceVersionClone.createdAtMoment = moment(
        vm.invoiceVersionClone.createdAt,
        dateService.getIsoFullDateFormatWithTz()
      );
    }
    return vm.invoiceVersionClone.createdAtMoment.format(dateService.getFullDateFormat());
  };

  vm.isEmployeeLogged = function () {
    return currentUserService.isEmployeeLogged();
  };

  vm.canEditInvoice = function () {
    return vm.editable && !(
      vm.isEmployeeLogged() && (employeeType.isSelfEmployed(vm.invoice.employmentType) || employeeType.isSubContractor(vm.invoice.employmentType))
    );
  };

  vm.toggleActive = function() {
    if (!vm.toggleable) { return; }
    return vm.invoice.isActive = !vm.invoice.isActive;
  };

  vm.previewInvoice = function () {
    $state.go('public.employee-invoice-history', {
      invoiceId: vm.invoice.id,
      invoiceVersionId: vm.invoiceVersion.id,
      invoicePreview: true,
      allList: vm.allList
    });
  };

  vm.goToInvoiceForm = function () {
    $state.go('public.employee-invoice-form', {
      invoiceId: vm.invoice.id,
      allList: vm.allList
    });
  };

  vm.showPdf = function (pdfType) {
    if(pdfType == "humyn" && vm.hasHumynInvoiceIssue()){
      return;
    }
    vm.pdfType = pdfType;
    vm.isShownPdf = true;
  };

  vm.closePdf = function () {
    vm.pdfType = "standard";
    vm.isShownPdf = false;
  };

  vm.getInvoicePdfUrl = function (options) {
    return EmployeeInvoiceVersion.getPdfUrl({
      id: vm.invoiceVersionClone.id,
      invoiceId: vm.invoice.id
    }, {
      token_id: _sessionToken.id,
      token: _sessionToken.token,
      type: vm.pdfType
    }, options);
  };

  vm.changeStatus = function(status) {
    status = parseInt(status, 10);
    if (vm.invoiceVersionClone.status === status) { return; }
    if (employeeInvoiceStatus.isOpened(vm.invoiceVersionClone.status)) {
      vm.invoiceVersionClone.status = status;
      confirmService.confirm(
        'Confirm close',
        `This payslip will be closed in current state to the end of current pay period. Are you sure?`,
        'Yes',
        () => initStatusChange(status),
        () => initVersionClone()
      );
    } else {
      vm.invoiceVersionClone.status = status;
      $scope.customData = undefined;
      if (vm.withPaidDate(status)) {
        initCommentFormForDatePicker();
        $timeout(function() {
          const datepickerElement = $element[0].querySelector('input[date-time]');
          datepickerElement.focus();
          return angular.element(datepickerElement).triggerHandler('focus');
        }); // todo redo to open
      } else {
        confirmStatusChange(status);
      }
    }
    return true;
  };

  vm.getPdfTitle = function () {
    return `${vm.employeesLabels.capStr} #${vm.invoice.number}`;
  };

  vm.printPdf = function() {
    try {
      return printJS({
        printable: vm.getInvoicePdfUrl({ withDomain: false }),
        onLoadingStart: vm.onPrintStart,
        onLoadingEnd: vm.onPrintEnd
      });
    } catch (error) {
      return vm.onPrintEnd();
    }
  };

  vm.viewInvoice = function () {
    return $state.go('public.employee-invoice-history', {
      invoiceId: vm.invoice.id,
      invoiceVersionId: vm.nextVersion.id
    });
  };

  vm.withPaidDate = function (status) {
    return employeeInvoiceStatus.withPaidDate(status || vm.invoiceVersionClone.status);
  };

  vm.isRemovable = function() {
    if (!vm.editable || !vm.isMain) { return; }
    if (currentUserService.isSecurityManagerLogged()) { return true; }
    return vm.isEmployeeLogged() &&
      (vm.invoice.versionsCount <= REMOVABLE_VERSIONS_COUNT) && (
        (vm.invoiceVersion.status === employeeInvoiceStatus.getCreatedStatus()) ||
        (vm.invoiceVersion.status === employeeInvoiceStatus.getSentStatus())
      );
  };

  vm.onPickerClose = function () {
    return $timeout(function () {
      if (vm.invoiceVersion.status !== vm.invoiceVersionClone.status) {
        return confirmStatusChange(vm.invoiceVersionClone.status);
      } else {
        return initStatusChange(vm.invoiceVersionClone.status);
      }
    });
  };

  vm.getNames = function() {
    if (vm.isEmployeeLogged()) {
      return employeeInvoiceStatus.getEmployeeTransitionNames(vm.invoiceVersion.status);
    } else if (currentUserService.isSecurityManagerLogged()) {
      if (employeeInvoiceStatus.isOpened(vm.invoiceVersionClone.status)) {
        return employeeInvoiceStatus.getSecurityManagerNamesFromOpened();
      } else {
        return employeeInvoiceStatus.getSecurityManagerNames();
      }
    }
  };

  vm.checkBooking = function () {
    vm.onBookingCheck({ invoiceVersion: vm.invoiceVersion });
  };

  vm.isShownCheckbox = function () {
    return vm.canEditInvoice() && !employeeInvoiceStatus.isOpened(vm.invoiceVersionClone.status) && vm.isMain;
  };

  const initStatusChange = function() {
    vm.invoiceVersionClone.invoiceId = vm.invoice.id;
    const newInvoiceVersion = new EmployeeInvoiceVersion(vm.invoiceVersionClone);
    newInvoiceVersion.createdAtMoment = null;
    if ($scope.customData) {
      newInvoiceVersion.payNote = $scope.customData.comment.commentModel;
    } else {
      newInvoiceVersion.payNote = null;
    }
    if (vm.withPaidDate()) {
      newInvoiceVersion.paidDate = vm.paidDate.format(formatISO);
    } else {
      newInvoiceVersion.paidDate = null;
    }
    if (!isInvoiceChanged(newInvoiceVersion)) { return; }
    return newInvoiceVersion.save()
      .then(function(invoiceVersion) {
        if (vm.invoiceVersion.status !== vm.invoiceVersionClone.status) {
          vm.onStatusChange({ newVersion: invoiceVersion });
        } else {
          vm.invoiceVersion = invoiceVersion;
          initVersionClone();
        }})
      .catch(error => Rollbar.log('EmployeeInvoiceVersion status change', error));
  };

  vm.getName = function(status) {
    if (vm.isEmployeeLogged()) {
      return employeeInvoiceStatus.getEmployeeName(status);
    } else {
      return employeeInvoiceStatus.getSecurityManagerName(status);
    }
  };

  vm.isLoadedSessionToken = function() {
    return _sessionToken !== undefined;
  };

  vm.getHumynStatusText = function() {
    if(vm.hasHumynIntegration())
    {
      let text = "View Payslip";
      if(vm.invoiceVersion.humynSyncInfo.hasSyncIssue)
      {
        text = `<b>last synced at:</b> ${getFullDateAndTime(vm.invoiceVersion.humynSyncInfo.syncTime)},<br/><b>error:</b> ${vm.invoiceVersion.humynSyncInfo.message}`;
      }
      else if(vm.invoiceVersion.humynSyncInfo.waitingToSync)
      {
        text = "Waiting to sync. Please check in 5 minutes"
      }
      return $sce.trustAsHtml(text);
    }
  }
  
  vm.hasHumynInvoiceIssue = function() {
    if(vm.hasHumynIntegration())
    {
      return (vm.invoiceVersion.humynSyncInfo.hasSyncIssue || vm.invoiceVersion.humynSyncInfo.waitingToSync);
    }
    return false;
  }

  vm.hasHumynIntegration = function() {
    return (vm.invoiceVersion != null && vm.invoiceVersion.humynSyncInfo != null)
  }

  const confirmStatusChange = function (toStatus) {
    confirmService.confirm(
      'Confirm new status',
      `Do you really want to change status to ${vm.getName(toStatus)}?`,
      'Yes',
      () => initStatusChange(toStatus),
      () => initVersionClone()
    );
  };

  const initCommentFormForDatePicker = function() { // todo redo!! to smth like transclude
    if (employeeInvoiceStatus.withPaidDate(vm.invoiceVersionClone.status)) {
      $scope.customData = {
        comment: {
          isForm: true,
          commentModel: vm.invoiceVersionClone.payNote,
          onSubmit() {
            return $scope.$broadcast('hidePicker');
          }
        }
      };
    } else {
      $scope.customData = undefined;
    }
  };

  const initVersionClone = function() {
    vm.invoiceVersionClone = angular.copy(vm.invoiceVersion);
    if (vm.invoiceVersionClone.paidDate) {
      vm.paidDate = moment(vm.invoiceVersionClone.paidDate, formatISO);
      initCommentFormForDatePicker();
    } else { // strange bug
      vm.paidDate = moment();
    }
  };

  const isInvoiceChanged = function (invoiceVersion) {
    return !(
      (invoiceVersion.paidDate === vm.invoiceVersion.paidDate) &&
      (invoiceVersion.status === vm.invoiceVersion.status) &&
      (invoiceVersion.payNote === vm.invoiceVersion.payNote)
    );
  };

  const initPdfUrl = function() {
    new SessionToken({
      entityId: vm.invoiceVersionClone.id,
      entityType: 'employee_invoice'
    }).save().then(function(sessionToken) {
      _sessionToken = sessionToken;
    }).catch(function(error) {
      Rollbar.log(`Can not create session token on ${window.location.href}`, error);
    });
  };

  const getFullDateAndTime = date => {
    return moment(date, dateService.getIsoFullDateFormatWithTz()).format(dateService.getFullDateFormat());
  };

  return vm;

};

angular.module('public.invoice.employee')
  .controller('InvoiceEmployeeInvoiceVersionController', dependencies.concat(InvoiceEmployeeInvoiceVersionController));
