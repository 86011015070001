/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('landing.index', []).config(['$stateProvider', $stateProvider => {

  return $stateProvider

    .state('landing.index', {
      url: '/?{ privacy-policy }',
      controller: 'LandingIndexController',
      controllerAs: 'landingIndexCtrl',
      template: require('./template.pug'),
      params: {
        scrollAnchor: null
      }
    })
    .state('landing.404', {
      url: '/error/404',
      controller: 'LandingIndexController',
      controllerAs: 'landingIndexCtrl',
      template: require('./404.pug'),
      params: {
        scrollAnchor: null
      }
    });
}]);
