/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = ['DateRange'];

const FormDatePickerRangeRangeSelector = function(DateRange) {

  class Yesterday extends DateRange {

    getLabel() {
      return 'Yesterday';
    }

    getFrom() {
      return this.getToday().subtract(1, 'days');
    }

    getTo() {
      return this.getToday();
    }
  }

  class LastSevenDays extends DateRange {

    getLabel() {
      return 'Last 7 Days';
    }

    getFrom() {
      return this.getToday().subtract(7, 'days');
    }

    getTo() {
      return this.getToday();
    }
  }

  class LastThirtyDays extends DateRange {

    getLabel() {
      return 'Last 30 Days';
    }

    getFrom() {
      return this.getToday().subtract(30, 'days');
    }

    getTo() {
      return this.getToday();
    }
  }

  class ThisMonth extends DateRange {

    getLabel() {
      return 'This Month';
    }

    getFrom() {
      return this.getToday().startOf('month');
    }

    getTo() {
      return this.getToday().add(1, 'month').startOf('month');
    }
  }

  class LastMonth extends DateRange {

    getLabel() {
      return 'Last Month';
    }

    getFrom() {
      return this.getToday().subtract(1, 'month').startOf('month');
    }

    getTo() {
      return this.getToday().startOf('month');
    }
  }

  class CustomRange extends DateRange {

    getLabel() {
      return 'Custom Range';
    }

    _setRangeSelectorDates() {}
  }
      // NOOP

  class RangeSelector {

    constructor(today, from, to, updateFromCb, updateToCb) {
      this.today = today.clone();
      this.from = from.clone();
      this.to = to.clone();
      this.updateFromCb = updateFromCb;
      this.updateToCb = updateToCb;
      this.lastMonthRange = new LastMonth(this.today);
      this.customRange = new CustomRange(this.today);
      this._initRanges();
    }

    smartSelect() {
      let rangeActivated = false;
      for (let range of Array.from(this.ranges)) {
        if (range.hasSamePeriod(this.from, this.to)) {
          range.select(this);
          rangeActivated = true;
          break;
        }
      }
      if (!rangeActivated) { return this.customRange.select(this); }
    }

    setCustom() {
      return this.customRange.select(this);
    }

    isCustom() {
      return this.customRange.active;
    }

    setLastMonth() {
      return this.lastMonthRange.select(this);
    }

    setTo(to) {
      this.to = to;
      return this.updateToCb(this.to.clone());
    }

    setFrom(from) {
      this.from = from;
      return this.updateFromCb(this.from.clone());
    }

    getLabel() {
      return this.label;
    }

    setLabel(label, exceptRange) {
      this.label = label;
      return this._deactivateOtherRanges(exceptRange);
    }

    _initRanges() {
      this.ranges = [
        new Yesterday(this.today),
        new LastSevenDays(this.today),
        new LastThirtyDays(this.today),
        new ThisMonth(this.today),
        this.lastMonthRange,
        this.customRange
      ];
    }

    _deactivateOtherRanges(exceptRange) {
      return (() => {
        const result = [];
        for (let range of Array.from(this.ranges)) {
          if (range !== exceptRange) {
            result.push(range.deactivate());
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    }
  }

  return RangeSelector;

};

angular.module('form').factory('FormDatePickerRangeRangeSelector', dependencies.concat(FormDatePickerRangeRangeSelector));
