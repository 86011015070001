const dependencies = ['$scope', '$state', 'currentUserService', 'redirectService'];

const ReportsFinanceController = function($scope, $state, currentUserService, redirectService) {

  const vm = this;

  vm.$onInit = function() {
    checkReportsPermission();
    initTabs();
  };

  vm.isActiveState = function (tab) {
    return $state.includes(tab.sref, {
      employmentType: (tab.srefParams != null ? tab.srefParams.employmentType : undefined) || {}
    });
  };

  const initTabs = function() {
    vm.tabs = [{
      name: 'Income',
      sref: 'public.security-manager.reports.finance.income'
    }, {
      name: 'Daily income',
      sref: 'public.security-manager.reports.finance.daily-income'
    }, {
      name: 'Billable hours',
      sref: 'public.security-manager.reports.finance.billable-hours'
    }, {
      name: 'Payroll',
      sref: 'public.security-manager.reports.finance.payroll'
    }];
  };

  const checkReportsPermission = function () {
    let profile = currentUserService.getCurrentProfile();
    if (!(
      currentUserService.isSecurityManagerLogged() &&
      (profile.isAccountOwner() || profile.isAdmin() || (profile.isManager() && profile.hasAccessToReports))
    )) {
      redirectService.redirectToMain();
    }
  };

  return vm;

};

angular.module('public.security-manager.reports')
  .controller('ReportsFinanceController', dependencies.concat(ReportsFinanceController));
