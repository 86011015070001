/*
 * @author Oleksandr Papka <papkaos>
 */
const gccsFormDateVersionToggle = {
  controller: 'FormDateToggleController',
  controllerAs: 'formDateToggleCtrl',
  template: require('./template.pug'),
  bindings: {
    name: '@',
    dateModel: '=',
    minDate : '=',
    withTabElement : '=',
    addVersionCb: '&',
    removeVersionCb: '&',
    onChangeCb: '&'
  }
};

angular.module('form').component('gccsFormDateVersionToggle', gccsFormDateVersionToggle);
