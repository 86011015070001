const gccsPlanningHeaderDropdown = {
  controller: 'PlanningHeaderDropdownController',
  controllerAs: 'planningHeaderDropdownCtrl',
  template: require('./template.pug'),
  bindings: {
    name: '@',
    defaultLabel: '@',
    lockMessage: '@',
    isDisabled: '<',
    items: '<',
    chosenItem: '<',
    isLockedPromise: '<',
    onItemChooseCb: '&'
  }
};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .component('gccsPlanningHeaderDropdown', gccsPlanningHeaderDropdown);
