/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.client-invoice').factory('ClientInvoiceVersion', [
  'AppModel', 'getParamsService',
  function(AppModel, getParamsService) {

    class ClientInvoiceVersion extends AppModel {
      static initClass() {
        this.configure({ url: '/client_invoices/{{invoiceId}}/versions/{{id}}', name: 'version' });
      }

      static getPdfUrl(params, getParams, options = {}) {
        return this.resourceUrl(params, `/pdf_version.${options.format || 'pdf'}${getParamsService.buildUrl(getParams)}`, options);
      }
    }

    ClientInvoiceVersion.initClass();

    return ClientInvoiceVersion;

  }
]);
