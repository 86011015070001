/*
 * @author Oleksandr Papka <papkaos>
 */
const hoursReportType = function() {

  const CHARGES = 1;
  const ZERO_CHARGE_RATE = 2;
  const PAYMENT = 3;

  const names = {};
  names[CHARGES] = 'Charges';
  names[ZERO_CHARGE_RATE] = 'Zero charge rate';
  names[PAYMENT] = 'Payment';

  return {
    getNames() {
      return names;
    },

    getName(status) {
      return names[status];
    },

    getType(name) {
      return _.findKey(names, _.partial(_.isEqual, name));
    },

    isPaymentType(type) {
      return +type === PAYMENT;
    },

    isChargesType(type) {
      return +type === CHARGES;
    },

    isZeroChargeRateType(type) {
      return +type === ZERO_CHARGE_RATE;
    }
  };
};

angular.module('public.security-manager.reports').service('hoursReportType', hoursReportType);
