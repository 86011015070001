/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['AppModel'];

const EmployeeSickDay = AppModel => {

  class EmployeeSickDay extends AppModel {
    static initClass() {
      this.configure({ url: '/employees/{{ employeeId }}/sick_days/{{ id }}', name: 'sick_day' });
    }

    reject() {
      return this.$put(this.getUrl() + '/reject');
    }

    approve() {
      return this.$put(this.getUrl() + '/approve');
    }
  }

  EmployeeSickDay.initClass();

  return EmployeeSickDay;

};

angular.module('data.employee').factory('EmployeeSickDay', dependencies.concat(EmployeeSickDay));
