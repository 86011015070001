/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const gccsBookingTimeLine = () => {
  return {
    restrict: 'A',
    controller: 'BookingTimeLineController',
    controllerAs: 'timeLineCtrl',
    template: require('./template.pug'),
    bindToController: true,
    scope: {
      from: '<',
      timeLineInteractor: '<',
      timeLineLoader: '<',
      clients: '<',
      selectedClients: '<',
      newBooking: '&',
      bookingTimelineShiftSelector: '<',
      bookingEditRoute: '@',
      repeatableBookingEditRoute: '@',
      shiftStatistics: '<'
    }
  };
};

angular.module('public.timeline').directive('gccsBookingTimeLine', gccsBookingTimeLine);
