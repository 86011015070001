import './styles.css'
import template from './template.pug';

angular.module('shared-components').component('gccsInputWrapperIcon', {
  controller: function () {

  },
  template: template,
  transclude: true,
  bindings: {
    icon: '@',
  }
});
