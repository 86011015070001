/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').factory('BookingShiftChange', ['AppModel', (AppModel) => {

  class BookingShiftChange extends AppModel {
    static initClass() {
      this.configure({url : '/bookings/{{bookingId}}/shifts/{{shiftId}}/changes/{{id}}', name : 'change'});
    }
  }

  BookingShiftChange.initClass();
  return BookingShiftChange;
}

]);
