/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('public.security-manager.reports.finance', [
  'public.invoice',
  'data.report'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.reports.finance', {
      url: '/finance',
      abstract: true,
      template: require('./template.pug'),
      controller: 'ReportsFinanceController',
      controllerAs: 'reportsFinanceCtrl'
    })
    .state('public.security-manager.reports.finance.income', {
      url: '/income',
      template: require('./income/template.pug'),
      controller: 'ReportsFinanceIncomeController',
      controllerAs: 'reportsFinanceIncomeCtrl'
    })
    .state('public.security-manager.reports.finance.daily-income', {
      url: '/daily-income',
      template: require('./daily/template.pug'),
      controller: 'ReportsFinanceIncomeController',
      controllerAs: 'reportsFinanceIncomeCtrl'
    })
    .state('public.security-manager.reports.finance.billable-hours', {
      url: '/billable-hours',
      template: require('./billable-hours/template.pug'),
      controller: 'ReportsFinanceBillableHoursController',
      controllerAs: 'reportsFinanceBillableHoursCtrl'
    })
    .state('public.security-manager.reports.finance.payroll', {
      url: '/payroll',
      template: require('./payroll/template.pug'),
      controller: 'ReportsFinancePayrollController',
      controllerAs: 'reportsFinancePayrollIncomeCtrl'
    })

]);