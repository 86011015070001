/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = ['$scope', '$element'];

const SelectController = function($scope, $element) {

  const vm = this;

  vm.isShownSelect = false;
  vm.editable = $scope.editable === undefined ? true : $scope.editable;

  const ngModelController = $element.controller('ngModel');

  vm.getCurrentValue = function() {
    if ($scope.withId) {
      return findSelectedValueById();
    } else {
      return $scope.selectListItems[getSelectedValue()];
    }
  };

  vm.getCurrentClassNames = () => __guard__(vm.getCurrentValue(), x => x.classNames) || '';

  vm.getTextOf = value => (value != null ? value.text : undefined) || value || vm.getAddLabel();

  vm.getSelectedTextOf = function(value) {
    if ($scope.selectedListItems) {
      return $scope.selectedListItems[getSelectedValue()];
    } else {
      return vm.getTextOf(value);
    }
  };

  vm.toggleSelect = function () {
    if (vm.editable) {
      vm.isShownSelect = !vm.isShownSelect;
    }
  };

  vm.hideSelect = function () {
    vm.isShownSelect = false;
  };

  vm.select = function(key, value) {
    vm.hideSelect();
    const onSelect = function() {
      setSelectedValue(((value != null ? value.id : undefined) || key) - 0);
      if ($scope.onSelect) {
        $scope.onSelect({ selected: getSelectedValue(), value: value });
      }
    };
    if ($scope.beforeSelectCb) {
      $scope.beforeSelectCb({ onSuccessCb: onSelect });
    } else {
      onSelect();
    }
  };

  vm.onAdd = function() {
    vm.hideSelect();
    return $scope.onAdd();
  };

  vm.getAddLabel = function() {
    if ($scope.withoutAdd) {
      return '';
    } else {
      return $scope.addLabel || 'Add';
    }
  };

  const getSelectedValue = () => ngModelController.$viewValue - 0;

  const setSelectedValue = function (value) {
    ngModelController.$setViewValue(value);
  };

  const initDefaultSelectedKey = function() {
    if (_.isEmpty($scope.selectListItems)) { return; }
    if ($scope.withId) {
      if (findSelectedValueById() === undefined) {
        setSelectedValue(_.first($scope.selectListItems).id);
      }
    } else {
      const selectListKeys = _.map($scope.selectListItems, (value, key) => key - 0);
      if (selectListKeys.indexOf(getSelectedValue()) === -1) {
        setSelectedValue(_.first(selectListKeys));
      }
    }
  };

  const findSelectedValueById = function() {
    for (let value of Array.from($scope.selectListItems)) {
      if (value.id === getSelectedValue()) { return value; }
    }
  };

  $scope.$watchCollection('selectListItems', initDefaultSelectedKey);
  $scope.$watch(() => ngModelController.$viewValue, initDefaultSelectedKey);

  return vm;

};

angular.module('gccs-ui').controller('SelectController', dependencies.concat(SelectController));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
