/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.client.list').directive('gccsClientListFirstLetters', () =>
  ({
    restrict: 'A',
    controller: 'ClientListFirstLettersController',
    controllerAs: 'clientListFirstLettersCtrl',
    template: require('./template.pug'),
    scope: {
      firstLetterFilter: '=',
      resetFilter: '&'
    }
  })
);
