/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['$stateParams', 'elementService', '$timeout'];
const employeeTimeLineScrollToDayOff = ($stateParams, elementService, $timeout) =>
  ({
    restrict : 'A',
    scope : {
      dayOffDecorator : '<',
      openDayOffPopup : '<',
      employee : '<'
    },
    link($scope, $el) {
      if ($stateParams.goToDayOffId && ($stateParams.goToDayOffId === $scope.dayOffDecorator.id)) {
        const elementBox = $el[0].closest('.timeline-data-box');
        const scrollContainer = elementBox.closest('.timeline-data');
        scrollContainer.scrollTop = elementService.getOffsetTop(elementBox) - elementService.getOffsetTop(scrollContainer);
        return $timeout(() => $scope.openDayOffPopup(null, $scope.employee, $scope.dayOffDecorator, $el[0]));
      }
    }
  })
;

angular.module('public.timeline').directive('employeeTimeLineScrollToDayOff', dependencies.concat(employeeTimeLineScrollToDayOff));
