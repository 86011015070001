/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const ClientInvoiceEmail = AppModel => {

  class ClientInvoiceEmail extends AppModel {
    static initClass() {
      this.configure({url : '/emails/client_invoices', name : 'client_invoice'});
    }

    constructor() {
      super(...arguments);
      this.receiverEmails = [];
    }
  }

  ClientInvoiceEmail.initClass();
  return ClientInvoiceEmail;
};

angular.module('data.email').factory('ClientInvoiceEmail', dependencies.concat(ClientInvoiceEmail));
