/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const BookingShift = AppModel => {

  class BookingShift extends AppModel {
    static initClass() {
      this.configure({url : '/bookings/{{bookingId}}/shifts/{{id}}', name : 'shift'});
    }
  }

  BookingShift.initClass();
  return BookingShift;
};

angular.module('data.booking').factory('BookingShift', dependencies.concat(BookingShift));
