const dependencies = [
  'ClientLocation', 'activeStatus', 'packageTypeService', 'newConfirmService'
];

const SecurityManagerClientSiteLocationsController = function(
  ClientLocation, activeStatus, packageTypeService, confirmService
) {

  const vm = this;

  vm.removeLocation = function(location) {
    confirmService.confirmWithMultipleButtons({
      title: 'Are you sure you want to delete location?',
      question: `Location ${location.name} will be deleted completely, this action can not be undone. Are you sure you want to delete location?`,
      buttons: [{
        label: 'Cancel',
        type: 'white'
      }, {
        label: 'Delete',
        className: 'mod-white mod-red-text',
        onClickCb: function() {
          vm.onRemoveCb({ location });
        }
      }]
    });
  };

  vm.removeLocationFromSite = function(location) {
    confirmService.confirmWithMultipleButtons({
      title: 'Are you sure you want to remove location from site?',
      question: `Location ${location.name} will be removed from company site. If needed, you would need to add her manually again. Are you sure you want to remove location?`,
      buttons: [{
        label: 'Cancel',
        type: 'white'
      }, {
        label: 'Remove',
        type: 'green',
        onClickCb: function () {
          vm.onRemoveFromSiteCb({ location });
        }
      }]
    });
  };

  vm.showLocationPopup = function(location) {
    ClientLocation.getCount({ clientId: vm.clientId }, { activeStatus: activeStatus.getActiveStatus() }).then((data) => {
      if (packageTypeService.hasFreePackage()) {
        if (data.count >= packageTypeService.getLocationsLimitForFreePackage() && !location) {
          openLocationsExceedPopup();
        } else {
          openLocationPopup(location);
        }
      } else {
        openLocationPopup(location);
      }
    });
  };

  vm.closeLocationPopup = function(savedLocation) {
    vm.isShownLocationPopup = false;
    if (savedLocation) {
      vm.onUpdateCb({ location: savedLocation });
    }
  };

  vm.closeLocationsExceedPopup = function () {
    vm.isShownLocationsExceedPopup = false;
  };

  const openLocationsExceedPopup = function() {
    vm.isShownLocationsExceedPopup = true;
  };

  const openLocationPopup = function(location) {
    vm.popupLocation = new ClientLocation(location || { clientId: vm.clientId });
    vm.isShownLocationPopup = true;
  };

  return vm;

};

angular.module('public.security-manager.client.show').controller(
  'SecurityManagerClientSiteLocationsController',
  dependencies.concat(SecurityManagerClientSiteLocationsController)
);
