const dependencies = [];

const employeePayrollStatus = function() {

  const STATUS_PAYMENT_DUE = 1;
  const STATUS_PAID = 2;

  const LIST_CLASSES = {
    [STATUS_PAYMENT_DUE] : 'mod-red',
    [STATUS_PAID] : 'mod-green'
  };

  const NAMES = {
    [STATUS_PAYMENT_DUE] : 'Payment due',
    [STATUS_PAID] : 'Paid'
  };

  const filterNames = {};
  filterNames[0] = 'All';
  filterNames[STATUS_PAYMENT_DUE] = 'Payment due';
  filterNames[STATUS_PAID] = 'Paid';

  const selectedFilterNames = {};
  selectedFilterNames[0] = 'Status';
  selectedFilterNames[STATUS_PAYMENT_DUE] = 'Payment due';
  selectedFilterNames[STATUS_PAID] = 'Paid';

  return {
    getNames() {
      return NAMES;
    },

    getName(status) {
      return NAMES[status];
    },

    getClassByStatus(status) {
      return LIST_CLASSES[status];
    },

    getFilterNames() {
      return filterNames;
    },

    getSelectedFilterNames() {
      return selectedFilterNames;
    },

    getPaidStatus() {
      return STATUS_PAID;
    },

    getPaymentDueStatus() {
      return STATUS_PAYMENT_DUE;
    },

    isPaymentDue(status) {
      return status === STATUS_PAYMENT_DUE;
    },

    isPaid(status) {
      return status === STATUS_PAID;
    }
  };
};

angular.module('data.employee-payroll').service('employeePayrollStatus', dependencies.concat(employeePayrollStatus));
