const dependencies = [
  '$state', 'EmployeeEmail', 'emailStatus', 'packageTypeService', 'employeeStatus'
];

const SecurityManagerHrController = function(
  $state, EmployeeEmail, emailStatus, packageTypeService, employeeStatus
) {

  const vm = this;

  const draftsTab = {
    name: 'Email drafts',
    sref: 'public.security-manager.hr.draft-emails',
    count: 0
  };

  vm.$onInit = function() {
    initTabs();
    if (vm.tabs.length > 0 && $state.is('public.security-manager.hr.employees')) {
      $state.go(vm.tabs[0].sref)
    }
    initEmailCount();
  };

  vm.increaseDraftsCount = function() {
    vm.draftsCount++;
    draftsTab.count++;
  };

  vm.deceaseDraftsCount = function() {
    vm.draftsCount--;
    draftsTab.count--;
  };

  vm.isTabActive = function(tab) {
    return $state.includes(tab.sref);
  };

  const initTabs = function() {
    vm.tabs = [];
    vm.tabs = vm.tabs.concat({
      name: 'EMPLOYEES',
      sref: 'public.security-manager.hr.employees',
      stateParams: {
        status: employeeStatus.getActiveStatus()
      }
    });
    vm.tabs = vm.tabs.concat({
      name: 'BULK ADJUST PAY RATE',
      sref: 'public.security-manager.hr.bulk-rate'
    });
    if (packageTypeService.hasProPackage()) {
      vm.tabs = vm.tabs.concat({
        name: 'ID CARDS',
        sref: 'public.security-manager.hr.cards'
      });
    }
    if (packageTypeService.hasProPackage()) {
      vm.tabs = vm.tabs.concat([{
        name: 'Sent emails',
        sref: 'public.security-manager.hr.sent-emails'
      },
        draftsTab
      ]);
    }
  };

  const initEmailCount = function() {
    if (packageTypeService.hasProPackage()) {
      EmployeeEmail.getCount({}, { status: emailStatus.getDraftStatus() }).then(function (data) {
        vm.draftsCount = data.count;
        draftsTab.count = data.count;
      });
    }
  };

  return vm;
};

angular
  .module('public.security-manager.hr')
  .controller('SecurityManagerHrController', dependencies.concat(SecurityManagerHrController));
