const dependencies = ['popupDirectiveConfig'];

const scheduleManagerTimelineEmployeeDayOffPopup = popupDirectiveConfig => {
  return popupDirectiveConfig.configure({
    controller: 'ScheduleManagerTimelineEmployeeDayOffPopupController',
    controllerAs: '$ctrl',
    bodyTemplate: require('./template.pug'),
    scope: {
      dayOff: '<',
      isOpen: '<',
      isEditing: '<',
      eventOptions: '<',
      onClose: '&',
      onSave: '&',
      onDelete: '&',
      onBodyClickCb: '&',
      position: '<',
      employee: '<',
    },
    bindToController: true
  });
};

angular
  .module('public.security-manager.schedule-manager.timeline')
  .directive('scheduleManagerTimelineEmployeeDayOffPopup',
    dependencies.concat(scheduleManagerTimelineEmployeeDayOffPopup));
