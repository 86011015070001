/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.settings').directive('gccsSettingsManagersHeader', () => {
  return {
    restrict: 'A',
    controller: 'SettingsManagersHeaderController',
    controllerAs: 'settingsManagersHeaderCtrl',
    template: require('./template.pug'),
    scope: {
      nameOrderParam: '='
    }
  };
});
