const dependencies = [
  '$state', '$sce', 'ClientInvoiceVersion', 'SessionToken', 'dateService', 'invoiceStatus', 'invoiceType', 'confirmService',
  'currentUserService'
];

const InvoiceVersionController = function(
  $state, $sce, ClientInvoiceVersion, SessionToken, dateService, invoiceStatus, invoiceType, confirmService,
  currentUserService
) {

  const vm = this;

  vm.invoiceStatus = invoiceStatus;
  vm.invoiceType = invoiceType;
  vm.isShownPdf = false;
  vm.pdfType = "standard";
  vm.isActiveButtonList = false;

  let _sessionToken;

  vm.$onInit = function() {
    vm.invoiceCreated = vm.invoice.id !== null;
    vm.bookingWasUpdated = bookingWasUpdated();
    initButtonList();
    if (vm.invoiceVersion.id) {
      initPdfUrl();
    }
  };

  vm.getCreatorName = () => {
    return [vm.invoiceVersion.creator.firstName, vm.invoiceVersion.creator.lastName].join(' ');
  };

  vm.getCreatedAt = function() {
    if (!vm.invoiceVersion.createdAtMoment) {
      vm.invoiceVersion.createdAtMoment = moment(vm.invoiceVersion.createdAt, dateService.getIsoFullDateFormatWithTz());
    }
    return vm.invoiceVersion.createdAtMoment.format(dateService.getFullDateFormat());
  };

  vm.canCreateInvoice = () => !vm.invoiceCreated || invoiceType.isQuote(vm.invoiceVersion.type);

  vm.canEditInvoice = () => invoiceType.isInvoice(vm.invoiceVersion.type);

  vm.canEditCreditNote = () => invoiceType.isCreditNote(vm.invoiceVersion.type);

  vm.canCreateQuote = () => !vm.invoiceCreated;

  vm.toggleActive = function() {
    if (!vm.toggleable) { return; }
    vm.invoice.isActive = !vm.invoice.isActive;
  };

  vm.canEditQuote = () => invoiceType.isQuote(vm.invoiceVersion.type);

  vm.gotoQuoteForm = () => gotoForm(invoiceType.getQuoteType());

  vm.gotoInvoiceForm = () => gotoForm(invoiceType.getInvoiceType());

  vm.gotoCreditNoteForm = () => gotoForm(invoiceType.getCreditNoteType());

  vm.showPdf = function (pdfType) {
    if(pdfType == "humyn" && vm.hasHumynInvoiceIssue()){
      return;
    }
    vm.pdfType = pdfType;
    vm.isShownPdf = true;
  };

  vm.closePdf = function () {
    vm.pdfType = "standard";
    vm.isShownPdf = false;
  };

  vm.getInvoicePdfUrl = options => {
    return ClientInvoiceVersion.getPdfUrl({
      id: vm.invoiceVersion.id,
      invoiceId: vm.invoice.id
    }, {
      token_id: _sessionToken.id,
      token: _sessionToken.token,
      type: vm.pdfType
    }, options);
  };

  vm.changeStatus = function(status) {
    if (vm.invoiceVersion.status !== status) {
      confirmService.confirm(
        'Confirm new status',
        `Do you really want to change status to ${invoiceStatus.getName(status)}?`,
        'Yes',
        () => onStatusChange(status)
      );
    }
  };

  vm.checkBooking = () => vm.onBookingCheck({ booking: vm.invoiceVersion.booking, invoiceVersion: vm.invoiceVersion });

  vm.isEditable = () => vm.isMain && vm.editable;

  vm.isRemovable = function() {
    if (!vm.editable || !vm.isMain || !vm.invoiceVersion.status) { return; }
    return currentUserService.isSecurityManagerLogged();
  };

  vm.getPdfTitle = () => `${invoiceType.getName(vm.invoiceVersion.type)} #${vm.invoiceVersion.invoiceNumber}`;

  vm.printPdf = function() {
    try {
      printJS({
        printable: vm.getInvoicePdfUrl({ withDomain: false }),
        onLoadingStart: vm.onPrintStart,
        onLoadingEnd: vm.onPrintEnd
      });
    } catch (error) {
      vm.onPrintEnd();
    }
  };

  vm.toggleButtonList = () => vm.isActiveButtonList = !vm.isActiveButtonList;

  vm.closeButtonList = () => vm.isActiveButtonList = false;

  vm.isLoadedSessionToken = function() {
    return _sessionToken !== undefined;
  };


  vm.getHumynStatusText = function() {
    if(vm.hasHumynIntegration())
    {
      let text = "View Payslip";
      if(vm.invoiceVersion.humynSyncInfo.hasSyncIssue)
      {
        text = `<b>last synced at:</b> ${getFullDateAndTime(vm.invoiceVersion.humynSyncInfo.syncTime)},<br/><b>error:</b> ${vm.invoiceVersion.humynSyncInfo.message}`;
      }
      else if(vm.invoiceVersion.humynSyncInfo.waitingToSync)
      {
        text = "Waiting to sync. Please check in 5 minutes"
      }
      return $sce.trustAsHtml(text);
    }
  }
  
  vm.hasHumynInvoiceIssue = function() {
    if(vm.hasHumynIntegration())
    {
      return (vm.invoiceVersion.humynSyncInfo.hasSyncIssue || vm.invoiceVersion.humynSyncInfo.waitingToSync);
    }
    return false;
  }

  vm.hasHumynIntegration = function() {
    return (vm.invoiceVersion != null && vm.invoiceVersion.humynSyncInfo != null)
  }

  const bookingWasUpdated = function() {
    const bookingLastUpdated = moment(vm.invoice.bookingLastUpdatedAt, dateService.getIsoFullDateFormat());
    const invoiceBookingUpdated = moment(vm.invoiceVersion.booking.updatedAt, dateService.getIsoFullDateFormatWithT());
    return bookingLastUpdated.isAfter(invoiceBookingUpdated);
  };

  const gotoForm = type => {
    $state.go('public.security-manager-invoice-form', {
      invoiceId: vm.invoice.id,
      bookingIds: vm.invoiceVersion.booking.id,
      type,
      clientId: vm.invoice.clientId || vm.invoiceVersion.booking.clientId
    });
  };

  const onStatusChange = function(status) {
    const newInvoiceVersion = new ClientInvoiceVersion({ invoiceId: vm.invoice.id, id: vm.invoiceVersion.id, status });
    newInvoiceVersion.save().then(invoiceVersion => vm.onStatusChange({ newVersion: invoiceVersion }));
  };

  const initButtonList = function() {
    vm.buttonList = [];
    if (vm.canCreateInvoice()) {
      vm.buttonList.push({
        text: 'Create invoice',
        onClickCb: vm.gotoInvoiceForm
      });
    }
    if (vm.canEditInvoice()) {
      vm.buttonList.push({
        text: 'Edit invoice',
        onClickCb: vm.gotoInvoiceForm
      });
    }
    if (vm.canEditCreditNote()) {
      vm.buttonList.push({
        text: 'Edit credit note',
        onClickCb: vm.gotoCreditNoteForm
      });
    }
    if (vm.canCreateQuote()) {
      vm.buttonList.push({
        text: 'Create quote',
        onClickCb: vm.gotoQuoteForm
      });
    }
    if (vm.canEditQuote()) {
      vm.buttonList.push({
        text: 'Edit quote',
        onClickCb: vm.gotoQuoteForm
      });
    }
  };

  const initPdfUrl = function() {
    new SessionToken({
      entityId: vm.invoiceVersion.id,
      entityType: 'client_invoice'
    }).save().then(function(sessionToken) {
      _sessionToken = sessionToken;
    }).catch(function(error) {
      Rollbar.log(`Can not create session token on ${window.location.href}`, error);
    });
  };

  const getFullDateAndTime = date => {
    return moment(date, dateService.getIsoFullDateFormatWithTz()).format(dateService.getFullDateFormat());
  };

  return vm;

};

angular
  .module('public.security-manager.client.show.invoice')
  .controller('InvoiceVersionController', dependencies.concat(InvoiceVersionController));
