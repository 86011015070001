/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsPopupEmployeeTimelineShift = () => {
  return {
    template: require('./template.pug'),
    controller: 'PopupEmployeeTimelineShiftController',
    controllerAs: 'popupEmployeeTimelineShiftCtrl',
    scope: {
      shiftEmployee: '<',
      position: '<',
      popupCloseCb: '&'
    },
    bindToController: true
  };
};

angular.module('popup.employee').directive('gccsPopupEmployeeTimelineShift', gccsPopupEmployeeTimelineShift);
