/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.resource').factory('Resource', ['AppModel', function(AppModel) {

  class Resource extends AppModel {
    static initClass() {
      this.configure({ url: '/resources', name: 'resource' });
    }
  }

  Resource.initClass();

  return Resource;

}]);
