const dateService = function() {

  const DATE_FORMAT = 'DD/MM/YYYY';
  const DATE_SHORT_FORMAT = 'DD/MM/YY';
  const DATE_FORMAT_YEARLESS = 'DD/MM';
  const REVERTED_DATE_FORMAT = 'YYYY/MM/DD';
  const TIME_FORMAT = 'HH:mm';
  const FULL_DATE_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  const FULL_DATE_FORMAT_WITH_COMMA = `${DATE_FORMAT}, ${TIME_FORMAT}`;

  const WESTERN_TIME_FORMAT = 'h:mm a';
  const WESTERN_TIME_FORMAT_UPCASE = 'h:mm A';
  const WESTERN_FULL_DATE_FORMAT = `${DATE_FORMAT} ${WESTERN_TIME_FORMAT}`;
  const WESTERN_FULL_DATE_FORMAT_UPCASE = `${DATE_FORMAT} ${WESTERN_TIME_FORMAT_UPCASE}`;
  const WESTERN_FULL_DATE_FORMAT_UPCASE_YEARLESS = `${DATE_FORMAT_YEARLESS}, ${WESTERN_TIME_FORMAT_UPCASE}`;

  const ISO_DATE_FORMAT = 'YYYY-MM-DD';
  const ISO_FULL_DATE_FORMAT = `${ISO_DATE_FORMAT} ${TIME_FORMAT}:ss`;
  const ISO_FULL_DATE_FORMAT_WITH_TZ = `${ISO_FULL_DATE_FORMAT} ZZ`;

  const TEXT_FORMAT = 'DD of MMMM, YYYY';
  const SHORT_TEXT_FORMAT = 'DD MMM';

  // private

  const formatDate = function(date, fromFormat, toFormat = DATE_FORMAT) {
    if (!moment.isMoment(date)) { date = moment(date, fromFormat); }
    return date.format(toFormat);
  };

  const getMinMax = (type, date1, date2, format) => moment[type](moment(date1, format), moment(date2, format));

  // public

  return {
    getDateFormat() {
      return DATE_FORMAT;
    },

    getDateShortFormat() {
      return DATE_SHORT_FORMAT;
    },

    getDateFormatYearless() {
      return DATE_FORMAT_YEARLESS;
    },

    getRevertedDateFormat() {
      return REVERTED_DATE_FORMAT;
    },

    getFullDateFormat() {
      return FULL_DATE_FORMAT;
    },

    getFullDateFormatWithComma() {
      return FULL_DATE_FORMAT_WITH_COMMA;
    },

    getIsoDateFormat() {
      return ISO_DATE_FORMAT;
    },

    getShortTextFormat() {
      return SHORT_TEXT_FORMAT;
    },

    getIsoFullDateFormat() {
      return ISO_FULL_DATE_FORMAT;
    },

    getIsoFullDateFormatWithT(withTz = false) {
      let format = `${ISO_DATE_FORMAT}T${TIME_FORMAT}:ss`;
      if (withTz) { format += ' ZZ'; }
      return format;
    },

    getIsoFullDateFormatWithTz() {
      return ISO_FULL_DATE_FORMAT_WITH_TZ;
    },

    getTimeFormat() {
      return TIME_FORMAT;
    },

    getTextFormat() {
      return TEXT_FORMAT;
    },

    getWesternTimeFormat(upcase = false) {
      if (upcase) {
        return WESTERN_TIME_FORMAT_UPCASE;
      } else {
        return WESTERN_TIME_FORMAT;
      }
    },

    getWesternFullDateFormat(upcase = false) {
      if (upcase) {
        return WESTERN_FULL_DATE_FORMAT_UPCASE;
      } else {
        return WESTERN_FULL_DATE_FORMAT;
      }
    },

    getWesternFullDateFormatYearless() {
      return WESTERN_FULL_DATE_FORMAT_UPCASE_YEARLESS;
    },

    today() {
      return moment().startOf('day');
    },

    formatDate(date, format) {
      return formatDate(date, ISO_DATE_FORMAT, format);
    },

    formatDateTime(dateTime, format) {
      return formatDate(dateTime, ISO_FULL_DATE_FORMAT, format);
    },

    isValid(date) {
      return moment(date, DATE_FORMAT, true).isValid();
    },

    getMax(date1, date2, format) {
      return getMinMax('max', date1, date2, format);
    },

    getMin(date1, date2, format) {
      return getMinMax('min', date1, date2, format);
    },

    getDifferenceWithDST(earlierDate, laterDate, diffUnit = 'minutes') {
      if (earlierDate.isDST() && !laterDate.isDST()) {
        laterDate = laterDate.clone().add(-1, 'hour');
      } else if (!earlierDate.isDST() && laterDate.isDST()) {
        laterDate = laterDate.clone().add(1, 'hour');
      }
      return laterDate.diff(earlierDate, diffUnit);
    },

    getTimeLeft(date) {
      const timeDifference = moment().diff(date);
      const duration = moment.duration(timeDifference);
      if (duration.years()) {
        return `${duration.years()}y`;
      } else if (duration.months()) {
        return `${duration.months()}m`;
      } else if (duration.days()) {
        return `${duration.days()}d`;
      } else if (duration.hours()) {
        return `${duration.hours()}h`;
      } else if (duration.minutes()) {
        return `${duration.minutes()}m`;
      }
    },

    formatTimeDifference(baseTime, compareTime) {
      let earlierTime, laterTime, timeStatus;
      if (baseTime.isAfter(compareTime)) {
        earlierTime = compareTime;
        laterTime = baseTime;
        timeStatus = 'early';
      } else {
        earlierTime = baseTime;
        laterTime = compareTime;
        timeStatus = 'late';
      }
      let difference = laterTime.diff(earlierTime, 's');
      const hours = Math.floor(difference / 3600);
      difference %= 3600;
      const minutes = Math.floor(difference / 60);
      const seconds = difference % 60;
      let timeParts = [];
      if (hours) { timeParts.push(`${hours}h`); }
      if (minutes) { timeParts.push(`${minutes}m`); }
      if (seconds) { timeParts.push(`${seconds}s`); }
      return `${timeParts.join(' ')} ${timeStatus}`;
    }

  };
};

angular.module('shared').service('dateService', dateService);
