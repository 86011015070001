const dependencies = ['Booking', 'elementService', 'dateService'];

const PlanningShiftDetailsService = function(Booking, elementService, dateService) {

  return class PlanningShiftDetailsService {

    constructor() {
      this.isShownPopup = false;
      this.popupPosition = {};
    }

    showPopup(shiftEmployee, element) {
      this.popupPosition = {
        left: elementService.getOffsetLeft(element),
        top: elementService.getOffsetTop(element)
      };
      this.popupShiftDecorator = {
        shift: {
          name: shiftEmployee.name
        },
        timelineStartTime: shiftEmployee.startTime,
        startTimeMoment: moment(shiftEmployee.startTime, dateService.getIsoFullDateFormat()),
        timelineEndTime: shiftEmployee.endTime,
        endTimeMoment: moment(shiftEmployee.endTime, dateService.getIsoFullDateFormat())
      };
      Booking.forShift(shiftEmployee.shiftId).then(booking => {
        this.popupBooking = booking;
        this.isShownPopup = true;
      });
    }

    closePopup() {
      this.isShownPopup = false;
    }

  };

};

angular
  .module('public.security-manager.schedule-manager.timeline.planning')
  .factory('PlanningShiftDetailsService', dependencies.concat(PlanningShiftDetailsService));
