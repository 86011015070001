/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const securityManagerAccessLevel = function() {

  const ACCESS_LEVEL_ACCOUNT_OWNER = 1;
  const ACCESS_LEVEL_ADMIN = 2;
  const ACCESS_LEVEL_MANAGER = 3;
  const ACCESS_LEVEL_SUPERVISOR = 4;

  const names = {};
  names[ACCESS_LEVEL_ACCOUNT_OWNER] = 'Account owner';
  names[ACCESS_LEVEL_ADMIN] = 'Main administrator';
  names[ACCESS_LEVEL_MANAGER] = 'Manager';
  names[ACCESS_LEVEL_SUPERVISOR] = 'Supervisor';

  const namesFor = {};

  return {
    getNamesFor(requestedAccessLevel) {
      return namesFor[requestedAccessLevel] || (namesFor[requestedAccessLevel] = _.pickBy(names, (name, accessLevel) => requestedAccessLevel <= accessLevel));
    },

    getName(accessLevel) {
      return names[accessLevel];
    },

    isAccountOwner(accessLevel) {
      return accessLevel === ACCESS_LEVEL_ACCOUNT_OWNER;
    },

    isAdmin(accessLevel) {
      return accessLevel === ACCESS_LEVEL_ADMIN;
    },

    isManager(accessLevel) {
      return accessLevel === ACCESS_LEVEL_MANAGER;
    },

    isSupervisor(accessLevel) {
      return accessLevel === ACCESS_LEVEL_SUPERVISOR;
    },

    getAccountOwnerAccessLevel() {
      return ACCESS_LEVEL_ACCOUNT_OWNER;
    },

    getAdminAccessLevel() {
      return ACCESS_LEVEL_ADMIN;
    },

    getManagerAccessLevel() {
      return ACCESS_LEVEL_MANAGER;
    },

    getSupervisorAccessLevel() {
      return ACCESS_LEVEL_SUPERVISOR;
    },
  };
};

angular.module('data.security-manager').service('securityManagerAccessLevel', securityManagerAccessLevel);
