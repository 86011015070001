/*
 * @author Igor Serpak <robobee>
 */
const gccsEmployeeCardVersion = {
  controller: 'EmployeeCardVersionController',
  controllerAs: '$ctrl',
  template: require('./template.pug'),
  bindings: {
    onCardToggle: '&',
    onStatusChange: '&',
    onPrintStart: '&',
    onPrintEnd: '&',
    cardVersion: '<',
    toggleable: '<',
    isMain: '<'
  }
};

angular.module('public.security-manager.hr.cards').component('gccsEmployeeCardVersion', gccsEmployeeCardVersion);
