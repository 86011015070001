const gccsShiftRuleItem = {
  controller: 'ScheduleManagerShiftRuleController',
  controllerAs: 'scheduleManagerShiftRuleCtrl',
  template: require('./template.pug'),
  bindings: {
    shiftRule: '=',
    onShiftRuleDelete: '&',
    checkOtherRules: '&',
    isEdit: '<',
    repeatableBooking: '<'
  }
};

angular.module('public.security-manager.schedule-manager.repeatable-booking')
  .component('gccsShiftRuleItem', gccsShiftRuleItem);
