/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'Client', 'clientStatus', 'packageTypeService', 'systemPackagePopupService'
];

const PopupSystemPackageDowngradeClientsController = function(
  $scope, Client, clientStatus, packageTypeService, systemPackagePopupService
) {

  const vm = this;

  vm.onCheckBoxClick = function(e) {
    e.stopPropagation();
  };

  vm.toggleClient = function(client) {
    if (!client.isSelected) {
      deselectAll();
    }
    client.isSelected = !client.isSelected;
  };

  vm.isSelectedNumberValid = function() {
    return vm.getSelectedCount() === packageTypeService.getClientsLimitForFreePackage();
  };

  vm.onNextStep = function() {
    if (!vm.isSelectedNumberValid()) { return; }
    vm.downgradeService.addToStore('clients', vm.clients);
    vm.downgradeService.addCallback(systemPackagePopupService.goToClientsDowngradePopup);
    vm.downgradeService.incrementStep();
    systemPackagePopupService.goToLocationsDowngradePopup(systemPackagePopupService.getPackageType(), _.find(vm.clients, 'isSelected').id, $scope.popupCtrl.closePopup);
  };

  vm.isFirstStep = function() {
    return vm.downgradeService.getStep() === 1;
  };

  vm.goBack = function() {
    vm.downgradeService.goBack(systemPackagePopupService.getPackageType(), $scope.popupCtrl.closePopup);
  };

  vm.getSelectedCount = function() {
    return _.filter(vm.clients, 'isSelected').length;
  };

  const deselectAll = function() {
    vm.clients.forEach((client) => {
      client.isSelected = false;
    });
  };

  const initDowngradeService = function() {
    vm.downgradeService = systemPackagePopupService.getSystemPackageDowngrade();
  };

  const initClients = function() {
    if (vm.downgradeService.isInStore('clients')) {
      vm.clients = angular.copy(vm.downgradeService.getFromStore('clients'));
    } else {
      Client.query({ 'order[name]': 'asc', status: clientStatus.getActiveStatus() }).then(function (clients) {
        vm.clients = clients;
      });
    }
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      initDowngradeService();
      initClients();
    }
  });

  return vm;

};

angular.module('popup.system-package')
  .controller('PopupSystemPackageDowngradeClientsController', dependencies.concat(PopupSystemPackageDowngradeClientsController));
