const dependencies = [
  '$state', 'SecurityManagerNotification', 'Client', 'notificationService', 'clientStatus', 'currentUserService'
];

const SecurityManagerNotificationActionService = (
  $state, SecurityManagerNotification, Client, notificationService, clientStatus, currentUserService
) => {

  const goToEmployeeProfile = function(notification, employeeId) {
    markRead(notification);
    $state.go('public.security-manager.employee.details', { employeeId: employeeId });
  };

  const goToClientDetails = function(notification, clientId) {
    markRead(notification);
    $state.go('public.security-manager.client.details', { clientId: clientId });
  };

  const goToDayOff = function(notification, dayOffId, startDay) {
    markRead(notification);
    $state.go('public.security-manager.schedule-manager.timeline.employee', {
      goToDate: startDay,
      goToEventId: dayOffId,
      goToEventType: 'day-off'
    });
  };

  const goToSickDay = function(notification, sickDayId, startDay) {
    markRead(notification);
    $state.go('public.security-manager.schedule-manager.timeline.employee', {
      goToDate: startDay,
      goToEventId: sickDayId,
      goToEventType: 'sick-day'
    });
  };

  const goToBookingTimeline = function(notification, startDay) {
    markRead(notification);
    $state.go('public.security-manager.schedule-manager.timeline.booking', { goToDate: startDay });
  };

  const goToShift = function(notification, shiftId, startDay) {
    markRead(notification);
    Client.byShift(shiftId).then(function (client) {
      if (clientStatus.isActive(client.status)) {
        $state.go('public.security-manager.schedule-manager.timeline.booking', {
          goToDate: startDay,
          goToShiftId: shiftId
        });
      } else {
        notificationService.notifyError('Client is not active');
      }
    });
  };

  const goToEmployeeInvoiceReview = function(notification, invoiceId, versionId) {
    markRead(notification);
    $state.go('public.employee-invoice-history', {
      invoiceId: invoiceId,
      invoiceVersionId: versionId,
      invoiceReview: true
    });
  };

  const goToEmployeeInvoiceList = function(notification, employeeId) {
    markRead(notification);
    $state.go('public.security-manager.employee.invoice.list', { employeeId: employeeId });
  };

  const goToSettingsManagers = function(notification) {
    markRead(notification);
    $state.go('public.security-manager.settings.managers');
  };

  const approveDeclineNotification = function(notification, method) {
    if (notification.isUpdating) { return; }
    notification.isUpdating = true;
    markRead(notification);
    SecurityManagerNotification[method](currentUserService.getCurrentProfile().id, notification.id);
  };

  const markRead = function(notification) {
    const isRead = notification.securityManagerNotificationsSecurityManagers[0].isRead;
    if (isRead) { return }
    SecurityManagerNotification.markRead(currentUserService.getCurrentProfile().id, notification.id);
  };

  return {
    goToEmployeeProfile,
    goToClientDetails,
    goToDayOff,
    goToSickDay,
    goToBookingTimeline,
    goToShift,
    goToEmployeeInvoiceReview,
    goToEmployeeInvoiceList,
    goToSettingsManagers,
    approveDeclineNotification,
    markRead
  }

};

angular.module('public.security-manager.notifications')
  .service('securityManagerNotificationActionService', dependencies.concat(SecurityManagerNotificationActionService));
