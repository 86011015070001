/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('shared').filter('getName', ['$injector', ($injector) => {
    return (input, serviceName) => {
      if (serviceName) {
        try {
          return $injector.get(serviceName).getName(input);
        } catch (e) {
          console.warn('Not valid service name:', serviceName);
        }
      }
    };
  }
]);