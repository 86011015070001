/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').service('activeStatus', function() {

  const ACTIVE_STATUS_ACTIVE = 1;
  const ACTIVE_STATUS_INACTIVE = 2;

  return {
    getActiveStatus() {
      return ACTIVE_STATUS_ACTIVE;
    },

    isActive(status) {
      return status === ACTIVE_STATUS_ACTIVE;
    },

    isInactive(status) {
      return status === ACTIVE_STATUS_INACTIVE;
    }
  };
});
