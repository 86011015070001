/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$location', 'EntityManager', 'FilterParamsService', 'Role'
];

const SettingsRolesController = function(
  $scope, $location, EntityManager, FilterParamsService, Role
) {

  const vm = this;

  const roleManager = new EntityManager(Role);
  const filterParamsService = new FilterParamsService({
    filter: ['name'],
    order: ['name']
  });

  vm.isShownRolePopup = false;
  vm.filterParams = filterParamsService.getParams();

  vm.isDisabledLoading = roleManager.isDisabledLoading;

  Role.getCount().then(data => vm.rolesCount = data.count);

  vm.toggleNameOrder = function() {
    if (vm.filterParams.order.name.ascending === null) {
      vm.filterParams.order.name.ascending = true;
    } else {
      vm.filterParams.order.name.ascending = !vm.filterParams.order.name.ascending;
    }
  };

  vm.removeRole = (role, onSuccessCb) => {
    roleManager.removeEntity(role, function () {
      if (onSuccessCb) {
        onSuccessCb();
      }
      vm.rolesCount--;
    });
  };

  vm.loadMoreRoles = () => roleManager.loadMore();

  vm.getRoles = () => roleManager.entities;

  vm.showRolePopup = function(role) {
    if ($scope.settingsLayoutCtrl.editable) {
      vm.popupRole = role || roleManager.createEntity();
      vm.isShownRolePopup = true;
    }
  };

  vm.closeRolePopup = function(role) {
    vm.isShownRolePopup = false;
    if (role) {
      roleManager.saveEntity(role).then(function() {
        vm.rolesCount++;
        filterRoles();
      });
    }
  };

  const filterRoles = function() {
    roleManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    $location.search(urlParams);
    roleManager.load(urlParams);
  };

  $scope.$watch(() => vm.filterParams, filterRoles, true);

  return vm;

};

angular.module('public.security-manager.settings')
  .controller('SettingsRolesController', dependencies.concat(SettingsRolesController));
