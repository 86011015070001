const gccsScheduleManagerBookingTimeLineEmployeeSidebar = {
  controller: 'ScheduleManagerBookingTimeLineEmployeeSidebarController',
  controllerAs: 'sidebarCtrl',
  template: require('./template.pug'),
  bindings: {
    shiftSelector: '<'
  }
};

angular
  .module('public.security-manager.schedule-manager.timeline')
  .component('gccsScheduleManagerBookingTimeLineEmployeeSidebar', gccsScheduleManagerBookingTimeLineEmployeeSidebar);
