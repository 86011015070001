const dependencies = ['$location', '$timeout', 'BookingShiftIssue', 'dateService', 'bookingShiftIssueType'];

const LiveFeedLoader = function($location, $timeout, BookingShiftIssue, dateService, bookingShiftIssueType) {

  const TIME_SHIFT_START = 'start';
  const TIME_SHIFT_END = 'end';
  const TIME_SHIFT_ISSUE_TIME = 'issue';
  const ISSUE_FIELDS = ['id', 'type', 'employeeId'];

  const ANIMATION_DURATION_DELETE = 850;

  return class LiveFeedLoader {

    constructor() {
      this.isLoading = false;
      this.endedLoading = false;
      this.shiftIds = [];
      this.countData = [];
      this._initSearchParams();
    }

    setShiftSelector(shiftSelector) {
      this.shiftSelector = shiftSelector;
    };

    loadIssues() {
      if (this.isLoading) { return; }
      this._resetIssues();
      this._loadIssues();
    }

    loadIssue({ id, issue_type, start_time }) {
      if (+this.searchParams.type && +this.searchParams.type !== issue_type) { return; }
      const startTimeMoment = moment(start_time, dateService.getIsoFullDateFormat());
      const startDate = moment(this.searchParams.from, dateService.getIsoDateFormat());
      const endDate = moment(this.searchParams.to, dateService.getIsoDateFormat()).add(1, 'day');
      if (startTimeMoment.isSameOrAfter(startDate) && startTimeMoment.isSameOrBefore(endDate)) {
        this.isLoading = true;
        BookingShiftIssue.get(id).then((shiftIssue) => {
          shiftIssue.isNew = true;
          shiftIssue.isNewAnimation = true;
          this._addShiftIssue(shiftIssue, false);
          this.isLoading = false;
        });
      }
    }

    loadShiftIssues({ shift_id, start_time }) {
      if (!this.shiftIds.includes(shift_id)) { return; }
      const startTimeMoment = moment(start_time, dateService.getIsoFullDateFormat());
      const startDate = moment(this.searchParams.from, dateService.getIsoDateFormat());
      const endDate = moment(this.searchParams.to, dateService.getIsoDateFormat()).add(1, 'day');
      if (startTimeMoment.isSameOrAfter(startDate) && startTimeMoment.isSameOrBefore(endDate)) {
        this.isLoading = true;
        BookingShiftIssue.query(_.merge({ shiftId: shift_id }, this.searchParams)).then((shiftIssues) => {
          this._updateShiftIssuesForShift(shift_id, shiftIssues);
          this.isLoading = false;
        });
      }
    }

    updateSearchParams(searchParams) {
      for (let paramKey in searchParams) {
        this.searchParams[paramKey] = searchParams[paramKey];
      }
      this.loadIssues();
    }

    resetDates() {
      this.searchParams.from = dateService.today().format(dateService.getIsoDateFormat());
      this.searchParams.to = dateService.today().add(1, 'day').format(dateService.getIsoDateFormat());
      this.loadIssues();
    }

    removeIssue({ id, shift_id }) {
      if (this.shiftIds.includes(shift_id)) {
        const foundDShiftIssues = this.findDShiftIssues(shift_id);
        foundDShiftIssues.forEach((foundDShiftIssue) => {
          const existingIssueIndex = foundDShiftIssue.shiftIssues.findIndex(function(existingIssue) {
            return existingIssue.id === id;
          });
          if (existingIssueIndex !== -1) {
            foundDShiftIssue.shiftIssues.splice(existingIssueIndex, 1);
            if (foundDShiftIssue.shiftIssues.length === 0) {
              this._performRemove(foundDShiftIssue);
            }
          }
        })
      }
    }

    loadMoreIssues() {
      if (this.isLoading) { return; }
      this.page++;
      this._loadIssues();
    }

    loadCounts() {
      BookingShiftIssue.getCount({}, this.searchParams).then((countData) => {
        let count;
        let groupedCountData = [];
        for (let countType of bookingShiftIssueType.getCountTypes()) {
          count = 0;
          for (let data of countData) {
            if (countType.types.includes(data.type)) {
              count += data.count;
            }
          }
          groupedCountData.push({ label: countType.label, count: count })
        }
        this.countData = groupedCountData;
      });
    }

    isDisabledLoading() {
      return this.isLoading || this.endedLoading;
    }

    findDShiftIssue = function(shiftId, issueTime) {
      let foundDShiftIssue;
      foundDShiftIssue = this.urgentIssues.find((urgentIssue) => {
        return urgentIssue.shiftId === shiftId && !urgentIssue.isRemoving && urgentIssue.issueTime === issueTime;
      });
      if (!foundDShiftIssue) {
        foundDShiftIssue = this.upcomingIssues.find((upcomingIssue) => {
          return upcomingIssue.shiftId === shiftId && !upcomingIssue.isRemoving && upcomingIssue.issueTime === issueTime;
        });
      }
      return foundDShiftIssue;
    };

    findDShiftIssues = function(shiftId) {
      return this.urgentIssues.filter((urgentIssue) => {
        return urgentIssue.shiftId === shiftId && !urgentIssue.isRemoving;
      }).concat(this.upcomingIssues.filter((upcomingIssue) => {
        return upcomingIssue.shiftId === shiftId && !upcomingIssue.isRemoving;
      }));
    };

    _initSearchParams() {
      this.searchParams = $location.search();
      if (!this.searchParams.from || !this.searchParams.to) {
        this.resetDates();
      }
    }

    _loadIssues() {
      $location.search(this.searchParams);
      this.isLoading = true;
      this.loadCounts();
      BookingShiftIssue.query(_.merge({ page: this.page }, this.searchParams)).then((shiftIssues) => {
        this._initShiftIssues(shiftIssues);
        this.isLoading = false;
        if (shiftIssues.length === 0) {
          this.endedLoading = true;
        }
      });
    }

    _resetIssues() {
      this.urgentIssues = [];
      this.upcomingIssues = [];
      this.shiftIds = [];
      this.page = 1;
      this.endedLoading = false;
    }

    _initShiftIssues(shiftIssues) {
      const now = moment();
      shiftIssues.forEach((shiftIssue) => {
        this._addShiftIssue(shiftIssue, true, now);
      });
    };

    _addShiftIssue(shiftIssue, insertAtEnd, now = moment()) {
      const foundDShiftIssue = this.findDShiftIssue(shiftIssue.shift.id, shiftIssue.issueTime);
      if (foundDShiftIssue) {
        const additionalIssue = this._initAdditionalIssue(shiftIssue);
        if (!insertAtEnd) {
          foundDShiftIssue.isNew = true;
        }
        foundDShiftIssue.shiftIssues.push(additionalIssue);
      } else {
        const transformedShiftIssue = this._transformShiftIssue(shiftIssue);
        this._buildMomentTime(transformedShiftIssue, TIME_SHIFT_ISSUE_TIME);
        this._initShiftIssuesProps(transformedShiftIssue.shiftIssues[0]);
        this._initShiftDecoratorProps(transformedShiftIssue);
        const issues = now.isSameOrAfter(transformedShiftIssue.issueTimeMoment) ? this.urgentIssues : this.upcomingIssues;
        if (insertAtEnd) {
          issues.push(transformedShiftIssue);
        } else {
          this._addIssueAtCorrectPosition(issues, transformedShiftIssue);
        }
        this.shiftIds.push(transformedShiftIssue.shiftId);
      }
    };

    _addIssueAtCorrectPosition(issues, transformedShiftIssue) {
      let isIssueAdded = false;
      for (let issueIndex in issues) {
        const issue = issues[issueIndex];
        if (issue.issueTimeMoment.isAfter(transformedShiftIssue.issueTimeMoment)) {
          issues.splice(issueIndex, 0, transformedShiftIssue);
          isIssueAdded = true;
          break;
        }
      }
      if (!isIssueAdded) {
        issues.push(transformedShiftIssue);
      }
    }

    _updateShiftIssuesForShift(shiftId, shiftIssues) {
      const foundDShiftIssues = this.findDShiftIssues(shiftId);
      foundDShiftIssues.forEach((foundDShiftIssue) => {
        foundDShiftIssue.shift = shiftIssues[0].shift;
        foundDShiftIssue.issueTime = shiftIssues[0].issueTime;
        this._buildMomentTime(foundDShiftIssue, 'issue');
        this._initShiftDecoratorProps(foundDShiftIssue);
        // todo change urgent if time was changed
        let updateIssueIds = [];
        shiftIssues.forEach((shiftIssue) => {
          const additionalIssue = this._initAdditionalIssue(shiftIssue);
          if (foundDShiftIssue.issueTime === shiftIssue.issueTime) {
            const existingIssueIndex = foundDShiftIssue.shiftIssues.findIndex(function(existingIssue) {
              return existingIssue.id === shiftIssue.id;
            });
            if (existingIssueIndex === -1) {
              foundDShiftIssue.shiftIssues.push(additionalIssue);
            } else {
              foundDShiftIssue.shiftIssues[existingIssueIndex] = additionalIssue;
            }
          }
          updateIssueIds.push(shiftIssue.id);
        });
        _.remove(foundDShiftIssue.shiftIssues, function(existingIssue) {
          return !updateIssueIds.includes(existingIssue.id);
        });
      });
    }

    _transformShiftIssue(shiftIssue) {
      const tShiftIssue = {};
      tShiftIssue.shiftIssues = [{}];
      _.each(shiftIssue, (value, key) => {
        if (ISSUE_FIELDS.includes(key)) {
          tShiftIssue.shiftIssues[0][key] = value;
        } else {
          tShiftIssue[key] = shiftIssue[key];
        }
      });
      return tShiftIssue;
    };

    _initAdditionalIssue = function(shiftIssue) {
      const additionalIssue = {};
      _.each(shiftIssue, (value, key) => {
        if (ISSUE_FIELDS.includes(key)) {
          additionalIssue[key] = value;
        }
      });
      this._initShiftIssuesProps(additionalIssue);
      return additionalIssue;
    };

    _initShiftIssuesProps = function(shiftIssue) {
      shiftIssue.typeText = bookingShiftIssueType.getName(shiftIssue.type);
      shiftIssue.icon = bookingShiftIssueType.getIcon(shiftIssue.type);
    };

    _initShiftDecoratorProps = function(shiftIssue) {
      shiftIssue.shiftId = shiftIssue.shift.id;
      this._buildMomentTime(shiftIssue.shift, TIME_SHIFT_START);
      this._buildMomentTime(shiftIssue.shift, TIME_SHIFT_END);
      this._buildFormattedTime(shiftIssue.shift, TIME_SHIFT_START);
      this._buildFormattedTime(shiftIssue.shift, TIME_SHIFT_END);
      shiftIssue.issueTimeRelative = shiftIssue.issueTimeMoment.fromNow();
    };

    _performRemove = function(shiftIssue) {
      const shiftId = shiftIssue.shift_id || shiftIssue.shiftId;
      this.shiftSelector.multipleDeselectShift(shiftIssue);
      shiftIssue.isRemoving = true;
      _.pull(this.shiftIds, shiftId);
      $timeout(() => {
        _.remove(this.urgentIssues, ['isRemoving', true]);
        _.remove(this.upcomingIssues, ['isRemoving', true]);
      }, ANIMATION_DURATION_DELETE);
    };

    _buildMomentTime = function(object, field) {
      object[`${field}TimeMoment`] = moment(object[`${field}Time`], dateService.getIsoFullDateFormat());
    };

    _buildFormattedTime = function(object, field) {
      object[`${field}TimeFormatted`] = object[`${field}TimeMoment`].format(dateService.getWesternFullDateFormatYearless());
    };

  }

};

angular.module('public.security-manager.schedule-manager.timeline')
  .factory('LiveFeedLoader', dependencies.concat(LiveFeedLoader));
