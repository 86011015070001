const gccsReportsMissingDataItem = {
  controller: 'ReportsMissingDataItemController',
  controllerAs: 'reportsMissingDataItemCtrl',
  template: require('./template.pug'),
  bindings: {
    data: '<',
  }
};

angular.module('public.security-manager.reports.hr')
  .component('gccsReportsMissingDataItem', gccsReportsMissingDataItem);
