/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsPlanningTutorial = {
  template: require('./template.pug'),
  bindings: {
    planningInteractor: '<',
    tutorialService: '<'
  }
};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .component('gccsPlanningTutorial', gccsPlanningTutorial);
