/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'SecurityProviderSetting', 'currentUserService', 'notificationService',
  'redirectService', 'validationService'
];

const SettingsEmailController = function(
  $scope, SecurityProviderSetting, currentUserService, notificationService,
  redirectService, validationService
) {

  const vm = this;

  vm.signatureEmailHTML = '';
  vm.draggedImage = null;
  vm.validationService = validationService;

  vm.$onInit = function() {
    initSecurityManager();
    loadSettings();
  };

  vm.onImagePaste = function() {
    if (vm.draggedImage && isPresent(vm.signatureEmailHTML, 'img')) {
      return getBase64(vm.draggedImage).then(data => vm.signatureEmailHTML += `<img src='${data}'>`);
    }
  };

  vm.setEmailSignature = function() {
    vm.securityManager.emailSignature = vm.signatureEmailHTML;
    return vm.securityManager.save().then(() => {
      return notificationService.notifySuccess('Email signature was successfully updated!');
    });
  };

  vm.redirectToMain = () => redirectService.redirectToMain();

  vm.copySettings = function() {
    vm.settingsCopy = angular.copy(vm.settings);
  };

  vm.saveSettings = function() {
    vm.settingsCopy.save().then(function(settings) {
        vm.settings = settings;
        return vm.copySettings();
      }).catch(function (error) {
      Rollbar.log(`Email settings update on ${window.location.href}`, error);
      return vm.copySettings();
    })
  };

  const initSecurityManager = function() {
    vm.securityManager = currentUserService.getCurrentProfile();
    return initEmailSignature();
  };

  const initEmailSignature = function() {
    vm.signatureEmailHTML = vm.securityManager.emailSignature;
  };

  const loadSettings = function() {
    SecurityProviderSetting.getCurrent().then(function (settings) {
      vm.settings = settings;
      vm.copySettings();
    })
  };

  const getBase64 = function(file) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      return reader.onerror = error => reject(error);
    });
  };

  const isPresent = (collection, element) => collection.indexOf(element) === -1;

  return vm;
};

angular.module('public.security-manager.settings')
  .controller('SettingsEmailController', dependencies.concat(SettingsEmailController));
