/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.note').directive('gccsPopupNoteForm', ['popupDirectiveConfig', popupDirectiveConfig =>
  popupDirectiveConfig.configure({
    bodyTemplate: require('./template.pug'),
    scope: {
      note: '<',
      onRemoveCb: '&'
    }
  })
]);
