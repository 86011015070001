const dependencies = ['employeeActivityService', 'User', 'clipboardService', 'notificationService'];

const PopupInviteEmployeeController = function(employeeActivityService, User, clipboardService, notificationService) {

  const vm = this;

  vm.$onInit = function() {
    initInvitationLink();
  };

  vm.onSendInvite = function() {
    employeeActivityService.resendInvite(vm.employee, () => {
      vm.gccsPopupsLayout.closePopup();
    });
  };

  vm.copyInvitationLink = function() {
    clipboardService.copyToClipboard(vm.employeeInvitationLink, () => {
      notificationService.notifyInfo('Link copied to clipboard');
    });
  };

  const initInvitationLink = function() {
    new User({ id: vm.employee.userId }).getInvitationLink().then(function(data) {
      vm.employeeInvitationLink = data.invitationLink;
    });
  };

  return vm;

};

angular.module('shared-components.popup.employee')
  .controller('PopupInviteEmployeeController', dependencies.concat(PopupInviteEmployeeController));
