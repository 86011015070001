/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.repeatable-booking').service('repeatableBookingInvoicePeriodType', function() {

  const TYPE_WEEKLY = 1;
  const TYPE_MONTHLY = 2;

  const typeNames = {
    [TYPE_WEEKLY]: 'Week',
    [TYPE_MONTHLY]: 'Month'
  };

  return {
    getTypeNames() {
      return typeNames;
    },

    getTypeName(type) {
      return typeNames[type];
    },

    getWeeklyType() {
      return TYPE_WEEKLY;
    },

    getMonthlyType() {
      return TYPE_MONTHLY;
    },

    isWeeklyType(type) {
      return type === TYPE_WEEKLY;
    }
  };
});
