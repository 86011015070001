/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').factory('BookingShiftComment', ['BookingComment', (BookingComment) => {

  class BookingShiftComment extends BookingComment {
    static initClass() {
      this.configure({url : '/bookings/{{bookingId}}/shifts/{{shiftId}}/comments/{{id}}'});
    }
  }

  BookingShiftComment.initClass();
  return BookingShiftComment;
}

]);
