/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = [
  '$scope', 'Employee', 'Client', 'ExtendedFilterEmailService', 'employeeStatus'
];

const PopupEmailEmployeeFormFilterController = function(
  $scope, Employee, Client, ExtendedFilterEmailService, employeeStatus
) {

  const vm = this;

  vm.$onInit = function() {
    vm.filteredEmployees = [];
    vm.selectedEmployees = [];
    initSelectedEmployees();
    Client.filterCategories().then(filterData => {
      return vm.service = new ExtendedFilterEmailService(filterData, function (queryParams) {
        return loadAndSetEmployees(queryParams.queryParams);
      }, true);
    });
  };

  vm.toggleSelectedOnly = function() {
    vm.selectedOnly = !vm.selectedOnly;
    return vm.filterEmployees();
  };

  vm.confirm = function() {
    vm.onConfirmCb({ selectedEmployees: vm.selectedEmployees });
    return vm.closeFilter();
  };

  vm.employeeToggled = function() {
    setSelectedEmployees();
    return vm.filterEmployees();
  };

  vm.notAllSelected = () => {
    return (vm.filteredEmployees.length === 0) || _.some(vm.filteredEmployees, employee => !employee.isSelected);
  };

  vm.allSelected = () => !vm.notAllSelected();

  vm.selectAll = function() {
    for (let employee of Array.from(vm.filteredEmployees)) {
      employee.isSelected = true;
    }
    return vm.selectedEmployees = _.clone(vm.filteredEmployees);
  };

  vm.deselectAll = function() {
    for (let employee of Array.from(vm.filteredEmployees)) {
      employee.isSelected = false;
    }
    return vm.selectedEmployees = [];
  };

  vm.filterEmployees = () => {
    return vm.filteredEmployees = _.filter(vm.employees, employee => {
      return (!vm.filterString || employee.searchString.search(new RegExp(vm.filterString, 'i')) !== -1) &&
        (vm.selectedOnly ? employee.isSelected : true);
      }
    );
  };

  const initSelectedEmployees = function() {
    loadAndSetEmployees({}).then(() => {
      const result = [];
      for (let employee of Array.from(vm.preselectedEmployees)) {
        const found = _.find(vm.employees, e => e.id === employee.id);
        if (found) {
          found.isSelected = true;
          result.push(vm.selectedEmployees.push(found));
        } else {
          result.push(undefined);
        }
      }
      return result;
    });
  };

  const setSelectedEmployees = function() {
    const selected = [];
    const deselected = [];
    for (let employee of Array.from(vm.filteredEmployees)) {
      if (employee.isSelected) {
        selected.push(employee);
      } else {
        deselected.push(employee);
      }
    }
    _.pullAllBy(vm.selectedEmployees, deselected, 'id');
    return vm.selectedEmployees = _.unionBy(vm.selectedEmployees, selected, 'id');
  };

  const loadAndSetEmployees = queryParams => {
    return Employee.query(
      _.assign({
        status: employeeStatus.getActiveStatus(),
        template: 'planning'
      }, queryParams)
    ).then(setLoadedEmployees);
  };

  const setLoadedEmployees = function(employees) {
    vm.employees = employees;
    for (let employee of Array.from(vm.selectedEmployees)) {
      const found = _.find(vm.employees, e => e.id === employee.id);
      if (found) { found.isSelected = true; }
    }
    return vm.filterEmployees();
  };

  return vm;

};

angular.module('popup.email')
  .controller('PopupEmailEmployeeFormFilterController', dependencies.concat(PopupEmailEmployeeFormFilterController));
