/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('shared').service('landingUiInteractor', function() {

  const svc = this;

  svc.isMobileMenuOpened = false;
  svc.isShownPrivacyPolicy = false;

  svc.toggleMobileMenu = () => svc.isMobileMenuOpened = !svc.isMobileMenuOpened;

  svc.closeMobileMenu = () => svc.isMobileMenuOpened = false;

  svc.showPrivacyPolicy = () => svc.isShownPrivacyPolicy = true;

  svc.closePrivacyPolicy = () => svc.isShownPrivacyPolicy = false;

  return svc;
});
