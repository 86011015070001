/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').factory('BookingChange', ['AppModel', (AppModel) => {

  class BookingChange extends AppModel {
    static initClass() {
      this.configure({url : '/bookings/{{bookingId}}/changes/{{id}}', name : 'change'});
    }
  }

  BookingChange.initClass();
  return BookingChange;
}

]);
