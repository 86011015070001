const dependencies = ['AppModel'];

const SecurityManagerNotification = AppModel => {

  class SecurityManagerNotification extends AppModel {
    static initClass() {
      this.configure({
        url: '/security_managers/{{securityManagerId}}/notifications/{{id}}',
        name: 'security_manager_notification'
      });
    }

    static getNotifications(securityManagerId, params) {
      return this.query(params, {securityManagerId});
    }

    static getCount(securityManagerId, params) {
      return this.$get(this.resourceUrl({ securityManagerId }, 'count'), params);
    }

    static getStatusCount(securityManagerId) {
      return this.$get(this.resourceUrl({ securityManagerId }, 'status_count'));
    }

    static markRead(securityManagerId, id) {
      return this._postUrl(securityManagerId, id, 'mark_read');
    }

    static markSeen(securityManagerId, id) {
      return this._postUrl(securityManagerId, id, 'mark_seen');
    }

    static markReadByTypes(securityManagerId, params = {}) {
      return this.$put(this.resourceUrl({ securityManagerId }, 'mark_read_by_types'), params);
    }

    static markUnread(securityManagerId, id) {
      return this._postUrl(securityManagerId, id, 'mark_unread');
    }

    static approveDayOff(securityManagerId, id) {
      return this._postUrl(securityManagerId, id, 'approve_day_off');
    }

    static rejectDayOff(securityManagerId, id) {
      return this._postUrl(securityManagerId, id, 'reject_day_off');
    }

    static approveSickDay(securityManagerId, id) {
      return this._postUrl(securityManagerId, id, 'approve_sick_day');
    }

    static rejectSickDay(securityManagerId, id) {
      return this._postUrl(securityManagerId, id, 'reject_sick_day');
    }

    static approveShiftCancellation(securityManagerId, id) {
      return this._postUrl(securityManagerId, id, 'approve_shift_cancellation');
    }

    static rejectShiftCancellation(securityManagerId, id) {
      return this._postUrl(securityManagerId, id, 'reject_shift_cancellation');
    }

    static _postUrl(securityManagerId, id, url, params = {}) {
      return this.$post(
        new SecurityManagerNotification({
          id,
          securityManagerId
        }).$url(url), params
      );
    }

  }

  SecurityManagerNotification.initClass();

  return SecurityManagerNotification;

};

angular.module('data.security-manager')
  .factory('SecurityManagerNotification', dependencies.concat(SecurityManagerNotification));
