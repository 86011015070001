/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').directive('gccsSelectTransformer', function () {
  return {
    restrict: 'A',
    scope: {
      editable: '<'
    },
    link($scope, $element) {
      setTimeout(function () {

        if ($scope.editable === false) {
          $element.addClass('mod-disable-hover');
          return;
        }

        const element = $element[0];
        const selectHolder = element.querySelector('.input-holder');
        const selected = selectHolder.querySelector('.dropdown-current');
        const selectPreview = element.children[0];
        const $form = angular.element(element.closest('form[name]'));
        const formController = $form.data().$formController;

        const hideSelect = function () {
          selectPreview.classList.remove('hide');
          selectHolder.classList.add('hide');
          formController.$setPristine();
          formController.$setUntouched();
        };

        selectPreview.addEventListener('click', function () {
          selectPreview.classList.add('hide');
          selectHolder.classList.remove('hide');
          setTimeout(() => selected.click(), 0);
        });

        selectHolder.querySelector('.dropdown-list').addEventListener('click', function () {
          formController.$setSubmitted();
          $scope.$eval($form.attr('ng-submit'));
          hideSelect();
        });

        const onBodyClick = function (e) {
          if (!e.target.closest('[gccs-select-transformer]') && selectPreview.classList.contains('hide')) {
            hideSelect();
          }
        };

        document.body.addEventListener('click', onBodyClick);

        return $scope.$on('$destroy', function () {
          return document.body.removeEventListener('click', onBodyClick);
        });
      }, 0);
    }
  }
});
