/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [];

const timelineBookingGrouper = function() {

  const groupDBookingRows = function(dClient) {
    const groupedDBookingRows = [];
    for (let dBooking of dClient.dBookings) {
      if (!dBooking.visible) { continue; }
      let added = false;
      for (let groupedDBookingRow of groupedDBookingRows) {
        if (
          groupedDBookingRow.repeatableRowFor === dBooking.repeatedFromId &&
          bookingFitsInto(dBooking, groupedDBookingRow.dBookings)
        ) {
          added = true;
          sortedBookingAdd(groupedDBookingRow, dBooking);
          groupedDBookingRow.maxRowsCount = Math.max(groupedDBookingRow.maxRowsCount, dBooking.shiftRows.length);
          break;
        }
      }
      if (!added) {
        groupedDBookingRows.push({
          maxRowsCount: dBooking.shiftRows.length,
          collapsed: false,
          repeatableRowFor: dBooking.repeatedFromId,
          dBookings: [dBooking]
        });
      }
    }
    return groupedDBookingRows;
  };

  const bookingFitsInto = function(fitBooking, dBookings) {
    for (let dBooking of dBookings) {
      if (
        !(fitBooking.endTime.diff(dBooking.startTime) <= 0) &&
        !(fitBooking.startTime.diff(dBooking.endTime) >= 0)
      ) { return false; }
    }
    return true;
  };

  const sortedBookingAdd = function(groupedDBookingRow, dBooking) {
    let index = 0;
    const result = [];
    while (index <= groupedDBookingRow.dBookings.length) {
      if (
        isAfterPreviousBooking(groupedDBookingRow.dBookings[index - 1], dBooking) &&
        isBeforeNextBooking(groupedDBookingRow.dBookings[index], dBooking)
      ) {
        groupedDBookingRow.dBookings.splice(index, 0, dBooking);
        break;
      }
      result.push(index++);
    }
    return result;
  };

  const isAfterPreviousBooking = (previousBooking, dBooking) => {
    return (previousBooking === undefined) || (previousBooking.endTime.diff(dBooking.startTime) <= 0);
  };

  const isBeforeNextBooking = (nextBooking, dBooking) => {
    return (nextBooking === undefined) || (dBooking.endTime.diff(nextBooking.startTime) <= 0);
  };

  return {

    groupClientBookings(dClient) {
      const groupedDBookingRows = groupDBookingRows(dClient);
      let previousRowsCount = 0;
      for (let groupedDBookingRow of groupedDBookingRows) {
        groupedDBookingRow.previousRowsCount = previousRowsCount;
        previousRowsCount += groupedDBookingRow.maxRowsCount;
      }
      return groupedDBookingRows;
    }

  };

};

angular.module('public.timeline').service('timelineBookingGrouper', dependencies.concat(timelineBookingGrouper));
