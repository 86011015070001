const dependencies = [
  '$scope', '$q', 'pluralizeService', 'validationService', 'SecurityProviderBillingRecipient'
];

const PopupBillingHistoryRecipientsController = function(
  $scope, $q, pluralizeService, validationService, SecurityProviderBillingRecipient
) {

  const vm = this;

  vm.newRecipient = '';
  vm.recipients = [];
  vm.validationService = validationService;

  const errors = {
    required: 'Please fill out this field',
    pattern: 'Wrong format',
    minlength: 'Minimum length is 5',
    maxlength: 'Maximum length is 255',
    email: 'Please enter a valid email address'
  };

  let isFormSubmitted = false;

  vm.onRecipientAdd = function() {
    if ($scope.BillingInvoiceRecipients.$invalid || isNotUniqueEmail()) { return; }
    vm.recipients.push(vm.newRecipient.toLowerCase());
    vm.newRecipient = '';
    resetForm();
  };

  vm.onDeleteRecipient = function(index) {
    vm.recipients.splice(index, 1);
  };

  vm.onRecipientsSubmit = function() {
    if (isFormSubmitted) { return; }
    isFormSubmitted = true;
    SecurityProviderBillingRecipient.batchSave(vm.recipients).then((recipients) => {
      resetForm();
      $scope.$parent.$parent.popupsLayoutCtrl.closePopup({
        callbackData: {
          recipients: recipients
        }
      });
    });
  };

  vm.getRecipientsCountText = function() {
    return pluralizeService.simple(vm.recipients.length, 'recipient');
  };

  vm.getErrorMsgText = function(field) {
    if (
      !$scope.BillingInvoiceRecipients.$submitted ||
      $scope.BillingInvoiceRecipients.$valid ||
      $scope.BillingInvoiceRecipients.$pristine
    ) { return; }
    const formField = $scope.BillingInvoiceRecipients[field];
    if (formField) {
      if (formField.$valid) {
        return '';
      } else {
        return errors[_.first(_.keys(formField.$error))] || 'Invalid';
      }
    }
  };

  const resetForm = function() {
    isFormSubmitted = false;
    $scope.BillingInvoiceRecipients.$setPristine();
    $scope.BillingInvoiceRecipients.$setUntouched();
  };

  const isNotUniqueEmail = function() {
    return _.includes(vm.recipients, vm.newRecipient.toLowerCase());
  };

  const initRecipients = function() {
    vm.recipients = _.map(vm.billingRecipients, 'email');
  };

  $scope.$on('popup.open', function() {
    if ($scope.$parent.$parent.popupsLayoutCtrl.popupShowCondition) {
      initRecipients();
    }
  });

  return vm;

};

angular.module('public.security-manager.settings')
  .controller('PopupBillingHistoryRecipientsController',
    dependencies.concat(PopupBillingHistoryRecipientsController));
