/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$location', '$state', '$filter', '$timeout', 'ClientInvoice', 'Client', 'FilterParamsService',
  'clientStatus', 'selectService', 'invoiceStatus', 'dateService', 'invoiceReportExportType', 'chartService',
];

const ReportsFinanceIncomeController = function(
  $scope, $location, $state, $filter, $timeout, ClientInvoice, Client, FilterParamsService,
  clientStatus, selectService, invoiceStatus, dateService, invoiceReportExportType, chartService,
) {

  const vm = this;

  const DEFAULT_TABLE_ITEMS_COUNT = 20;
  const DEFAULT_LEGEND_ITEMS_COUNT = 10;

  const filterParamsService = new FilterParamsService({
    filter : ['from', 'to', 'status', 'clientId']
  });

  let isEmptyChart = false;
  let tablePage = 1;
  let chartDateFormatter = '%Y-%m-%d';

  vm.filterParams = filterParamsService.getParams();
  vm.invoiceStatus = invoiceStatus;
  vm.invoiceReportExportType = invoiceReportExportType;

  vm.isAnalyticsDataLoaded = false;
  vm.isShownLoadMoreButton = false;
  vm.isOpenedExportDropdown = false;
  vm.isShownLegendMoreButton = false;

  vm.$onInit = function() {
    initDates();
    loadClients();
    loadChart(true);
    updateChartDateFormatter();
  };

  vm.updateChartData = function() {
    tablePage = 1;
    setLoadingState();
    loadChart().then(() => setLoadingFinishedState());
    return loadTable();
  };

  vm.getExportUrl = function(exportType) {
    // type : 'standard', 'kashflow', 'sage'
    // params : 'from', 'to', 'status', 'client_id'
    const urlParams = getUrlParams();
    urlParams.type = vm.invoiceReportExportType.getStringType(+exportType);
    return ClientInvoice.exportUrl(urlParams);
  };

  vm.onClientSelect = client => vm.filterParams.filter.clientId = client.id;

  vm.isDonutChartShown = () => {
    return $state.is('public.security-manager.reports.finance.income');
  };

  vm.isGraphChartShown = () => {
    return $state.is('public.security-manager.reports.finance.daily-income');
  };

  vm.toggleTableItem = item => item.isOpenedItem = !item.isOpenedItem;

  vm.getFilteredPeriod = () => `${moment(vm.endDateFrom, dateService.getDateFormat()).format('DD MMM YYYY')} – ${moment(vm.endDateTo, dateService.getDateFormat()).format('DD MMM YYYY')}`;

  vm.getFormattedInvoicedDate = date => `${moment(date, dateService.getIsoDateFormat()).format('DD MMM YYYY')}`;

  vm.loadMoreTableItems = function() {
    tablePage++;
    return loadTable();
  };

  vm.toggleExportDropdown = () => vm.isOpenedExportDropdown = !vm.isOpenedExportDropdown;

  vm.closeExportDropdown = () => vm.isOpenedExportDropdown = false;

  vm.showRestLegendItems = function() {
    const legendList = document.querySelectorAll('.pie-chart-list .pie-chart-item');
    const listItemDisplayStyle = getComputedStyle(legendList[0]).display;
    _.each(legendList, item => item.style.display = listItemDisplayStyle);
    return vm.isShownLegendMoreButton = false;
  };

  vm.getInvoiceVat = function(version) {
    if (version.withVat) {
      return $filter('formatPriceFull')(version.totalVat);
    } else {
      return '-';
    }
  };

  const setLoadingState = () => vm.isAnalyticsDataLoaded = false;

  const setLoadingFinishedState = () => vm.isAnalyticsDataLoaded = true;

  const updateChartDateFormatter = function(byMonth) {
    const format = byMonth ? '%b %y' : '%Y-%m-%d';
    return chartDateFormatter = d3.timeFormat(format);
  };

  const pieSliceMouseOverHandler = function(obj) {
    const foundLegendItem = document.querySelector(`.pie-chart-list [data-id='${obj.id}']`);
    const showMoreButton = document.querySelector('.pie-chart-btn-more');
    if (foundLegendItem && (getComputedStyle(foundLegendItem).display === 'none')) {
      return (showMoreButton != null ? showMoreButton.classList.add('active') : undefined);
    } else {
      return (foundLegendItem != null ? foundLegendItem.classList.add('active') : undefined);
    }
  };

  const pieSliceMouseOutHandler = function(obj) {
    __guard__(document.querySelector('.pie-chart-btn-more'), x => x.classList.remove('active'));
    return __guard__(document.querySelector(`.pie-chart-list [data-id='${obj.id}']`), x1 => x1.classList.remove('active'));
  };

  const pieSliceOnClickHandler = function(obj) {
    let foundClient;
    _.each(vm.clients, client => {
      foundClient = _.find(client.items, ['text', obj.id]);
      if (foundClient) { return false; }
    });
    if (foundClient) {
      vm.filterParams.filter.clientId = foundClient.id;
      vm.selectedClient = getSelectedClient();
    }
  };

  const getLegendHTML = function(index, data, chart, listItem) {
    initCompactLegend(index, listItem);
    const { id } = data[index];
    return `\
<span class="pie-chart-item-title" title="${id}">
  ${id}
  <i class="pie-chart-item-ico" style="background-color: ${chart.color(id)}"></i>
</span>
<span class="pie-chart-item-amount">${$filter('formatPriceFull')(+chart.data.values(id))}</span>
<span class="pie-chart-item-part">${((chart.data.values(id) / getTotalAmount(data)) * 100).toFixed(1)}%</span>\
`;
  };

  const initCompactLegend = function(index, listItem) {
    if (index >= DEFAULT_LEGEND_ITEMS_COUNT) {
      listItem.style.display = 'none';
      return $timeout(() => vm.isShownLegendMoreButton = true);
    } else {
      return vm.isShownLegendMoreButton = false;
    }
  };

  const getChartConfig = function(data) {
    if (vm.isDonutChartShown()) {
      return {
        bindto : '#chart',
        data : {
          columns : data,
          type : 'donut',
          onmouseover : pieSliceMouseOverHandler,
          onmouseout : pieSliceMouseOutHandler,
          onclick : pieSliceOnClickHandler
        },
        color : {
          pattern : [
            '#464248', '#6391d4', '#f7e234', '#f7a281', '#b62bd3', '#8231e4', '#fb8d3b', '#f4bf2e', '#a1d8f7', '#dc2bda',
            '#f2425d', '#eaf551', '#d2d090', '#c43631', '#9d9ea3', '#a8cd3e', '#77c658', '#9c5e31', '#ee99cf', '#fa4e45'
          ]
        },
        legend : {
          show : false
        },
        tooltip : {
          show : false
        },
        donut : {
          label : {
            show : false
          },
          title : ''
        },
        onrendered() {
          return setTimeout(function() {
              initTitle(vm.chart.data());
              return initLegend(vm.chart.data(), vm.chart);
            }
            , 0);
        }
      };
    } else {
      return {
        bindto : '#chart',
        data : {
          x : 'x',
          columns : data,
          type: 'bar',
          groups : [
            _.values(invoiceStatus.getNames())
          ],
          colors : {
            Created : '#FF4743',
            Sent : '#F5A623',
            Paid : '#00B05A',
            Pending : '#91969c'
          }
        },
        grid : {
          y : {
            show : true
          }
        },
        axis : {
          x : {
            type : 'timeseries',
            tick : {
              format(x) {
                return chartDateFormatter(x);
              }
            }
          },
          y : {
            min : 0,
            padding : {
              bottom : 0
            },
            label : {
              text : 'Income (£)',
              position: 'outer-middle'
            },
            tick : {
              outer : false
            }
          }
        },
        tooltip : {
          format : {
            value(value) {
              return `${$filter('formatPriceFull')(value)}`;
            }
          }
        }
      };
    }
  };

  const initChart = data => c3.generate(getChartConfig(data));

  const updateChart = function(data, chart) {
    if (!chart) { return; }
    const itemsToRemove = [];
    _.each(chart.data(), function(chartItem) {
      const foundItem = _.find(data, item => item[0] === chartItem.id);
      if (!foundItem) {
        itemsToRemove.push(chartItem.id);
      }
      return true;
    });
    return chart.load({
      columns : data,
      unload : itemsToRemove
    });
  };

  const initEmptyChart = () =>
    c3.generate({
      bindto : '#chart',
      data : {
        columns : [
          ['empty', 1]
        ],
        type : 'donut'
      },
      color : {
        pattern : ['#e6e6e6']
      },
      legend : {
        show : false
      },
      tooltip : {
        show : false
      },
      donut : {
        label : {
          show : false
        },
        title : ''
      },
      onrendered() {
        if (vm.chart) {
          initTitle(vm.chart.data(), true);
          return initEmptyLegend();
        }
      }
    })
  ;

  var initTitle = function(data, isEmpty) {
    if (isEmpty == null) { isEmpty = false; }
    const amountText = isEmpty ? '£ 0.00' : `${$filter('formatPriceFull')(getTotalAmount(data))}`;
    const node = d3
      .select('#chart .c3-chart-arcs-title')
      .style('transform', 'translate(0, -26px)')
      .node();
    if (node) {
      node.innerHTML = `\
<tspan class="pie-chart-info-text">Grand total calculated</tspan>
<tspan x="0" y="20" class="pie-chart-info-text">(excluding VAT)</tspan>
<tspan x="0" y="50" class="pie-chart-info-amount">${amountText}</tspan>\
`;
    }
  };

  var initEmptyLegend = () =>
    d3.select('.pie-chart-holder .pie-chart-data')
      .html(() => '').insert('div')
      .attr('class', 'msg-text')
      .html(() => 'There are no invoices for selected period')
  ;

  var initLegend = function(data, chart) {
    data = getSortedChartData(data);
    return d3.select('.pie-chart-holder .pie-chart-data')
      .html(() => '').insert('ul')
      .attr('class', 'pie-chart-list')
      .selectAll('li')
      .data(getChartKeys(data))
      .enter()
      .append('li')
      .attr('data-id', (id, index) => data[index].id)
      .attr('class', 'pie-chart-item')
      .each(function(id, index) {
        return d3.select(this).html(function() {
          return getLegendHTML(index, data, chart, this);
        });}).on('mouseover', (id, index) => chart.focus(data[index].id)).on('mouseout', id => chart.revert());
  };

  const initAverageDayIncome = function(income) {
    const totalIncome = _.reduce(income, function(prevTotalSum, currTotalSum) {
        const dailyAverage = _.reduce(currTotalSum, (prevDailySum, currentSum) => prevDailySum + currentSum
          , 0);
        return prevTotalSum + dailyAverage;
      }
      , 0);
    return vm.averageDayIncome = $filter('formatPriceFull')(totalIncome / Object.keys(income).length);
  };

  const getTotalAmount = data => {
    return _.reduce(data, (prev, curr) => prev + (curr.values != null ? curr.values[0].value : undefined), 0);
  };

  const getChartKeys = data => {
    return _.map(data, item => item.values[0].id);
  };

  const getSortedChartData = data => {
    return _.orderBy(data, item => item.values[0].value, ['desc']);
  };

  const getTableItems = data => data;

  const loadClients = () => {
    return Client.autocomplete({}, { 'order[name]': 'asc' }).then(function (clients) {
      vm.clients = selectService.toSelectGroupedArray(clients, getClientGroupsConfig());
      return vm.selectedClient = getSelectedClient();
    });
  };

  const getClientGroupsConfig = function() {
    return {
      defaultField: 'All',
      groups: [
        { id: -clientStatus.getActiveStatus(), name: clientStatus.getName(clientStatus.getActiveStatus()) },
        { id: -clientStatus.getInactiveStatus(), name: clientStatus.getName(clientStatus.getInactiveStatus()) }
      ],
      entityName: 'client',
      additionalText: '(A - Z)'
    };
  };

  const getSelectedClient = function() {
    return _.chain(vm.clients)
      .map('items')
      .flatten()
      .find((client => client.id === +vm.filterParams.filter.clientId))
      .value();
  };

  const loadChart = function(isInit) {
    let promise;
    if (isInit == null) { isInit = false; }
    const urlParams = getUrlParams();
    $location.search(urlParams);

    // type : 'client', 'date'
    // fields for type
    // client - 'from', 'to', 'status', 'client_id'
    if (vm.isDonutChartShown()) {
      promise = ClientInvoice.getIncome(_.extend({type : 'client'}, urlParams)).then(function(income) {
        if (income.length === 0) {
          isEmptyChart = true;
          return vm.chart = initEmptyChart();
        } else {
          if (isInit || isEmptyChart) {
            vm.chart = initChart(income);
            return isEmptyChart = false;
          } else {
            return updateChart(income, vm.chart);
          }
        }
      });
    } else {
      promise = ClientInvoice.getIncome(_.extend({type : 'date'}, urlParams)).then(function(income) {
        const incomeGraphData = chartService.getGraphFormatData(income, vm.endDateFrom, vm.endDateTo, invoiceStatus.getName, updateChartDateFormatter);
        if (isInit) {
          vm.chart = initChart(incomeGraphData);
        } else {
          updateChart(incomeGraphData, vm.chart);
        }
        return initAverageDayIncome(income);
      });
    }
    return promise;
  };

  const loadTable = function() {
    const urlParams = getUrlParams();
    $location.search(urlParams);

    // type : 'client', 'date'
    // fields for type
    // client - 'from', 'to', 'status', 'client_id'
    return ClientInvoice.getIncome(_.extend({type : 'table', page : tablePage}, urlParams)).then(function(data) {
      vm.isShownLoadMoreButton = data.length >= DEFAULT_TABLE_ITEMS_COUNT;
      if (tablePage === 1) {
        return vm.tableItems = getTableItems(data);
      } else {
        return vm.tableItems = _.concat(vm.tableItems, getTableItems(data));
      }
    });
  };

  const initDates = function() {
    const locationParams = $location.search();
    if (locationParams.from && locationParams.to) {
      vm.endDateFrom = moment(locationParams.from, dateService.getIsoDateFormat());
      return vm.endDateTo = moment(locationParams.to, dateService.getIsoDateFormat());
    } else {
      const today = dateService.today();
      vm.endDateFrom = today.clone().subtract(30, 'days');
      return vm.endDateTo = today.clone();
    }
  };

  const getUrlParams = function() {
    const urlParams = filterParamsService.getFilterUrlParams();
    if (vm.endDateTo && vm.endDateTo) {
      urlParams.from = moment(vm.endDateFrom, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
      urlParams.to = moment(vm.endDateTo, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
    }
    return urlParams;
  };

  $scope.$watch(() => vm.filterParams, _.debounce(vm.updateChartData, 200), true);

  return vm;

};

angular.module('public.security-manager.reports.finance')
  .controller('ReportsFinanceIncomeController', dependencies.concat(ReportsFinanceIncomeController));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
