const dependencies = ['bookingShiftCommentClockType', 'dateService'];

const bookingClockCommentPreparer = function(bookingShiftCommentClockType, dateService) {

  const prepareClockComments = function(clockComments) {
    _.each(clockComments, clockComment => {
      clockComment.typeText = bookingShiftCommentClockType.getName(clockComment.type);
      clockComment.text = getClockCommentText(clockComment);
      clockComment.visibleToStaff = false;
      clockComment.visibleToClient = false;
      clockComment.isClockComment = true;
    });
    return clockComments;
  };

  const getClockCommentText = function(clockComment) {
    let commentText = `${clockComment.employee.fullName}`;
    if (clockComment.oldClockTime) {
      commentText += ` from ${getFormattedFullDate(clockComment.oldClockTime)} to ${getFormattedFullDate(clockComment.clockTime)}`;
    } else if (clockComment.clockTime) {
      commentText += ` at ${getFormattedFullDate(clockComment.clockTime)}`;
    }
    if (bookingShiftCommentClockType.isTimeErrorType(clockComment.type)) {
      commentText += ` - ${getTimeErrorText(clockComment)}`;
    }
    if (clockComment.securityManager) {
      let createdAt = moment(clockComment.createdAt, dateService.getIsoFullDateFormatWithTz());
      commentText += ` by ${clockComment.securityManager.fullName} at ${createdAt.format(dateService.getFullDateFormatWithComma())}`;
    }
    return commentText;
  };

  const getFormattedFullDate = function(time) {
    return moment(time, dateService.getIsoFullDateFormat()).format(dateService.getFullDateFormatWithComma());
  };

  const getTimeErrorText = function(clockComment) {
    const clockTime = moment(clockComment.clockTime, dateService.getIsoFullDateFormat());
    const shiftTime = moment(clockComment.shiftEmployeeTime, dateService.getIsoFullDateFormatWithT());
    return dateService.formatTimeDifference(shiftTime, clockTime);
  };

  return {
    prepareClockComments
  };

};

angular.module('popup.booking').service('bookingClockCommentPreparer', dependencies.concat(bookingClockCommentPreparer));
