/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.client').factory('ClientService', ['AppModel', function(AppModel) {

  class ClientService extends AppModel {
    static initClass() {
      this.configure({
        url: '/clients/{{clientId}}/services/{{id}}',
        name: 'service',
      });
    }

    constructor() {
      super(...arguments);
      if (!this.resources) { this.resources = []; }
      if (!this.contacts) { this.contacts = []; }
    }

    static getWithPayableResources(clientId) {
      return this.$get(this.resourceUrl({ clientId }, 'with_payable_resources'));
    }

    isLocked() {
      return ClientService.$get(this.getUrl() + '/is_locked');
    }
    
  }

  ClientService.initClass();
  
  return ClientService;
  
}]);
