const dependencies = ['$scope', '$timeout', 'dateService', 'notificationService'];

const PopupEditPayRateController = function($scope, $timeout, dateService, notificationService) {

  const vm = this;

  vm.isShownDateOfChange = false;

  let isFormSubmitted = false;

  vm.closePopup = function() {
    vm.isShownDateOfChange = false;
    vm.gccsPopupsLayout.closePopup();
  };

  vm.saveRateChange = function() {
    if (isFormSubmitted || vm.isSaveButtonDisabled()) { return; }
    isFormSubmitted = true;
    vm.rateChangeCopy.save().then((rateChange) => {
      notificationService.notifySuccess('Updating rate');
      vm.isShownDateOfChange = false;
      vm.gccsPopupsLayout.closePopup({
        callbackData: {
          rateChange: rateChange
        }
      });
    });
  };

  vm.isSaveButtonDisabled = function() {
    return $scope.RateChangeForm.$invalid || !isPayRateChanged();
  };

  vm.onApplyToBookingsChange = function() {
    setMinDate();
  };

  const resetDateOfChange = function(isSubmiting) {
    vm.isShownDateOfChange = false;
    $timeout(() => {
      vm.isShownDateOfChange = true;
    });
    // TODO maybe find another solution
    $timeout(() => {
      if (isSubmiting) { $scope.RateChangeForm.$setSubmitted(); }
    }, 100);
  };

  const isPayRateChanged = function() {
    if (!vm.rateChangeCopy) { return; }
    return vm.rateChange.payRate !== vm.rateChangeCopy.payRate;
  };

  const copyRateChange = function() {
    vm.rateChangeCopy = angular.copy(vm.rateChange);
  };

  const setMinDate = function() {
    let isSubmitting = false;
    if (!vm.rateChangeCopy.applyToBookings) {
      vm.minDateOfChange = dateService.today();
      isSubmitting = true;
    } else {
      vm.minDateOfChange = undefined;
    }
    resetDateOfChange(isSubmitting);
  };

  $scope.$on('popup.open', function() {
    if (vm.gccsPopupsLayout.popupShowCondition) {
      copyRateChange();
      setMinDate();
      vm.isShownDateOfChange = true;
    }
  });

  return vm;

};

angular.module('shared-components.popup.employee')
  .controller('PopupEditPayRateController', dependencies.concat(PopupEditPayRateController));
