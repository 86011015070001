const dependencies = [
  '$scope', 'ClientService', 'uploadService', 'managerAccessLevel', 'userValidation',
  'User', 'ClientSite', 'ClientLocation'
];

const PopupClientManagerFormController = function(
  $scope, ClientService, uploadService, managerAccessLevel, userValidation,
  User, ClientSite, ClientLocation
) {

  const vm = this;

  vm.managerAccessLevel = managerAccessLevel;

  vm.loadServices = text => ClientService.query({ name : text }, { clientId : vm.manager.clientId });

  vm.loadSites = text => ClientSite.query({ name: text }, { clientId : vm.manager.clientId });

  vm.loadLocations = text => ClientLocation.query({ name: text }, { clientId : vm.manager.clientId });

  vm.getAccessLevelNames = () => managerAccessLevel.getNames();

  vm.getEmailErrors = () => userValidation.getEmailErrors(vm.manager.user && vm.manager.user.id);

  vm.onSiteAdd = function(site) {
    vm.manager.locations = _.unionBy(vm.manager.locations, site.locations, 'id');
  };

  const initManager = function() {
    vm.manager = angular.copy($scope.manager);
    if ($scope.manager.isNew()) {
      $scope.popupFormCtrl.title = 'Add new manager';
      $scope.popupFormCtrl.saveButtonText = 'Add manager';
    } else {
      $scope.popupFormCtrl.title = 'Edit manager';
      $scope.popupFormCtrl.saveButtonText = 'Save';
    }
  };

  const saveManager = manager => {
    $scope.popupCtrl.closePopup(() => $scope.popupCloseCb({ manager }));
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) { initManager(); }
  });

  $scope.$on('popup.submit', function() {
    let avatarUploaded = vm.manager.user.avatarUploaded;
    let user = new User(vm.manager.user);
    user.avatarUploaded = avatarUploaded;

    uploadService.saveWithFile(user, 'avatar', user => {
      vm.manager.user = user;
      vm.manager.save().then(saveManager);
    });
  });

  return vm;

};

angular.module('popup.client')
  .controller('PopupClientManagerFormController', dependencies.concat(PopupClientManagerFormController));
