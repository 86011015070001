angular.module('popup.booking').directive('gccsPopupBookingShiftTimelineEmployee', [
  'popupDirectiveConfig',
  popupDirectiveConfig =>
    popupDirectiveConfig.configure({
      bodyTemplate: require('./template.pug'),
      controllerAs: 'popupShiftTimelineEmployeeCtrl',
      controller: 'PopupBookingShiftTimelineEmployeeController',
      scope: {
        shiftDecorator: '=',
        booking: '=',
        employee: '=',
        position: '=',
        onFixIssueClickCb: '&',
        onEditClickCb: '&?',
        editable: '<'
      }
    })
]);
