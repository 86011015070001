const dependencies = ['$scope', 'dateService'];

const FormDatePickerController = function($scope, dateService) {

  const vm = this;

  vm.format = $scope.format || dateService.getDateFormat();

  if ($scope.dateModel) {
    vm.dateModel = moment($scope.dateModel, [dateService.getIsoDateFormat(), vm.format]);
  } else {
    vm.dateModel = dateService.today();
  }

  $scope.$watch(() => vm.dateModel, function() {
    if (vm.dateModel) {
      if (_.isString(vm.dateModel)) { vm.dateModel = moment(vm.dateModel, vm.format); }
      $scope.dateModel = vm.dateModel.format(vm.format);
    }
  }, true);

  return vm;

};

angular.module('form').controller('FormDatePickerController', dependencies.concat(FormDatePickerController));
