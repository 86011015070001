/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$state', 'ClientService', 'EntityManager', 'pluralizeService', 'resourceIcon', 'activeStatus'
];

const ClientShowServicesController = function(
  $scope, $state, ClientService, EntityManager, pluralizeService, resourceIcon, activeStatus
) {

  const vm = this;

  vm.pluralizeService = pluralizeService;
  vm.isShownServicePopup = false;
  vm.ascendingNameOrder = true;

  let entityManager = undefined;

  const setServiceIcons = services => {
    return Array.from(services).map((service) =>
      (() => {
        const result = [];
        for (let resource of Array.from(service.resources)) {
          if (!resource.iconClass) {
            result.push(resource.iconClass = resourceIcon.getIconClass(resource.icon));
          } else {
            result.push(undefined);
          }
        }
        return result;
      })());
  };

  const setServices = function(services) {
    services = _.sortBy(services, ['sortPosition']);
    setServiceIcons(services);
    vm.services = services;
  };

  const loadServices = () => {
    return entityManager.loadAll().then(services => setServices(services));
  };

  const updateSortCode = function(newPosition) {
    const movedServiceClone = vm.services[newPosition];
    const service = new ClientService(movedServiceClone);
    service.sortPosition = newPosition + 1;
    return service.save();
  };

  $scope.clientShowLayoutCtrl.loadClient().then(function(client) {
    entityManager = new EntityManager(ClientService, {
      params : {
        clientId : client.id
      },
      queryParams : {
        activeStatus : activeStatus.getActiveStatus()
      }
    });
    loadServices();
    return vm.client = client;
  });

  vm.removeService = service => {
    return entityManager.removeEntity(service, function () {
      vm.client.servicesCount--;
      return loadServices();
    });
  };

  vm.showServicePopup = function(service) {
    if (!$scope.clientShowLayoutCtrl.editable) { return; }
    const serviceParams = service ? service : { clientId: vm.client.id };
    $state.go('public.security-manager.client-service', { clientId: serviceParams.clientId, serviceId: serviceParams.id });
    // vm.popupService = service || entityManager.createEntity();
    // vm.isShownServicePopup = true;
  };

  vm.closeServicePopup = function(savedService) {
    vm.isShownServicePopup = false;
    if (savedService) {
      vm.client.servicesCount++;
      entityManager.updateEntity(savedService);
      return setServices(entityManager.entities);
    }
  };

  vm.elementMoved = function(index) {
    const movedService = vm.services[index];
    vm.services.splice(index, 1);
    const newPosition = _.findIndex(vm.services, service => {
      return (service.id === movedService.id) && (service !== movedService);
    });
    return updateSortCode(newPosition).then(loadServices);
  };

  return vm;

};

angular.module('public.security-manager.client.show')
  .controller('ClientShowServicesController', dependencies.concat(ClientShowServicesController));
