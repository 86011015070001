const dependencies = ['notificationService'];

const NotificationController = function(notificationService) {

  const vm = this;

  vm.notificationService = notificationService;

  return vm;
};

angular.module('gccs-ui').controller('NotificationController', dependencies.concat(NotificationController));
