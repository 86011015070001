/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'Booking', 'BookingShift', 'bookingSummaryService', 'calculationService'
];

const TimelineBookingFormSummaryController = function(
  $scope, Booking, BookingShift, bookingSummaryService, calculationService
) {

  const vm = this;

  vm.summary = [];

  $scope.timelineBookingFormLayoutCtrl.isLong = false;

  $scope.timelineBookingFormLayoutCtrl.reloadBooking().then(function(booking) {
    vm.purchaseNumber = booking.purchaseNumber;
    vm.totalHours = calculationService.round(booking.totalHours, 2);
    return BookingShift.query({}, { bookingId: $scope.timelineBookingFormLayoutCtrl.bookingId }).then(function(shifts) {
      const shiftLocations = [];
      _.each(shifts, function(shift) {
        if (shiftLocations.indexOf(shift.location.name) === -1) { shiftLocations.push(shift.location.name); }
        let shiftSummary = bookingSummaryService.getShiftSummary(shift);
        shiftSummary.isTogglable = true;
        vm.summary.push(shiftSummary);
      });
      return vm.summary.unshift(bookingSummaryService.getBookingSummary(booking, shiftLocations));
    });
  });

  Booking.totalCost($scope.timelineBookingFormLayoutCtrl.bookingId).then(data => vm.totalCost = data.totalCost);

  vm.getSaveButtonText = function() {
    if ($scope.timelineBookingFormLayoutCtrl.isTemplate) {
      return 'Save template';
    } else if ($scope.timelineBookingFormLayoutCtrl.isEdit) {
      return 'Save changes';
    } else {
      return 'Create booking';
    }
  };

  vm.pluralizeTotalHours = function() {
    if (vm.totalHours === 1) {
      return 'hour';
    } else {
      return 'hours';
    }
  };

  return vm;

};

angular
  .module('public.timeline')
  .controller('TimelineBookingFormSummaryController', dependencies.concat(TimelineBookingFormSummaryController));
