/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = [
  '$scope', 'SecurityProviderSetting', 'SecurityProviderDocument', 'Country', 'validationService',
  'uploadService', 'currentUserService', 'confirmService', 'securityManagerDocumentVisibility', 'selectService'
];

const SettingsCompanyDetailsController = function(
  $scope, SecurityProviderSetting, SecurityProviderDocument, Country, validationService,
  uploadService, currentUserService, confirmService, securityManagerDocumentVisibility, selectService
) {

  const vm = this;

  const FIELD_LOGO = 'logo';
  const FIELD_CARD_LOGO = 'cardLogo';
  const DEFAULT_COUNTRY = 'United Kingdom of Great Britain and Northern Ireland';

  const customErrorTexts = {
    email: { pattern: 'Enter a valid email' },
    registrationNumber: { pattern: 'Enter a valid number' },
    vatNumber: { pattern: 'Enter a valid number' },
    bankAccountNumber: { pattern: 'Enter a valid number' },
    ibanNumber: { pattern: 'Enter a valid number' },
    swiftNumber: { pattern: 'Enter a valid number' },
    sortCode: { mask: 'Enter a valid code' },
    phoneNumber: { mask: 'Enter a valid phone number' },
  };
  const isNotSupervisor = !currentUserService.getCurrentProfile().isSupervisor();
  const visibilityNames = securityManagerDocumentVisibility.getNames();

  vm.validationService = validationService;
  vm.userCanEdit = $scope.settingsLayoutCtrl.editable && (
    currentUserService.getCurrentProfile().isAccountOwner() ||
    currentUserService.getCurrentProfile().isAdmin()
  );
  vm.isShownManagers = isNotSupervisor;
  vm.canManageDocuments = $scope.settingsLayoutCtrl.editable && isNotSupervisor;
  vm.isShownLogo = isNotSupervisor;
  vm.documents = [];
  vm.visibilities = securityManagerDocumentVisibility.getOptions();

  vm.$onInit = function() {
    loadSettings();
    initEmptyDocument();
    loadDocuments();
  };

  vm.getVisibilityName = id => visibilityNames[id];

  vm.getFileIcon = document => `#file-type-${document.fileExtension}`;

  vm.uploadFile = function(file) {
    if (!file) { return; }
    vm.newDocument.name = file.name.substring(0, 50);
    vm.documents.unshift(vm.newDocument);
    vm.newDocument.isLoading = true;
    return uploadService.saveWithFile(vm.newDocument, 'file', function(document) {
      vm.documents[0] = document;
      return initEmptyDocument();
    }, function(error) {
      initEmptyDocument();
      vm.documents.shift();
      return Rollbar.log(`File upload update on ${window.location.href}`, error);
    });
  };

  vm.saveSettings = () => {
    return vm.settingsCopy.save()
      .then(function (settings) {
        vm.settings = settings;
        return vm.copySettings();
      }).catch(function (error) {
        Rollbar.log(`Settings update on ${window.location.href}`, error);
        return vm.copySettings();
      });
  };

  vm.copySettings = () => vm.settingsCopy = angular.copy(vm.settings);

  vm.getErrors = fieldName => customErrorTexts[fieldName];

  vm.onLogoUpload = () => onUpload(FIELD_LOGO);

  vm.onLogoRemove = () => onRemove(FIELD_LOGO);

  vm.onCardLogoUpload = () => onUpload(FIELD_CARD_LOGO);

  vm.onCardLogoRemove = () => onRemove(FIELD_CARD_LOGO);

  vm.saveCountry = function(selected) {
    vm.selectedCountry = selected;
    vm.settingsCopy.countryId = vm.selectedCountry.id;
    vm.saveSettings();
  };

  const onUpload = field => {
    return uploadService.saveWithFile(vm.settingsCopy, field, function (settings) {
      vm.settings = settings;
      return vm.copySettings();
    });
  };

  const onRemove = function(field) {
    vm.settingsCopy[field] = undefined;
    return onUpload(field);
  };

  vm.toggleEditDocumentName = function(document) {
    if (document.isEdited) {
      document.isEdited = false;
      return vm.updateDocument(document);
    } else {
      return document.isEdited = true;
    }
  };

  vm.removeDocument = document => {
    return confirmService.confirmRemove('document', () => {
      return document.delete().then(() => _.pull(vm.documents, document));
    });
  };

  vm.updateDocument = document => {
    return document.save().catch(error => Rollbar.log(`Document update on ${window.location.href}`, error));
  };

  const loadSettings = () => {
    SecurityProviderSetting.getCurrent().then(function (settings) {
      vm.settings = settings;
      vm.copySettings();
      loadCountries();
    }).catch(error => Rollbar.log(`Settings get on ${window.location.href}`, error));
  };

  const initEmptyDocument = () => {
    vm.newDocument = new SecurityProviderDocument({
      securityProviderId: currentUserService.getCurrentProfile().securityProvider.id
    });
  };

  const loadDocuments = () => {
    SecurityProviderDocument.query({}, {
      securityProviderId: currentUserService.getCurrentProfile().securityProvider.id
    }).then(documents => vm.documents = documents);
  };

  const loadCountries = function() {
    Country.query().then(function(countries) {
      vm.countriesForSelect = selectService.toSelectArray(countries, { titleField: 'name' });
      const country = vm.settingsCopy.country ? vm.settingsCopy.country.name : DEFAULT_COUNTRY;
      vm.selectedCountry = _.find(vm.countriesForSelect, ['text', country]);
      if (!vm.settingsCopy.countryId) {
        vm.settingsCopy.countryId = vm.selectedCountry.id;
        vm.saveSettings();
      }
    });
  };

  return vm;

};

angular
  .module('public.security-manager.settings')
  .controller('SettingsCompanyDetailsController', dependencies.concat(SettingsCompanyDetailsController));
