/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').directive('contenteditable', ['elementService', elementService => {
  return {
    require : '?ngModel',
    link($scope, $el, attrs, ctrl) {
      if (!ctrl) { return; }

      const element = $el[0];

      const removeNbsp = string => string.replace(/&nbsp;/g, ' ');

      const updateViewValue = function() {
        if (!attrs.withoutSanitize) {
          return ctrl.$setViewValue(removeNbsp($el.html()));
        } else {
          return ctrl.$setViewValue($el.html());
        }
      };

      $el.bind('blur', function() {
        window.getSelection().removeAllRanges();
        return $scope.$apply(updateViewValue);
      });

      $el.bind('keydown', function(event) {
        if (attrs.disabled) {
          event.stopPropagation();
          event.preventDefault();
          return false;
        }
      });

      $el.bind('input', function() {
        if (!attrs.withoutSanitize) {
          const currentOffset = elementService.getCaretOffset(element);
          $el.html(String($el.html()).replace(/<[^>]+>/gm, ''));
          elementService.setCaretOffset(element, currentOffset);
        }
        return updateViewValue();
      });

      $el.bind('paste', function(e) {
        e.stopPropagation();
        e.preventDefault();
        return insertTextAtCursor(e.clipboardData.getData('Text'));
      });

      const insertTextAtCursor = function(text) {
        let selection;
        if (window.getSelection) {
          selection = window.getSelection();
          if (selection.getRangeAt && selection.rangeCount) {
            return insertPlainTextAndSetCursor(text, selection);
          }
        } else if (document.selection && document.selection.createRange) {
          return document.selection.createRange().text = text;
        }
      };

      const insertPlainTextAndSetCursor = function(text, selection) {
        let range = selection.getRangeAt(0);
        range.deleteContents();
        const newSpan = document.createElement("span");
        const newContent = document.createTextNode(text);
        newSpan.appendChild(newContent);
        range.insertNode( newSpan );
        range = document.createRange();
        range.setStartAfter(newSpan);
        range.collapse(true);
        selection.removeAllRanges();
        return selection.addRange(range);
      };

      return ctrl.$render = function() {
        if (!attrs.withoutSanitize) {
          return $el.html(removeNbsp(ctrl.$viewValue || ''));
        } else {
          return $el.html(ctrl.$viewValue || '');
        }
      };
    }
  }
}]);
