angular.module('data.user').service('userValidation', ['$q', 'User', function($q, User) {

  const passwordError = {
    error : 'password',
    message : 'Please enter at least one uppercase, one lowercase and one digit symbols',
    condition(value) {
      if (!value) { return false; }
      return !(/\d/.test(value) && /[A-Z]/.test(value) && /[a-z]/.test(value));
    }
  };

  const rePasswordError = function(passwordName = 'password', rePasswordName = 'rePassword') {
    return {
     error : 'rePassword',
     message : "Oops! Password didn't match",
     compositeField : [passwordName, rePasswordName],
     condition(password, rePassword) {
       return password !== rePassword;
     }
    };
  };

  return {
    getPasswordErrors() {
      return [passwordError];
    },

    getRePasswordErrors(passwordName, rePasswordName) {
      return [passwordError, rePasswordError(passwordName, rePasswordName)];
    },

    getEmailErrorMessages() {
      return {
        required : 'Oops! Email is required',
        pattern : 'Please enter a valid email address'
      };
    },

    getPasswordErrorMessages() {
      return {
        required : 'Oops! Password is required',
        minlength : 'Please enter at least 6 symbols'
      };
    },

    getEmailErrors(userId, securityProviderId) {
      return [{
        error : 'unique',
        message : 'Email is not unique',
        asyncValidation(email) {
          if (email) {
            return User.validate({ email, securityProviderId, except: userId });
          } else {
            return $q.when(false);
          }
        }
      }
      ];
    }
  };
}

]);
