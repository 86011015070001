/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'EmployeeWorkHistory', 'EntityManager', 'employeeType'];

const EmployeeRegistrationWorkHistoryController = function($scope, EmployeeWorkHistory, EntityManager, employeeType) {

  const vm = this;

  vm.employeeType = employeeType;

  const entityManager = new EntityManager(EmployeeWorkHistory, {
    params : {
      employeeId : $scope.employeeRegistrationLayoutCtrl.employeeId
    },
    withBlank : true
  }
  );

  vm.addNewWorkHistory = entityManager.addNewEntity;
  vm.removeWorkHistory = entityManager.removeEntity;

  vm.getWorkHistories = () => entityManager.entities;

  entityManager.loadAll();

  $scope.$on('employee.registration.submit', () =>
    entityManager.saveAll().then(() => $scope.employeeRegistrationLayoutCtrl.goNext())
  );

  return vm;
};

angular.module('public.security-manager.employee.registration').controller('EmployeeRegistrationWorkHistoryController',
  dependencies.concat(EmployeeRegistrationWorkHistoryController));
