const dependencies = ['EmployeeSidebar', 'bookingShiftEmployeeAssignStatus'];

const BookingTimeLineEmployeeSidebarController = function(EmployeeSidebar, bookingShiftEmployeeAssignStatus) {

  const vm = this;

  vm.$onInit = function() {
    vm.employeeSidebar = new EmployeeSidebar(vm.shiftSelector);
    vm.shiftSelector.setEmployeeSidebarService(vm.employeeSidebar);
  };

  vm.stopPropagation = $event => $event.stopPropagation();

  vm.isInAvailability = function (employee) {
    return employee.availabilityStatus === bookingShiftEmployeeAssignStatus.getInAvailabilityStatus();
  };

  return vm;

};

angular
  .module('public.security-manager.schedule-manager.timeline')
  .controller('ScheduleManagerBookingTimeLineEmployeeSidebarController',
    dependencies.concat(BookingTimeLineEmployeeSidebarController));
