// todo rename
const gccsBulkPayRateEmployees = {
  controller: 'BulkPayRateEmployeesComponentController',
  template: require('./template.pug'),
  bindings: {
    employees: '<',
    hoveredEmployeeRowId: '=',
    searchEmployee: '<',
    editable: '<',
    banEmployee: '&',
    unbanEmployee: '&',
    onCheckEmployee: '&',
    selectedRoles: '<'
  }
};

angular.module('public.security-manager.hr').component('gccsBulkPayRateEmployees', gccsBulkPayRateEmployees);
