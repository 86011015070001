const dependencies = ['BookingShiftRoot'];

const LiveFeedItemController = function(BookingShiftRoot) {

  const vm = this;

  vm.hasLoadedDetails = false;

  vm.toggleShiftIssue = function(dShiftIssue) {
    vm.onShiftIssueToggle({ dShiftIssue, onOpenCb: () => {
      vm.hasLoadedDetails = false;
      return new BookingShiftRoot({ id: dShiftIssue.shiftId }).loadIssueDetails().then(function(issueDetails) {
        _.assign(dShiftIssue.shift, issueDetails);
        vm.hasLoadedDetails = true;
      });
    }});
  };

  vm.updateShiftIssueDetails = function(dShiftIssue, onUpdate) {
    new BookingShiftRoot({ id: dShiftIssue.shiftId }).loadIssueDetails().then(function(issueDetails) {
      _.assign(dShiftIssue.shift, issueDetails);
      if (onUpdate) { onUpdate(); }
    });
  };

  vm.removeNewStatus = function() {
    if (vm.dShiftIssue.isNew) {
      vm.dShiftIssue.isNew = false;
      vm.dShiftIssue.isNewAnimation = false;
    }
  };

  return vm;

};

angular.module('public.security-manager.schedule-manager.timeline').controller('LiveFeedItemController',
  dependencies.concat(LiveFeedItemController));
