/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.employee').factory('EmployeeLicense', ['Document', function(Document) {

  class EmployeeLicense extends Document {
    static initClass() {
      this.configure({url : '/employees/{{employeeId}}/licenses/{{id}}', name : 'license'});
    }
  }

  EmployeeLicense.initClass();
  return EmployeeLicense;
}
]);