/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$q', '$scope', '$state', 'SecurityProvider', 'validationService', 'userValidation',
  'currentUserService', 'storageService'
];

const SignSecurityProviderLayoutController = function(
  $q, $scope, $state, SecurityProvider, validationService, userValidation,
  currentUserService, storageService
) {

  const vm = this;

  vm.steps = [{
    title: 'Choose your plan',
    sref: 'sign.security-provider-choose-plan',
  }, {
    title: 'Sign up',
    sref: 'sign.security-provider.step1',
  }, {
    title: 'Company details',
    sref: 'sign.security-provider.step2'
  }, {
    title: '2-Step verification',
    sref: 'sign.security-provider.step3'
  }, {
    title: 'Confirmation',
    sref: 'sign.security-provider.step4'
  }];
  vm.validationService = validationService;
  vm.userValidation = userValidation;
  vm.isSubmitButtonDisabled = false;

  let cachedSecurityProvider = null;
  let isFormSubmitted = false;

  vm.$onInit = function() {
    initActiveStep();
  };

  vm.getTitle = () => vm.steps[vm.activeStepNumber - 1].title;

  vm.updateSecurityProvider = function(securityProvider) {
    cachedSecurityProvider = securityProvider;
    vm.updateSecurityProviderId(securityProvider.id);
  };

  vm.updateSecurityProviderId = function(securityProviderId) {
    storageService.set('securityProviderId', securityProviderId);
  };

  vm.goBack = () => $state.go(vm.steps[vm.activeStepNumber - 2].sref);

  vm.goNext = function() {
    if (vm.steps[vm.activeStepNumber]) {
      $state.go(vm.steps[vm.activeStepNumber].sref);
    }
  };

  vm.onFormSubmit = function() {
    if (!$scope.SecurityProviderForm.$valid || isFormSubmitted) { return; }
    isFormSubmitted = true;
    $scope.$broadcast('security-provider.registration.submit');
  };

  vm.loadSecurityProvider = function() {
    if (cachedSecurityProvider) {
      return $q.when(cachedSecurityProvider);
    } else if (storageService.get('securityProviderId')) {
      return new SecurityProvider({ id: storageService.get('securityProviderId') }).withManager().then(securityProvider => {
        vm.updateSecurityProvider(securityProvider);
        return securityProvider;
      }).catch(function() {
        resetSecurityProvider();
      });
    } else {
      return $q.when(undefined);
    }
  };

  vm.resetForm = function() {
    vm.resetFormSubmitted();
    if ($scope.SecurityProviderForm) {
      $scope.SecurityProviderForm.$setPristine();
      $scope.SecurityProviderForm.$setUntouched();
    }
  };

  vm.resetFormSubmitted = () => isFormSubmitted = false;

  vm.isLoadedSecurityProvider = () => cachedSecurityProvider !== null;

  vm.getNextButtonText = function() {
    if (vm.activeStepNumber === vm.steps.length) {
      return 'Done';
    } else {
      return 'Next step';
    }
  };

  vm.resetCache = function() {
    cachedSecurityProvider = null;
  };

  vm.gotoFirstStep = function() {
    $state.go(vm.steps[0].sref);
  };

  const resetSecurityProvider = function() {
    storageService.remove('securityProviderId');
  };

  const initActiveStep = function() {
    const index = _.findIndex(vm.steps, step => $state.is(step.sref));
    vm.activeStepNumber = index + 1;
    vm.resetForm();
  };

  $scope.$on('$stateChangeSuccess', initActiveStep);

  return vm;

};

angular.module('sign.security-provider')
  .controller('SignSecurityProviderLayoutController', dependencies.concat(SignSecurityProviderLayoutController));
