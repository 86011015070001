const dependencies = ['AppModel'];

const User = AppModel => {

  class User extends AppModel {
    static initClass() {
      this.configure({ url: '/users', name: 'user' });
    }

    getInvitationLink() {
      return User.$get(this.getUrl() + '/invitation_link');
    }

    toggleAutoLogout() {
      return this.$put(this.getUrl() + '/toggle_auto_logout');
    }

    otpDetails() {
      return User.$get(this.getUrl() + '/otp_details');
    }

    updateOtp() {
      return this.$post(this.getUrl() + '/two_factor_auth' );
    }

    getWebAuthnCredentials() {
      return User.$get(this.getUrl() + '/webauthn_credentials' );
    }

    deleteWebAuthnCredential(id) {
      return this.$delete(this.getUrl() + '/webauthn_credential/' + id );
    }
  }

  User.initClass();
  
  return User;

};

angular.module('data.user').factory('User', dependencies.concat(User));
