const dependencies = [
  '$scope', 'BookingShiftEmployee', 'EntityManager', 'cableService', 'currentUserService', 'dateService', 'resourceChargeType'
];

const PopupBookingShiftFormEmployeesController = function(
  $scope, BookingShiftEmployee, EntityManager, cableService, currentUserService, dateService, resourceChargeType
) {

  const vm = this;

  vm.bookingTimelineShiftSelector = $scope.bookingTimelineShiftSelector;
  vm.timeLineLoader = $scope.timeLineLoader;
  vm.isShownEmployeeInvite = $scope.isShownEmployeeInvite;
  vm.isLoaded = false;

  vm.$onInit = function() {
    if ($scope.shouldGoToInvited) {
      vm.isShownEmployeeInvite = false;
    }
    subscribeToBookingTimeline();
    initShiftEmployeeManagers();
  };

  vm.showEmployeeInvite = function(shiftEmployee) {
    vm.isShownEmployeeInvite = true;
    return vm.assignForShiftEmployee = shiftEmployee;
  };

  vm.canInviteEmployees = () => $scope.editable && !currentUserService.isClientManagerLogged();

  const checkedReloadShiftEmployees = function(data) {
    if ($scope.shift.id === data.shift_id) { loadShiftEmployees(); }
  };

  const subscribeToBookingTimeline = () => {
    const bookingTimelineChannel = cableService.getBookingTimelineChannel();
    if (bookingTimelineChannel) {
      ['shift_employee', 'shift_employee_destroyed'].forEach((cableUpdateEvent) => {
        bookingTimelineChannel.addCallbackWithCleanup($scope, cableUpdateEvent, checkedReloadShiftEmployees);
      });
    }
  };

  const initShiftEmployeeManagers = function() {
    vm.shiftEmployeeManager = new EntityManager(BookingShiftEmployee);
    const options = {
      params: {
        bookingId: $scope.shift.bookingId,
        shiftId: $scope.shift.id
      }
    };
    vm.shiftEmployeeManager.addOptions(angular.copy(options));
    if (currentUserService.isClientManagerLogged()) {
      vm.shiftEmployeeManager.addOptions({
        queryParams: {
          withoutSplit: true
        }
      });
    }
    loadShiftEmployees();
    vm.conflictedShiftEmployeeManager = vm.shiftEmployeeManager.clone();
    vm.conflictedShiftEmployeeManager.addOptions(angular.copy(options));
    vm.conflictedShiftEmployeeManager.addOptions({
      queryParams: {
        assigned: true
      }
    });
  };

  const loadShiftEmployees = function() {
    vm.shiftEmployeeManager.loadAll().then((shiftEmployees) => {
      _.each(shiftEmployees, (shiftEmployee) => {
        initTimeMoments(shiftEmployee);
        shiftEmployee.splitShifts = _.map(shiftEmployee.splitShifts, (splitShift) => {
          initTimeMoments(splitShift);
          return new BookingShiftEmployee(splitShift);
        });
      });
      vm.isLoaded = true;
    });
  };

  const initTimeMoments = function(shiftEmployee) {
    if ($scope.shift.enableClockIn && !resourceChargeType.isPerUnit($scope.shift.resource.chargeType)) {
      initTimeMoment(shiftEmployee, 'start');
      initTimeMoment(shiftEmployee, 'end');
      initTimeMoment(shiftEmployee, 'clockIn');
      initTimeMoment(shiftEmployee, 'clockOut');
    }
  };

  const initTimeMoment = function(shiftEmployee, field) {
    const fieldTime = shiftEmployee[`${field}Time`];
    if (fieldTime) {
      const time = moment(fieldTime, dateService.getIsoFullDateFormat());
      shiftEmployee[`${field}TimeMoment`] = time;
      shiftEmployee[`${field}DayTime`] = time.format(dateService.getTimeFormat());
    } else {
      shiftEmployee[`${field}TimeMoment`] = null;
      shiftEmployee[`${field}DayTime`] = null;
    }
  };

  return vm;
};

angular.module('popup.booking').controller('PopupBookingShiftFormEmployeesController',
  dependencies.concat(PopupBookingShiftFormEmployeesController));
