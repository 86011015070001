/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = ['Role'];

const BulkPayRateEmployeesComponentController = function(Role) {

  const vm = this;

  vm.isHoveredEmployee = false;
  vm.isOpenedMenuDropdown = false;

  vm.showPreviewForEmployee = null;

  vm.toggleHover = () => vm.isHoveredEmployee = !vm.isHoveredEmployee;

  vm.toggleMenuDropdown = () => vm.isOpenedMenuDropdown = !vm.isOpenedMenuDropdown;

  vm.showEmployeePreview = (employee) => {
    vm.showPreviewForEmployee = employee;
  };

  vm.closeEmployeePreview = () => vm.showPreviewForEmployee = null;

  vm.initHoverEmployee = employeeId => vm.hoveredEmployeeRowId = employeeId;

  vm.closeMenuDropdown = () => vm.isOpenedMenuDropdown = false;

  vm.closeHoverEmployee = function() {
    vm.hoveredEmployeeRowId = false;
    return vm.closeMenuDropdown();
  };

  vm.checkEmployee = employee => vm.onCheckEmployee({ employee });

  vm.getFilteredEmployees = function() {
    return _.filter(vm.employees, function(employee) {
      return employee.fullName.search(new RegExp(vm.searchEmployee, 'i')) !== -1 && (
        vm.selectedRoles.length === 0 ||
        vm.selectedRoles[0] instanceof Role ||
        _.intersectionBy(employee.roles, vm.selectedRoles, 'id').length > 0
      );
    })
  };

  vm.getEmployeeRolesText = function(employee) {
    if (employee.roles.length > 0) {
      return _.map(employee.roles, 'name').join(', ');
    } else {
      return 'No role selected';
    }
  };

  return vm;
};

angular.module('public.security-manager.hr')
  .controller('BulkPayRateEmployeesComponentController', dependencies.concat(BulkPayRateEmployeesComponentController));
