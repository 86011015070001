/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$rootScope', '$state', 'currentUserService', 'sessionService', 'confirmService', 'headerItemsService', 'redirectService'
];

const HeaderController = function(
  $scope, $rootScope, $state, currentUserService, sessionService, confirmService, headerItemsService, redirectService
) {

  const vm = this;

  vm.currentProfile = currentUserService.getCurrentProfile();
  vm.currentUser = currentUserService.getCurrentUser();
  vm.headerItems = headerItemsService.getItems();
  vm.isEmployeeLogged = () => currentUserService.isEmployeeLogged();
  vm.isSecurityManagerLogged = () => currentUserService.isSecurityManagerLogged();

  vm.isScopedUnderEmployee = () => $state.includes('public.employee');

  vm.isActiveHeaderItem = function(headerItem) {
    if (headerItem.activeStates) {
      return _.some(headerItem.activeStates, state => $state.includes(state));
    } else {
      return $state.includes(headerItem.sref);
    }
  };

  vm.getUISrefOpts = function(headerItem) {
    return vm.isActiveHeaderItem(headerItem) ? { reload: true } : {};
  };

  vm.getProfileLink = function() {
    if (currentUserService.isSecurityManagerLogged()) {
      return 'public.security-manager.profile.personal-details';
    } else if (currentUserService.isEmployeeLogged()) {
      return 'public.employee.profile-settings.personal-details';
    } else {
      return 'public.client-manager.profile.personal-details';
    }
  };

  vm.getProfileLinkText = function() {
    if (currentUserService.isEmployeeLogged()) {
      return 'Settings';
    } else {
      return 'Profile';
    }
  };

  vm.logoClicked = function() {
    redirectService.redirectToMain();
  };

  vm.logout = () => {
    return confirmService.confirm('Confirm logout', 'Do you really want to log out?', 'Yes', sessionService.logout);
  };

  const logoutListener = $rootScope.$on('logoutUnauthorized', () => sessionService.logout());

  $scope.$on('$destroy', logoutListener);

  return vm;
};

angular.module('public').controller('HeaderController', dependencies.concat(HeaderController));
