const dependencies = [
  '$scope', 'dateService', 'notificationService', 'bookingShiftValidationService', 'bookingShiftClockInErrorsService'
];

const ManagerClockInEditController = function(
  $scope, dateService, notificationService, bookingShiftValidationService, bookingShiftClockInErrorsService
) {

  const vm = this;

  const TIME_FIELD_CLOCK_START = 'clockIn';
  const TIME_FIELD_CLOCK_END = 'clockOut';
  const TIME_FIELD_START = 'start';
  const TIME_FIELD_END = 'end';

  let isSending = false;

  vm.$onChanges = function() {
    initShiftEmployee();
    initCompareWith();
  };

  vm.getClockInTimeErrors = function () {
    if (vm.isShownClockIn && vm.isShownClockOut) {
      return bookingShiftValidationService.getClockInOutTimeErrors(TIME_FIELD_CLOCK_START);
    } else {
      return bookingShiftValidationService.getClockInTimeErrors(vm.shiftEmployeeClone.clockOutTime || vm.shiftEmployeeClone.endTime);
    }
  };

  vm.getClockOutTimeErrors = function () {
    if (vm.isShownClockIn && vm.isShownClockOut) {
      return bookingShiftValidationService.getClockInOutTimeErrors(TIME_FIELD_CLOCK_END);
    } else {
      return bookingShiftValidationService.getClockOutTimeErrors(vm.shiftEmployeeClone.clockInTime || vm.shiftEmployeeClone.startTime);
    }
  };

  vm.updateClockIn = function() {
    if (!$scope.ClockInEditForm.$valid || isSending) { return; }
    isSending = true;
    if (vm.withoutSave) {
      angular.copy(vm.shiftEmployeeClone, vm.shiftEmployee);
      vm.closeCb();
    } else {
      vm.shiftEmployeeClone.save().then(function() {
        angular.copy(vm.shiftEmployeeClone, vm.shiftEmployee);
        if (vm.successMessage) {
          notificationService.notifySuccess(vm.successMessage);
        }
        if (vm.closeCb) {
          vm.closeCb();
          isSending = false;
        }
      }, function () {
        $scope.ClockInEditForm.$setPristine();
        $scope.ClockInEditForm.$setUntouched();
        isSending = false;
      });
    }
  };

  vm.isChanged = function(field) {
    return vm.compareWith && vm.compareWith[field] !== vm.shiftEmployeeClone[field];
  };

  vm.hasClockInError = function() {
    return vm.withClockErrors && bookingShiftClockInErrorsService.hasClockInError(vm.shiftEmployeeClone);
  };

  vm.hasClockOutError = function() {
    return vm.withClockErrors && bookingShiftClockInErrorsService.hasClockOutError(vm.shiftEmployeeClone);
  };

  vm.updateClockInTime = function() {
    if (vm.shiftEmployeeClone.clockInDayTime) {
      buildTime(TIME_FIELD_CLOCK_START);
      vm.shiftEmployeeClone.clockInTimeMoment = moment(vm.shiftEmployeeClone.clockInTime, dateService.getIsoFullDateFormat());
    }
  };

  vm.updateClockOutTime = function() {
    if (vm.shiftEmployeeClone.clockOutDayTime) {
      buildTime(TIME_FIELD_CLOCK_END);
      vm.shiftEmployeeClone.clockOutTimeMoment = moment(vm.shiftEmployeeClone.clockOutTime, dateService.getIsoFullDateFormat());
    }
  };

  vm.beforeClockInShow = function() {
    if (!vm.shiftEmployeeClone.clockInDayTime) {
      initDefaultTime(TIME_FIELD_CLOCK_START, TIME_FIELD_START);
      vm.updateClockInTime();
    }
  };

  vm.beforeClockOutShow = function() {
    if (!vm.shiftEmployeeClone.clockOutDayTime) {
      initDefaultTime(TIME_FIELD_CLOCK_END, TIME_FIELD_END);
      vm.updateClockOutTime();
    }
  };

  const initShiftEmployee = function() {
    vm.shiftEmployeeClone = angular.copy(vm.shiftEmployee);
    if (vm.isShownClockIn) {
      if (!vm.shiftEmployeeClone.clockInTime && !vm.isInInvoice) {
        vm.shiftEmployeeClone.clockInTime = vm.shiftEmployee.startTime;
      }
      if (vm.shiftEmployeeClone.clockInTime) {
        splitTime(TIME_FIELD_CLOCK_START);
      } else {
        initDefaultDate(TIME_FIELD_CLOCK_START, TIME_FIELD_START);
      }
    }
    if (vm.isShownClockOut) {
      if (!vm.shiftEmployeeClone.clockOutTime && !vm.isInInvoice) {
        vm.shiftEmployeeClone.clockOutTime = vm.shiftEmployee.endTime;
      }
      if (vm.shiftEmployeeClone.clockOutTime) {
        splitTime(TIME_FIELD_CLOCK_END);
      } else {
        initDefaultDate(TIME_FIELD_CLOCK_END, TIME_FIELD_END);
      }
    }
  };

  const initCompareWith = function() {
    if (vm.compareWith) {
      if (vm.compareWith.clockInTime) {
        splitTime(TIME_FIELD_CLOCK_START, vm.compareWith);
      } else {
        initDefaultDate(TIME_FIELD_CLOCK_START, TIME_FIELD_START, vm.compareWith);
      }
      if (vm.compareWith.clockOutTime) {
        splitTime(TIME_FIELD_CLOCK_END, vm.compareWith);
      } else {
        initDefaultDate(TIME_FIELD_CLOCK_END, TIME_FIELD_END, vm.compareWith);
      }
    }
  };

  const buildTime = function(field) {
    const time = [vm.shiftEmployeeClone[`${field}Date`], vm.shiftEmployeeClone[`${field}DayTime`]].join(' ');
    const timeMoment = moment(time, dateService.getFullDateFormat());
    vm.shiftEmployeeClone[`${field}Time`] = timeMoment.format(dateService.getIsoFullDateFormat());
  };

  const splitTime = function(field, object = vm.shiftEmployeeClone) {
    let time = moment(object[`${field}Time`], dateService.getIsoFullDateFormat());
    if (!time.isValid()) {
      time = moment(object[`${field}Time`], dateService.getFullDateFormat());
    }
    object[`${field}Date`] = time.format(dateService.getDateFormat());
    object[`${field}DayTime`] = time.format(dateService.getTimeFormat());
  };

  const initDefaultDate = function(field, originalField, object = vm.shiftEmployeeClone) {
    let time = moment(object[`${originalField}Time`], dateService.getIsoFullDateFormat());
    if (!time.isValid()) {
      time = moment(object[`${originalField}Time`], dateService.getFullDateFormat());
    }
    object[`${field}Date`] = time.format(dateService.getDateFormat());
  };

  const initDefaultTime = function(field, originalField, object = vm.shiftEmployeeClone) {
    let time = moment(object[`${originalField}Time`], dateService.getIsoFullDateFormat());
    if (!time.isValid()) {
      time = moment(object[`${originalField}Time`], dateService.getFullDateFormat());
    }
    object[`${field}DayTime`] = time.format(dateService.getTimeFormat());
  };

  return vm;

};

angular.module('shared-components.popup.booking')
  .controller('ManagerClockInEditController', dependencies.concat(ManagerClockInEditController));
