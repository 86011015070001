/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.user').factory('UserPhoneCode', ['AppModel', function(AppModel) {

  class UserPhoneCode extends AppModel {
    static initClass() {
      this.configure({
        url: '/users/{{ userId }}/phone_codes/{{ id }}', name: 'phone_code'
      });
    }

    static confirm(userId, code) {
      return this.$put(this.resourceUrl({ userId: userId }) + '/confirm', { code: code });
    }
  }

  UserPhoneCode.initClass();

  return UserPhoneCode;

}]);
