/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('shared').service('timelineShiftPopupType', function() {

  const SHIFT_TIMELINE_POPUP = 1;
  const SHIFT_FORM_POPUP = 2;

  return {
    getShiftTimelinePopup() {
      return SHIFT_TIMELINE_POPUP;
    },

    getShiftFormPopup() {
      return SHIFT_FORM_POPUP;
    },

    isShiftTimelinePopup(type) {
      return type === SHIFT_TIMELINE_POPUP;
    },

    isShiftFormPopup(type) {
      return type === SHIFT_FORM_POPUP;
    }
  };
});
