/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['$scope', '$timeout'];

const FormDateToggleController = function($scope, $timeout) {

  const vm = this;

  vm.isDateAdded = false;

  vm.openAddDate = function() {
    if (vm.addVersionCb) {
      vm.addVersionCb();
    }
    vm.isDateAdded = true;
  };

  vm.closeAddDate = function() {
    if (vm.removeVersionCb) {
      vm.removeVersionCb({
        successCb: () => {
          vm.dateModel = null;
          vm.isDateAdded = false;
        }
      });
    } else {
      vm.dateModel = null;
      vm.isDateAdded = false;
    }
  };

  $scope.$watch(function() {
    return vm.dateModel;
  }, function() {
    if (vm.dateModel) {
      if (vm.onChangeCb) {
        vm.onChangeCb();
      }
      $timeout(function() {
        vm.isDateAdded = !!vm.dateModel;
      });
    }
  });

  return vm;

};

angular.module('form')
  .controller('FormDateToggleController', dependencies.concat(FormDateToggleController));
