const dependencies = [
  '$scope', 'BookingChange', 'bookingChangeService', 'dateService',
  'bookingRepeatable', 'bookingEndRepeat', 'bookingShiftChange'
];

const PopupBookingInfoActivityLogController = function(
  $scope, BookingChange, bookingChangeService, dateService,
  bookingRepeatable, bookingEndRepeat, bookingShiftChange
) {

  const vm = this;

  const FIELD_LABELS = {
    name: 'Booking name',
    repeatable: 'Repeatable',
    service: 'Service',
    location: 'Location',
    client: 'Client',
    from: 'Booking Start date',
    to: 'Booking End date',
    repeatUntil: 'Repeat until',
    startTime: 'Start day',
    endTime: 'End day',
    endRepeat: 'End Repeat',
    repeatTimes: 'Repeat times',
    homeZones: 'Home zones',
    projectCode: 'Project code',
    purchaseNumber: 'Purchase number'
  };

  const STATUS_SHIFT = {
    [bookingShiftChange.getShiftChangeCreate()]: 'added',
    [bookingShiftChange.getShiftChangeDestroy()]: 'removed'
  };

  vm.$onInit = function() {
    vm.areLoadedLogs = false;
    BookingChange.query({}, { bookingId: $scope.bookingId }).then(function(changes) {
      vm.areLoadedLogs = true;
      initChanges(changes);
    });
  };

  vm.getChangedField = function(change) {
    if (change.type) {
      if (change.type === bookingShiftChange.getShiftChangeName()) {
        return `Shift title was changed from ${change.name[0]} to ${change.name[1]}`;
      } else {
        let shiftName = change.name;
        if (change.startTime && change.endTime) {
          change.startTimeMoment = change.startTimeMoment || moment(change.startTime, dateService.getIsoFullDateFormat());
          change.endTimeMoment = change.endTimeMoment || moment(change.endTime, dateService.getIsoFullDateFormat());
          const timeInfo = [
            change.startTimeMoment.format(dateService.getFullDateFormat()),
            change.endTimeMoment.format(dateService.getFullDateFormat())
          ].join(' - ');
          shiftName += ` (${timeInfo})`;
        }
        return `${shiftName} was ${STATUS_SHIFT[change.type]}`;
      }
    } else {
      return `${change.fieldLabel} ${getChangeName(change.oldValue, change.newValue)} ` +
        `${getChangedValues(change.field, change.oldValue, change.newValue)}`;
    }
  };

  const getChangedValues = function(fieldType, oldValue, newValue) {
    const formattedOldValue = getFormattedField(fieldType, oldValue);
    const formattedNewValue = getFormattedField(fieldType, newValue);
    if (_.isEmpty(oldValue)) {
      return `to ${formattedNewValue}`;
    } else if (_.isEmpty(newValue)) {
      return `from ${formattedOldValue}`;
    } else {
      return `from ${formattedOldValue} to ${formattedNewValue}`;
    }
  };

  const getFormattedField = function(fieldType, fieldValue) {
    if (fieldValue === null) { return fieldValue; }
    switch (fieldType) {
      case 'name':
        return `\"${fieldValue}\"`;
      case 'startTime':
      case 'endTime':
        return moment(fieldValue, dateService.getIsoFullDateFormatWithT()).format(dateService.getFullDateFormat());
      case 'from':
      case 'to':
      case 'repeatUntil':
        return moment(fieldValue, dateService.getIsoDateFormat()).format(dateService.getDateFormat());
      case 'client':
      case 'location':
      case 'service':
        return `\"${fieldValue.name}\"`;
      case 'homeZones':
        return _.map(fieldValue, value => `\"${value.name}\"`).join(', ');
      case 'repeatable':
        return bookingRepeatable.getName(fieldValue);
      case 'endRepeat':
        return bookingEndRepeat.getName(fieldValue);
      default:
        return fieldValue;
    }
  };

  const getChangeName = function(oldValue, newValue) {
    if (isEmptyChange(oldValue)) {
      return 'created';
    } else if (isEmptyChange(newValue)) {
      return 'deleted';
    } else {
      return 'edited';
    }
  };

  const isEmptyChange = value => (value === null) || (_.isEmpty(value) && !_.isNumber(value));

  const getFullDateAndTime = date => {
    return moment(date, dateService.getIsoFullDateFormatWithTz()).format(dateService.getFullDateFormat());
  };

  const getFieldLabel = field => FIELD_LABELS[field] || field;

  const isShiftLoggable = field => (field === 'startTime') || (field === 'endTime');

  const buildBookingChange = (bookingChange, changesList) => {
    _.each(bookingChange.changes, function (change, key) {
      changesList.push({
        name: bookingChange.name,
        changes: [{
          field: key,
          fieldLabel: getFieldLabel(key),
          oldValue: change[0],
          newValue: change[1]
        }],
        date: getFullDateAndTime(bookingChange.createdAt),
        userName: bookingChange.user.fullName
      });
    });
  };

  const buildShiftChange = function(bookingChange, changesList) {
    const shiftChanges = [];
    _.each(bookingChange.changes, function(change, key) {
      if (isShiftLoggable(key)) {
        shiftChanges.push({
          field: key,
          fieldLabel: getFieldLabel(key),
          oldValue: change[0],
          newValue: change[1]
        });
      }
    });
    if (shiftChanges.length) {
      changesList.push({
        name: bookingChange.name,
        changes: shiftChanges,
        date: getFullDateAndTime(bookingChange.createdAt),
        userName: bookingChange.user.fullName
      });
    }
  };

  const buildShiftShifting = (bookingChange, changesList) => {
    changesList.push({
      name: bookingChange.name,
      changes: [bookingChange.shiftChange],
      date: getFullDateAndTime(bookingChange.createdAt),
      userName: bookingChange.user.fullName
    });
  };

  const initChanges = function(changes) {
    const changesList = [];
    _.each(changes, function(bookingChange) {
      if (!bookingChange.changes && bookingChange.shiftChange) {
        buildShiftShifting(bookingChange, changesList);
      } else if (bookingChange.type === bookingChangeService.getBookingChangeType()) {
        buildBookingChange(bookingChange, changesList);
      } else if (bookingChange.type === bookingChangeService.getShiftChangeType()) {
        buildShiftChange(bookingChange, changesList);
      }
    });
    vm.bookingChangesList = changesList;
  };

  return vm;

};

angular.module('popup.booking')
  .controller('PopupBookingInfoActivityLogController', dependencies.concat(PopupBookingInfoActivityLogController));
