const gccsEmployeeTimelineShiftModal = {
  controller: 'EmployeeTimelineShiftModalController',
  template: require('./template.pug'),
  bindings: {
    timelineItem: '<',
    position: '=',
    isShownConfirmPopup: '=',
    closeTimelineItemPopup: '&'
  }
};

angular.module('public.employee.timeline')
  .component('gccsEmployeeTimelineShiftModal', gccsEmployeeTimelineShiftModal);
