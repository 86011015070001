/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$state', '$scope', '$q', 'RepeatableBooking', 'redirectService', 'localStorageService'
];

const ClientManagerRepeatableBookingLayoutController = function(
  $state, $scope, $q, RepeatableBooking, redirectService, localStorageService
) {

  const vm = this;

  vm.activeStepNumber = 1;
  vm.holderWidth = null;

  let cachedRepeatableBooking;
  let isFinishedBooking = false;

  vm.$onInit = function() {
    initBookingId();
    initSteps();
    initActiveStepNumber();
  };

  vm.getTitle = () => vm.steps[vm.activeStepNumber - 1].title;

  vm.goBack = () => $state.go(vm.steps[vm.activeStepNumber - 2].sref);

  vm.goNext = function() {
    if (vm.steps[vm.activeStepNumber]) {
      $state.go(vm.steps[vm.activeStepNumber].sref);
    } else {
      if (isFinishedBooking) { return; }
      isFinishedBooking = true;
      vm.loadRepeatableBooking().then((repeatableBooking) => {
        repeatableBooking.finish().then(function() {
          localStorageService.remove('repeatableBookingId');
          redirectFromForm();
        }).catch(function() {
          isFinishedBooking = false;
        });
      });
    }
  };

  vm.getHolderClass = function() {
    if (vm.holderWidth) {
      return `mod-w${vm.holderWidth}`;
    }
  };

  vm.loadRepeatableBooking = function() {
    if (cachedRepeatableBooking) {
      return $q.when(cachedRepeatableBooking);
    } else if (vm.repeatableBookingId) {
      return vm.reloadRepeatableBooking();
    } else {
      // is not saving without params
      return new RepeatableBooking({ name: '' }).save().then(vm.updateRepeatableBooking);
    }
  };

  vm.reloadRepeatableBooking = () => {
    return RepeatableBooking.get(vm.repeatableBookingId).then(loadedRepeatableBooking => {
      return cachedRepeatableBooking = loadedRepeatableBooking;
    }, () => redirectService.redirectToMain());
  };

  vm.updateRepeatableBooking = function(repeatableBooking) {
    if (!vm.isEdit) { localStorageService.set('repeatableBookingId', repeatableBooking.id); }
    vm.repeatableBookingId = repeatableBooking.id;
    return cachedRepeatableBooking = repeatableBooking;
  };

  const initActiveStepNumber = function() {
    const index = _.findIndex(vm.steps, step => $state.is(step.sref));
    vm.activeStepNumber = index + 1;
  };

  const initBookingId = function() {
    if ($state.params.id) {
      vm.isEdit = true;
      vm.repeatableBookingId = $state.params.id;
    } else {
      vm.isEdit = false;
      vm.repeatableBookingId = localStorageService.get('repeatableBookingId');
    }
  };

  const initSteps = () => {
    return vm.steps = [{
      title: vm.isEdit ? 'Edit repeatable booking' : 'New repeatable booking',
      sref: 'public.client-manager.repeatable-booking.form'
    }, {
      title: vm.isEdit ? 'Edit shifts' : 'Create shifts',
      sref: 'public.client-manager.repeatable-booking.shift-rules'
    }];
  };

  const redirectFromForm = function() {
    vm.repeatableBookingId = undefined;
    $state.go('public.client-manager.timeline');
  };

  $scope.$on('$stateChangeSuccess', initActiveStepNumber);

  return vm;

};

angular.module('public.client-manager.repeatable-booking')
  .controller('ClientManagerRepeatableBookingLayoutController',
    dependencies.concat(ClientManagerRepeatableBookingLayoutController)
  );
