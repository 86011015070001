/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const stringService = () => {
  return {
    toSnakeCase(string) {
      return string.replace(/([A-Z])/g, $found => `_${$found.toLowerCase()}`);
    },

    // https://stackoverflow.com/a/2970667
    toCamelCase(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
        return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
      }).replace(/\s+/g, '').replace(/[^a-zA-Z0-9]/g, '');
    }
  };
};

angular.module('shared').service('stringService', stringService);
