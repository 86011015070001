const gccsSecurityManagerNotificationsPage = {
  template: require('./template.pug'),
  controller: 'SecurityManagerNotificationsPageController',
  bindings: {
    service: '<'
  }
};

angular.module('public.security-manager.notifications')
  .component('gccsSecurityManagerNotificationsPage', gccsSecurityManagerNotificationsPage);
