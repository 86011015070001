/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'securityManagerAccessLevel', 'currentUserService', 'userValidation', 'validationService',
  'notificationService', 'User'
];

const ManagerProfileBasicController = function(
  $scope, securityManagerAccessLevel, currentUserService, userValidation, validationService,
  notificationService, User
) {

  const vm = this;

  vm.securityManagerAccessLevel = securityManagerAccessLevel;
  vm.validationService = validationService;

  const currentSecurityManagerAccessLevel = currentUserService.getCurrentProfile().accessLevel;

  let securityManagerCopy = undefined;
  let isFormSubmitted = false;

  vm.$onInit = function() {
    initSecurityManager();
  };

  vm.getAccessLevelNames = () => vm.securityManagerAccessLevel.getNamesFor(currentSecurityManagerAccessLevel);

  vm.getEmailErrors = () => userValidation.getEmailErrors(vm.securityManager.user && vm.securityManager.user.id);

  vm.copySecurityManager = function() {
    if (!securityManagerCopy) { return; }
    vm.securityManager = angular.copy(securityManagerCopy);
  };

  vm.onFormSubmit = function() {
    if ($scope.SecurityManagerForm.$invalid || $scope.SecurityManagerForm.$pristine || isFormSubmitted) { return; }
    isFormSubmitted = true;
    let user = new User(vm.securityManager.user);

    user.save().then((user) => {
      vm.securityManager.user = user;
      vm.securityManager.save().then(securityManager => {
        notificationService.notifySuccess(`Manager ${securityManager.getFullName()} updated!`);
        $scope.managerProfileCtrl.updateSecurityManager(securityManager);
        vm.resetForm();
      });
    });
  };

  vm.resetForm = function() {
    vm.resetFormSubmitted();
    $scope.SecurityManagerForm.$setPristine();
    $scope.SecurityManagerForm.$setUntouched();
  };

  vm.resetFormSubmitted = () => isFormSubmitted = false;

  const initSecurityManager = function() {
    $scope.managerProfileCtrl.loadSecurityManager().then(function(loadedSecurityManager) {
      securityManagerCopy = loadedSecurityManager;
      vm.copySecurityManager();
    });
  };

  $scope.$on('$stateChangeSuccess', function() {
    return vm.resetForm();
  });

  return vm;

};

angular.module('public.security-manager.manager-profile')
  .controller('ManagerProfileBasicController', dependencies.concat(ManagerProfileBasicController));