import './shift-rules.styles/_import.css';

const dependencies = [
  '$scope', '$timeout', 'ClientServiceResource', 'activeStatus', 'redirectService', 'payRateVisibilityService',
  'RepeatableBookingShiftRule', 'dateService', 'notificationService', 'resourceChargeType'
];

const ScheduleManagerRepeatableBookingShiftRulesController = function(
  $scope, $timeout, ClientServiceResource, activeStatus, redirectService, payRateVisibilityService,
  RepeatableBookingShiftRule, dateService, notificationService, resourceChargeType
) {

  const vm = this;

  const DEFAULT_MIN_HOURS = 8;
  const DEFAULT_DELAY = 1500;

  vm.isShownResourceTipFlag = false;

  vm.$onInit = function() {
    $scope.repeatableBookingLayoutCtrl.holderWidth = 1255;
    $scope.repeatableBookingLayoutCtrl.loadRepeatableBooking().then((repeatableBooking) => {
      vm.repeatableBooking = repeatableBooking;
      initResources(repeatableBooking);
      initShiftRules();
    });
  };

  vm.isShownResourceTip = function() {
    return vm.isShownResourceTipFlag && !vm.shiftRules.length;
  };

  vm.showResourceTip = function() {
    vm.isShownResourceTipFlag = true;
  };

  vm.closeResourceTip = function() {
    vm.isShownResourceTipFlag = false;
  };

  vm.isShownChargeRate = resource => payRateVisibilityService.isShownChargeRate(resource.chargeRate);

  vm.isShownPayRate = resource => {
    return payRateVisibilityService.isShownPayRate(resource.contractorPayRate, resource.employedPayRate);
  };

  vm.addResource = function(resource) {
    vm.checkAllRulesAndApply(() => {
      const shiftRule = buildShiftRule(resource);
      vm.shiftRules.push(shiftRule);
    });
  };

  vm.checkAllRulesAndApply = function(applyCb) {
    if (isUnsavedShiftsPresent()) {
      notificationService.notifyError('Please save or delete entered data');
    } else {
      if (isOpenedShiftRulesPresent()) {
        closeAllShiftRules();
      }
      if (applyCb) {
        applyCb();
      }
    }
  };

  vm.onShiftRuleDelete = function(id) {
    _.remove(vm.shiftRules, (shiftRule) => {
      return shiftRule.id === id;
    });
  };

  vm.getSaveButtonText = function() {
    if ($scope.repeatableBookingLayoutCtrl.isEdit) {
      return 'Update booking'
    } else {
      return 'Create booking';
    }
  };

  vm.isShiftRulesFormDisabled = function() {
    if (!vm.shiftRules) { return; }
    return !vm.shiftRules.length || isNewShiftsPresent();
  };

  vm.onSubmit = function() {
    if (isUnsavedShiftsPresent()) {
      notificationService.notifyError('Please save or delete entered data');
    } else {
      $scope.repeatableBookingLayoutCtrl.goNext();
    }
  };

  const closeAllShiftRules = function() {
    _.each(vm.shiftRules, (shiftRule) => {
      shiftRule.isOpen = false;
    });
  };

  const isUnsavedShiftsPresent = function() {
    return _.some(vm.shiftRules, (shiftRule) => {
      return shiftRule.isChanged;
    });
  };

  const isNewShiftsPresent = function() {
    return _.some(vm.shiftRules, (shiftRule) => {
      return shiftRule.id < 0;
    });
  };

  const isOpenedShiftRulesPresent = function() {
    return !_.every(vm.shiftRules, (shiftRule) => {
      return !shiftRule.isOpen;
    });
  };

  const initShiftRules = function() {
    if ($scope.repeatableBookingLayoutCtrl.repeatableBookingId) {
      RepeatableBookingShiftRule.query({}, {
        repeatableBookingId: $scope.repeatableBookingLayoutCtrl.repeatableBookingId
      }).then((shiftRules) => {
        _.each(shiftRules, (shiftRule) => {
          shiftRule.repeatableBookingId = $scope.repeatableBookingLayoutCtrl.repeatableBookingId;
        });
        vm.shiftRules = shiftRules;
        $timeout(() => {
          vm.showResourceTip();
        }, DEFAULT_DELAY);
      });
    } else {
      vm.shiftRules = [];
    }
  };

  const buildShiftRule = function(resource) {
    const shiftRule = new RepeatableBookingShiftRule({
      id: -(vm.shiftRules.length + 1),
      repeatableBookingId: $scope.repeatableBookingLayoutCtrl.repeatableBookingId,
      resourceId: resource.id,
      resource
    });
    shiftRule.versions = [{
      name: 'Shift title',
      startTime: getShiftRuleStartTime(),
      endTime: getShiftRuleEndTime(resource),
      frequencyEvery: 1,
      frequencyOn: 1,
      startFromWeek: 1,
      locationId: vm.repeatableBooking.locationId,
      location: vm.repeatableBooking.location,
      unpaidBreakInMinutes: 0,
      unchargedBreakInMinutes: 0,
      resourcesAmount: 1,
      chargeRate: resource.chargeRate,
      contractorPayRate: resource.contractorPayRate,
      employedPayRate: resource.employedPayRate,
      enableClockIn: !resourceChargeType.isPerUnit(resource.chargeType) && vm.repeatableBooking.enableClockIn,
      equipment: '',
      comments: []
    }];
    return shiftRule;
  };

  const getShiftRuleStartTime = function() {
    return moment().startOf('day').format(dateService.getTimeFormat());
  };

  const getShiftRuleEndTime = function(resource) {
    let minHours = DEFAULT_MIN_HOURS;
    if (resource.minHours) {
      minHours = resource.minHours
    }
    return moment().startOf('day').add(minHours, 'hours').format(dateService.getTimeFormat())
  };

  const initResources = function(repeatableBooking) {
    return ClientServiceResource.query({
      active_status: activeStatus.getActiveStatus()
    }, {
      clientId: repeatableBooking.clientId,
      serviceId: repeatableBooking.serviceId,
    }).then(resources => vm.resources = resources);
  };

  return vm;

};

angular.module('public.security-manager.schedule-manager.repeatable-booking')
  .controller('ScheduleManagerRepeatableBookingShiftRulesController',
    dependencies.concat(ScheduleManagerRepeatableBookingShiftRulesController)
  );
