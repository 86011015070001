const gccsNoteList = {
  controller: 'NoteListController',
  template: require('./template.pug'),
  bindings: {
    clientId: '<',
    employeeId: '<',
    editable: '<'
  }
};

angular.module('public.note').component('gccsNoteList', gccsNoteList);
