const dependencies = ['AppModel'];

const RepeatableBooking = AppModel => {

  class RepeatableBooking extends AppModel {

    static initClass() {
      this.configure({ url: '/repeatable_bookings', name: 'repeatable_booking' });
    }

    static clone(repeatableBookingId) {
      return this.$post([this.resourceUrl(), repeatableBookingId, 'clone'].join('/'));
    }

    static createFrom(repeatableBookingId) {
      return this.$post([this.resourceUrl(), repeatableBookingId, 'create_from'].join('/'));
    }

    finish() {
      return this.$put(this.getUrl() + '/finish');
    }

    clearAfterDate(date) {
      return this.$delete(this.getUrl() + '/clear_after_date', { date });
    }

  }

  RepeatableBooking.initClass();

  return RepeatableBooking;

};

angular.module('data.repeatable-booking').factory('RepeatableBooking', dependencies.concat(RepeatableBooking));
