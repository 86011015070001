/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$state', '$scope', '$timeout', '$templateCache', 'DemoRequest', 'validationService', 'gccsConfig',
  'anchorSmoothScroll', 'landingUiInteractor', '$interval'
];

const LandingController = function(
  $state, $scope, $timeout, $templateCache, DemoRequest, validationService, gccsConfig,
  anchorSmoothScroll, landingUiInteractor, $interval
) {

  const vm = this;

  $scope.applicationCtrl.isLanding = true;

  vm.validationService = validationService;
  vm.isPrivacyPolicyAccepted = false;
  vm.demoRequest = new DemoRequest();
  vm.isLoading = false;
  vm.isSuccess = false;
  vm.isPageLoaded = false;
  vm.landingUiInteractor = landingUiInteractor;
  vm.openedMobileFeaturesItemId = 0;
  vm.activeFeaturesItem = 0;
  vm.headerItems = [{
    title: 'Features',
    anchor: 'section__features'
  }, {
    title: 'Scheduling',
    anchor: 'section__scheduling'
  }, {
    title: 'Staff Engagement',
    anchor: 'section__staff'
  }, {
    title: 'Use Cases',
    anchor: 'section__security'
  }, {
    title: 'Contact',
    anchor: 'section__book'
  }];

  validationService.setRegexRules(gccsConfig.regex);

  vm.$onInit = function() {
    requirePartials();
    checkStylesheetsApplying();
  };

  vm.onBookDemoSubmit = function(form) {
    if (form.$invalid || vm.isLoading || vm.isSuccess) { return; }
    vm.isLoading = true;
    return vm.demoRequest.save().then(() => vm.isSuccess = true).finally(() => vm.isLoading = false);
  };

  vm.getCurrentYear = () => new Date().getFullYear();

  vm.gotoElement = function(eID) {
    if ($state.is('landing.index')) {
      anchorSmoothScroll.scrollToId(eID);
      vm.landingUiInteractor.closeMobileMenu();
    } else {
      $state.go('landing.index', { scrollAnchor: eID });
    }
  };

  vm.openMobileFeaturesMenuItem = id => vm.openedMobileFeaturesItemId = id;

  vm.setActiveFeaturesItem = id => vm.activeFeaturesItem = id;

  const checkStylesheetsApplying = function() {
    let loadingCSSInterval;
    return loadingCSSInterval = $interval(function() {
      const el = document.querySelector('.special-check-css-applied');
      if (el && (getComputedStyle(el).cursor === 'help')) {
        vm.isPageLoaded = true;
        return $interval.cancel(loadingCSSInterval);
      }
    }, 100);
  };

  const onLandingStateChange = function(event, toState, toParams) {
    $timeout(() => {
      window.scrollTo(0, 0);
      if (toParams.scrollAnchor) {
        vm.gotoElement(toParams.scrollAnchor);
      }
    });
  };

  const requirePartials = function() {
    const folder = 'landing/_partials';
    [
      'features', 'footer', 'header', 'privacy-policy-popup', 'promo',
      'request-demo-form', 'scheduling', 'security', 'staff', 'subscription'
    ].forEach(function(partialName) {
      $templateCache.put(`${folder}/${partialName}`, require(`../${folder}/${partialName}.pug`));
    });
  };

  $scope.$on('$stateChangeSuccess', onLandingStateChange);

  return vm;

};

angular.module('landing').controller('LandingController', dependencies.concat(LandingController));
