/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').factory('BookingShiftLock', ['AppModel', (AppModel) => {

  class BookingShiftLock extends AppModel {
    static initClass() {
      this.configure({url : '/bookings/{{bookingId}}/shifts/{{shiftId}}/locks/{{id}}', name : 'lock'});
    }
  }

  BookingShiftLock.initClass();
  return BookingShiftLock;
}

]);
