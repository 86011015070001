/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'EntityManager', 'EmployeeLicense', 'documentTypeIconService'];

const EmployeeProfileLicensesController = function($scope, EntityManager, EmployeeLicense, documentTypeIconService) {

  const vm = this;

  vm.isShownViewPopup = false;
  vm.ascendingNameOrder = true;
  vm.documentTypeIcon = documentTypeIconService;

  const entityManager = new EntityManager(EmployeeLicense, {
    params: {
      employeeId: $scope.employeeProfileLayoutCtrl.employeeId
    }
  });

  $scope.employeeProfileLayoutCtrl.loadEmployee().then(employee => {
    vm.employee = employee;
  });

  vm.$onInit = function() {
    entityManager.loadAll();
  };

  vm.showViewPopup = function(license) {
    vm.popupViewLicense = license;
    vm.isShownViewPopup = true;
  };

  vm.closeViewPopup = () => vm.isShownViewPopup = false;

  vm.getLicenses = () => entityManager.entities;

  vm.toggleNameOrder = () => vm.ascendingNameOrder = !vm.ascendingNameOrder;

  vm.isPassed = function(license) {
    return !license.expireDate || license.isActive;
  };

  vm.isExpired = function(license) {
    return !license.isActive;
  };

  vm.getName = function(license) {
    let { name } = license;
    if (license.training) { name += ` (${license.training.resource.name})`; }
    return name;
  };

  return vm;

};

angular.module('public.employee.profile')
  .controller('EmployeeProfileLicensesController', dependencies.concat(EmployeeProfileLicensesController));
