/*
 * @author Igor Serpak <robobee>
 */
const gccsSecurityManagerNotificationsHeadline = {
  template: require('./template.pug'),
  bindings: {
    tabs: '<',
    selectTab: '&'
  }
};

angular.module('public.security-manager.notifications').component('gccsSecurityManagerNotificationsHeadline',
  gccsSecurityManagerNotificationsHeadline);
