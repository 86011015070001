const dependencies = [
  '$scope', 'BookingComment', 'BookingClockComment', 'EntityManager', 'currentUserService',
  'bookingCommentVisibleTo', 'cableService', 'bookingClockCommentPreparer'
];

const PopupBookingInfoCommentsController = function(
  $scope, BookingComment, BookingClockComment, EntityManager, currentUserService,
  bookingCommentVisibleTo, cableService, bookingClockCommentPreparer
) {

  const vm = this;

  vm.currentUser = currentUserService.getCurrentUser();

  let isSending = false;

  const commentManager = new EntityManager(BookingComment, {
    params: {
      bookingId: $scope.booking.id
    },
    prepare: {
      userId: vm.currentUser.id
    }
  });

  vm.$onInit = function() {
    commentManager.loadAll();
    initBookingClockComments();
    createNewComment();
    subscribeToBookingTimeline();
  };

  vm.$onDestroy = function() {
    vm.getMergedComments().forEach((commentEntity) => {
      if (commentEntity.isForDelete) {
        vm.removeComment(commentEntity);
      }
    });
  };

  vm.getVisibleToText = bookingCommentVisibleTo.getVisibleToText;

  vm.markForDelete = comment => {
    comment.isForDelete = true;
  };

  vm.removeComment = comment => {
    commentManager.removeEntity(
      comment,
      () => {
        if (!comment.isClockComment) {
          $scope.onCommentsCountChange({ count: $scope.booking.commentsCount - 1 });
        }
      },
      {},
      false
    );
  };

  vm.getComments = () => commentManager.entities;

  vm.onSubmit = function () {
    let commentText;
    if (vm.newComment.text) {
      commentText = vm.newComment.text.replace(/&nbsp;/g, ' ');
    } else {
      commentText = '';
    }
    if (!$scope.BookingCommentForm.$valid || !/\S/.test(commentText) || isSending) {
      return;
    }
    isSending = true;
    vm.newComment.text = commentText;
    return vm.newComment.save().then(function (comment) {
      commentManager.updateEntity(comment);
      createNewComment();
      return isSending = false;
    });
  };

  vm.isShownClientVisibility = () => !currentUserService.isClientManagerLogged();

  vm.isShownVisibleOptions = () => currentUserService.isSecurityManagerLogged();

  vm.isShownDeleteComments = function (comment) {
    if (currentUserService.isSecurityManagerLogged()) {
      if (currentUserService.getCurrentProfile().isSupervisor()) {
        return false;
      } else if (currentUserService.getCurrentProfile().isManager()) {
        return comment.user.id === currentUserService.getCurrentProfile().userId;
      } else {
        return true;
      }
    } else if (currentUserService.isEmployeeLogged()) {
      return comment.user.id === currentUserService.getCurrentProfile().userId;
    } else if (currentUserService.isClientManagerLogged()) {
      return comment.user.id === currentUserService.getCurrentProfile().userId;
    } else {
      return false;
    }
  };

  vm.undoDelete = function(comment) {
    comment.isForDelete = false;
  };

  vm.getMergedComments = function() {
    return _.orderBy(_.concat(vm.getComments(), vm.clockComments), 'createdAt', 'asc');
  };

  const subscribeToBookingTimeline = () => {
    cableService.getBookingTimelineChannel().addCallbackWithCleanup($scope, 'comment_booking', function (data) {
      if (data.commentable_entity_id === $scope.booking.id) {
        commentManager.loadOne(data.comment_id).then(() => {
          $scope.onCommentsCountChange({ count: vm.getComments().length });
        });
      }
    });
  };

  const createNewComment = function () {
    vm.newComment = commentManager.createEntity();
    vm.newComment.visibleToClient = true;
  };

  const initBookingClockComments = function() {
    vm.clockComments = [];
    BookingClockComment.query({}, { bookingId: $scope.booking.id }).then(function(clockComments) {
      vm.clockComments = bookingClockCommentPreparer.prepareClockComments(clockComments);
    });
  };

  return vm;

};

angular.module('popup.booking')
  .controller('PopupBookingInfoCommentsController', dependencies.concat(PopupBookingInfoCommentsController));
