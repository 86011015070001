const PaginatorService = function() {

  class Paginator {

    constructor(elements_per_page = 20) {
      this.elements_per_page = elements_per_page;
      this.offset = -this.elements_per_page;
    }

    resetOffset() {
      return this.offset = -this.elements_per_page;
    }

    params() {
      return {offset : this.offset};
    }

    nextPageParams() {
      this._nextPage();
      return this.params();
    }

    incrementOffset() {
      return this.offset += 1;
    }

    decrementOffset() {
      if (this.offset > 0) {
        return this.offset -= 1;
      }
    }

    _nextPage() {
      return this.offset += this.elements_per_page;
    }
  }

  return Paginator;
};

angular.module('shared').factory('PaginatorService', PaginatorService);
