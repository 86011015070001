/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').service('keyListenerService', ['keyService', 'elementService', function(keyService, elementService) {

  const _findFocusElement = function(tabIndexElements, reversed) {
    let defaultIndex, elementToFocus, increment;
    const focusedElement = document.querySelector(':focus');
    if (reversed) {
      increment = -1;
      defaultIndex = tabIndexElements.length - 1;
    } else {
      increment = 1;
      defaultIndex = 0;
    }
    for (let index = 0; index < tabIndexElements.length; index++) {
      const tabIndexElement = tabIndexElements[index];
      if (tabIndexElement.isEqualNode(focusedElement)) {
        elementToFocus = tabIndexElements[index + increment];
        break;
      }
    }
    return elementToFocus || (elementToFocus = tabIndexElements[defaultIndex]);
  };

  const _focusTabElement = function(tabElement) {
    const transformer = tabElement.closest('[gccs-input-transformer]');
    if (transformer) {
      return transformer.children[transformer.childElementCount - 2].click();
    } else {
      return elementService.focus(tabElement);
    }
  };

  return {
    initTabElementListener() {
      return document.onkeydown = function(event) {
        if (keyService.tabPressed(event)) {
          const tabIndexElements = document.querySelectorAll('[tab-element]');
          if (tabIndexElements.length > 0) {
            const elementToFocus = _findFocusElement(tabIndexElements, event.shiftKey);
            if (elementToFocus) {
              event.preventDefault();
              return _focusTabElement(elementToFocus);
            }
          }
        }
      };
    }
  };
}]);
