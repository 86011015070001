/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$timeout', 'Country', 'SecurityProviderSystemPackage', 'SecurityProviderStripeCustomer', 'Employee',
  'currentUserService', 'stripeService', 'notificationService', 'cableService', 'packagePaymentStatusService',
  'packageTypeService', 'employeeStatus', 'packageDescriptionService', 'systemPackagePopupService',
  'dateService'
];

const PopupSystemPackageBillingController = function(
  $scope, $timeout, Country, SecurityProviderSystemPackage, SecurityProviderStripeCustomer, Employee,
  currentUserService, stripeService, notificationService, cableService, packagePaymentStatusService,
  packageTypeService, employeeStatus, packageDescriptionService, systemPackagePopupService,
  dateService
) {

  const vm = this;

  const MANUAL_TAB_FRONT = 1;
  const MANUAL_TAB_CVV = 2;

  const STRIPE_ELEMENT_NUMBER = 'number';
  const STRIPE_ELEMENT_EXPIRATION = 'expiration';
  const STRIPE_ELEMENT_CVC = 'cvc';

  vm.manualTabs = [{
    id: MANUAL_TAB_FRONT,
    text: 'Front',
    imgSrc: '/images/credit-card-front.png'
  }, {
    id: MANUAL_TAB_CVV,
    text: 'CVV',
    imgSrc: '/images/credit-card-back.png'
  }];
  vm.isShownCardForm = false;
  vm.isShownPaymentLoader = false;

  let cardElements;
  let activeEmployeesCount = 0;
  let isFormSubmitted = false;
  let cardDomElements = {};
  let cardValidations = {};
  let oneTimeError;
  let currentCustomer;

  vm.$onInit = function() {
    loadEmployeesCount();
    loadCurrentCardInfo();
    loadCurrentCountry();
    initCableChannel();
    vm.isShownPaymentLoader = $scope.isShownPaymentLoader;
  };

  vm.showCardForm = function() {
    vm.isShownCardForm = true;
  };

  vm.showPackageFormPopup = function() {
    $scope.popupCtrl.closePopup(() => {
      $scope.popupCloseCb({ showPackageFormPopup: true });
    });
  };

  vm.subscribe = function() {
    oneTimeError = null;
    if (
      $scope.SubscriptionForm.$valid &&
      (!vm.isShownCardForm || isValidStripe()) &&
      vm.licensesCount >= activeEmployeesCount
    ) {
      if (isFormSubmitted) { return; }
      isFormSubmitted = true;
      vm.isShownPaymentLoader = true;
      systemPackagePopupService
        .getSystemPackageDowngrade()
        .deactivateRestForFreePackage()
        .then(function() {
          if (vm.isShownCardForm) {
            stripeService.createToken(cardElements.number, { name: vm.cardholderName }).then(function(result) {
              if (result.token) {
                updateSubscription(result.token);
              } else if (result.error) {
                showStripeError(result.error);
              }
            });
          } else {
            updatePackage(currentCustomer);
          }
        });
    }
  };

  vm.isPaidPackageType = function() {
    return !packageTypeService.hasFreePackage(vm.packageType);
  };

  vm.getPackageTitle = function() {
    return packageDescriptionService.getDescriptionFor(vm.packageType).title;
  };

  vm.openCardManualPopup = function() {
    initDefaultTab();
    vm.isShownCardManualPopup = true;
  };

  vm.closeCardManualPopup = function() {
    vm.isShownCardManualPopup = false;
  };

  vm.selectTab = function(tab) {
    vm.chosenTab = tab;
  };

  vm.stripeNumberIsInvalid = function() {
    return stripeElementIsInvalid(STRIPE_ELEMENT_NUMBER);
  };

  vm.stripeExpirationIsInvalid = function() {
    return stripeElementIsInvalid(STRIPE_ELEMENT_EXPIRATION);
  };

  vm.stripeCvcIsInvalid = function() {
    return stripeElementIsInvalid(STRIPE_ELEMENT_CVC);
  };

  vm.getStripeError = function() {
    if (oneTimeError) {
      return oneTimeError;
    }
    for (let field in cardValidations) {
      if (cardValidations[field].error) {
        return cardValidations[field].error.message;
      }
    }
  };

  vm.hasLicensesCountError = function() {
    return vm.licensesCount < activeEmployeesCount;
  };

  vm.getCurrentActiveEmployeesText = function() {
     let currentActiveEmployeesText = `${activeEmployeesCount} active employee`;
     if (activeEmployeesCount > 1) {
       currentActiveEmployeesText += 's';
     }
     return currentActiveEmployeesText;
  };

  vm.onPopupClose = function() {
    if (vm.isShownPaymentLoader) { return; }
    $scope.popupCtrl.closePopup();
  };

  vm.increaseLicensesNumber = function(value) {
    vm.licensesCount = Math.max(0, vm.licensesCount + value);
  };

  const loadEmployeesCount = function() {
    Employee.getCount({}, { status: employeeStatus.getActiveStatus() }).then(function(data) {
      activeEmployeesCount = data.count;
      vm.licensesCount = activeEmployeesCount;
    });
  };

  const loadCurrentCardInfo = function() {
    SecurityProviderStripeCustomer.getCurrent().then(function(stripeCustomer) {
      if(stripeCustomer) {
        currentCustomer = stripeCustomer;
        if (isExpiredCard(stripeCustomer.expireDate)) {
          vm.showCardForm();
        } else {
          vm.cardLast4 = stripeCustomer.cardLast4;
        }
      }
      else {
        vm.showCardForm();
      }
    }).catch(function() {
      vm.showCardForm();
    });
  };

  const showStripeError = function(error) {
    vm.isShownPaymentLoader = false;
    isFormSubmitted = false;
    oneTimeError = error;
  };

  const initDefaultTab = function() {
    vm.chosenTab = _.first(vm.manualTabs);
  };

  const updateSubscription = function(token) {
    new SecurityProviderStripeCustomer({
      securityProviderId: currentUserService.getCurrentProfile().securityProvider.id,
      token_id: token.id,
      card_data: token.card
    }).save().then(function(stripeCustomer) {
      updatePackage(stripeCustomer);
    }).catch(function(result) {
      showStripeError(result.data.error);
    });
  };

  const updatePackage = function(stripeCustomer) {
    return new SecurityProviderSystemPackage({
      id: getSystemPackageId(),
      securityProviderId: currentUserService.getCurrentProfile().securityProvider.id,
      packageType: vm.packageType,
      licensesCount: vm.licensesCount
    }).updatePackage().then(function(systemPackage) {
      systemPackage.customerId = stripeCustomer.id;
      systemPackage.subscribe().then(function(result) {
        if (!packagePaymentStatusService.isInProcess(result.paymentStatus)) {
          vm.isShownPaymentLoader = false;
          $scope.popupCloseCb();
          showSuccess();
        }
      }).catch(function(result) {
        showStripeError(result.data.error);
      });
    });
  };

  const initSelectedPackage = function() {
    vm.packageType = $scope.packageType;
    vm.pricePerEmployee = packageTypeService.getPricePerEmployee(vm.packageType);
  };

  const initStripe = function() {
    vm.cardholderName = '';
    cardElements = stripeService.createCardElements();
    $timeout(function() {
      mountStripeElement(STRIPE_ELEMENT_NUMBER);
      mountStripeElement(STRIPE_ELEMENT_EXPIRATION);
      mountStripeElement(STRIPE_ELEMENT_CVC);
    });
  };

  const mountStripeElement = function(field, elementSelector = `[data-card-${field}]`) {
    const cardNumberElement = document.querySelector(elementSelector);
    cardElements[field].mount(cardNumberElement);
    cardDomElements[field] = cardNumberElement;
    cardValidations[field] = {
      complete: false,
      error: false
    };
    cardElements[field].on('change', function(event) {
      $timeout(function() {
        cardValidations[field] = {
          complete: event.complete,
          error: event.error
        };
      });
    });
  };

  const isValidStripe = function() {
    return _.every(cardValidations, function(cardValidation) {
      return cardValidation.complete;
    });
  };

  const stripeElementIsInvalid = function(cardElementType) {
    if (cardValidations[cardElementType]) {
      return cardValidations[cardElementType].error || (
        $scope.SubscriptionForm.$submitted &&
        !cardValidations[cardElementType].complete
      );
    } else {
      return false;
    }
  };

  const onPaymentStatusChange = function(paymentStatus) {
    vm.isShownPaymentLoader = false;
    $scope.popupCloseCb();
    if (packagePaymentStatusService.isPaid(paymentStatus)) {
      showSuccess();
    } else if (packagePaymentStatusService.isFailed(paymentStatus)) {
      systemPackagePopupService.showErrorPopup(
        'There is a problem with your payment',
        'Please contact your bank or update billing info.'
      )
    }
  };

  const initCableChannel = function() {
    cableService.connect(currentUserService.getCurrentProfile().userId);
    cableService.addDelayCb(function() {
      const channel = cableService.createSystemPackageChannel(getSystemPackageId());
      channel.addCallback('payment_status', function(data) {
        $timeout(function() {
          onPaymentStatusChange(data.payment_status);
        });
      });
    });
    const onDestroy = function() {
      cableService.destroySystemPackageChannel();
      const channel = cableService.getSystemPackageChannel();
      if (channel) {
        channel.disconnect();
      }
    };
    $scope.$on('$destroy', onDestroy);
    window.onunload = onDestroy;
  };

  const getSystemPackageId = function() {
    return currentUserService.getCurrentProfile().securityProvider.systemPackage.id;
  };

  const showSuccess = function() {
    systemPackagePopupService.showSuccessPopup(
      'Payment confirmed',
      'Thank you! Your payment was successful and your subscription is now active',
      $scope.onSuccessCb
    );
  };

  const loadCurrentCountry = function() {
    Country.getCurrent().then(function(country) {
      vm.currentCountry = country;
    });
  };

  const isExpiredCard = function(expireDate) {
    const today = dateService.today();
    return expireDate.year < today.year() || (expireDate.year === today.year() && expireDate.month <= today.month() + 1);
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      initSelectedPackage();
      vm.closeCardManualPopup();
      if (vm.isPaidPackageType()) {
        initStripe();
      }
    }
  });

  return vm;

};

angular.module('popup.system-package')
  .controller('PopupSystemPackageBillingController', dependencies.concat(PopupSystemPackageBillingController));
