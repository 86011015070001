const employeeTimelineExtendedFilter = {
  controller: 'EmployeeTimelineExtendedFilterController',
  controllerAs: 'filterCtrl',
  template: require('./template.pug'),
  bindings: {
    applyFilterCb: '&',
    filterStringChangedCb: '&'
  }
};

angular.module('public.security-manager.schedule-manager.timeline')
  .component('employeeTimelineExtendedFilter', employeeTimelineExtendedFilter);
