const dependencies = [
  'BookingShiftEmployeeRoot', 'resourceIcon', 'bookingShiftIssueType', 'notificationService'
];

const LiveFeedDetailsIssue = function(
  BookingShiftEmployeeRoot, resourceIcon, bookingShiftIssueType, notificationService
) {

  const vm = this;

  vm.isShownClockPopup = false;
  vm.isShownClockInEdit = false;
  vm.isShownClockOutEdit = false;

  vm.$onInit = function() {
    initShiftEmployee();
    initData();
  };

  vm.openClockPopup = function() {
    vm.editedShiftEmployee = new BookingShiftEmployeeRoot(vm.shiftEmployee);
    vm.clockEditSuccessMessage = 'Shift issue resolved';
    switch (vm.shiftIssue.type) {
      case bookingShiftIssueType.getMissedClockIn():
        vm.isShownClockInEdit = true;
        vm.isShownClockOutEdit = false;
        vm.clockInEditTitle = 'Add clock in time';
        vm.clockInEditButton = 'Clock in';
        break;
      case bookingShiftIssueType.getMissedClockOut():
        vm.isShownClockInEdit = false;
        vm.isShownClockOutEdit = true;
        vm.clockInEditTitle = 'Add clock out time';
        vm.clockInEditButton = 'Clock out';
        break;
    }
    vm.isShownClockPopup = true;
  };

  vm.closeClockPopup = function() {
    vm.isShownClockPopup = false;
  };

  vm.approveShiftCancellation = function() {
    new BookingShiftEmployeeRoot({ id: vm.shiftEmployee.id }).approveShiftCancellation().then(() => {
      notifySuccessShiftIssueResolve();
    });
  };

  vm.declineShiftCancellation = function() {
    new BookingShiftEmployeeRoot({ id: vm.shiftEmployee.id }).rejectShiftCancellation().then(() => {
      notifySuccessShiftIssueResolve();
    });
  };

  vm.manualCheckIn = function() {
    new BookingShiftEmployeeRoot({ id: vm.shiftEmployee.id, time: vm.dShiftIssue.issueTime }).checkIn().then(() => {
      notifySuccessShiftIssueResolve();
    });
  };

  vm.openShiftIssuePopup = function() {
    if (bookingShiftIssueType.isMissingSplit(vm.shiftIssue.type)) {
      vm.onOpenShiftIssuePopup({
        options: {
          isInviteEmployees: true,
          shouldGoToInvited: true
        }
      });
    } else {
      vm.onOpenShiftIssuePopup({
        options: {
          isInviteEmployees: true,
        }
      });
    }
  };

  const notifySuccessShiftIssueResolve = function() {
    notificationService.notifySuccess('Shift issue resolved');
  };

  const initShiftEmployee = function() {
    if (vm.shiftIssue.employeeId) {
      vm.shiftEmployee = _.find(vm.shift.approvedShiftEmployees, ['employee.id', vm.shiftIssue.employeeId]);
      if (vm.shiftEmployee) {
        vm.employee = vm.shiftEmployee.employee;
      }
    }
  };

  const initData = function() {
    vm.issueSubject = vm.employee ? vm.employee.fullName : vm.shift.resource.name;
    vm.avatarEntity = vm.employee ? vm.employee.thumbAvatar : vm.shift.resource;
    prepareResourceIcon();
    vm.isShownAvatarIcon = vm.employee ? !vm.avatarEntity.url : !vm.avatarEntity.class && !vm.avatarEntity.url;
    vm.isShownMissedClockInActions = bookingShiftIssueType.isMissedClockIn(vm.shiftIssue.type) ||
      bookingShiftIssueType.isMissedClockOut(vm.shiftIssue.type);
    vm.isShownUnassignedShiftActions = bookingShiftIssueType.isUnassignedShift(vm.shiftIssue.type) ||
      bookingShiftIssueType.isMissingSplit(vm.shiftIssue.type);
    vm.isShownCancelRequestActions = bookingShiftIssueType.isCancelRequest(vm.shiftIssue.type);
    vm.isShownMissedCheckInActions = bookingShiftIssueType.isMissedCheckIn(vm.shiftIssue.type);
    if (vm.isShownMissedClockInActions) {
      vm.clockInActionsButtonText = bookingShiftIssueType.isMissedClockIn(vm.shiftIssue.type) ? 'Clock in to shift' : 'Clock out to shift';
    }
  };

  const prepareResourceIcon = function() {
    const icon = vm.avatarEntity.icon;
    if (icon) {
      if (resourceIcon.isUploadedType(icon.iconType)) {
        vm.avatarEntity.url = icon.icon.url;
      } else {
        vm.avatarEntity.class = resourceIcon.getIconClass(icon.iconType);
      }
    }
  };

  return vm;

};

angular.module('public.security-manager.schedule-manager.timeline')
  .controller('LiveFeedDetailsIssueController', dependencies.concat(LiveFeedDetailsIssue));
