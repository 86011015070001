const dependencies = ['dateService'];

const FlexibleTimeLineConverter = dateService => {

  let MINUTES_IN_HOUR = 60;
  let HOURS_IN_DAY = 24;
  let MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR;

  return class FlexibleTimeLineConverter {

    constructor(view, hourWidth) {
      this.setOffsets(view);
      this.setHourWidth(hourWidth);
    }

    setOffsets(view) {
      this.offsetCount = view.offsetCount;
      this.offset = view.offset;
    }

    setHourWidth(hourWidth) {
      this.hourWidth = hourWidth;
      this.minutesPerPixel = MINUTES_IN_HOUR / this.hourWidth;
    }

    getNumberOfIntervalsForDays(numDays) {
      return this.offsetCount + (numDays * HOURS_IN_DAY) + this.offsetCount;
    }

    getInitialOffset() {
      return this.offset;
    }

    getOutset() {
      return this.offsetCount * this.hourWidth;
    }

    getHourWidth() {
      return this.hourWidth;
    }

    getDaysWidth(daysCount) {
      return (daysCount * MINUTES_IN_DAY) / this.minutesPerPixel;
    }

    getTotalOffset(offset) {
      return this.getInitialOffset() + offset;
    }

    getOffsetForDays(days) {
      return this.getTotalOffset(this.getDaysWidth(days));
    }

    getTimelineItemOffset(startTime, timeLineStart) {
      return this.getOffsetFromTime(startTime, timeLineStart);
    }

    getTimeFromOffset(offset, from, withInitialOffset) {
      if (withInitialOffset == null) { withInitialOffset = true; }
      if (!withInitialOffset) { offset -= this.getInitialOffset(); }
      if (offset < 0) { offset = 0; }
      const date = from ? from.clone() : dateService.today();
      return date.add(offset * this.minutesPerPixel, 'minutes');
    }

    getOffsetFromTime(time, from) {
      if (!from) { from = dateService.today(); }
      if (!time) { time = moment(); }
      let datesDiff = time.diff(from, 'minutes') + time.utcOffset() - from.utcOffset();
      return datesDiff / this.minutesPerPixel;
    }

  }

};

angular.module('public.timeline').factory('FlexibleTimeLineConverter', dependencies.concat(FlexibleTimeLineConverter));
