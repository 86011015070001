/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$state', 'Session', 'currentUserService', 'storageService',
  'defaultConfig', 'systemPackagePopupService', 'packagePaymentStatusService'
];

const SignPaymentMessageUnpaidController = function(
  $scope, $state, Session, currentUserService, storageService,
  defaultConfig, systemPackagePopupService, packagePaymentStatusService
) {

  const vm = this;

  vm.packageErrorData = $scope.signPaymentMessageCtrl.packageErrorData;
  vm.systemPackagePopupService = systemPackagePopupService;

  vm.tempProfileLoaded = false;

  vm.$onInit = function() {
    if (currentUserService.getCurrentSession()) {
      onProfileLoad();
    } else {
      Session.getInstance().then(function(currentSession) {
        currentUserService.setCurrentUserAndSession(currentSession);
        onProfileLoad();
      });
    }
  };

  vm.redirectToLogin = function() {
    storageService.remove(defaultConfig.sessionKey);
    $state.go('sign.login');
  };

  const onProfileLoad = function() {
    vm.tempProfileLoaded = true;
    const systemPackage = currentUserService.getCurrentProfile().securityProvider.systemPackage;
    if (packagePaymentStatusService.isInProcess(systemPackage.paymentStatus)) {
      vm.isPaymentInProcess = true;
      vm.systemPackagePopupService.showBillingPopup(systemPackage.packageType);
    }
  };

  return vm;
};

angular.module('sign.payment-message')
  .controller('SignPaymentMessageUnpaidController', dependencies.concat(SignPaymentMessageUnpaidController));
