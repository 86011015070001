/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'validationService'];

const ManagerClientDetailsSummaryController = function($scope, validationService) {

  const vm = this;

  vm.validationService = validationService;

  vm.copyClient = () => vm.client = angular.copy($scope.client);

  vm.saveClient = () =>
    vm.client.save().then(() => angular.copy(vm.client, $scope.client))
  ;

  $scope.$on('manager.client.update', (event, client) => vm.client = angular.copy(client));

  vm.copyClient();

  return vm;
};

angular.module('public.client-manager.client').controller('ManagerClientDetailsSummaryController',
  dependencies.concat(ManagerClientDetailsSummaryController));
