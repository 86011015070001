/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [];

let FilterOption = function() {

  return class FilterOption {

    constructor(option) {
      this.label = option.label;
      this.id = option.id;
      this.parentId = option.parentId;
      this.tag = option.tag;
      this.isSelected = !!option.isSelected;
      if (option.status !== undefined) {
        this.status = option.status;
      }
      if (option.isVisible) {
        this.isVisible = true;
      }
    }

    select() {
      this.isSelected = true;
    }

    deselect() {
      this.isSelected = false;
    }
    
  };
  
};

angular.module('public.security-manager.schedule-manager.timeline').service('FilterOption', FilterOption);
