angular.module('data.rate-change').factory('RateChange', ['AppModel', function(AppModel) {

  class RateChange extends AppModel {

    static initClass() {
      this.configure({ url: '/rate_changes/{{ id }}', name: 'rate_change' });
    }

  }

  RateChange.initClass();

  return RateChange;

}]);
