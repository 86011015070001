angular.module('shared').factory('VerifyAuthCodeService', ['UserPhoneCode', function(UserPhoneCode) {

  class VerifyAuthCodeService {

    constructor() {
      this.authCode = {};
      this.isCodeResent = false;
    }

    resendCode() {
      this.isCodeResent = false;
      this.sendCode(this.data).then(() => {
        this.isCodeResent = true;
      });
    };

    sendCode(data = {}) {
      let { userId, phoneNumber, countryId, resetCb, failCb } = data;
      this.data = data;
      if (resetCb) {
        resetCb();
      }
      this.authCode = {};
      return new UserPhoneCode({
        userId: userId,
        phoneNumber: phoneNumber,
        countryId: countryId
      }).save().catch(function(error) {
        if (failCb) {
          failCb(error);
        }
      });
    }

    confirmUserPhoneCode() {
      const code = _.values(this.authCode).join('');
      return UserPhoneCode.confirm(this.data.userId, code);
    }

  }

  return VerifyAuthCodeService;

}]);
