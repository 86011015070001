angular.module('popup.client').directive('gccsPopupClientManagerForm', [
  'popupDirectiveConfig',
  popupDirectiveConfig =>
    popupDirectiveConfig.configure({
      bodyTemplate: require('./template.pug'),
      scope: {
        manager: '='
      }
    })
]);
