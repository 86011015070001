/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.client-manager').factory('ClientManagerRoot', [ // todo rename except ClientManager
  'AppModel', 'managerAccessLevel',
  function(AppModel, managerAccessLevel) {

    class ClientManagerRoot extends AppModel {
      static initClass() {
        this.configure({url : '/client_managers', name : 'manager'});
      }

      isAccountOwner() {
        return managerAccessLevel.isAccountOwner(this.accessLevel);
      }

      isManager() {
        return managerAccessLevel.isManager(this.accessLevel);
      }

      isViewOnly() {
        return managerAccessLevel.isViewOnly(this.accessLevel);
      }
    }

    ClientManagerRoot.initClass();
    return ClientManagerRoot;
  }
]);
