const SecurityManagerNotificationsFilter = function() {

  return class SecurityManagerNotificationsFilter {

    constructor(options) {
      this.options = options;
      this.selectedOption = this.options[0];
      this.isOpen = false;
    }

    getSelectedScope() {
      return this.selectedOption.scope;
    }

    close() {
      return this.isOpen = false;
    }

    toggleOpen() {
      if (this.isOpen) {
        return this.close();
      } else {
        return this.isOpen = true;
      }
    }

    selectOption(option) {
      return this.selectedOption = option;
    }

    isSelectedOption(option) {
      return option === this.selectedOption;
    }
  }
  
};

angular.module('public.security-manager.notifications')
  .factory('SecurityManagerNotificationsFilter', SecurityManagerNotificationsFilter);
