const dependencies = [
  '$scope', '$element', 'confirmService', 'employeeActivityService', 'employeeDeviceType'
];

const SecurityManagerEmployeesPreviewController = function(
  $scope, $element, confirmService, employeeActivityService, employeeDeviceType
) {

  const vm = this;

  vm.employee = $scope.employee;
  vm.mobileType = employeeDeviceType.getMobile();

  vm.getEmployeeActivityLabel = function (employee) {
    return employeeActivityService.getActivityLabel(employee);
  };

  vm.resendInvite = function (employee) {
    $scope.onResendInviteClick({ employee });
  };

  vm.isNewEmployee = function (employee) {
    return employeeActivityService.isNew(employee);
  };

  vm.removeEmployee = function () {
    confirmService.confirmRemove('employee', function () {
      vm.employee.remove();
      $element.remove();
    });
  };

  vm.isHumynSynced = function (employee) {
    if(vm.hasHumynIntegration(employee))
    {
      return employee.humynSyncInfo && !employee.humynSyncInfo.hasSyncIssue;
    }
    return true;
  };

  vm.hasHumynIntegration = function(employee) {
    return (employee != null && employee.humynSyncInfo != null)
  }
  return vm;

};

angular.module('public.security-manager.hr.employees').controller('SecurityManagerEmployeesPreviewController',
  dependencies.concat(SecurityManagerEmployeesPreviewController));
