const dependencies = ['confirmService'];

const SecurityManagerServiceFormTrainingsController = function(confirmService) {

  const vm = this;

  vm.isShownAddTrainingForm = false;
  vm.serviceResourceTraining = {};

  vm.isEditedTraining = fakeId => fakeId === vm.editedTrainingId;

  vm.isVisibleAddTrainingForm = function() {
    if (!vm.service) { return; }
    return vm.isShownAddTrainingForm || (!vm.hasSomeTrainings() && vm.service.resources.length);
  };

  vm.isShownAddTrainingButton = function() {
    if (!vm.service) { return; }
    return vm.hasSomeTrainings() && !vm.hasEveryTraining() && vm.isEditable;
  };

  vm.getTrainingsList = trainings => _.map(trainings, 'name').join(', ');

  vm.setEditResourceTrainingMode = function(resource) {
    vm.editedTrainingId = resource.fakeId;
    vm.updateResourceSelect();
    vm.serviceResourceTraining = angular.copy(resource);
    addHoverTextToTrainings(vm.serviceResourceTraining.trainings);
  };

  vm.turnOffEditResourceTrainingMode = () => initResourceTraining();

  vm.saveEditedTraining = function() {
    const foundResource = _.find(vm.service.resources, ['fakeId', vm.serviceResourceTraining.fakeId]);
    foundResource.trainings = angular.copy(vm.serviceResourceTraining.trainings);
    initResourceTraining();
    vm.updateResourceSelect();
  };

  vm.setAddTrainingMode = function() {
    if (vm.isShownAddTrainingForm) { return; }
    vm.updateResourceSelect();
    vm.isShownAddTrainingForm = true;
  };

  vm.addResourceTraining = function(trainingForm) {
    if (trainingForm.$invalid) {
      return trainingForm.$setSubmitted();
    } else {
      const foundResource = _.find(vm.service.resources, ['fakeId', vm.serviceResourceTraining.fakeId]);
      foundResource.trainings = angular.copy(vm.serviceResourceTraining.trainings);
      initResourceTraining();
      return vm.turnOffAddTrainingMode();
    }
  };

  vm.turnOffAddTrainingMode = function() {
    vm.isShownAddTrainingForm = false;
    return initResourceTraining();
  };

  vm.removeTraining = resource => {
    return confirmService.confirmRemove('trainings from this resource', function () {
      resource.trainings = [];
      vm.updateResourceSelect();
    });
  };

  vm.hasSomeTrainings = function() {
    if (!vm.service) { return; }
    return _.some(vm.service.resources, resource => resource.trainings.length > 0);
  };

  vm.hasEveryTraining = function() {
    if (!vm.service) { return; }
    return _.every(vm.service.resources, resource => resource.trainings.length > 0);
  };

  vm.showTrainingError = function($tag) {
    if ($tag.name.length > 100) {
      vm.trainingError = 'Maximum length is 100';
    } else {
      vm.trainingError = 'Training name must be unique';
    }
  };

  vm.onAddTraining = function ($tag) {
    $tag.hoverText = $tag.name;
    hideTrainingError();
  };

  vm.getTrimmedTrainingName = function (name, limit) {
    return `${name.slice(0, limit)}${name.length > limit ? '...' : ''}`;
  };

  vm.getTrainingComma = function (trainingsCount, index) {
    return `${trainingsCount === index + 1 ? '' : ', '}`;
  };

  const initResourceTraining = function() {
    vm.editedTrainingId = null;
    hideTrainingError();
    vm.serviceResourceTraining = {};
  };

  const addHoverTextToTrainings = function(trainings) {
    _.each(trainings, (training) => {
      training.hoverText = training.name;
    });
  };

  const hideTrainingError = function () {
    vm.trainingError = '';
  };

  return vm;

};

angular.module('public.security-manager.client.show').controller(
  'SecurityManagerServiceFormTrainingsController',
  dependencies.concat(SecurityManagerServiceFormTrainingsController)
);
