/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').filter('formatWesternTime', ['dateService', dateService =>
  function(rawTime, upcase) {
    if (rawTime) {
      return dateService.formatDateTime(rawTime, dateService.getWesternTimeFormat(upcase));
    }
  }
]);
