/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$state', 'Employee', 'validationService', 'dateService', 'pluralizeService', 'employeeHolidayService',
  'employeeValidation', 'employeeType', 'userValidation', 'currentUserService', 'packageTypeService'
];

const EmployeeProfileDetailsController = function(
  $scope, $state, Employee, validationService, dateService, pluralizeService, employeeHolidayService,
  employeeValidation, employeeType, userValidation, currentUserService, packageTypeService
) {

  const vm = this;

  vm.validationService = validationService;
  vm.pluralizeService = pluralizeService;
  vm.employeeType = employeeType;
  vm.expireMinDate = dateService.today();
  vm.employeeIsLoaded = false;

  let employee = undefined;

  vm.getBirthDateErrors = employeeValidation.getBirthDateErrors;

  vm.getEmailErrors = () => userValidation.getEmailErrors(vm.employee.user && vm.employee.user.id);

  vm.copyEmployee = () => vm.employee = angular.copy(employee);

  vm.saveEmployee = function() {
    const birthDate = moment(vm.employee.formattedBirthDate, dateService.getDateFormat());
    vm.employee.birthDate = birthDate.isValid() ? birthDate.format(dateService.getIsoDateFormat()) : null;
    return vm.employee.save().then(function() {
      prepareBirthDate(vm.employee);
      angular.copy(vm.employee, employee);
      return loadEmployeeHolidayData();
    });
  };

  vm.getFormattedEmploymentStartDate = function() {
    if (employee != null ? employee.employmentStartDate : undefined) {
      return dateService.formatDate(employee.employmentStartDate, dateService.getDateFormat());
    }
  };

  vm.pluralizeDays = days => pluralizeService.simple(days, 'day');

  vm.pluralizeHours = hours => pluralizeService.simple(hours, 'hour');

  vm.getHolidayTakenModifier = function() {
    if (vm.tookMoreHolidays()) { return 'mod-red'; }
  };

  vm.getHolidayTakenNotification = function() {
    if (vm.tookMoreHolidays()) { return 'Holiday allowance exceeded'; }
  };

  vm.updateRemainingHolidayHours = () => {
    return vm.remainingHolidayHours = vm.remainingHolidayDays * employeeHolidayService.getDefaultHolidaysHours();
  };

  vm.tookMoreHolidays = () => vm.remainingHolidayHours < 0;

  vm.isShownEmploymentType = function() {
    return vm.employeeIsLoaded && packageTypeService.hasProPackage(vm.employee.securityProvider.systemPackage.packageType);
  };

  vm.getHolidaysTitle = function() {
    if (vm.takenHolidays) {
      return 'Holidays and sick days';
    } else {
      return 'Sick days';
    }
  };

  vm.getTakenSickDays = function() {
    if (vm.employee) {
      return Math.floor(vm.employee.takenSickDayHours / employeeHolidayService.getDefaultSickDaysHours());
    }
  };

  const prepareBirthDate = function(employee) {
    const birthDate = moment(employee.birthDate, dateService.getIsoDateFormat());
    if (birthDate.isValid()) { return employee.formattedBirthDate = birthDate.format(dateService.getDateFormat()); }
  };

  const loadEmployeeHolidayData = function() {
    if (employeeType.isSelfEmployed(vm.employee.employmentType) || employeeType.isSubContractor(vm.employee.employmentType)) { return; }
    return Employee.getHolidaysData($scope.employeeProfileLayoutCtrl.employeeId).then(function(holidaysData) {
      vm.takenHolidays = holidaysData.takenHolidays;
      vm.accumulatedHours = holidaysData.accumulatedHours;
      vm.remainingHolidayHours = vm.accumulatedHours - vm.takenHolidays.hours;
      return vm.remainingHolidayDays = employeeHolidayService.getRemainingHolidayDays(
        vm.accumulatedHours,
        vm.takenHolidays.hours
      );
    });
  };

  $scope.$on('employee.profile.update', function(event, updatedEmployee) {
    employee = updatedEmployee;
    vm.copyEmployee();
    currentUserService.updateProfile(updatedEmployee);
    return vm.employeeIsLoaded = true;
  });

  $scope.employeeProfileLayoutCtrl.loadEmployee().then(function(loadedEmployee) {
    prepareBirthDate(loadedEmployee);
    employee = loadedEmployee;
    vm.copyEmployee();
    loadEmployeeHolidayData();
    return vm.employeeIsLoaded = true;
  });

  vm.hasHumynIntegration = function() {
    return (vm.employee && vm.employee.humynSyncInfo)
  }

  return vm;
};

angular.module('public.employee.profile').controller('EmployeeProfileDetailsController',
  dependencies.concat(EmployeeProfileDetailsController));
