/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['dateService'];

const PopupEmployeeTimelineShiftInfoController = function(dateService) {

  const vm = this;

  vm.openLink = link => window.open(link, '_blank').focus();

  vm.getGoogleLink = () => `//maps.google.com/?q=${vm.shiftEmployee.location.address}`;

  vm.formatTime = time => dateService.formatDateTime(time, dateService.getWesternFullDateFormat(true));

  return vm;
};

angular.module('popup.employee').controller('PopupEmployeeTimelineShiftInfoController', dependencies.concat(PopupEmployeeTimelineShiftInfoController));
