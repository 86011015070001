/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = ['employeeType'];

const employeeInvoiceLabelService = function(employeeType) {

  const getCapString = str => str[0].toUpperCase() + str.slice(1);

  const pluralizeString = str => `${str}s`;

  return {
    getEmployeesTexts(employmentType) {
      const textObj = {};
      let string = undefined;
      if (employeeType.isEmployed(employmentType)) {
        string = 'payslip';
        textObj.titleText = `Gross ${pluralizeString(string)}`;
        textObj.singular = string;
        textObj.tabText = `Gross ${string}`.toUpperCase();
      } else {
        string = 'invoice';
        textObj.titleText = pluralizeString(getCapString(string));
        textObj.singular = string;
        textObj.tabText = textObj.titleText.toUpperCase();
      }
      textObj.capStr = getCapString(string);
      textObj.searchText = pluralizeString(string);

      return textObj;
    }
  };
};

angular.module('public.invoice.employee').service('employeeInvoiceLabelService', dependencies.concat(employeeInvoiceLabelService));
