/*
 * decaffeinate suggestions:
 * DS103: Rewrite code to no longer use __guard__
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$q', '$scope', '$state', 'Client', 'validationService', 'notificationService',
  'currentUserService', 'securityProvider', 'packageTypeService', 'SecurityProvider'
];

const SignClientRegistrationLayoutController = function(
  $q, $scope, $state, Client, validationService, notificationService,
  currentUserService, securityProvider, packageTypeService, SecurityProvider
) {

  const vm = this;

  vm.steps = [{
    title: 'Account details',
    sref: 'sign.client-registration.account-details'
  }, {
    title: 'Company details',
    sref: 'sign.client-registration.company-details'
  }, {
    title: 'Managing locations',
    sref: 'sign.client-registration.locations'
  }];
  vm.validationService = validationService;
  vm.securityProviderId = securityProvider.id;

  let cachedClient = null;
  let isFormSubmitted = false;

  vm.$onInit = function () {
    SecurityProvider.get($state.params.security_provider_alias).then(function (securityProvider) {
      if (packageTypeService.hasProPackage(securityProvider.systemPackage.packageType)) {
        vm.steps.push({
          title: 'Invite manager',
          sref: 'sign.client-registration.managers'
        });
      }
    });
  };

  vm.getTitle = () => vm.steps[vm.activeStepNumber - 1].title;

  vm.updateClient = function(client) {
    cachedClient = client;
    return cachedClient;
  };

  vm.goBack = () => $state.go(vm.steps[vm.activeStepNumber - 2].sref);

  vm.goNext = function() {
    if (vm.steps[vm.activeStepNumber]) {
      return $state.go(vm.steps[vm.activeStepNumber].sref);
    } else {
      return vm.loadClient().then(function(client) {
        client.isRegistered = true;
        return client.save().then(function() {
          cachedClient = null;
          notificationService.notifySuccess('You will receive email with email confirmation instructions shortly!');
          return $state.go('sign.login');
        });
      });
    }
  };

  vm.onFormSubmit = function() {
    if (!$scope.ClientRegistrationForm.$valid || isFormSubmitted) { return; }
    isFormSubmitted = true;
    return $scope.$broadcast('client.registration.submit');
  };

  vm.loadClient = function() {
    if (cachedClient) {
      return $q.when(cachedClient);
    } else {
      return Client.get(vm.getClientId(), {withFirstManager: true}).then(loadedClient => cachedClient = loadedClient);
    }
  };

  vm.resetForm = function() {
    vm.resetFormSubmitted();
    if ($scope.ClientRegistrationForm) {
      $scope.ClientRegistrationForm.$setPristine();
      return $scope.ClientRegistrationForm.$setUntouched();
    }
  };

  vm.resetFormSubmitted = () => isFormSubmitted = false;

  vm.isLoadedClient = () => cachedClient !== null;

  vm.getNextButtonText = function() {
    if (vm.activeStepNumber === vm.steps.length) {
      return 'Done';
    } else {
      return 'Next step';
    }
  };

  vm.getClientId = () => __guard__(currentUserService.getCurrentProfile(), x => x.clientId);

  const initActiveStep = function() {
    const index = _.findIndex(vm.steps, step => $state.is(step.sref));
    vm.activeStepNumber = index + 1;
    return vm.resetForm();
  };

  initActiveStep();

  $scope.$on('$stateChangeSuccess', initActiveStep);

  return vm;

};

angular.module('sign.client.registration').controller('SignClientRegistrationLayoutController',
  dependencies.concat(SignClientRegistrationLayoutController));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value): undefined;
}
