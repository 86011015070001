/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').service('symbolService', function() {

  const getSymbolRange = (from, to) =>
    _.map(__range__(from.charCodeAt(0), to.charCodeAt(0), true), symbolCode => String.fromCharCode(symbolCode))
  ;

  return {
    getLowerCaseLetters() {
      return getSymbolRange('a', 'z');
    },

    getUpperCaseLetters() {
      return getSymbolRange('A', 'Z');
    }
  };
});

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
