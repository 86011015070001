/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('data.repeatable-booking').factory('RepeatableBookingShiftRule', ['AppModel', (AppModel) => {

  class RepeatableBookingShiftRule extends AppModel {

    static initClass() {
      this.configure({
        url: '/repeatable_bookings/{{ repeatableBookingId }}/shift_rules/{{ id }}',
        name: 'shift_rule'
      });
    }

    hasAssignedEmployees(from) {
      return RepeatableBookingShiftRule.$get(this.getUrl() + '/has_assigned_employees', { from: from });
    }

  }

  RepeatableBookingShiftRule.initClass();

  return RepeatableBookingShiftRule;

}]);
