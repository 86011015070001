import './styles.css';

angular.module('shared-components.extended-filters').component('gccsExtendedListFilterCategory', {
  controller: 'ExtendedListFilterCategoryController',
  template: require('./template.html'),
  transclude: true,
  bindings: {
    service: '<',
    category: '<'
  }
});
