/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').factory('AppModel', [
  'RailsResource', 'defaultConfig', 'storageService',
  function(RailsResource, defaultConfig, storageService) {

    class AppModel extends RailsResource {

      static initClass() {
        this.configure();
      }

      static resourceUrl(context, path, options = { withDomain: true }) {
        if (options.withDomain) {
          return this.getApiPrefix() + super.resourceUrl(...arguments);
        } else {
          return this.getApiPrefix().replace(/^(.*\/\/)?[^\/]+/, '') + super.resourceUrl(...arguments);
        }
      }

      static validate(data) {
        return this.$put(this.resourceUrl('validate'), data);
      }

      static autocomplete(queryParams = {}, data = {}) {
        return this.$get(this.resourceUrl(queryParams) + '/autocomplete', data);
      }

      static getCount(queryParams = {}, data = {}) {
        return this.$get(this.resourceUrl(queryParams) + '/count', data);
      }

      static $http(httpConfig) {
        httpConfig.headers = { 'X-Session-Id': storageService.get(defaultConfig.sessionKey) };
        return super.$http(...arguments);
      }

      static getApiPrefix() {
        if (defaultConfig.apiPrefix === "UNSET") {
          return document.location.origin + '/api';
        }
        return defaultConfig.apiPrefix;
      }

      getUrl() {
        return this.constructor.resourceUrl(this._getUrlParams());
      }

      getName() {
        return this.constructor.config.name.replace(/_/g, ' ');
      }

      _getUrlParams() {
        let paramsToPick;
        const urlParams = this.constructor.config.url.match(/[^{{]+?(?=}})/g);
        if (urlParams) {
          paramsToPick = urlParams.map(function(param) {
            return param.replace(/ /g, '');
          });
        } else {
          paramsToPick = 'id';
        }
        return _.pick(this, paramsToPick);
      }
    }

    AppModel.initClass();

    return AppModel;

  }
]);
