const dependencies = [
  'securityManagerNotificationType', 'dateService', 'employeeShiftCancellationStatus', 'profileType',
  'employeeDayOffRequestStatus', 'SecurityManager', 'currentUserService', 'expirationNotificationType',
  'pluralizeService', 'securityManagerNotificationButton', 'securityManagerNotificationPriorityService',
  'securityManagerNotificationActionService', 'securityManagerNotificationClockButtonType'
];

const securityManagerNotificationTranslator = function(
  securityManagerNotificationType, dateService, employeeShiftCancellationStatus, profileType,
  employeeDayOffRequestStatus, SecurityManager, currentUserService, expirationNotificationType,
  pluralizeService, notificationButton, securityManagerNotificationPriorityService,
  securityManagerNotificationActionService, securityManagerNotificationClockButtonType
) {

  const STATUS_APPROVED = 'APPROVED';
  const STATUS_DECLINED = 'DECLINED';

  const STATUS_APPROVED_CLASSNAME = 'mod-green';
  const STATUS_DECLINED_CLASSNAME = 'mod-red';

  const dateFormat = dateService.getDateFormat();
  const timeFormat = dateService.getWesternTimeFormat(true);
  const isoDateFormat = dateService.getIsoDateFormat();
  const isoFullDateFormat = dateService.getIsoFullDateFormat();
  const timeDateFormat = `${timeFormat}, ${dateFormat}`;

  const formatDate = date => moment(date, isoFullDateFormat).format(dateFormat);

  const formatDateWithTime = function(date) {
    return moment(date, isoFullDateFormat).format(`${dateFormat}, ${timeFormat}`);
  };

  const formatTimeWithDate = function(date) {
    return moment(date, isoFullDateFormat).format(timeDateFormat);
  };

  const formatDateRange = (startDate, endDate) => `${formatDate(startDate)} — ${formatDate(endDate)}`;

  const formatDateTimeRange = (startDate, endDate) => `${formatDateWithTime(startDate)} — ${formatDateWithTime(endDate)}`;

  const getEmployeeGroupRegistrationText = (notification) => {
    const isSingular = notification.employeeGroupRegistration.employeeIds.length === 1;
    return `${isSingular ? 'was' : 'were'} registered as new employee${isSingular ? '' : 's'}`;
  };

  const getPriorityStatus = function(resultNotification) {
    const todayLabel = dateService.formatDate(moment(), isoDateFormat);
    if (resultNotification.isUrgent && resultNotification.createdAtDay === todayLabel) {
      return securityManagerNotificationPriorityService.getUrgentToday();
    } else if (resultNotification.createdAtDay === todayLabel) {
      return securityManagerNotificationPriorityService.getToday();
    } else {
      return securityManagerNotificationPriorityService.getEarlier();
    }
  };

  const getFormattedCreatedAt = function(resultNotification) {
    const yesterdayLabel = dateService.formatDate(moment().add(-1, 'days'), isoDateFormat);
    if (
      securityManagerNotificationPriorityService.isUrgentToday(resultNotification.priorityStatus) ||
      securityManagerNotificationPriorityService.isToday(resultNotification.priorityStatus)
    ) {
      return `Today, ${resultNotification.createdAtMoment.format(timeFormat)}`;
    } else if (resultNotification.createdAtDay === yesterdayLabel) {
      return `Yesterday, ${resultNotification.createdAtMoment.format(timeFormat)}`;
    } else {
      return resultNotification.createdAtMoment.format(`${dateFormat}, ${timeFormat}`);
    }
  };

  const setGeneralNotificationProps = function(result, notification) {
    result.id = notification.id;
    result.type = notification.type;
    result.object = notification;
    result.isRead = notification.securityManagerNotificationsSecurityManagers[0].isRead;
    result.isSeen = notification.securityManagerNotificationsSecurityManagers[0].isSeen;
    result.createdAt = notification.createdAt;
    result.createdAtDay = dateService.formatDate(notification.createdAt, isoDateFormat);
    result.createdAtMoment = moment(notification.createdAt, dateService.getIsoFullDateFormatWithTz());
    result.eventDate = notification.eventDate;
    result.eventDateDay = dateService.formatDate(notification.eventDate, isoDateFormat);
    result.eventDateMoment = moment(notification.eventDate, isoDateFormat);
    result.isUrgent = notification.isUrgent;
    result.priorityStatus = getPriorityStatus(result);
    result.formattedCreatedAt = getFormattedCreatedAt(result);
  };

  const setEmployeeRegistrationProps = function(result, notification) {
    const employee = notification.employeeRegistration.employee;
    result.bigTitle = 'new employee registration';
    result.textBoldUpper = employee.fullName;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToEmployeeProfile(notification, employee.id);
      };
    }
    result.text = 'registered as new employee';
    result.list = { 'Date of registration': formatDate(employee.createdAt) };
    result.secondaryButtons = [notificationButton.getViewEmployeeProfile(notification, employee.id)];
  };

  const setEmployeeDayOffRequestProps = function(result, notification) {
    const dayOff = notification.employeeDayOffRequest.employeeDayOff;
    const startDay = formatDate(dayOff.startTime);
    result.bigTitle = dayOff.paidDays > 0 ? 'request for holiday' : 'request for day off';
    result.textBoldUpper = dayOff.employee.fullName;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToEmployeeProfile(notification, dayOff.employee.id);
      };
    }
    result.list = {
      'Duration': formatDateTimeRange(dayOff.startTime, dayOff.endTime),
      'Reason': dayOff.name
    };
    if (dayOff.paidDays > 0) {
      result.list['Paid days'] = dayOff.paidDays;
    }
    if (employeeDayOffRequestStatus.isNew(notification.employeeDayOffRequest.status)) {
      result.actionButtons = [
        notificationButton.getDecline(notification, 'rejectDayOff'),
        notificationButton.getApprove(notification, 'approveDayOff'),
      ];
      result.secondaryButtons = [
        notificationButton.getGoToDayOff(notification, dayOff.id, startDay)
      ];
    } else if (employeeDayOffRequestStatus.isApproved(notification.employeeDayOffRequest.status)) {
      result.secondaryButtons = [notificationButton.getGoToDayOff(notification, dayOff.id, startDay)];
      result.decision = STATUS_APPROVED;
      result.decisionClassName = STATUS_APPROVED_CLASSNAME;
    } else if (employeeDayOffRequestStatus.isRejected(notification.employeeDayOffRequest.status)) {
      result.decision = STATUS_DECLINED;
      result.decisionClassName = STATUS_DECLINED_CLASSNAME;
    }
  };

  const setEmployeeSickDayRequestProps = function(result, notification) {
    const sickDay = notification.employeeSickDayRequest.sickDay;
    result.bigTitle = 'request for sick day';
    result.textBoldUpper = sickDay.employee.fullName;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToEmployeeProfile(notification, sickDay.employee.id);
      };
    }
    result.list = {
      'Duration': formatDateTimeRange(sickDay.startTime, sickDay.endTime),
      'Description': sickDay.name
    };
    const startDay = formatDate(sickDay.startTime);
    if (employeeDayOffRequestStatus.isNew(notification.employeeSickDayRequest.status)) {
      result.actionButtons = [
        notificationButton.getDecline(notification, 'rejectSickDay'),
        notificationButton.getApprove(notification, 'approveSickDay'),
      ];
      result.secondaryButtons = [
        notificationButton.getGoToSickDay(notification, sickDay.id, startDay)
      ];
    } else if (employeeDayOffRequestStatus.isApproved(notification.employeeSickDayRequest.status)) {
      result.secondaryButtons = [notificationButton.getGoToSickDay(notification, sickDay.id, startDay)];
      result.decision = STATUS_APPROVED;
      result.decisionClassName = STATUS_APPROVED_CLASSNAME;
    } else if (employeeDayOffRequestStatus.isRejected(notification.employeeSickDayRequest.status)) {
      result.decision = STATUS_DECLINED;
      result.decisionClassName = STATUS_DECLINED_CLASSNAME;
    }
  };

  const setEmployeeShiftCancellationProps = function(result, notification) {
    const notificationData = notification.employeeShiftCancellation;
    const shiftEmployee = notificationData.employeesShift;
    const startDay = formatDate(notificationData.employeesShift.startTime);
    result.bigTitle = 'cancellation for shift';
    result.textBoldUpper = notificationData.employee.fullName;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToEmployeeProfile(notification, notificationData.employee.id);
      };
    }
    result.text = ' wants to cancel shift ';
    result.textBold = shiftEmployee.name;
    result.textBoldCb = function() {
      securityManagerNotificationActionService.goToShift(notification, shiftEmployee.shiftId, startDay);
    };
    result.list = {
      'Duration': formatDateTimeRange(shiftEmployee.startTime, shiftEmployee.endTime),
      'Reason': shiftEmployee.denyReason
    };
    if (employeeDayOffRequestStatus.isNew(notificationData.status)) {
      result.actionButtons = [
        notificationButton.getDecline(notification, 'rejectShiftCancellation'),
        notificationButton.getApprove(notification, 'approveShiftCancellation')
      ];
    } else if (employeeShiftCancellationStatus.isApproved(notificationData.status)) {
      result.decision = STATUS_APPROVED;
      result.decisionClassName = STATUS_APPROVED_CLASSNAME;
    } else if (employeeShiftCancellationStatus.isRejected(notificationData.status)) {
      result.decision = STATUS_DECLINED;
      result.decisionClassName = STATUS_DECLINED_CLASSNAME;
    }
    result.secondaryButtons = [
      notificationButton.getViewEmployeeProfile(notification, notificationData.employee.id),
      notificationButton.getGoToShift(notification, shiftEmployee.shiftId, startDay)
    ];
  };

  const setNewClientProps = function(result, notification) {
    const client = notification.newClient.client;
    result.bigTitle = 'new client registration';
    result.textBoldUpper = client.name;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToClientDetails(notification, client.id)
      };
    }
    result.list = { 'Date of registration': formatDate(client.createdAt) };
    result.secondaryButtons = [notificationButton.getReviewClientProfile(notification, client.id)];
  };

  const setNewBookingByClientProps = function(result, notification) {
    const notificationData = notification.newBookingByClient;
    const startDay = formatDate(notificationData.booking.from);
    result.bigTitle = 'new booking by client';
    result.textBoldUpper = notificationData.booking.name;
    result.textBoldUpperCb = function() {
      securityManagerNotificationActionService.goToBookingTimeline(notification, startDay)
    };
    result.text = 'was created by';
    result.textBold = notificationData.manager && notificationData.manager.fullName ?
      `${notificationData.manager.fullName} of ${notificationData.client.name}`
      :
      notificationData.client.name;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldCb = function() {
        securityManagerNotificationActionService.goToClientDetails(notification, notificationData.client.id)
      };
    }
    result.secondaryButtons = [
      notificationButton.getReviewClientProfile(notification, notificationData.client.id),
      notificationButton.getGoToBooking(notification, startDay)
    ];
    result.list = {
      'Duration': formatDateRange(notificationData.booking.from, notificationData.booking.to)
    };
  };

  const setChangedBookingProps = function(result, notification) {
    const notificationData = notification.changedBooking;
    const startDay = formatDate(notificationData.booking.from);
    result.bigTitle = 'changed booking';
    result.textBoldUpper = notificationData.manager && notificationData.manager.fullName ?
      `${notificationData.manager.fullName} of ${notificationData.client.name}`
      :
      notificationData.client.name;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToClientDetails(notification, notificationData.client.id)
      };
    }
    result.text = notificationData.manager ? ' made changes in booking' : 'Made changes in booking';
    result.textBold = notificationData.booking.name;
    result.textBoldCb = function() {
      securityManagerNotificationActionService.goToBookingTimeline(notification, startDay)
    };
    result.secondaryButtons = [notificationButton.getGoToBooking(notification, startDay)];
    result.list = {
      'Duration': formatDateRange(notificationData.booking.from, notificationData.booking.to)
    };
  };

  const setUnassignedShiftWarningProps = function(result, notification) {
    const unassignedShiftWarning = notification.unassignedShiftWarning;
    const startDay = formatDate(unassignedShiftWarning.startTime);
    result.bigTitle = 'unassigned shift';
    result.textBoldUpper = unassignedShiftWarning.shiftName;
    result.textBoldUpperCb = function() {
      securityManagerNotificationActionService.goToShift(notification, unassignedShiftWarning.shiftId, startDay)
    };
    result.text = ` starts at ${formatTimeWithDate(unassignedShiftWarning.startTime)}`;
    result.secondaryButtons = [
      notificationButton.getGoToShift(notification, unassignedShiftWarning.shiftId, startDay)
    ];
    result.list = {
      'Duration': formatDateRange(unassignedShiftWarning.startTime, unassignedShiftWarning.endTime)
    };
  };

  const setNewBookingCommentProps = function(result, notification) {
    const startDay = formatDate(notification.newBookingComment.comment.booking.from);
    const profile = notification.newBookingComment.profile;
    result.bigTitle = 'new comment';
    result.textBoldUpper = notification.newBookingComment.profile.fullName;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        if (result.profileType === profileType.getEmployee()) {
          securityManagerNotificationActionService.goToEmployeeProfile(notification, profile.employeeId);
        } else if (result.profileType === profileType.getClientManager()) {
          securityManagerNotificationActionService.goToClientDetails(notification, profile.clientId);
        } else if (result.profileType === profileType.getSecurityManager()) {
          securityManagerNotificationActionService.goToSettingsManagers(notification);
        }
      };
    }
    result.text = ' has left new comment on booking: ';
    result.textBold = notification.newBookingComment.comment.booking.name;
    result.textBoldCb = function() {
      securityManagerNotificationActionService.goToBookingTimeline(notification, startDay)
    };
    result.list = { 'Text': notification.newBookingComment.comment.text };
  };

  const setNewShiftCommentProps = function(result, notification) {
    if (!notification.newShiftComment) { return; }
    const shift = notification.newShiftComment.comment.shift;
    const profile = notification.newShiftComment.profile;
    const startDay = formatDate(shift.startTime);
    result.bigTitle = 'new comment';
    result.textBoldUpper = profile.fullName;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        if (result.profileType === profileType.getEmployee()) {
          securityManagerNotificationActionService.goToEmployeeProfile(notification, profile.employeeId);
        } else if (result.profileType === profileType.getClientManager()) {
          securityManagerNotificationActionService.goToClientDetails(notification, profile.clientId);
        } else if (result.profileType === profileType.getSecurityManager()) {
          securityManagerNotificationActionService.goToSettingsManagers(notification);
        }
      };
    }
    result.text = ' has left new comment on shift: ';
    result.textBold = shift.name;
    result.textBoldCb = function() {
      securityManagerNotificationActionService.goToShift(notification, shift.id, startDay)
    };
    result.list = {
      'Duration': formatDateTimeRange(shift.startTime, shift.endTime),
      'Text': notification.newShiftComment.comment.text
    };
  };

  const setCancelledBookingProps = function(result, notification) {
    const clientManager = notification.cancelledBooking.clientManager;
    result.bigTitle = 'cancelled booking';
    result.textBoldUpper = clientManager && clientManager.fullName ?
      `${clientManager.fullName} of ${clientManager.client.name}`
      :
      notification.cancelledBooking.client.name;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToClientDetails(notification, clientManager.client.id);
      };
    }
    result.text = ' has cancelled booking: ';
    result.textBold = notification.cancelledBooking.bookingName;
    result.list = {
      'Duration': formatDateRange(notification.cancelledBooking.from, notification.cancelledBooking.to)
    };
    result.secondaryButtons = [notificationButton.getViewClientDetails(notification, clientManager.client.id)];
  };

  const setNewBookingByProviderProps = function(result, notification) {
    const startDay = formatDate(notification.newBookingByProvider.booking.from);
    const { securityManager } = notification.newBookingByProvider;
    result.bigTitle = 'new booking by provider';
    result.textBoldUpper = notification.newBookingByProvider.booking.name;
    result.textBoldUpperCb = function() {
      securityManagerNotificationActionService.goToBookingTimeline(notification, startDay)
    };
    if (securityManager) {
      result.text = 'was created by ';
      result.textBold = securityManager.fullName;
      result.textBoldCb = function() {
        securityManagerNotificationActionService.goToSettingsManagers(notification);
      };
    }
    result.secondaryButtons = [notificationButton.getGoToBooking(notification, startDay)];
  };

  const setInvoiceBySelfEmployedProps = function(result, notification) {
    const notificationData = notification.invoiceBySelfEmployed;
    result.bigTitle = `Invoice #${notificationData.invoice.number}`;
    result.text = `New invoice was generated by `;
    result.textBold = notificationData.employee.fullName;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldCb = function() {
        securityManagerNotificationActionService.goToEmployeeProfile(notification, notificationData.employee.id);
      };
    }
    result.description = `On ${formatDate(notificationData.createdAt)}`;
    result.secondaryButtons = [
      notificationButton.getViewEmployeeProfile(notification, notificationData.employee.id),
      notificationButton.getEmployeeInvoiceReview(
        notification, notificationData.invoice.id, notificationData.employeeInvoicesVersionId
      )
    ];
  };

  const setUninvoicedPeriodProps = function(result, notification) {
    const employee = notification.uninvoicedPeriod.employee;
    result.bigTitle = 'Uninvoiced period';
    result.textBoldUpper = employee.fullName;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToEmployeeProfile(notification, employee.id);
      };
    }
    result.text = ` did not invoice you for shifts for more than a 1 month`;
    result.description = `From ${formatDate(notification.uninvoicedPeriod.from)}`;
    result.secondaryButtons = [
      notificationButton.getEmployeeInvoiceList(notification, employee.id)
    ];
  };

  const setLicenseExpirationProps = function(result, notification) {
    const license = notification.licenseExpiration.employeeLicense;
    let capitalizedLabel, label;
    if (license.trainingId) {
      capitalizedLabel = 'Training';
      label = 'training';
    } else {
      capitalizedLabel = 'License';
      label = 'license';
    }
    if (expirationNotificationType.getPassed() === notification.licenseExpiration.type) {
      result.bigTitle = `${capitalizedLabel} has expired`;
    } else {
      result.bigTitle = `${capitalizedLabel} is due to expire`;
    }
    result.textBoldUpper = `${license.employee.fullName}'s`;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToEmployeeProfile(notification, license.employee.id);
      };
    }
    result.text = ` ${label} ${status}`;
    result.list = {
      [`${capitalizedLabel} name`]: license.name,
      'Expires On': formatDate(notification.licenseExpiration.expireDate)
    };
    result.secondaryButtons = [notificationButton.getViewEmployeeProfile(notification, license.employee.id)];
  };

  const setCardExpirationProps = function(result, notification) {
    const employee = notification.cardExpiration.employee;
    result.bigTitle = 'Employee card has expired';
    result.textBoldUpper = `${employee.name}'s`;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToEmployeeProfile(notification, employee.id);
      };
    }
    result.text = ' card has expired';
    result.list = {
      'Expiration Date': formatDate(notification.cardExpiration.expireDate)
    };
    result.secondaryButtons = [notificationButton.getViewEmployeeProfile(notification, employee.id)];
  };

  const setShiftRemovalProps = function(result, notification) {
    const clientManager = notification.shiftRemoval.clientManager;
    const startDay = formatDate(notification.shiftRemoval.startTime);
    result.bigTitle = 'removed shift';
    result.textBoldUpper = clientManager && clientManager.fullName ?
      `${clientManager.fullName} of ${clientManager.client.name}`
      :
      notification.shiftRemoval.client.name;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToClientDetails(notification, clientManager.client.id);
      };
    }
    result.text = ` has removed shift for booking: "${notification.shiftRemoval.bookingName}"`;
    result.textBold = notification.shiftRemoval.shiftName;
    result.list = {
      'Shift Duration': formatDateTimeRange(notification.shiftRemoval.startTime, notification.shiftRemoval.endTime)
    };
    result.secondaryButtons = [
      notificationButton.getViewClientDetails(notification, clientManager.client.id),
      notificationButton.getGoToBooking(notification, startDay)
    ];
  };

  const setDocumentExpirationProps = function(result, notification) {
    let status;
    const document = notification.documentExpiration.document;
    if (expirationNotificationType.getPassed() === notification.documentExpiration.type) {
      status = 'has expired';
    } else {
      status = 'is due to expire';
    }
    result.bigTitle = `Document ${status}`;
    result.textBoldUpper = `${document.ownerName}'s`;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        if (document.employeeId) {
          securityManagerNotificationActionService.goToEmployeeProfile(notification, document.employeeId);
        } else if (document.clientId) {
          securityManagerNotificationActionService.goToClientDetails(notification, document.clientId);
        }
      };
    }
    result.text = ` document ${status}`;
    result.list = {
      'Document name': document.name,
      'Expires On': formatDate(notification.documentExpiration.expireDate)
    };
    if (document.clientId) {
      result.secondaryButtons = [notificationButton.getViewClientDetails(notification, document.clientId)];
    } else {
      result.secondaryButtons = [notificationButton.getViewEmployeeProfile(notification, document.employeeId)];
    }
  };

  const setEmployeeGroupRegistrationProps = function(result, notification, service) {
    const notificationData = notification.employeeGroupRegistration;
    const showRegisteredEmployeesPopup = function() {
      service.uploaderSecurityManagerId = notificationData.uploader.id;
      service.uploadedGroupEmployees = notificationData.employeeIds;
      service.isShownRegisteredEmployeesPopup = true;
    };
    result.bigTitle = 'new employees group registration';
    result.textBoldUpper = pluralizeService.simple(notificationData.employeeIds.length, 'user');
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.markRead(notification);
        showRegisteredEmployeesPopup();
      };
    }
    result.text = getEmployeeGroupRegistrationText(notification);
    result.list = { 'Uploaded by': notificationData.uploader.fullName };
    result.secondaryButtons = [notificationButton.getViewUsersList(notification, showRegisteredEmployeesPopup)];
  };

  const setNewRepeatableBookingByClientProps = function(result, notification) {
    const notificationData = notification.newRepeatableBookingByClient;
    const startDay = formatDate(notificationData.repeatableBooking.startDate);
    result.bigTitle = 'new repeatable booking by client';
    result.textBoldUpper = notificationData.repeatableBooking.name;
    result.textBoldUpperCb = function() {
      securityManagerNotificationActionService.goToBookingTimeline(notification, startDay)
    };
    result.text = 'was created by';
    result.textBold = notificationData.manager && notificationData.manager.fullName ?
      `${notificationData.manager.fullName} of ${notificationData.client.name}`
      :
      notificationData.client.name;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldCb = function() {
        securityManagerNotificationActionService.goToClientDetails(notification, notificationData.client.id);
      };
    }
    result.secondaryButtons = [notificationButton.getGoToBooking(notification, startDay)];
    result.list = {
      'Duration': formatDateRange(notificationData.repeatableBooking.startDate, notificationData.repeatableBooking.endDate)
    };
  };

  const setChangedRepeatableBookingProps = function(result, notification) {
    const notificationData = notification.changedRepeatableBooking;
    const startDay = formatDate(notificationData.repeatableBooking.startDate);
    result.bigTitle = 'changed repeatable booking';
    result.textBoldUpper = notificationData.manager && notificationData.manager.fullName ?
      `${notificationData.manager.fullName} of ${notificationData.client.name}`
      :
      notificationData.client.name;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToClientDetails(notification, notificationData.client.id);
      };
    }
    result.text = notificationData.manager && notificationData.manager.fullName ?
      ' made changes in repeatable booking'
      :
      'Made changes in repeatable booking';
    result.textBold = notificationData.repeatableBooking.name;
    result.textBoldCb = function() {
      securityManagerNotificationActionService.goToBookingTimeline(notification, startDay)
    };
    result.secondaryButtons = [notificationButton.getGoToBooking(notification, startDay)];
    result.list = {
      'Duration': formatDateRange(notificationData.repeatableBooking.startDate, notificationData.repeatableBooking.endDate)
    };
  };

  const setLateClockInProps = function(result, notification) {
    const shiftEmployee = notification.lateClockIn.shiftEmployee;
    setClockInProps(result, notification, shiftEmployee);
    result.bigTitle = 'Late clock in';
    result.clockInOutButton = {
      type: securityManagerNotificationClockButtonType.getEditClockIn(),
      shiftEmployee: shiftEmployee,
      changeLog: notification.lateClockIn.changeLog,
      iconName: 'edit'
    };
  };

  const setMissedClockInProps = function(result, notification) {
    const shiftEmployee = notification.missedClockIn.shiftEmployee;
    setClockInProps(result, notification, shiftEmployee);
    result.bigTitle = 'Missed clock in';
    result.clockInOutButton = {
      type: securityManagerNotificationClockButtonType.getAddClockIn(),
      shiftEmployee: shiftEmployee,
      classes: 'mod-blue-text',
      iconName: 'clock-in'
    };
  };

  const setEarlyClockOutProps = function(result, notification) {
    const shiftEmployee = notification.earlyClockOut.shiftEmployee;
    setClockOutProps(result, notification, shiftEmployee);
    result.bigTitle = 'Early clock out';
    result.clockInOutButton = {
      type: securityManagerNotificationClockButtonType.getEditClockOut(),
      shiftEmployee: shiftEmployee,
      changeLog: notification.earlyClockOut.changeLog,
      iconName: 'edit'
    };
  };

  const setLateClockOutProps = function(result, notification) {
    const shiftEmployee = notification.lateClockOut.shiftEmployee;
    setClockOutProps(result, notification, shiftEmployee);
    result.bigTitle = 'Late clock out';
    result.clockInOutButton = {
      type: securityManagerNotificationClockButtonType.getEditClockOut(),
      shiftEmployee: shiftEmployee,
      changeLog: notification.lateClockOut.changeLog,
      iconName: 'edit'
    };
  };

  const setMissedClockOutProps = function(result, notification) {
    const shiftEmployee = notification.missedClockOut.shiftEmployee;
    setClockOutProps(result, notification, shiftEmployee);
    result.bigTitle = 'Missed clock out';
    result.clockInOutButton = {
      type: securityManagerNotificationClockButtonType.getAddClockOut(),
      shiftEmployee: shiftEmployee,
      classes: 'mod-blue-text',
      iconName: 'clock-in'
    };
  };

  const setManualClockInProps = function(result, notification) {
    const shiftEmployee = notification.manualClockIn.shiftEmployee;
    setClockInProps(result, notification, shiftEmployee);
    result.bigTitle = 'Manual clock in';
    result.clockInOutButton = {
      type: securityManagerNotificationClockButtonType.getEditClockIn(),
      shiftEmployee: shiftEmployee,
      lastChangeLog: notification.manualClockIn.lastChangeLog,
      iconName: 'edit'
    };
  };

  const setManualClockOutProps = function(result, notification) {
    const shiftEmployee = notification.manualClockOut.shiftEmployee;
    setClockOutProps(result, notification, shiftEmployee);
    result.bigTitle = 'Manual clock out';
    result.clockInOutButton = {
      type: securityManagerNotificationClockButtonType.getEditClockOut(),
      shiftEmployee: shiftEmployee,
      lastChangeLog: notification.manualClockOut.lastChangeLog,
      iconName: 'edit'
    };
  };

  const setClockOutProps = function(result, notification, shiftEmployee) {
    const startTime = moment(shiftEmployee.startTime, isoFullDateFormat);
    const endTime = moment(shiftEmployee.endTime, isoFullDateFormat);
    result.textBoldUpper = shiftEmployee.employeeName;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToEmployeeProfile(notification, shiftEmployee.employeeId);
      };
    }
    if (shiftEmployee.clockOutTime) {
      const clockOutTime = moment(shiftEmployee.clockOutTime, isoFullDateFormat);
      result.text = `at ${clockOutTime.format(timeFormat)}`;
      if (!endTime.isSame(clockOutTime)) {
        result.highlightText = dateService.formatTimeDifference(endTime, clockOutTime);
      }
    }
    result.description =
      `${shiftEmployee.shiftName}, ${shiftEmployee.locationName}, ${shiftEmployee.clientName}, ends at ${endTime.format(timeDateFormat)}`;
    result.secondaryButtons = [
      notificationButton.getViewEmployeeProfile(notification, shiftEmployee.employeeId),
      notificationButton.getGoToShift(notification, shiftEmployee.shiftId, startTime.format(dateFormat))
    ];
  };

  const setClockInProps = function(result, notification, shiftEmployee) {
    const startTime = moment(shiftEmployee.startTime, isoFullDateFormat);
    result.textBoldUpper = shiftEmployee.employeeName;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToEmployeeProfile(notification, shiftEmployee.employeeId);
      };
    }
    if (shiftEmployee.clockInTime) {
      const clockInTime = moment(shiftEmployee.clockInTime, isoFullDateFormat);
      result.text = `at ${clockInTime.format(timeFormat)}`;
      if (!startTime.isSame(clockInTime)) {
        result.highlightText = dateService.formatTimeDifference(startTime, clockInTime);
      }
    }
    result.description =
      `${shiftEmployee.shiftName}, ${shiftEmployee.locationName}, ${shiftEmployee.clientName}, starts at ${startTime.format(timeDateFormat)}`;
    result.secondaryButtons = [
      notificationButton.getViewEmployeeProfile(notification, shiftEmployee.employeeId),
      notificationButton.getGoToShift(notification, shiftEmployee.shiftId, startTime.format(dateFormat))
    ];
  };

  const setManagedBookingProps = function(result, notification) {
    const startDay = formatDate(notification.managedBooking.bookingInfo.from);
    const withBookingButton = notification.managedBooking.managerInfo.type === 'update';
    result.bigTitle = 'booking update';
    result.textBoldUpper = notification.managedBooking.bookingInfo.name;
    if (withBookingButton) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToBookingTimeline(notification, startDay);
      };
    }
    result.text = `was ${notification.managedBooking.managerInfo.type}d by `;
    result.textBold = notification.managedBooking.managerInfo.name;
    result.textBoldCb = function() {
      securityManagerNotificationActionService.goToSettingsManagers(notification);
    };

    result.secondaryButtons = [];
    if (withBookingButton) {
      result.secondaryButtons.push(notificationButton.getGoToBooking(notification, startDay));
    }
  };

  const setMissedCheckInProps = function(result, notification) {
    const shiftEmployee = notification.missedCheckIn.shiftEmployee;
    const startTime = moment(shiftEmployee.startTime, isoFullDateFormat);
    result.bigTitle = 'Missed check in';
    result.textBoldUpper = shiftEmployee.employeeName;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToEmployeeProfile(notification, shiftEmployee.employeeId);
      };
    }
    const checkInTime = moment(notification.missedCheckIn.checkInTime, isoFullDateFormat);
    result.text = `at ${checkInTime.format(timeFormat)}`;
    result.description =
      `${shiftEmployee.shiftName}, ${shiftEmployee.locationName}, ${shiftEmployee.clientName}, starts at ${startTime.format(timeDateFormat)}`;
    result.secondaryButtons = [
      notificationButton.getViewEmployeeProfile(notification, shiftEmployee.employeeId),
      notificationButton.getGoToShift(notification, shiftEmployee.shiftId, startTime.format(dateFormat))
    ];
  };

  const setHumynEmployeeIssueProps = function(result, notification) {
    const employee = notification.humynIssue.employee;
    result.bigTitle = 'Humyn - Employee Sync Issue';
    result.textBoldUpper = employee.fullName;
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      result.textBoldUpperCb = function() {
        securityManagerNotificationActionService.goToEmployeeProfile(notification, employee.id);
      };
    }
    result.text = ' failed to sync';
    result.list = {
      'Error': notification.humynIssue.syncError
    };
    //result.description =
  };

  const setHumynEmployerIssueProps = function(result, notification) {
    const securityProvider = notification.humynIssue.securityProvider;
    result.bigTitle = 'Humyn - Employer Sync Issue';
    result.textBoldUpper = securityProvider.companyName;
    result.text = ' failed to sync';
    result.list = {
      'Error': notification.humynIssue.syncError
    };
    //result.description =
  };

  // todo remove service from parameters
  const translateNotification = function(service, notification) {
    const result = {};
    setGeneralNotificationProps(result, notification);
    switch (notification.type) {
      case securityManagerNotificationType.getEmployeeRegistration():
        setEmployeeRegistrationProps(result, notification);
        break;
      case securityManagerNotificationType.getEmployeeDayOffRequest():
        setEmployeeDayOffRequestProps(result, notification);
        break;
      case securityManagerNotificationType.getEmployeeSickDayRequest():
        setEmployeeSickDayRequestProps(result, notification);
        break;
      case securityManagerNotificationType.getEmployeeShiftCancellation():
        setEmployeeShiftCancellationProps(result, notification);
        break;
      case securityManagerNotificationType.getNewClient():
        setNewClientProps(result, notification);
        break;
      case securityManagerNotificationType.getNewBookingByClient():
        setNewBookingByClientProps(result, notification);
        break;
      case securityManagerNotificationType.getChangedBooking():
        setChangedBookingProps(result, notification);
        break;
      case securityManagerNotificationType.getUnassignedShiftWarning():
        setUnassignedShiftWarningProps(result, notification);
        break;
      case securityManagerNotificationType.getNewBookingComment():
        setNewBookingCommentProps(result, notification);
        break;
      case securityManagerNotificationType.getNewShiftComment():
        setNewShiftCommentProps(result, notification);
        break;
      case securityManagerNotificationType.getCancelledBooking():
        setCancelledBookingProps(result, notification);
        break;
      case securityManagerNotificationType.getNewBookingByProvider():
        setNewBookingByProviderProps(result, notification);
        break;
      case securityManagerNotificationType.getInvoiceBySelfEmployed():
        setInvoiceBySelfEmployedProps(result, notification);
        break;
      case securityManagerNotificationType.getUninvoicedPeriod():
        setUninvoicedPeriodProps(result, notification);
        break;
      case securityManagerNotificationType.getLicenseExpiration():
        setLicenseExpirationProps(result, notification);
        break;
      case securityManagerNotificationType.getCardExpiration():
        setCardExpirationProps(result, notification);
        break;
      case securityManagerNotificationType.getShiftRemoval():
        setShiftRemovalProps(result, notification);
        break;
      case securityManagerNotificationType.getDocumentExpiration():
        setDocumentExpirationProps(result, notification);
        break;
      case securityManagerNotificationType.getEmployeeGroupRegistration():
        setEmployeeGroupRegistrationProps(result, notification, service);
        break;
      case securityManagerNotificationType.getNewRepeatableBookingByClient():
        setNewRepeatableBookingByClientProps(result, notification);
        break;
      case securityManagerNotificationType.getChangedRepeatableBooking():
        setChangedRepeatableBookingProps(result, notification);
        break;
      case securityManagerNotificationType.getLateClockIn():
        setLateClockInProps(result, notification);
        break;
      case securityManagerNotificationType.getMissedClockIn():
        setMissedClockInProps(result, notification);
        break;
      case securityManagerNotificationType.getEarlyClockOut():
        setEarlyClockOutProps(result, notification);
        break;
      case securityManagerNotificationType.getLateClockOut():
        setLateClockOutProps(result, notification);
        break;
      case securityManagerNotificationType.getMissedClockOut():
        setMissedClockOutProps(result, notification);
        break;
      case securityManagerNotificationType.getManualClockIn():
        setManualClockInProps(result, notification);
        break;
      case securityManagerNotificationType.getManualClockOut():
        setManualClockOutProps(result, notification);
        break;
      case securityManagerNotificationType.getManagedBooking():
        setManagedBookingProps(result, notification);
        break;
      case securityManagerNotificationType.getMissedCheckIn():
        setMissedCheckInProps(result, notification);
        break;
      case securityManagerNotificationType.getHumynEmployeeIssues():
        setHumynEmployeeIssueProps(result, notification);
        break;
      case securityManagerNotificationType.getHumynEmployerIssues():
        setHumynEmployerIssueProps(result, notification);
        break;
      default:
        throw new Error('Unknown notification type');
    }
    return result;
  };

  return {
    translateNotification
  }

};

angular.module('public.security-manager.notifications')
  .service('securityManagerNotificationTranslator', dependencies.concat(securityManagerNotificationTranslator));
