angular.module('shared').directive('gccsFlexibleListBookingTimeline', () => {
  return {
    restrict : 'A',
    scope : {
      list : '<',
      resizeOnChange : '&'
    },
    link($scope, $el) {

      const DEFAULT_RESOURCE_ICON_WIDTH = 18;

      let listUpdated = false;

      const element = $el[0];
      const numberListItem = element.querySelector('.mod-num');

      const hideNumberListItem = numberListItem => numberListItem.classList.add('hide');

      const showNumberListItem = function(numberListItem, extraCount) {
        numberListItem.firstElementChild.innerHTML = extraCount;
        numberListItem.classList.remove('hide');
      };

      const hideExtra = function(listItems, extraCount) {
        showAllListItems(listItems);
        let toHideCount = extraCount + 1;
        while (toHideCount > 0) {
          const listItem = listItems[listItems.length - 1 - toHideCount];
          if (listItem) { listItem.classList.add('hide'); }
          toHideCount -= 1;
        }
        showNumberListItem(numberListItem, extraCount + 1);
      };

      const showAllListItems = (listItems) => {
        for (let index = 0; index < listItems.length; index++) {
          const listItem = listItems[index];
          if (index < listItems.length - 1) {
            listItem.classList.remove('hide');
          }
        }
      };

      const getContainerWidth = function(container) {
        const containerStyle = window.getComputedStyle(container);
        const leftPadding = parseInt(containerStyle.getPropertyValue('padding-left'));
        const rightPadding = parseInt(containerStyle.getPropertyValue('padding-right'));
        return container.offsetWidth - leftPadding - rightPadding;
      };

      const getListItemActualWidth = function(listItem) {
        const marginRight = parseInt(window.getComputedStyle(listItem).getPropertyValue('margin-right'));
        return (listItem.getBoundingClientRect().width || DEFAULT_RESOURCE_ICON_WIDTH) + marginRight;
      };

      const updateList = function(oldValue, newValue) {
        if (!listUpdated || !_.isEqual(oldValue, newValue)) {
          listUpdated = true;
          setTimeout(function() {
            const container = element.parentElement;
            const listItems = element.children;
            const firstListItem = listItems[0];

            const containerWidth = getContainerWidth(container);
            const listItemActualWidth = getListItemActualWidth(firstListItem);

            const maxItemsCount = Math.floor(containerWidth / listItemActualWidth) || 1;
            const listLength = $scope.list.length;
            const extraCount = listLength - maxItemsCount;

            if (extraCount > 0) {
              hideExtra(listItems, extraCount);
            } else {
              hideNumberListItem(numberListItem);
              showAllListItems(listItems);
            }
          }
          , 0);
        }
      };

      hideNumberListItem(numberListItem);

      $scope.$watchCollection('list', updateList);
      $scope.$watch($scope.resizeOnChange, updateList, true);
    }
  }
});
