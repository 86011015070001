/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$q', 'AppModel', 'User', 'defaultConfig', 'storageService'];

const Session = ($q, AppModel, User, defaultConfig, storageService) => {

  let instances = undefined;

  class Session extends AppModel {
    static initClass() {
      this.configure({
        url : '/session',
        name : 'session',
        rootWrapping : false
      });

      instances = {};
    }

    static getInstance() {
      const key = storageService.get(defaultConfig.sessionKey);
      if (instances[key]) {
        return $q.when(instances[key]);
      } else {
        return this.getCurrent().then(session => instances[key] = session);
      }
    }

    static getCurrent() {
      return this.$get(this.resourceUrl());
    }

    check() {
      return this.$put(this.getUrl() + '/check');
    }
  }

  Session.initClass();
  return Session;
};

angular.module('shared').factory('Session', dependencies.concat(Session));
