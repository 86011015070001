/*
 * @author Igor Serpak <robobee>
 */
const gccsScheduleManagerTimelineShiftStatistics = {
  controller: 'ScheduleManagerShiftStatisticsController',
  controllerAs: 'statisticsCtrl',
  template: require('./template.pug'),
  bindings: {
    shiftStatistics: '<',
    clients: '<',
    onSelect: '&',
    hide: '<',
    selectedClients: '<'
  }
};

angular
  .module('public.security-manager.schedule-manager.timeline')
  .component('gccsScheduleManagerTimelineShiftStatistics',
    gccsScheduleManagerTimelineShiftStatistics);
