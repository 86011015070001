/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'Country', 'packageTypeService', 'systemPackagePopupService'
];

const PopupSystemPackageLicensesNumberController = function(
  $scope, Country, packageTypeService, systemPackagePopupService
) {

  const vm = this;

  vm.packageTypeService = packageTypeService;

  vm.$onInit = function() {
    loadCurrentCountry();
  };

  vm.increaseLicensesNumber = function(value) {
    vm.licensesCount = Math.max(0, vm.licensesCount + value);
  };

  vm.onSubmit = function() {
    if ($scope.LicensesNumberForm.$invalid) { return; }
    systemPackagePopupService.onLicensesNumberClose(vm.licensesCount);
  };

  const init = function() {
    vm.licensesCount = packageTypeService.getLicensesCount();
    vm.packageType = packageTypeService.getCurrentPackageType();
  };

  const loadCurrentCountry = function() {
    Country.getCurrent().then(function(country) {
      vm.currentCountry = country;
    });
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      init();
    }
  });

  return vm;

};

angular.module('popup.system-package')
  .controller('PopupSystemPackageLicensesNumberController', dependencies.concat(PopupSystemPackageLicensesNumberController));
