const dependencies = ['$timeout', 'pluralizeService'];

const LiveFeedListController = function($timeout, pluralizeService) {

  const vm = this;

  const ANIMATION_DURATION_TOGGLE = 500;

  let lastOpenedIssue = null;

  vm.onShiftIssueToggle = function(shiftIssue, onOpenCb) {
    if (lastOpenedIssue === shiftIssue) {
      lastOpenedIssue.isOpenedFully = false;
      lastOpenedIssue.isOpenFinish = false;
      $timeout(() => {
        lastOpenedIssue.isOpenStart = false;
        lastOpenedIssue = null;
      }, ANIMATION_DURATION_TOGGLE);
    } else {
      if (!shiftIssue.isOpenStart) {
        if (lastOpenedIssue) {
          lastOpenedIssue.isOpenedFully = false;
          lastOpenedIssue.isOpenFinish = false;
          $timeout(() => {
            lastOpenedIssue.isOpenStart = false;
          }, ANIMATION_DURATION_TOGGLE);
        }
        shiftIssue.isOpenStart = true;
        onOpenCb().then(() => {
          // fix: sometimes element is not rendered for animation to kick in
          $timeout(() => {
            shiftIssue.isOpenFinish = true;
            $timeout(() => {
              lastOpenedIssue.isOpenedFully = true;
            }, ANIMATION_DURATION_TOGGLE);
          }, 50);
        });
        $timeout(() => {
          lastOpenedIssue = shiftIssue;
        }, ANIMATION_DURATION_TOGGLE);
      }
    }
  };

  vm.getSelectedShiftsCount = function() {
    const selectedShiftsLength = vm.shiftSelector.selectedDShiftGroup.length;
    return `${pluralizeService.simple(selectedShiftsLength, 'Shift')} selected.`;
  };
  
  return vm;

};

angular.module('public.security-manager.schedule-manager.timeline')
  .controller('LiveFeedListController', dependencies.concat(LiveFeedListController));
