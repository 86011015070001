/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('public.security-manager.schedule-manager.timeline.planning', [
  'popup.booking'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.planning', {
      url: '/schedule/manager/timeline/planning',
      template: require('./template.pug'),
      controller: 'PlanningController',
      controllerAs: 'planningCtrl'
    })

]);
