const dependencies = ['Notification', '$timeout'];

const notificationService = function(Notification, $timeout) {

  const SHOWN_TIME = 5000;

  const SUCCESS = 'success';
  const ERROR = 'error';
  const INFO = 'info';

  return new class NotificationService {

    constructor() {
      this.notifications = [];
    }

    notifySuccess(message, persistent = false) {
      this._notify(message, SUCCESS, persistent);
    }

    notifyError(message, persistent = false) {
      this._notify(message, ERROR, persistent);
    }

    notifyInfo(message, persistent = false) {
      this._notify(message, INFO, persistent);
    }

    hideNotification(notification) {
      _.pull(this.notifications, notification);
    }

    hide() {
      this.notifications = _.filter(this.notifications, 'persistent');
    }

    _notify(message, notificationType, persistent) {
      const notification = new Notification(message, notificationType, persistent);
      this.notifications.push(notification);
      $timeout(() => {
        return this.hideNotification(notification);
      }, SHOWN_TIME);
    }
  };

};

angular.module('gccs-ui').service('notificationService', dependencies.concat(notificationService));
