const dependencies = ['AppModel'];

const SecurityProviderBillingHistoryItem = (AppModel) => {

  class SecurityProviderBillingHistoryItem extends AppModel {
    static initClass() {
      this.configure({
        url: '/security_providers/{{ securityProviderId }}/billing_history_items/{{ id }}',
        name: 'billing_history_item'
      });
    }

  }

  SecurityProviderBillingHistoryItem.initClass();

  return SecurityProviderBillingHistoryItem;

};

angular
  .module('data.security-provider')
  .factory('SecurityProviderBillingHistoryItem', dependencies.concat(SecurityProviderBillingHistoryItem));
