/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'uploadService', 'Employee'];

const PopupEmployeeUploadController = function($scope, uploadService, Employee) {

  const vm = this;

  vm.uploadFile = function () {
    uploadService.uploadFile(Employee.importEmployeesUrl(), vm.importFile.fileUploaded, function (importResult) {
      $scope.uploadSuccessCb({ importResult });
    });
  };

  vm.getDownloadTemplateUrl = function() {
    return Employee.importTemplateUrl();
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      initFile();
    }
  });

  const initFile = function () {
    vm.importFile = {
      file: undefined,
      fileName: undefined,
      fileUploaded: undefined
    };
  };

  return vm;
};

angular.module('popup.employee')
  .controller('PopupEmployeeUploadController', dependencies.concat(PopupEmployeeUploadController));
