/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'ClientLocation', 'EntityManager', 'packageTypeService', 'activeStatus'
];

const ClientRegistrationLocationManagementController = function(
  $scope, ClientLocation, EntityManager, packageTypeService, activeStatus
) {

  const vm = this;

  const entityManager = new EntityManager(ClientLocation, {
    params: {
      clientId: $scope.clientRegistrationLayoutCtrl.clientId
    },
    queryParams: {
      activeStatus: activeStatus.getActiveStatus()
    },
    withBlank: true
  });

  $scope.clientRegistrationLayoutCtrl.loadClient().then(function(client) {
    entityManager.addOptions({
      prepare: {
        address: client.address
      }
    });
    return entityManager.loadAll();
  });

  vm.canAddLocation = function() {
    return !packageTypeService.hasFreePackage();
  };

  vm.addNewLocation = location => entityManager.addNewEntity(location);

  vm.removeLocation = location => entityManager.removeEntity(location);

  vm.getLocations = () => entityManager.entities;

  $scope.$on('client.registration.submit', () => {
    return entityManager.saveAll().then(() => $scope.clientRegistrationLayoutCtrl.goNext());
  });

  return vm;

};

angular
  .module('public.security-manager.client.registration')
  .controller('ClientRegistrationLocationManagementController',
    dependencies.concat(ClientRegistrationLocationManagementController));
