/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['$timeout'];

const gccsAutoFocus = $timeout => {
  return {
    restrict: 'A',
    scope : {
      isOpen : '<'
    },

    link($scope, $el) {
      const element = $el[0];

      const focusInput = function(current, previous) {
        if ((previous === false) && (current === true)) {
          return $timeout(() => element.focus());
        }
      };

      return $scope.$watch('isOpen', focusInput, true);
    }
  }
};

angular.module('shared').directive('gccsAutoFocus', dependencies.concat(gccsAutoFocus));
