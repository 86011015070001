/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsPopupPdfView = popupDirectiveConfig => {
  return popupDirectiveConfig.configure({
    bodyTemplate: require('./template.pug'),
    scope: {
      title: '<',
      pdfUrl: '<',
      pdfDownloadUrl: '<'
    }
  });
};

angular.module('popup.pdf').directive('gccsPopupPdfView', ['popupDirectiveConfig', gccsPopupPdfView]);
