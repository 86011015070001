const dependencies = ['$timeout'];

const dayOffShift = $timeout => {
  return {
    restrict: 'A',
    template: require('./template.pug'),
    controller: 'DayOffShiftController',
    controllerAs: 'dayOffShiftCtrl',
    scope: {
      onRender: '&',
      onClose: '&',
      isShownPopup: '=',
      eventOptions: '<',
      getShiftStyle: '&',
      decorator: '<'
    },
    bindToController: true,
    link($scope, $el, attrs, ctrl) {
      const elem = $el[0];
      return $timeout(function () {
        const rect = elem.querySelector('.timeline-event').getBoundingClientRect();
        return ctrl.onRender({ rect });
      });
    }
  };
};

angular.module('public.security-manager.schedule-manager.timeline')
  .directive('dayOffShift', dependencies.concat(dayOffShift));
