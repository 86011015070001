/*
 * @author Igor Serpak <robobee>
 */
const SelectFilteredController = function() {

  const vm = this;

  vm.filteredListItems = [];

  vm.$onInit = () => initDefaultSelectedItem();

  vm.getSelectedTextOf = function(value) {
    if (vm.selectedListItems) {
      const foundItem = _.find(vm.selectedListItems, selectedListItem => selectedListItem.id === value.id);
      return foundItem.text;
    } else {
      return vm.getTextOf(value);
    }
  };

  vm.toggleSelect = () => vm.isShownSelect = !vm.isShownSelect;

  vm.hideSelect = () => vm.isShownSelect = false;

  vm.select = function(item) {
    vm.selectedItem = item;
    vm.onSelect({selected : vm.selectedItem});
    return vm.hideSelect();
  };

  vm.getTextOf = value => value.text;

  vm.filterListItems = function() {
    if (vm.filterText) {
      const filterText = vm.filterText.toLowerCase();
      if (vm.isGroupedSelect) {
        vm.filteredListItems = angular.copy(vm.selectListItems);
        _.each(vm.filteredListItems, (category, index) => {
          if (index !== 0) {
            category.items = _.filter(category.items, selectListItem => ~selectListItem.text.toLowerCase().indexOf(filterText));
          }
        });
      } else {
        vm.filteredListItems = _.filter(vm.selectListItems, selectListItem => (selectListItem.id === 0) || ~selectListItem.text.toLowerCase().indexOf(filterText));
      }
    } else {
      vm.filteredListItems = vm.selectListItems;
    }
  };

  vm.getSelectListItems = function() {
    return vm.filteredListItems;
  };

  const initDefaultSelectedItem = function() {
    if (_.isEmpty(vm.selectListItems)) { return; }
    if (!vm.selectedItem) {
      if (vm.isGroupedSelect) {
        vm.select(_.first(_.first(vm.selectListItems).items));
      } else {
        vm.select(_.first(vm.selectListItems));
      }
    }
    vm.filteredListItems = vm.selectListItems;
  };

  return vm;
};

angular.module('gccs-ui').controller('SelectFilteredController', SelectFilteredController);
