const gccsFormDatePickerRange = () => {
  return {
    restrict: 'A',
    controller: 'FormDatePickerRangeController',
    controllerAs: 'formDatePickerRangeCtrl',
    template: require('./template.pug'),
    scope: {
      isRight: '=',
      isSmallHeight: '<',
      withDateRanges: '<',
      checkboxTitle: '@',
      canBeWithoutEnd: '=',
      startDateModel: '=',
      endDateModel: '=',
      customErrors: '&',
      onClose: '&'
    }
  };
};

angular.module('form').directive('gccsFormDatePickerRange', gccsFormDatePickerRange);
