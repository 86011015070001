/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const headerNotificationsObservable = function() {

  const events = ['shift-invite-accept', 'shift-invite-decline', 'go-to-notification'];

  const observerCallbacks = {};
  for (let event of Array.from(events)) {
    observerCallbacks[event] = [];
  }

  const subscribe = function(event, callback) {
    if (Array.from(events).includes(event) && !(Array.from(observerCallbacks[event]).includes(callback))) {
      return observerCallbacks[event].push(callback);
    }
  };

  const unsubscribe = function(event, callback) {
    if (Array.from(observerCallbacks[event]).includes(callback)) {
      return _.pull(observerCallbacks[event], callback);
    }
  };

  const triggerEvent = function(event, data) {
    if (Array.from(events).includes(event)) {
      return Array.from(observerCallbacks[event]).map((callback) =>
        callback(data));
    }
  };

  return {
    subscribe,
    unsubscribe,
    triggerEvent
  };
};

angular.module('public.employee').service('headerNotificationsObservable', headerNotificationsObservable);
