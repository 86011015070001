const dependencies = ['$filter', 'dateService', 'emailStatus'];

const ClientInvoiceEmailRowController = function($filter, dateService, emailStatus) {

  const vm = this;

  const HIGHLIGHT_CLASS = 'is-emails-highlighted';
  const CUT_TEXT_LENGTH = 50;

  vm.shownReceiver = null;
  vm.isShownReceivers = false;
  vm.isShownPreviewPopup = false;

  vm.$onInit = function() {
    if (vm.showStatusFor) {
      vm.employeeWithStatus = _.find(vm.email.receivers, receiver => receiver.id === +vm.showStatusFor);
    }
  };

  vm.getOrderedReceivers = function() {
    let foundReceivers = [];
    let otherReceivers = [];
    _.each(vm.email.receivers, function(receiver) {
      if (isReceiverMatches(receiver, vm.receiverSearch)) {
        foundReceivers.push(receiver);
      } else {
        otherReceivers.push(receiver);
      }
    });
    return foundReceivers.concat(otherReceivers);
  };

  vm.showClientEmailPreview = function () {
    vm.isShownPreviewPopup = true;
  };

  vm.closeClientEmailPreview = function () {
    vm.isShownPreviewPopup = false;
  };

  vm.highlight = function (value) {
    return $filter('highlight')(value, vm.highlightString, HIGHLIGHT_CLASS);
  };

  vm.getEmailText = function () {
    return vm.email.cleanText.substring(0, CUT_TEXT_LENGTH);
  };

  vm.getFormattedDate = function() {
    if (!vm.email.formattedDate) {
      let date;
      if (vm.email.status === emailStatus.getDraftStatus()) {
        date = vm.email.updatedAt || vm.email.createdAt;
      } else {
        date = vm.email.sentTime;
      }
      const dateMoment = moment(date, dateService.getIsoFullDateFormatWithT(true));
      dateMoment.add(moment().utcOffset() - dateMoment.utcOffset(), 'minutes');
      vm.email.formattedDate = dateMoment.format(`D MMM YYYY, ${dateService.getWesternTimeFormat(true)}`);
    }
    return vm.email.formattedDate;
  };

  const isReceiverMatches = function (receiver, matchString) {
    return (receiver.employee.fullName.toLowerCase().indexOf(matchString.toLowerCase()) !== -1) ||
      (receiver.employee.email.toLowerCase().indexOf(matchString.toLowerCase()) !== -1);
  };

  return vm;

};

angular.module('public.security-manager.client.show')
  .controller('ClientInvoiceEmailRowController', dependencies.concat(ClientInvoiceEmailRowController));
