/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$timeout', 'FlexibleTimeLineConverter', 'FlexibleTimeLineViewSelector', 'FlexibleTimeLineInteractor',
  'dateService', 'timelinePager', 'bookingShiftEmployeeAssignStatus'
];

const EmployeeTimeLineInteractor = function(
  $timeout, FlexibleTimeLineConverter, FlexibleTimeLineViewSelector, FlexibleTimeLineInteractor,
  dateService, timelinePager, bookingShiftEmployeeAssignStatus
) {

  return class EmployeeTimeLineInteractor extends FlexibleTimeLineInteractor {

    constructor(timeLineLoader, from) {
      super(...arguments);
      this.timeLineLoader = timeLineLoader;
      this.viewSelector = new FlexibleTimeLineViewSelector;
      if (timelinePager.getPagerParams()) {
        this.viewSelector.selectViewByType(timelinePager.getPagerParams().viewType);
      }
      this.timeLineConverter = new FlexibleTimeLineConverter(this.viewSelector.getCurrentView(), this._getHourWidth());
      this._initDates(timelinePager.getPagerParams().from || from);
      this.updateHourWidth();
    }

    selectView(view) {
      const selected = this.viewSelector.selectView(view);
      if (selected) {
        this._showLoader();
        this.timeLineConverter.setOffsets(view);
        this.loadDataAndSetView();
        return this.updateHourWidth();
      }
    }

    getTimelineItemPeriod(timelineItem) {
      return timelineItem.period || (timelineItem.period = [
        timelineItem.getStartTimeMoment().format(dateService.getWesternTimeFormat()),
        timelineItem.getEndTimeMoment().format(dateService.getWesternTimeFormat())
      ].join(' - '));
    }

    getTimelineItemDimensions(timelineItem) {
      return {
        width: this._getTimelineItemWidth(timelineItem) + 'px',
        left: this._getTimelineItemOffset(timelineItem) + 'px'
      };
    }

    getTimelineItemClass(timelineItem) {
      let color;
      if (timelineItem.shiftEmployee) {
        const { assignStatus } = timelineItem.shiftEmployee;
        if (bookingShiftEmployeeAssignStatus.countsAsAssigned(assignStatus)) {
          color = 'green';
        } else if (bookingShiftEmployeeAssignStatus.countsAsInvited(assignStatus)) {
          color = 'yellow';
        } else if (bookingShiftEmployeeAssignStatus.countsAsUnavailable(assignStatus)) {
          color = 'red';
        }
      }
      if (color) { return `mod-${color}`; }
    }

    getCurrentTimeOffset() {
      if (!this.timeLineStart || !this.timeLineEnd) { return; }
      if ((this.now.diff(this.timeLineEnd) >= 0) || (this.timeLineStart.diff(this.now) > 0)) { return; }
      return this.timeLineConverter.getOffsetFromTime(this.now, this.timeLineStart);
    }

    loadDataAndSetView() {
      timelinePager.setPagerParams({ from: this.from.clone(), viewType: this.viewSelector.getCurrentView().type });
      if (this.viewSelector.isSevenDays) {
        this.timeLineStart = this.from.clone();
      } else {
        this.timeLineStart = this.from.clone().subtract(12, 'hours');
      }
      this.timeLineEnd = this.timeLineStart.clone().add(this.viewSelector.getCurrentDaysCount() + 2, 'days').add(12, 'hours');
      this.timeLineStart = this.timeLineStart.add(30, 'minutes'); // timeline starts after half hour
      return this.loadData();
    }

    loadData() {
      this._showLoader();
      return this.timeLineLoader.loadForDates(
        this.getFrom().format(dateService.getIsoFullDateFormatWithTz()),
        this.getTo().format(dateService.getIsoFullDateFormatWithTz())
      ).then(() => {
        this._setDatesArray();
        return this._hideLoader();
      });
    }

    updateHourWidth() {
      return $timeout(() => {
        return this.timeLineConverter.setHourWidth(this._getHourWidth());
      });
    }

    _getTimelineItemOffset(timelineItem) {
      return this.timeLineConverter.getTimelineItemOffset(timelineItem.timelineStartTime, this.timeLineStart);
    }

    _getTimelineItemWidth(timelineItem) {
      return this.timeLineConverter.getHourWidth() * timelineItem.timelineEndTime.diff(timelineItem.timelineStartTime, 'hours', true);
    }

    _getHourWidth() {
      return document.querySelector('.employee-timeline-interval-day-item').getBoundingClientRect().width;
    }
  }

};

angular.module('public.employee.timeline')
  .factory('EmployeeTimeLineInteractor', dependencies.concat(EmployeeTimeLineInteractor));
