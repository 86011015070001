const gccsPopupShiftDetailsCheckIn = {
  controller: 'PopupShiftDetailsCheckInController',
  template: require('./template.pug'),
  bindings: {
    editable: '<',
    shift: '<'
  }
};

angular.module('popup.booking').component('gccsPopupShiftDetailsCheckIn', gccsPopupShiftDetailsCheckIn);
