/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$timeout', 'Client', 'securityManagerNotificationTranslator', 'securityManagerNotificationType',
  'securityManagerNotificationReadFilterService', 'notificationService', 'PaginatorService',
  'SecurityManagerNotificationsFilter', 'SecurityManagerNotificationsTab', 'SecurityManagerNotificationsLoader',
  'SecurityManagerNotification', 'securityManagerNotificationPriorityService', 'pluralizeService', 'packageTypeService'
];

const SecurityManagerNotificationsService = (
  $timeout, Client, securityManagerNotificationTranslator, securityManagerNotificationType,
  securityManagerNotificationReadFilterService, notificationService, PaginatorService,
  SecurityManagerNotificationsFilter, SecurityManagerNotificationsTab, SecurityManagerNotificationsLoader,
  SecurityManagerNotification, securityManagerNotificationPriorityService, pluralizeService, packageTypeService
) => {

  const DELETE_ANIMATION_DELAY = 550;

  const READ_FILTER_NAMES = securityManagerNotificationReadFilterService.getNames();
  const READ_FILTER_ALL = securityManagerNotificationReadFilterService.getAll();
  const READ = securityManagerNotificationReadFilterService.getRead();
  const UNREAD = securityManagerNotificationReadFilterService.getUnread();

  return class SecurityManagerNotificationsService {

    constructor(securityManager) {
      this.securityManager = securityManager;
      this.loader = new SecurityManagerNotificationsLoader(this.securityManager);
      this.paginator = new PaginatorService();
      this.notifications = [];
      this.notificationGroups = [];
      this.isShownRegisteredEmployeesPopup = false;
      this.uploadedGroupEmployees = [];
      this._initTabs();
      this._initFilters();
      this._loadCurrentView();
      this._loadTabCounts();
    }

    loadMore() {
      this.loader.isLoading = true;
      const queryParams = this._queryParams();
      const paginationParams = this.paginator.nextPageParams();
      return this.loader.load(queryParams, paginationParams, newNotifications => {
        const translatedNotifications = _.map(newNotifications, this._translateNotification);
        this.notifications = _.uniqBy(this.notifications.concat(translatedNotifications), notification => notification.id);
        return this._setNotificationGroups();
      });
    }

    isDisabledLoading() {
      return this.loader.isDisabledLoading();
    }

    selectTab(tab) {
      if (tab.isSelected) { return; }
      tab.select();
      this._deselectTabsExcept(this.notificationTabs, tab);
      return this._loadCurrentView();
    }

    markTabAllRead(tab) {
      SecurityManagerNotification.markReadByTypes(this.securityManager.id, { types: tab.tabTypes }).then((data) => {
        if (data.updatedCount > 0) {
          this._loadCurrentView();
          this._loadTabCounts();
          notificationService.notifySuccess(`${pluralizeService.simple(data.updatedCount, 'notification')} marked as read`);
        }
      });
    }

    selectFilterOption(filter, option) {
      if (filter.isSelectedOption(option)) { return; }
      filter.selectOption(option);
      return this._loadCurrentView();
    }

    toggleReadCb(notification) {
      const statusFilter = this.notificationFilters[1];
      if (statusFilter.selectedOption.label !== 'All') {
        notification.isRemoving = true;
      }
      $timeout(() => {
        const promise = notification.isRead ?
          this._markRead(notification)
          :
          this._markUnread(notification);
        return promise.finally(() => {
          this._loadNotification(notification);
          return this._loadTabCounts();
        });
      }, DELETE_ANIMATION_DELAY);
    }

    closeRegisteredEmployeesPopup() {
      this.isShownRegisteredEmployeesPopup = false;
    }

    _translateNotification = (notification) => {
      return securityManagerNotificationTranslator.translateNotification(this, notification);
    };

    notificationCreated(notification) {
      let notificationToReload;
      if ((notificationToReload = this._alreadyPresentNotification(notification.id))) {
        return this._loadNotification(notificationToReload);
      } else {
        return this._loadNewNotification(notification);
      }
    }

    notificationUpdated(notification) {
      let notificationToReload;
      if (notificationToReload = this._alreadyPresentNotification(notification.id)) {
        return this._loadNotification(notificationToReload);
      }
    }

    notificationDestroyed(notification) {
      let notificationToRemove;
      if (notificationToRemove = this._alreadyPresentNotification(notification.id)) {
        this._removeNotification(notificationToRemove);
      }
      return this._loadTabCounts();
    }

    canLoadMore() {
      return this.notifications.length < this.viewCount;
    }

    _initTabs() {
      this.notificationTabs = [
        new SecurityManagerNotificationsTab(
          'All',
          { tabTypes: securityManagerNotificationType.getAllStatuses() },
          true
        ),
        new SecurityManagerNotificationsTab(
          'Shifts',
          { tabTypes: securityManagerNotificationType.getShiftsNotifications() }
        ),
        new SecurityManagerNotificationsTab(
          'Bookings',
          { tabTypes: securityManagerNotificationType.getBookingsNotifications() }
        ),
        new SecurityManagerNotificationsTab(
          'Requests',
          { tabTypes: securityManagerNotificationType.getRequestsNotifications() }
        ),
        new SecurityManagerNotificationsTab(
          'Invoices',
          { tabTypes: securityManagerNotificationType.getInvoiceNotifications() }
        ),
        new SecurityManagerNotificationsTab(
          'Training & Licenses',
          { tabTypes: securityManagerNotificationType.getTrainingAndLicensesNotifications() }
        )
      ];
      if (packageTypeService.hasProPackage()) {
        this.notificationTabs.splice(2, 0,
          new SecurityManagerNotificationsTab(
            'Clock in/out',
            { tabTypes: securityManagerNotificationType.getClockInOutNotifications() }
          )
        );
        this.notificationTabs.push(
          new SecurityManagerNotificationsTab(
            'Integrations',
            { tabTypes: securityManagerNotificationType.getIntegrationNotifications() }
          )
        );
      }
    }

    _initFilters() {
      const roleFilter = new SecurityManagerNotificationsFilter([
        { label: 'All', alias: 'Type', scope: { filterTypes: securityManagerNotificationType.getAllStatuses() } },
        { label: 'Staff', scope: { filterTypes: securityManagerNotificationType.getStaffNotifications() } },
        { label: 'Client', scope: { filterTypes: securityManagerNotificationType.getClientsNotifications() } },
        { label: 'System', scope: { filterTypes: securityManagerNotificationType.getSystemNotifications() } },
      ]);
      const readFilter = new SecurityManagerNotificationsFilter([
        { label: READ_FILTER_NAMES[READ_FILTER_ALL], alias: 'All statuses', scope: { read: READ_FILTER_ALL } },
        { label: READ_FILTER_NAMES[READ], scope: { read: READ } },
        { label: READ_FILTER_NAMES[UNREAD], scope: { read: UNREAD } },
      ]);
      readFilter.selectOption(readFilter.options[2]);
      this.notificationFilters = [roleFilter, readFilter];
    }

    _setNotificationGroups() {
      const priorityGroups = this._getGroupsByPriority();
      this._setNotificationsToPriorityGroups(priorityGroups);
      this.notificationGroups = this._getNotificationGroups(priorityGroups);
      this.loader.isLoading = false;
    }

    _getGroupsByPriority() {
      const priorityGroups = {};
      _.each(securityManagerNotificationPriorityService.getNames(), (priorityName, priorityId) => {
        priorityGroups[priorityId] = {
          label: priorityName,
          notifications: []
        }
      });
      return priorityGroups;
    }

    _setNotificationsToPriorityGroups(priorityGroups) {
      _.each(this.notifications, (notification) => {
        priorityGroups[notification.priorityStatus].notifications.push(notification);
      });
    }

    _getNotificationGroups(priorityGroups) {
      const notificationGroups = [];
      _.each(priorityGroups, (priorityGroup) => {
        if (priorityGroup.notifications.length) {
          priorityGroup.notifications = _.orderBy(priorityGroup.notifications, ['createdAt'], ['desc']);
          notificationGroups.push(priorityGroup);
        }
      });
      return notificationGroups;
    }

    _loadCurrentView() {
      this.loader.isLoading = true;
      const queryParams = this._queryParams();
      this.paginator.resetOffset();
      const paginationParams = this.paginator.nextPageParams();
      if (queryParams['types[]'].length === 0) {
        this.notifications = [];
        this._setNotificationGroups();
        return this.viewCount = 0;
      } else {
        this.loader.load(queryParams, paginationParams, notifications => {
          this.notifications = _.map(notifications, this._translateNotification);
          return this._setNotificationGroups();
        });
        return this._loadCurrentViewCount();
      }
    }

    _queryParams() {
      const params = _.reduce(this.notificationFilters.concat(this.notificationTabs), (acc, selectable) => {
        return _.assign(acc, selectable.getSelectedScope());
      }, {});
      return {
        'types[]': _.intersection(params.tabTypes, params.filterTypes),
        read: params.read
      };
    }

    _loadNewNotification(notification) {
      this.loader.loadNotification(notification)
        .then(newNotification => {
          return this._checkedAddNewNotification(this._translateNotification(newNotification));
        }).then(() => this._loadCurrentViewCount());
      return this._loadTabCounts();
    }

    _shouldSeeNotification(notification) {
      const params = this._queryParams();
      if (!_.includes(params['types[]'], notification.type)) {
        return;
      }
      if (!!params.read && (!(notification.isRead || (params.read !== READ)) && !(!notification.isRead || (params.read !== UNREAD)))) {
        return;
      }
      return this.notifications.length === 0 ||
        !this.canLoadMore() ||
        notification.eventDateMoment.isSameOrAfter(_.last(this.notifications).eventDateMoment);
    }

    _addNewNotification(notification) {
      this.notifications.unshift(notification);
      this._setNotificationGroups();
      return this.paginator.incrementOffset();
    }

    _checkedAddNewNotification(notification) {
      if (this._shouldSeeNotification(notification)) {
        return this._addNewNotification(notification);
      }
    }

    _alreadyPresentNotification(notificationId) {
      for (let group of Array.from(this.notificationGroups)) {
        const notification = _.find(group.notifications, n => n.object.id === notificationId);
        if (notification) {
          return notification;
        }
      }
    }

    _markRead(notification) {
      return SecurityManagerNotification.markRead(this.securityManager.id, notification.id).then(() => {
        if (this._queryParams().read === UNREAD) {
          return this._removeNotification(notification);
        }
      });
    }

    _markUnread(notification) {
      return SecurityManagerNotification.markUnread(this.securityManager.id, notification.id).then(() => {
        if (this._queryParams().read === READ) {
          return this._removeNotification(notification);
        }
      });
    }

    _removeNotification(notification) {
      this.paginator.decrementOffset();
      return this._loadCurrentViewCount().then(() => {
        _.pull(this.notifications, notification);
        return this._setNotificationGroups();
      });
    }

    _updateReloadedNotificationInView(notification) {
      const i = _.findIndex(this.notifications, n => n.object.id === notification.id);
      if (i !== -1) {
        this.notifications[i] = this._translateNotification(notification);
        return this._setNotificationGroups();
      }
    }

    _loadNotification(notification) {
      notification.object.securityManagerId = this.securityManager.id;
      return notification.object.get().then(actualNotification => {
        return this._updateReloadedNotificationInView(actualNotification);
      }).catch(function() {
        // do nothing
      });
    }

    _deselectTabsExcept(tabs, exceptTab) {
      return (() => {
        const result = [];
        for (let tab of Array.from(tabs)) {
          if (tab !== exceptTab) {
            result.push(tab.deselect());
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    }

    _loadTabCounts() {
      return this.loader.loadCounts().then(counts => {
        return this._setTabCounts(counts.counts);
      });
    }

    _loadCurrentViewCount() {
      return this.loader.loadCounts(this._queryParams()).then(counts => {
        return this.viewCount = counts.counts;
      });
    }

    _setTabCounts(counts) {
      return _.each(this.notificationTabs, tab => tab.setCount(counts));
    }

  };

};

angular.module('public.security-manager.notifications')
  .factory('SecurityManagerNotificationsService', dependencies.concat(SecurityManagerNotificationsService));
