const dependencies = ['notificationService'];

const LiveFeedItemPreview = function(notificationService) {

  const vm = this;

  vm.toggleSelectShift = function() {
    if (vm.dShiftIssue.isMultipleSelected) {
      vm.shiftSelector.multipleDeselectShift(vm.dShiftIssue);
      vm.dShiftIssue.isMultipleSelected = false;
    } else {
      const selected = vm.shiftSelector.multipleSelectShift(vm.dShiftIssue);
      if (selected) {
        vm.dShiftIssue.isMultipleSelected = true;
      } else {
        notificationService.notifyInfo('You try to select shifts that belong to different clients');
      }
    }
  };
  
  vm.getSelectText = function() {
    if (vm.dShiftIssue.isMultipleSelected) {
      return 'Unselect shift';
    } else {
      return 'Select shift';
    }
  };

  vm.getOpenText = function() {
    if (vm.dShiftIssue.isOpenedFully) {
      return 'Close shift details';
    } else {
      return 'Open shift details';
    }
  };

  return vm;

};

angular.module('public.security-manager.schedule-manager.timeline')
  .controller('LiveFeedItemPreviewController', dependencies.concat(LiveFeedItemPreview));
