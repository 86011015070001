const dependencies = [
  '$scope', '$location', 'FilterParamsService', 'dateService', 'employeeHolidayService', 'EntityManager',
  'Employee', 'downloadService', 'employeeStatus'
];

const ReportsHrEmployeeAvailabilityController = function(
  $scope, $location, FilterParamsService, dateService, employeeHolidayService, EntityManager,
  Employee, downloadService, employeeStatus
) {

  const vm = this;

  const entityManager = new EntityManager(Employee, {
    loadCbName: 'getAvailabilityReport',
    queryParams: {
      status: employeeStatus.getActiveStatus()
    }
  });
  const filterParamsService = new FilterParamsService({
    order: ['name'],
    filter: ['from', 'to', 'name']
  });

  vm.filterParams = filterParamsService.getParams();

  vm.paramsInitialized = false;

  vm.$onInit = function() {
    initParams();
    vm.filterAvailabilityData();
  };

  vm.getRemainingHolidaysHours = function(employeeAvailability) {
    return employeeAvailability.accruedHolidays +
      employeeAvailability.additionalHolidayHours -
      vm.getTakenHolidaysHours(employeeAvailability.takenHolidays);
  };

  vm.getRemainingHolidaysCount = function(employeeAvailability) {
    return employeeHolidayService.getRemainingHolidayDays(
      employeeAvailability.accruedHolidays + employeeAvailability.additionalHolidayHours,
      vm.getTakenHolidaysHours(employeeAvailability.takenHolidays)
    );
  };

  vm.getTakenHolidaysDays = function(takenHolidays) {
    const [days, hours] = takenHolidays;
    if (hours !== undefined) {
      return +days
    } else {
      return vm.getHolidayDaysCount(days);
    }
  };

  /*
    Returned value depends on arguments array length.
    Arguments array treated next way:
    for 2 values -> [days, hours]
    for 1 value -> [hours]
   */
  vm.getTakenHolidaysHours = function(takenHolidays) {
    const [possiblyHours, hours] = takenHolidays;
    if (hours !== undefined) {
      return +hours;
    } else {
      return +possiblyHours;
    }
  };

  vm.getHolidayDaysCount = function(hours) {
    return Math.floor(hours / employeeHolidayService.getDefaultHolidaysHours());
  };

  vm.getSickDaysCount = function(hours) {
    return Math.floor(hours / employeeHolidayService.getDefaultSickDaysHours());
  };

  vm.toggleTableOrder = function(field) {
    _.each(vm.filterParams.order, (value, key) => {
      if (key === field) {
        if (value.ascending === null) {
          value.ascending = true;
        } else {
          value.ascending = !value.ascending;
        }
      } else {
        value.ascending = null;
      }
    });
  };

  vm.getSortClass = function(field) {
    if (vm.filterParams.order[field].ascending === true) {
      return 'reports-hr-title-sortable-bottom';
    } else if (vm.filterParams.order[field].ascending === false) {
      return 'reports-hr-title-sortable-top';
    } else {
      return '';
    }
  };

  vm.loadMoreEmployeesAvailabilityData = () => entityManager.loadMore();

  vm.getEmployeesAvailabilityData = () => entityManager.entities;

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  vm.exportAvailabilityReport = function() {
    const urlParams = filterParamsService.getFilterUrlParams();
    if (vm.endDateFrom && vm.endDateTo) {
      urlParams.from = moment(vm.endDateFrom, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
      urlParams.to = moment(vm.endDateTo, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
    }
    _.extend(urlParams, entityManager.options.queryParams);
    Employee.exportAvailabilityReport(urlParams).then(function (response) {
      downloadService.downloadBase64File(response);
    });
  };

  vm.filterAvailabilityData = function() {
    if (!vm.paramsInitialized) { return; }
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    if (vm.endDateFrom && vm.endDateTo) {
      urlParams.from = moment(vm.endDateFrom, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
      urlParams.to = moment(vm.endDateTo, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
    }
    $location.search(urlParams);
    return entityManager.load(urlParams);
  };

  const initParams = function() {
    const locationParams = $location.search();
    if (locationParams.from && locationParams.to) {
      vm.endDateFrom = moment(locationParams.from, dateService.getIsoDateFormat());
      vm.endDateTo = moment(locationParams.to, dateService.getIsoDateFormat());
    } else {
      const today = dateService.today();
      vm.endDateFrom = today.clone().subtract(30, 'days');
      vm.endDateTo = today.clone();
    }
    vm.paramsInitialized = true;
  };

  $scope.$watch(() => vm.filterParams, _.debounce(vm.filterAvailabilityData, 50) , true);

  return vm;

};

angular.module('public.security-manager.reports.hr')
  .controller('ReportsHrEmployeeAvailabilityController', dependencies.concat(ReportsHrEmployeeAvailabilityController));
