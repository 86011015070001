const dependencies = [
  '$scope', '$timeout', 'User', 'notificationService', 'CountryCodeInput',
  'VerifyAuthCodeService'
];

const ProfileDetailsChangePhoneController = function(
  $scope, $timeout, User, notificationService, CountryCodeInput,
  VerifyAuthCodeService
) {

  const vm = this;

  vm.countryCodeInput = new CountryCodeInput();
  vm.verifyAuthCodeService = new VerifyAuthCodeService();

  vm.isPhoneChangeInitiated = false;
  vm.errorMessage = null;

  vm.$onInit = function () {
    copyUser();
    vm.countryCodeInput.initPhoneOptions();
    vm.countryCodeInput.initPhoneCodesList();
  };

  vm.onCleaveInit = function(cleave) {
    vm.countryCodeInput.setCleaveInstance(cleave);
  };

  vm.onFormSubmit = function () {
    if ($scope.ProfileChangePhoneForm.$invalid) {
      return;
    }
    if (!vm.isPhoneChangeInitiated) {
      validatePhoneNumberAndSend();
    } else {
      confirmChangePhone();
    }
  };

  vm.turnOffPhoneChangeMode = function () {
    copyUser();
    vm.isPhoneChangeInitiated = false;
    vm.countryCodeInput.initPhoneNumber();
  };

  vm.isErrorClassAdded = function () {
    return !vm.isPhoneChangeInitiated &&
      (($scope.ProfileChangePhoneForm.$submitted && $scope.ProfileChangePhoneForm.$invalid) || vm.errorMessage)
  };

  const validatePhoneNumberAndSend = function () {
    if (!isPhoneNumberChanged()) {
      vm.errorMessage = 'Phone number was not changed';
    } else {
      validateUniquenessAndSend();
    }
  };

  const validateUniquenessAndSend = function () {
    vm.userCopy.phoneNumber = vm.countryCodeInput.getFormattedPhoneNumber();
    const phoneNumber = vm.userCopy.phoneNumber;
    vm.errorMessage = null;
    User.validate({ phoneNumber: phoneNumber, except: vm.user.id }).then(function(isUnique) {
      if (!isUnique) {
        vm.errorMessage = 'Phone number is not unique';
      } else {
        initPhoneChangeMode();
      }
    });
  };

  const isPhoneNumberChanged = function () {
    return !(vm.userCopy.phoneNumber === `+${vm.countryCodeInput.selectedCode.callingCode}` ||
      vm.userCopy.phoneNumber === (vm.user.phoneNumber && vm.user.phoneNumber.replace(/\s/g, '')));
  };

  const initPhoneChangeMode = function () {
    vm.isPhoneChangeInitiated = true;
    vm.verifyAuthCodeService.sendCode({
      userId: vm.userCopy.id,
      phoneNumber: vm.userCopy.phoneNumber,
      resetCb: () => {
        vm.errorMessage = null;
      },
      failCb: () => {
        vm.errorMessage = 'Phone number is not valid';
      }
    });
  };

  const confirmChangePhone = function () {
    vm.verifyAuthCodeService.confirmUserPhoneCode().then(function() {
      onSuccessChangePhone();
    }).catch(function(response) {
      vm.errorMessage = response.data.error;
    });
  };

  const onSuccessChangePhone = function () {
    vm.userCopy.save().then(function() {
      vm.user = angular.copy(vm.userCopy);
      vm.turnOffPhoneChangeMode();
      notificationService.notifySuccess('Your profile was successfully updated!');
    });
  };

  const copyUser = function () {
    vm.userCopy = angular.copy(vm.user);
    vm.countryCodeInput.setPhoneHolder(vm.userCopy);
  };

  return vm;

};

angular.module('public.security-manager.profile')
  .controller('ProfileDetailsChangePhoneController', dependencies.concat(ProfileDetailsChangePhoneController));
