/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('popup.report').directive('gccsPopupReportMissingDataFilter', [
  'popupDirectiveConfig',
  popupDirectiveConfig =>
    popupDirectiveConfig.configure({
      bodyTemplate: require('./template.pug'),
      scope: {
        fields: '<'
      }
    })
]);