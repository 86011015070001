const dependencies = ['validationService', 'bookingShiftValidationService'];

const ScheduleManagerShiftRuleCheckInController = function(validationService, bookingShiftValidationService) {

  const vm = this;

  const DEFAULT_CHECK_IN_INTERVAL = 60;

  vm.validationService = validationService;

  vm.$onInit = function() {
    if (!vm.version.firstCheckInTime) {
      vm.version.firstCheckInTime = vm.version.startTime;
    }
    if (!vm.version.lastCheckInTime) {
      vm.version.lastCheckInTime = vm.version.endTime;
    }
    if (!vm.version.checkInInterval) {
      vm.version.checkInInterval = DEFAULT_CHECK_IN_INTERVAL;
    }
  };

  vm.getFirstCheckInErrors = function() {
    return bookingShiftValidationService.getFirstCheckInDatelessError();
  };

  vm.getLastCheckInErrors = function() {
    return bookingShiftValidationService.getLastCheckInDatelessError();
  };

  return vm;

};

angular.module('public.security-manager.schedule-manager.repeatable-booking')
  .controller('ScheduleManagerShiftRuleCheckInController', dependencies.concat(ScheduleManagerShiftRuleCheckInController));
