/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$filter', 'EntityManager', 'EmployeeWorkHistory', 'dateService', 'employeeType'
];

const EmployeeProfileWorkHistoryController = function(
  $scope, $filter, EntityManager, EmployeeWorkHistory, dateService, employeeType
) {

  const vm = this;

  const LAST_YEAR = 10000;
  const NOW_LABEL = 'Now';

  vm.isShownWorkHistoryPopup = false;
  vm.groupedWorkHistories = {};
  vm.maxDescriptionLength = 125;
  vm.employeeType = employeeType;

  const entityManager = new EntityManager(EmployeeWorkHistory, {
    params : {
      employeeId : $scope.employeeProfileLayoutCtrl.employeeId
    }
  }
  );
  const formatFilter = $filter('formatDate');

  $scope.employeeProfileLayoutCtrl.loadEmployee().then(employee => vm.employee = employee);

  vm.removeWorkHistory = workHistory =>
    entityManager.removeEntity(workHistory, function() {
      vm.employee.workHistoriesCount--;
      return groupWorkHistories();
    })
  ;

  vm.showWorkHistoryPopup = function(workHistory) {
    vm.popupWorkHistory = workHistory || entityManager.createEntity();
    return vm.isShownWorkHistoryPopup = true;
  };

  vm.closeWorkHistoryPopup = function(savedWorkHistory) {
    vm.isShownWorkHistoryPopup = false;
    if (savedWorkHistory) {
      vm.employee.workHistoriesCount++;
      entityManager.updateEntity(savedWorkHistory);
      return groupWorkHistories();
    }
  };

  vm.getYears = () => _.reverse(_.sortBy(_.keys(vm.groupedWorkHistories), year => year - 0));

  vm.getYearLabel = function(year) {
    if ((year - 0) === LAST_YEAR) { return NOW_LABEL; } else { return year; }
  };

  vm.getPeriodLabel = function(workHistory) {
    const labelParts = [formatFilter(workHistory.from)];
    if (workHistory.to) {
      labelParts.push(formatFilter(workHistory.to));
    } else {
      labelParts.push(NOW_LABEL);
    }
    return labelParts.join(' - ');
  };

  var groupWorkHistories = function() {
    const groupedWorkHistories = {};
    _.each(entityManager.entities, function(workHistory) {
      let year;
      if (workHistory.to) {
        year = new Date(workHistory.to).getFullYear();
      } else {
        year = LAST_YEAR;
      }
      if (!groupedWorkHistories[year]) { groupedWorkHistories[year] = []; }
      return groupedWorkHistories[year].push(workHistory);
    });
    return vm.groupedWorkHistories = groupedWorkHistories;
  };

  entityManager.loadAll().then(groupWorkHistories);

  return vm;
};

angular.module('public.employee.profile').controller('EmployeeProfileWorkHistoryController',
  dependencies.concat(EmployeeProfileWorkHistoryController));
