/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$timeout', 'FlexibleTimeLineInteractor', 'User', 'cableService', 'dateService',
  'employeeDayOffRequestStatus', 'employeeSickDayRequestStatus'
];

const FlexibleTimeLineEmployeeInteractor = (
  $timeout, FlexibleTimeLineInteractor, User, cableService, dateService,
  employeeDayOffRequestStatus, employeeSickDayRequestStatus
) => {

  const SHIFT_ROW_LEFT_PADDING = 80;
  const ISO_FULL_DATE_FORMAT = dateService.getIsoFullDateFormat();
  const WESTERN_TIME_FORMAT = dateService.getWesternTimeFormat();
  const VIEW_1_MONTH = 3;

  return class FlexibleTimeLineEmployeeInteractor extends FlexibleTimeLineInteractor {

    constructor() {
      super(...arguments);
      this.viewSelector.viewList.push({
        label: 'Month view', days: 31, hourWidth: 9.25 / 4, offsetCount: 34, offset: 50, type: VIEW_1_MONTH
      })
      this._subscribeToBookingTimeline();
    }

    isMonthView() {
      return this.viewSelector.currentView.type === VIEW_1_MONTH;
    }

    getHourWidth() {
      const itemWidth = this.element.querySelector('.interval-day-item').getBoundingClientRect().width;
      if (this.isMonthView()) {
        return itemWidth / 4;
      } else {
        return itemWidth;
      }
    }

    getShiftDimensions(dShift) {
      return {
        width: this._getShiftWidth(dShift) + 'px',
        left: (this.timeLineConverter.getOutset() + this._getShiftOffset(dShift) - SHIFT_ROW_LEFT_PADDING) + 'px'
      };
    }

    _getShiftOffset(dShift) {
      return this.timeLineConverter.getTimelineItemOffset(dShift.timelineStartTime, this.timeLineStart);
    }

    _getShiftWidth(dShift) {
      return this._getTimelineItemWidth(dShift.timelineEndTime.diff(dShift.timelineStartTime, 'hours', true));
    }

    _getShiftPeriod(dShift) {
      return dShift.timelineStartTime.format(WESTERN_TIME_FORMAT) + ' - ' + dShift.timelineEndTime.format(WESTERN_TIME_FORMAT);
    }

    _subscribeToBookingTimeline() {
      const bookingTimelineChannel = cableService.getBookingTimelineChannel();
      bookingTimelineChannel.addCallback('shift_lock', (data) => this._shiftLocked(data));
      bookingTimelineChannel.addCallback('shift_unlock', (data) => this._shiftUnlocked(data));
      bookingTimelineChannel.addCallback('shift_employee', (data) => this._shiftEmployeeUpdated(data));
      bookingTimelineChannel.addCallback('shift_employee_destroyed', (data) => this._shiftEmployeeDestroyed(data));
      bookingTimelineChannel.addCallback('day_off', (data) => this._dayOffUpdated(data));
      bookingTimelineChannel.addCallback('day_off_destroyed', (data) => this._dayOffDestroyed(data));
      bookingTimelineChannel.addCallback('sick_day', (data) => this._sickDayUpdated(data));
      bookingTimelineChannel.addCallback('sick_day_destroyed', (data) => this._sickDayDestroyed(data));
      bookingTimelineChannel.addCallback('availability', (data) => this._availabilityUpdated(data));
      bookingTimelineChannel.addCallback('availability_destroyed', (data) => this._availabilityDestroyed(data));
    }

    _shiftLocked(shiftLock) {
      let lockedBy = undefined;
      if (shiftLock.user) {
        lockedBy = new User(shiftLock.user);
      }
      $timeout(() => this.timeLineLoader.shiftLocked(lockedBy, shiftLock.booking_series_id));
    }

    _shiftUnlocked(shiftUnlock) {
      $timeout(() => this.timeLineLoader.shiftUnlocked(shiftUnlock.booking_series_id));
    }

    _shiftEmployeeDestroyed(shiftEmployee) {
      $timeout(() => this.timeLineLoader.deleteShiftEmployee(shiftEmployee));
    }

    _shiftEmployeeUpdated(shiftEmployee) {
      if (this._shouldBeInView(shiftEmployee.start_time, shiftEmployee.end_time)) {
        $timeout(() => this._reloadShiftEmployee(shiftEmployee));
      }
    }

    _dayOffUpdated(dayOff) {
      if (this._shouldBeInView(dayOff.start_time, dayOff.end_time)) {
        if (employeeDayOffRequestStatus.isRejected(dayOff.status)) {
          $timeout(() => this.timeLineLoader.dayOffDeleted(dayOff));
        } else {
          $timeout(() => this._reloadDayOff(dayOff));
        }
      }
    }

    _dayOffDestroyed(dayOff) {
      if (this._shouldBeInView(dayOff.start_time, dayOff.end_time)) {
        $timeout(() => this.timeLineLoader.dayOffDeleted(dayOff));
      }
    }

    _sickDayUpdated(sickDay) {
      if (this._shouldBeInView(sickDay.start_time, sickDay.end_time)) {
        if (employeeSickDayRequestStatus.isRejected(sickDay.status)) {
          $timeout(() => this.timeLineLoader.sickDayDeleted(sickDay));
        } else {
          $timeout(() => this._reloadSickDay(sickDay));
        }
      }
    }

    _sickDayDestroyed(sickDay) {
      if (this._shouldBeInView(sickDay.start_time, sickDay.end_time)) {
        $timeout(() => this.timeLineLoader.sickDayDeleted(sickDay));
      }
    }

    _availabilityUpdated(availability) {
      if (this._shouldBeInView(availability.start_time, availability.end_time)) {
        $timeout(() => this._reloadAvailability(availability));
      }
    }

    _availabilityDestroyed(availability) {
      if (this._shouldBeInView(availability.start_time, availability.end_time)) {
        $timeout(() => this.timeLineLoader.availabilityDeleted(availability));
      }
    }

    _reloadShiftEmployee(shiftEmployee) {
      return this.timeLineLoader.reloadShiftEmployee(shiftEmployee)
        .then(shiftEmployee => {
          if (this._shouldBeInView(shiftEmployee.startTime, shiftEmployee.endTime)) {
            return this.timeLineLoader.updateShiftEmployee(shiftEmployee);
          }
        })
        .catch(() => {
          return this.timeLineLoader.deleteShiftEmployee(shiftEmployee);
        });
    }

    _reloadDayOff(dayOff) {
      return this.timeLineLoader.reloadDayOff(dayOff)
        .then(dayOff => {
          if (this._shouldBeInView(dayOff.startTime, dayOff.endTime)) {
            return this.timeLineLoader.dayOffUpdated(dayOff);
          }
        })
        .catch(() => {
          return this.timeLineLoader.dayOffDeleted(dayOff);
        });
    }

    _reloadSickDay(sickDay) {
      return this.timeLineLoader.reloadSickDay(sickDay)
        .then(sickDay => {
          if (this._shouldBeInView(sickDay.startTime, sickDay.endTime)) {
            return this.timeLineLoader.sickDayUpdated(sickDay);
          }
        })
        .catch(() => {
          return this.timeLineLoader.sickDayDeleted(sickDay);
        });
    }

    _reloadAvailability(availability) {
      this.timeLineLoader.reloadAvailability(availability)
        .then(availability => {
          if (this._shouldBeInView(availability.startTime, availability.endTime)) {
            return this.timeLineLoader.availabilityUpdated(availability);
          }
        })
        .catch(() => {
          return this.timeLineLoader.availabilityDeleted(availability);
        });
    }

    _shouldBeInView(startTime, endTime) {
      return !(
        moment(endTime, ISO_FULL_DATE_FORMAT).isBefore(this.getFrom()) ||
        moment(startTime, ISO_FULL_DATE_FORMAT).isAfter(this.getTo())
      );
    }

  };

};

angular.module('public.security-manager.schedule-manager.timeline')
  .factory('FlexibleTimeLineEmployeeInteractor', dependencies.concat(FlexibleTimeLineEmployeeInteractor));
