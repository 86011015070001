import './styles.css';
import './styles/_import.css';

const dependencies = [
  '$scope', 'ClientServiceResource', 'confirmService', 'currentUserService', 'resourceChargeType'
];

const ClientResourceTableController = function(
  $scope, ClientServiceResource, confirmService, currentUserService, resourceChargeType
) {

  const vm = this;

  vm.resourceChargeType = resourceChargeType;
  vm.serviceResource = new ClientServiceResource(); // todo rename to newResource
  vm.isShownAddResourceForm = false;
  vm.isShownFormError = false;

  let fakeId = 0;

  vm.$onInit = function() {
    initTableHeaders();
    initEditedResource();
    if (vm.isEditable === undefined) { vm.isEditable = true; }
  };

  vm.isEditedResource = index => index === vm.editedResourceId;

  vm.setEditResourceMode = function(index) {
    vm.editedResourceId = index;
  };

  vm.turnOffEditResourceMode = () => {
    initEditedResource();
    $scope.$broadcast('reset-resource');
  };

  vm.saveEditedResource = function(resource, index, resourceForm) {
    if (resourceForm.$invalid) {
      vm.isShownFormError = true;
      resourceForm.$setSubmitted();
    } else {
      vm.isShownFormError = false;
      vm.service.resources[index] = resource;
      initEditedResource();
      updateResourceSelect();
      $scope.$broadcast('update-resource');
    }
  };

  vm.setAddResourceMode = function() {
    if (vm.isShownAddResourceForm) { return; }
    vm.isShownAddResourceForm = true;
  };

  vm.turnOffAddResourceMode = function() {
    vm.isShownAddResourceForm = false;
    initEditedResource();
  };

  vm.addServiceResource = function(resourceForm) {
    if (resourceForm.$invalid) {
      vm.isShownFormError = true;
      resourceForm.$setSubmitted();
    } else {
      vm.isShownFormError = false;
      vm.serviceResource.fakeId = --fakeId;
      vm.serviceResource.trainings = [];
      vm.service.resources.push(vm.serviceResource);
      initEditedResource();
      vm.turnOffAddResourceMode();
      updateResourceSelect();
      $scope.$broadcast('update-resource');
    }
  };

  vm.removeResource = resource => {
    confirmService.confirmRemove('resource', function () {
      removeResource(resource);
      updateResourceSelect();
    });
  };

  vm.isShownPayRateEditable = (resource) => {
    resource = resource || vm.serviceResource;
    return !currentUserService.isClientManagerLogged() && resourceChargeType.isRequiredPayRate(resource.chargeType);
  };
  
  vm.toggleFormError = function(isShown) {
    vm.isShownFormError = isShown;
  };

  const initEditedResource = function() {
    vm.serviceResource = new ClientServiceResource({
      chargeRate: null,
      contractorPayRate: null,
      employedPayRate: null
    });
    vm.editedResourceId = null;
    vm.isShownFormError = false;
  };

  const removeResource = resource => {
    vm.service.resources = _.without(vm.service.resources, resource);
  };

  const updateResourceSelect = function() {
    if (vm.updateResourceSelect) {
      vm.updateResourceSelect();
    }
  };

  const initTableHeaders = function() {
    vm.tableHeaders = [{
      title: 'Resource',
      className: 'name'
    }, {
      title: 'Roles',
      className: 'roles'
    }, {
      title: 'Charge type',
      className: 'type'
    }, {
      title: 'Min/Max hours',
      className: 'hours'
    }, {
      title: 'Charge rate',
      className: 'rate'
    }];
    if (!currentUserService.isClientManagerLogged()) {
      vm.tableHeaders = vm.tableHeaders.concat([{
        title: 'Contractor Payrate',
        className: 'contractor-pay-rate'
      }, {
        title: 'Employed Payrate',
        className: 'employee-pay-rate'
      }, {
        title: '',
        className: 'actions'
      }]);
    }
  };

  return vm;

};

angular.module('public.security-manager.client.resource')
  .controller('ClientResourceTableController', dependencies.concat(ClientResourceTableController));
