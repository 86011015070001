/*
 * @author Oleksandr Papka <papkaos>
 */
const gccsProfileDetailsChangePassword = {
  controller: 'ProfileDetailsChangePasswordController',
  controllerAs: 'profileDetailsChangePasswordCtrl',
  template: require('./template.pug'),
  bindings: {
    user: '=',
  }
};

angular.module('public.security-manager.profile')
  .component('gccsProfileDetailsChangePassword', gccsProfileDetailsChangePassword);
