/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'currentUserService', 'systemPackagePopupService', 'packageTypeService'
];

const PopupSystemPackageLicensesExceedController = function(
  $scope, currentUserService, systemPackagePopupService, packageTypeService
) {

  const vm = this;

  vm.getPopupMessageText = function() {
    let requiredActionMessage = '';
    if (!currentUserService.getCurrentProfile().isAccountOwner()) {
      requiredActionMessage = 'contact your account owner to ';
    }
    return `To add a new active employee please ${requiredActionMessage}increase the number of licenses`;
  };

  vm.getPopupButtonText = function() {
    if (currentUserService.getCurrentProfile().isAccountOwner()) {
      return 'Upgrade';
    } else {
      return 'Got it';
    }
  };

  vm.doPackageRestrictionPopupAction = function() {
    if (currentUserService.getCurrentProfile().isAccountOwner()) {
      let popupCb;
      if (packageTypeService.hasFreePackage()) {
        popupCb = systemPackagePopupService.showFormPopup;
      } else {
        popupCb = systemPackagePopupService.showLicensesNumberPopup;
      }
      closePopup(popupCb);
    } else {
      closePopup();
    }
  };

  const closePopup = function(onClose) {
    $scope.popupCtrl.closePopup(function() {
      $scope.popupCloseCb();
      if (onClose) {
        onClose();
      }
    });
  };
  
  return vm;

};

angular.module('popup.system-package')
  .controller('PopupSystemPackageLicensesExceedController',
    dependencies.concat(PopupSystemPackageLicensesExceedController));
