/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.hr.email', []).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.hr.sent-emails', {
      url: '/sent',
      template: require('./sent/template.pug'),
      controller: 'SecurityManagerHrEmailSentController',
      controllerAs: 'employeeEmailsSentCtrl'
    })
    .state('public.security-manager.hr.draft-emails', {
      url: '/drafts',
      template: require('./drafts/template.pug'),
      controller: 'SecurityManagerHrEmailDraftsController',
      controllerAs: 'employeeEmailsDraftsCtrl'
    })

]);

