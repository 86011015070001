/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').service('bookingPeriod', function() {

  const PERIOD_EVERY_DAY = 1;

  const names = {};
  names[PERIOD_EVERY_DAY] = 'Every day';

  return {
    getNames() {
      return names;
    },

    getName(type) {
      return names[type];
    }
  };
});