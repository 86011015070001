/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').factory('BookingSeries', ['AppModel', (AppModel) => {

  class BookingSeries extends AppModel {
    static initClass() {
      this.configure({url : '/bookings/{{bookingId}}/series/{{id}}', name : 'series'});
    }
  }

  BookingSeries.initClass();
  return BookingSeries;
}

]);
