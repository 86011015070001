angular.module('public.invoice.employee').service('employeeInvoiceElementFactory', [
  '$rootScope', 'dateService', 'invoiceEmployeeElementType', 'resourceChargeType',
  function($rootScope, dateService, invoiceEmployeeElementType, resourceChargeType) {

    const TIME_FIELD_CLOCK_START = 'clockIn';
    const TIME_FIELD_CLOCK_END = 'clockOut';
    const TIME_FIELD_START = 'start';
    const TIME_FIELD_END = 'end';

    const SHIFT_EMPLOYEE = invoiceEmployeeElementType.getShiftEmployee();
    const BONUS = invoiceEmployeeElementType.getBonus();
    const DEDUCTION = invoiceEmployeeElementType.getDeduction();
    const HOLIDAY_PAY = invoiceEmployeeElementType.getHolidayPay();
    const OVERTIME = invoiceEmployeeElementType.getOvertime();
    const MINUTES_IN_HOUR = 60;
    const ISO_FULL_DATE_FORMAT = dateService.getIsoFullDateFormat();
    const TIME_FORMAT = dateService.getTimeFormat();
    const DATE_FORMAT = dateService.getIsoDateFormat();
    const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

    let fakeId = 0;

    class InvoiceElement {

      constructor(invoiceElement, editable, totalPayChangeCb) {
        this.element = invoiceElement;
        this.editable = editable;
        this.isRemoved = false;
        this.totalPayChangeCb = totalPayChangeCb;
        this.initDateValues();
      }

      initDateValues() {
        if (!this.element.startTimeMoment) {
          this.element.startTimeMoment = moment(this.element.shiftEmployee.startTime, ISO_FULL_DATE_FORMAT);
        }
        if (!this.element.endTimeMoment) {
          this.element.endTimeMoment = moment(this.element.shiftEmployee.endTime, ISO_FULL_DATE_FORMAT);
        }
        this.initDatePickerValues();
        if (this.editable) {
          return $rootScope.$watch(() => {
            return this.element.startDate;
          }, () => {
            this.updateTime();
          });
        }
      }

      initDatePickerValues() {
        this.element.startDate = this.element.startTimeMoment.clone();
        this.element.startTime = this.element.startTimeMoment.format(TIME_FORMAT);
        this.element.endTime = this.element.endTimeMoment.format(TIME_FORMAT);
      }

      updateTime() {
        let startDate;
        if (!this.editable) { return; }
        if (moment.isMoment(this.element.startDate)) {
          startDate = this.element.startDate.format(DATE_FORMAT);
        } else {
          ({ startDate } = this.element);
        }
        this.element.startTimeMoment = moment(`${startDate} ${this.element.startTime}`, DATE_TIME_FORMAT);
        this.element.endTimeMoment = moment(`${startDate} ${this.element.endTime}`, DATE_TIME_FORMAT);
        if (this.element.endTimeMoment.isSameOrBefore(this.element.startTimeMoment)) {
          this.element.endTimeMoment.add(1, 'day');
        }
        this.updateTotalPay();
      }

      calculateTotalPay() {
        if (resourceChargeType.isPerHour(this.element.resource.chargeType)) {
          return this._getTotalMinutesDiff() * (this.element.shiftEmployee.payRate / MINUTES_IN_HOUR);
        } else {
          return this.element.shiftEmployee.payRate;
        }
      }

      updateTotalPay() {
        const newTotalPay = this.calculateTotalPay();
        if (newTotalPay !== this.element.totalPay) {
          this.element.totalPay = newTotalPay;
          this.totalPayChangeCb();
        }
      }

      _getTotalMinutesDiff() {
        return this.element.endTimeMoment.diff(this.element.startTimeMoment, 'minutes') -
          (this.element.unpaidBreakInMinutes || 0);
      }

      getHoursDiff() {
        const totalMinutesDiff = this._getTotalMinutesDiff();
        const minutesDiff = totalMinutesDiff % MINUTES_IN_HOUR;
        const diff = [Math.floor(totalMinutesDiff / MINUTES_IN_HOUR)];
        if (minutesDiff) { diff.push((`0${minutesDiff}`).slice(-2)); }
        return diff.join(':');
      }

      getStartDate() {
        if (moment.isMoment(this.element.startDate)) {
          return this.element.startDate.format(DATE_FORMAT);
        } else {
          return this.element.startDate;
        }
      }

      getCompareValue() {
        return false;
      }

      isSame(another) {
        return another.constructor.name === this.constructor.name && another.getCompareValue() === this.getCompareValue();
      }

      setAsRemoved() {
        return this.isRemoved = true;
      }

      isShownComments() {
        return false;
      }

      getIdForForm() {
        if (!this.element.formId) {
          this.element.formId = this.element.id ? `id${this.element.id}` : `fake${++fakeId}`;
        }
        return this.element.formId;
      }

      isEnabledClockIn() {
        return this.element.enableClockIn;
      }

      getShortTypeText() {
        return '';
      }

      getFullTypeText() {
        return '';
      }

      isOvertime() {
        return false;
      }

    }

    class InvoiceElementShiftEmployee extends InvoiceElement {

      constructor(invoiceElement, editable, totalPayChangeCb) {
        super(...arguments);
        this._initTimeMoments(invoiceElement.shiftEmployee);
      }

      getCompareValue() {
        return this.element.shiftEmployee.id;
      }

      getPrefix() {
        return '';
      }

      isShownComments() {
        return true;
      }

      getShortTypeText() {
        return 'S';
      }

      getFullTypeText() {
        return 'Shift';
      }

      _initTimeMoments(shiftEmployee) {
        this._initEmployeeClockTime(shiftEmployee, TIME_FIELD_START);
        this._initEmployeeClockTime(shiftEmployee, TIME_FIELD_END);
        this._initEmployeeClockTime(shiftEmployee, TIME_FIELD_CLOCK_START);
        this._initEmployeeClockTime(shiftEmployee, TIME_FIELD_CLOCK_END);
      }

      _initEmployeeClockTime(shiftEmployee, field) {
        const fieldTime = shiftEmployee[`${field}Time`];
        if (fieldTime) {
          const time = moment(fieldTime, ISO_FULL_DATE_FORMAT);
          shiftEmployee[`${field}TimeMoment`] = time;
          shiftEmployee[`${field}DayTime`] = time.format(TIME_FORMAT);
        } else {
          shiftEmployee[`${field}TimeMoment`] = null;
          shiftEmployee[`${field}DayTime`] = null;
        }
      }
    }

    class InvoiceElementAdditionalElement extends InvoiceElement {

      getCompareValue() {
        return this.element.uniqueNumber;
      }

    }

    class InvoiceElementBonus extends InvoiceElementAdditionalElement {

      getShortTypeText() {
        return 'B';
      }

      getFullTypeText() {
        return 'Bonus';
      }

    }

    class InvoiceElementHolidayPay extends InvoiceElementBonus {

      getShortTypeText() {
        return 'HP';
      }

      getFullTypeText() {
        return 'Holiday pay';
      }

    }

    class InvoiceElementDeduction extends InvoiceElementAdditionalElement {

      getPrefix() {
        return '-';
      }

      getShortTypeText() {
        return 'D';
      }

      getFullTypeText() {
        return 'Deduction';
      }

      calculateTotalPay() {
        return -super.calculateTotalPay();
      }
    }

    class InvoiceElementOvertime extends InvoiceElement {

      constructor(invoiceElement, editable, totalPayChangeCb) {
        super(...arguments);
        this.updateTotalPay();
        this.element.endDate = this.element.startDate.clone().add(6, 'days');
      }

      getCompareValue() {
        return 'overtime_for_' + this.element.startTime;
      }

      calculateTotalPay() {
        return this.element.shiftEmployee.payRate * this.element.shiftEmployee.overtimeHours;
      }

      getShortTypeText() {
        return 'OT';
      }

      getFullTypeText() {
        return 'Overtime';
      }

      isOvertime() {
        return true;
      }

      getStartDate() {
        return this.element.startDate.format(dateService.getDateFormat());
      }

      getEndDate() {
        return this.element.endDate.format(dateService.getDateFormat());
      }

    }

    const createEmptyAdditionalElement = (startTimeMoment, type) => {
      return {
        startTimeMoment,
        endTimeMoment: startTimeMoment.add(1, 'hour'),
        shiftEmployee: {
          payRate: 0
        },
        resource: {
          chargeType: resourceChargeType.getPerHourType()
        },
        type
      };
    };

    return {
      create(shiftEmployee, editable, totalPayChangeCb) {
        switch (shiftEmployee.type) {
          case BONUS:
            return new InvoiceElementBonus(shiftEmployee, editable, totalPayChangeCb);
          case DEDUCTION:
            return new InvoiceElementDeduction(shiftEmployee, editable, totalPayChangeCb);
          case SHIFT_EMPLOYEE:
            return new InvoiceElementShiftEmployee(shiftEmployee, editable, totalPayChangeCb);
          case HOLIDAY_PAY:
            return new InvoiceElementHolidayPay(shiftEmployee, editable, totalPayChangeCb);
          case OVERTIME:
            return new InvoiceElementOvertime(shiftEmployee, editable, totalPayChangeCb);
        }
      },

      createEmptyBonus(startTimeMoment, editable, totalPayChangeCb) {
        const element = createEmptyAdditionalElement(startTimeMoment, BONUS);
        return new InvoiceElementBonus(element, editable, totalPayChangeCb);
      },

      createEmptyDeduction(startTimeMoment, editable, totalPayChangeCb) {
        const element = createEmptyAdditionalElement(startTimeMoment, DEDUCTION);
        return new InvoiceElementDeduction(element, editable, totalPayChangeCb);
      }
    };
  }

]);
