angular.module('public.security-manager.employee').component('gccsEmployeeClientApproveServices', {
  template: require('./template.pug'),
  controller: 'EmployeeClientApproveServiceController',
  bindings: {
    employee: '<',
    selectedClientId: '<',
    onResourceApprove: '&',
    onResourceReject: '&'
  }
});
