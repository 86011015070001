const gccsInvoicesEmployeesVersion = {
  controller: 'InvoiceEmployeeInvoiceVersionController', // uses controller from employee invoices, mb move out
  template: require('./template.pug'),
  bindings: {
    invoice: '<',
    invoiceVersion: '<',
    isMain: '<',
    toggleable: '<',
    onStatusChange: '&',
    showClient: '<',
    editable: '<',
    employee: '<',
    onInvoiceRemove: '&',
    onBookingCheck: '&',
    allList: '<',
    onPrintStart: '&',
    onPrintEnd: '&',
    reloadListCb: '&'
  }
};

angular
  .module('public.security-manager.invoices')
  .component('gccsInvoicesEmployeesVersion', gccsInvoicesEmployeesVersion);
