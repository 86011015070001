const dependencies = [
  '$scope', 'RepeatableBooking', 'Client', 'ClientService', 'ClientLocation', 'HomeZone', 'dateService',
  'activeStatus', 'selectService', 'packageTypeService', 'dateWeek',
  'repeatableBookingInvoicePeriodType', 'currentUserService'
];

const ScheduleManagerRepeatableBookingFormController = function(
  $scope, RepeatableBooking, Client, ClientService, ClientLocation, HomeZone, dateService,
  activeStatus, selectService, packageTypeService, dateWeek,
  repeatableBookingInvoicePeriodType, currentUserService
) {

  const vm = this;

  vm.isShownLocationsExceedPopup = false;
  vm.isShownServicePopup = false;
  vm.isShownHomeZones = packageTypeService.hasProPackage();
  vm.dateWeek = dateWeek;
  vm.repeatableBookingInvoicePeriodType = repeatableBookingInvoicePeriodType;

  let isFormSubmitted = false;

  vm.$onInit = function() {
    $scope.repeatableBookingLayoutCtrl.holderWidth = 825;
    initRepeatableBooking();
    initTitle();
  };

  vm.loadClients = text => {
    return Client.autocomplete({}, { name: text, limit: 5, status: activeStatus.getActiveStatus() });
  };

  vm.loadHomeZones = text => {
    return HomeZone.autocomplete({}, { 'order[name]': 'asc', searchString: text });
  };

  vm.onClientChange = function(selectedObject) {
    if (selectedObject) {
      return initClientData(selectedObject.originalObject.id, selectedObject.originalObject);
    } else {
      return resetClientData();
    }
  };

  vm.addService = function() {
    vm.popupService = new ClientService();
    vm.popupService.clientId = vm.repeatableBooking.clientId;
    vm.isShownServicePopup = true;
  };

  vm.addLocation = function() {
    ClientLocation.getCount({
      clientId: vm.repeatableBooking.clientId
    }, {
      activeStatus: activeStatus.getActiveStatus()
    }).then((data) => {
      if (packageTypeService.hasFreePackage()) {
        if (data.count >= packageTypeService.getLocationsLimitForFreePackage()) {
          openLocationsExceedPopup();
        } else {
          openLocationPopup();
        }
      } else {
        openLocationPopup();
      }
    });
  };

  vm.closeLocationsExceedPopup = function () {
    vm.isShownLocationsExceedPopup = false;
  };

  vm.onLocationChange = () => {
    if (vm.isShownHomeZones) {
      HomeZone.forLocation(vm.repeatableBooking.locationId).then(locationHomeZones => {
        vm.repeatableBooking.homeZones = locationHomeZones;
      });
    }
  };

  vm.onSubmit = function() {
    if (!$scope.RepeatableBookingForm.$valid || isFormSubmitted) { return; }
    isFormSubmitted = true;
    vm.repeatableBooking.invoicePeriodStart = dateWeek.toValue(vm.chosenWeeks);
    vm.repeatableBooking.save().then(function(repeatableBooking) {
      $scope.repeatableBookingLayoutCtrl.updateRepeatableBooking(repeatableBooking);
      $scope.repeatableBookingLayoutCtrl.goNext();
    });
  };

  vm.closeServicePopup = function(service) {
    vm.isShownServicePopup = false;
    if (service) {
      vm.clientServices = _.merge(vm.clientServices, selectService.toSelectData([service]));
      vm.repeatableBooking.serviceId = service.id;
    }
  };

  vm.closeLocationPopup = function(location) {
    vm.isShownLocationPopup = false;
    if (location) {
      vm.clientLocations = _.merge(vm.clientLocations, selectService.toSelectData([location]));
      vm.repeatableBooking.locationId = location.id;
      vm.onLocationChange();
    }
  };

  vm.getDateRange = function() {
    return `${dateService.formatDate(vm.repeatableBooking.startDate)} - ${dateService.formatDate(vm.repeatableBooking.endDate)}`;
  };

  vm.getInvoicePeriod = function() {
    if (repeatableBookingInvoicePeriodType.isWeeklyType(vm.repeatableBooking.invoicePeriodType)) {
      return `Weekly, starts every ${dateWeek.getName(vm.repeatableBooking.invoicePeriodStart)}`;
    } else {
      return 'Monthly';
    }
  };

  vm.isShownClientSelect = function() {
    return !currentUserService.isClientManagerLogged() && !$scope.repeatableBookingLayoutCtrl.isEdit;
  };

  vm.isShownClockIn = function() {
    return vm.repeatableBooking.clientId && (vm.repeatableBooking.client.enableDesktopClockIn || vm.repeatableBooking.client.enableMobileClockIn);
  };

  const initRepeatableBooking = function() {
    $scope.repeatableBookingLayoutCtrl.loadRepeatableBooking().then(repeatableBooking => {
      vm.repeatableBooking = repeatableBooking;
      vm.chosenWeeks = dateWeek.toArray(repeatableBooking.invoicePeriodStart);
      if ($scope.repeatableBookingLayoutCtrl.isEdit) {
        initClientData(repeatableBooking.clientId);
      } else if (currentUserService.isClientManagerLogged()) {
        initClientData(currentUserService.getCurrentProfile().clientId);
      }
    });
  };

  const openLocationsExceedPopup = function() {
    vm.isShownLocationsExceedPopup = true;
  };

  const openLocationPopup = function() {
    vm.popupLocation = new ClientLocation();
    vm.popupLocation.clientId = vm.repeatableBooking.clientId;
    vm.isShownLocationPopup = true;
  };

  const initClockInForClient = function(client) {
    if (client && client !== vm.repeatableBooking.client) {
      vm.repeatableBooking.enableClockIn = false;
      vm.repeatableBooking.client = client;
    }
  };

  const initClientData = function(clientId, client) {
    vm.repeatableBooking.clientId = clientId;
    initClockInForClient(client);
    const serviceQueryParams = {};
    if (!$scope.repeatableBookingLayoutCtrl.isEdit) {
      serviceQueryParams.activeStatus = activeStatus.getActiveStatus();
    }
    if (currentUserService.isClientManagerLogged()) {
      serviceQueryParams.clientManagerId = currentUserService.getCurrentProfile().id;
    }
    ClientService.query(serviceQueryParams, { clientId }).then(services => {
      vm.clientServices = selectService.toSelectData(services);
    });
    ClientLocation.query({ activeStatus: activeStatus.getActiveStatus() }, { clientId }).then(locations => {
      if ($scope.repeatableBookingLayoutCtrl.isEdit) {
        const foundLocation = _.find(locations, ['id', vm.repeatableBooking.location.id]);
        if (!foundLocation) {
          locations.push(vm.repeatableBooking.location);
        }
      }
      vm.clientLocations = selectService.toSelectArray(locations);
    });
  };

  const initTitle = function() {
    if ($scope.repeatableBookingLayoutCtrl.isEdit) {
      return vm.title = 'Edit repeatable booking';
    } else {
      return vm.title = 'New repeatable booking';
    }
  };

  const resetClientData = function() {
    vm.repeatableBooking.clientId = null;
    vm.clientServices = {};
    vm.clientLocations = {};
  };

  return vm;

};

angular.module('public.security-manager.schedule-manager.repeatable-booking')
  .controller('ScheduleManagerRepeatableBookingFormController',
    dependencies.concat(ScheduleManagerRepeatableBookingFormController)
  );
