/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$rootScope', '$state', 'Session', 'storageService', 'defaultConfig', 'timelinePager',
  'profileType', 'verificationType'
];

const sessionService = (
  $rootScope, $state, Session, storageService, defaultConfig, timelinePager,
  profileType, verificationType
) => {

  const createSession = function (sessionCopy, onSuccess, onFail) {
    sessionCopy.create().then(function(session) {
      storageService.set(defaultConfig.sessionKey, session.encryptedId);
      onSuccess();
    }, onFail);
  };

  const redirectToConfirm = function (user, userProfile) {
    $state.go('sign.login-confirm', { profile: userProfile, user: user });
  };

  const redirectToPhoneNumberSetup = function (user, userProfile) {
    $state.go('sign.phone-number-setup', { profile: userProfile, user: user });
  };

  const redirectToSecondFactorAuth = function (user, userProfile) {
    $state.go('sign.second-factor-auth', { profile: userProfile, user: user, sessionId: userProfile.encryptedId });
  };

  const handleTwoFactorAuth = function (user, userProfile, sessionCopy, onSuccess, onFail) {
    if (profileType.isSecurityManager(userProfile)) {
      if (userProfile.secretPhoneNumber) {
        redirectToConfirm(user, userProfile);
      } else {
        redirectToPhoneNumberSetup(user, userProfile);
      }
    } else {
      createSession(sessionCopy, onSuccess, onFail);
    }
  };

  const defineAuthType = function (user, userProfile, sessionCopy, onSuccess, onFail) {
    if(userProfile.secondFactorRequired) {
      redirectToSecondFactorAuth(user, userProfile);
    } else if (!userProfile.twillioEnabled) {
      createSession(sessionCopy, onSuccess, onFail);
    } else if (userProfile.needLoginConfirmation) {
      handleTwoFactorAuth(user, userProfile, sessionCopy, onSuccess, onFail);
    } else {
      switch (userProfile.verificationType) {
        case verificationType.getVerificationTypeSwitchedOff():
          createSession(sessionCopy, onSuccess, onFail);
          break;
        case verificationType.getVerificationTypeAlwaysOn():
          handleTwoFactorAuth(user, userProfile, sessionCopy, onSuccess, onFail);
          break;
        case verificationType.getVerificationTypeOnlyNewDevices():
          if (userProfile.deviceUsedForLogin) {
            createSession(sessionCopy, onSuccess, onFail);
          } else {
            handleTwoFactorAuth(user, userProfile, sessionCopy, onSuccess, onFail);
          }
          break;
      }
    }
  };

  return {
    login(user, onSuccess, onFail) {
      const session = new Session(user);
      const sessionCopy = angular.copy(session);
      session.check().then((userProfile) => {
        defineAuthType(user, userProfile, sessionCopy, onSuccess, onFail);
      }).catch(onFail);
    },

    simpleLogin(user, onSuccess, onFail) {
      const session = new Session(user);
      createSession(session, onSuccess, onFail);
    },

    logout() {
      return new Session().remove().finally(function() {
        timelinePager.clearPagerParams();
        return $rootScope.$broadcast('logout');
      });
    },

    getSessionId() {
      return storageService.get(defaultConfig.sessionKey);
    },

    createSession

  }
};

angular.module('shared').service('sessionService', dependencies.concat(sessionService));
