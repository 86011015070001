/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.security-manager').directive('gccsPopupSecurityManagerForm', [
  'popupDirectiveConfig',
  popupDirectiveConfig =>
    popupDirectiveConfig.configure({
      bodyTemplate: require('./template.pug'),
      scope: {
        securityManager: '='
      }
    })
]);
