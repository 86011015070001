angular.module('data.booking').service('bookingShiftValidationService', ['dateService', function(dateService) {

  const getTime = function(stamp, dayTime) {
    const date = moment(stamp, [dateService.getDateFormat(), 'x']).format(dateService.getDateFormat());
    return moment(`${date} ${dayTime}`, dateService.getFullDateFormat(), true);
  };

  const getPeriodInHours = function(startDateStamp, startDayTime, endDateStamp, endDayTime) {
    const startTime = getTime(startDateStamp, startDayTime);
    const endTime = getTime(endDateStamp, endDayTime);
    return endTime.diff(startTime, 'hours', true);
  };

  const getPeriodError = dataCb => {
    return {
      error: 'period',
      message: 'Period is out of range',
      compositeField: ['startDate', 'startDayTime', 'endDate', 'endDayTime'],
      condition(startDateStamp, startDayTime, endDateStamp, endDayTime) {
        const period = dataCb();
        if (period.maxHours) {
          const periodInHours = getPeriodInHours(startDateStamp, startDayTime, endDateStamp, endDayTime);
          return !(period.minHours <= periodInHours && periodInHours <= period.maxHours);
        } else if (period.minHours) {
          const periodInHours = getPeriodInHours(startDateStamp, startDayTime, endDateStamp, endDayTime);
          return !(period.minHours <= periodInHours);
        } else {
          return false;
        }
      }
    };
  };

  const getDatelessPeriodError = dataCb => {
    return {
      error: 'period',
      message: 'Period is out of range',
      compositeField: ['startTime', 'endTime'],
      condition(startTime, endTime) {
        let startDate = moment().startOf('year');
        let endDate = moment().startOf('year');
        let startDayAndTime = getDayAndTime(startDate, startTime);
        let endDayAndTime = getDayAndTime(endDate, endTime);
        if (endDayAndTime.isBefore(startDayAndTime)) {
          endDayAndTime = endDayAndTime.add(1, 'day');
        }
        const period = dataCb();
        if (period.maxHours) {
          const periodInHours = endDayAndTime.diff(startDayAndTime, 'hours', true);
          return !(period.minHours <= periodInHours && periodInHours <= period.maxHours);
        } else if (period.minHours) {
          const periodInHours = endDayAndTime.diff(startDayAndTime, 'hours', true);
          return !(period.minHours <= periodInHours);
        } else {
          return false;
        }
      }
    };
  };

  const getDayAndTime = function(date, time) {
    return moment(`${date.format(dateService.getDateFormat())} ${time}`, dateService.getFullDateFormat());
  };

  const getTimeError = type => {
    return {
      error: 'time',
      message: 'Wrong time format',
      compositeField: [`${type}Date`, `${type}DayTime`],
      condition(date, dayTime) {
        return !getTime(date, dayTime).isValid();
      }
    };
  };

  const getDatelessTimeError = type => {
    return {
      error: 'time',
      message: 'Wrong time format',
      condition(dayTime) {
        const date = moment().startOf('year');
        return !getTime(date, dayTime).isValid();
      }
    };
  };

  const getStartEndTimeError = () => {
    return {
      error: 'startend',
      message: 'Start day must be before end day',
      compositeField: ['startDate', 'startDayTime', 'endDate', 'endDayTime'],
      condition(startDateStamp, startDayTime, endDateStamp, endDayTime) {
        const startTime = getTime(startDateStamp, startDayTime);
        const endTime = getTime(endDateStamp, endDayTime);
        return startTime.isAfter(endTime);
      }
    };
  };

  const getCheckInTimeError = function() {
    return {
      error: 'check-in',
      message: 'First check in must be before last check in',
      compositeField: ['firstCheckInDate', 'firstCheckInDayTime', 'lastCheckInDate', 'lastCheckInDayTime'],
      condition(firstCheckInDateStamp, firstCheckInDayTime, lastCheckInDateStamp, lastCheckInDayTime) {
        const firstCheckInTime = getTime(firstCheckInDateStamp, firstCheckInDayTime);
        const lastCheckInTime = getTime(lastCheckInDateStamp, lastCheckInDayTime);
        return firstCheckInTime.isAfter(lastCheckInTime);
      }
    };
  };

  const getFirstCheckInError = function() {
    return {
      error: 'first-check-in',
      message: 'First check in must be after start of shift',
      compositeField: ['startDate', 'startDayTime', 'firstCheckInDate', 'firstCheckInDayTime'],
      condition(startDate, startDayTime, firstCheckInDateStamp, firstCheckInDayTime) {
        const startTime = getTime(startDate, startDayTime);
        const firstCheckInTime = getTime(firstCheckInDateStamp, firstCheckInDayTime);
        return startTime.isAfter(firstCheckInTime);
      }
    };
  };

  const getLastCheckInError = function() {
    return {
      error: 'last-check-in',
      message: 'Last check in must be before end of shift',
      compositeField: ['endDate', 'endDayTime', 'lastCheckInDate', 'lastCheckInDayTime'],
      condition( endDateStamp, endDayTime, lastCheckInDateStamp, lastCheckInDayTime) {
        const endTime = getTime(endDateStamp, endDayTime);
        const lastCheckInTime = getTime(lastCheckInDateStamp, lastCheckInDayTime);
        return lastCheckInTime.isAfter(endTime);
      }
    };
  };

  const getClockInOutTimeError = () => {
    return {
      error: 'clock-in-out',
      message: 'Clock in must be before clock out',
      compositeField: ['clockInDate', 'clockInDayTime', 'clockOutDate', 'clockOutDayTime'],
      condition(clockInDateStamp, clockInDayTime, clockOutDateStamp, clockOutDayTime) {
        const clockInTime = getTime(clockInDateStamp, clockInDayTime);
        const clockOutTime = getTime(clockOutDateStamp, clockOutDayTime);
        return clockInTime.isAfter(clockOutTime);
      }
    };
  };

  const getClockInTimeError = function(clockOutTime) {
    return {
      error: 'clock-in',
      message: 'Clock in must be before clock out',
      compositeField: ['clockInDate', 'clockInDayTime'],
      condition(clockInDateStamp, clockInDayTime) {
        const clockInTime = getTime(clockInDateStamp, clockInDayTime);
        const clockOutTimeMoment = moment(clockOutTime, dateService.getIsoFullDateFormat());
        return clockInTime.isAfter(clockOutTimeMoment);
      }
    };
  };

  const getClockOutTimeError = function(clockInTime) {
    return {
      error: 'clock-out',
      message: 'Clock in must be before clock out',
      compositeField: ['clockOutDate', 'clockOutDayTime'],
      condition(clockOutDateStamp, clockOutDayTime) {
        const clockOutTime = getTime(clockOutDateStamp, clockOutDayTime);
        const clockInTimeMoment = moment(clockInTime, dateService.getIsoFullDateFormat());
        return clockInTimeMoment.isAfter(clockOutTime);
      }
    };
  };

  return {
    getDateErrors(dataCb) {
      return [getStartEndTimeError(), getPeriodError(dataCb)];
    },

    getStartTimeErrors(dataCb) {
      return [getStartEndTimeError(), getPeriodError(dataCb), getTimeError('start')];
    },

    getEndTimeErrors(dataCb) {
      return [getStartEndTimeError(), getPeriodError(dataCb), getTimeError('end')];
    },

    getStartTimeDatelessErrors(dataCb) {
      return [getDatelessPeriodError(dataCb), getDatelessTimeError('start')];
    },

    getEndTimeDatelessErrors(dataCb) {
      return [getDatelessPeriodError(dataCb), getDatelessTimeError('end')];
    },

    getStartEndTimeErrors(type) {
      return [getStartEndTimeError(), getTimeError(type)];
    },

    getClockInOutTimeErrors(type) {
      return [getClockInOutTimeError(), getTimeError(type)];
    },

    getClockInTimeErrors(clockOutTime) {
      return [getClockInTimeError(clockOutTime), getTimeError('clockIn')];
    },

    getClockOutTimeErrors(clockInTime) {
      return [getClockOutTimeError(clockInTime), getTimeError('clockOut')];
    },

    getCheckInTimeErrors() {
      return [getCheckInTimeError(), getFirstCheckInError(), getLastCheckInError()];
    },

    getFirstCheckInErrors() {
      return [getCheckInTimeError(), getFirstCheckInError(), getLastCheckInError(), getTimeError('firstCheckIn')];
    },

    getLastCheckInErrors() {
      return [getCheckInTimeError(), getFirstCheckInError(), getLastCheckInError(), getTimeError('lastCheckIn')];
    },

    getFirstCheckInDatelessError() {
      return [getDatelessTimeError('firstCheckIn')];
    },

    getLastCheckInDatelessError() {
      return [getDatelessTimeError('lastCheckIn')];
    },

    getRateErrors() {
      return [{
        error : 'rate',
        message : 'Rate cannot be 0',
        condition(rate) {
          return +rate === 0;
        }
      }];
    },

    getResourcesAmountErrors() {
      return [{
        error : 'min',
        message : 'Resources amount cannot be 0',
        condition(resourcesAmount) {
          return (resourcesAmount - 0) === 0;
        }
      }];
    }
  };
}

]);
