/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const HomeZone = AppModel => {

  class HomeZone extends AppModel {
    static initClass() {
      this.configure({url : '/home_zones', name : 'home_zone'});
    }

    static forLocation(locationId) {
      return this.$get(this.resourceUrl('for_location', locationId));
    }
  }

  HomeZone.initClass();
  return HomeZone;
};

angular.module('data.home-zone').factory('HomeZone', dependencies.concat(HomeZone));
