const dependencies = ['$scope', 'clientStatus'];

const EmployeeProfileApproveClientsController = function($scope, clientStatus) {

  const vm = this;

  vm.clientSearchString = '';
  vm.clientStatus = clientStatus;

  vm.$onChanges = function() {
    if (vm.getApprovedClients().length) {
      vm.selectClient(_.first(vm.getApprovedClients()).id);
    }
  };

  vm.getApprovedClients = function() {
    return _.filter(vm.clients, (client) => {
      return client.resourceIds.length && includesSearchName(client);
    });
  };

  vm.selectClient = function(clientId) {
    vm.selectedClientId = clientId;
  };

  vm.isClientSelected = function(clientId) {
    return vm.selectedClientId === clientId;
  };

  const includesSearchName = function(client) {
    return client.name.search(new RegExp(vm.clientSearchString, 'i')) !== -1;
  };

  return vm;

};

angular.module('public.security-manager.employee')
  .controller('EmployeeProfileApproveClientsController', dependencies.concat(EmployeeProfileApproveClientsController));
