/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$location', '$state', '$filter', '$timeout', '$q', 'Client', 'Report',
  'FilterParamsService', 'selectService', 'invoiceStatus', 'dateService', 'confirmService',
  'chartService', 'hoursReportType', 'employeeInvoiceStatus', 'clientStatus', 'billableHoursReportExportType'
];

const ReportsFinanceBillableHoursController = function(
  $scope, $location, $state, $filter, $timeout, $q, Client, Report,
  FilterParamsService, selectService, invoiceStatus, dateService, confirmService,
  chartService, hoursReportType, employeeInvoiceStatus, clientStatus, billableHoursReportExportType
) {

  const vm = this;

  const filterParamsService = new FilterParamsService({
    filter: ['from', 'to', 'client_status', 'employee_status', 'client_id']
  });

  const DATE_FORMAT_BY_DAY = '%Y-%m-%d';
  const DATE_FORMAT_BY_MONTH = '%b %y';

  const DEFAULT_DETAILS_TOP = 80;
  const DEFAULT_DETAILS_LEFT = 70;

  vm.filterParams = filterParamsService.getParams();
  vm.invoiceStatus = invoiceStatus;
  vm.employeeInvoiceStatus = employeeInvoiceStatus;
  vm.billableHoursReportExportType = billableHoursReportExportType;

  vm.isAnalyticsDataLoaded = true;
  vm.isExprortInitiated = false;
  vm.isShownLoadMoreButton = false;
  vm.isOpenedExportDropdown = false;
  vm.paramsInitialized = false;
  vm.isShownChartDetails = false;

  let chartDateFormatter = DATE_FORMAT_BY_DAY;
  let lastSelectedPoints = [];

  vm.$onInit = function() {
    initParams();
    loadClients();
    updateChartDateFormatter();
  };

  vm.updateChartData = function() {
    if (!vm.isAnalyticsDataLoaded) { return; }
    setLoadingState();
    loadChart().finally(() => setLoadingFinishedState());
  };

  vm.onClientSelect = client => vm.filterParams.filter.client_id = client.id;

  vm.getFilteredPeriod = () => `${moment(vm.endDateFrom, dateService.getDateFormat()).format('DD MMM YYYY')} – ${moment(vm.endDateTo, dateService.getDateFormat()).format('DD MMM YYYY')}`;

  vm.getFormattedInvoicedDate = date => `${moment(date, dateService.getIsoDateFormat()).format('DD MMM YYYY')}`;

  vm.toggleExportDropdown = () => vm.isOpenedExportDropdown = !vm.isOpenedExportDropdown;

  vm.closeExportDropdown = () => vm.isOpenedExportDropdown = false;

  vm.getTotalHoursAndMinutes = function(minutes) {
    if (!minutes) { return 0; }
    let label = '';
    const totalMinutes = minutes % 60;
    const totalHours = (minutes - totalMinutes) / 60;
    if (totalHours) {
      label += `${ $filter('number')(totalHours) }h`;
      if (totalMinutes) {
        label += ` ${totalMinutes}m`;
      }
    } else {
      label += `${totalMinutes}m`
    }
    return label;
  };

  vm.updateEmployeeStatuses = function(selected) {
    vm.filterParams.filter.employee_status = selected.join(',');
  };

  vm.toggleChartDetails = function() {
    vm.isShownChartDetails = !vm.isShownChartDetails;
  };

  vm.openChartDetails = function() {
    vm.isShownChartDetails = true;
  };

  vm.closeChartDetails = function() {
    vm.isShownChartDetails = false;
    unselectDataPoint();
  };

  vm.getChartDetailsStyles = function() {
    return { 'top': `${vm.chartDetailsTop}px`, 'left': `${vm.chartDetailsLeft}px` };
  };

  vm.isWideDetailsVersion = function() {
    return !hoursReportType.isZeroChargeRateType(hoursReportType.getType(vm.pointsData[0].id));
  };

  vm.sendReport = function(exportType) {
    let urlParams = getUrlParams();
    urlParams.exportType = exportType;
    Report.exportBillableHoursReport(urlParams);
    confirmService.confirmWithMultipleButtons({
      title: 'Report export',
      question: 'Report will be generated and sent to your email. Please check your email in 3-5 minutes',
      buttons: [{
        label: 'OK',
        type: 'green'
      }]
    })
  };

  const setLoadingState = () => vm.isAnalyticsDataLoaded = false;

  const setLoadingFinishedState = () => {
    $timeout(function() {
      vm.isAnalyticsDataLoaded = true;
    }, 500);
  };

  const updateChartDetailsPosition = function([left = 0, top = 0]) {
    // TODO move to constant and add criteria
    vm.chartDetailsLeft = left + DEFAULT_DETAILS_LEFT;
    if (vm.pointsData.length > 1) {
      vm.chartDetailsTop = 0;
    } else {
      vm.chartDetailsTop = top - DEFAULT_DETAILS_TOP;
    }
  };

  const updateChartDateFormatter = function(byMonth) {
    const format = byMonth ? DATE_FORMAT_BY_MONTH : DATE_FORMAT_BY_DAY;
    return chartDateFormatter = d3.timeFormat(format);
  };

  const getChartConfig = function(data) {
    return {
      bindto: '#chart',
      data: {
        x: 'x',
        columns: data,
        groups: [
          _.values(invoiceStatus.getNames())
        ],
        colors: {
          'Payment': '#373F51',
          'Charges': '#1DAAFC',
          'Zero charge rate': '#FF4743',
        },
        onclick: onDataPointClick,
        custom: {
          onclick: {
            apply: true,
            delta: 0.05,
            exclude: 'Zero charge rate'
          }
        },
      },
      size: {
        height: 395
      },
      grid: {
        x: {
          show: true
        },
        y: {
          show: true
        },
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format(x) {
              return chartDateFormatter(x);
            }
          }
        },
        y: {
          min: 0,
          padding: {
            bottom: 0
          },
          label: {
            text: 'Hours',
            position: 'outer-middle'
          },
          tick: {
            outer: false
          }
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      hint: {
        show: true,
        text: 'Click here to see details'
      },
      point: {
        r: 5,
        focus: {
          expand: {
            r: 6
          }
        },
        stroke: {
          show: true
        },
      },
      oninit() {
        return setTimeout(function() {
          return initLegend(vm.chart.data(), vm.chart);
        }, 0);
      }
    };
  };

  const initChart = data => c3.generate(getChartConfig(data));

  const updateChart = function(data, chart) {
    if (!chart) { return; }
    const itemsToRemove = [];
    _.each(chart.data(), function(chartItem) {
      const foundItem = _.find(data, item => item[0] === chartItem.id);
      if (!foundItem) {
        itemsToRemove.push(chartItem.id);
      }
      return true;
    });
    return chart.load({
      columns: data,
      unload: itemsToRemove
    });
  };

  const initLegend = function(data, chart) {
    return d3.select('.billable-hours-legend-wrapper')
      .html(() => '').insert('div')
      .attr('class', 'billable-hours-legend')
      .selectAll('div')
      .data(getChartKeys(data))
      .enter()
      .append('div')
      .attr('data-id', (id, index) => data[index].id)
      .attr('class', 'billable-hours-legend-item mod-checked')
      .each(function(id, index) {
        return d3.select(this).html(function() {
          return getLegendHTML(index, data, chart, this);
        });
      })
      .on('mouseover', (id, index) => chart.focus(data[index].id))
      .on('mouseout', id => chart.revert())
      .on('click', function (id, index) {
        this.classList.toggle('mod-checked');
        chart.toggle(data[index].id);
      });
  };

  const onDataPointClick = function(data, elements, mouse) {
    lastSelectedPoints = elements;
    vm.pointsData = data;
    vm.pointsData.forEach((pointData, index) => {
      pointData.element = elements[index];
      pointData._index = index;
      setPeriodToPoint(pointData);
      selectDataPoint(pointData.element);
    });
    updateChartDetailsPosition(mouse);
    vm.openChartDetails();
  };

  const selectDataPoint = function(element) {
    const style = getComputedStyle(element);
    element.style.strokeWidth = 0;
    element.style.fill = style.stroke;
  };

  const unselectDataPoint = function() {
    if (lastSelectedPoints.length) {
      lastSelectedPoints.forEach((lastSelectedPoint) => {
        lastSelectedPoint.style.strokeWidth = 2;
        lastSelectedPoint.style.fill = '#FFFFFF';
      });
    }
  };

  const setPeriodToPoint = function(pointData) {
    pointData._from = getD3IsoDateMoment(pointData.x).format(dateService.getIsoDateFormat());
    pointData._to = pointData._from;

    const momentFrom = moment(vm.endDateFrom, dateService.getDateFormat());
    const momentTo = moment(vm.endDateTo, dateService.getDateFormat());
    const monthMeasurment = momentTo.diff(momentFrom, 'months');
    if (monthMeasurment >= 1) {
      if (monthMeasurment >= 3 && pointData.index === 0) {
        pointData._from = momentFrom.format(dateService.getIsoDateFormat());
      }
      const chartDates = vm.chart.internal.getOtherTargetXs();
      if (pointData.index + 1 < chartDates.length) {
        const nextPointDateMoment = getD3IsoDateMoment(chartDates[pointData.index + 1]);
        const nextPeriodPointMoment = nextPointDateMoment.subtract(1, 'days');
        pointData._to = nextPeriodPointMoment.format(dateService.getIsoDateFormat());
      } else {
        pointData._to = momentTo.format(dateService.getIsoDateFormat());
      }
    }
  };

  const getD3IsoDateMoment = function(date) {
    return moment(d3.timeFormat(DATE_FORMAT_BY_DAY)(date), dateService.getIsoDateFormat());
  };

  const getLegendHTML = function(index, data, chart, listItem) {
    const { id } = data[index];
    return `\
<span class="billable-hours-legend-title" title="${id}">
  ${id}
  <i class="billable-hours-legend-icon" style="background-color: ${chart.color(id)}"></i>
</span>
<input type="checkbox" class="billable-hours-legend-checkbox-input">
<i class="billable-hours-legend-checkbox-ico">
  <svg class="svg-ico">
    <use xlink:href="#checked-thin"></use>
</svg>
</i>
\
`;
  };

  const getChartKeys = data => {
    return _.map(data, item => item[0]);
  };

  const loadClients = () => {
    return Client.autocomplete({}, { 'order[name]': 'asc' }).then(function (clients) {
      vm.clients = selectService.toSelectGroupedArray(clients, getClientGroupsConfig());
      vm.selectedClient = getSelectedClient();
    });
  };

  const getClientGroupsConfig = function() {
    return {
      defaultField: 'All',
      groups: [
        { id: -clientStatus.getActiveStatus(), name: clientStatus.getName(clientStatus.getActiveStatus()) },
        { id: -clientStatus.getInactiveStatus(), name: clientStatus.getName(clientStatus.getInactiveStatus()) }
      ],
      entityName: 'client',
      additionalText: '(A - Z)'
    };
  };

  const getSelectedClient = function() {
    return _.chain(vm.clients)
      .map('items')
      .flatten()
      .find((client => client.id === +vm.filterParams.filter.client_id))
      .value();
  };

  const loadChart = function() {
    const urlParams = getUrlParams();
    $location.search(urlParams);
    const billableHoursPromise = Report.getBillableHours(urlParams).then(function(report) {
      const billableHoursData = chartService.getGraphFormatData(report, vm.endDateFrom, vm.endDateTo, hoursReportType.getName, updateChartDateFormatter);
      if (vm.chart) {
        updateChart(billableHoursData, vm.chart);
      } else {
        vm.chart = initChart(billableHoursData);
      }
    });
    const generalInfoPromise = Report.getGeneralInfo(urlParams).then(function(generalInfo) {
      vm.generalInfo = generalInfo;
    });
    return $q.all([billableHoursPromise, generalInfoPromise]);
  };

  const initParams = function() {
    const locationParams = $location.search();
    if (locationParams.from && locationParams.to) {
      vm.endDateFrom = moment(locationParams.from, dateService.getIsoDateFormat());
      vm.endDateTo = moment(locationParams.to, dateService.getIsoDateFormat());
    } else {
      const today = dateService.today();
      vm.endDateFrom = today.clone().subtract(30, 'days');
      vm.endDateTo = today.clone();
    }
    if (locationParams.employee_status) {
      vm.employeeStatuses = locationParams.employee_status.split(',');
    } else {
      vm.employeeStatuses = _.keys(vm.employeeInvoiceStatus.getReportNames());
      vm.filterParams.filter.employee_status = vm.employeeStatuses.join(',');
    }
    vm.paramsInitialized = true;
  };

  const getUrlParams = function() {
    const urlParams = filterParamsService.getFilterUrlParams();
    if (vm.endDateTo && vm.endDateTo) {
      urlParams.from = moment(vm.endDateFrom, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
      urlParams.to = moment(vm.endDateTo, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
    }
    return urlParams;
  };

  $scope.$watch(() => vm.filterParams, _.debounce(vm.updateChartData, 100), true);

  return vm;

};

angular.module('public.security-manager.reports.finance')
  .controller('ReportsFinanceBillableHoursController', dependencies.concat(ReportsFinanceBillableHoursController));
