/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = [
  '$scope', '$location', '$timeout', 'EmployeeCard', 'SessionToken', 'employeeStatus',
  'dateService', 'FilterParamsService', 'EntityManager', 'EmployeeCardVersionRoot', 'EmployeeCardVersion',
  'employeeCardVersionPrintStatus', 'packageTypeService'
];

const SecurityManagerHrCardsController = function(
  $scope, $location, $timeout, EmployeeCard, SessionToken, employeeStatus,
  dateService, FilterParamsService, EntityManager, EmployeeCardVersionRoot, EmployeeCardVersion,
  employeeCardVersionPrintStatus, packageTypeService
) {

  const vm = this;

  vm.employeeStatus = employeeStatus;

  vm.isSelected = false;
  vm.isLoading = false;

  const filterParamsService = new FilterParamsService({
    filter: ['firstLetter', 'name', 'status']
  });

  const statusNames = employeeStatus.getNames();

  const entityManager = new EntityManager(EmployeeCard, { prepare: function(card) {
    return translateCard(card)
  } });

  vm.isDisabledLoading = entityManager.isDisabledLoading;
  vm.filterParams = filterParamsService.getParams();

  vm.$onInit = function() {
    packageTypeService.checkProPackage();
  };

  vm.resetFilter = () => vm.filterParams.filter = {};

  vm.getCardVersions = card => _.sortBy(card.versions, version => -version.id);

  vm.getCards = () => entityManager.entities;

  vm.loadMoreCards = () => entityManager.loadMore();

  vm.filterCards = function() {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    $location.search(urlParams);
    return entityManager.load(urlParams);
  };

  vm.toggleCard = function(card) {
    if (!(card.versions.length > 1)) { return; }
    return card.isOpen = !card.isOpen;
  };

  vm.onStatusChange = function(newVersion) {
    const card = _.find(entityManager.entities, card => card.id === newVersion.employeeCardId);
    if (!card) { return; }
    card.versions.unshift(translateVersion(newVersion));
    return deselectAll();
  };

  vm.printCards = function() {
    const versions = _.flatMap(entityManager.entities, vm.getCardVersions);
    vm.selectedVersions = _.filter(versions, 'isSelected');
    if (!(vm.selectedVersions.length > 0)) { return; }
    try {
      const selectedVersionIds = _.map(vm.selectedVersions, 'id');
      new SessionToken({
        entityId: selectedVersionIds[0],
        entityType: 'employee_cards'
      }).save().then(function(sessionToken) {
        printJS({
          printable: EmployeeCardVersionRoot.getPdfUrl({
            ids: selectedVersionIds
          }, {
            token_id: sessionToken.id,
            token: sessionToken.token
          }, {
            withDomain: false
          }),
          onLoadingStart: vm.showLoader,
          onLoadingEnd: vm.hideLoader
        });
      }).catch(function(error) {
        Rollbar.log(`Can not create session token on ${window.location.href}`, error);
      });
    } catch (error) {
      vm.hideLoader();
    }
    return addPrintedVersions();
  };

  vm.showLoader = () => vm.isLoading = true;

  vm.hideLoader = () => {
    return $timeout(() => vm.isLoading = false);
  };

  vm.toggleSelectAll = function() {
    if (vm.isSelected) {
      return selectAll();
    } else {
      return deselectAll();
    }
  };

  const translateVersion = version => {
    let createdAt = moment(version.createdAt, dateService.getIsoFullDateFormatWithTz());
    return {
      id: version.id,
      employeeCardId: version.employeeCardId,
      name: version.name,
      status: statusNames[version.status],
      printStatus: version.printStatus,
      code: (version.codePrefix || '') + version.code,
      avatarUrl: version.avatar.url,
      expireDate: dateService.formatDate(version.expireDate, dateService.getDateFormat()),
      createdAt: createdAt.format(dateService.getFullDateFormat())
    };
  };

  const translateCard = function(card) {
    card.versions = _.map(card.versions, translateVersion);
    return card;
  };

  const addPrintedVersions = () => {
    return (() => {
      const result = [];
      for (let version of Array.from(vm.selectedVersions)) {
        const newVersion = new EmployeeCardVersion({
          id: version.id,
          cardId: version.employeeCardId,
          printStatus: employeeCardVersionPrintStatus.getPrintedStatus()
        });
        result.push(newVersion.save().then(newVersion => vm.onStatusChange(newVersion)));
      }
      return result;
    })();
  };

  const selectAll = function() {
    const versions = _.flatMap(entityManager.entities, vm.getCardVersions);
    return Array.from(versions).map((cardVersion) => {
      return (cardVersion.isSelected = true);
    });
  };

  const deselectAll = function() {
    const versions = _.flatMap(entityManager.entities, vm.getCardVersions);
    return Array.from(versions).map((cardVersion) => {
      return (cardVersion.isSelected = undefined);
    });
  };

  $scope.$watch(() => vm.filterParams, _.debounce(vm.filterCards, 50), true);

  return vm;

};

angular
  .module('public.security-manager.hr.cards')
  .controller('SecurityManagerHrCardsController', dependencies.concat(SecurityManagerHrCardsController));
