/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['AppModel'];

const EmployeeTimesheet = AppModel => {

  class EmployeeTimesheet extends AppModel {
    static initClass() {
      this.configure({
        url : '/employees/{{employeeId}}/timesheet',
        name : 'employee_timesheet',
        singular : true
      });
    }

    static exportUrl(employeeId, from, to) {
      return this.resourceUrl({ employeeId }) + `.csv?from=${from}&to=${to}`;
    }
  }

  EmployeeTimesheet.initClass();
  return EmployeeTimesheet;
};

angular.module('data.employee').factory('EmployeeTimesheet', dependencies.concat(EmployeeTimesheet));
