/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').directive('onEvent', () => {
  return {
    restrict : 'A',
    scope : {
      onEventCb : '&'
    },
    link($scope, $el, attrs) {
      return $el.bind(attrs.onEvent, () => $scope.$apply($scope.onEventCb));
    }
  }
});
