/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const DayOff = AppModel => {

  class DayOff extends AppModel {
    static initClass() {
      this.configure({url : '/day_offs/{{id}}', name : 'day_off'});
    }
  }

  DayOff.initClass();
  return DayOff;
};

angular.module('data.employee').factory('DayOff', dependencies.concat(DayOff));
