/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['$scope', 'Employee'];

const ManagerClientEmployeesController = function($scope, Employee) {

  const vm = this;

  vm.employees = [];
  vm.employeeSearch = '';
  vm.selectedEmployee = undefined;
  vm.ascendingEmployeeNameOrder = true;
  vm.ascendingResourceNameOrder = true;

  vm.$onInit = function() {
    $scope.managerClientLayoutCtrl.loadClient().then(function (client) {
      vm.client = client;
      loadInitialState();
    });
  };

  const formResources = employee => {
    return _.flatMap(employee.employeeResources, employeeResource => ({
      name: employeeResource.resource.name,
      serviceName: employeeResource.resource.service.name,
      resource: employeeResource.resource
    }));
  };

  const formServices = resources => {
    return _.uniq(_.map(resources, resource => {
      return resource.serviceName;
    }));
  };

  const translateEmployee = function(employee) {
    employee = new Employee(employee);
    const result = {
      id: employee.id,
      employee,
      fullName: employee.getFullName(),
      resources: formResources(employee),
      thumbAvatar: employee.user.thumbAvatar.url,
      avatar: employee.user.avatar.url,
      approved: true
    };
    result.services = formServices(result.resources);
    return result;
  };

  const setEmployees = function(employees) {
    vm.employees = _.map(employees.approvedEmployees, translateEmployee);
    return vm.selectEmployee(vm.employees[0]);
  };

  const loadInitialState = () => {
    return Employee.getWithResources({ clientId: vm.client.id })
      .then(setEmployees)
      .catch(error => Rollbar.log(`Client employees on ${window.location.href}`, error));
  };

  vm.toggleEmployeeNameOrder = () => vm.ascendingEmployeeNameOrder = !vm.ascendingEmployeeNameOrder;

  vm.toggleResourceNameOrder = () => vm.ascendingResourceNameOrder = !vm.ascendingResourceNameOrder;

  vm.selectEmployee = employee => vm.selectedEmployee = employee;

  vm.employeeFilter = object => object.fullName;

  return vm;

};

angular
  .module('public.client-manager.client')
  .controller('ManagerClientEmployeesController', dependencies.concat(ManagerClientEmployeesController));
