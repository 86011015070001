const dependencies = ['AppModel', 'clientStatus'];

const Client = (AppModel, clientStatus) => {

  class Client extends AppModel {
    static initClass() {
      this.configure({ url: '/clients/{{id}}', name: 'client' });
    }

    static withShifts(from, to, params) {
      if (params == null) { params = {}; }
      return this.$get(this.resourceUrl('with_shifts'), _.merge(params, { from, to }));
    }

    static filterCategories(params) {
      if (params == null) { params = {}; }
      return this.$get(this.resourceUrl('filter_categories'), params);
    }

    static byShift(shiftId) {
      return this.$get(this.resourceUrl('for_shift') + '/' + shiftId);
    }

    isActive() {
      return clientStatus.isActive(this.status);
    }

    isInactive() {
      return clientStatus.isInactive(this.status);
    }

    isIssued() {
      return clientStatus.isIssued(this.status);
    }

    cancelFutureShifts() {
      return this.$post(this.getUrl() + '/cancel_future_shifts');
    }

    getPeopleDataUrl() {
      return this.getUrl() + '/people_data.csv';
    }

    isLocked() {
      return Client.$get(this.getUrl() + '/is_locked');
    }

    isPartiallyLocked() {
      return Client.$get(this.getUrl() + '/is_partially_locked');
    }
  }

  Client.initClass();

  return Client;

};

angular.module('data.client').factory('Client', dependencies.concat(Client));
