/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const securityProviderPayPeriodStatus = function() {

  const TEMPORARILY = 0;
  const ACCEPTED = 1;
  const NEW = 2;

  return {

    getTempStatus() {
      return TEMPORARILY;
    }

  }

};

angular.module('data.security-provider')
  .service('securityProviderPayPeriodStatus', securityProviderPayPeriodStatus);