/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.employee.profile-settings', ['data.user']).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.employee.profile-settings', {
      abstract: true,
      url: '/profile-settings',
      template: require('./template.pug'),
      controller: 'EmployeeProfileSettingsLayoutController',
      controllerAs: 'employeeProfileSettingsLayoutCtrl'
    })
    .state('public.employee.profile-settings.personal-details', {
      url: '/my-personal-details',
      template: require('./personal-details/template.pug'),
      controller: 'EmployeeProfileSettingsPersonalDetailsController',
      controllerAs: 'employeeProfileSettingsPersonalDetailsCtrl'
    })

]);
