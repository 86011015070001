/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  'Client', 'ClientService', 'ClientLocation', 'BookingTemplate', 'BookingShift', 'HomeZone', 'bookingValidation',
  'clientStatus', 'bookingPeriod', 'selectService', 'dateService', 'dateWeek', 'currentUserService',
  'bookingTemplateValidation', 'activeStatus', 'packageTypeService'
];

const TimelineBookingFormDetailsDetailsController = function(
  Client, ClientService, ClientLocation, BookingTemplate, BookingShift, HomeZone, bookingValidation,
  clientStatus, bookingPeriod, selectService, dateService, dateWeek, currentUserService,
  bookingTemplateValidation, activeStatus, packageTypeService
) {

  const vm = this;

  vm.isShownServicePopup = false;
  vm.isShownLocationPopup = false;
  vm.isLoadedBookingsCount = false;
  vm.isShownLocationsExceedPopup = false;
  vm.isClientManagerLogged = currentUserService.isClientManagerLogged();
  vm.bookingPeriod = bookingPeriod;
  vm.dateWeek = dateWeek;
  vm.todayDate = dateService.today();
  vm.isShownHomeZones = packageTypeService.hasProPackage();

  vm.getDurationErrors = bookingValidation.getDurationErrors;

  vm.$onInit = function() {
    vm.chosenWeeks = dateWeek.toArray(vm.booking.frequencyOn);
    if (!vm.booking.to) { vm.booking.to = moment().add(1, 'day'); }
    vm.booking.templateId = null;
    vm.clientName = vm.booking.client != null ? vm.booking.client.name : undefined;
    loadBookingShiftsCount();
    resetClientData();
    return initClient();
  };

  vm.loadClients = text => {
    return Client.autocomplete({}, { name: text, limit: 5, status: activeStatus.getActiveStatus() });
  };

  vm.loadHomeZones = text => {
    const serviceQueryParams = {};
    if (currentUserService.isClientManagerLogged()) {
      serviceQueryParams.clientManagerId = currentUserService.getCurrentProfile().id;
    }
    return HomeZone.autocomplete({}, _.merge(serviceQueryParams, { 'order[name]': 'asc', searchString: text }));
  };

  vm.loadTemplates = function(text) {
    const params = {
      name: text,
      limit: 3
    };
    if (currentUserService.isClientManagerLogged()) {
      params.managerId = currentUserService.getCurrentProfile().id;
    }
    return BookingTemplate.query(params).then(function(bookingTemplates) {
      for (let bookingTemplate of Array.from(bookingTemplates)) {
        const createdAt = moment(bookingTemplate.createdAt, dateService.getIsoFullDateFormatWithTz());
        bookingTemplate.description = `Template from ${createdAt.format(dateService.getDateFormat())}`;
      }
      return bookingTemplates;
    });
  };

  vm.onClientChange = function(selectedObject) {
    if (selectedObject) {
      return initClientData(selectedObject.originalObject.id, selectedObject.originalObject);
    } else {
      return resetClientData();
    }
  };

  vm.onTemplateChange = function(selectedObject) {
    if (selectedObject) {
      vm.booking.templateId = selectedObject.originalObject.id;
      vm.booking.clientId = selectedObject.originalObject.clientId;
      vm.clientName = selectedObject.originalObject.clientName;
      initClientData(vm.booking.clientId);
      vm.booking.serviceId = selectedObject.originalObject.serviceId;
      return vm.isDisabledService = true;
    } else {
      vm.booking.templateId = null;
      vm.clientName = null;
      resetClientData();
      vm.booking.serviceId = null;
      return vm.isDisabledService = false;
    }
  };

  vm.hasClientServices = () => !_.isEmpty(vm.clientServices);

  vm.hasClientLocations = () => !_.isEmpty(vm.clientLocations);

  vm.getChosenServiceName = () => vm.clientServices[vm.booking.serviceId];

  vm.updateFrequencyOn = selected => vm.booking.frequencyOn = dateWeek.toValue(selected);

  vm.addService = function() {
    if (currentUserService.isClientManagerLogged()) { return; }
    vm.popupService = new ClientService;
    vm.popupService.clientId = vm.booking.clientId;
    return vm.isShownServicePopup = true;
  };

  vm.addLocation = function() {
    ClientLocation.getCount({ clientId: vm.booking.clientId }, { activeStatus: activeStatus.getActiveStatus() }).then((data) => {
      if (packageTypeService.hasFreePackage()) {
        if (data.count >= packageTypeService.getLocationsLimitForFreePackage()) {
          openLocationsExceedPopup();
        } else {
          openLocationPopup();
        }
      } else {
        openLocationPopup();
      }
    });
  };

  vm.closeServicePopup = function(service) {
    vm.isShownServicePopup = false;
    if (service) {
      vm.clientServices = _.merge(vm.clientServices, selectService.toSelectData([service]));
      return vm.booking.serviceId = service.id;
    }
  };

  vm.closeLocationPopup = function(location) {
    vm.isShownLocationPopup = false;
    if (location) {
      vm.clientLocations = _.merge(vm.clientLocations, selectService.toSelectData([location]));
      vm.booking.locationId = location.id;
      vm.onLocationChange();
    }
  };

  vm.isShowClientSelect = () => !currentUserService.isClientManagerLogged() && !vm.isEdit && !vm.withTemplate();

  vm.isChosenClient = () => vm.isLoadedBookingsCount && vm.booking.clientId;

  vm.withTemplate = () => vm.booking.templateId;

  vm.getTemplateNameErrors = () => bookingTemplateValidation.getTemplateNameErrors(vm.booking.id);

  vm.onLocationChange = () => {
    if (vm.isShownHomeZones) {
      HomeZone.forLocation(vm.booking.locationId).then(locationHomeZones => {
        if (vm.isClientManagerLogged) {
          vm.loadHomeZones().then((homeZones) => {
            vm.booking.homeZones = _.intersectionBy(locationHomeZones, homeZones, 'id')
          });
        } else {
          vm.booking.homeZones = locationHomeZones;
        }
      });
    }
  };

  vm.closeLocationsExceedPopup = function () {
    vm.isShownLocationsExceedPopup = false;
  };

  vm.isShownProjectCode = function() {
    return currentUserService.isSecurityManagerLogged() && !currentUserService.getCurrentProfile().isSupervisor();
  };

  vm.isShownClockIn = function() {
    return vm.isChosenClient() && (vm.booking.client.enableDesktopClockIn || vm.booking.client.enableMobileClockIn);
  };

  const openLocationsExceedPopup = function() {
    vm.isShownLocationsExceedPopup = true;
  };

  const openLocationPopup = function() {
    vm.popupLocation = new ClientLocation;
    vm.popupLocation.clientId = vm.booking.clientId;
    return vm.isShownLocationPopup = true;
  };

  const loadBookingShiftsCount = () => {
    return BookingShift.getCount({ bookingId: vm.booking.id }).then(function (data) {
      vm.isDisabledService = data.count > 0;
      return vm.isLoadedBookingsCount = true;
    });
  };

  const initClockInForClient = function(client) {
    if (client && client !== vm.booking.client) {
      vm.booking.enableClockIn = false;
      vm.booking.client = client;
    }
  };

  const initClientData = function(clientId, client) {
    vm.booking.clientId = clientId;
    initClockInForClient(client);
    const serviceQueryParams = {};
    if (currentUserService.isClientManagerLogged()) {
      serviceQueryParams.clientManagerId = currentUserService.getCurrentProfile().id;
    }
    if (!vm.isEdit) {
      serviceQueryParams.activeStatus = activeStatus.getActiveStatus();
    }
    ClientService.query(serviceQueryParams, { clientId }).then(services => {
      vm.clientServices = selectService.toSelectData(services);
    });
    ClientLocation.query({ activeStatus: activeStatus.getActiveStatus() }, { clientId }).then(locations => {
      if (vm.isEdit) {
        const foundLocation = _.find(locations, ['id', vm.booking.location.id]);
        if (!foundLocation) {
          locations.push(vm.booking.location);
        }
      }
      vm.clientLocations = selectService.toSelectArray(locations);
    });
  };

  const initClient = function() {
    if (vm.isEdit) {
      return initClientData(vm.booking.client.id);
    } else if (currentUserService.isClientManagerLogged()) {
      return initClientData(currentUserService.getCurrentProfile().clientId);
    }
  };

  const resetClientData = function() {
    vm.booking.clientId = null;
    vm.clientServices = {};
    vm.clientLocations = {};
  };

  return vm;

};

angular
  .module('public.timeline')
  .controller('TimelineBookingFormDetailsDetailsController',
    dependencies.concat(TimelineBookingFormDetailsDetailsController));
