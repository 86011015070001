/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('data.employee').factory('EmployeeGapChart', ['dateService', function(dateService) {

  const YEARS_MAX_GAP = 5;

  class EmployeeGapChart {

    constructor(dateRanges) {
      this.lastDate = dateService.today();
      this.firstYear = this.getFirstYear();
      this.firstDate = this.getLastDate();
      this.years = this.getYears();
      this.gaps = this.getGaps(dateRanges);
    }

    getFirstYear() {
      return this.lastDate.year() - YEARS_MAX_GAP + 1;
    }

    getLastDate() {
      return moment({ year: this.firstYear, month: 0, date: 1 });
    }

    getYears() {
      let years = [];
      for (let i = 0; i <= YEARS_MAX_GAP; i++) {
        years.push(this.firstYear + i);
      }
      return years;
    }

    getGaps(dateRanges) {
      if (dateRanges.length > 0) {
        return this.buildGaps(dateRanges);
      } else {
        return [{
          monthsCount: this.monthsDiff(this.firstDate, this.lastDate),
          from: this.firstDate,
          to: this.lastDate
        }];
      }
    }

    buildGaps(dateRanges) {
      let gaps = [];
      let currentDate = this.firstDate;
      _.each(dateRanges, dateRange => {
        const from = moment(dateRange.from, dateService.getIsoDateFormat());
        const to = moment(dateRange.to, dateService.getIsoDateFormat());
        if (to.isSameOrAfter(this.firstDate) && from.isSameOrBefore(this.lastDate)) {
          const startDate = moment.max(from, this.firstDate);
          const endDate = moment.min(to, this.lastDate);
          const beforeMonthsCount = this.monthsDiff(currentDate, startDate);
          let monthsCount = this.monthsDiff(startDate, endDate);
          if (beforeMonthsCount > 1) {
            gaps.push({
              monthsCount: beforeMonthsCount,
              from: currentDate,
              to: startDate
            });
          } else {
            monthsCount += beforeMonthsCount;
          }
          gaps.push({ monthsCount: monthsCount, items: dateRange.items });
          currentDate = endDate;
        }
      });
      const afterMonthsCount = this.monthsDiff(currentDate, this.lastDate);
      if (afterMonthsCount > 1) {
        gaps.push({ monthsCount: afterMonthsCount, from: currentDate });
      }
      this.addGroupSeparators(gaps);
      return gaps;
    }

    addGroupSeparators(gaps) {
      _.each(gaps, (gap, index, arrayGaps) => {
        if (index > 0) {
          const previousGap = arrayGaps[index - 1];
          if (previousGap.items && gap.items) {
            previousGap.isInGroup = true;
          }
        }
      });
    }

    monthsDiff(from, to) {
      return to.diff(from, 'month', true);
    }

  }

  return EmployeeGapChart;
}]);
