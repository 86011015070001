angular.module('public').service('headerItemsService', [
  'clientStatus', 'employeeStatus', 'currentUserService', 'packageTypeService',
  function(
    clientStatus, employeeStatus, currentUserService, packageTypeService
  ) {

    const getSecurityManagerHeaderItems = function() {
      const profile = currentUserService.getCurrentProfile();
      const isSupervisor = profile.isSupervisor();
      let headerItems = [{
        name: 'Schedule manager',
        sref: 'public.security-manager.schedule-manager.timeline.booking',
        activeStates: ['public.security-manager.schedule-manager']
      }];
      if (!isSupervisor) {
        headerItems.push({
          name: 'Clients',
          sref: 'public.security-manager.clients',
          stateParams: {
            status: clientStatus.getActiveStatus()
          },
          activeStates: [
            'public.security-manager.clients',
            'public.security-manager.client',
            'public.security-manager-invoice-form',
            'public.security-manager-invoice-history',
            'public.security-manager.client-service'
          ]
        })
      }
      if (!isSupervisor) {
        headerItems.push({
          name: 'HR',
          sref: 'public.security-manager.hr.employees',
          stateParams: {
            status: employeeStatus.getActiveStatus()
          },
          activeStates: [
            'public.security-manager.hr',
            'public.security-manager.employee',
            'public.security-manager.hr.bulk-rate',
            'public.employee-invoice-form',
            'public.employee-invoice-history'
          ]
        })
      }
      if (
        packageTypeService.hasProPackage() &&
        (profile.isAccountOwner() || profile.isAdmin() || (profile.isManager() &&profile.hasAccessToReports))
      ) {
        headerItems.push({
          name: 'Reports',
          sref: 'public.security-manager.reports.finance.income',
          activeStates: [
            'public.security-manager.reports.finance.income',
            'public.security-manager.reports.finance.daily-income',
            'public.security-manager.reports.finance.payroll',
            'public.security-manager.reports.finance.billable-hours',
            'public.security-manager.reports.hr.missing-data',
            'public.security-manager.reports.hr.licence',
            'public.security-manager.reports.hr.employee-availability',
            'public.security-manager.reports.hr.employee-list'
          ]
        });
      }
      if (
        packageTypeService.hasProPackage() &&
        (profile.isAccountOwner() || profile.isAdmin() || (profile.isManager() && profile.hasAccessToFinances))
      ) {
        headerItems.push({
          name: 'Finance',
          sref: 'public.security-manager.invoices.clients',
          activeStates: [
            'public.security-manager.invoices.clients',
            'public.security-manager.invoices.employees',
            'public.security-manager.invoices.payrolls',
            'public.security-manager.payroll-form',
            'public.security-manager.payroll-preview'
          ]
        });
      }
      headerItems.push({
        name: getSecurityManagerSettingsTitle(),
        sref: 'public.security-manager.settings.company-details',
        activeStates: [
          'public.security-manager.settings.resources',
          'public.security-manager.settings.trainings',
          'public.security-manager.settings.company-details',
          'public.security-manager.settings.payment',
          'public.security-manager.settings.email',
          'public.security-manager.settings.home-zone',
          'public.security-manager.settings.document-types',
          'public.security-manager.settings.settings',
          'public.security-manager.settings.roles',
          'public.security-manager.settings.billing',
          'public.security-manager.manager-profile'
        ]
      });
      return headerItems;
    };

    const getEmployeeHeaderItems = function() {
      return [{
        name: 'My timeline',
        sref: 'public.employee.timeline'
      }, {
        name: 'Profile',
        sref: 'public.employee.profile.details',
        stateParams: {
          employeeId: currentUserService.getCurrentProfile().id
        },
        activeStates: ['public.employee.profile']
      }];
    };

    const getClientManagerHeaderItems = function() {
      return [{
        name: 'Profile',
        sref: 'public.client-manager.client.details',
        activeStates: ['public.client-manager.client']
      }, {
        name: 'Timeline',
        sref: 'public.client-manager.timeline',
        activeStates: ['public.client-manager.timeline', 'public.client-manager.repeatable-booking']
      }, {
        name: 'Company',
        sref: 'public.client-manager.company.details'
      }];
    };

    const getSecurityManagerSettingsTitle = function() {
      if (currentUserService.getCurrentProfile().isSupervisor()) {
        return 'Company info';
      } else {
        return 'Settings';
      }
    };

    return {
      getItems() {
        if (currentUserService.isSecurityManagerLogged()) {
          return getSecurityManagerHeaderItems();
        } else if (currentUserService.isEmployeeLogged()) {
          return getEmployeeHeaderItems();
        } else {
          return getClientManagerHeaderItems();
        }
      }
    };
  }

]);
