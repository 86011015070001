import './styles.css'

const gccsInputWrapper = {
  controller: 'InputWrapperController',
  controllerAs: 'inputWrapperCtrl',
  template: require('./template.html'),
  transclude: {
    mainContainer: 'mainContainer',
    tooltipContainer: '?tooltipContainer'
  },
  bindings: {
    label: '@',
    sign: '<',
    error: '<',
    errorMessages: '<',
    customErrors: '&'
  }
};

angular.module('shared-components').component('gccsInputWrapper', gccsInputWrapper);
