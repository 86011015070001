const securityManagerNotificationTimeFilterService = function() {

  const DATE = 1;
  const URGENT = 2;
  const MISSED = 3;

  const names = {};
  names[DATE] = 'View by date';
  names[URGENT] = 'View by urgent';
  names[MISSED] = 'View by missed';

  return {
    getDate() {
      return DATE;
    },

    getUrgent() {
      return URGENT;
    },

    getMissed() {
      return MISSED;
    },

    getNames() {
      return names;
    }
  };
};

angular.module('public.security-manager.notifications')
  .service('securityManagerNotificationTimeFilterService', securityManagerNotificationTimeFilterService);
