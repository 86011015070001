/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [];

const shiftFormDetailsChangeService = function() {

  let _changed = false;
  let _isShownWarning = false;
  let _afterWarningCb = undefined;

  return {
    reset() {
      _changed = false;
      _isShownWarning = false;
      return _afterWarningCb = undefined;
    },

    setChanged() {
      return _changed = true;
    },

    hasChanged() {
      return _changed;
    },

    showWarning(afterWarningCb) {
      _afterWarningCb = afterWarningCb;
      return _isShownWarning = true;
    },

    hideWarning() {
      return _isShownWarning = false;
    },

    isShownWarning() {
      return _isShownWarning;
    },

    callAfterWarningCb() {
      return _afterWarningCb();
    }
  };
};

angular.module('popup.booking')
  .service('shiftFormDetailsChangeService', dependencies.concat(shiftFormDetailsChangeService));
