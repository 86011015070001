/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').factory('Document', ['AppModel', function(AppModel) {

  const FILE_TYPE_PDF = 1;
  const FILE_TYPE_IMAGE = 2;

  return class Document extends AppModel {

    isPdf() {
      return this.fileType === FILE_TYPE_PDF;
    }

    isImage() {
      return this.fileType === FILE_TYPE_IMAGE;
    }

    isViewable() {
      return this.isPdf() || this.isImage();
    }

  };

}]);
