/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'EmployeeDocument', 'EntityManager', 'uploadService', '$q'];

const EmployeeRegistrationDocumentsController = function($scope, EmployeeDocument, EntityManager, uploadService, $q) {

  const vm = this;

  const entityManager = new EntityManager(EmployeeDocument, {
    params : {
      employeeId : $scope.employeeRegistrationLayoutCtrl.employeeId
    },
    withBlank : true
  }
  );

  vm.addNewDocument = entityManager.addNewEntity;
  vm.removeDocument = entityManager.removeEntity;

  vm.getDocuments = () => entityManager.entities;

  entityManager.loadAll();

  $scope.$on('employee.registration.submit', function() {
    const documentPromises = _.map(vm.getDocuments(), document => uploadService.saveWithFile(document, 'file'));
    return $q.all(documentPromises).then(() => $scope.employeeRegistrationLayoutCtrl.goNext());
  });

  return vm;
};

angular.module('public.security-manager.employee.registration').controller('EmployeeRegistrationDocumentsController',
  dependencies.concat(EmployeeRegistrationDocumentsController));
