/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [];

const ReportsHrEmployeeListItemController = function() {

  const vm = this;

  vm.$onInit = function() {
    initEmployeeData();
  };

  vm.isHighlighted = function(entity, key) {
    if (vm.filterParams && vm.filterParams[key]) {
      return vm.filterParams[key].includes(entity.id);
    } else {
      return false
    }
  };

  const initEmployeeData = function() {
    vm.clients = vm.employeeData.clientsData.clients;
    vm.services = vm.employeeData.clientsData.services;
    vm.resources = vm.employeeData.clientsData.resources;
    vm.trainings = vm.employeeData.trainings;
    vm.roles = vm.employeeData.roles;
  };

  return vm;

};

angular.module('public.security-manager.reports.hr')
  .controller('ReportsHrEmployeeListItemController', dependencies.concat(ReportsHrEmployeeListItemController));
