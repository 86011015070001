/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('gccs-ui').directive('gccsMultipleButtons', () => {
  return {
    restrict: 'A',
    controller: 'MultipleButtonsController',
    controllerAs: 'multipleButtonsCtrl',
    template: require('./template.pug'),
    scope: {
      buttons: '<',
      chosenButtons: '=',
      isSingle: '<'
    }
  };
});
