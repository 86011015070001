const employeeType = function() {

  const EMPLOYED = 1;
  const SELF_EMPLOYED = 2;
  const SUB_CONTRACTOR = 3;

  const names = {
    [EMPLOYED]: 'Employed',
    [SELF_EMPLOYED]: 'Self-employed',
    [SUB_CONTRACTOR]: 'Sub-contractor'
  };

  const filterNames = {
    0: 'All',
    [EMPLOYED]: 'Employed',
    [SELF_EMPLOYED]: 'Self-employed',
    [SUB_CONTRACTOR]: 'Sub-contractor'
  };

  const selectedFilterNames = {
    0: 'Employment type',
    [EMPLOYED]: 'Employed',
    [SELF_EMPLOYED]: 'Self-employed',
    [SUB_CONTRACTOR]: 'Sub-contractor'
  };

  const selectedFilterPayrollNames = {
    0: 'Total',
    [EMPLOYED]: 'Employed',
    [SELF_EMPLOYED]: 'Self-employed',
    [SUB_CONTRACTOR]: 'Sub-contractor'
  };

  return {
    getNames() {
      return names;
    },

    getFilterNames() {
      return filterNames;
    },

    getSelectedFilterNames() {
      return selectedFilterNames;
    },

    getSelectedFilterPayrollNames() {
      return selectedFilterPayrollNames;
    },

    getName(type) {
      return names[type];
    },

    isEmployed(type) {
      return type === EMPLOYED;
    },

    isSelfEmployed(type) {
      return type === SELF_EMPLOYED;
    },

    isSubContractor(type) {
      return type === SUB_CONTRACTOR;
    },

    getEmployedStatus() {
      return EMPLOYED;
    },

    getSelfEmployedStatus() {
      return SELF_EMPLOYED;
    },

    getSubContractorStatus() {
      return SUB_CONTRACTOR;
    }
  };
};

angular.module('data.employee').service('employeeType', employeeType);
