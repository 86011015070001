/*
 * @author Oleksandr Papka <papkaos>
 */
const securityManagerNotificationPriorityService = function() {

  const URGENT_TODAY = 1;
  const TODAY = 2;
  const EARLIER = 3;

  const names = {};
  names[URGENT_TODAY] = 'Urgent today';
  names[TODAY] = 'Today';
  names[EARLIER] = 'Earlier';

  return {
    getUrgentToday() {
      return URGENT_TODAY;
    },

    getToday() {
      return TODAY;
    },

    getEarlier() {
      return EARLIER;
    },

    isUrgentToday(status) {
      return status === URGENT_TODAY;
    },

    isToday(status) {
      return status === TODAY;
    },

    isEarlier(status) {
      return status === EARLIER;
    },

    getNames() {
      return names;
    },

    getName(status) {
      return names[status];
    }
  };
};

angular.module('public.security-manager.notifications')
  .service('securityManagerNotificationPriorityService', securityManagerNotificationPriorityService);