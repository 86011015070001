/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  'bookingShiftEmployeeAssignStatus', 'BookingShiftEmployee', 'ShiftEmployee', 'currentUserService',
  '$q', 'notificationService'
];

const ShiftEmployeeActionsService = function(
  bookingShiftEmployeeAssignStatus, BookingShiftEmployee, ShiftEmployee, currentUserService,
  $q, notificationService
) {

  return class ShiftEmployeeActionsService {

    constructor() {
      this.closeInviteDropdown = this.closeInviteDropdown.bind(this);
      this.callbacks = {};
      this._initAvailabilityStatuses();
    }

    on(action, cb) {
      return this.callbacks[action] = cb;
    }

    setShifts(shifts) {
      shifts = [].concat(shifts);
      return this._shifts = shifts;
    }

    clearShifts() {
      return this._shifts = undefined;
    }

    isSplitShift() {
      return this.workingWithSplitShift = true;
    }

    setEmployees(employeeRows) {
      return this._employeeRows = employeeRows;
    }

    invite(shiftEmployee, bookingId, shiftId, employeeId) {
      const status = bookingShiftEmployeeAssignStatus.getNewStatus();
      return this._updateStatus(shiftEmployee, bookingId, shiftId, employeeId, status);
    }

    assign(shiftEmployee, bookingId, shiftId, employeeId) {
      const status = bookingShiftEmployeeAssignStatus.getAssignedStatus();
      return this._updateStatus(shiftEmployee, bookingId, shiftId, employeeId, status);
    }

    markUnavailable(shiftEmployee, bookingId, shiftId, employeeId) {
      const status = bookingShiftEmployeeAssignStatus.getUnavailableStatus();
      return this._updateStatus(shiftEmployee, bookingId, shiftId, employeeId, status);
    }

    declineInvitation(shiftEmployee, bookingId) {
      if (!shiftEmployee.bookingId) { shiftEmployee.bookingId = bookingId; }
      return this.unassign(shiftEmployee);
    }

    blowOut(shiftEmployee, bookingId) {
      const status = bookingShiftEmployeeAssignStatus.getBlowOut();
      return this._updateStatus(shiftEmployee, bookingId, null, null, status);
    }

    unassign(shiftEmployee, bookingId) {
      if (!shiftEmployee.bookingId) { shiftEmployee.bookingId = bookingId; }
      return shiftEmployee.unassign();
    }

    approveShiftCancellation(shiftEmployee, employeeId) {
      return ShiftEmployee.approveShiftCancellation(employeeId, shiftEmployee.id);
    }

    rejectShiftCancellation(shiftEmployee, employeeId) {
      return ShiftEmployee.rejectShiftCancellation(employeeId, shiftEmployee.id);
    }

    performButtonAction(employee) {
      const status = employee.availabilityStatus || 'default';
      if (!status) { return; }
      const action = this._availabilityStatuses[status].buttonAction;
      if (!action) { return; }
      if (!(this.workingWithSplitShift || this._haveSpaceForNonSplitInvites(this._shifts))) { return; }
      return Array.from(this._shifts).map((shift) => action(employee, shift));
    }

    performAction(action, employee) {
      const actionFactories = _.map(this._shifts, shift => () => action.perform(employee, shift));
      return _.reduce(actionFactories, (promiseChain, factory) => promiseChain.then(factory).catch(factory)
      , $q.when());
    }

    getClasses(employeeRow) {
      const status = employeeRow.availabilityStatus || 'default';
      return this._availabilityStatuses[status].classes;
    }

    getStatusText(employeeRow) {
      const status = employeeRow.availabilityStatus || 'default';
      if(employeeRow.isSubContractor && status === bookingShiftEmployeeAssignStatus.getAssignedStatus()){
        const assignedCount = _.map(this._shifts, shift => {return shift.approvedEmployees.filter(x => x.id === employeeRow.id).length;}).reduce((a, c) => a + c, 0);
        if(assignedCount > 1) {
          return this._availabilityStatuses[status].statusText + " x" + assignedCount
        }
      }

      return this._availabilityStatuses[status].statusText;
    }

    closeInviteDropdown(employeeRow) {
      this.dropdownActions = undefined;
      return employeeRow.inviteDropdownOpened = undefined;
    }

    toggleInviteDropdown(employeeRow) {
      if (employeeRow.inviteDropdownOpened) {
        return this.closeInviteDropdown(employeeRow);
      } else {
        return this._openInviteDropdown(employeeRow);
      }
    }

    _openInviteDropdown(employeeRow) {
      const { availabilityStatus, isSubContractor } = employeeRow;
      for (let empl of Array.from(this._employeeRows)) {
        empl.inviteDropdownOpened = undefined;
      }
      this.dropdownActions = this._getDropdownActions(availabilityStatus, isSubContractor);
      if (this.dropdownActions.length > 0) {
        return employeeRow.inviteDropdownOpened = true;
      }
    }

    _haveSpaceForNonSplitInvites(shifts) {
      return _.every(shifts, function(shift) {
        const nonSplitAssignedCount = _.filter(shift.employeesShifts, es => {
          return bookingShiftEmployeeAssignStatus.countsAsAssigned(es.assignStatus) && !es.splitForId;
        }).length;
        return shift.resourcesAmount > nonSplitAssignedCount;
      });
    }

    _getDropdownActions(availabilityStatus, isSubContractor) {
      if (!availabilityStatus) { availabilityStatus = 'default'; }
      let actions = {};
      if (!(this.workingWithSplitShift || this._haveSpaceForNonSplitInvites(this._shifts))) {
        actions = _.filter(this._availabilityStatuses[availabilityStatus].actions, action => !action.notAvailableWhenFull);
      } else {
        actions = this._availabilityStatuses[availabilityStatus].actions;
      }
      if(isSubContractor && !actions.some(function(action) { return action.text === 'Assign employee'}) && this._haveSpaceForNonSplitInvites(this._shifts)) {
        actions.unshift({
          text: 'Assign employee', notAvailableWhenFull: true, perform: function() { return this.callbacks['assign'].apply(null, arguments); }.bind(this)
        });
      }
      return actions;
    }

    _initAvailabilityStatuses() {
      let cancellingActions;
      this._availabilityStatuses = {};
      this._availabilityStatuses.default = {
        classes: 'mod-clickable',
        statusText: 'INVITE',
        actions: [{
          text: 'Assign employee', notAvailableWhenFull: true, perform: function() { return this.callbacks['assign'].apply(null, arguments); }.bind(this)
        }, {
          text: 'Mark unavailable', perform: function() { return this.callbacks['markUnavailable'].apply(null, arguments); }.bind(this)
        }],
        buttonAction: function() { return this.callbacks['invite'].apply(null, arguments); }.bind(this)
      };

      this._availabilityStatuses[bookingShiftEmployeeAssignStatus.getInAvailabilityStatus()] = this._availabilityStatuses.default;

      this._availabilityStatuses[bookingShiftEmployeeAssignStatus.getNotAssignedStatus()] = this._availabilityStatuses.default;

      this._availabilityStatuses[bookingShiftEmployeeAssignStatus.getNewStatus()] = {
        classes: 'mod-yellow',
        statusText: 'INVITED',
        actions: [{
          text: 'Assign employee', notAvailableWhenFull: true, perform: function() { return this.callbacks['assign'].apply(null, arguments); }.bind(this)
        }, {
          text: 'Clear invitation', perform: function() { return this.callbacks['declineInvitation'].apply(null, arguments); }.bind(this)
        }]
      };

      this._availabilityStatuses[bookingShiftEmployeeAssignStatus.getAssignedStatus()] = {
        classes: 'mod-green',
        statusText: 'ASSIGNED',
        actions: [{
          text: 'Unassign', perform: function() { return this.callbacks['unassign'].apply(null, arguments); }.bind(this)
        }, {
          text: 'Blow out', perform: function() { return this.callbacks['blowOut'].apply(null, arguments); }.bind(this)
        }]
      };

      this._availabilityStatuses[bookingShiftEmployeeAssignStatus.getDeclinedStatus()] = {
        classes: 'mod-red',
        statusText: 'DECLINED',
        actions: [{
          text: 'Assign employee', notAvailableWhenFull: true, perform: function() { return this.callbacks['assign'].apply(null, arguments); }.bind(this)
        }, {
          text: 'Invite employee', notAvailableWhenFull: true, perform: function() { return this.callbacks['invite'].apply(null, arguments); }.bind(this)
        }]
      };

      this._availabilityStatuses[bookingShiftEmployeeAssignStatus.getUnavailableStatus()] = {
        classes: 'mod-red',
        statusText: 'UNAVAILABLE',
        actions: [{
          text: 'Assign employee', notAvailableWhenFull: true, perform: function() { return this.callbacks['assign'].apply(null, arguments); }.bind(this)
        }, {
          text: 'Invite employee', notAvailableWhenFull: true, perform: function() { return this.callbacks['invite'].apply(null, arguments); }.bind(this)
        }]
      };

      this._availabilityStatuses[bookingShiftEmployeeAssignStatus.getBannedForClientStatus()] = {
        classes: 'mod-red',
        statusText: 'BANNED',
        actions: []
      };

      if (currentUserService.getCurrentProfile().isSupervisor()) {
        cancellingActions = [];
      } else {
        cancellingActions = [{
          text: 'Approve',
          perform: function() { return this.callbacks['approveShiftCancellation'].apply(null, arguments); }.bind(this)
        }, {
          text: 'Decline',
          perform: function() { return this.callbacks['rejectShiftCancellation'].apply(null, arguments); }.bind(this)
        }];
      }

      this._availabilityStatuses[bookingShiftEmployeeAssignStatus.getInDeclineProcessStatus()] = {
        classes: 'mod-yellow',
        statusText: 'CANCELLING',
        actions: cancellingActions
      };

      this._availabilityStatuses[bookingShiftEmployeeAssignStatus.getInDayOffStatus()] = {
        classes: 'mod-red',
        statusText: 'DAY OFF',
        actions: []
      };

      this._availabilityStatuses[bookingShiftEmployeeAssignStatus.getInSickDayStatus()] = {
        classes: 'mod-red',
        statusText: 'SICK DAY',
        actions: []
      };

      this._availabilityStatuses[bookingShiftEmployeeAssignStatus.getBlowOut()] = {
        classes: 'mod-red',
        statusText: 'BLEW OUT',
        actions: [{
          text: 'Cancel',
          notAvailableWhenFull: true,
          perform: function() { this.callbacks['assign'].apply(null, arguments); }.bind(this)
        }]
      };
    }

    _updateStatus(shiftEmployee, bookingId, shiftId, employeeId, status) {
      if (shiftEmployee) {
        if (!shiftEmployee.bookingId) { shiftEmployee.bookingId = bookingId; }
        if (!shiftEmployee.shiftId) { shiftEmployee.shiftId = shiftId; }
        if (!shiftEmployee.employeeId) { shiftEmployee.employeeId = employeeId; }
        shiftEmployee.assignStatus = status;
        return new BookingShiftEmployee(shiftEmployee).save();
      } else {
        if (!bookingId || !shiftId || !employeeId) { return; }
        return new BookingShiftEmployee({ bookingId, shiftId, employeeId, assignStatus: status }).save()
          .catch(function(result) {
            if (result.data.show_error) {
              notificationService.notifyError(result.data.error);
            }
            throw result;
          });
      }
    }
  }

};

angular
  .module('public.security-manager.schedule-manager')
  .factory('ShiftEmployeeActionsService', dependencies.concat(ShiftEmployeeActionsService));
