/*
 * @author Igor Serpak <robobee>
 */
const employeeDocumentType = function() {

  const PASSPORT = 1;
  const RIGHT_TO_WORK = 2;
  const PROOF_OF_ADDRESS = 3;
  const VETTING_FILE = 4;
  const VISA = 5;

  const names = {};
  names[PASSPORT] = 'Passport';
  names[RIGHT_TO_WORK] = 'Right to Work';
  names[PROOF_OF_ADDRESS] = 'Proof of Address';
  names[VETTING_FILE] = 'Vetting File';
  names[VISA] = 'Visa';

  const icons = {};
  icons[PASSPORT] = '#type-password';
  icons[RIGHT_TO_WORK] = '#type-right-to-work';
  icons[PROOF_OF_ADDRESS] = '#type-proof-address';
  icons[VETTING_FILE] = '#type-vetting-file';
  icons[VISA] = '#type-visa';

  const types = [{
    type: PASSPORT,
    name: names[PASSPORT],
    icon: icons[PASSPORT]
  }, {
    type: RIGHT_TO_WORK,
    name: names[RIGHT_TO_WORK],
    icon: icons[RIGHT_TO_WORK]
  }, {
    type: PROOF_OF_ADDRESS,
    name: names[PROOF_OF_ADDRESS],
    icon: icons[PROOF_OF_ADDRESS]
  }, {
    type: VETTING_FILE,
    name: names[VETTING_FILE],
    icon: icons[VETTING_FILE]
  }, {
    type: VISA,
    name: names[VISA],
    icon: icons[VISA]
  }];

  return {
    getPassport() { return PASSPORT; },
    getRightToWork() { return RIGHT_TO_WORK; },
    getProofOfAddress() { return PROOF_OF_ADDRESS; },
    getVettingFile() { return VETTING_FILE; },
    getVisa() { return VISA; },

    getTypes() { return types; },
    getName(type) { return names[type]; },
    getIcon(type) { return icons[type]; }
  };
};

angular.module('data.employee').service('employeeDocumentType', employeeDocumentType);
