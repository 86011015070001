/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$q', 'ClientManager', 'ClientService', 'EntityManager', 'uploadService', 'userValidation', 'User'
];

const ClientRegistrationManagerInvitationController = function(
  $scope, $q, ClientManager, ClientService, EntityManager, uploadService, userValidation, User
) {

  const vm = this;

  const params = { clientId: $scope.clientRegistrationLayoutCtrl.clientId };
  const entityManager = new EntityManager(ClientManager, {
    params,
    withBlank: true
  });

  vm.addNewManager = entityManager.addNewEntity;
  vm.removeManager = entityManager.removeEntity;
  vm.getEmailErrors = userValidation.getEmailErrors;

  vm.getManagers = () => entityManager.entities;

  vm.loadServices = text => ClientService.query({ name: text }, params);

  entityManager.loadAll();

  vm.managerNotEmpty = function(manager) {
    return (
      manager.user &&
      (manager.user.avatar || manager.user.avatarUploaded || manager.user.firstName || manager.user.lastName || manager.user.email)
    ) ||
      manager.position ||
      manager.phoneNumber ||
      manager.services.length > 0;
  };

  const withRequiredFields = function(manager) {
    const user = manager.user;
    return user && user.firstName && user.lastName && user.email;
  };

  const buildManagerPromise = function(manager) {
    let avatarUploaded = manager.user.avatarUploaded;
    return manager.save().then(function(savedManager) {
      let user = new User(savedManager.user);
      user.avatarUploaded = avatarUploaded;
      uploadService.saveWithFile(user, 'avatar');
    });
  };

  $scope.$on('client.registration.submit', function() {
    const managerPromises = _.map(_.filter(vm.getManagers(), withRequiredFields), buildManagerPromise);
    return $q.all(managerPromises).then(() => $scope.clientRegistrationLayoutCtrl.goNext());
  });

  return vm;

};

angular
  .module('public.security-manager.client.registration')
  .controller('ClientRegistrationManagerInvitationController',
    dependencies.concat(ClientRegistrationManagerInvitationController));
