/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const ClientEmployeeResource = AppModel => {

  class ClientEmployeeResource extends AppModel {
    static initClass() {
      this.configure({
        url : '/clients/{{clientId}}/employee_resources/{{id}}',
        name : 'employee_resource'
      });
    }
  }

  ClientEmployeeResource.initClass();
  return ClientEmployeeResource;
};

angular.module('data.client').factory('ClientEmployeeResource', dependencies.concat(ClientEmployeeResource));
