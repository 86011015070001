/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['AppModel'];

const SilenceMode = AppModel => {

  class SilenceMode extends AppModel {
    static initClass() {
      this.configure({
        url : '/silence_mode',
        name : 'silence_mode',
        singular : true
      });
    }
  }

  SilenceMode.initClass();
  return SilenceMode;
};

angular.module('data.security-manager').factory('SilenceMode', dependencies.concat(SilenceMode));
