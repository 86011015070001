/*
 * @author Igor Serpak <robobee>
 */
const employeeCardVersionPrintStatus = function() {

  const NOT_PRINTED = 1;
  const PRINTED = 2;
  const EXPIRED = 3;
  const LOST = 4;

  const NAMES = {
    [NOT_PRINTED] : 'NOT PRINTED',
    [PRINTED] : 'PRINTED',
    [EXPIRED] : 'EXPIRED',
    [LOST] : 'LOST'
  };

  const CLASSES = {
    [NOT_PRINTED] : 'mod-yellow',
    [PRINTED] : 'mod-green',
    [EXPIRED] : 'mod-red',
    [LOST] : 'mod-lilac'
  };

  return {
    getClasses() {
      return CLASSES;
    },

    getNames() {
      return NAMES;
    },

    getPrintedStatus() {
      return PRINTED;
    }
  };
};

angular.module('data.employee').service('employeeCardVersionPrintStatus', employeeCardVersionPrintStatus);
