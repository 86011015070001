/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
angular.module('public.client-manager', [
  'data.client',
  'public.client-manager.client',
  'public.client-manager.profile',
  'public.client-manager.timeline',
  'public.client-manager.booking-form',
  'public.client-manager.company',
  'public.client-manager.repeatable-booking'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.client-manager', {
      abstract: true,
      url: '/manager',
      template: require('./template.pug'),
      controller: 'ManagerLayoutController',
      controllerAs: 'managerLayoutCtrl'
    })

]);
