import './reports-hr.styles/_import.css'

const dependencies = ['$scope', '$state'];

const ReportsHrController = function($scope, $state) {

  const vm = this;

  vm.$onInit = function() {
    initTabs();
  };

  vm.isActiveState = function (tab) {
    return $state.includes(tab.sref, {
      employmentType: (tab.srefParams != null ? tab.srefParams.employmentType : undefined) || {}
    });
  };

  const initTabs = function() {
    vm.tabs = [{
      name: 'Documents',
      sref: 'public.security-manager.reports.hr.licence'
    }, {
      name: 'Employee list',
      sref: 'public.security-manager.reports.hr.employee-list'
    }, {
      name: 'Missing data',
      sref: 'public.security-manager.reports.hr.missing-data'
    }, {
      name: "Employee's Availability",
      sref: 'public.security-manager.reports.hr.employee-availability'
    }];
  };

  return vm;

};

angular.module('public.security-manager.reports.hr')
  .controller('ReportsHrController', dependencies.concat(ReportsHrController));
