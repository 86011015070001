/*
 * @author Igor Serpak <robobee>
 */
const gccsEmailEmployeeFilter = {
  controller: 'PopupEmailEmployeeFormFilterController',
  template: require('./template.pug'),
  bindings: {
    isFilterOpen: '<',
    closeFilter: '&',
    preselectedEmployees: '<',
    onConfirmCb: '&'
  }
};

angular.module('popup.email').component('gccsEmailEmployeeFilter', gccsEmailEmployeeFilter);
