/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['BookingDecorator', 'timelineBookingGrouper'];

const ClientDecorator = function(BookingDecorator, timelineBookingGrouper) {

  return class ClientDecorator {

    constructor(client) {
      this.client = client;
      this.logo = client.logo;
      this.id = client.id;
      this.dBookings = [];
      this._decorateBookings();
    }

    groupSeries() {
      return Array.from(this.dBookings).map((dBooking) => dBooking.groupSeries());
    }

    setVisibilityFromStats(currentStatFilter) {
      let visible = false;
      for (let dBooking of Array.from(this.dBookings)) {
        if (dBooking.setVisibilityFromStats(currentStatFilter) && !visible) {
          visible = true;
        }
      }
      return this.visible = visible;
    }

    updateShiftsForSeries(dSeries, shifts) {
      return Array.from(this.dBookings).map((dBooking) => dBooking.updateShiftsForSeries(dSeries, shifts));
    }

    updateEmployeeShift(employeeShift) {
      return Array.from(this.dBookings).map((dBooking) => dBooking.updateEmployeeShift(employeeShift));
    }

    removeEmployeeShift(employeeShift) {
      return Array.from(this.dBookings).map((dBooking) => dBooking.removeEmployeeShift(employeeShift));
    }

    hasEmployeeShift(employeeShift) {
      for (let dBooking of Array.from(this.dBookings)) {
        if (dBooking.hasEmployeeShift(employeeShift)) { return true; }
      }
      return false;
    }

    closeShiftTimelinePopups(activeDShift) {
      return Array.from(this.dBookings).map((dBooking) => dBooking.closeShiftTimelinePopups(activeDShift));
    }

    invalidateCache() {
      this.clientDimensions = undefined;
      return Array.from(this.dBookings).map((dBooking) => dBooking.invalidateCache());
    }

    groupBookings() {
      return this.groupedDBookingRows = timelineBookingGrouper.groupClientBookings(this);
    }

    clearDimensions() {
      this.clientDimensions = undefined;
      return Array.from(this.dBookings).map((dBooking) => dBooking.clearDimensions());
    }

    _decorateBookings() {
      return Array.from(this.client.bookings).map((booking) => {
        return this.dBookings.push(new BookingDecorator(this.id, this.logo, booking));
      });
    }

  }

};

angular.module('public.timeline').factory('ClientDecorator', dependencies.concat(ClientDecorator));
