/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('public.employee.timeline').service('timelineItemsGroupService', [
  'intersectionMergerService', 'dateService',
  function(intersectionMergerService, dateService) {

    let _timelineItems = [];

    const groupTimelineItems = function(timelineItemsToGroup) {
      _timelineItems = divideTimelineItemsByDate(angular.copy(timelineItemsToGroup));
      const groupedTimelineItems = {};
      _.each(getGroupedTimelineItemsByDate(), (timelineItems, dateKey) =>
        _.each(timelineItems, function(timelineItem) {
          if (groupedTimelineItems[dateKey]) {
            _.each(groupedTimelineItems[dateKey], function(groupedTimelineItem) {
              if (areTimelineItemsIntersecting(timelineItem, groupedTimelineItem)) {
                updateRowsCount(timelineItem, groupedTimelineItem);
                return addIntersections(timelineItem, groupedTimelineItem);
              }
            });
          } else {
            if (!groupedTimelineItems[dateKey]) { groupedTimelineItems[dateKey] = []; }
          }
          return groupedTimelineItems[dateKey].push(timelineItem);
        })
      );
      _.each(groupedTimelineItems, timelineItems => intersectionMergerService.mergeIntersections(timelineItems));
      return groupedTimelineItems;
    };

    const divideTimelineItemsByDate = function(timelineItems) {
      const dividedTimelineItems = [];
      _.each(timelineItems, function(timelineItem) {
        initTimelineItemAdditionalData(timelineItem);
        const startDayTime = timelineItem.startTimeMoment.clone().startOf('day');
        const daysCount = Math.ceil(timelineItem.endTimeMoment.diff(startDayTime, 'days', true));
        if (daysCount > 1) {
          return (() => {
            const result = [];
            for (let day = 1, end = daysCount, asc = 1 <= end; asc ? day <= end : day >= end; asc ? day++ : day--) {
              const newTimelineItem = angular.copy(timelineItem);
              if (day !== 1) { newTimelineItem.startTimeMoment.add(day - 1, 'days').startOf('day'); }
              if (day !== daysCount) {
                newTimelineItem.endTimeMoment = newTimelineItem.startTimeMoment.clone().add(1, 'days').startOf('day');
              }
              newTimelineItem.fakeId = timelineItem.id + '_' + day;
              result.push(dividedTimelineItems.push(newTimelineItem));
            }
            return result;
          })();
        } else {
          timelineItem.fakeId = timelineItem.id;
          return dividedTimelineItems.push(timelineItem);
        }
      });
      return dividedTimelineItems;
    };

    const addIntersections = function(timelineItem1, timelineItem2) {
      const intersection = {
        from: moment.max(timelineItem1.startTimeMoment, timelineItem2.startTimeMoment),
        to: moment.min(timelineItem1.endTimeMoment, timelineItem2.endTimeMoment)
      };
      timelineItem1.intersections.push(intersection);
      return timelineItem2.intersections.push(intersection);
    };

    const areTimelineItemsIntersecting = (timelineItem1, timelineItem2) => {
      return !(
        (timelineItem1.endTimeMoment.diff(timelineItem2.startTimeMoment) <= 0) ||
        (timelineItem1.startTimeMoment.diff(timelineItem2.endTimeMoment) >= 0)
      );
    };

    const getGroupedTimelineItemsByDate = function() {
      const groupedByDateTimelineItems = {};
      _.each(_timelineItems, function(timelineItem) {
        const key = timelineItem.startTimeMoment.format(dateService.getDateFormat());
        if (!groupedByDateTimelineItems[key]) { groupedByDateTimelineItems[key] = []; }
        return groupedByDateTimelineItems[key].push(timelineItem);
      });
      return groupedByDateTimelineItems;
    };

    const initTimelineItemAdditionalData = function(timelineItem) {
      if (!timelineItem.startTimeMoment) {
        timelineItem.startTimeMoment = moment(timelineItem.startTime, dateService.getIsoFullDateFormat());
      }
      if (!timelineItem.endTimeMoment) {
        timelineItem.endTimeMoment = moment(timelineItem.endTime, dateService.getIsoFullDateFormat());
      }
      timelineItem.row = 1;
      timelineItem.rowsCount = 1;
      timelineItem.intersections = [];
    };

    const updateRowsCount = function(timelineItem, groupedTimelineItem) {
      timelineItem.row = groupedTimelineItem.row + 1;
      timelineItem.rowsCount = timelineItem.row;
      if (!groupedTimelineItem.conflictedTimelineItemIds) { groupedTimelineItem.conflictedTimelineItemIds = []; }
      if (!timelineItem.conflictedTimelineItemIds) { timelineItem.conflictedTimelineItemIds = []; }
      groupedTimelineItem.conflictedTimelineItemIds.push(timelineItem.fakeId);
      timelineItem.conflictedTimelineItemIds.push(groupedTimelineItem.fakeId);
      return fixRowsCount(groupedTimelineItem);
    };

    const fixRowsCount = timelineItem => {
      return _.each(timelineItem.conflictedTimelineItemIds, function (timelineItemId) {
        const conflictedTimelineItem = _.find(_timelineItems,
          findingTimelineItem => findingTimelineItem.fakeId === timelineItemId);
        if (timelineItem.rowsCount < conflictedTimelineItem.rowsCount) {
          timelineItem.rowsCount = conflictedTimelineItem.rowsCount;
          return fixRowsCount(conflictedTimelineItem);
        } else if (timelineItem.rowsCount > conflictedTimelineItem.rowsCount) {
          conflictedTimelineItem.rowsCount = timelineItem.rowsCount;
          return fixRowsCount(conflictedTimelineItem);
        }
      });
    };

    return { groupTimelineItems };

  }

]);
