/**
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope'];

const ClientShowNotesController = function($scope) {

  const vm = this;

  vm.clientId = $scope.clientShowLayoutCtrl.clientId;

  return vm;

};

angular.module('public.security-manager.client.show')
  .controller('ClientShowNotesController', dependencies.concat(ClientShowNotesController));
