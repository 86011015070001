const dependencies = [
  'ExtendedFilterBookingService', 'ExtendedFilterEmployeesService', 'ExtendedFilterLiveFeedService',
  'ExtendedFilterClientBookingService', 'Client', 'Employee'
];

const ExtendedSearchController = function(
  ExtendedFilterBookingService, ExtendedFilterEmployeesService, ExtendedFilterLiveFeedService,
  ExtendedFilterClientBookingService, Client, Employee
) {

  const vm = this;

  const FILTER_TYPE_BOOKING_TIMELINE = 'bookingTimeline';
  const FILTER_TYPE_CLIENT_BOOKING_TIMELINE = 'clientBookingTimeline';
  const FILTER_TYPE_LIVE_FEED = 'liveFeed';

  vm.$onInit = function() {
    if (vm.filterType === FILTER_TYPE_BOOKING_TIMELINE) {
      Client.filterCategories({ type: 'booking_timeline' }).then(function (filterData) {
        vm.service = new ExtendedFilterBookingService(filterData, vm.applyFilterCb);
        vm.service.setFilterType(vm.filterType);
        vm.service.applyFilter({ isFirstApply: true });
      });
    } else if (vm.filterType === FILTER_TYPE_LIVE_FEED) {
      Client.filterCategories({ type: 'live_feed' }).then(function (filterData) {
        vm.service = new ExtendedFilterLiveFeedService(filterData, vm.applyFilterCb);
        vm.service.setFilterType(vm.filterType);
        vm.service.initFilter();
      });
    } else if (vm.filterType === FILTER_TYPE_CLIENT_BOOKING_TIMELINE) {
      Client.filterCategories({ type: 'booking_timeline' }).then(function (filterData) {
        vm.service = new ExtendedFilterClientBookingService(filterData, vm.applyFilterCb);
        vm.service.setFilterType(vm.filterType);
        vm.service.applyFilter();
      });
    } else {
      Employee.filterCategories().then(function(filterData) {
        vm.service = new ExtendedFilterEmployeesService(filterData, vm.applyFilterCb);
        vm.service.setData(vm.selectedShifts, vm.availability, vm.resourceId);
        vm.service.applyFilter();
      });
    }
  };

  vm.$onChanges = function(changes) {
    if (changes.clients && !changes.clients.isFirstChange()) {
      if (vm.service) {
        vm.service.setClients(vm.clients, vm.selectedClients);
      }
    }
    if (changes.selectedShifts && (changes.selectedShifts.currentValue !== undefined)) {
      if (vm.service) {
        vm.service.setSelectedShifts(vm.selectedShifts)
      }
    }
  };

  vm.initSelectedOption = function(filterCategory, option) {
    if (option.isSelected) {
      filterCategory.selectedOption = option;
    }
  };

  return vm;

};

angular.module('gccs-ui').controller('ExtendedSearchController', dependencies.concat(ExtendedSearchController));
