const dependencies = ['AppModel'];

const ClientSite = AppModel => {

  class ClientSite extends AppModel {
    static initClass() {
      this.configure({
        url: '/clients/{{ clientId }}/sites/{{ id }}',
        name: 'site'
      });
    }

    static forEmployee(clientId, employeeId) {
      return this.$get(this.resourceUrl({ clientId }, 'for_employee'), { employeeId });
    }
  }

  ClientSite.initClass();
  
  return ClientSite;
  
};

angular.module('data.client').factory('ClientSite', dependencies.concat(ClientSite));
