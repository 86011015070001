const dependencies = [];

const bookingShiftClockInErrorsService = function() {

  const EARLY_CLOCK_OUT_MINUTES = 5;
  const MISSED_CLOCK_OUT_MINUTES = 60;

  const getClockOutDiffInMinutes = function(shiftEmployee) {
    if (shiftEmployee.endTimeMoment && shiftEmployee.clockOutTimeMoment) {
      return shiftEmployee.endTimeMoment.diff(shiftEmployee.clockOutTimeMoment, 'minutes');
    }
  };

  return {

    isClockInLate(shiftEmployee) {
      if (shiftEmployee.startTimeMoment && shiftEmployee.clockInTimeMoment) {
        return shiftEmployee.clockInTimeMoment.diff(shiftEmployee.startTimeMoment, 'minutes') > 0;
      }
    },

    wasClockOutEarly(shiftEmployee) {
      return getClockOutDiffInMinutes(shiftEmployee) > EARLY_CLOCK_OUT_MINUTES;
    },

    wasClockOutLate(shiftEmployee) {
      return getClockOutDiffInMinutes(shiftEmployee) < 0 && getClockOutDiffInMinutes(shiftEmployee) >= -MISSED_CLOCK_OUT_MINUTES;
    },

    wasClockOutMissed(shiftEmployee) {
      return getClockOutDiffInMinutes(shiftEmployee) < -MISSED_CLOCK_OUT_MINUTES;
    },

    hasClockInError(shiftEmployee) {
      return this.isClockInLate(shiftEmployee) || !shiftEmployee.clockInTimeMoment;
    },

    hasClockOutError(shiftEmployee) {
      return this.wasClockOutEarly(shiftEmployee) || this.wasClockOutLate(shiftEmployee) ||
        this.wasClockOutMissed(shiftEmployee) || !shiftEmployee.clockOutTimeMoment;
    },

    hasClockInOutErrors(shiftEmployee) {
      return this.hasClockInError(shiftEmployee) || this.hasClockOutError(shiftEmployee);
    }

  }

};

angular.module('data.booking')
  .service('bookingShiftClockInErrorsService', dependencies.concat(bookingShiftClockInErrorsService));
