dependencies = [
  'SecurityManagerNotificationsLoader', 'SecurityManagerNotification', 'PaginatorService',
  'securityManagerNotificationTranslator', 'currentUserService'
];

const SecurityManagerNotificationTopBarService = (
  SecurityManagerNotificationsLoader, SecurityManagerNotification, PaginatorService,
  securityManagerNotificationTranslator, currentUserService
) => {

  return class SecurityManagerNotificationTopBarService {

    constructor(securityManager) {
      this.securityManager = securityManager;
      this.loader = new SecurityManagerNotificationsLoader(securityManager);
      this.paginator = new PaginatorService();
      this.statusCount = {
        unseenCount: 0,
        unreadCount: 0
      };
      this.loadStatusCount();
    }

    loadMore() {
      const paginationParams = this.paginator.nextPageParams();
      return this.loader.load({ topBar: true }, paginationParams, newNotifications => {
        const translatedNotifications = _.map(newNotifications, this._translateNotification);
        this.notifications = _.uniqBy(this.notifications.concat(translatedNotifications), notification => notification.id);
      });
    }

    isDisabledLoading() {
      return this.loader.isDisabledLoading();
    }

    loadNotifications() {
      this.notifications = [];
      this.paginator.resetOffset();
      this.loadMore().then(() => {
        this.loadStatusCount();
      });
    }

    loadStatusCount() {
      SecurityManagerNotification.getStatusCount(this.securityManager.id).then((statusCount) => {
        this.statusCount = statusCount;
      });
    }

    toggleRead(notification) {
      const promise = notification.isRead ?
        this._markRead(notification)
        :
        this._markUnread(notification);
      return promise.finally(() => {
        this.loadStatusCount();
      });
    }

    notificationCreated(notification, isBarOpened, { withSound }) {
      this._loadNotification(notification, isBarOpened, withSound);
    }

    notificationUpdated(notification, isBarOpened) {
      this.loadStatusCount();
      this._loadNotification(notification, isBarOpened, false);
    }

    notificationDestroyed(notification) {
      this.loadStatusCount();
      this._removeNotification(notification);
    }

    _translateNotification = (notification) => {
      return securityManagerNotificationTranslator.translateNotification(this, notification);
    };

    _markRead(notification) {
      return SecurityManagerNotification.markRead(this.securityManager.id, notification.id);
    }

    _markUnread(notification) {
      return SecurityManagerNotification.markUnread(this.securityManager.id, notification.id);
    }

    _findNotificationIndex(notificationId) {
      return _.findIndex(this.notifications, (notification) => notification.object.id === notificationId);
    }

    _loadNotification(notification, isBarOpened = false, withSound = true) {
      this.loader.loadNotification(notification).then(updatedNotification => {
        this._updateReloadedNotificationInView(updatedNotification, isBarOpened, withSound);
      }).catch(function() {
        // do nothing
      });
    }

    _removeNotification(notification) {
      const notificationIndex = this._findNotificationIndex(notification.id);
      if (notificationIndex !== -1) {
        this.paginator.decrementOffset();
        this.notifications.splice(notificationIndex, 1);
      }
    }

    _updateReloadedNotificationInView(updatedNotification, isBarOpened, withSound) {
      const translatedNotification = this._translateNotification(updatedNotification);
      const notificationIndex = this._findNotificationIndex(updatedNotification.id);
      if (notificationIndex !== -1) {
        this.notifications[notificationIndex] = translatedNotification;
      } else {
        const isShownInBar = translatedNotification.isUrgent || !currentUserService.getCurrentProfile().onlyUrgentNotifications;
        if (isShownInBar && currentUserService.getCurrentProfile().enableNotificationSound && withSound) {
          document.getElementById('newNotificationAudio').play();
        }
        if (isShownInBar && this.notifications && (
          this.notifications.length === 0 ||
          this.loader.endedLoading ||
          translatedNotification.eventDateMoment.isSameOrAfter(_.last(this.notifications).eventDateMoment)
        )) {
          this.notifications.push(translatedNotification);
          this.notifications = _.sortBy(this.notifications, (notification) => -notification.createdAtMoment);
          this.paginator.incrementOffset();
          this.statusCount.unreadCount += 1;
          if (isBarOpened) {
            SecurityManagerNotification.markSeen(this.securityManager.id, updatedNotification.id);
          } else {
            this.statusCount.unseenCount += 1;
          }
        }
      }
    }

  }

};

angular.module('public.security-manager.notifications')
  .factory('SecurityManagerNotificationTopBarService', dependencies.concat(SecurityManagerNotificationTopBarService));
