/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = [
  'SecurityProviderSetting', 'SecurityProviderDocument', 'currentUserService'
];

const ClientManagerCompanyDetailsController = function(
  SecurityProviderSetting, SecurityProviderDocument, currentUserService
) {

  const vm = this;

  const customErrorTexts = {
    email: { pattern: 'Enter a valid email' },
    registrationNumber: { pattern: 'Enter a valid number' },
    vatNumber: { pattern: 'Enter a valid number' },
    bankAccountNumber: { pattern: 'Enter a valid number' },
    ibanNumber: { pattern: 'Enter a valid number' },
    swiftNumber: { pattern: 'Enter a valid number' },
    sortCode: { mask: 'Enter a valid code' },
    phoneNumber: { mask: 'Enter a valid phone number' },
  };

  vm.documents = [];

  vm.getErrors = fieldName => customErrorTexts[fieldName];

  vm.getFileIcon = document => `#file-type-${document.fileExtension}`;

  vm.$onInit = function() {
    loadSettings();
    loadDocuments();
  };

  const loadSettings = () => {
    return SecurityProviderSetting.getCurrent()
      .then(settings => vm.settings = settings)
      .catch(error => Rollbar.log(`Settings get on ${window.location.href}`, error));
  };

  const loadDocuments = () => {
    return SecurityProviderDocument.query({}, {
      securityProviderId: currentUserService.getCurrentProfile().securityProvider.id
    }).then(documents => vm.documents = documents);
  };

  return vm;

};

angular
  .module('public.client-manager.company')
  .controller('ClientManagerCompanyDetailsController', dependencies.concat(ClientManagerCompanyDetailsController));
