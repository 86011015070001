/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.timeline').directive('gccsTimelineBookingFormTimeLine', () => {
  return {
    restrict: 'A',
    controller: 'TimelineBookingFormTimeLineController',
    controllerAs: 'timeLineCtrl',
    template: require('./template.pug'),
    bindToController: true,
    transclude: true,
    scope: {
      from: '<',
      to: '<',
      timeLineInteractor: '<'
    }
  };
});
