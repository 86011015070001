const securityManagerNotificationPreferenceType = function() {

  const DESKTOP = 1;
  const EMAIL = 2;
  const URGENT = 3;

  return {
    getDesktop() {
      return DESKTOP;
    },

    getEmail() {
      return EMAIL;
    },

    getUrgent() {
      return URGENT;
    }
  };
};

angular.module('data.security-manager')
  .service('securityManagerNotificationPreferenceType', securityManagerNotificationPreferenceType);
