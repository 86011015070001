const dependencies = [];

const BookingTimelineShiftSelector = function() {

  return class BookingTimelineShiftSelector {

    constructor() {
      this.employeeSidebarService = null;
      this.selectedDShift = null;
      this.selectedDShiftGroup = [];
      this._updateMultipleSelectionState();
    }

    setTimelineLoader(timeLineLoader) {
      this.timeLineLoader = timeLineLoader;
    }

    setEmployeeSidebarService(employeeSidebarService) {
      this.employeeSidebarService = employeeSidebarService;
    }

    setShiftStatisticsService(shiftStatistics) {
      this.shiftStatistics = shiftStatistics;
    }

    selectShift(dShift) {
      if (this.isMultipleSelected) { return; }
      this.selectedDShift = dShift;
      if (this.employeeSidebarService) {
        this.employeeSidebarService.selectShifts(this.selectedDShift);
      }
    }

    deselectShift() {
      this.selectedDShift = null;
      if (!this.isMultipleSelected && this.employeeSidebarService) {
        this.employeeSidebarService.deselectShifts();
      }
    }

    shiftReloaded(dShift) {
      if (this.employeeSidebarService) {
        this.employeeSidebarService.shiftReloaded(dShift);
      }
    }

    // return bool
    multipleSelectShift(dShift) {
      const isSameClient = this._checkClientId(dShift);
      if (!isSameClient) { return false; }
      this.selectedDShiftGroup.push(dShift);
      this._updateMultipleSelectionState();
      if (this.employeeSidebarService) {
        this.employeeSidebarService.selectShifts(this.selectedDShiftGroup);
      }
      return true;
    }

    multipleDeselectShift(dShift) {
      _.pull(this.selectedDShiftGroup, dShift);
      this._updateMultipleSelectionState();
      this.deselectShift();
      if (this.employeeSidebarService) {
        if (this.selectedDShiftGroup.length === 0) {
          this.employeeSidebarService.searchEmployees();
        } else {
          this.employeeSidebarService.selectShifts(this.selectedDShiftGroup);
        }
      }
    }

    multipleDeselectAll() {
      for (let shift of Array.from(this.selectedDShiftGroup)) {
        shift.isMultipleSelected = false;
      }
      this.selectedDShiftGroup = [];
      this._updateMultipleSelectionState();
      if (this.employeeSidebarService) {
        this.employeeSidebarService.deselectShifts();
      }
    }

    getClientId() {
      return this.clientId;
    }

    hasSelectedShifts() {
      return this.selectedDShiftGroup.length > 0;
    }

    _updateMultipleSelectionState() {
      this._updateIsMultipleSelected();
      this._updateClientId();
    }

    _updateIsMultipleSelected() {
      this.isMultipleSelected = this.selectedDShiftGroup.length > 0;
    }

    _checkClientId(dShift) {
      const clientId = dShift.clientId || dShift.shift.clientId;
      if (this.clientId) {
        return this.clientId === clientId;
      } else {
        this.clientId = clientId;
        return true;
      }
    }

    _updateClientId() {
      if (this.selectedDShiftGroup.length === 0) {
        this.clientId = null;
      }
    }
  }

};

angular
  .module('public.timeline')
  .factory('BookingTimelineShiftSelector', dependencies.concat(BookingTimelineShiftSelector));
