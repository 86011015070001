const gccsSecurityManagerNotificationsPageNotification = {
  template: require('./template.pug'),
  controller: 'SecurityManagerNotificationController',
  bindings: {
    notification: '<',
    toggleReadCb: '&'
  }
};

angular.module('public.security-manager.notifications').component('gccsSecurityManagerNotificationsPageNotification',
  gccsSecurityManagerNotificationsPageNotification);
