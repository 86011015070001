/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'SecurityProviderSystemPackage', 'currentUserService', 'packageTypeService', 'packageDescriptionService',
  'systemPackagePopupService'
];

const PopupSystemPackageFormController = function(
  $scope, SecurityProviderSystemPackage, currentUserService, packageTypeService, packageDescriptionService,
  systemPackagePopupService
) {

  const vm = this;

  $scope.popupFormCtrl.title = 'Choose your subscription';
  $scope.popupFormCtrl.sign = 'all pricing excluding VAT';
  $scope.popupFormCtrl.withoutCancelButton = true;
  $scope.popupFormCtrl.popupWidth = 432;

  vm.packageDescriptionService = packageDescriptionService;
  vm.packageDescriptions = packageDescriptionService.getAllDescriptions();
  vm.systemPackagePopupService = systemPackagePopupService;

  vm.isSelectedPackage = function(packageType) {
    return vm.selectedPackageType === packageType;
  };

  vm.selectPackage = function(packageType) {
    vm.selectedPackageType = packageType;
    $scope.popupFormCtrl.saveButtonText = `Continue with ${getPackageName(packageType)}`;
  };

  vm.isCurrentPackage = function(packageType) {
    return packageType.type === vm.currentPackageType;
  };

  const getPackageName = function(packageType) {
    return packageDescriptionService.getDescriptionFor(packageType).title;
  };

  const initInitialPackageType = function() {
    if (packageTypeService.hasProPackage()) {
      vm.currentPackageType = packageTypeService.getProPackage();
    } else if (packageTypeService.hasBasicPackage()) {
      vm.currentPackageType = packageTypeService.getBasicPackage();
    } else {
      vm.currentPackageType = packageTypeService.getFreePackage();
    }
    vm.selectPackage(vm.currentPackageType);
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) { initInitialPackageType(); }
  });

  $scope.$on('popup.submit', () => {
    systemPackagePopupService.onSubmitPackageType(vm.selectedPackageType, $scope.popupCtrl.closePopup);
  });

  return vm;

};

angular.module('popup.system-package')
  .controller('PopupSystemPackageFormController', dependencies.concat(PopupSystemPackageFormController));
