/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('popup.repeatable-booking').directive('gccsPopupRepeatableBookingRuleStartFrom', [
  'popupDirectiveConfig',
  popupDirectiveConfig =>
    popupDirectiveConfig.configure({
      bodyTemplate: require('./template.pug'),
      scope: {
        repeatableBooking: '<',
        chosenDate: '<'
      }
    })
]);
