/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$state', '$stateParams', 'EmployeeInvoice', 'pluralizeService', 'dateService', 'currentUserService',
  'EmployeeInvoiceVersion', 'employeeInvoiceStatus', 'employeeInvoiceRedirectService', 'employeeInvoiceLabelService'
];

const InvoiceEmployeeHistoryController = function(
  $state, $stateParams, EmployeeInvoice, pluralizeService, dateService, currentUserService,
  EmployeeInvoiceVersion, employeeInvoiceStatus, employeeInvoiceRedirectService, employeeInvoiceLabelService
) {

  const vm = this;

  vm.employeeInvoiceStatus = employeeInvoiceStatus;

  vm.currentVersionIndex = 0;
  vm.isSecurityManagerLogged = currentUserService.isSecurityManagerLogged();

  vm.$onInit = function() {
    vm.employeeInvoiceReviewPage = vm.isSecurityManagerLogged && !!$stateParams.invoiceReview;
    vm.employeeInvoicePreviewPage = vm.isSecurityManagerLogged && !!$stateParams.invoicePreview;
    loadInvoice();
    vm.editable = true;
  };

  vm.goBack = function() {
    if (window.history.length > 1) {
      return window.history.back();
    } else if (vm.invoice) {
      return employeeInvoiceRedirectService.gotoEmployeeInvoiceList({employeeId : vm.invoice.employeeId});
    }
  };

  vm.chooseVersion = index => vm.currentVersionIndex = index;

  vm.approveInvoice = () => {
    return approveInvoice()
      .then(() => {
        return employeeInvoiceRedirectService.gotoEmployeeInvoiceList({
          toAllList: true,
          forInvoice: vm.invoice
        });
      })
      .catch(logError);
  };

  vm.goToInvoiceForm = () => {
    $state.go('public.employee-invoice-form', {
      invoiceId: vm.invoice.id,
      allList: $stateParams.allList
    });
  };

  vm.goToInvoiceCorrectForm = () => {
    $state.go('public.employee-invoice-form', {
      invoiceId: vm.invoice.id,
      preselectedStatus: vm.employeeInvoiceStatus.getApprovedStatus()
    });
  };

  vm.isShownReviewMessage = () => vm.employeeInvoiceReviewPage && (_.first(vm.invoiceVersions).status === vm.employeeInvoiceStatus.getSentStatus());

  vm.isShownButton = () => {
    return vm.editable && vm.invoice && !vm.invoice.isInPayroll && (
      vm.employeeInvoicePreviewPage ||
      (vm.employeeInvoiceReviewPage && (__guard__(_.first(vm.invoiceVersions), x => x.status) !== vm.employeeInvoiceStatus.getSentStatus()))
    );
  };

  vm.getTotalText = () => pluralizeService.simple(vm.invoice.versions.length, 'version');

  vm.isCurrentVersion = index => index === vm.currentVersionIndex;

  vm.getFormattedCreatedAt = function(version) {
    if (!version.createdAtMoment) {
      version.createdAtMoment = moment(version.createdAt, dateService.getIsoFullDateFormatWithTz());
    }
    return version.createdAtMoment.format('MMM DD, YYYY, HH:mm');
  };

  vm.getCreatorFullName = version => [version.creator.firstName, version.creator.lastName].join(' ');

  vm.getCompareWith = () => vm.invoiceVersions[vm.currentVersionIndex + 1];

  const loadInvoice = function() {
    const params = { all_versions: true };
    return EmployeeInvoice.get($stateParams.invoiceId, params).then(function(invoice) {
      vm.invoice = invoice;
      vm.invoiceVersions = _.orderBy(vm.invoice.versions, 'id', 'desc');
      initEditData();
      vm.currentVersionIndex = _.findIndex(vm.invoiceVersions, invoiceVersion => invoiceVersion.id === ($stateParams.invoiceVersionId - 0));
    });
  };

  const initEditData = function() {
    const employeesLabels = employeeInvoiceLabelService.getEmployeesTexts(vm.invoice.employmentType);
    vm.title = getTitle(employeesLabels.capStr);
    vm.buttonText = getButtonText(_.first(vm.invoiceVersions), employeesLabels.singular);
  };

  const getTitle = function(entityType) {
    if (vm.employeeInvoiceReviewPage) {
      return 'Invoice created by employee';
    } else if (vm.employeeInvoicePreviewPage) {
      return `Preview ${entityType.toLowerCase()}`;
    } else {
      return `${entityType} history`;
    }
  };

  const getButtonText = function(lastVersion, entityType) {
    if (lastVersion.status === vm.employeeInvoiceStatus.getSentStatus()) {
      return `Correct ${entityType}`;
    } else {
      return `Edit ${entityType}`;
    }
  };

  const setInvoiceStatus = status => {
    return new EmployeeInvoiceVersion({
      invoiceId: vm.invoice.id,
      id: getCurrentVersion().id,
      status
    }).save();
  };

  const getCurrentVersion = () => vm.invoiceVersions[vm.currentVersionIndex];

  const approveInvoice = () => setInvoiceStatus(vm.employeeInvoiceStatus.getApprovedStatus());

  const logError = error => Rollbar.log('EmployeeInvoiceVersion status change', error);

  return vm;

};

angular.module('public.invoice.employee').controller('InvoiceEmployeeHistoryController',
  dependencies.concat(InvoiceEmployeeHistoryController));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
