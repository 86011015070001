/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const profileType = function() {

  const SECURITY_MANAGER = 1;
  const CLIENT_MANAGER = 2;
  const EMPLOYEE = 3;
  const TEMPORARY = 4;

  return {
    isSecurityManager(session) {
      return (session != null ? session.profileType : undefined) === SECURITY_MANAGER;
    },

    isClientManager(session) {
      return (session != null ? session.profileType : undefined) === CLIENT_MANAGER;
    },

    isEmployee(session) {
      return (session != null ? session.profileType : undefined) === EMPLOYEE;
    },

    isTemporary(session) {
      return (session != null ? session.profileType : undefined) === TEMPORARY;
    },

    getSecurityManager() {
      return SECURITY_MANAGER;
    },

    getClientManager() {
      return CLIENT_MANAGER;
    },

    getEmployee() {
      return EMPLOYEE;
    },

    getTemporary() {
      return TEMPORARY;
    }
  };
};

angular.module('data.user').service('profileType', profileType);
