/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').service('bookingRepeatFrequency', function() {

  const FREQUENCY_DAILY = 1;
  const FREQUENCY_WEEKLY = 2;

  const names = {};
  names[FREQUENCY_DAILY] = 'Daily';
  names[FREQUENCY_WEEKLY] = 'Weekly';

  const everyLabels = {};
  everyLabels[FREQUENCY_DAILY] = 'days';
  everyLabels[FREQUENCY_WEEKLY] = 'weeks';

  return {
    getNames() {
      return names;
    },

    getName(type) {
      return names[type];
    },

    getEveryLabel(type) {
      return everyLabels[type];
    },

    isDaily(type) {
      return type === FREQUENCY_DAILY;
    },

    isWeekly(type) {
      return type === FREQUENCY_WEEKLY;
    }
  };
});