/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.repeatable-booking').factory('RepeatableBookingComment', ['AppModel', (AppModel) => {

  class RepeatableBookingComment extends AppModel {
    static initClass() {
      this.configure({
        url: '/repeatable_bookings/{{ repeatableBookingId }}/comments/{{ id }}',
        name: 'comment'
      });
    }
  }

  RepeatableBookingComment.initClass();

  return RepeatableBookingComment;

}]);
