/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['$q', 'employeeStatus', 'clientStatus'];

const SystemPackageDowngrade = function($q, employeeStatus, clientStatus) {

  class SystemPackageDowngrade {

    constructor() {
      this.step = 1;
      this.store = {};
      this.clientId = null;
      this.licensesNumber = null;
      this.showPopupsCallbaks = [];
    }

    getStep() {
      return this.step;
    }

    incrementStep() {
      ++this.step;
    }

    decrementStep() {
      --this.step;
    }

    addToStore(key, collection) {
      this.store[key] = angular.copy(collection);
    }

    isInStore(key) {
      return !!this.store[key];
    }

    getFromStore(key) {
      return this.store[key];
    }

    getClientId() {
      return this.clientId;
    }

    setClientId(id) {
      this.clientId = id;
    }

    getLicensesNumber() {
      return this.licensesNumber;
    }

    setLicensesNumber(value) {
      this.licensesNumber = value;
    }

    addCallback(cb) {
      this.showPopupsCallbaks.push(cb);
    }

    goBack(packageType, popupCloseCb) {
      if (this.showPopupsCallbaks.length) {
        this.decrementStep();
        (this.showPopupsCallbaks.pop())(packageType, popupCloseCb);
      }
    }

    deactivateEmployees() {
      const promises = [];
      if (this.store.employees) {
        this.store.employees.forEach(function(employee) {
          if (!employee.isSelected) {
            employee.status = employeeStatus.getInactiveStatus();
            promises.push(employee.save());
          }
        });
      }
      return promises;
    }

    deactivateClients() {
      const promises = [];
      if (this.store.clients) {
        this.store.clients.forEach(function(client) {
          if (!client.isSelected) {
            client.status = clientStatus.getInactiveStatus();
            promises.push(client.save());
          }
        });
      }
      return promises;
    }

    deleteLocations() {
      const promises = [];
      if (this.store.locations) {
        this.store.locations.forEach(function(location) {
          if (!location.isSelected) {
            promises.push(location.delete());
          }
        });
      }
      return promises;
    }

    deactivateRestForFreePackage() {
      if (!_.isEmpty(this.store)) {
        return $q.all(_.concat(
          this.deactivateEmployees(),
          this.deactivateClients(),
          this.deleteLocations()
        ));
      } else {
        return $q.when();
      }
    }

  }

  return SystemPackageDowngrade;

};

angular.module('public').factory('SystemPackageDowngrade', dependencies.concat(SystemPackageDowngrade));
