/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.booking').directive('gccsPopupBookingShiftFormActivityLog', () => {
  return {
    restrict: 'A',
    controller: 'PopupBookingShiftFormActivityLogController',
    controllerAs: 'shiftFromActivityLogCtrl',
    template: require('./template.pug'),
    scope: {
      shift: '<'
    }
  };
});
