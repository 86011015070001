/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', '$q', 'EmployeeLicense', 'EntityManager', 'uploadService', 'dateService'];

const EmployeeRegistrationLicensesController = function($scope, $q, EmployeeLicense, EntityManager, uploadService, dateService) {

  const vm = this;

  const entityManager = new EntityManager(EmployeeLicense, {
    params : {
      employeeId : $scope.employeeRegistrationLayoutCtrl.employeeId
    },
    withBlank : true
  }
  );

  vm.expireMinDate = dateService.today();

  vm.addNewLicense = entityManager.addNewEntity;
  vm.removeLicense = entityManager.removeEntity;

  vm.getLicenses = () => entityManager.entities;

  entityManager.loadAll();

  $scope.$on('employee.registration.submit', function() {
    const licensePromises = _.map(vm.getLicenses(), license => uploadService.saveWithFile(license, 'file'));
    return $q.all(licensePromises).then(() => $scope.employeeRegistrationLayoutCtrl.goNext());
  });

  return vm;
};

angular.module('public.security-manager.employee.registration').controller('EmployeeRegistrationLicensesController',
  dependencies.concat(EmployeeRegistrationLicensesController));
