const gccsEmployeeTimelineShiftModalComments = {
  controller: 'EmployeeTimelineShiftModalCommentsController',
  template: require('./template.pug'),
  bindings: {
    isShownConfirmPopup: '=',
    timelineItem: '<'
  }
};

angular.module('public.employee.timeline')
  .component('gccsEmployeeTimelineShiftModalComments', gccsEmployeeTimelineShiftModalComments);
