/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', '$location', 'EmployeeDocument', 'EntityManager', 'FilterParamsService', 'dateService',
  'employeeDocumentType', 'documentTypeIconService', 'documentTypeType', 'downloadService'
];

const EmployeeShowDocumentsController = function(
  $scope, $location, EmployeeDocument, EntityManager, FilterParamsService, dateService,
  employeeDocumentType, documentTypeIconService, documentTypeType, downloadService
) {

  const vm = this;

  vm.isShownDocumentPopup = false;
  vm.isShownViewPopup = false;
  vm.documentTypeIcon = documentTypeIconService;
  vm.documentTypeType = documentTypeType;

  const entityManager = new EntityManager(EmployeeDocument, {
    params: {
      employeeId: $scope.employeeShowLayoutCtrl.employeeId
    }
  });
  const filterParamsService = new FilterParamsService({
    order: ['name']
  });

  $scope.employeeShowLayoutCtrl.loadEmployee().then(employee => vm.employee = employee);

  vm.filterParams = filterParamsService.getParams();

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  vm.removeDocument = (document, onSuccessCb) => {
    return entityManager.removeEntity(document, function () {
      if (onSuccessCb) {
        onSuccessCb();
      }
      return vm.employee.documentsCount--;
    });
  };

  vm.loadMoreDocuments = () => entityManager.loadMore();

  vm.getDocuments = () => entityManager.entities;

  vm.showDocumentPopup = function(document) {
    vm.popupDocument = document || entityManager.createEntity();
    return vm.isShownDocumentPopup = true;
  };

  vm.closeDocumentPopup = function(savedDocument) {
    vm.isShownDocumentPopup = false;
    if (savedDocument) {
      vm.employee.documentsCount++;
      return filterDocuments();
    }
  };

  vm.showViewPopup = function(document) {
    vm.popupViewDocument = document;
    return vm.isShownViewPopup = true;
  };

  vm.closeViewPopup = () => vm.isShownViewPopup = false;

  vm.getFileIcon = document => `#file-type-${document.fileExtension}`;

  vm.getDocumentTypeIcon = document => employeeDocumentType.getIcon(document.type);

  vm.isExpiredDocument = function(document) {
    if (document.expireDate) {
      return dateService.today().isAfter(moment(document.expireDate, dateService.getIsoDateFormat()));
    } else {
      return false;
    }
  };

  vm.downloadDocument = function(document) {
    downloadService.downloadWithoutCache(document.file, document.fileName);
  };

  const filterDocuments = function() {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    $location.search(urlParams);
    return entityManager.load(urlParams);
  };

  $scope.$watch(() => vm.filterParams, filterDocuments, true);

  return vm;
};

angular.module('public.security-manager.employee')
  .controller('EmployeeShowDocumentsController', dependencies.concat(EmployeeShowDocumentsController));
