const dependencies = [
  'EmployeeTimelineAvailabilityDecorator', 'SickDayDecorator', 'bookingShiftEmployeeAssignStatus',
  'employeeType', 'dateService', 'bookingShiftUnpaidBreak', 'defaultConfig'
];

const EmployeeTimelineShiftDetailsController = function(
  EmployeeTimelineAvailabilityDecorator, SickDayDecorator, bookingShiftEmployeeAssignStatus,
  employeeType, dateService, bookingShiftUnpaidBreak, defaultConfig
) {

  const vm = this;

  vm.$onInit = function() {
    vm.modalTitle = getModalTitle(vm.timelineItem);
    initInfoItemList();
    if (vm.isShift()) {
      initLocationMap();
    }
  };

  vm.isShift = function () {
    return vm.timelineItem.shiftEmployee;
  };

  const getModalTitle = function (item) {
    return item instanceof EmployeeTimelineAvailabilityDecorator ? 'Availability' : item.name;
  };

  const initInfoItemList = function() {
    if (vm.timelineItem.isDayOff) {
      initDayOffInfo();
    } else if (vm.timelineItem instanceof SickDayDecorator) {
      initSickDayInfo();
    } else if (vm.timelineItem instanceof EmployeeTimelineAvailabilityDecorator) {
      initAvailabilityInfo();
    } else {
      initShiftEmployeeInfo();
    }
  };

  const initDayOffInfo = function() {
    vm.infoItemList = [{
      title: 'Status:',
      text: getDayOffStatus(),
      class: getDayOffClass()
    }, {
      title: 'Employer:',
      text: getEmployerName()
    }, {
      title: 'Start day:',
      text: getStartTime()
    }, {
      title: 'End day:',
      text: getEndTime()
    }];
    if (isEmployed()) {
      vm.infoItemList.push({ title: 'Paid days:', text: +vm.timelineItem.paidDays });
    }
  };

  const initSickDayInfo = function() {
    vm.infoItemList = [{
      title: 'Status:',
      text: getSickDayStatus(),
      class: getSickDayClass()
    }, {
      title: 'Employer:',
      text: getEmployerName()
    }, {
      title: 'Start day:',
      text: getStartTime()
    }, {
      title: 'End day:',
      text: getEndTime()
    }];
  };

  const initAvailabilityInfo = function() {
    vm.infoItemList = [{
      title: 'Employer:',
      text: getEmployerName()
    }, {
      title: 'Start day:',
      text: getStartTime()
    }, {
      title: 'End day:',
      text: getEndTime()
    }];
  };

  const initShiftEmployeeInfo = function() {
    return vm.infoItemList = [{
      title: 'Status:',
      text: getShiftStatus(),
      class: bookingShiftEmployeeAssignStatus.isBlowOut(vm.timelineItem.shiftEmployee.assignStatus) ? 'mod-red' : ''
    }, {
      title: 'Employer:',
      text: getEmployerName()
    }, {
      title: 'Start day:',
      text: getStartTime()
    }, {
      title: 'End day:',
      text: getEndTime()
    }, {
      title: 'Clock in/out:',
      text: getClockIn(),
      class: getClockInClass()
    }, {
      title: 'Unpaid break',
      text: getBreakName(vm.timelineItem.shift.unpaidBreakInMinutes)
    }, {
      title: 'Uniform Equipment',
      text: getEquipment()
    }, {
      title: 'Uncharged break',
      text: getBreakName(vm.timelineItem.shift.unchargedBreakInMinutes)
    }, {
      title: 'Pay rate',
      text: getPayRate()
    }];
  };

  const initLocationMap = function() {
    vm.googleMapSrc = getGoogleMapSrc(vm.timelineItem.shift.booking.location);
    vm.locationGoogleMapLink = {
      label: vm.timelineItem.shift.booking.location.address,
      href: getGoogleMapHref(vm.timelineItem.shift.booking.location)
    };
  };

  const getGoogleMapSrc = function({ address, lat, lng }) {
    let center;
    if (lat && lng) {
      center = `${lat},${lng}`;
    } else if (address.length > 0) {
      center = address;
    }
    return `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=14&size=225x120&markers=color:blue%7C${center}&key=${defaultConfig.googleApiKey}`;
  };

  const getGoogleMapHref = function({ address, lat, lng }) {
    const linkPrefix = 'https://www.google.com/maps/search/?api=1&query=';
    if (address) {
      return `${linkPrefix}${address}`;
    } else {
      return `${linkPrefix}${lat},${lng}`;
    }
  };

  const getDayOffStatus = () => vm.timelineItem.getDayOffRequestStatusEmployeeTimeline().text;

  const getDayOffClass = () => vm.timelineItem.getDayOffRequestStatusEmployeeTimeline().class;

  const getSickDayStatus = () => vm.timelineItem.getSickDayRequestStatusEmployeeTimeline().text;

  const getSickDayClass = () => vm.timelineItem.getSickDayRequestStatusEmployeeTimeline().class;

  const getShiftStatus = () => bookingShiftEmployeeAssignStatus.getName(vm.timelineItem.shiftEmployee.assignStatus);

  const getStartTime = () => vm.timelineItem.timelineStartTime.format(dateService.getFullDateFormat());

  const getEndTime = () => vm.timelineItem.timelineEndTime.format(dateService.getFullDateFormat());

  const getClockIn = () => vm.timelineItem.shift.enableClockIn ? 'Enabled' : 'Disabled';

  const getClockInClass = () => vm.timelineItem.shift.enableClockIn ? '' : 'mod-disabled';

  const getBreakName = breakInMinutes => bookingShiftUnpaidBreak.getNameOfMinutes(breakInMinutes);

  const getEquipment = () => vm.timelineItem.shift.equipment || 'No';

  const getPayRate = () => `£${+vm.timelineItem.shiftEmployee.payRate}`;

  const getEmployerName = function () {
    return vm.timelineItem.employee.securityProvider.name;
  };

  const isEmployed = () => employeeType.isEmployed(vm.timelineItem.employee.employmentType);

  return vm;

};

angular.module('public.employee.timeline')
  .controller('EmployeeTimelineShiftDetailsController', dependencies.concat(EmployeeTimelineShiftDetailsController));
