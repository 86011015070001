/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$timeout', 'DocumentType', 'DocumentTypeIcon', 'uploadService',
  'documentTypeType', 'documentTypeIconService'
];

const PopupDocumentFormController = function(
  $scope, $timeout, DocumentType, DocumentTypeIcon, uploadService,
  documentTypeType, documentTypeIconService
) {

  const vm = this;

  $scope.popupFormCtrl.isShortPopup = true;

  vm.documentTypeIconService = documentTypeIconService;

  vm.selectDocumentType = function (documentType) {
    vm.document.documentTypeId = documentType.id;
    vm.selectedDocumentType = documentType;
  };

  vm.toggleDocumentTypeSelect = function () {
    vm.isShownDocumentTypeDropdown = !vm.isShownDocumentTypeDropdown;
  };

  vm.closeDocumentTypeSelect = function () {
    vm.isShownDocumentTypeDropdown = false;
  };

  vm.addNewType = function() {
    vm.isAddingNewType = true;
    vm.documentType = new DocumentType({ type: $scope.documentTypeType });
  };

  vm.cancelAddNewType = function() {
    vm.isAddingNewType = false;
    vm.documentType = null;
  };

  vm.toggleIconsDropdown = () => vm.isShownDocumentIconsDropdown = !vm.isShownDocumentIconsDropdown;

  vm.hideIconsDropdown = () => vm.isShownDocumentIconsDropdown = false;

  vm.onIconUpload = function(documentType, $file) {
    const documentIcon = new DocumentTypeIcon({ iconType: documentTypeIconService.getUploadedType() });
    documentIcon.save().then(function(savedDocumentTypeIcon) {
      savedDocumentTypeIcon.icon = $file;
      uploadService.updateFile(savedDocumentTypeIcon, function(updatedIcon) {
        loadDocumentTypeIcons();
        documentType.icon = new DocumentTypeIcon(updatedIcon);
        documentType.iconId = updatedIcon.id;
        vm.uploadedIcon = null;
      });
    });
  };

  const initDocument = function() {
    vm.cancelAddNewType();
    vm.isShownDocumentTypeDropdown = false;
    if ($scope.document.isNew()) {
      vm.document = $scope.document;
      $scope.popupFormCtrl.title = 'Add new document';
      $scope.popupFormCtrl.saveButtonText = 'Add';
    } else {
      vm.document = angular.copy($scope.document);
      $scope.popupFormCtrl.title = 'Edit document';
      $scope.popupFormCtrl.saveButtonText = 'Save';
    }
  };

  const initDefaultValues = function () {
    vm.selectedDocumentTypes = [];
    vm.documentTypeIcons = [];
    vm.isShownDocumentTypeDropdown = false;
    vm.isAddingNewType = false;
    vm.isShownDocumentIconsDropdown = false;
  };

  const saveDocument = document => {
    return $scope.popupCtrl.closePopup(() => $scope.popupCloseCb({ document }));
  };

  const loadDocumentTypes = function() {
    return DocumentType.query({ type: $scope.documentTypeType }).then(function(documentTypes) {
      vm.selectedDocumentTypes = [{
        id: null,
        name: 'No type'
      }];
      vm.selectedDocumentTypes = _.concat(vm.selectedDocumentTypes, documentTypes);
    });
  };

  const loadDocumentTypeIcons = function() {
    return DocumentTypeIcon.query({ type: $scope.documentTypeType }).then(function(documentTypeIcons) {
      vm.documentTypeIcons = documentTypeIcons;
    });
  };

  const initDocumentType = function() {
    if (vm.document.documentTypeId) {
      vm.selectedDocumentType = _.find(vm.selectedDocumentTypes, function(documentType) {
        return documentType.id === vm.document.documentTypeId;
      });
    } else {
      vm.selectedDocumentType = _.first(vm.selectedDocumentTypes);
    }
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      initDefaultValues();
      initDocument();
      loadDocumentTypes().then(() => {
        initDocumentType();
      });
      loadDocumentTypeIcons();
    }
  });

  $scope.$on('popup.submit', () => {
    if (vm.documentType) {
      vm.documentType.iconId = vm.documentType.icon.id;
      vm.documentType.type = $scope.documentTypeType;
      vm.documentType.save().then(function(documentType) {
        loadDocumentTypes();
        vm.document.documentTypeId = documentType.id;
        uploadService.saveWithFile(vm.document, 'file', saveDocument);
      });
    } else {
      uploadService.saveWithFile(vm.document, 'file', saveDocument);
    }
  });

  return vm;

};

angular.module('popup.document')
  .controller('PopupDocumentFormController', dependencies.concat(PopupDocumentFormController));
