/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'Booking', 'BookingSeries', 'BookingShift', 'ClientInvoice', 'bookingSummaryService',
  'cableService', 'payRateVisibilityService', 'invoiceStatus', 'calculationService', 'currentUserService'
];

const PopupBookingInfoSummaryController = function(
  $scope, Booking, BookingSeries, BookingShift, ClientInvoice, bookingSummaryService,
  cableService, payRateVisibilityService, invoiceStatus, calculationService, currentUserService
) {

  const vm = this;

  vm.getInvoiceStatusName = () => invoiceStatus.getName(vm.bookingInvoiceVersion.status);

  vm.isSentInvoice = () => invoiceStatus.isSent(vm.bookingInvoiceVersion.status);

  vm.isPaidInvoice = () => invoiceStatus.isPaid(vm.bookingInvoiceVersion.status);

  vm.$onInit = function() {
    loadBookingSummary();
    subscribeToBookingTimeline();
  };

  vm.pluralizeTotalHours = function() {
    if (vm.totalHours === 1) {
      return 'hour';
    } else {
      return 'hours';
    }
  };

  const loadBookingSummary = function() {
    vm.summary = [];
    return Booking.get($scope.bookingId).then(function(booking) {
      if (booking.isBlocked) { loadInvoiceData(booking.id); }
      vm.totalHours = calculationService.round(booking.totalHours, 2);
      BookingShift.query({}, { bookingId : $scope.bookingId }).then(function(shifts) {
        const shiftLocations = [];
        _.each(shifts, function(shift) {
          if (shift.location && shiftLocations.indexOf(shift.location.name) === -1) { shiftLocations.push(shift.location.name); }
          let shiftSummary = bookingSummaryService.getShiftSummary(shift);
          shiftSummary.isTogglable = true;
          vm.summary.push(shiftSummary);
        });
        const bookingSummary = bookingSummaryService.getBookingSummary(booking, shiftLocations);
        vm.summary.unshift(bookingSummary);
      });
      if (payRateVisibilityService.isShownRates()) {
        Booking.totalCost($scope.bookingId).then(data => {
          vm.totalCost = data.totalCost;
          if (!currentUserService.isClientManagerLogged()) {
            vm.totalEmployeeCost = data.totalEmployeeCost;
          }
        });
      }
    });
  };

  const subscribeToBookingTimeline = () => {
    return cableService.getBookingTimelineChannel().addDefaultCallback(loadBookingSummary);
  };

  const loadInvoiceData = bookingId => {
    return ClientInvoice.getForBooking(bookingId).then(invoice => vm.bookingInvoiceVersion = invoice.lastVersion);
  };

  $scope.$on('$destroy', () => cableService.getBookingTimelineChannel().removeDefaultCallback(loadBookingSummary));

  return vm;

};

angular.module('popup.booking')
  .controller('PopupBookingInfoSummaryController', dependencies.concat(PopupBookingInfoSummaryController));
