angular.module('popup.booking').directive('gccsPopupBookingShiftFormDetails', () => {
  return {
    restrict: 'A',
    controller: 'PopupBookingShiftFormDetailsController',
    controllerAs: 'shiftFormDetailsCtrl',
    template: require('./template.pug'),
    scope: {
      shift: '<',
      clientId: '<',
      availableDateRange: '<',
      onFormSubmit: '&',
      onRemove: '&',
      editable: '<',
      disableRates: '<',
      withoutRemove: '<'
    }
  };
});
