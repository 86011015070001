const gccsLiveFeedDetailsIssue = {
  controller: 'LiveFeedDetailsIssueController',
  template: require('./template.pug'),
  bindings: {
    shiftIssue: '<',
    dShiftIssue: '<',
    shift: '<',
    onOpenShiftIssuePopup: '&'
  }
};

angular.module('public.security-manager.schedule-manager.timeline')
  .component('gccsLiveFeedDetailsIssue', gccsLiveFeedDetailsIssue);
