/*
 * @author Igor Serpak <robobee>
 */
const gccsEmployeeHeaderNotificationsList = {
  controller: 'EmployeeHeaderNotificationsListController',
  controllerAs: 'notificationsCtrl',
  template: require('./template.pug'),
  bindings: {
    employee: '<'
  }
};

angular.module('public.employee').component('gccsEmployeeHeaderNotificationsList', gccsEmployeeHeaderNotificationsList);
