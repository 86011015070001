/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'Employee', 'downloadService'];

const PopupEmployeeUploadResultController = function($scope, Employee, downloadService) {

  const vm = this;

  const ERROR_WRONG_COLUMNS_COUNT = 1;

  vm.downloadFailFile = function () {
    downloadService.downloadBase64File(vm.importResult.fail_file);
  };

  vm.getSuccessText = function () {
    if (vm.importResult.success_count === 1) {
      return '1 employee was uploaded';
    } else {
      return `${vm.importResult.success_count} employees were uploaded`;
    }
  };

  vm.getFailText = function () {
    if (vm.importResult.fail_count === 1) {
      return '1 employee has problems';
    } else {
      return `${vm.importResult.fail_count} employees have problems`;
    }
  };

  vm.hasWrongColumnCountError = function () {
    return vm.importResult.error === ERROR_WRONG_COLUMNS_COUNT;
  };

  vm.getDownloadTemplateUrl = function() {
    return Employee.importTemplateUrl();
  };

  const onPopupClose = function() {
    if (vm.importResult.success_count) {
      reloadPage();
    }
  };

  const reloadPage = function() {
    window.location.reload();
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      vm.importResult = $scope.importResult;
    }
  });

  $scope.$on('popup.close', onPopupClose);

  return vm;
};

angular.module('popup.employee')
  .controller('PopupEmployeeUploadResultController', dependencies.concat(PopupEmployeeUploadResultController));
