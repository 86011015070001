angular.module('data.booking').service('bookingShiftCommentClockType', function() {

  const LATE_CLOCK_IN = 1;
  const MISSED_CLOCK_IN = 2;
  const EARLY_CLOCK_OUT = 3;
  const LATE_CLOCK_OUT = 4;
  const MISSED_CLOCK_OUT = 5;
  const MANUAL_CLOCK_IN = 6;
  const MANUAL_CLOCK_OUT = 7;

  const names = {
    [LATE_CLOCK_IN]: 'Late clock in',
    [MISSED_CLOCK_IN]: 'Missed clock in',
    [EARLY_CLOCK_OUT]: 'Early clock out',
    [LATE_CLOCK_OUT]: 'Late clock out',
    [MISSED_CLOCK_OUT]: 'Missed clock out',
    [MANUAL_CLOCK_IN]: 'Manual clock in',
    [MANUAL_CLOCK_OUT]: 'Manual clock out'
  };

  const TIME_ERROR_TYPES = [LATE_CLOCK_IN, EARLY_CLOCK_OUT, LATE_CLOCK_OUT];

  return {
    getNames() {
      return names;
    },

    getName(type) {
      return names[type];
    },

    isLateClockIn(type) {
      return type === LATE_CLOCK_IN;
    },

    isEarlyClockOut(type) {
      return type === EARLY_CLOCK_OUT;
    },

    isLateClockOut(type) {
      return type === LATE_CLOCK_OUT;
    },

    isTimeErrorType(type) {
      return TIME_ERROR_TYPES.includes(type);
    },
  };
});