const gccsLiveFeedList = {
  controller: 'LiveFeedListController',
  template: require('./template.pug'),
  bindings: {
    loader: '<',
    shiftSelector: '<'
  }
};

angular.module('public.security-manager.schedule-manager.timeline').component('gccsLiveFeedList', gccsLiveFeedList);
