/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = [
  'Client', 'FilterCategory', 'ExtendedFilterService', 'employeeGender', 'employeeType'
];

var ExtendedFilterEmailService = function(
  Client, FilterCategory, ExtendedFilterService, employeeGender, employeeType
) {

  return (ExtendedFilterEmailService = class ExtendedFilterEmailService extends ExtendedFilterService {

    _categories(filterData) {
      return [
        {
          label: 'Client',
          params: 'client_ids[]',
          top: true,
          type: 'radio',
          options: this._clientOptions(filterData),
          children: [
            {
              label: 'Choose a service',
              params: 'service_ids[]',
              type: 'radio',
              options: this._serviceOptions(filterData),
              children: [
                {
                  label: 'Choose resources',
                  params: 'resource_ids[]',
                  type: 'checkbox',
                  options: this._resourceOptions(filterData),
                  children: [
                    {
                      label: 'Choose trainings',
                      params: 'training_ids[]',
                      type: 'checkbox',
                      options: this._trainingOptions(filterData),
                      children: []
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          label: 'Employment type',
          params: 'employment_types[]',
          top: true,
          type: 'checkbox',
          options: this._employmentTypeOptions(),
          children: []
        },
        {
          label: 'Gender',
          params: 'genders[]',
          top: true,
          type: 'checkbox',
          options: this._genderOptions(),
          children: []
        }
      ];
    }

//    _genderOptions : () ->
//      names = employeeGender.getNames()
//      result = []
//      for id, name of names
//        result.push(id : +id, label : name, tag : true)
//      result

    _employmentTypeOptions() {
      const names = employeeType.getNames();
      const result = [];
      for (let id in names) {
        const name = names[id];
        result.push({id : +id, label : name, tag : true});
      }
      return result;
    }
  });
};

angular.module('public.security-manager.schedule-manager.timeline')
  .service('ExtendedFilterEmailService', dependencies.concat(ExtendedFilterEmailService));
