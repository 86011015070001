/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('public.invoice').directive('gccsInvoiceShiftComments', () =>
  ({
    restrict: 'A',
    controller: 'InvoiceShiftCommentsController',
    controllerAs: 'invoiceShiftCommentsCtrl',
    template: require('./template.pug'),
    bindToController: true,
    scope: {
      bookingId: '<',
      shiftId: '<',
      editable: '<',
      onCommentsCountChange: '&'
    }
  })
);
