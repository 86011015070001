/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [];

const fileSizeFormatter = function() {

  const THRESH = 1024;
  const UNITS = ['kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

  return {
    humanFileSize(bytes) {
      if (Math.abs(bytes) < THRESH) { return bytes + ' b'; }
      let unit = -1;
      while (true) {
        bytes /= THRESH;
        ++unit;
        if (!(Math.abs(bytes) >= THRESH) || !(unit < (UNITS.length - 1))) { break; }
      }
      return `${bytes.toFixed(1)} ${UNITS[unit]}`;
    }
  };
};

angular.module('shared').service('fileSizeFormatter', dependencies.concat(fileSizeFormatter));

