/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', '$timeout', 'Booking', 'dateService', 'notificationService'];

const PopupBookingSplitController = function($scope, $timeout, Booking, dateService, notificationService) {

  const vm = this;

  $scope.popupFormCtrl.title = 'Split booking';
  $scope.popupFormCtrl.saveButtonText = 'Save';
  $scope.popupFormCtrl.popupWidth = 700;

  vm.splitDates = [];
  vm.splitDatesBuilt = false;
  vm.maxDate = undefined;
  vm.minDate = undefined;
  vm.calendarFormat = dateService.getFullDateFormat();
  vm.customData = {
    getDayClasses(date) {
      return getDayClasses(date);
    }
  };

  let previousSplitDates = [];

  vm.getSplitDateRange = () => __range__(0, vm.splitDates.length / 2, false);

  vm.updateSplitDates = function(type, changedDate) {
    // i hate this method
    changedDate.endOf('day');
    return $timeout(function() {
      vm.splitDatesBuilt = false;
      const foundDate = _.find(previousSplitDates, splitDate => splitDate.isSame(changedDate, 'minute'));
      if (foundDate) {
        vm.splitDates = _.clone(previousSplitDates);
      } else {
        vm.splitDates = fixSplitDates();
        previousSplitDates = _.clone(vm.splitDates);
      }
      return $timeout(() => vm.splitDatesBuilt = true);
    });
  };

  vm.removeSplitDate = function(index) {
    vm.splitDatesBuilt = false;
    return $timeout(function() {
      if ((index + 1) < vm.getSplitDateRange().length) {
        vm.splitDates[2 * (index + 1)] = vm.splitDates[2 * index];
      } else {
        vm.splitDates[(2 * index) - 1] = vm.splitDates[(2 * index) + 1];
      }
      vm.splitDates.splice(2 * index, 2);
      previousSplitDates = getSplitMoments();
      return vm.splitDatesBuilt = true;
    });
  };

  const initSplitDates = function() {
    vm.minDate = moment($scope.booking.from, dateService.getIsoDateFormat());
    vm.maxDate = moment($scope.booking.to, dateService.getIsoDateFormat()).endOf('day');
    vm.splitDates = [vm.minDate.clone(), vm.maxDate.clone()];
    previousSplitDates = _.clone(vm.splitDates);
    return vm.splitDatesBuilt = true;
  };

  const getDayClasses = function(date) {
    if (!date.isSameOrAfter(vm.minDate) || !date.isSameOrBefore(vm.maxDate)) { return 'is-black-day'; }
  };

  const fixSplitDates = function() {
    const newSplitDates = getSplitMoments();
    const lastSplitDate = _.last(newSplitDates);
    if (!vm.maxDate.isSame(lastSplitDate, 'minute')) {
      newSplitDates.push(lastSplitDate.clone().add(1, 'day').startOf('day'));
      newSplitDates.push(vm.maxDate.clone());
    }
    for (let index of Array.from(vm.getSplitDateRange())) {
      const previousTo = newSplitDates[(2 * (index - 1)) + 1];
      if (previousTo) { newSplitDates[2 * index] = previousTo.clone().add(1, 'minute'); }
    }
    newSplitDates.sort((a, b) => +a.isAfter(b));
    return newSplitDates;
  };

  const getSplitMoments = () => _.map(vm.splitDates, splitDate => moment(splitDate, vm.calendarFormat));

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) { return initSplitDates(); }
  });

  $scope.$on('popup.submit', function() {
    if (vm.splitDates.length === 2) {
      notificationService.notifyError('Please, choose at least two periods');
      return $scope.popupFormCtrl.resetForm();
    } else {
      const splitDates = [];
      for (let index of Array.from(vm.getSplitDateRange())) {
        splitDates[index] = {
          from : vm.splitDates[2 * index],
          to : vm.splitDates[(2 * index) + 1]
        };
      }
      return new Booking({id : $scope.booking.id, splitDates}).split().then(() => $scope.popupCloseCb());
    }
  });

  return vm;
};

angular.module('popup.booking').controller('PopupBookingSplitController',
  dependencies.concat(PopupBookingSplitController));

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
