/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$state', 'currentUserService', 'packageTypeService', 'redirectService'];

const ReportsController = function($state, currentUserService, packageTypeService, redirectService) {

  const vm = this;

  vm.$onInit = function() {
    packageTypeService.checkProPackage();
    const profile = currentUserService.getCurrentProfile();
    if (profile.isSupervisor() || (profile.isManager() && !profile.hasAccessToReports)) {
      redirectService.redirectToMain();
    }
    vm.editable = true;
    initTabs();
  };

  vm.isActiveState = function (tab) {
    return $state.includes(tab.origin);
  };

  const initTabs = function() {
    vm.tabs = [{
      name: 'Finance',
      sref: 'public.security-manager.reports.finance.income',
      origin: 'public.security-manager.reports.finance'
    }, {
      name: 'Hr',
      sref: 'public.security-manager.reports.hr.licence',
      origin: 'public.security-manager.reports.hr'
    }];
  };

  return vm;

};

angular.module('public.security-manager.reports')
  .controller('ReportsController', dependencies.concat(ReportsController));
