/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['dateService', 'bookingShiftEmployeeAssignStatus'];

const PlanningTimelineEntity = function(dateService, bookingShiftEmployeeAssignStatus) {

  const ISO_FULL_DATE_FORMAT = dateService.getIsoFullDateFormat();

  class TimelineEntity {

    constructor() { // todo redo to instanceof check
      this.isDayOff = false;
      this.isPlaceholder = false;
      this.isShiftEmployee = false;
    }
  }

  return {
    DayOffEntity() {

      return class DayOffEntity extends TimelineEntity {

        constructor(dayOff) {
          super();
          this.type = 'dayOff';
          this.isDayOff = true;
          this.intersections = [];
          if (!dayOff) { return; }
          this.id = dayOff.id;
          this.employeeId = dayOff.employeeId;
          this.startTime = moment(dayOff.startTime, ISO_FULL_DATE_FORMAT);
          this.startTimeValue = this.startTime.valueOf();
          this.endTime = moment(dayOff.endTime, ISO_FULL_DATE_FORMAT);
          this.endTimeValue = this.endTime.valueOf();
          this.name = dayOff.name;
          this.hash = `${this.type}_${this.id}`;
        }

      };
    },

    PlaceholderEntity() {

      return class PlaceholderEntity extends TimelineEntity {

        constructor(dShift, rowId) {
          super();
          this.type = 'placeholder';
          this.isPlaceholder = true;
          this.intersections = [];
          this.shiftId = dShift.shift.id;
          this.shiftName = dShift.shift.name;
          this.startTime = dShift.startTimeMoment;
          this.startTimeValue = this.startTime.valueOf();
          this.endTime = dShift.endTimeMoment;
          this.endTimeValue = this.endTime.valueOf();
          this.employeeId = rowId;
          this.hash = `${this.type}_${this.shiftId}`;
        }

      };
    },

    ShiftEmployeeEntity() {

      return class ShiftEmployeeEntity extends TimelineEntity {

        constructor(shiftEmployee) {
          super();
          this.type = 'shiftEmployee';
          this.isShiftEmployee = true;
          this.intersections = [];
          if (!shiftEmployee) { return; }
          this.id = shiftEmployee.id;
          this.employeeId = shiftEmployee.employeeId;
          this.startTime = moment(shiftEmployee.startTime, ISO_FULL_DATE_FORMAT);
          this.startTimeValue = this.startTime.valueOf();
          this.endTime = moment(shiftEmployee.endTime, ISO_FULL_DATE_FORMAT);
          this.endTimeValue = this.endTime.valueOf();
          this.name = shiftEmployee.name;
          this.assignStatus = shiftEmployee.assignStatus;
          this.isInvite = bookingShiftEmployeeAssignStatus.countsAsInvited(this.assignStatus);
          this.isAssign = bookingShiftEmployeeAssignStatus.countsAsAssigned(this.assignStatus);
          this.shiftId = shiftEmployee.shiftId;
          this.updateHash();
        }

        set(params) {
          this.id = params.id;
          this.employeeId = params.employeeId;
          this.startTime = params.startTime;
          this.startTimeValue = this.startTime.valueOf();
          this.endTime = params.endTime;
          this.endTimeValue = this.endTime.valueOf();
          this.name = params.shiftName;
          this.assignStatus = params.assignStatus;
          this.isInvite = bookingShiftEmployeeAssignStatus.countsAsInvited(this.assignStatus);
          this.isAssign = bookingShiftEmployeeAssignStatus.countsAsAssigned(this.assignStatus);
          this.shiftId = params.shiftId;
          this.hasChanges = true;
          return this.updateHash();
        }

        updateHash() {
          return this.hash = `${this.type}_${this.id}_${this.shiftId}_${this.employeeId}`;
        }

      };
    }
  };
};

angular
  .module('public.security-manager.schedule-manager.timeline.planning')
  .factory('PlanningTimelineEntity', dependencies.concat(PlanningTimelineEntity));
