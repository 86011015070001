const dependencies = ['$scope', '$timeout', 'dateService', 'rateChangeType'];

const PopupResourceEditPayRateController = function($scope, $timeout, dateService, rateChangeType) {

  const vm = this;

  const DEFAULT_DELAY = 700;

  vm.isShownDateOfChange = false;
  vm.isShownHint = false;

  let isFormSubmitted = false;

  vm.closePopup = function() {
    vm.isShownDateOfChange = false;
    vm.gccsPopupsLayout.closePopup();
  };

  vm.saveRateChange = function() {
    if (isFormSubmitted || vm.isSaveButtonDisabled()) { return; }
    isFormSubmitted = true;
    vm.rateChangeCopy.save().then((rateChange) => {
      vm.isShownDateOfChange = false;
      vm.gccsPopupsLayout.closePopup({
        callbackData: {
          rateChange: rateChange
        }
      });
    });
  };

  vm.isSaveButtonDisabled = function() {
    return $scope.ResourceRateChangeForm.$invalid || !isPayRateChanged();
  };

  vm.closeHint = function() {
    vm.isShownHint = false;
  };

  vm.onApplyToBookingsChange = function() {
    if (!vm.rateChangeCopy.applyToBookings) {
      vm.minDateOfChange = dateService.today();
    } else {
      vm.minDateOfChange = undefined;
    }
    vm.isShownDateOfChange = false;
    $timeout(() => {
      vm.isShownDateOfChange = true;
    });
  };

  const isPayRateChanged = function() {
    if (!vm.rateChangeCopy) { return; }
    return vm.rateChange.payRate !== vm.rateChangeCopy.payRate;
  };

  const copyRateChange = function() {
    vm.rateChangeCopy = angular.copy(vm.rateChange);
  };

  const initHint = function() {
    $timeout(() => {
      vm.isShownHint = true;
    }, DEFAULT_DELAY);
  };

  $scope.$on('popup.open', function() {
    if (vm.gccsPopupsLayout.popupShowCondition) {
      copyRateChange();
      initHint();
      if (rateChangeType.isServicePayRateType(vm.rateChangeCopy.rateType)) {
        vm.minDateOfChange = dateService.today();
      }
      vm.isShownDateOfChange = true;
    }
  });

  return vm;

};

angular.module('public.security-manager')
  .controller('PopupResourceEditPayRateController', dependencies.concat(PopupResourceEditPayRateController));
