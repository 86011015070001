const dependencies = [];

const ShiftStatistics = () => {

  const ALL_SHIFTS = 'All shifts';
  const OVERLAPPING = 'Overlapping';
  const UNASSIGNED = 'Unassigned';
  const NOT_CONFIRMED = 'Not confirmed';
  const CONFIRMED = 'Confirmed';

  return class ShiftStatistics {

    constructor() {
      this.list = [ ALL_SHIFTS, OVERLAPPING, UNASSIGNED, NOT_CONFIRMED, CONFIRMED ];
      this.statistics = {
        [ALL_SHIFTS]: { property: null, color: '', count: 0 },
        [OVERLAPPING]: { property: 'overlaps', color: 'mod-gray', count: 0 },
        [UNASSIGNED]: { property: 'unassigned', color: 'mod-red', count: 0 },
        [NOT_CONFIRMED]: { property: 'notConfirmed', color: 'mod-yellow', count: 0 },
        [CONFIRMED]: { property: 'isConfirmed', color: 'mod-green', count: 0 }
      };
      this.currentStat = this.statistics[ALL_SHIFTS];
    }

    setClients(dClients) {
      return this.dClients = dClients;
    }

    isVisible() {
      return this.dClients;
    }

    setSelectedClients(selectedClients) {
      return this.selectedClients = selectedClients;
    }

    recalculate(deep) {
      if (deep == null) { deep = false; }
      this._setEmptyStatistics();
      if (this.dClients && this.dClients.length > 0) {
        return this.dClients.map((dClient) =>
          dClient.dBookings.map((dBooking) =>
            dBooking.dSeries.map((dSeries) => {
              const result = [];
              for (let dShift of Array.from(dSeries.dShifts)) {
                if (deep) { dShift.updateStatistics(); }
                if (dShift.statistics && ((this.selectedClients.length === 0) || (this.selectedClients.indexOf(dClient.id) !== -1))) {
                  this.statistics[ALL_SHIFTS].count += (dShift.statistics.all || 0);
                  this.statistics[OVERLAPPING].count += (dShift.statistics.overlaps || 0);
                  this.statistics[UNASSIGNED].count += (dShift.statistics.unassigned || 0);
                  this.statistics[NOT_CONFIRMED].count += (dShift.statistics.notConfirmed || 0);
                  result.push(this.statistics[CONFIRMED].count += (dShift.statistics.isConfirmed || 0));
                } else {
                  result.push(undefined);
                }
              }
              return result;
            })
          )
        );
      }
    }

    selectFilter(stat) {
      this.currentStat = stat;
      if (this.currentStat === this.statistics[ALL_SHIFTS]) {
        return null;
      } else {
        return stat.property;
      }
    }

    _setEmptyStatistics() {
      return Array.from(this.list).map((stat) => (this.statistics[stat].count = 0));
    }

  }

};

angular.module('public.security-manager.schedule-manager.timeline')
  .factory('ShiftStatistics', dependencies.concat(ShiftStatistics));
