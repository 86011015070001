/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['currentUserService'];

const ManagerClientNotesController = function(currentUserService) {

  const vm = this;

  vm.clientId = currentUserService.getCurrentProfile().clientId;

  return vm;

};

angular.module('public.client-manager.client')
  .controller('ManagerClientNotesController', dependencies.concat(ManagerClientNotesController));
