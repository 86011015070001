/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const bookingShiftEmployeeAssignStatus = function() {

  const NOT_ASSIGNED = 0;
  const NEW = 1;
  const ASSIGNED = 2;
  const DECLINED = 3;
  const IN_DECLINE_PROCESS = 4;
  const UNAVAILABLE = 5;
  const BLOW_OUT = 6;
  const ALREADY_ASSIGNED = 10;
  const BANNED_FOR_CLIENT = 11;
  const IN_DAY_OFF = 12;
  const IN_AVAILABILITY = 13;
  const IN_SICK_DAY = 14;

  const SORT_POSITIONS = [
    IN_AVAILABILITY, NOT_ASSIGNED, ALREADY_ASSIGNED, DECLINED, UNAVAILABLE, NEW,
    ASSIGNED, IN_DAY_OFF, IN_SICK_DAY, BLOW_OUT, BANNED_FOR_CLIENT
  ];
  const INVITABLE_STATUSES = [IN_AVAILABILITY, NOT_ASSIGNED, DECLINED, UNAVAILABLE, ALREADY_ASSIGNED, BLOW_OUT];
  const COUNTS_AS_ASSIGNED = [ASSIGNED, IN_DECLINE_PROCESS];
  const COUNTS_AS_UNAVAILABLE = [DECLINED, UNAVAILABLE, BLOW_OUT];
  const COUNTS_AS_INVITED = [NEW];

  const names = {};
  names[NEW] = 'New';
  names[ASSIGNED] = 'Accepted';
  names[IN_DECLINE_PROCESS] = 'In decline process';
  names[UNAVAILABLE] = 'Unavailable';
  names[BLOW_OUT] = 'Blow out';

  const assignedStatusName = {
    text: 'Accepted',
    classNames: 'mod-ico mod-green'
  };

  const declineStatusName = {
    text: 'Decline',
    classNames: 'mod-ico mod-red'
  };

  const employeeStatusNames = {};
  employeeStatusNames[NEW] = {};
  employeeStatusNames[NEW][NEW] = { text : 'New' };
  employeeStatusNames[NEW][ASSIGNED] = assignedStatusName;
  employeeStatusNames[NEW][DECLINED] = declineStatusName;

  employeeStatusNames[ASSIGNED] = {};
  employeeStatusNames[ASSIGNED][ASSIGNED] = assignedStatusName;
  employeeStatusNames[ASSIGNED][IN_DECLINE_PROCESS] = declineStatusName;

  const onlyNewStatusList = _.pick(employeeStatusNames[NEW], NEW);

  return {
    getNotAssignedStatus() {
      return NOT_ASSIGNED;
    },

    getNewStatus() {
      return NEW;
    },

    getAssignedStatus() {
      return ASSIGNED;
    },

    getDeclinedStatus() {
      return DECLINED;
    },

    getUnavailableStatus() {
      return UNAVAILABLE;
    },

    getAlreadyAssignedStatus() {
      return ALREADY_ASSIGNED;
    },

    getBannedForClientStatus() {
      return BANNED_FOR_CLIENT;
    },

    getInDeclineProcessStatus() {
      return IN_DECLINE_PROCESS;
    },

    getInDayOffStatus() {
      return IN_DAY_OFF;
    },

    getInAvailabilityStatus() {
      return IN_AVAILABILITY;
    },

    getInSickDayStatus() {
      return IN_SICK_DAY;
    },

    getBlowOut() {
      return BLOW_OUT;
    },

    sortPositionOf(status) {
      return SORT_POSITIONS.indexOf(status);
    },

    canBeInvited(status) {
      return INVITABLE_STATUSES.indexOf(status) !== -1;
    },

    getEmployeeStatusNames(shiftEmployee) {
      const currentStatus = shiftEmployee.assignStatus;
      if (!currentStatus) {
        return employeeStatusNames[NEW];
      } else if ((currentStatus === NEW) && (shiftEmployee.endTimeMoment && shiftEmployee.endTimeMoment.isBefore(moment()))) {
        return onlyNewStatusList;
      } else {
        return employeeStatusNames[currentStatus];
      }
    },

    isInDeclineProcess(status) {
      return status === IN_DECLINE_PROCESS;
    },

    isDeclined(status) {
      return status === DECLINED;
    },

    isUnavailable(status) {
      return status === UNAVAILABLE;
    },

    isBlowOut(status) {
      return status === BLOW_OUT;
    },

    countsAsAssigned(status) {
      return COUNTS_AS_ASSIGNED.includes(status);
    },

    countsAsInvited(status) {
      return COUNTS_AS_INVITED.includes(status);
    },

    countsAsUnavailable(status) {
      return COUNTS_AS_UNAVAILABLE.includes(status);
    },

    getName(status) {
      return names[status];
    }
  };
};

angular.module('data.booking').service('bookingShiftEmployeeAssignStatus', bookingShiftEmployeeAssignStatus);
