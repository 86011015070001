/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.booking').directive('gccsPopupBookingShiftFormEmployeesSplit', () => {
  return {
    restrict: 'A',
    controller: 'PopupBookingShiftFormEmployeesSplitController',
    controllerAs: 'shiftEmployeeSplitCtrl',
    template: require('./template.pug'),
    scope: {
      shiftEmployee: '<',
      isShownCb: '&',
      closeCb: '&'
    }
  };
});
