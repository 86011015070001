/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [];

const PlanningScrollService = () => {

  const HOURS_PER_DAY = 24;
  const SCROLL_DAYS = 8;
  const scrollableElements = [document.documentElement, document.body];

  return class PlanningScrollService {

    constructor(planningInteractor) {
      this.planningInteractor = planningInteractor;
    }

    scroll(days) {
      return Array.from(scrollableElements).map((scrollableElement) => {
        return scrollableElement.scrollLeft += days * HOURS_PER_DAY * this.planningInteractor.hourWidth;
      });
    }

    scrollLeft() {
      return this.scroll(-SCROLL_DAYS);
    }

    scrollRight() {
      return this.scroll(SCROLL_DAYS);
    }

    getCurrentScroll() {
      for (let scrollableElement of Array.from(scrollableElements)) {
        if (scrollableElement.scrollLeft) {
          return scrollableElement.scrollLeft;
        }
      }
      return 0;
    }

  };

};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .factory('PlanningScrollService', dependencies.concat(PlanningScrollService));
