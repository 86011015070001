/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['EmployeeInvoice', 'dateService'];

const employeeInvoiceSaver = function(EmployeeInvoice, dateService) {

  const ISO_FULL_DATE_FORMAT = dateService.getIsoFullDateFormat();

  const prepareShiftEmployee = invoiceShiftEmployee =>
    ({
      id : invoiceShiftEmployee.id,
      type : invoiceShiftEmployee.type,
      uniqueNumber : invoiceShiftEmployee.uniqueNumber,
      totalPay : invoiceShiftEmployee.totalPay,
      shiftEmployee : _.assign(invoiceShiftEmployee.shiftEmployee, {
        startTime : invoiceShiftEmployee.startTimeMoment.format(ISO_FULL_DATE_FORMAT),
        endTime : invoiceShiftEmployee.endTimeMoment.format(ISO_FULL_DATE_FORMAT)
      }),
      client : invoiceShiftEmployee.client,
      resource : invoiceShiftEmployee.resource,
      service : invoiceShiftEmployee.service
    })
  ;

  const prepareInvoiceVersion = invoiceVersion =>
    ({
      totalAmount : invoiceVersion.totalAmount,
      payNote : invoiceVersion.payNote,
      shiftEmployees : _.map(invoiceVersion.shiftEmployees, prepareShiftEmployee)
    })
  ;

  return {
    save(invoice) {
      const invoiceData = {
        id : invoice.id,
        employeeId : invoice.employeeId,
        startDate : invoice.startDate,
        endDate : invoice.endDate,
        number : invoice.number,
        status : invoice.status,
        paidDate : invoice.paidDate,
        version : prepareInvoiceVersion(invoice.version)
      };

      if (invoice.originalVersion) {
        invoiceData.originalVersion = prepareInvoiceVersion(invoice.originalVersion);
      }

      return new EmployeeInvoice(invoiceData).save();
    }
  };
};

angular.module('public.invoice.employee').service('employeeInvoiceSaver', dependencies.concat(employeeInvoiceSaver));
