/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['AppModel'];

const EmployeeCard = AppModel => {

  class EmployeeCard extends AppModel {
    static initClass() {
      this.configure({url : '/employee_cards/{{id}}', name : 'employee_card'});
    }
  }

  EmployeeCard.initClass();
  return EmployeeCard;
};

angular.module('data.card').factory('EmployeeCard', dependencies.concat(EmployeeCard));
