/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['$timeout'];

const gccsCustomScroll = ($timeout) => {
  return {
    restrict: 'A',
    link($scope, $el) {
      const element = $el[0];
      let timer = null;

      const onScroll = function() {
        element.classList.add('is-scrolling');
        if (timer) {
          $timeout.cancel(timer)
        }
        timer = $timeout(function() {
          element.classList.remove('is-scrolling');
        }, 500);
      };

      element.addEventListener('scroll', onScroll)
    }
  }
};

angular.module('shared').directive('gccsCustomScroll', dependencies.concat(gccsCustomScroll));