/*
 * @author Maksym Shutiak <maxshutyak>
 */
const dependencies = [
  'ShiftEditModeService', 'BookingShift', 'bookingShiftEditMode', 'confirmService',
  'elementService', 'bookingRepeatable'
];

const PlanningShiftsController = function(
  ShiftEditModeService, BookingShift, bookingShiftEditMode, confirmService,
  elementService, bookingRepeatable
) {

  const vm = this;

  const SHIFT_OUTER_HEIGHT = 71;
  const TOP_PADDING = 7;
  const SHIFT_POPUP_OFFSET = 60;
  const TAIL_HEIGHT = 5;

  vm.isShownShiftFormPopup = false;
  vm.popupShiftDecorator = undefined;
  vm.editModeService = ShiftEditModeService();

  vm.$onInit = function () {
    vm.planningInteractor.subscribeToDateChanges(function () {
      vm.planningShiftLoader.loadShifts();
      vm.planningShiftLoader.uncheckShifts();
    });
  };

  vm.getShiftStyle = function(dShift) {
    const hoursDiffFromStart = dShift.startTimeMoment.diff(vm.planningInteractor.from, 'hours', true);
    return {
      width: `${vm.planningInteractor.hourWidth * dShift.getShiftLengthInHours()}px`,
      top: `${((dShift.row - 1) * SHIFT_OUTER_HEIGHT) + TOP_PADDING}px`,
      left: `${(hoursDiffFromStart * vm.planningInteractor.hourWidth) - (vm.planningInteractor.hourWidth / 2)}px`
    };
  };

  vm.getShiftDuration = function (dShift) {
    const duration = dShift.getShiftDurationMoment();
    let label = `${ duration.hours() }h`;
    if (duration.minutes()) {
      label = label.concat(`${duration.minutes()}m`);
    }
    return label;
  };

  vm.showShiftFormPopup = function($event, dShift) {
    $event.stopPropagation();
    vm.popupShiftDecorator = dShift;
    updateShiftPosition($event.target.closest('.planning-shift'));
    vm.popupDateRange = [dShift.getBookingFrom(), dShift.getBookingTo()];
    vm.isShownShiftFormPopup = true;
  };

  vm.toggleChecked = function(dShift) {
    if (dShift.isChecked) {
      vm.onShiftDeselect({ shift: dShift });
    } else {
      vm.onShiftSelect({ shift: dShift });
    }
    dShift.toggleChecked();
  };

  vm.closeShiftFormPopup = function() {
    vm.popupShiftDecorator = undefined;
    vm.isShownShiftFormPopup = false;
  };

  vm.saveShift = function (shift, afterSaveCb) {
    getShiftCount(shift).then(function (shiftCount) {
      updateShift(shiftCount.count, shift, function () {
        new BookingShift(shift).save().then(function () {
          if (afterSaveCb) {
            afterSaveCb();
          }
          vm.planningShiftLoader.reloadShifts();
        });
      });
    });
  };

  vm.removeShift = function (shift, afterRemoveCb) {
    getShiftCount(shift).then(function (shiftCount) {
      updateShift(shiftCount.count, shift, function () {
        confirmService.confirmRemove('shift', function () {
          const entity = new BookingShift(shift);
          entity.$delete(entity.getUrl(), { editMode: shift.editMode }).then(function () {
            vm.planningShiftLoader.reloadShifts();
            if (afterRemoveCb) {
              afterRemoveCb();
            }
            vm.closeShiftFormPopup();
          });
        });
      });
    });
  };

  vm.getShiftPopupOffset = () => SHIFT_POPUP_OFFSET;

  vm.isEditOnTop = function (dShift) {
    return dShift.isSmall() && (dShift.shift.requiredEmployeesCount > 0);
  };

  vm.isHighlighted = function (dShift) {
    return dShift.shift.isHighlighted;
  };

  const getShiftCount = function (shift) {
    return BookingShift.getCount({ bookingId: shift.bookingId }, { bookingSeriesId: shift.bookingSeriesId });
  };

  const updateShift = function(countInSeries, shift, cb) {
    if (countInSeries > 1) {
      vm.editModeService.show(shift, cb);
    } else {
      if (bookingRepeatable.isNone(shift.repeatable)) {
        shift.editMode = bookingShiftEditMode.getOnlyCurrentMode();
      } else {
        shift.editMode = bookingShiftEditMode.getFollowingMode();
      }
      cb();
    }
  };

  const updateShiftPosition = function(shiftElement) {
    const left = elementService.getOffsetLeft(shiftElement);
    const top = elementService.getOffsetTop(shiftElement);
    vm.shiftFormPopupPosition = {
      left: left + shiftElement.offsetWidth,
      top: ((top + (shiftElement.offsetHeight / 2)) - TAIL_HEIGHT) + SHIFT_POPUP_OFFSET
    };
  };

  return vm;

};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .controller('PlanningShiftsController', dependencies.concat(PlanningShiftsController));
