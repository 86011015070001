const dependencies = [
  '$scope', '$state', 'RateChange', 'EmployeeResource', 'notificationService', 'confirmService',
  'employeeType', 'rateChangeType'
];

const EmployeeClientApproveResourceController = function(
  $scope, $state, RateChange, EmployeeResource, notificationService, confirmService,
  employeeType, rateChangeType
) {

  const vm = this;

  vm.isShownEditPayRatePopup = false;
  vm.isEditingChargeRate = false;
  vm.isEditingOvertimeRate = false;
  vm.isEditingOvertimeMinHours = false;

  vm.$onChanges = function() {
    if (!vm.employeeResource) {
      initEmployeeResource();
    }
  };

  vm.showEditPayRatePopup = function() {
    vm.rateChange = new RateChange({
      employeeResourceId: vm.employeeResource.id,
      payRate: vm.employeeResource.payRate,
      rateType: rateChangeType.getPayRateType()
    });
    vm.isShownEditPayRatePopup = true;
  };

  vm.showEditChargeRatePopup = function() {
    vm.rateChange = new RateChange({
      employeeResourceId: vm.employeeResource.id,
      payRate: vm.employeeResource.chargeRate,
      rateType: rateChangeType.getChargeRateType()
    });
    vm.isShownEditPayRatePopup = true;
  };

  vm.hideEditPayRatePopup = function(rateChange) {
    vm.isShownEditPayRatePopup = false;
    if (rateChange) {
      if (rateChange.id) {
        vm.employeeResource.rateChange = rateChange;
      } else {
        let field;
        if (rateChange.rateType === rateChangeType.getChargeRateType()) {
          field = 'chargeRate';
        } else {
          field = 'payRate';
        }
        vm.employeeResource[field] = rateChange.payRate;
        vm.employeeResource.save();
      }
    }
  };

  vm.isResourceApproved = function() {
    return !!(vm.employeeResource && vm.employeeResource.id);
  };

  vm.approveResource = function() {
    vm.employeeResource.save().then(savedEmployeeResource => {
      vm.onResourceApprove({ approvedResource: savedEmployeeResource });
    }).catch((e) => {
      notificationService.notifyError(e.data.error);
    });
  };

  vm.rejectResource = function() {
    vm.employeeResource.remove().then(function () {
      initEmployeeResource();
      vm.onResourceReject({ rejectedResource: vm.employeeResource });
    });
  };
  
  vm.getPayRate = function() {
    return getRate('payRate');
  };

  vm.getChargeRate = function() {
    return getRate('chargeRate');
  };

  vm.getOvertimeRate = function() {
    return getRate('overtimeRate');
  };

  vm.removeRateChange = function(rateChange) {
    confirmService.confirm(
      `Remove rate change for ${vm.resource.name}?`,
      'Do you really want to remove planned rate changes? This action can not be undone.',
      'Remove',
      function () {
        new RateChange(rateChange).remove().then(function() {
          vm.employeeResource.rateChange = null;
        });
      },
    );
  };

  vm.getPopupTitle = function() {
    return 'Edit cost for rate change';
  };

  vm.showChargeEdit = function() {
    vm.isEditingChargeRate = true;
    vm.isEditingOvertimeRate = false;
    vm.isEditingOvertimeMinHours = false;
  };

  vm.showOvertimeRateEdit = function() {
    vm.isEditingChargeRate = false;
    vm.isEditingOvertimeRate = true;
    vm.isEditingOvertimeMinHours = false;
  };

  vm.showOvertimeMinHoursEdit = function() {
    vm.isEditingChargeRate = false;
    vm.isEditingOvertimeRate = false;
    vm.isEditingOvertimeMinHours = true;
  };

  vm.saveEmployeeResourceRate = function() {
    vm.isEditingChargeRate = false;
    vm.isEditingOvertimeRate = false;
    vm.isEditingOvertimeMinHours = false;
    vm.employeeResource.save();
  };

  const getRate = function(field) {
    if (vm.employeeResource && vm.employeeResource[field] !== null) {
      return `£ ${vm.employeeResource[field]}`;
    } else {
      return '';
    }
  };

  const initEmployeeResource = function() {
    let payRate;
    if (employeeType.isSelfEmployed(vm.employee.employmentType) || employeeType.isSubContractor(vm.employee.employmentType)) {
      payRate = vm.resource.contractorPayRate;
    } else {
      payRate = vm.resource.employedPayRate;
    }
    vm.employeeResource = new EmployeeResource({
      employeeId: vm.employee.id,
      resourceId: vm.resource.id,
      payRate: payRate,
      chargeRate: vm.resource.chargeRate,
      overtimeRate: 0,
      overtimeMinHours: 40,
      minPayRate: vm.resource.payRate,
      resource: vm.resource
    });
  };

  return vm;

};

angular.module('public.security-manager.employee')
  .controller('EmployeeClientApproveResourceController', dependencies.concat(EmployeeClientApproveResourceController));
