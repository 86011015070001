const dependencies = ['ExtendedFilterService'];

const ExtendedFilterClientBookingService = (ExtendedFilterService) => {

  return class ExtendedFilterClientBookingService extends ExtendedFilterService {

    constructor(filterData) {
      super(...arguments);
      this.filterData = filterData;
      this.searchString = '';
    }

    setFilterType(filterType) {
      return this.filterType = filterType;
    }

    applyFilter(...args) {
      const val = args[0],
        obj = val != null ? val : {},
        val1 = obj.isFirstApply,
        isFirstApply = val1 != null ? val1 : false;
      this.applyFilterCb({
        queryParams: this.queryParams,
        isFirstApply
      });
      this.close();
    }

    _categories(filterData) {
      return [{
        label: 'Choose sites',
        params: 'site_ids[]',
        type: 'checkbox',
        top: true,
        options: this._siteOptions(filterData),
        children: [{
          label: 'Choose locations',
          params: 'location_ids[]',
          type: 'checkbox',
          options: this._locationOptions(filterData),
          children: []
        }]
      }];
    }

    _siteOptions(filterData) {
      return _.map(filterData.sites, site => {
        return {
          id: site.id,
          label: site.name,
          tag: true
        };
      });
    }

    _locationOptions(filterData) {
      return _.map(filterData.locations, location => {
        return {
          id: location.id,
          label: location.name,
          parentId: location.siteId,
          tag: true
        };
      });
    }

  }

};

angular.module('public.security-manager.schedule-manager.timeline')
  .service('ExtendedFilterClientBookingService', dependencies.concat(ExtendedFilterClientBookingService));
