/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'ClientManager', 'EntityManager', 'pluralizeService', 'currentUserService'];

const ManagerClientDetailsManagersController = function($scope, ClientManager, EntityManager, pluralizeService, currentUserService) {

  const vm = this;

  vm.isShownManagerPopup = false;
  vm.pluralizeService = pluralizeService;
  vm.isAccountOwnerManager = currentUserService.getCurrentProfile().isAccountOwner();

  const entityManager = new EntityManager(ClientManager, {
    params : {
      clientId : $scope.clientId
    }
  }
  );

  vm.removeManager = entityManager.removeEntity;

  vm.getManagers = () => entityManager.entities;

  vm.showManagerPopup = function(manager) {
    if (!vm.isAccountOwnerManager) { return; }
    vm.popupManager = manager || entityManager.createEntity();
    return vm.isShownManagerPopup = true;
  };

  vm.closeManagerPopup = function(savedManager) {
    vm.isShownManagerPopup = false;
    if (savedManager) { return entityManager.updateEntity(savedManager); }
  };

  entityManager.loadAll();

  return vm;
};

angular.module('public.client-manager.client').controller('ManagerClientDetailsManagersController',
  dependencies.concat(ManagerClientDetailsManagersController));
