/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$state', 'EmployeePayroll', 'EmployeeInvoice', 'EntityManager', 'dateService', 'pluralizeService',
  'employeeType', 'employeeInvoiceStatus', 'employeePayrollStatus', 'confirmService'
];

const InvoicesPayrollFormController = function(
  $state, EmployeePayroll, EmployeeInvoice, EntityManager, dateService, pluralizeService,
  employeeType, employeeInvoiceStatus, employeePayrollStatus, confirmService
) {

  const vm = this;

  vm.employmentType = +$state.params.employmentType;

  const entityManager = new EntityManager(EmployeeInvoice, {
    loadCbName: 'all',
    queryParams: {
      template: 'simple_list',
      status: employeeInvoiceStatus.getApprovedStatus(),
      employmentType: vm.employmentType,
      'order[employee_name]': 'asc'
    },
    prepare(invoice) {
      invoice.startDateMoment = moment(invoice.startDate, dateService.getIsoFullDateFormat());
      invoice.endDateMoment = moment(invoice.endDate, dateService.getIsoFullDateFormat());
    }
  });

  vm.employeePayrollStatus = employeePayrollStatus;
  vm.selectedInvoices = [];
  vm.isDisabledLoading = entityManager.isDisabledLoading;
  vm.isShownCreatePayrollPopup = false;
  vm.isShownEditPayrollPopup = false;
  vm.popupViewPayroll = undefined;
  vm.isSavingPayroll = false;
  vm.payroll = undefined;
  vm.isEditing = !!$state.params.id;

  vm.$onInit = function () {
    initPayroll();
    initEmploymentTypeLabel();
    initTitle();
    initActionButtonText();
    initDates();
    vm.filterInvoices();
  };

  vm.initSavingPayroll = function () {
    if (vm.isSavingPayroll) { return; }
    if (vm.isEditing) {
      applyEditPayroll();
    } else {
      applyCreatePayroll();
    }
  };

  vm.closeCreatePopup = function (payroll) {
    vm.isShownCreatePayrollPopup = false;
    if (!payroll) { return; }
    savePayroll(payroll);
  };

  vm.closeEditPopup = function (payroll) {
    vm.isShownEditPayrollPopup = false;
    if (!payroll) { return; }
    vm.payroll.description = payroll.description;
  };

  vm.showEditPopup = function () {
    vm.isShownEditPayrollPopup = true;
    vm.popupViewPayroll = vm.payroll;
  };

  vm.getInvoices = function () {
    return _.differenceBy(entityManager.entities, vm.selectedInvoices, 'id');
  };

  vm.getPeriod = function (invoice) {
    return `${invoice.startDateMoment.format(dateService.getDateFormat())} - ${invoice.endDateMoment.format(dateService.getDateFormat())}`;
  };

  vm.selectInvoice = function (invoice) {
    vm.selectedInvoices.push(invoice);
  };

  vm.selectAllInvoices = function () {
    vm.selectedInvoices = _.unionBy(vm.selectedInvoices, entityManager.entities, 'id');
  };

  vm.unselectInvoice = function (invoice) {
    _.remove(vm.selectedInvoices, invoice);
  };

  vm.unselectAllInvoices = function () {
    vm.selectedInvoices = [];
  };

  vm.getTotalAmount = function () {
    return _.reduce(vm.selectedInvoices, (totalAmount, invoice) => totalAmount + invoice.totalAmount, 0);
  };

  vm.filterInvoices = function () {
    const urlParams = {};
    if (vm.startDate && vm.startDate) {
      urlParams.from = moment(vm.startDate, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
      urlParams.to = moment(vm.endDate, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
    }
    entityManager.loadAll(urlParams);
  };

  vm.goBack = function () {
    $state.go('public.security-manager.invoices.payrolls');
  };

  vm.onChangePayrollStatus = function (status) {
    vm.payroll.latestVersion.status = +status;
  };

  vm.onDeletePayroll = function () {
    confirmService.confirmRemove('this payroll', () =>
      vm.payroll.delete().then(() => vm.goBack())
    );
  };

  vm.getSelectedInvoicesText = function () {
    return pluralizeService.simple(vm.selectedInvoices.length, 'Invoice');
  };

  const applyEditPayroll = function () {
    vm.payroll.invoiceIds = _.map(vm.selectedInvoices, 'id');
    if (moment.isMoment(vm.payroll.latestVersion.paidDate)) {
      vm.payroll.latestVersion.paidDate = vm.payroll.latestVersion.paidDate.format(dateService.getIsoDateFormat());
    }
    savePayroll(vm.payroll);
  };

  const applyCreatePayroll = function () {
    vm.popupViewPayroll = new EmployeePayroll({
      employmentType: vm.employmentType,
      invoiceIds: _.map(vm.selectedInvoices, 'id'),
      latestVersion: {
        status: employeePayrollStatus.getPaymentDueStatus()
      }
    });
    vm.isShownCreatePayrollPopup = true;
  };

  const getPayrollInvoices = function () {
    const invoices = [];
    _.each(vm.payroll.latestVersion.invoices, function (invoice) {
      invoices.push({
        id: invoice.id,
        startDate: invoice.startDate,
        endDate: invoice.endDate,
        totalAmount: invoice.latestVersion.totalAmount,
        employee: {
          fullName: invoice.latestVersion.employeeName
        },
        startDateMoment: moment(invoice.startDate, dateService.getIsoFullDateFormat()),
        endDateMoment: moment(invoice.endDate, dateService.getIsoFullDateFormat()),
        isFromStart: true
      });
    });
    return invoices;
  };

  const initPayroll = function () {
    if (vm.isEditing) {
      EmployeePayroll.get($state.params.id).then(function (payroll) {
        vm.payroll = payroll;
        vm.selectedInvoices = getPayrollInvoices(vm.payroll.latestVersion.invoices);
      });
    } else {
      vm.payroll = new EmployeePayroll({ employmentType: vm.employmentType });
    }
  };

  const initEmploymentTypeLabel = function () {
    if (employeeType.isEmployed(vm.employmentType)) {
      vm.employmentTypeLabel = 'Employees';
    } else {
      vm.employmentTypeLabel = 'Contractors';
    }
  };

  const initTitle = function () {
    let action;
    if (vm.isEditing) {
      action = 'Editing';
    } else {
      action = 'Creating new';
    }
    vm.title = `${action} ${vm.employmentTypeLabel.toLowerCase()} payroll`;
  };

  const initActionButtonText = function () {
    if (vm.isEditing) {
      vm.confirmButtonText = 'Confirm changes';
    } else {
      vm.confirmButtonText = 'Create';
    }
  };

  const initDates = function () {
    const today = dateService.today();
    vm.startDate = today.clone().subtract(30, 'days');
    vm.endDate = today.clone();
  };

  const savePayroll = function (payroll) {
    vm.isSavingPayroll = true;
    payroll.save().then(() => vm.goBack());
  };

  return vm;

};

angular.module('public.security-manager.invoices.payroll')
  .controller('InvoicesPayrollFormController', dependencies.concat(InvoicesPayrollFormController));
