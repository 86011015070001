angular.module('shared').constant('defaultConfig', {
  actionCableDomain: ENV.actionCableDomain,
  apiPrefix: ENV.apiPrefix,
  stripeKey: ENV.stripeKey,
  rollbarToken: ENV.rollbarToken,
  environment: ENV.environment,
  isProduction: ENV.isProduction,
  sessionKey: 'sessionId',
  googleApiKey: ENV.googleApiKey
});
