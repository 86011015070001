/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = ['$filter', 'dateService', 'emailStatus'];

const SecurityManagerHrEmailRowController = function($filter, dateService, emailStatus) {

  const vm = this;

  const VISIBLE_RECEIVERS_COUNT = 5;
  const HIGHLIGHT_CLASS = 'is-emails-highlighted';
  const CUT_TEXT_LENGTH = 50;

  vm.shownReceiver = null;
  vm.isShownReceivers = false;
  vm.isShownPreviewPopup = false;

  vm.$onInit = function() {
    if (vm.showStatusFor) {
      vm.employeeWithStatus = _.find(vm.email.receivers, receiver => receiver.employee.id === +vm.showStatusFor);
    }
  };

  vm.toggleReceivers = function(receiver) {
    if (vm.isShownReceivers) {
      vm.receiverSearch = '';
      vm.hideReceivers();
    } else {
      if (receiver) {
        vm.receiverSearch = receiver.employee.fullName;
        vm.shownReceiver = receiver;
      } else {
        vm.receiverSearch = '';
      }
      vm.isShownReceivers = true;
    }
  };

  vm.hideReceivers = function() {
    vm.shownReceiver = null;
    vm.isShownReceivers = false;
  };

  vm.isShownReceiver = function (receiver) {
    return receiver === vm.shownReceiver;
  };

  vm.getVisibleReceivers = function () {
    return getReceivers().slice(0, VISIBLE_RECEIVERS_COUNT);
  };

  vm.getLeftReceiversCount = function () {
    return getReceivers().length - VISIBLE_RECEIVERS_COUNT;
  };

  vm.getOrderedReceivers = function() {
    const foundReceivers = [];
    const otherReceivers = [];
    _.each(vm.email.receivers, function(receiver) {
      if (isReceiverMatches(receiver, vm.receiverSearch)) {
        foundReceivers.push(receiver);
      } else {
        otherReceivers.push(receiver);
      }
    });
    return foundReceivers.concat(otherReceivers);
  };

  vm.showEmployeeEmailPreview = function () {
    vm.isShownPreviewPopup = true;
  };

  vm.closeEmployeeEmailPreview = function () {
    vm.isShownPreviewPopup = false;
  };

  vm.highlight = function (value) {
    return $filter('highlight')(value, vm.highlightString, HIGHLIGHT_CLASS);
  };

  vm.getEmailText = function () {
    return vm.email.cleanText.substring(0, CUT_TEXT_LENGTH);
  };

  vm.getFormattedDate = function() {
    if (!vm.email.formattedDate) {
      let date;
      if (vm.email.status === emailStatus.getDraftStatus()) {
        date = vm.email.updatedAt || vm.email.createdAt;
      } else {
        date = vm.email.sentTime;
      }
      const dateMoment = moment(date, dateService.getIsoFullDateFormatWithT(true));
      dateMoment.add(moment().utcOffset() - dateMoment.utcOffset(), 'minutes');
      vm.email.formattedDate = dateMoment.format(`D MMM YYYY, ${dateService.getWesternTimeFormat(true)}`);
    }
    return vm.email.formattedDate;
  };

  const getReceivers = function() {
    if (vm.highlightString) {
      return _.filter(vm.email.receivers, receiver => isReceiverMatches(receiver, vm.highlightString));
    } else {
      return vm.email.receivers;
    }
  };

  const isReceiverMatches = function (receiver, matchString) {
    return (receiver.employee.fullName.toLowerCase().indexOf(matchString.toLowerCase()) !== -1) ||
      (receiver.employee.email.toLowerCase().indexOf(matchString.toLowerCase()) !== -1);
  };

  return vm;

};

angular.module('public.security-manager.hr.email')
  .controller('SecurityManagerHrEmailRowController', dependencies.concat(SecurityManagerHrEmailRowController));
