/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.schedule-manager', [
  'public.timeline',
  'public.security-manager.schedule-manager.booking-form',
  'public.security-manager.schedule-manager.timeline',
  'public.security-manager.schedule-manager.repeatable-booking'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.schedule-manager', {
      abstract: true,
      url: '/schedule/manager',
      template: require('./template.pug')
    })

]);
