/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', 'BookingComment', 'BookingClockComment', 'RepeatableBookingComment', 'EntityManager',
  'currentUserService', 'bookingCommentVisibleTo', 'ShiftRuleVersionComment', 'dateService',
  'bookingShiftCommentClockType', 'confirmService'
];

const TimelineBookingFormDetailsCommentsController = function (
  $scope, BookingComment, BookingClockComment, RepeatableBookingComment, EntityManager,
  currentUserService, bookingCommentVisibleTo, ShiftRuleVersionComment, dateService,
  bookingShiftCommentClockType, confirmService
) {

  const vm = this;

  vm.currentProfile = currentUserService.getCurrentProfile();
  vm.currentUser = currentUserService.getCurrentUser();

  vm.isEdit = $scope.isEdit;
  vm.commentsLimit = 2;
  vm.isShownAddCommentForm = false;
  vm.isShownMoreComments = false;
  vm.editedCommentId = null;
  vm.editable = $scope.editable === undefined ? true : $scope.editable;

  let isSending = false;
  let entityManager;

  vm.getVisibleToText = bookingCommentVisibleTo.getVisibleToText;

  vm.$onInit = function() {
    initEntityManager();
    createNewComment();
    initBookingClockComments();
  };

  vm.onSubmit = function () {
    if (!vm.newComment.text || isSending) {
      return;
    }
    if ($scope.shiftRule) {
      entityManager.addEntity(vm.newComment);
      createNewComment();
      if (!vm.isEdit) {
        vm.closeAddCommentForm();
      }
    } else {
      isSending = true;
      return vm.newComment.save().then(function (comment) {
        entityManager.addEntity(comment);
        createNewComment();
        isSending = false;
        if (!vm.isEdit) {
          vm.closeAddCommentForm();
        }
      });
    }
  };

  vm.removeComment = function(entity, onSuccessCb, removeOptions) {
    if ($scope.shiftRule) {
      entityManager._removeEntity(entity);
    } else {
      if (entity.isClockComment) {
        confirmService.confirmRemove('comment', () => {
          entity.$delete(entity.getUrl(), removeOptions).then(() => {
            _.remove(this.clockComments, function(clockComment) {
              return clockComment === entity;
            });
            if (onSuccessCb) { onSuccessCb(); }
          });
        });
      } else {
        entityManager.removeEntity(entity, onSuccessCb, removeOptions);
      }
    }
  };

  vm.initEditing = function(comment) {
    vm.editedCommentId = comment.id;
  };

  vm.closeEditing = function() {
    vm.editedCommentId = null;
  };

  vm.isEditedComment = function(comment) {
    if (!comment) { return; }
    return vm.editedCommentId === comment.id;
  };

  vm.getComments = () => {
    let comments;
    if (!$scope.repeatableBookingId) {
      comments = _.concat(entityManager.entities, vm.clockComments);
    }
    return _.orderBy(comments, 'createdAt', 'desc');
  };

  vm.isShownClientVisibility = () => !currentUserService.isClientManagerLogged();

  vm.openAddCommentForm = function() {
    vm.isShownAddCommentForm = true;
  };

  vm.closeAddCommentForm = function() {
    vm.isShownAddCommentForm = false;
  };

  vm.hasComments = function() {
    return vm.getComments().length;
  };

  vm.hasMoreComments = function() {
    return vm.getComments().length > vm.commentsLimit;
  };

  vm.toggleShowMoreComments = function() {
    vm.isShownMoreComments = !vm.isShownMoreComments;
  };

  vm.getShowMoreButtonText = function() {
    if (vm.hasMoreComments() && !vm.isShownMoreComments) {
      return 'View all comments';
    } else {
      return 'Show less'
    }
  };

  vm.onSaveEdit = function(comment) {
    if ($scope.shiftRule) {
      vm.closeEditing();
    } else {
      comment.save().then(function() {
        vm.closeEditing();
      });
    }
  };

  vm.isShownCommentActions = function(comment) {
    if (!comment) {
      return false;
    }
    if (currentUserService.isSecurityManagerLogged()) {
      if (vm.currentProfile.isSupervisor()) {
        return false;
      } else if (vm.currentProfile.isManager()) {
        return comment.user.id === vm.currentProfile.userId;
      } else {
        return true;
      }
    } else if (currentUserService.isClientManagerLogged()) {
      return comment.user.id === vm.currentProfile.userId;
    } else {
      return false;
    }
  };

  vm.isShownStaffVisibility = function() {
    return !!$scope.shiftRule;
  };

  vm.isShownAddCommentsButton = function() {
    return !vm.isEdit && !vm.isShownAddCommentForm && !vm.hasComments()
  };

  const initBookingClockComments = function() {
    if (!$scope.repeatableBookingId && currentUserService.isSecurityManagerLogged()) {
      BookingClockComment.query({}, {
        bookingId: $scope.bookingId
      }).then(function(clockComments) {
        vm.clockComments = prepareClockComments(clockComments);
      });
    }
  };

  const createNewComment = function () {
    vm.newComment = entityManager.createEntity();
    vm.newComment.visibleToClient = true;
    vm.newComment.user = currentUserService.getCurrentUser();
    vm.newComment.createdAt = moment().format(dateService.getIsoFullDateFormatWithTz());
  };

  const initEntityManager = function() {
    if ($scope.repeatableBookingId) {
      if ($scope.shiftRule) {
        entityManager = new EntityManager(ShiftRuleVersionComment, {
          params: {
            repeatableBookingId: $scope.repeatableBookingId,
            shiftRuleId: $scope.shiftRule.id,
            versionId: $scope.shiftRuleVersion.id
          },
          prepare: {
            userId: vm.currentProfile.userId
          }
        });
      } else {
        entityManager = new EntityManager(RepeatableBookingComment, {
          params: {
            repeatableBookingId: $scope.repeatableBookingId
          },
          prepare: {
            userId: vm.currentProfile.userId
          }
        });
      }
    } else {
      entityManager = new EntityManager(BookingComment, {
        params: {
          bookingId: $scope.bookingId
        },
        prepare: {
          userId: vm.currentProfile.userId
        }
      });
    }
    if ($scope.shiftRuleVersion) {
      entityManager.setEntities($scope.shiftRuleVersion.comments);
      $scope.shiftRuleVersion.comments = entityManager.entities;
    } else {
      entityManager.loadAll();
    }
  };

  const prepareClockComments = function(clockComments) {
    _.each(clockComments, clockComment => {
      clockComment.typeText = bookingShiftCommentClockType.getName(clockComment.type);
      if (clockComment.shiftName) {
        clockComment.typeText += ` (${clockComment.shiftName})`;
      }
      clockComment.text = getClockCommentText(clockComment);
      clockComment.visibleToStaff = false;
      clockComment.visibleToClient = false;
      clockComment.isClockComment = true;
    });
    return clockComments;
  };

  const getClockCommentText = function(clockComment) {
    let commentText = `${ clockComment.employee.fullName }`;
    if (clockComment.oldClockTime) {
      commentText += ` from ${ getFormattedFullDate(clockComment.oldClockTime) } to ${ getFormattedFullDate(clockComment.clockTime) }`;
    } else {
      commentText += ` at ${ getFormattedFullDate(clockComment.clockTime) }`;
    }
    if (bookingShiftCommentClockType.isTimeErrorType(clockComment.type)) {
      commentText += ` - ${ getTimeErrorText(clockComment) }`;
    }
    if (clockComment.securityManager) {
      commentText += ` by ${ clockComment.securityManager.fullName } at ${ getFormattedFullDate(clockComment.createdAt) }`;
    }
    return commentText;
  };

  const getFormattedFullDate = function(time) {
    return moment(time, dateService.getIsoFullDateFormatWithT()).format(dateService.getFullDateFormatWithComma())
  };

  const getTimeErrorText = function(clockComment) {
    const clockTime = moment(clockComment.clockTime, dateService.getIsoFullDateFormatWithT());
    const shiftTime = moment(clockComment.shiftEmployeeTime, dateService.getIsoFullDateFormatWithT());
    return dateService.formatTimeDifference(shiftTime, clockTime);
  };

  return vm;

};

angular
  .module('public.timeline')
  .controller('TimelineBookingFormDetailsCommentsController',
    dependencies.concat(TimelineBookingFormDetailsCommentsController));
