/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('popup.booking').directive('gccsPopupBookingShiftForm', [
  'popupDirectiveConfig',
  popupDirectiveConfig =>
    popupDirectiveConfig.configure({
      bodyTemplate: require('./template.pug'),
      controllerAs: 'shiftFormCtrl',
      controller: 'PopupBookingShiftFormController',
      scope: {
        withEmployees: '<',
        withComments: '<',
        withLog: '<',
        isShownEmployeeInvite: '<',
        activeTab: '<',
        client: '<',
        shift: '<',
        position: '<',
        availableDateRange: '<',
        onRemove: '&',
        saveShift: '&',
        bookingTimelineShiftSelector: '<',
        timeLineLoader: '<',
        disableRates: '<',
        editable: '<',
        withoutLock: '<',
        tailOffset: '<',
        regularPopup: '<',
        withoutCommentRead: '<',
        withoutRemove: '<',
        isShiftIssueInviteEmployee: '<',
        shouldGoToInvited: '<'
      }
    })
]);
