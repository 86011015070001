angular.module('gccs-ui').config(['dynamicNumberStrategyProvider', dynamicNumberStrategyProvider => {

  dynamicNumberStrategyProvider.addStrategy('money', {
    numInt: 6,
    numFract: 2,
    numPos: true,
    numNeg: false,
    numThousand: true
  });

  dynamicNumberStrategyProvider.addStrategy('percent', {
    numInt: 3,
    numFract: 2,
    numPos: true,
    numNeg: false,
    numThousand: false
  });

}]);
