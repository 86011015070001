const dependencies = ['AppModel'];

const SessionToken = AppModel => {

  class SessionToken extends AppModel {
    static initClass() {
      this.configure({
        url: '/session/tokens',
        name: 'token',
        rootWrapping: false
      });
    }
  }

  SessionToken.initClass();

  return SessionToken;

};

angular.module('shared').factory('SessionToken', dependencies.concat(SessionToken));
