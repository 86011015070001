/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsSecurityManagerEmailReceiver = {
  template: require('./template.pug'),
  bindings: {
    receiver: '<'
  }
};

angular.module('public.security-manager.hr.email')
  .component('gccsSecurityManagerEmailReceiver', gccsSecurityManagerEmailReceiver);
