/*
 * @author Oleksandr Papka <papkaos>
 */
const gccsFinanceChartDetails = {
  controller: 'FinanceChartDetailsController',
  controllerAs: 'financeChartDetailsCtrl',
  template: require('./template.pug'),
  bindings: {
    pointsData: '<',
    filterParams: '<'
  }
};

angular.module('public.security-manager.reports.finance')
  .component('gccsFinanceChartDetails', gccsFinanceChartDetails);
