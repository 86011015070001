const dependencies = [
  '$scope', '$sce', 'downloadService'
];

const PopupEmailEmployeePreviewController = function(
  $scope, $sce, downloadService
) {

  const vm = this;

  vm.onButtonClick = function() {
    $scope.popupCtrl.closePopup();
    $scope.onButtonClick();
  };

  vm.downloadAttachment = function(attachment) {
    downloadService.downloadWithoutCache(attachment.file, attachment.fileName);
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      vm.employeeEmail = angular.copy($scope.employeeEmail);
      vm.isClientEmail = $scope.isClientEmail;
      vm.emailHTML = $sce.trustAsHtml(vm.employeeEmail.text);
      vm.signatureEmailHTML = $sce.trustAsHtml(vm.employeeEmail.sender.emailSignature);
    }
  });

  return vm;

};

angular.module('popup.email')
  .controller('PopupEmailEmployeePreviewController', dependencies.concat(PopupEmailEmployeePreviewController));
