const TimelinePager = function() {

  let _from = undefined;
  let _viewType = undefined;

  const setPagerParams = function({ from, viewType }) {
    _from = _.clone(from);
    return _viewType = _.clone(viewType);
  };

  const getPagerParams = () =>
    ({
      from : _.clone(_from),
      viewType : _.clone(_viewType)
    })
  ;

  const clearPagerParams = function() {
    _from = undefined;
    return _viewType = undefined;
  };

  return {
    setPagerParams,
    getPagerParams,
    clearPagerParams
  };
};

angular.module('shared').service('timelinePager', TimelinePager);
