/*
 * @author Igor Serpak <robobee>
 */
angular.module('shared').directive('onHorizontalScrollTranslate', () => {
  return {
    restrict : 'A',
    scope : {
      scrollElementSelector : '<',
      updateOnChange : '<'
    },
    link($scope, $el) {
      const translateElement = $el[0];
      const scrollContainer = $el[0].closest($scope.scrollElementSelector);

      const onScroll = function() {
        const latestKnownScroll = scrollContainer.scrollLeft;
        return translateElement.style.transform = `translateX(${latestKnownScroll}px)`;
      };

      scrollContainer.addEventListener('scroll', onScroll);

      $scope.$watch($scope.updateOnChange, onScroll);

      return $scope.$on('$destroy', () => scrollContainer.removeEventListener('scroll', onScroll));
    }
  }
});
