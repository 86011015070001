angular.module('public.security-manager.client.show', [
  'public.security-manager.client.show.invoice',
  'popup.client',
  'popup.document',
  'popup.note'
]).config(['$stateProvider', $stateProvider => {

  $stateProvider

    .state('public.security-manager.client', {
      abstract: true,
      url: '/client/:clientId',
      template: require('./template.pug'),
      controller: 'ClientShowLayoutController',
      controllerAs: 'clientShowLayoutCtrl'
    })
    .state('public.security-manager.client.details', {
      url: '/details',
      template: require('./details/template.pug'),
      controller: 'ClientShowDetailsController',
      controllerAs: 'clientShowDetailsCtrl'
    })
    .state('public.security-manager.client.services', {
      url: '/services',
      template: require('./services/template.pug'),
      controller: 'ClientShowServicesController',
      controllerAs: 'clientShowServicesCtrl'
    })
    .state('public.security-manager.client-service', {
      url: '/client/:clientId/service/form?{ serviceId }',
      template: require('./services/form/template.pug'),
      controller: 'ClientShowServicesFormController',
      controllerAs: 'clientShowServicesFormCtrl'
    })
    .state('public.security-manager.client.documents', {
      url: '/documents',
      template: require('./documents/template.pug'),
      controller: 'ClientShowDocumentsController',
      controllerAs: 'clientShowDocumentsCtrl'
    })
    .state('public.security-manager.client.sites', {
      url: '/sites',
      template: require('./sites/template.pug'),
      controller: 'ClientShowSitesController',
      controllerAs: 'clientShowSitesCtrl'
    })
    .state('public.security-manager.client.clock-in', {
      url: '/clock-in',
      template: require('./clock-in/template.pug'),
      controller: 'ClientShowClockInController',
      controllerAs: 'clientShowClockInCtrl'
    })
    .state('public.security-manager.client.employees', {
      url: '/employees',
      template: require('./employees/template.pug'),
      controller: 'ClientShowEmployeesController',
      controllerAs: 'clientShowEmployeesCtrl'
    })
    .state('public.security-manager.client.booking-templates', {
      url: '/booking-templates',
      template: require('./booking-templates/template.pug'),
      controller: 'ClientShowBookingTemplatesController',
      controllerAs: 'clientShowBookingTemplatesCtrl'
    })
    .state('public.security-manager.client.notes', {
      url: '/notes',
      template: require('./notes/template.pug'),
      controller: 'ClientShowNotesController',
      controllerAs: 'clientShowNotesCtrl'
    })
    .state('public.security-manager.client.emails', {
      url: '/emails',
      template: require('./emails/template.pug'),
      controller: 'ClientShowEmailsController',
      controllerAs: 'clientShowEmailsCtrl'
    });

}]);
