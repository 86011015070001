/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.employee').factory('EmployeeResource', ['AppModel', function(AppModel) {

  class EmployeeResource extends AppModel {
    static initClass() {
      this.configure({url : '/employees/{{employeeId}}/resources/{{resourceId}}', name : 'employee_resource'});
    }
  }

  EmployeeResource.initClass();
  return EmployeeResource;
}
]);
