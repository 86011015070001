/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').service('bookingTemplateValidation', ['BookingTemplate', BookingTemplate => {

  return {
    getTemplateNameErrors(bookingTemplateId) {
      return [{
        error: 'templateName',
        message: 'Name is not unique',
        asyncValidation(name) {
          return BookingTemplate.validate({name, except: bookingTemplateId});
        }
      }
      ];
    }
  };

}]);
