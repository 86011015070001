/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.country').factory('Country', ['AppModel', function(AppModel) {

  class Country extends AppModel {
    static initClass() {
      this.configure({ url: '/countries', name: 'country' });
    }

    static getCurrent() {
      return this.$get(this.resourceUrl('current'));
    }

    static getCodes() {
      return this.$get(this.resourceUrl('codes'));
    }
  }

  Country.initClass();

  return Country;

}]);
