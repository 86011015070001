/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope'];

const PopupEmployeePayrollEditController = function($scope) {

  const vm = this;

  $scope.popupFormCtrl.popupWidth = 390;
  $scope.popupFormCtrl.title = 'Description of payroll';
  $scope.popupFormCtrl.disableSaveOnInvalid = true;

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      vm.payroll = $scope.payroll;
    }
  });

  $scope.$on('popup.submit', () => {
    $scope.popupCtrl.closePopup(() => $scope.popupCloseCb({ payroll: vm.payroll }))
  });

  return vm;
};

angular.module('popup.employee-payroll')
  .controller('PopupEmployeePayrollEditController', dependencies.concat(PopupEmployeePayrollEditController));
