/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$state', '$scope', 'Employee', 'validationService', 'userValidation', 'notificationService', 'securityProvider',
  'employeeGender'
];

const SignEmployeeRegistrationController = function(
  $state, $scope, Employee, validationService, userValidation, notificationService, securityProvider,
  employeeGender
) {

  const vm = this;

  vm.employee = new Employee({
    securityProviderId: securityProvider.id,
    registrationForm: true,
    user: {}
  });

  vm.employeeGender = employeeGender;
  vm.validationService = validationService;
  vm.userValidation = userValidation;
  vm.getEmailErrors = () => userValidation.getEmailErrors();

  vm.onSubmit = function() {
    if (!$scope.EmployeeRegistrationForm.$valid) { return; }
    return vm.employee.save().then(function() {
      notificationService.notifySuccess('You will receive email with email confirmation instructions shortly!');
      return $state.go('sign.login');
    }).catch(() => notificationService.notifyError('Something went wrong'));
  };

  return vm;
};

angular.module('sign.employee.registration').controller('SignEmployeeRegistrationController',
  dependencies.concat(SignEmployeeRegistrationController));
