const gccsPlanningHeaderDate = {
  controller: 'PlanningHeaderDateController',
  controllerAs: 'planningHeaderDateCtrl',
  template: require('./template.pug'),
  bindings: {
    planningInteractor: '<',
    planningShiftLoader: '<',
    employeeService: '<'
  }
};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .component('gccsPlanningHeaderDate', gccsPlanningHeaderDate);
