/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').service('bookingEndRepeat', function() {

  const END_REPEAT_DATE = 1;
  const END_REPEAT_AFTER = 2;

  const names = {};
  names[END_REPEAT_DATE] = 'Date';
  names[END_REPEAT_AFTER] = 'After';

  return {
    getNames() {
      return names;
    },

    getName(type) {
      return names[type];
    },

    isDate(type) {
      return type === END_REPEAT_DATE;
    },

    isAfter(type) {
      return type === END_REPEAT_AFTER;
    }
  };
});