/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('form').directive('gccsFormInputHolder', () => {
  return {
    restrict: 'A',
    controller: 'FormInputHolderController',
    scope: {
      customErrors: '&',
      errorMessages: '&',
      flashError: '=',
      withoutErrorMessages: '<',
      isNewWrapper: '<'
    }
  };
});
