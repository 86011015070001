/*
 * @author Yaroslav Konoplev <yivo>
 */
const dependencies = ['$document'];

const onBodyClick = $document => {

  return {
    link($scope, $el, attrs) {

      // Keep reference to callback for future unbind
      const callback = function(e) {

        if (!$el[0].contains(e.target)) {
          // invoke given callback
          return $scope.$apply(attrs.onBodyClick);
        }
      };

      $document.bind('click', callback);

      return $scope.$on('$destroy', () =>
        // To unbind exactly the same event pass reference to same callback
        $document.unbind('click', callback)
      );
    }
  }
};

angular.module('shared').directive('onBodyClick', dependencies.concat(onBodyClick));
