/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.system-package').directive('gccsPopupSystemPackageForm', [
  'popupDirectiveConfig',
  popupDirectiveConfig =>
    popupDirectiveConfig.configure({
      bodyTemplate: require('./template.pug')
    })
]);
