/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['AppModel', 'securityProviderPayPeriodStatus'];

const SecurityProviderPayPeriod = (AppModel, securityProviderPayPeriodStatus) => {

  class SecurityProviderPayPeriod extends AppModel {
    static initClass() {
      this.configure({
        url: '/security_provider/pay_period',
        name: 'pay_period',
        singular: true
      });
    }

    nextPeriod() {
      let payPeriodData = _.pick(this, ['type', 'intervals']);
      payPeriodData.status = securityProviderPayPeriodStatus.getTempStatus();
      return SecurityProviderPayPeriod.$put(this.getUrl() + '/next_period', payPeriodData);
    }
  }

  SecurityProviderPayPeriod.initClass();

  return SecurityProviderPayPeriod;

};

angular.module('data.security-provider')
  .factory('SecurityProviderPayPeriod', dependencies.concat(SecurityProviderPayPeriod));
