/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').service('bookingCommentVisibleTo', () => {

  return {
    getVisibleToText(comment) {
      if (comment.visibleToClient && comment.visibleToStaff) {
        return 'Visible to all';
      } else if (comment.visibleToClient) {
        return 'Visible to Client';
      } else if (comment.visibleToStaff) {
        return 'Visible to Staff';
      } else {
        return 'Visible only to security manager';
      }
    }
  };
});
