/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
angular.module('confirmation', []).config(['$stateProvider', $stateProvider =>

  $stateProvider
    .state('email-confirmation', {
      url: '/email-confirmation/:userId/:code',
      controller: 'EmailConfirmationController'
    })

]);
