/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'EntityManager', 'EmployeeLicense', 'employeeLicenseType', 'documentTypeType', 'documentTypeIconService'
];

const EmployeeShowLicensesController = function(
  $scope, EntityManager, EmployeeLicense, employeeLicenseType, documentTypeType, documentTypeIconService
) {

  const vm = this;

  vm.isShownLicensePopup = false;
  vm.isShownViewPopup = false;
  vm.ascendingNameOrder = true;
  vm.employeeLicenseType = employeeLicenseType;
  vm.documentTypeType = documentTypeType;
  vm.documentTypeIcon = documentTypeIconService;

  const entityManager = new EntityManager(EmployeeLicense, {
    params: {
      employeeId: $scope.employeeShowLayoutCtrl.employeeId
    }
  });

  $scope.employeeShowLayoutCtrl.loadEmployee().then(function (employee) {
    vm.employee = employee;
  });

  vm.$onInit = function() {
    entityManager.loadAll();
  };

  vm.removeLicense = function (license, onSuccessCb) {
    entityManager.removeEntity(license, function () {
      if (onSuccessCb) {
        onSuccessCb();
      }
      vm.employee.licensesCount--;
    });
  };

  vm.showLicensePopup = function(license) {
    vm.popupLicense = license || entityManager.createEntity();
    vm.isShownLicensePopup = true;
  };

  vm.closeLicensePopup = function(savedLicense) {
    vm.isShownLicensePopup = false;
    if (savedLicense) {
      entityManager.loadAll().then(licenses => {
        vm.employee.licensesCount = licenses.length;
      });
    }
  };

  vm.showViewPopup = function(license) {
    vm.popupViewLicense = license;
    vm.isShownViewPopup = true;
  };

  vm.closeViewPopup = function () {
    vm.isShownViewPopup = false;
  };

  vm.getLicenses = function () {
    return entityManager.entities;
  };

  vm.toggleNameOrder = function () {
    vm.ascendingNameOrder = !vm.ascendingNameOrder;
  };

  vm.isPassed = function (license) {
    return !license.expireDate || license.isActive;
  };

  vm.isExpired = function (license) {
    return !license.isActive;
  };

  vm.getName = function(license) {
    let { name } = license;
    if (license.training) { name += ` (${license.training.resource.name})`; }
    return name;
  };

  vm.getEditLabel = function(license) {
    if (license.training) {
      return 'Edit training';
    } else {
      return 'Edit license';
    }
  };

  vm.getDeleteLabel = function(license) {
    if (license.training) {
      return 'Delete training';
    } else {
      return 'Delete license';
    }
  };

  return vm;

};

angular.module('public.security-manager.employee')
  .controller('EmployeeShowLicensesController', dependencies.concat(EmployeeShowLicensesController));
