/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['dateService'];

const timeLineConverter = function(dateService) {

  var TimeLineConverter = (function() {
    let INITIAL_OFFSET = undefined;
    let MINUTES_IN_HOUR = undefined;
    let MINUTES_IN_DAY = undefined;
    let MINUTES_PER_PIXEL = undefined;
    let PIXELS_PER_DAY = undefined;
    TimeLineConverter = class TimeLineConverter {
      static initClass() {
  
        INITIAL_OFFSET = 17;
        MINUTES_IN_HOUR = 60;
        MINUTES_IN_DAY = 24 * MINUTES_IN_HOUR;
        MINUTES_PER_PIXEL = 5;
        PIXELS_PER_DAY = MINUTES_IN_DAY / MINUTES_PER_PIXEL;
      }

      getDaysWidth(daysCount) {
        return daysCount * PIXELS_PER_DAY;
      }

      getTotalOffset(offset) {
        return INITIAL_OFFSET + offset;
      }

      getOffsetForDays(days) {
        return this.getTotalOffset(this.getDaysWidth(days));
      }

      getTimeFromOffset(offset, from, withInitialOffset) {
        if (withInitialOffset == null) { withInitialOffset = true; }
        if (!withInitialOffset) { offset -= INITIAL_OFFSET; }
        if (offset < 0) { offset = 0; }
        const date = from ? from.clone() : dateService.today();
        return date.add(offset * MINUTES_PER_PIXEL, 'minutes');
      }

      getOffsetFromTime(time, from) {
        if (!from) { from = dateService.today(); }
        const momentTime = time ? moment(time, dateService.getIsoFullDateFormat()) : moment();
        return this.getTotalOffset(momentTime.diff(from, 'minutes') / MINUTES_PER_PIXEL);
      }
    };
    TimeLineConverter.initClass();
    return TimeLineConverter;
  })();

  return new TimeLineConverter;
};

angular.module('public.timeline').service('timeLineConverter',
  dependencies.concat(timeLineConverter));
