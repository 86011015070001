/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('public.security-manager.manager-profile', [])
  .config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.manager-profile', {
      abstract: true,
      url: '/manager-profile/:securityManagerId',
      template: require('./template.pug'),
      controller: 'ManagerProfileController',
      controllerAs: 'managerProfileCtrl'
    })
    .state('public.security-manager.manager-profile.basic', {
      url: '/basic',
      template: require('./basic/template.pug'),
      controller: 'ManagerProfileBasicController',
      controllerAs: 'managerProfileBasicCtrl'
    })
    .state('public.security-manager.manager-profile.notifications', {
      url: '/notifications',
      template: require('./notifications/template.pug'),
      controller: 'ManagerProfileNotificationsController',
      controllerAs: 'managerProfileNotificationsCtrl'
    })

]);
