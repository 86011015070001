const dependencies = ['$scope', '$element', 'dateService'];

const EmployeeTimelineBlowOutModalController = function($scope, $element, dateService) {

  const vm = this;

  vm.getShiftPeriod = () => {
    return [
      vm.timelineItem.timelineStartTime.format(dateService.getWesternTimeFormat()),
      vm.timelineItem.timelineEndTime.format(dateService.getWesternTimeFormat())
    ].join(' - ');
  };

  const updatePopupPosition = function() {
    if (_.isEmpty(vm.position)) { return; }
    const popup = $element[0];
    const { top } = vm.position;
    popup.style.left = `${vm.position.left}px`;
    return popup.style.top = `${top}px`;
  };

  $scope.$watch('position', updatePopupPosition);

  return vm;

};

angular.module('public.employee.timeline')
  .controller('EmployeeTimelineBlowOutModalController', dependencies.concat(EmployeeTimelineBlowOutModalController));
