const dependencies = [
  '$scope', 'BookingShiftChange', 'dateService', 'bookingRepeatable', 'bookingShiftUnpaidBreak',
  'bookingEndRepeat', 'bookingRepeatFrequency', 'dateWeek', 'bookingChangeService',
  'bookingShiftEmployeeAssignStatus', 'bookingShiftEmployeeClockInOutChange'
];

const PopupBookingShiftFormActivityLogController = function(
  $scope, BookingShiftChange, dateService, bookingRepeatable, bookingShiftUnpaidBreak,
  bookingEndRepeat, bookingRepeatFrequency, dateWeek, bookingChangeService,
  bookingShiftEmployeeAssignStatus, bookingShiftEmployeeClockInOutChange
) {

  const vm = this;

  const FIELD_LABELS = {
    name: 'Name',
    startTime: 'Start day',
    endTime: 'End day',
    repeatable: 'Repeat',
    endRepeat: 'End Repeat',
    repeatUntil: 'End Repeat date',
    repeatTimes: 'Repeat times',
    repeatFrequency: 'Repeat frequency',
    frequencyEvery: 'Repeat every',
    frequencyOn: 'Custom repeat on',
    unpaidBreakInMinutes: 'Unpaid break',
    unchargedBreakInMinutes: 'Uncharged break',
    equipment: 'Required uniform / Equipment',
    resourcesAmount: 'Amnt. of res.',
    chargeRate: 'Charge rate',
    payRate: 'Pay rate',
    contractorPayRate: 'Contractor pay rate',
    employedPayRate: 'Employee pay rate',
    assignStatus: 'Assign status',
    denyReason: 'Reason to deny shift',
    isHighlighted: 'Highlight shift',
    enableClockIn: 'Clock in/out'
  };

  vm.$onInit = function() {
    vm.areLoadedLogs = false;
    BookingShiftChange.query({}, {
      bookingId: $scope.shift.bookingId,
      shiftId: $scope.shift.id
    }).then(function(changes) {
      vm.areLoadedLogs = true;
      initChanges(changes);
    });
  };

  const getChangedValues = function(fieldType, oldValue, newValue, employeeChange = false) {
    const formattedOldValue = getFormattedField(fieldType, oldValue);
    const formattedNewValue = getFormattedField(fieldType, newValue);
    const to = employeeChange ? 'to' : 'To';
    const from = employeeChange ? 'from' : 'From';
    if (!oldValue) {
      return `${to} ${formattedNewValue}`;
    } else if (!newValue) {
      return `${from} ${formattedOldValue}`;
    } else {
      return `${from} ${formattedOldValue} to ${formattedNewValue}`;
    }
  };

  const getFormattedField = function(fieldType, fieldValue) {
    switch (fieldType) {
      case 'name':
      case 'equipment':
      case 'denyReason':
        return `\"${fieldValue}\"`;
      case 'startTime':
      case 'endTime':
        return moment(fieldValue, dateService.getIsoFullDateFormatWithT()).format(dateService.getFullDateFormat());
      case 'repeatUntil':
        return moment(fieldValue, dateService.getIsoDateFormat()).format(dateService.getDateFormat());
      case 'repeatable':
        return bookingRepeatable.getName(fieldValue);
      case 'contractorPayRate':
      case 'employedPayRate':
      case 'chargeRate':
      case 'payRate':
        return `£ ${fieldValue}`;
      case 'unpaidBreakInMinutes':
      case 'unchargedBreakInMinutes':
        return bookingShiftUnpaidBreak.getName(fieldValue);
      case 'endRepeat':
        return bookingEndRepeat.getName(fieldValue);
      case 'repeatFrequency':
        return bookingRepeatFrequency.getName(fieldValue);
      case 'frequencyOn':
        return dateWeek.getNamesArray(fieldValue).join(', ');
      case 'isHighlighted':
        return fieldValue ? 'Yes' : 'No';
      case 'enableClockIn':
        return fieldValue ? 'Enabled' : 'Disabled';
      default:
        return fieldValue;
    }
  };

  const getEmployeeChangeText = function(fieldType, oldValue, newValue) {
    if (fieldType === 'assignStatus') {
      if (newValue === bookingShiftEmployeeAssignStatus.getNewStatus()) {
        return 'Was invited to this shift';
      } else if (
        oldValue === bookingShiftEmployeeAssignStatus.getNewStatus() &&
        newValue === bookingShiftEmployeeAssignStatus.getAssignedStatus()
      ) {
        return 'Accepted invitation to this shift';
      } else if (newValue === bookingShiftEmployeeAssignStatus.getAssignedStatus()) {
        return 'Was assigned to this shift';
      } else if (
        oldValue === bookingShiftEmployeeAssignStatus.getInDeclineProcessStatus() &&
        newValue === bookingShiftEmployeeAssignStatus.getDeclinedStatus()
      ) {
        return 'Request on shift denial was accepted';
      } else if (newValue === bookingShiftEmployeeAssignStatus.getDeclinedStatus()) {
        return 'Declined invitation to this shift';
      } else if (newValue === bookingShiftEmployeeAssignStatus.getInDeclineProcessStatus()) {
        return 'Requested cancel from this shift';
      } else if (newValue === bookingShiftEmployeeAssignStatus.getUnavailableStatus()) {
        return 'Was marked as unavailable';
      } else if (oldValue && (newValue === null)) {
        return 'Was unassigned from this shift';
      } else {
        return getEmployeeChangeTextItem(fieldType, oldValue, newValue);
      }
    } else {
      return getEmployeeChangeTextItem(fieldType, oldValue, newValue);
    }
  };

  const getEmployeeChangeTextItem = (fieldType, oldValue, newValue) => {
    return `${getFieldLabel(fieldType)} ${getChangeName(oldValue, newValue)} ` +
      `${getChangedValues(fieldType, oldValue, newValue, true)}`;
  };

  const getChangeName = function(oldValue, newValue) {
    if (isEmptyChange(oldValue)) {
      return 'created';
    } else if (isEmptyChange(newValue)) {
      return 'deleted';
    } else {
      return 'edited';
    }
  };

  const isEmptyChange = value => (value === null) || (_.isEmpty(value) && !_.isNumber(value));

  const getFullDateAndTime = date => {
    return moment(date, dateService.getIsoFullDateFormatWithTz()).format(dateService.getFullDateFormat());
  };

  const getFieldLabel = field => FIELD_LABELS[field] || field;

  const buildShiftChange = (shiftChange, changesList) => {
    return _.each(shiftChange.changes, function (change, key) {
      changesList.push({
        changeTitle: `${getFieldLabel(key)} ${getChangeName(change[0], change[1])}`,
        changes: [{
          text: getChangedValues(key, change[0], change[1])
        }],
        description: `${shiftChange.user.fullName}, ${getFullDateAndTime(shiftChange.createdAt)}`
      });
      return true;
    });
  };

  const buildEmployeeChange = function(shiftEmployeeChange, changesList) {
    const employeeChanges = [];
    _.each(shiftEmployeeChange.changes, function(change, key) {
      employeeChanges.push({ text: getEmployeeChangeText(key, change[0], change[1]) });
    });
    changesList.push({
      changeTitle: `Employee ${shiftEmployeeChange.name}`,
      changes: employeeChanges.reverse(),
      description: `${shiftEmployeeChange.user.fullName}, ${getFullDateAndTime(shiftEmployeeChange.createdAt)}`
    });
  };

  const buildClockInOutChange = function(shiftEmployeeChange, changesList) {
    let highlightedText, clockTime, description;
    const clockInOutChange = shiftEmployeeChange.clockInOutChange;
    let title = bookingShiftEmployeeClockInOutChange.getNames()[clockInOutChange.type];
    let changeText = shiftEmployeeChange.name;
    if (bookingShiftEmployeeClockInOutChange.isManualType(clockInOutChange.type)) {
      let oldClockTime, newClockTime;
      if (bookingShiftEmployeeClockInOutChange.isManualClockIn(clockInOutChange.type)) {
        [oldClockTime, newClockTime] = clockInOutChange.clockInTime;
      } else {
        [oldClockTime, newClockTime] = clockInOutChange.clockOutTime;
      }
      if (oldClockTime) {
        title += ' time edit';
        changeText += ` from ${parseClockDate(oldClockTime).format(dateService.getFullDateFormatWithComma())} ` +
          `to ${parseClockDate(newClockTime).format(dateService.getFullDateFormatWithComma())}`;
      } else {
        clockTime = parseClockDate(newClockTime);
        changeText += ` at ${clockTime.format(dateService.getFullDateFormatWithComma())}`;
      }
      description = `${shiftEmployeeChange.user.fullName}, ${getFullDateAndTime(shiftEmployeeChange.createdAt)}`;
    } else {
      let shiftTime;
      if (bookingShiftEmployeeClockInOutChange.isClockInType(clockInOutChange.type)) {
        shiftTime = parseDate(clockInOutChange.startTime);
        if (clockInOutChange.clockInTime) { clockTime = parseClockDate(clockInOutChange.clockInTime); }
      } else if (bookingShiftEmployeeClockInOutChange.isClockOutType(clockInOutChange.type)) {
        shiftTime = parseDate(clockInOutChange.endTime);
        if (clockInOutChange.clockOutTime) { clockTime = parseClockDate(clockInOutChange.clockOutTime); }
      }
      if (clockTime) { changeText += ` at ${clockTime.format(dateService.getFullDateFormatWithComma())}`; }
      if (bookingShiftEmployeeClockInOutChange.isTimeErrorType(clockInOutChange.type)) {
        highlightedText = ` - ${dateService.formatTimeDifference(shiftTime, clockTime)}`;
      }
    }
    changesList.push({
      changeTitle: title,
      titleClass: bookingShiftEmployeeClockInOutChange.isErrorType(clockInOutChange.type) ? 'activity-log__danger-highlight' : '',
      changes: [{ text: changeText, highlightedText }],
      description: description
    });
  };

  const parseDate = function(date) {
    return moment(date, dateService.getIsoFullDateFormat());
  };

  const parseClockDate = function(date) {
    return moment(date, dateService.getIsoFullDateFormat());
  };

  const initChanges = function(changes) {
    const changesList = [];
    _.each(changes, function(shiftChange) {
      if (shiftChange.type === bookingChangeService.getShiftChangeType()) {
        buildShiftChange(shiftChange, changesList);
      } else {
        if (shiftChange.clockInOutChange) {
          buildClockInOutChange(shiftChange, changesList);
        } else {
          buildEmployeeChange(shiftChange, changesList);
        }
      }
    });
    vm.bookingShiftChangesList = changesList;
  };

  return vm;

};

angular.module('popup.booking').controller('PopupBookingShiftFormActivityLogController',
  dependencies.concat(PopupBookingShiftFormActivityLogController));
