/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.timeline').service('shiftDndService', [
  'BookingShift', 'timeLineConverter', 'elementService', 'dateService',
  function(BookingShift, timeLineConverter, elementService, dateService) {

    const DEFAULT_MIN_HOURS = 1;
    const DEFAULT_MAX_HOURS = 24;
    const DEFAULT_HOURS = 8;

    let _from = undefined;

    const getShiftOffset = shift => timeLineConverter.getOffsetFromTime(shift.startTime, _from);

    const getShiftWidth = function(shift, offset) {
      let endTime;
      if (offset) {
        endTime = moment(shift.startTime, dateService.getIsoFullDateFormat()).add(offset, 'hours');
      } else {
        ({ endTime } = shift);
      }
      return timeLineConverter.getOffsetFromTime(endTime, _from) - getShiftOffset(shift);
    };

    const getQuickShiftStartTime = function (dateRange) {
      const now = moment();
      if (now.isBetween(dateRange[0], dateRange[1])) {
        return now;
      } else {
        return dateRange[0];
      }
    };

    return {
      setFrom(from) {
        return _from = from;
      },

      getShiftRect(shift) {
        return shift.rect ||(shift.rect =  {
          top : 10,
          height : '',
          left : getShiftOffset(shift),
          width : getShiftWidth(shift)
        });
      },

      updateShiftTime($element, offsetLeft, shift, resource) {
        let endTime;
        shift.resourceId = resource.id;
        const totalOffsetLeft = timeLineConverter.getTotalOffset(elementService.getOffsetLeft($element[0]));
        const startTime = timeLineConverter.getTimeFromOffset(offsetLeft - totalOffsetLeft, _from);
        if (shift.endTime) {
          const momentStartTime = moment(shift.startTime, dateService.getIsoFullDateFormat());
          const momentEndTime = moment(shift.endTime, dateService.getIsoFullDateFormat());
          endTime = momentEndTime.add(startTime.diff(momentStartTime));
        } else {
          endTime = startTime.clone().add(resource.minHours || DEFAULT_HOURS, 'hours');
        }
        shift.startTime = startTime.format(dateService.getIsoFullDateFormat());
        shift.endTime = endTime.format(dateService.getIsoFullDateFormat());
      },

      updateShiftTimeForQuickAdd(shift, resource, dateRange) {
        shift.resourceId = resource.id;
        const startTime = getQuickShiftStartTime(dateRange);
        const endTime = startTime.clone().add(resource.minHours || DEFAULT_HOURS, 'hours');
        shift.startTime = startTime.format(dateService.getIsoFullDateFormat());
        shift.endTime = endTime.format(dateService.getIsoFullDateFormat());
      },

      onResize(shift) {
        const minWidth = getShiftWidth(shift, shift.resource.minHours || DEFAULT_MIN_HOURS);
        const maxWidth = getShiftWidth(shift, shift.resource.maxHours || DEFAULT_MAX_HOURS);
        shift.rect.width = _.clamp(shift.rect.width, minWidth, maxWidth);
        const newStartTime = timeLineConverter.getTimeFromOffset(shift.rect.left, _from, false);
        if (!newStartTime.isSame(moment(shift.startTime, dateService.getIsoFullDateFormat()))) {
          shift.rect.left = timeLineConverter.getOffsetFromTime(shift.endTime, _from) - shift.rect.width;
        }
        const startTime = timeLineConverter.getTimeFromOffset(shift.rect.left, _from, false);
        const endTime = timeLineConverter.getTimeFromOffset(shift.rect.left + shift.rect.width, _from, false);
        shift.startTime = startTime.format(dateService.getIsoFullDateFormat());
        shift.endTime = endTime.format(dateService.getIsoFullDateFormat());
      }
    };
  }
]);
