import './styles.css';

const gccsPopupResourceEditPayRate = {
  controller: 'PopupResourceEditPayRateController',
  template: require('./template.pug'),
  require: {
    gccsPopupsLayout: '^gccsPopupsLayout'
  },
  bindings: {
    rateChange: '<'
  }
};

angular.module('shared-components').component('gccsPopupResourceEditPayRate', gccsPopupResourceEditPayRate);