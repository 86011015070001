const gccsPopupSetRate = {
  controller: 'PopupSetRateController',
  template: require('./template.pug'),
  require: {
    gccsPopupsLayout: '^gccsPopupsLayout'
  },
  bindings: {
    employeeResource: '<',
    onlyOvertimeFields: '<',
    onSubmit: '&'
  }
};

angular.module('shared-components.popup.employee').component('gccsPopupSetRate', gccsPopupSetRate);
