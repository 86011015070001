/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [];

const clipboardService = function() {

  return {
    // https://stackoverflow.com/a/42393479
    copyToClipboard(text, successCb) {
      // create temp element
      const copyElement = document.createElement("span");
      copyElement.appendChild(document.createTextNode(text));
      copyElement.id = 'tempCopyToClipboard';
      angular.element(document.body.append(copyElement));

      // select the text
      const range = document.createRange();
      range.selectNode(copyElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      // copy & cleanup
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      copyElement.remove();
      if (successCb) { successCb(); }
    }
  };
};

angular.module('shared').service('clipboardService', dependencies.concat(clipboardService));