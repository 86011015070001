/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('gccs-ui').directive('gccsMultipleSelect', () => {
  return {
    restrict: 'A',
    require: 'ngModel',
    controller: 'MultipleSelectController',
    controllerAs: 'multipleSelectCtrl',
    template: require('./template.pug'),
    scope: {
      selectModel: '=',
      selectClassName: '@?',
      selectListItems: '=',
      selectedListItems: '<',
      selectedKeys: '<',
      defaultSelectedName: '@',
      onSelect: '&'
    }
  };
});
