const dependencies = [
  '$scope', '$element', 'BookingShiftComment', 'ShiftEmployee', 'BookingShiftRoot',
  'bookingShiftEmployeeAssignStatus', 'notificationService'
];

const EmployeeTimelineShiftModalController = function(
  $scope, $element, BookingShiftComment, ShiftEmployee, BookingShiftRoot,
  bookingShiftEmployeeAssignStatus, notificationService
) {

  const vm = this;

  vm.reasonToDecline = '';

  vm.$onInit = function() {
    initTabs();
  };

  vm.changeTab = function(tab) {
    vm.destroyDeclineMode();
    vm.activeTabKey = tab;
  };

  vm.acceptInvitation = () => {
    saveIfUnlocked(() => {
      ShiftEmployee.acceptInvitation(vm.timelineItem.employee.id, vm.timelineItem.shiftEmployee.id)
        .then(function() {
          notificationService.notifySuccess('Shift was Accepted');
        });
    });
  };

  vm.declineInvitation = () => {
    saveIfUnlocked(() => {
      ShiftEmployee.declineInvitation(vm.timelineItem.employee.id, vm.timelineItem.shiftEmployee.id);
    });
  };

  vm.isShownInviteControls = () => {
    return vm.timelineItem.shiftEmployee && vm.timelineItem.shiftEmployee.assignStatus === bookingShiftEmployeeAssignStatus.getNewStatus();
  };

  vm.isShownDeclineControls = () => {
    return vm.timelineItem.shiftEmployee && vm.timelineItem.shiftEmployee.assignStatus === bookingShiftEmployeeAssignStatus.getAssignedStatus() && !vm.isShiftPassed();
  };

  vm.isShownControls = () => {
    return !vm.isShownDeclineMode && !vm.isShiftPassed() && (vm.isShownInviteControls() || vm.isShownDeclineControls());
  };

  vm.isShiftPassed = function() {
    if (!vm.timelineItem) { return; }
    return moment().isAfter(vm.timelineItem.timelineStartTime);
  };

  vm.initDeclineMode = () => vm.isShownDeclineMode = true;

  vm.destroyDeclineMode = function() {
    vm.isShownDeclineMode = false;
    return vm.reasonToDecline = '';
  };

  vm.applyDeclineShift = function() {
    if (!vm.reasonToDecline.length) { return; }
    const { shiftEmployee } = vm.timelineItem;
    new ShiftEmployee({
      employeeId: shiftEmployee.employeeId,
      id: shiftEmployee.id,
      assignStatus: bookingShiftEmployeeAssignStatus.getInDeclineProcessStatus(),
      denyReason: vm.reasonToDecline
    }).save().then(() => vm.closeTimelineItemPopup());
  };

  vm.isShift = function () {
    return vm.timelineItem.shiftEmployee;
  };

  const initTabs = function() {
    vm.tabs = {
      details: {
        name: 'Shift details'
      },
      comments: {
        name: 'Comments'
      }
    };
    vm.activeTabKey = _.first(_.keys(vm.tabs));
    if (!vm.isShift()) { return; }
    BookingShiftComment.getCount({
      bookingId: vm.timelineItem.shift.booking.id,
      shiftId: vm.timelineItem.shift.id
    }).then(data => {
      vm.tabs.comments.count = data.count;
    });
  };

  const saveIfUnlocked = function(onSave) {
    const shift = new BookingShiftRoot(vm.timelineItem);
    shift.isLocked()
      .then(function(locked) {
        if (locked) {
          notificationService.notifyError('This shift is being edited now. Please try again later');
          throw 'shiftLocked';
        }
      })
      .then(() => onSave())
      .then(() => vm.closeTimelineItemPopup())
      .catch(function(error) {
        if (error === 'shiftLocked') { return; }
        Rollbar.log(error.data.error, error);
      });
  };

  const updatePopupPosition = function() {
    if (_.isEmpty(vm.position)) { return; }
    const popup = $element[0];
    const { top } = vm.position;
    popup.style.left = `${vm.position.left}px`;
    popup.style.top = `${top}px`;
  };

  $scope.$watch('position', updatePopupPosition);

  return vm;

};

angular.module('public.employee.timeline')
  .controller('EmployeeTimelineShiftModalController', dependencies.concat(EmployeeTimelineShiftModalController));
