/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$state', '$location', '$timeout', 'FilterParamsService', 'EntityManager', 'ClientInvoice',
  'ClientInvoiceEmail', 'FileAttachment', 'Client', 'ClientServiceContact', 'dateService', 'invoiceStatus',
  'invoiceType', 'notificationService', 'packageTypeService'
];

const ClientShowInvoicesController = function (
  $scope, $state, $location, $timeout, FilterParamsService, EntityManager, ClientInvoice,
  ClientInvoiceEmail, FileAttachment, Client, ClientServiceContact, dateService, invoiceStatus,
  invoiceType, notificationService, packageTypeService
) {

  const vm = this;

  const filterParamsService = new FilterParamsService({
    order: ['number', 'period'],
    filter: ['period', 'status', 'type', 'name']
  });

  const entityManager = new EntityManager(ClientInvoice, { loadCbName: 'all' });

  vm.filterParams = filterParamsService.getParams();
  vm.invoiceStatus = invoiceStatus;
  vm.invoiceType = invoiceType;
  vm.checkedBookingIds = [];
  vm.isShownEmailPopup = false;
  vm.checkedInvoiceVersions = [];
  vm.isActiveButtonList = false;
  vm.buttonList = [];
  vm.isShownStatusChangePopup = false;
  vm.isLoading = false;

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  let checkedServiceIds = [];
  let checkedBookings = [];

  vm.$onInit = function () {
    packageTypeService.checkProPackage();
    vm.editable = true;
    const locationParams = $location.search();
    if (locationParams.from && locationParams.to) {
      vm.endDateFrom = moment(locationParams.from, dateService.getIsoDateFormat());
      return vm.endDateTo = moment(locationParams.to, dateService.getIsoDateFormat());
    } else {
      const today = dateService.today();
      vm.endDateFrom = today.clone().subtract(30, 'days');
      return vm.endDateTo = today.clone();
    }
  };

  vm.loadMoreInvoices = () => entityManager.loadMore();

  vm.getInvoices = () => entityManager.entities;

  vm.getInvoiceVersions = invoice => {
    return invoice.sortedInvoiceVersions || (invoice.sortedInvoiceVersions = _.sortBy(invoice.invoiceVersions,
      invoiceVersion => -invoiceVersion.id));
  };

  vm.filterInvoices = function () {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    urlParams.clientId = $scope.clientShowLayoutCtrl.clientId;
    if (vm.endDateTo && vm.endDateTo) {
      urlParams.from = moment(vm.endDateFrom, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
      urlParams.to = moment(vm.endDateTo, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
    }
    $location.search(urlParams);
    return entityManager.load(urlParams);
  };

  vm.removeInvoice = invoice => entityManager.removeEntity(invoice);

  vm.toggleNumberOrder = function () {
    vm.filterParams.order.period.ascending = null;
    if (vm.filterParams.order.number.ascending === null) {
      return vm.filterParams.order.number.ascending = true;
    } else {
      return vm.filterParams.order.number.ascending = !vm.filterParams.order.number.ascending;
    }
  };

  vm.togglePeriodOrder = function () {
    vm.filterParams.order.number.ascending = null;
    if (vm.filterParams.order.period.ascending === null) {
      return vm.filterParams.order.period.ascending = true;
    } else {
      return vm.filterParams.order.period.ascending = !vm.filterParams.order.period.ascending;
    }
  };

  vm.toggleButtonList = () => vm.isActiveButtonList = !vm.isActiveButtonList;

  vm.closeButtonList = () => vm.isActiveButtonList = false;

  vm.createInvoiceWithoutBooking = () => {
    return $state.go('public.security-manager-invoice-form', {
      type: invoiceType.getInvoiceType(),
      clientId: $scope.clientShowLayoutCtrl.clientId
    });
  };

  vm.createMultiInvoice = function () {
    if (vm.checkedBookingIds.length > 1) {
      return $state.go('public.security-manager-invoice-form', {
          bookingIds: vm.checkedBookingIds,
          type: invoiceType.getInvoiceType(),
          clientId: $scope.clientShowLayoutCtrl.clientId
        }
      );
    } else {
      return notificationService.notifyError('Please choose at least two bookings.');
    }
  };

  vm.onBookingCheck = function (booking, invoiceVersion) {
    if (vm.checkedInvoiceVersions.indexOf(invoiceVersion) === -1) {
      vm.checkedInvoiceVersions.push(invoiceVersion);
    } else {
      vm.checkedInvoiceVersions = _.without(vm.checkedInvoiceVersions, invoiceVersion);
    }
    const foundBooking = _.find(checkedBookings, checkedBooking => checkedBooking.id === booking.id);
    if (foundBooking) {
      checkedBookings = _.without(checkedBookings, foundBooking);
    } else {
      checkedBookings.push(booking);
    }
    checkedServiceIds = [];
    const checkedBookingIds = [];
    _.each(checkedBookings, function (checkedBooking) {
      if (checkedBooking.serviceId) {
        checkedServiceIds.push(checkedBooking.serviceId);
      }
      checkedBookingIds.push(checkedBooking.id);
    });
    vm.checkedBookingIds = checkedBookingIds;
    return updateButtonList();
  };

  vm.onStatusChange = newVersion => {
    return _.each(entityManager.entities, function (invoice) {
      if (invoice.id === newVersion.invoiceId) {
        return updateVersions(invoice, newVersion);
      }
    });
  };

  vm.openChangeStatusPopup = () => vm.isShownStatusChangePopup = true;

  vm.onChangeMultipleStatus = function (selectedStatus) {
    vm.isShownStatusChangePopup = false;
    if (selectedStatus) {
      setMultipleStatusChange(selectedStatus);
    }
    for (let invoice of Array.from(vm.getInvoices())) {
      for (let invoiceVersion of Array.from(invoice.invoiceVersions)) {
        invoiceVersion.isChecked = false;
      }
    }
    vm.checkedBookingIds = [];
    vm.checkedInvoiceVersions = [];
    return vm.buttonList = [];
  };

  vm.showLoader = () => vm.isLoading = true;

  vm.hideLoader = () => {
    return $timeout(() => vm.isLoading = false);
  };

  const setMultipleStatusChange = function (selectedStatus) {
    const multipleChanges = {
      ids: _.map(vm.checkedInvoiceVersions, 'id'),
      status: selectedStatus
    };
    return ClientInvoice.bulkStatusChange(multipleChanges).then(() => vm.filterInvoices());
  };

  const updateButtonList = function () {
    vm.buttonList = [];
    if (_.every(vm.checkedInvoiceVersions, invoiceVersion =>
      (invoiceVersion.id === null) && _.every(checkedServiceIds,
      serviceId => serviceId === invoiceVersion.booking.serviceId)
    )) {
      vm.buttonList.push({
        text: 'Create multi invoice',
        onClickCb: vm.createMultiInvoice
      });
    }
    if (_.every(vm.checkedInvoiceVersions, invoiceVersion =>
      !!invoiceVersion.id &&
      (invoiceVersion.status === invoiceStatus.getCreatedStatus()) &&
      _.every(checkedServiceIds, serviceId => serviceId === invoiceVersion.booking.serviceId)
    )) {
      vm.buttonList.push({
        text: 'Send selected via e-mail',
        onClickCb: vm.createInvoiceEmail
      });
    }
    if (_.every(vm.checkedInvoiceVersions,
      invoiceVersion => !!invoiceVersion.id && (invoiceVersion.type !== invoiceType.getQuoteType()))) {
      return vm.buttonList.push({
        text: 'Change invoice status',
        onClickCb: vm.openChangeStatusPopup
      });
    }
  };

  vm.createInvoiceEmail = function () {
    const clientInvoiceEmail = new ClientInvoiceEmail();
    clientInvoiceEmail.clientId = $scope.clientShowLayoutCtrl.clientId;
    clientInvoiceEmail.serviceId = checkedServiceIds[0];
    clientInvoiceEmail.attachments = _.map(vm.checkedInvoiceVersions, invoiceVersion => {
      return new FileAttachment({
        fileName: `${invoiceType.getName(invoiceVersion.type)} #${invoiceVersion.invoiceNumber}`,
        invoiceVersionId: invoiceVersion.id
      });
    });
    loadContacts(clientInvoiceEmail, function () {
      vm.popupClientInvoiceEmail = clientInvoiceEmail;
      vm.isShownEmailPopup = true;
    });
  };

  vm.closeClientEmailPopup = function () {
    vm.isShownEmailPopup = false;
    vm.popupClientInvoiceEmail = null;
    vm.checkedBookingIds = [];
    vm.checkedInvoiceVersions = [];
    checkedServiceIds = [];
    checkedBookings = [];
    $timeout(function() {
      vm.filterInvoices();
    }, 500);
    return Array.from(vm.getInvoices()).map((invoice) =>
      Array.from(invoice.invoiceVersions).map((invoiceVersion) =>
        (invoiceVersion.isChecked = false)));
  };

  const loadContacts = (clientInvoiceEmail, onLoadCb) => {
    ClientServiceContact.query({}, {
      clientId: clientInvoiceEmail.clientId,
      serviceId: clientInvoiceEmail.serviceId
    }).then(function (contacts) {
      if (contacts.length > 0) {
        clientInvoiceEmail.receiverEmails = contacts;
        onLoadCb();
      } else {
        loadDefaultContacts(clientInvoiceEmail, onLoadCb);
      }
    }).catch(function() {
      loadDefaultContacts(clientInvoiceEmail, onLoadCb);
    });
  };

  const loadDefaultContacts = function(clientInvoiceEmail, onLoadCb) {
    Client.get(clientInvoiceEmail.clientId).then(function (client) {
      if (client.contactEmail) {
        clientInvoiceEmail.receiverEmails = [{ name: client.name, email: client.contactEmail }];
      } else {
        notificationService.notifyError(`Client ${client.name} has no Email`);
      }
      onLoadCb();
    });
  };

  const updateVersions = (invoice, newVersion) => {
    return _.each(newVersion.bookings, function (booking) {
      if (_.last(invoice.invoiceVersions).booking.id === booking.id) {
        const invoiceVersion = _.clone(newVersion);
        invoiceVersion.booking = booking;
        invoice.invoiceVersions.push(invoiceVersion);
        return invoice.sortedInvoiceVersions.unshift(invoiceVersion);
      }
    });
  };

  $scope.$watch(() => vm.filterParams, _.debounce(vm.filterInvoices, 100), true);

  return vm;
};

angular.module('public.security-manager.client.show.invoice')
  .controller('ClientShowInvoicesController', dependencies.concat(ClientShowInvoicesController));
