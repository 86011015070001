import './styles.css';

angular.module('shared-components').component('gccsToggleButton', {
  controller: 'ToggleButtonController',
  template: require('./template.pug'),
  bindings: {
    label: '@',
    value: '=',
    onToggleCb: '&',
    isRestricted: '<',
    onRestrictCb: '&'
  }
});
