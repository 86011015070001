const gccsSettingsBillingCard = {
  controller: 'SettingsBillingCardInfoController',
  controllerAs: 'settingsBillingCardInfoCtrl',
  template: require('./template.html'),
  bindings: {
    label: '=',
  }
};

angular.module('public.security-manager.settings').component('gccsSettingsBillingCard', gccsSettingsBillingCard);
