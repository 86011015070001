/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$state', 'securityProviderChosenPackageOptions', 'packageTypeService', 'packageDescriptionService'
];

const SignSecurityProviderChoosePlanController = function(
  $scope, $state, securityProviderChosenPackageOptions, packageTypeService, packageDescriptionService
) {

  const vm = this;

  const DEFAULT_EMPLOYEES_NUMBER = 5;
  const NUMBER_OF_EMPLOYEES_MIN = 1;

  vm.numberOfEmployees = DEFAULT_EMPLOYEES_NUMBER;
  vm.packageType = packageTypeService.getFreePackage();
  vm.packageDescriptionService = packageDescriptionService;
  vm.steps = [{
    title: 'Choose your plan'
  }, {
    title: 'Sign up'
  }, {
    title: 'Company details'
  }, {
    title: '2-Step verification'
  }, {
    title: 'Confirmation'
  }];

  let isFormSubmitted = false;

  vm.packageDescriptions = [
    packageDescriptionService.getFreeDescription(),
    packageDescriptionService.getBasicDescription({ priceCb: function() {
      return packageTypeService.getBasicPricePerEmployee() * vm.numberOfEmployees;
    }}),
    packageDescriptionService.getProDescription({ priceCb: function() {
      return packageTypeService.getProPricePerEmployee() * vm.numberOfEmployees;
    }})
  ];

  vm.$onInit = function() {
    const chosenPackageOptions = securityProviderChosenPackageOptions.get();
    if (chosenPackageOptions) {
      vm.numberOfEmployees = chosenPackageOptions.numberOfEmployees;
      vm.packageType = chosenPackageOptions.packageType;
    }
  };

  vm.onFormSubmit = function() {
    if (!$scope.SecurityProviderForm.$valid || isFormSubmitted) { return; }
    isFormSubmitted = true;
    securityProviderChosenPackageOptions.update({
      numberOfEmployees: vm.numberOfEmployees,
      packageType: vm.packageType
    });
    $state.go('sign.security-provider.step1');
  };

  vm.isSelectedPackage = function(packageType) {
    return packageType === vm.packageType;
  };

  vm.selectPackage = function(packageType) {
    vm.packageType = packageType;
  };

  vm.onNumberEmployeesBlur = function() {
    if (vm.numberOfEmployees < NUMBER_OF_EMPLOYEES_MIN) {
      vm.numberOfEmployees = DEFAULT_EMPLOYEES_NUMBER;
    }
  };

  vm.getButtonText = function() {
    let buttonTextParts = ['Start'];
    if (vm.packageType === packageTypeService.getFreePackage()) {
      buttonTextParts.push('starter');
    } else {
      buttonTextParts.push('trial');
      if (vm.packageType === packageTypeService.getBasicPackage()) {
        buttonTextParts.push('basic');
      } else {
        buttonTextParts.push('pro');
      }
    }
    buttonTextParts.push('plan');
    return buttonTextParts.join(' ');
  };

  return vm;

};

angular.module('sign.security-provider')
  .controller('SignSecurityProviderChoosePlanController', dependencies.concat(SignSecurityProviderChoosePlanController));
