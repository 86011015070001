const dependencies = [
  'SecurityManagerNotificationsService', 'currentUserService', 'cableService'
];

const SecurityManagerNotificationsController = function(
  SecurityManagerNotificationsService, currentUserService, cableService
) {

  const vm = this;

  vm.notificationsService = new SecurityManagerNotificationsService(currentUserService.getCurrentProfile());

  vm.$onInit = function() {
    cableService.createSecurityManagerNotificationsChannel(currentUserService.getCurrentProfile().securityProvider.id);
    const channel = cableService.getSecurityManagerNotificationsChannel();
    channel.addCallback('notification-updated', data => vm.notificationsService.notificationUpdated(data));
    channel.addCallback('notification-destroyed', data => vm.notificationsService.notificationDestroyed(data));
    channel.addCallback('notification-created', data => vm.notificationsService.notificationCreated(data));
  };

  return vm;

};

angular.module('public.security-manager.notifications')
  .controller('SecurityManagerNotificationsController', dependencies.concat(SecurityManagerNotificationsController));
