/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['storageService'];

const securityProviderChosenPackageOptions = function(storageService) {

  const STORAGE_KEY = 'chosenPackageOptions';

  return {

    get() {
      const chosenPackageOptions = storageService.get(STORAGE_KEY);
      if (chosenPackageOptions) {
        return JSON.parse(chosenPackageOptions);
      }
    },

    update(chosenPackageOptions) {
      storageService.set(STORAGE_KEY, JSON.stringify(chosenPackageOptions));
    }

  }

};

angular.module('data.security-provider')
  .service('securityProviderChosenPackageOptions', dependencies.concat(securityProviderChosenPackageOptions));