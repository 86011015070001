angular.module('shared').service('phoneNumberService', function() {

  return {
    secretize(phoneNumber) {
      return phoneNumber.replace(/(\+?\d{3})\s?(\d+)(\d{2})/, function(match, capture1, capture2, capture3) {
        return `${capture1} ${new Array(capture2.length).fill('*').join('')} ${capture3}`;
      });
    },
  };
});
