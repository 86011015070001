/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.booking').directive('gccsPopupBookingShiftTimelineBooking', () => {
  return {
    template: require('./template.pug'),
    controllerAs: 'popupShiftTimelineBookingCtrl',
    controller: 'PopupBookingShiftTimelineBookingController',
    bindToController: true,
    scope: {
      editable: '<',
      shiftDecorator: '<',
      resource: '<',
      onEditClickCb: '&',
      onInviteClickCb: '&',
      onMultipleSelectClickCb: '&',
      closePopup: '&'
    }
  };
});
