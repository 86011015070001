const gccsExtendedSearch = {
  controller: 'ExtendedSearchController',
  controllerAs: 'extendedSearchCtrl',
  template: require('./template.pug'),
  bindings: {
    filterType: '@',
    rightAlign: '@',
    clients: '<',
    availability: '<',
    selectedClients: '<',
    resourceId: '<',
    selectedShifts: '<',
    applyFilterCb: '&'
  }
};

angular.module('gccs-ui').component('gccsExtendedSearch', gccsExtendedSearch);
