/*
 * decaffeinate suggestions:
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$element', 'BookingDecorator', 'Booking',
  'RepeatableBooking', 'BookingTemplate', 'BookingShift', 'BookingSeries', 'EntityManager', 'currentUserService',
  'elementService', 'notificationService', 'dateService', 'ShiftTitleEditModeService', 'ShiftEditModeService',
  'bookingShiftEditMode', 'ShiftCommentEditModeService', 'bookingTemplateType', 'profileService',
  'bookingCollapseService', 'confirmService', 'timelineShiftPopupType', 'bookingRepeatable', 'resourceChargeType'
];

const BookingTimeLineBookingController = function(
  $rootScope, $scope, $state, $stateParams, $timeout, $element, BookingDecorator, Booking,
  RepeatableBooking, BookingTemplate, BookingShift, BookingSeries, EntityManager, currentUserService,
  elementService, notificationService, dateService, ShiftTitleEditModeService, ShiftEditModeService,
  bookingShiftEditMode, ShiftCommentEditModeService, bookingTemplateType, profileService,
  bookingCollapseService, confirmService, timelineShiftPopupType, bookingRepeatable, resourceChargeType
) {

  const vm = this;

  const FORM_POPUP_WIDTH = 400;
  const PADDING_RIGHT = 50;
  const BOOKING_TINY_WIDTH = 33;
  const BOOKING_SMALL_WIDTH = 156;

  const DEFAULT_HOURS = 8;

  vm.isSmallBooking = false;
  vm.isShownBookingEditPopup = false;
  vm.isShownSmallBookingEditPopup = false;
  vm.isShownSmallRepeatableBookingPopup = false;
  vm.isShownBookingAddShiftPopup = false;
  vm.isShownEndRepeatableBookingPopup = false;
  vm.isShownCounter = false;
  vm.isShownBookingSplit = false;

  let showDetailsForDShift = undefined;
  let bookingIdToRemove = undefined;

  const bookingManager = new EntityManager(Booking);
  const shiftManager = new EntityManager(BookingShift);

  vm.$onInit = function() {
    vm.booking = vm.bookingDecorator.booking;
    vm.editModeService = ShiftEditModeService();
    vm.commentEditModeService = ShiftCommentEditModeService();
    vm.titleEditModeService = ShiftTitleEditModeService();
    vm.shiftFormPopupPosition = {};
    vm.dateRange = [
      moment(vm.booking.from, dateService.getIsoDateFormat()),
      moment(vm.booking.to, dateService.getIsoDateFormat())
    ];
    vm.isShownAddShiftOption = currentUserService.isSecurityManagerLogged();
    vm.canManage = !vm.onlyView && profileService.managerHasAllowedServices(vm.booking.serviceId);
  };

  vm.$onChanges = function(changes) {
    if (changes.bookingDimensions) {
      $element.css({
        width: vm.bookingDimensions.width + 'px',
        top: vm.bookingDimensions.top + 'px',
        left: vm.bookingDimensions.left + 'px'
      });
      vm.isSmallBooking = vm.bookingDimensions.width <= BOOKING_SMALL_WIDTH;
      $element.toggleClass('mod-small', vm.isSmallBooking);
      $timeout(() => vm.isShownCounter = vm.bookingDimensions.width > BOOKING_TINY_WIDTH);
    }
  };

  vm.toggleCollapsed = function() {
    bookingCollapseService.collapseBookingRow(vm.groupedBookingRow);
    vm.clientDecorator.clearDimensions();
  };

  vm.toggleBookingEditPopup = function() {
    if (vm.isShownBookingEditPopup || !vm.bookingDecorator.hasLockedSeries()) {
      vm.isShownBookingEditPopup = !vm.isShownBookingEditPopup;
    }
  };

  vm.toggleSmallBookingEditPopup = function() {
    if (vm.isShownSmallBookingEditPopup || !vm.bookingDecorator.hasLockedSeries()) {
      vm.isShownSmallBookingEditPopup = !vm.isShownSmallBookingEditPopup;
    }
  };

  vm.toggleSmallRepeatableBookingPopup = function() {
    vm.isShownSmallRepeatableBookingPopup = !vm.isShownSmallRepeatableBookingPopup;
  };

  vm.openEndRepeatableBookingPopup = function() {
    vm.isShownEndRepeatableBookingPopup = true;
  };

  vm.closeEndRepeatableBookingPopup = function(endDate) {
    vm.isShownEndRepeatableBookingPopup = false;
  };

  vm.multipleSelectShift = function($event, dShift) {
    $event.stopPropagation();
    if (vm.seriesIsLocked(dShift)) { return; }
    vm.closeShiftTimelinePopup();
    const selected = vm.bookingTimelineShiftSelector.multipleSelectShift(dShift);
    if (selected) {
      dShift.multipleSelect();
    } else {
      notificationService.notifyInfo('You try to select shifts that belong to different clients');
    }
  };

  vm.multipleDeselectShift = function(dShift) {
    vm.bookingTimelineShiftSelector.multipleDeselectShift(dShift);
    dShift.multipleDeselect();
  };

  const openShiftTimelinePopup = function($event, dShift, element) {
    vm.bookingTimelineShiftSelector.selectShift(dShift);
    vm.popupSeries = vm.timeLineLoader.getSeriesForShift(dShift);
    vm.popupShift = dShift;
    // called from direct click
    if ($event) {
      updateShiftPosition($event.target.closest('.timeline-shift'));
    // called from scroll-to-shift directive
    } else if (element) {
      $timeout(() => updateShiftPosition(element));
    }
    dShift.openShiftTimelinePopup();
    vm.timeLineLoader.closeShiftTimelinePopups(dShift);
  };

  vm.showShiftTimelinePopup = function($event, dShift, element) {
    vm.closeShiftDetails(dShift);
    if (vm.seriesIsLocked(dShift)) { return; }
    if (dShift.isMultipleSelected) {
      vm.multipleDeselectShift(dShift);
    }
    if ($event && ($event.ctrlKey || $event.metaKey) && currentUserService.isSecurityManagerLogged()) {
      vm.multipleSelectShift($event, dShift);
    } else {
      if (!dShift.isShownShiftTimelinePopup) {
        openShiftTimelinePopup($event, dShift, element);
      }
    }
  };

  vm.getLockedBy = function (dShift) {
    return __guard__(vm.timeLineLoader.getSeriesForShift(dShift), x => x.series.lockedBy);
  };

  vm.closeShiftTimelinePopup = function() {
    if (vm.popupShift != null ? vm.popupShift.isShownShiftTimelinePopup : undefined) {
      vm.bookingTimelineShiftSelector.deselectShift(vm.popupShift);
      vm.popupShift.closeShiftTimelinePopup();
    }
  };

  vm.showShiftFormPopup = function($event, dShift, dBooking) {
    vm.closeShiftDetails(dShift);
    if ($event) { $event.stopPropagation(); }
    if (vm.seriesIsLocked(dShift)) { return; }
    vm.closeShiftTimelinePopup();
    (dBooking || vm.bookingDecorator).openShiftFormPopup();
  };

  vm.showShiftFormInvitePopup = function($event, dShift) {
    $event.stopPropagation();
    if (vm.seriesIsLocked(dShift)) { return; }
    vm.closeShiftTimelinePopup();
    vm.isShownEmployeeInvite = true;
    vm.bookingDecorator.openShiftFormPopup();
  };

  vm.closeShiftFormPopup = () => closeShiftFormPopup();

  vm.saveShift = function(shift, afterSaveCb) {
    const bookingShift = new BookingShift(shift);
    return updateShift(vm.popupSeries, bookingShift, function({ withNotification = false } = {}) {
      if (bookingShift.editMode !== bookingShiftEditMode.getOnlyCurrentMode()) {
        if (isShiftInPast(shift)) {
          // repeatable shift creation
          bookingShift.commentEditMode = bookingShiftEditMode.getOnlyCurrentMode();
          bookingShift.titleEditMode = bookingShiftEditMode.getOnlyCurrentMode();
          return bookingShift.save().then(function() {
            if (afterSaveCb) { return afterSaveCb(); }
          });
        } else {
          return vm.commentEditModeService.show(bookingShift, () => saveShiftAfterTitleEditCheck(bookingShift, afterSaveCb));
        }
      } else {
        return bookingShift.save().then(function() {
          if (afterSaveCb) { afterSaveCb(); }
          if (withNotification) {
            notificationService.notifySuccess(`Shift ${bookingShift.name} was updated!`);
          }
        });
      }
    });
  };

  vm.removeShift = function(shift, afterRemoveCb) {
    const bookingShift = new BookingShift(shift);
    updateShift(vm.popupSeries, bookingShift, function ({ withNotification = false } = {}) {
      shiftManager.removeEntity(bookingShift, function () {
        if (afterRemoveCb) {
          afterRemoveCb();
        }
        const dSeries = vm.popupSeries;
        vm.closeShiftFormPopup();
        if (withNotification) {
          notificationService.notifySuccess(`Shift ${bookingShift.name} was deleted!`);
        }
        vm.timeLineLoader.removeShiftFor(dSeries, bookingShift);
      }, { editMode: bookingShift.editMode });
    });
  };

  vm.removeBooking = function() {
    if (vm.bookingDecorator.hasLockedSeries()) { return; }
    bookingManager.removeEntity(new Booking(vm.booking), function () {
      vm.timeLineLoader.removeBookingFor(vm.clientDecorator, vm.bookingDecorator);
    });
  };

  vm.gotoBookingEdit = function() {
    if (vm.bookingDecorator.hasLockedSeries()) { return; }
    Booking.clone(vm.booking.id).then(function(clonedBooking) {
      bookingIdToRemove = clonedBooking.id;
      $state.go(vm.bookingEditRoute, { bookingId : bookingIdToRemove });
    });
  };

  vm.gotoRepeatableBookingEdit = function() {
    RepeatableBooking.clone(vm.booking.repeatedFrom.id).then(function(clonedRepeatableBooking) {
      $state.go(vm.repeatableBookingEditRoute, { id: clonedRepeatableBooking.id });
    });
  };

  vm.gotoBookingTemplateCreate = function() {
    if (vm.bookingDecorator.hasLockedSeries()) { return; }
    new BookingTemplate({ bookingId : vm.booking.id }).save().then(function (bookingTemplate) {
      $state.go(vm.bookingEditRoute, {
        bookingId: bookingTemplate.id,
        template: bookingTemplateType.getCreateType()
      });
    });
  };

  vm.seriesIsLocked = function (dShift) {
    return dShift && !!__guard__(vm.timeLineLoader.getSeriesForShift(dShift), x => x.series.lockedBy);
  };

  vm.copyBooking = function () {
    Booking.copy(vm.booking.id).then(function (copiedBooking) {
      bookingIdToRemove = copiedBooking.id;
      $state.go(vm.bookingEditRoute, { bookingId: bookingIdToRemove, isCopy: true });
    });
  };

  vm.splitBooking = function () {
    vm.isShownBookingSplit = true;
  };

  vm.closeBookingSplit = function () {
    vm.isShownBookingSplit = false;
  };

  vm.showShiftDetails = function (dShift) {
    showDetailsForDShift = dShift;
  };

  vm.isShownShiftDetails = function (dShift) {
    return (showDetailsForDShift != null ? showDetailsForDShift.id : undefined) === dShift.id;
  };

  vm.getShiftDetailsPositionClass = function(dShift) {
    const shiftIsOnLeft = (vm.bookingDimensions.left + parseFloat(dShift.shiftDimensions.left)) < PADDING_RIGHT;
    const shiftIsOnTop = getTimelineTopOffset() < getShiftDetailsHeight(dShift);
    const verticalDetailsPosition = shiftIsOnTop ? 'bottom' : 'top';
    const horizontalDetailsPosition = shiftIsOnLeft ? 'right' : 'left';
    return `mod-${verticalDetailsPosition}-${horizontalDetailsPosition}`;
  };

  vm.gotoComments = function($event, dShift) {
    $event.stopPropagation();
    if (vm.seriesIsLocked(dShift)) { return; }
    vm.closeShiftDetails(dShift);
    vm.popupShift = dShift;
    updateShiftPosition($event.target.closest('.timeline-shift'));
    vm.timeLineLoader.closeShiftTimelinePopups(dShift);
    vm.activeShiftTab = 'comments';
    vm.bookingDecorator.openShiftFormPopup();
  };

  vm.titleFullyFits = function(dShift) {
    const shiftTitle = $element[0].querySelector(`[data-shift-timeline-id='${dShift.id}'] .shift-title`);
    if (!shiftTitle) { return false; }
    return shiftTitle.offsetWidth >= shiftTitle.scrollWidth;
  };

  vm.toggleClickDetails = function(dShift, $event) {
    $event.stopPropagation();
    dShift.clickDetails = !dShift.clickDetails;
  };

  vm.closeShiftDetails = function(dShift) {
    dShift.clickDetails = false;
    dShift.hoverDetails = false;
  };

  vm.setHoverDetails = function(dShift) {
    if (vm.popupShift != null ? vm.popupShift.isShownShiftTimelinePopup : undefined) { return; }
    dShift.hoverDetails = true;
  };

  vm.clearHoverDetails = function (dShift) {
    dShift.hoverDetails = false;
  };

  vm.isHighlighted = function (dShift) {
    return currentUserService.isSecurityManagerLogged() && dShift.shift.isHighlighted;
  };

  vm.canEditBooking = function() {
    return currentUserService.isSecurityManagerLogged() || currentUserService.isClientManagerLogged();
  };

  vm.initAddShiftSelectResource = function () {
    vm.isShownBookingAddShiftPopup = true;
  };

  vm.showConditionallyPopup = function (dShift, element, openType) {
    if (timelineShiftPopupType.isShiftFormPopup(openType)) {
      $timeout(function () {
        vm.popupShift = dShift;
        vm.popupSeries = vm.timeLineLoader.getSeriesForShift(dShift);
        updateShiftPosition(element);
        vm.showShiftFormPopup(false, dShift);
      });
    } else {
      vm.showShiftTimelinePopup(null, dShift, element);
    }
  };

  vm.onApplyNewShiftResource = function (resourceId) {
    new BookingSeries({ resourceId: resourceId, bookingId: vm.booking.id }).save().then((newSeries) => {
      const startTime = moment(vm.booking.from, dateService.getIsoDateFormat());
      new BookingShift({
        bookingId: vm.booking.id,
        bookingSeriesId: newSeries.id,
        resourceId: resourceId,
        enableClockIn: !resourceChargeType.isPerUnit(newSeries.resource.chargeType) && vm.booking.enableClockIn,
        startTime: startTime.format(dateService.getIsoFullDateFormatWithTz()),
        endTime: startTime
          .clone()
          .add(newSeries.resource.minHours || DEFAULT_HOURS, 'hours')
          .format(dateService.getIsoFullDateFormatWithTz())
      }).save().then(onAddNewBookingShiftLoad);
    });
  };

  vm.closeQuickAddShiftPopup = function () {
    vm.isShownBookingAddShiftPopup = false;
  };

  vm.isBookingBlocked = function() {
    return !!vm.booking.lockedBy;
  };

  vm.isBookingBlockDisabled = function() {
    return vm.isBookingBlocked() && (
      !currentUserService.isSecurityManagerLogged() || !(
        vm.booking.lockedBy.id === currentUserService.getCurrentUser().id ||
        currentUserService.getCurrentProfile().isAccountOwner() ||
        currentUserService.getCurrentProfile().isAdmin()
      )
    );
  };

  vm.unblockBooking = function() {
    if (vm.isBookingBlockDisabled()) { return; }
    confirmService.confirm('Confirm booking unlock', 'Do you really want to unlock booking from editing?', 'Yes', () => {
      new Booking(vm.booking).unlock();
    });
  };

  vm.showSummary = function() {
    if (!vm.isBookingBlocked()) {
      vm.isShownSummary = true;
    }
  };

  const onAddNewBookingShiftLoad = function (newBookingShift) {
    vm.closeQuickAddShiftPopup();
    const bookingFromMoment = moment(vm.booking.from, dateService.getIsoDateFormat());
    if (bookingFromMoment.isBefore(vm.timeLineInteractor.from)) {
      openOutsideRangeAddedShiftPopup(newBookingShift, bookingFromMoment);
    } else {
      openInsideRangeAddedShiftPopup(newBookingShift);
    }
  };

  const openOutsideRangeAddedShiftPopup = function (newBookingShift, bookingFromMoment) {
    $state.go('public.security-manager.schedule-manager.timeline.booking', {
      goToDate: bookingFromMoment.format(dateService.getDateFormat()),
      goToShiftId: newBookingShift.id,
      openShiftType: timelineShiftPopupType.getShiftFormPopup()
    });
  };

  const openInsideRangeAddedShiftPopup = function (newBookingShift) {
    $rootScope.$on('timeline-shift-added', function (event, shiftId) {
      if (newBookingShift.id !== shiftId) { return; }
      $timeout(() => {
        const foundDShift = _.chain(vm.bookingDecorator.shiftRows).map('dShifts').flatten().find(['id', newBookingShift.id]).value();
        if (foundDShift) {
          vm.popupShift = foundDShift;
          vm.popupSeries = vm.timeLineLoader.getSeriesForShift(foundDShift);
          const shiftElement = document.querySelector(`[data-shift-timeline-id="${foundDShift.id}"]`);
          const scrollContainer = shiftElement.closest('.timeline-data');
          scrollContainer.scrollTop += elementService.getOffsetTop(shiftElement) - elementService.getOffsetTop(scrollContainer);
          updateShiftPosition(shiftElement);
          vm.showShiftFormPopup(false, foundDShift);
        }
      });
    });
  };

  const saveShiftAfterTitleEditCheck = function (shift, afterSaveCb) {
    vm.titleEditModeService.show(shift, () => shift.save().then(function () {
      if (afterSaveCb) {
        afterSaveCb();
      }
    }));
  };

  const closeShiftFormPopup = function() {
    vm.bookingDecorator.closeShiftFormPopup();
    vm.isShownEmployeeInvite = false;
    vm.popupSeries = undefined;
    vm.popupShift = undefined;
    vm.activeShiftTab = undefined;
  };

  const updateShift = function(dSeries, shift, cb) {
    if (dSeries.dShifts.length > 1) {
      if (isShiftInPast(shift)) {
        shift.editMode = bookingShiftEditMode.getOnlyCurrentMode();
        cb({ withNotification: true });
      } else {
        vm.editModeService.show(shift, cb);
      }
    } else {
      if (bookingRepeatable.isNone(shift.repeatable)) {
        shift.editMode = bookingShiftEditMode.getOnlyCurrentMode();
        cb();
      } else {
        vm.editModeService.show(shift, cb);
      }
    }
  };

  const isShiftInPast = function(shift) {
    let entTimeMoment = moment(shift.endTime, dateService.getFullDateFormat());
    if (entTimeMoment.isValid()) {
      return entTimeMoment.isBefore(moment());
    } else {
      return moment(shift.endTime, dateService.getIsoFullDateFormatWithT(true)).isBefore(moment());
    }
  };

  const updateShiftPosition = function(shiftElement) {
    if (!shiftElement) { return; }
    const left = elementService.getOffsetLeft(shiftElement);
    const top = elementService.getOffsetTop(shiftElement);
    const formPopupLeft = left + shiftElement.offsetWidth;
    const rightDiff = Math.min(0, window.innerWidth - (FORM_POPUP_WIDTH + formPopupLeft + PADDING_RIGHT));
    vm.shiftFormPopupPosition = {
      left: formPopupLeft + rightDiff,
      top: top + (shiftElement.offsetHeight / 2)
    };
  };

  const getShiftDetailsHeight = function(dShift) {
    if (dShift.shift.comments.length > 0) {
      return 300;
    } else {
      return 70;
    }
  };

  const getTimelineTopOffset = function() {
    let element = $element[0];
    let offsetTop = 0;
    while (!element.classList.contains('timeline-data')) {
      offsetTop += element.offsetTop;
      element = element.offsetParent;
    }
    return offsetTop;
  };

  $scope.$on('logout', function() {
    if (bookingIdToRemove) {
      new Booking({ id : bookingIdToRemove }).remove();
    }
  });

  return vm;

};

angular.module('public.timeline').controller('BookingTimeLineBookingController',
  dependencies.concat(BookingTimeLineBookingController));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
