/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.booking').directive('gccsPopupBookingShiftFormEmployeesInvite', () => {
  return {
    restrict: 'A',
    controller: 'PopupBookingShiftFormEmployeesInviteController',
    controllerAs: 'employeesInviteCtrl',
    template: require('./template.pug'),
    scope: {
      clientId: '<',
      shift: '<',
      shiftEmployeeManager: '<',
      conflictedShiftEmployeeManager: '<',
      assignForShiftEmployee: '<',
      backCb: '&',
      timeLineLoader: '<',
      isShiftIssueInviteEmployee: '<',
      onClose: '&'
    }
  };
});
