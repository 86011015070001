const dependencies = [];

const employeeInvoiceStatus = function() {

  const STATUS_CREATED = 1;
  const STATUS_SENT = 2;
  const STATUS_APPROVED = 3;
  const STATUS_PAID = 4;
  const STATUS_REJECTED = 5;
  const STATUS_PAYMENT_DUE = 6;
  const STATUS_OPENED = 10;
  const STATUS_PENDING = 20;

  const WITH_PAID_DATE = [STATUS_PAID, STATUS_PAYMENT_DUE];

  const LIST_CLASSES = {
    [STATUS_CREATED]: 'mod-red',
    [STATUS_SENT]: 'mod-yellow',
    [STATUS_APPROVED]: 'mod-lilac',
    [STATUS_PAID]: 'mod-green',
    [STATUS_PAYMENT_DUE]: 'mod-red',
    [STATUS_OPENED]: 'mod-yellow'
  };

  const EMPLOYEE_NAMES = {
    [STATUS_CREATED]: 'Created',
    [STATUS_SENT]: 'Sent',
    [STATUS_OPENED]: 'Open'
  };

  const SECURITY_MANAGER_NAMES = {
    [STATUS_CREATED]: 'Created',
    [STATUS_SENT]: 'Received',
    [STATUS_APPROVED]: 'Approved',
    [STATUS_PAYMENT_DUE]: 'Payment due',
    [STATUS_PAID]: 'Paid',
    [STATUS_OPENED]: 'Open'
  };

  const SECURITY_MANAGER_NAMES_EDITABLE = {
    [STATUS_CREATED]: 'Created',
    [STATUS_SENT]: 'Received',
    [STATUS_APPROVED]: 'Approved',
    [STATUS_PAYMENT_DUE]: 'Payment due',
    [STATUS_PAID]: 'Paid'
  };

  const SECURITY_MANAGER_NAMES_EDITABLE_FROM_OPENED = {
    [STATUS_OPENED]: 'Open',
    [STATUS_APPROVED]: 'Approved'
  };

  const EMPLOYEE_STATUS_TRANSITIONS = {
    [STATUS_CREATED]: {
      [STATUS_CREATED]: 'Created',
      [STATUS_SENT]: 'Sent'
    },
    [STATUS_SENT]: {
      [STATUS_SENT]: 'Sent'
    }
  };

  const REPORT_NAMES = {
    [STATUS_CREATED]: 'Created',
    [STATUS_SENT]: 'Received',
    [STATUS_APPROVED]: 'Approved',
    [STATUS_PAYMENT_DUE]: 'Payment due',
    [STATUS_PAID]: 'Paid',
    [STATUS_OPENED]: 'Open',
    [STATUS_PENDING]: 'Pending'
  };

  const EMPLOYEE_FILTER_NAMES = _.assign({ 0: 'All' }, EMPLOYEE_NAMES);
  const SELECTED_EMPLOYEE_FILTER_NAMES = _.assign({ 0: 'Status' }, EMPLOYEE_NAMES);
  const SECURITY_MANAGER_FILTER_NAMES = _.assign({ 0: 'All' }, SECURITY_MANAGER_NAMES);
  const SELECTED_SECURITY_MANAGER_FILTER_NAMES = _.assign({ 0: 'Status' }, SECURITY_MANAGER_NAMES);

  return {
    getSecurityManagerNames() {
      return SECURITY_MANAGER_NAMES_EDITABLE;
    },
    getSecurityManagerNamesFromOpened() {
      return SECURITY_MANAGER_NAMES_EDITABLE_FROM_OPENED;
    },

    getEmployeeTransitionNames(currentStatus) {
      return EMPLOYEE_STATUS_TRANSITIONS[currentStatus] || {};
    },

    getEmployeeFilterNames() {
      return EMPLOYEE_FILTER_NAMES;
    },

    getSecurityManagerFilterNames() {
      return SECURITY_MANAGER_FILTER_NAMES;
    },

    getSelectedEmployeeFilterNames() {
      return SELECTED_EMPLOYEE_FILTER_NAMES;
    },

    getSelectedSecurityManagerFilterNames() {
      return SELECTED_SECURITY_MANAGER_FILTER_NAMES;
    },

    getEmployeeName(status) {
      return _.assign({
        [STATUS_APPROVED]: 'Approved',
        [STATUS_PAYMENT_DUE]: 'Payment due',
        [STATUS_PAID]: 'Paid'
      }, EMPLOYEE_NAMES)[status];
    },

    getSecurityManagerName(status) {
      return SECURITY_MANAGER_NAMES[status];
    },

    getReportName(status) {
      return REPORT_NAMES[status];
    },

    getReportNames() {
      return REPORT_NAMES;
    },

    getListClasses(status) {
      return LIST_CLASSES[status];
    },

    getSentStatus() {
      return STATUS_SENT;
    },

    getCreatedStatus() {
      return STATUS_CREATED;
    },

    getApprovedStatus() {
      return STATUS_APPROVED;
    },

    getPaidStatus() {
      return STATUS_PAID;
    },

    getPaymentDueStatus() {
      return STATUS_PAYMENT_DUE;
    },

    getRejectedStatus() {
      return STATUS_REJECTED;
    },

    isPaid(status) {
      return status === STATUS_PAID;
    },

    isOpened(status) {
      return status === STATUS_OPENED;
    },

    withPaidDate(status) {
      return WITH_PAID_DATE.includes(status);
    }
  };
};

angular.module('data.employee-invoice').service('employeeInvoiceStatus', dependencies.concat(employeeInvoiceStatus));
