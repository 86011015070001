const dependencies = ['SecurityManagerNotification'];

const SecurityManagerNotificationsLoader = (SecurityManagerNotification) => {

  const DEFAULT_LIMIT = 20;
  const DEFAULT_OFFSET = 20;

  return class SecurityManagerNotificationsLoader {

    constructor(securityManager) {
      this.securityManagerId = securityManager.id;
      this.isLoading = false;
      this.endedLoading = false;
    }

    load(queryParams, paginationParams, onSuccessCb) {
      if (paginationParams == null) {
        paginationParams = { limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET };
      }
      this.isLoading = true;
      return SecurityManagerNotification
        .getNotifications(this.securityManagerId, _.merge(queryParams, paginationParams))
        .then((notifications) => {
          this.isLoading = false;
          this.endedLoading = notifications.length === 0;
          if (onSuccessCb) {
            onSuccessCb(notifications);
          }
        });
    }

    loadNotification(notification) {
      return SecurityManagerNotification.get({ securityManagerId: this.securityManagerId, id: notification.id });
    }

    loadCounts(params) {
      if (params == null) {
        params = {};
      }
      return SecurityManagerNotification.getCount(this.securityManagerId, params);
    }

    isDisabledLoading() {
      return this.endedLoading || this.isLoading;
    }

  }

};

angular.module('public.security-manager.notifications')
  .factory('SecurityManagerNotificationsLoader', dependencies.concat(SecurityManagerNotificationsLoader));
