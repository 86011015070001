/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').directive('scrollBottom', () => {
  return {
    restrict : 'A',
    link($scope, $el) {
      const holder = $el.parent()[0];
      return setTimeout(() => holder.scrollTop = holder.scrollHeight
      , 0);
    }
  }
});
