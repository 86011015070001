/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['$scope'];

const GccsPdfViewerController = function($scope) {

  const vm = this;

  $scope.isLoading = true;
  $scope.hasPdfError = false;
  vm.isDownloadingFile = false;

  $scope.onLoad = () => $scope.isLoading = false;
  $scope.onError = () => {
    $scope.isLoading = false;
    $scope.hasPdfError = true;
    $scope.$apply();
  }
  return vm;
};

angular.module('gccs-ui').controller('GccsPdfViewerController', dependencies.concat(GccsPdfViewerController));
