const dependencies = [
  '$scope', '$sce', 'FileAttachment', 'Employee', 'Upload', 'sessionService', 'editorInitializer',
  'notificationService', 'currentUserService', 'emailStatus', 'confirmService'
];

const PopupEmailEmployeeFormController = function(
  $scope, $sce, FileAttachment, Employee, Upload, sessionService, editorInitializer,
  notificationService, currentUserService, emailStatus, confirmService
) {

  const vm = this;

  $scope.popupFormCtrl.title = 'Creating new e-mail';
  $scope.popupFormCtrl.saveButtonText = 'Send e-mail';
  $scope.popupFormCtrl.withoutButtons = true;
  $scope.popupFormCtrl.popupWidth = 970;

  const EDITOR_SELECTOR = '#employeeEmailEditor';

  let isEmailSaving = false;
  let fakeIdsCounter = -1;

  vm.signatureEmailHTML = $sce.trustAsHtml(currentUserService.getCurrentProfile().emailSignature);

  vm.removeAttachment = attachment => _.remove(vm.employeeEmail.attachments, attachment);

  vm.isDisabledForm = function() {
    if (!vm.employeeEmail.attachments) { return false; }
    for (let attachment of vm.employeeEmail.attachments) {
      if ((attachment.isFinished !== undefined) && (attachment.isFinished !== true)) { return true; }
    }
    return false;
  };

  vm.loadEmployees = text => Employee.autocomplete({}, { 'order[full_name]': 'asc', fullName: text });

  vm.toggleFilter = () => {
    return vm.isFilterOpen = !vm.isFilterOpen;
  };

  vm.closeFilter = () => vm.isFilterOpen = false;

  vm.setSelectedFromFilter = function(selectedEmployees) {
    _.each(selectedEmployees, selectedEmployee => selectedEmployee.hoverText = getHoverText(selectedEmployee.user));
    return vm.employeeEmail.receiverEmails = _.map(selectedEmployees, (employee) => {
      return {
        id: employee.id,
        fullName: employee.user.fullName,
        email: employee.user.email
      }
    });
  };

  vm.onTagAdd = ($tag) => {
    if (!$tag.id) {
      $tag.id = fakeIdsCounter--;
      $tag.email = $tag.fullName;
    }
    $tag.hoverText = getHoverText($tag);
  };

  const addAttachment = function(attachmentFile) {
    const attachment = new FileAttachment({
      fileName: attachmentFile.name,
      fileSize: attachmentFile.size
    });
    if (!vm.employeeEmail.attachments) { vm.employeeEmail.attachments = []; }
    vm.employeeEmail.attachments.push(attachment);
    return Upload.upload({
      url: attachment.getUrl(),
      data: {
        attachment: {
          file: attachmentFile
        }
      },
      headers: {
        'X-Session-Id': sessionService.getSessionId()
      }
    }).then((response => {
      return attachment.id = response.data.id;
    }), (() => {
      return Rollbar.log(`Employee email attachment upload on ${window.location.href}`);
    }), e => {
      return attachment.progress = parseInt((100.0 * e.loaded) / e.total);
    }).finally(() => attachment.isFinished = true);
  };

  const getForm = () => $scope[$scope.popupFormCtrl.formName];

  const saveEmployeeEmail = function(status, withPopupClose) {
    if (isEmailSaving) { return; }
    isEmailSaving = true;
    vm.employeeEmail.status = status;
    return vm.employeeEmail.save().then(function(employeeEmail) {
      if (status === emailStatus.getDraftStatus()) {
        notificationService.notifySuccess('E-mail was saved as draft');
      } else if (status === emailStatus.getSentStatus()) {
        notificationService.notifySuccess('E-mail successfully scheduled for delivery');
      }
      const onPopupClose = () => $scope.popupCloseCb({employeeEmail});
      if (withPopupClose) {
        return $scope.popupCtrl.closePopup(onPopupClose);
      } else {
        return onPopupClose();
      }
    });
  };

  const initEmployeeEmail = function() {
    $scope.employeeEmail.receiverEmails = _.map($scope.employeeEmail.receivers, function(receiver) {
      receiver.employee.hoverText = getHoverText(receiver.employee);
      if (receiver.employee.id === undefined) receiver.employee.id = fakeIdsCounter--;
      return receiver.employee;
    });
    return vm.employeeEmail = angular.copy($scope.employeeEmail);
  };

  const initRemoveButtonClick = function() {
    if (!$scope.onRemoveClickCb) { return; }
    return $scope.$popup[0].querySelector('[data-remove-button]').addEventListener('click', $scope.onRemoveClickCb);
  };

  const getHoverText = tag => {
    return `\
      ${tag.fullName}
      ${tag.email}\
    `;
  };

  const showValidationError = function() {
    notificationService.notifyError('Please enter all fields');
    $scope.popupFormCtrl.resetForm();
  };

  const isFormValid = function() {
    return !!vm.employeeEmail.text;
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      isEmailSaving = false;
      initRemoveButtonClick(); // didn't work with ng-click
      initEmployeeEmail();
      editorInitializer.init(EDITOR_SELECTOR);
    }
  });

  $scope.$on('popup.close', function() {
    vm.employeeEmail.text = tinymce.activeEditor.getContent();
    if (getForm().$valid && !emailStatus.isSent(vm.employeeEmail.status)) {
      saveEmployeeEmail(emailStatus.getDraftStatus(), false);
    }
  });

  $scope.$on('popup.submit', () => {
    vm.employeeEmail.text = tinymce.activeEditor.getContent();
    if (isFormValid()) {
      confirmService.confirm('Email', 'Are you sure you want to send this email?', 'Yes', () => {
        saveEmployeeEmail(emailStatus.getSentStatus(), true);
      }, () => $scope.popupFormCtrl.resetForm());
    } else {
      showValidationError();
    }
  });

  $scope.$on('popup.error-submit', () => showValidationError());

  $scope.$watch('attachment', function() {
    if ($scope.attachment) {
      addAttachment($scope.attachment);
    } else if (getForm().$error.maxSize) {
      notificationService.notifyError('You can upload only 25 mb');
    }
  });

  return vm;

};

angular.module('popup.email')
  .controller('PopupEmailEmployeeFormController', dependencies.concat(PopupEmailEmployeeFormController));
