/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['SecurityProvider', 'defaultConfig', 'storageService', 'redirectService'];

const SignSecurityProviderSuccessController = function(SecurityProvider, defaultConfig, storageService, redirectService) {

  const vm = this;

  vm.isShownResendMailSuccessMsg = false;
  vm.isEmailResent = false;

  let securityProvider;

  vm.$onInit = function() {
    new SecurityProvider({ id: storageService.get('securityProviderId') }).withManager().then(function(loadedSecurityProvider) {
      securityProvider = loadedSecurityProvider;
      sendEmail();
    }).catch(function() {
      redirectService.redirectToMain();
    });
  };

  vm.resendEmail = function() {
    vm.isEmailResent = false;
    sendEmail().then(function() {
      vm.isEmailResent = true;
    });
  };

  const sendEmail = function() {
    return securityProvider.activate().then(function() {
      storageService.remove('securityProviderId');
      storageService.remove(defaultConfig.sessionKey);
    });
  };

  return vm;
};

angular.module('sign.security-provider')
  .controller('SignSecurityProviderSuccessController', dependencies.concat(SignSecurityProviderSuccessController));
