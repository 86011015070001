/*
 * @author Igor Serpak <robobee>
 */
const employeeDayOffRequestStatus = function() {

  const NEW = 1;
  const APPROVED = 2;
  const REJECTED = 3;

  const names = {};
  names[NEW] = 'New';
  names[APPROVED] = 'APPROVED';
  names[REJECTED] = 'REJECTED';

  const ACTION_TAKEN_STATUSES = [APPROVED, REJECTED];

  return {
    getNames() {
      return names;
    },

    getNewStatus() {
      return NEW;
    },

    getApprovedStatus() {
      return APPROVED;
    },

    getRejectedStatus() {
      return REJECTED;
    },

    isNew(status) {
      return status === NEW;
    },

    isApproved(status) {
      return status === APPROVED;
    },

    isRejected(status) {
      return status === REJECTED;
    },

    isActionTaken(status) {
      return ACTION_TAKEN_STATUSES.includes(status);
    }
  };
};

angular.module('data.employee').service('employeeDayOffRequestStatus', employeeDayOffRequestStatus);
