/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$q', 'BookingShiftRoot', 'PlanningShiftDecorator', 'Booking', 'dateService'];

const PlanningShiftLoader = ($q, BookingShiftRoot, PlanningShiftDecorator, Booking, dateService) => {

  const ISO_DATE_FORMAT = dateService.getIsoDateFormat();

  return class PlanningShiftLoader {

    constructor(planningInteractor) {
      this._lockSeries = this._lockSeries.bind(this);
      this.allDShifts = {};
      this.planningInteractor = planningInteractor;
      this.clearShifts();
    }

    setLoadParams(loadParams) {
      return this.loadParams = loadParams;
    }

    loadShifts(withLock) {
      if (withLock === null) { withLock = true; }
      if (_.isEmpty(this.loadParams)) { return; }
      this.planningInteractor.isShiftsLoading = true;
      return $q.when()
        .then(() => {
          if (withLock) { return this._lockSeries(); }
        }).then(() => {
          return this._loadShifts();
        });
    }

    reloadShifts() {
      this.allDShifts = {};
      const checkedShiftIds = [];
      for (let dShift of Array.from(this.dShifts)) {
        if (dShift.isChecked) { checkedShiftIds.push(dShift.shift.id); }
      }
      return this.loadShifts(false).then(dShifts => {
        return (() => {
          const result = [];
          for (let dShift of Array.from(dShifts)) {
            result.push(dShift.isChecked = ~checkedShiftIds.indexOf(dShift.shift.id));
          }
          return result;
        })();
      });
    }

    clearShifts() {
      this.loadParams = {};
      this.dShifts = [];
      return this.lockedClients = []; // [clientId_serviceId]{ from: to: }
    }

    uncheckShifts() {
      return Array.from(this.dShifts).map((shift) => {
        return shift.isChecked = false;
      });
    }

    getShift(shiftId) {
      if (this.allDShifts[shiftId]) {
        return $q.when(this.allDShifts[shiftId]);
      } else {
        return BookingShiftRoot.planning({shiftId}).then(shifts => {
          return this._getDShift(shifts[0]);
        });
      }
    }

    _loadShifts() {
      return BookingShiftRoot.planning(_.merge({
        from: this.planningInteractor.from.format(ISO_DATE_FORMAT),
        to: this.planningInteractor.to.format(ISO_DATE_FORMAT)
      }, this.loadParams)).then(shifts => {
        this.dShifts = this._groupShifts(shifts);
        this.planningInteractor.isShiftsLoading = false;
        return this.dShifts;
      });
    }

    _lockSeries() {
      let newBlockedRange;
      if (!this.loadParams.clientId) { return; }
      const lockKey = `${this.loadParams.clientId}_${this.loadParams.serviceId}`;
      const blockedRange = this.lockedClients[lockKey];
      if (blockedRange) {
        newBlockedRange = {
          from: moment.min(blockedRange.from, this.planningInteractor.from),
          to: moment.max(blockedRange.to, this.planningInteractor.to)
        };
        if (newBlockedRange.from.isSame(blockedRange.from) && newBlockedRange.to.isSame(blockedRange.to)) { return; }
      } else {
        newBlockedRange = {
          from: this.planningInteractor.from,
          to: this.planningInteractor.to
        };
      }
      return Booking.lockSeries({
        clientId: this.loadParams.clientId,
        serviceId: this.loadParams.serviceId,
        from: newBlockedRange.from.format(ISO_DATE_FORMAT),
        to: newBlockedRange.to.format(ISO_DATE_FORMAT)
      }).then(() => {
        return this.lockedClients[lockKey] = newBlockedRange;
      });
    }

    _groupShifts(shifts) {
      const dShifts = [];
      for (let shift of Array.from(shifts)) {
        const newDShift = this._getDShift(shift);
        let previousRow = 0;
        for (let dShift of Array.from(dShifts)) {
          if (!(newDShift.endTimeMoment.diff(dShift.startTimeMoment) <= 0) && !(newDShift.startTimeMoment.diff(dShift.endTimeMoment) >= 0)) {
            previousRow = Math.max(previousRow, dShift.row);
          }
        }
        newDShift.row = previousRow + 1;
        dShifts.push(newDShift);
      }
      return dShifts;
    }

    _getDShift(shift) {
      return this.allDShifts[shift.id] || (this.allDShifts[shift.id] = new PlanningShiftDecorator(shift));
    }

  }

};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .factory('PlanningShiftLoader', dependencies.concat(PlanningShiftLoader));
