/*
 * @author Maksym Shutiak <maxshutyak>
 */
const gccsPlanningTutorialMessage = {
  template: require('./template.pug'),
  transclude: true,
  bindings: {
    tutorialService: '<',
    step: '@',
    text: '@',
    link: '@'
  }
};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .component('gccsPlanningTutorialMessage', gccsPlanningTutorialMessage);
