/*
 * @author Oleksandr Papka <papkaos>
 */
const gccsReportsHrEmployeeStatus = {
  controller: 'ReportsHrEmployeeStatusController',
  template: require('./template.pug'),
  bindings: {
    status: '<'
  }
};

angular.module('public.security-manager.reports.hr')
  .component('gccsReportsHrEmployeeStatus', gccsReportsHrEmployeeStatus);
