/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
// todo rename module
angular.module('public.security-manager.invoices', [
  'public.invoice',
  'public.security-manager.invoices.payroll'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.invoices', {
      url: '/invoices',
      abstract: true,
      template: require('./template.pug'),
      controller: 'InvoicesController',
      controllerAs: 'invoicesCtrl'
    })
    .state('public.security-manager.invoices.clients', {
      url: '/clients',
      template: require('./clients/template.pug'),
      controller: 'InvoicesClientsController',
      controllerAs: 'invoicesClientsCtrl'
    })
    .state('public.security-manager.invoices.employees', {
      url: '/employees?{ employmentType, numberFilter, employeeId, from, to }',
      template: require('./employees/template.pug'),
      controller: 'InvoicesEmployeesController',
      controllerAs: 'invoicesEmployeesCtrl',
      reloadOnSearch: false
    })

]);
