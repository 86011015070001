/*
 * decaffeinate suggestions:
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$timeout', '$scope', '$element', 'bookingShiftEmployeeAssignStatus', 'BookingShiftComment',
  'notificationService', 'BookingShiftRoot'
];

const PopupEmployeeTimelineShiftController = function(
  $timeout, $scope, $element, bookingShiftEmployeeAssignStatus, BookingShiftComment,
  notificationService, BookingShiftRoot
) {

  const vm = this;

  const SCROLL_OFFSET = 10;

  const setPopupView = () => setPopupPosition(true);

  const setPopupVisibility = child => child.style.visibility = '';

  const setPopupPosition = (function() {
    const el = $element[0];
    const child = $element[0].children[0];
    return updateVisibility =>
      $timeout(function() {
        const bottomFromTop = el.getBoundingClientRect().top + child.offsetHeight;
        const invisibleHeight = bottomFromTop - window.innerHeight;
        const invisibleWidth = child.getBoundingClientRect().right - window.innerWidth;
        if ((invisibleHeight > 0) || (invisibleWidth > 0)) {
          if (invisibleHeight > 0) { child.style.top = `-${invisibleHeight}px`; }
          if (invisibleWidth > 0) { child.style.left = `-${invisibleWidth + SCROLL_OFFSET}px`; }
        }
        if (updateVisibility) {
          return setPopupVisibility(child);
        }})
    ;})();

  const getShiftCommentsCount = () =>
    BookingShiftComment.getCount({bookingId : vm.shift.booking.id, shiftId : vm.shift.id}).then(data => vm.shift.commentsCount = data.count)
  ;

  setPopupView();

  vm.isShiftDetailsOpen = true;
  vm.isCommentsOpen = false;

  vm.getEmployeeStatusNames = () => bookingShiftEmployeeAssignStatus.getEmployeeStatusNames(vm.shiftEmployee);

  vm.getStatusLabel = () => bookingShiftEmployeeAssignStatus.getStatusName(vm.shiftEmployee.assignStatus);

  vm.openComments = function() {
    if (vm.isCommentsOpen) { return; }
    vm.isShiftDetailsOpen = false;
    vm.isCommentsOpen = true;
    return setPopupPosition();
  };

  vm.changeCommentsCount = function(count) {
    vm.shift.commentsCount = count;
    return setPopupPosition();
  };

  vm.openShiftDetails = function() {
    if (vm.isShiftDetailsOpen) { return; }
    vm.isShiftDetailsOpen = true;
    vm.isCommentsOpen = false;
    return setPopupPosition();
  };

  vm.bookingShiftEmployeeAssignStatus = bookingShiftEmployeeAssignStatus;

  vm.closePopup = () => vm.popupCloseCb();

  vm.getUnpaidBreakText = function() {
    if (__guard__(vm.shiftEmployeeCopy != null ? vm.shiftEmployeeCopy.shift : undefined, x => x.unpaidBreakInMinutes)) {
      return `${vm.shiftEmployeeCopy.shift.unpaidBreakInMinutes} min`;
    } else {
      return 'No';
    }
  };

  vm.isShownDenyReason = () => bookingShiftEmployeeAssignStatus.isInDeclineProcess(vm.shiftEmployeeCopy != null ? vm.shiftEmployeeCopy.assignStatus : undefined);

  vm.isInDeclineProcess = () => bookingShiftEmployeeAssignStatus.isInDeclineProcess(vm.shiftEmployee.assignStatus);

  vm.hasChanges = () =>
    vm.shiftEmployeeCopy && vm.shiftEmployee &&
    ((vm.shiftEmployeeCopy.assignStatus !== vm.shiftEmployee.assignStatus) ||
    (vm.shiftEmployeeCopy.denyReason !== vm.shiftEmployee.denyReason))
  ;

  vm.saveShift = function() {
    if (!vm.canSave()) { return; }
    const shift = new BookingShiftRoot(vm.shift);
    return shift.isLocked()
      .then(function(locked) {
        if (locked) {
          notificationService.notifyError('This shift is being edited now. Please try again later');
          throw 'shiftLocked';
        }}).then(() => vm.shiftEmployeeCopy.save()).then(() => vm.popupCloseCb({shiftEmployee : vm.shiftEmployeeCopy})).catch(function(error) {
        if (error === 'shiftLocked') { return; }
        return Rollbar.log(error.data.error, error);
    });
  };

  vm.canSave = () =>
    !bookingShiftEmployeeAssignStatus.isInDeclineProcess(vm.shiftEmployeeCopy.assignStatus) ||
      vm.shiftEmployeeCopy.denyReason
  ;

  $scope.$watch(vm.isShownDenyReason, setPopupPosition);

  $scope.$watch(vm.shiftEmployee, function() {
    vm.shiftEmployeeCopy = angular.copy(vm.shiftEmployee);
    vm.shift = vm.shiftEmployee.shift;
    return getShiftCommentsCount();
  });

  return vm;
};

angular.module('popup.employee').controller('PopupEmployeeTimelineShiftController',
  dependencies.concat(PopupEmployeeTimelineShiftController));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
