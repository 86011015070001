/**
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const securityProviderPayPeriodMonth = function() {

  const THIS_MONTH = 1;
  const NEXT_MONTH = 2;

  const names = {
    [THIS_MONTH]: 'This month',
    [NEXT_MONTH]: 'Next month'
  };

  return {
    getNames() {
      return names;
    },

    getName(type) {
      return names[type];
    },

    getThisMonth() {
      return THIS_MONTH;
    },

    getNextMonth() {
      return NEXT_MONTH;
    }
  };
};

angular.module('public.security-manager.settings')
  .service('securityProviderPayPeriodMonth', securityProviderPayPeriodMonth);
