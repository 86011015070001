/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').factory('FilterParamsService', ['$location', function($location) {

  let ORDER_ASCENDING = undefined;
  let ORDER_DESCENDING = undefined;

  class FilterParamsService {
    static initClass() {
      ORDER_ASCENDING = 'asc';
      ORDER_DESCENDING = 'desc';
    }

    constructor(options) {
      this.options = options;
      this.initFilterParams();
    }

    getFilterUrlParams(asObject = false) {
      const urlParams = {};
      _.each(this.params.filter, function(value, key) {
        if (value) { urlParams[key] = value; }
      });
      _.each(this.params.order, (value, key) => {
        if (value.ascending !== null) {
          const order = value.ascending ? ORDER_ASCENDING : ORDER_DESCENDING;
          if (asObject) {
            urlParams.order = urlParams.order || {};
            urlParams.order[key] = order;
          } else {
            urlParams[this._getOrderParamName(key)] = order;
          }
        }
      });
      return urlParams;
    }

    initFilterParams() {
      const locationParams = $location.search();
      const params = {
        filter : _.pick(locationParams, this.options.filter),
        order : {}
      };
      _.each(this.options.order, (param, index) => {
        let ascendingValue;
        if (index === 0) {
          ascendingValue = locationParams[this._getOrderParamName(param)] !== ORDER_DESCENDING;
        } else {
          ascendingValue = null;
        }
        return params.order[param] =
            {ascending : ascendingValue};
      });
      return this.params = _.defaultsDeep(params, this.options.default);
    }

    getParams() {
      return this.params;
    }

    _getOrderParamName(param) {
      return `order[${param}]`;
    }
  }

  FilterParamsService.initClass();
  return FilterParamsService;
}]);
