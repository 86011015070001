/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const EmployeeAvailability = AppModel => {

  class EmployeeAvailability extends AppModel {
    static initClass() {
      this.configure({ url: '/employees/{{ employeeId }}/availabilities/{{ id }}', name: 'availability' });
    }
  }

  EmployeeAvailability.initClass();

  return EmployeeAvailability;

};

angular.module('data.employee').factory('EmployeeAvailability', dependencies.concat(EmployeeAvailability));
