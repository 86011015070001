const employeeNationalInsuranceCategory = function() {

  const UNKNOWN = 0;
  const CATEGORYA = 1;
  const CATEGORYB = 2;
  const CATEGORYC = 3;
  const CATEGORYD = 4;
  const CATEGORYE = 5;

  const names = {};
  names[UNKNOWN] = 'Unknown';
  names[CATEGORYA] = 'Category A';
  names[CATEGORYB] = 'Category B';
  names[CATEGORYC] = 'Category C';
  names[CATEGORYD] = 'Category D';
  names[CATEGORYE] = 'Category E';

//   const knownStartingBasisNames = {};
//   knownStartingBasisNames[CATEGORYA] = 'Category A';
//   knownStartingBasisNames[CATEGORYB] = 'Category B';
//   knownStartingBasisNames[CATEGORYC] = 'Category C';
//   knownStartingBasisNames[EXISTINGEMPLOYEE] = 'Existing Employee';

  return {
    getNames() {
      return names;
    },

    getName(nationalInsuranceCategory) {
      return names[nationalInsuranceCategory];
    },

    // getKnownStartingBasisNames() {
    //   return knownStartingBasisNames;
    // },

    getUnknown() {
      return UNKNOWN;
    },

    getCategoryA() {
      return CATEGORYA;
    },

      getCategoryB() {
        return CATEGORYB;
      },

      getCategoryC() {
        return CATEGORYC;
      },

      getCategoryD() {
        return CATEGORYD;
      },

      getCategoryE() {
        return CATEGORYE;
      }
  };
};

angular.module('data.employee').service('employeeNationalInsuranceCategory', employeeNationalInsuranceCategory);