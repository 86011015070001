/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  'EmployeeSidebarEmployeeList', 'bookingShiftEmployeeAssignStatus', 'BookingShiftEmployee', '$q',
  'dateService', 'confirmService', 'notificationService', 'cableService',
  'ShiftEmployeeActionsService', '$timeout', 'bookingShiftEmployeeAvailability',
  'currentUserService'
];

const EmployeeSidebar = (
  EmployeeSidebarEmployeeList, bookingShiftEmployeeAssignStatus, BookingShiftEmployee, $q,
  dateService, confirmService, notificationService, cableService,
  ShiftEmployeeActionsService, $timeout, bookingShiftEmployeeAvailability,
  currentUserService
) => {

  const SEARCH_LIMIT = 20;

  return class EmployeeSidebar {

    constructor(shiftSelector) {
      this.employeePredicate = this.employeePredicate.bind(this);
      this.applyFilter = this.applyFilter.bind(this);
      this.approveShiftCancellation = this.approveShiftCancellation.bind(this);
      this.rejectShiftCancellation = this.rejectShiftCancellation.bind(this);
      this.assign = this.assign.bind(this);
      this.markUnavailable = this.markUnavailable.bind(this);
      this.blowOut = this.blowOut.bind(this);
      this.declineInvitation = this.declineInvitation.bind(this);
      this.invite = this.invite.bind(this);
      this.unassign = this.unassign.bind(this);
      this.shiftSelector = shiftSelector;
      this.shiftEmployeeAvailability = bookingShiftEmployeeAvailability;
      this.queryParams = null;
      this.isFilterApplied = false;
      this._init();
      this._subscribeToBookingTimeline();
    }

    // [shifts] -> sets state
    selectShifts(shifts) {
      this.selectedShifts = [].concat(shifts);
      if (!this.sidebarOpen) {
        return;
      }
      return this.employeesService.setSelectedShifts(this.selectedShifts);
    }

    deselectShifts() {
      this.selectedShifts = undefined;
      this.employeesService.clearSelectedShifts();
      return this.employeesService.showSearchEmployees();
    }

    searchEmployees() {
      let clientId;
      const queryParams = { fullName: this.searchQuery, limit: SEARCH_LIMIT };
      if (clientId = this.shiftSelector.getClientId()) {
        queryParams.clientId = clientId;
      }
      return this.employeesService.searchEmployees(queryParams);
    }

    employeePredicate(employee) {
      if (!this.selectedShifts || !this.isFilterApplied || _.isEmpty(this.queryParams)) {
        return true;
      }
      return this.employeesService.employeePredicate(employee, this.queryParams);
    }

    applyFilter(queryParams) {
      this.queryParams = queryParams;
      return this.isFilterApplied = true;
    }

    approveShiftCancellation(employee, shift) {
      let shiftEmployee;
      if (!(shiftEmployee = this.employeesService.findShiftEmployee(employee.id, shift.id))) {
        return;
      }
      return this.actionsService.approveShiftCancellation(new BookingShiftEmployee(shiftEmployee), employee.id)
        .then(function () {
          notificationService.notifySuccess(`${employee.user.fullName}'s cancellation from ${shift.name} approved`);
        }).catch(function () {
          notificationService.notifyError(`Can't approve ${employee.user.fullName} cancellation from ${shift.name}`);
        });
    }

    rejectShiftCancellation(employee, shift) {
      let shiftEmployee;
      if (!(shiftEmployee = this.employeesService.findShiftEmployee(employee.id, shift.id))) {
        return;
      }
      return this.actionsService.rejectShiftCancellation(new BookingShiftEmployee(shiftEmployee), employee.id)
        .then(function () {
          notificationService.notifySuccess(`${employee.user.fullName}'s cancellation from ${shift.name} rejected`);
        }).catch(function () {
          notificationService.notifySuccess(`Can't reject ${employee.user.fullName} cancellation from ${shift.name}`);
        });
    }

    blowOut(employee, shift) {
      return confirmService.confirm('Confirm blow out', 'This employee was blew out?', 'Yes', () => {
        let shiftEmployee;
        if (!(shiftEmployee = this.employeesService.findShiftEmployee(employee.id, shift.id))) {
          return;
        }
        return this.actionsService.blowOut(new BookingShiftEmployee(shiftEmployee), this._getBookingId(shift))
          .then(function () {
            notificationService.notifySuccess(`${employee.user.fullName} marked as blew out for ${shift.name}`);
          }).catch(function () {
            notificationService.notifyError(`Can't mark ${employee.user.fullName} as blew out to ${shift.name}`);
          });
      });
    }

    assign(employee, shift) {
      let existingShiftEmployee, promise;
      if (!employee.isSubContractor && (existingShiftEmployee = this.employeesService.findShiftEmployee(employee.id, shift.id))) {
        promise = this.actionsService.assign(new BookingShiftEmployee(existingShiftEmployee), this._getBookingId(shift));
      } else {
        promise = this.actionsService.assign(null, this._getBookingId(shift), shift.id, employee.id);
      }
      return promise
        .then(function () {
          notificationService.notifySuccess(`${employee.user.fullName} assigned to ${shift.name}`);
        }).catch(function () {
          notificationService.notifyError(`Can't assign ${employee.user.fullName} to ${shift.name}`);
        });
    }

    markUnavailable(employee, shift) {
      return this.actionsService
        .markUnavailable(null, this._getBookingId(shift), shift.id, employee.id)
        .then(function () {
          notificationService.notifySuccess(`${employee.user.fullName} marked as unavailable for ${shift.name}`);
        }).catch(function () {
          notificationService.notifyError(`Can't mark ${employee.user.fullName} as unavailable to ${shift.name}`);
        });
    }

    declineInvitation(employee, shift) {
      let existingShiftEmployee, promise;
      if ((existingShiftEmployee = this.employeesService.findShiftEmployee(employee.id, shift.id))) {
        promise = this.actionsService.declineInvitation(new BookingShiftEmployee(existingShiftEmployee), this._getBookingId(shift));
      } else {
        promise = this.actionsService.declineInvitation(null, this._getBookingId(shift), shift.id, employee.id);
      }
      return promise
        .then(function () {
          notificationService.notifySuccess(`${employee.user.fullName}'s invitation to ${shift.name} cleared`);
        }).catch(function () {
          notificationService.notifyError(`Can't clear invitation for ${employee.user.fullName} to ${shift.name}`);
        });
    }

    invite(employee, shift) {
      let existingShiftEmployee, promise;
      if ((existingShiftEmployee = this.employeesService.findShiftEmployee(employee.id, shift.id))) {
        promise = this.actionsService.invite(new BookingShiftEmployee(existingShiftEmployee), this._getBookingId(shift));
      } else {
        promise = this.actionsService.invite(null, this._getBookingId(shift), shift.id, employee.id);
      }
      return promise
        .then(function () {
          notificationService.notifySuccess(`${employee.user.fullName} invited to ${shift.name}`);
        }).catch(function () {
          notificationService.notifyError(`Can't invite ${employee.user.fullName} to ${shift.name}`);
        });
    }

    unassign(employee, shift) {
      const shiftEmployee = this.employeesService.findShiftEmployee(employee.id, shift.id);
      if (!shiftEmployee) {
        return $q.when();
      }
      return $q(resolve => {
        return $timeout(() => {
          return confirmService.confirm(
            'Confirm unassign',
            `Do you want to unassign ${employee.user.fullName} from shift \"${shift.name}\"?`,
            'Yes',
            () => {
              return this.actionsService.unassign(new BookingShiftEmployee(shiftEmployee), this._getBookingId(shift))
                .then(function () {
                  notificationService.notifySuccess(`${employee.user.fullName} unassigned from ${shift.name}`);
                }).catch(function () {
                  notificationService.notifyError(`Can't unassign ${employee.user.fullName} from ${shift.name}`);
                }).finally(() => resolve(employee));
            },
            () => resolve(employee));
        });
      });
    }

    getOvertime(shift) {
      const maxStartDate = dateService.getMax(
        _.first(this.selectedShifts).shift.startTime,
        shift.startTime,
        dateService.getIsoFullDateFormat()
      );
      const minEndDate = dateService.getMin(
        _.first(this.selectedShifts).shift.endTime,
        shift.endTime,
        dateService.getIsoFullDateFormat()
      );
      return Math.ceil(minEndDate.diff(maxStartDate, 'hours', true));
    }

    toggleSidebar() {
      if (this.sidebarOpen) {
        return this._closeSidebar();
      } else {
        return this._openSidebar();
      }
    }

    toggleSidebarSearch() {
      if (this.sidebarOpen) { return; }
      return this._openSidebar();
    }

    _findShiftEmployee(shiftEmployees, employee) {
      return _.find(shiftEmployees, shiftEmployee => shiftEmployee.employeeId === employee.id);
    }

    _init() {
      this._initActionsService();
      this._initEmployeesService();
      this.sidebarOpen = false;
      return this.selectedShifts = undefined;
    }

    _initActionsService() {
      this.actionsService = new ShiftEmployeeActionsService();
      this.actionsService.on('invite', this.invite);
      this.actionsService.on('assign', this.assign);
      this.actionsService.on('declineInvitation', this.declineInvitation);
      this.actionsService.on('markUnavailable', this.markUnavailable);
      this.actionsService.on('unassign', this.unassign);
      this.actionsService.on('approveShiftCancellation', this.approveShiftCancellation);
      this.actionsService.on('rejectShiftCancellation', this.rejectShiftCancellation);
      this.actionsService.on('blowOut', this.blowOut);
    }

    _initEmployeesService() {
      return this.employeesService = new EmployeeSidebarEmployeeList(this.actionsService);
    }

    _openSidebar() {
      this.sidebarOpen = true;
      return this.employeesService.setSelectedShifts(this.selectedShifts);
    }

    _closeSidebar() {
      return this.sidebarOpen = false;
    }

    shiftReloaded(dShift) {
      if (!this.sidebarOpen || !((this.selectedShifts != null ? this.selectedShifts.length : undefined) > 0)) {
        return;
      }
      return this.employeesService.shiftUpdated(dShift);
    }

    _selectedShiftsIntersectWith(startTime, endTime) {
      startTime = moment(startTime, dateService.getIsoFullDateFormat());
      endTime = moment(endTime, dateService.getIsoFullDateFormat());
      for (let shift of Array.from(this.selectedShifts)) {
        if (!shift.endTimeMoment.isBefore(startTime) && !shift.startTimeMoment.isAfter(endTime)) {
          return true;
        }
      }
      return false;
    }

    _subscribeToBookingTimeline() {
      cableService.getBookingTimelineChannel().addCallback('shift_employee_destroyed', shiftEmployee => {
        if (this._needUpdate(shiftEmployee)) {
          this.employeesService.shiftEmployeeDestroyed(shiftEmployee);
        }
      });
      cableService.getBookingTimelineChannel().addCallback('day_off', dayOff => {
        if (this._needUpdate(dayOff)) {
          this.employeesService.dayOffUpdated(dayOff);
        }
      });
      cableService.getBookingTimelineChannel().addCallback('day_off_destroyed', dayOff => {
        if (this._needUpdate(dayOff)) {
          this.employeesService.dayOffDestroyed(dayOff);
        }
      });
      cableService.getBookingTimelineChannel().addCallback('sick_day', sickDay => {
        if (this._needUpdate(sickDay)) {
          this.employeesService.sickDayUpdated(sickDay);
        }
      });
      cableService.getBookingTimelineChannel().addCallback('sick_day_destroyed', sickDay => {
        if (this._needUpdate(sickDay)) {
          this.employeesService.sickDayDestroyed(sickDay);
        }
      });
      cableService.getBookingTimelineChannel().addCallback('availability', availability => {
        if (this._needUpdate(availability)) {
          this.employeesService.availabilityUpdated(availability);
        }
      });
      cableService.getBookingTimelineChannel().addCallback('availability_destroyed', availability => {
        if (this._needUpdate(availability)) {
          this.employeesService.availabilityDeleted(availability);
        }
      });
    }

    _needUpdate(entity) {
      if (!this.sidebarOpen || !((this.selectedShifts != null ? this.selectedShifts.length : undefined) > 0)) {
        return false;
      }
      return this._selectedShiftsIntersectWith(entity.start_time, entity.end_time);
    }

    _areRequiredRolesPresent(employee) {
      let selectedRoles = [];
      let areRolesPresent = true;

      this._buildSelectedRolesArray(selectedRoles);

      if (selectedRoles.length) {
        if (employee.roles && employee.roles.length) {
          const intersectedRoles = _.intersectionBy(selectedRoles, employee.roles, 'id');
          if (!intersectedRoles.length) {
            areRolesPresent = false;
          }
        } else {
          areRolesPresent = false;
        }
      }
      return areRolesPresent;
    }

    _buildSelectedRolesArray(selectedRoles) {
      let selectedRolesArray = [];
      let isFirstIteration = true;

      this.selectedShifts.forEach((selectedShift) => {
        if (selectedShift.shift.resource.roles.length) {
          selectedRolesArray.push(selectedShift.shift.resource.roles);
        }
      });
      selectedRolesArray.forEach((selectedRoleItem) => {
        if (isFirstIteration) {
          selectedRoles.push(...selectedRoleItem);
          isFirstIteration = false;
        } else {
          selectedRoles.forEach((selectedRole) => {
            const foundRole = _.find(selectedRoleItem, ['id', selectedRole.id]);
            if (!foundRole) {
              _.pull(selectedRoles, selectedRole);
            }
          });
        }
      });
    }

    isShownActionDropdown(employee) {
      return !currentUserService.getCurrentProfile().isSupervisor() && this.selectedShifts &&
        !employee.differingStatus && !employee.perUnit && this._areRequiredRolesPresent(employee);
    }

    isShownInvite() {
      return _.every(this.selectedShifts, (selectedShift) => {
        const startTimeMoment = selectedShift.startTimeMoment || selectedShift.shift.startTimeMoment;
        return startTimeMoment && startTimeMoment.isAfter(moment());
      });
    }

    _getBookingId(shift) {
      return shift.bookingId || shift.booking.id;
    }

  };

};

angular
  .module('public.security-manager.schedule-manager.timeline')
  .factory('EmployeeSidebar', dependencies.concat(EmployeeSidebar));
