const dependencies = [
  '$scope', '$location', 'ClientDocument', 'EntityManager', 'FilterParamsService',
  'documentTypeType', 'documentTypeIconService', 'downloadService', 'dateService'
];

const ClientShowDocumentsController = function(
  $scope, $location, ClientDocument, EntityManager, FilterParamsService,
  documentTypeType, documentTypeIconService, downloadService, dateService
) {

  const vm = this;

  vm.isShownDocumentPopup = false;
  vm.isShownViewPopup = false;
  vm.documentTypeType = documentTypeType;
  vm.documentTypeIcon = documentTypeIconService;

  const entityManager = new EntityManager(ClientDocument, {
    params: {
      clientId: $scope.clientShowLayoutCtrl.clientId
    }
  });
  const filterParamsService = new FilterParamsService({
    order: ['name']
  });

  $scope.clientShowLayoutCtrl.loadClient().then(client => vm.client = client);

  vm.filterParams = filterParamsService.getParams();

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  vm.removeDocument = (document, onSuccessCb) => {
    entityManager.removeEntity(document, function () {
      if (onSuccessCb) {
        onSuccessCb();
      }
      vm.client.documentsCount--;
    });
  };

  vm.loadMoreDocuments = () => entityManager.loadMore();

  vm.getDocuments = () => entityManager.entities;

  vm.showDocumentPopup = function(document) {
    if (!$scope.clientShowLayoutCtrl.editable) { return; }
    vm.popupDocument = document || entityManager.createEntity();
    vm.isShownDocumentPopup = true;
  };

  vm.closeDocumentPopup = function(savedDocument) {
    vm.isShownDocumentPopup = false;
    if (savedDocument) {
      vm.client.documentsCount++;
      filterDocuments();
    }
  };

  vm.showViewPopup = function(document) {
    vm.popupViewDocument = document;
    return vm.isShownViewPopup = true;
  };

  vm.closeViewPopup = () => vm.isShownViewPopup = false;

  vm.isExpiredDocument = function(document) {
    if (document.expireDate) {
      return dateService.today().isAfter(moment(document.expireDate, dateService.getIsoDateFormat()));
    } else {
      return false;
    }
  };

  vm.downloadDocument = function(document) {
    downloadService.downloadWithoutCache(document.file, document.fileName);
  };

  const filterDocuments = function() {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    $location.search(urlParams);
    return entityManager.load(urlParams);
  };

  $scope.$watch(() => vm.filterParams, filterDocuments, true);

  return vm;

};

angular.module('public.security-manager.client.show')
  .controller('ClientShowDocumentsController', dependencies.concat(ClientShowDocumentsController));
