/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('popup.notification').directive('gccsPopupNotificationRegisteredEmployees', [
  'popupDirectiveConfig',
  popupDirectiveConfig =>
    popupDirectiveConfig.configure({
      bodyTemplate: require('./template.pug'),
      scope: {
        employees: '<',
        securityManagerId: '<'
      }
    })
]);
