/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS201: Simplify complex destructure assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$q', '$scope', 'Employee', 'DayOff', 'ShiftEmployeeActionsService', 'SickDay',
  'bookingShiftEmployeeAvailability', 'cableService', 'bookingShiftEmployeeAssignStatus',
  'dateService', 'confirmService', 'employeeStatus', 'employeeDayOffRequestStatus', 'employeeSickDayRequestStatus',
  'notificationService'
];

const PopupBookingShiftFormEmployeesInviteController = function(
  $q, $scope, Employee, DayOff, ShiftEmployeeActionsService, SickDay,
  bookingShiftEmployeeAvailability, cableService, bookingShiftEmployeeAssignStatus,
  dateService, confirmService, employeeStatus, employeeDayOffRequestStatus, employeeSickDayRequestStatus,
  notificationService
) {

  const vm = this;

  vm.shiftEmployeeAvailability = bookingShiftEmployeeAvailability;
  vm.filteredEmployees = [];
  vm.timeLineLoader = $scope.timeLineLoader;
  vm.isShiftInFuture = new Date($scope.shift.endTime) > Date.now();

  vm.$onInit = function() {
    initActionsService();
    subscribeToBookingTimeline();
    loadEmployees();
  };

  const getShiftId = () => $scope.shiftEmployeeManager.options.params.shiftId;
  const getBookingId = () => $scope.shiftEmployeeManager.options.params.bookingId;

  vm.getResourceRolesText = function () {
    return _.map(vm.shift.resource.roles, 'name').join(', ');
  };

  const updateShiftEmployeeAfterSuccessStatusChange = function(employee, updatedShiftEmployee) {
    $scope.shiftEmployeeManager.updateEntity(updatedShiftEmployee);
    if (updatedShiftEmployee.splitForId) { updateSplitShifts(updatedShiftEmployee); }
    return prepareEmployee(employee, updatedShiftEmployee);
  };

  const setEmployeeStatusForSplit = function(employee, splitShiftEmployee, updateDataCb) {
    splitShiftEmployee.employeeId = employee.id;
    return updateDataCb(employee, splitShiftEmployee).then(function() {
      $scope.assignForShiftEmployee = undefined;
      return $scope.backCb();
    });
  };

  const inviteForSplit = (employee, splitShiftEmployee) => {
    return setEmployeeStatusForSplit(employee, splitShiftEmployee, inviteExisting);
  };

  const inviteExisting = (employee, existingShiftEmployee) => {
    return vm.actionsService.invite(existingShiftEmployee).then(updatedShiftEmployee => {
      return updateShiftEmployeeAfterSuccessStatusChange(employee, updatedShiftEmployee);
    }).catch(() => loadEmployees());
  };

  const inviteNew = employee => {
    return vm.actionsService.invite(null, getBookingId(), getShiftId(), employee.id).then(updatedShiftEmployee => {
      return updateShiftEmployeeAfterSuccessStatusChange(employee, updatedShiftEmployee);
    }).catch(() => loadEmployees());
  };

  const invite = function(employee) {
    let existingShiftEmployee;
    if (!canBeInvited(employee)) { return; }
    if ($scope.assignForShiftEmployee) {
      return inviteForSplit(employee, $scope.assignForShiftEmployee);
    } else if ((existingShiftEmployee = findShiftEmployee($scope.shiftEmployeeManager, employee))) {
      return inviteExisting(employee, existingShiftEmployee);
    } else {
      return inviteNew(employee);
    }
  };

  const assignForSplit = (employee, splitShiftEmployee) => {
    return setEmployeeStatusForSplit(employee, splitShiftEmployee, assignExisting);
  };

  const assignExisting = (employee, existingShiftEmployee) => {
    return vm.actionsService.assign(existingShiftEmployee).then(updatedShiftEmployee => {
      updateShiftEmployeeAfterSuccessStatusChange(employee, updatedShiftEmployee);
      if ($scope.isShiftIssueInviteEmployee) {
        notificationService.notifySuccess('Shift issue resolved');
        if ($scope.onClose) { $scope.onClose(); }
      }
    }).catch(() => loadEmployees());
  };

  const assignNew = employee => {
    return vm.actionsService.assign(null, getBookingId(), getShiftId(), employee.id).then(updatedShiftEmployee => {
      updateShiftEmployeeAfterSuccessStatusChange(employee, updatedShiftEmployee);
      if ($scope.isShiftIssueInviteEmployee) {
        notificationService.notifySuccess('Shift issue resolved');
        if ($scope.onClose) { $scope.onClose(); }
      }
    }).catch(() => loadEmployees());
  };

  const assign = function(employee) {
    let existingShiftEmployee;
    if (!(canBeInvited(employee) || hasStatus(employee, bookingShiftEmployeeAssignStatus.getNewStatus()))) { return; }
    if ($scope.assignForShiftEmployee) {
      return assignForSplit(employee, $scope.assignForShiftEmployee);
    } else if (!employee.isSubContractor && (existingShiftEmployee = findShiftEmployee($scope.shiftEmployeeManager, employee))) {
      return assignExisting(employee, existingShiftEmployee);
    } else {
      return assignNew(employee);
    }
  };

  const actOnShiftCancellation = function(employee, action) {
    const shiftEmployee = findShiftEmployee($scope.shiftEmployeeManager, employee);
    if (!shiftEmployee) { return; }
    return action(shiftEmployee, employee.id).then(updatedShiftEmployee => {
      return updateShiftEmployeeAfterSuccessStatusChange(employee, updatedShiftEmployee);
    }).catch(() => loadEmployees());
  };

  const approveShiftCancellation = employee => {
    return actOnShiftCancellation(employee, vm.actionsService.approveShiftCancellation);
  };

  const rejectShiftCancellation = employee => {
    return actOnShiftCancellation(employee, vm.actionsService.rejectShiftCancellation);
  };

  const blowOut = employee => {
    const shiftEmployee = findShiftEmployee($scope.shiftEmployeeManager, employee);
    if (!shiftEmployee) { return; }
    return vm.actionsService.blowOut(shiftEmployee, employee.id).then(updatedShiftEmployee => {
      return updateShiftEmployeeAfterSuccessStatusChange(employee, updatedShiftEmployee);
    }).catch(() => loadEmployees());
  };

  const declineInvitationOrUnassign = function(employee, confirmationText, conflictedShiftEmployee) {
    let existingShiftEmployee;
    if (!conflictedShiftEmployee) {
      existingShiftEmployee = findShiftEmployee($scope.shiftEmployeeManager, employee);
    }
    if (!conflictedShiftEmployee && !existingShiftEmployee) { return; }

    return confirmService.confirm(confirmationText[0], confirmationText[1], confirmationText[2], () => {
      return vm.actionsService.unassign(conflictedShiftEmployee || existingShiftEmployee).then(function() {
        employee.availabilityStatus = bookingShiftEmployeeAssignStatus.getNotAssignedStatus();
        if (existingShiftEmployee) { $scope.shiftEmployeeManager._removeEntity(existingShiftEmployee); }
        if (conflictedShiftEmployee) { $scope.conflictedShiftEmployeeManager._removeEntity(conflictedShiftEmployee); }
      }).catch(() => loadEmployees());
    });
  };

  const declineInvitation = employee => {
    return declineInvitationOrUnassign(
      employee, ['Confirm cancelling invitation', 'Do you want to cancel invitation for this employee?', 'Yes']
    );
  };

  const unassign = employee => {
    return declineInvitationOrUnassign(employee, ['Confirm unassign', 'Do you want to unassign this employee?', 'Yes']);
  };

  const markUnavailable = employee => {
    return vm.actionsService.markUnavailable(null, getBookingId(), getShiftId(), employee.id)
      .then(updatedShiftEmployee => {
        return updateShiftEmployeeAfterSuccessStatusChange(employee, updatedShiftEmployee);
      })
      .catch(() => loadEmployees());
  };

  vm.getAssignedCount = () => {
    return _.filter($scope.shiftEmployeeManager.entities, shiftEmployee => {
      return bookingShiftEmployeeAssignStatus.countsAsAssigned(shiftEmployee.assignStatus);
    }).length;
  };

  vm.getConflictedShifts = employee => {
    return _.filter($scope.conflictedShiftEmployeeManager.entities, shiftEmployee => {
      return shiftEmployee.employeeId === employee.id;
    });
  };

  vm.getOvertime = function(shiftEmployee) {
    const maxStartDate = dateService.getMax(vm.shift.startTime, shiftEmployee.startTime, dateService.getIsoFullDateFormat());
    const minEndDate = dateService.getMin(vm.shift.endTime, shiftEmployee.endTime, dateService.getIsoFullDateFormat());
    return Math.ceil(minEndDate.diff(maxStartDate, 'hours', true));
  };

  vm.getNotPassedLicensesText = employee => _.map(employee.notPassedLicensesForResource, 'name').join(', ');

  vm.isShownActionDropdown = function (employee) {
    if (vm.shift.resource.roles.length > 0) {
      return _.intersectionBy(employee.roles, vm.shift.resource.roles, 'id').length > 0;
    } else {
      return true;
    }
  };

  const filterByAvailability = employees => {
    return _.filter(employees, employee => {
      return (
        hasStatus(employee, bookingShiftEmployeeAssignStatus.getNotAssignedStatus()) ||
        hasStatus(employee, bookingShiftEmployeeAssignStatus.getInAvailabilityStatus()) || 
        employee.isSubContractor
      ) && !employee.isBannedForClient;
    });
  };

  const filterByApproval = employees => _.filter(employees, employee => employee.isApprovedForClient);

  // todo refactor to methods
  // todo remake queryParams to real params
  const filterEmployeesByProperties = function(queryParams, employees) {
    // store queryParams on apply filtration, so search change wouldn't erase them
    if (!queryParams) { ({ queryParams } = vm); }
    vm.queryParams = queryParams;
    if (_.isEmpty(queryParams)) { return employees; }
    let filteredEmployees = angular.copy(employees);
    if (queryParams['availabilities[]']) {
      switch (+queryParams['availabilities[]'][0]) {
        case vm.shiftEmployeeAvailability.getOnlyAvailable():
          filteredEmployees = filterByAvailability(employees);
          break;
        case vm.shiftEmployeeAvailability.getOnlyApproved():
          filteredEmployees = filterByApproval(employees);
          break;
        case vm.shiftEmployeeAvailability.getApprovedAndAvailable():
          filteredEmployees = filterByAvailability(filterByApproval(employees));
          break;
      }
    }
    if (queryParams['roles[]']) {
      filteredEmployees = _.filter(filteredEmployees, employee =>
        _.some(employee.roles, role => queryParams['roles[]'].indexOf(role.id) !== -1)
      );
    }
    if (queryParams['sites[]']) {
      filteredEmployees = _.filter(filteredEmployees, employee => {
        return _.some(employee.sites, site => queryParams['sites[]'].indexOf(site.id) !== -1) ||
          _.some(employee.locations, location => _.intersection(queryParams['sites[]'], location.siteIds).length > 0);
      });
    }
    if (queryParams['locations[]']) {
      filteredEmployees = _.filter(filteredEmployees, employee => {
        return _.some(employee.sites, site => queryParams['sites[]'].indexOf(site.id) !== -1) ||
          _.some(employee.locations, location => queryParams['locations[]'].indexOf(location.id) !== -1);
      });
    }
    if (queryParams['home_zones[]']) {
      filteredEmployees = _.filter(filteredEmployees, employee =>
        _.some(employee.homeZones, homeZone => queryParams['home_zones[]'].indexOf(homeZone.id) !== -1)
      );
    }
    if (queryParams['genders[]']) {
      filteredEmployees = _.filter(filteredEmployees, employee => queryParams['genders[]'].indexOf(employee.gender) !== -1);
    }
    if (queryParams['trainings[]']) {
      filteredEmployees = _.filter(filteredEmployees, employee => {
        return (employee.passedLicenses != null ? employee.passedLicenses.length : undefined) && _.every(queryParams['trainings[]'],
          trainingId => _.find(employee.passedLicenses, { trainingId }));
      });
    }
    return filteredEmployees;
  };

  const filterByName = function(employees, employeeName) {
    if (!employeeName) { return employees; }
    return _.filter(employees, employee => employee.fullNameLower.indexOf(employeeName.toLowerCase()) !== -1);
  };

  vm.filterEmployees = function(queryParams) {
    sortEmployees();
    return vm.filteredEmployees = filterByName(filterEmployeesByProperties(queryParams, vm.employees), vm.employeeName);
  };

  const hasStatus = (employee, status) => employee.availabilityStatus === status;

  vm.isInAvailability = function(employee) {
    return employee.availabilityStatus === bookingShiftEmployeeAssignStatus.getInAvailabilityStatus();
  };

  const canBeInvited = employee => {
    return !employee.isBannedForClient && (employee.isSubContractor || bookingShiftEmployeeAssignStatus.canBeInvited(employee.availabilityStatus));
  };

  const sortEmployees = () => {
    return vm.employees = _.sortBy(_.sortBy(vm.employees, ['fullNameLower']), employee => {
      return bookingShiftEmployeeAssignStatus.sortPositionOf(employee.availabilityStatus);
    });
  };

  const prepareEmployee = function(employee, shiftEmployee, dayOff, sickDay) {
    if (employee.isBannedForClient) {
      employee.availabilityStatus = bookingShiftEmployeeAssignStatus.getBannedForClientStatus();
    } else if (dayOff) {
      employee.availabilityStatus = bookingShiftEmployeeAssignStatus.getInDayOffStatus();
    } else if (sickDay) {
      employee.availabilityStatus = bookingShiftEmployeeAssignStatus.getInSickDayStatus();
    } else if (shiftEmployee && !$scope.assignForShiftEmployee) {
      employee.availabilityStatus = shiftEmployee.assignStatus;
    } else if (employee.availabilities.length > 0) {
      employee.availabilityStatus = bookingShiftEmployeeAssignStatus.getInAvailabilityStatus();
    } else {
      employee.availabilityStatus = bookingShiftEmployeeAssignStatus.getNotAssignedStatus();
    }
    return true;
  };

  const prepareEmployees = (employees, dayOffs, sickDays) => {
    return _.each(employees, function (employee) {
      employee.fullName = employee.getFullName();
      employee.fullNameLower = employee.fullName.toLowerCase();
      employee.notPassedLicensesForResource = getNotPassedLicensesForResource(employee.passedLicenses);
      const shiftEmployee = findShiftEmployee($scope.shiftEmployeeManager, employee);
      const foundDayOff = _.find(dayOffs, dayOff => dayOff.employeeId === employee.id);
      const foundSickDay = _.find(sickDays, sickDay => sickDay.employeeId === employee.id);
      return prepareEmployee(employee, shiftEmployee, foundDayOff, foundSickDay);
    });
  };

  const getNotPassedLicensesForResource = function (passedLicenses) {
    return _.differenceWith(
      $scope.shift.resource.trainings,
      passedLicenses,
      (resourceTraining, passedLicense) => {
        return resourceTraining.id === passedLicense.trainingId;
      }
    );
  };

  const updateSplitShifts = shiftEmployee => {
    return _.each($scope.shiftEmployeeManager.entities, function (existingShiftEmployee) {
      if (existingShiftEmployee.id === shiftEmployee.splitForId) {
        const foundSplitShift = _.find(existingShiftEmployee.splitShifts, splitShift => {
          return splitShift.id === shiftEmployee.id;
        });
        if (foundSplitShift && !_.isEqual(shiftEmployee, foundSplitShift)) {
          angular.copy(shiftEmployee, foundSplitShift);
        }
        return false;
      }
    });
  };

  const findShiftEmployee = (entityManager, employee) => {
    return _.find(entityManager.entities, shiftEmployee => shiftEmployee.employeeId === employee.id);
  };

  const loadEmployees = () => {
    return $scope.conflictedShiftEmployeeManager.loadAll().then(function () {
      _.each($scope.conflictedShiftEmployeeManager.entities, conflictedShiftEmployee => {
        _.each(conflictedShiftEmployee.splitShifts, splitShift => {
          splitShift.shift = conflictedShiftEmployee.shift;
        });
      });
      const promises = [
        Employee.query({
          resourceId: $scope.shift.resource.id,
          clientId: $scope.clientId,
          status: employeeStatus.getActiveStatus(),
          from: $scope.shift.startTime,
          to: $scope.shift.endTime
        }),
        DayOff.query({
          from: $scope.shift.startTime,
          to: $scope.shift.endTime,
          status: employeeDayOffRequestStatus.getApprovedStatus()
        }),
        SickDay.query({
          from: $scope.shift.startTime,
          to: $scope.shift.endTime,
          status: employeeSickDayRequestStatus.getApprovedStatus()
        })
      ];
      return $q.all(promises).then(function (...args) {
        const [employees, dayOffs, sickDays] = Array.from(args[0]);
        vm.employees = prepareEmployees(employees, dayOffs, sickDays);
        vm.actionsService.setEmployees(vm.employees);
        return vm.filterEmployees();
      });
    });
  };

  const checkedReloadEmployees = function(data) {
    if ($scope.shift.id === data.shift_id) { return loadEmployees(); }
  };

  const checkedReloadEmployeesAfterEventChange = function(event) {
    if (!(event.start_time >= $scope.shift.endTime) && !(event.end_time <= $scope.shift.startTime)) {
      return loadEmployees();
    }
  };

  const subscribeToBookingTimeline = function() {
    const bookingTimelineChannel = cableService.getBookingTimelineChannel();
    if (bookingTimelineChannel) {
      for (let cableUpdateEvent of ['shift_employee', 'shift_employee_destroyed']) {
        bookingTimelineChannel.addCallbackWithCleanup($scope, cableUpdateEvent, checkedReloadEmployees);
      }
      for (let cableUpdateEvent of ['day_off', 'day_off_destroyed', 'sick_day', 'sick_day_destroyed']) {
        bookingTimelineChannel.addCallbackWithCleanup($scope, cableUpdateEvent, checkedReloadEmployeesAfterEventChange);
      }
    }
  };

  const initActionsService = function() {
    vm.actionsService = new ShiftEmployeeActionsService;
    if ($scope.assignForShiftEmployee) {
      vm.actionsService.isSplitShift();
    }
    vm.actionsService.on('invite', invite);
    vm.actionsService.on('assign', assign);
    vm.actionsService.on('declineInvitation', declineInvitation);
    vm.actionsService.on('markUnavailable', markUnavailable);
    vm.actionsService.on('unassign', unassign);
    vm.actionsService.on('approveShiftCancellation', approveShiftCancellation);
    vm.actionsService.on('rejectShiftCancellation', rejectShiftCancellation);
    vm.actionsService.on('blowOut', blowOut);
  };

  $scope.$watch('shift', function() {
    vm.shift = angular.copy($scope.shift);
    return vm.actionsService.setShifts(vm.shift);
  });

  return vm;

};

angular.module('popup.booking').controller('PopupBookingShiftFormEmployeesInviteController',
  dependencies.concat(PopupBookingShiftFormEmployeesInviteController));
