/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const DocumentTypeIcon = AppModel => {

  class DocumentTypeIcon extends AppModel {
    static initClass() {
      this.configure({ url: '/document_type_icons', name: 'document_type_icon' });
    }
  }

  DocumentTypeIcon.initClass();

  return DocumentTypeIcon;

};

angular.module('data.document').factory('DocumentTypeIcon', dependencies.concat(DocumentTypeIcon));
