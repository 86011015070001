/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').filter('formatTime', ['dateService', dateService =>
  function(rawTime) {
    if (rawTime) {
      return dateService.formatDateTime(rawTime, dateService.getTimeFormat());
    }
  }
]);
