/*
 * @author Igor Serpak <robobee>
 */
const gccsBookingTimeLineBooking = {
  controller: 'BookingTimeLineBookingController',
  controllerAs: 'bookingCtrl',
  template: require('./template.pug'),
  bindings: {
    bookingDimensions: '<',
    clientDecorator: '<',
    bookingDecorator: '<',
    timeLineLoader: '<',
    timeLineInteractor: '<',
    bookingTimelineShiftSelector: '<',
    bookingEditRoute: '<',
    repeatableBookingEditRoute: '<',
    groupedBookingRow: '<',
    onlyView: '<'
  }
};

angular.module('public.timeline').component('gccsBookingTimeLineBooking', gccsBookingTimeLineBooking);
