const dependencies = ['currentUserService', 'cableService'];

const AutoLogoutSwitcherController = function(currentUserService, cableService) {

  const vm = this;

  vm.$onInit = function() {
    initAutoLogout();
  };

  vm.toggleAutoLogout = function() {
    currentUserService.getCurrentUser().toggleAutoLogout().then(function() {
      cableService.getBaseActivityChannel().channel.perform('refresh');
    });
  };

  const initAutoLogout = function() {
    vm.isEnabledAutoLogout = currentUserService.getCurrentUser().isEnabledAutoLogout;
  };

  return vm;

};

angular.module('public.security-manager.schedule-manager.timeline')
  .controller('AutoLogoutSwitcherController', dependencies.concat(AutoLogoutSwitcherController));
