/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$state', 'UserCode', 'notificationService'];

const SignPasswordSetupController = function($state, UserCode, notificationService) {

  const vm = this;

  vm.passwordData = {};

  let isFormSubmitted = false;

  UserCode.get({ userId : $state.params.userId, code : $state.params.code }).then(userCode => {
    vm.userCode = userCode;
  }, () => $state.go('sign.login'));

  vm.setupPassword = function(invalid) {
    if (invalid || isFormSubmitted) { return; }
    isFormSubmitted = true;
    vm.userCode.password = vm.passwordData.password;
    return vm.userCode.save().then(function() {
      notificationService.notifySuccess('Sweet, now you can login with your new password!');
      return $state.go('sign.login');
    }, () => isFormSubmitted = false);
  };

  return vm;
};

angular.module('sign').controller('SignPasswordSetupController', dependencies.concat(SignPasswordSetupController));
