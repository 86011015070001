/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$state', 'storageService', 'defaultConfig'];

const SignPaymentMessageController = function($state, storageService, defaultConfig) {

  const vm = this;

  const PACKAGE_ERROR_DEACTIVATED = 'deactivated';
  const PACKAGE_ERROR_UNPAID = 'unpaid';
  const PACKAGE_ERROR_OUT_OF_PACKAGE = 'out_of_package';

  const packageErrorStates = {
    [PACKAGE_ERROR_DEACTIVATED]: 'sign.payment-message.deactivated',
    [PACKAGE_ERROR_OUT_OF_PACKAGE]: 'sign.payment-message.deactivated',
    [PACKAGE_ERROR_UNPAID]: 'sign.payment-message.unpaid'
  };

  vm.$onInit = function() {
    initPackageErrorData();
  };

  const initPackageErrorData = function() {
    vm.packageErrorData = JSON.parse(storageService.get('packageError'));
    storageService.remove('packageError');
    const packageErrorState = packageErrorStates[vm.packageErrorData.package_error];
    if (packageErrorState) {
      if (vm.packageErrorData.encrypted_id) {
        storageService.set(defaultConfig.sessionKey, vm.packageErrorData.encrypted_id);
      }
      $state.go(packageErrorState);
    } else {
      $state.go('sign.login');
    }
  };

  return vm;
};

angular.module('sign.payment-message')
  .controller('SignPaymentMessageController', dependencies.concat(SignPaymentMessageController));
