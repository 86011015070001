/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', '$state', 'confirmService', 'gccsConfig', 'validationService', 'notificationService',
  'cableService', 'inactivityService'
];

const PublicLayoutController = function(
  $scope, $state, confirmService, gccsConfig, validationService, notificationService,
  cableService, inactivityService
) {

  const vm = this;

  vm.confirmService = confirmService;
  vm.notificationService = notificationService;
  vm.isShownPackageBillingPopup = false;

  let intervalId;

  vm.$onInit = function() {
    validationService.setRegexRules(gccsConfig.regex);
    cableService.createBaseActivityChannel();
    intervalId = inactivityService.checkActivity(() => {
      cableService.getBaseActivityChannel().channel.perform('refresh');
    });
  };

  vm.isShownHeader = () => !($state.includes('public.planning') || $state.includes('public.planning-tutorial'));

  $scope.$on('$stateChangeSuccess', () => notificationService.hide());

  $scope.$on('$destroy', function() {
    clearInterval(intervalId);
    cableService.destroyBaseActivityChannel();
  });

  return vm;

};

angular.module('public').controller('PublicLayoutController', dependencies.concat(PublicLayoutController));
