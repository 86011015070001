/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = ['$q', 'CableChannel', 'SocketAuthToken', 'employeeDeviceType', 'defaultConfig'];

const cableService = function($q, CableChannel, SocketAuthToken, employeeDeviceType, defaultConfig) {

  const BOOKING_TIMELINE_CHANNEL = 'BookingTimelineChannel';
  const SECURITY_MANAGER_NOTIFICATIONS_CHANNEL = 'SecurityManagerNotificationsChannel';
  const SECURITY_MANAGER_CHANNEL = 'SecurityManagerChannel';
  const EMPLOYEE_NOTIFICATIONS_CHANNEL = 'EmployeeNotificationsChannel';
  const EMPLOYEE_TIMELINE_CHANNEL = 'EmployeeTimelineChannel';
  const EMPLOYEE_ACTIVITY_CHANNEL = 'EmployeeActivityChannel';
  const SESSION_CHANNEL = 'SessionChannel';
  const SYSTEM_PACKAGE_CHANNEL = 'SystemPackageChannel';
  const ACTIVITY_BASE_CHANNEL = 'Activity::BaseActivityChannel';
  const ACTIVITY_PLANNING_CHANNEL = 'Activity::PlanningActivityChannel';
  const LIVE_FEED_CHANNEL = 'LiveFeedChannel';

  let _cable = undefined;
  let _cablePromise = undefined;
  let _channels = {};
  let _delayedCbs = [];

  const createChannel = function(channelName, params, channelKey = channelName) {
    if (!_cable) { return; }
    return _channels[channelKey] || (_channels[channelKey] = new CableChannel(_cable, channelName, params));
  };

  const destroyChannel = function(channelKey) {
    if (_channels[channelKey] != null) {
      _channels[channelKey].destroy();
    }
    return _channels[channelKey] = undefined;
  };

  const getEmployeeChannelKey = function(channelName, employeeId) {
    return `${channelName}_${employeeId}`;
  };

  const getActionCableDomain = function() {
    if(defaultConfig.actionCableDomain === "UNSET") { 
      if (document.location.protocol !== 'https:')
      {
        return `ws://${document.location.host}/cable`;
      }
      return `wss://${document.location.host}/cable`;
    }
    return defaultConfig.actionCableDomain;
  };

  return {
    connect(userId) {
      if (_cable && !_cable.connection.disconnected) {
        return _cable;
      } else if (_cablePromise) {
        return _cablePromise;
      } else {
        return _cablePromise = new SocketAuthToken({ userId }).create().then(function(socketAuthToken) {
          _cable = ActionCable.createConsumer(`${getActionCableDomain()}?user_id=${userId}&token=${socketAuthToken.token}`);
          _cablePromise = undefined;
          for (let delayCb of Array.from(_delayedCbs)) { delayCb(); }
          _delayedCbs = [];
          return _cable;
        }).catch(function(error) {});
      }
    },

    addDelayCb(cb) {
      if (_cable) {
        return cb();
      } else {
        return _delayedCbs.push(cb);
      }
    },

    disconnect() {
      if (_cable != null) {
        _cable.disconnect();
      }
      _cable = undefined;
      _cablePromise = undefined;
      return _channels = {};
    },

    // todo remove securityProviderId from params and set it on backend
    createBookingTimelineChannel(securityProviderId) {
      if (!securityProviderId) { return; }
      return createChannel(BOOKING_TIMELINE_CHANNEL, { securityProviderId });
    },

    getBookingTimelineChannel() {
      return _channels[BOOKING_TIMELINE_CHANNEL];
    },

    destroyBookingTimelineChannel() {
      return destroyChannel(BOOKING_TIMELINE_CHANNEL);
    },

    createSecurityManagerNotificationsChannel(securityProviderId) {
      if (!securityProviderId) { return; }
      return createChannel(SECURITY_MANAGER_NOTIFICATIONS_CHANNEL, { securityProviderId });
    },

    getSecurityManagerNotificationsChannel() {
      return _channels[SECURITY_MANAGER_NOTIFICATIONS_CHANNEL];
    },

    destroySecurityManagerNotificationsChannel() {
      return destroyChannel(SECURITY_MANAGER_NOTIFICATIONS_CHANNEL);
    },

    createEmployeeNotificationsChannel(employeeId) {
      if (!employeeId) { return; }
      return createChannel(
        EMPLOYEE_NOTIFICATIONS_CHANNEL,
        { employeeId },
        getEmployeeChannelKey(EMPLOYEE_NOTIFICATIONS_CHANNEL, employeeId)
      );
    },

    getEmployeeNotificationsChannel(employeeId) {
      return _channels[getEmployeeChannelKey(EMPLOYEE_NOTIFICATIONS_CHANNEL, employeeId)];
    },

    destroyEmployeeNotificationsChannel(employeeId) {
      return destroyChannel(getEmployeeChannelKey(EMPLOYEE_NOTIFICATIONS_CHANNEL, employeeId));
    },

    createEmployeeTimelineChannel(employeeId) {
      if (!employeeId) { return; }
      return createChannel(
        EMPLOYEE_TIMELINE_CHANNEL,
        { employeeId },
        getEmployeeChannelKey(EMPLOYEE_TIMELINE_CHANNEL, employeeId),
      );
    },

    getEmployeeTimelineChannel(employeeId) {
      return _channels[getEmployeeChannelKey(EMPLOYEE_TIMELINE_CHANNEL, employeeId)];
    },

    destroyEmployeeTimelineChannel(employeeId) {
      return destroyChannel(getEmployeeChannelKey(EMPLOYEE_TIMELINE_CHANNEL, employeeId));
    },

    createEmployeeActivityChannel(employeeId) {
      if (!employeeId) { return; }
      const channelKey = getEmployeeChannelKey(EMPLOYEE_ACTIVITY_CHANNEL, employeeId);
      createChannel(EMPLOYEE_ACTIVITY_CHANNEL, { employeeId }, channelKey);
      _channels[channelKey].channel.updateActive = function() {
        return this.perform('update_active', { device: employeeDeviceType.getDesktop() });
      };
      return _channels[channelKey].channel.connected = function() {
        return this.updateActive();
      };
    },

    getEmployeeActivityChannel(employeeId) {
      return _channels[getEmployeeChannelKey(EMPLOYEE_ACTIVITY_CHANNEL, employeeId)];
    },

    destroyEmployeeActivityChannel(employeeId) {
      return destroyChannel(getEmployeeChannelKey(EMPLOYEE_ACTIVITY_CHANNEL, employeeId));
    },

    getSystemPackageChannel() {
      return _channels[SYSTEM_PACKAGE_CHANNEL];
    },

    destroySystemPackageChannel() {
      return destroyChannel(SYSTEM_PACKAGE_CHANNEL);
    },

    createSystemPackageChannel(systemPackageId) {
      if (!systemPackageId) { return; }
      return createChannel(SYSTEM_PACKAGE_CHANNEL, { system_package_id: systemPackageId });
    },

    getSessionChannel() {
      return _channels[SESSION_CHANNEL];
    },

    destroySessionChannel() {
      return destroyChannel(SESSION_CHANNEL);
    },

    createSessionChannel(sessionId) {
      if (!sessionId) { return; }
      return createChannel(SESSION_CHANNEL, { sessionId });
    },

    getBaseActivityChannel() {
      return _channels[ACTIVITY_BASE_CHANNEL];
    },

    destroyBaseActivityChannel() {
      return destroyChannel(ACTIVITY_BASE_CHANNEL);
    },

    createBaseActivityChannel() {
      return createChannel(ACTIVITY_BASE_CHANNEL);
    },

    getPlanningActivityChannel() {
      return _channels[ACTIVITY_PLANNING_CHANNEL];
    },

    destroyPlanningActivityChannel() {
      return destroyChannel(ACTIVITY_PLANNING_CHANNEL);
    },

    createPlanningActivityChannel() {
      return createChannel(ACTIVITY_PLANNING_CHANNEL);
    },

    createSecurityManagerChannel(securityManagerId) {
      if (!securityManagerId) { return; }
      return createChannel(SECURITY_MANAGER_CHANNEL, { security_manager_id: securityManagerId });
    },

    getSecurityManagerChannel() {
      return _channels[SECURITY_MANAGER_CHANNEL];
    },

    destroySecurityManagerChannel() {
      return destroyChannel(SECURITY_MANAGER_CHANNEL);
    },

    createLiveFeedChannel(securityProviderId) {
      if (!securityProviderId) { return; }
      return createChannel(LIVE_FEED_CHANNEL, { security_provider_id: securityProviderId });
    },

    getLiveFeedChannel() {
      return _channels[LIVE_FEED_CHANNEL];
    },

    destroyLiveFeedChannel() {
      destroyChannel(LIVE_FEED_CHANNEL);
    }

  };
};

angular.module('shared').service('cableService', dependencies.concat(cableService));
