/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').directive('onEnter', ['keyService', keyService => {
  return {
    link($scope, $el, attrs) {

      return $el.bind('keydown keypress', function(event) {
        if (keyService.enterPressed(event)) {
          event.preventDefault();
          $scope.$eval(attrs.onEnter);
          return $el[0].blur();
        }
      });
    }
  }
}]);
