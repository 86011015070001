/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
import './styles/_import.sass';

angular.module('public').directive('gccsHeader', () =>
  ({
    restrict: 'A',
    controller: 'HeaderController',
    controllerAs: 'headerCtrl',
    template: require('./template.pug')
  })
);
