const dependencies = [
  '$stateParams', '$scope', 'FlexibleTimeLineBookingInteractor', 'pluralizeService'
];

const ScheduleManagerTimelineBookingController = function(
  $stateParams, $scope, FlexibleTimeLineBookingInteractor, pluralizeService
) {

  const vm = this;

  vm.timeLineInteractor = FlexibleTimeLineBookingInteractor;

  vm.$onInit = function() {
    if ($stateParams.goToDate) {
      vm.from = $stateParams.goToDate;
    }
  };

  vm.getPluralizeShifts = function() {
    const selectedShiftsLength =
      $scope.scheduleManagerTimelineLayoutCtrl.bookingTimelineShiftSelector.selectedDShiftGroup.length;
    return `${pluralizeService.simple(selectedShiftsLength, 'Shift')} selected.`;
  };

  return vm;

};

angular
  .module('public.security-manager.schedule-manager.timeline')
  .controller('ScheduleManagerTimelineBookingController',
    dependencies.concat(ScheduleManagerTimelineBookingController));
