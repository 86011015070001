/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  'RateChange', 'rateChangeType', 'confirmService', 'pluralizeService', 'employeeType', 'rateType'
];

const BulkRateComponentController = function(
  RateChange, rateChangeType, confirmService, pluralizeService, employeeType, rateType
) {

  const vm = this;

  vm.isEditingRate = false;
  vm.isShownEditPayRatePopup = false;
  vm.isShownSetRatePopup = false;
  vm.isShownOvertimePopup = false;

  vm.showEditPayRatePopup = function() {
    vm.rateChange = new RateChange({
      employeeResourceId: vm.employeeResource.id,
      payRate: vm.employeeResource.payRate,
      rateType: rateChangeType.getPayRateType()
    });
    vm.isShownEditPayRatePopup = true;
    vm.closeMenuDropdown();
  };

  vm.showEditChargeRatePopup = function() {
    vm.rateChange = new RateChange({
      employeeResourceId: vm.employeeResource.id,
      payRate: vm.employeeResource.chargeRate,
      rateType: rateChangeType.getChargeRateType()
    });
    vm.isShownEditPayRatePopup = true;
    vm.closeMenuDropdown();
  };

  vm.hideEditPayRatePopup = function(rateChange) {
    vm.isShownEditPayRatePopup = false;
    if (rateChange) {
      if (rateChange.id) {
        vm.employeeResource.rateChange = rateChange;
        vm.onRateChange({ rateChange: rateChange });
      } else {
        if (vm.employeeResource.rateType === rateChangeType.getPayRateType()) {
          vm.employeeResource.payRate = rateChange.payRate;
        } else {
          vm.employeeResource.chargeRate = rateChange.payRate;
        }
        vm.onApprove();
      }
    }
  };

  vm.removeRateChange = function(rateChange) {
    confirmService.confirm(
      `Remove rate change for ${vm.resource.name}?`,
      'Do you really want to remove planned rate changes? This action can not be undone.',
      'Remove',
      function () {
        new RateChange(rateChange).remove().then(function() {
          vm.employeeResource.rateChange = null;
        });
      },
    );
  };

  vm.getSetRatePopupTitle = function() {
    return `Set cost for ${vm.resource.name}`;
  };

  vm.getOvertimePopupTitle = function() {
    return `Edit overtime data for ${vm.resource.name}`;
  };

  vm.getEditPayRatePopupTitle = function() {
    return `Edit cost for ${vm.resource.name}`;
  };

  vm.getEditPayRatePopupSubTitle = function() {
    if (vm.checkedEmployees && vm.checkedEmployees.length > 0) {
      return `${pluralizeService.simple(vm.checkedEmployees.length, 'employee')} selected`;
    }
  };

  vm.turnOfEditingRateMode = () => vm.isEditingRate = false;

  vm.setEmployeeResourceRate = function() {
    vm.isEditingRate = false;
    vm.onSetRate();
  };

  vm.approveEmployeeResource = function() {
    let field = (employeeType.isSelfEmployed(vm.employeeResource.employee.employmentType) || employeeType.isSubContractor(vm.employeeResource.employee.employmentType)) ? 'contractorPayRate' : 'employedPayRate';
    vm.popupEmployeeResource = _.clone(vm.employeeResource);
    vm.popupEmployeeResource.payRate = vm.resourcesRates[vm.employeeResource.resourceId][field];
    vm.popupEmployeeResource.chargeRate = vm.resourcesRates[vm.employeeResource.resourceId].chargeRate;
    vm.popupEmployeeResource.overtimeRate = 0;
    vm.popupEmployeeResource.overtimeMinHours = 40;
    vm.isShownSetRatePopup = true;
  };

  vm.unassignEmployeeResource = function() {
    vm.closeMenuDropdown();
    confirmService.confirm(
      `Remove rates for ${vm.employeeResource.employee.fullName} for ${vm.resource.name}?`,
      'This will remove pay rate, charge rate and overtime rate? This action can not be undone.',
      'Remove',
      vm.onUnapprove
    );
  };

  vm.isShownEditableRate = function () {
    return vm.employeeResource.payRate !== null && !vm.isEditingRate && !vm.employeeResource.isBanned && vm.editable && vm.roleCanBeAssigned();
  };

  vm.isShownNotEditableRate = function () {
    return vm.employeeResource.payRate !== null && !vm.isEditingRate && !vm.employeeResource.isBanned && !vm.editable && vm.roleCanBeAssigned();
  };

  vm.isShownApproveAction = function () {
    return vm.employeeResource.payRate === null && !vm.isEditingRate && !vm.employeeResource.isBanned && vm.editable && vm.roleCanBeAssigned();
  };

  vm.isShownNotApprovedLabel = function () {
    return vm.employeeResource.payRate === null && !vm.isEditingRate && !vm.employeeResource.isBanned && !vm.editable && vm.roleCanBeAssigned();
  };

  vm.hasRoles = function() {
    return vm.employeeResource.employee.roles.length > 0;
  };

  vm.roleCanBeAssigned = function() {
    return (
      vm.employeeResource.resourceRoles.length === 0 ||
      _.intersectionBy(vm.employeeResource.employee.roles, vm.employeeResource.resourceRoles, 'id').length > 0
    );
  };

  vm.isPayRateSelected = function() {
    return rateType.isPayRate(this.selectedRateType);
  }

  vm.isChargeRateSelected = function() {
    return rateType.isChargeRate(this.selectedRateType);
  }

  vm.isOvertimeRateSelected = function() {
    return rateType.isOvertimeRate(this.selectedRateType);
  }

  vm.onEditClick = function() {
    if (vm.isPayRateSelected()) {
      vm.showEditPayRatePopup();
    } else if (vm.isOvertimeRateSelected()) {
      vm.showOvertimePopup();
    } else if (vm.isChargeRateSelected()) {
      vm.showEditChargeRatePopup();
    }
  };

  vm.getEditText = function() {
    return `Edit ${rateType.getSmallNames()[this.selectedRateType]}`;
  };

  vm.getRate = function() {
    return vm.employeeResource[rateType.getField(this.selectedRateType)];
  };

  vm.hideSetRatePopup = function() {
    vm.isShownSetRatePopup = false;
    vm.popupEmployeeResource = null;
  };

  vm.onSetRatePopupSubmit = function() {
    vm.employeeResource.payRate = vm.popupEmployeeResource.payRate;
    vm.employeeResource.chargeRate = vm.popupEmployeeResource.chargeRate;
    vm.employeeResource.overtimeRate = vm.popupEmployeeResource.overtimeRate;
    vm.employeeResource.overtimeMinHours = vm.popupEmployeeResource.overtimeMinHours;
    vm.onApprove();
    vm.hideSetRatePopup();
    vm.hideOvertimePopup();
  };

  vm.showOvertimePopup = function() {
    vm.isShownOvertimePopup = true;
    vm.popupEmployeeResource = _.clone(vm.employeeResource);
  };

  vm.hideOvertimePopup = function() {
    vm.isShownOvertimePopup = false;
    vm.popupEmployeeResource = null;
  };

  vm.isCurrentTab = function() {
    return (
      rateChangeType.getPayRateTypes().includes(this.employeeResource.rateChange.rateType) &&
      this.selectedRateType === rateType.getPayRate()
    ) || (
      this.employeeResource.rateChange.rateType === rateChangeType.getChargeRateType() &&
      this.selectedRateType === rateType.getChargeRate()
    );
  };

  return vm;

};

angular.module('public.security-manager.hr')
  .controller('BulkRateComponentController', dependencies.concat(BulkRateComponentController));
