const gccsSecurityManagerServiceFormRecipients = {
  template: require('./template.pug'),
  controller: 'SecurityManagerServiceFormRecipientsController',
  controllerAs: 'serviceFormRecipientsCtrl',
  bindings: {
    service: '<',
    isEditable: '<'
  }
};

angular.module('public.security-manager.client.show')
  .component('gccsSecurityManagerServiceFormRecipients', gccsSecurityManagerServiceFormRecipients);
