const dependencies = [
  '$scope', '$location', 'FilterParamsService', 'EntityManager', 'DocumentType', 'DocumentTypeIcon',
  'documentTypeType', 'documentTypeIconService'
];

const ReportsHrLicenceController = function(
  $scope, $location, FilterParamsService, EntityManager, DocumentType, DocumentTypeIcon,
  documentTypeType, documentTypeIconService
) {

  const vm = this;

  const entityManager = new EntityManager(DocumentType);
  const filterParamsService = new FilterParamsService({
    order: ['presence', 'absence'],
    filter: ['type', 'name']
  });

  let lastOpenedReport;

  vm.filterParams = filterParamsService.getParams();
  vm.documentTypeType = documentTypeType;
  vm.documentTypeIcon = documentTypeIconService;

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  vm.$onInit = function() {
    setLabelsEntity();
  };

  vm.getDocumentTypes = function () {
    return entityManager.entities;
  };

  vm.toggleDocumentType = function(documentType) {
    if (lastOpenedReport === documentType) {
      lastOpenedReport.isOpened = false;
      lastOpenedReport = null;
    } else {
      if (!documentType.isOpened) {
        if (lastOpenedReport) {
          lastOpenedReport.isOpened = false;
        }
        documentType.isOpened = true;
        lastOpenedReport = documentType;
      }
    }
  };

  vm.toggleTableOrder = function(field) {
    _.each(vm.filterParams.order, (value, key) => {
      if (key === field) {
        if (value.ascending === null) {
          value.ascending = true;
        } else {
          value.ascending = !value.ascending;
        }
      } else {
        value.ascending = null;
      }
    });
  };

  vm.getSortClass = function(field) {
    if (vm.filterParams.order[field].ascending === true) {
      return 'reports-hr-title-sortable-bottom';
    } else if (vm.filterParams.order[field].ascending === false) {
      return 'reports-hr-title-sortable-top';
    } else {
      return '';
    }
  };

  const setLabelsEntity = function() {
    const type = +vm.filterParams.filter.type;
    if (documentTypeType.isTrainingType(type)) {
      vm.labelsEntity = {
        entityName: 'Licence/training',
        subjectEntityName: 'Employee'
      };
    } else if (documentTypeType.isClientDocumentType(type)) {
      vm.labelsEntity = {
        entityName: 'Document',
        subjectEntityName: 'Client'
      };
    } else {
      vm.labelsEntity = {
        entityName: 'Document',
        subjectEntityName: 'Employee'
      };
    }
  };

  const filterLicenceData = function() {
    setLabelsEntity();
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    $location.search(urlParams);
    return entityManager.load(urlParams);
  };

  $scope.$watch(() => vm.filterParams, _.debounce(filterLicenceData, 50) , true);

  return vm;

};

angular.module('public.security-manager.reports.hr')
  .controller('ReportsHrLicenceController', dependencies.concat(ReportsHrLicenceController));
