/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.client-manager.repeatable-booking', [
  'data.repeatable-booking',
  'data.client',
  'data.resource'
]).config(['$stateProvider', $stateProvider => {

  return $stateProvider

    .state('public.client-manager.repeatable-booking', {
      abstract: true,
      url: '/repeatable-booking?{ id }',
      template: require('./template.pug'),
      controller: 'ClientManagerRepeatableBookingLayoutController',
      controllerAs: 'repeatableBookingLayoutCtrl'
    })
    .state('public.client-manager.repeatable-booking.form', {
      url: '/form',
      template: require('../../security-manager/schedule-manager/repeatable-booking/form/template.pug'),
      controller: 'ScheduleManagerRepeatableBookingFormController',
      controllerAs: 'repeatableBookingFormCtrl'
    })
    .state('public.client-manager.repeatable-booking.shift-rules', {
      url: '/shift-rules',
      template: require('../../security-manager/schedule-manager/repeatable-booking/shift-rules/template.pug'),
      controller: 'ScheduleManagerRepeatableBookingShiftRulesController',
      controllerAs: 'repeatableBookingShiftRulesCtrl'
    })

}]);
