angular.module('shared').service('dateWeek', function() {

  const MONDAY = 1;
  const TUESDAY = 2;
  const WEDNESDAY = 4;
  const THURSDAY = 8;
  const FRIDAY = 16;
  const SATURDAY = 32;
  const SUNDAY = 64;

  const weekDays = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

  const names = {
    [MONDAY]: 'Monday',
    [TUESDAY]: 'Tuesday',
    [WEDNESDAY]: 'Wednesday',
    [THURSDAY]: 'Thursday',
    [FRIDAY]: 'Friday',
    [SATURDAY]: 'Saturday',
    [SUNDAY]: 'Sunday'
  };

  const shortNames = {
    [MONDAY]: 'm',
    [TUESDAY]: 't',
    [WEDNESDAY]: 'w',
    [THURSDAY]: 't',
    [FRIDAY]: 'f',
    [SATURDAY]: 's',
    [SUNDAY]: 's'
  };

  const isChosen = (weekDay, weekDays) => weekDay & weekDays;

  return {
    getNames() {
      return names;
    },

    getName(weekDay) {
      return names[weekDay];
    },

    getShortNames() {
      return shortNames;
    },

    getNamesArray(value) {
      return _.map(this.toArray(value), weekDay => {
        return this.getName(weekDay);
      });
    },

    toArray(value) {
      return _.filter(weekDays, weekDay => isChosen(weekDay, value));
    },

    toValue(chosenWeekDays) {
      return _.reduce(chosenWeekDays, (value, weekDay) => value + weekDay
      , 0);
    },

    getMonday() {
      return MONDAY;
    },
    
    isSunday(weekDay) {
      return weekDay == SUNDAY;
    },

    getPreviousDay(weekDay) {
      let previousWeekDayIndex = weekDays.indexOf(weekDay) - 1;
      if (previousWeekDayIndex === -1) {
        previousWeekDayIndex = weekDays.length - 1;
      }
      return weekDays[previousWeekDayIndex];
    }
  };
});
