const gccsSecurityManagerClientSiteLocations = {
  template: require('./template.pug'),
  controller: 'SecurityManagerClientSiteLocationsController',
  controllerAs: 'siteLocationsCtrl',
  bindings: {
    title: '@',
    clientId: '<',
    locations: '<',
    onRemoveCb: '&',
    onRemoveFromSiteCb: '&',
    onUpdateCb: '&'
  }
};

angular.module('public.security-manager.client.show')
  .component('gccsSecurityManagerClientSiteLocations', gccsSecurityManagerClientSiteLocations);
