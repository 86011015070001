/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$state', 'packageTypeService', 'securityProviderChosenPackageOptions'];

const LandingPricingController = function($state, packageTypeService, securityProviderChosenPackageOptions) {

  const vm = this;

  const DEFAULT_EMPLOYEES_NUMBER = 5;

  const NUMBER_OF_EMPLOYEES_MIN = 1;
  const NUMBER_OF_EMPLOYEES_MAX = 1000;

  const NUMBER_TEXT_LOW = 100;
  const NUMBER_TEXT_MEDIUM = 500;
  const NUMBER_TEXT_LARGE = 1000;

  vm.numberOfEmployees = DEFAULT_EMPLOYEES_NUMBER;
  vm.perEmployeeBasic = packageTypeService.getBasicPricePerEmployee();
  vm.perEmployeePro = packageTypeService.getProPricePerEmployee();
  vm.minNumberEmployees = NUMBER_OF_EMPLOYEES_MIN;
  vm.maxNumberEmployees = NUMBER_OF_EMPLOYEES_MAX;
  vm.packageTypeService = packageTypeService;

  vm.getTotalBasicPrice = function() {
    return vm.perEmployeeBasic * vm.numberOfEmployees;
  };

  vm.getTotalProPrice = function() {
    return vm.perEmployeePro * vm.numberOfEmployees;
  };

  vm.onNumberEmployeesBlur = function() {
    if (vm.numberOfEmployees < NUMBER_OF_EMPLOYEES_MIN) {
      vm.numberOfEmployees = DEFAULT_EMPLOYEES_NUMBER;
    }
  };

  vm.goToLoginWithPackage = function(chosenPackage) {
    securityProviderChosenPackageOptions.update({
      numberOfEmployees: vm.numberOfEmployees,
      packageType: chosenPackage
    });
    window.location.href = $state.href('sign.security-provider-choose-plan');
  };

  vm.getBarValue = function() {
    return vm.numberOfEmployees / NUMBER_OF_EMPLOYEES_MAX * 100;
  };

  vm.getBarText = function() {
    if (vm.numberOfEmployees < NUMBER_TEXT_LOW) {
      return 'Good start!';
    } else if (vm.numberOfEmployees < NUMBER_TEXT_MEDIUM) {
      return 'Nice team!';
    } else if (vm.numberOfEmployees < NUMBER_TEXT_LARGE) {
      return 'Keep it growing!';
    } else {
      return "Let's rock!";
    }
  };

  return vm;

};

angular.module('landing.pricing').controller('LandingPricingController', dependencies.concat(LandingPricingController));
