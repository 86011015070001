/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.client').factory('ClientServiceContact', ['AppModel', function(AppModel) {

  class ClientServiceContact extends AppModel {
    static initClass() {
      this.configure({url : '/clients/{{clientId}}/services/{{serviceId}}/contacts/{{id}}'});
    }
  }

  ClientServiceContact.initClass();
  return ClientServiceContact;
}
]);
