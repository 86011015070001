/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const gccsSecurityManagerNotificationsPageTabs = () => {
  return {
    restrict: 'E',
    template: require('./template.pug'),
    replace: true,
    controller: 'SecurityManagerNotificationsPageTabsController',
    controllerAs: '$ctrl',
    bindToController: true,
    scope: {
      tabs: '<',
      selectTab: '&',
      markTabAllRead: '&'
    }
  };
};

angular.module('public.security-manager.notifications').directive('gccsSecurityManagerNotificationsPageTabs',
  gccsSecurityManagerNotificationsPageTabs);
