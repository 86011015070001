/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['AppModel'];

const EmployeeBan = AppModel => {

  class EmployeeBan extends AppModel {
    static initClass() {
      this.configure({
        url : '/clients/{{clientId}}/employee_bans/{{id}}',
        name : 'employee_ban'
      });
    }

    static removeByEmployee(clientId, employeeId) {
      return this.$delete(this.resourceUrl({ clientId }, `/for_employee/${employeeId}`));
    }
  }

  EmployeeBan.initClass();
  return EmployeeBan;
};

angular.module('data.client').factory('EmployeeBan', dependencies.concat(EmployeeBan));
