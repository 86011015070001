/*
 * @author Igor Serpak <robobee>
 */
angular.module('public.employee', [
  'data.employee',
  'public.employee.timeline',
  'public.employee.profile',
  'public.employee.profile-settings'
]).config(['$stateProvider', $stateProvider => {

  return $stateProvider

    .state('public.employee', {
      abstract: true,
      url: '/employee',
      template: require('./template.pug'),
      controller: 'EmployeeLayoutController',
      controllerAs: 'employeeLayoutCtrl'
    });

}]);
