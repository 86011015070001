/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.client').factory('ClientManager', ['AppModel', function(AppModel) {

  class ClientManager extends AppModel {
    static initClass() {
      this.configure({url : '/clients/{{clientId}}/managers/{{id}}', name : 'manager'});
    }

    constructor() {
      super(...arguments);
      if (!this.services) { this.services = []; }
    }
  }

  ClientManager.initClass();
  return ClientManager;
}
]);
