/*
 * @author Igor Serpak <robobee>
 */
angular.module('popup.booking').directive('gccsPopupBookingShiftFormEmployees', () => {
  return {
    restrict: 'A',
    controller: 'PopupBookingShiftFormEmployeesController',
    controllerAs: 'shiftFormEmployeesCtrl',
    template: require('./template.pug'),
    scope: {
      clientId: '<',
      shift: '<',
      isShownEmployeeInvite: '<',
      onRemove: '&',
      onClose: '&',
      bookingTimelineShiftSelector: '<',
      timeLineLoader: '<',
      editable: '<',
      withoutRemove: '<',
      isShiftIssueInviteEmployee: '<',
      shouldGoToInvited: '<'
    }
  };
});
