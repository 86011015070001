/*
 * @author Maksym Shutiak <maxshutyak>
 */
const gccsPlanningShifts = {
  controller: 'PlanningShiftsController',
  controllerAs: 'planningShiftsCtrl',
  template: require('./template.pug'),
  bindings: {
    planningInteractor: '<',
    planningShiftLoader: '<',
    onShiftSelect: '&',
    onShiftDeselect: '&',
    onAllShiftsDeselect: '&',
    client: '<'
  }
};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .component('gccsPlanningShifts', gccsPlanningShifts);
