const gccsReportsMissingDataHistoryGraph = {
  controller: 'ReportsMissingDataHistoryGraphController',
  controllerAs: 'reportsMissingDataHistoryGraphCtrl',
  template: require('./template.pug'),
  bindings: {
    historyGaps: '<',
    years: '<',
    employee: '<',
    iconName: '@'
  }
};

angular.module('public.security-manager.reports.hr')
  .component('gccsReportsMissingDataHistoryGraph', gccsReportsMissingDataHistoryGraph);
