const dependencies = [
  '$scope', 'uploadService', 'userValidation', 'notificationService', 'currentUserService', 'redirectService'
];

const ProfilePersonalDetailsController = function(
  $scope, uploadService, userValidation, notificationService, currentUserService, redirectService
) {

  const vm = this;

  let isFormSubmitted = false;

  vm.flashErrors = {};

  vm.getOldPasswordErrors = userValidation.getPasswordErrors;
  vm.getNewPasswordErrors = userValidation.getPasswordErrors;

  vm.getEmailErrors = () => {
    return userValidation.getEmailErrors($scope.profileLayoutCtrl.user && $scope.profileLayoutCtrl.user.id);
  };

  vm.onFormSubmit = function() {
    if (!$scope.ProfileForm.$valid || isFormSubmitted) { return; }
    isFormSubmitted = true;
    if (_.isEqual($scope.profileLayoutCtrl.user, currentUserService.getCurrentUser())) {
      return resetFormSubmitted();
    }
    uploadService.saveWithFile($scope.profileLayoutCtrl.user, 'avatar', function(user) {
      user.oldPassword = undefined;
      user.newPassword = undefined;
      $scope.profileLayoutCtrl.updateUser(user);
      notificationService.notifySuccess('Your profile was successfully updated!');
      resetFormSubmitted();
    }, response => {
      vm.flashErrors[response.data.field] = response.data.error
    }, function(userSaveResponse) {
      vm.flashErrors[userSaveResponse.data.field] = userSaveResponse.data.error;
      resetFormSubmitted();
    })
  };

  vm.resetForm = function() {
    resetFormSubmitted();
    $scope.ProfileForm.$setPristine();
    $scope.ProfileForm.$setUntouched();
    $scope.profileLayoutCtrl.setUserAndSecurityManager();
  };

  vm.redirectToMain = () => {
    redirectService.redirectToMain();
  };

  const resetFormSubmitted = () => isFormSubmitted = false;

  return vm;

};

angular.module('public.security-manager.profile')
  .controller('ProfilePersonalDetailsController', dependencies.concat(ProfilePersonalDetailsController));
