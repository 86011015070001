/*
 * @author Oleksandr Papka <papkaos>
 */
const gccsFormDateToggle = {
  controller: 'FormDateToggleController',
  controllerAs: 'formDateToggleCtrl',
  template: require('./template.pug'),
  bindings: {
    name: '@',
    label: '@',
    dateModel: '='
  }
};

angular.module('form')
  .component('gccsFormDateToggle', gccsFormDateToggle);
