/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$state', 'currentUserService'];

const employeeInvoiceRedirectService = function($state, currentUserService) {

  const getDate = dateTime => dateTime.split('T')[0];

  return {
    gotoEmployeeInvoiceList(...args) {
      let routeName;
      const obj = args[0],
        val = obj.toAllList,
        toAllList = val != null ? val : false,
        { employeeId,
        forInvoice } = obj;
      const routeParams = {};
      if (forInvoice) {
        routeParams.numberFilter = forInvoice.number;
        routeParams.employeeId = forInvoice.employeeId;
        routeParams.from = getDate(forInvoice.startDate);
        routeParams.to = getDate(forInvoice.endDate);
      }
      if (currentUserService.isEmployeeLogged()) {
        routeName = 'public.employee.profile.invoice.list';
      } else {
        if (toAllList) {
          routeName = 'public.security-manager.invoices.employees';
          routeParams.employmentType = forInvoice.employmentType;
        } else {
          routeName = 'public.security-manager.employee.invoice.list';
          routeParams.employeeId = employeeId;
        }
      }

      return $state.go(routeName, routeParams);
    }
  };
};

angular.module('public.invoice.employee')
  .service('employeeInvoiceRedirectService', dependencies.concat(employeeInvoiceRedirectService));
