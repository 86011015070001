/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['AppModel', 'securityManagerAccessLevel'];

const SecurityManager = (AppModel, securityManagerAccessLevel) => {

  class SecurityManager extends AppModel {
    static initClass() {
      this.configure({
        url: '/security_managers',
        name: 'security_manager'
      });
    }

    static getAccountOwner() {
      return this.query({
        accessLevel: securityManagerAccessLevel.getAccountOwnerAccessLevel(),
        'order[id]': 'asc'
      }).then(function(securityManagers) {
        return securityManagers[0];
      });
    }

    restrictedEntitiesInfo() {
      return SecurityManager.$get(this.getUrl() + '/restricted_entities_info');
    }

    hasMoreAccess(securityManager) {
      return this.accessLevel < securityManager.accessLevel;
    }

    isAccountOwner() {
      return securityManagerAccessLevel.isAccountOwner(this.accessLevel);
    }

    isAdmin() {
      return securityManagerAccessLevel.isAdmin(this.accessLevel);
    }

    isManager() {
      return securityManagerAccessLevel.isManager(this.accessLevel);
    }

    isSupervisor() {
      return securityManagerAccessLevel.isSupervisor(this.accessLevel);
    }

    getFullName() {
      const nameParts = [];
      if (this.user && this.user.firstName) { nameParts.push(this.user.firstName); }
      if (this.user && this.user.lastName) { nameParts.push(this.user.lastName); }
      return nameParts.join(' ');
    }

  }

  SecurityManager.initClass();

  return SecurityManager;

};

angular.module('data.security-manager').factory('SecurityManager', dependencies.concat(SecurityManager));
