/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('public.security-manager.client.show.invoice').service('invoiceElementFactory', [
  '$rootScope', 'dateService', 'invoiceCostService', 'resourceChargeType', 'clientInvoiceElementType',
  function($rootScope, dateService, invoiceCostService, resourceChargeType, clientInvoiceElementType) {

    const MINUTES_IN_HOUR = 60;

    const TIME_FIELD_CLOCK_START = 'clockIn';
    const TIME_FIELD_CLOCK_END = 'clockOut';
    const TIME_FIELD_START = 'start';
    const TIME_FIELD_END = 'end';

    let fakeId = 0;

    class InvoiceElement {

      constructor(invoiceElement, editable, hideUnassignedShifts) {
        this.editable = editable;
        this.element = invoiceElement;
        this.hideUnassignedShifts = hideUnassignedShifts;
        this.dateTimeFormat = `${dateService.getIsoDateFormat()} ${dateService.getTimeFormat()}`;
        this.isRemoved = false;
        this._buildShiftEmployees();
        this.initDateValues();
      }

      toggleHideUnassignedShifts(hideUnassignedShifts) {
        this.hideUnassignedShifts = hideUnassignedShifts;
        this.updateCost();
      }

      initDateValues() {
        if (!this.element.startTimeMoment) {
          this.element.startTimeMoment = moment(this.element.startTime, dateService.getIsoFullDateFormat());
        }
        if (!this.element.endTimeMoment && this.element.endTime) {
          this.element.endTimeMoment = moment(this.element.endTime, dateService.getIsoFullDateFormat());
        }
        this.initDatePickerValues();
        if (this.editable) {
          return $rootScope.$watch(() => {
            return this.element.startDate;
          }, () => {
            this.updateTime();
          });
        }
      }

      initDatePickerValues() {
        this.element.startDate = this.element.startTimeMoment.format(dateService.getIsoDateFormat());
        this.element.startDayTime = this.element.startTimeMoment.format(dateService.getTimeFormat());
        if (!this.element.endTimeMoment) { return; }
        this.element.endDayTime = this.element.endTimeMoment.format(dateService.getTimeFormat());
      }

      updateTime() {
        let startDate;
        if (!this.editable) { return; }
        if (moment.isMoment(this.element.startDate)) {
          startDate = this.element.startDate.format(dateService.getIsoDateFormat());
        } else {
          ({ startDate } = this.element);
        }
        this.element.startTimeMoment = moment(`${startDate} ${this.element.startDayTime}`, this.dateTimeFormat);
        if (this.element.endDayTime) {
          this.element.endTimeMoment = moment(`${startDate} ${this.element.endDayTime}`, this.dateTimeFormat);
          if (this.element.endTimeMoment.isSameOrBefore(this.element.startTimeMoment)) {
            this.element.endTimeMoment.add(1, 'day');
          }
        }
        this.updateCost();
      }

      updateCost() {
        if (this.editable) { return invoiceCostService.updateCostOfShift(this.element, this.getShiftEmployees()); }
      }

      getHoursDiff() {
        let totalMinutesDiff = this.element.endTimeMoment.diff(this.element.startTimeMoment, 'minutes') -
          (this.element.unpaidBreakInMinutes || 0);
        const minutesDiff = totalMinutesDiff % MINUTES_IN_HOUR;
        const diff = [Math.floor(totalMinutesDiff / MINUTES_IN_HOUR)];
        if (minutesDiff) { diff.push((`0${minutesDiff}`).slice(-2)); }
        return diff.join(':');
      }

      getCompareValue() {
        return false;
      }

      isSame(another) {
        return (another.constructor.name === this.constructor.name) && (another.getCompareValue() === this.getCompareValue());
      }

      setAsRemoved() {
        return this.isRemoved = true;
      }

      getCost() {
        return this.element.cost;
      }

      getEmployeesText() {
        if ( this.element.allShiftEmployees.length == 1)
        {
          return `${this.element.allShiftEmployees.length} person`;
        }
        return `${this.element.allShiftEmployees.length} people`;
      }

      toggleEmployees() {
        return this.isShownEmployees = !this.isShownEmployees;
      }

      getAverageChargeRate() {
        if (this.element.allShiftEmployees.length === 0) {
          this.element.averageChargeRate = 0;
        } else {
          this.element.averageChargeRate = invoiceCostService.getAverageCost(this.element, this.getShiftEmployees());
        }
        return this.element.averageChargeRate;
      }

      getShiftEmployees() {
        return this.element.allShiftEmployees;
      }

      addNewShiftEmployee() {
        const newEmployee = {
          chargeRate: this.element.chargeRate || 0,
          startTime: this.element.startTime,
          endTime: this.element.endTime,
          employee: {
            fullName: ''
          }
        };
        this._initEmployeeClockTime(newEmployee, TIME_FIELD_START);
        this._initEmployeeClockTime(newEmployee, TIME_FIELD_END);
        if (this.element.enableClockIn && moment().isAfter(this.element.endTimeMoment)) {
          newEmployee.clockInTime = this.element.startTime;
          newEmployee.clockOutTime = this.element.endTime;
          this._initEmployeeClockTime(newEmployee, TIME_FIELD_CLOCK_START);
          this._initEmployeeClockTime(newEmployee, TIME_FIELD_CLOCK_END);
        }
        this.element.allShiftEmployees.push(newEmployee);
        this.updateCost();
      }

      _initEmployeeClockInTimes() {
        _.each(this.element.employees, shiftEmployee => {
          this._initEmployeeClockTime(shiftEmployee, TIME_FIELD_START);
          this._initEmployeeClockTime(shiftEmployee, TIME_FIELD_END);
          this._initEmployeeClockTime(shiftEmployee, TIME_FIELD_CLOCK_START);
          this._initEmployeeClockTime(shiftEmployee, TIME_FIELD_CLOCK_END);
        });
      }

      _initEmployeeClockTime(shiftEmployee, field) {
        const fieldTime = shiftEmployee[`${field}Time`];
        if (fieldTime) {
          const time = moment(fieldTime, dateService.getIsoFullDateFormat());
          shiftEmployee[`${field}TimeMoment`] = time;
          shiftEmployee[`${field}DayTime`] = time.format(dateService.getTimeFormat());
        } else {
          shiftEmployee[`${field}TimeMoment`] = null;
          shiftEmployee[`${field}DayTime`] = null;
        }
      }

      removeShiftEmployee(shiftEmployee) {
        _.remove(this.element.allShiftEmployees, shiftEmployee);
        this.updateCost();
      }

      isShownComments() {
        return false;
      }

      getIdForForm() {
        if (!this.element.formId) {
          this.element.formId = this.element.id ? `id${this.element.id}` : `fake${++fakeId}`;
        }
        return this.element.formId;
      }

      _buildShiftEmployees() {
        this.element.allShiftEmployees = this.element.employees;
      }

      isEnabledClockIn() {
        return this.element.enableClockIn;
      }

      isUnassignedShift() {
        return false;
      }
    }

    class InvoiceElementShift extends InvoiceElement {

      constructor(invoiceElement, editable, hideUnassignedShifts, isNewInvoice) {
        super(...arguments);
        this.isNewInvoice = isNewInvoice;
        this._buildShiftEmployees();
      }

      getCompareValue() {
        return this.element.id;
      }

      isShownComments() {
        return true;
      }

      isUnassignedShift() {
        return !resourceChargeType.isPerUnit(this.element.chargeType) &&
          _.filter(this.element.employees, (employee) => employee.employee).length === 0;
      }

      getEmployeesText() {
        if ( this.getShiftEmployees().length == 1)
        {
          return `${this.getShiftEmployees().length} person`;
        }
        return `${this.getShiftEmployees().length} people`;
      }

      getShiftEmployees() {
        if (!this.hideUnassignedShifts || resourceChargeType.isPerUnit(this.element.chargeType)) {
          return this.element.allShiftEmployees;
        } else {
          return _.filter(this.element.employees, (employee) => employee.id);
        }
      }

      _buildShiftEmployees() {
        this._initEmployeeClockInTimes();
        const resourcesArray = [];
        if (this.isNewInvoice) {
          const resourcesCount = Math.max(0, this.element.resourcesAmount - this.element.employees.length);
          const { chargeRate } = this.element;
          _.times(resourcesCount, () => resourcesArray.push({ chargeRate }));
        }
        this.element.allShiftEmployees = this.element.employees.concat(resourcesArray);
      }

      _updateEmployeeClockTime(shiftEmployee, field) {
        if (shiftEmployee[`${field}DayTime`]) {
          shiftEmployee[`${field}Time`] = `${this.element.startTimeMoment.format(dateService.getIsoDateFormat())} ${shiftEmployee[`${field}DayTime`]}`;
          shiftEmployee[`${field}TimeMoment`] = moment(shiftEmployee[`${field}Time`], dateService.getIsoFullDateFormat());
        }
      }

      updateClockTime(shiftEmployee) {
        this._updateEmployeeClockTime(shiftEmployee, TIME_FIELD_CLOCK_START);
        this._updateEmployeeClockTime(shiftEmployee, TIME_FIELD_CLOCK_END);
        if (
          shiftEmployee[`${TIME_FIELD_CLOCK_START}TimeMoment`] && shiftEmployee[`${TIME_FIELD_CLOCK_END}TimeMoment`] &&
          shiftEmployee[`${TIME_FIELD_CLOCK_END}TimeMoment`].isSameOrBefore(shiftEmployee[`${TIME_FIELD_CLOCK_START}TimeMoment`])
        ) {
          shiftEmployee[`${TIME_FIELD_CLOCK_END}TimeMoment`].add(1, 'day');
          shiftEmployee[`${TIME_FIELD_CLOCK_END}Time`] = shiftEmployee[`${TIME_FIELD_CLOCK_END}TimeMoment`].format(dateService.getIsoFullDateFormat());
        }
      }

      isShiftElement() {
        return true;
      }

    }

    class InvoiceElementAdditionalShiftCost extends InvoiceElement {

      getCompareValue() {
        return this.element.uniqueNumber;
      }

      isShiftElement() {
        return true;
      }
    }

    class InvoiceElementAdditionalSimpleCost extends InvoiceElement {

      getCompareValue() {
        return this.element.uniqueNumber;
      }

      updateCost() {
        if (!this.editable) { return; }
        return this.element.cost = this.element.quantity * this.element.chargeRate;
      }

      isShiftElement() {
        return false;
      }
    }

    return {
      create(shift, editable, hideUnassignedShifts, isNewInvoice) {
        if (shift.id) {
          shift.type = clientInvoiceElementType.getShiftCost();
          return new InvoiceElementShift(shift, editable, hideUnassignedShifts, isNewInvoice);
        } else if (shift.type === clientInvoiceElementType.getSimpleCost()) {
          return new InvoiceElementAdditionalSimpleCost(shift, editable, hideUnassignedShifts);
        } else {
          return new InvoiceElementAdditionalShiftCost(shift, editable, hideUnassignedShifts);
        }
      },

      createEmptyAdditionalShiftCost(editable, hideUnassignedShifts) {
        const additionalCost = {
          employees: [],
          startTimeMoment: moment(),
          endTimeMoment: moment().add(1, 'hour'),
          chargeType: resourceChargeType.getPerHourType(),
          type: clientInvoiceElementType.getShiftCost()
        };
        const newAdditionalCost = new InvoiceElementAdditionalShiftCost(additionalCost, editable, hideUnassignedShifts);
        newAdditionalCost.addNewShiftEmployee();
        return newAdditionalCost;
      },

      createEmptyAdditionalCost(editable, hideUnassignedShifts) {
        const additionalCost = {
          employees: [],
          startTimeMoment: moment().startOf('day'),
          quantity: 1,
          chargeRate: 0,
          type: clientInvoiceElementType.getSimpleCost()
        };
        return new InvoiceElementAdditionalSimpleCost(additionalCost, editable, hideUnassignedShifts);
      }
    };
  }
]);
