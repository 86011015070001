/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$q', 'ShiftEmployee', 'EmployeeDayOff', 'EmployeeAvailability', 'EmployeeSickDay',
  'EmployeeTimelineShiftDecorator', 'EmployeeTimelineAvailabilityDecorator', 'DayOffDecorator',
  'SickDayDecorator', 'currentUserService', 'dateService', 'intersectionMergerService',
  'bookingShiftEmployeeAssignStatus'
];

const EmployeeTimeLineLoader = (
  $q, ShiftEmployee, EmployeeDayOff, EmployeeAvailability, EmployeeSickDay,
  EmployeeTimelineShiftDecorator, EmployeeTimelineAvailabilityDecorator, DayOffDecorator,
  SickDayDecorator, currentUserService, dateService, intersectionMergerService,
  bookingShiftEmployeeAssignStatus
) => {

  let ISO_FULL_DATE_FORMAT = dateService.getIsoFullDateFormatWithT();

  return class EmployeeTimeLineLoader {

    constructor() {
      this._groupAndSetTimelineItems = this._groupAndSetTimelineItems.bind(this);
      this.timelineItems = [];
    }

    loadForDates(from, to) {
      const dateParams = { from, to };
      const queryParams = { employeeId: currentUserService.getCurrentProfile().id };
      return $q.all([
        ShiftEmployee.query(dateParams, queryParams),
        EmployeeDayOff.query(dateParams, queryParams),
        EmployeeSickDay.query(dateParams, queryParams),
        EmployeeAvailability.query(dateParams, queryParams)
      ]).then(this._groupAndSetTimelineItems);
    }

    _decorateShiftEmployee(shiftEmployee) {
      const dShift = new EmployeeTimelineShiftDecorator(shiftEmployee.shift);
      dShift.shiftEmployee = _.pick(shiftEmployee, 'id', 'payRate', 'assignStatus', 'employeeId');
      dShift.name = shiftEmployee.shift.name;
      dShift.timelineStartTime = moment(shiftEmployee.startTime, ISO_FULL_DATE_FORMAT);
      dShift.timelineEndTime = moment(shiftEmployee.endTime, ISO_FULL_DATE_FORMAT);
      dShift.employee = shiftEmployee.employee;
      dShift.isBlowOut = bookingShiftEmployeeAssignStatus.isBlowOut(shiftEmployee.assignStatus);
      dShift.isDayOff = false;
      return dShift;
    }

    _groupAndSetTimelineItems(result) {
      const [shiftEmployees, dayOffs, sickDays, availabilities] = Array.from(result);

      const dShifts = _.map(shiftEmployees, this._decorateShiftEmployee);
      const dDayOffs = _.map(dayOffs, dayOff => new DayOffDecorator(dayOff));
      const dSickDays = _.map(sickDays, sickDay => new SickDayDecorator(sickDay));
      const dAvailabilities = _.map(availabilities, availability => {
        return new EmployeeTimelineAvailabilityDecorator(availability);
      });

      this.timelineItems = [];
      for (let timelineItem of Array.from(dShifts.concat(dDayOffs, dSickDays, dAvailabilities))) {
        timelineItem.intersections = [];
        timelineItem.row = 1;
        this._findIntersections(this.timelineItems, timelineItem);
        if (!timelineItem.timelineStartTime.isSame(timelineItem.timelineEndTime)) {
          this.timelineItems.push(timelineItem);
        }
      }
    }

    _findIntersections(timelineItems, newTimelineItem) {
      const newStartTime = newTimelineItem.timelineStartTime;
      const newEndTime = newTimelineItem.timelineEndTime;
      let splitTimelineItem;
      for (let oldTimelineItem of timelineItems) {
        if (
          oldTimelineItem instanceof EmployeeTimelineAvailabilityDecorator &&
          newTimelineItem instanceof EmployeeTimelineAvailabilityDecorator
        ) {
          continue;
        }
        const oldStartTime = oldTimelineItem.timelineStartTime;
        const oldEndTime = oldTimelineItem.timelineEndTime;
        const isCorrectStartTime = (oldStartTime.diff(newStartTime) >= 0) && (oldStartTime.diff(newEndTime) >= 0);
        const isCorrectEndTime = (newStartTime.diff(oldEndTime) >= 0) && (newEndTime.diff(oldEndTime) >= 0);
        if (!isCorrectStartTime && !isCorrectEndTime) {
          const intersectionFrom = moment.max(newStartTime, oldStartTime);
          const intersectionTo = moment.min(newEndTime, oldEndTime);
          if (newTimelineItem instanceof EmployeeTimelineAvailabilityDecorator) {
            if (oldEndTime.diff(newEndTime) >= 0) {
              newTimelineItem.timelineEndTime = intersectionFrom;
            } else {
              splitTimelineItem = _.clone(newTimelineItem);
              splitTimelineItem.timelineStartTime = intersectionTo;
              newTimelineItem.timelineEndTime = intersectionFrom;
              this._findIntersections(timelineItems, splitTimelineItem);
              return timelineItems.push(splitTimelineItem);
            }
          } else {
            newTimelineItem.row = Math.max(newTimelineItem.row, oldTimelineItem.row + 1);
            this._addIntersections([oldTimelineItem, newTimelineItem], intersectionFrom, intersectionTo);
          }
        }
      }
    }

    _addIntersections(timelineItems, intersectsFrom, intersectsTo) {
      return _.each(timelineItems, timelineItem => timelineItem.intersections.push({
        from: intersectsFrom,
        to: intersectsTo
      }));
    }
  }

};

angular.module('public.employee.timeline')
  .factory('EmployeeTimeLineLoader', dependencies.concat(EmployeeTimeLineLoader));
