angular.module('public.timeline').directive('gccsTimelineBookingFormDetailsDetails', () => {
  return {
    restrict: 'A',
    controller: 'TimelineBookingFormDetailsDetailsController',
    controllerAs: 'detailsCtrl',
    template: require('./template.pug'),
    bindToController: true,
    scope: {
      booking: '<',
      isEdit: '<',
      isTemplate: '<',
      needDateRange: '<'
    }
  };
});
