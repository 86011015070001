/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['Report', 'ExtendedAccessFilterService', 'SecurityManagerRestrictedEntity'];

const ExtendedAccessFilterController = function(Report, ExtendedAccessFilterService, SecurityManagerRestrictedEntity) {

  const vm = this;

  vm.isAccessRightsFilter = true;

  vm.$onInit = function() {
    SecurityManagerRestrictedEntity.getFilterCategories().then(filterData => {
      vm.service = new ExtendedAccessFilterService(filterData, vm.applyFilterCb, false);
      vm.service.setData(vm.securityManager);
      vm.service.updateSelectedClients();
    });
  };

  vm.toggleFilter = function() {
    vm.service.toggleOpen();
  };

  vm.isShownForTagsForAccessRights = function() {
    return vm.service.areTagsShown();
  };

  return vm;

};

angular.module('shared-components.extended-filters')
  .controller('ExtendedAccessFilterController', dependencies.concat(ExtendedAccessFilterController));
