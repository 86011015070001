/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope'];

const TimelineBookingFormDetailsController = function($scope) {

  const vm = this;

  let isFormSubmitted = false;

  $scope.timelineBookingFormLayoutCtrl.isLong = false;

  $scope.timelineBookingFormLayoutCtrl.loadBooking().then(booking => vm.booking = booking);

  vm.onSubmit = function() {
    if (!$scope.BookingForm.$valid || isFormSubmitted) { return; }
    isFormSubmitted = true;
    vm.booking.migrateShifts = $scope.timelineBookingFormLayoutCtrl.isCopy && !$scope.timelineBookingFormLayoutCtrl.copyDateWasSet;
    return vm.booking.save().then(function(booking) {
      $scope.timelineBookingFormLayoutCtrl.updateBooking(booking);
      return $scope.timelineBookingFormLayoutCtrl.goNext();
    });
  };

  const initTitle = function() {
    if ($scope.timelineBookingFormLayoutCtrl.isEdit) {
      return vm.title = 'Edit booking';
    } else {
      return vm.title = 'New booking';
    }
  };

  initTitle();

  return vm;
};

angular
  .module('public.timeline')
  .controller('TimelineBookingFormDetailsController',
    dependencies.concat(TimelineBookingFormDetailsController));
