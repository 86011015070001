const dependencies = ['ExtendedFilterService', 'Client'];

const EmployeeTimelineExtendedFilterController = function(ExtendedFilterService, Client) {

  const vm = this;

  vm.filterString = '';

  Client.filterCategories().then(filterData => vm.service = new ExtendedFilterService(filterData, vm.applyFilterCb));

  return vm;
};

angular.module('public.security-manager.schedule-manager.timeline')
  .controller('EmployeeTimelineExtendedFilterController', dependencies.concat(EmployeeTimelineExtendedFilterController));
