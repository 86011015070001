/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$state', '$q', 'Employee', 'currentUserService', 'employeeInvoiceLabelService',
  'packageTypeService', 'employeeSiblingProfileService'
];

const EmployeeProfileLayoutController = function(
  $scope, $state, $q, Employee, currentUserService, employeeInvoiceLabelService,
  packageTypeService, employeeSiblingProfileService
) {

  const vm = this;

  vm.employeeId = $state.params.employeeId;
  vm.isShownEditPopup = false;

  const invoiceTab = {
    name: '',
    sref: 'public.employee.profile.invoice.list'
  };

  vm.$onInit = function() {
    loadEmployers();
    vm.loadEmployee();
  };

  vm.showEditPopup = () => vm.isShownEditPopup = true;

  vm.closeEditPopup = function(employee) {
    vm.isShownEditPopup = false;
    if (employee) {
      angular.copy(employee, vm.employee);
      return triggerUpdate();
    }
  };

  vm.loadEmployee = function() {
    if (vm.employee) {
      return $q.when(vm.employee);
    } else {
      return Employee.get(vm.employeeId).then(function(employee) {
        vm.employee = employee;
        initEmployeeData(employee);
        initTabs();
        triggerUpdate();
        return $q.when(vm.employee);
      });
    }
  };

  vm.isProviderSelected = function(employee) {
    return employee.id === +$state.params.employeeId;
  };

  const loadEmployers = function() {
    employeeSiblingProfileService.getSiblingProfiles().then((employees) => {
      vm.employeesByProviders = employees;
    });
  };

  const initEmployeeData = function(employee) {
    vm.employeesLabels = employeeInvoiceLabelService.getEmployeesTexts(employee.employmentType);
    invoiceTab.name = vm.employeesLabels.tabText;
  };

  const triggerUpdate = () => $scope.$broadcast('employee.profile.update', vm.employee);

  const initTabs = function() {
    vm.tabs = [{
      name: 'PERSONAL DETAILS',
      sref: 'public.employee.profile.details'
    }, {
      name: 'WORK HISTORY',
      sref: 'public.employee.profile.work-history'
    }, {
      name: 'ADDRESS HISTORY',
      sref: 'public.employee.profile.address-history'
    }, {
      name: 'CLIENT RESOURCES',
      sref: 'public.employee.profile.approved-clients'
    }, {
      name: 'LICENSES AND TRAININGS',
      sref: 'public.employee.profile.licenses'
    }, {
      name: 'DOCUMENTS',
      sref: 'public.employee.profile.documents'
    }, {
      name: 'TIMESHEET',
      sref: 'public.employee.profile.timesheet'
    }, {
      name: 'COMPANY',
      sref: 'public.employee.profile.company'
    }];
    if (packageTypeService.hasProPackage(vm.employee.securityProvider.systemPackage.packageType)) {
      vm.tabs.push(invoiceTab);
    }
    vm.tabs.push({
      name: 'NOTES',
      sref: 'public.employee.profile.notes'
    });
  };

  return vm;

};

angular.module('public.employee.profile')
  .controller('EmployeeProfileLayoutController', dependencies.concat(EmployeeProfileLayoutController));
