const dependencies = ['currentUserService', 'securityManagerNotificationActionService'];

const securityManagerNotificationButton = function(currentUserService, securityManagerNotificationActionService) {

  const BUTTON_WHITE = 'mod-white';
  const BUTTON_RED = 'mod-red-text';

  return {

    getViewEmployeeProfile(notification, employeeId) {
      return {
        label: 'View profile',
        classes: BUTTON_WHITE,
        onClickCb: () => securityManagerNotificationActionService.goToEmployeeProfile(notification, employeeId)
      };
    },

    getViewClientDetails(notification, clientId) {
      return {
        label: 'View profile',
        classes: BUTTON_WHITE,
        onClickCb: () => securityManagerNotificationActionService.goToClientDetails(notification, clientId)
      };
    },

    getReviewClientProfile(notification, clientId) {
      return {
        label: 'Review client profile',
        classes: BUTTON_WHITE,
        isHidden: currentUserService.getCurrentProfile().isSupervisor(),
        onClickCb: () => securityManagerNotificationActionService.goToClientDetails(notification, clientId)
      };
    },

    getDecline(notification, method) {
      return {
        label: 'Decline',
        classes: BUTTON_RED,
        isHidden: currentUserService.getCurrentProfile().isSupervisor(),
        onClickCb: () => {
          this.isHidden = true;
          securityManagerNotificationActionService.approveDeclineNotification(notification, method);
        }
      };
    },

    getApprove(notification, method) {
      return {
        label: 'Accept',
        isHidden: currentUserService.getCurrentProfile().isSupervisor(),
        onClickCb: () => {
          this.isHidden = true;
          securityManagerNotificationActionService.approveDeclineNotification(notification, method);
        }
      };
    },

    getGoToDayOff(notification, dayOffId, startDay) {
      return {
        label: 'Go to timeline',
        classes: BUTTON_WHITE,
        onClickCb: () => securityManagerNotificationActionService.goToDayOff(notification, dayOffId, startDay)
      };
    },

    getGoToSickDay(notification, sickDayId, startDay) {
      return {
        label: 'Go to timeline',
        classes: BUTTON_WHITE,
        onClickCb: () => securityManagerNotificationActionService.goToSickDay(notification, sickDayId, startDay)
      };
    },

    getGoToBooking(notification, startDay) {
      return {
        label: 'Go to booking',
        classes: BUTTON_WHITE,
        onClickCb: () => securityManagerNotificationActionService.goToBookingTimeline(notification, startDay)
      };
    },

    getGoToShift(notification, shiftId, startDay) {
      return {
        label: 'Go to shift',
        classes: BUTTON_WHITE,
        onClickCb: () => securityManagerNotificationActionService.goToShift(notification, shiftId, startDay)
      };
    },

    getEmployeeInvoiceReview(notification, invoiceId, versionId) {
      return {
        label: 'Preview invoice',
        classes: BUTTON_WHITE,
        onClickCb: () => {
          securityManagerNotificationActionService.goToEmployeeInvoiceReview(notification, invoiceId, versionId);
        }
      };
    },

    getViewUsersList(notification, onClickCb) {
      return {
        label: 'View users list',
        classes: BUTTON_WHITE,
        isHidden: currentUserService.getCurrentProfile().isSupervisor(),
        onClickCb: () => {
          securityManagerNotificationActionService.markRead(notification);
          onClickCb();
        }
      };
    },

    getEmployeeInvoiceList(notification, employeeId) {
      return {
        label: 'View profile',
        classes: BUTTON_WHITE,
        isHidden: currentUserService.getCurrentProfile().isSupervisor(),
        onClickCb: () => securityManagerNotificationActionService.goToEmployeeInvoiceList(notification, employeeId)
      };
    }

  };

};

angular.module('public.security-manager.notifications')
  .service('securityManagerNotificationButton', dependencies.concat(securityManagerNotificationButton));
