/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  'SecurityProvider', 'EmployeeInvoice', 'validationService', 'currentUserService', 'dateWeek',
  'dateService', 'employeeHolidaysLimitType', 'packageTypeService', 'notificationService'
];

const SettingsPaymentController = function(
  SecurityProvider, EmployeeInvoice, validationService, currentUserService, dateWeek,
  dateService, employeeHolidaysLimitType, packageTypeService, notificationService
) {

  const vm = this;

  vm.monthNames = moment.months();
  vm.dateWeek = dateWeek;

  const NON_LEAP_YEAR = 2001;

  let holidaysPercentageCopy = undefined;

  vm.$onInit = function() {
    vm.validationService = validationService;
    vm.currentSecurityProvider = currentUserService.getCurrentProfile().securityProvider;
    vm.employeeHolidaysLimitType = employeeHolidaysLimitType;
    vm.employeeHolidaysLimitTypes = employeeHolidaysLimitType.getNames();
    vm.isShownByPackageType = packageTypeService.hasProPackage();
    holidaysPercentageCopy = vm.currentSecurityProvider.holidaysPercentage;
    setSecurityProvider(vm.currentSecurityProvider);
    vm.currentDate = moment();
  };

  vm.setHolidayPercentage = function() {
    if (
      !vm.currentSecurityProvider.holidaysPercentage ||
      vm.currentSecurityProvider.holidaysPercentage === holidaysPercentageCopy
    ) { return; }
    if (vm.currentSecurityProvider.holidaysPercentage > 100) {
      vm.currentSecurityProvider.holidaysPercentage = 100;
    }
    return new SecurityProvider({
      id: vm.currentSecurityProvider.id,
      holidaysPercentage: +vm.currentSecurityProvider.holidaysPercentage
    }).save()
      .then(() => holidaysPercentageCopy = vm.currentSecurityProvider.holidaysPercentage)
      .catch(error => Rollbar.log(`Security provider holidays percentage error ${window.location.href}`, error));
  };

  vm.setHolidaysResetDate = function() {
    let holidaysResetDay = +vm.holidaysResetDay;
    if (holidaysResetDay <= 0) {
      holidaysResetDay = 1;
    }
    const holidaysResetMonth = 1 + vm.holidaysResetMonth;
    if (isValidDate(holidaysResetDay, holidaysResetMonth)) {
      if (
        (vm.currentSecurityProvider.futureHolidaysResetDay && vm.currentSecurityProvider.futureHolidaysResetMonth) ||
        (
          vm.currentSecurityProvider.holidaysResetDay !== holidaysResetDay ||
          vm.currentSecurityProvider.holidaysResetMonth !== holidaysResetMonth
        )
      ) {
        vm.currentSecurityProvider.futureHolidaysResetDay = holidaysResetDay;
        vm.currentSecurityProvider.futureHolidaysResetMonth = holidaysResetMonth;
        new SecurityProvider(vm.currentSecurityProvider).save();
      }
    } else {
      notificationService.notifyError('Invalid date');
      initResetDate();
    }
  };

  const resetSecurityProviderFromCopy = () => vm.currentSecurityProvider = _.clone(vm.currentSecurityProviderCopy);

  const saveField = (fieldParams, errorTextPrefix) => {
    return new SecurityProvider(
      _.assign({ id: vm.currentSecurityProvider.id }, fieldParams)
    ).save()
      .then(setSecurityProvider)
      .catch(function (error) {
        resetSecurityProviderFromCopy();
        return Rollbar.log(errorTextPrefix + ` ${window.location.href}`, error);
      });
  };

  vm.holidaysLimitTypeChanged = function() {
    if (vm.holidaysLimitType !== employeeHolidaysLimitType.getUnlimited()) { return; }
    saveField({ holidaysLimit: null }, 'Security provider holidays limit error');
  };

  vm.setHolidaysLimit = function() {
    if (
      !vm.currentSecurityProvider.holidaysLimit ||
      (vm.currentSecurityProvider.holidaysLimit === vm.currentSecurityProviderCopy.holidaysLimit)
    ) { return; }
    saveField({
      holidaysLimit: +vm.currentSecurityProvider.holidaysLimit
    }, 'Security provider holidays percentage error');
  };

  vm.setEmployeeCodePrefix = function() {
    if (
      !vm.currentSecurityProvider.employeeCodePrefix ||
      (vm.currentSecurityProvider.employeeCodePrefix === vm.currentSecurityProviderCopy.employeeCodePrefix)
    ) { return; }
    saveField({
      employeeCodePrefix: vm.currentSecurityProvider.employeeCodePrefix
    }, 'Security provider employee code prefix error');
  };

  vm.getPreviousOvertimeFromWeekday = function() {
    return vm.dateWeek.getName(vm.dateWeek.getPreviousDay(vm.currentSecurityProvider.overtimeFromWeekday));
  };

  vm.setOvertimeFromWeekday = function() {
    if (vm.currentSecurityProvider.overtimeFromWeekday === vm.currentSecurityProviderCopy.overtimeFromWeekday) { return; }
    saveField({
      overtimeFromWeekday: vm.currentSecurityProvider.overtimeFromWeekday
    }, 'Security provider overtime from weekday error');
  };

  const setSecurityProvider = function(securityProvider) {
    vm.currentSecurityProvider = securityProvider;
    vm.currentSecurityProviderCopy = _.clone(securityProvider);
    if ((securityProvider.holidaysLimit === null) || (securityProvider.holidaysLimit === undefined)) {
      vm.holidaysLimitType = employeeHolidaysLimitType.getUnlimited();
    } else {
      vm.holidaysLimitType = employeeHolidaysLimitType.getLimited();
    }
    initResetDate();
  };

  const isValidDate = function(day, month) {
    return moment({ year: NON_LEAP_YEAR, month: month - 1, day: day }).isValid();
  };

  const initResetDate = function() {
    vm.holidaysResetDay = vm.currentSecurityProvider.futureHolidaysResetDay || vm.currentSecurityProvider.holidaysResetDay;
    vm.holidaysResetMonth = (vm.currentSecurityProvider.futureHolidaysResetMonth || vm.currentSecurityProvider.holidaysResetMonth) - 1;
  };

  return vm;

};

angular
  .module('public.security-manager.settings')
  .controller('SettingsPaymentController', dependencies.concat(SettingsPaymentController));
