const dependencies = ['ClientInvoice', 'dateService', 'invoiceCostService', 'invoiceType'];

const invoiceSaver = function(ClientInvoice, dateService, invoiceCostService, invoiceType) {

  const prepareBooking = (invoiceBooking) => {
    return {
      id: invoiceBooking.id,
      name: invoiceBooking.name,
      purchaseNumber: invoiceBooking.purchaseNumber,
      projectCode: invoiceBooking.projectCode,
      totalCost: invoiceCostService.getBookingCost(invoiceBooking),
      from: invoiceBooking.from,
      to: invoiceBooking.to,
      shifts: _.map(invoiceBooking.shifts, prepareShift)
    };
  };

  const prepareShift = invoiceShift => {
    return {
      id: invoiceShift.id,
      uniqueNumber: invoiceShift.uniqueNumber,
      name: invoiceShift.name,
      startTime: invoiceShift.startTimeMoment.format(dateService.getIsoFullDateFormat()),
      endTime: invoiceShift.endTimeMoment ? invoiceShift.endTimeMoment.format(dateService.getIsoFullDateFormat()) : undefined,
      quantity: invoiceShift.quantity, // used for simple cost
      chargeRate: invoiceShift.chargeRate, // used for simple cost
      type: invoiceShift.type, // differentiating field
      cost: invoiceShift.cost,
      chargeType: invoiceShift.chargeType,
      averageCost: invoiceShift.averageChargeRate,
      employees: _.map(invoiceShift.allShiftEmployees, shiftEmployee => {
        return {
          id: shiftEmployee.id,
          fullName: shiftEmployee.employee ? shiftEmployee.employee.fullName : undefined,
          chargeRate: shiftEmployee.chargeRate,
          clockInTime: shiftEmployee.clockInTime,
          clockOutTime: shiftEmployee.clockOutTime
        };
      })
    };
  };

  return {
    save(invoice, discountPercentage, baseData) {
      const invoiceData = _.extend(baseData, {
        id: invoice.id,
        clientId: invoice.clientId,
        invoiceNumber: invoice.lastVersion.invoiceNumber,
        issueDate: invoice.lastVersion.issueDate,
        subTotal: invoiceCostService.getSubTotal(invoice),
        totalVat: invoiceCostService.getTotalVat(invoice, discountPercentage),
        totalDiscount: invoiceCostService.getDiscount(invoice, discountPercentage),
        totalCost: invoiceCostService.getTotalCost(invoice, discountPercentage),
        withVat: invoice.lastVersion.withVat,
        discountPercentage,
        vatPercentage: invoice.lastVersion.vatPercentage,
        displayEmployeeNames: invoice.lastVersion.displayEmployeeNames,
        hideUnassignedShifts: invoice.lastVersion.hideUnassignedShifts,
        addClockDataInPdf: invoice.lastVersion.addClockDataInPdf,
        bookings: _.map(invoice.lastVersion.bookings, prepareBooking)
      });
      if ((invoiceData.totalCost < 0) && (invoice.lastVersion.bookings.length === 1) && !invoice.lastVersion.bookings[0].id) {
        invoiceData.type = invoiceType.getCreditNoteType();
      }
      return new ClientInvoice(invoiceData).save();
    }
  };
};

angular.module('public.security-manager.client.show.invoice').service('invoiceSaver', dependencies.concat(invoiceSaver));
