/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup').service('popupDirectiveConfig', ['$compile', function($compile) {

  const defaultOptions = function(bodyTemplate) {
    const options = {
      restrict: 'A',
      scope: {
        popupShowCondition: '=',
        popupCloseCb: '&'
      }
    };
    if (bodyTemplate) {
      options.link = function ($scope) {
        $scope.$popup = $compile(bodyTemplate)($scope);
        document.body.appendChild($scope.$popup[0]);
        return $scope.$on('$destroy', () => $scope.$popup.remove());
      };
    }
    return options;
  };

  return {
    configure(options) {
      return _.defaultsDeep(options, defaultOptions(options.bodyTemplate));
    }
  };

}]);
