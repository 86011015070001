const dependencies = [
  '$state', '$timeout', '$scope', 'SecurityManagerNotificationTopBarService', 'dateService',
  'cableService', 'currentUserService'
];

const SecurityManagerNotificationsTopBarController = function(
  $state, $timeout, $scope, SecurityManagerNotificationTopBarService, dateService,
  cableService, currentUserService
) {

  const DELETE_ANIMATION_DELAY = 550;

  const vm = this;

  vm.$onInit = function() {
    vm.service = new SecurityManagerNotificationTopBarService(vm.securityManager);
    initNotificationCable();
    initManagerCable();
  };

  vm.$onDestroy = function() {
    cableService.destroySecurityManagerNotificationsChannel();
    cableService.destroySecurityManagerChannel();
  };

  vm.isActive = function() {
    return $state.is('public.security-manager.notifications');
  };

  vm.goToNotifications = function() {
    vm.closeNotifications();
    $state.go('public.security-manager.notifications');
  };

  vm.toggleNotifications = function() {
    vm.notificationsShown = !vm.notificationsShown;
    if (vm.notificationsShown) {
      vm.service.loadNotifications();
    }
  };

  vm.closeNotifications = function() {
    vm.notificationsShown = false;
  };

  vm.onUrgentChange = function() {
    vm.service.loadNotifications();
  };

  vm.onTextBoldUpperClick = function(notification) {
    vm.closeNotifications();
    if (notification.textBoldUpperCb) {
      notification.textBoldUpperCb();
    }
  };

  vm.onTextBoldClick = function(notification) {
    vm.closeNotifications();
    if (notification.textBoldCb) {
      notification.textBoldCb();
    }
  };

  vm.getNotificationText = function() {
    if (vm.securityManager.onlyUrgentNotifications) {
      return 'Urgent notifications';
    } else {
      return 'All notifications';
    }
  };

  vm.isMuted = function() {
    return !vm.securityManager.enableNotificationSound;
  };

  vm.getTimeLeft = function(createdAtMoment) {
    return dateService.getTimeLeft(createdAtMoment);
  };

  vm.toggleRead = function(notification) {
    notification.isRemoving = true;
    $timeout(() => {
      vm.service.toggleRead(notification);
    }, DELETE_ANIMATION_DELAY);
  };

  vm.onButtonClick = function(button) {
    vm.closeNotifications();
    button.onClickCb();
  };

  const initNotificationCable = function() {
    cableService.createSecurityManagerNotificationsChannel(currentUserService.getCurrentProfile().securityProvider.id);
    const channel = cableService.getSecurityManagerNotificationsChannel();
    channel.addCallback('notification-created', data => {
      vm.service.notificationCreated(data, vm.notificationsShown, { withSound: true });
    });
    channel.addCallback('notification-updated', data => vm.service.notificationUpdated(data,  vm.notificationsShown));
    channel.addCallback('notification-destroyed', data => vm.service.notificationDestroyed(data));
  };

  const initManagerCable = function() {
    cableService.createSecurityManagerChannel(currentUserService.getCurrentProfile().id);
    const channel = cableService.getSecurityManagerChannel();
    channel.addCallback('notification_read', (data) => vm.service.notificationDestroyed(data));
    channel.addCallback('notification_unread', (data) => {
      vm.service.notificationCreated(data, vm.notificationsShown, { withSound: false });
    });
    channel.addCallback('notification_seen', () => vm.service.loadStatusCount());
  };

  return vm;

};

angular.module('public.security-manager.notifications')
  .controller('SecurityManagerNotificationsTopBarController', dependencies.concat(SecurityManagerNotificationsTopBarController));
