const gccsPopupsLayout = {
  controller: 'PopupsLayoutController',
  controllerAs: 'popupsLayoutCtrl',
  template: require('./template.html'),
  transclude: true,
  bindings: {
    headerTitle: '@',
    subTitle: '@',
    popupShowCondition: '<',
    popupCloseCb: '&'
  }
};

angular.module('shared-components.popup').component('gccsPopupsLayout', gccsPopupsLayout);
