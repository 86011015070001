/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.employee', [
  'public.security-manager.employee.invoice',
  'public.security-manager.employee.registration',
  'popup.employee',
  'popup.document',
  'popup.email',
  'shared-components.popup.employee'
]).config(['$stateProvider', function ($stateProvider) {

  $stateProvider
    .state('public.security-manager.employee', {
      abstract: true,
      url: '/employee/:employeeId',
      template: require('./template.pug'),
      controller: 'EmployeeShowLayoutController',
      controllerAs: 'employeeShowLayoutCtrl'
    })
    .state('public.security-manager.employee.details', {
      url: '/details',
      template: require('./details/template.pug'),
      controller: 'EmployeeShowDetailsController',
      controllerAs: 'employeeShowDetailsCtrl'
    })
    .state('public.security-manager.employee.work-history', {
      url: '/work-history',
      template: require('./work-history/template.pug'),
      controller: 'EmployeeShowWorkHistoryController',
      controllerAs: 'employeeShowWorkHistoryCtrl'
    })
    .state('public.security-manager.employee.address-history', {
      url: '/address-history',
      template: require('./address-history/template.pug'),
      controller: 'EmployeeShowAddressHistoryController',
      controllerAs: 'employeeShowAddressHistoryCtrl'
    })
    .state('public.security-manager.employee.approved-clients', {
      url: '/approved-clients',
      template: require('./approved-clients/template.pug'),
      controller: 'EmployeeShowApprovedClientsController',
      controllerAs: 'employeeShowApprovedClientsCtrl'
    })
    .state('public.security-manager.employee.documents', {
      url: '/documents',
      template: require('./documents/template.pug'),
      controller: 'EmployeeShowDocumentsController',
      controllerAs: 'employeeShowDocumentsCtrl'
    })
    .state('public.security-manager.employee.licenses', {
      url: '/licenses',
      template: require('./licenses/template.pug'),
      controller: 'EmployeeShowLicensesController',
      controllerAs: 'employeeShowLicensesCtrl'
    })
    .state('public.security-manager.employee.timesheet', {
      url: '/timesheet',
      template: require('./timesheet/template.pug'),
      controller: 'EmployeeShowTimesheetController',
      controllerAs: 'employeeShowTimesheetCtrl'
    })
    .state('public.security-manager.employee.emails', {
      url: '/emails',
      template: require('./emails/template.pug'),
      controller: 'EmployeeShowEmailsController',
      controllerAs: 'employeeShowEmailsCtrl'
    })
    .state('public.security-manager.employee.notes', {
      url: '/notes',
      template: require('./notes/template.pug'),
      controller: 'EmployeeShowNotesController',
      controllerAs: 'employeeShowNotesCtrl'
    });

}]);
