const gccsPlanningMode = {
  template: require('./template.pug'),
  bindings: {
    planningInteractor: '<',
    planningShiftDetailsService: '<',
    planningScrollService: '<',
    planningShiftLoader: '<',
    employeeService: '<',
    filterData: '<'
  }
};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .component('gccsPlanningMode', gccsPlanningMode);
