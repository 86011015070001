/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'confirmService'];

const PopupConfirmController = function($scope, confirmService) {

  const vm = this;

  vm.confirmService = confirmService;

  $scope.popupCtrl.overlayClass = 'mod-confirmation';

  vm.onButtonClick = button => {
    return $scope.popupCtrl.closePopup(function () {
      if (button != null ? button.onClickCb : undefined) {
        if (button != null) {
          button.onClickCb();
        }
      }
      return $scope.popupCloseCb();
    });
  };

  vm.closePopup = function() {
    return $scope.popupCtrl.closePopup(function () {
      const cancelButton = confirmService.getButtons()[0];
      if (cancelButton && cancelButton.onClickCb) {
        cancelButton.onClickCb();
      }
      $scope.popupCloseCb();
    });
  };

  return vm;
};

angular.module('popup.confirm').controller('PopupConfirmController', dependencies.concat(PopupConfirmController));
