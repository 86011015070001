const dependencies = [
  '$scope', '$element', 'SickDayDecorator', 'EmployeeTimelineAvailabilityDecorator', 'EmployeeDayOff',
  'EmployeeSickDay', 'EmployeeAvailability', 'confirmService', 'bookingShiftEmployeeAssignStatus'
];

const EmployeeTimelineModalController = function(
  $scope, $element, SickDayDecorator, EmployeeTimelineAvailabilityDecorator, EmployeeDayOff,
  EmployeeSickDay, EmployeeAvailability, confirmService, bookingShiftEmployeeAssignStatus
) {

  const vm = this;

  vm.cancelEventRequest = function() {
    vm.isShownConfirmPopup = true;
    confirmService.confirmRemove('this request', function () {
      const model = getEmployeeEventModel();
      if (model) {
        new model({
          id: vm.timelineItem.id,
          employeeId: vm.timelineItem.employeeId
        }).remove().then(function() {
          vm.closeTimelineItemPopup();
        });
      }
    }, function() {
      vm.isShownConfirmPopup = false;
    });
  };

  vm.isShownCancelEventButton = function() {
    return !vm.isShiftPassed()
      && !bookingShiftEmployeeAssignStatus.countsAsAssigned(vm.timelineItem.status);
  };

  vm.isShiftPassed = function() {
    if (!vm.timelineItem) { return; }
    return moment().isAfter(vm.timelineItem.timelineStartTime);
  };

  const getEmployeeEventModel = function() {
    if (vm.timelineItem.isDayOff) {
      return EmployeeDayOff;
    } else if (vm.timelineItem instanceof SickDayDecorator) {
      return EmployeeSickDay;
    } else if (vm.timelineItem instanceof EmployeeTimelineAvailabilityDecorator) {
      return EmployeeAvailability;
    }
  };

  const updatePopupPosition = function() {
    if (_.isEmpty(vm.position)) { return; }
    const popup = $element[0];
    const { top } = vm.position;
    popup.style.left = `${vm.position.left}px`;
    popup.style.top = `${top}px`;
  };

  $scope.$watch('position', updatePopupPosition);

  return vm;

};

angular.module('public.employee.timeline')
  .controller('EmployeeTimelineModalController', dependencies.concat(EmployeeTimelineModalController));
