/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.booking').directive('gccsPopupBookingInfoSummary', () => {
  return {
    restrict: 'A',
    controller: 'PopupBookingInfoSummaryController',
    controllerAs: 'bookingInfoSummaryCtrl',
    template: require('./template.pug'),
    scope: {
      bookingId: '<'
    }
  };
});
