/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
// todo rename to event
const employeeHolidayService = function() {

  const DEFAULT_HOLIDAYS_HOURS = 12;
  const DEFAULT_SICK_DAYS_HOURS = 24;

  return {
    getDefaultHolidaysHours() {
      return DEFAULT_HOLIDAYS_HOURS;
    },

    getDefaultSickDaysHours() {
      return DEFAULT_SICK_DAYS_HOURS;
    },

    getRemainingHolidayDays(accumulatedHours, takenHours) {
      const remainingHolidays = (accumulatedHours - takenHours) / DEFAULT_HOLIDAYS_HOURS;
      if (remainingHolidays < 0) {
        return Math.ceil(remainingHolidays);
      } else {
        return Math.floor(remainingHolidays);
      }
    }
  };
};

angular.module('data.employee').service('employeeHolidayService', employeeHolidayService);
