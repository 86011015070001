/*
 * @author Oleksandr Papka <papkaos>
 */
const gccsLicenseGrandTotal = {
  controller: 'SecurityManagerBillingLicenseGrandTotalController',
  controllerAs: 'billingLicenseGrandTotalCtrl',
  template: require('./template.pug'),
  bindings: {
    packageType: '<',
    licensesCount: '<',
    country: '<',
    withAsterisk: '<'
  }
};

angular.module('public.security-manager.billing')
  .component('gccsLicenseGrandTotal', gccsLicenseGrandTotal);
