/*
 * @author Igor Serpak <robobee>
 */
const employeeTrainingStatus = function() {

  const PASSED = 0;
  const NONE = 1;

  const names = {};
  names[PASSED] = 'Passed';
  names[NONE] = 'None';

  return {
    getNames() {
      return names;
    },

    getPassedStatus() {
      return PASSED;
    },

    getNoneStatus() {
      return NONE;
    }
  };
};

angular.module('data.employee').service('employeeTrainingStatus', employeeTrainingStatus);
