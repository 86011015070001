/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsClientInvoiceEmailRow = {
  template: require('./template.pug'),
  controller: 'ClientInvoiceEmailRowController',
  controllerAs: 'clientInvoiceEmailRowCtrl',
  bindings: {
    buttonName: '@',
    buttonClass: '@',
    email: '<',
    highlightString: '<',
    showStatusFor: '<',
    onButtonClick: '&'
  }
};

angular.module('public.security-manager.client.show').component('gccsClientInvoiceEmailRow', gccsClientInvoiceEmailRow);
