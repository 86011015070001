const gccsScheduleManagerTimelineAutoLogoutSwitcher = {
  controller: 'AutoLogoutSwitcherController',
  template: require('./template.pug'),
  bindings: {
    securityManager: '<'
  }
};

angular
  .module('public.security-manager.schedule-manager.timeline')
  .component('gccsScheduleManagerTimelineAutoLogoutSwitcher', gccsScheduleManagerTimelineAutoLogoutSwitcher);
