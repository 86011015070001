const gccsPopupClientSiteForm = {
  controller: 'PopupClientSiteFormController',
  template: require('./template.pug'),
  require: {
    gccsPopupsLayout: '^gccsPopupsLayout'
  },
  bindings: {
    site: '<'
  }
};

angular.module('shared-components.popup.client').component('gccsPopupClientSiteForm', gccsPopupClientSiteForm);
