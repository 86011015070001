import './styles.css';

const gccsPopupNewManager = {
  controller: 'PopupNewManagerController',
  template: require('./template.pug'),
  require: {
    gccsPopupsLayout: '^gccsPopupsLayout'
  },
  bindings: {
    securityManager: '='
  }
};

angular.module('shared-components').component('gccsPopupNewManager', gccsPopupNewManager);
