const dependencies = ['$scope', 'ClientServiceResource', 'selectService'];

const PopupBookingQuickAddShiftController = function($scope, ClientServiceResource, selectService) {

  const vm = this;

  vm.onConfirmSelectResource = function () {
    $scope.onApplyNewShiftResource({ resourceId: vm.selectedResource });
  };

  $scope.$on('popup.open', () => {
    ClientServiceResource
      .query({}, { clientId: $scope.booking.clientId, serviceId: $scope.booking.serviceId })
      .then(resources => {
        vm.serviceResources = selectService.toSelectData(resources);
        vm.selectedResource = +Object.keys(vm.serviceResources)[0];
      });
  });

  return vm;

};

angular.module('popup.booking')
  .controller('PopupBookingQuickAddShiftController', dependencies.concat(PopupBookingQuickAddShiftController));
