const dependencies = ['validationService', 'bookingShiftValidationService', 'dateService'];

const PopupShiftDetailsCheckInController = function(validationService, bookingShiftValidationService, dateService) {

  const vm = this;

  const DEFAULT_CHECK_IN_INTERVAL = 60;

  vm.validationService = validationService;

  vm.$onInit = function() {
    initTimes();
    splitTime('firstCheckIn');
    splitTime('lastCheckIn');
    vm.updateCheckInTime();
    if (!vm.shift.checkInInterval) {
      vm.shift.checkInInterval = DEFAULT_CHECK_IN_INTERVAL;
    }
  };

  vm.updateCheckInTime = function() {
    buildTime('firstCheckIn');
    buildTime('lastCheckIn');
  };

  vm.getDateErrors = function() {
    return bookingShiftValidationService.getCheckInTimeErrors();
  };
  
  vm.getFirstCheckInErrors = function() {
    return bookingShiftValidationService.getFirstCheckInErrors();    
  };
  
  vm.getLastCheckInErrors = function() {
    return bookingShiftValidationService.getLastCheckInErrors();    
  };

  const initTimes = function() {
    const startTimeMoment = moment(vm.shift.startTime, dateService.getIsoFullDateFormat());
    const endTimeMoment = moment(vm.shift.endTime, dateService.getIsoFullDateFormat());
    vm.isShiftStarted = startTimeMoment.isBefore();
    if (vm.shift.firstCheckInTime) {
      vm.firstCheckInTimeMoment = moment(vm.shift.firstCheckInTime, dateService.getIsoFullDateFormat());
    } else {
      vm.firstCheckInTimeMoment = startTimeMoment.clone();
    }
    if (vm.shift.lastCheckInTime) {
      vm.lastCheckInTimeMoment = moment(vm.shift.lastCheckInTime, dateService.getIsoFullDateFormat());
    } else {
      vm.lastCheckInTimeMoment = endTimeMoment.clone();
    }
  };

  const buildTime = function(field) {
    vm.shift[`${field}Time`] = [vm[`${field}Date`], vm[`${field}DayTime`]].join(' ');
  };

  const splitTime = function(field) {
    vm[`${field}Date`] = vm[`${field}TimeMoment`].format(dateService.getDateFormat());
    vm[`${field}DayTime`] = vm[`${field}TimeMoment`].format(dateService.getTimeFormat());
  };

  return vm;

};

angular.module('popup.booking')
  .controller('PopupShiftDetailsCheckInController', dependencies.concat(PopupShiftDetailsCheckInController));
