/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.profile', ['data.user', 'data.security-manager']).config([
  '$stateProvider',
  $stateProvider =>

    $stateProvider

      .state('public.security-manager.profile', {
        abstract: true,
        url: '/profile',
        template: require('./template.pug'),
        controller: 'ProfileLayoutController',
        controllerAs: 'profileLayoutCtrl'
      })
      .state('public.security-manager.profile.personal-details', {
        url: '/personal-details',
        template: require('./personal-details/template.pug'),
        controller: 'ProfilePersonalDetailsController',
        controllerAs: 'profilePersonalDetailsCtrl'
      })
      .state('public.security-manager.profile.notifications', {
        url: '/notifications',
        template: require('./notifications/template.pug'),
        controller: 'ProfileNotificationSettingsController',
        controllerAs: 'profileNotificationSettingsCtrl'
      })

]);
