// TODO: set silenceMode on refresh
const dependencies = ['SilenceMode'];

const silenceModeService = function(SilenceMode) {

  let _silenceMode = false;

  const getSilenceModeState = () => _silenceMode;

  const setSilenceModeState = silenceMode => _silenceMode = silenceMode;

  const turnOnSilenceMode = () => {
    return new SilenceMode().save().then(() => _silenceMode = true);
  };

  const turnOffSilenceMode = () => {
    return new SilenceMode().delete().then(() => _silenceMode = false);
  };

  return {
    getSilenceModeState,
    setSilenceModeState,
    turnOnSilenceMode,
    turnOffSilenceMode
  };
};

angular.module('public.security-manager.schedule-manager.timeline')
  .service('silenceModeService', dependencies.concat(silenceModeService));
