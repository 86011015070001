const dependencies = [
  '$scope', '$state', '$q', 'Client', 'localStorageService', 'redirectService',
  'clientStatus', 'packageTypeService', 'currentUserService'
];

const ClientShowLayoutController = function(
  $scope, $state, $q, Client, localStorageService, redirectService,
  clientStatus, packageTypeService, currentUserService
) {

  const vm = this;

  vm.clientId = $state.params.clientId;
  vm.isShownEditPopup = false;
  vm.isShownClientsExceedPopup = false;

  vm.activeStatus = clientStatus.getActiveStatus();

  let clientPromise = undefined;

  vm.$onInit = function() {
    initTabs();
    vm.editable = true;
    vm.loadClient();
  };

  vm.showEditPopup = () => vm.isShownEditPopup = true;

  vm.closeEditPopup = function(client) {
    vm.isShownEditPopup = false;
    if (client) {
      angular.copy(client, vm.client);
      triggerUpdate();
    }
  };

  vm.loadClient = function() {
    if (vm.client) {
      return $q.when(vm.client);
    } else if (clientPromise) {
      return clientPromise;
    } else {
      return clientPromise = Client.get(vm.clientId).then(function(client) {
        vm.client = client;
        clientPromise = undefined;
        if (localStorageService.get('client.popup.edit.open')) {
          vm.isShownEditPopup = true;
          localStorageService.remove('client.popup.edit.open');
        }
        triggerUpdate();
        return $q.when(vm.client);
      }).catch(() => redirectService.redirectToMain());
    }
  };

  vm.closeClientsExceedPopup = function () {
    vm.isShownClientsExceedPopup = false;
  };

  const openClientsExceedPopup = function() {
    vm.isShownClientsExceedPopup = true;
  };

  vm.onClientProfilePopupSubmit = function(successPopupSubmitCb) {
    checkPackageRestrictions(successPopupSubmitCb);
  };

  const checkPackageRestrictions = function(successCb) {
    if (packageTypeService.hasFreePackage()) {
      Client.getCount({}, { status: clientStatus.getActiveStatus() }).then(data => {
        if (data.count >= packageTypeService.getClientsLimitForFreePackage()) {
          openClientsExceedPopup();
        } else {
          successCb();
        }
      });
    } else {
      successCb();
    }
  };

  const initTabs = function () {
    vm.tabs = [{
      name: 'DETAILS',
      sref: 'public.security-manager.client.details'
    }, {
      name: 'SERVICES',
      sref: 'public.security-manager.client.services'
    }, {
      name: 'DOCUMENTS',
      sref: 'public.security-manager.client.documents'
    }, {
      name: 'SITES',
      sref: 'public.security-manager.client.sites'
    }, {
      name: 'CLOCK IN/OUT',
      sref: 'public.security-manager.client.clock-in'
    }, {
      name: 'Employees',
      sref: 'public.security-manager.client.employees'
    },{
      name: 'Booking templates',
      sref: 'public.security-manager.client.booking-templates'
    }];
    if (packageTypeService.hasProPackage()) {
      if (
        !currentUserService.getCurrentProfile().isManager() || 
        currentUserService.getCurrentProfile().hasAccessToFinances
      ) {
        vm.tabs.push({
          name: 'Invoices',
          sref: 'public.security-manager.client.invoice.list'
        });
      }
      vm.tabs.push({
        name: 'Notes',
        sref: 'public.security-manager.client.notes'
      });
    }
    vm.tabs.push({
      name: 'Emails',
      sref: 'public.security-manager.client.emails'
    });
  };

  const triggerUpdate = () => $scope.$broadcast('client.show.update', vm.client);

  return vm;

};

angular.module('public.security-manager.client.show')
  .controller('ClientShowLayoutController', dependencies.concat(ClientShowLayoutController));
