/*
 * @author Igor Serpak <robobee>
 */
angular.module('public.security-manager.notifications', []).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.notifications', {
      url: '/notifications',
      controller: 'SecurityManagerNotificationsController',
      controllerAs: 'securityManagerNotificationsCtrl',
      template: require('./template.pug')
    })

]);
