/*
 * @author Igor Serpak <robobee>
 */
const employeeBanStatus = function() {

  const APPROVED = 1;
  const BANNED = 2;

  const names = {};
  names[APPROVED] = 'Approved';
  names[BANNED] = 'Banned';

  return {
    getNames() {
      return names;
    },

    getBannedStatus() {
      return BANNED;
    },

    getApprovedStatus() {
      return APPROVED;
    }
  };
};

angular.module('data.employee').service('employeeBanStatus', employeeBanStatus);
