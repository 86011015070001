/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', '$element', '$filter', '$q', '$timeout', 'BookingSeries', 'BookingShift', 'EntityManager',
  'ShiftEditModeService', 'User', 'ShiftCommentEditModeService', 'ShiftTitleEditModeService', 'resourceIcon',
  'elementService', 'dateService', 'shiftDndService', 'seriesGroupService', 'cableService', 'bookingRepeatable',
  'currentUserService', 'bookingShiftEditMode', 'uniqueValuesService', 'bookingShiftTitleEditMode',
  'notificationService', 'resourceChargeType'
];

const TimelineBookingFormShiftsTimeLineShiftsController = function(
  $scope, $element, $filter, $q, $timeout, BookingSeries, BookingShift, EntityManager,
  ShiftEditModeService, User, ShiftCommentEditModeService, ShiftTitleEditModeService, resourceIcon,
  elementService, dateService, shiftDndService, seriesGroupService, cableService, bookingRepeatable,
  currentUserService, bookingShiftEditMode, uniqueValuesService, bookingShiftTitleEditMode,
  notificationService, resourceChargeType
) {

  const vm = this;

  const SHORT_SHIFT_WIDTH = 60;

  vm.resourceIcon = resourceIcon;
  vm.editModeService = ShiftEditModeService();
  vm.commentEditModeService = ShiftCommentEditModeService();
  vm.titleEditModeService = ShiftTitleEditModeService();
  vm.isShownShiftPopup = false;
  vm.isShownShiftEditPopup = false;
  vm.shiftPopupPosition = {};
  vm.groupedSeries = [];

  const queryParams = {};

  if (currentUserService.isClientManagerLogged()) {
    queryParams.withoutSplit = true;
  }

  let seriesManager = undefined;
  let from = undefined;
  let to = undefined;
  let onScrollCallback = undefined;
  let isResized = false;
  let interactorService = null;

  vm.onResize = shiftDndService.onResize;
  vm.getShiftRect = shiftDndService.getShiftRect;

  vm.$onInit = function() {
    seriesManager = new EntityManager(BookingSeries, {
      params: {
        bookingId: vm.booking.id
      },
      queryParams
    });
    from = moment(vm.booking.from, dateService.getIsoDateFormat());
    to = moment(vm.booking.to, dateService.getIsoDateFormat()).add(2, 'days');
    shiftDndService.setFrom(from);
    vm.dateRange = [from, moment(vm.booking.to, dateService.getIsoDateFormat())];
    loadAllSeries();
    initCable();
  };

  vm.onDrop = function(api, droppedModel) {
    if (!droppedModel) { return; }
    const dragEvent = api._manipulator.event;
    return getSeries(droppedModel).then(function(series) {
      let offsetX, shift, withSave;
      if (droppedModel instanceof BookingShift) {
        shift = droppedModel;
        shift.editMode = bookingShiftEditMode.getOnlyCurrentMode();
        offsetX = elementService.getOffsetLeft(dragEvent.target.closest('[dnd-model]'));
        withSave = false;
      } else {
        shift = initShift(series);
        offsetX = dragEvent.pageX;
        withSave = true;
      }
      shiftDndService.updateShiftTime($element, offsetX, shift, series.resource);
      if (isDraggedShiftStartTimeValid(shift)) {
        if (withSave) {
          return shift.save().then(loadSeriesShifts);
        }
      } else {
        vm.isShownShiftPopup = false;
        notificationService.notifyError(`Shift must be within booking duration up to ${to.clone().subtract(1, 'day').format('ddd D MMM YYYY')}`);
        return loadSeriesShifts(shift);
      }
    });
  };

  const isDraggedShiftStartTimeValid = shift => {
    return moment(shift.startTime, dateService.getIsoFullDateFormatWithTz()).isBefore(to.clone().subtract(1, 'day'));
  };

  vm.resetIsResized = () => isResized = false;

  vm.showShiftPopup = function($event, shift, element = null) {
    if (seriesIsLocked(shift)) { return; }
    if (isResized) { return isResized = false; } // ignore click to show shift popup if resize event ended on the shift element
    if (!isDraggedShiftStartTimeValid(shift)) { return; }
    vm.popupShift = shift;
    vm.isShownShiftPopup = true;
    const shiftElement = element ? element : $event.target.closest('[ng-click]');
    updateShiftPosition(shiftElement);
    return onScrollCallback = () => updateShiftPosition(shiftElement);
  };

  vm.closeShiftPopup = () => closePopup();

  const saveShiftAfterTitleEditCheck = (shift, afterSaveCb) => {
    return vm.titleEditModeService.show(shift, () => saveShift(shift).then(function () {
      if (afterSaveCb) {
        return afterSaveCb();
      }
    }));
  };

  vm.saveShift = (shift, afterSaveCb) => {
    return updateShift(shift, function () {
      if (shift.editMode === bookingShiftEditMode.getFollowingMode()) {
        return vm.commentEditModeService.show(shift, () => saveShiftAfterTitleEditCheck(shift, afterSaveCb));
      } else if (shift.editMode === bookingShiftEditMode.getFollowingMode()) {
        return saveShiftAfterTitleEditCheck(shift, afterSaveCb);
      } else {
        return saveShift(shift).then(function () {
          if (afterSaveCb) {
            return afterSaveCb();
          }
        });
      }
    });
  };

  vm.removeShift = (shift, afterRemoveCb) => {
    updateShift(shift, () => {
      seriesManager.removeEntity(shift, function () {
        if (afterRemoveCb) {
          afterRemoveCb();
        }
        vm.closeShiftPopup();
      }, { editMode: shift.editMode });
    });
  };

  vm.getShiftDuration = function(shift) {
    const startTime = moment(shift.startTime, dateService.getIsoFullDateFormat());
    const endTime = moment(shift.endTime, dateService.getIsoFullDateFormat());
    return endTime.diff(startTime, 'hours') + 'h';
  };

  vm.getShiftPeriod = function(shift) {
    const startTime = moment(shift.startTime, dateService.getIsoFullDateFormat());
    const endTime = moment(shift.endTime, dateService.getIsoFullDateFormat());
    return [
      startTime.format(dateService.getTimeFormat()),
      endTime.format(dateService.getTimeFormat())
    ].join(' - ');
  };

  vm.isShort = shift => parseInt(vm.getShiftRect(shift).width) < SHORT_SHIFT_WIDTH;

  vm.onResizeEnd = function(shift) {
    isResized = true;
    if (isDraggedShiftStartTimeValid(shift)) {
      return updateShift(shift, function() {
        saveShift(shift);
      });
    } else {
      return loadSeriesShifts(shift);
    }
  };

  const loadAllSeries = () => {
    return seriesManager.loadAll().then(function (bookingSeries) {
      const promises = _.map(bookingSeries, series => loadSeriesShifts(series, false));
      return $q.all(promises).then(groupSeries);
    });
  };

  const seriesIsLocked = shift => shift && !!seriesManager.findEntity(shift.bookingSeriesId).lockedBy;

  const closePopup = function() {
    vm.isShownShiftPopup = false;
    loadSeriesShifts(vm.popupShift);
    return vm.popupShift = undefined;
  };

  const updateShift = function(shift, cb) {
    const series = seriesManager.findEntity(shift.bookingSeriesId);
    if (series.shifts.length === 1) {
      if (!shift.editMode) {
        if (bookingRepeatable.isNone(shift.repeatable)) {
          shift.editMode = bookingShiftEditMode.getOnlyCurrentMode();
        } else {
          shift.editMode = bookingShiftEditMode.getFollowingMode();
        }
      }
      cb();
    } else {
      vm.editModeService.show(shift, function() {
        shift.titleEditMode = bookingShiftTitleEditMode.getOnlyCurrentMode();
        cb();
      }, () => loadSeriesShifts(shift));
    }
  };

  const getSeries = function(model) {
    if (model instanceof BookingShift) {
      return $q.when(seriesManager.findEntity(model.bookingSeriesId));
    } else {
      const series = seriesManager.createEntity();
      series.resourceId = model.id;
      return seriesManager.saveEntity(series).then(() => $q.when(series));
    }
  };

  const updateShiftPosition = shiftElement => {
    return vm.shiftPopupPosition = {
      left: elementService.getOffsetLeft(shiftElement) + shiftElement.offsetWidth,
      top: elementService.getOffsetTop(shiftElement) + (shiftElement.offsetHeight / 2)
    };
  };

  const onWindowChange = function() {
    if (vm.isShownShiftPopup) { return $scope.$apply(onScrollCallback); }
  };

  const loadSeriesShifts = function(model, withGrouping) {
    if (withGrouping == null) { withGrouping = true; }
    const series = model instanceof BookingSeries ? model : seriesManager.findEntity(model.bookingSeriesId);
    if (series) {
      return updateSeriesShift(series, withGrouping);
    } else {
      return loadAllSeries();
    }
  };

  const updateSeriesShift = (series, withGrouping) => {
    return BookingShift.query(_.merge(queryParams, {
      bookingSeriesId: series.id,
      from,
      to
    }), {
      bookingId: vm.booking.id
    }).then(function (shifts) {
      for (let shift of Array.from(shifts)) {
        shift.uniqueApprovedEmployees = uniqueValuesService.getUniqueValuesById(shift.approvedEmployees);
      }
      series.shifts = shifts;
      if (withGrouping) {
        groupSeries();
      }
      return $q.when(shifts);
    });
  };

  const saveShift = shift => {
    return shift.save().then(() => {
      shift.editMode = bookingShiftEditMode.getFollowingMode();
      return loadSeriesShifts(shift);
    });
  };

  const groupSeries = () => {
    return withScrollReset(function () {
      const sortedSeries = {};
      for (let series of Array.from(seriesManager.entities)) {
        sortedSeries[series.id] = series;
      }
      return vm.groupedSeries = seriesGroupService.groupSeries(sortedSeries);
    });
  };

  const quickAddShiftHandler = function (event, resource) {
    if (!resource) { return; }
    scrollToAddedShiftDate();
    getSeries(resource).then(function(series) {
      let shift = initShift(series);
      shiftDndService.updateShiftTimeForQuickAdd(shift, series.resource, vm.dateRange);
      if (isDraggedShiftStartTimeValid(shift)) {
        return shift.save().then((model) => {
          loadSeriesShifts(model).then(function () {
            $timeout(() => {
              vm.showShiftPopup(null, model, document.querySelector("[data-shift-id='" + model.id + "']"));
            });
          });
        });
      } else {
        vm.isShownShiftPopup = false;
        return loadSeriesShifts(shift);
      }
    })
  };

  const interactorServiceInitHandler = function(event, service) {
    interactorService = service;
  };

  const scrollToAddedShiftDate = function() {
    if (interactorService) {
      const now = moment();
      if (now.isBetween(vm.dateRange[0], vm.dateRange[1])) {
        interactorService.scrollToToday();
      } else {
        interactorService.scrollToStart();
      }
    }
  };

  const initShift = function(series) {
    return new BookingShift({
      bookingId: vm.booking.id,
      bookingSeriesId: series.id,
      enableClockIn: !resourceChargeType.isPerUnit(series.resource.chargeType) && vm.booking.enableClockIn
    });
  };

  const initCable = function() {
    cableService.createBookingTimelineChannel(currentUserService.getCurrentProfile().securityProvider.id);
    $scope.$on('$destroy', cableService.destroyBookingTimelineChannel);
    return window.onunload = cableService.destroyBookingTimelineChannel;
  };

  const scrollHolder = $element[0].closest('.add-timeline');
  const withScrollReset = function(callback) {
    const { scrollLeft } = scrollHolder;
    callback();
    return setTimeout(() => scrollHolder.scrollLeft = scrollLeft, 0);
  };

  window.addEventListener('scroll', onWindowChange);
  window.addEventListener('resize', onWindowChange);

  $scope.$on('quickShiftAdded', quickAddShiftHandler);
  $scope.$on('interactorServiceInitiatedParent', interactorServiceInitHandler);

  $scope.$on('$destroy', function() {
    window.removeEventListener('scroll', onWindowChange);
    return window.removeEventListener('resize', onWindowChange);
  });

  return vm;

};

angular
  .module('public.timeline')
  .controller('TimelineBookingFormShiftsTimeLineShiftsController',
    dependencies.concat(TimelineBookingFormShiftsTimeLineShiftsController));
