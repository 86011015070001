angular.module('data.resource').service('rateType', function() {

  const TYPE_PAY_RATE = 1;
  const TYPE_CHARGE_RATE = 2;
  const TYPE_OVERTIME_RATE = 3;

  const names = {
    [TYPE_PAY_RATE]: 'Pay rate',
    [TYPE_CHARGE_RATE]: 'Charge rate',
    [TYPE_OVERTIME_RATE]: 'Overtime'
  };

  const fields = {
    [TYPE_PAY_RATE]: 'payRate',
    [TYPE_CHARGE_RATE]: 'chargeRate',
    [TYPE_OVERTIME_RATE]: 'overtimeRate'
  };

  const smallNames = {
    [TYPE_PAY_RATE]: 'pay rate',
    [TYPE_CHARGE_RATE]: 'charge rate',
    [TYPE_OVERTIME_RATE]: 'overtime'
  };

  return {
    getNames() {
      return names;
    },

    getSmallNames() {
      return smallNames;
    },

    getPayRate() {
      return TYPE_PAY_RATE;
    },

    getChargeRate() {
      return TYPE_CHARGE_RATE;
    },

    isPayRate(type) {
      return type === TYPE_PAY_RATE;
    },

    isChargeRate(type) {
      return type === TYPE_CHARGE_RATE;
    },

    isOvertimeRate(type) {
      return type === TYPE_OVERTIME_RATE;
    },

    getField(type) {
      return fields[type];
    }
  };
});
