/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'defaultConfig', 'ClientLocation', 'packageTypeService', 'systemPackagePopupService', 'activeStatus'
];

const PopupSystemPackageDowngradeLocationsController = function(
  $scope, defaultConfig, ClientLocation, packageTypeService, systemPackagePopupService, activeStatus
) {

  const vm = this;

  vm.toggleLocation = function(location) {
    if (!location.isSelected) {
      deselectAll();
    }
    location.isSelected = !location.isSelected;
  };

  vm.onCheckBoxClick = function(e) {
    e.stopPropagation();
  };

  vm.isSelectedNumberValid = function() {
    return vm.getSelectedCount() === packageTypeService.getLocationsLimitForFreePackage();
  };

  vm.onNextStep = function() {
    if (!vm.isSelectedNumberValid()) { return; }
    vm.downgradeService.addToStore('locations', vm.clientLocations);
    $scope.popupCtrl.closePopup();
    systemPackagePopupService.onFormClose(systemPackagePopupService.getPackageType());
  };

  vm.getGoogleMapSrc = function({ address, lat, lng }) {
    let center;
    if (lat && lng) {
      center = `${lat},${lng}`;
    } else if (address.length > 0) {
      center = address;
    }
    return `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=14&size=90x90&markers=color:blue%7C${center}&key=${defaultConfig.googleApiKey}`;
  };

  vm.isFirstStep = function() {
    return vm.downgradeService.getStep() === 1;
  };

  vm.goBack = function() {
    vm.downgradeService.goBack(systemPackagePopupService.getPackageType(), $scope.popupCtrl.closePopup);
  };

  vm.getSelectedCount = function() {
    return _.filter(vm.clientLocations, 'isSelected').length;
  };

  const deselectAll = function() {
    vm.clientLocations.forEach((location) => {
      location.isSelected = false;
    });
  };

  const initDowngradeService = function() {
    vm.downgradeService = systemPackagePopupService.getSystemPackageDowngrade();
  };

  const initLocations = function() {
    ClientLocation.query({ activeStatus: activeStatus.getActiveStatus() }, { clientId : vm.downgradeService.getClientId() }).then(function (locations) {
      vm.clientLocations = locations;
    });
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      initDowngradeService();
      initLocations();
    }
  });

  return vm;

};

angular.module('popup.system-package')
  .controller('PopupSystemPackageDowngradeLocationsController', dependencies.concat(PopupSystemPackageDowngradeLocationsController));
