const dependencies = [];

const DayOffShiftController = function() {

  const vm = this;

  vm.closeTemp = function () {
    vm.onClose();
  };

  vm.onSelectEventType = function(option) {
    vm.eventOptions.setSelectedOption(option);
    vm.isShownPopup = true;
  };

  return vm;
};

angular.module('public.security-manager.schedule-manager.timeline').controller('DayOffShiftController',
  dependencies.concat(DayOffShiftController));
