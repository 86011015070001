/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', '$location', 'EmployeeDocument', 'EntityManager', 'FilterParamsService',
  'documentTypeIconService', 'downloadService', 'dateService'
];

const EmployeeProfileDocumentsController = function(
  $scope, $location, EmployeeDocument, EntityManager, FilterParamsService,
  documentTypeIconService, downloadService, dateService
) {

  const vm = this;

  vm.isShownViewPopup = false;

  const entityManager = new EntityManager(EmployeeDocument, {
    params: {
      employeeId: $scope.employeeProfileLayoutCtrl.employeeId
    }
  });

  const filterParamsService = new FilterParamsService({
    order: ['name']
  });

  vm.filterParams = filterParamsService.getParams();
  vm.documentTypeIcon = documentTypeIconService;

  vm.$onInit = function() {
    $scope.employeeProfileLayoutCtrl.loadEmployee().then(employee => vm.employee = employee);
  };

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  vm.loadMoreDocuments = () => entityManager.loadMore();

  vm.getDocuments = () => entityManager.entities;

  vm.showViewPopup = function(document) {
    vm.popupViewDocument = document;
    return vm.isShownViewPopup = true;
  };

  vm.closeViewPopup = () => vm.isShownViewPopup = false;

  vm.getFileIcon = document => `#file-type-${document.fileExtension}`;

  vm.isExpiredDocument = function(document) {
    if (document.expireDate) {
      return dateService.today().isAfter(moment(document.expireDate, dateService.getIsoDateFormat()));
    } else {
      return false;
    }
  };

  vm.downloadDocument = function(document) {
    downloadService.downloadWithoutCache(document.file, document.fileName);
  };

  const filterDocuments = function() {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    $location.search(urlParams);
    return entityManager.load(urlParams);
  };

  $scope.$watch(() => vm.filterParams, filterDocuments, true);

  return vm;

};

angular.module('public.employee.profile')
  .controller('EmployeeProfileDocumentsController', dependencies.concat(EmployeeProfileDocumentsController));
