angular.module('shared').factory('CountryCodeInput', ['$http', '$timeout', '$filter', 'Country', function($http, $timeout, $filter, Country) {

  const DEFAULT_COUNTRY = 'United Kingdom of Great Britain and Northern Ireland';
  const HINT_DELAY = 700;

  class CountryCodeInput {

    constructor() {
      this.cleaveInstance = null;
      this.isOpenedCodesDropdown = false;
      this.phoneHolder = null;
      this.isFocusedPhoneField = false;
      this.searchString = '';
      this.isShownHint = false;
    }

    setPhoneHolder(holder) {
      this.phoneHolder = holder;
    }

    initPhoneOptions() {
      // Default values for cleave.js better not to delete
      this.phoneOptions = {
        phone: true,
        onValueChanged: this.setCountryCodeToPhoneNumber.bind(this),
        phoneRegionCode: 'GB'
      };
    }

    initPhoneCodesList(successCb) {
      Country.getCodes().then((countries) => {
        this.selectCodes = countries;
        if (successCb) {
          successCb();
        } else {
          this.initPhoneNumber();
        }
      });
    }

    initPhoneNumber(defaultCountry) {
      if (this.phoneHolder.phoneNumber) {
        const phoneNumberParts = this.phoneHolder.phoneNumber.split(' ');
        this.selectedCode = _.find(this.selectCodes, function(code) {
          return +code.callingCode === +phoneNumberParts[0];
        });
        if (!this.selectedCode) {
          this.selectedCode = _.find(this.selectCodes, ['name', DEFAULT_COUNTRY]);
        }
        this.initPhoneFormatOptions();
        this.phoneHolder.phoneNumber = `${phoneNumberParts[0]}${phoneNumberParts[1]}`;
      } else {
        this.selectedCode = _.find(this.selectCodes, ['name', defaultCountry || DEFAULT_COUNTRY]);
        this.initPhoneFormatOptions();
        this.phoneHolder.phoneNumber = `+${this.selectedCode.callingCode}`;
      }
    }

    initPhoneFormatOptions() {
      this.cleaveInstance.setPhoneRegionCode(this.selectedCode.alpha2Code);
    }

    setCleaveInstance(cleave) {
      this.cleaveInstance = cleave;
    }

    toggleCodesDropdown() {
      this.isOpenedCodesDropdown = !this.isOpenedCodesDropdown;
    }

    closeCodesDropdown() {
      this.isOpenedCodesDropdown = false;
    }

    setCountryCodeToPhoneNumber(e) {
      if (this.phoneHolder.phoneNumber && this.selectedCode && !e.target.rawValue.startsWith(`+${this.selectedCode.callingCode}`)) {
        $timeout(() => {
          this.phoneHolder.phoneNumber = `+${this.selectedCode.callingCode}`;
        });
      }
    }

    getFormattedPhoneNumber() {
      let numberArr = this.phoneHolder.phoneNumber.split('');
      numberArr.splice(this.selectedCode.callingCode.length + 1, 0, ' ');
      return numberArr.join('');
    }

    selectCountry(code) {
      this.selectedCode = code;
      this.phoneHolder.phoneNumber = `+${code.callingCode}`;
      this.phoneOptions.phoneRegionCode = code.alpha2Code;
      this.isFocusedPhoneField = false;
      $timeout(() => {
        this.isFocusedPhoneField = true;
      });
    }

    highlight(code) {
      return $filter('highlight')(`${code.name} (+${code.callingCode})`, this.searchString, 'is-highlighted');
    }

    showHint() {
      if (this.isShownHint) { return; }
      this.isShownHint = true;
    }

    hideHint() {
      this.isShownHint = false;
    }

    showHintWithDelay() {
      $timeout(() => {
        this.showHint();
      }, HINT_DELAY);
    }

    getTooltipText() {
      return 'Entering your number please use the international format and do not include the first "0"';
    }

  }

  return CountryCodeInput;
}]);
