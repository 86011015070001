/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', '$state', 'localStorageService', 'confirmService', 'clientStatus', '$timeout', 'Client'];

const ClientListPreviewController = function($scope, $state, localStorageService, confirmService, clientStatus, $timeout, Client) {

  const vm = this;

  vm.client = $scope.client;

  vm.editClient = function() {
    localStorageService.set('client.popup.edit.open', true);
    return $state.go('public.security-manager.client.details', {clientId : vm.client.id});
  };

  vm.checkedDeleteFutureShifts = () =>
    $timeout(() =>
      confirmService.confirm(
        'Cancel future shifts/bookings?',
        `Do you want to cancel future shifts/bookings of ${vm.client.name}?`,
        'Yes, cancel future bookings',
        () => vm.client.cancelFutureShifts())
    )
  ;

  vm.disableClient = () =>
    confirmService.confirm(
      'Make company inactive?',
      'Do you want to make this company inactive?',
      'Yes, make inactive',
      function() {
        vm.client.status = clientStatus.getInactiveStatus();
        vm.client.save();
        return vm.checkedDeleteFutureShifts();
    })
  ;

  return vm;
};

angular.module('public.security-manager.client.list').controller('ClientListPreviewController',
  dependencies.concat(ClientListPreviewController));
