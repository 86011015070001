/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const clientInvoiceElementType = function() {

  const SHIFT_COST = 1;
  const SIMPLE_COST = 2;

  return {
    getShiftCost() {
      return SHIFT_COST;
    },

    getSimpleCost() {
      return SIMPLE_COST;
    }
  };
};

angular
  .module('public.security-manager.client.show.invoice')
  .service('clientInvoiceElementType', clientInvoiceElementType);