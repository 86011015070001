angular.module('public.security-manager.employee.invoice', ['public.invoice.employee']).config([
  '$stateProvider',
  function ($stateProvider) {

    $stateProvider

      .state('public.security-manager.employee.invoice', {
        url: '/invoice',
        abstract: true,
        template: require('./template.pug')
      })
      .state('public.security-manager.employee.invoice.list', {
        url: '/list?{ numberFilter, employeeId, from, to }',
        template: require('../../../invoice/employee/list/template.pug'),
        controller: 'InvoiceEmployeeInvoicesController',
        controllerAs: 'invoiceEmployeeInvoicesCtrl',
        reloadOnSearch: false
      });

  }
]);
