const dependencies = ['$timeout', 'currentUserService', 'systemPackagePopupService'];

const PopupClientClockInPackageRestrictionController = function($timeout, currentUserService, systemPackagePopupService) {

  const vm = this;

  vm.getPopupButtonText = function() {
    if (currentUserService.getCurrentProfile().isAccountOwner()) {
      return 'Upgrade plan';
    } else {
      return 'Got it';
    }
  };

  vm.doPackageRestrictionPopupAction = function() {
    if (currentUserService.getCurrentProfile().isAccountOwner()) {
      closePopup(function() {
        $timeout(() => {
          systemPackagePopupService.showFormPopup();
        });
      });
    } else {
      closePopup();
    }
  };

  const closePopup = function(onClose) {
    vm.gccsPopupsLayout.closePopup({
      onClose() {
        vm.gccsPopupsLayout.popupCloseCb();
        if (onClose) {
          onClose();
        }
      }
    });
  };

  return vm;

};

angular.module('shared-components.popup.client')
  .controller('PopupClientClockInPackageRestrictionController', dependencies.concat(PopupClientClockInPackageRestrictionController));
