dependencies = ['$q', 'Employee', 'currentUserService'];

const employeeSiblingProfileService = function($q, Employee, currentUserService) {

  let siblingProfiles = {};
  let promises = {};

  return {

    getSiblingProfiles: function (employeeId) {
      employeeId = employeeId || currentUserService.getCurrentProfile().id;
      if (siblingProfiles[employeeId]) {
        return $q.when(siblingProfiles[employeeId]);
      } else if (promises[employeeId]) {
        return promises[employeeId];
      } else {
        return promises[employeeId] = Employee.withSecurityProviders(employeeId).then(function(employees) {
          return siblingProfiles[employeeId] = employees;
        });
      }
    }

  }

};

angular.module('public.employee')
  .service('employeeSiblingProfileService', dependencies.concat(employeeSiblingProfileService));
