/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular
  .module('public.timeline')
  .directive('gccsTimelineBookingFormShiftsTimeLineShifts', () => {
    return {
      restrict: 'A',
      controller: 'TimelineBookingFormShiftsTimeLineShiftsController',
      controllerAs: 'shiftsCtrl',
      template: require('./template.pug'),
      bindToController: true,
      scope: {
        withEmployees: '<',
        withComments: '<',
        withLog: '<',
        booking: '<',
        updateWrapper: '&',
        isEdit: '<',
        isTemplate: '<'
      }
    };
  });
