const dependencies = ['$scope', 'Client', 'ClientService', 'selectService'];

const EmployeeProfileApproveServiceController = function($scope, Client, ClientService, selectService) {

  const vm = this;

  vm.getEmployeeResource = function(resource) {
    return _.find(vm.employee.employeeResources, function(employeeResource) {
      return employeeResource.resourceId === resource.id;
    });
  };

  const initClient = function() {
    Client.autocomplete({}, { id: vm.selectedClientId }).then(clients => {
      vm.client = clients[0];
    });
  };

  const initClientServices = function() {
    ClientService.getWithPayableResources(vm.selectedClientId).then(services => {
      let approvedServices = [];
      _.each(services, function(service) {
        service.approvedResources = _.filter(service.resources, function(resource) {
          return !!vm.getEmployeeResource(resource);
        });
        if (service.approvedResources.length > 0) {
          approvedServices.push(service);
        }
      });
      vm.services = approvedServices;
      vm.selectServices = selectService.toSelectData(approvedServices, { defaultField: 'All services' });
    });
  };

  $scope.$watch(() => vm.selectedClientId, (newVal, oldVal) => {
    if (newVal || oldVal) {
      initClient();
      initClientServices();
    }
  });

  return vm;

};

angular.module('public.security-manager.employee')
  .controller('EmployeeProfileApproveServiceController', dependencies.concat(EmployeeProfileApproveServiceController));
