const dependencies = ['dateService', 'employeeType', 'currentUserService'];

const ReportsMissingDataHistoryGraphController = function(dateService, employeeType, currentUserService) {

  const vm = this;

  let employmentStartDate = null;

  vm.$onInit = function() {
    initEmploymentStartDate();
  };

  vm.getGapItemsAddresses = function(gap) {
    return _.map(gap.items, function(item) {
      if (item.jobTitle) {
        return [item.jobTitle, item.name].join('; ');
      } else {
        return `${item.name}${item.address ?  '; ' + item.address : '' }`;
      }
    }).join('; ');
  };

  vm.getDropdownGapTitle = function(item) {
    if (item.jobTitle) {
      return [item.jobTitle, item.name].join('; ') + (item.address ? '; ' + item.address : '');
    } else {
      return `${item.name}${item.address ?  '; ' + item.address : '' }`;
    }
  };

  vm.getDateRange = function({ from, to }) {
    let dateParts = [moment(from, dateService.getIsoDateFormat()).format(dateService.getDateShortFormat())];
    if (to) {
      dateParts.push(moment(to, dateService.getIsoDateFormat()).format(dateService.getDateShortFormat()))
    } else {
      dateParts.push('Now');
    }
    return dateParts.join(' - ');
  };

  vm.isShownStartDate = function(month, year) {
    return employmentStartDate && employmentStartDate.year() === year && employmentStartDate.month() === month;
  };

  vm.getEmploymentStartText = function() {
    if (employmentStartDate) {
      return `Started with ${currentUserService.getCurrentProfile().securityProvider.companyName} - ${employmentStartDate.format(dateService.getDateShortFormat())}`;
    }
  };

  const initEmploymentStartDate = function() {
    if (vm.employee && vm.employee.employmentStartDate && employeeType.isEmployed(vm.employee.employmentType)) {
      employmentStartDate = moment(vm.employee.employmentStartDate, dateService.getIsoDateFormat());
    }
  };

  return vm;

};

angular.module('public.security-manager.reports.hr')
  .controller('ReportsMissingDataHistoryGraphController', dependencies.concat(ReportsMissingDataHistoryGraphController));
