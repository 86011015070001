/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', '$element', '$window', '$timeout', 'currentUserService', 'bookingCollapseService', 'clientStatus'
];

const BookingTimeLineController = function(
  $scope, $element, $window, $timeout, currentUserService, bookingCollapseService, clientStatus
) {

  const vm = this;

  const scrollHolder = $element[0].querySelector('.timeline-scroll-area');

  vm.isShownCalendar = false;
  vm.isCollapsedRow = bookingCollapseService.isCollapsedRow;

  let isFirstFiltering = true;

  vm.$onInit = () => {
    vm.service = new vm.timeLineInteractor(
      $scope,
      vm.timeLineLoader,
      scrollHolder,
      vm.from,
      $element[0],
      vm.shiftStatistics,
      vm.bookingTimelineShiftSelector
    );
  };

  vm.scrollLeftOneDay = function () {
    vm.service.scrollLeftBy(1);
  };

  vm.scrollRightOneDay = function () {
    vm.service.scrollRightBy(1);
  };

  vm.scrollRight = function () {
    vm.service.scrollRight();
  };

  vm.scrollLeft = function () {
    vm.service.scrollLeft();
  };

  vm.canOnlyView = function () {
    return (currentUserService.isSecurityManagerLogged() && currentUserService.getCurrentProfile().isSupervisor()) ||
      (
        currentUserService.isClientManagerLogged() &&
        (
          currentUserService.getCurrentProfile().isViewOnly() ||
          currentUserService.getCurrentProfile().services.length === 0 ||
          !clientStatus.isActive(currentUserService.getCurrentProfile().client.status)
        )
      );
  };

  vm.toggleCalendar = function() {
    if (vm.isShownCalendar) {
      return vm.hideCalendar();
    } else {
      vm.calendarDate = vm.service.from.clone();
      return vm.isShownCalendar = true;
    }
  };

  vm.hideCalendar = function () {
    vm.isShownCalendar = false;
  };

  vm.getClients = function () {
    return _.filter(vm.clients, client => client.visible && ((vm.selectedClients.length === 0) || (vm.selectedClients.indexOf(client.id) !== -1)));
  };

  const updateOnResize = function () {
    return $timeout(function () {
      if (vm.clients) {
        for (let dClient of Array.from(vm.clients)) {
          dClient.invalidateCache();
        }
      }
      return vm.service.setHourWidth($element[0].querySelector('.interval-day-item').getBoundingClientRect().width);
    });
  };

  updateOnResize();

  $window.addEventListener('resize', updateOnResize);

  $scope.$on('filterQueryParamsChanged', function($event, queryParams) {
    if (isFirstFiltering) {
      return isFirstFiltering = false;
    } else {
      return vm.service.loadDataAndSetView(queryParams);
    }
  });

  $scope.$on('$destroy', () => $window.removeEventListener('resize', updateOnResize));

  $scope.$on('setDate', function(model, date, view, isArrowClick) {
    if ((view === 'date') && !isArrowClick) {
      vm.hideCalendar();
      return vm.service.scrollTo(date);
    }
  });

  return vm;
};

angular.module('public.timeline').controller('BookingTimeLineController', dependencies.concat(BookingTimeLineController));
