/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$timeout', '$rootScope', '$q', 'FlexibleTimeLineConverter', 'FlexibleTimeLineViewSelector',
  'dateService', 'timelinePager'
];

const FlexibleTimeLineInteractor = (
  $timeout, $rootScope, $q, FlexibleTimeLineConverter, FlexibleTimeLineViewSelector,
  dateService, timelinePager
) => {

  const TIME_RANGE_FORMAT_BREAKPOINT = 130;
  const SMALL_SHIFT_WIDTH = 45;
  const DATE_FORMAT = dateService.getDateFormat();
  const COMMENTS_BREAKPOINT_WIDTH = 125;

  return class FlexibleTimeLineInteractor {

    constructor(timeLineLoader, scrollHolder, from, element, shiftStatistics, shiftSelector) {
      this.timeLineLoader = timeLineLoader;
      this.element = element;
      this.viewSelector = new FlexibleTimeLineViewSelector;
      this.viewSelector.selectViewByType(__guard__(timelinePager.getPagerParams(), x => x.viewType));
      this.scrollHolder = scrollHolder;
      this.timeLineConverter = new FlexibleTimeLineConverter(this.viewSelector.getCurrentView(), this.getHourWidth());
      this.shiftStatistics = shiftStatistics;
      this.shiftSelector = shiftSelector;
      this.queryParams = {};
      this._initDates(from || timelinePager.getPagerParams().from);
    }

    selectView(view) {
      const selected = this.viewSelector.selectView(view);
      if (selected) {
        this._showLoader();
        this.timeLineConverter.setOffsets(view);
        this.loadDataAndSetView();
        return $timeout(() => {
          return this.timeLineConverter.setHourWidth(this.getHourWidth());
        });
      }
    }

    getTitle() {
      return this.from.format('MMMM, YYYY');
    }

    getCurrentDate() {
      return this.from.format('MMMM D, YYYY');
    }

    isToday(date) {
      return date.format(DATE_FORMAT) === this.today.format(DATE_FORMAT);
    }

    getLastDate() {
      return this.getTo();
    }

    getHourWidth() {
      if (!this.element) { return 0; }
      return this.element.querySelector('.interval-day-item').getBoundingClientRect().width;
    }

    getCurrentTimeOffset() {
      if (!this.timeLineStart || !this.timeLineEnd) { return; }
      if ((this.now.diff(this.timeLineEnd) >= 0) || (this.timeLineStart.diff(this.now) > 0)) { return; }
      return this.timeLineConverter.getOffsetFromTime(this.now, this.timeLineStart) + this.timeLineConverter.getOutset();
    }

    getConditionalHolderWidth() {
      if (this.dates) {
        const width = this.timeLineConverter.getNumberOfIntervalsForDays(this.dates.length) * this.timeLineConverter.getHourWidth();
        return { 'width' : width + 'px' };
      } else {
        return {};
      }
    }

    scrollLeftBy(daysCount) {
      this.from.subtract(daysCount, 'days');
      return this.loadDataAndSetView();
    }

    scrollRightBy(daysCount) {
      this.from.add(daysCount, 'days');
      return this.loadDataAndSetView();
    }

    scrollLeft() {
      return this.scrollLeftBy(this.viewSelector.getCurrentDaysCount());
    }

    scrollRight() {
      return this.scrollRightBy(this.viewSelector.getCurrentDaysCount());
    }

    scrollToToday() {
      return this.scrollTo(this.today);
    }

    scrollTo(date) {
      if (this.from.isSame(date)) {
        return $q.when();
      } else {
        this.from = date.clone();
        return this.loadDataAndSetView();
      }
    }

    getOffsets() {
      return new Array(Math.floor(this.viewSelector.currentView.offsetCount));
    }

    setHourWidth(hourWidth) {
      return this.timeLineConverter.setHourWidth(hourWidth);
    }

    getFrom() {
      return this.timeLineStart;
    }

    getTo() {
      return this.timeLineEnd;
    }

    getShiftTime(dShift) {
      if (this.isSmallTime(dShift)) {
        const duration = dShift.getShiftDurationMoment();
        let label = `${ duration.hours() }h`;
        if (duration.minutes()) {
          label = label.concat(`${duration.minutes()}m`);
        }
        return label;
      } else {
        return dShift.getPeriod();
      }
    }

    isSmallTime(dShift) {
      return this._getShiftWidth(dShift) < TIME_RANGE_FORMAT_BREAKPOINT;
    }

    getTimeFromOffset(offset) {
      offset = offset - this.timeLineConverter.getOutset();
      return this.timeLineConverter.getTimeFromOffset(offset, this.timeLineStart, true);
    }

    getShiftDimensions(shift) {
      return {
        'width' : this._getShiftWidth(shift) + 'px',
        'left' : this.timeLineConverter.getInitialOffset() + this._getShiftOffset(shift) + 'px'
      };
    }

    getWidthOfDiff(time1, time2) {
      return time1.diff(time2, 'hours', true) * this.timeLineConverter.getHourWidth();
    }

    isSmallShift(shift) {
      return this._getShiftWidth(shift) < SMALL_SHIFT_WIDTH;
    }

    showCommentsIcon(shift) {
      return this._getShiftWidth(shift) >= COMMENTS_BREAKPOINT_WIDTH;
    }

    _initDates(from) {
      this._showLoader();
      if (from) {
        this.from = moment(from, DATE_FORMAT);
      } else {
        this.from = dateService.today();
      }
      this.today = dateService.today();
      this.now = moment();
      return this.loadDataAndSetView();
    }

    loadDataAndSetView(queryParams) {
      if (queryParams !== undefined) { this.queryParams = queryParams; }
      timelinePager.setPagerParams({from : this.from, viewType : this.viewSelector.getCurrentView().type});
      if (this.shiftSelector != null) {
        this.shiftSelector.multipleDeselectAll();
      }
      this.timeLineStart = this.from.clone().subtract(12, 'hours');
      this.timeLineEnd = this.timeLineStart.clone().add(this.viewSelector.getCurrentDaysCount() + 1, 'days');
      this._showLoader();
      return this.timeLineLoader.loadForDates(
        this.getFrom().format(dateService.getIsoFullDateFormatWithTz()),
        this.getTo().format(dateService.getIsoFullDateFormatWithTz()),
        this.queryParams
      ).then(() => {
        this._setDatesArray();
        return this._hideLoader();
      });
    }

    _setDatesArray() {
      const count = this.timeLineEnd.diff(this.timeLineStart, 'days');
      const epochTime = moment(0);
      return this.dates = _.map(__range__(0, count, false), increment => {
        const date = this.timeLineStart.clone().add(increment, 'days');
        date.dayEven = date.diff(epochTime, 'days') % 2;
        return date;
      });
    }

    _geOffsetFromTime(time, fromTime) {
      if (fromTime == null) { fromTime = this.timeLineStart; }
      return Math.max(0, this._getRawOffsetFromTime(time, fromTime));
    }

    _getRawOffsetFromTime(time, fromTime) {
      return this.timeLineConverter.getTimelineItemOffset(time, fromTime);
    }

    _getTimelineItemWidth(lengthInHours) {
      return this.timeLineConverter.getHourWidth() * lengthInHours;
    }

    _getShiftWidth(shift) {
      return this._getTimelineItemWidth(shift.getShiftLengthInHours());
    }

    _getShiftOffset(shift) {
      return this.timeLineConverter.getTimelineItemOffset(shift.getStartTimeMoment(), this.timeLineStart);
    }

    _showLoader() {
      return this.showLoader = true;
    }

    _hideLoader() {
      return this.showLoader = false;
    }

  }

};

angular.module('public.timeline').factory('FlexibleTimeLineInteractor', dependencies.concat(FlexibleTimeLineInteractor));

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
