const dependencies = ['dateService', 'notificationService'];

const employeeActivityService = (dateService, notificationService) => {

  return {

    isNew(employee) {
      const createdAtTime = moment(employee.createdAt, dateService.getIsoFullDateFormatWithTz());
      return moment().diff(createdAtTime, 'months') < 3;
    },

    getActivityLabel(employee) {
      if (employee.lastActive) {
        return moment(employee.lastActive, dateService.getIsoFullDateFormatWithTz()).fromNow();
      } else {
        return 'Never';
      }
    },

    resendInvite(employee, afterCb) {
      return employee.resendInvite().then(() => {
        notificationService.notifySuccess('The invitation has been resent');
        if (afterCb) {
          afterCb();
        }
      }).catch(function (error) {
        notificationService.notifyError('There was an error resending the invitation');
        if (afterCb) {
          afterCb();
        }
        Rollbar.log(error.message, error);
      });
    }

  };

};

angular.module('public.security-manager.employee')
  .service('employeeActivityService', dependencies.concat(employeeActivityService));
