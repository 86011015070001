/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('form').directive('gccsFormFileUploader', () => {
  return {
    restrict: 'A',
    template: require('./template.pug'),
    controller: 'FormFileUploaderController',
    controllerAs: 'formFileUploaderCtrl',
    scope: {
      name: '@',
      file: '=',
      fileName: '=',
      uploadedFile: '=',
      uploaderPattern: '@',
      uploaderMaxSize: '@',
      isRequired: '=',
      onFileUpload: '&',
      withProgress: '<'
    }
  };
});
