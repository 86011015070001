/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$http', '$timeout', 'User', 'SecurityManager', 'SecurityProviderSetting', 'CountryCodeInput'
];

const SignSecurityProviderStep3Controller = function(
  $scope, $http, $timeout, User, SecurityManager, SecurityProviderSetting, CountryCodeInput
) {

  const vm = this;

  $scope.signSecurityProviderLayoutCtrl.isSubmitButtonDisabled = false;

  vm.countryCodeInput = new CountryCodeInput();
  vm.countryCodeInput.isFocusedPhoneField = true;

  vm.$onInit = function () {
    initPhoneNumberWrapper();
    vm.countryCodeInput.initPhoneOptions();
    vm.countryCodeInput.initPhoneCodesList(initSecurityManager);
  };

  vm.onCleaveInit = function (cleave) {
    vm.countryCodeInput.setCleaveInstance(cleave);
  };

  const initSecurityManager = function () {
    $scope.signSecurityProviderLayoutCtrl.loadSecurityProvider().then(function(securityProvider) {
      vm.securityManager = new SecurityManager(securityProvider.accountOwner);
      vm.phoneNumberWrapper.phoneNumber = vm.securityManager.phoneNumber;
      SecurityProviderSetting.get({ securityProviderId: securityProvider.id }).then(function(securityProviderSettings) {
        vm.securityProviderSettings = securityProviderSettings;
        vm.countryCodeInput.initPhoneNumber(vm.securityProviderSettings.country.name);
      });
    }).catch(function() {
      $scope.signSecurityProviderLayoutCtrl.gotoFirstStep();
    });
  };

  const initPhoneNumberWrapper = function () {
    vm.phoneNumberWrapper = {};
    vm.countryCodeInput.setPhoneHolder(vm.phoneNumberWrapper);
  };

  $scope.$on('security-provider.registration.submit', () => {
    vm.phoneNumberWrapper.phoneNumber = vm.countryCodeInput.getFormattedPhoneNumber();
    const phoneNumber = vm.phoneNumberWrapper.phoneNumber;
    vm.errorMessage = null;
    User.validate({ phoneNumber: phoneNumber, except: vm.securityManager.userId }).then(function(isUnique) {
      if (!isUnique) {
        $scope.signSecurityProviderLayoutCtrl.resetFormSubmitted();
        vm.errorMessage = 'Phone number is not unique';
      } else {
        vm.securityManager.user.phoneNumber = phoneNumber;
        vm.securityManager.save().then(function() {
          $scope.signSecurityProviderLayoutCtrl.resetCache();
          $scope.signSecurityProviderLayoutCtrl.goNext();
        });
      }
    });
  });

  return vm;

};

angular.module('sign.security-provider')
  .controller('SignSecurityProviderStep3Controller', dependencies.concat(SignSecurityProviderStep3Controller));
