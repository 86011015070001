/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.employee').factory('EmployeeDocument', ['Document', function(Document) {

  class EmployeeDocument extends Document {
    static initClass() {
      this.configure({url : '/employees/{{employeeId}}/documents/{{id}}', name : 'document'});
    }
  }

  EmployeeDocument.initClass();
  return EmployeeDocument;
}
]);