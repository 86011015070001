/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.client.registration', []).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.client-registration', {
      abstract: true,
      url: '/client/registration',
      template: require('./template.pug'),
      controller: 'ClientRegistrationLayoutController',
      controllerAs: 'clientRegistrationLayoutCtrl'
    })
    .state('public.security-manager.client-registration.step-1', {
      url: '/step1',
      template: require('./company-details/template.pug'),
      controller: 'ClientRegistrationCompanyDetailsController',
      controllerAs: 'clientRegistrationCompanyDetailsCtrl'
    })
    .state('public.security-manager.client-registration.step-2', {
      url: '/step2',
      template: require('./location-management/template.pug'),
      controller: 'ClientRegistrationLocationManagementController',
      controllerAs: 'clientRegistrationLocationManagementCtrl'
    })
    .state('public.security-manager.client-registration.step-3', {
      url: '/step3',
      template: require('./service-creation/template.pug'),
      controller: 'ClientRegistrationServiceCreationController',
      controllerAs: 'clientRegistrationServiceCreationCtrl'
    })
    .state('public.security-manager.client-registration.step-4', {
      url: '/step4',
      template: require('./manager-invitation/template.pug'),
      controller: 'ClientRegistrationManagerInvitationController',
      controllerAs: 'clientRegistrationManagerInvitationCtrl'
    })

]);
