/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'EntityManager', 'FilterParamsService', 'EmployeeEmail', 'emailStatus', 'packageTypeService'
];

const EmployeeShowEmailsController = function (
  $scope, EntityManager, FilterParamsService, EmployeeEmail, emailStatus, packageTypeService
) {

  const vm = this;

  const EMAIL_STATUS_SENT = emailStatus.getSentStatus();

  const entityManager = new EntityManager(EmployeeEmail, {
    queryParams: {
      status: EMAIL_STATUS_SENT,
      employeeId: $scope.employeeShowLayoutCtrl.employeeId
    }
  });

  const filterParamsService = new FilterParamsService({ filter: ['searchString'] });

  vm.filterParams = filterParamsService.getParams();

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  let loadedEmployee = null;
  let openedEmployeeEmail = null;

  vm.$onInit = function () {
    packageTypeService.checkProPackage();
    EmployeeEmail.getCount({}, {
      status: emailStatus.getSentStatus(),
      employeeId: $scope.employeeShowLayoutCtrl.employeeId
    }).then(function (data) {
      vm.emailsCount = data.count;
    });
    $scope.employeeShowLayoutCtrl.loadEmployee().then(function (employee) {
      loadedEmployee = employee;
    });
  };

  vm.getEmails = function () {
    return entityManager.entities;
  };

  vm.loadMoreEmployeeEmails = function () {
    entityManager.loadMore();
  };

  vm.showEmployeeEmailPopup = function () {
    const popupEmployeeEmail = entityManager.createEntity();
    popupEmployeeEmail.receivers = [{
      employee: {
        id: loadedEmployee.id,
        email: loadedEmployee.user.email,
        fullName: loadedEmployee.user.fullName
      }
    }];
    vm.popupEmployeeEmail = popupEmployeeEmail;
    vm.isShownEmailPopup = true;
  };

  vm.closeEmployeeEmailPopup = function (employeeEmail) {
    closePopup();
    if ((employeeEmail && employeeEmail.status === EMAIL_STATUS_SENT) && currentEmployeeInReceivers(employeeEmail)) {
      entityManager.unshiftEntity(employeeEmail);
    }
  };

  vm.resendEmail = function (employeeEmail) {
    openedEmployeeEmail = employeeEmail;
    const popupEmployeeEmail = angular.copy(employeeEmail);
    popupEmployeeEmail.id = null;
    vm.popupEmployeeEmail = popupEmployeeEmail;
    vm.isShownEmailPopup = true;
  };

  vm.removeEmployeeEmail = function () {
    $scope.$apply(() => entityManager.removeEntity(openedEmployeeEmail, closePopup));
  };

  const closePopup = function () {
    vm.popupEmployeeEmail = null;
    vm.isShownEmailPopup = false;
    openedEmployeeEmail = null;
  };

  const currentEmployeeInReceivers = function (employeeEmail) {
    return _.find(employeeEmail.receiverEmails, receiverEmail => receiverEmail.id === loadedEmployee.id);
  };

  const filterEmployeeEmails = function () {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    entityManager.load(urlParams);
  };

  $scope.$watch(function () {
    return vm.filterParams;
  }, _.debounce(filterEmployeeEmails, 50), true);

  return vm;
};

angular.module('public.security-manager.employee').controller('EmployeeShowEmailsController',
  dependencies.concat(EmployeeShowEmailsController));
