const dependencies = ['$scope', '$element', '$state', '$timeout', 'confirmService'];

const PopupController = function($scope, $element, $state, $timeout, confirmService) {

  const OPENED_POPUP_CLASS = 'mod-opened-popup';

  const vm = this;

  vm.isShownPopup = false;
  vm.confirmBeforeClose = false;
  vm.isEditable = true;
  vm.overlayClass = '';

  const htmlElement = document.querySelector('html');

  vm.showPopup = function() {
    if (!vm.isShownPopup) {
      vm.isShownPopup = true;
      $timeout(() => $element.css('z-index', document.querySelectorAll('.popup-overlay:not(.ng-hide)').length + 990));
      htmlElement.classList.add(OPENED_POPUP_CLASS);
      $scope.$broadcast('popup.open');
    }
  };

  vm.closePopup = function(onClose, isSubmitPopup = false) {
    if (!vm.isShownPopup) { return; }
    if (vm.confirmBeforeClose && vm.isEditable && !isSubmitPopup) {
      confirmService.confirm(
        'Confirm close',
        'Are you sure you want to close popup? Changes may be lost.',
        'Yes',
        () => closePopup(onClose)
      );
    } else {
      closePopup(onClose);
    }
  };

  const closePopup = function(onClose) {
    vm.isShownPopup = false;
    htmlElement.classList.remove(OPENED_POPUP_CLASS);
    $scope.$broadcast('popup.close');
    if (onClose) {
      onClose();
    } else {
      $scope.popupCloseCb();
    }
  };

  $scope.$watch('popupShowCondition', function() {
    if ($scope.popupShowCondition) { $scope.popupCtrl.showPopup(); }
  });

  $scope.$on('$stateChangeStart', function(event, toState, toParams) {
    if (vm.isShownPopup) {
      event.preventDefault();
      vm.closePopup();
      setTimeout(() => $state.go(toState, toParams), 0);
    }
  });

  return vm;

};

angular.module('popup').controller('PopupController', dependencies.concat(PopupController));
