const gccsSecurityManagerNotificationPreferences = {
  controller: 'SecurityManagerNotificationPreferencesController',
  controllerAs: 'securityManagerNotificationPreferencesCtrl',
  template: require('./template.pug'),
  bindings: {
    securityManagerId: '<'
  }
};

angular.module('public.security-manager.notifications')
  .component('gccsSecurityManagerNotificationPreferences', gccsSecurityManagerNotificationPreferences);
