/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('landing', [
  'landing.index',
  'landing.pricing',
  'data.demo-request'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider
    .state('landing', {
      url: '',
      abstract: true,
      template: require('./template.pug'),
      controller: 'LandingController',
      controllerAs: 'landingController',
      resolve: {
        gccsConfig: ['Config', Config => Config.get()],
        currentUser: [
          'Session', '$state', 'currentUserService', 'redirectService',
          (Session, $state, currentUserService, redirectService) => {
            return Session.getInstance().then(function (currentSession) {
              currentUserService.setCurrentUserAndSession(currentSession);
              if (currentSession.isLoggedIn) {
                //return redirectService.redirectToMain();
              }
            }, () => null);
          }
        ]
      }
    })

]);
