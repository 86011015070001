const gccsEmployeeTimelineBlowOutModal = {
  controller: 'EmployeeTimelineBlowOutModalController',
  controllerAs: 'employeeTimelineBlowOutModalCtrl',
  template: require('./template.pug'),
  bindings: {
    timelineItem: '<',
    position: '=',
    closeTimelineItemPopup: '&'
  }
};

angular.module('public.employee.timeline')
  .component('gccsEmployeeTimelineBlowOutModal', gccsEmployeeTimelineBlowOutModal);
