/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.client').factory('ClientDocument', ['Document', function(Document) {

  class ClientDocument extends Document {
    static initClass() {
      this.configure({url : '/clients/{{clientId}}/documents/{{id}}', name : 'document'});
    }
  }

  ClientDocument.initClass();
  return ClientDocument;
}
]);
