angular.module('data.resource').service('resourceIcon', function() {

  const UPLOADED = -1;
  const ALARM_RESPONSE = 1;
  const FEMALE_SECURITY_OFFICER = 2;
  const KEY_HOLDING = 3;
  const PATROL_SECURITY = 4;
  const RADIO_RENTAL = 5;
  const SECRET_CAMERA = 6;
  const SECRET_SHOPPER = 7;
  const SECURITY_OFFICER = 8;
  const SECURITY_SUPERVISOR = 9;
  const STEWARD = 10;
  const TRAFFIC_MARSHAL = 11;
  const TRAINER = 12;

  const iconClasses = {
    [ALARM_RESPONSE]: 'mod-alarm-response',
    [FEMALE_SECURITY_OFFICER]: 'mod-female-security-officer',
    [KEY_HOLDING]: 'mod-key-holding',
    [PATROL_SECURITY]: 'mod-patrol-security',
    [RADIO_RENTAL]: 'mod-radio-rental',
    [SECRET_CAMERA]: 'mod-secret-camera',
    [SECRET_SHOPPER]: 'mod-secret-shopper',
    [SECURITY_OFFICER]: 'mod-security-officer',
    [SECURITY_SUPERVISOR]: 'mod-security-supervisor',
    [STEWARD]: 'mod-steward',
    [TRAFFIC_MARSHAL]: 'mod-traffic-marshal',
    [TRAINER]: 'mod-trainer'
  };

  return {
    getFirstIcon() {
      return ALARM_RESPONSE;
    },
  
    getIconClasses() {
      return iconClasses;
    },

    getIconClass(iconType) {
      return iconClasses[iconType];
    },

    getUploadedType() {
      return UPLOADED;
    },

    isUploadedType(iconType) {
      return iconType === UPLOADED;
    }
  };
});
