const dependencies = ['storageService'];

const clockInHintService = function(storageService) {

  const STORAGE_KEY = 'clockInHintViewsCount';
  const HINT_ON_BOARD_LIMIT = 5;

  const getHintViewsCount = function() {
    const clockInHintViewsCount = storageService.get(STORAGE_KEY);
    return +clockInHintViewsCount || 0;
  };

  const isHintOnBoardFinished = function() {
    return getHintViewsCount() >= HINT_ON_BOARD_LIMIT;
  };
  
  let isShownHint = !isHintOnBoardFinished();
  
  const updateHintViewsCount = function() {
    storageService.set(STORAGE_KEY, getHintViewsCount() + 1);
  };

  return {

    isShownClockInHint() {
      return isShownHint;
    },

    hideClockInHint() {
      if (!isShownHint) { return; }
      isShownHint = false;
      updateHintViewsCount();
    }

  }

};

angular.module('shared-components.popup.booking')
  .service('clockInHintService', dependencies.concat(clockInHintService));
