const dependencies = ['User', 'clipboardService', 'notificationService'];

const PopupInviteEmployeeMessageController = function(User, clipboardService, notificationService) {

  const vm = this;

  vm.$onInit = function() {
    initInvitationLink();
  };

  vm.copyInvitationLink = function() {
    clipboardService.copyToClipboard(vm.employeeInvitationLink, () => {
      notificationService.notifyInfo('Link copied to clipboard');
    });
  };

  const initInvitationLink = function() {
    new User({ id: vm.employee.userId }).getInvitationLink().then(function(data) {
      vm.employeeInvitationLink = data.invitationLink;
    });
  };

  return vm;

};

angular.module('public.security-manager')
  .controller('PopupInviteEmployeeMessageController', dependencies.concat(PopupInviteEmployeeMessageController));
