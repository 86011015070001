const dependencies = [
  '$scope', '$location', 'LiveFeedDateRangeSelector', 'dateService', 'bookingShiftIssueType', 'cableService'
];

const LiveFeedHeaderController = function(
  $scope, $location, LiveFeedDateRangeSelector, dateService, bookingShiftIssueType, cableService
) {

  const vm = this;

  let initialFrom, initialTo;

  vm.isShownCalendar = false;
  vm.dateFormat = dateService.getDateFormat();
  vm.bookingShiftIssueType = bookingShiftIssueType;
  vm.customData = {
    withDateRanges: true,
    disableArrows: true,
    onSubmit() {
      vm.hideCalendar();
      vm.loader.updateSearchParams({
        from: vm.from.format(dateService.getIsoDateFormat()),
        to: vm.to.format(dateService.getIsoDateFormat())
      });
      copyInitialDates();
    },
    onCancel() {
      vm.from = initialFrom.clone();
      vm.to = initialTo.clone();
      vm.hideCalendar();
    }
  };

  vm.$onInit = function() {
    vm.minDate = dateService.today();
    vm.maxDate = dateService.today().add(1, 'week');
    vm.issueType = vm.loader.searchParams.type;
    initDates();
    copyInitialDates();
    initDateRanges();
    initLiveFeedCallbacks();
  };

  vm.toggleCalendar = function() {
    if (vm.isShownCalendar) {
      vm.hideCalendar();
    } else {
      vm.isShownCalendar = true;
    }
  };

  vm.hideCalendar = function () {
    vm.isShownCalendar = false;
  };

  vm.getFormattedRange = function() {
    return `${vm.from.format(dateService.getShortTextFormat())} - ${vm.to.format(dateService.getShortTextFormat())}`;
  };

  vm.onIssueTypeChange = function() {
    vm.loader.updateSearchParams({ type: vm.issueType });
  };

  vm.resetDates = function() {
    vm.loader.resetDates();
    initDates();
  };

  $scope.onDateChange = function() {
    vm.customData.rangeSelector.setCustom();
  };

  const initDates = function() {
    vm.from = moment(vm.loader.searchParams.from, dateService.getIsoDateFormat());
    vm.to = moment(vm.loader.searchParams.to, dateService.getIsoDateFormat());
    if (vm.from.isBefore(vm.minDate)) {
      vm.from = vm.minDate.clone();
    }
    if (vm.to.isAfter(vm.maxDate)) {
      vm.to = vm.maxDate.clone();
    }
    vm.loader.updateSearchParams({
      from: vm.from.format(dateService.getIsoDateFormat()),
      to: vm.to.format(dateService.getIsoDateFormat())
    });
  };

  const copyInitialDates = function() {
    initialFrom = vm.from.clone();
    initialTo = vm.to.clone();
  };

  const initDateRanges = function() {
    vm.customData.rangeSelector = new LiveFeedDateRangeSelector(
      dateService.today(),
      vm.from,
      vm.to,
      setFromDateModel,
      setToDateModel
    );
    vm.customData.rangeSelector.smartSelect();
  };

  const setFromDateModel = startDate => vm.from = startDate;

  const setToDateModel = endDate => vm.to = endDate;

  const initLiveFeedCallbacks = function() {
    const liveFeedChannel = cableService.getLiveFeedChannel();
    liveFeedChannel.addCallback('issue_create', function() {
      vm.loader.loadCounts();
    });
    liveFeedChannel.addCallback('issue_destroy', function() {
      vm.loader.loadCounts();
    });
    liveFeedChannel.addCallback('shift_change', function() {
      vm.loader.loadCounts();
    });
  };

  $scope.$watch(() => vm.from, function() {
    vm.maxDate = vm.from.clone().add(1, 'week');
  });

  return vm;

};

angular.module('public.security-manager.schedule-manager.timeline')
  .controller('LiveFeedHeaderController', dependencies.concat(LiveFeedHeaderController));
