const securityManagerNotificationType = function() {

  const EMPLOYEE_REGISTRATION = 1;
  const EMPLOYEE_DAY_OFF_REQUEST = 2;
  const EMPLOYEE_SHIFT_CANCELLATION = 3;
  const NEW_CLIENT = 4;
  const NEW_BOOKING_BY_CLIENT = 5;
  const CHANGED_BOOKING = 6;
  const UNASSIGNED_SHIFT_WARNING = 7;
  const NEW_BOOKING_COMMENT = 8;
  const NEW_SHIFT_COMMENT = 9;
  const CANCELLED_BOOKING = 10;
  const NEW_BOOKING_BY_PROVIDER = 11;
  const INVOICE_BY_SELF_EMPLOYED = 12;
  const UNINVOICED_PERIOD = 13;
  const LICENSE_EXPIRATION = 14;
  const CARD_EXPIRATION = 15;
  const SHIFT_REMOVAL = 16;
  const DOCUMENT_EXPIRATION = 17;
  const EMPLOYEE_GROUP_REGISTRATION = 18;
  const EMPLOYEE_SICK_DAY_REQUEST = 19;
  const NEW_REPEATABLE_BOOKING_BY_CLIENT = 20;
  const CHANGED_REPEATABLE_BOOKING = 21;
  const LATE_CLOCK_IN = 22;
  const MISSED_CLOCK_IN = 23;
  const EARLY_CLOCK_OUT = 24;
  const LATE_CLOCK_OUT = 25;
  const MISSED_CLOCK_OUT = 26;
  const MANUAL_CLOCK_IN = 27;
  const MANUAL_CLOCK_OUT = 28;
  const MANAGED_BOOKING = 29;
  const MISSED_CHECK_IN = 30;
  const HUMYN_SYNC_EMPLOYER_ISSUE = 31;
  const HUMYN_SYNC_EMPLOYEE_ISSUE = 32

  const ALL_STATUSES = [
    EMPLOYEE_REGISTRATION, EMPLOYEE_DAY_OFF_REQUEST, EMPLOYEE_SHIFT_CANCELLATION, NEW_CLIENT, NEW_BOOKING_BY_CLIENT,
    CHANGED_BOOKING, UNASSIGNED_SHIFT_WARNING, NEW_BOOKING_COMMENT, NEW_SHIFT_COMMENT, CANCELLED_BOOKING,
    NEW_BOOKING_BY_PROVIDER, INVOICE_BY_SELF_EMPLOYED, UNINVOICED_PERIOD, LICENSE_EXPIRATION, CARD_EXPIRATION,
    SHIFT_REMOVAL, DOCUMENT_EXPIRATION, EMPLOYEE_GROUP_REGISTRATION, EMPLOYEE_SICK_DAY_REQUEST,
    NEW_REPEATABLE_BOOKING_BY_CLIENT, CHANGED_REPEATABLE_BOOKING, LATE_CLOCK_IN, MISSED_CLOCK_IN, EARLY_CLOCK_OUT,
    LATE_CLOCK_OUT, MISSED_CLOCK_OUT, MANUAL_CLOCK_IN, MANUAL_CLOCK_OUT, MANAGED_BOOKING, MISSED_CHECK_IN, 
    HUMYN_SYNC_EMPLOYER_ISSUE, HUMYN_SYNC_EMPLOYEE_ISSUE
  ];

  const NOTIFICATIONS_WITH_EMAIL = [
    EMPLOYEE_DAY_OFF_REQUEST, EMPLOYEE_SHIFT_CANCELLATION, NEW_CLIENT, NEW_BOOKING_BY_CLIENT, CHANGED_BOOKING,
    UNASSIGNED_SHIFT_WARNING, CANCELLED_BOOKING, CARD_EXPIRATION, SHIFT_REMOVAL, DOCUMENT_EXPIRATION,
    EMPLOYEE_SICK_DAY_REQUEST, NEW_REPEATABLE_BOOKING_BY_CLIENT, CHANGED_REPEATABLE_BOOKING, MANAGED_BOOKING
  ];

  const STAFF_NOTIFICATIONS = [
    EMPLOYEE_REGISTRATION, EMPLOYEE_DAY_OFF_REQUEST, EMPLOYEE_SHIFT_CANCELLATION, INVOICE_BY_SELF_EMPLOYED,
    EMPLOYEE_GROUP_REGISTRATION, EMPLOYEE_SICK_DAY_REQUEST, LATE_CLOCK_IN, MISSED_CLOCK_IN,
    EARLY_CLOCK_OUT, LATE_CLOCK_OUT, MISSED_CLOCK_OUT, MANUAL_CLOCK_IN, MANUAL_CLOCK_OUT, MISSED_CHECK_IN
  ];
  const CLIENTS_NOTIFICATIONS = [
    NEW_CLIENT, NEW_BOOKING_BY_CLIENT, CHANGED_BOOKING, CANCELLED_BOOKING,
    SHIFT_REMOVAL, NEW_REPEATABLE_BOOKING_BY_CLIENT, CHANGED_REPEATABLE_BOOKING
  ];
  const SYSTEM_NOTIFICATIONS = [
    UNASSIGNED_SHIFT_WARNING, NEW_BOOKING_COMMENT, NEW_SHIFT_COMMENT, NEW_BOOKING_BY_PROVIDER, UNINVOICED_PERIOD,
    LICENSE_EXPIRATION, CARD_EXPIRATION, DOCUMENT_EXPIRATION, MANAGED_BOOKING, HUMYN_SYNC_EMPLOYER_ISSUE, HUMYN_SYNC_EMPLOYEE_ISSUE
  ];

  const SHIFTS_NOTIFICATIONS = [
    EMPLOYEE_SHIFT_CANCELLATION, UNASSIGNED_SHIFT_WARNING, NEW_SHIFT_COMMENT, SHIFT_REMOVAL,
    LATE_CLOCK_IN, MISSED_CLOCK_IN, EARLY_CLOCK_OUT, LATE_CLOCK_OUT, MISSED_CLOCK_OUT,
    MANUAL_CLOCK_IN, MANUAL_CLOCK_OUT, MISSED_CHECK_IN
  ];
  const BOOKINGS_NOTIFICATIONS = [
    NEW_BOOKING_BY_CLIENT, CHANGED_BOOKING, NEW_BOOKING_COMMENT, CANCELLED_BOOKING, MANAGED_BOOKING,
    NEW_BOOKING_BY_PROVIDER, NEW_REPEATABLE_BOOKING_BY_CLIENT, CHANGED_REPEATABLE_BOOKING
  ];
  const REQUESTS_NOTIFICATIONS = [
    EMPLOYEE_REGISTRATION, EMPLOYEE_DAY_OFF_REQUEST, EMPLOYEE_SHIFT_CANCELLATION, NEW_CLIENT,
    EMPLOYEE_GROUP_REGISTRATION, EMPLOYEE_SICK_DAY_REQUEST
  ];
  const INVOICE_NOTIFICATIONS = [INVOICE_BY_SELF_EMPLOYED, UNINVOICED_PERIOD];
  const TRAINING_AND_LICENSES_NOTIFICATIONS = [LICENSE_EXPIRATION, CARD_EXPIRATION, DOCUMENT_EXPIRATION];
  const CLOCK_IN_OUT_NOTIFICATIONS = [
    LATE_CLOCK_IN, MISSED_CLOCK_IN, EARLY_CLOCK_OUT, LATE_CLOCK_OUT,
    MISSED_CLOCK_OUT, MANUAL_CLOCK_IN, MANUAL_CLOCK_OUT, MISSED_CHECK_IN
  ];
  const INTEGRATION_NOTIFICATIONS = [
    HUMYN_SYNC_EMPLOYER_ISSUE, HUMYN_SYNC_EMPLOYEE_ISSUE
  ];

  const SHIFTS_NOTIFICATION_SETTINGS = [UNASSIGNED_SHIFT_WARNING, NEW_SHIFT_COMMENT, SHIFT_REMOVAL];
  const REQUESTS_NOTIFICATION_SETTINGS = [
    EMPLOYEE_DAY_OFF_REQUEST, EMPLOYEE_SHIFT_CANCELLATION, EMPLOYEE_GROUP_REGISTRATION, EMPLOYEE_SICK_DAY_REQUEST
  ];

  const NOTIFICATION_NAMES = {
    [EMPLOYEE_REGISTRATION]: 'New employee registration request',
    [EMPLOYEE_DAY_OFF_REQUEST]: 'Employee request for day off',
    [EMPLOYEE_SHIFT_CANCELLATION]: 'Employee cancels shift',
    [NEW_CLIENT]: 'New client registration request',
    [NEW_BOOKING_BY_CLIENT]: 'New booking by client',
    [CHANGED_BOOKING]: 'Client changes booking',
    [UNASSIGNED_SHIFT_WARNING]: 'Shift unassign warning',
    [NEW_BOOKING_COMMENT]: 'New booking comment',
    [NEW_SHIFT_COMMENT]: 'New shift comment',
    [CANCELLED_BOOKING]: 'Client cancels booking',
    [NEW_BOOKING_BY_PROVIDER]: 'New booking by provider',
    [INVOICE_BY_SELF_EMPLOYED]: 'Invoice by self employed',
    [UNINVOICED_PERIOD]: 'Uninvoiced period',
    [LICENSE_EXPIRATION]: 'Trainings & licenses expiration',
    [CARD_EXPIRATION]: 'Card expiration',
    [SHIFT_REMOVAL]: 'Shift removal',
    [DOCUMENT_EXPIRATION]: 'Document expiration',
    [EMPLOYEE_GROUP_REGISTRATION]: 'Employee bulk registration',
    [EMPLOYEE_SICK_DAY_REQUEST]: 'Employee request for sick day',
    [NEW_REPEATABLE_BOOKING_BY_CLIENT]: 'New repeatable booking by client',
    [CHANGED_REPEATABLE_BOOKING]: 'Client changes repeatable booking',
    [LATE_CLOCK_IN]: 'Late clock in',
    [MISSED_CLOCK_IN]: 'Missed clock in',
    [EARLY_CLOCK_OUT]: 'Early clock out',
    [LATE_CLOCK_OUT]: 'Late clock out',
    [MISSED_CLOCK_OUT]: 'Missed clock out',
    [MANUAL_CLOCK_IN]: 'Manual clock in',
    [MANUAL_CLOCK_OUT]: 'Manual clock out',
    [MANAGED_BOOKING]: 'Booking was updated/removed',
    [MISSED_CHECK_IN]: 'Missed check in',
    [HUMYN_SYNC_EMPLOYER_ISSUE]: 'Humyn Employer Issues', 
    [HUMYN_SYNC_EMPLOYEE_ISSUE]: 'Humyn Employee Issues'
  };

  return {
    getEmployeeRegistration() { return EMPLOYEE_REGISTRATION; },
    getEmployeeDayOffRequest() { return EMPLOYEE_DAY_OFF_REQUEST; },
    getEmployeeShiftCancellation() { return EMPLOYEE_SHIFT_CANCELLATION; },
    getNewClient() { return NEW_CLIENT; },
    getNewBookingByClient() { return NEW_BOOKING_BY_CLIENT; },
    getChangedBooking() { return CHANGED_BOOKING; },
    getUnassignedShiftWarning() { return UNASSIGNED_SHIFT_WARNING; },
    getNewBookingComment() { return NEW_BOOKING_COMMENT; },
    getNewShiftComment() { return NEW_SHIFT_COMMENT; },
    getCancelledBooking() { return CANCELLED_BOOKING; },
    getNewBookingByProvider() { return NEW_BOOKING_BY_PROVIDER; },
    getInvoiceBySelfEmployed() { return INVOICE_BY_SELF_EMPLOYED; },
    getUninvoicedPeriod() { return UNINVOICED_PERIOD; },
    getLicenseExpiration() { return LICENSE_EXPIRATION; },
    getCardExpiration() { return CARD_EXPIRATION; },
    getShiftRemoval() { return SHIFT_REMOVAL; },
    getDocumentExpiration() { return DOCUMENT_EXPIRATION },
    getEmployeeGroupRegistration() { return EMPLOYEE_GROUP_REGISTRATION },
    getEmployeeSickDayRequest() { return EMPLOYEE_SICK_DAY_REQUEST },
    getNewRepeatableBookingByClient() { return NEW_REPEATABLE_BOOKING_BY_CLIENT; },
    getChangedRepeatableBooking() { return CHANGED_REPEATABLE_BOOKING; },
    getLateClockIn() { return LATE_CLOCK_IN; },
    getMissedClockIn() { return MISSED_CLOCK_IN; },
    getEarlyClockOut() { return EARLY_CLOCK_OUT; },
    getLateClockOut() { return LATE_CLOCK_OUT; },
    getMissedClockOut() { return MISSED_CLOCK_OUT; },
    getManualClockIn() { return MANUAL_CLOCK_IN; },
    getManualClockOut() { return MANUAL_CLOCK_OUT; },
    getManagedBooking() { return MANAGED_BOOKING; },
    getMissedCheckIn() { return MISSED_CHECK_IN; },
    getHumynEmployerIssues() { return HUMYN_SYNC_EMPLOYER_ISSUE; },
    getHumynEmployeeIssues() { return HUMYN_SYNC_EMPLOYEE_ISSUE; },

    getStaffNotifications() { return STAFF_NOTIFICATIONS; },
    getClientsNotifications() { return CLIENTS_NOTIFICATIONS; },
    getSystemNotifications() { return SYSTEM_NOTIFICATIONS; },

    getShiftsNotifications() { return SHIFTS_NOTIFICATIONS; },
    getBookingsNotifications() { return BOOKINGS_NOTIFICATIONS; },
    getRequestsNotifications() { return REQUESTS_NOTIFICATIONS; },
    getInvoiceNotifications() { return INVOICE_NOTIFICATIONS; },
    getTrainingAndLicensesNotifications() { return TRAINING_AND_LICENSES_NOTIFICATIONS; },
    getClockInOutNotifications() { return CLOCK_IN_OUT_NOTIFICATIONS; },
    getIntegrationNotifications() { return INTEGRATION_NOTIFICATIONS; },

    getShiftsNotificationSettings() { return SHIFTS_NOTIFICATION_SETTINGS; },
    getRequestsNotificationSettings() { return REQUESTS_NOTIFICATION_SETTINGS; },

    getAllStatuses() { return ALL_STATUSES; },

    getNotificationName(notificationType) {
      return NOTIFICATION_NAMES[notificationType];
    },

    getNotificationEntityTypeName(notificationType) {
      if (STAFF_NOTIFICATIONS.includes(notificationType)) {
        return 'Staff';
      } else if (CLIENTS_NOTIFICATIONS.includes(notificationType)) {
        return 'Client';
      } else if (SYSTEM_NOTIFICATIONS.includes(notificationType)) {
        return 'System';
      }
    },

    hasEmailNotification(notificationType) {
      return NOTIFICATIONS_WITH_EMAIL.includes(notificationType);
    }

  };
};

angular.module('data.security-manager').service('securityManagerNotificationType', securityManagerNotificationType);
