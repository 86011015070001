/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  'ExtendedFilterService', 'employeeType', 'employeeStatus'
];

const ListFilterService = (
  ExtendedFilterService, employeeType, employeeStatus
) => {

  return class ExtendedListFilterService extends ExtendedFilterService {

    constructor(filterData) {
      super(...arguments);
      this.filterData = filterData;
    }

    open() {
      super.open();
      this._selectFirstCategory();
    }

    initDefaultFilter() {
      this._initCategories(this.filterData);
      this._updateQueryParams();
      this._updateTopPanel();
      this.applyFilter();
    }

    applyFilter() {
      this.applyFilterCb({ queryParams: this.queryParams });
    }

    clearAllSelections() {
      super.clearAllSelections();
      this._selectFirstCategory();
    }

    selectAllOptions(category) {
      category.selectAll();
      this._updateTopPanel();
      this._updateQueryParams();
      if (this.autoApply) { this.applyFilter(); }
    }

    deselectAllOptions(category) {
      category.deselectAll();
      this._updateTopPanel();
      this._updateQueryParams();
      if (this.autoApply) { this.applyFilter(); }
    }

    _categories(filterData) {
      return [{
        label: 'Client',
        params: 'client_ids',
        top: true,
        type: 'radio',
        options: this._clientOptions(filterData),
        children: [{
          label: 'Service',
          params: 'service_ids',
          type: 'radio',
          tooltip: 'Select Client first',
          options: this._serviceOptions(filterData),
          children: [{
            label: 'Resource',
            params: 'resource_ids',
            type: 'checkbox',
            tooltip: 'Select Client and Service first',
            options: this._resourceOptions(filterData),
            children: [{
              label: 'Training',
              params: 'training_ids',
              type: 'checkbox',
              tooltip: 'Select Client, Service<br/> and Resource first',
              options: this._trainingOptions(filterData),
              children: []
            }]
          }]
        }]
      }, {
        label: 'Employment type',
        params: 'employment_types',
        top: true,
        type: 'checkbox',
        options: this._employmentTypeOptions(),
        children: []
      }, {
        label: 'Status',
        params: 'statuses',
        top: true,
        type: 'checkbox',
        options: this._statusesOptions(),
        children: []
      }, {
        label: 'Role',
        params: 'roles',
        top: true,
        type: 'checkbox',
        options: this._rolesOptions(filterData),
        children: []
      }, {
        label: 'Gender',
        params: 'genders',
        top: true,
        type: 'checkbox',
        options: this._genderOptions(),
        children: []
      }];
    }

    _clientData(client) {
      return _.extend({ tag: true }, super._clientData(client));
    }

    _serviceData(service) {
      return _.extend({ tag: true }, super._serviceData(service));
    }

    _employmentTypeOptions() {
      const names = employeeType.getNames();
      const result = [];
      for (let id in names) {
        const name = names[id];
        result.push({ id: +id, label: name, tag: true });
      }
      return result;
    }

    _rolesOptions(filterData) {
      return _.map(filterData.roles, role => {
        return {
          id: role.id,
          label: role.name,
          resourceIds: role.resourceIds,
          tag: true,
        };
      });
    }

    _statusesOptions() {
      const names = employeeStatus.getNames();
      const result = [];
      for (let id in names) {
        const name = names[id];
        result.push({
          id: +id,
          label: name,
          tag: true,
          isSelected: employeeStatus.isActive(+id)
        });
      }
      return result;
    }

    _selectFirstCategory() {
      _.first(this.categories).open();
    }
  }

};

angular.module('shared-components.extended-filters')
  .service('ExtendedListFilterService', dependencies.concat(ListFilterService));
