/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = ['dateService', 'Booking', '$q'];

const PlanningInteractor = (dateService, Booking, $q) => {

  const MOVE_DAYS_LONG = 8;

  return class PlanningInteractor {

    constructor() {
      this.from = dateService.today();
      this.to = dateService.today().add(1, 'month');
      this.dates = [];
      this.dateCallbacks = [];
      this.quitCallbacks = [];
      this.resizeCallbacks = [];
      this.isShiftsLoading = false;
      this.isEmployeesLoading = false;
      this.isQuitting = false;
      this._initDates();
    }

    isLoading() {
      return this.isShiftsLoading || this.isEmployeesLoading || this.isQuitting;
    }

    getDates() {
      return { from: this.from.clone(), to: this.to.clone() };
    }

    updateDates(from, to) {
      this.from = from.clone();
      this.to = to.clone();
      this._initDates();
      return this._notifyDateChanges();
    }

    setHourWidth(hourWidth) {
      this.hourWidth = hourWidth;
      return Array.from(this.resizeCallbacks).map((cb) => cb());
    }

    moveRangeLeftShort() {
      return this._moveRange(-1);
    }

    moveRangeLeftLong() {
      return this._moveRange(-MOVE_DAYS_LONG);
    }

    moveRangeRightShort() {
      return this._moveRange(1);
    }

    moveRangeRightLong() {
      return this._moveRange(MOVE_DAYS_LONG);
    }

    subscribeToDateChanges(callback) {
      return this.dateCallbacks.push(callback);
    }

    beforeQuit(callback) {
      return this.beforeQuitCb = callback;
    }

    subscribeToQuit(callback) {
      return this.quitCallbacks.push(callback);
    }

    subscribeToResize(callback) {
      return this.resizeCallbacks.push(callback);
    }

    // returns promise
    quitPlanningMode() {
      this.isQuitting = true;
      if (this.beforeQuitCb) {
        this.beforeQuitCb();
      }
      return _.reduce(this.quitCallbacks, (promise, cb) => {
        return promise.then(cb);
      }, $q.when()).then(() => Booking.unlockSeries());
    }

    _moveRange(daysCount) {
      const newFrom = this.from.clone().add(daysCount, 'day');
      const newTo = this.to.clone().add(daysCount, 'day');
      return this.updateDates(newFrom, newTo);
    }

    _initDates() {
      const dates = [];
      let date = this.from.clone();
      const to = this.to.clone();
      while (true) {
        dates.push(date);
        date = date.clone();
        date.add(1, 'days');
        if (to.diff(date) < 0) {
          break;
        }
      }
      return this.dates = dates;
    }

    _notifyDateChanges() {
      return Array.from(this.dateCallbacks).map((cb) => {
        return cb({ from: this.from, to: this.to });
      });
    }
  };

};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .factory('PlanningInteractor', dependencies.concat(PlanningInteractor));
