const dependencies = ['newConfirmService'];

const NewPopupConfirmController = function(confirmService) {

  const vm = this;

  vm.confirmService = confirmService;

  vm.onButtonClick = function (button) {
    if (button && button.onClickCb) {
      button.onClickCb();
    }
    vm.confirmService.closePopup();
  };

  vm.closePopup = function() {
    const cancelButton = vm.confirmService.getButtons()[0];
    if (cancelButton && cancelButton.onClickCb) {
      cancelButton.onClickCb();
    }
    vm.confirmService.closePopup();
  };

  return vm;

};

angular.module('shared-components')
  .controller('NewPopupConfirmController', dependencies.concat(NewPopupConfirmController));
