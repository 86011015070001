/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.employee').factory('EmployeeWorkHistory', ['AppModel', function(AppModel) {

  class EmployeeWorkHistory extends AppModel {
    static initClass() {
      this.configure({ url: '/employees/{{employeeId}}/work_histories/{{id}}', name: 'work_history' });
    }

    static getGapInfo(employeeId) {
      return this.$get(this.resourceUrl({ employeeId }, 'gap_info'));
    }
    
  }

  EmployeeWorkHistory.initClass();
  
  return EmployeeWorkHistory;

}]);