/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.user').factory('UserCode', ['AppModel', function(AppModel) {

  class UserCode extends AppModel {
    static initClass() {
      this.configure({
        url : '/users/{{userId}}/codes/{{code}}', name : 'user_code'});
    }

    static confirmEmail(userId, code) {
      return this.$post(new UserCode({ userId, code }).$url('confirm_email'));
    }
  }

  UserCode.initClass();
  return UserCode;
}
]);
