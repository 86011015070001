/*
 * @author Oleksandr Papka <papkaos>
 */
// import './styles.css';

const gccsManagerAccessLevelDescription = {
  controller: 'ManagerAccessLevelDescriptionController',
  template: require('./template.pug'),
  bindings: {
    securityManager: '='
  }
};

angular.module('shared-components').component('gccsManagerAccessLevelDescription', gccsManagerAccessLevelDescription);
