/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'systemPackagePopupService'
];

const PopupSystemPackageSuccessMessageController = function(
  $scope, systemPackagePopupService
) {

  const vm = this;

  const initPopupContent = function() {
    vm.popupTitle = systemPackagePopupService.getSuccessPopupTitle();
    vm.popupMsg = systemPackagePopupService.getSuccessPopupMsg();
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      initPopupContent();
    }
  });

  return vm;

};

angular.module('popup.system-package')
  .controller('PopupSystemPackageSuccessMessageController', dependencies.concat(PopupSystemPackageSuccessMessageController));