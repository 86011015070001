/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const employeeHolidaysLimitType = function() {

  const UNLIMITED = 1;
  const LIMITED = 2;

  const names = {};
  names[UNLIMITED] = 'Unlimited';
  names[LIMITED] = 'Limited';

  return {
    getNames() {
      return names;
    },

    getUnlimited() {
      return UNLIMITED;
    },

    getLimited() {
      return LIMITED;
    }
  };
};

angular.module('public.security-manager.settings').service('employeeHolidaysLimitType', employeeHolidaysLimitType);
