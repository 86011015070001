/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const Note = AppModel => {

  class Note extends AppModel {

    static initClass() {
      this.configure({ url: '/notes', name: 'note' });
    }

  }

  Note.initClass();

  return Note;

};

angular.module('data.note').factory('Note', dependencies.concat(Note));
