const dependencies = [
  '$scope', 'dateService', 'confirmService', 'employeeInvoiceElementFactory',
  'notificationService', 'employeeInvoiceStatus', 'currentUserService', 'bookingShiftClockInErrorsService'
];

const InvoiceEmployeeFormInvoiceVersionController = function(
  $scope, dateService, confirmService, employeeInvoiceElementFactory,
  notificationService, employeeInvoiceStatus, currentUserService, bookingShiftClockInErrorsService
) {

  const vm = this;

  const ISO_FULL_DATE_FORMAT = dateService.getIsoFullDateFormat();

  let lastOpenedInvoiceElement = null;
  let originalStatus;

  vm.dateFormat = dateService.getDateFormat();
  vm.employeeInvoiceStatus = employeeInvoiceStatus;

  vm.$onInit = function() {
    vm.minDate = moment(vm.invoice.startDate, ISO_FULL_DATE_FORMAT).startOf('day');
    vm.maxDate = moment(vm.invoice.endDate, ISO_FULL_DATE_FORMAT).endOf('day');
    originalStatus = vm.invoiceVersion.status;
  };

  vm.$onChanges = function() {
    if (vm.makeCopy) {
      vm.invoice.originalVersion = _.cloneDeep(vm.invoiceVersion);
      initInvoiceElements(vm.invoice.originalVersion);
    }
    initInvoiceElements(vm.invoiceVersion);
    if (vm.statusEditable && (vm.preselectedStatus !== undefined)) {
      vm.changeStatus(vm.preselectedStatus);
    }
    initPaidDate();
    if (vm.compareWith) { initCompareElements(); }
  };

  vm.getInvoicePeriod = () => {
    return `${dateService.formatDate(vm.invoice.startDate)} - ${dateService.formatDate(vm.invoice.endDate)}`;
  };

  vm.getInvoiceElements = () => {
    return _.orderBy(vm.invoiceVersion.invoiceElements, invoiceElement => {
      return invoiceElement.element.startTimeMoment;
    }, vm.startDateOrderDesc ? 'desc' : 'asc');
  };

  vm.removeElement = function(element) {
    if (vm.invoiceVersion.invoiceElements.length > 1) {
      return confirmService.confirmRemove('this', function() {
        _.remove(vm.invoiceVersion.shiftEmployees, element.element);
        _.remove(vm.invoiceVersion.invoiceElements, element);
        updateTotalAmount(vm.invoiceVersion);
      });
    } else {
      notificationService.notifyInfo("Invoice can't be empty");
    }
  };

  vm.addBonus = function() {
    const newAdditionalElement = employeeInvoiceElementFactory.createEmptyBonus(vm.minDate, vm.editable, () => {
      updateTotalAmount(vm.invoiceVersion);
    });
    addNewElement(newAdditionalElement);
  };

  vm.addDeduction = function() {
    const newAdditionalElement = employeeInvoiceElementFactory.createEmptyDeduction(vm.minDate, vm.editable, () => {
      updateTotalAmount(vm.invoiceVersion);
    });
    addNewElement(newAdditionalElement);
  };

  vm.getHighlightClass = (invoiceElement, options) => ({ 'is-highlighted' : isChanged(invoiceElement, options) });

  vm.getHighlightClassForHolder = function(invoiceElement) {
    if (!vm.compareWith) { return; }
    const previousElement = findElementIn(vm.compareWith, invoiceElement);
    if (previousElement === undefined) {
      return 'is-highlighted-green';
    } else if (previousElement.isRemoved) {
      return 'is-highlighted-red';
    }
  };

  vm.changeStatus = function(status) {
    status = parseInt(status);
    if (vm.invoiceVersion.status === status) { return; }
    vm.invoiceVersion.status = status;
    if (vm.withPaidDate()) {
      initPaidDate();
    } else {
      vm.paidDate = undefined;
    }
  };

  vm.withPaidDate = status => employeeInvoiceStatus.withPaidDate(status || vm.invoiceVersion.status);

  vm.onCommentsCountChange = (invoiceElement, count) => invoiceElement.element.shiftCommentsCount = count;

  vm.isShownComments = invoiceElement => {
    return currentUserService.isSecurityManagerLogged() && invoiceElement.isShownComments();
  };

  vm.openClockPopup = function(invoiceElement) {
    if (invoiceElement.isShownClockPopup) {
      vm.closeEditPopup(invoiceElement);
    } else {
      if (lastOpenedInvoiceElement) {
        vm.closeEditPopup(lastOpenedInvoiceElement);
      }
      if (vm.isClockEditEditable()) {
        vm.compareForClock = null;
        vm.clockEditButton = 'Save';
        vm.clockEditTitle = 'Edit clock in/out time';
      } else {
        vm.compareForClock = vm.getCompareForClock(invoiceElement);
        vm.clockEditButton = null;
        vm.clockEditTitle = null;
      }
      invoiceElement.isShownClockPopup = true;
      lastOpenedInvoiceElement = invoiceElement;
    }
  };

  vm.closeEditPopup = function(invoiceElement) {
    invoiceElement.isShownClockPopup = false;
    lastOpenedInvoiceElement = null;
  };

  vm.hasClockChanges = function(invoiceElement) {
    return isChanged(invoiceElement, { field: 'shiftEmployee.clockInTime' }) ||
      isChanged(invoiceElement, { field: 'shiftEmployee.clockOutTime' });
  };

  vm.getCompareForClock = function(invoiceElement) {
    if (!vm.compareWith) { return null; }
    const previousElement = findElementIn(vm.compareWith, invoiceElement);
    if (!previousElement) { return null; }
    return previousElement.element.shiftEmployee;
  };

  vm.isClockInDisplayed = function(invoiceElement) {
    return invoiceElement.element.enableClockIn;
  };

  vm.hasClockInOutErrors = function(invoiceElement) {
    return bookingShiftClockInErrorsService.hasClockInOutErrors(invoiceElement.element.shiftEmployee);
  };

  vm.isClockEditEditable = function() {
    return vm.editable && currentUserService.isSecurityManagerLogged();
  };

  vm.getStatusNames = function() {
    if (employeeInvoiceStatus.isOpened(originalStatus)) {
      return employeeInvoiceStatus.getSecurityManagerNamesFromOpened();
    } else {
      return employeeInvoiceStatus.getSecurityManagerNames();
    }
  };

  const isChanged = function(invoiceElement, { field, func }) {
    if (!vm.compareWith || !!invoiceElement.isRemoved) { return false; }
    const previousElement = findElementIn(vm.compareWith, invoiceElement);
    if (!previousElement) { return false; }
    return compare(previousElement, invoiceElement, field, func);
  };

  const compare = function(previousElement, invoiceElement, field, func) {
    if (func) {
      return previousElement[func]() !== invoiceElement[func]();
    } else if (field) {
      const path = field.split('.');
      return !_.isEqual(_.get(previousElement.element, path), _.get(invoiceElement.element, path));
    }
  };

  const initInvoiceElements = function(invoiceVersion) {
    invoiceVersion.invoiceElements = [];
    _.each(invoiceVersion.shiftEmployees, shiftEmployee => {
      invoiceVersion.invoiceElements.push(
        employeeInvoiceElementFactory.create(shiftEmployee, vm.editable, () => updateTotalAmount(invoiceVersion))
      );
    });
    updateTotalAmount(invoiceVersion);
  };

  const initCompareElements = function() {
    initInvoiceElements(vm.compareWith);
    _.each(vm.compareWith.invoiceElements, function(compareElement) {
      if (!findElementIn(vm.invoiceVersion, compareElement)) {
        compareElement.setAsRemoved();
        vm.invoiceVersion.invoiceElements.push(compareElement);
      }
    });
  };

  const findElementIn = (invoiceVersion, element) => {
    return _.find(invoiceVersion.invoiceElements, collectionElement => collectionElement.isSame(element));
  };

  const updateTotalAmount = invoiceVersion => {
    invoiceVersion.totalAmount = _.sumBy(invoiceVersion.invoiceElements, element => element.element.totalPay || 0);
  };

  const initPaidDate = function() {
    if (!vm.statusEditable || !vm.withPaidDate()) { return; }
    if (vm.invoiceVersion.paidDate) {
      vm.paidDate = moment(vm.invoiceVersion.paidDate, dateService.getIsoDateFormat());
    } else {
      vm.paidDate = moment();
    }
    initCommentFormForDatePicker();
  };

  const initCommentFormForDatePicker = function() { // todo redo!! to smth like transclude
    if (employeeInvoiceStatus.withPaidDate(vm.invoiceVersion.status)) {
      $scope.customData = {
        comment: {
          isForm: true,
          commentModel: vm.invoiceVersion.payNote,
          onSubmit() {
            vm.invoiceVersion.payNote = $scope.customData.comment.commentModel;
            $scope.$broadcast('hidePicker');
          }
        }
      };
    } else {
      $scope.customData = undefined;
    }
  };

  const addNewElement = function(newAdditionalElement) {
    vm.invoiceVersion.invoiceElements.push(newAdditionalElement);
    vm.invoiceVersion.shiftEmployees.push(newAdditionalElement.element);
  };

  const updatePaidDate = function() {
    const paidDate = vm.paidDate ? vm.paidDate.format(dateService.getIsoDateFormat()) : undefined;
    vm.paidDateChangeCb({ paidDate });
  };

  $scope.$watch(() => vm.paidDate, updatePaidDate);

  return vm;

};

angular.module('public.invoice.employee')
  .controller('InvoiceEmployeeFormInvoiceVersionController', dependencies.concat(InvoiceEmployeeFormInvoiceVersionController));
