/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['ShiftDecorator'];

var SeriesDecorator = function(ShiftDecorator) {

  return (SeriesDecorator = class SeriesDecorator {

    constructor(dBooking, series, updateBookingStatisticsCb) {
      if (!series) { return; }
      this.series = series;
      this.updateBookingStatisticsCb = updateBookingStatisticsCb;
      this.id = series.id;
      this.clientId = dBooking.clientId;
      this.clientLogo = dBooking.clientLogo;
      this.bookingId = dBooking.id;
      this.bookingName = dBooking.name;
      this.icon = this.series.resource.icon;
      this._initDShifts();
    }

    closeShiftTimelinePopups(activeDShift) {
      return (() => {
        const result = [];
        for (let dShift of Array.from(this.dShifts)) {
          if (!dShift.isSameDShift(activeDShift)) {
            result.push(dShift.closeShiftTimelinePopup(activeDShift));
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    }

    updateShift(shift) {
      const newDShift = new ShiftDecorator(shift, this.updateBookingStatisticsCb, this._getShiftParams());
      return this._updateShifts([newDShift]);
    }

    updateShiftsForSeries(dSeries, shifts) {
      if (this.id !== dSeries.id) { return; }
      const params = this._getShiftParams();
      const newDShifts = _.map(shifts, shift => {
        return new ShiftDecorator(shift, this.updateBookingStatisticsCb, params);
      });
      const updatedShiftIds = this._updateShifts(newDShifts);
      const dShiftsToRemove = [];
      for (let dShift of Array.from(this.dShifts)) {
        if (updatedShiftIds.indexOf(dShift.id) === -1) { dShiftsToRemove.push(dShift); }
      }
      return this.dShifts = _.without(this.dShifts, ...Array.from(dShiftsToRemove));
    }

    setVisibilityFromStats(currentStatFilter) {
      let visible = false;
      for (let shift of Array.from(this.dShifts)) {
        if (shift.setVisibilityFromStats(currentStatFilter) && !visible) {
          visible = true;
        }
      }
      return this.visible = visible;
    }

    hasEmployeeShift(employeeShift) {
      for (let dShift of Array.from(this.dShifts)) {
        if (dShift.hasEmployeeShift(employeeShift)) { return true; }
      }
      return false;
    }

    updateEmployeeShift(employeeShift) {
      return Array.from(this.dShifts).map((dShift) => {
        return dShift.updateEmployeeShift(employeeShift);
      });
    }

    removeEmployeeShift(employeeShift) {
      return Array.from(this.dShifts).map((dShift) => {
        return dShift.removeEmployeeShift(employeeShift);
      });
    }

    invalidateCache() {
      return Array.from(this.dShifts).map((dShift) => {
        return dShift.clearCachedValues();
      });
    }

    _initDShifts() {
      this.dShifts = [];
      const params = this._getShiftParams();
      return Array.from(this.series.shifts).map((shift) => {
        return this.dShifts.push(new ShiftDecorator(shift, this.updateBookingStatisticsCb, params));
      });
    }

    _updateShifts(newDShifts) {
      const updatedShiftIds = [];
      for (let newDShift of Array.from(newDShifts)) {
        let isNewShift = true;
        for (let dShift of Array.from(this.dShifts)) {
          if (dShift.isSameDShift(newDShift)) {
            if (dShift.isShownShiftTimelinePopup) { newDShift.isShownShiftTimelinePopup = true; }
            if (dShift.isMultipleSelected) { newDShift.isMultipleSelected = true; }
            _.merge(dShift, newDShift);
            dShift.setResourcesArray();
            dShift.clearCachedValues();
            isNewShift = false;
            break;
          }
        }
        if (isNewShift) { this.dShifts.push(newDShift); }
        newDShift.updateStatistics();
        updatedShiftIds.push(newDShift.id);
      }
      return updatedShiftIds;
    }

    _getShiftParams() {
      return {
        bookingName: this.bookingName,
        icon: this.icon,
        clientId: this.clientId,
        clientLogo: this.clientLogo
      };
    }

  });
};

angular.module('public.timeline').factory('SeriesDecorator', dependencies.concat(SeriesDecorator));
