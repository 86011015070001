/*
 * @author Igor Serpak <robobee>
 */
const employeeDeviceType = function() {

  const DESKTOP = 0;
  const MOBILE = 1;

  return {
    getDesktop() {
      return DESKTOP;
    },

    getMobile() {
      return MOBILE;
    }
  };
};

angular.module('public.employee').service('employeeDeviceType', employeeDeviceType);
