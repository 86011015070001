/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.employee.profile.invoice', ['public.invoice.employee']).config([
  '$stateProvider',
  $stateProvider => {

    return $stateProvider

      .state('public.employee.profile.invoice', {
        url: '/my-invoices',
        abstract: true,
        template: require('./template.pug')
      })
      .state('public.employee.profile.invoice.list', {
        url: '/list?{ numberFilter, from, to }',
        template: require('../../../invoice/employee/list/template.pug'),
        controller: 'InvoiceEmployeeInvoicesController',
        controllerAs: 'invoiceEmployeeInvoicesCtrl'
      });

}]);
