/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').directive('form', ['elementService', elementService => {
  return {
    link($scope, $el) {
      return $el[0].onsubmit = () => elementService.scrollInto(document.querySelector('.is-error'));
    }
  }
}]);
