const dependencies = ['$timeout', 'notificationService'];

const PlanningHeaderDropdownController = function($timeout, notificationService) {

  const vm = this;

  vm.isShown = false;

  vm.toggle = function() {
    if (vm.isDisabled) { return; }
    if (!vm.isShown && ((vm.items === undefined) || (vm.items.length === 0))) { return; } // do not show if no items
    vm.isShown = !vm.isShown;
  };

  vm.hide = () => vm.isShown = false;

  vm.onItemChange = function(item, $event) {
    if ($event) { $event.preventDefault(); }
    if (vm.chosenItem !== item) {
      if (vm.isLockedPromise && item) {
        vm.isLockedPromise(item).then(function(isLocked) {
          if (isLocked) {
            notificationService.notifyError(vm.lockMessage);
          } else {
            vm.onItemChooseCb({ item });
          }
        });
      } else {
        $timeout(function() {
          vm.onItemChooseCb({ item });
        });
      }
    }
  };

  vm.getLabel = function() {
    if (vm.chosenItem) {
      return vm.chosenItem.name;
    } else {
      return vm.defaultLabel;
    }
  };

  return vm;

};

angular
  .module('public.security-manager.schedule-manager.timeline.planning')
  .controller('PlanningHeaderDropdownController', dependencies.concat(PlanningHeaderDropdownController));
