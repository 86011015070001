/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.client.show.invoice', ['public.invoice', 'popup.email', 'data.email']).config([
  '$stateProvider',
  $stateProvider =>

    $stateProvider

      .state('public.security-manager.client.invoice', {
        url: '/invoice',
        abstract: true,
        template: require('./template.pug')
      })
      .state('public.security-manager.client.invoice.list', {
        url: '/list',
        template: require('./list/template.pug'),
        controller: 'ClientShowInvoicesController',
        controllerAs: 'clientShowInvoicesCtrl'
      })
      .state('public.security-manager-invoice-form', {
        url: '/invoice-form?{ invoiceId, bookingIds, type, clientId }',
        template: require('./form/template.pug'),
        controller: 'ClientShowInvoiceFormController',
        controllerAs: 'clientShowInvoiceFormCtrl'
      })
      .state('public.security-manager-invoice-history', {
        url: '/invoice-history/:invoiceId/:invoiceVersionId',
        template: require('./history/template.pug'),
        controller: 'ClientShowInvoiceHistoryController',
        controllerAs: 'clientShowInvoiceHistoryCtrl'
      })

]);
