const dependencies = ['AppModel'];

const SecurityManagerRestrictedEntity = AppModel => {

  class SecurityManagerRestrictedEntity extends AppModel {
    static initClass() {
      this.configure({ url: '/security_managers/restricted_entities', name: 'restricted_entity' });
    }

    static getFilterCategories(params = {}) {
      return this.$get(this.resourceUrl('filter_categories'), params);
    }
  }

  SecurityManagerRestrictedEntity.initClass();

  return SecurityManagerRestrictedEntity;

};

angular.module('data.security-manager')
  .factory('SecurityManagerRestrictedEntity', dependencies.concat(SecurityManagerRestrictedEntity));
