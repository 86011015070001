/*
 * @author Igor Serpak <robobee>
 */
const bookingShiftTitleEditMode = function() {

  const EDIT_MODE_ONLY_CURRENT = 1;
  const EDIT_MODE_ALL_EDITED = 2;

  return {
    getOnlyCurrentMode() {
      return EDIT_MODE_ONLY_CURRENT;
    },

    getAllEditedMode() {
      return EDIT_MODE_ALL_EDITED;
    }
  };
};

angular.module('data.booking').service('bookingShiftTitleEditMode', bookingShiftTitleEditMode);
