/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.client.list', []).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.clients', {
      url: '/clients?{ status }',
      template: require('./template.pug'),
      controller: 'ClientListController',
      controllerAs: 'clientListCtrl',
      reloadOnSearch: false
    })

]);
