const dependencies = [
  '$scope', '$element', '$timeout', '$state', 'FlexibleTimeLineBookingInteractor', 'TimeLineBookingLoader',
  'BookingTimelineShiftSelector', 'ShiftStatistics', 'ClientService',
  'cableService', 'currentUserService', 'localStorageService', 'clientStatus'
];

const ManagerTimelineController = function(
  $scope, $element, $timeout, $state, FlexibleTimeLineBookingInteractor, TimeLineBookingLoader,
  BookingTimelineShiftSelector, ShiftStatistics, ClientService,
  cableService, currentUserService, localStorageService, clientStatus
) {

  const vm = this;

  cableService.createBookingTimelineChannel(currentUserService.getCurrentProfile().securityProvider.id);

  vm.timeLineBookingLoader = new TimeLineBookingLoader({
    clientId: currentUserService.getCurrentProfile().clientId,
    withoutSplit: true
  });
  vm.timeLineInteractor = FlexibleTimeLineBookingInteractor;
  vm.shiftStatistics = new ShiftStatistics();
  vm.bookingTimelineShiftSelector = new BookingTimelineShiftSelector();
  vm.bookingTimelineShiftSelector.setShiftStatisticsService(vm.shiftStatistics);
  vm.isShownServiceWarningPopup = false;
  vm.isOpenedToggleButton = false;

  vm.addNewBooking = () => {
    return ClientService.getCount({
      clientId: currentUserService.getCurrentProfile().clientId
    }, {
      managerId: currentUserService.getCurrentProfile().id
    }).then(function (data) {
      if (data.count > 0) {
        localStorageService.remove('managerBookingId');
        return $state.go('public.client-manager.booking-form.details');
      } else {
        return vm.isShownServiceWarningPopup = true;
      }
    });
  };

  vm.canManageBooking = () => {
    return currentUserService.isClientManagerLogged() &&
      !currentUserService.getCurrentProfile().isViewOnly() &&
      currentUserService.getCurrentProfile().services.length > 0 &&
      clientStatus.isActive(currentUserService.getCurrentProfile().client.status);
  };

  vm.toggleToggleButton = function() {
    vm.isOpenedToggleButton = !vm.isOpenedToggleButton;
  };

  vm.closeToggleButton = function() {
    vm.isOpenedToggleButton = false;
  };

  vm.addNewRepeatableBooking = function() {
    localStorageService.remove('repeatableBookingId');
    $state.go('public.client-manager.repeatable-booking.form');
  };

  vm.onBookingTimelineFilter = function(queryParams) {
    $scope.$broadcast('filterQueryParamsChanged', queryParams);
  };

  $scope.$on('$destroy', cableService.destroyBookingTimelineChannel);

  window.onunload = cableService.destroyBookingTimelineChannel;

  return vm;

};

angular.module('public.client-manager.timeline')
  .controller('ManagerTimelineController', dependencies.concat(ManagerTimelineController));
