const gccsEmployeeTimelineModal = {
  controller: 'EmployeeTimelineModalController',
  template: require('./template.pug'),
  bindings: {
    timelineItem: '<',
    position: '=',
    isShownConfirmPopup: '=',
    closeTimelineItemPopup: '&'
  }
};

angular.module('public.employee.timeline')
  .component('gccsEmployeeTimelineModal', gccsEmployeeTimelineModal);
