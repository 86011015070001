/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsCheckboxSelect = {
  controller: 'CheckboxSelectController',
  controllerAs: 'checkboxSelectCtrl',
  template: require('./template.pug'),
  bindings: {
    selectClassName: '@?',
    selectTitle: '@',
    selectedTitle: '@',
    selectListItems: '<',
    selectedListItems: '<',
    onSelect: '&',
    beforeSelectCb: '&?'
  }
};

angular.module('gccs-ui').component('gccsCheckboxSelect', gccsCheckboxSelect);
