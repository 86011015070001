/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['currentUserService', 'packageTypeService'];

const SettingsLayoutController = function(currentUserService, packageTypeService) {

  const vm = this;

  vm.headlineTitle = currentUserService.getCurrentProfile().isSupervisor() ? 'Company info' : 'Settings';

  vm.$onInit = function() {
    vm.editable = true;
    initTabs();
  };

  const initTabs = function() {
    vm.tabs = [{
      name: 'Company Details',
      sref: 'public.security-manager.settings.company-details'
    }];
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      vm.tabs = vm.tabs.concat([{
        name: 'Employee roles',
        sref: 'public.security-manager.settings.roles'
      }, {
        name: 'Payment to personnel',
        sref: 'public.security-manager.settings.payment'
      }]);
      if (packageTypeService.hasProPackage()) {
        vm.tabs = vm.tabs.concat([{
          name: 'Email settings',
          sref: 'public.security-manager.settings.email'
        }, {
          name: 'Home Zone',
          sref: 'public.security-manager.settings.home-zone'
        }]);
      }
      vm.tabs = vm.tabs.concat([{
        name: 'Document types',
        sref: 'public.security-manager.settings.document-types'
      }]);
    }
    if (currentUserService.getCurrentProfile().isAccountOwner()) {
      vm.tabs = vm.tabs.concat([{
        name: 'Billing',
        sref: 'public.security-manager.settings.billing'
      }]);
    }
    if (currentUserService.getCurrentProfile().isAccountOwner() || currentUserService.getCurrentProfile().isAdmin()) {
      vm.tabs = vm.tabs.concat([{
        name: 'Security settings',
        sref: 'public.security-manager.settings.settings'
      }]);
    }
  };

  return vm;

};

angular.module('public.security-manager.settings')
  .controller('SettingsLayoutController', dependencies.concat(SettingsLayoutController));
