/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  'securityManagerAccessLevel'
];

const securityManagerAccessLevelDescription = function(securityManagerAccessLevel) {

  const accountOwnerDescription = {
    descriptionText: 'has highest access level to the system without any restrictions',
    includedFeatures: [
      'Access to all features and info',
      'Creating all types of manager roles'
    ]
  };

  const mainAdministratorDescription = {
    descriptionText: 'has top-level access to the system with minor restrictions',
    includedFeatures: [
      'Access to all features except Security settings and Billing',
      'Creating Main Administrators, Managers and Supervisors'
    ]
  };

  const managerDescription = {
    descriptionText: 'has customizable top-level access to the system with minor restrictions',
    includedFeatures: [
      'Access to all features except Security settings and Billing',
      'Optional access to Reports and Finances'
    ]
  };

  const supervisorDescription = {
    descriptionText: 'has basic-level access to the system with ability to monitor clients bookings',
    includedFeatures: [
      'Limited access to features',
      'Ability to monitor clients bookings info on timeline'
    ]
  };

  return {
    getDescriptionFor(accessLevel) {
      switch (accessLevel) {
        case securityManagerAccessLevel.getAccountOwnerAccessLevel():
          return accountOwnerDescription;
        case securityManagerAccessLevel.getAdminAccessLevel():
          return mainAdministratorDescription;
        case securityManagerAccessLevel.getManagerAccessLevel():
          return managerDescription;
        case securityManagerAccessLevel.getSupervisorAccessLevel():
          return supervisorDescription;
      }
    }
  };
};

angular.module('data.security-manager').service('securityManagerAccessLevelDescription', dependencies.concat(securityManagerAccessLevelDescription));
