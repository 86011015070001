const bookingShiftEmployeeClockInOutChange = function() {

  const NORMAL_CLOCK_IN = 1;
  const LATE_CLOCK_IN = 2;
  const MISSED_CLOCK_IN = 3;
  const NORMAL_CLOCK_OUT = 4;
  const EARLY_CLOCK_OUT = 5;
  const LATE_CLOCK_OUT = 6;
  const MISSED_CLOCK_OUT = 7;
  const MANUAL_CLOCK_IN = 8;
  const MANUAL_CLOCK_OUT = 9;

  const names = {
    [NORMAL_CLOCK_IN]: 'Clock in',
    [LATE_CLOCK_IN]: 'Late clock in',
    [MISSED_CLOCK_IN]: 'Missed clock in',
    [NORMAL_CLOCK_OUT]: 'Clock out',
    [EARLY_CLOCK_OUT]: 'Early clock out',
    [LATE_CLOCK_OUT]: 'Late clock out',
    [MISSED_CLOCK_OUT]: 'Missed clock out',
    [MANUAL_CLOCK_IN]: 'Manual clock in',
    [MANUAL_CLOCK_OUT]: 'Manual clock out'
  };

  const ERROR_TYPES = [LATE_CLOCK_IN, MISSED_CLOCK_IN, EARLY_CLOCK_OUT, LATE_CLOCK_OUT, MISSED_CLOCK_OUT];
  const TIME_ERROR_TYPES = [LATE_CLOCK_IN, EARLY_CLOCK_OUT, LATE_CLOCK_OUT];

  const MANUAL_TYPES = [MANUAL_CLOCK_IN, MANUAL_CLOCK_OUT];
  const CLOCK_IN_TYPES = [NORMAL_CLOCK_IN, LATE_CLOCK_IN, MISSED_CLOCK_IN];
  const CLOCK_OUT_TYPES = [NORMAL_CLOCK_OUT, EARLY_CLOCK_OUT, LATE_CLOCK_OUT, MISSED_CLOCK_OUT];

  return {
    getNames() {
      return names;
    },

    getName(type) {
      return names[type];
    },

    isErrorType(type) {
      return ERROR_TYPES.includes(type);
    },

    isTimeErrorType(type) {
      return TIME_ERROR_TYPES.includes(type);
    },

    isManualType(type) {
      return MANUAL_TYPES.includes(type);
    },

    isManualClockIn(type) {
      return type === MANUAL_CLOCK_IN;
    },

    isManualClockOut(type) {
      return type === MANUAL_CLOCK_OUT;
    },

    isClockInType(type) {
      return CLOCK_IN_TYPES.includes(type);
    },

    isClockOutType(type) {
      return CLOCK_OUT_TYPES.includes(type);
    }
  };
};

angular.module('data.booking').service('bookingShiftEmployeeClockInOutChange', bookingShiftEmployeeClockInOutChange);
