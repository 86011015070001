const employeeNotificationType = function() {

  const SHIFT_INVITATION = 1;
  const SHIFT_CANCELLATION = 2;
  const SHIFT_CANCELLATION_APPROVAL = 3;
  const SHIFT_CANCELLATION_REJECTION = 4;
  const DAY_OFF_APPROVAL = 5;
  const DAY_OFF_REJECTION = 6;
  const SHIFT_ASSIGN = 7;
  const SHIFT_UNASSIGN = 8;
  const NEW_COMMENT = 9;
  const INVOICE_APPROVAL = 10;
  const INVOICE_CORRECTION = 11;
  const INVOICE_PAYMENT = 12;
  const SHIFT_CHANGE = 13;
  const INVOICE_PAYMENT_DUE = 14;
  const LICENSE_EXPIRATION = 15;
  const SHIFT_REASSIGN = 16;
  const SICK_DAY_APPROVAL = 17;
  const SICK_DAY_REJECTION = 18;
  const CLOCK_IN_UPCOMING_SHIFT = 19;
  const CLOCK_IN_SHIFT_STARTED = 20;
  const MISSED_CLOCK_OUT = 21;
  const MISSED_CHECK_IN = 22;

  const NOTIFICATION_OBJECTS = {};
  NOTIFICATION_OBJECTS[SHIFT_INVITATION] = 'shiftInvitation';
  NOTIFICATION_OBJECTS[SHIFT_CANCELLATION] = 'shiftCancellation';
  NOTIFICATION_OBJECTS[SHIFT_CANCELLATION_APPROVAL] = 'shiftCancellationApproval';
  NOTIFICATION_OBJECTS[SHIFT_CANCELLATION_REJECTION] = 'shiftCancellationRejection';
  NOTIFICATION_OBJECTS[DAY_OFF_APPROVAL] = 'dayOffApproval';
  NOTIFICATION_OBJECTS[DAY_OFF_REJECTION] = 'dayOffRejection';
  NOTIFICATION_OBJECTS[SHIFT_ASSIGN] = 'shiftAssign';
  NOTIFICATION_OBJECTS[SHIFT_UNASSIGN] = 'shiftUnassign';
  NOTIFICATION_OBJECTS[NEW_COMMENT] = 'newComment';
  NOTIFICATION_OBJECTS[INVOICE_APPROVAL] = 'invoiceApproval';
  NOTIFICATION_OBJECTS[INVOICE_CORRECTION] = 'invoiceCorrection';
  NOTIFICATION_OBJECTS[INVOICE_PAYMENT] = 'invoicePayment';
  NOTIFICATION_OBJECTS[SHIFT_CHANGE] = 'shiftChange';
  NOTIFICATION_OBJECTS[INVOICE_PAYMENT_DUE] = 'invoicePaymentDue';
  NOTIFICATION_OBJECTS[LICENSE_EXPIRATION] = 'licenseExpiration';
  NOTIFICATION_OBJECTS[SHIFT_REASSIGN] = 'shiftReassign';
  NOTIFICATION_OBJECTS[SICK_DAY_APPROVAL] = 'sickDayApproval';
  NOTIFICATION_OBJECTS[SICK_DAY_REJECTION] = 'sickDayRejection';

  const INVOICE_RELATED_NOTIFICATIONS = [
    INVOICE_APPROVAL,
    INVOICE_CORRECTION,
    INVOICE_PAYMENT,
    INVOICE_PAYMENT_DUE
  ];

  const APPROVAL_NOTIFICATIONS = [
    DAY_OFF_APPROVAL,
    SHIFT_CANCELLATION_APPROVAL,
    SICK_DAY_APPROVAL
  ];

  const REJECTION_NOTIFICATIONS = [
    DAY_OFF_REJECTION,
    SHIFT_CANCELLATION_REJECTION,
    SHIFT_CANCELLATION,
    SHIFT_UNASSIGN
  ];

  return {
    getShiftInvitation() { return SHIFT_INVITATION; },
    getShiftCancellation() { return SHIFT_CANCELLATION; },
    getShiftCancellationApproval() { return SHIFT_CANCELLATION_APPROVAL; },
    getShiftCancellationRejection() { return SHIFT_CANCELLATION_REJECTION; },
    getDayOffApproval() { return DAY_OFF_APPROVAL; },
    getDayOffRejection() { return DAY_OFF_REJECTION; },
    getShiftAssign() { return SHIFT_ASSIGN; },
    getShiftUnassign() { return SHIFT_UNASSIGN; },
    getNewComment() { return NEW_COMMENT; },
    getInvoiceApproval() { return INVOICE_APPROVAL; },
    getInvoiceCorrection() { return INVOICE_CORRECTION; },
    getInvoicePayment() { return INVOICE_PAYMENT; },
    getShiftChange() { return SHIFT_CHANGE; },
    getInvoicePaymentDue() { return INVOICE_PAYMENT_DUE; },
    getLicenseExpiration() { return LICENSE_EXPIRATION; },
    getShiftReassign() { return SHIFT_REASSIGN; },
    getSickDayApproval() { return SICK_DAY_APPROVAL; },
    getSickDayRejection() { return SICK_DAY_REJECTION; },

    getNotificationObject(notification) {
      return notification[NOTIFICATION_OBJECTS[notification.type]];
    },

    isApproval(type) {
      return _.includes(APPROVAL_NOTIFICATIONS, type);
    },

    isRejectionCancellationOrUnassign(type) {
      return _.includes(REJECTION_NOTIFICATIONS, type);
    },

    isInvitation(type) {
      return this.getShiftInvitation() === type;
    },

    isInvoiceRelated(type) {
      return _.includes(INVOICE_RELATED_NOTIFICATIONS, type);
    }
  };
};

angular.module('data.employee').service('employeeNotificationType', employeeNotificationType);
