/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', '$state', '$stateParams', 'notificationService', '$http', 'User', 'storageService', 'defaultConfig', 'redirectService'];

const SignSecondFactorAuthController = function($scope, $state, $stateParams, notificationService, $http, User, storageService, defaultConfig, redirectService) {

  const vm = this;
  vm.securityKeyCount = 0;
  vm.securityKeyErrorMessage = "";
  vm.errorMessage = "";

  vm.$onInit = async function () {
    if($stateParams.sessionId)
    {
      storageService.set(defaultConfig.authSessionKey, $stateParams.sessionId);
    }
    vm.sessionId = storageService.get(defaultConfig.authSessionKey);
    if (!($stateParams.profile || $stateParams.user || vm.sessionId)) {
      $state.go('sign.login');
    } else {
      await initUser();
    }
  };

  const initUser = async function () {
    vm.user = new User($stateParams.profile);
    vm.authcode = { 
      digit1: "", 
      digit2: "", 
      digit3: "", 
      digit4: "", 
      digit5: "", 
      digit6: ""
    };
    await $http.post("/api/webauthn_credential_authentication/options",null, {
      headers: { 'X-Second-Factor-Auth-Id': vm.sessionId }
    }).then(function (response) {
      vm.webauthn_options = response.data
      vm.securityKeyCount = vm.webauthn_options.allowCredentials.length;
    });
  };

  vm.login_otp = async function() {
    try {
      $http.post("/api/webauthn_credential_authentication", { otp: vm.authcode}, {
        headers: { 'X-Second-Factor-Auth-Id': vm.sessionId }
      }).then(function(response) {
        if (response.status == 200) {
          storageService.set(defaultConfig.sessionKey, response.data.encrypted_id);
          storageService.remove(defaultConfig.authSessionKey);
          redirectService.redirectToMain();
        } else {
          console.log("Sorry, something wrong happened.");
          const responseData = response.data;
          if (responseData.package_error) {
            storageService.set('packageError', JSON.stringify(responseData));
            $state.go('sign.payment-message');
          } else {
            redirectService.redirectToMain();
          }
        }
      },
      function(response) {
        if (response.status == 422) {
          vm.errorMessage = response.data.error;
          vm.securityKeyErrorMessage = "";
          vm.authcode = { 
            digit1: "", 
            digit2: "", 
            digit3: "", 
            digit4: "", 
            digit5: "", 
            digit6: ""
          };
        } else {
          console.log("Sorry, something wrong happened.");
          const responseData = response.data;
          if (responseData.package_error) {
            storageService.set('packageError', JSON.stringify(responseData));
            $state.go('sign.payment-message');
          } else {
            redirectService.redirectToMain();
          }
        }
      });
    } catch (error) {
      vm.errorMessage = "An unexpected error occurred:" + error.message;
      vm.securityKeyErrorMessage = "";
    }
  };

  vm.login_webauthn = async function() {
    try {
      await webauthnJSON.get({ publicKey: vm.webauthn_options }).then(function(credential) {
        $http.post("/api/webauthn_credential_authentication",credential, {
          headers: { 'X-Second-Factor-Auth-Id': vm.sessionId }
        }).then(function(response) {
          if (response.status == 200) {
            storageService.set(defaultConfig.sessionKey, response.data.encrypted_id);
            storageService.remove(defaultConfig.authSessionKey);
            redirectService.redirectToMain();
          } else {
            console.log("Sorry, something wrong happened."); 
            const responseData = response.data;
            if (responseData.package_error) {
              storageService.set('packageError', JSON.stringify(responseData));
              $state.go('sign.payment-message');
            } else {
              redirectService.redirectToMain();
            }
          }
        },
        function(response) {
          if (response.status == 422) {
            vm.errorMessage = "";
            vm.securityKeyErrorMessage = response.data.error;
          } else {
            console.log("Sorry, something wrong happened.");
            const responseData = response.data;
            if (responseData.package_error) {
              storageService.set('packageError', JSON.stringify(responseData));
              $state.go('sign.payment-message');
            } else {
              redirectService.redirectToMain();
            }
          }
        });
      });
    } catch (error) {
      vm.errorMessage = "";
      if (error.name === "NotAllowedError") {
        vm.securityKeyErrorMessage = "Error validating with that method. Please try again"
      } else {
        vm.securityKeyErrorMessage = "An unexpected error occurred:" + error.message;
      }
    }
    $scope.$digest();
  };

  return vm;
};

angular.module('sign').controller('SignSecondFactorAuthController', dependencies.concat(SignSecondFactorAuthController));
