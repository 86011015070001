const gccsManagerClockInEdit = {
  controller: 'ManagerClockInEditController',
  template: require('./template.pug'),
  bindings: {
    title: '@',
    button: '@',
    successMessage: '@',
    shiftEmployee: '<',
    isShownClockIn: '<',
    isShownClockOut: '<',
    isInNotification: '<',
    isInInvoice: '<',
    editable: '<',
    compareWith: '<',
    withoutSave: '<',
    withClockErrors: '<',
    closeCb: '&'
  }
};

angular.module('shared-components.popup.booking').component('gccsManagerClockInEdit', gccsManagerClockInEdit);
