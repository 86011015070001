const dependencies = ['$scope', '$state', 'currentUserService', 'validationService'];

const ProfileLayoutController = function($scope, $state, currentUserService, validationService) {

  const vm = this;

  vm.validationService = validationService;

  vm.$onInit = function() {
    vm.setUserAndSecurityManager();
    initTabs();
    initTitle();
  };

  const initTabs = function() {
    vm.tabs = [{
      name : 'Personal details',
      sref : 'public.security-manager.profile.personal-details'
    }];
    if (!currentUserService.getCurrentProfile().isSupervisor()) {
      vm.tabs = vm.tabs.concat([{
        name: 'Notification Settings',
        sref: 'public.security-manager.profile.notifications'
      }]);
    }
  };

  vm.setUserAndSecurityManager = function() {
    vm.user = angular.copy(currentUserService.getCurrentUser());
    vm.securityManager = angular.copy(currentUserService.getCurrentProfile());
  };

  vm.updateUser = user => currentUserService.updateCurrentUser(user);

  vm.updateProfile = function(securityManager) {
    currentUserService.updateProfile(securityManager);
    $scope.profilePersonalDetailsCtrl.resetForm();
  };

  vm.updateUserAndSecurityManager = function(user, securityManager) {
    currentUserService.updateCurrentUser(user);
    currentUserService.updateProfile(securityManager);
    $scope.profilePersonalDetailsCtrl.resetForm();
  };

  const initTitle = function () {
    const activeTab = _.find(vm.tabs, tab => $state.is(tab.sref));
    vm.title = activeTab && activeTab.name;
  };

  return vm;

};

angular.module('public.security-manager.profile')
  .controller('ProfileLayoutController', dependencies.concat(ProfileLayoutController));
