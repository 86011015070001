const dependencies = [
  '$scope', '$timeout', 'ClientLocation', 'RepeatableShiftRuleVersion', 'RepeatableBookingShiftRule',
  'confirmService', 'bookingShiftUnpaidBreak', 'notificationService', 'selectService', 'activeStatus',
  'dateService', 'dateWeek', 'payRateVisibilityService', 'packageTypeService',
  'bookingShiftValidationService', 'repeatableBookingInvoicePeriodType'
];

const ScheduleManagerShiftRuleController = function(
  $scope, $timeout, ClientLocation, RepeatableShiftRuleVersion, RepeatableBookingShiftRule,
  confirmService, bookingShiftUnpaidBreak, notificationService, selectService, activeStatus,
  dateService, dateWeek, payRateVisibilityService, packageTypeService,
  bookingShiftValidationService, repeatableBookingInvoicePeriodType
) {

  const vm = this;

  const VERSION_INDEX_CURRENT = 0;
  const VERSION_INDEX_NEW = 1;

  const DEFAULT_DELAY = 1500;

  vm.shiftUnpaidBreak = bookingShiftUnpaidBreak;
  vm.dateWeek = dateWeek;
  vm.getResourcesAmountErrors = bookingShiftValidationService.getResourcesAmountErrors;
  vm.shiftRuleCopy = null;
  vm.isShownLocationPopup = false;
  vm.isShownLocationsExceedPopup = false;
  vm.isShownRuleStartFromPopup = false;
  vm.isShownInvoicePeriodPopup = false;
  vm.isShownForm = true;
  vm.startFromWeekItems = {};

  let currentRuleIndex = VERSION_INDEX_CURRENT;

  vm.$onInit = function() {
    initNextBookingDate();
    checkToOpen();
    initChosenWeeks();
  };

  vm.toggleDetails = function() {
    if (!vm.shiftRule.isOpen) {
      vm.checkOtherRules({ applyCb: initOnOpen });
    } else {
      if (vm.shiftRule.isChanged || vm.shiftRule.id < 0) {
        notificationService.notifyError('Please save or delete entered data');
        return;
      }
      vm.closeDetails();
    }
  };

  vm.closeDetails = function() {
    vm.shiftRule.isOpen = false;
    vm.shiftRule.isChanged = false;
  };

  vm.deleteShiftRule = function() {
    confirmService.confirmRemove('this rule', () => {
      if (vm.isNewlyAddedRule()) {
        vm.onShiftRuleDelete({ id: vm.shiftRule.id });
      } else {
        vm.shiftRuleCopy.delete().then(() => {
          vm.onShiftRuleDelete({ id: vm.shiftRule.id });
        });
      }
    });
  };

  vm.saveShiftRule = function(isInvalid) {
    if (isInvalid || !vm.isSaveRuleAllowed()) { return; }
    if (vm.isNewlyAddedRule()) {
      delete vm.shiftRuleCopy.id;
    }
    _.each(vm.shiftRuleCopy.versions, function(version) {
      version.frequencyOn = dateWeek.toValue(version.chosenWeeks);
      version.frequencyEvery = +version.frequencyEvery;
    });
    saveShiftRule();
  };

  vm.isNewlyAddedRule = function() {
    return vm.shiftRuleCopy.id < 0;
  };

  vm.getShiftRuleDuration = function() {
    const startTimeMoment = moment(vm.shiftRule.versions[VERSION_INDEX_CURRENT].startTime, ['h:m a', 'H:m']);
    const endTimeMoment = moment(vm.shiftRule.versions[VERSION_INDEX_CURRENT].endTime, ['h:m a', 'H:m']);
    const shiftRuleDuration = moment.duration(endTimeMoment.diff(startTimeMoment));
    let label = `${ shiftRuleDuration.hours() }h`;
    if (shiftRuleDuration.minutes()) {
      label = label.concat(`${shiftRuleDuration.minutes()}m`);
    }
    return label;
  };

  vm.openFrequencyDropdown = function() {
    if (vm.isEditableVersion() && !vm.isLockedForReset()) {
      vm.isShownFrequencyDropdown = true;
    }
  };

  vm.closeFrequencyDropdown = function() {
    if (+vm.getCurrentVersion().frequencyEvery < 1) {
      vm.getCurrentVersion().frequencyEvery = vm.shiftRule.versions[currentRuleIndex].frequencyEvery
    }
    vm.isShownFrequencyDropdown = false;
  };

  vm.getFrequencyEveryText = function() {
    if (+vm.getCurrentVersion().frequencyEvery === 1) {
      return 'Weekly';
    } else {
      return `Every ${vm.getCurrentVersion().frequencyEvery} weeks`;
    }
  };

  vm.getFrequencyEveryMainText = function() {
    if (+vm.shiftRule.versions[VERSION_INDEX_CURRENT].frequencyEvery === 1) {
      return 'Week';
    } else {
      return `${vm.shiftRule.versions[VERSION_INDEX_CURRENT].frequencyEvery} weeks`;
    }
  };

  vm.canSeeChargeRate = function () {
    return payRateVisibilityService.isShownChargeRate(vm.getCurrentVersion().chargeRate);
  };

  vm.canSeePayRate = function () {
    return payRateVisibilityService.isShownPayRate(vm.getCurrentVersion().contractorPayRate, vm.getCurrentVersion().employedPayRate);
  };

  vm.closeLocationsExceedPopup = function () {
    vm.isShownLocationsExceedPopup = false;
  };

  vm.openRuleStartFromPopup = function(date) {
    vm.closeEditTooltip();
    if (!isInvoicePeriodAvailable()) {
      vm.isShownInvoicePeriodPopup = true;
    } else {
      vm.isShownRuleStartFromPopup = true;
      vm.chosenStartFrom = date;
    }
  };

  vm.closeInvoicePeriodPopup = function() {
    vm.isShownInvoicePeriodPopup = false;
  };

  vm.closeRuleStartFromPopup = function(date) {
    vm.isShownRuleStartFromPopup = false;
    if (date) {
      if (vm.hasNewVersion()) {
        vm.getNewVersion().startFrom = date;
        vm.updateStartFromWeekItems();
      } else {
        new RepeatableShiftRuleVersion({
          repeatableBookingId: vm.shiftRuleCopy.repeatableBookingId,
          shiftRuleId: vm.shiftRuleCopy.id,
          id: vm.getCurrentVersion().id,
          startFrom: date
        }).copy().then(function(newVersion) {
          newVersion.chosenWeeks = dateWeek.toArray(newVersion.frequencyOn);
          if (!vm.repeatableBooking.enableClockIn) {
            newVersion.enableClockIn = false;
          }
          vm.shiftRuleCopy.versions.push(newVersion);
          vm.selectRule(VERSION_INDEX_NEW);
          addNewVersionTab();
        });
      }

    }
  };

  vm.addLocation = function() {
    ClientLocation.getCount({
      clientId: vm.repeatableBooking.clientId
    }, {
      activeStatus: activeStatus.getActiveStatus()
    }).then((data) => {
      if (packageTypeService.hasFreePackage()) {
        if (data.count >= packageTypeService.getLocationsLimitForFreePackage()) {
          openLocationsExceedPopup();
        } else {
          openLocationPopup();
        }
      } else {
        openLocationPopup();
      }
    });
  };

  vm.closeLocationPopup = function(location) {
    vm.isShownLocationPopup = false;
    if (location) {
      vm.clientLocations = _.merge(vm.clientLocations, selectService.toSelectData([location]));
      vm.getCurrentVersion().locationId = location.id;
    }
  };

  vm.getStartTimeErrors = function () {
    return bookingShiftValidationService.getStartTimeDatelessErrors(hoursData);
  };

  vm.getEndTimeErrors = function () {
    return bookingShiftValidationService.getEndTimeDatelessErrors(hoursData);
  };

  vm.hasNewVersion = function() {
    return vm.shiftRuleCopy.versions.length > 1;
  };

  vm.getCurrentVersion = function() {
    if (!vm.shiftRuleCopy) { return; }
    return vm.shiftRuleCopy.versions[currentRuleIndex];
  };

  vm.isCurrentRule = function(index) {
    return currentRuleIndex === index;
  };

  vm.selectRule = function(index, isDelete = false) {
    if (currentRuleIndex === VERSION_INDEX_NEW && vm.shiftRule.isChanged && !isDelete) {
      notificationService.notifyError('Please save or delete entered data');
      return;
    }
    vm.isShownForm = false;
    currentRuleIndex = index;
    $timeout(function() {
      vm.isShownForm = true;
    });
  };

  vm.isEditableVersion = function() {
    return vm.isNewShiftRule() || currentRuleIndex === VERSION_INDEX_NEW;
  };

  vm.getMainVersion = function() {
    return vm.shiftRuleCopy.versions[VERSION_INDEX_CURRENT];
  };

  vm.getNewVersion = function() {
    return vm.shiftRuleCopy.versions[VERSION_INDEX_NEW];
  };

  vm.removeNewVersion = function(successCb) {
    confirmService.confirm(
      'Confirm new details removal',
      'This action will remove new details planned for this shift. Are you sure want to remove new details?',
      'Remove',
      () => {
        if (successCb) {
          successCb();
        }
        new RepeatableShiftRuleVersion({
          repeatableBookingId: vm.shiftRuleCopy.repeatableBookingId,
          shiftRuleId: vm.shiftRuleCopy.id,
          id: vm.getNewVersion().id
        }).remove();
        vm.shiftRuleCopy.versions.pop();
        vm.selectRule(VERSION_INDEX_CURRENT, true);
      }
    );
  };

  vm.isNewShiftRule = function() {
    return !vm.shiftRule.clonedFromId;
  };

  vm.updateStartFromWeekItems = function() {
    let startFromWeekItems = {};
    const frequencyEvery = +vm.getCurrentVersion().frequencyEvery;
    for (let startFromWeek = 1; startFromWeek <= frequencyEvery; startFromWeek++) {
      startFromWeekItems[startFromWeek] = `${getStartFromWeekText(startFromWeek)} (${getNextFirstShiftDate(startFromWeek)})`;
    }
    if (vm.getCurrentVersion().startFromWeek > frequencyEvery) {
      vm.getCurrentVersion().startFromWeek = 1;
    }
    vm.startFromWeekItems = startFromWeekItems;
  };

  vm.getClockFieldText = function() {
    if (vm.isEditableVersion()) {
      return 'Enable clock in/out for booking shifts';
    } else {
      return vm.getCurrentVersion().enableClockIn ? 'Enabled' : 'Disabled';
    }
  };

  const isInvoicePeriodAvailable = function() {
    const endDate = moment(vm.repeatableBooking.endDate, dateService.getIsoDateFormat());
    if (repeatableBookingInvoicePeriodType.isWeeklyType(vm.repeatableBooking.invoicePeriodType)) {
      const monthDate = dateService.today().startOf('month');
      let date = monthDate.clone();
      date.day(dateWeek.getName(vm.repeatableBooking.invoicePeriodStart));
      while (date.isBefore(endDate)) {
        date.add(1, 'week');
      }
      date.add(-1, 'week');
      return date.isAfter(dateService.today());
    } else {
      let date = endDate.clone().startOf('month');
      return date.isAfter(dateService.today());
    }
  };

  const openDetails = function() {
    vm.shiftRule.isOpen = true;
  };

  const getNextFirstShiftDate = function(startFromWeek) {
    let startFromMoment;
    if (vm.getCurrentVersion().startFrom) {
      startFromMoment = moment(vm.getCurrentVersion().startFrom, dateService.getDateFormat());
    } else {
      startFromMoment = moment(vm.repeatableBooking.startDate, dateService.getIsoDateFormat());
    }
    const currentStartFromMoment = startFromMoment.clone().add(startFromWeek - 1, 'week');
    let firstShiftDate = currentStartFromMoment.clone().add(1, 'week');
    let firstRealShiftDate;
    for (let chosenWeek of vm.getCurrentVersion().chosenWeeks) {
      let chosenWeekDate = currentStartFromMoment.clone().day(dateWeek.getName(chosenWeek));
      if (dateWeek.isSunday(chosenWeek)) {
        chosenWeekDate.add(1, 'week');
      }
      if (chosenWeekDate.isBefore(firstShiftDate)) {
        firstShiftDate = chosenWeekDate;
      }
      if (chosenWeekDate.isSameOrAfter(currentStartFromMoment) && (!firstRealShiftDate || chosenWeekDate.isBefore(firstRealShiftDate))) {
        firstRealShiftDate = chosenWeekDate;
      }
    }
    if (firstShiftDate.isBefore(startFromMoment) && firstRealShiftDate) {
      return firstRealShiftDate.format(dateService.getDateShortFormat());
    } else {
      return firstShiftDate.format(dateService.getDateShortFormat());
    }
  };

  const saveShiftRule = function() {
    vm.shiftRuleCopy.save().then((shiftRule) => {
      notificationService.notifySuccess('Shift rule updated!');
      vm.closeDetails();
      _.assign(vm.shiftRule, shiftRule);
      initChosenWeeks();
    });
  };

  vm.isShownNewVersionStartDateSelect = function() {
    return getNewVersionStartDateCopy() && vm.isEditableVersion();
  };

  vm.isShownNewVersionStartDateEditButton = function() {
    return !getNewVersionStartDateCopy() && !vm.isEditableVersion();
  };

  vm.isShownNewVersionStartDateMain = function() {
    return !vm.shiftRule.isOpen && getNewVersionStartDate();
  };

  vm.getFormatStartDate = function() {
    return getNewVersionStartDate();
  };

  vm.onFrequencyEveryChange = function() {
    vm.updateStartFromWeekItems();
  };

  vm.isLockedForReset = function() {
    return vm.isEditableVersion() && vm.getNewVersion() && !vm.getNewVersion().needReset;
  };

  vm.setReset = function() {
    confirmService.confirm(
      'Unassign employees from future shifts?',
      'Your assigned employees will be deleted and you have to reassign all employees manually again.',
      'Yes',
      function() {
        vm.getNewVersion().needReset = true;
        vm.updateStartFromWeekItems();
      },
      () => {},
      'Back to editing'
    );
  };

  vm.isSaveRuleAllowed = function() {
    return vm.isNewlyAddedRule() || vm.shiftRule.isChanged;
  };

  vm.isShownEditButton = function() {
    return vm.isEdit && !vm.isNewShiftRule() && vm.isShownNewVersionStartDateEditButton() && !isBookingPassed();
  };

  vm.closeEditTooltip = function() {
    vm.isShownEditTooltip = false;
  };

  vm.isClockInOutDisabled = function() {
    if (vm.shiftRule.versions[currentRuleIndex]) {
      return !vm.isEditableVersion() || (!vm.repeatableBooking.enableClockIn && !vm.shiftRule.versions[currentRuleIndex].enableClockIn);
    } else {
      return !vm.isEditableVersion() || !vm.repeatableBooking.enableClockIn;
    }
  };

  const isBookingPassed = function() {
    const endDate = moment(vm.repeatableBooking.endDate, dateService.getIsoDateFormat());
    return dateService.today().isAfter(endDate);
  };

  const getStartFromWeekText = function(startFromWeek) {
    switch (startFromWeek) {
      case 1:
        return 'This week';
      case 2:
        return 'Next week';
      default:
        return `In ${startFromWeek} weeks`;
    }
  };

  const getNewVersionStartDate = function() {
    return vm.shiftRule.versions[vm.shiftRule.versions.length - 1].startFrom;
  };

  const getNewVersionStartDateCopy = function() {
    return vm.shiftRuleCopy.versions[vm.shiftRuleCopy.versions.length - 1].startFrom;
  };

  const checkToOpen = function() {
    if (vm.shiftRule.id < 0) {
      vm.toggleDetails();
    }
  };

  const openLocationsExceedPopup = function() {
    vm.isShownLocationsExceedPopup = true;
  };

  const hoursData = function () {
    return {
      minHours: vm.shiftRuleCopy.resource.minHours,
      maxHours: vm.shiftRuleCopy.resource.maxHours
    };
  };

  const openLocationPopup = function() {
    vm.popupLocation = new ClientLocation;
    vm.popupLocation.clientId = vm.repeatableBooking.clientId;
    vm.isShownLocationPopup = true;
  };

  const initChosenWeeks = function() {
    _.each(vm.shiftRule.versions, function(version) {
      version.chosenWeeks = dateWeek.toArray(version.frequencyOn);
    });
  };

  const initOnOpen = function() {
    copyShiftRule();
    initRuleTabs();
    initClientLocations();
    initRepeatData();
    vm.updateStartFromWeekItems();
    openDetails();
    openEditTooltip();
  };

  const openEditTooltip = function() {
    if (vm.isShownEditButton()) {
      $timeout(() => {
        vm.isShownEditTooltip = true;
      }, DEFAULT_DELAY);
    }
  };

  const initRuleTabs = function() {
    vm.selectRule(VERSION_INDEX_CURRENT);
    vm.ruleTabs = ['Current details'];
    if (vm.hasNewVersion()) {
      vm.ruleTabs.push(`Future details (${vm.getFormatStartDate()})`);
    }
  };

  const addNewVersionTab = function() {
    vm.ruleTabs[VERSION_INDEX_NEW] = `Future details (${getNewVersionStartDateCopy()})`;
  };

  const initRepeatData = function() {
    _.each(vm.shiftRuleCopy.versions, function(version) {
      version.chosenWeeks = dateWeek.toArray(version.frequencyOn);
    });
    vm.isShownFrequencyDropdown = false;
  };

  const initClientLocations = function() {
    vm.clientLocations = [];
    ClientLocation.query({
      activeStatus: activeStatus.getActiveStatus()
    }, {
      clientId: vm.repeatableBooking.clientId
    }).then(function (locations) {
      const foundLocation = _.find(locations, ['id', vm.getCurrentVersion().locationId]);
      if (!foundLocation) {
        locations.push(vm.getCurrentVersion().location);
      }
      vm.clientLocations = selectService.toSelectData(locations);
    });
  };

  const copyShiftRule = function() {
    vm.shiftRuleCopy = angular.copy(vm.shiftRule);
  };

  const initNextBookingDate = function() {
    let nextBookingDate = dateService.today();
    if (repeatableBookingInvoicePeriodType.isWeeklyType(vm.repeatableBooking.invoicePeriodType)) {
      nextBookingDate.day(dateWeek.getName(vm.repeatableBooking.invoicePeriodStart));
      if (nextBookingDate.isBefore()) {
        nextBookingDate.add(1, 'week');
      }
    } else {
      nextBookingDate.add(1, 'month').startOf('month');
    }
    vm.nextBookingDate = nextBookingDate;
  };

  const isCommentsModelChanged = function(versionComments, otherVersionComments) {
    let isModelChange = false;
    if (versionComments.length === otherVersionComments.length) {
      _.each(versionComments, (value, index) => {
        if (value.constructor.name !== otherVersionComments[index].constructor.name) {
          isModelChange = true;
          return false;
        }
      });
    }
    return isModelChange;
  };

  const isValidChange = function(newVersions, oldVersions) {
    let isValidChange = true;
    if (newVersions.length === oldVersions.length) {
      _.each(newVersions, (version, index) => {
        if (isCommentsModelChanged(version.comments, oldVersions[index].comments)) {
          isValidChange = false;
          return false;
        }
      });
    }
    return isValidChange;
  };

  $scope.$watch(() => vm.shiftRuleCopy && vm.shiftRuleCopy.versions, (newVal, oldVal) => {
    if (oldVal && vm.shiftRule.isOpen && isValidChange(newVal, oldVal)) {
      vm.shiftRule.isChanged = true;
    }
  }, true);

  return vm;

};

angular.module('public.security-manager.schedule-manager.repeatable-booking')
  .controller('ScheduleManagerShiftRuleController',
    dependencies.concat(ScheduleManagerShiftRuleController)
  );
