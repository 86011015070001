/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [];

const ExtendedListFilterOptionsController = function() {

  const vm = this;

  vm.onAccessToggleChange = function(value) {
    if (value) {
      vm.service.selectAllOptions(vm.category);
    } else {
      vm.service.deselectAllOptions(vm.category);
    }
  };


  return vm;

};

angular.module('shared-components.extended-filters')
  .controller('ExtendedListFilterOptionsController', dependencies.concat(ExtendedListFilterOptionsController));
