const dependencies = ['dateService'];

const RateChangeInfoController = function(dateService) {

  const vm = this;

  vm.getFormattedDateOfChange = function() {
    const dateOfChange = moment(vm.rateChange.dateOfChange, dateService.getIsoDateFormat());
    return dateOfChange.format(dateService.getDateShortFormat());
  };

  return vm;

};

angular.module('shared-components')
  .controller('RateChangeInfoController', dependencies.concat(RateChangeInfoController));
