/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$filter', 'packageTypeService', 'calculationService'
];

const SecurityManagerBillingLicenseGrandTotalController = function(
  $filter, packageTypeService, calculationService
) {

  const vm = this;

  vm.getPricePerEmployee = function() {
    return packageTypeService.getPricePerEmployee(vm.packageType);
  };

  vm.getActiveEmployeesChargeFormula = function() {
    return `${$filter('formatPriceFull')(vm.getPricePerEmployee())} х ${vm.licensesCount}`;
  };

  vm.getActiveEmployeesChargeTotal = function() {
    return vm.getPricePerEmployee() * vm.licensesCount;
  };

  vm.getVatPercent = function() {
    let vatPercent = 0;
    if (vm.country && vm.country.vatPercentage) {
      vatPercent = vm.country.vatPercentage;
    }
    return vatPercent;
  };

  vm.getVatTotal = function() {
    return calculationService.percentValue(vm.getActiveEmployeesChargeTotal(), vm.getVatPercent());
  };

  vm.getTotalPrice = function() {
    return vm.getActiveEmployeesChargeTotal() + vm.getVatTotal();
  };
  
  return vm;

};

angular.module('public.security-manager.billing')
  .controller('SecurityManagerBillingLicenseGrandTotalController',
    dependencies.concat(SecurityManagerBillingLicenseGrandTotalController));
