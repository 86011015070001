/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['Document'];

const SecurityProviderDocument = Document => {

  class SecurityProviderDocument extends Document {
    static initClass() {
      this.configure({
        url: '/security_providers/{{ securityProviderId }}/documents/{{ id }}',
        name: 'document'
      });
    }
  }

  SecurityProviderDocument.initClass();

  return SecurityProviderDocument;

};

angular
  .module('data.security-provider')
  .factory('SecurityProviderDocument', dependencies.concat(SecurityProviderDocument));
