/*
 * @author Igor Serpak <robobee>
 */
const employeeLicenseType = function() {

  const OTHER = 1;
  const SIA = 2;
  const FIRST_AID = 3;

  const names = {};
  names[SIA] = 'SIA';
  names[FIRST_AID] = 'First Aid';

  const icons = {};
  icons[SIA] = '#type-sia';
  icons[FIRST_AID] = '#type-first-aid';

  const types = [{
    type : SIA,
    name : names[SIA],
    icon : icons[SIA]
  }
  , {
    type : FIRST_AID,
    name : names[FIRST_AID],
    icon : icons[FIRST_AID]
  }
  ];

  return {
    getOther() { return OTHER; },
    getSia() { return SIA; },
    getFirstAid() { return FIRST_AID; },

    getTypes() { return types; },
    getIcon(type) { return icons[type]; }
  };
};

angular.module('data.employee').service('employeeLicenseType', employeeLicenseType);
