const dependencies = ['$timeout'];

const onBodyClickNext = $timeout => {

  return {
    scope: {
      onBodyClickCondition: '<',
      onBodyClickCb: '&',
      onCapture: '<',
      stopPropagation: '<'
    },

    link($scope, $el) {

      const elem = $el[0];

      const onCapturePhase = $scope.onCapture === undefined ? true : $scope.onCapture;
      const stopPropagation = $scope.stopPropagation === undefined ? true : $scope.stopPropagation;

      const captureHandler = function(e) {
        if (!$scope.onBodyClickCondition) { return; }
        // If click is outside container
        if (!elem.contains(e.target) && (e.target.tagName !== 'BODY')) {
          // invoke given callback
          $timeout(() => $scope.onBodyClickCb(), 0);
          // stop propagation
          if (stopPropagation) {
            e.stopPropagation();
            e.stopImmediatePropagation();
            e.preventDefault();
          }
        }
      };

      document.addEventListener('click', captureHandler, onCapturePhase);

      $scope.$on('$destroy', () => document.removeEventListener('click', captureHandler, onCapturePhase));
    }
  }
};

angular.module('shared').directive('onBodyClickNext', dependencies.concat(onBodyClickNext));
