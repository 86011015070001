const dependencies = [
  '$scope', 'ClientLocation', 'ClientService', 'Employee', 'packageTypeService', 'activeStatus'
];

const PopupClientSiteFormController = function(
  $scope, ClientLocation, ClientService, Employee, packageTypeService, activeStatus
) {

  const vm = this;

  let isFormSubmitted = false;

  vm.loadLocations = function(text) {
    return ClientLocation
      .query({
        'order[name]': 'asc',
        name: text,
        limit: 9,
        activeStatus: activeStatus.getActiveStatus()
      }, { clientId: vm.site.clientId })
      .then(function (result) {
        result.push({
          dummyItem: true,
          label: 'Add new location',
          onSelect() {
            showLocationPopup();
          }
        });
        return result;
      });
  };

  vm.loadEmployees = function(text) {
    return Employee.autocomplete({}, { 'order[full_name]': 'asc', fullName: text, limit: 20 });
  };

  vm.closeLocationPopup = function(savedLocation) {
    vm.isShownLocationPopup = false;
    vm.gccsPopupsLayout.showPopup();
    if (savedLocation) {
      if (!vm.site.locations) {
        vm.site.locations = [];
      }
      vm.site.locations.push(savedLocation);
    }
  };

  vm.onSubmit = function(form) {
    if (form.$invalid) { return; }
    if (isFormSubmitted) { return; }
    isFormSubmitted = true;
    vm.site.save().then(site => {
      vm.gccsPopupsLayout.closePopup({
        callbackData: {
          site: site
        }
      });
    });
  };

  vm.closeLocationsExceedPopup = function () {
    vm.isShownLocationsExceedPopup = false;
    vm.gccsPopupsLayout.showPopup();
  };

  let showLocationPopup = function() {
    ClientLocation.getCount({ clientId: vm.site.clientId }, { activeStatus: activeStatus.getActiveStatus() }).then((data) => {
      if (packageTypeService.hasFreePackage()) {
        if (data.count >= packageTypeService.getLocationsLimitForFreePackage()) {
          openLocationsExceedPopup();
        } else {
          openLocationPopup();
        }
      } else {
        openLocationPopup();
      }
      vm.gccsPopupsLayout.hidePopup();
    });
  };

  const openLocationsExceedPopup = function() {
    vm.isShownLocationsExceedPopup = true;
  };

  const openLocationPopup = function() {
    vm.popupLocation = new ClientLocation({ clientId: vm.site.clientId });
    vm.isShownLocationPopup = true;
  };

  const initSite = function() {
    vm.saveButtonText = vm.site.isNew() ? 'Add' : 'Save';
  };

  $scope.$on('popup.open', function() {
    if (vm.gccsPopupsLayout.popupShowCondition) {
      initSite();
      vm.site = angular.copy(vm.site);
    }
  });

  return vm;

};

angular.module('shared-components.popup.client')
  .controller('PopupClientSiteFormController', dependencies.concat(PopupClientSiteFormController));
