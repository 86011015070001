/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const DEFAULT_DISTANCE_TO_END = 200;

angular.module('shared').directive('onScrollPagination', () => {
  return {
    restrict : 'A',
    scope : {
      onScrollPaginationCb : '&',
      disablePaginationCondition : '&',
      distanceToEnd : '@',
      listenElement : '<',
      isHorizontal : '<'
    },
    link($scope, $el) {
      let bindElement, scrollableElements;
      const distanceToEnd = ($scope.distanceToEnd - 0) || DEFAULT_DISTANCE_TO_END;

      if ($scope.listenElement) {
        bindElement = $el[0];
        scrollableElements = [bindElement];
      } else {
        bindElement = window;
        scrollableElements = [document.documentElement, document.body];
      }

      const getScrollPosition = function() {
        if ($scope.isHorizontal) {
          return getScrollableAttr('scrollLeft');
        } else {
          return getScrollableAttr('scrollTop');
        }
      };

      const updateScrollPosition = function(scrollPosition) {
        if ($scope.isHorizontal) {
          return setScrollableAttr('scrollLeft', scrollPosition);
        } else {
          return setScrollableAttr('scrollTop', scrollPosition);
        }
      };

      const getScrollDistanceToEnd = function() {
        if ($scope.isHorizontal) {
          return getScrollableAttr('scrollWidth') - getScrollableAttr('scrollLeft') - getScrollableAttr('offsetWidth');
        } else {
          return getScrollableAttr('scrollHeight') - getScrollableAttr('scrollTop') - getScrollableAttr('offsetHeight');
        }
      };

      const getScrollableAttr = function(attr) {
        for (let scrollableElement of Array.from(scrollableElements)) {
          const value = scrollableElement[attr];
          if (value !== 0) { return value; }
        }
        return 0;
      };

      const setScrollableAttr = (attr, value) => {
        return Array.from(scrollableElements).map((scrollableElement) => (scrollableElement[attr] = value));
      };

      const needPaginate = () => getScrollDistanceToEnd() <= distanceToEnd;

      const onScroll = function() {
        if (!$scope.disablePaginationCondition() && needPaginate()) {
          const scrollPosition = getScrollPosition();
          $scope.$apply($scope.onScrollPaginationCb);
          return updateScrollPosition(scrollPosition); // strange bug
        }
      };

      bindElement.addEventListener('scroll', onScroll);

      return $scope.$on('$destroy', () => bindElement.removeEventListener('scroll', onScroll));
    }
  }
});
