/*
 * @author Igor Serpak <robobee>
 */
angular.module('data.user').factory('SupportMessage', ['AppModel', function(AppModel) {

  class SupportMessage extends AppModel {
    static initClass() {
      this.configure({
        url : '/support_messages/{{id}}', name : 'support_message'});
    }
  }

  SupportMessage.initClass();
  return SupportMessage;
}
]);
