/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dSeriesGroupService = function() {

  const isDIntersecting = function(oldDShifts, newDShifts) {
    for (let newDShift of Array.from(newDShifts)) {
      const newStartTimeMoment = newDShift.getStartTimeMoment();
      const newEndTimeMoment = newDShift.getEndTimeMoment();
      for (let oldDShift of Array.from(oldDShifts)) {
        const oldStartTimeMoment = oldDShift.getStartTimeMoment();
        const oldEndTimeMoment = oldDShift.getEndTimeMoment();
        if ((!(newStartTimeMoment.diff(oldStartTimeMoment) <= 0) ||
          !(newEndTimeMoment.diff(oldStartTimeMoment) <= 0)) &&
          (!(newStartTimeMoment.diff(oldEndTimeMoment) >= 0) ||
            !(newEndTimeMoment.diff(oldEndTimeMoment) >= 0))) { return true; }
      }
    }
    return false;
  };

  return {
    groupDSeries(allDSeries) {
      const groupedDSeries = [];
      for (let dSeries of Array.from(allDSeries)) {
        if (!dSeries.dShifts) { continue; }
        let isMerged = false;
        for (let index = 0; index < groupedDSeries.length; index++) {
          const groupedDShifts = groupedDSeries[index];
          if (!isDIntersecting(groupedDShifts, dSeries.dShifts)) {
            groupedDSeries[index] = groupedDShifts.concat(dSeries.dShifts);
            isMerged = true;
            break;
          }
        }
        if (!isMerged) { groupedDSeries.push(dSeries.dShifts); }
      }
      return groupedDSeries;
    }
  };
};

angular.module('public.timeline').service('dSeriesGroupService', dSeriesGroupService);
