const dependencies = ['$timeout', 'packageTypeService'];

const ClientShowClockInPreviewController = function($timeout, packageTypeService) {

  const vm = this;

  vm.isShownClockInRestrictPopup = false;

  vm.onChange = function() {
    $timeout(() => {
      vm.onValueChange({ value: vm.value });
    });
  };

  vm.openClockInRestrictPopup = function() {
    vm.isShownClockInRestrictPopup = true;
  };

  vm.closeClockInRestrictPopup = function() {
    vm.isShownClockInRestrictPopup = false;
  };

  vm.isClockInRestricted = function() {
    return !packageTypeService.hasProPackage();
  };

  return vm;

};

angular.module('public.security-manager.client.show')
  .controller('ClientShowClockInPreviewController', dependencies.concat(ClientShowClockInPreviewController));
