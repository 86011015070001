/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$state', 'Employee', 'employeeStatus', 'pluralizeService', 'SecurityManager'
];

const PopupNotificationRegisteredEmployeesController = function(
  $scope, $state, Employee, employeeStatus, pluralizeService, SecurityManager
) {

  const vm = this;

  vm.getPopupTitle = function() {
    return `${pluralizeService.simple($scope.employees.length, 'user')} uploaded by:`;
  };

  vm.openEmployeeProfileTab = function(employee) {
    const url = $state.href('public.security-manager.employee.details', { employeeId: employee.employeeId });
    window.open(url, '_blank').focus();
  };

  const initUploader = function() {
    if (!$scope.securityManagerId) { return; }
    SecurityManager.get($scope.securityManagerId).then(securityManager => {
      vm.uploader = securityManager;
    });
  };

  const initEmployees = function() {
    Employee.query({
      'order[full_name]': 'asc',
      template: 'bulk_pay_rate',
      status: employeeStatus.getActiveStatus(),
      id: $scope.employees.join()
    }).then(function (employees) {
      employees.forEach(employee => employee.lowerCaseFullName = employee.fullName.toLowerCase());
      vm.employees = _.orderBy(employees, ['lowerCaseFullName']);
    });
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      initUploader();
      initEmployees();
    }
  });

  return vm;

};

angular.module('popup.notification')
  .controller('PopupNotificationRegisteredEmployeesController',
    dependencies.concat(PopupNotificationRegisteredEmployeesController)
  );
