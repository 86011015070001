const dependencies = [
  '$scope', '$state', '$location', 'Report', 'FilterParamsService', 'EntityManager',
  'employeeDetailsField', 'downloadService', 'employeeStatus'
];

const ReportsHrMissingDataController = function(
  $scope, $state, $location, Report, FilterParamsService, EntityManager,
  employeeDetailsField, downloadService, employeeStatus
) {

  const vm = this;

  const entityManager = new EntityManager(Report, {
    loadCbName: 'getEmployeesMissingData',
    queryParams: {
      status: employeeStatus.getActiveStatus()
    }
  });
  const filterParamsService = new FilterParamsService({
    order: ['fields', 'workGap', 'addressGap'],
    filter: ['name', 'fields']
  });

  let lastOpenedReport = null;

  vm.filterParams = filterParamsService.getParams();
  vm.isShownFilterPopup = false;

  vm.$onInit = function() {
    initParams();
  };

  vm.loadMoreEmployeesMissingData = () => entityManager.loadMore().then(buildMissingFields);

  vm.getEmployeesMissingData = () => entityManager.entities;

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  vm.toggleMissingData = function(employeeMissingData) {
    if (lastOpenedReport === employeeMissingData) {
      lastOpenedReport.isOpened = false;
      lastOpenedReport = null;
    } else {
      if (!employeeMissingData.isOpened) {
        if (lastOpenedReport) {
          lastOpenedReport.isOpened = false;
        }
        employeeMissingData.isOpened = true;
        lastOpenedReport = employeeMissingData;
      }
    }
  };

  vm.toggleTableOrder = function(field) {
    _.each(vm.filterParams.order, (value, key) => {
      if (key === field) {
        if (value.ascending === null) {
          value.ascending = true;
        } else {
          value.ascending = !value.ascending;
        }
      } else {
        value.ascending = null;
      }
    });
  };

  vm.exportData = function() {
    const urlParams = filterParamsService.getFilterUrlParams(true);
    buildUrlParamsForFields(urlParams);
    _.extend(urlParams, entityManager.options.queryParams);
    Report.exportMissingData(urlParams).then(function (response) {
      downloadService.downloadBase64File(response);
    });
  };

  vm.getSortClass = function(field) {
    if (vm.filterParams.order[field].ascending === true) {
      return 'reports-hr-title-sortable-bottom';
    } else if (vm.filterParams.order[field].ascending === false) {
      return 'reports-hr-title-sortable-top';
    } else {
      return '';
    }
  };

  vm.showFilterPopup = function() {
    vm.isShownFilterPopup = true;
  };

  vm.onCloseFilterPopup = function(fieldsIds) {
    vm.isShownFilterPopup = false;
    if (fieldsIds) {
      initParams(fieldsIds);
    }
  };

  vm.isShownFilterCount = function() {
    return vm.fieldsParams.length !== employeeDetailsField.getNamesCount();
  };

  const initParams = function(fieldsIds) {
    const locationParams = $location.search();
    if (locationParams.fields) {
      vm.fieldsParams = fieldsIds || locationParams.fields.split(',');
    } else {
      vm.fieldsParams = fieldsIds || _.keys(employeeDetailsField.getNames());
    }
    vm.filterParams.filter.fields = vm.fieldsParams.join(',');
  };

  const buildUrlParamsForFields = function(params) {
    if (!params.fields) { return; }
    const fieldParams = [];
    params.fields.split(',').forEach(id => {
      if (!employeeDetailsField.isCharacterReference(+id)) {
        fieldParams.push(employeeDetailsField.getParamName(id));
      } else {
        const foundCharacterReference = _.find(fieldParams, _.isObject);
        if (!foundCharacterReference) {
          fieldParams.push({ characterReference: [employeeDetailsField.getParamName(id)] });
        } else {
          foundCharacterReference.characterReference.push(employeeDetailsField.getParamName(id))
        }
      }
    });
    params.fields = angular.copy(fieldParams);
  };

  const buildMissingFields = function() {
    _.each(vm.getEmployeesMissingData(), (employeeMissingData) => {
      _.each(employeeMissingData.missingFields, (value, index, array) => {
        if (_.isObject(value)) {
          array.splice(index, 1, ...value.characterReference);
        }
      });
    });
  };

  const filterMissingData = function() {
    entityManager.resetLoadData();
    $location.search(filterParamsService.getFilterUrlParams());
    const urlParams = filterParamsService.getFilterUrlParams(true);
    buildUrlParamsForFields(urlParams);
    return entityManager.load(urlParams).then(() => {
      buildMissingFields();
    });
  };

  $scope.$watch(() => vm.filterParams, _.debounce(filterMissingData, 50) , true);

  return vm;

};

angular.module('public.security-manager.reports.hr')
  .controller('ReportsHrMissingDataController', dependencies.concat(ReportsHrMissingDataController));
