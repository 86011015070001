/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', '$element'];

const TimelineBookingFormTimeLineController = function($scope, $element) {

  const vm = this;

  const scrollHolder = $element[0].querySelector('.add-timeline');

  vm.$onInit = function() {
    vm.service = new vm.timeLineInteractor(scrollHolder);
    vm.service.initDates(vm.from, vm.to);
    $scope.$emit('interactorServiceInitiated', vm.service);
  };

  return vm;
};

angular.module('public.timeline').controller('TimelineBookingFormTimeLineController',
  dependencies.concat(TimelineBookingFormTimeLineController));
