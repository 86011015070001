const dependencies = ['RepeatableBooking', 'dateService', 'confirmService'];

const PopupEndRepeatableBookingController = function(RepeatableBooking, dateService, confirmService) {

  const vm = this;

  let isFormSubmitted = false;

  vm.$onInit = function() {
    vm.minDate = dateService.today();
    vm.maxDate = moment(vm.repeatableBooking.endDate, dateService.getIsoDateFormat());
    vm.repeatableBookingEndDate = dateService.today();
  };

  vm.endRepeatableBooking = function(isInvalid) {
    if (isInvalid || isFormSubmitted) { return; }
    isFormSubmitted = true;
    confirmService.confirm(
      'Confirm remove',
      "Do you really want to remove repeatable bookings from selected date? This action can't be undone.",
      'Yes',
      function() {
        new RepeatableBooking(vm.repeatableBooking).clearAfterDate(vm.repeatableBookingEndDate);
        vm.gccsPopupsLayout.closePopup();
      },
      function() {
        isFormSubmitted = false;
      }
    );
  };

  return vm;

};

angular.module('shared-components.popup.booking')
  .controller('PopupEndRepeatableBookingController', dependencies.concat(PopupEndRepeatableBookingController));
