/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('data.user').service('verificationType', function() {

  const VERIFICATION_TYPE_ALWAYS = 1;
  const VERIFICATION_TYPE_NEW_DEVICE = 2;
  const VERIFICATION_TYPE_NONE = 3;

  return {
    getVerificationTypeAlwaysOn() {
      return VERIFICATION_TYPE_ALWAYS;
    },

    getVerificationTypeOnlyNewDevices() {
      return VERIFICATION_TYPE_NEW_DEVICE;
    },

    getVerificationTypeSwitchedOff() {
      return VERIFICATION_TYPE_NONE;
    },
  };
});
