/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.booking').directive('gccsPopupBookingShiftFormComments', () => {
  return {
    restrict: 'A',
    controller: 'PopupBookingShiftFormCommentsController',
    controllerAs: 'shiftFormCommentsCtrl',
    template: require('./template.pug'),
    scope: {
      shift: '=',
      onClose: '&?',
      onCommentsCountChange: '&',
      holderClass: '@',
      isOnMyTimeline: '<',
      editable: '<',
      withoutCommentRead: '<'
    }
  };
});
