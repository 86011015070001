/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'downloadService'];

const PopupEmployeeLicenseViewController = function($scope, downloadService) {

  const vm = this;

  vm.editLicense = () => {
    $scope.popupCtrl.closePopup(function () {
      $scope.popupCloseCb();
      $scope.editCb({ license: vm.license });
    });
  };

  vm.removeLicense = () => {
    $scope.removeCb({
      license: vm.license, onSuccessCb() {
        $scope.popupCtrl.closePopup();
      }
    });
  };

  vm.downloadLicense = function() {
    downloadService.downloadWithoutCache(vm.license.file, vm.license.fileName);
  };

  $scope.$on('popup.open', () => {
    return vm.license = $scope.license;
  });

  return vm;

};

angular.module('popup.employee').controller('PopupEmployeeLicenseViewController',
  dependencies.concat(PopupEmployeeLicenseViewController));
