/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'EmployeeCharacterReference'];

const EmployeeRegistrationPaymentDetailsController = function($scope, EmployeeCharacterReference) {

  const vm = this;

  $scope.employeeRegistrationLayoutCtrl.loadEmployee().then(function(employee) {
    if (!employee.characterReference) { employee.characterReference = new EmployeeCharacterReference(); }
    if (!employee.characterReference.knownFor) { employee.characterReference.knownFor = 1; }
    return vm.employee = employee;
  });

  vm.increaseKnownFor = value => vm.employee.characterReference.knownFor = Math.max(0, vm.employee.characterReference.knownFor + value);

  $scope.$on('employee.registration.submit', () =>
    vm.employee.save().then(function() {
      $scope.employeeRegistrationLayoutCtrl.updateEmployee(vm.employee);
      return $scope.employeeRegistrationLayoutCtrl.goNext();
    })
  );

  return vm;
};

angular
  .module('public.security-manager.employee.registration')
  .controller('EmployeeRegistrationPaymentDetailsController',
    dependencies.concat(EmployeeRegistrationPaymentDetailsController));
