import './styles.sass';

const gccsPopupRepeatableBookingMessage = {
  controller: 'PopupRepeatableBookingMessageController',
  require: {
    gccsPopupsLayout: '^gccsPopupsLayout'
  },
  template: require('./template.html'),
  bindings: {
    repeatableBooking: '<'
  }
};

angular.module('public.security-manager').component(
    'gccsPopupRepeatableBookingMessage',
    gccsPopupRepeatableBookingMessage
);
