/*
 * decaffeinate suggestions:
 * DS201: Simplify complex destructure assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('gccs-ui').service('selectService', function() {

  const getTitle = function(item, titleField = 'name', titleCb) {
    if (titleCb) {
      return titleCb(item);
    } else {
      return item[titleField];
    }
  };

  const setHeadCategory = function(selectArray, opts) {
    const { entityName } = opts;
    selectArray.push({
      category: `${entityName} type`,
      items: getHeadCategoryItems(opts)
    });
  };

  const setCategories = function(collection, selectArray, opts) {
    const {
      groups,
      additionalText,
      entityName
    } = opts;
    groups.forEach((group) => {
      selectArray.push({
        category: `${group.name} ${entityName}s ${additionalText}`,
        items: getCategoryItems(collection, group, opts)
      });
    });
  };

  const getHeadCategoryItems = function(opts) {
    const {
      defaultField,
      groups,
      entityName
    } = opts;
    const categoryItems = [];
    if (defaultField) {
      categoryItems.push({
        id : 0,
        text : `${defaultField} ${entityName}s`
      });
    }
    groups.forEach((group) => {
      categoryItems.push({
        id: group.id,
        text: `${group.name} ${entityName}s`
      });
    });
    return categoryItems;
  };

  const getCategoryItems = function(collection, group, opts) {
    const {
      titleField,
      titleCb
    } = opts;
    const categoryItems = [];

    collection.forEach((item) => {
      if (item.status === -(group.id)) {
        categoryItems.push({
          id : item['id'],
          text : getTitle(item, titleField, titleCb)
        });
      }
    });

    return categoryItems;
  };

  return {
    toSelectData(collection, ...rest) {
      const val = rest[0],
        obj = val != null ? val : {},
        val1 = obj.keyField,
        keyField = val1 != null ? val1 : 'id',
        {
          titleField,
          titleCb,
          defaultField
        } = obj;
      const selectData = {};
      if (defaultField) { selectData[0] = defaultField; }
      _.each(collection, item => selectData[item[keyField]] = getTitle(item, titleField, titleCb));
      return selectData;
    },

    toSelectArray(collection, ...rest) {
      const val = rest[0],
        obj = val != null ? val : {},
        val1 = obj.keyField,
        keyField = val1 != null ? val1 : 'id',
        { titleField,
        titleCb,
        defaultField } = obj;
      const selectArray = [];
      if (defaultField) {
        selectArray.push({
          id : 0,
          text : defaultField
        });
      }
      _.each(collection, item =>
        selectArray.push({
          id : item[keyField],
          text : getTitle(item, titleField, titleCb)
        })
      );
      return selectArray;
    },

    toSelectGroupedArray(collection, opts = {}) {
      const selectArray = [];
      setHeadCategory(selectArray, opts);
      setCategories(collection, selectArray, opts);
      return selectArray;
    }

  };
});
