/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$state', 'employeeType', 'currentUserService', 'packageTypeService', 'redirectService'];

const InvoicesController = function($state, employeeType, currentUserService, packageTypeService, redirectService) {

  const vm = this;

  vm.$onInit = function() {
    packageTypeService.checkProPackage();
    const profile = currentUserService.getCurrentProfile();
    if (profile.isSupervisor() || (profile.isManager() && !profile.hasAccessToFinances)) {
      redirectService.redirectToMain();
    }
    vm.editable = true;
    initTabs();
  };

  vm.isActiveState = function (tab) {
    return $state.includes(tab.sref, {
      employmentType: (tab.srefParams != null ? tab.srefParams.employmentType : undefined) || {}
    });
  };

  const initTabs = function() {
    vm.tabs = [];
    if (packageTypeService.hasProPackage()) {
      vm.tabs.push({
        name: 'Client invoices',
        sref: 'public.security-manager.invoices.clients'
      });
    }
    vm.tabs.push({
      name: 'Contractor invoices',
      sref: 'public.security-manager.invoices.employees',
      srefParams: {
        employmentType: employeeType.getSelfEmployedStatus()
      }
    }, {
      name: 'Staff payslips',
      sref: 'public.security-manager.invoices.employees',
      srefParams: {
        employmentType: employeeType.getEmployedStatus()
      }
    });
    if (currentUserService.isSecurityManagerLogged() && (
      currentUserService.getCurrentProfile().isAccountOwner() ||
      currentUserService.getCurrentProfile().isAdmin()
    )) {
      vm.tabs.push({
        name: 'Payroll',
        sref: 'public.security-manager.invoices.payrolls'
      });
    }
  };


  return vm;
};

angular.module('public.security-manager.invoices')
  .controller('InvoicesController', dependencies.concat(InvoicesController));
