/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = ['$stateParams', '$scope', 'EmployeeTimesheet', 'dateService', '$filter'];

const EmployeeShowTimesheetController = function($stateParams, $scope, EmployeeTimesheet, dateService, $filter) {

  const vm = this;

  vm.today = dateService.today();
  vm.from = vm.today.clone();
  vm.to = vm.from.clone().add(1, 'month');
  vm.columns = {
    number : {
      label : '№'
    },
    date : {
      label : 'Date',
      sortableField : true
    },
    client : {
      label : 'Client'
    },
    service : {
      label : 'Service'
    },
    resource : {
      label : 'Resource'
    },
    time : {
      label : 'Start & Finish time'
    },
    paid : {
      label : 'Paid hours'
    },
    payRate : {
      label : 'Pay rate'
    },
    total : {
      label : 'Total pay'
    }
  };

  const timeFormat = dateService.getTimeFormat();
  const dateFormat = dateService.getDateFormat();

  vm.loadData = function() {
    const from = moment(vm.from, dateFormat).format(dateService.getIsoDateFormat());
    const to = moment(vm.to, dateFormat).format(dateService.getIsoDateFormat());
    return EmployeeTimesheet.query({ from, to }, { employeeId: $stateParams.employeeId }).then(function(data) {
      vm.timesheetEntries = prepareData(data);
      return vm.total = getTotal(data);
    });
  };

  vm.getExportUrl = function() {
    const from = moment(vm.from, dateFormat).format(dateService.getIsoDateFormat());
    const to = moment(vm.to, dateFormat).format(dateService.getIsoDateFormat());
    return EmployeeTimesheet.exportUrl($stateParams.employeeId, from, to);
  };

  vm.toggleSort = function(columnData) {
    if (columnData.sortableField !== undefined) {
      return columnData.sortableField = !columnData.sortableField;
    }
  };

  vm.getColumnClassNames = function(columnData) {
    if (columnData.sortableField !== undefined) {
      return {
        'mod-sortable' : true,
        'mod-sortable-bottom' : columnData.sortableField,
        'mod-sortable-top' : !columnData.sortableField
      };
    }
  };

  const startAndFinishDate = (startTime, endTime) => {
    return dateService.formatDateTime(startTime, timeFormat) + ' – ' + dateService.formatDateTime(endTime, timeFormat);
  };

  const getTotal = data => {
    return _.reduce(data, (total, entry) => entry.totalPay + total, 0);
  };

  const formTimesheetEntry = entryData =>
    ({
      rawDate : entryData.startTime,
      date : dateService.formatDateTime(entryData.startTime, dateFormat),
      client : entryData.clientName,
      service : entryData.serviceName,
      resource : entryData.resourceName,
      time : startAndFinishDate(entryData.startTime, entryData.endTime),
      paid : entryData.paidHours,
      payRate : `${$filter('formatPriceFull')(entryData.payRate)}`,
      total : `${$filter('formatPriceFull')(entryData.totalPay)}`
    })
  ;

  var prepareData = function(data) {
    let number = 1;
    const timesheetEntries = _.map(data, formTimesheetEntry);
    _.each(timesheetEntries, entry => entry.number = `${number++}.`);
    return timesheetEntries;
  };

  vm.loadData();

  return vm;
};

angular
  .module('public.security-manager.employee')
  .controller('EmployeeShowTimesheetController', dependencies.concat(EmployeeShowTimesheetController));
