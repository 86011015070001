/*
 * @author Igor Serpak <robobee>
 */
angular.module('data.employee-invoice').factory('EmployeeInvoice', ['AppModel', function(AppModel) {

  class EmployeeInvoice extends AppModel {
    static initClass() {
      this.configure({ url: '/employee_invoices', name: 'employee_invoice' });
    }

    static all(params = {}) {
      return this.$get(this.resourceUrl('all'), params);
    }

    static getNew(params = {}) {
      return this.$get(this.resourceUrl('new'), params);
    }

    static bulkStatusChange(params = {}) {
      return this.$put(this.resourceUrl('versions/bulk_status_change'), params);
    }

    static destroyOpenedInvoices() {
      return this.$delete(this.resourceUrl('versions/opened_invoices'));
    }

    static destroyClosedPayslips() {
      return this.$delete(this.resourceUrl('versions/closed_payslips'));
    }

    static closeOpenedInvoices(params) {
      return this.$put(this.resourceUrl('versions/opened_invoices'), params);
    }

    static generatePayslips(params) {
      return this.$post(this.resourceUrl('versions/payslips'), params);
    }

    static generatePayslipsWithAdditionalDays(params) {
      return this.$post(this.resourceUrl('versions/payslips/additional-days'), params);
    }

  }

  EmployeeInvoice.initClass();

  return EmployeeInvoice;

}]);
