/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['resourceIcon'];

const IconController = function(resourceIcon) {

  const vm = this;

  vm.$onInit = function () {
    vm.iconServiceView = vm.iconService || resourceIcon;
  };

  return vm;

};

angular.module('public.security-manager').controller('IconController', dependencies.concat(IconController));
