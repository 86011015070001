/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'FormValidator', 'dateService', 'bookingShiftValidationService', 'payRateVisibilityService'
];

const PopupBookingShiftFormEmployeesSplitController = function(
  $scope, FormValidator, dateService, bookingShiftValidationService, payRateVisibilityService
) {

  const vm = this;

  const TIME_FIELD_START = 'start';
  const TIME_FIELD_END = 'end';

  let isSending = false;

  vm.getStartTimeErrors = function () {
    return bookingShiftValidationService.getStartEndTimeErrors(TIME_FIELD_START);
  };

  vm.getEndTimeErrors = function () {
    return bookingShiftValidationService.getStartEndTimeErrors(TIME_FIELD_END);
  };

  vm.updateEmployeeShift = function() {
    if (!$scope.EmployeeShiftSplitForm.$valid || isSending) { return; }
    isSending = true;
    buildTime(TIME_FIELD_START);
    buildTime(TIME_FIELD_END);
    vm.shiftEmployee.save().then(function() {
      resetForm();
      angular.copy(vm.shiftEmployee, $scope.shiftEmployee);
      $scope.closeCb();
    }, resetForm);
  };

  vm.close = function() {
    reset();
    $scope.closeCb();
  };

  vm.canSeeRates = function () {
    return payRateVisibilityService.isShownRates();
  };

  vm.canChangeChargeRate = function () {
    return vm.shiftEmployee.splitForId === null;
  };

  vm.canSeeChargeRate = function () {
    return payRateVisibilityService.isShownChargeRate(vm.shiftEmployee.chargeRate);
  };

  vm.canSeePayRate = function () {
    return payRateVisibilityService.isShownPayRate(vm.shiftEmployee.contractorPayRate, vm.shiftEmployee.employedPayRate);
  };

  vm.isEmployeeAssigned = function() {
    return !!vm.shiftEmployee.employeeId;
  };

  const buildTime = function(field) {
    const timeParts = vm.shiftEmployee[`${field}Time`].split(' ');
    timeParts[0] = vm.shiftEmployee[`${field}Date`];
    timeParts[1] = vm.shiftEmployee[`${field}DayTime`];
    vm.shiftEmployee[`${field}Time`] = timeParts.join(' ');
  };

  const splitTime = function(field) {
    const time = moment(vm.shiftEmployee[`${field}Time`], dateService.getIsoFullDateFormat());
    vm.shiftEmployee[`${field}Date`] = time.format(dateService.getDateFormat());
    vm.shiftEmployee[`${field}DayTime`] = time.format(dateService.getTimeFormat());
  };

  const resetForm = function() {
    $scope.EmployeeShiftSplitForm.$setPristine();
    $scope.EmployeeShiftSplitForm.$setUntouched();
    isSending = false;
  };

  const reset = function() {
    resetForm();
    vm.shiftEmployee = angular.copy($scope.shiftEmployee);
    splitTime(TIME_FIELD_START);
    splitTime(TIME_FIELD_END);
  };

  $scope.$watch('shiftEmployee', reset);

  return vm;

};

angular.module('popup.booking').controller('PopupBookingShiftFormEmployeesSplitController',
  dependencies.concat(PopupBookingShiftFormEmployeesSplitController));
