const dependencies = [
  'BookingShiftEmployeeRoot', 'dateService', 'bookingShiftClockInErrorsService', 'clockInHintService'
];

const ManagerClockInProgressController = function(
  BookingShiftEmployeeRoot, dateService, bookingShiftClockInErrorsService, clockInHintService
) {

  const vm = this;

  vm.clockInErrorsService = bookingShiftClockInErrorsService;
  vm.isShownStartClockInTooltip = true;
  vm.clockInHintService = clockInHintService;
  vm.isHoveredClockInIcon = false;
  vm.isHoveredClockOutIcon = false;

  vm.onButtonMouseEnter = function(isHovered) {
    vm[isHovered] = true;
  };

  vm.onButtonMouseLeave = function(isHovered) {
    vm[isHovered] = false;
  };

  vm.canClockIn = function() {
    const now = moment();
    const startTime = moment(vm.shiftEmployee.startTime, dateService.getIsoFullDateFormat());
    const endTime = moment(vm.shiftEmployee.endTime, dateService.getIsoFullDateFormat());
    return !vm.shiftEmployee.clockInTime && now.isSameOrAfter(startTime.add(-15, 'minutes')) && now.isBefore(endTime);
  };

  vm.isShiftStarted = function() {
    return moment().isSameOrAfter(moment(vm.shiftEmployee.startTime, dateService.getIsoFullDateFormat()));
  };

  vm.isShiftEnded = function() {
    return moment().isAfter(moment(vm.shiftEmployee.endTime, dateService.getIsoFullDateFormat()));
  };

  vm.isMissedClockIn = function() {
    return vm.isShiftEnded() && !vm.shiftEmployee.clockInTime;
  };

  vm.isShiftInProgress = function() {
    return !vm.shiftEmployee.clockOutTime && vm.isShiftStarted() && !vm.isShiftEnded();
  };

  vm.isShownShiftEndText = function() {
    return vm.isShiftEnded() && !vm.shiftEmployee.clockOutTime;
  };

  vm.getTimeTillShiftEnd = function() {
    const diff = moment(vm.shiftEmployee.endTime, dateService.getIsoFullDateFormat()).diff(moment(), 'minutes');
    if (diff >= 60) {
      return `${Math.ceil(diff / 60)}h`
    } else {
      return `${diff}m`;
    }
  };

  vm.canEdit = function() {
    return vm.shiftEmployee.clockInTime || vm.isMissedClockIn();
  };

  vm.isShownClockOut = function() {
    return vm.shiftEmployee.clockInTime && !vm.shiftEmployee.clockOutTime;
  };

  vm.isCorrectClockOutAvailable = function() {
    const now = moment();
    const endTime = moment(vm.shiftEmployee.endTime, dateService.getIsoFullDateFormat());
    const diffInMinutes = endTime.diff(now, 'minutes');
    return diffInMinutes >= 0 && diffInMinutes < 5;
  };

  vm.isShownClockPostInfo = function() {
    return vm.shiftEmployee.clockInTime && vm.shiftEmployee.clockOutTime;
  };

  vm.getProgressValue = function() {
    if (!vm.isShiftStarted()) {
      return 0;
    }
    const progressCriteria = vm.shiftEmployee.clockOutTime ? moment(vm.shiftEmployee.clockOutTime, dateService.getIsoFullDateFormat()) : moment();
    const startTime = moment(vm.shiftEmployee.startTime, dateService.getIsoFullDateFormat());
    const endTime = moment(vm.shiftEmployee.endTime, dateService.getIsoFullDateFormat());
    const shiftLengthInMinutes = endTime.diff(startTime, 'minutes');
    const shiftProgressInMinutes = progressCriteria.diff(startTime, 'minutes');
    const progressValue = +(shiftProgressInMinutes / shiftLengthInMinutes * 100).toFixed(2);
    return Math.min(progressValue, 100);
  };

  vm.getProgressBarClass = function() {
    let classes = '';
    if (!vm.isShiftStarted()) {
      classes = 'progress-bar--border';
    } else if (!vm.shiftEmployee.clockInTime) {
      classes = 'progress-bar--red';
    } else if (vm.isShownClockOut()) {
      classes = 'progress-bar--green';
    }
    if (vm.getProgressValue() === 100) {
      classes += ' progress-bar--finished';
    }
    return classes;
  };

  vm.isShownProgressTick = function() {
    return vm.getProgressValue() > 0 && vm.getProgressValue() < 100;
  };

  vm.getFormattedClockTime = function(time) {
    return moment(time, dateService.getIsoFullDateFormat()).format(dateService.getTimeFormat());
  };

  vm.getClockInIcon = function(isHovered) {
    if (isHovered) {
      return '#clock-in-start-filled';
    } else {
      return '#clock-in-start';
    }
  };

  vm.getClockOutIcon = function(isHovered) {
    if (isHovered) {
      return '#clock-in-stop-filled';
    } else {
      return '#clock-in-stop';
    }
  };

  return vm;

};

angular.module('shared-components.popup.booking')
  .controller('ManagerClockInProgressController', dependencies.concat(ManagerClockInProgressController));
