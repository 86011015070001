const expirationNotificationType = function() {

  const PASSED = 1;
  const EXPIRES_IN_DAY = 2;
  const EXPIRES_IN_WEEK = 3;
  const EXPIRES_IN_MONTH = 4;
  const EXPIRES_IN_2_MONTHS = 5;
  const EXPIRES_IN_3_MONTHs = 6;

  return { getPassed() { return PASSED; } };

};

angular.module('public.security-manager.notifications')
  .service('expirationNotificationType', expirationNotificationType);
