/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', '$state', '$q', 'Client', 'localStorageService', 'currentUserService'];

const ManagerClientLayoutController = function($scope, $state, $q, Client, localStorageService, currentUserService) {

  const vm = this;

  vm.clientId = currentUserService.getCurrentProfile().clientId;
  vm.isShownEditPopup = false;
  vm.tabs = [{
    name: 'DETAILS',
    sref: 'public.client-manager.client.details'
  }, {
    name: 'SERVICES',
    sref: 'public.client-manager.client.services'
  }, {
    name: 'SITES',
    sref: 'public.client-manager.client.sites'
  }, {
    name: 'Booking templates',
    sref: 'public.client-manager.client.booking-templates'
  }, {
    name: 'Employees',
    sref: 'public.client-manager.client.employees'
  }, {
    name: 'Notes',
    sref: 'public.client-manager.client.notes'
  }];

  let clientPromise = undefined;

  vm.showEditPopup = () => vm.isShownEditPopup = true;

  vm.closeEditPopup = function(client) {
    vm.isShownEditPopup = false;
    if (client) {
      angular.copy(client, vm.client);
      return triggerUpdate();
    }
  };

  vm.canEditProfile = () => {
    return currentUserService.isClientManagerLogged() && !currentUserService.getCurrentProfile().isViewOnly();
  };

  vm.loadClient = function() {
    if (vm.client) {
      return $q.when(vm.client);
    } else {
      return clientPromise || (clientPromise = Client.get(vm.clientId).then(function(client) {
        vm.client = client;
        if (localStorageService.get('client.popup.edit.open')) {
          vm.isShownEditPopup = true;
          localStorageService.remove('client.popup.edit.open');
        }
        triggerUpdate();
        return $q.when(vm.client);
      }));
    }
  };

  const triggerUpdate = () => $scope.$broadcast('manager.client.update', vm.client);

  vm.loadClient();

  return vm;
};

angular.module('public.client-manager.client').controller('ManagerClientLayoutController',
  dependencies.concat(ManagerClientLayoutController));
