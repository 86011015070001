/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.employee').service('employeeLicenseState', function() {

  const STATUS_ACTIVE = 1;
  const STATUS_INACTIVE = 2;

  const names = {};
  names[STATUS_ACTIVE] = 'Active';
  names[STATUS_INACTIVE] = 'Inactive';

  const filterNames = {};
  filterNames[0] = 'All';
  filterNames[STATUS_ACTIVE] = 'Active Only';
  filterNames[STATUS_INACTIVE] = 'Inactive Only';

  return {
    getNames() {
      return names;
    },

    getFilterNames() {
      return filterNames;
    },

    isActive(status) {
      return status === STATUS_ACTIVE;
    },

    isInactive(status) {
      return status === STATUS_INACTIVE;
    }
  };
});
