const dependencies = ['dateService'];

const EmployeeTimelineAvailabilityDecorator = (dateService) => {

  const ISO_FULL_DATE_FORMAT = dateService.getIsoFullDateFormat();

  return class AvailabilityDecorator {

    constructor(params) {
      this.id = params.id;
      this.startTime = params.startTime;
      this.endTime = params.endTime;
      this.startTimeMoment = params.startTimeMoment;
      this.endTimeMoment = params.endTimeMoment;
      this.timelineStartTime = this.getStartTimeMoment();
      this.timelineEndTime = this.getEndTimeMoment();
      this.employeeId = params.employeeId;
      this.employee = params.employee;
    }

    getStartTimeMoment() {
      return this.startTimeMoment || moment(this.startTime, ISO_FULL_DATE_FORMAT);
    }

    getEndTimeMoment() {
      return this.endTimeMoment || moment(this.endTime, ISO_FULL_DATE_FORMAT);
    }

    getStartTime() {
      return this.startTime || this.getStartTimeMoment().format(ISO_FULL_DATE_FORMAT);
    }

    getEndTime() {
      return this.endTime || this.getEndTimeMoment().format(ISO_FULL_DATE_FORMAT);
    }

  }

};

angular.module('public.security-manager.schedule-manager.timeline')
  .factory('EmployeeTimelineAvailabilityDecorator', dependencies.concat(EmployeeTimelineAvailabilityDecorator));
