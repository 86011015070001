angular.module('data.employee').service('employeeDetailsField', function() {

  const USER_PHONE_NUMBER = 1;
  const BIRTH_DATE = 2;
  const BANK_ACCOUNT_NUMBER = 3;
  const SORT_CODE = 4;
  const NATIONAL_INSURANCE_NUMBER = 5;
  const UNIQUE_TAX_REFERENCE_NUMBER = 6;
  const NEXT_OF_KIN_NAME = 7;
  const NEXT_OF_KIN_PHONE_NUMBER = 8;
  const EMPLOYMENT_START_DATE = 9;
  const JOB_TITLE = 10;
  const CHARACTER_REFERENCE_NAME = 11;
  const CHARACTER_REFERENCE_EMAIL = 12;
  const CHARACTER_REFERENCE_PHONE_NUMBER = 13;
  const CHARACTER_REFERENCE_ADDRESS = 14;
  const CHARACTER_REFERENCE_KNOWN_FOR = 15;
  const TAX_DETAILS_TAX_CODE = 16;
  const TAX_DETAILS_IS_DIRECTOR = 17;
  const TAX_DETAILS_IS_DISABLED = 18;
  const TAX_DETAILS_IS_NON_UK_WORKER = 19;

  const PERSONAL_DETAILS = [
    USER_PHONE_NUMBER, BIRTH_DATE
  ];

  const PAYMENT_DETAILS = [
    BANK_ACCOUNT_NUMBER, SORT_CODE, NATIONAL_INSURANCE_NUMBER, UNIQUE_TAX_REFERENCE_NUMBER
  ];

  const NEXT_OF_KIN = [
    NEXT_OF_KIN_NAME, NEXT_OF_KIN_PHONE_NUMBER
  ];

  const EMPLOYMENT_DETAILS = [
    EMPLOYMENT_START_DATE, JOB_TITLE
  ];

  const CHARACTER_REFERENCE = [
    CHARACTER_REFERENCE_NAME, CHARACTER_REFERENCE_EMAIL, CHARACTER_REFERENCE_PHONE_NUMBER,
    CHARACTER_REFERENCE_ADDRESS, CHARACTER_REFERENCE_KNOWN_FOR
  ];

  const TAX_DETAILS = [
    TAX_DETAILS_TAX_CODE, UNIQUE_TAX_REFERENCE_NUMBER, TAX_DETAILS_IS_DIRECTOR, TAX_DETAILS_IS_DISABLED, TAX_DETAILS_IS_NON_UK_WORKER
  ];

  const names = {
    [USER_PHONE_NUMBER]: 'Contact number',
    [BIRTH_DATE]: 'Date of birth',
    [BANK_ACCOUNT_NUMBER]: 'Bank account №',
    [SORT_CODE]: 'Sort code',
    [NATIONAL_INSURANCE_NUMBER]: 'National insurance №',
    [UNIQUE_TAX_REFERENCE_NUMBER]: 'Unique tax reference №',
    [NEXT_OF_KIN_NAME]: 'Name',
    [NEXT_OF_KIN_PHONE_NUMBER]: 'Contact number',
    [EMPLOYMENT_START_DATE]: 'Empl. start date',
    [JOB_TITLE]: 'Job title',
    [CHARACTER_REFERENCE_NAME]: 'Name',
    [CHARACTER_REFERENCE_EMAIL]: 'Email',
    [CHARACTER_REFERENCE_PHONE_NUMBER]: 'Contact number',
    [CHARACTER_REFERENCE_ADDRESS]: 'Address',
    [CHARACTER_REFERENCE_KNOWN_FOR]: 'Known for',
    [TAX_DETAILS_TAX_CODE]: 'Tax code',
    [TAX_DETAILS_IS_DIRECTOR]: 'Is director',
    [TAX_DETAILS_IS_DISABLED]: 'Is disabled',
    [TAX_DETAILS_IS_NON_UK_WORKER]: 'Is non UK employee'    
  };

  const paramNames = {
    [USER_PHONE_NUMBER]: 'contact_number',
    [BIRTH_DATE]: 'birth_date',
    [BANK_ACCOUNT_NUMBER]: 'bank_account_number',
    [SORT_CODE]: 'sort_code',
    [NATIONAL_INSURANCE_NUMBER]: 'national_insurance_number',
    [UNIQUE_TAX_REFERENCE_NUMBER]: 'unique_tax_reference_number',
    [NEXT_OF_KIN_NAME]: 'next_of_kin_name',
    [NEXT_OF_KIN_PHONE_NUMBER]: 'next_of_kin_phone_number',
    [EMPLOYMENT_START_DATE]: 'employment_start_date',
    [JOB_TITLE]: 'job_title',
    [CHARACTER_REFERENCE_NAME]: 'name',
    [CHARACTER_REFERENCE_EMAIL]: 'email',
    [CHARACTER_REFERENCE_PHONE_NUMBER]: 'phone_number',
    [CHARACTER_REFERENCE_ADDRESS]: 'address_id',
    [CHARACTER_REFERENCE_KNOWN_FOR]: 'known_for',
    [TAX_DETAILS_TAX_CODE]: 'tax_code',
    [TAX_DETAILS_IS_DIRECTOR]: 'is_director',
    [TAX_DETAILS_IS_DISABLED]: 'is_disabled',
    [TAX_DETAILS_IS_NON_UK_WORKER]: 'is_non_uk_worker',
  };

  const PERSONAL_DETAILS_ID = 1;
  const PAYMENT_DETAILS_ID = 2;
  const NEXT_OF_KIN_ID = 3;
  const EMPLOYMENT_DETAILS_ID = 4;
  const CHARACTER_REFERENCE_ID = 5;
  const TAX_DETAILS_ID = 6;

  const categories = {
    [PERSONAL_DETAILS_ID]: PERSONAL_DETAILS,
    [PAYMENT_DETAILS_ID]: PAYMENT_DETAILS,
    [NEXT_OF_KIN_ID]: NEXT_OF_KIN,
    [EMPLOYMENT_DETAILS_ID]: EMPLOYMENT_DETAILS,
    [CHARACTER_REFERENCE_ID]: CHARACTER_REFERENCE,
    [TAX_DETAILS_ID]: TAX_DETAILS
  };

  const categoryNames = {
    [PERSONAL_DETAILS_ID]: 'Personal details',
    [PAYMENT_DETAILS_ID]: 'Payment details',
    [NEXT_OF_KIN_ID]: 'Next of kin',
    [EMPLOYMENT_DETAILS_ID]: 'Employment Details',
    [CHARACTER_REFERENCE_ID]: 'Character Reference',
    [TAX_DETAILS_ID]: 'Tax Details'
  };

  const getKeyByValue = function(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  };

  return {
    getNames() {
      return names;
    },

    getName(field) {
      return names[field];
    },

    getCategories() {
      return categories;
    },

    getCategoryName(field) {
      return categoryNames[field];
    },

    getParamNames() {
      return paramNames;
    },

    getParamName(field) {
      return paramNames[field];
    },

    getNameByFieldName(field) {
      return this.getName(getKeyByValue(paramNames, field));
    },

    getCategoryByFieldName(field) {
      return this.getCategoryName(_.findKey(categories, (array) => {
        return array.includes(+getKeyByValue(paramNames, field));
      }));
    },

    getPersonalDetails() {
      return PERSONAL_DETAILS;
    },

    getPaymentDetails() {
      return PAYMENT_DETAILS;
    },

    getNextOfKin() {
      return NEXT_OF_KIN;
    },

    getEmploymentDetails() {
      return EMPLOYMENT_DETAILS;
    },

    getTaxDetails() {
      return TAX_DETAILS;
    },

    getCharacterReference() {
      return CHARACTER_REFERENCE;
    },

    isCharacterReference(field) {
      return CHARACTER_REFERENCE.includes(field);
    },

    getNamesCount() {
      return _.size(names);
    }
  };
});
