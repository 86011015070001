const dependencies = ['Client', 'ExtendedFilterService'];

const PlanningSearchController = function(Client, ExtendedFilterService) {

  const vm = this;

  vm.filterString = '';
  vm.selectedOptions = [];

  vm.$onInit = () => {
    Client.filterCategories().then(filterData => {
      vm.service = new ExtendedFilterService(filterData, function(queryParams) {
        vm.applyFilterCb(queryParams);
        updateSelectedOptions();
      }, false);
    });
  };

  vm.$onChanges = function(changes) {
    if (changes.filterData && !changes.filterData.isFirstChange()) {
      const filterData = changes.filterData.currentValue;
      vm.service.setOption('Client', filterData.client ? filterData.client.id : undefined);
      vm.service.setOption('Choose a service', filterData.service ? filterData.service.id : undefined);
      vm.service.setOption('Choose sites', filterData.site ? filterData.site.id : undefined);
      vm.service.setOption('Choose locations', filterData.location ? filterData.location.id : undefined);
    }
  };

  vm.deselectOptionAndApply = function(option) {
    vm.service.deselectOption(option.category, option);
    vm.service.applyFilter();
  };

  const updateSelectedOptions = function() {
    let selectedOptions = [];
    for (let category of Array.from(vm.service.categories)) {
      const categorySelectedOptions = _.map(category.getSelectedOptions(), function(selectedOption) {
        selectedOption.category = category;
        return selectedOption;
      });
      selectedOptions = selectedOptions.concat(categorySelectedOptions);
    }
    return vm.selectedOptions = selectedOptions;
  };

  return vm;

};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .controller('PlanningSearchController', dependencies.concat(PlanningSearchController));
