/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.document').directive('gccsPopupDocumentView', ['popupDirectiveConfig', popupDirectiveConfig =>
  popupDirectiveConfig.configure({
    bodyTemplate: require('./template.pug'),
    scope: {
      document: '=',
      editCb: '&',
      removeCb: '&',
      withoutEdit: '<',
      withoutRemove: '<'
    }
  })
]);
