/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['$sce'];

const ExtendedListFilterCategoryController = function($sce) {

  const vm = this;
  vm.sce = $sce;

  return vm;

};

angular.module('shared-components.extended-filters')
  .controller('ExtendedListFilterCategoryController', dependencies.concat(ExtendedListFilterCategoryController));