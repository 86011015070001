/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsBookingTimeLineBookingComments = {
  template: require('./template.pug'),
  bindings: {
    comments: '<',
    unreadCount: '<',
    onSeeCommentsCb: '&'
  }
};

angular.module('public.timeline').component('gccsBookingTimeLineBookingComments', gccsBookingTimeLineBookingComments);
