/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = [
  '$rootScope', 'Booking', 'BookingDecorator', 'FlexibleTimeLineInteractor', 'User', 'BookingShiftComment',
  'cableService', 'dateService', 'bookingCollapseService', 'currentUserService'
];

const FlexibleTimeLineBookingInteractor = (
  $rootScope, Booking, BookingDecorator, FlexibleTimeLineInteractor, User, BookingShiftComment,
  cableService, dateService, bookingCollapseService, currentUserService
) => {

  const SHIFT_FULL_HEIGHT = 131;
  const SHIFT_SMALL_HEIGHT = 81;
  const BOOKING_FIT_PADDING = 10;
  const COLLAPSED_SHIFT_ADDITIONAL_HEIGHT = 10;
  const BOOKING_INTERVALS = {
    top: 20,
    bottom: 30,
    height: 52
  };
  const REPEATABLE_BOOKING_INTERVALS = {
    headerHeight: 36,
    top: 10,
    bottom: 10
  };

  return class FlexibleTimeLineBookingInteractor extends FlexibleTimeLineInteractor {

    constructor($scope, timeLineLoader, scrollHolder, from, firstHourReference, hourWidth, shiftStatistics, shiftSelector) {
      super(timeLineLoader, scrollHolder, from, firstHourReference, hourWidth, shiftStatistics, shiftSelector);
      this._shiftEmployeeUpdated = this._shiftEmployeeUpdated.bind(this);
      this._subscribeToBookingTimeline($scope);
    }

    getShiftDimensions(groupedDBookingRow, dBooking, dShift, shiftRowIndex) {
      return dShift.shiftDimensions || (dShift.shiftDimensions = this._getShiftDimensions(groupedDBookingRow, dBooking, dShift, shiftRowIndex));
    }

    getBookingDimensions(dClient, bookingRowIndex, bookingIndex) {
      const { dBookings } = dClient.groupedDBookingRows[bookingRowIndex];
      const dBooking = dBookings[bookingIndex];
      return dBooking.bookingDimensions || (dBooking.bookingDimensions = this._getBookingDimensions(
        dClient,
        dBooking,
        dBookings[bookingIndex + 1],
        bookingRowIndex
      ));
    }

    getRepeatableBookingRowDimensions(dClient, bookingRowIndex, groupedDBookingRow) {
      let height = groupedDBookingRow.maxRowsCount * this._getShiftHeightForRow(groupedDBookingRow) +
        REPEATABLE_BOOKING_INTERVALS.headerHeight + REPEATABLE_BOOKING_INTERVALS.bottom +
        REPEATABLE_BOOKING_INTERVALS.top + 20;
      if (bookingCollapseService.isCollapsedRow(groupedDBookingRow)) {
        height += COLLAPSED_SHIFT_ADDITIONAL_HEIGHT;
      }
      return {
        left: 0,
        width: '100vw',
        height: `${height}px`,
        top: `${this._getBookingTop(dClient, bookingRowIndex) - REPEATABLE_BOOKING_INTERVALS.top}px`
      };
    }

    _getBookingTop(dClient, bookingRowIndex) {
      let previousHeight = 0;
      let repeatableBookingsCount = 0;
      for (let index = 0; index < dClient.groupedDBookingRows.length; index++) {
        const groupedDBookingRow = dClient.groupedDBookingRows[index];
        if (index === bookingRowIndex) {
          break;
        }
        if (groupedDBookingRow.repeatableRowFor) { repeatableBookingsCount++; }
        previousHeight += groupedDBookingRow.maxRowsCount * this._getShiftHeightForRow(groupedDBookingRow);
        if (bookingCollapseService.isCollapsedRow(groupedDBookingRow)) {
          previousHeight += COLLAPSED_SHIFT_ADDITIONAL_HEIGHT;
        }
      }
      return previousHeight + bookingRowIndex * BOOKING_INTERVALS.height + BOOKING_INTERVALS.top +
        REPEATABLE_BOOKING_INTERVALS.headerHeight * repeatableBookingsCount;
    }

    getClientDimensions(dClient) {
      if (dClient.clientDimensions) {
        return dClient.clientDimensions;
      } else {
        let height = this.getShiftRowsHeight(dClient.groupedDBookingRows) +
          dClient.groupedDBookingRows.length * BOOKING_INTERVALS.height +
          BOOKING_INTERVALS.bottom;
        for (let groupedDBookingRow of dClient.groupedDBookingRows) {
          if (groupedDBookingRow.repeatableRowFor) {
            height += REPEATABLE_BOOKING_INTERVALS.headerHeight;
          }
          if (bookingCollapseService.isCollapsedRow(groupedDBookingRow)) {
            height += COLLAPSED_SHIFT_ADDITIONAL_HEIGHT;
          }
        }
        return dClient.clientDimensions = {
          height: `${height - 20}px`
        }
      }
    }

    getShiftRowsHeight(groupedDBookingRows) {
      let height = 0;
      for (let groupedDBookingRow of Array.from(groupedDBookingRows)) {
        height += this._getShiftHeightForRow(groupedDBookingRow) * groupedDBookingRow.maxRowsCount;
      }
      return height;
    }

    _getBookingDimensions(dClient, dBooking, nextDBooking, bookingRowIndex) {
      const from = moment.max(dBooking.startTime, this.timeLineStart);
      const to = moment.min(dBooking.endTime, this.timeLineEnd);
      const lengthInHours = to.diff(from, 'hours', true);
      let width = this._getTimelineItemWidth(lengthInHours);
      if ((nextDBooking != null ? nextDBooking.startTime.diff(dBooking.endTime) : undefined) === 0) {
        width -= BOOKING_FIT_PADDING;
      }
      return {
        width: Math.max(0, width),
        top: this._getBookingTop(dClient, bookingRowIndex),
        left: this._geOffsetFromTime(dBooking.startTime) + this.timeLineConverter.getOutset()
      };
    }

    _getShiftDimensions(groupedDBookingRow, dBooking, dShift, shiftRowIndex) {
      let from;
      if (dBooking) {
        from = moment.max(dBooking.startTime, this.timeLineStart);
      } else {
        from = this.timeLineStart;
      }
      const left = this._getRawOffsetFromTime(dShift.getStartTimeMoment(), from);
      const top = shiftRowIndex * this._getShiftHeightFor(dBooking);
      return {
        width: this._getShiftWidth(dShift) + 'px',
        top: top + 'px',
        left: left + 'px'
      };
    }

    _subscribeToBookingTimeline($scope) {
      const bookingTimelineChannel = cableService.getBookingTimelineChannel();
      this._initBaseUpdates(bookingTimelineChannel);
      this._initShiftLockUpdate(bookingTimelineChannel);
      this._initFullBookingUpdate(bookingTimelineChannel);
      this._initBookingRemove(bookingTimelineChannel);
      this._initCommentUpdate(bookingTimelineChannel);
      $scope.$on('destroy', function() {
        bookingTimelineChannel.removeCallbacks();
      });
      $rootScope.$on('$stateChangeSuccess', function() {
        bookingTimelineChannel.removeCallbacks();
      });
    }

    _shiftEmployeeUpdated(shiftEmployeeData) {
      const startTime = moment(shiftEmployeeData.start_time, dateService.getIsoFullDateFormat());
      const endTime = moment(shiftEmployeeData.end_time, dateService.getIsoFullDateFormat());
      if (!(this.getFrom().diff(endTime) >= 0) && !(startTime.diff(this.getTo()) >= 0)) {
        return this.timeLineLoader.reloadShiftById(shiftEmployeeData.shift_id).then(reloadedShift => {
          if (this.shiftStatistics != null) {
            this.shiftStatistics.recalculate(true);
          }
          return (this.shiftSelector != null ? this.shiftSelector.shiftReloaded(reloadedShift) : undefined);
        });
      }
    }

    _initBaseUpdates(bookingTimelineChannel) {
      bookingTimelineChannel.addCallback('shift_employee', this._shiftEmployeeUpdated);
      bookingTimelineChannel.addCallback('shift_employee_destroyed', this._shiftEmployeeUpdated);
      bookingTimelineChannel.addCallback('booking_series', data => {
        const updatedDSeries = this.timeLineLoader.getDSeries(data.booking_series_id);
        if (updatedDSeries) {
          return this.timeLineLoader.updateShiftsForSeries(updatedDSeries, this._getFormattedFrom(), this._getFormattedTo()).then(() => {
            return (this.shiftStatistics != null ? this.shiftStatistics.recalculate(true) : undefined);
          });
        } else {
          this._addNewSeries(data);
          return (this.shiftStatistics != null ? this.shiftStatistics.recalculate(true) : undefined);
        }
      });
      bookingTimelineChannel.addCallback('client', data => {
        return this.timeLineLoader.reloadClient(data.client_id).then(() => {
          return (this.shiftStatistics != null ? this.shiftStatistics.recalculate() : undefined);
        });
      });
      bookingTimelineChannel.addCallback('booking', data => {
        return Booking.get(data.booking_id, {
          template: 'timeline',
          from: this._getFormattedFrom(),
          to: this._getFormattedTo()
        }).then(booking => {
          const foundDClient = this._findClientById(booking.clientId);
          this._updateBooking(booking);
          if (this.shiftStatistics != null) {
            this.shiftStatistics.recalculate();
          }
          if (foundDClient) {
            foundDClient.groupBookings();
            return foundDClient.clearDimensions();
          }
        }).catch(() => { });
      }); // no action required (when booking becomes empty)

      return bookingTimelineChannel.addCallback('booking_block', data => {
        const booking = __guard__(this._findBookingById(data.id), x => x.booking);
        if (booking && (booking.isBlocked !== data.is_blocked)) {
          return $rootScope.$apply(() => booking.isBlocked = data.is_blocked);
        }
      });
    }

    _initShiftLockUpdate(bookingTimelineChannel) {
      bookingTimelineChannel.addCallback('shift_lock', data => {
        const dSeries = this.timeLineLoader.getDSeries(data.booking_series_id);
        if (dSeries) {
          return $rootScope.$apply(function () {
            let user;
            if (data.user) {
              user = new User(data.user);
            } else {
              user = null;
            }
            return dSeries.series.lockedBy = user;
          });
        }
      });
      bookingTimelineChannel.addCallback('shift_unlock', data => {
        const dSeries = this.timeLineLoader.getDSeries(data.booking_series_id);
        if (dSeries) {
          return $rootScope.$apply(() => dSeries.series.lockedBy = null);
        }
      });
      bookingTimelineChannel.addCallback('booking_lock', data => {
        const dBooking = this._findBookingById(data.booking_id);
        if (dBooking) {
          $rootScope.$apply(function () {
            dBooking.booking.lockedBy = data.user ? new User(data.user) : null;
          });
        }
      });
      bookingTimelineChannel.addCallback('booking_unlock', data => {
        const dBooking = this._findBookingById(data.booking_id);
        if (dBooking) {
          $rootScope.$apply(function () {
            dBooking.booking.lockedBy = null;
          });
        }
      });
    }

    _initFullBookingUpdate(bookingTimelineChannel) {
      bookingTimelineChannel.addCallback('full_booking', data => {
        if (!this._datesFitToTimeline(data.from, data.to)) {
          return;
        }
        Booking.get(data.booking_id, {
          template: 'show_with_shifts',
          from: this._getFormattedFrom(),
          to: this._getFormattedTo()
        }).then(booking => {
          const foundDClient = this._findClientById(data.client_id);
          if (foundDClient) {
            let foundDBooking = _.find(foundDClient.dBookings, dBooking => dBooking.id === data.booking_id);
            if (foundDBooking) {
              foundDBooking.updateBooking(booking, booking.series);
              foundDBooking._decorateSeries();
            } else {
              foundDBooking = new BookingDecorator(foundDClient.id, foundDClient.logo, booking);
              foundDClient.dBookings.push(foundDBooking);
            }
            this.timeLineLoader.groupSeries(foundDBooking.dSeries);
            foundDBooking.setVisibilityFromStats(this.timeLineLoader.currentStatFilter);
            foundDBooking.groupSeries();
            if (this.shiftStatistics != null) {
              this.shiftStatistics.recalculate(true);
            }
            foundDClient.groupBookings();
            foundDClient.clearDimensions();
          } else {
            this.timeLineLoader.loadClient(this._getFormattedFrom(), this._getFormattedTo(), { clientId: data.client_id }).then(() => {
              (this.shiftStatistics != null ? this.shiftStatistics.recalculate(true) : undefined);
            });
          }
        }).catch(function() {
          // booking not found for these dates, do nothing
        });
      });
    }

    _initBookingRemove(bookingTimelineChannel) {
      bookingTimelineChannel.addCallback('removed_booking', data => {
        const dClient = this._findClientById(data.client_id);
        if (!dClient) { return; }
        $rootScope.$apply(() => {
          _.remove(dClient.dBookings, dBooking => dBooking.id === data.booking_id);
          if (this.shiftStatistics != null) {
            this.shiftStatistics.recalculate();
          }
          dClient.groupBookings();
          dClient.clearDimensions();
        });
      });
    }

    _initCommentUpdate(bookingTimelineChannel) {
      bookingTimelineChannel.addCallback('comment_shift', data => {
        const dShift = this._findShiftById(data.commentable_entity_id);
        if (!dShift) {
          return;
        }
        return BookingShiftComment.get({
          id: data.comment_id,
          shiftId: dShift.shift.id,
          bookingId: dShift.shift.bookingId
        }).then(function (comment) {
          const existing = _.find(dShift.shift.comments, c => c.id === comment.id);
          if (existing) {
            return _.merge(existing, comment);
          } else {
            if (dShift.shift.comments.length >= 3) {
              dShift.shift.comments.shift();
            }
            dShift.shift.comments.push(comment);
            if (comment.user.id !== currentUserService.getCurrentUser.id) {
              return dShift.shift.unreadCommentsCount += 1;
            }
          }
        });
      });
      bookingTimelineChannel.addCallback('comment_shift_destroy', data => {
        const dShift = this._findShiftById(data.commentable_entity_id);
        if (!dShift) {
          return;
        }
        return BookingShiftComment.query({
          limit: 3,
          'order[id]': 'desc'
        }, {
          shiftId: dShift.shift.id,
          bookingId: dShift.shift.bookingId
        }).then(comments => dShift.shift.comments = comments.reverse());
      });
    }

    _addNewSeries(data) {
      if (!this._updateBookingSeries(data)) {
        return this.loadDataAndSetView();
      }
    }

    _updateBooking(booking) {
      return __guard__(this._findBookingById(booking.id), x => x.updateBooking(booking));
    }

    _updateBookingSeries(data) {
      let bookingUpdated = false;
      const dBooking = this._findBookingById(data.booking_id);
      if (dBooking) {
        this.timeLineLoader.loadSeries(dBooking, data.booking_series_id, this._getFormattedFrom(), this._getFormattedTo()).then((shifts) => {
          _.each(shifts, function (shift) {
            $rootScope.$broadcast('timeline-shift-added', shift.id);
          });
          return (this.shiftStatistics != null ? this.shiftStatistics.recalculate() : undefined);
        });
        bookingUpdated = true;
      }
      return bookingUpdated;
    }

    _getFormattedFrom() {
      return this.getFrom().format(dateService.getIsoFullDateFormat());
    }

    _getFormattedTo() {
      return this.getTo().format(dateService.getIsoFullDateFormat());
    }

    _getShiftHeightForRow(bookingRow) {
      if (bookingCollapseService.isCollapsedRow(bookingRow)) {
        return SHIFT_SMALL_HEIGHT;
      } else {
        return SHIFT_FULL_HEIGHT;
      }
    }

    _getShiftHeightFor(booking) {
      if (bookingCollapseService.isCollapsed(booking)) {
        return SHIFT_SMALL_HEIGHT;
      } else {
        return SHIFT_FULL_HEIGHT;
      }
    }

    _findShiftById(shiftId) {
      for (let dClient of Array.from(this.timeLineLoader.clients)) {
        for (let dBooking of Array.from(dClient.dBookings)) {
          for (let dSeries of Array.from(dBooking.dSeries)) {
            for (let dShift of Array.from(dSeries.dShifts)) {
              if (dShift.id === shiftId) {
                return dShift;
              }
            }
          }
        }
      }
    }

    _findBookingById(bookingId) {
      if (!this.timeLineLoader.clients) {
        return null;
      }
      for (let dClient of Array.from(this.timeLineLoader.clients)) {
        for (let dBooking of Array.from(dClient.dBookings)) {
          if (dBooking.id === bookingId) {
            return dBooking;
          }
        }
      }
    }

    _findClientById(clientId) {
      for (let dClient of Array.from(this.timeLineLoader.clients)) {
        if (dClient.id === clientId) {
          return dClient;
        }
      }
    }

    // todo check all broadcast updates for timeline fit
    _datesFitToTimeline(startDateString, endDateString) {
      const startDate = moment(startDateString, dateService.getIsoDateFormat());
      const endDate = moment(endDateString, dateService.getIsoDateFormat());
      return startDate.isBefore(this.getTo()) && endDate.isAfter(this.getFrom());
    }

  };

};

angular.module('public.timeline').factory('FlexibleTimeLineBookingInteractor',
  dependencies.concat(FlexibleTimeLineBookingInteractor));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
