const SecurityManagerNotificationsTab = function() {

  return class SecurityManagerNotificationsTab {

    constructor(label, scope, isSelected) {
      if (isSelected == null) { isSelected = false; }
      this.label = label;
      this.scope = scope;
      this.tabTypes = scope.tabTypes;
      this.isSelected = isSelected;
    }

    setCount(counts) {
      let count = 0;
      for (let type of Array.from(this.tabTypes)) {
        const c = _.find(counts, c => c.type === type);
        if (c) {
          count += c.count;
        }
      }
      return this.count = count;
    }

    getSelectedScope() {
      if (this.isSelected) {
        return this.scope;
      } else {
        return {};
      }
    }

    select() {
      return this.isSelected = true;
    }

    deselect() {
      return this.isSelected = false;
    }
  }
  
};

angular.module('public.security-manager.notifications')
  .factory('SecurityManagerNotificationsTab', SecurityManagerNotificationsTab);
