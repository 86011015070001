/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.employee').factory('EmployeeCharacterReference', ['AppModel', function(AppModel) {

  class EmployeeCharacterReference extends AppModel {
    static initClass() {
      this.configure({url : '/employees/{{employeeId}}/character_references/{{id}}', name : 'character_reference'});
    }
  }

  EmployeeCharacterReference.initClass();
  return EmployeeCharacterReference;
}
]);