/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'ClientService', 'EntityManager', 'pluralizeService', 'activeStatus'];

const ManagerClientServicesController = function($scope, ClientService, EntityManager, pluralizeService, activeStatus) {

  const vm = this;

  vm.pluralizeService = pluralizeService;
  vm.isShownServicePopup = false;
  vm.ascendingNameOrder = true;

  let entityManager = undefined;

  $scope.managerClientLayoutCtrl.loadClient().then(function(client) {
    entityManager = new EntityManager(ClientService, {
      params : {
        clientId : client.id
      },
      queryParams : {
        activeStatus : activeStatus.getActiveStatus()
      }
    }
    );
    entityManager.loadAll();
    return vm.client = client;
  });

  vm.toggleNameOrder = () => vm.ascendingNameOrder = !vm.ascendingNameOrder;

  vm.showServicePopup = function(service) {
    vm.popupService = service;
    return vm.isShownServicePopup = true;
  };

  vm.closeServicePopup = () => vm.isShownServicePopup = false;

  vm.getServices = () => entityManager != null ? entityManager.entities : undefined;

  return vm;
};

angular.module('public.client-manager.client').controller('ManagerClientServicesController',
  dependencies.concat(ManagerClientServicesController));
