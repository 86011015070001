/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').service('payRateVisibilityService', ['currentUserService', function(currentUserService) {

  const isShownRates = function() {
    return !(currentUserService.isSecurityManagerLogged() && currentUserService.getCurrentProfile().isSupervisor());
  };

  return {
    isShownPayRate(contractorPayRate, employedPayRate = true) {
      return (contractorPayRate !== null) && (employedPayRate !== null) &&
        !currentUserService.isClientManagerLogged() &&
        isShownRates();
    },

    isShownChargeRate(chargeRate) {
      return (chargeRate !== null) && isShownRates();
    },

    isShownRates

  };

}]);
