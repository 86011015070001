/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'bookingShiftEditMode'];

const PopupBookingShiftEditController = function($scope, bookingShiftEditMode) {

  const vm = this;

  $scope.popupFormCtrl.isShortPopup = true;
  $scope.popupFormCtrl.title = 'Edit shift';

  vm.editMode = bookingShiftEditMode.getOnlyCurrentMode();
  vm.radioButtons = [{
    title: 'Only this shift',
    sign: 'All other shifts in the series will remain the same.',
    value: bookingShiftEditMode.getOnlyCurrentMode()
  }, {
    title: 'This Shift Onwards',
    sign: 'This and all following shifts in the series will be changed.',
    value: bookingShiftEditMode.getFollowingMode()
  }];

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      return vm.editMode = bookingShiftEditMode.getOnlyCurrentMode();
    }
  });

  $scope.$on('popup.close', () => $scope.editModeService.onFail());

  $scope.$on('popup.submit', function() {
    $scope.editModeService.onSuccess(vm.editMode);
    return $scope.popupCtrl.closePopup();
  });

  return vm;

};

angular.module('popup.booking')
  .controller('PopupBookingShiftEditController', dependencies.concat(PopupBookingShiftEditController));
