/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Maksym Shutiak <maxshutyak>
 */
const dependencies = ['$scope', 'employeeStatus', 'currentUserService'];

const EmployeeTimelineHeaderController = function($scope, employeeStatus, currentUserService) {

  const vm = this;

  vm.$onInit = () => vm.calendarDate = vm.interactor.from.clone();

  vm.employeeIsActive = () => employeeStatus.isActive(__guard__(currentUserService.getCurrentProfile(), x => x.status));

  vm.toggleCalendar = function() {
    if (vm.isShownCalendar) {
      return vm.hideCalendar();
    } else {
      vm.calendarDate = vm.interactor.from.clone();
      return vm.isShownCalendar = true;
    }
  };

  vm.hideCalendar = () => vm.isShownCalendar = false;

  $scope.$on('setDate', function(model, date, view, isArrowClick) {
    if ((view === 'date') && !isArrowClick) {
      vm.hideCalendar();
      return vm.interactor.scrollTo(date);
    }
  });

  return vm;
};

angular.module('public.employee.timeline').controller('EmployeeTimelineHeaderController',
  dependencies.concat(EmployeeTimelineHeaderController));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}