import './styles.css';

angular.module('shared-components.extended-filters').component('gccsExtendedListFilterOptions', {
  template: require('./template.html'),
  controller: 'ExtendedListFilterOptionsController',
  bindings: {
    service: '<',
    category: '<',
    isAccessRightsFilter: '<'
  }
});
