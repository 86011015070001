/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['dateService', 'employeeDayOffRequestStatus'];

const DayOffDecorator = (dateService, employeeDayOffRequestStatus) => {

  let WESTERN_TIME_FORMAT = dateService.getWesternTimeFormat();
  let ISO_FULL_DATE_FORMAT = dateService.getIsoFullDateFormat();

  return class DayOffDecorator {

    constructor(params) {
      this.id = params.id;
      this.name = params.name;
      this.startTime = params.startTime;
      this.endTime = params.endTime;
      this.startTimeMoment = params.startTimeMoment;
      this.endTimeMoment = params.endTimeMoment;
      this.timelineStartTime = this.getStartTimeMoment();
      this.timelineEndTime = this.getEndTimeMoment();
      this.employeeId = params.employeeId;
      this.isTemporary = params.isTemporary || false;
      this.paidDays = params.paidDays;
      this.paidHoursPerDay = params.paidHoursPerDay;
      this.paidRate = params.paidRate;
      this.status = params.status;
      this.isDayOff = true;
      this.holidaysTaken = params.holidaysTaken;
      this.holidaysRemaining = params.holidaysRemaining;
      this.employee = params.employee
    }

    getStartTimeMoment() {
      return this.startTimeMoment || moment(this.startTime, ISO_FULL_DATE_FORMAT);
    }

    getEndTimeMoment() {
      return this.endTimeMoment || moment(this.endTime, ISO_FULL_DATE_FORMAT);
    }

    getStartTime() {
      return this.startTime || this.getStartTimeMoment().format(ISO_FULL_DATE_FORMAT);
    }

    getEndTime() {
      return this.endTime || this.getEndTimeMoment().format(ISO_FULL_DATE_FORMAT);
    }

    getShiftTime() {
      return [
        moment(this.startTime, ISO_FULL_DATE_FORMAT).format(WESTERN_TIME_FORMAT),
        moment(this.endTime, ISO_FULL_DATE_FORMAT).format(WESTERN_TIME_FORMAT)
      ].join(' - ');
    }

    getShiftLengthInHours() {
      if (this.isTemporary) {
        return 24;
      } else {
        return this.getEndTimeMoment().diff(this.getStartTimeMoment(), 'hours', true);
      }
    }

    getShiftDurationMoment() {
      if (this.isTemporary) {
        return '24h';
      } else {
        return moment.duration(this.getEndTimeMoment().diff(this.getStartTimeMoment()));
      }
    }

    getHoursDuration() {
      const duration = this.getShiftDurationMoment();
      let label = `${ duration.hours() }h`;
      if (duration.minutes()) {
        label = label.concat(`${duration.minutes()}m`);
      }
      return label;
    }

    getDayOffType() {
      if (this.paidDays > 0) {
        return 'HOLIDAY';
      } else {
        return 'DAY OFF';
      }
    }

    getDayOffRequestStatus() {
      if (employeeDayOffRequestStatus.isNew(this.status)) {
        return { text: 'Request', class: 'mod-rejected' };
      } else if (employeeDayOffRequestStatus.isApproved(this.status)) {
        return { text: 'Approved', class: 'mod-approved' };
      } else {
        return { text: 'Rejected', class: 'mod-rejected' };
      }
    }

    getDayOffRequestStatusEmployeeTimeline() {
      if (employeeDayOffRequestStatus.isNew(this.status)) {
        return { text: 'On approve' };
      } else if (employeeDayOffRequestStatus.isApproved(this.status)) {
        return { text: 'Approved', class: 'mod-green' };
      }
    }
  };

};

angular.module('public.security-manager.schedule-manager.timeline')
  .factory('DayOffDecorator', dependencies.concat(DayOffDecorator));
