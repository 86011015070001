const dependencies = [
  '$state', '$stateParams', 'ClientInvoice', 'ClientService', 'pluralizeService', 'dateService'
];

const ClientShowInvoiceHistoryController = function(
  $state, $stateParams, ClientInvoice, ClientService, pluralizeService, dateService
) {

  const vm = this;

  let currentVersionIndex = 0;

  vm.invoiceVersions = [];

  vm.$onInit = function() {
    vm.isChangesMadePage = $stateParams.invoiceVersionId === 'new';
    loadInvoice();
  };

  vm.goBack = function() {
    if (window.history.length > 1) {
      window.history.back();
    } else if (vm.invoice) {
      $state.go('public.security-manager.client.invoice.list', { clientId: version.booking.clientId });
    }
  };

  vm.chooseVersion = index => currentVersionIndex = index;

  vm.getTitle = function() {
    if (vm.isChangesMadePage) {
      return 'Updates';
    } else {
      return 'Invoice history';
    }
  };

  vm.getTotalText = () => pluralizeService.simple(vm.invoice.versions.length, 'version');

  vm.isCurrentVersion = index => index === currentVersionIndex;

  vm.getFormattedCreatedAt = function(version) {
    if (!version.createdAtMoment) {
      version.createdAtMoment = moment(version.createdAt, dateService.getIsoFullDateFormatWithTz());
    }
    return version.createdAtMoment.format('MMM DD, YYYY, HH:mm');
  };

  vm.getCreatorFullName = version => [version.creator.firstName, version.creator.lastName].join(' ');

  vm.getCurrentVersion = () => vm.invoiceVersions[currentVersionIndex];

  vm.getPreviousBooking = function(forBooking) {
    const previousVersion = vm.invoiceVersions[currentVersionIndex + 1];
    if (previousVersion) {
      return _.find(previousVersion.bookings, booking => forBooking.id === booking.id);
    }
  };

  const loadInvoice = function() {
    const params = { all_versions: true };
    if (vm.isChangesMadePage) { params.with_latest_version = true; }
    ClientInvoice.get($stateParams.invoiceId, params).then(function(invoice) {
      vm.invoice = invoice;
      vm.invoiceVersions = _.orderBy(vm.invoice.versions, 'id', 'desc');
      if (!vm.isChangesMadePage) {
        currentVersionIndex = _.findIndex(vm.invoiceVersions, invoiceVersion => {
          return invoiceVersion.id === ($stateParams.invoiceVersionId - 0);
        });
      }
    });
  };

  return vm;

};

angular.module('public.security-manager.client.show.invoice')
  .controller('ClientShowInvoiceHistoryController', dependencies.concat(ClientShowInvoiceHistoryController));
