const inactivityService = function() {

  const MINUTE_DURATION = 60 * 1000;
  const EVENTS = ['mousedown', 'keypress', 'touchstart'];

  const inactivityObservers = [];

  const createObserver = function(inactivityTimeInMinutes, cb) {
    const observer = {inactivityTime : inactivityTimeInMinutes * MINUTE_DURATION, cb};
    observer.resetTimer = function() {
      clearTimeout(observer.timeoutId);
      return observer.timeoutId = setTimeout(observer.cb, observer.inactivityTime);
    };
    return observer;
  };

  return {

    callAfterInactivity(inactivityTimeInMinutes, cb) {
      const observer = createObserver(inactivityTimeInMinutes, cb);
      inactivityObservers.push(observer);
      for (let event of Array.from(EVENTS)) {
        document.addEventListener(event, observer.resetTimer, false);
      }
      observer.resetTimer();
    },

    removeInactivityObserver(cb) {
      const observer = _.find(inactivityObservers, ob => ob.cb === cb);
      if (!observer) { return; }
      for (let event of Array.from(EVENTS)) {
        document.removeEventListener(event, observer.resetTimer, false);
      }
      clearTimeout(observer.timeoutId);
      _.pull(inactivityObservers, observer);
    },

    checkActivity(onActiveCb) {
      let wasActive = false;
      for (let event of Array.from(EVENTS)) {
        document.addEventListener(event, () => wasActive = true, true);
      }
      setInterval(function() {
        if (wasActive) {
          wasActive = false;
          onActiveCb();
        }
      }, MINUTE_DURATION);
    }

  };
};

angular.module('shared').service('inactivityService', inactivityService);
