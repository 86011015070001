const dependencies = ['$state'];

const ManagerProfileNotificationsController = function($state) {

  const vm = this;

  vm.securityManagerId = $state.params.securityManagerId;

  return vm;

};

angular.module('public.security-manager.manager-profile')
  .controller('ManagerProfileNotificationsController', dependencies.concat(ManagerProfileNotificationsController));
