/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').factory('EntityManager', ['$q', 'confirmService', function($q, confirmService) {

  class EntityManager {

    constructor(entityClass, options) {
      this.loadMore = this.loadMore.bind(this);
      this.isDisabledLoading = this.isDisabledLoading.bind(this);
      this.createEntity = this.createEntity.bind(this);
      this.addNewEntity = this.addNewEntity.bind(this);
      this.addEntity = this.addEntity.bind(this);
      this.findEntity = this.findEntity.bind(this);
      this.saveEntity = this.saveEntity.bind(this);
      this.updateEntity = this.updateEntity.bind(this);
      this.removeEntity = this.removeEntity.bind(this);
      this._performRemove = this._performRemove.bind(this);
      this._removeEntity = this._removeEntity.bind(this);
      this.entityClass = entityClass;
      if (options == null) { options = {}; }
      this.options = options;
      this.entities = [];
      this.onLoadCbs = [];
      this.resetLoadData();
      if (this.options.withBlank) { this.addNewEntity(); }
    }

    clone() {
      return new this.constructor(this.entityClass, angular.copy(this.options));
    }

    addOptions(options) {
      return this.options = _.merge(options, this.options);
    }

    mergeOptions(options) {
      return this.options = _.merge(this.options, options);
    }

    loadAll(urlParams) {
      const loadCbName = this.options.loadCbName || 'query';
      return this.entityClass[loadCbName](_.extend({}, urlParams, this.options.queryParams || {}), this.options.params).then(entities => {
        let cb;
        this.entities = [];
        if ((entities.length === 0) && this.options.withBlank) { this.addNewEntity(); }
        _.each(entities, this.addEntity);
        while ((cb = this.onLoadCbs.pop())) { cb(); }
        return this.entities;
      });
    }

    onLoad(cb) {
      return this.onLoadCbs.push(cb);
    }

    load(urlParams = {}) {
      this.isLoading = true;
      this.urlParams = urlParams;
      urlParams.page = this.page;
      const loadCbName = this.options.loadCbName || 'query';
      return this.entityClass[loadCbName](_.extend({}, urlParams, this.options.queryParams), this.options.params).then(entities => {
        if (urlParams.page === 1) { this.entities = []; }
        _.each(entities, this.addEntity);
        this.isLoading = false;
        return this.endedLoading = entities.length === 0;
      });
    }

    loadOne(id, queryParams) {
      if (queryParams == null) { queryParams = {}; }
      return this.entityClass.$get(this.entityClass.resourceUrl(this.options.params) + '/' + id, queryParams).then(entity => {
        return this.updateEntity(entity);
      });
    }

    loadMore() {
      this.page++;
      return this.load(this.urlParams);
    }

    isDisabledLoading() {
      return this.endedLoading || this.isLoading;
    }

    resetLoadData() {
      this.page = 1;
      this.endedLoading = false;
      return this.isLoading = false;
    }

    saveAll() {
      const entityPromises = _.map(this.entities, entity => entity.save());
      return $q.all(entityPromises);
    }

    createEntity() {
      return this._prepareEntity(new this.entityClass());
    }

    addNewEntity() {
      return this.addEntity(new this.entityClass());
    }

    addEntity(entity) {
      const preparedEntity = this._prepareEntity(entity);
      this.entities.push(preparedEntity);
      return preparedEntity;
    }

    unshiftEntity(entity) {
      const preparedEntity = this._prepareEntity(entity);
      this.entities.unshift(preparedEntity);
      return preparedEntity;
    }

    findEntity(entityOrIdToFound) {
      const idField = this.options.idField || 'id';
      const idToFound = entityOrIdToFound[idField] || entityOrIdToFound;
      return _.find(this.entities, entity => entity[idField] === idToFound);
    }

    saveEntity(entity) {
      return entity.save().then(() => {
        return this.updateEntity(entity);
      });
    }

    updateEntity(entity) {
      const existingEntity = this.findEntity(entity);
      if (existingEntity) {
        if (!_.isEqual(entity, existingEntity)) { return angular.copy(entity, existingEntity); }
      } else {
        return this.addEntity(entity);
      }
    }

    removeEntity(entity, onSuccessCb, removeOptions, withConfirm = true) {
      if (removeOptions == null) { removeOptions = {}; }
      if (entity.isNew()) {
        this._removeEntity(entity);
        if (onSuccessCb) { return onSuccessCb(); }
      } else {
        if (withConfirm) {
          return confirmService.confirmRemove(entity.getName(), () => {
            return this._performRemove(entity, onSuccessCb, removeOptions);
          });
        } else {
          return this._performRemove(entity, onSuccessCb, removeOptions);
        }
      }
    }

    setEntities(entities) {
      _.each(entities, (entity) => {
        this.addEntity(new this.entityClass(entity));
      });
    }

    clearEntities() {
      this.entities.length = 0;
    }

    _performRemove(entity, onSuccessCb, removeOptions){
      return entity.$delete(entity.getUrl(), removeOptions).then(() => {
        this._removeEntity(entity);
        if (onSuccessCb) { return onSuccessCb(); }
      });
    }

    _removeEntity(entityToRemove) {
      _.remove(this.entities, function(entity) {
        return entity === entityToRemove;
      })
    }

    _prepareEntity(entity) {
      let prepareObject;
      if (this.options.prepare) {
        if (_.isFunction(this.options.prepare)) {
          prepareObject = this.options.prepare(entity);
        } else {
          prepareObject = this.options.prepare;
        }
      } else {
        prepareObject = {};
      }
      return _.defaults(entity, this.options.params, prepareObject);
    }
  }

  return EntityManager;

}]);
