const dependencies = [
  '$filter', 'SecurityProviderBillingHistoryItem', 'SecurityProviderBillingRecipient', 'currentUserService', 'dateService',
  'packageDescriptionService', 'pluralizeService', 'packageTypeService'
];

const SettingsBillingHistoryController = function(
  $filter, SecurityProviderBillingHistoryItem, SecurityProviderBillingRecipient, currentUserService, dateService,
  packageDescriptionService, pluralizeService, packageTypeService
) {

  const vm = this;

  vm.billingHistoryItems = [];

  let totalHistoryLength;
  let page = 1;
  let isLoading = false;

  vm.$onInit = function() {
    loadHistoryItems();
    loadTotalHistoryLength();
  };

  vm.formatCreatedAt = function(billingHistoryItem) {
    return billingHistoryItem.createdAtMoment.format('D MMM YYYY');
  };

  vm.getLicensesCount = function(billingHistoryItem) {
    return pluralizeService.simple(billingHistoryItem.licensesCount, 'license');
  };

  vm.canLoadMore = function() {
    return totalHistoryLength > vm.billingHistoryItems.length;
  };

  vm.loadMore = function() {
    if (isLoading) { return; }
    page++;
    loadHistoryItems();
  };

  const loadHistoryItems = function() {
    isLoading = true;
    SecurityProviderBillingHistoryItem.query({
      page: page
    }, {
      securityProviderId: currentUserService.getCurrentProfile().securityProvider.id
    }).then(function(billingHistoryItems) {
      vm.billingHistoryItems = vm.billingHistoryItems.concat(prepareHistoryItems(billingHistoryItems));
      isLoading = false;
    });
  };

  const loadTotalHistoryLength = function() {
    SecurityProviderBillingHistoryItem.getCount({
      securityProviderId: currentUserService.getCurrentProfile().securityProvider.id
    }).then(function(data) {
      totalHistoryLength = data.count;
    });
  };

  const getTotalPriceText = function(historyItem) {
    if (packageTypeService.hasFreePackage(historyItem.packageType)) {
      return packageDescriptionService.getDescriptionFor(historyItem.packageType).getPrice();
    } else {
      return `${$filter('formatPriceFull')(historyItem.totalPrice / 100)}`;
    }
  };

  const prepareHistoryItems = function(billingHistoryItems) {
    return _.map(billingHistoryItems, function(historyItem) {
      return prepareHistoryItem(historyItem);
    });
  };

  const prepareHistoryItem = function(historyItem) {
    historyItem.createdAtMoment = moment(historyItem.createdAt, dateService.getIsoFullDateFormatWithTz());
    historyItem.packageTitle = packageDescriptionService.getDescriptionFor(historyItem.packageType).shortTitle;
    historyItem.totalPriceText = getTotalPriceText(historyItem);
    return historyItem;
  };

  return vm;

};

angular.module('public.security-manager.settings')
  .controller('SettingsBillingHistoryController', dependencies.concat(SettingsBillingHistoryController));
