/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'gccsConfig', 'validationService', 'userValidation', 'confirmService'];

const SignLayoutController = function($scope, gccsConfig, validationService, userValidation, confirmService) {

  const vm = this;

  vm.userValidation = userValidation;
  vm.validationService = validationService;
  vm.confirmService = confirmService;

  validationService.setRegexRules(gccsConfig.regex);

  return vm;
};

angular.module('sign').controller('SignLayoutController', dependencies.concat(SignLayoutController));
