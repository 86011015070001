/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope'];

const PopupRoleFormController = function($scope) {

  const vm = this;
  
  $scope.popupFormCtrl.isShortPopup = true;

  const initRole = function() {
    if ($scope.role.isNew()) {
      vm.role = $scope.role;
      $scope.popupFormCtrl.title = 'Add role';
    } else {
      vm.role = angular.copy($scope.role);
      $scope.popupFormCtrl.title = 'Edit role';
    }
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) { initRole(); }
  });

  $scope.$on('popup.submit', () => {
    vm.role.save().then(role =>
      $scope.popupCtrl.closePopup(function() {
        $scope.popupCloseCb({ role });
      })
    )
  });

  return vm;

};

angular.module('popup.role')
  .controller('PopupRoleFormController', dependencies.concat(PopupRoleFormController));
