/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'Client', 'uploadService'];

const ClientRegistrationCompanyDetailsController = function($scope, Client, uploadService) {

  const vm = this;

  $scope.clientRegistrationLayoutCtrl.loadClient().then(client => vm.client = client);

  const updateClient = function(client) {
    $scope.clientRegistrationLayoutCtrl.updateClient(client);
    return $scope.clientRegistrationLayoutCtrl.goNext();
  };

  $scope.$on('client.registration.submit', () => uploadService.saveWithFile(vm.client, 'logo', updateClient));

  return vm;
};

angular.module('public.security-manager.client.registration').controller('ClientRegistrationCompanyDetailsController',
  dependencies.concat(ClientRegistrationCompanyDetailsController));
