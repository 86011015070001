// https://stackoverflow.com/a/21918502
angular.module('shared').service('anchorSmoothScroll', function() {

  const SCROLL_DURATION = 800;
  const SCROLL_DURATION_HIGH = 250;

  const currentYPosition = function() {
    // Firefox, Chrome, Opera, Safari
    if (self.pageYOffset) {
      return self.pageYOffset;
    }
    // Internet Explorer 6 - standards mode
    if (document.documentElement && document.documentElement.scrollTop) {
      return document.documentElement.scrollTop;
    }
    // Internet Explorer 6, 7 and 8
    if (document.body.scrollTop) {
      return document.body.scrollTop;
    }
    return 0;
  };

  const elmYPosition = function(eID) {
    const elm = document.getElementById(eID);
    let y = elm.offsetTop;
    let node = elm;
    while (node.offsetParent && (node.offsetParent !== document.body)) {
      node = node.offsetParent;
      y += node.offsetTop;
    }
    return y;
  };

  const scrollTo = function(startPositionY = 0, stopPositionY = 0, { 
    scrollList = window, 
    scrollDuration = SCROLL_DURATION 
  }) {
    const baseY = (startPositionY + stopPositionY) * 0.5;
    const difference = startPositionY - baseY;
    const startTime = performance.now();

    const step = function() {
      let normalizedTime = (performance.now() - startTime) / scrollDuration;
      if (normalizedTime > 1) {
        normalizedTime = 1;
      }
      scrollList.scrollTo(0, baseY + (difference * Math.cos(normalizedTime * Math.PI)));
      if (normalizedTime < 1) {
        return window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };
  
  return {
    scrollToId(eID) {
      const headerElem = document.querySelector('.header');
      const startPositionY = currentYPosition();
      const stopPositionY = elmYPosition(eID) - headerElem.offsetHeight;

      scrollTo(startPositionY, stopPositionY, {});
    },

    scrollToY(startPositionY, stopPositionY, scrollList) {
      scrollTo(startPositionY, stopPositionY, {
        scrollList,
        scrollDuration: SCROLL_DURATION_HIGH
      });
    }
  };
});
