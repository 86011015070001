/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'EntityManager', 'EmployeeAddressHistory', 'dateService', 'EmployeeGapChart'
];

const EmployeeShowAddressHistoryController = function(
  $scope, EntityManager, EmployeeAddressHistory, dateService, EmployeeGapChart
) {

  const vm = this;

  vm.isShownAddressHistoryPopup = false;
  vm.ascendingNameOrder = true;

  const entityManager = new EntityManager(EmployeeAddressHistory, {
    params : {
      employeeId : $scope.employeeShowLayoutCtrl.employeeId
    }
  });

  vm.$onInit = function() {
    initEmployee().then(() => {
      initAddressHistoryChart();
    });
  };

  vm.removeAddressHistory = function (addressHistory) {
    entityManager.removeEntity(addressHistory, () => {
      return vm.employee.addressHistoriesCount--;
    });
  };

  vm.showAddressHistoryPopup = function(addressHistory) {
    if ($scope.employeeShowLayoutCtrl.editable) {
      vm.popupAddressHistory = addressHistory || entityManager.createEntity();
      vm.isShownAddressHistoryPopup = true;
    }
  };

  vm.getPeriod = function(addressHistory) {
    let to;
    const from = dateService.formatDate(addressHistory.from, dateService.getDateFormat());
    if (addressHistory.to) {
      to = dateService.formatDate(addressHistory.to, dateService.getDateFormat());
    } else {
      to = 'Now';
    }
    return `${from} - ${to}`;
  };

  vm.closeAddressHistoryPopup = function(savedAddressHistory) {
    vm.isShownAddressHistoryPopup = false;
    if (savedAddressHistory) {
      vm.employee.addressHistoriesCount++;
      entityManager.updateEntity(savedAddressHistory);
      entityManager.resetLoadData();
      entityManager.loadAll();
      return $scope.employeeShowLayoutCtrl.reloadEmployee();
    }
  };

  vm.getAddressHistories = function () {
    return entityManager.entities;
  };

  const initAddressHistoryChart = function() {
    return EmployeeAddressHistory.getGapInfo($scope.employeeShowLayoutCtrl.employeeId).then(function(gapInfo) {
      vm.addressHistoryGapChart = new EmployeeGapChart(gapInfo.dateRanges);
    });
  };

  const initEmployee = function() {
    return $scope.employeeShowLayoutCtrl.loadEmployee().then(employee => vm.employee = employee);
  };

  entityManager.loadAll();

  return vm;
};

angular.module('public.security-manager.employee')
  .controller('EmployeeShowAddressHistoryController', dependencies.concat(EmployeeShowAddressHistoryController));
