/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'validationService', 'confirmService'];

const PopupEmployeeAddressHistoryFormController = function($scope, validationService, confirmService) {

  const vm = this;

  $scope.popupFormCtrl.isShortPopup = true;

  vm.validationService = validationService;

  const initAddressHistory = function() {
    if ($scope.addressHistory.isNew()) {
      vm.addressHistory = $scope.addressHistory;
      $scope.popupFormCtrl.title = 'Add new address';
      $scope.popupFormCtrl.saveButtonText = 'Add';
    } else {
      vm.addressHistory = angular.copy($scope.addressHistory);
      $scope.popupFormCtrl.title = 'Edit address';
      $scope.popupFormCtrl.saveButtonText = 'Save';
    }
  };

  const saveAddressHistory = () => {
    return vm.addressHistory.save().then(addressHistory => {
      return $scope.popupCtrl.closePopup(() => $scope.popupCloseCb({ addressHistory }));
    });
  };

  const confirmHomeZoneReset = function() {
    const homeZoneNames = _.map($scope.currentHomeZones, 'name');
    if (homeZoneNames.length > 0) {
      confirmService.confirmWithMultipleButtons({
        title: 'Home zones reset',
        question: `This will remove the following home zones: ${homeZoneNames.join(', ')}.`,
        buttons: [{
          label: 'Cancel',
          type: 'white',
          onClickCb() {
            $scope.popupFormCtrl.resetFormSubmitted();
          }
        }, {
          label: 'Accept',
          type: 'green',
          onClickCb() {
            vm.addressHistory.resetHomeZone = true;
            saveAddressHistory();
          }
        }, {
          label: 'Keep',
          type: 'green',
          onClickCb() {
            saveAddressHistory();
          }
        }]
      });
    } else {
      saveAddressHistory();
    }
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) { return initAddressHistory(); }
  });

  $scope.$on('popup.submit', function() {
    if ((vm.addressHistory.to === null) && ($scope.addressHistory.to !== vm.addressHistory.to)) {
      return confirmHomeZoneReset();
    } else {
      return saveAddressHistory();
    }
  });

  return vm;

};

angular.module('popup.employee').controller('PopupEmployeeAddressHistoryFormController',
  dependencies.concat(PopupEmployeeAddressHistoryFormController));
