const dependencies = [
  'BookingShiftEmployeeRoot', 'securityManagerNotificationClockButtonType',
  'bookingShiftEmployeeClockInOutChange', 'dateService'
];

const SecurityManagerNotificationController = function(
  BookingShiftEmployeeRoot, securityManagerNotificationClockButtonType,
  bookingShiftEmployeeClockInOutChange, dateService
) {

  const vm = this;

  vm.isShownClockPopup = false;
  vm.isShownClockInEdit = false;
  vm.isShownClockOutEdit = false;

  vm.openClockPopup = function() {
    if (vm.notification.clockInOutButton) {
      vm.editedShiftEmployee = new BookingShiftEmployeeRoot(vm.notification.clockInOutButton.shiftEmployee);
      vm.clockEditSuccessMessage = 'Changes are saved';
      switch (vm.notification.clockInOutButton.type) {
        case securityManagerNotificationClockButtonType.getAddClockIn():
          vm.isShownClockInEdit = true;
          vm.isShownClockOutEdit = false;
          vm.clockInEditTitle = 'Add clock in time';
          vm.clockInEditButton = 'Clock in';
          break;
        case securityManagerNotificationClockButtonType.getAddClockOut():
          vm.isShownClockInEdit = false;
          vm.isShownClockOutEdit = true;
          vm.clockInEditTitle = 'Add clock out time';
          vm.clockInEditButton = 'Clock out';
          break;
        case securityManagerNotificationClockButtonType.getEditClockIn():
          vm.isShownClockInEdit = true;
          vm.isShownClockOutEdit = false;
          vm.clockInEditTitle = 'Edit clock in time';
          vm.clockInEditButton = 'Save';
          break;
        case securityManagerNotificationClockButtonType.getEditClockOut():
          vm.isShownClockInEdit = false;
          vm.isShownClockOutEdit = true;
          vm.clockInEditTitle = 'Edit clock out time';
          vm.clockInEditButton = 'Save';
          break;
      }
      vm.isShownClockPopup = true;
    }
  };

  vm.hasChangeLog = function() {
    return vm.notification.clockInOutButton && (
      (vm.notification.clockInOutButton.changeLog && vm.notification.clockInOutButton.changeLog.length > 1) ||
      vm.notification.clockInOutButton.lastChangeLog
    );
  };

  vm.getLastUpdateManagerName = function() {
    return (vm.notification.clockInOutButton.lastChangeLog || vm.notification.clockInOutButton.changeLog[0]).user.fullName;
  };

  vm.showChangeLog = function() {
    vm.notification.isShownChangeLog = true;
  };

  vm.hideChangeLog = function() {
    vm.notification.isShownChangeLog = false;
  };

  vm.getFormatedChangeLogFullTime = function(createdAt) {
    return moment(createdAt, dateService.getIsoFullDateFormatWithTz()).format(dateService.getWesternFullDateFormat(true));
  };

  vm.getFormatedChangeLogTime = function(createdAt) {
    return moment(createdAt, dateService.getIsoFullDateFormatWithTz()).format(dateService.getWesternTimeFormat(true));
  };

  vm.closeClockPopup = function() {
    vm.isShownClockPopup = false;
  };

  vm.isManualType = function(type) {
    return bookingShiftEmployeeClockInOutChange.isManualType(type);
  };

  vm.getManualChangeName = function(type) {
    if (bookingShiftEmployeeClockInOutChange.isClockInType(type) || bookingShiftEmployeeClockInOutChange.isManualClockIn(type)) {
      return 'Clock in';
    } else if (bookingShiftEmployeeClockInOutChange.isClockOutType(type) || bookingShiftEmployeeClockInOutChange.isManualClockOut(type)) {
      return 'Clock out';
    } else {
      return 'Clock in/out';
    }
  };

  vm.getChangeTimeField = function(type) {
    if (bookingShiftEmployeeClockInOutChange.isClockInType(type) || bookingShiftEmployeeClockInOutChange.isManualClockIn(type)) {
      return 'clockInTime';
    } else if (bookingShiftEmployeeClockInOutChange.isClockOutType(type) || bookingShiftEmployeeClockInOutChange.isManualClockOut(type)) {
      return 'clockOutTime';
    }
  };

  vm.getClockInOutChangeTime = function(clockInOutChange) {
    return vm.getFormatedChangeLogTime(clockInOutChange[vm.getChangeTimeField(clockInOutChange.type)]);
  };

  vm.getChangeTimeOld = function(clockInOutChange) {
    return vm.getFormatedChangeLogTime(clockInOutChange[vm.getChangeTimeField(clockInOutChange.type)][0]);
  };

  vm.getChangeTimeNew = function(clockInOutChange) {
    return vm.getFormatedChangeLogTime(clockInOutChange[vm.getChangeTimeField(clockInOutChange.type)][1]);
  };

  vm.getButtonName = function () {
    return securityManagerNotificationClockButtonType.getName(vm.notification.clockInOutButton.type);
  };

  return vm;

};

angular.module('public.security-manager.notifications')
  .controller('SecurityManagerNotificationController', dependencies.concat(SecurityManagerNotificationController));
