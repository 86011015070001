const dependencies = [
  '$scope', 'dateService', 'bookingShiftEmployeeAssignStatus', 'BookingShiftEmployee', 'confirmService',
  'notificationService'
];

const PopupBookingShiftTimelineEmployeeController = function(
  $scope, dateService, bookingShiftEmployeeAssignStatus, BookingShiftEmployee, confirmService,
  notificationService
) {

  const vm = this;

  vm.isShownPopup = false;
  vm.isConfirmPopupShowed = false;

  vm.showPopup = function() {
    if (!vm.isShownPopup) {
      vm.isShownPopup = true;
    }
  };

  vm.closePopup = function() {
    if (vm.isShownPopup) {
      vm.isShownPopup = false;
      vm.shiftEmployees = [];
      $scope.popupCloseCb();
    }
  };

  vm.getTotalOverTime = function() {
    let totalOverTime = 0;
    if($scope.employee.employmentType === 3){return totalOverTime;}
    for (let intersection of Array.from($scope.shiftDecorator.mergedIntersections)) {
      totalOverTime += intersection.to.diff(intersection.from, 'hours', true);
    }
    return Math.ceil(totalOverTime);
  };

  vm.getShiftPeriod = function() {
    return [
      $scope.shiftDecorator.startTimeMoment.format(dateService.getWesternTimeFormat()),
      $scope.shiftDecorator.endTimeMoment.format(dateService.getWesternTimeFormat())
    ].join(' - ');
  };

  vm.isBlowOut = function() {
    return bookingShiftEmployeeAssignStatus.isBlowOut($scope.shiftDecorator.assignStatus);
  };

  vm.cancelBlowout = function() {
    if ($scope.shiftDecorator.notCoveredResourcesCount <= 0) {
      notificationService.notifyError('Current shift already full. Please unassign someone to cancel this blowout.');
      return;
    }
    vm.isConfirmPopupShowed = true;
    confirmService.confirm(
      'Confirm reject',
      `Do you really want to reject blow out? This action can't be undone.`,
      'Yes',
      function() {
        new BookingShiftEmployee({
          id: $scope.shiftDecorator.shiftEmployeeId,
          bookingId: $scope.shiftDecorator.bookingId,
          shiftId: $scope.shiftDecorator.shiftId,
          assignStatus: bookingShiftEmployeeAssignStatus.getAssignedStatus()
        }).save().then(function() {
          $scope.shiftDecorator.assignStatus = bookingShiftEmployeeAssignStatus.getAssignedStatus();
          vm.isConfirmPopupShowed = false;
          vm.closePopup();
        });
      },
      function() {
        vm.isConfirmPopupShowed = false;
      });
  };

  const updatePopupPosition = function() {
    if (_.isEmpty($scope.position)) { return; }
    const popup = $scope.$popup[0];
    let { top } = $scope.position;
    const invisibleHeight = (top + popup.offsetHeight) - window.innerHeight;
    if (invisibleHeight > 0) { top -= invisibleHeight; }
    popup.style.left = `${$scope.position.left}px`;
    popup.style.top = `${top}px`;
  };

  const getButtonText = function() {
    if ($scope.editable) {
      return 'Edit shift';
    } else {
      return 'Show shift';
    }
  };

  $scope.$watch('popupShowCondition', function() {
    if ($scope.popupShowCondition) {
      $scope.$popup[0].style.visibility = 'hidden';
      vm.showPopup();
      setTimeout(function() {
        updatePopupPosition();
        $scope.$popup[0].style.visibility = '';
      }, 0);
      vm.buttonText = getButtonText();
    } else {
      vm.isShownPopup = false;
    }
  });

  $scope.$watch('position', updatePopupPosition);

  return vm;

};

angular.module('popup.booking').controller('PopupBookingShiftTimelineEmployeeController',
  dependencies.concat(PopupBookingShiftTimelineEmployeeController));
