/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$state', 'UserCode', 'notificationService'];

const SignPasswordRecoveryController = function($state, UserCode, notificationService) {

  const vm = this;

  vm.flashErrors = {};

  let isFormSubmitted = false;

  vm.recoverPassword = function(invalid) {
    if (invalid || isFormSubmitted) { return; }
    isFormSubmitted = true;
    return new UserCode({userId : 'new', email : vm.email}).save().then(function() {
      notificationService.notifySuccess('Sweet, your password reset email has been sent!');
      return $state.go('sign.login');
    }
    , function(response) {
      vm.flashErrors[response.data.field] = response.data.error;
      return isFormSubmitted = false;
    });
  };

  return vm;
};

angular.module('sign').controller('SignPasswordRecoveryController', dependencies.concat(SignPasswordRecoveryController));
