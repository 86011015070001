/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const bookingShiftEmployeeAvailability = function() {

  const ALL = 1;
  const ONLY_AVAILABLE = 2;
  const ONLY_APPROVED = 3;
  const APPROVED_AND_AVAILABLE = 4;

  const names = {};
  names[ALL] = 'All employees';
  names[ONLY_AVAILABLE] = 'Available';
  names[ONLY_APPROVED] = 'Approved';
  names[APPROVED_AND_AVAILABLE] = 'Approved + Available';

  return {
    getNames() {
      return names;
    },

    getAll() {
      return ALL;
    },

    getOnlyAvailable() {
      return ONLY_AVAILABLE;
    },

    getOnlyApproved() {
      return ONLY_APPROVED;
    },

    getApprovedAndAvailable() {
      return APPROVED_AND_AVAILABLE;
    },

    getDefault() {
      return ONLY_AVAILABLE;
    },

    isOnlyAvailable(type) {
      return type === ONLY_AVAILABLE;
    }
  };
};

angular.module('data.booking').service('bookingShiftEmployeeAvailability', bookingShiftEmployeeAvailability);
