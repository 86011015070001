import './recipients.sass';

const gccsPopupBillingRecipients = {
  controller: 'PopupBillingHistoryRecipientsController',
  controllerAs: 'popupBillingHistoryRecipientsCtrl',
  template: require('./template.html'),
  bindings: {
    billingRecipients: '<'
  }
};

angular.module('public.security-manager.settings').component('gccsPopupBillingRecipients', gccsPopupBillingRecipients);
