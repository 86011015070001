const DEFAULT_PLACEHOLDER = 'Type to add info';

angular.module('shared').directive('gccsInputTransformer', [
  '$compile', '$timeout', 'keyService',
  ($compile, $timeout, keyService) => {
    return {
      restrict: 'A',
      scope: {
        autoSubmit: '<',
        activeClass: '@',
        editable: '<',
        beforeShow: '&'
      },
      link($scope, $element) {
        $timeout(function() {

          if ($scope.editable === false) {
            $element.addClass('mod-disable-hover');
          }

          const element = $element[0];
          const inputHolder = element.querySelector('.input-holder');
          if(!inputHolder) { return; }

          const input = inputHolder.querySelector('input') || inputHolder.querySelector('textarea');
          const $input = angular.element(input);
          const fieldName = $input.attr('name');
          const inputPreview = element.children[element.childElementCount - 2];
          const $inputPreview = angular.element(inputPreview);
          const $form = angular.element(element.closest('form[name]'));
          const formData = $form.data();

          if (!formData) { return; }

          const formController = formData.$formController;

          if (!formController) { return; }

          if (!$scope.getTransformerPreviewValue) {
            $scope.getTransformerPreviewValue = () => formController[fieldName] ? formController[fieldName].$viewValue : '';
          }

          if ($scope.editable === false) {
            if ($inputPreview.html() === '') {
              $inputPreview.attr('ng-bind', `getTransformerPreviewValue()`);
              $compile($inputPreview)($scope);
            }
          } else {
            const errorHolder = element.querySelector('.item-val-error-msg-text');
            formController.isEditing = false;

            const modifyAttributes = function() {
              const placeholder = $input.attr('placeholder') || DEFAULT_PLACEHOLDER;
              $input.attr('placeholder', placeholder);
              if ($inputPreview.html() === '') {
                $inputPreview.attr('ng-bind', `getTransformerPreviewValue() || '${placeholder}'`);
                $inputPreview.attr('ng-class', `{ 'mod-placeholder': !getTransformerPreviewValue() }`);
                $compile($inputPreview)($scope);
              }
            };

            const hideInput = function() {
              if ($scope.activeClass) { element.classList.remove($scope.activeClass); }
              inputHolder.classList.add('hide');
              inputPreview.classList.remove('hide');
              formController.$setPristine();
              formController.$setUntouched();
              formController.isEditing = false;
            };

            const onSubmit = function() {
              formController.$setSubmitted();
              if (formController[fieldName].$valid) {
                if ($scope.autoSubmit) { $scope.$parent.$eval($form.attr('ng-submit')); }
                hideInput();
              }
            };

            const $onSubmit = () => $scope.$apply(onSubmit);

            inputPreview.addEventListener('click', function() {
              if (!formController.isEditing || !$scope.autoSubmit) {
                if ($scope.beforeShow) { $scope.beforeShow(); }
                formController.isEditing = true;
                if ($scope.activeClass) { element.classList.add($scope.activeClass); }
                inputPreview.classList.add('hide');
                inputHolder.classList.remove('hide');
                input.focus();
              }
            });

            $form.bind('submit', function() {
              if (formController.$valid) { return hideInput(); }
            });

            $input.bind('keydown keypress', function(event) {
              if (keyService.enterPressed(event)) {
                $onSubmit();
              }
              if (keyService.escapePressed(event)) {
                $scope.$parent.$eval($form.attr('on-cancel'));
                $input.triggerHandler('input');
                hideInput();
              }
              return true;
            });

            $input.bind('blur', $onSubmit);
            $input.bind('googlePlaceChanged', function(event) {
              formController[fieldName].$setViewValue(event.detail);
              $onSubmit();
            });

            if (errorHolder) {
              const error = errorHolder.querySelector('span');
              $scope.$watch(() => formController.$submitted && formController[fieldName].$error, function() {
                setTimeout(function() {
                  const firstError = element.querySelector('.form-msg span:not(.ng-hide)');
                  if (firstError) {
                    errorHolder.style.display = '';
                    return error.innerText = firstError.innerText;
                  } else {
                    return errorHolder.style.display = 'none';
                  }
                }, 0);
                return $element.toggleClass('is-error', formController.$submitted && formController[fieldName].$invalid);
              }, true);
            }

            modifyAttributes();
          }
        });
      }
    }
  }

]);
