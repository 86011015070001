/*
 * @author Igor Serpak <robobee>
 */
angular.module('public.security-manager', [
  'data.security-manager',
  'public.security-manager.invoices',
  'public.security-manager.client',
  'public.security-manager.hr',
  'public.security-manager.profile',
  'public.security-manager.schedule-manager',
  'public.security-manager.settings',
  'public.security-manager.manager-profile',
  'public.security-manager.notifications',
  'public.security-manager.billing',
  'public.security-manager.reports',
  'popup.system-package',
  'popup.notification'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager', {
      abstract: true,
      url: '',
      template: require('./template.pug'),
      controller: 'SecurityManagerLayoutController',
      controllerAs: 'securityManagerLayoutCtrl'
    })

]);
