/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.timeline').service('timelineSeriesGroupService', function() {

  const twoShiftsIntersect = shiftOne =>
    shiftTwo =>
      !(
        (shiftOne.getEndTimeMoment().diff(shiftTwo.getStartTimeMoment()) <= 0) ||
        (shiftOne.getStartTimeMoment().diff(shiftTwo.getEndTimeMoment()) >= 0)
      )
    
  ;

  // groupSeries :: [SeriesDecorator] -> [[ShiftDecorator]]
  return {
    groupSeries(newSeries) {
      const groupedSeries = [];
      for (let series of Array.from(newSeries)) {
        if (!series.dShifts) { continue; }
        if (groupedSeries.length === 0) {
          groupedSeries.push([].concat(series.dShifts));
        } else {
          for (let dShift of Array.from(series.dShifts)) {
            let isMerged = false;
            for (let row of Array.from(groupedSeries)) {
              if (!_.some(row, twoShiftsIntersect(dShift))) {
                row.push(dShift);
                isMerged = true;
                break;
              }
            }
            if (!isMerged) {
              groupedSeries.push([dShift]);
            }
          }
        }
      }
      return groupedSeries;
    }
  };
});
