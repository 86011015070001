/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const ShiftEditModeService = () =>

  function() {

    let _isShown = false;
    let _shift = undefined;
    let _onSuccessCb = undefined;
    let _onFailCb = undefined;

    return {
      close() {
        return _isShown = false;
      },

      show(shift, onSuccessCb, onFailCb) {
        _isShown = true;
        _shift = shift;
        _onSuccessCb = onSuccessCb;
        return _onFailCb = onFailCb;
      },

      isShown() {
        return _isShown;
      },

      onSuccess(editMode) {
        _shift.editMode = editMode - 0;
        return _onSuccessCb();
      },

      onFail() {
        if (_onFailCb) { return _onFailCb(); }
      }
    };
  }
;

angular.module('public.security-manager.schedule-manager').factory('ShiftEditModeService', ShiftEditModeService);
