/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['currentUserService'];

const profileService = function(currentUserService) {

  const hasService = function(serviceId) {
    const index = _.findIndex(currentUserService.getCurrentProfile().services, service => service.id === serviceId);
    return index !== -1;
  };

  return {
    managerHasAllowedServices(serviceId) {
      return !currentUserService.isClientManagerLogged() || hasService(serviceId);
    }
  };
};

angular.module('shared').service('profileService', dependencies.concat(profileService));
