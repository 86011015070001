/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  'SecurityProviderStripeCustomer', 'Country', 'packageTypeService', 'packageDescriptionService',
  'systemPackagePopupService', 'dateService', 'currentUserService', 'calculationService', 'pluralizeService'
];

const SettingsBillingEstimationController = function(
  SecurityProviderStripeCustomer, Country, packageTypeService, packageDescriptionService,
  systemPackagePopupService, dateService, currentUserService, calculationService, pluralizeService
) {

  const vm = this;

  const NEXT_CHARGE_DATE_FORMAT = 'D MMMM YYYY HH:mm';

  vm.packageTypeService = packageTypeService;
  vm.packageType = packageTypeService.getCurrentPackageType();
  vm.systemPackagePopupService = systemPackagePopupService;
  vm.isLoadedNextCharge = false;
  vm.isFreePackage = packageTypeService.hasFreePackage(vm.packageType);
  vm.isShownLicensesGrandTotal = false;
  vm.licensesCount = packageTypeService.getLicensesCount();

  vm.$onInit = function() {
    initNextCharge();
  };

  vm.getPackageTitle = function() {
    return packageDescriptionService.getDescriptionFor(vm.packageType).title;
  };

  vm.getPricePerMonth = function() {
    return getPricePerEmployee() * vm.licensesCount;
  };

  vm.onEditLicensesClick = function() {
    if (vm.isFreePackage) {
      systemPackagePopupService.showFormPopup();
    } else {
      systemPackagePopupService.showLicensesNumberPopup();
    }
  };

  vm.getEditLicensesNumberText = function() {
    if (vm.isFreePackage) {
      return 'Upgrade plan';
    } else {
      return 'Edit No of Licences';
    }
  };

  vm.getTooltipText = function() {
    let text = 'The number of licences shows how many active employees you can have on your GoPlanr account';
    if (vm.isFreePackage) {
      text += '. To increase number please upgrade your plan';
    }
    return text;
  };

  vm.getEmployeeLicensesText = function() {
    return pluralizeService.simple(vm.licensesCount, 'employee license');
  };

  const getPricePerEmployee = function() {
    return packageTypeService.getPricePerEmployee(vm.packageType);
  };

  const initNextCharge = function() {
    if (vm.isFreePackage) { return; }
    const systemPackage = currentUserService.getCurrentProfile().securityProvider.systemPackage;
    Country.getCurrent().then(function(country) {
      vm.currentCountry = country;
      if (systemPackage.isTrial) {
        calculateNextCharge(systemPackage);
      } else {
        loadNextCharge();
      }
    });
  };

  const calculateNextCharge = function(systemPackage) {
    vm.isLoadedNextCharge = true;
    vm.totalNextCharge = vm.getPricePerMonth();
    if (vm.currentCountry.vatPercentage) {
      vm.totalNextCharge = calculationService.percentValue(vm.totalNextCharge, 100 + vm.currentCountry.vatPercentage);
    }
    vm.nextChargeDate = moment(systemPackage.firstPaidDate, dateService.getIsoDateFormat()).format(NEXT_CHARGE_DATE_FORMAT);
  };

  const loadNextCharge = function() {
    SecurityProviderStripeCustomer.getNextCharge().then(function(data) {
      if(data) {
        vm.isLoadedNextCharge = true;
        vm.totalNextCharge = data.total / 100;
        vm.nextChargeDate = moment(data.date, dateService.getIsoFullDateFormat()).format(NEXT_CHARGE_DATE_FORMAT);
      }
    }).catch(function() {
      // empty
    });
  };

  return vm;

};

angular.module('public.security-manager.settings')
  .controller('SettingsBillingEstimationController', dependencies.concat(SettingsBillingEstimationController));
