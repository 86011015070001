const gccsPopupInviteEmployee = {
  controller: 'PopupInviteEmployeeController',
  template: require('./template.pug'),
  require: {
    gccsPopupsLayout: '^gccsPopupsLayout'
  },
  bindings: {
    employee: '<'
  }
};

angular.module('shared-components.popup.employee').component('gccsPopupInviteEmployee', gccsPopupInviteEmployee);
