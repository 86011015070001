/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const FileAttachment = AppModel => {

  class FileAttachment extends AppModel {
    static initClass() {
      this.configure({ url: '/file_attachments', name: 'file_attachment' });
    }
  }

  FileAttachment.initClass();

  return FileAttachment;

};

angular.module('shared').factory('FileAttachment', dependencies.concat(FileAttachment));
