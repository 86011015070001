/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['AppModel'];

const Report = AppModel => {

  class Report extends AppModel {

    static initClass() {
      this.configure({ url: '/reports', name: 'report' });
    }

    static getBillableHours(params = {}) {
      return this.$get(this.resourceUrl('billable_hours'), params);
    }

    static getGeneralInfo(params = {}) {
      return this.$get(this.resourceUrl('general_info'), params);
    }

    static getBillableHoursDetails(params = {}) {
      return this.$get(this.resourceUrl('billable_hours_details'), params);
    }

    static getEmployeesMissingData(params = {}) {
      return this.$put(this.resourceUrl('employees_missing_data'), params);
    }

    static exportBillableHoursReport(params = {}) {
      return this.$post(this.resourceUrl('export_billable_hours'), params);
    }

    static exportMissingData(params = {}) {
      return this.$put(this.resourceUrl('export_missing_data'), params);
    }

    static getEmployees(params = {}) {
      return this.$put(this.resourceUrl('employees'), params);
    }

    static getEmployeeFilterCategories(params = {}) {
      return this.$get(this.resourceUrl('employee_filter_categories'), params);
    }

    static exportEmployees(params = {}) {
      return this.$put(this.resourceUrl('export_employees'), params);
    }

  }

  Report.initClass();

  return Report;

};

angular.module('data.report').factory('Report', dependencies.concat(Report));
