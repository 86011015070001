/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').directive('gccsDownloadUrl', () => {
  return {
    restrict: 'A',
    scope: {
      isLoading: '='
    },
    controller: [
      '$scope', '$element', '$http', '$timeout', 'storageService', 'defaultConfig', 'downloadService',
      function($scope, $element, $http, $timeout, storageService, defaultConfig, downloadService) {
        const element = $element[0];
        element.addEventListener('click', function () {
          if ($scope.isLoading !== undefined) { $scope.isLoading = true; }
          const downloadUrl = element.getAttribute('gccs-download-url');
          if (!downloadUrl) { return; }
          $http.get(downloadUrl, {
            headers: {
              'X-Session-Id': storageService.get(defaultConfig.sessionKey)
            }
          }).then(function (response) {
            if ($scope.isLoading !== undefined) { $scope.isLoading = false; }
            downloadService.downloadBase64File(response.data);
          });
        });
      }
    ]
  }
});
