/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('shared').service('uniqueValuesService', () => {

  return {
    getUniqueValuesById(array) {
      return _.uniqBy(array, 'id');
    },

    getUniqueValuesByField(array, property) {
      const uniqueArr = [];
      const uniquePropertyArr = [];
      array.forEach(function(item) {
        if (item[property]) {
          if (uniquePropertyArr.indexOf(item[property]) === -1) {
            uniquePropertyArr.push(item[property]);
            return uniqueArr.push(item);
          }
        } else {
          return uniqueArr.push(item);
        }
      });
      return uniqueArr;
    }
  };
});
