const gccsLiveFeedHeader = {
  controller: 'LiveFeedHeaderController',
  template: require('./template.pug'),
  bindings: {
    loader: '<'
  }
};

angular.module('public.security-manager.schedule-manager.timeline')
  .component('gccsLiveFeedHeader', gccsLiveFeedHeader);
