const dependencies = ['AppModel', 'currentUserService'];

const SecurityProviderStripeCustomer = (AppModel, currentUserService) => {

  class SecurityProviderStripeCustomer extends AppModel {
    static initClass() {
      this.configure({
        url: '/security_providers/{{ securityProviderId }}/stripe_customers/{{ id }}',
        name: 'stripe_customer'
      });
    }

    static getCurrent() {
      return this.$get(this.resourceUrl({
        securityProviderId: currentUserService.getCurrentProfile().securityProvider.id
      }, 'current'));
    }

    static getNextCharge() {
      return this.$get(this.resourceUrl({
        securityProviderId: currentUserService.getCurrentProfile().securityProvider.id
      }, 'next_charge'));
    }

  }

  SecurityProviderStripeCustomer.initClass();

  return SecurityProviderStripeCustomer;

};

angular
  .module('data.security-provider')
  .factory('SecurityProviderStripeCustomer', dependencies.concat(SecurityProviderStripeCustomer));
