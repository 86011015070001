/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$state', '$filter', '$location', 'EmployeePayroll', 'FilterParamsService',
  'dateService', 'employeeType', 'chartService', 'employeePayrollStatus'
];

const ReportsFinancePayrollController = function(
  $scope, $state, $filter, $location, EmployeePayroll, FilterParamsService,
  dateService, employeeType, chartService, employeePayrollStatus
) {

  const vm = this;

  const filterParamsService = new FilterParamsService({
    filter: ['from', 'to', 'employmentType', 'status']
  });

  let chartDateFormatter = '%Y-%m-%d';

  vm.filterParams = filterParamsService.getParams();
  vm.employeePayrollStatus = employeePayrollStatus;
  vm.employeeType = employeeType;
  vm.isReportsDataLoaded = false;

  vm.$onInit = function() {
    initDates();
    loadChart(true);
    updateChartDateFormatter();
    vm.editable = true;
  };

  vm.updateChartData = function() {
    setLoadingState();
    loadChart().then(() => setLoadingFinishedState());
  };

  vm.getPayrollGrandTotal = invoices => {
    return _.reduce(invoices, (prevSum, curr) => prevSum + curr.latestVersion.totalAmount, 0);
  };

  const setLoadingState = () => vm.isReportsDataLoaded = false;

  const setLoadingFinishedState = () => vm.isReportsDataLoaded = true;

  const updateChartDateFormatter = function(byMonth) {
    const format = byMonth ? '%b %y' : '%Y-%m-%d';
    return chartDateFormatter = d3.timeFormat(format);
  };

  const getChartConfig = data => {
    return ({
      bindto: '#chart',
      data: {
        x: 'x',
        columns: data,
        type: 'bar',
        groups: [
          _.values(employeeType.getNames())
        ],
        colors: {
          'Self-employed': '#FF8010',
          'Employed': '#84B7E5'
        }
      },
      legend: {
        show: false
      },
      grid: {
        y: {
          show: true
        }
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format(x) {
              return chartDateFormatter(x);
            }
          }
        },
        y: {
          min: 0,
          padding: {
            bottom: 0
          },
          label: {
            text: 'Income (£)',
            position: 'outer-middle'
          },
          tick: {
            outer: false
          }
        }
      },
      tooltip: {
        format: {
          value(value) {
            return `${$filter('formatPriceFull')(value)}`;
          }
        }
      }
    });
  };

  const initChart = data => c3.generate(getChartConfig(data));

  const updateChart = function(data, chart) {
    if (!chart) { return; }
    const itemsToRemove = [];
    _.each(chart.data(), function(chartItem) {
      const foundItem = _.find(data, item => item[0] === chartItem.id);
      if (!foundItem) {
        itemsToRemove.push(chartItem.id);
      }
      return true;
    });
    return chart.load({
      columns: data,
      unload: itemsToRemove
    });
  };

  const loadChart = function(isInit) {
    if (isInit == null) { isInit = false; }
    const urlParams = getUrlParams();
    $location.search(urlParams);

    // params : 'from', 'to', 'employmentType'
    return EmployeePayroll.getIncome(urlParams).then(function(income) {
      const incomeGraphData = chartService.getGraphFormatData(
        income,
        vm.endDateFrom,
        vm.endDateTo,
        employeeType.getName,
        updateChartDateFormatter
      );
      if (isInit) {
        vm.chart = initChart(incomeGraphData);
      } else {
        updateChart(incomeGraphData, vm.chart);
      }
      return initChartSummary(income);
    });
  };

  const initChartSummary = function(income) {
    resetChartSummary();
    if (!vm.filterParams.filter.employmentType || (+vm.filterParams.filter.employmentType === employeeType.getEmployedStatus())) {
      vm.chartSummaryEmployedTotal = getTotalByEmploymentType(income, employeeType.getEmployedStatus());
    }
    if (!vm.filterParams.filter.employmentType || (+vm.filterParams.filter.employmentType === employeeType.getSelfEmployedStatus())) {
      vm.chartSummarySelfEmployedTotal = getTotalByEmploymentType(income, employeeType.getSelfEmployedStatus());
    }
    if (!vm.filterParams.filter.employmentType) {
      vm.chartSummaryGrandTotal = vm.chartSummaryEmployedTotal + vm.chartSummarySelfEmployedTotal;
      vm.chartSummaryEmployedPercentage = ((vm.chartSummaryEmployedTotal / vm.chartSummaryGrandTotal) * 100).toFixed(1);
      return vm.chartSummarySelfEmployedPercentage = ((vm.chartSummarySelfEmployedTotal / vm.chartSummaryGrandTotal) * 100).toFixed(1);
    }
  };

  const resetChartSummary = function() {
    vm.chartSummaryEmployedTotal = null;
    vm.chartSummarySelfEmployedTotal = null;
    vm.chartSummaryGrandTotal = null;
    vm.chartSummaryEmployedPercentage = null;
    return vm.chartSummarySelfEmployedPercentage = null;
  };

  const getTotalByEmploymentType = (data, type) => {
    return _.reduce(data, (prevTotalSum, currTotalSum) => prevTotalSum + currTotalSum[type], 0);
  };

  const initDates = function() {
    const locationParams = $location.search();
    if (locationParams.from && locationParams.to) {
      vm.endDateFrom = moment(locationParams.from, dateService.getIsoDateFormat());
      return vm.endDateTo = moment(locationParams.to, dateService.getIsoDateFormat());
    } else {
      const today = dateService.today();
      vm.endDateTo = today.clone().startOf('month');
      return vm.endDateFrom = vm.endDateTo.clone().subtract(1, 'month');
    }
  };

  const getUrlParams = function() {
    const urlParams = filterParamsService.getFilterUrlParams();
    if (vm.endDateTo && vm.endDateTo) {
      urlParams.from = moment(vm.endDateFrom, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
      urlParams.to = moment(vm.endDateTo, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
    }
    return urlParams;
  };

  $scope.$watch(() => vm.filterParams, _.debounce(vm.updateChartData, 100), true);

  return vm;

};

angular.module('public.security-manager.reports.finance')
  .controller('ReportsFinancePayrollController', dependencies.concat(ReportsFinancePayrollController));
