/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.employee.profile', [
  'popup.employee',
  'popup.document',
  'public.employee.profile.invoice'
]).config(['$stateProvider', $stateProvider => {

  return $stateProvider

    .state('public.employee.profile', {
      abstract: true,
      url: '/profile/:employeeId',
      template: require('./template.pug'),
      controller: 'EmployeeProfileLayoutController',
      controllerAs: 'employeeProfileLayoutCtrl'
    })
    .state('public.employee.profile.details', {
      url: '/my-details',
      template: require('./details/template.pug'),
      controller: 'EmployeeProfileDetailsController',
      controllerAs: 'employeeProfileDetailsCtrl'
    })
    .state('public.employee.profile.work-history', {
      url: '/my-work-history',
      template: require('./work-history/template.pug'),
      controller: 'EmployeeProfileWorkHistoryController',
      controllerAs: 'employeeProfileWorkHistoryCtrl'
    })
    .state('public.employee.profile.address-history', {
      url: '/my-address-history',
      template: require('./address-history/template.pug'),
      controller: 'EmployeeProfileAddressHistoryController',
      controllerAs: 'employeeProfileAddressHistoryCtrl'
    })
    .state('public.employee.profile.approved-clients', {
      url: '/my-approved-clients',
      template: require('./approved-clients/template.pug'),
      controller: 'EmployeeProfileApprovedClientsController',
      controllerAs: 'employeeProfileApprovedClientsCtrl'
    })
    .state('public.employee.profile.documents', {
      url: '/my-documents',
      template: require('./documents/template.pug'),
      controller: 'EmployeeProfileDocumentsController',
      controllerAs: 'employeeProfileDocumentsCtrl'
    })
    .state('public.employee.profile.licenses', {
      url: '/my-licenses',
      template: require('./licenses/template.pug'),
      controller: 'EmployeeProfileLicensesController',
      controllerAs: 'employeeProfileLicensesCtrl'
    })
    .state('public.employee.profile.timesheet', {
      url: '/my-timesheet',
      template: require('./timesheet/template.pug'),
      controller: 'EmployeeProfileTimesheetController',
      controllerAs: 'employeeProfileTimesheetCtrl'
    })
    .state('public.employee.profile.company', {
      url: '/company-details',
      template: require('./company/template.pug'),
      controller: 'EmployeeProfileCompanyDetailsController',
      controllerAs: 'employeeProfileCompanyDetailsCtrl'
    })
    .state('public.employee.profile.notes', {
      url: '/notes',
      template: require('./notes/template.pug'),
      controller: 'EmployeeProfileNotesController',
      controllerAs: 'employeeProfileNotesCtrl'
    });

}]);
