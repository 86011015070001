const employeeStartingBasis = function() {

  const UNKNOWN = 0;
  const STATEMENTA = 1;
  const STATEMENTB = 2;
  const STATEMENTC = 3;
  const EXISTINGEMPLOYEE = 4;

  const names = {};
  names[UNKNOWN] = 'Unknown';
  names[STATEMENTA] = 'Statement A';
  names[STATEMENTB] = 'Statement B';
  names[STATEMENTC] = 'Statement C';
  names[EXISTINGEMPLOYEE] = 'Existing Employee';

  const knownStartingBasisNames = {};
  knownStartingBasisNames[STATEMENTA] = 'Statement A';
  knownStartingBasisNames[STATEMENTB] = 'Statement B';
  knownStartingBasisNames[STATEMENTC] = 'Statement C';
  knownStartingBasisNames[EXISTINGEMPLOYEE] = 'Existing Employee';

  return {
    getNames() {
      return names;
    },

    getName(startingBasis) {
      return names[startingBasis];
    },

    getKnownStartingBasisNames() {
      return knownStartingBasisNames;
    },

    getUnknown() {
      return UNKNOWN;
    },

    getStatementA() {
      return STATEMENTA;
    },

      getStatementB() {
        return STATEMENTB;
      },

      getStatementC() {
        return STATEMENTC;
      },

      getExistingEmployee() {
        return EXISTINGEMPLOYEE;
      },
  };
};

angular.module('data.employee').service('employeeStartingBasis', employeeStartingBasis);