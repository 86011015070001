const gccsFormDatePicker = () => {
  return {
    restrict: 'A',
    controller: 'FormDatePickerController',
    controllerAs: 'formDatePickerCtrl',
    template: require('./template.pug'),
    scope: {
      name: '@',
      format: '@',
      holderClassName: '@?',
      minDateErrorMsg: '@?',
      dateModel: '=',
      minDate: '<',
      maxDate: '<',
      customErrors: '&',
      onDateChange: '=',
      withoutIcon: '=',
      withClockIcon: '=',
      withTabElement: '=',
      disabled: '=',
      customData: '<',
      isNewWrapper: '<'
    }
  };
};

angular.module('form').directive('gccsFormDatePicker', gccsFormDatePicker);
