/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const invoiceReportExportType = function() {

  const STANDARD = 1;
  const KASHFLOW = 2;
  const SAGE = 3;
  const DETAILED = 4;

  const names = {};
  names[STANDARD] = 'Standard';
  names[KASHFLOW] = 'Kashflow';
  names[DETAILED] = 'Invoice by shifts';
//  names[SAGE] = 'Sage'

  const stringTypes = {};
  stringTypes[STANDARD] = 'standard';
  stringTypes[KASHFLOW] = 'kashflow';
  stringTypes[SAGE] = 'sage';
  stringTypes[DETAILED] = 'invoice by shifts';

  return {
    getNames() {
      return names;
    },

    getStringType(type) {
      return stringTypes[type];
    },

    getStandard() {
      return STANDARD;
    }
  };
};

angular.module('public.security-manager.reports').service('invoiceReportExportType', invoiceReportExportType);
