/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.client-manager.client').directive('gccsManagerClientDetailsSummary', () =>
  ({
    restrict: 'A',
    controller: 'ManagerClientDetailsSummaryController',
    controllerAs: 'managerClientDetailsSummaryCtrl',
    template: require('./template.pug'),
    scope: {
      client: '='
    }
  })
);
