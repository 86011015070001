/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$state', '$q', 'Client', 'localStorageService', 'validationService', 'notificationService',
  'clientStatus', 'redirectService', 'packageTypeService'
];

const ClientRegistrationLayoutController = function(
  $scope, $state, $q, Client, localStorageService, validationService, notificationService,
  clientStatus, redirectService, packageTypeService
) {

  const vm = this;

  const STEP_ADD_SERVICES = 3;
  const STEP_ADD_MANAGERS = 4;

  vm.clientId = localStorageService.get('clientId');
  vm.steps = [{
    title: 'Company details',
    sref: 'public.security-manager.client-registration.step-1'
  }, {
    title: 'Managing locations',
    sref: 'public.security-manager.client-registration.step-2'
  }, {
    title: 'Create a new service',
    sref: 'public.security-manager.client-registration.step-3'
  }];
  if (packageTypeService.hasProPackage()) {
    vm.steps.push({
      title: 'Invite managers',
      sref: 'public.security-manager.client-registration.step-4'
    });
  }
  vm.validationService = validationService;

  let cachedClient = null;
  let isFormSubmitted = false;

  vm.$onInit = function() {
    checkPackageRestrictions();
    initActiveStep();
    $scope.$on('$stateChangeSuccess', initActiveStep);
  };

  vm.getTitle = () => vm.steps[vm.activeStepNumber - 1].title;

  vm.updateClient = function(client) {
    localStorageService.set('clientId', client.id);
    cachedClient = client;
    vm.clientId = client.id;
    return cachedClient;
  };

  vm.goBack = () => $state.go(vm.steps[vm.activeStepNumber - 2].sref);

  vm.goNext = function() {
    if (vm.steps[vm.activeStepNumber]) {
      return $state.go(vm.steps[vm.activeStepNumber].sref);
    } else {
      return vm.loadClient().then(function(client) {
        client.isRegistered = true;
        return client.save().then(function() {
          notificationService.notifySuccess(`Company ${client.name} created!`);
          localStorageService.remove('clientId');
          cachedClient = null;
          return $state.go('public.security-manager.clients', { status: clientStatus.getActiveStatus() });
        });
      });
    }
  };

  vm.onFormSubmit = function() {
    if (!$scope.ClientRegistrationForm.$valid || isFormSubmitted) { return; }
    if ($scope.ClientRegistrationForm.$pristine) {
      return vm.goNext();
    } else {
      isFormSubmitted = true;
      return $scope.$broadcast('client.registration.submit');
    }
  };

  vm.loadClient = function() {
    if (cachedClient) {
      return $q.when(cachedClient);
    } else if (vm.clientId) {
      return Client.get(vm.clientId).then(loadedClient => cachedClient = loadedClient);
    } else {
      cachedClient = new Client({status : clientStatus.getActiveStatus()});
      return $q.when(cachedClient);
    }
  };

  vm.isLoadedClient = () => cachedClient !== null;

  vm.resetForm = function() {
    vm.resetFormSubmitted();
    if ($scope.ClientRegistrationForm) {
      $scope.ClientRegistrationForm.$setPristine();
      return $scope.ClientRegistrationForm.$setUntouched();
    }
  };

  vm.canBeSkipped = () => vm.activeStepNumber === STEP_ADD_MANAGERS;

  vm.resetFormSubmitted = () => isFormSubmitted = false;

  vm.getContainerClass = function() {
    if (vm.activeStepNumber === STEP_ADD_SERVICES) {
      return 'mod-w1300';
    } else {
      return 'mod-w825';
    }
  };

  const checkPackageRestrictions = function() {
    if (packageTypeService.hasFreePackage()) {
      Client.getCount({}, { status: clientStatus.getActiveStatus() }).then(data => {
        if (data.count >= packageTypeService.getClientsLimitForFreePackage()) {
          $state.go('public.security-manager.clients', { status: clientStatus.getActiveStatus() });
        }
      });
    }
  };

  const initActiveStep = function() {
    const index = _.findIndex(vm.steps, step => $state.is(step.sref));
    vm.activeStepNumber = index + 1;
    return vm.resetForm();
  };

  return vm;

};

angular.module('public.security-manager.client.registration').controller('ClientRegistrationLayoutController',
  dependencies.concat(ClientRegistrationLayoutController));
