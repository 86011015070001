/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const EmployeeEmail = AppModel => {

  class EmployeeEmail extends AppModel {
    static initClass() {
      this.configure({url : '/emails/employees', name : 'employee'});
    }

    getName() {
      return 'email';
    }
  }

  EmployeeEmail.initClass();
  return EmployeeEmail;
};

angular.module('data.email').factory('EmployeeEmail', dependencies.concat(EmployeeEmail));
