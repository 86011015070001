/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  'bookingRepeatable', 'bookingPeriod', 'bookingEndRepeat', 'currentUserService',
  'bookingShiftUnpaidBreak', 'dateService', 'payRateVisibilityService',
];

const bookingSummaryService = function(
  bookingRepeatable, bookingPeriod, bookingEndRepeat, currentUserService,
  bookingShiftUnpaidBreak, dateService, payRateVisibilityService
) {

  const DATE_FORMAT = dateService.getDateFormat();

  const getBookingSummary = function(booking, shiftLocations) {
    const bookingSummary = {
      main: {
        title: 'Booking name',
        value: booking.name
      },
      sub: [{
        title: 'Purchase number',
        value: booking.purchaseNumber
      }, {
        title: 'Service',
        value: booking.serviceName
      }, {
        title: 'Duration',
        value: `${dateService.formatDate(booking.from, DATE_FORMAT)} – ${dateService.formatDate(booking.to, DATE_FORMAT)}`
      }, {
        title: 'Clock in/out',
        value: getClockInValue(booking.enableClockIn),
        className: getClockInClassName(booking.enableClockIn)
      }]
    };
    if (shiftLocations.length || booking.location) {
      bookingSummary.sub.splice(1, 0, {
        title: 'Location',
        value: shiftLocations.join(', ') || booking.location.name
      });
    }
    if (currentUserService.isSecurityManagerLogged() && !currentUserService.getCurrentProfile().isSupervisor()) {
      bookingSummary.sub.splice(1, 0, {
        title: 'Project code',
        value: booking.projectCode
      });
    }
    return bookingSummary;
  };

  const getShiftSummary = function(shift) {
    const shiftSummary = {
      main: {
        title: 'Shift name',
        value: shift.name
      },
      sub: [{
        title: 'Shift Date & Time',
        value: getShiftDateAndTime(shift)
      }, {
        title: 'Unpaid break',
        value: bookingShiftUnpaidBreak.getFullName(shift.unpaidBreakInMinutes)
      }, {
        title: 'Clock in/out',
        value: getClockInValue(shift.enableClockIn),
        className: getClockInClassName(shift.enableClockIn)
      }, {
        title: 'Resources',
        value: shift.resource.name
      }, {
        title: 'Amnt. of res',
        value: shift.resourcesAmount
      }]
    };
    if (shift.location) {
      shiftSummary.sub.splice(1, 0, {
        title: 'Location',
        value: shift.location.name
      });
    }
    if (payRateVisibilityService.isShownChargeRate(shift.chargeRate)) {
      shiftSummary.sub.push({
        title: 'Charge rate (CR)',
        value: `£ ${shift.chargeRate}`
      });
    }
    if (payRateVisibilityService.isShownPayRate(shift.contractorPayRate, shift.employedPayRate)) {
      shiftSummary.sub.push(
        {
          title: 'Contractor pay rate (CPR)',
          value: `£ ${shift.contractorPayRate}`
        },
        {
          title: 'Employee pay rate (EPR)',
          value: `£ ${shift.employedPayRate}`
        }
      );
    }
    return shiftSummary;
  };

  const getClockInValue = function(enableClockIn) {
    return enableClockIn ? 'Enabled' : 'Disabled';
  };

  const getClockInClassName = function(enableClockIn) {
    return enableClockIn ? undefined : 'mod-disabled';
  };

  const getShiftDateAndTime = function(shift) {
    const startTimeMoment = moment(shift.startTime, dateService.getIsoFullDateFormat());
    const endTimeMoment = moment(shift.endTime, dateService.getIsoFullDateFormat());
    return [
      startTimeMoment.format(dateService.getFullDateFormat()),
      endTimeMoment.format(dateService.getFullDateFormat())
    ].join(' - ');
  };

  return {
    getBookingSummary,
    getShiftSummary
  };

};

angular.module('data.booking').service('bookingSummaryService', dependencies.concat(bookingSummaryService));
