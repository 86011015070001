/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('public.security-manager.hr', [
  'data.employee',
  'data.resource',
  'data.client',
  'public.security-manager.hr.employees',
  'public.security-manager.hr.cards',
  'public.security-manager.hr.email'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.hr', {
      abstract: true,
      url: '/hr',
      template: require('./template.pug'),
      controller: 'SecurityManagerHrController',
      controllerAs: 'securityManagerHrCtrl'
    })
    .state('public.security-manager.hr.bulk-rate', {
      url: '/bulk-rate',
      template: require('./bulk-rate/template.pug'),
      controller: 'SecurityManagerHrBulkRateController',
      controllerAs: 'bulkRateController'
    })

]);

