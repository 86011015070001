angular.module('data.client').factory('ClientLocation', ['AppModel', function(AppModel) {

  class ClientLocation extends AppModel {
    static initClass() {
      this.configure({url : '/clients/{{clientId}}/locations/{{id}}', name : 'location'});
    }
  }

  ClientLocation.initClass();
  return ClientLocation;
}
]);
