/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.employee').factory('ShiftEmployee', [
  'AppModel', 'dateService', 'railsSerializer',
  function(AppModel, dateService, railsSerializer) {

    class ShiftEmployee extends AppModel {
      static initClass() {
        this.configure({
          url : '/employees/{{employeeId}}/shift_employees/{{id}}',
          name : 'shift_employee',
          serializer : railsSerializer(function() {
            return this.resource('shift', 'BookingShift');
          })
        });
      }

      static acceptInvitation(employeeId, id) {
        return this._postUrl(employeeId, id, 'accept');
      }

      static declineInvitation(employeeId, id) {
        return this._postUrl(employeeId, id, 'decline');
      }

      static approveShiftCancellation(employeeId, id) {
        return this._postUrl(employeeId, id, 'approve_shift_cancellation');
      }

      static rejectShiftCancellation(employeeId, id) {
        return this._postUrl(employeeId, id, 'reject_shift_cancellation');
      }

      static getForEmployeeTimeline(params) {
        return this.get({ employeeId: params.employeeId, id: params.id }, { forTimeline: true });
      }

      static _postUrl(employeeId, id, url) {
        return this.$post(new ShiftEmployee({employeeId, id}).$url(url));
      }
    }

    ShiftEmployee.initClass();
    return ShiftEmployee;
  }

]);
