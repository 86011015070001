const dependencies = [
  '$scope', '$location', '$sce', 'Report', 'downloadService', 'EntityManager', 'FilterParamsService',
  'employeeStatus', 'Employee'
];

const ReportsHrEmployeeListController = function(
  $scope, $location, $sce, Report, downloadService, EntityManager, FilterParamsService,
  employeeStatus, Employee
) {

  const vm = this;

  const entityManager = new EntityManager(Report, { loadCbName: 'getEmployees' });
  const filterParamsService = new FilterParamsService({
    order: ['name'],
    filter: ['name']
  });

  let totalEmployees = 0;

  let lastOpenedReport = null;

  vm.extendedFilterParams = null;
  vm.filterParams = filterParamsService.getParams();

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  vm.loadMoreEmployees = () => entityManager.loadMore();

  vm.getEmployees = () => entityManager.entities;

  vm.toggleEmployeeData = function(employeeData) {
    if (lastOpenedReport === employeeData) {
      lastOpenedReport.isOpened = false;
      lastOpenedReport = null;
    } else {
      if (!employeeData.isOpened) {
        if (lastOpenedReport) {
          lastOpenedReport.isOpened = false;
        }
        employeeData.isOpened = true;
        lastOpenedReport = employeeData;
      }
    }
  };

  vm.toggleTableOrder = function(field) {
    _.each(vm.filterParams.order, (value, key) => {
      if (key === field) {
        if (value.ascending === null) {
          value.ascending = true;
        } else {
          value.ascending = !value.ascending;
        }
      } else {
        value.ascending = null;
      }
    });
  };

  vm.getSortClass = function(field) {
    if (vm.filterParams.order[field].ascending === true) {
      return 'reports-hr-title-sortable-bottom';
    } else if (vm.filterParams.order[field].ascending === false) {
      return 'reports-hr-title-sortable-top';
    } else {
      return '';
    }
  };

  vm.getRolesText = function(employeeData) {
    return _.map(employeeData.roles, 'name').join(', ');
  };

  vm.getStatusClass = function(status) {
    if (employeeStatus.isActive(status)) {
      return 'mod-passed';
    } else if (employeeStatus.isInactive(status)) {
      return 'mod-expired';
    } else {
      return '';
    }
  };

  vm.exportReport = function() {
    const params = _.extend({}, filterParamsService.getFilterUrlParams(), vm.extendedFilterParams);
    Report.exportEmployees(params).then(function (response) {
      downloadService.downloadBase64File(response);
    });
  };

  vm.filterEmployees = function(queryParams) {
    vm.extendedFilterParams = queryParams;
    filterEmployeeData();
  };

  vm.isShownPlaceholder = function() {
    return vm.isExtendedFilterParamsEmpty() || vm.getEmployees().length === 0;
  };

  vm.isExtendedFilterParamsEmpty = function() {
    return _.isEmpty(vm.extendedFilterParams);
  };

  vm.getPlaceholderText = function() {
    if (vm.isExtendedFilterParamsEmpty()) {
      return $sce.trustAsHtml(`There are no filters applied yet. Add them via Filter button from
<br/>
the right side of your screen`);
    } else if (vm.getEmployees().length === 0) {
      return $sce.trustAsHtml(`No matches, please try to apply another filters`);
    }
  };

  vm.getFilterCountText = function() {
    return `${vm.getEmployees().length} out of ${totalEmployees} employees filtered`;
  };

  const updateTotalEmployees = function() {
    Employee.getCount().then(data => {
      totalEmployees = data.count;
    });
  };

  const filterEmployeeData = function() {
    if (vm.isExtendedFilterParamsEmpty()) {
      entityManager.clearEntities();
      return;
    }
    updateTotalEmployees();
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    $location.search(urlParams);
    return entityManager.load(_.extend({}, urlParams, vm.extendedFilterParams));
  };

  $scope.$watch(() => vm.filterParams, _.debounce(filterEmployeeData, 50) , true);

  return vm;

};

angular.module('public.security-manager.reports.hr')
  .controller('ReportsHrEmployeeListController', dependencies.concat(ReportsHrEmployeeListController));
