/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'currentUserService', 'systemPackagePopupService'
];

const PopupSystemPackageClientManagersExceedController = function(
  $scope, currentUserService, systemPackagePopupService
) {

  const vm = this;

  vm.getPopupMessageText = function() {
    let requiredActionMessage = '';
    if (!currentUserService.getCurrentProfile().isAccountOwner()) {
      requiredActionMessage = 'contact your account owner to ';
    }
    return `To add managers for client please ${requiredActionMessage}upgrade your subscription plan`;
  };

  vm.getPopupButtonText = function() {
    if (currentUserService.getCurrentProfile().isAccountOwner()) {
      return 'Upgrade';
    } else {
      return 'Got it';
    }
  };

  vm.doPackageRestrictionPopupAction = function() {
    if (currentUserService.getCurrentProfile().isAccountOwner()) {
      closePopup(function() {
        systemPackagePopupService.showFormPopup();
      });
    } else {
      closePopup();
    }
  };

  const closePopup = function(onClose) {
    $scope.popupCtrl.closePopup(function() {
      $scope.popupCloseCb();
      if (onClose) {
        onClose();
      }
    });
  };

  return vm;

};

angular.module('popup.system-package')
  .controller('PopupSystemPackageClientManagersExceedController',
    dependencies.concat(PopupSystemPackageClientManagersExceedController));
