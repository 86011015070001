/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', 'BookingShift', 'ClientLocation', 'resourceIcon', 'bookingShiftUnpaidBreak', 'bookingRepeatable',
  'bookingRepeatFrequency', 'bookingPeriod', 'bookingEndRepeat', 'bookingShiftValidationService', 'selectService',
  'dateService', 'payRateVisibilityService', 'dateWeek', 'currentUserService', 'notificationService',
  'bookingShiftEmployeeAssignStatus', 'shiftFormDetailsChangeService', 'repeatTimesValidationService',
  'packageTypeService', 'activeStatus', 'resourceChargeType'
];

const PopupBookingShiftFormDetailsController = function(
  $scope, BookingShift, ClientLocation, resourceIcon, bookingShiftUnpaidBreak, bookingRepeatable,
  bookingRepeatFrequency, bookingPeriod, bookingEndRepeat, bookingShiftValidationService, selectService,
  dateService, payRateVisibilityService, dateWeek, currentUserService, notificationService,
  bookingShiftEmployeeAssignStatus, shiftFormDetailsChangeService, repeatTimesValidationService,
  packageTypeService, activeStatus, resourceChargeType
) {

  const vm = this;

  vm.resourceIcon = resourceIcon;
  vm.shiftUnpaidBreak = bookingShiftUnpaidBreak;
  vm.bookingRepeatable = bookingRepeatable;
  vm.bookingRepeatFrequency = bookingRepeatFrequency;
  vm.bookingPeriod = bookingPeriod;
  vm.bookingEndRepeat = bookingEndRepeat;
  vm.dateWeek = dateWeek;
  vm.getResourcesAmountErrors = bookingShiftValidationService.getResourcesAmountErrors;
  [vm.minDate, vm.maxStartDate] = Array.from($scope.availableDateRange);
  vm.maxEndDate = vm.maxStartDate.clone();
  vm.isShownResourcesAmountWarning = false;
  vm.isShownLocationPopup = false;
  vm.shiftFormDetailsChangeService = shiftFormDetailsChangeService;
  vm.isShownLocationsExceedPopup = false;

  vm.$onInit = function() {
    vm.clientLocations = [];
    ClientLocation.query({ activeStatus: activeStatus.getActiveStatus() }, { clientId : $scope.clientId }).then(function (locations) {
      const foundLocation = _.find(locations, ['id', vm.shift.locationId]);
      if (!foundLocation) {
        locations.push(vm.shift.location);
      }
      vm.clientLocations = selectService.toSelectArray(locations);
    });
  };

  vm.isShownWarning = function () {
    return vm.isShownResourcesAmountWarning || vm.shiftFormDetailsChangeService.isShownWarning();
  };

  vm.getDateErrors = function () {
    return bookingShiftValidationService.getDateErrors(hoursData);
  };

  vm.getStartTimeErrors = function () {
    return bookingShiftValidationService.getStartTimeErrors(hoursData);
  };

  vm.getEndTimeErrors = function () {
    return bookingShiftValidationService.getEndTimeErrors(hoursData);
  };

  vm.onSubmit = function(isWarningSave = false) {
    if (!$scope.ShiftForm.$valid) { return; }
    vm.assignedShiftEmployeesCount = 0;
    _.each(vm.shift.employeesShifts, function(employeeShift) {
      if (!employeeShift.splitForId && bookingShiftEmployeeAssignStatus.countsAsAssigned(employeeShift.assignStatus)) {
        return vm.assignedShiftEmployeesCount++;
      }
    });
    if (vm.shift.resourcesAmount < vm.assignedShiftEmployeesCount) {
      vm.isShownResourcesAmountWarning = true;
      return shiftFormDetailsChangeService.hideWarning();
    } else {
      prepareShift();
      if (repeatTimesValidationService.isValid(vm.shift, vm.maxEndDate)) {
        return $scope.onFormSubmit({ shift: vm.shift, isWarningSave });
      } else {
        return notificationService.notifyError('You can not create shifts outside booking ranges');
      }
    }
  };

  vm.canSeeChargeRate = function () {
    return payRateVisibilityService.isShownChargeRate(vm.shift.chargeRate);
  };

  vm.canSeePayRate = function () {
    return payRateVisibilityService.isShownPayRate(vm.shift.contractorPayRate, vm.shift.employedPayRate);
  };

  vm.addLocation = function() {
    ClientLocation.getCount({ clientId: $scope.clientId }, { activeStatus: activeStatus.getActiveStatus() }).then((data) => {
      if (packageTypeService.hasFreePackage()) {
        if (data.count >= packageTypeService.getLocationsLimitForFreePackage()) {
          openLocationsExceedPopup();
        } else {
          openLocationPopup();
        }
      } else {
        openLocationPopup();
      }
    });
  };

  vm.closeLocationPopup = function(location) {
    vm.isShownLocationPopup = false;
    if (location) {
      vm.clientLocations = _.merge(vm.clientLocations, selectService.toSelectData([location]));
      vm.shift.locationId = location.id;
    }
  };

  vm.closeLocationsExceedPopup = function () {
    vm.isShownLocationsExceedPopup = false;
  };

  vm.isShownClockEnablence = function() {
    const now = moment();
    if (resourceChargeType.isPerUnit(vm.shift.resource.chargeType)) {
      return false;
    } else if (now.isBefore(moment(vm.shift.endTime, dateService.getIsoFullDateFormat()))) {
      return vm.shift.bookingsEnableClockIn;
    } else {
      return vm.shift.enableClockIn;
    }
  };

  const openLocationsExceedPopup = function() {
    vm.isShownLocationsExceedPopup = true;
  };

  const openLocationPopup = function() {
    vm.popupLocation = new ClientLocation;
    vm.popupLocation.clientId = $scope.clientId;
    vm.isShownLocationPopup = true;
  };

  const buildTime = function(field) {
    const timeParts = vm.shift[`${field}Time`].split(' ');
    timeParts[0] = vm.shift[`${field}Date`];
    timeParts[1] = vm.shift[`${field}DayTime`];
    vm.shift[`${field}Time`] = timeParts.join(' ');
  };

  const splitTime = function(field) {
    const time = moment(vm.shift[`${field}Time`], dateService.getIsoFullDateFormat());
    vm.shift[`${field}Date`] = time.format(dateService.getDateFormat());
    vm.shift[`${field}DayTime`] = time.format(dateService.getTimeFormat());
  };

  const hoursData = function () {
    return {
      minHours: vm.shift.resource.minHours,
      maxHours: vm.shift.resource.maxHours
    };
  };

  const prepareShift = function() {
    buildTime('start');
    buildTime('end');
    vm.shift.frequencyOn = dateWeek.toValue(vm.chosenWeeks);
  };

  $scope.$watch('shift', function() {
    vm.shift = angular.copy($scope.shift);
    if (!vm.shift.repeatUntil) { vm.shift.repeatUntil = vm.maxEndDate.clone(); }
    vm.chosenWeeks = dateWeek.toArray(vm.shift.frequencyOn);
    splitTime('start');
    splitTime('end');
  });

  $scope.$watch('ShiftForm.$dirty', function() {
    if ($scope.ShiftForm.$dirty) { shiftFormDetailsChangeService.setChanged(); }
  });

  return vm;

};

angular.module('popup.booking')
  .controller('PopupBookingShiftFormDetailsController', dependencies.concat(PopupBookingShiftFormDetailsController));
