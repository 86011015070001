const securityManagerNotificationClockButtonType = function() {

  const ADD_CLOCK_IN = 1;
  const ADD_CLOCK_OUT = 2;
  const EDIT_CLOCK_IN = 3;
  const EDIT_CLOCK_OUT = 4;

  const names = {
    [ADD_CLOCK_IN]: 'Clock in to shift',
    [ADD_CLOCK_OUT]: 'Clock out of shift',
    [EDIT_CLOCK_IN]: 'Edit clock in time',
    [EDIT_CLOCK_OUT]: 'Edit clock out time',
  };

  return {
    getNames() {
      return names;
    },

    getName(type) {
      return names[type];
    },

    getAddClockIn() {
      return ADD_CLOCK_IN;
    },

    getAddClockOut() {
      return ADD_CLOCK_OUT;
    },

    getEditClockIn() {
      return EDIT_CLOCK_IN;
    },

    getEditClockOut() {
      return EDIT_CLOCK_OUT;
    }

  };

};

angular.module('public.security-manager.notifications')
  .service('securityManagerNotificationClockButtonType', securityManagerNotificationClockButtonType);
