/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$http', 'SecurityProviderSetting', 'Country', 'localStorageService', 'selectService'
];

const SignSecurityProviderStep2Controller = function(
  $scope, $http, SecurityProviderSetting, Country, localStorageService, selectService
) {

  const vm = this;

  const DEFAULT_COUNTRY = 'United Kingdom of Great Britain and Northern Ireland';

  $scope.signSecurityProviderLayoutCtrl.isSubmitButtonDisabled = false;

  vm.selectCountries = null;

  vm.$onInit = function () {
    initCountryList();
  };

  vm.onCountrySelect = function (country) {
    if (!vm.securityProviderSettings) { return; }
    vm.securityProviderSettings.countryId = country.id;
  };

  const initCountryList = function () {
    Country.query().then(function (countries) {
      vm.selectCountries = selectService.toSelectArray(countries, { titleField: 'name' });
      initSecurityProviderSettings();
    });
  };

  const initSecurityProviderSettings = function () {
    $scope.signSecurityProviderLayoutCtrl.loadSecurityProvider().then(function(securityProvider) {
      SecurityProviderSetting.get({ securityProviderId: securityProvider.id }).then(function(securityProviderSettings) {
        vm.securityProviderSettings = securityProviderSettings;
        const country = securityProviderSettings.country ? securityProviderSettings.country.name : DEFAULT_COUNTRY;
        vm.selectedCountry = _.find(vm.selectCountries, ['text', country]);
        vm.onCountrySelect(vm.selectedCountry);
      });
    }).catch(function() {
      $scope.signSecurityProviderLayoutCtrl.gotoFirstStep();
    });
  };

  $scope.$on('security-provider.registration.submit', () => {
    vm.securityProviderSettings.save().then(function () {
      $scope.signSecurityProviderLayoutCtrl.goNext();
    });
  });

  return vm;

};

angular.module('sign.security-provider')
  .controller('SignSecurityProviderStep2Controller', dependencies.concat(SignSecurityProviderStep2Controller));
