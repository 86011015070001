/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$state', 'planningNotificationService'];

const PlanningNotificationsController = function($state, planningNotificationService) {

  const vm = this;

  vm.planningNotificationService = planningNotificationService;

  vm.quitPlanningMode = function() {
    planningNotificationService.hideQuitMessage();
    return vm.planningInteractor.quitPlanningMode().then(() => $state.go('public.security-manager.schedule-manager.timeline.employee'));
  };

  return vm;
};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .controller('PlanningNotificationsController', dependencies.concat(PlanningNotificationsController));
