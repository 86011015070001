/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'employeePayrollStatus'];

const PopupEmployeePayrollCreateController = function($scope, employeePayrollStatus) {

  const vm = this;

  vm.$onInit = () => initPopupData();

  vm.submitStatusChange = function () {
    return $scope.popupCtrl.closePopup(function () {
      $scope.popupCloseCb({ payroll: $scope.payroll });
      return initPopupData();
    });
  };

  const initPayroll = function() {
    if ($scope.payroll.isNew()) {
      vm.payroll = $scope.payroll;
    } else {
      vm.payroll = angular.copy($scope.payroll);
    }
  };

  const initPopupData = function () {
    vm.payrollStatuses = employeePayrollStatus.getNames();
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) { return initPayroll(); }
  });

  return vm;
};

angular.module('popup.employee-payroll')
  .controller('PopupEmployeePayrollCreateController', dependencies.concat(PopupEmployeePayrollCreateController));
