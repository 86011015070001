/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsSettingsPaymentPayPeriods = {
  template: require('./template.pug'),
  controller: 'SettingsPaymentPayPeriodsController',
  controllerAs: 'settingsPaymentPayPeriodsCtrl',
  bindings: {
    editable: '<',
    securityProviderId: '<'
  }
};

angular.module('public.security-manager.settings')
  .component('gccsSettingsPaymentPayPeriods', gccsSettingsPaymentPayPeriods);
