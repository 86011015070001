const dependencies = [
  'SecurityProviderBillingRecipient', 'SecurityManager', 'currentUserService', 'pluralizeService'
];

const SettingsBillingRecipientsController = function(
  SecurityProviderBillingRecipient, SecurityManager, currentUserService, pluralizeService
) {

  const vm = this;

  vm.isShownRecipientsPopup = false;

  vm.$onInit = function() {
    loadRecipients();
  };

  vm.getOtherRecipientsText = function() {
    let otherRecipientsText;
    const billingRecipientsLength = vm.billingRecipients.length;
    if (billingRecipientsLength > 0) {
      otherRecipientsText = pluralizeService.simple(billingRecipientsLength, 'recipient');
    } else {
      otherRecipientsText = 'nobody';
    }
    return otherRecipientsText;
  };

  vm.shownRecipientsPopup = function() {
    vm.isShownRecipientsPopup = true;
  };

  vm.closeRecipientsPopup = function(recipients) {
    vm.isShownRecipientsPopup = false;
    if (recipients) {
      vm.billingRecipients = recipients;
    }
  };

  const loadRecipients = function() {
    SecurityManager.getAccountOwner().then(function(accountOwner) {
      vm.mainBillingRecipient = accountOwner;
    });
    SecurityProviderBillingRecipient.query({}, {
      securityProviderId: currentUserService.getCurrentProfile().securityProvider.id
    }).then(function(billingRecipients) {
      vm.billingRecipients = billingRecipients;
    });
  };

  return vm;

};

angular.module('public.security-manager.settings')
  .controller('SettingsBillingRecipientsController', dependencies.concat(SettingsBillingRecipientsController));
