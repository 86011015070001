angular.module('public.security-manager.employee').component('gccsEmployeeProfileApproveServices', {
  template: require('./template.pug'),
  controller: 'EmployeeProfileApproveServiceController',
  bindings: {
    employee: '<',
    selectedClientId: '<',
    onResourceApprove: '&',
    onResourceReject: '&'
  }
});
