/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'symbolService'];

const SecurityManagerEmployeesFirstLettersController = function($scope, symbolService) {

  const vm = this;

  vm.firstLetters = [];

  vm.chooseLetter = letter => $scope.firstLetterFilter = letter;

  vm.isActiveLetter = letter => $scope.firstLetterFilter === letter;

  vm.chooseAllEmployees = () => $scope.resetFilter();

  vm.isActiveAllEmployees = () => $scope.firstLetterFilter === undefined;

  const initFirstLetters = function() {
    const firstLetters = symbolService.getLowerCaseLetters();
    firstLetters.push('#');
    return vm.firstLetters = firstLetters;
  };

  initFirstLetters();

  return vm;
};

angular.module('public.security-manager.hr.employees').controller('SecurityManagerEmployeesFirstLettersController',
  dependencies.concat(SecurityManagerEmployeesFirstLettersController));
