const dependencies = ['$scope', '$element', '$timeout'];

const PopupsLayoutController = function($scope, $element, $timeout) {

  const OPENED_POPUP_CLASS = 'mod-opened-popup';

  const vm = this;

  vm.isShownPopup = false;

  const htmlElement = document.querySelector('html');

  vm.$onChanges = function() {
    if (!vm.isShownPopup && vm.popupShowCondition) {
      vm.isShownPopup = true;
      setStyles();
      appendElementToBody();
      $timeout(function() {
        $scope.$broadcast('popup.open');
      });
    }
  };

  vm.$onDestroy = function() {
    closePopup();
  };

  vm.closePopup = function(data) {
    closePopup(data);
  };
  
  vm.hidePopup = function() {
    $element[0].classList.add('ng-hide');
  };
  
  vm.showPopup = function() {
    $element[0].classList.remove('ng-hide');
  };

  const appendElementToBody = function() {
    document.body.append($element[0]);
  };

  const setStyles = function() {
    $timeout(() => $element.css('z-index', document.querySelectorAll('.popup-overlay:not(.ng-hide)').length + 990));
    htmlElement.classList.add(OPENED_POPUP_CLASS);
  };

  const closePopup = function({ callbackData, onClose } = {}) {
    htmlElement.classList.remove(OPENED_POPUP_CLASS);
    $scope.$broadcast('popup.close');
    if (onClose) {
      onClose();
    } else {
      vm.popupCloseCb(callbackData);
    }
  };

  return vm;

};

angular.module('shared-components.popup').controller('PopupsLayoutController', dependencies.concat(PopupsLayoutController));
