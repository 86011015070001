/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('data.repeatable-booking').factory('ShiftRuleVersionComment', ['AppModel', (AppModel) => {

  class ShiftRuleVersionComment extends AppModel {
    static initClass() {
      this.configure({
        url: '/repeatable_bookings/{{ repeatableBookingId }}/shift_rules/{{ shiftRuleId }}/versions/{{ versionId }}/comments/{{ id }}',
        name: 'comment'
      });
    }
  }

  ShiftRuleVersionComment.initClass();

  return ShiftRuleVersionComment;

}]);