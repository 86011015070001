/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['currentUserService', 'redirectService'];

const SettingsBillingController = function(currentUserService, redirectService) {

  const vm = this;

  vm.$onInit = function() {
    checkManagerAccessLevel();
  };

  const checkManagerAccessLevel = function () {
    if (!currentUserService.getCurrentProfile().isAccountOwner()) {
      redirectService.redirectToMain();
    }
  };

  return vm;

};

angular.module('public.security-manager.settings')
  .controller('SettingsBillingController', dependencies.concat(SettingsBillingController));
