/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').service('keyService', function() {

  const KEY_TAB = 9;
  const KEY_ENTER = 13;
  const KEY_ESCAPE = 27;

  const keyPressed = function(event, keyCode, modifiers) {
    let result = event.which === keyCode;
    if (modifiers) {
      if (modifiers.alt) { if (result) { result = event.altKey; } }
      if (modifiers.ctrl) { if (result) { result = event.ctrlKey; } }
      if (modifiers.shift) { if (result) { result = event.shiftKey; } }
    }
    return result;
  };

  return {
    tabPressed(event) {
      return keyPressed(event, KEY_TAB);
    },

    enterPressed(event) {
      return keyPressed(event, KEY_ENTER);
    },

    escapePressed(event) {
      return keyPressed(event, KEY_ESCAPE);
    }
  };
});
