/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.security-provider').factory('SecurityProvider', ['AppModel', function(AppModel) {

  class SecurityProvider extends AppModel {
    static initClass() {
      this.configure({ url: '/security_providers', name: 'security_provider' });
    }

    withManager() {
      return SecurityProvider.$get(this.getUrl() + '/with_manager');
    }

    activate() {
      return SecurityProvider.$put(this.getUrl() + '/activate');
    }
  }

  SecurityProvider.initClass();

  return SecurityProvider;

}]);
