/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const bookingShiftEditMode = function() {

  const EDIT_MODE_ONLY_CURRENT = 1;
  const EDIT_MODE_FOLLOWING = 2;

  return {
    getOnlyCurrentMode() {
      return EDIT_MODE_ONLY_CURRENT;
    },

    getFollowingMode() {
      return EDIT_MODE_FOLLOWING;
    }
  };
};

angular.module('data.booking').service('bookingShiftEditMode', bookingShiftEditMode);
