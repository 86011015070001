/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.settings', [
  'data.user',
  'data.security-manager',
  'data.resource',
  'data.home-zone',
  'data.role',
  'data.document',
  'popup.security-manager',
  'popup.role'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.settings', {
      abstract: true,
      url: '/settings',
      template: require('./template.pug'),
      controller: 'SettingsLayoutController',
      controllerAs: 'settingsLayoutCtrl'
    })
    .state('public.security-manager.settings.roles', {
      url: '/roles',
      template: require('./roles/template.pug'),
      controller: 'SettingsRolesController',
      controllerAs: 'settingsRolesCtrl'
    })
    .state('public.security-manager.settings.company-details', {
      url: '/company-details',
      template: require('./company-details/template.pug'),
      controller: 'SettingsCompanyDetailsController',
      controllerAs: 'settingsCompanyDetailsCtrl'
    })
    .state('public.security-manager.settings.payment', {
      url: '/payment-to-personnel',
      template: require('./payment/template.pug'),
      controller: 'SettingsPaymentController',
      controllerAs: 'settingsPaymentCtrl'
    })
    .state('public.security-manager.settings.email', {
      url: '/email',
      template: require('./email/template.pug'),
      controller: 'SettingsEmailController',
      controllerAs: 'settingsEmailCtrl'
    })
    .state('public.security-manager.settings.home-zone', {
      url: '/home-zone',
      template: require('./home-zone/template.pug'),
      controller: 'SettingsHomeZoneController',
      controllerAs: 'settingsHomeZoneCtrl'
    })
    .state('public.security-manager.settings.settings', {
      url: '/settings',
      template: require('./settings/template.pug'),
      controller: 'SettingsSettingsController',
      controllerAs: 'settingsSettingsCtrl'
    })
    .state('public.security-manager.settings.document-types', {
      url: '/document-types?{ type }',
      template: require('./document-types/template.pug'),
      controller: 'SettingsDocumentTypesController',
      controllerAs: 'settingsDocumentTypesCtrl',
      reloadOnSearch: true
    })
    .state('public.security-manager.settings.billing', {
      url: '/billing',
      template: require('./billing/template.html'),
      controller: 'SettingsBillingController',
      controllerAs: 'SettingsBillingCtrl'
    })

]);
