/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
angular.module('public.client-manager.company', []).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.client-manager.company', {
      abstract: true,
      url: '/company',
      template: require('./template.pug'),
      controller: 'ClientManagerCompanyLayoutController',
      controllerAs: 'clientManagerCompanyLayoutCtrl'
    })
    .state('public.client-manager.company.details', {
      url: '/company-details',
      template: require('./details/template.pug'),
      controller: 'ClientManagerCompanyDetailsController',
      controllerAs: 'clientManagerCompanyDetailsCtrl'
    })

]);
