/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [];

const planningNotificationService = function() {

  let _isShownQuitMessage = false;
  const _isShownUndoMessage = false;

  return {
    showQuitMessage() {
      return _isShownQuitMessage = true;
    },

    hideQuitMessage() {
      return _isShownQuitMessage = false;
    },

    isShownQuitMessage() {
      return _isShownQuitMessage;
    },

    isShownUndoMessage() {
      return _isShownUndoMessage;
    },

    areShownMessages() {
      return _isShownQuitMessage || _isShownUndoMessage;
    }
  };
};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .service('planningNotificationService', dependencies.concat(planningNotificationService));
