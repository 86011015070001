/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager').service('securityManagerStateAccessService', [
  '$state', 'currentUserService',
  function($state, currentUserService) {

    const canViewCurrentState = function() {
      if (currentUserService.getCurrentProfile().isSupervisor()) {
        return $state.includes('public.security-manager.notifications') ||
          $state.includes('public.security-manager.profile') ||
          $state.includes('public.security-manager.schedule-manager') ||
          $state.includes('public.security-manager.settings.company-details');
      } else {
        return true;
      }
    };

    return {canViewCurrentState};
  }

]);
