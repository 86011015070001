const dependencies = [];

const EmployeeProfileApproveResourceController = function() {

  const vm = this;

  vm.getPayRate = function() {
    if (vm.employeeResource && vm.employeeResource.payRate !== null) {
      return `£ ${vm.employeeResource.payRate}`;
    } else {
      return '';
    }
  };
  return vm;

};

angular.module('public.security-manager.employee')
  .controller('EmployeeProfileApproveResourceController', dependencies.concat(EmployeeProfileApproveResourceController));
