angular.module('data.employee-invoice').factory('EmployeeInvoiceVersion', [
  'AppModel', 'getParamsService',
  function(AppModel, getParamsService) {

    class EmployeeInvoiceVersion extends AppModel {
      static initClass() {
        this.configure({ url: '/employee_invoices/{{invoiceId}}/versions/{{id}}', name: 'version' });
      }

      static getPdfUrl(params, getParams, options = {}) {
        return this.resourceUrl(params, `/pdf_version.${options.format || 'pdf'}${getParamsService.buildUrl(getParams)}`, options);
      }
    }

    EmployeeInvoiceVersion.initClass();

    return EmployeeInvoiceVersion;

  }
]);
