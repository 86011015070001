/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'dateWeek'];

const MultipleButtonsController = function($scope, dateWeek) {

  const vm = this;

  vm.dateWeek = dateWeek;

  vm.isActive = button => $scope.chosenButtons.indexOf(button - 0) !== -1;

  vm.toggle = function(button) {
    button -= 0;
    if ($scope.isSingle) {
      if (!vm.isActive(button)) {
        $scope.chosenButtons = [button];
        callOnChangeCb();
      }
    } else {
      if (vm.isActive(button)) {
        if (!($scope.isRequired && $scope.chosenButtons.length === 1)) {
          for (let index = 0; index < $scope.chosenButtons.length; index++){
            if ($scope.chosenButtons[index] === button) {
              $scope.chosenButtons.splice(index, 1);
              callOnChangeCb();
              break;
            }
          }
        }
      } else {
        $scope.chosenButtons.push(button);
        callOnChangeCb();
      }
    }
  };

  const callOnChangeCb = function() {
    if ($scope.onChangeCb) {
      $scope.onChangeCb();
    }
  };

  return vm;
};

angular.module('gccs-ui').controller('MultipleButtonsController', dependencies.concat(MultipleButtonsController));
