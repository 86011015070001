/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['employeeStatus'];

const ReportsHrEmployeeStatusController = function(employeeStatus) {

  const vm = this;

  vm.$onChanges = function() {
    vm.statusText = employeeStatus.getName(vm.status);
    vm.statusClass = employeeStatus.isActive(vm.status) ? '' : 'mod-red';
  };

  return vm;

};

angular.module('public.security-manager.reports.hr')
  .controller('ReportsHrEmployeeStatusController', dependencies.concat(ReportsHrEmployeeStatusController));
