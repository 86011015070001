const dependencies = ['$state', 'sessionService', 'redirectService', 'storageService'];

const SignLoginController = function($state, sessionService, redirectService, storageService) {

  const vm = this;

  vm.user = {};
  vm.flashErrors = {};
  vm.rateLimited = false;
  let isFormSubmitted = false;

  vm.login = function(invalid) {
    if (invalid || isFormSubmitted) { return; }
    vm.rateLimited = false;
    isFormSubmitted = true;
    sessionService.login(vm.user, () => redirectService.redirectToMain(), function(response) {
      if(response.status === 429)
      {
        vm.rateLimited = true;
        vm.flashErrors = {};
        isFormSubmitted = false;
        return;
      }
      const responseData = response.data;
      if (responseData.package_error) {
        storageService.set('packageError', JSON.stringify(responseData));
        $state.go('sign.payment-message');
      } else {
        vm.flashErrors[responseData.field] = responseData.error;
        isFormSubmitted = false;
      }
    });
  };

  return vm;

};

angular.module('sign').controller('SignLoginController', dependencies.concat(SignLoginController));
