/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$timeout', 'securityManagerAccessLevel', 'notificationService', 'currentUserService',
  'userValidation', 'stringService', 'HomeZone', 'Client', 'User'
];

const PopupSecurityManagerFormController = function(
  $scope, $timeout, securityManagerAccessLevel, notificationService, currentUserService,
  userValidation, stringService, HomeZone, Client, User
) {

  const vm = this;

  vm.securityManagerAccessLevel = securityManagerAccessLevel;

  const currentSecurityManagerAccessLevel = currentUserService.getCurrentProfile().accessLevel;
  let isInvitation = false;

  vm.getAccessLevelNames = () => vm.securityManagerAccessLevel.getNamesFor(currentSecurityManagerAccessLevel);

  vm.getEmailErrors = () => {
    return userValidation.getEmailErrors($scope.securityManager.user && $scope.securityManager.user.id);
  };

  const initSecurityManager = function() {
    if ($scope.securityManager.isNew()) {
      isInvitation = true;
      vm.securityManager = $scope.securityManager;
      $scope.popupFormCtrl.title = 'Invite new manager';
      $scope.popupFormCtrl.saveButtonText = 'Invite manager';
    } else {
      vm.securityManager = angular.copy($scope.securityManager);
      $scope.popupFormCtrl.title = 'Edit managers profile';
      $scope.popupFormCtrl.saveButtonText = 'Save';
    }
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) { initSecurityManager(); }
  });

  $scope.$on('popup.submit', () => {
    let user = new User(vm.securityManager.user);
    user.save().then((user) => {
      vm.securityManager.user = user;
      vm.securityManager.save().then(securityManager => {
        $scope.popupCtrl.closePopup(function () {
          $scope.popupCloseCb({ securityManager });
          if (isInvitation) {
            notificationService.notifySuccess(`Manager ${securityManager.getFullName()} invited!`);
          }
        });
      })
    });
  });

  return vm;

};

angular.module('popup.security-manager')
  .controller('PopupSecurityManagerFormController', dependencies.concat(PopupSecurityManagerFormController));
