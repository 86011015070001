angular.module('shared').directive('fixedHeader', () => {
  return {
    restrict : 'A',
    link($scope, $el) {
      const el = $el[0];

      const onScroll = function() {
        if (window.pageYOffset > 0) {
          return el.classList.add('is-active');
        } else {
          return el.classList.remove('is-active');
        }
      };

      window.addEventListener('scroll', onScroll);

      return $scope.$on('$destroy', () => window.removeEventListener('scroll', onScroll));
    }
  }
});
