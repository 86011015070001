/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$state', 'EmployeePayroll', 'employeePayrollStatus', 'pluralizeService', 'dateService'
];

const InvoicesPayrollPreviewController = function(
  $state, EmployeePayroll, employeePayrollStatus, pluralizeService, dateService
) {

  const vm = this;

  const CHANGE_STATUS_NOT_CHANGED = 1;
  const CHANGE_STATUS_ADDED = 2;
  const CHANGE_STATUS_REMOVED = 3;

  vm.currentVersionIndex = 0;

  vm.$onInit = function () {
    vm.editable = true;
    EmployeePayroll.get($state.params.id, { all_versions: true }).then(function (payroll) {
      vm.payroll = payroll;
      vm.payrollVersions = _.orderBy(vm.payroll.versions, 'id', 'desc');
      _.each(vm.payrollVersions, function (payrollVersion) {
        payrollVersion.totalAmount = _.reduce(payrollVersion.invoices, function (totalAmount, invoice) {
          return totalAmount + invoice.latestVersion.totalAmount;
        }, 0);
      });
      initVisibilityOptions();
      initChanges();
    });
  };

  vm.chooseVersion = index => vm.currentVersionIndex = index;

  vm.getCurrentVersion = () => vm.payrollVersions[vm.currentVersionIndex];

  vm.getPreviousVersion = () => vm.payrollVersions[vm.currentVersionIndex + 1];

  vm.getCurrentStatus = () => employeePayrollStatus.getName(vm.getCurrentVersion().status);

  vm.getCurrentStatusClass = () => employeePayrollStatus.getClassByStatus(vm.getCurrentVersion().status);

  vm.getTotalText = () => pluralizeService.simple(vm.payrollVersions.length, 'version');

  vm.getFormattedCreatedAt = function(version) {
    if (!version.createdAtMoment) {
      version.createdAtMoment = moment(version.createdAt, dateService.getIsoFullDateFormatWithTz());
    }
    return version.createdAtMoment.format('MMM DD, YYYY, HH:mm');
  };

  vm.getCreatorFullName = version => [version.creator.firstName, version.creator.lastName].join(' ');

  vm.getPeriod = function(invoice) {
    if (!invoice.period) {
      const startDateMoment = moment(invoice.startDate, dateService.getIsoDateFormat());
      const endDateMoment = moment(invoice.endDate, dateService.getIsoDateFormat());
      invoice.period = `${startDateMoment.format(dateService.getDateFormat())} - ${endDateMoment.format(dateService.getDateFormat())}`;
    }
    return invoice.period;
  };

  vm.goBack = () => $state.go('public.security-manager.invoices.payrolls');

  vm.totalAmountChanged = () => !isFirstVersion() && (vm.getPreviousVersion().totalAmount !== vm.getCurrentVersion().totalAmount);

  vm.invoiceWasAdded = invoice => invoice.changeStatus === CHANGE_STATUS_ADDED;

  vm.invoiceWasRemoved = invoice => invoice.changeStatus === CHANGE_STATUS_REMOVED;

  const initVisibilityOptions = () => vm.isShownEditButton = vm.payroll.versions[vm.payroll.versions.length - 1].status !== employeePayrollStatus.getPaidStatus();

  const isFirstVersion = () => (vm.currentVersionIndex + 1) === vm.payrollVersions.length;

  const initChanges = () =>
    (() => {
      const result = [];
      for (var index = 0, end = vm.payrollVersions.length - 1, asc = 0 <= end; asc ? index <= end : index >= end; asc ? index++ : index--) {
        vm.payrollVersions[index].allInvoices = _.clone(vm.payrollVersions[index].invoices);
        if ((index + 1) < vm.payrollVersions.length) {
          _.each(vm.payrollVersions[index].allInvoices, invoice => invoice.changeStatus = CHANGE_STATUS_ADDED);
          result.push(_.each(vm.payrollVersions[index + 1].invoices, function(invoice) {
            const foundInvoice = _.find(vm.payrollVersions[index].allInvoices, invoice, 'id');
            if (foundInvoice) {
              return foundInvoice.changeStatus = CHANGE_STATUS_NOT_CHANGED;
            } else {
              const removedInvoice = _.clone(invoice);
              removedInvoice.changeStatus = CHANGE_STATUS_REMOVED;
              return vm.payrollVersions[index].allInvoices.push(removedInvoice);
            }
          }));
        } else {
          result.push(undefined);
        }
      }
      return result;
    })()
  ;

  return vm;
};

angular.module('public.security-manager.invoices.payroll')
  .controller('InvoicesPayrollPreviewController', dependencies.concat(InvoicesPayrollPreviewController));
