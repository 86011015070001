/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', '$timeout', 'EmployeeDayOff', 'SecurityManagerNotification', 'Employee', 'EmployeeAvailability',
  'dateService', 'employeeType', 'bookingShiftValidationService', 'elementService', 'notificationService',
  'confirmService', 'pluralizeService', 'employeeDayOffRequestStatus', 'employeeHolidayService',
  'EmployeeTimelineAvailabilityDecorator', 'employeeEventType', 'EmployeeSickDay'
];

const ScheduleManagerTimelineEmployeeDayOffPopupController = function(
  $scope, $timeout, EmployeeDayOff, SecurityManagerNotification, Employee, EmployeeAvailability,
  dateService, employeeType, bookingShiftValidationService, elementService, notificationService,
  confirmService, pluralizeService, employeeDayOffRequestStatus, employeeHolidayService,
  EmployeeTimelineAvailabilityDecorator, employeeEventType, EmployeeSickDay
) {

  const vm = this;

  const MINIMUM_HOLIDAY_HOURS = 12;
  
  vm.pluralizeService = pluralizeService;
  vm.isConfirmPopupShowed = false;

  let isFormSubmitted = false;

  vm.$onInit = function() {
    initAvailabilityTitle();
    initDayOff();
    initDateTimes();
  };

  vm.actionTaken = function() {
    if (!vm.dayOffClone.status) { return false; }
    return employeeDayOffRequestStatus.isActionTaken(vm.dayOffClone.status);
  };

  vm.isApproved = () => employeeDayOffRequestStatus.isApproved(vm.dayOffClone.status);

  vm.getPosition = () => vm.position;

  vm.delete = function() {
    vm.isConfirmPopupShowed = true;
    return confirmService.confirm(
      'Confirm reject',
      `Do you really want to reject ${getTypeName()}? This action can't be undone.`,
      'Yes',
      function() {
        const dayOff = prepareDayOff(vm.dayOffClone);
        const action = vm.isAvailabilityEventType() ? dayOff.remove : dayOff.reject;
        return action.call(dayOff).then(data => vm.onDelete({$event: { dayOff: data }})).catch(function(error) {
          vm.isConfirmPopupShowed = false;
          return Rollbar.log(`Schedule manager ${getTypeName()} remove on ${window.location.href}`, error);});
    });
  };

  vm.save = function() {
    if (isFormSubmitted) { return; }
    if ($scope.dayOffForm.$invalid) {
      if ($scope.dayOffForm.$error.period) {
        notificationService.notifyError('Please select another period');
      } else if (!vm.isAvailabilityEventType()) {
        if ($scope.dayOffForm.name.$invalid) {
          return focusName();
        } else if ($scope.dayOffForm.paidDays.$invalid) {
          return notificationService.notifyError('Number of paid days exceeds the period');
        }
      }
    } else {
      isFormSubmitted = true;
      const dayOff = prepareDayOff(vm.dayOffClone);
      return dayOff.save()
        .then(data => vm.onSave({ $event: { dayOff: data }})).catch(function(error) {
          isFormSubmitted = false;
          notificationService.notifyError(error.data.error);
          return Rollbar.log(`Schedule manager day off save on ${window.location.href}`, error);
      });
    }
  };

  vm.getDateErrors = () => bookingShiftValidationService.getDateErrors(hoursData);

  vm.getStartTimeErrors = () => bookingShiftValidationService.getStartTimeErrors(hoursData);

  vm.getEndTimeErrors = () => bookingShiftValidationService.getEndTimeErrors(hoursData);

  vm.tookMoreHolidays = () => getRemainingHolidayHours() < 0;

  vm.getMaxPaidDays = function() {
    const startTimeMoment = moment(`${vm.dayOffClone.startDate} ${vm.dayOffClone.startDayTime}`, dateService.getFullDateFormat());
    const endTimeMoment = moment(`${vm.dayOffClone.endDate} ${vm.dayOffClone.endDayTime}`, dateService.getFullDateFormat()).add(1, 'minute');
    const maxPaidDays = Math.floor(endTimeMoment.diff(startTimeMoment, 'days', true));
    if (maxPaidDays > 0) {
      return maxPaidDays;
    } else {
      const hoursDiff = endTimeMoment.diff(startTimeMoment, 'hours', true);
      return hoursDiff >= MINIMUM_HOLIDAY_HOURS ? 1 : 0;
    }
  };

  vm.getMaxPaidHoursPerDay = () => employeeHolidayService.getDefaultHolidaysHours();

  vm.approve = function() {
    const dayOff = prepareDayOff(vm.dayOffClone);
    return dayOff.approve().then(() => vm.onBodyClickCb()).catch(function(error) {
      notificationService.notifyError("Can't approve day off for this time");
      return Rollbar.log(`Schedule manager day off approve on ${window.location.href}`, error);
    });
  };

  vm.isEmployed = () => employeeType.isEmployed(vm.employee.employmentType);

  vm.getTakenText = function() {
    const takenDays = vm.holidaysData.takenHolidays.days + getPaidDaysDiff();
    const takenHours = vm.holidaysData.takenHolidays.hours + getPaidHoursDiff();
    return `${pluralizeService.simple(takenDays, 'day')} (${takenHours} h.)`;
  };

  vm.getRemainingText = () => {
    return `${pluralizeService.simple(getRemainingHolidayDays(), 'day')} (${getRemainingHolidayHours()} h.)`;
  };

  vm.isAvailability = function () {
    return vm.dayOffClone instanceof EmployeeTimelineAvailabilityDecorator;
  };

  vm.isAvailabilityEventType = function() {
    return employeeEventType.isAvailabilityEventType(vm.eventOptions.getSelectedType());
  };

  vm.isSickDayEventType = function() {
    return employeeEventType.isSickDayEventType(vm.eventOptions.getSelectedType());
  };

  vm.isDayOffEventType = function() {
    return employeeEventType.isDayOffEventType(vm.eventOptions.getSelectedType());
  };

  vm.getSickDaysText = function() {
    if (vm.takenSickDayHours >= 0) {
      const takenSickDays = Math.floor(vm.takenSickDayHours / employeeHolidayService.getDefaultSickDaysHours());
      return `${pluralizeService.simple(takenSickDays, 'day')} (${vm.takenSickDayHours} h.)`;
    }
  };

  vm.getPlaceholder = function() {
    if (vm.isSickDayEventType()) {
      return 'Sick day description...';
    } else if (vm.isDayOffEventType()) {
      return 'Name of day off...';
    } else {
      return 'Add name...';
    }
  };

  const getTypeName = function() {
    return (employeeEventType.getName(vm.eventOptions.getSelectedType()).toLowerCase());
  };

  const initAvailabilityTitle = function () {
    if (!vm.isAvailabilityEventType()) {
      return;
    }
    if (!vm.isEditing) {
      vm.availabilityTitle = 'Add availability';
    } else {
      vm.availabilityTitle = 'Availability';
    }
  };

  const initDayOff = function() {
    vm.dayOffClone = angular.copy(vm.dayOff);
    if (vm.dayOffClone.isTemporary) {
      vm.dayOffClone.paidDays = 0;
      if (vm.isEmployed()) {
        vm.dayOffClone.paidHoursPerDay = employeeHolidayService.getDefaultHolidaysHours();
        Employee.getMinPaidRate(vm.dayOffClone.employeeId).then(paidRate => vm.dayOffClone.paidRate = paidRate);
      }
    }
    if (vm.isEmployed()) {
      Employee.getHolidaysData(vm.dayOffClone.employeeId, {
        from: vm.dayOffClone.timelineStartTime.format(dateService.getIsoDateFormat())
      }).then(holidaysData => vm.holidaysData = holidaysData);
    }
    if (vm.isSickDayEventType()) {
      vm.takenSickDayHours = null;
      Employee.get(vm.employee.id).then(employee => {
        vm.takenSickDayHours = employee.takenSickDayHours;
      });
    }
  };

  const getRemainingHolidayDays = () => {
    return employeeHolidayService.getRemainingHolidayDays(
      vm.holidaysData.accumulatedHours,
      vm.holidaysData.takenHolidays.hours + getPaidHoursDiff()
    );
  };

  const getRemainingHolidayHours = () => {
    return vm.holidaysData.accumulatedHours - (vm.holidaysData.takenHolidays.hours + getPaidHoursDiff());
  };

  const getPaidDaysDiff = () => (vm.dayOffClone.paidDays || 0) - (vm.dayOff.paidDays || 0);

  const getPaidHoursDiff = () => getPaidDaysDiff() * vm.dayOffClone.paidHoursPerDay;

  const splitTime = function(dateTime) {
    const time = moment(dateTime, dateService.getIsoFullDateFormat());
    const dateString = time.format(dateService.getDateFormat());
    const timeString = time.format(dateService.getTimeFormat());
    return [dateString, timeString];
  };

  const buildTime = (date, time) => [date, time].join(' ');

  const prepareDayOff = function(dayOffClone) {
    const params = {
      id: dayOffClone.id,
      name: dayOffClone.name,
      employeeId: dayOffClone.employeeId,
      startTime: buildTime(dayOffClone.startDate, dayOffClone.startDayTime),
      endTime: buildTime(dayOffClone.endDate, dayOffClone.endDayTime)
    };

    if (vm.isAvailabilityEventType()) {
      return new EmployeeAvailability(params);
    } else if (vm.isSickDayEventType()) {
      return new EmployeeSickDay(params);
    } else {
      if (vm.isEmployed()) {
        params.paidDays = dayOffClone.paidDays;
        params.paidHoursPerDay = dayOffClone.paidHoursPerDay;
        params.paidRate = dayOffClone.paidRate;
      }
      return new EmployeeDayOff(params);
    }
  };

  const hoursData = () => {
    const hoursObj = {
      minHours: 1
    };
    if (!vm.isAvailabilityEventType()) {
      hoursObj.maxHours = 24 * 31;
    }
    return hoursObj;
  };

  const initDateTimes = function() {
    [vm.dayOffClone.startDate, vm.dayOffClone.startDayTime] = Array.from(splitTime(vm.dayOffClone.getStartTime()));
    [vm.dayOffClone.endDate, vm.dayOffClone.endDayTime] = Array.from(splitTime(vm.dayOffClone.getEndTime()));
  };

  const focusName = () => {
    return setTimeout(() => elementService.focus($scope.$popup[0].querySelector('[name="name"]')), 0);
  };

  return vm;
};

angular
  .module('public.security-manager.schedule-manager.timeline')
  .controller('ScheduleManagerTimelineEmployeeDayOffPopupController',
    dependencies.concat(ScheduleManagerTimelineEmployeeDayOffPopupController));
