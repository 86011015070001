/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['anchorSmoothScroll'];

const gccsListScrollTo = (anchorSmoothScroll) => {
  return {
    restrict: 'A',
    link($scope, $el) {
      const element = $el[0];
      const group = element.closest('.page-notifications-preview-group');
      const list = group.closest('.page-notifications-preview-list');


      const onClick = function() {
        anchorSmoothScroll.scrollToY(list.scrollTop, group.offsetTop, list);
      };

      element.addEventListener('click', onClick)
    }
  }
};

angular.module('shared').directive('gccsListScrollTo', dependencies.concat(gccsListScrollTo));
