/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope'];

const PopupEmployeeClientApproveFormController = function($scope) {

  const vm = this;

  $scope.popupFormCtrl.title = 'Add & approve client';
  $scope.popupFormCtrl.withoutCancelButton = true;

  const initData = function() {
    vm.employee = $scope.employee;
    vm.client = $scope.client;
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) { return initData(); }
  });

  $scope.$on('popup.submit', () => $scope.popupCtrl.closePopup($scope.popupCloseCb));

  return vm;
};

angular.module('popup.employee').controller('PopupEmployeeClientApproveFormController',
  dependencies.concat(PopupEmployeeClientApproveFormController));
