/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = ['$scope', 'dateService', 'FormDatePickerRangeRangeSelector'];

const FormDatePickerRangeController = function($scope, dateService, FormDatePickerRangeRangeSelector) {

  const vm = this;

  vm.isShowedDatePicker = false;
  vm.format = dateService.getDateFormat();

  let dataRange = [];
  const checkboxModel = $scope.startDateModel && !$scope.endDateModel;

  vm.customData = {
    canBeWithoutEnd : $scope.canBeWithoutEnd,
    withDateRanges : $scope.withDateRanges,
    checkboxTitle : $scope.checkboxTitle,
    checkboxModel,
    onlyStartDateModel : dateService.today(),
    onSubmit() {
      return vm.hideDatePicker();
    },
    onCancel() {
      vm.startDateModel = moment(initialStartDate);
      return vm.endDateModel = moment(initialEndDate);
    }
  };

  vm.toggleDatePicker = () => vm.isShowedDatePicker = !vm.isShowedDatePicker;

  const hideDatePicker = function() {
    vm.isShowedDatePicker = false;
    if ($scope.canBeWithoutEnd && vm.customData.checkboxModel) {
      $scope.startDateModel = vm.customData.onlyStartDateModel.format(vm.format);
      return $scope.endDateModel = null;
    }
  };

  vm.hideDatePicker = function() {
    if (vm.isShowedDatePicker) {
      hideDatePicker();
      return $scope.onClose();
    }
  };

  vm.getLabel = function() {
    if ($scope.withDateRanges) {
      const rangeSelector = vm.customData.rangeSelector;
      if (rangeSelector.isCustom()) {
        return `${vm.startDateModel.format(dateService.getShortTextFormat())} - ${vm.endDateModel.format(dateService.getShortTextFormat())}`;
      } else {
        return rangeSelector.getLabel();
      }
    } else {
      let labelParts;
      if ($scope.canBeWithoutEnd && vm.customData.checkboxModel) {
        labelParts = [vm.customData.onlyStartDateModel.format(vm.format), 'Now'];
      } else {
        labelParts = [$scope.startDateModel, $scope.endDateModel];
      }
      return labelParts.join(' - ');
    }
  };

  vm.dateRange = function() {
    if (arguments.length === 0) {
      const newDateRange = [vm.startDateModel, vm.endDateModel];
      if (!_.isEqual(newDateRange, dataRange)) { dataRange = newDateRange; }
      return dataRange;
    }
  };

  const initDateModel = function(name) {
    if ($scope[name]) {
      vm[name] = moment($scope[name], [dateService.getIsoDateFormat(), vm.format]);
    } else {
      vm[name] = dateService.today();
    }
    $scope.$watch(() => vm[name], () => $scope[name] = vm[name] != null ? vm[name].format(vm.format) : undefined, true);
  };

  initDateModel('startDateModel');
  initDateModel('endDateModel');

  const initialStartDate = moment(vm.startDateModel);
  const initialEndDate = moment(vm.endDateModel);

  const setStartDateModel = startDate => vm.startDateModel = startDate;

  const setEndDateModel = endDate => vm.endDateModel = endDate;

  $scope.onDateChange = function() {
    if ($scope.withDateRanges) { vm.customData.rangeSelector.setCustom(); }
  };

  if ($scope.withDateRanges) {
    vm.customData.rangeSelector = new FormDatePickerRangeRangeSelector(
      dateService.today(),
      vm.startDateModel,
      vm.endDateModel,
      setStartDateModel,
      setEndDateModel
    );
    vm.customData.rangeSelector.smartSelect();
  }

  return vm;
};

angular.module('form').controller('FormDatePickerRangeController', dependencies.concat(FormDatePickerRangeController));
