/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.client').factory('ClientServiceContactRoot', ['AppModel', function(AppModel) {

  class ClientServiceContactRoot extends AppModel {
    static initClass() {
      this.configure({ url: '/clients/{{clientId}}/service_contacts/{{id}}' });
    }
  }

  ClientServiceContactRoot.initClass();

  return ClientServiceContactRoot;

}]);
