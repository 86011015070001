/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [];

const SecurityManagerNotificationsPageTabsController = function() {

  const vm = this;

  vm.onMouseEnterTab = function(tab) {
    tab.isShownMarkAllDropdown = true;
  };

  vm.onMouseLeaveTab = function(tab) {
    tab.isShownMarkAllDropdown = false;
  };

  vm.isShownMarkAllMenu = function(tab) {
    return tab.isShownMarkAllDropdown || tab.isOpenedMenuDropdown;
  };

  vm.openMarkTabAllRead = function(tab) {
    tab.isOpenedMenuDropdown = true;
  };

  vm.closeMarkTabAllRead = function(tab) {
    tab.isOpenedMenuDropdown = false;
  };

  vm.onMarkAllReadClick = function(tab) {
    vm.onMouseLeaveTab(tab);
    vm.closeMarkTabAllRead(tab);
    vm.markTabAllRead({ tab: tab })
  };

  return vm;

};

angular.module('public.security-manager.notifications').controller('SecurityManagerNotificationsPageTabsController',
  dependencies.concat(SecurityManagerNotificationsPageTabsController));
