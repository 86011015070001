/*
 * @author Igor Serpak <robobee>
 */
angular.module('gccs-ui').directive('gccsSelectFiltered', () => {
  return {
    restrict: 'A',
    controller: 'SelectFilteredController',
    controllerAs: '$ctrl',
    template: require('./template.pug'),
    bindToController: true,
    scope: {
      selectClassName: '@',
      withoutDefaultClass: '@',
      name: '@',
      filterPlaceholder: '@',
      searchInputClassName: '@',
      withIcons: '<',
      selectedItem: '<',
      selectListItems: '<',
      selectedListItems: '<',
      withoutRightAlign: '<',
      onSelect: '&'
    }
  };
});
