const gccsEmployeeTimelineShiftDetails = {
  controller: 'EmployeeTimelineShiftDetailsController',
  template: require('./template.pug'),
  bindings: {
    timelineItem: '<',
    isShownDeclineMode: '<',
    reasonToDecline: '='
  }
};

angular.module('public.employee.timeline')
  .component('gccsEmployeeTimelineShiftDetails', gccsEmployeeTimelineShiftDetails);
