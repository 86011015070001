angular.module('gccs-ui').directive('gccsNotification', () => {
  return {
    restrict: 'A',
    controller: 'NotificationController',
    controllerAs: 'notificationCtrl',
    template: require('./template.pug'),
    scope: {
      holderClassName: '@?'
    }
  };
});
