/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('shared').service('userAgentService', function() {

  const userAgent = navigator.userAgent;

  return {
    isAndroid() {
      return userAgent.match(/Android/i);
    },

    isIOS() {
      return userAgent.match(/iPhone|iPad|iPod/i);
    },

    isMobile() {
      return this.isAndroid() || this.isIOS();
    },

    getAppLink() {
      if (this.isAndroid()) {
        return 'https://play.google.com/store/apps/details?id=gccs.mobile';
      } else if (this.isIOS()) {
        return 'https://itunes.apple.com/app/goplanr/id1202929922';
      } else {
        return '#';
      }
    }
  };
});
