angular.module('public.security-manager.client.resource').component('gccsClientResourceTableItem', {
  template: require('./template.pug'),
  controller: 'ClientResourceTableItemController',
  transclude: true,
  bindings: {
    resource: '<',
    isForm: '<',
    isNew: '<',
    form: '<',
    isEditable: '<',
    onEditCb: '&',
    onDeleteCb: '&',
    onSaveCb: '&',
    onCancelCb: '&'
  }
});
