/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['$scope', 'bookingShiftTitleEditMode'];

const PopupBookingShiftTitleEditController = function($scope, bookingShiftTitleEditMode) {

  const vm = this;

  $scope.popupFormCtrl.isShortPopup = true;
  $scope.popupFormCtrl.title = "Copy shift's title";

  vm.editMode = bookingShiftTitleEditMode.getOnlyCurrentMode();
  vm.radioButtons = [{
    title: 'Only this shift',
    sign: 'Only this shift will have the new title.',
    value: bookingShiftTitleEditMode.getOnlyCurrentMode()
  }, {
    title: 'All edited shifts',
    sign: 'All edited shifts in the series will have new title.',
    value: bookingShiftTitleEditMode.getAllEditedMode()
  }];

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      return vm.editMode = bookingShiftTitleEditMode.getOnlyCurrentMode();
    }
  });

  $scope.$on('popup.close', () => $scope.titleEditModeService.onFail());

  $scope.$on('popup.submit', function() {
    $scope.titleEditModeService.onSuccess(vm.editMode);
    return $scope.popupCtrl.closePopup();
  });

  return vm;

};

angular.module('popup.booking')
  .controller('PopupBookingShiftTitleEditController', dependencies.concat(PopupBookingShiftTitleEditController));
