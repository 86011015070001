/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.booking').directive('gccsPopupBookingQuickAddShift', [
  'popupDirectiveConfig',
  popupDirectiveConfig =>
    popupDirectiveConfig.configure({
      bodyTemplate: require('./template.pug'),
      scope: {
        booking: '<',
        onApplyNewShiftResource: '&'
      }
    })
]);
