/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['EmployeeCardVersion', 'SessionToken', 'employeeCardVersionPrintStatus'];

const EmployeeCardVersionController = function(EmployeeCardVersion, SessionToken, employeeCardVersionPrintStatus) {

  const vm = this;

  vm.classes = employeeCardVersionPrintStatus.getClasses();
  vm.printStatusNames = employeeCardVersionPrintStatus.getNames();
  vm.employeeCardVersionPrintStatus = employeeCardVersionPrintStatus;

  let _sessionToken;

  vm.$onInit = function() {
    initPdfUrl();
  };

  vm.changeStatus = function(newStatus) {
    if (vm.cardVersion.printStatus === +newStatus) { return; }
    const newVersion = new EmployeeCardVersion({
      id: vm.cardVersion.id,
      cardId: vm.cardVersion.employeeCardId,
      printStatus: newStatus
    });
    return newVersion.save().then(newVersion => vm.onStatusChange({ newVersion }));
  };

  vm.getCardPdfUrl = function (options) {
    return EmployeeCardVersion.getPdfUrl({
      id: vm.cardVersion.id,
      cardId: vm.cardVersion.employeeCardId
    }, {
      token_id: _sessionToken.id,
      token: _sessionToken.token
    }, options);
  };

  vm.printCard = function() {
    try {
      printJS({
        printable: vm.getCardPdfUrl({ withDomain: false }),
        onLoadingStart: vm.onPrintStart,
        onLoadingEnd: vm.onPrintEnd
      });
    } catch (error) {
      vm.onPrintEnd();
    }
    vm.changeStatus(vm.employeeCardVersionPrintStatus.getPrintedStatus());
  };

  vm.showPdf = function () {
    vm.isShownPdf = true;
  };

  vm.closePdf = function () {
    vm.isShownPdf = false;
  };

  vm.isLoadedSessionToken = function() {
    return _sessionToken !== undefined;
  };

  const initPdfUrl = function() {
    new SessionToken({ entityId: vm.cardVersion.id, entityType: 'card' }).save().then(function(sessionToken) {
      _sessionToken = sessionToken;
    }).catch(function(error) {
      Rollbar.log(`Can not create session token on ${window.location.href}`, error);
    });
  };

  return vm;

};

angular
  .module('public.security-manager.hr.cards')
  .controller('EmployeeCardVersionController', dependencies.concat(EmployeeCardVersionController));
