const dependencies = ['ExtendedFilterService', 'packageTypeService', 'clientStatus'];

const ExtendedFilterBookingService = (ExtendedFilterService, packageTypeService, clientStatus) => {

  const PARAM_CLIENTS = 'client_ids[]';

  return class ExtendedFilterBookingService extends ExtendedFilterService {

    constructor(filterData) {
      super(...arguments);
      this.filterData = filterData;
      this.searchString = '';
      this.clientCategories = this.getClientCategories();
    }

    setFilterType(filterType) {
      return this.filterType = filterType;
    }

    setClients(clients, selectedClients) {
      this.clients = clients;
      this.selectedClients = selectedClients;
      this._initCategory(PARAM_CLIENTS, this.filterData);
      this._updateQueryParams();
      this._updateTopPanel();
    }

    isClientCategory(category) {
      return category.params === PARAM_CLIENTS;
    }

    applyFilter(...args) {
      const val = args[0],
        obj = val != null ? val : {},
        val1 = obj.isFirstApply,
        isFirstApply = val1 != null ? val1 : false;
      this.applyFilterCb({
        queryParams: this.queryParams,
        isFirstApply
      });
      this.close();
    }

    getClientCategories() {
      return [
        { title: 'Active clients (A - Z)', status: clientStatus.getActiveStatus() },
        { title: 'Inactive clients (A - Z)', status: clientStatus.getInactiveStatus() }
      ];
    }

    _categories(filterData) {
      let categories = [{
        label: 'Client',
        params: 'client_ids[]',
        top: true,
        type: 'checkbox',
        options: this._clientOptions(),
        children: [{
          label: 'Choose sites',
          params: 'site_ids[]',
          type: 'checkbox',
          options: this._siteOptions(filterData),
          children: [{
            label: 'Choose locations',
            params: 'location_ids[]',
            type: 'checkbox',
            options: this._locationOptions(filterData),
            children: []
          }]
        }]
      }];
      if (packageTypeService.hasProPackage()) {
        categories.push({
          label: 'Home zones',
          params: 'home_zone_ids[]',
          top: true,
          type: 'checkbox',
          options: this._homeZoneOptions(filterData),
          children: []
        })
      }
      return categories;
    }

    _clientOptions() {
      return _.map(this.clients, client => {
        return {
          id: client.id,
          label: client.text,
          tag: true,
          isSelected: (this.selectedClients != null ? this.selectedClients.indexOf(client.id) : undefined) !== -1,
          status: client.status
        };
      });
    }

    _homeZoneOptions(filterData) {
      return _.map(filterData.homeZones, homeZone => {
        return {
          id: homeZone.id,
          label: homeZone.name,
          tag: true
        };
      });
    }

    _siteOptions(filterData) {
      return _.map(filterData.sites, site => {
        return {
          id: site.id,
          label: site.name,
          parentId: site.clientId,
          tag: true
        };
      });
    }

    _locationOptions(filterData) {
      return _.map(filterData.locations, location => {
        return {
          id: location.id,
          label: location.name,
          parentId: location.siteId,
          tag: true
        };
      });
    }

  }

};

angular.module('public.security-manager.schedule-manager.timeline')
  .service('ExtendedFilterBookingService', dependencies.concat(ExtendedFilterBookingService));
