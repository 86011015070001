/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const EmailAttachment = AppModel => {

  class EmailAttachment extends AppModel {
    static initClass() {
      this.configure({url : '/emails/attachments', name : 'email_attachment'});
    }
  }

  EmailAttachment.initClass();
  return EmailAttachment;
};

angular.module('data.email').factory('EmailAttachment', dependencies.concat(EmailAttachment));
