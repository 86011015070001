const gccsSecurityManagerNotificationsTopBar = {
  controller: 'SecurityManagerNotificationsTopBarController',
  template: require('./template.pug'),
  bindings: {
    securityManager: '<'
  }
};

angular.module('public.security-manager.notifications')
  .component('gccsSecurityManagerNotificationsTopBar', gccsSecurityManagerNotificationsTopBar);
