const dependencies = ['$scope', 'EntityManager', 'EmployeeApprovedClient', 'activeStatus'];

const EmployeeProfileApprovedClientsController = function($scope, EntityManager, EmployeeApprovedClient, activeStatus) {

  const vm = this;

  vm.selectedClientId = null;

  let clientsLoaded = false;

  const entityManager = new EntityManager(EmployeeApprovedClient, {
    params: {
      employeeId: $scope.employeeProfileLayoutCtrl.employeeId
    }
  });

  vm.$onInit = function() {
    initClients();
    initEmployee();
  };

  vm.getApprovedClients = function () {
    return entityManager.entities;
  };

  vm.toggleNameOrder = function () {
    vm.ascendingNameOrder = !vm.ascendingNameOrder;
  };

  vm.getResourcesNameOf = function (client) {
    return _.map(client.resources, 'name').join(', ');
  };

  vm.isShownEmptyClients = function () {
    return clientsLoaded && (vm.getApprovedClients().length === 0);
  };

  const initClients = function() {
    EmployeeApprovedClient.query({
      status: activeStatus.getActiveStatus()
    }, {
      employeeId: $scope.employeeProfileLayoutCtrl.employeeId
    }).then(clients => {
      vm.clients = clients;
    });
  };

  const initEmployee = function() {
    $scope.employeeProfileLayoutCtrl.loadEmployee().then(employee => vm.employee = employee);
  };

  return vm;
  
};

angular.module('public.employee.profile')
  .controller('EmployeeProfileApprovedClientsController', dependencies.concat(EmployeeProfileApprovedClientsController));
