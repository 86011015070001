/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const employeeStatus = function() {

  const STATUS_ACTIVE = 1;
  const STATUS_INACTIVE = 2;

  const names = {};
  names[STATUS_ACTIVE] = 'Active';
  names[STATUS_INACTIVE] = 'Inactive';

  const filterNames = {};
  filterNames[0] = 'All employees';
  filterNames[STATUS_ACTIVE] = 'Active Only';
  filterNames[STATUS_INACTIVE] = 'Inactive Only';

  return {
    getNames() {
      return names;
    },

    getName(status) {
      return names[status];
    },

    getFilterNames() {
      return filterNames;
    },

    isActive(status) {
      return status === STATUS_ACTIVE;
    },

    isInactive(status) {
      return status === STATUS_INACTIVE;
    },

    getActiveStatus() {
      return STATUS_ACTIVE;
    },

    getInactiveStatus() {
      return STATUS_INACTIVE;
    }
  };
};

angular.module('data.employee').service('employeeStatus', employeeStatus);
