/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.schedule-manager.repeatable-booking', [
  'data.repeatable-booking',
  'data.client',
  'data.resource',
  'popup.repeatable-booking'
]).config(['$stateProvider', $stateProvider => {

  return $stateProvider

    .state('public.security-manager.schedule-manager.repeatable-booking', {
      abstract: true,
      url: '/repeatable-booking?{ id }',
      template: require('./template.pug'),
      controller: 'ScheduleManagerRepeatableBookingLayoutController',
      controllerAs: 'repeatableBookingLayoutCtrl'
    })
    .state('public.security-manager.schedule-manager.repeatable-booking.form', {
      url: '/form',
      template: require('./form/template.pug'),
      controller: 'ScheduleManagerRepeatableBookingFormController',
      controllerAs: 'repeatableBookingFormCtrl'
    })
    .state('public.security-manager.schedule-manager.repeatable-booking.shift-rules', {
      url: '/shift-rules',
      template: require('./shift-rules/template.pug'),
      controller: 'ScheduleManagerRepeatableBookingShiftRulesController',
      controllerAs: 'repeatableBookingShiftRulesCtrl'
    })

}]);
