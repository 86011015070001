const gccsPlanningSearch = {
  template: require('./template.pug'),
  controller: 'PlanningSearchController',
  controllerAs: 'filterCtrl',
  bindings: {
    applyFilterCb: '&',
    filterStringChangedCb: '&',
    filterData: '<'
  }
};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .component('gccsPlanningSearch', gccsPlanningSearch);
