/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.client', [
  'data.client',
  'public.security-manager.client.list',
  'public.security-manager.client.show',
  'public.security-manager.client.registration',
  'public.security-manager.client.resource',
  'shared-components.popup.client'
]);
