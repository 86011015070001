const gccsSecurityManagerNotificationsTopBarSettings = {
  controller: 'SecurityManagerNotificationsTopBarSettingsController',
  template: require('./template.pug'),
  bindings: {
    securityManager: '<',
    closeCb: '&',
    onUrgentChangeCb: '&'
  }
};

angular.module('public.security-manager.notifications')
  .component('gccsSecurityManagerNotificationsTopBarSettings', gccsSecurityManagerNotificationsTopBarSettings);
