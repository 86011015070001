/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.client-manager.profile', ['data.user', 'data.client-manager']).config([
  '$stateProvider',
  $stateProvider => {

    return $stateProvider

      .state('public.client-manager.profile', {
        abstract: true,
        url: '/profile',
        template: require('./template.pug'),
        controller: 'ManagerProfileLayoutController',
        controllerAs: 'managerProfileLayoutCtrl'
      })
      .state('public.client-manager.profile.personal-details', {
        url: '/personal-details',
        template: require('./personal-details/template.pug'),
        controller: 'ManagerProfilePersonalDetailsController',
        controllerAs: 'managerProfilePersonalDetailsCtrl'
      });

}]);
