const dependencies = ['employeeDetailsField', 'dateService', 'EmployeeGapChart'];

const ReportsMissingDataItemController = function(employeeDetailsField, dateService, EmployeeGapChart) {

  const vm = this;

  vm.employeeDetailsField = employeeDetailsField;

  let isInitialized = false;

  vm.initData = function() {
    if (!isInitialized) {
      isInitialized = true;
      vm.workHistoryGapChart = new EmployeeGapChart(vm.data.workHistoryGap.dateRanges);
      vm.addressHistoryGapChart = new EmployeeGapChart(vm.data.addressHistoryGap.dateRanges);
    }
  };

  return vm;

};

angular.module('public.security-manager.reports.hr')
  .controller('ReportsMissingDataItemController', dependencies.concat(ReportsMissingDataItemController));
