/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.hr.cards', ['data.card']).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.hr.cards', {
      url: '/cards?{ employeeFilter, firstLetter }',
      template: require('./template.pug'),
      controller: 'SecurityManagerHrCardsController',
      controllerAs: 'employeeCardsCtrl',
      reloadOnSearch: false
    })

]);
