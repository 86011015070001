/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').service('bookingTemplateType', function() {

  const TYPE_CREATE = 'create';
  const TYPE_EDIT = 'edit';

  return {
    getCreateType() {
      return TYPE_CREATE;
    },

    getEditType() {
      return TYPE_EDIT;
    }
  };
});
