/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'employeeDetailsField'
];

const PopupReportMissingDataFilterController = function(
  $scope, employeeDetailsField
) {

  const vm = this;

  $scope.popupFormCtrl.title = 'Select missing data fields for report';
  $scope.popupFormCtrl.withoutButtons = true;
  $scope.popupFormCtrl.popupWidth = 824;

  vm.employeeDetailsField = employeeDetailsField;

  vm.isSelected = function(option) {
    return vm.fieldsCopy.includes(option);
  };

  vm.onOptionClick = function(option) {
    vm.fieldsCopy = _.xor([option], vm.fieldsCopy);
  };

  vm.isSelectedCategory = function(options) {
    return _.difference(options, vm.fieldsCopy).length === 0;
  };

  vm.onCategoryClick = function(options) {
    if (vm.isSelectedCategory(options)) {
      vm.fieldsCopy = _.difference(vm.fieldsCopy, options);
    } else {
      vm.fieldsCopy = _.union(vm.fieldsCopy, options);
    }
  };

  const initFieldPreferences = function() {
    const preferences = [];
    _.each(employeeDetailsField.getCategories(), (value, key) => {
      preferences.push({
        name: employeeDetailsField.getCategoryName(key),
        options: value
      });
    });
    vm.preferences = preferences;
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      vm.fieldsCopy = _.map($scope.fields, field => +field);
      initFieldPreferences();
    }
  });

  $scope.$on('popup.submit', function () {
    $scope.popupCtrl.closePopup(() => $scope.popupCloseCb({ fieldsIds: vm.fieldsCopy }))
  });

  return vm;

};

angular.module('popup.report')
  .controller('PopupReportMissingDataFilterController',
    dependencies.concat(PopupReportMissingDataFilterController)
  );