/*
 * @author Oleksandr Papka <papkaos>
 */
const gccsProfileDetailsTwoFactorAuth = {
  controller: 'profileDetailsTwoFactorAuthController',
  controllerAs: 'profileDetailsTwoFactorAuthCtrl',
  template: require('./template.pug'),
  bindings: {
    user: '=',
  }
};

angular.module('public.security-manager.profile')
  .component('gccsProfileDetailsTwoFactorAuth', gccsProfileDetailsTwoFactorAuth);
