const dependencies = ['Employee'];

const PopupClientLocationFormController = function(Employee) {

  const vm = this;

  const RADIUS_OPTIONS = [100, 250, 500, 750, 1000, 2000, 5000];

  vm.radiusSelectOptions = {};

  let isFormSubmitted = false;

  vm.$onInit = function() {
    vm.gccsPopupsLayout.isShortPopup = true;
    initRadiusSelectOptions();
    checkEditable();
    initLocation();
  };

  vm.onSubmit = function(form) {
    if (form.$invalid) { return; }
    if (isFormSubmitted) { return; }
    isFormSubmitted = true;
    vm.locationCopy.save().then(location => {
      vm.gccsPopupsLayout.closePopup({
        callbackData: {
          location: location
        }
      });
    });
  };

  vm.loadEmployees = function(text) {
    return Employee.autocomplete({}, { 'order[full_name]': 'asc', fullName: text, limit: 20 });
  };

  const initRadiusSelectOptions = function() {
    for (let radiusOption of RADIUS_OPTIONS) {
      vm.radiusSelectOptions[radiusOption] = radiusOption;
    }
  };

  const checkEditable = function() {
    if (vm.isEditable === undefined) {
      vm.isEditable = true;
      vm.cancelButtonText = 'Cancel';
    } else if (!vm.isEditable) {
      vm.withoutSaveButton = true;
      vm.cancelButtonText = 'Close';
    }
  };

  const initLocation = function() {
    if (vm.location.isNew()) {
      vm.locationCopy = vm.location;
      vm.gccsPopupsLayout.headerTitle = 'Add new location';
      vm.saveButtonText = 'Add';
    } else {
      vm.locationCopy = angular.copy(vm.location);
      vm.gccsPopupsLayout.headerTitle = 'Edit location';
      vm.saveButtonText = 'Save';
    }
  };

  return vm;

};

angular.module('shared-components.popup.client')
  .controller('PopupClientLocationFormController', dependencies.concat(PopupClientLocationFormController));
