/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$http', '$timeout', '$state', '$stateParams', 'User', 'Session',
  'SecurityManager', 'SecurityProviderSetting', 'CountryCodeInput', 'storageService',
  'phoneNumberService', 'sessionService', 'redirectService', 'VerifyAuthCodeService'
];

const SignPhoneNumberSetupController = function(
  $scope, $http, $timeout, $state, $stateParams, User, Session,
  SecurityManager, SecurityProviderSetting, CountryCodeInput, storageService,
  phoneNumberService, sessionService, redirectService, VerifyAuthCodeService
) {

  const vm = this;

  vm.countryCodeInput = new CountryCodeInput();
  vm.verifyAuthCodeService = new VerifyAuthCodeService();

  vm.countryCodeInput.isFocusedPhoneField = true;
  vm.isShownAuthCode = false;
  vm.errorMessage = null;

  vm.$onInit = function () {
    if (!($stateParams.profile || $stateParams.user)) {
      $state.go('sign.login');
    } else {
      initUser();
      vm.countryCodeInput.initPhoneOptions();
      vm.countryCodeInput.initPhoneCodesList();
    }
  };

  vm.onCleaveInit = function (cleave) {
    vm.countryCodeInput.setCleaveInstance(cleave);
  };

  const initUser = function () {
    vm.user = new User($stateParams.profile);
    vm.countryCodeInput.setPhoneHolder(vm.user);
  };

  vm.onFormSubmit = function () {
    if ($scope.SignPhoneNumberSetupForm.$invalid) {
      return;
    }
    if (!vm.isShownAuthCode) {
      validateUniquenessAndSend();
    } else {
      confirmAuthCode();
    }
  };

  const validateUniquenessAndSend = function () {
    vm.user.phoneNumber = vm.countryCodeInput.getFormattedPhoneNumber();
    const phoneNumber = vm.user.phoneNumber;
    vm.errorMessage = null;
    User.validate({ phoneNumber: phoneNumber }).then(function(isUnique) {
      if (!isUnique) {
        vm.errorMessage = 'Phone number is not unique';
      } else {
        initVerifyAuthCodeMode();
      }
    });
  };

  const initVerifyAuthCodeMode = function () {
    vm.isShownAuthCode = true;
    vm.secretPhoneNumber = phoneNumberService.secretize(vm.user.phoneNumber);
    vm.verifyAuthCodeService.sendCode({
      userId: vm.user.id,
      phoneNumber: vm.user.phoneNumber,
      resetCb: () => {
        vm.errorMessage = null;
      },
      failCb: () => {
        vm.errorMessage = 'Phone number is not valid';
      }
    });
  };

  const confirmAuthCode = function () {
    vm.verifyAuthCodeService.confirmUserPhoneCode().then(function(phoneAuth) {
      loginUserWithPhoneSave(phoneAuth);
    }).catch(function(response) {
      vm.errorMessage = response.data.error;
    });
  };

  const loginUserWithPhoneSave = function (phoneAuth) {
    let session = new Session($stateParams.user);
    session.phoneNumber = vm.user.phoneNumber; // update phone number with login
    session.phoneAuth = phoneAuth;
    sessionService.createSession(session, function() {
      redirectService.redirectToMain();
    }, function(response) {
      const responseData = response.data;
      if (responseData.package_error) {
        storageService.set('packageError', JSON.stringify(responseData));
        $state.go('sign.payment-message');
      } else {
        redirectService.redirectToMain();
      }
    });
  };

  return vm;

};

angular.module('sign')
  .controller('SignPhoneNumberSetupController', dependencies.concat(SignPhoneNumberSetupController));
