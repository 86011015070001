const dependencies = ['silenceModeService'];

const SilenceModeController = function(silenceModeService) {

  const vm = this;

  vm.$onInit = () => setSilenceModeState();

  // optimistic updates
  vm.toggleSilenceMode = function() {
    if (vm.silenceMode) {
      silenceModeService.turnOnSilenceMode()
        .then(getSilenceModeState)
        .catch(getSilenceModeState);
    } else {
      silenceModeService.turnOffSilenceMode()
        .then(getSilenceModeState)
        .catch(getSilenceModeState);
    }
  };

  const setSilenceModeState = () => {
    vm.silenceMode = silenceModeService.setSilenceModeState(!!vm.securityManager.silenceMode);
  };

  const getSilenceModeState = () => vm.silenceMode = silenceModeService.getSilenceModeState();

  return vm;

};

angular.module('public.security-manager.schedule-manager.timeline')
  .controller('SilenceModeController', dependencies.concat(SilenceModeController));
