/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const billableHoursReportExportType = function() {

  const STANDARD = 1;
  const NON_HOURS_CHARGES = 2;
  const NON_HOURS_PAYMENTS = 3;
  const HOURS_PAY_CHARGE_DEVIATION = 4;
  const HOUR_RATE_DEVIATION = 5;

  const names = {
    [STANDARD]: 'Standard',
    [NON_HOURS_CHARGES]: 'Non-Hours Charges',
    [NON_HOURS_PAYMENTS]: 'Non-Hours Payments',
    [HOURS_PAY_CHARGE_DEVIATION]: 'Hours Pay/charge Deviation',
    [HOUR_RATE_DEVIATION]: 'Hour Rate Deviation'
  };

  return {
    getNames() {
      return names;
    },

    getStandard() {
      return STANDARD;
    }
  };

};

angular.module('public.security-manager.reports.finance')
  .service('billableHoursReportExportType', billableHoursReportExportType);
