/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', '$state', 'currentUserService', 'validationService', 'redirectService'];

const EmployeeProfileSettingsLayoutController = function($scope, $state, currentUserService, validationService, redirectService) {

  const vm = this;

  vm.tabs = [{
    name : 'Personal details',
    sref : 'public.employee.profile-settings.personal-details'
  }
  ];
  vm.validationService = validationService;

  let isFormSubmitted = false;

  const initUser = function() {
    vm.user = angular.copy(currentUserService.getCurrentUser());
  };

  vm.onFormSubmit = function() {
    if (!$scope.EmployeeProfileSettingsForm.$valid || isFormSubmitted) { return; }
    isFormSubmitted = true;
    return $scope.$broadcast('employee.profile-settings.submit');
  };

  vm.redirectToMain = () => redirectService.redirectToMain();

  vm.resetForm = function() {
    vm.resetFormSubmitted();
    $scope.EmployeeProfileSettingsForm.$setPristine();
    $scope.EmployeeProfileSettingsForm.$setUntouched();
    return initUser();
  };

  vm.updateUser = user => currentUserService.updateCurrentUser(user);

  vm.resetFormSubmitted = () => isFormSubmitted = false;

  $scope.$on('$stateChangeSuccess', function() {
    const activeTab = _.find(vm.tabs, tab => $state.is(tab.sref));
    vm.title = activeTab.name;
    return vm.resetForm();
  });

  initUser();

  return vm;
};

angular.module('public.employee.profile-settings').controller('EmployeeProfileSettingsLayoutController',
  dependencies.concat(EmployeeProfileSettingsLayoutController));
