/*
 * @author Igor Serpak <robobee>
 */
angular.module('data.employee-payroll').factory('EmployeePayrollVersion', ['AppModel', function(AppModel) {

  class EmployeePayrollVersion extends AppModel {
    static initClass() {
      this.configure({url : '/employee_payrolls/{{ payrollId }}/versions/{{ id }}', name : 'version'});
    }
  }

  EmployeePayrollVersion.initClass();
  return EmployeePayrollVersion;
}
]);
