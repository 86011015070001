/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['AppModel'];

const EmployeeDayOff = AppModel => {

  class EmployeeDayOff extends AppModel {
    static initClass() {
      this.configure({url : '/employees/{{employeeId}}/day_offs/{{id}}', name : 'day_off'});
    }

    reject() {
      return this.$put(this.getUrl() + '/reject');
    }

    approve() {
      return this.$put(this.getUrl() + '/approve');
    }
  }

  EmployeeDayOff.initClass();
  return EmployeeDayOff;
};

angular.module('data.employee').factory('EmployeeDayOff', dependencies.concat(EmployeeDayOff));
