/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').filter('formatPublishTime', ['dateService', dateService =>
  function(rawTime) {
    if (rawTime) {
      const time = moment(rawTime, dateService.getIsoFullDateFormatWithTz());
      if (time.isSame(moment(), 'day')) {
        return time.fromNow();
      } else {
        return time.format(dateService.getDateFormat());
      }
    }
  }
]);
