/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.client-manager').service('managerAccessLevel', function() {

  const ACCOUNT_OWNER = 1;
  const MANAGER = 2;
  const VIEW_ONLY = 3;

  const names = {};
  names[ACCOUNT_OWNER] = 'Account owner';
  names[MANAGER] = 'Manager';
  names[VIEW_ONLY] = 'View only';

  return {
    getNames() {
      return names;
    },

    getName(accessLevel) {
      return names[accessLevel];
    },

    getAccountOwnerAccessLevel() {
      return ACCOUNT_OWNER;
    },

    getManagerAccessLevel() {
      return MANAGER;
    },

    getViewOnlyAccessLevel() {
      return VIEW_ONLY;
    },

    isAccountOwner(accessLevel) {
      return accessLevel === ACCOUNT_OWNER;
    },

    isManager(accessLevel) {
      return accessLevel === MANAGER;
    },

    isViewOnly(accessLevel) {
      return accessLevel === VIEW_ONLY;
    }
  };
});
