/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['pluralizeService'];

const EmployeeClientApproveClientTitleController = function(pluralizeService) {

  const vm = this;

  vm.getCountText = function() {
    return pluralizeService.simple(vm.count, 'client');
  };

  return vm;

};

angular.module('public.security-manager.employee')
  .controller('EmployeeClientApproveClientTitleController', dependencies.concat(EmployeeClientApproveClientTitleController));
