/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$state', 'defaultConfig', 'storageService', 'redirectService'];

const SignLoginWithSessionController = function($state, defaultConfig, storageService, redirectService) {

  const vm = this;

  vm.$onInit = function() {
    storageService.set(defaultConfig.sessionKey, $state.params.session_id);
    return redirectService.redirectToMain();
  };

  return vm;
};

angular.module('sign').controller('SignLoginWithSessionController', dependencies.concat(SignLoginWithSessionController));
