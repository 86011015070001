/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['$state', 'UserCode', 'notificationService'];

const EmailConfirmationController = function($state, UserCode, notificationService) {

  const vm = this;

  UserCode.confirmEmail($state.params.userId, $state.params.code)
    .then(function() {
      notificationService.notifySuccess('Your email was confirmed');
      return $state.go('sign.login');
    })
    .catch(function() {
      notificationService.notifyError('There was an error confirming your registration');
      return $state.go('sign.login');
    });

  return vm;

};

angular.module('confirmation')
  .controller('EmailConfirmationController', dependencies.concat(EmailConfirmationController));
