/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
angular.module('public.client-manager.timeline', [
  'public.timeline',
  'data.booking',
  'data.client',
  'data.resource',
  'popup.booking'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.client-manager.timeline', {
      url: '/client-timeline',
      template: require('./template.pug'),
      controller: 'ManagerTimelineController',
      controllerAs: 'managerTimelineCtrl'
    })

]);
