const gccsPopupEndRepeatableBooking = {
  controller: 'PopupEndRepeatableBookingController',
  template: require('./template.pug'),
  require: {
    gccsPopupsLayout: '^gccsPopupsLayout'
  },
  bindings: {
    repeatableBooking: '<'
  }
};

angular.module('shared-components.popup.booking')
  .component('gccsPopupEndRepeatableBooking', gccsPopupEndRepeatableBooking);
