/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'EntityManager', 'FilterParamsService', 'EmployeeEmail', 'emailStatus', 'packageTypeService'
];

const SecurityManagerHrEmailSentController = function(
  $scope, EntityManager, FilterParamsService, EmployeeEmail, emailStatus, packageTypeService
) {

  const vm = this;

  const EMAIL_STATUS_SENT = emailStatus.getSentStatus();

  const entityManager = new EntityManager(EmployeeEmail, {
    queryParams: {
      status: EMAIL_STATUS_SENT
    }
  });

  const filterParamsService = new FilterParamsService({
    filter: ['searchString']
  });

  let openedEmployeeEmail = null;

  vm.filterParams = filterParamsService.getParams();

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  vm.$onInit = function () {
    packageTypeService.checkProPackage();
    EmployeeEmail.getCount({}, { status: EMAIL_STATUS_SENT }).then(data => vm.emailsCount = data.count);
  };

  vm.getEmails = function () {
    return entityManager.entities;
  };

  vm.loadMoreEmployeeEmails = function () {
    entityManager.loadMore();
  };

  vm.showEmployeeEmailPopup = function() {
    vm.popupEmployeeEmail = entityManager.createEntity();
    vm.isShownEmailPopup = true;
  };

  vm.closeEmployeeEmailPopup = function(employeeEmail) {
    closePopup();
    if ((employeeEmail != null ? employeeEmail.status : undefined) === EMAIL_STATUS_SENT) {
      return entityManager.unshiftEntity(employeeEmail);
    } else if ((employeeEmail != null ? employeeEmail.status : undefined) === emailStatus.getDraftStatus()) {
      return $scope.securityManagerHrCtrl.increaseDraftsCount();
    }
  };

  vm.resendEmail = function(employeeEmail) {
    openedEmployeeEmail = employeeEmail;
    const popupEmployeeEmail = angular.copy(employeeEmail);
    popupEmployeeEmail.id = null;
    vm.popupEmployeeEmail = popupEmployeeEmail;
    vm.isShownEmailPopup = true;
  };

  const closePopup = function() {
    vm.popupEmployeeEmail = null;
    vm.isShownEmailPopup = false;
    openedEmployeeEmail = null;
  };

  const filterEmployeeEmails = function() {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    return entityManager.load(urlParams);
  };

  $scope.$watch(function () {
    return vm.filterParams;
  }, _.debounce(filterEmployeeEmails, 100), true);

  return vm;
};

angular.module('public.security-manager.hr.email')
  .controller('SecurityManagerHrEmailSentController', dependencies.concat(SecurityManagerHrEmailSentController));
