/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'User', 'Role', 'uploadService', 'employeeType', 'employeeValidation',
  'dateService', 'userValidation', 'employeeGender', 'currentUserService', 'packageTypeService'
];

const EmployeeRegistrationPersonalDetailsController = function(
  $scope, User, Role, uploadService, employeeType, employeeValidation,
  dateService, userValidation, employeeGender, currentUserService, packageTypeService
) {

  const vm = this;

  vm.employeeType = employeeType;
  vm.employeeGender = employeeGender;
  vm.monthNames = moment.months();
  vm.employeeValidation = employeeValidation;
  vm.packageTypeService = packageTypeService;

  vm.getEmailErrors = () => {
    return userValidation.getEmailErrors(
      vm.employee.user && vm.employee.user.id,
      currentUserService.getCurrentProfile().securityProvider.id
    );
  };

  vm.loadRoles = function(text) {
    return Role.query({ 'order[name]': 'asc', name: text });
  };

  const getBirthDate = function() {
    const momentBirthDate = moment([vm.employee.birthYear, vm.employee.birthMonth, vm.employee.birthDay]);
    if (momentBirthDate.isValid()) {
      return momentBirthDate.format(dateService.getIsoDateFormat());
    } else {
      return null;
    }
  };

  $scope.employeeRegistrationLayoutCtrl.loadEmployee().then(function(employee) {
    const momentBirthDate = moment(employee.birthDate, dateService.getIsoDateFormat());
    employee.birthDay = momentBirthDate.date();
    employee.birthMonth = momentBirthDate.month();
    employee.birthYear = momentBirthDate.year();
    vm.employee = employee;
  });

  $scope.$on('employee.registration.submit', function() {
    vm.employee.birthDate = getBirthDate();
    let avatarUploaded = vm.employee.user.avatarUploaded;
    let user = new User(vm.employee.user);
    user.avatarUploaded = avatarUploaded;
    vm.employee.save().then(function(employee) {
      user.id = employee.user.id;
      uploadService.saveWithFile(user, 'avatar', function(user) {
        vm.employee.user = user;
        $scope.employeeRegistrationLayoutCtrl.updateEmployee(vm.employee);
        $scope.employeeRegistrationLayoutCtrl.goNext();
      });
    });
  });

  return vm;

};

angular
  .module('public.security-manager.employee.registration')
  .controller('EmployeeRegistrationPersonalDetailsController',
    dependencies.concat(EmployeeRegistrationPersonalDetailsController));
