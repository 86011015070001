/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'Employee', 'dateService', 'notificationService', 'FormDatePickerRangeRangeSelector'];

const PopupInvoicePeriodController = function($scope, Employee, dateService, notificationService, FormDatePickerRangeRangeSelector) {

  const ISO_DATE_FORMAT = dateService.getIsoDateFormat();

  const vm = this;

  $scope.popupFormCtrl.popupWidth = 751;
  $scope.popupFormCtrl.title = 'Select invoice period';
  $scope.popupFormCtrl.saveButtonText = 'Continue';

  vm.dateFormat = dateService.getDateFormat();
  vm.maxDate = dateService.today().add(-1, 'day');
  vm.from = vm.maxDate.clone();
  vm.to = vm.maxDate.clone();
  vm.hasSetInitialDates = false;

  vm.customData = {
    isRelative : true,
    withoutHeader : true,
    getDayClasses(date) {
      return getDayClasses(date);
    }
  };

  let uninvoicedDates = {};

  const getDayClasses = function(date) {
    if (isInvoicedDate(date)) {
      return 'is-selected-red';
    } else if (isWithoutShifts(date)) {
      return 'is-disabled';
    } else {
      return '';
    }
  };

  const isWithoutShifts = date => !uninvoicedDates[date.format(ISO_DATE_FORMAT)];

  const isInvoicedDate = date => $scope.invoicedDates[date.format(ISO_DATE_FORMAT)];

  const getSelectedDates = function() {
    const dates = [];
    const date = vm.from.clone().startOf('day');
    const toDate = vm.to.clone().startOf('day').add(1, 'day');
    while (!date.isSame(toDate)) {
      dates.push(date.clone());
      date.add(1, 'days');
    }
    return dates;
  };

  const containsInvoicedDates = dates => _.some(dates, date => isInvoicedDate(date));

  const containsNoShifts = dates => _.every(dates, date => isWithoutShifts(date));

  const resetAndNotifyError = function(errorText) {
    $scope.popupFormCtrl.resetFormSubmitted();
    return notificationService.notifyError(errorText);
  };

  const toHash = dates =>
    _.reduce(dates, function(acc, date) {
      acc[date] = true;
      return acc;
    }
    , {})
  ;

  const loadUninvoicedDates = () =>
    Employee.getDatesWithShifts($scope.employeeId, {
      from : vm.from.clone().subtract(1, 'month').startOf('month').format(dateService.getIsoDateFormat()),
      to : vm.to.clone().add(1, 'month').endOf('month').format(dateService.getIsoDateFormat())
    }
    ).then((data) => {
      _.merge(uninvoicedDates, toHash(data));
      if(data.length > 0 && !vm.hasSetInitialDates) {
        vm.maxDate = moment(data[data.length - 1], ISO_DATE_FORMAT);
        vm.from = moment(data[0], ISO_DATE_FORMAT);
        vm.to = vm.maxDate.clone();
        vm.hasSetInitialDates = true;
      }
    })
  ;

  $scope.$on('popup.submit', function() {
    const selectedDates = getSelectedDates();
    if (containsInvoicedDates(selectedDates)) {
      return resetAndNotifyError('Invoice for this period was created earlier. Select another one.');
    } else if (containsNoShifts(selectedDates)) {
      return resetAndNotifyError('There are no shifts in the selected period');
    } else {
      return $scope.popupCtrl.closePopup(() => $scope.popupCloseCb({from : vm.from.startOf('day'), to : vm.to.startOf('day')}));
    }
  });

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      uninvoicedDates = {};
      return loadUninvoicedDates();
    }
  });

  $scope.$on('setDate', function(event, model, view, isArrowClick) {
    if (isArrowClick) { return loadUninvoicedDates(); }
  });

  return vm;
};

angular.module('popup.invoice').controller('PopupInvoicePeriodController',
  dependencies.concat(PopupInvoicePeriodController));
