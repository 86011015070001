/*
 * @author Igor Serpak <robobee>
 */
const gccsEmployeeHeaderNotification = {
  template: require('./template.pug'),
  bindings : {
    notification : '<',
    acceptInvitation : '&',
    declineInvitation : '&'
  }
};

angular.module('public.employee').component('gccsEmployeeHeaderNotification', gccsEmployeeHeaderNotification);
