angular.module('public.invoice.employee').service('invoiceEmployeeElementType', function() {

  const SHIFT_EMPLOYEE = 1;
  const BONUS = 2;
  const DEDUCTION = 3;
  const HOLIDAY_PAY = 4;
  const OVERTIME = 5;

  return {
    getShiftEmployee() {
      return SHIFT_EMPLOYEE;
    },

    getBonus() {
      return BONUS;
    },

    getDeduction() {
      return DEDUCTION;
    },

    getHolidayPay() {
      return HOLIDAY_PAY;
    },

    getOvertime() {
      return OVERTIME;
    }
  };
});
