const dependencies = [
  'SecurityProviderStripeCustomer', 'SecurityManager', 'currentUserService', 'dateService', 'pluralizeService',
  'systemPackagePopupService', 'trialEndNotificationService', 'packageTypeService'
];

const SecurityManagerBillingTrialNotificationController = function(
  SecurityProviderStripeCustomer, SecurityManager, currentUserService, dateService, pluralizeService,
  systemPackagePopupService, trialEndNotificationService, packageTypeService
) {

  const vm = this;

  const DAYS_BEFORE_TRIAL_END = 7;

  vm.trialEndNotification = trialEndNotificationService;

  let daysToTrialEnd;
  let systemPackage;

  vm.$onInit = function() {
    systemPackage = currentUserService.getCurrentProfile().securityProvider.systemPackage;
    if (systemPackage.isTrial && !packageTypeService.hasFreePackage(systemPackage.packageType)) {
      const today = dateService.today();
      const firstPaidDateMoment = moment(systemPackage.firstPaidDate, dateService.getIsoDateFormat());
      daysToTrialEnd = Math.max(0, firstPaidDateMoment.diff(today, 'days'));
      if (daysToTrialEnd <= DAYS_BEFORE_TRIAL_END) {
        SecurityManager.getAccountOwner().then(function(accountOwner) {
          vm.accountOwner = accountOwner;
        });
        SecurityProviderStripeCustomer.getCurrent().then(function() {
          vm.trialEndNotification.hideNotification(true);
        }).catch(function() {
          vm.trialEndNotification.showNotification();
          vm.getDaysToTrialEndText = getDaysToTrialEnd();
        });
      }
    }
  };

  vm.showBillingPopup = function() {
    systemPackagePopupService.showBillingPopup(systemPackage.packageType);
  };

  vm.canAddPaymentInfo = function() {
    return currentUserService.getCurrentProfile().isAccountOwner();
  };

  const getDaysToTrialEnd = function() {
    if (daysToTrialEnd === 0) {
      return 'today'
    } else {
      return `in ${pluralizeService.simple(daysToTrialEnd, 'day')}`;
    }
  };

  return vm;

};

angular.module('public.security-manager.billing')
  .controller('SecurityManagerBillingTrialNotificationController',
    dependencies.concat(SecurityManagerBillingTrialNotificationController));
