/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['SecurityProviderStripeCustomer'];

const SettingsBillingCardInfoController = function(SecurityProviderStripeCustomer) {

  const vm = this;

  vm.isShownCardEditPopup = false;
  vm.isCardInfoEmpty = false;

  vm.$onInit = function() {
    loadCurrentCardInfo();
  };

  vm.openCardEditPopup = function() {
    vm.isShownCardEditPopup = true;
  };

  vm.closeCardEditPopup = function() {
    vm.isShownCardEditPopup = false;
  };

  vm.getEditButtonText = function() {
    return vm.isCardInfoEmpty ? 'Add card' : 'Edit info';
  };

  const loadCurrentCardInfo = function() {
    SecurityProviderStripeCustomer.getCurrent().then(function(stripeCustomer) {
      if(stripeCustomer) {
        vm.cardLast4 = stripeCustomer.cardLast4;
      }
      else {
        vm.isCardInfoEmpty = true;
      }
    }).catch(function() {
      vm.isCardInfoEmpty = true;
    });
  };

  return vm;

};

angular.module('public.security-manager.settings')
  .controller('SettingsBillingCardInfoController', dependencies.concat(SettingsBillingCardInfoController));