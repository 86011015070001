/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.document').directive('gccsPopupDocumentForm', ['popupDirectiveConfig', popupDirectiveConfig =>
  popupDirectiveConfig.configure({
    bodyTemplate: require('./template.pug'),
    scope: {
      document: '=',
      documentTypeType: '<'
    }
  })
]);
