const dependencies = [];

const ResourceListController = function() {

  const vm = this;

  vm.isShownAvatarIcon = function(resource) {
    if (vm.isResource) {
      return !resource.class && !resource.url;
    } else {
      return !vm.getAvatarEntity().url;
    }
  };

  vm.getAvatarEntity = function(resource) {
    if (!resource) return {};
    if (vm.isResource) {
      return resource;
    } else {
      return resource.employee.user.thumbAvatar;
    }
  };

  return vm;

};

angular.module('shared-components')
  .controller('ResourceListController', dependencies.concat(ResourceListController));
