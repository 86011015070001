/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const invoiceType = function() {

  const TYPE_QUOTE = 1;
  const TYPE_INVOICE = 2;
  const TYPE_CREDIT_NOTE = 3;

  const names = {};
  names[TYPE_QUOTE] = 'Quote';
  names[TYPE_INVOICE] = 'Invoice';
  names[TYPE_CREDIT_NOTE] = 'Credit Note';

  const smallNames = {};
  smallNames[TYPE_QUOTE] = 'quote';
  smallNames[TYPE_INVOICE] = 'invoice';
  smallNames[TYPE_CREDIT_NOTE] = 'credit note';

  const filterNames = {};
  filterNames[0] = 'All';
  filterNames[TYPE_QUOTE] = 'Quote';
  filterNames[TYPE_INVOICE] = 'Invoice';
  filterNames[TYPE_CREDIT_NOTE] = 'Credit Note';

  const selectedFilterNames = {};
  selectedFilterNames[0] = 'Type';
  selectedFilterNames[TYPE_QUOTE] = 'Quote';
  selectedFilterNames[TYPE_INVOICE] = 'Invoice';
  selectedFilterNames[TYPE_CREDIT_NOTE] = 'Credit Note';

  return {
    getNames() {
      return names;
    },

    getFilterNames() {
      return filterNames;
    },

    getSelectedFilterNames() {
      return selectedFilterNames;
    },

    getName(type) {
      return names[type];
    },

    getSmallName(type) {
      return smallNames[type];
    },

    getQuoteType() {
      return TYPE_QUOTE;
    },

    getInvoiceType() {
      return TYPE_INVOICE;
    },

    getCreditNoteType() {
      return TYPE_CREDIT_NOTE;
    },

    isQuote(type) {
      return type === TYPE_QUOTE;
    },

    isInvoice(type) {
      return type === TYPE_INVOICE;
    },

    isCreditNote(type) {
      return type === TYPE_CREDIT_NOTE;
    }
  };
};

angular.module('data.client-invoice').service('invoiceType', invoiceType);
