/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', '$state', '$filter', '$location', 'EmployeePayroll', 'EmployeePayrollVersion', 'FilterParamsService',
  'dateService', 'employeeType', 'employeeInvoiceStatus', 'employeePayrollStatus', 'confirmService',
  'employeePayrollExportType', 'defaultConfig'
];

const InvoicesPayrollsController = function(
  $scope, $state, $filter, $location, EmployeePayroll, EmployeePayrollVersion, FilterParamsService,
  dateService, employeeType, employeeInvoiceStatus, employeePayrollStatus, confirmService,
  employeePayrollExportType, defaultConfig
) {

  const vm = this;

  const DEFAULT_TABLE_ITEMS_COUNT = 20;

  const filterParamsService = new FilterParamsService({
    filter: ['from', 'to', 'employmentType', 'status']
  });

  let tablePage = 1;

  vm.filterParams = filterParamsService.getParams();
  vm.employeeInvoiceStatus = employeeInvoiceStatus;
  vm.employeePayrollStatus = employeePayrollStatus;
  vm.employeeType = employeeType;
  vm.isReportsDataLoaded = false;
  vm.grandTotalTable = 0;
  vm.isOpenedToggleButton = false;
  vm.showQAButtons = !defaultConfig.isProduction; 
  vm.createPayrollButtons = [{
    name: 'Employed',
    type: employeeType.getEmployedStatus()
  }, {
    name: 'Self-employed',
    type: employeeType.getSelfEmployedStatus()
  }];

  vm.$onInit = function() {
    initDates();
    vm.editable = true;
  };

  vm.updateChartData = function() {
    tablePage = 1;
    setLoadingState();
    loadTable().then(() => setLoadingFinishedState());
  };

  vm.loadMoreTableItems = function() {
    tablePage++;
    return loadTable();
  };

  vm.getFormattedInvoicedDate = date => `${moment(date, dateService.getIsoDateFormat()).format('DD MMM YYYY')}`;

  vm.getInvoicedDate = date => `${moment(date, dateService.getIsoDateFormat()).format(dateService.getDateFormat())}`;

  vm.getPayrollGrandTotal = invoices => {
    return _.reduce(invoices, (prevSum, curr) => prevSum + curr.latestVersion.totalAmount, 0);
  };

  vm.toggleTableItem = item => item.isOpenedItem = !item.isOpenedItem;

  vm.onChangePayrollStatus = function(payroll, newStatus) {
    if (payroll.latestVersion.status === +newStatus) { return; }
    return confirmService.confirm(
      'Confirm new status',
      `Do you really want to change status to ${employeePayrollStatus.getName(newStatus)}?`,
      'Yes',
      () => changeStatus(payroll, newStatus));
  };

  vm.onChangePayrollPaidDate = payroll => {
    return function () {
      if (payroll.latestVersion.copyPaidDate.format(dateService.getIsoDateFormat()) === payroll.latestVersion.paidDate) {
        return;
      }
      return confirmService.confirm(
        'Confirm new paid date',
        `Do you really want to change paid date to ${payroll.latestVersion.copyPaidDate.format(dateService.getDateFormat())}?`,
        'Yes',
        () => changePaidDate(payroll),
        () => payroll.latestVersion.copyPaidDate = moment(payroll.latestVersion.paidDate, dateService.getIsoDateFormat()));
    };
  };

  vm.isPayrollDeletable = function (payroll) {
    return vm.editable && payroll.latestVersion.status !== employeePayrollStatus.getPaidStatus();
  };

  vm.onDeletePayroll = function (payroll) {
    return confirmService.confirmRemove('this payroll', () =>
      payroll.delete().then(() => vm.updateChartData())
    );
  };

  vm.onSyncPayrollWithHumyn = function(payroll) {
    return confirmService.confirm(
      'Sync to Humyn?',
      `This will mark all invoices in the payroll to sync with humyn. This will only work if the humyn integration is enabled on the provider`,
      'Yes',
      () => EmployeePayroll.syncPayrollWithHumyn({ payrollVersionId: payroll.latestVersion.id})
    );
  };

  vm.previewInvoice = invoice => {
    return $state.go('public.employee-invoice-history', {
      invoiceId: invoice.id,
      invoiceVersionId: invoice.latestVersion.id,
      invoicePreview: true,
      allList: false
    });
  };

  vm.getExportNames = payroll => employeePayrollExportType.getNamesFor(payroll.employmentType);

  vm.getExportUrl = (payroll, type) => payroll.exportUrl({ type: employeePayrollExportType.getStringType(type) });

  vm.toggleToggleButton = function() {
    vm.isOpenedToggleButton = !vm.isOpenedToggleButton;
  };

  vm.closeToggleButton = function() {
    vm.isOpenedToggleButton = false;
  };

  const setLoadingState = () => vm.isReportsDataLoaded = false;

  const setLoadingFinishedState = () => vm.isReportsDataLoaded = true;

  const changePaidDate = function(payroll) {
    payroll.latestVersion.paidDate = payroll.latestVersion.copyPaidDate.format(dateService.getIsoDateFormat());
    return new EmployeePayrollVersion({
      id : payroll.latestVersion.id,
      payrollId : payroll.id,
      paidDate : payroll.latestVersion.paidDate
    }).save().then(() => vm.updateChartData());
  };

  const changeStatus = (payroll, newStatus) => {
    return new EmployeePayrollVersion({
      id: payroll.latestVersion.id,
      payrollId: payroll.id,
      status: +newStatus
    }).save().then(() => vm.updateChartData());
  };

  const loadTable = function() {
    const urlParams = getUrlParams();
    $location.search(urlParams);

    // params : 'from', 'to', 'employmentType', 'page'
    return EmployeePayroll.query(_.extend(urlParams, {page : tablePage})).then(function(payrolls) {
      vm.isShownLoadMoreButton = payrolls.length >= DEFAULT_TABLE_ITEMS_COUNT;
      if (tablePage === 1) {
        preparePayrolls(payrolls);
        vm.tablePayrolls = payrolls;
      } else {
        vm.tablePayrolls = _.concat(vm.tablePayrolls, payrolls);
      }
      vm.grandTotalTable = getGrandTotalTable(vm.tablePayrolls);
    });
  };

  const preparePayrolls = payrolls => {
    return _.each(payrolls, function (payroll) {
      payroll.latestVersion.copyPaidDate = moment(payroll.latestVersion.paidDate, dateService.getIsoDateFormat());
      return true;
    });
  };

  const getGrandTotalTable = function(payrolls) {
    return _.reduce(payrolls, function(prevTotalSum, currTotalSum) {
        return prevTotalSum + vm.getPayrollGrandTotal(currTotalSum.latestVersion.invoices);
      }
      , 0);
  };

  const initDates = function() {
    const locationParams = $location.search();
    if (locationParams.from && locationParams.to) {
      vm.endDateFrom = moment(locationParams.from, dateService.getIsoDateFormat());
      return vm.endDateTo = moment(locationParams.to, dateService.getIsoDateFormat());
    } else {
      const today = dateService.today();
      vm.endDateTo = today.clone().startOf('month');
      return vm.endDateFrom = vm.endDateTo.clone().subtract(1, 'month');
    }
  };

  const getUrlParams = function() {
    const urlParams = filterParamsService.getFilterUrlParams();
    if (vm.endDateTo && vm.endDateTo) {
      urlParams.from = moment(vm.endDateFrom, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
      urlParams.to = moment(vm.endDateTo, dateService.getDateFormat()).format(dateService.getIsoDateFormat());
    }
    return urlParams;
  };

  $scope.$watch(() => vm.filterParams, _.debounce(vm.updateChartData, 100), true);

  return vm;

};

angular.module('public.security-manager.invoices.payroll')
  .controller('InvoicesPayrollsController', dependencies.concat(InvoicesPayrollsController));
