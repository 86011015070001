/*
 * @author Maksym Shutiak <maxshutyak>
 */
const gccsPlanningEmployeeSidebar = {
  controller: 'PlanningEmployeeSidebarController',
  template: require('./template.pug'),
  bindings: {
    employees: '<',
    scrollService: '<'
  }
};

angular
  .module('public.security-manager.schedule-manager.timeline.planning')
  .component('gccsPlanningEmployeeSidebar', gccsPlanningEmployeeSidebar);
