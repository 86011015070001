/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$state', 'BookingTemplate', 'Booking', 'EntityManager', 'dateService',
  'pluralizeService', 'confirmService', 'bookingTemplateType'
];

const ClientShowBookingTemplatesController = function(
  $scope, $state, BookingTemplate, Booking, EntityManager, dateService,
  pluralizeService, confirmService, bookingTemplateType
) {

  const vm = this;

  vm.ascendingNameOrder = true;
  vm.pluralizeService = pluralizeService;

  const entityManager = new EntityManager(BookingTemplate, {
    queryParams : {
      clientId : $scope.clientShowLayoutCtrl.clientId
    }
  }
  );

  vm.getBookingTemplates = () => entityManager.entities;

  vm.toggleNameOrder = () => vm.ascendingNameOrder = !vm.ascendingNameOrder;

  vm.getDuration = function(bookingTemplate) {
    const formattedFrom = bookingTemplate.fromMoment.format(dateService.getDateFormat());
    const formattedTo = bookingTemplate.toMoment.format(dateService.getDateFormat());
    return `${formattedFrom} – ${formattedTo}`;
  };

  vm.removeTemplate = bookingTemplate =>
    confirmService.confirmRemove('booking template', () => {
      const booking = new Booking({id : bookingTemplate.id});
      return booking.$delete(booking.getUrl()).then(() => {
        return entityManager._removeEntity(bookingTemplate);
      });
    })
  ;

  vm.gotoEditTemplate = bookingTemplate =>
    Booking.clone(bookingTemplate.id).then(clonedBookingTemplate =>
      $state.go('public.security-manager.schedule-manager.booking-form.details', {
        bookingId : clonedBookingTemplate.id,
        isTemplate : bookingTemplateType.getEditType(),
        backState : {
          name : $state.current.name,
          params : $state.params
        }
      }
      )
    )
  ;

  const loadBookingTemplates = () =>
    entityManager.loadAll().then(bookingTemplates =>
      _.each(bookingTemplates, function(bookingTemplate) {
        bookingTemplate.fromMoment = moment(bookingTemplate.from, dateService.getIsoDateFormat());
        return bookingTemplate.toMoment = moment(bookingTemplate.to, dateService.getIsoDateFormat());
      })
    )
  ;

  loadBookingTemplates();

  return vm;
};

angular.module('public.security-manager.client.show').controller('ClientShowBookingTemplatesController',
  dependencies.concat(ClientShowBookingTemplatesController));
