/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.client').factory('ClientServiceResourceIcon', ['AppModel', function(AppModel) {

  class ClientServiceResourceIcon extends AppModel {
    static initClass() {
      this.configure({ url : '/security_provider/resource_icons', name: 'resource_icon' });
    }
  }

  ClientServiceResourceIcon.initClass();

  return ClientServiceResourceIcon;

}]);
