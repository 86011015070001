angular.module('sign.security-provider', ['data.security-provider']).config(['$stateProvider', $stateProvider => {

  $stateProvider

    .state('sign.security-provider', {
      abstract: true,
      url: '/security-provider/registration',
      template: require('./template.pug'),
      controller: 'SignSecurityProviderLayoutController',
      controllerAs: 'signSecurityProviderLayoutCtrl'
    })
    .state('sign.security-provider-choose-plan', {
      url: '/security-provider/registration/choose-plan',
      template: require('./choose-plan/template.pug'),
      controller: 'SignSecurityProviderChoosePlanController',
      controllerAs: 'signSecurityProviderChoosePlanCtrl'
    })
    .state('sign.security-provider.step1', {
      url: '/step1',
      template: require('./step1/template.pug'),
      controller: 'SignSecurityProviderStep1Controller',
      controllerAs: 'signSecurityProviderStep1sCtrl'
    })
    .state('sign.security-provider.step2', {
      url: '/step2',
      template: require('./step2/template.pug'),
      controller: 'SignSecurityProviderStep2Controller',
      controllerAs: 'signSecurityProviderStep2Ctrl'
    })
    .state('sign.security-provider.step3', {
      url: '/step3',
      template: require('./step3/template.pug'),
      controller: 'SignSecurityProviderStep3Controller',
      controllerAs: 'signSecurityProviderStep3Ctrl'
    })
    .state('sign.security-provider.step4', {
      url: '/step4',
      template: require('./step4/template.pug'),
      controller: 'SignSecurityProviderStep4Controller',
      controllerAs: 'signSecurityProviderStep4Ctrl'
    })
    .state('sign.security-provider-success', {
      url: '/security-provider/success',
      template: require('./success/template.pug'),
      controller: 'SignSecurityProviderSuccessController',
      controllerAs: 'signSecurityProviderSuccessCtrl'
    })

}]);
