/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsPlanningNotifications = {
  controller: 'PlanningNotificationsController',
  controllerAs: 'planningNotificationsCtrl',
  template: require('./template.pug'),
  bindings: {
    planningInteractor: '<'
  }
};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .component('gccsPlanningNotifications', gccsPlanningNotifications);
