angular.module('sign.payment-message', [
  'data.security-provider',
  'popup'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('sign.payment-message', {
      url: '/payment-message',
      template: require('./template.pug'),
      controller: 'SignPaymentMessageController',
      controllerAs: 'signPaymentMessageCtrl'
    })
    .state('sign.payment-message.deactivated', {
      url: '/deactivated',
      template: require('./deactivated/template.pug'),
      controller: 'SignPaymentMessageDeactivatedController',
      controllerAs: 'signPaymentMessageDeactivatedCtrl'
    })
    .state('sign.payment-message.unpaid', {
      url: '/unpaid',
      template: require('./unpaid/template.pug'),
      controller: 'SignPaymentMessageUnpaidController',
      controllerAs: 'signPaymentMessageUnpaidCtrl'
    })

]);
