/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Maksym Shutiak <maxshutyak>
 */
const dependencies = ['$scope', '$element'];

const PlanningEmployeeSidebarController = function($scope, $element) {

  const vm = this;

  const scrollSidebar = () => $element.css({left : `${vm.scrollService.getCurrentScroll()}px`});

  window.addEventListener('scroll', scrollSidebar);

  $scope.$on('$destroy', () => window.removeEventListener('scroll', scrollSidebar));

  return vm;
};

angular
  .module('public.security-manager.schedule-manager.timeline.planning')
  .controller('PlanningEmployeeSidebarController', dependencies.concat(PlanningEmployeeSidebarController));
