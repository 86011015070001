/*
 * @author Igor Serpak <robobee>
 */
const dependencies = [];

const ClientManagerCompanyLayoutController = function() {

  const vm = this;

  vm.tabs = [{
    name : 'COMPANY DETAILS',
    sref : 'public.client-manager.company.details'
  }
  ];

  return vm;
};

angular
  .module('public.client-manager.company')
  .controller('ClientManagerCompanyLayoutController', dependencies.concat(ClientManagerCompanyLayoutController));
