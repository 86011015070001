angular.module('public.security-manager.client.show').component('clockInPreview', {
  template: require('./template.pug'),
  controller: 'ClientShowClockInPreviewController',
  bindings: {
    headerTitle: '@',
    description: '@',
    image: '@',
    value: '<',
    onValueChange: '&'
  }
});
