angular.module('gccs-ui').directive('gccsSelect', () => {
  return {
    restrict: 'A',
    require: 'ngModel',
    controller: 'SelectController',
    controllerAs: 'selectCtrl',
    template: require('./template.pug'),
    scope: {
      selectClassName: '@?',
      withoutDefaultClass: '@?',
      name: '@?',
      withIcons: '=',
      selectListItems: '=',
      selectedListItems: '<',
      onSelect: '&?',
      addLabel: '@?',
      onAdd: '&?',
      withoutAdd: '<',
      withId: '<',
      editable: '<',
      ignoreKey: '<',
      beforeSelectCb: '&?'
    }
  };
});
