/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.invoices.payroll', [
  'data.employee-payroll',
  'data.employee-invoice',
  'popup.employee-payroll'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.security-manager.invoices.payrolls', {
      url: '/payrolls',
      template: require('./list/template.pug'),
      controller: 'InvoicesPayrollsController',
      controllerAs: 'invoicesPayrollsCtrl'
    })
    .state('public.security-manager.payroll-form', {
      url: '/invoices/payroll/form?{ id, employmentType }',
      template: require('./form/template.pug'),
      controller: 'InvoicesPayrollFormController',
      controllerAs: 'invoicesPayrollFormCtrl',
//      fix weird router behaviour
      onEnter() {
        return window.scrollTo(0, 0);
      }
    })
    .state('public.security-manager.payroll-preview', {
      url: '/invoices/payroll/preview/:id',
      template: require('./preview/template.pug'),
      controller: 'InvoicesPayrollPreviewController',
      controllerAs: 'invoicesPayrollPreviewCtrl'
    })

]);
