const dependencies = [
  '$scope', 'ClientSite', 'ClientLocation', 'EntityManager', 'pluralizeService', 'clientSiteStatus'
];

const ClientShowSitesController = function(
  $scope, ClientSite, ClientLocation, EntityManager, pluralizeService, clientSiteStatus
) {

  const vm = this;

  vm.isShownSitePopup = false;
  vm.isShownClockInPopup = false;
  vm.ascendingNameOrder = true;
  vm.pluralizeService = pluralizeService;
  vm.clientSiteStatus = clientSiteStatus;

  let toggledSite;

  const entityManager = new EntityManager(ClientSite, {
    params: {
      clientId: $scope.clientShowLayoutCtrl.clientId
    }
  });
  
  vm.$onInit = function() {
    initClient();
    entityManager.loadAll();
  };

  vm.showSitePopup = function(site) {
    if (!$scope.clientShowLayoutCtrl.editable) { return; }
    vm.popupSite = site || entityManager.createEntity();
    vm.isShownSitePopup = true;
  };

  vm.closeSitePopup = function(savedSite) {
    vm.isShownSitePopup = false;
    if (savedSite) {
      vm.client.sitesCount++; // todo only for add
      entityManager.updateEntity(savedSite);
    }
  };

  vm.toggleNameOrder = () => vm.ascendingNameOrder = !vm.ascendingNameOrder;

  vm.getSites = () => entityManager.entities;

  vm.removeSite = function (site) {
    entityManager.removeEntity(site, () => vm.client.sitesCount--);
  };

  vm.areShownSiteLocations = function(site) {
    return toggledSite === site;
  };
  
  vm.toggleSiteLocations = function(site) {
    if (vm.areShownSiteLocations(site)) {
      toggledSite = null;
    } else {
      toggledSite = site;
    }
  };
  
  vm.showClockInPopup = function() {
    vm.isShownClockInPopup = true;
  };
  
  vm.closeClockInPopup = function() {
    vm.isShownClockInPopup = false;
  };

  vm.onMobileClockInChange = function(value) {
    vm.client.enableMobileClockIn = value;
    vm.client.save();
  };

  vm.onDesktopClockInChange = function(value) {
    vm.client.enableDesktopClockIn = value;
    vm.client.save();
  };
  
  vm.onLocationRemove = function(location) {
    _.each(vm.getSites(), function(site) {
      const foundLocation = _.find(site.locations, function(siteLocation) {
        return siteLocation.id === location.id;
      });
      if (foundLocation) {
        site.locations = _.without(site.locations, foundLocation);
      }
    });
    new ClientLocation(location).remove();
  };
  
  vm.onLocationRemoveFromSite = function(location) {
    toggledSite.locations = _.without(toggledSite.locations, location);
    toggledSite.save();
  };
  
  vm.onLocationUpdate = function(location) {
    _.each(vm.getSites(), function(site) {
      const foundLocation = _.find(site.locations, function(siteLocation) {
        return siteLocation.id === location.id;
      });
      if (foundLocation) {
        angular.copy(location, foundLocation);
      } else if (vm.areShownSiteLocations(site)) {
        site.locations.push(location);
        site.save();
      }
    });
  };

  vm.getSitePopupTitle = function() {
    if (vm.popupSite.isNew()) {
      return 'Add new site';
    } else {
      return 'Edit site';
    }
  };

  const initClient = function() {
    $scope.clientShowLayoutCtrl.loadClient().then(client => vm.client = client);
  };

  return vm;

};

angular.module('public.security-manager.client.show')
  .controller('ClientShowSitesController', dependencies.concat(ClientShowSitesController));
