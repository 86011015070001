const dependencies = ['employeeType'];

const employeePayrollExportType = function(employeeType) {

  const STANDARD = 1;
  const KASHFLOW = 2;
  const SAGE = 3;
  const LLOYDS_BANK = 4;
  const STANDARD_RATE_AND_HOURS = 5;

  const names = {
    [STANDARD]: 'Standard',
    [STANDARD_RATE_AND_HOURS]: 'Standard - Rate & Hours',
    [KASHFLOW]: 'Kashflow',
    [SAGE]: 'Sage',
    [LLOYDS_BANK]: 'Lloyds Bank'
  };

  const namesForEmployee = {
    [STANDARD]: names[STANDARD],
    [STANDARD_RATE_AND_HOURS]: names[STANDARD_RATE_AND_HOURS],
    [SAGE]: names[SAGE]
  };

  const namesForContractor = {
    [STANDARD]: names[STANDARD],
    [STANDARD_RATE_AND_HOURS]: names[STANDARD_RATE_AND_HOURS],
    [KASHFLOW]: names[KASHFLOW],
    [SAGE]: names[SAGE],
    [LLOYDS_BANK]: names[LLOYDS_BANK]
  };

  const stringTypes = {
    [STANDARD]: 'standard',
    [STANDARD_RATE_AND_HOURS]: 'standard_rate_hours',
    [KASHFLOW]: 'kashflow',
    [SAGE]: 'sage',
    [LLOYDS_BANK]: 'lloyds_bank'
  };

  return {

    getNames() {
      return names;
    },

    getStringType(type) {
      return stringTypes[type];
    },

    getStandard() {
      return STANDARD;
    },

    getNamesFor(employmentType) {
      if (employeeType.isEmployed(employmentType)) {
        return namesForEmployee;
      } else {
        return namesForContractor;
      }
    }

  };
};

angular.module('public.security-manager.invoices')
  .service('employeePayrollExportType', dependencies.concat(employeePayrollExportType));
