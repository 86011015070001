const gccsIconHolder = {
  controller: 'IconController',
  controllerAs: 'iconCtrl',
  template: require('./template.pug'),
  bindToController: true,
  transclude: {
    footer: '?iconFooter'
  },
  bindings: {
    entity: '<',
    iconService: '<',
    isShiftRuleIcon: '<',
    onIconClick: '&?'
  }
};

angular.module('public.security-manager').component('gccsIconHolder', gccsIconHolder);
