angular.module('public.security-manager.employee').directive('gccsEmployeeClientApproveResourcePayRate', () => {
  return {
    restrict: 'A',
    controller: 'EmployeeClientApproveResourcePayRateController',
    controllerAs: 'employeeClientApproveResourcePayRateCtrl',
    template: require('./template.pug'),
    scope: {
      employeeResource: '=',
      onChange: '&'
    }
  };
});
