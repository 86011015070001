const bookingShiftChange = function() {

  const SHIFT_CHANGE_CREATE = 1;
  const SHIFT_CHANGE_DESTROY = 2;
  const SHIFT_CHANGE_NAME = 3;

  return {
    getShiftChangeCreate() {
      return SHIFT_CHANGE_CREATE;
    },

    getShiftChangeDestroy() {
      return SHIFT_CHANGE_DESTROY;
    },

    getShiftChangeName() {
      return SHIFT_CHANGE_NAME;
    }
  };
};

angular.module('data.booking').service('bookingShiftChange', bookingShiftChange);
