/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  'Employee', 'BookingShiftEmployeeRoot', 'DayOff', 'PlanningTimelineEntity', 'dateService', 'employeeStatus', '$q',
  'employeeDayOffRequestStatus', 'PlanningTimelineActions'
];

const PlanningEmployeeService = function(
  Employee, BookingShiftEmployeeRoot, DayOff, PlanningTimelineEntity, dateService, employeeStatus, $q,
  employeeDayOffRequestStatus, PlanningTimelineActions
) {

  const ISO_DATE_FORMAT = dateService.getIsoDateFormat();
  const ISO_FULL_DATE_FORMAT = dateService.getIsoFullDateFormat();

  const DayOffEntity = PlanningTimelineEntity.DayOffEntity();
  const PlaceholderEntity = PlanningTimelineEntity.PlaceholderEntity();
  const ShiftEmployeeEntity = PlanningTimelineEntity.ShiftEmployeeEntity();

  class HighlightService {

    constructor() {
      this.openedRowId = undefined;
      this.openedEntityShiftId = undefined;
    }

    openNewShiftPopup(employeeRows, rowId, shiftId) {
      this.openedRowId = rowId || this.openedRowId;
      this.openedEntityShiftId = shiftId || this.openedEntityShiftId;
      if (!this.openedRowId) { return; }
      if (!this.openedEntityShiftId) { return; }
      const row = _.find(employeeRows, r => r.id === this.openedRowId);
      if (!row) { return this.clearHighlights(); }
      return (() => {
        const result = [];
        for (let e of Array.from(row.entities)) {
          if (e.isPlaceholder) {
            e.isHighlighted = true;
            if (e.shiftId === this.openedEntityShiftId) {
              result.push(e.isOpen = true);
            } else {
              result.push(e.isOpen = false);
            }
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    }

    closeNewShiftPopup(employeeRows, rowId, shiftId) {
      const row = _.find(employeeRows, r => r.id === rowId);
      for (let e of Array.from(row.entities)) {
        if (e.isPlaceholder) { e.isHighlighted = undefined; }
        if (e.shiftId === shiftId) { e.isOpen = undefined; }
      }
      this.openedRowId = undefined;
      return this.openedEntityShiftId = undefined;
    }

    toggleNewShiftPopup(employeeRows, row, entity) {
      if (!this.openedRowId || !this.openedEntityShiftId) {
        this.openNewShiftPopup(employeeRows, row.id, entity.shiftId);
        return;
      }
      if ((this.openedRowId === row.id) && (this.openedEntityShiftId === entity.shiftId)) {
        return this.closeNewShiftPopup(employeeRows, row.id, entity.shiftId);
      } else {
        this.closeNewShiftPopup(employeeRows, this.openedRowId, this.openedEntityShiftId);
        return this.openNewShiftPopup(employeeRows, row.id, entity.shiftId);
      }
    }

    setShiftHighlights(selectedShifts, employeeRows) {
      if (!this.openedRowId || !this.openedEntityShiftId) { return; }
      if (selectedShifts.length === 0) {
        return this._removeHighlights(employeeRows);
      } else {
        return this.openNewShiftPopup(employeeRows);
      }
    }

    _removeHighlights(employeeRows) {
      const row = _.find(employeeRows, r => r.id === this.openedRowId);
      for (let e of Array.from(row.entities)) {
        if (e.isPlaceholder) {
          e.isHighlighted = undefined;
          if (e.shiftId === this.openedEntityShiftId) {
            e.isOpen = undefined;
          }
        }
      }
      this.openedRowId = undefined;
      return this.openedEntityShiftId = undefined;
    }

    clearHighlights() {
      this.openedRowId = undefined;
      return this.openedEntityShiftId = undefined;
    }
  }

  const ENTITY_HEIGHT = 31;
  const TOP_ROW_PADDING = 10;
  const BOTTOM_ROW_PADDING = 9;
  const TOTAL_ROW_PADDING = TOP_ROW_PADDING + BOTTOM_ROW_PADDING;
  const INSIDE_PADDING = 8;
  const HEIGHT_WITH_PADDING = ENTITY_HEIGHT + INSIDE_PADDING;

  return class PlanningEmployeeService {

    constructor(planningInteractor, shiftDetailsService) {
      this._updateSize = this._updateSize.bind(this);
      this.invitePlaceholders = this.invitePlaceholders.bind(this);
      this.assignPlaceholders = this.assignPlaceholders.bind(this);
      this.assign = this.assign.bind(this);
      this.unassign = this.unassign.bind(this);
      this.showDetails = this.showDetails.bind(this);
      this.highlightService = new HighlightService();
      this.planningInteractor = planningInteractor;
      this.shiftDetailsService = shiftDetailsService;
      this.actionsService = new PlanningTimelineActions();
      this._resetParams();
      this.employeesPromise = this._loadEmployees().then(employees => {
        return this.employees = employees;
      });
      const dates = this.planningInteractor.getDates();
      this._updateDates(dates.from, dates.to);
      this.planningInteractor.subscribeToDateChanges(({ from, to }) => this._updateDates(from, to));
      this.planningInteractor.subscribeToQuit(this.actionsService.commitChanges);
      this.planningInteractor.subscribeToResize(this._updateSize);
    }

    // TODO:
    shiftLocked(data) {}
    shiftUnlocked(data) {}

    dayOffUpdated(data) {
      if (!this._insideView(data)) { return; }
      return this._loadDayOff(data.id).then(dayOff => {
        if (!this._insideView(dayOff)) { return; }
        if (dayOff.status === employeeDayOffRequestStatus.getRejectedStatus()) {
          const removed = _.remove(this.dayOffs, dayOff => dayOff.id === data.id);
          if (removed) { return this._setEmployeeRows(); }
        } else {
          this._replaceOrAddById(this.dayOffs, dayOff);
          return this._setEmployeeRows();
        }
      });
    }

    dayOffDestroyed(data) {
      if (!this._insideView(data)) { return; }
      const removed = _.remove(this.dayOffs, dayOff => dayOff.id === data.id);
      if (removed) { return this._setEmployeeRows(); }
    }

    shiftEmployeeDestroyed(data) {
      if (!this._insideView(data)) { return; }
      const removed = _.remove(this.shiftEmployees, shiftEmployee => shiftEmployee.id === data.shift_employee_id);
      if (removed) { return this._setEmployeeRows(); }
    }

    shiftEmployeeChanged(data) {
      if (!this._insideView(data)) { return; }
      return this._loadShiftEmployee(data.shift_employee_id).then(shiftEmployee => {
        if (!this._insideView(shiftEmployee)) { return; }
        this._replaceOrAddById(this.shiftEmployees, shiftEmployee);
        return this._setEmployeeRows();
      });
    }

    _replaceOrAddById(collection, newEntity) {
      const i = _.findIndex(collection, entity => entity.id === newEntity.id);
      if (i >= 0) {
        return collection[i] = newEntity;
      } else {
        return collection.push(newEntity);
      }
    }

    _insideView(entity) {
      let startTime = entity.startTime || entity.start_time;
      let endTime = entity.endTime || entity.end_time;
      if (!moment.isMoment(startTime)) { startTime = moment(startTime, ISO_FULL_DATE_FORMAT); }
      if (!moment.isMoment(endTime)) { endTime = moment(endTime, ISO_FULL_DATE_FORMAT); }
      return !((this.from.diff(endTime) >= 0) || (startTime.diff(this.to) >= 0));
    }

    _updateDates(from, to) {
      this.from = from;
      this.to = to;
      return this._initEmployeeRows();
    }

    _loadShiftEmployees() {
      return BookingShiftEmployeeRoot.query({
        from: this.from.format(ISO_DATE_FORMAT),
        to: this.to.format(ISO_DATE_FORMAT)
      });
    }

    _loadShiftEmployee(id) {
      return BookingShiftEmployeeRoot.get(id);
    }

    _loadDayOffs() {
      return DayOff.query({
        from: this.from.format(ISO_DATE_FORMAT),
        to: this.to.format(ISO_DATE_FORMAT),
        status: employeeDayOffRequestStatus.getApprovedStatus()
      });
    }

    _loadDayOff(id) {
      return DayOff.get(id);
    }

    _loadEmployees() {
      return Employee.query(_.assign({
        status: employeeStatus.getActiveStatus(),
        template: 'planning'
      }, this.employeeQueryParams));
    }

    _initEmployeeRows() {
      this.selectedShifts = [];
      this.planningInteractor.isEmployeesLoading = true;
      return $q.all([this._loadShiftEmployees(), this._loadDayOffs()]).then(data => {
        [this.shiftEmployees, this.dayOffs] = Array.from(data);
        return this.employeesPromise.then(() => {
          if (this.planningInteractor.hourWidth) { this._setEmployeeRows(); }
          return this.planningInteractor.isEmployeesLoading = false;
        });
      });
    }

    _resetParams() {
      this.employees = [];
      this.shiftEmployees = [];
      this.dayOffs = [];
      this.employeeQueryParams = {};
      return this.selectedShifts = [];
    }

    _setEmployeeRows() {
      const employeeIds = [];
      const employeesObj = {};
      for (let employee of Array.from(this.employees)) {
        if (this.filterString && !employee.searchString.includes(this.filterString)) { continue; }
        employeesObj[employee.id] = { employee, entities: [] };
        employeeIds.push(employee.id);
      }
      for (const shiftEmployee of Array.from(this.shiftEmployees)) {
        if (employeesObj[shiftEmployee.employeeId]) {
          employeesObj[shiftEmployee.employeeId].entities.push(new ShiftEmployeeEntity(shiftEmployee));
        }
      }
      for (const dayOff of Array.from(this.dayOffs)) {
        if (employeesObj[dayOff.employeeId]) {
          employeesObj[dayOff.employeeId].entities.push(new DayOffEntity(dayOff));
        }
      }

      let employeeRows = [];
      for (let id of Array.from(employeeIds)) {
        employeeRows.push({
          id,
          avatar: employeesObj[id].employee.user.thumbAvatar.url,
          fullName: employeesObj[id].employee.user.fullName,
          initials: employeesObj[id].employee.user.firstName.charAt(0) + employeesObj[id].employee.user.lastName.charAt(0),
          searchString: employeesObj[id].employee.searchString,
          entities: _.sortBy(employeesObj[id].entities, 'startTimeValue')
        });
      }
      employeeRows = _.sortBy(employeeRows, 'fullName');
      this.employeeRowsSource = _.cloneDeep(employeeRows);
      this.actionsService.mergeShiftEmployees(employeeRows, this.from, this.to);
      this._setShiftPlaceholders(employeeRows);
      return this._setEmployeeRowGroups(employeeRows);
    }

    // TODO: ask Maxim if we can make offsets inside employee row
    _setEmployeeRowGroups(employeeRows) {
      let rowTopOffset = 0;
      let intersection;
      for (let employeeRow of Array.from(employeeRows)) {
        let entity;
        const rows = [];
        for (let i = 0; i < employeeRow.entities.length; i++) {
          // explicitly setting for cloned data
          let row;
          entity = employeeRow.entities[i];
          entity.row = undefined;
          entity.intersections = [];
          entity.dashedIntersections = [];
          for (let k = 0; k < rows.length; k++) {
            row = rows[k];
            for (let j = 0; j < row.length; j++) {
              const addedEntity = row[j];
              if ((addedEntity.id !== entity.id) && (intersection = this._getIntersection(addedEntity, entity))) {
                this._addIntersections(entity, addedEntity, intersection);
              } else {
                // uses invariant: entities are sorted by time
                if ((j === (row.length - 1)) && (entity.row === undefined)) {
                  row.push(entity);
                  entity.row = k;
                }
              }
            }
          }
          if (entity.row === undefined) {
            rows.push([entity]);
            entity.row = rows.length - 1;
          }
          this._setEntityStyles(entity, rowTopOffset);
        }
        for (entity of Array.from(employeeRow.entities)) {
          entity.intersections = this._mergeIntersections(entity, entity.intersections);
          entity.dashedIntersections = this._mergeIntersections(entity, entity.dashedIntersections);
        }

        const rowHeight = TOTAL_ROW_PADDING +
          (ENTITY_HEIGHT * Math.max(rows.length, 1)) +
          (INSIDE_PADDING * Math.max(rows.length - 1, 0));
        rowTopOffset += rowHeight;
        employeeRow.styles = { height: rowHeight + 'px' };
      }
      return this.employeeRows = employeeRows;
    }

    _setEntityStyles(entity, rowTopOffset) {
      if (!entity.leftOffsetValue) { entity.leftOffsetValue = this._getLeftOffset(entity); }
      if (!entity.styles) { entity.styles = {}; }
      if (!entity.styles.left) { entity.styles.left = entity.leftOffsetValue + 'px'; }
      if (!entity.styles.width) { entity.styles.width = this._getWidth(entity) + 'px'; }
      return entity.styles.top = (rowTopOffset + TOP_ROW_PADDING + (entity.row * HEIGHT_WITH_PADDING)) + 'px';
    }

    // returns null if entities do not intersect
    // TODO: could probably use left / width for intersections to optimize speed
    _getIntersection(e1, e2) {
      if (this._intersects(e1, e2)) {
        if (e1.startTimeValue < e2.startTimeValue) {
          if (e1.endTimeValue < e2.endTimeValue) {
            return { startTimeValue: e2.startTimeValue, endTimeValue: e1.endTimeValue };
          } else {
            return { startTimeValue: e2.startTimeValue, endTimeValue: e2.endTimeValue };
          }
        } else {
          if (e1.endTimeValue < e2.endTimeValue) {
            return { startTimeValue: e1.startTimeValue, endTimeValue: e1.endTimeValue };
          } else {
            return { startTimeValue: e1.startTimeValue, endTimeValue: e2.endTimeValue };
          }
        }
      } else {
        return null;
      }
    }

    // TODO: since this fn is only used on sorted values can probably drop one check
    _intersects(p1, p2) {
      return (p1.endTimeValue > p2.startTimeValue) && (p1.startTimeValue < p2.endTimeValue);
    }

    _intersectsStrictly(p1, p2) {
      return (p1.endTimeValue >= p2.startTimeValue) && (p1.startTimeValue <= p2.endTimeValue);
    }

    _addIntersections(e1, e2, intersection) {
      if (e1.isPlaceholder) {
        if (e2.isPlaceholder) { return; }
        return e2.dashedIntersections.push(intersection);
      } else if (e2.isPlaceholder) {
        return e1.dashedIntersections.push(intersection);
      } else {
        e1.intersections.push(_.clone(intersection));
        return e2.intersections.push(intersection);
      }
    }

    _mergeIntersections(entity, intersections) {
      let intersection;
      if (intersections.length === 0) { return intersections; }
      let mergedIntersections = [];
      if (intersections.length === 1) {
        mergedIntersections = intersections;
      } else {
        mergedIntersections = [];
        if (intersections.length > 0) { intersections = _.sortBy(intersections, 'startTimeValue'); }
        let current = intersections[0];
        for (let i = 0; i < intersections.length; i++) {
          intersection = intersections[i];
          if (i === 0) { continue; }
          if (!this._intersectsStrictly(intersection, current)) {
            mergedIntersections.push(current);
            current = intersection;
          } else {
            current = {
              startTimeValue: current.startTimeValue,
              endTimeValue: Math.max(intersection.endTimeValue, current.endTimeValue)
            };
          }
        }
        mergedIntersections.push(current);
      }
      for (intersection of Array.from(mergedIntersections)) {
        intersection.startTime = moment(intersection.startTimeValue);
        intersection.endTime = moment(intersection.endTimeValue);
        intersection.left = (this._getLeftOffset(intersection) - entity.leftOffsetValue) + 'px';
        intersection.width = this._getWidth(intersection) + 'px';
      }
      return mergedIntersections;
    }

    _getWidth(entity) {
      const endTime = moment.min(entity.endTime, this.planningInteractor.to);
      return this.planningInteractor.hourWidth * endTime.diff(entity.startTime, 'hours', true);
    }

    _getLeftOffset(entity) {
      const hoursDiffFromStart = entity.startTime.diff(this.planningInteractor.from, 'hours', true);
      return (hoursDiffFromStart * this.planningInteractor.hourWidth) - (this.planningInteractor.hourWidth / 2);
    }

    _setShiftPlaceholders(employeeRows) {
      for (let row of employeeRows) {
        // TODO: can probably avoid recreating all placeholders here
        let entities = [];
        const shiftEmployees = [];
        for (let entity of row.entities) {
          if (!entity.isPlaceholder) { entities.push(entity); }
          if (entity.isShiftEmployee) { shiftEmployees.push(entity); }
        }
        let added = false;
        for (let dShift of this.selectedShifts) {
          if (dShift.shift.requiredEmployeesCount <= 0) { continue; }
          if (_.some(shiftEmployees, entity => entity.shiftId === dShift.shift.id)) { continue; }
          entities.push(new PlaceholderEntity(dShift, row.id));
          added = true;
        }
        if (added) {
          entities = _.sortBy(entities, entity => entity.startTimeValue);
        }
        row.entities = entities;
      }
      // TODO: check, refactor
      return this.highlightService.setShiftHighlights(this.selectedShifts, employeeRows);
    }

    _updateSize() {
      if (!this.shiftEmployees) { return; }
      this.planningInteractor.isEmployeesLoading = true;
      this._setEmployeeRows();
      return this.planningInteractor.isEmployeesLoading = false;
    }

    shiftSelected(dShift) {
      this.selectedShifts.push(dShift);
      const employeeRows = _.cloneDeep(this.employeeRows);
      this._setShiftPlaceholders(employeeRows);
      return this._setEmployeeRowGroups(employeeRows);
    }

    shiftDeselected(dShift) {
      _.remove(this.selectedShifts, s => s.shift.id === dShift.shift.id);
      const employeeRows = _.cloneDeep(this.employeeRows);
      this._setShiftPlaceholders(employeeRows);
      return this._setEmployeeRowGroups(employeeRows);
    }

    selectedShiftsCleared() {
      if (this.selectedShifts.length === 0) { return; }
      for (let shift of Array.from(this.selectedShifts)) {
        shift.isChecked = false;
      }
      this.selectedShifts = [];
      const employeeRows = _.cloneDeep(this.employeeRows);
      this._setShiftPlaceholders(employeeRows);
      return this._setEmployeeRowGroups(employeeRows);
    }

    toggleShiftEmployeePopup(row, entity) {
      if (entity.isPlaceholder) {
        return this.highlightService.toggleNewShiftPopup(this.employeeRows, row, entity);
      } else if (entity.isShiftEmployee) {
        return entity.isOpen = !entity.isOpen;
      }
    }

    invitePlaceholders(entity, row) {
      const entities = _.filter(row.entities, e => e.isPlaceholder);
      this.actionsService.addInvites(this.employeeRowsSource, entities, row.id);
      const employeeRows = _.cloneDeep(this.employeeRowsSource);
      this.actionsService.mergeShiftEmployees(employeeRows, this.from, this.to);
      this.highlightService.clearHighlights();
      this._setShiftPlaceholders(employeeRows);
      return this._setEmployeeRowGroups(employeeRows);
    }

    assignPlaceholders(entity, row) {
      const entities = _.filter(row.entities, e => e.isPlaceholder);
      const shifts = this._getShiftsByIds(_.map(entities, e => e.shiftId));
      this.actionsService.addAssigns(this.employeeRowsSource, entities, shifts, row.id);
      return this._updateEmployeeRows();
    }

    assign(entity, row, shiftLoader) {
      return shiftLoader.getShift(entity.shiftId).then(shift => {
        this.actionsService.addAssigns(this.employeeRowsSource, [entity], [shift], row.id);
        return this._updateEmployeeRows();
      });
    }

    unassign(entity, row, shiftLoader) {
      return shiftLoader.getShift(entity.shiftId).then(shift => {
        this.actionsService.addUnassign(entity, shift);
        return this._updateEmployeeRows();
      });
    }

    showDetails(entity, row, shiftLoader, $event) {
      return this.shiftDetailsService.showPopup(entity, $event.target.closest('.planning-employee-shift'));
    }

    getEntityActionButtons(entity) {
      if (entity.isPlaceholder) {
        return this._placeholderButtons || (this._placeholderButtons = [{
          class: 'mod-blue',
          onClickCb: this.assignPlaceholders,
          text: 'Assign'
        }, {
          onClickCb: this.invitePlaceholders,
          text: 'Invite'
        }]);
      } else if (entity.isShiftEmployee) {
        if (entity.isAssign) {
          return this._shiftEmployeeAssignButtons || (this._shiftEmployeeAssignButtons = [{
            class: 'mod-red',
            onClickCb: this.unassign,
            text: 'Unassign'
          } , {
            class: 'mod-black',
            onClickCb: this.showDetails,
            text: 'Details'
          }]);
        } else if (entity.isInvite) {
          return this._shiftEmployeeInviteButtons || (this._shiftEmployeeInviteButtons = [{
            class: 'mod-blue',
            onClickCb: this.assign,
            text: 'Assign'
          }, {
            class: 'mod-red',
            onClickCb: this.unassign,
            text: 'Uninvite'
          }, {
            class: 'mod-black',
            onClickCb: this.showDetails,
            text: 'Details'
          }]);
        }
      }
    }

    closePopup(entity, row) {
      return this.highlightService.closeNewShiftPopup(this.employeeRows, row.id, entity.shiftId);
    }

    _getShiftsByIds(shiftIds) {
      const shifts = [];
      for (let shiftId of Array.from(shiftIds)) {
        shifts.push(_.find(this.selectedShifts, dShift => dShift.shift.id === shiftId));
      }
      return shifts;
    }

    _updateEmployeeRows() {
      const employeeRows = _.cloneDeep(this.employeeRowsSource);
      this.actionsService.mergeShiftEmployees(employeeRows, this.from, this.to);
      this.highlightService.clearHighlights();
      this._setShiftPlaceholders(employeeRows);
      return this._setEmployeeRowGroups(employeeRows);
    }

    setQueryParams(queryParams) {
      this.employeeQueryParams = queryParams;
      return this._loadEmployees().then(employees => {
        this.employees = employees;
        return this._setEmployeeRows();
      });
    }

    updateFilterString(filterString) {
      this.filterString = filterString.toLowerCase();
      return this._setEmployeeRows();
    }
    
  }

};

angular
  .module('public.security-manager.schedule-manager.timeline.planning')
  .factory('PlanningEmployeeService', dependencies.concat(PlanningEmployeeService));
