/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').service('elementService', function() {

  const getOffset = (element, property) => Math.round(element.getBoundingClientRect()[property]);

  const setCarretOffset = function(element, position) {
    const { firstChild } = element;
    if (firstChild) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.setStart(element.firstChild, position);
      range.collapse(true);
      selection.removeAllRanges();
      return selection.addRange(range);
    }
  };

  return {
    scrollInto(element) {
      if (!element) { return; }
      if (element.scrollIntoViewIfNeeded) {
        return element.scrollIntoViewIfNeeded();
      } else {
        const clientRect = document.body.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();
        if ((elementRect.top < clientRect.top) || (elementRect.bottom > clientRect.bottom)) { return elementRect.scrollIntoView(); }
      }
    },

    getOffsetLeft(element) {
      return getOffset(element, 'left');
    },

    getOffsetTop(element) {
      return getOffset(element, 'top');
    },

    getCaretOffset(element) {
      let caretOffset = 0;
      const doc = element.ownerDocument || element.document;
      const win = doc.defaultView || doc.parentWindow;
      let selection = undefined;
      if (typeof win.getSelection !== 'undefined') {
        selection = win.getSelection();
        if (selection.rangeCount > 0) {
          const range = win.getSelection().getRangeAt(0);
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          caretOffset = preCaretRange.toString().length;
        }
      } else if ((selection = doc.selection) && (selection.type !== 'Control')) {
        const textRange = selection.createRange();
        const preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint('EndToEnd', textRange);
        caretOffset = preCaretTextRange.text.length;
      }
      return caretOffset;
    },

    focus(element) {
      if (!element) { return; }
      element.focus();
      if (element.contentEditable === 'true') { return setCarretOffset(element, element.innerText.length); }
    },

    setCaretOffset : setCarretOffset
  };
});
