const bookingChangeService = function() {

  const TYPE_BOOKING_CHANGE = 1;
  const TYPE_SHIFT_CHANGE = 2;

  return {
    getBookingChangeType() {
      return TYPE_BOOKING_CHANGE;
    },

    getShiftChangeType() {
      return TYPE_SHIFT_CHANGE;
    }
  };
};

angular.module('data.booking').service('bookingChangeService', bookingChangeService);
