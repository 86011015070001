/*
 * @author Oleksandr Papka <papkaos>
 */
const gccsSecurityManagerClientEmailReceiver = {
  template: require('./template.pug'),
  controller: 'SecurityManagerClientEmailReceiverController',
  controllerAs: 'securityManagerClientEmailReceiverCtrl',
  bindings: {
    receivers: '<'
  }
};

angular.module('public.security-manager.client.show')
  .component('gccsSecurityManagerClientEmailReceiver', gccsSecurityManagerClientEmailReceiver);
