const dependencies = ['Report', 'ExtendedListFilterService'];

const ExtendedListFilterController = function(Report, ExtendedListFilterService) {

  const vm = this;

  vm.isShownTooltip = true;
  
  vm.$onInit = function() {
    Report.getEmployeeFilterCategories().then(filterData => {
      vm.service = new ExtendedListFilterService(filterData, vm.applyFilterCb, true);
      vm.service.initDefaultFilter();
    });
  };
  
  vm.closeTooltip = function() {
    vm.isShownTooltip = false;
  };

  vm.toggleFilter = function() {
    vm.service.toggleOpen();
    vm.closeTooltip();
  };

  vm.isShownForTagsForAccessRights = function() {
    return true;
  };

  return vm;
  
};

angular.module('shared-components.extended-filters')
  .controller('ExtendedListFilterController', dependencies.concat(ExtendedListFilterController));