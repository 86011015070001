/**
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const securityProviderPayPeriodDate = function() {

  const DATE = 1;
  const END = 2;

  const END_OF_MONTH = 31;

  const names = {
    [END]: 'End of the month',
    [DATE]: 'Date'
  };

  return {
    getNames() {
      return names;
    },

    getEnd() {
      return END;
    },

    getDate() {
      return DATE;
    },

    getEndOfMonth() {
      return END_OF_MONTH;
    },

    isDate(type) {
      return type === DATE;
    },

    isEnd(type) {
      return type === END;
    }
  };
};

angular.module('public.security-manager.settings')
  .service('securityProviderPayPeriodDate', securityProviderPayPeriodDate);
