/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const CableChannel = function() {

  class CableChannel {

    constructor(cable, channelName, params) {
      this.destroy = this.destroy.bind(this);
      params = _.assign({ channel : channelName }, params || {});
      this.destroy();
      this.channel = cable.subscriptions.create(params, {
        received : data => {
          let cb;
          if (this.callbacks[data.type]) {
            for (cb of Array.from(this.callbacks[data.type])) { cb(data); }
          }
          return (() => {
            const result = [];
            for (cb of Array.from(this.callbacks.default)) {result.push(cb(data));
            }
            return result;
          })();
        }
      });
    }

    destroy() {
      if (this.channel != null) {
        this.channel.unsubscribe();
      }
      this.channel = undefined;
      this.removeCallbacks();
    }

    addCallback(type, cb) {
      if (!this.callbacks[type]) { this.callbacks[type] = []; }
      return this.callbacks[type].push(cb);
    }

    setCallbacks(type, cbs) {
      return this.callbacks[type] = cbs;
    }

    addCallbackWithCleanup($scope, type, cb) {
      this.addCallback(type, cb);
      const cleanup = () => {
        return this.removeCallback(type, cb);
      };
      window.onunload = cleanup;
      return $scope.$on('$destroy', cleanup);
    }

    removeCallback(type, cb) {
      if (this.callbacks[type]) {
        return this.callbacks[type] = _.without(this.callbacks[type], cb);
      }
    }

    removeCallbacks() {
      this.callbacks = { default: [] };
    }

    addDefaultCallback(cb) {
      return this.callbacks.default.push(cb);
    }

    removeDefaultCallback(cb) {
      return this.removeCallback('default', cb);
    }
  }

  return CableChannel;
};

angular.module('shared').factory('CableChannel', CableChannel);
