/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', '$q', 'ClientService', 'EntityManager'];

const ClientRegistrationServiceCreationController = function($scope, $q, ClientService, EntityManager) {

  const vm = this;

  const entityManager = new EntityManager(ClientService, {
    params: {
      clientId: $scope.clientRegistrationLayoutCtrl.clientId
    },
    withBlank: true
  });

  vm.addNewService = entityManager.addNewEntity;
  vm.removeService = entityManager.removeEntity;

  vm.getServices = () => entityManager.entities;

  entityManager.loadAll();

  $scope.$on('client.registration.submit', function() {
    let hasEmptyResources = false;
    _.each(vm.getServices(), function(service) {
      if (service.resources.length === 0) {
        hasEmptyResources = true;
        return false;
      }
    });
    if (hasEmptyResources) {
      return $scope.clientRegistrationLayoutCtrl.resetFormSubmitted();
    } else {
      return entityManager.saveAll().then(() => $scope.clientRegistrationLayoutCtrl.goNext());
    }
  });

  return vm;

};

angular
  .module('public.security-manager.client.registration')
  .controller('ClientRegistrationServiceCreationController',
    dependencies.concat(ClientRegistrationServiceCreationController));
