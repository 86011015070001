/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsEmployeePreview = {
  controller: 'EmployeePreviewController',
  controllerAs: 'employeePreviewCtrl',
  template: require('./template.pug'),
  bindings: {
    employeeId: '<'
  }
};

angular.module('public.security-manager.employee').component('gccsEmployeePreview', gccsEmployeePreview);
