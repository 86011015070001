/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope'];

const SettingsManagersHeaderController = function($scope) {

  const vm = this;

  vm.headerItems = [{
    name : 'Client name',
    sortableParam : $scope.nameOrderParam
  }
  ,
    {name : 'Email'}
  ,
    {name : 'Access level'}
  ];

  vm.toggleOrder = function(headerItem) {
    if (headerItem.sortableParam !== undefined) {
      return headerItem.sortableParam.ascending = !headerItem.sortableParam.ascending;
    }
  };

  vm.getClassName = function(headerItem) {
    if (headerItem.sortableParam !== undefined) {
      let className = 'mod-sortable';
      if (headerItem.sortableParam.ascending) {
        className += ' mod-sortable-bottom';
      } else {
        className += ' mod-sortable-top';
      }
      return className;
    } else {
      return '';
    }
  };

  return vm;
};

angular.module('public.security-manager.settings').controller('SettingsManagersHeaderController',
  dependencies.concat(SettingsManagersHeaderController));
