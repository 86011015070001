/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'bookingShiftCommentEditMode'];

const PopupBookingShiftCommentEditController = function ($scope, bookingShiftCommentEditMode) {

  const vm = this;

  $scope.popupFormCtrl.isShortPopup = true;
  $scope.popupFormCtrl.title = "Copy shift's comments";

  vm.editMode = bookingShiftCommentEditMode.getOnlyCurrentMode();
  vm.radioButtons = [{
    title: 'Only for current shift',
    sign: 'All other shifts will not have current comments.',
    value: bookingShiftCommentEditMode.getOnlyCurrentMode()
  }, {
    title: 'This Shift Onwards',
    sign: 'This and all following shifts in the series will be changed.',
    value: bookingShiftCommentEditMode.getFollowingMode()
  }];

  $scope.$on('popup.open', function () {
    if ($scope.popupShowCondition) {
      vm.editMode = bookingShiftCommentEditMode.getOnlyCurrentMode();
    }
  });

  $scope.$on('popup.close', () => $scope.commentEditModeService.onFail());

  $scope.$on('popup.submit', function () {
    $scope.commentEditModeService.onSuccess(vm.editMode);
    $scope.popupCtrl.closePopup();
  });

  return vm;

};

angular.module('popup.booking')
  .controller('PopupBookingShiftCommentEditController', dependencies.concat(PopupBookingShiftCommentEditController));
