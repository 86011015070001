/**
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$stateParams'];

const EmployeeShowNotesController = function($stateParams) {

  const vm = this;

  vm.employeeId = $stateParams.employeeId;

  return vm;

};

angular.module('public.security-manager.employee')
  .controller('EmployeeShowNotesController', dependencies.concat(EmployeeShowNotesController));
