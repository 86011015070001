/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.client').factory('ClientServiceResource', ['Resource', function(Resource) {

  class ClientServiceResource extends Resource {
    static initClass() {
      this.configure({url : '/clients/{{clientId}}/services/{{serviceId}}/resources/{{id}}'});
    }
  }

  ClientServiceResource.initClass();
  return ClientServiceResource;
}
]);
