/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'uploadService', 'clientStatus', 'currentUserService', 'confirmService'];

const PopupClientFormController = function($scope, uploadService, clientStatus, currentUserService, confirmService) {

  const vm = this;

  vm.clientStatus = clientStatus;

  vm.canEditStatus = () => currentUserService.isSecurityManagerLogged();

  const closePopup = client =>
    $scope.popupCtrl.closePopup(() => $scope.popupCloseCb({client}))
  ;

  $scope.popupFormCtrl.title = 'Edit company profile';

  const onSuccessSubmit = function() {
    return uploadService.saveWithFile(vm.client, 'logo', function(client) {
      const previousStatus = $scope.client.status;
      const newStatus = vm.client.status;
      if (clientStatus.isActive(previousStatus) && (clientStatus.isInactive(newStatus) || clientStatus.isIssued(newStatus))) {
        return confirmService.confirm(
          'Cancel future shifts/bookings?',
          `Do you want to cancel future shifts/bookings of ${vm.client.name}?`,
          'Yes, cancel future bookings',
          () =>
            client.cancelFutureShifts().then(() => closePopup(client))

          ,
          () => closePopup(client));
      } else {
        return closePopup(client);
      }
    });
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      return vm.client = angular.copy($scope.client);
    }
  });

  $scope.$on('popup.submit', () => {
    if ($scope.client.status !== clientStatus.getActiveStatus() && vm.client.status === clientStatus.getActiveStatus()) {
      $scope.checkPackageRestrictions({ successPopupSubmitCb: onSuccessSubmit });
    } else {
      onSuccessSubmit();
    }
  });

  return vm;
};

angular.module('popup.client').controller('PopupClientFormController', dependencies.concat(PopupClientFormController));
