/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['EmployeeResource', 'confirmService'];

const EmployeeClientApproveClientPreviewController = function(EmployeeResource, confirmService) {

  const vm = this;

  vm.defaultColor = '#373F51';
  vm.currentColor = vm.defaultColor;
  vm.colors = [vm.defaultColor, '#1EC882', '#FF4743', '#C64DFF', '#FFAD4D'];

  vm.$onInit = function() {
    vm.setPlaceHolderBackground();
  };

  vm.setPlaceHolderBackground = () => {
    vm.currentColor = vm.colors[Math.floor(Math.random() * vm.colors.length)];
  };

  vm.removeClient = function() {
    return confirmService.confirm('Confirm remove', 'This action will remove all approvals', 'Yes', function () {
      _.each(vm.client.resourceIds, function(resourceId) {
        const removingEmployeeResource = new EmployeeResource({
          resourceId,
          employeeId: vm.employee.id
        });
        return removingEmployeeResource.remove().then(() => {
          removeEmployeeResource(removingEmployeeResource);
          removeClientResource(removingEmployeeResource);
        });
      });
    });
  };

  const removeEmployeeResource = removingEmployeeResource => {
    return _.remove(vm.employee.employeeResources, employeeResource => {
      return employeeResource.resourceId === removingEmployeeResource.resourceId;
    });
  };

  const removeClientResource = function(removingResource) {
    _.remove(vm.client.resourceIds, clientResourceId => {
      return clientResourceId === removingResource.resourceId;
    });
  };

  return vm;

};

angular.module('public.security-manager.employee')
  .controller(
    'EmployeeClientApproveClientPreviewController',
    dependencies.concat(EmployeeClientApproveClientPreviewController)
  );
