/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['currentUserService'];

const PlanningTutorialService = currentUserService => {

  const TUTORIAL_STEPS_COUNT = 4;

  return class PlanningTutorialService {

    constructor() {
      if (currentUserService.getCurrentUser().isSeenPlanningTutorial) {
        this.isActive = false;
      } else {
        this.startTutorial();
      }
    }

    startTutorial() {
      this.isActive = true;
      return this.step = 1;
    }

    nextStep() {
      if (this.step === TUTORIAL_STEPS_COUNT) {
        this.isActive = false;
        const currentUser = currentUserService.getCurrentUser();
        currentUser.isSeenPlanningTutorial = true;
        return currentUser.save();
      } else {
        return this.step++;
      }
    }

  };

};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .factory('PlanningTutorialService', dependencies.concat(PlanningTutorialService));
