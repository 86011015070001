const dependencies = ['BookingShift'];

const BookingShiftRoot = BookingShift => {

  class BookingShiftRoot extends BookingShift {
    static initClass() {
      this.configure({url: '/shifts/{{id}}', name: 'shift'});
    }

    static planning(params) {
      return this.$get(this.resourceUrl('planning'), params);
    }

    loadIssueDetails() {
      return BookingShiftRoot.$get(this.getUrl() + '/issue_details');
    }

    isLocked() {
      return BookingShiftRoot.$get(this.getUrl() + '/is_locked');
    }
  }

  BookingShiftRoot.initClass();

  return BookingShiftRoot;

};

angular.module('data.booking').factory('BookingShiftRoot', dependencies.concat(BookingShiftRoot));
