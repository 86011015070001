/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope'];

const FormPhotoUploaderController = function($scope) {

  const vm = this;

  _.defaults($scope, {
    width: 125,
    height: 125
  });

  vm.isRound = ($scope.isRound === undefined) || $scope.isRound;
  vm.resizeOptions = $scope.resizeOptions || { ratio: '1:1', centerCrop: true };
  vm.disabled = ($scope.disabled === undefined) ? false : $scope.disabled;

  vm.removePhoto = function() {
    $scope.uploadedPhoto = undefined;
    $scope.photo = undefined;
    $scope.onRemove();
  };

  return vm;

};

angular.module('form').controller('FormPhotoUploaderController', dependencies.concat(FormPhotoUploaderController));
