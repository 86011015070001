/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.role').directive('gccsPopupRoleForm', [
  'popupDirectiveConfig',
  popupDirectiveConfig =>
    popupDirectiveConfig.configure({
      bodyTemplate: require('./template.pug'),
      scope: {
        role: '='
      }
    })
]);
