angular.module('data.client').service('clientSiteStatus', function() {

  const STATUS_ACTIVE = 1;
  const STATUS_INACTIVE = 2;
  const STATUS_DISCONNECTED = 3;

  const names = {
    [STATUS_ACTIVE]: 'Active',
    [STATUS_INACTIVE]: 'Inactive',
    [STATUS_DISCONNECTED]: 'Disconnected'
  };

  const classNames = {
    [STATUS_ACTIVE]: 'mod-blue',
    [STATUS_INACTIVE]: 'mod-gray',
    [STATUS_DISCONNECTED]: 'mod-red'
  };

  return {
    getNames() {
      return names;
    },

    getName(status) {
      return names[status];
    },

    getClassName(status) {
      return classNames[status];
    },

    isActive(status) {
      return status === STATUS_ACTIVE;
    },

    isInactive(status) {
      return status === STATUS_INACTIVE;
    },

    isDisconnected(status) {
      return status === STATUS_DISCONNECTED;
    },

    getActiveStatus() {
      return STATUS_ACTIVE;
    },

    getInactiveStatus() {
      return STATUS_INACTIVE;
    },

    getDisconnectedStatus() {
      return STATUS_DISCONNECTED;
    }
  };
});
