/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'systemPackagePopupService'
];

const PopupSystemPackageErrorMessageController = function(
  $scope, systemPackagePopupService
) {

  const vm = this;

  const initPopupContent = function() {
    vm.popupTitle = systemPackagePopupService.getErrorPopupTitle();
    vm.popupMsg = systemPackagePopupService.getErrorPopupMsg();
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      initPopupContent();
    }
  });

  return vm;

};

angular.module('popup.system-package')
  .controller('PopupSystemPackageErrorMessageController', dependencies.concat(PopupSystemPackageErrorMessageController));