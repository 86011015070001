/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.employee').service('employeeValidation', function() {

  const MIN_BIRTH_YEAR = 1900;

  const isValidYear = year => MIN_BIRTH_YEAR <= year && year <= new Date().getFullYear();

  const getCompositeBirthDateError = () =>
    ({
      error: 'date',
      message: 'Wrong date',
      compositeField: ['birthDay', 'birthMonth', 'birthYear'],
      condition(birthDay, birthMonth, birthYear) {
        if (birthDay && birthYear) {
          return !moment({ year: birthYear, month: birthMonth, day: birthDay }).isValid();
        } else {
          return false;
        }
      }
    });

  const getBirthYearError = () =>
    ({
      error: 'year',
      message: 'Wrong year',
      condition(year) {
        if (year) {
          return !isValidYear(year);
        } else {
          return false;
        }
      }
    });

  const getBirthDateErrors = () =>
    ({
      error: 'date',
      message: 'Wrong date',
      condition(date) {
        if (date) {
          return !(date.isValid() && date.isBefore(moment()) && (date.get('year') >= MIN_BIRTH_YEAR));
        } else {
          return false;
        }
      }
    });

  return {
    getBirthDateErrors() {
      return [getBirthDateErrors()];
    },

    getBirthDayMonthErrors() {
      return [getCompositeBirthDateError()];
    },

    getBirthYearErrors() {
      return [getCompositeBirthDateError(), getBirthYearError()];
    }
  };

});
