/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope'];

const FormFileUploaderController = function($scope) {

  const vm = this;

  vm.name = $scope.name || 'file';

  vm.removeFile = function() {
    $scope.uploadedFile = undefined;
    $scope.fileName = undefined;
    $scope.file = undefined;
  };

  vm.uploadFile = file => $scope.onFileUpload({file});

  vm.getAvailablePatterns = () => $scope.uploaderPattern.replace(/\./g, '').replace(/,/g, ', ');

  vm.downloadFile = function() {
    download($scope.file);
  };

  return vm;
};

angular.module('form').controller('FormFileUploaderController', dependencies.concat(FormFileUploaderController));
