/*
 * @author Oleksandr Papka <papkaos>
 */
const gccsReportsDocumentTypeItem = {
  controller: 'ReportsDocumentTypeItemController',
  controllerAs: 'reportsDocumentTypeItemCtrl',
  template: require('./template.pug'),
  bindings: {
    data: '<',
    type: '<',
    labels: '<',
  }
};

angular.module('public.security-manager.reports.hr')
  .component('gccsReportsDocumentTypeItem', gccsReportsDocumentTypeItem);
