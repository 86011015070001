/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public', [
  'public.security-manager',
  'public.employee',
  'public.client-manager',
  'public.note',
  'popup.confirm',
  'popup.system-package'
]).run().config(['$stateProvider', $stateProvider => {

  return $stateProvider

    .state('public', {
      abstract: true,
      url: '',
      template: require('./template.pug'),
      controller: 'PublicLayoutController',
      controllerAs: 'publicLayoutCtrl',
      resolve: {
        gccsConfig: ['Config', Config => Config.getInstance()],
        currentSession: ['$state', 'Session', 'currentUserService', ($state, Session, currentUserService) =>
          Session.getInstance().then(function (currentSession) {
            if (currentSession.isLoggedIn) {
              currentUserService.setCurrentUserAndSession(currentSession);
              return currentUserService.getCurrentSession();
            } else {
              return $state.go('sign.login');
            }
          }).catch(() => $state.go('sign.login'))
        ],
        initCableConnection: ['currentSession', 'cableService', (currentSession, cableService) => {
          return cableService.connect(currentSession.user.id);
        }]
      }
    });

}]);
