/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['AppModel'];

const SecurityProviderSystemPackage = (AppModel) => {

  class SecurityProviderSystemPackage extends AppModel {
    static initClass() {
      this.configure({
        url: '/security_providers/{{ securityProviderId }}/system_package/{{ singular }}',
        name: 'system_package',
        singular: true
      });
    }

    updatePackage() {
      return this.$put(this.getUrl());
    }

    subscribe(params) {
      return this.$post(this.getUrl() + '/subscribe', params);
    }

  }

  SecurityProviderSystemPackage.initClass();

  return SecurityProviderSystemPackage;

};

angular.module('data.security-provider')
  .factory('SecurityProviderSystemPackage', dependencies.concat(SecurityProviderSystemPackage));
