/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$filter', 'Employee', 'EmployeeTimelineShiftDecorator', 'DayOffDecorator', 'SickDayDecorator',
  'EmployeeTimelineAvailabilityDecorator', 'intersectionMergerService', 'dateService',
  'ShiftEmployee', 'EmployeeDayOff', 'EmployeeSickDay', 'EmployeeAvailability', 'bookingShiftEmployeeAssignStatus',
  'currentUserService'
];

const TimeLineEmployeeLoader = (
  $filter, Employee, EmployeeTimelineShiftDecorator, DayOffDecorator, SickDayDecorator,
  EmployeeTimelineAvailabilityDecorator, intersectionMergerService, dateService,
  ShiftEmployee, EmployeeDayOff, EmployeeSickDay, EmployeeAvailability, bookingShiftEmployeeAssignStatus,
  currentUserService
) => {

  let ISO_FULL_DATE_FORMAT = dateService.getIsoFullDateFormat();

  return class TimeLineEmployeeLoader {

    constructor() {
      this.employees = null;
      this.tempDayOff = undefined;
      this.popupDayOff = undefined;
      this.employeeWithPopupDayOff = undefined;
      this.queryParams = {};
    }

    loadForDates(from, to) {
      return Employee.withShifts(from, to, this.queryParams).then(employees => {
        this._setMaps(employees);
        this._buildDecorators(employees);
        return this.employees = employees;
      });
    }

    setQueryParams(queryParams) {
      return this.queryParams = queryParams;
    }

    shiftLocked(lockedBy, bookingSeriesId) {
      return this._setShiftLocked(lockedBy, bookingSeriesId);
    }

    shiftUnlocked(bookingSeriesId) {
      return this._setShiftLocked(undefined, bookingSeriesId);
    }

    _setShiftLocked(lockedBy, bookingSeriesId) {
      return _.each(this.employees, employee =>
        _.each(employee.timelineItems, function (tItem) {
          if ((tItem.shift != null ? tItem.shift.bookingSeriesId : undefined) === bookingSeriesId) {
            return tItem.shift.lockedBy = lockedBy;
          }
        })
      );
    }

    _setMaps(employees) {
      const shiftEmployeeEmployeeMap = {};
      const dayOffEmployeeMap = {};
      const sickDayEmployeeMap = {};
      const availabilityMap = {};
      employees.forEach(function (employee) {
        employee.shiftEmployees.forEach(shiftEmployee => shiftEmployeeEmployeeMap[shiftEmployee.id] = employee);
        employee.dayOffs.forEach(dayOff => dayOffEmployeeMap[dayOff.id] = employee);
        employee.sickDays.forEach(sickDay => sickDayEmployeeMap[sickDay.id] = employee);
        employee.availabilities.forEach(availability => availabilityMap[availability.id] = employee);
      });
      this.shiftEmployeeEmployeeMap = shiftEmployeeEmployeeMap;
      this.dayOffEmployeeMap = dayOffEmployeeMap;
      this.sickDayEmployeeMap = sickDayEmployeeMap;
      this.availabilityMap = availabilityMap;
    }

    _shiftEmployeeIsInView(id) {
      return !!(this.shiftEmployeeEmployeeMap[id]);
    }

    _dayOffIsInView(id) {
      return !!(this.dayOffEmployeeMap[id]);
    }

    _sickDayIsInView(id) {
      return !!(this.sickDayEmployeeMap[id]);
    }

    _availabilityIsInView(id) {
      return !!(this.availabilityMap[id]);
    }

    _updateShiftEmployeeEmployeeMap(shiftEmployee) {
      const employee = _.find(this.employees, employee => employee.id === shiftEmployee.employeeId);
      this.shiftEmployeeEmployeeMap[shiftEmployee.id] = employee;
    }

    _updateDayOffEmployeeMap(dayOff) {
      const employee = _.find(this.employees, employee => employee.id === dayOff.employeeId);
      this.dayOffEmployeeMap[dayOff.id] = employee;
    }

    _updateSickDayEmployeeMap(sickDay) {
      const employee = _.find(this.employees, employee => employee.id === sickDay.employeeId);
      this.sickDayEmployeeMap[sickDay.id] = employee;
    }

    _updateAvailabilityMap(availability) {
      const employee = _.find(this.employees, employee => employee.id === availability.employeeId);
      this.availabilityMap[availability.id] = employee;
    }

    reloadDayOff(dayOff) {
      const employeeId = dayOff.employee_id;
      const { id } = dayOff;
      return EmployeeDayOff.get({ employeeId, id });
    }

    reloadSickDay(sickDay) {
      const employeeId = sickDay.employee_id;
      const { id } = sickDay;
      return EmployeeSickDay.get({ employeeId, id });
    }

    reloadAvailability(availability) {
      return EmployeeAvailability.get({ employeeId: availability.employee_id, id: availability.id });
    }

    reloadShiftEmployee(shiftEmployee) {
      const shiftEmployeeId = shiftEmployee.shift_employee_id;
      const employeeId = shiftEmployee.employee_id;
      return ShiftEmployee.getForEmployeeTimeline({ employeeId, id: shiftEmployeeId });
    }

    dayOffUpdated(dayOff) {
      let employee = this._getEmployeeByDayOffId(dayOff.id);
      if (!employee) {
        this._updateDayOffEmployeeMap(dayOff);
        employee = this._getEmployeeByDayOffId(dayOff.id);
      }
      this._addOrUpdateInCollection(employee.dayOffs, dayOff, (dayOff, existing) => dayOff.id === existing.id);
      return this.buildDecoratorsForEmployee(employee);
    }

    dayOffDeleted(dayOff) {
      let employee = this._getEmployeeByDayOffId(dayOff.id);
      if (employee) {
        _.remove(employee.dayOffs, d => d.id === dayOff.id);
        this.dayOffEmployeeMap[dayOff.id] = undefined;
        return this.buildDecoratorsForEmployee(employee);
      }
    }

    sickDayUpdated(sickDay) {
      let employee = this._getEmployeeBySickDayId(sickDay.id);
      if (!employee) {
        this._updateSickDayEmployeeMap(sickDay);
        employee = this._getEmployeeBySickDayId(sickDay.id);
      }
      this._addOrUpdateInCollection(employee.sickDays, sickDay, (sickDay, existing) => sickDay.id === existing.id);
      this.buildDecoratorsForEmployee(employee);
    }

    sickDayDeleted(sickDay) {
      let employee = this._getEmployeeBySickDayId(sickDay.id);
      if (employee) {
        _.remove(employee.sickDays, sd => sd.id === sickDay.id);
        this.sickDayEmployeeMap[sickDay.id] = undefined;
        this.buildDecoratorsForEmployee(employee);
      }
    }

    availabilityUpdated(availability) {
      let employee = this._getEmployeeByAvailabilityId(availability.id);
      if (!employee) {
        this._updateAvailabilityMap(availability);
        employee = this._getEmployeeByAvailabilityId(availability.id);
      }
      this._addOrUpdateInCollection(employee.availabilities, availability, (availability, existing) => {
        return availability.id === existing.id;
      });
      this.buildDecoratorsForEmployee(employee);
    }

    availabilityDeleted(availability) {
      let employee = this._getEmployeeByAvailabilityId(availability.id);
      if (employee) {
        _.remove(employee.availabilities, a => a.id === availability.id);
        this.availabilityMap[availability.id] = undefined;
        this.buildDecoratorsForEmployee(employee);
      }
    }

    updateShiftEmployee(shiftEmployee) {
      let employee;
      if (employee = this._getEmployeeByShiftEmployeeId(shiftEmployee.id)) {
      } else {
        this._updateShiftEmployeeEmployeeMap(shiftEmployee);
        employee = this._getEmployeeByShiftEmployeeId(shiftEmployee.id);
      }
      this._addOrUpdateInCollection(employee.shiftEmployees, shiftEmployee, (
        shiftEmployee, existing) => shiftEmployee.id === existing.id);
      return this.buildDecoratorsForEmployee(employee);
    }

    deleteShiftEmployee(shiftEmployee) {
      let employee;
      const shiftEmployeeId = shiftEmployee.shift_employee_id;
      if (employee = this._getEmployeeByShiftEmployeeId(shiftEmployeeId)) {
        _.remove(employee.shiftEmployees, d => d.id === shiftEmployeeId);
        this.shiftEmployeeEmployeeMap[shiftEmployeeId] = undefined;
        return this.buildDecoratorsForEmployee(employee);
      }
    }

    _addOrUpdateInCollection(collection, item, compareCb) {
      const index = _.findIndex(collection, cItem => compareCb(item, cItem));
      if (index === -1) {
        return collection.push(item);
      } else {
        return collection[index] = item;
      }
    }

    _getEmployeeByShiftEmployeeId(shiftEmployeeId) {
      return this.shiftEmployeeEmployeeMap[shiftEmployeeId];
    }

    _getEmployeeByDayOffId(dayOffId) {
      return this.dayOffEmployeeMap[dayOffId];
    }

    _getEmployeeBySickDayId(sickDayId) {
      return this.sickDayEmployeeMap[sickDayId];
    }

    _getEmployeeByAvailabilityId(availabilityId) {
      return this.availabilityMap[availabilityId];
    }

    editDayOff(employee, dayOff) {
      this.employeeWithPopupDayOff = employee;
      return this.popupDayOff = dayOff;
    }

    _updateEmployeeDayOff(employee, newDayOff) {
      const decoratedDayOff = this._decorateDayOff(newDayOff);
      return this._addOrUpdateInCollection(employee.dayOffs, decoratedDayOff, (dayOff, existing) => {
        return dayOff.id === existing.id;
      });
    }

    _removeEmployeeDayOff(employee, deletedDayOff) {
      return _.remove(employee.dayOffs, this._compareById(deletedDayOff));
    }

    _compareById(first) {
      return second => first.id === second.id;
    }

    updateDayOff(event) {
      this._removeTempDayOff();
      this._clearPopupDayOff();
      this._removeEmployeeDayOff(this.employeeWithPopupDayOff, event.dayOff);
      this._refreshDecorators();
    }

    deleteDayOff(event) {
      this._clearPopupDayOff();
      this._removeEmployeeDayOff(this.employeeWithPopupDayOff, event.dayOff);
      this._refreshDecorators();
    }

    addTempDayOff(startTimeMoment, endTimeMoment, employee) {
      this.employeeWithPopupDayOff = employee;
      this.tempDayOff = new DayOffDecorator({
        startTimeMoment,
        endTimeMoment,
        isTemporary: true,
        employeeId: employee.id
      });
      this.buildDecoratorsForEmployee(employee, this.tempDayOff);
      this.employeeWithPopupDayOff = employee;
      return this.popupDayOff = this.tempDayOff;
    }

    closeDayOffPopupAndRefresh() {
      this._removeTempDayOff();
      this._clearPopupDayOff();
      this._refreshDecorators();
    }

    _removeTempDayOff() {
      if (!this.tempDayOff || !this.employeeWithPopupDayOff) {
        return;
      }
      _.pull(this.employeeWithPopupDayOff.timelineItems, this.tempDayOff);
      return this.tempDayOff = undefined;
    }

    _clearPopupDayOff() {
      return this.popupDayOff = undefined;
    }

    _refreshDecorators() {
      this.buildDecoratorsForEmployee(this.employeeWithPopupDayOff);
      return this.employeeWithPopupDayOff = undefined;
    }

    _decorateShift(shift) {
      return new EmployeeTimelineShiftDecorator(shift);
    }

    _decorateDayOff(dayOff) {
      return new DayOffDecorator(dayOff);
    }

    _decorateSickDay(sickDay) {
      return new SickDayDecorator(sickDay);
    }

    _decorateAvailability(availability) {
      return new EmployeeTimelineAvailabilityDecorator(availability);
    }

    buildDecoratorsForEmployee(employee, tempDayOff) {
      employee.fullNameLower = employee.getFullName().toLowerCase();
      employee.searchString = `${employee.code} ${employee.user.firstName} ${employee.user.lastName} ${employee.user.email}`;
      const dShifts = _.map(employee.shiftEmployees, shiftEmployee => {
        const dShift = this._decorateShift(shiftEmployee.shift);
        dShift.notCoveredResourcesCount = this._getNotCoveredResourcesCount(shiftEmployee);
        dShift.shiftEmployeeId = shiftEmployee.id;
        dShift.bookingId = shiftEmployee.shift.bookingId;
        dShift.timelineStartTime = moment(shiftEmployee.startTime, ISO_FULL_DATE_FORMAT);
        dShift.timelineEndTime = moment(shiftEmployee.endTime, ISO_FULL_DATE_FORMAT);
        dShift.startTimeMoment = dShift.timelineStartTime.clone();
        dShift.endTimeMoment =  dShift.timelineEndTime.clone();
        dShift.assignStatus = shiftEmployee.assignStatus;
        return dShift;
      });
      let dDayOffs = _.map(employee.dayOffs, this._decorateDayOff);
      if (tempDayOff) {
        dDayOffs = dDayOffs.concat(tempDayOff);
      }
      const dSickDays = _.map(employee.sickDays, this._decorateSickDay);
      const dAvailabilities = this._getDAvailabilitiesSplitByDay(employee.availabilities);
      const timelineItems = dShifts.concat(dDayOffs, dSickDays, dAvailabilities);
      // todo refactor and calculate only one timeline items depending on view
      employee.rowsCount = 0;
      employee.monthRowsCount = 0;
      employee.timelineItems = [];
      employee.monthTimelineItems = [];
      for (let timelineItem of timelineItems) {
        timelineItem.intersections = [];
        timelineItem.row = 1;
        this._findIntersections(employee, timelineItem);
        if (!timelineItem.timelineStartTime.isSame(timelineItem.timelineEndTime)) {
          employee.rowsCount = Math.max(employee.rowsCount, timelineItem.row);
          employee.timelineItems.push(timelineItem);
        }
      }
      for (let timelineItem of timelineItems) {
        const monthTimelineItem = _.clone(timelineItem);
        const daysDiff = monthTimelineItem.timelineEndTime.diff(monthTimelineItem.timelineStartTime, 'days') + 1;
        monthTimelineItem.timelineStartTime = monthTimelineItem.timelineStartTime.clone().startOf('day');
        monthTimelineItem.timelineEndTime = monthTimelineItem.timelineStartTime.clone().add(daysDiff, 'day');
        monthTimelineItem.row = 1;
        monthTimelineItem.subItems = [monthTimelineItem];
        this._findMonthIntersections(employee, monthTimelineItem);
        employee.monthRowsCount = Math.max(employee.monthRowsCount, monthTimelineItem.row);
        if (monthTimelineItem.subItems.length > 0) {
          employee.monthTimelineItems.push(monthTimelineItem);
        }
      }
      return intersectionMergerService.mergeIntersections(employee.timelineItems);
    }

    _buildDecorators(employees) {
      return Array.from(employees).map((employee) => {
        return this.buildDecoratorsForEmployee(employee);
      });
    }

    _findIntersections(employee, newTimelineItem) {
      const newStartTime = newTimelineItem.timelineStartTime;
      const newEndTime = newTimelineItem.timelineEndTime;
      let splitTimelineItem;
      for (let oldTimelineItem of employee.timelineItems) {
        if (
          oldTimelineItem instanceof EmployeeTimelineAvailabilityDecorator &&
          newTimelineItem instanceof EmployeeTimelineAvailabilityDecorator
        ) {
          continue;
        }
        const oldStartTime = oldTimelineItem.timelineStartTime;
        const oldEndTime = oldTimelineItem.timelineEndTime;
        const isCorrectStartTime = oldStartTime.diff(newStartTime) >= 0 && oldStartTime.diff(newEndTime) >= 0;
        const isCorrectEndTime = newStartTime.diff(oldEndTime) >= 0 && newEndTime.diff(oldEndTime) >= 0;
        if (!isCorrectStartTime && !isCorrectEndTime) {
          const intersectionFrom = moment.max(newStartTime, oldStartTime);
          const intersectionTo = moment.min(newEndTime, oldEndTime);
          if (newTimelineItem instanceof EmployeeTimelineAvailabilityDecorator) {
            if (oldEndTime.diff(newEndTime) >= 0) {
              newTimelineItem.timelineEndTime = intersectionFrom;
            } else {
              splitTimelineItem = _.clone(newTimelineItem);
              splitTimelineItem.timelineStartTime = intersectionTo;
              newTimelineItem.timelineEndTime = intersectionFrom;
              this._findIntersections(employee, splitTimelineItem);
              employee.timelineItems.push(splitTimelineItem);
            }
          } else {
            newTimelineItem.row = Math.max(newTimelineItem.row, oldTimelineItem.row + 1);
            this._addIntersections([oldTimelineItem, newTimelineItem], intersectionFrom, intersectionTo);
          }
        }
      }
    }

    _findMonthIntersections(employee, newMonthTimelineItem) {
      const newStartTime = newMonthTimelineItem.timelineStartTime;
      const newEndTime = newMonthTimelineItem.timelineEndTime;
      for (let oldMonthTimelineItem of employee.monthTimelineItems) {
        const oldStartTime = oldMonthTimelineItem.timelineStartTime;
        const oldEndTime = oldMonthTimelineItem.timelineEndTime;
        const isCorrectStartTime = oldStartTime.diff(newStartTime) >= 0 && oldStartTime.diff(newEndTime) >= 0;
        const isCorrectEndTime = newStartTime.diff(oldEndTime) >= 0 && newEndTime.diff(oldEndTime) >= 0;
        if (!isCorrectStartTime && !isCorrectEndTime) {
          if (
            oldMonthTimelineItem instanceof EmployeeTimelineShiftDecorator &&
            newMonthTimelineItem instanceof EmployeeTimelineShiftDecorator
          ) {
            oldMonthTimelineItem.subItems = oldMonthTimelineItem.subItems.concat(newMonthTimelineItem.subItems);
            newMonthTimelineItem.subItems = [];
          } else {
            newMonthTimelineItem.row = Math.max(newMonthTimelineItem.row, oldMonthTimelineItem.row + 1);
          }
        }
      }
    }

    _getDAvailabilitiesSplitByDay(availabilities) {
      const dAvailabilities = _.map(availabilities, this._decorateAvailability);
      let splitDAvailabilities = [];
      for (let dAvailability of dAvailabilities) {
        let startTime = dAvailability.timelineStartTime.clone();
        while (startTime.isBefore(dAvailability.timelineEndTime)) {
          let splitDAvailability = _.clone(dAvailability);
          splitDAvailability.timelineStartTime = startTime.clone();
          splitDAvailability.timelineEndTime = moment.min(startTime.clone().endOf('day'), dAvailability.timelineEndTime);
          splitDAvailabilities.push(splitDAvailability);
          startTime = startTime.add(1, 'day').startOf('day');
        }
      }
      return splitDAvailabilities;
    }

    _addIntersections(timelineItems, intersectsFrom, intersectsTo) {
      _.each(timelineItems, timelineItem => {
        timelineItem.intersections.push({
          from: intersectsFrom,
          to: intersectsTo
        });
      });
    }

    _getNotCoveredResourcesCount(shiftEmployee) {
      return Math.max(0, shiftEmployee.shift.requiredEmployeesCount - this._getCoveredResourcesCount(shiftEmployee))
    }

    _getCoveredResourcesCount(shiftEmployee) {
      return this._getFullyApprovedEmployees(shiftEmployee).length;
    }

    _getFullyApprovedEmployees(shiftEmployee) {
      const fullyApprovedEmployees = [];
      (() => {
        const result = [];
        for (let groupedEmployeeShift of Array.from(this._getGroupedShiftEmployees(shiftEmployee))) {
          const employeeShift = groupedEmployeeShift.main;
          // todo sometimes employeeShift is undefined - check why
          if (!bookingShiftEmployeeAssignStatus.countsAsAssigned(employeeShift != null ? employeeShift.assignStatus : undefined)) { continue; }
          if (currentUserService.isClientManagerLogged()) {
            fullyApprovedEmployees.push(employeeShift);
          } else {
            result.push(this._buildApprovedEmployeesForSplit(employeeShift, groupedEmployeeShift.split, fullyApprovedEmployees, shiftEmployee));
          }
        }
        return result;
      })();
      return fullyApprovedEmployees;
    }

    _buildApprovedEmployeesForSplit(mainShiftEmployee, splitShifts, fullyApprovedEmployees, shiftEmployee) {
      this._memoizeTime(mainShiftEmployee);
      let totalTime = mainShiftEmployee.endTimeMoment.diff(mainShiftEmployee.startTimeMoment);
      if (splitShifts.length === 0) {
        if (totalTime >= this.getEndTimeMoment(shiftEmployee).diff(this.getStartTimeMoment(shiftEmployee))) {
          fullyApprovedEmployees.push(mainShiftEmployee);
        }
      } else {
        for (let splitShift of Array.from(splitShifts)) {
          if (!bookingShiftEmployeeAssignStatus.countsAsAssigned(splitShift.assignStatus)) { continue; }
          this._memoizeTime(splitShift);
          totalTime += splitShift.endTimeMoment.diff(splitShift.startTimeMoment);
        }
        fullyApprovedEmployees.push(mainShiftEmployee);
        if (totalTime >= this.getEndTimeMoment(shiftEmployee).diff(this.getStartTimeMoment(shiftEmployee))) {
          return (() => {
            const result = [];
            for (let splitShift of Array.from(splitShifts)) {
              result.push(fullyApprovedEmployees.push(splitShift));
            }
            return result;
          })();
        }
      }
    }

    _getGroupedShiftEmployees(shiftEmployee) {
      const groupedShiftEmployees = {};
      for (let employeeShift of Array.from(shiftEmployee.shift.employeesShifts)) {
        if (employeeShift.splitForId) {
          if (!groupedShiftEmployees[employeeShift.splitForId]) {
            groupedShiftEmployees[employeeShift.splitForId] = { split: [] };
          }
          if (!bookingShiftEmployeeAssignStatus.countsAsUnavailable(employeeShift)) {
            groupedShiftEmployees[employeeShift.splitForId].split.push(employeeShift);
          }
        } else {
          if (!groupedShiftEmployees[employeeShift.id]) {
            groupedShiftEmployees[employeeShift.id] = { split: [] };
          }
          groupedShiftEmployees[employeeShift.id].main = employeeShift;
        }
      }
      return _.values(groupedShiftEmployees);
    }

    _memoizeTime(object) {
      if (!object.startTimeMoment) {
        object.startTimeMoment = moment(object.startTime, dateService.getIsoFullDateFormat());
      }
      return object.endTimeMoment || (object.endTimeMoment = moment(object.endTime, dateService.getIsoFullDateFormat()));
    }

    getStartTimeMoment(shiftEmployee) {
      return shiftEmployee.startTimeMoment || (shiftEmployee.startTimeMoment = moment(shiftEmployee.shift.startTime, dateService.getIsoFullDateFormat()));
    }

    getEndTimeMoment(shiftEmployee) {
      return shiftEmployee.endTimeMoment || (shiftEmployee.endTimeMoment = moment(shiftEmployee.shift.endTime, dateService.getIsoFullDateFormat()));
    }

  }

};

angular.module('public.security-manager.schedule-manager.timeline')
  .factory('TimeLineEmployeeLoader', dependencies.concat(TimeLineEmployeeLoader));
