const dependencies = [
  '$scope', '$state', 'dateService', 'Client', 'BookingShift', 'clientStatus', 'notificationService', 'BookingShiftEmployee',
  'bookingShiftEmployeeAssignStatus', 'uniqueValuesService', 'resourceIcon', 'bookingRepeatable',
  'bookingShiftEditMode', 'ShiftEditModeService'
];

const LiveFeedItemDetails = function(
  $scope, $state, dateService, Client, BookingShift, clientStatus, notificationService, BookingShiftEmployee,
  bookingShiftEmployeeAssignStatus, uniqueValuesService, resourceIcon, bookingRepeatable,
  bookingShiftEditMode, ShiftEditModeService
) {

  const vm = this;

  vm.editModeService = ShiftEditModeService();
  vm.isShownEditShiftPopup = false;

  vm.$onInit = function() {
    initShiftEmployees();
  };

  vm.openEditShiftPopup = function({ isInviteEmployees = false, shouldGoToInvited = false } = {}) {
    vm.editShiftPopupPosition = { left: 0, right: 0 };
    vm.popupShift = angular.copy(vm.dShiftIssue.shift);
    vm.isShownEmployeeInvite = isInviteEmployees;
    vm.clientPopup = { id: vm.dShiftIssue.shift.clientId };
    vm.dateRange = [
      moment(vm.dShiftIssue.shift.booking.from, dateService.getIsoDateFormat()),
      moment(vm.dShiftIssue.shift.booking.to, dateService.getIsoDateFormat())
    ];
    vm.isShiftIssueInviteEmployee = isInviteEmployees;
    vm.shouldGoToInvited = shouldGoToInvited;
    vm.isShownEditShiftPopup = true;
  };

  vm.closeEditShiftPopup = function() {
    vm.isShownEditShiftPopup = false;
    onUpdateShiftIssueDetails();
  };

  vm.saveShift = function (shift, afterSaveCb) {
    getShiftCount(shift).then(function (shiftCount) {
      updateShift(shiftCount.count, shift, function () {
        new BookingShift(shift).save().then(function () {
          if (afterSaveCb) {
            afterSaveCb();
          }
          notificationService.notifySuccess('Changes are saved');
        });
      });
    });
  };

  const onUpdateShiftIssueDetails = function() {
    vm.updateShiftIssueDetails({ dShiftIssue: vm.dShiftIssue, onUpdate: initShiftEmployees });
  };

  const getShiftCount = function (shift) {
    return BookingShift.getCount({ bookingId: shift.booking.id }, { bookingSeriesId: shift.bookingSeriesId });
  };

  const updateShift = function(countInSeries, shift, cb) {
    if (countInSeries > 1) {
      vm.editModeService.show(shift, cb);
    } else {
      if (bookingRepeatable.isNone(shift.repeatable)) {
        shift.editMode = bookingShiftEditMode.getOnlyCurrentMode();
      } else {
        shift.editMode = bookingShiftEditMode.getFollowingMode();
      }
      cb();
    }
  };

  const initShiftEmployees = function() {
    BookingShiftEmployee.query({}, {
      bookingId: vm.dShiftIssue.shift.booking.id,
      shiftId: vm.dShiftIssue.shift.id
    }).then(function (shiftEmployees) {
      vm.shiftEmployees = shiftEmployees;
      initInvitedEmployees();
      initUniqueResourcesIcons();
      initDeclinedAndUnavailable();
    });
  };

  const initInvitedEmployees = function() {
    vm.invitedEmployees = _.filter(vm.shiftEmployees, shiftEmployee => {
      return bookingShiftEmployeeAssignStatus.countsAsInvited(shiftEmployee.assignStatus);
    });
  };

  const initUniqueResourcesIcons = function() {
    vm.uniqueApprovedEmployees = uniqueValuesService.getUniqueValuesById(vm.dShiftIssue.shift.approvedShiftEmployees);
    const resourcesIconsArray = buildResourcesArray();
    vm.uniqueResourcesIconsArray = uniqueValuesService.getUniqueValuesByField(resourcesIconsArray, 'code');
  };

  const initDeclinedAndUnavailable = function() {
    vm.declinedAndUnavailable = _.filter(vm.shiftEmployees, shiftEmployee => {
      return bookingShiftEmployeeAssignStatus.countsAsUnavailable(shiftEmployee.assignStatus);
    });
  };

  const buildResourcesArray = function() {
    let resourcesArray = getEmployeeData();
    const icon = vm.dShiftIssue.shift.resource.icon;
    let resourceParam = {
      hoverTitle: null,
      class: null,
      url: null
    };
    if (icon) {
      if (resourceIcon.isUploadedType(icon.iconType)) {
        resourceParam.url = icon.icon.url;
      } else {
        resourceParam.class = resourceIcon.getIconClass(icon.iconType);
      }
    }
    for (let i = 0; i < vm.dShiftIssue.shift.requiredEmployeesCount - vm.dShiftIssue.shift.approvedShiftEmployees.length; i++) {
      resourcesArray.push(_.clone(resourceParam));
    }
    return resourcesArray;
  };

  const getEmployeeData = function() {
    return _.map(vm.dShiftIssue.shift.approvedShiftEmployees, shiftEmployee => {
      const employee = shiftEmployee.employee;
      return {
        class: null,
        url: (employee.thumbAvatar != null ? employee.thumbAvatar.url : undefined),
        hoverTitle: employee.fullName,
        code: employee.code,
        initials: employee.initials
      };
    });
  };

  vm.goToTimelineShift = function() {
    const startDay = vm.dShiftIssue.shift.startTimeMoment.format(dateService.getDateFormat());
    Client.byShift(vm.dShiftIssue.shiftId).then(function (client) {
      if (clientStatus.isActive(client.status)) {
        $state.go('public.security-manager.schedule-manager.timeline.booking', {
          goToDate: startDay,
          goToShiftId: vm.dShiftIssue.shiftId
        });
      } else {
        notificationService.notifyError('Client is not active');
      }
    });
  };

  const onIssueResolved = function(e, data) {
    if (data.shift_id === vm.dShiftIssue.shiftId) {
      onUpdateShiftIssueDetails();
    }
  };

  const shiftResolveListener = $scope.$on('issueResolved', onIssueResolved);

  // Just in case unbind listener
  $scope.$on('$destroy', () => {
    shiftResolveListener();
  });

  return vm;

};

angular.module('public.security-manager.schedule-manager.timeline')
  .controller('LiveFeedItemDetailsController', dependencies.concat(LiveFeedItemDetails));
