/*
 * @author Igor Serpak <robobee>
 */
const dependencies = [
  '$scope', 'SecurityProviderSetting', 'SecurityProviderDocument', 'currentUserService'
];

const EmployeeProfileCompanyDetailsController = function(
  $scope, SecurityProviderSetting, SecurityProviderDocument, currentUserService
) {

  const vm = this;

  vm.documents = [];

  const customErrorTexts = {
    email: { pattern: 'Enter a valid email' },
    registrationNumber: { pattern: 'Enter a valid number' },
    vatNumber: { pattern: 'Enter a valid number' },
    bankAccountNumber: { pattern: 'Enter a valid number' },
    ibanNumber: { pattern: 'Enter a valid number' },
    swiftNumber: { pattern: 'Enter a valid number' },
    sortCode: { mask: 'Enter a valid code' },
    phoneNumber: { mask: 'Enter a valid phone number' },
  };

  vm.getFileIcon = document => {
    return `#file-type-${document.fileExtension}`;
  };

  vm.$onInit = function() {
    loadEmployee();
    loadDocuments();
  };

  vm.getErrors = fieldName => customErrorTexts[fieldName];

  const loadEmployee = function() {
    $scope.employeeProfileLayoutCtrl.loadEmployee().then(function(loadedEmployee) {
      vm.employee = loadedEmployee;
      loadSettings();
    });
  };

  const loadSettings = () => {
    SecurityProviderSetting.get({ securityProviderId: vm.employee.securityProvider.id }).then(settings => {
      vm.settings = settings;
    }).catch(error => {
      return Rollbar.log(`Settings get on ${window.location.href}`, error);
    });
  };

  const loadDocuments = () => {
    return SecurityProviderDocument.query({}, {
      securityProviderId: currentUserService.getCurrentProfile().securityProvider.id
    }).then(documents => vm.documents = documents);
  };

  return vm;

};

angular
  .module('public.employee.profile')
  .controller('EmployeeProfileCompanyDetailsController', dependencies.concat(EmployeeProfileCompanyDetailsController));
