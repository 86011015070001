import './style.css';

const dependencies = [
  '$scope', 'EntityManager', 'EmployeeApprovedClient', 'EmployeeResource', 'confirmService'
];

const EmployeeShowApprovedClientsController = function(
  $scope, EntityManager, EmployeeApprovedClient, EmployeeResource, confirmService
) {

  const vm = this;

  vm.selectedClientId = null;

  let clientsLoaded = false;

  const entityManager = new EntityManager(EmployeeApprovedClient, {
    params: {
      employeeId: $scope.employeeShowLayoutCtrl.employeeId
    }
  });

  vm.$onInit = function() {
    initClients();
    initEmployee();
  };

  vm.onResourceApprove = function(approvedResource) {
    _.find(vm.clients, ['id', vm.selectedClientId]).resourceIds.push(approvedResource.resourceId);
  };

  vm.onResourceReject = function(rejectedResource) {
    _.remove(_.find(vm.clients, ['id', vm.selectedClientId]).resourceIds, (resourceId) => {
      return resourceId === rejectedResource.resourceId;
    });
  };

  vm.showClientApprovePopup = function(client) {
    if ($scope.employeeShowLayoutCtrl.editable) {
      vm.isShownClientApprovePopup = true;
      vm.popupClient = client;
    }
  };

  vm.closeClientApprovePopup = function() {
    vm.isShownClientApprovePopup = false;
    entityManager.loadAll();
  };

  vm.getApprovedClients = function () {
    return entityManager.entities;
  };

  vm.toggleNameOrder = function () {
    vm.ascendingNameOrder = !vm.ascendingNameOrder;
  };

  vm.getResourcesNameOf = function (client) {
    return _.map(client.resources, 'name').join(', ');
  };

  vm.isShownEmptyClients = function () {
    return clientsLoaded && (vm.getApprovedClients().length === 0);
  };

  vm.removeClient = function (client) {
    confirmService.confirm('Confirm remove', 'This action will remove all approvals', 'Yes', function () {
      entityManager._removeEntity(client);
      _.each(client.resources, function (resource) {
        const removingEmployeeResource = new EmployeeResource({
          resourceId: resource.id,
          employeeId: vm.employee.id
        });
        removingEmployeeResource.remove().then(() => {
          _.remove(
            vm.employee.employeeResources,
            employeeResource => employeeResource.resourceId === removingEmployeeResource.resourceId
          );
        });
      });
    });
  };

  const initClients = function() {
    EmployeeApprovedClient.query({}, { employeeId: $scope.employeeShowLayoutCtrl.employeeId }).then(clients => {
      vm.clients = clients;
    });
  };

  const initEmployee = function() {
    $scope.employeeShowLayoutCtrl.loadEmployee().then(employee => vm.employee = employee);
  };

  return vm;

};

angular.module('public.security-manager.employee')
  .controller('EmployeeShowApprovedClientsController', dependencies.concat(EmployeeShowApprovedClientsController));
