const dependencies = ['currentUserService', 'redirectService'];

const packageTypeService = function(currentUserService, redirectService) {

  const FREE = 1;
  const BASIC = 2;
  const PRO = 3;
  const UNLIMITED = 6;

  const LIMIT_FREE_PACKAGE_EMPLOYEES = 5;
  const LIMIT_FREE_PACKAGE_CLIENTS = 1;
  const LIMIT_FREE_PACKAGE_LOCATIONS = 1;

  const PRICE_PER_EMPLOYEE_BASIC = 1.5;
  const PRICE_PER_EMPLOYEE_PRO = 2.5;

  const PACKAGE_FREE = [FREE];
  const PACKAGE_BASIC = [BASIC];
  const PACKAGE_PRO = [PRO, UNLIMITED];
  const PACKAGE_UNLIMITED = [UNLIMITED];

  const getCurrentPackageType = function() {
    if (currentUserService.getCurrentProfile()) {
      return currentUserService.getCurrentProfile().securityProvider.systemPackage.packageType;
    }
  };

  const hasPackage = function(packages, packageType) {
    return packages.includes(packageType || getCurrentPackageType());
  };

  return {
    // todo split to hasPackage and isPackage
    hasFreePackage: function(packageType) {
      return hasPackage(PACKAGE_FREE, packageType);
    },

    hasBasicPackage: function(packageType) {
      return hasPackage(PACKAGE_BASIC, packageType);
    },

    hasProPackage: function(packageType) {
      return hasPackage(PACKAGE_PRO, packageType);
    },

    hasUnlimitedPackage: function(packageType) {
      return hasPackage(PACKAGE_UNLIMITED, packageType);
    },

    checkProPackage() {
      if (!this.hasProPackage()) {
        redirectService.redirectToMain();
      }
    },

    getPricePerEmployee(packageType) {
      if (this.hasProPackage(packageType)) {
        return this.getProPricePerEmployee();
      } else if (this.hasBasicPackage(packageType)) {
        return this.getBasicPricePerEmployee();
      } else {
        return 0;
      }
    },

    getBasicPricePerEmployee() {
      return PRICE_PER_EMPLOYEE_BASIC;
    },

    getProPricePerEmployee() {
      return PRICE_PER_EMPLOYEE_PRO;
    },

    getFreePackage() {
      return FREE;
    },

    getBasicPackage() {
      return BASIC;
    },

    getProPackage() {
      return PRO;
    },

    getUnlimitedPackage() {
      return UNLIMITED;
    },

    getLicensesCount() {
      if (currentUserService.getCurrentProfile()) {
        if (this.hasFreePackage()) {
          return LIMIT_FREE_PACKAGE_EMPLOYEES;
        } else {
          return currentUserService.getCurrentProfile().securityProvider.systemPackage.licensesCount;
        }
      }
    },

    getEmployeesLimitForFreePackage() {
      return LIMIT_FREE_PACKAGE_EMPLOYEES;
    },

    getClientsLimitForFreePackage() {
      return LIMIT_FREE_PACKAGE_CLIENTS;
    },

    getLocationsLimitForFreePackage() {
      return LIMIT_FREE_PACKAGE_LOCATIONS;
    },

    getCurrentPackageType
  }

};

angular.module('data.security-provider').service('packageTypeService', dependencies.concat(packageTypeService));
