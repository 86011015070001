/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('form').directive('gccsFormPhotoUploader', () => {
  return {
    restrict: 'A',
    template: require('./template.pug'),
    controller: 'FormPhotoUploaderController',
    controllerAs: 'formPhotoUploaderCtrl',
    scope: {
      label: '@?',
      width: '@',
      height: '@',
      isRound: '=',
      photo: '=',
      uploadedPhoto: '=',
      onUpload: '&',
      onRemove: '&',
      resizeOptions: '<',
      disabled: '<'
    }
  };
});
