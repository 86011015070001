/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', '$timeout', 'EmployeeTimeLineInteractor', 'EmployeeTimeLineLoader', 'SickDayDecorator',
  'EmployeeTimelineShiftDecorator', 'dateService', 'cableService', 'headerNotificationsObservable',
  'elementService', 'EmployeeTimelineAvailabilityDecorator', 'employeeSiblingProfileService'
];

const EmployeeTimelineController = function(
  $scope, $timeout, EmployeeTimeLineInteractor, EmployeeTimeLineLoader, SickDayDecorator,
  EmployeeTimelineShiftDecorator, dateService, cableService, headerNotificationsObservable,
  elementService, EmployeeTimelineAvailabilityDecorator, employeeSiblingProfileService
) {

  const vm = this;

  const TOP_OFFSET = 10;
  const ROW_HEIGHT = 55;

  const SHIFT_POPUP_WIDTH = 315;

  vm.loader = new EmployeeTimeLineLoader();

  vm.timelineItemPopupPosition = {};
  vm.isShownTimelineItemPopup = false;
  vm.isShownTimelineBlowOutPopup = false;
  vm.isShownConfirmRemovePopup = false;

  const currentDate = dateService.today();

  vm.$onInit = function() {
    initCable();
    $timeout(() => vm.interactor = new EmployeeTimeLineInteractor(vm.loader, currentDate));
  };

  vm.getTimelineItemStyle = timelineItem => {
    return _.merge(vm.interactor.getTimelineItemDimensions(timelineItem), {
      top: ((timelineItem.row - 1) * ROW_HEIGHT) + TOP_OFFSET + 'px'
    })
  };

  vm.getIntersectionStyle = (timelineItem, intersection) => {
    return {
      left: vm.interactor.getWidthOfDiff(intersection.from, timelineItem.timelineStartTime) + 'px',
      width: vm.interactor.getWidthOfDiff(intersection.to, intersection.from) + 'px'
    };
  };

  vm.showTimelineItemPopup = ($event, timelineItem) => {
    return showTimelineItemPopup(timelineItem, $event.target.closest('[ng-click]'));
  };

  vm.closeTimelineItemPopup = () => vm.isShownTimelineItemPopup = false;

  vm.closeTimelineBlowOutPopup = () => vm.isShownTimelineBlowOutPopup = false;

  vm.closeTimelineItemsPopups = function() {
    vm.closeTimelineItemPopup();
    vm.closeTimelineBlowOutPopup();
  };

  vm.getTimelineItemElementId = function(timelineItem) {
    if (timelineItem.isDayOff) {
      return `dayOff_${timelineItem.id}`;
    } else if (vm.isSickDay(timelineItem)) {
      return `sickDay_${timelineItem.id}`;
    } else if (vm.isAvailability(timelineItem)) {
      return `availability_${timelineItem.id}`;
    } else {
      return `shiftEmployee_${timelineItem.shiftEmployee.id}`;
    }
  };

  vm.getTimelineItemClass = function (timelineItem) {
    if (timelineItem.isDayOff) {
      return 'mod-day-off';
    } else if (vm.isSickDay(timelineItem)) {
      return 'mod-sick-day';
    } else if (vm.isAvailability(timelineItem)) {
      return 'mod-available-work';
    } else {
      return 'mod-shift';
    }
  };

  vm.isSevenDays = function() {
    if (vm.interactor) {
      return vm.interactor.viewSelector.isSevenDays;
    } else {
      return true;
    }
  };

  vm.isSickDay = function(timelineItem) {
    return timelineItem instanceof SickDayDecorator;
  };

  vm.isShift = function(timelineItem) {
    return timelineItem instanceof EmployeeTimelineShiftDecorator;
  };

  vm.isAvailability = function (timelineItem) {
    return timelineItem instanceof EmployeeTimelineAvailabilityDecorator;
  };

  const updateShiftPosition = function(shiftElement) {
    let left = elementService.getOffsetLeft(shiftElement);
    const top = elementService.getOffsetTop(shiftElement) + shiftElement.offsetHeight;

    if (left > (window.innerWidth - SHIFT_POPUP_WIDTH)) { left = window.innerWidth - SHIFT_POPUP_WIDTH; }
    if (left < 0) { left = 0; }
    vm.timelineItemPopupPosition = { left, top };
  };

  const initCable = function() {
    employeeSiblingProfileService.getSiblingProfiles().then(function(employees) {
      employees.forEach(function(employee) {
        const channel = cableService.getEmployeeTimelineChannel(employee.id);
        channel.addCallbackWithCleanup($scope, 'shift-employee', timelineItemUpdate);
        channel.addCallbackWithCleanup($scope, 'day-off', timelineItemUpdate);
        channel.addCallbackWithCleanup($scope, 'sick_day', timelineItemUpdate);
        channel.addCallbackWithCleanup($scope, 'sick_day_destroyed', timelineItemUpdate);
        channel.addCallbackWithCleanup($scope, 'availability', timelineItemUpdate);
        channel.addCallbackWithCleanup($scope, 'availability_destroyed', timelineItemUpdate);
      });
    });
  };

  const timelineItemUpdate = function(timelineItem) {
    if (isInCurrentView(timelineItem)) { return reloadData(); }
  };

  const reloadData = () => vm.interactor.loadData();

  const scrollToNotification = function(notification) {
    if (notification.transitionOptions) {
      const object = notification.transitionOptions;
      return vm.interactor.scrollTo(moment(object.startTime, dateService.getDateFormat())).then(function() {
        if (object.type !== 'shift') { return; }
        const timelineItem = _.find(vm.interactor.timeLineLoader.timelineItems, timelineItem => {
          return timelineItem.shiftId === object.id;
        });
        if (!timelineItem) { return; }
        return $timeout(function() {
          const $timelineItem = document.getElementById(vm.getTimelineItemElementId(timelineItem));
          return showTimelineItemPopup(timelineItem, $timelineItem);
        });
      });
    } else {
      return vm.interactor.scrollTo(moment(notification.startDay, dateService.getDateFormat()));
    }
  };

  const isInCurrentView = timeLineItem => {
    return !(
      moment(timeLineItem.end_time, dateService.getIsoFullDateFormat()).isBefore(vm.interactor.getFrom()) ||
      moment(timeLineItem.start_time, dateService.getIsoFullDateFormat()).isAfter(vm.interactor.getTo())
    );
  };

  const setHourWidthOnResize = () => {
    return $timeout(() => vm.interactor.updateHourWidth());
  };

  const showTimelineItemPopup = function(timelineItem, $element) {
    vm.popupTimelineItem = timelineItem;
    updateShiftPosition($element);
    if (!timelineItem.isBlowOut) {
      vm.isShownTimelineItemPopup = true;
    } else {
      vm.isShownTimelineBlowOutPopup = true;
    }
  };

  window.addEventListener('resize', setHourWidthOnResize);

  $scope.$on('$destroy', () => window.removeEventListener('resize', setHourWidthOnResize));

  headerNotificationsObservable.subscribe('shift-invite-accept', reloadData);
  headerNotificationsObservable.subscribe('shift-invite-decline', reloadData);
  headerNotificationsObservable.subscribe('go-to-notification', scrollToNotification);

  $scope.$on('$destroy', function() {
    headerNotificationsObservable.unsubscribe('shift-invite-accept', reloadData);
    headerNotificationsObservable.unsubscribe('shift-invite-decline', reloadData);
    headerNotificationsObservable.unsubscribe('go-to-notification', scrollToNotification);
  });

  return vm;

};

angular.module('public.employee.timeline')
  .controller('EmployeeTimelineController', dependencies.concat(EmployeeTimelineController));
