/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['AppModel'];

const CommentRead = AppModel => {

  class CommentRead extends AppModel {
    static initClass() {
      this.configure({
        url : '/users/{{userId}}/comment_reads/{{id}}',
        name : 'comment_read'
      });
    }
  }

  CommentRead.initClass();
  return CommentRead;
};

angular.module('data.user').factory('CommentRead', dependencies.concat(CommentRead));
