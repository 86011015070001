/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'ClientLocation', 'EntityManager', 'packageTypeService'
];

const SignClientRegistrationLocationManagementController = function(
  $scope, ClientLocation, EntityManager, packageTypeService
) {

  const vm = this;

  const entityManager = new EntityManager(ClientLocation, {
    params: {
      clientId: $scope.signClientRegistrationLayoutCtrl.getClientId()
    },
    withBlank: true
  });

  $scope.signClientRegistrationLayoutCtrl.loadClient().then(function(client) {
    entityManager.addOptions({
      prepare: {
        address: client.address
      }
    });
    return entityManager.loadAll();
  });

  vm.addNewLocation = location => entityManager.addNewEntity(location);

  vm.removeLocation = location => entityManager.removeEntity(location);

  vm.getLocations = () => entityManager.entities;

  vm.canAddLocation = function() {
    return !packageTypeService.hasFreePackage();
  };

  $scope.$on('client.registration.submit', () => {
    return entityManager.saveAll().then(() => $scope.signClientRegistrationLayoutCtrl.goNext());
  });

  return vm;

};

angular.module('sign.client.registration').controller('SignClientRegistrationLocationManagementController',
  dependencies.concat(SignClientRegistrationLocationManagementController));
