/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.email').service('emailStatus', function() {

  const STATUS_DRAFT = 1;
  const STATUS_SENT = 2;

  return {
    getDraftStatus() {
      return STATUS_DRAFT;
    },

    getSentStatus() {
      return STATUS_SENT;
    },

    isSent(status) {
      return status === STATUS_SENT;
    },

    isDraft(status) {
      return status === STATUS_DRAFT;
    }
  };
});
