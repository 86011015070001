/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'EmployeeAddressHistory', 'EntityManager', 'validationService'];

const EmployeeRegistrationAddressHistoryController = function($scope, EmployeeAddressHistory, EntityManager, validationService) {

  const vm = this;

  vm.validationService = validationService;

  const entityManager = new EntityManager(EmployeeAddressHistory, {
    params : {
      employeeId : $scope.employeeRegistrationLayoutCtrl.employeeId
    },
    withBlank : true
  }
  );

  vm.addNewAddressHistory = entityManager.addNewEntity;
  vm.removeAddressHistory = entityManager.removeEntity;

  vm.getAddressHistories = () => entityManager.entities;

  entityManager.loadAll();

  $scope.$on('employee.registration.submit', () =>
    entityManager.saveAll().then(() => $scope.employeeRegistrationLayoutCtrl.goNext())
  );

  return vm;
};

angular
  .module('public.security-manager.employee.registration')
  .controller('EmployeeRegistrationAddressHistoryController',
    dependencies.concat(EmployeeRegistrationAddressHistoryController));
