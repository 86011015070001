/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').factory('Booking', ['AppModel', function(AppModel) {

  class Booking extends AppModel {
    static initClass() {
      this.configure({url : '/bookings', name : 'booking'});
    }

    static clone(bookingId) {
      return this.$post(this.bookingUrl(bookingId, 'clone'));
    }

    static copy(bookingId) {
      return this.$post(this.bookingUrl(bookingId, 'copy'));
    }

    static mergeClone(bookingId) {
      return this.$put(this.bookingUrl(bookingId, 'merge_clone'));
    }

    static totalCost(bookingId) {
      return this.$get(this.bookingUrl(bookingId, 'total_cost'));
    }

    static shiftsCost(bookingId) {
      return this.$get(this.bookingUrl(bookingId, 'shifts_cost'));
    }

    static invoice(bookingIds) {
      return this.$get(this.resourceUrl('invoice'), {'ids[]' : bookingIds});
    }

    static getLastUpdatedAt(bookingIds) {
      return this.$get(this.resourceUrl('last_updated_at'), {'ids[]' : bookingIds});
    }

    static bookingUrl(bookingId, url) {
      return [this.resourceUrl(), bookingId, url].join('/');
    }

    static lockSeries(params) {
      return this.$post(this.resourceUrl() + '/lock_series', params);
    }

    static unlockSeries() {
      return this.$delete(this.resourceUrl() + '/unlock_series');
    }

    static forShift(shiftId) {
      return this.$get(this.bookingUrl(shiftId, 'for_shift'));
    }

    createRepeatableItems() {
      return this.$post(this.getUrl() + '/repeat');
    }

    split() {
      return this.$put(this.getUrl() + '/split');
    }

    unlock() {
      return this.$delete(this.getUrl() + '/unlock');
    }
  }

  Booking.initClass();

  return Booking;

}]);
