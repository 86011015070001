const documentTypeType = function() {

  const TRAINING = 1;
  const EMPLOYEE_DOCUMENT = 2;
  const CLIENT_DOCUMENT = 3;

  const names = {
    [TRAINING]: 'Licenses and trainings',
    [EMPLOYEE_DOCUMENT]: 'Documents',
    [CLIENT_DOCUMENT]: 'Client documents'
  };

  return {
    getNames() {
      return names;
    },

    getName(type) {
      return names[type];
    },

    getTraining() {
      return TRAINING;
    },

    getEmployeeDocument() {
      return EMPLOYEE_DOCUMENT;
    },

    getClientDocument() {
      return CLIENT_DOCUMENT;
    },

    isTrainingType(type) {
      return type === TRAINING;
    },

    isEmployeeDocumentType(type) {
      return type === EMPLOYEE_DOCUMENT;
    },

    isClientDocumentType(type) {
      return type === CLIENT_DOCUMENT;
    }
  };
};

angular.module('data.document').service('documentTypeType', documentTypeType);
