/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'EntityManager', 'EmployeeAddressHistory', 'dateService'];

const EmployeeProfileAddressHistoryController = function($scope, EntityManager, EmployeeAddressHistory, dateService) {

  const vm = this;

  vm.isShownAddressHistoryPopup = false;
  vm.ascendingNameOrder = true;

  const entityManager = new EntityManager(EmployeeAddressHistory, {
    params : {
      employeeId : $scope.employeeProfileLayoutCtrl.employeeId
    }
  }
  );

  $scope.employeeProfileLayoutCtrl.loadEmployee().then(employee => vm.employee = employee);

  vm.removeAddressHistory = addressHistory =>
    entityManager.removeEntity(addressHistory, () => vm.employee.addressHistoriesCount--)
  ;

  vm.showAddressHistoryPopup = function(addressHistory) {
    vm.popupAddressHistory = addressHistory || entityManager.createEntity();
    return vm.isShownAddressHistoryPopup = true;
  };

  vm.getPeriod = function(addressHistory) {
    let to;
    const from = dateService.formatDate(addressHistory.from, dateService.getDateFormat());
    if (addressHistory.to) {
      to = dateService.formatDate(addressHistory.to, dateService.getDateFormat());
    } else {
      to = 'Now';
    }
    return `${from} - ${to}`;
  };

  vm.closeAddressHistoryPopup = function(savedAddressHistory) {
    vm.isShownAddressHistoryPopup = false;
    if (savedAddressHistory) {
      vm.employee.addressHistoriesCount++;
      entityManager.updateEntity(savedAddressHistory);
      entityManager.resetLoadData();
      return entityManager.loadAll();
    }
  };

  vm.getAddressHistories = () => entityManager.entities;

  entityManager.loadAll();

  return vm;
};

angular.module('public.employee.profile').controller('EmployeeProfileAddressHistoryController',
  dependencies.concat(EmployeeProfileAddressHistoryController));
