/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const SickDay = AppModel => {

  class SickDay extends AppModel {
    static initClass() {
      this.configure({ url: '/sick_days/{{ id }}', name: 'sick_day' });
    }
  }

  SickDay.initClass();

  return SickDay;

};

angular.module('data.employee').factory('SickDay', dependencies.concat(SickDay));
