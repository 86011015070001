const dependencies = [
  '$q', 'Employee', 'BookingShiftEmployeeRoot', 'PlanningTimelineEntity', 'bookingShiftEmployeeAssignStatus'
];

const PlanningTimelineActions = function (
  $q, Employee, BookingShiftEmployeeRoot, PlanningTimelineEntity, bookingShiftEmployeeAssignStatus
) {

  const SHIFT_EMPLOYEE_NEW_STATUS = bookingShiftEmployeeAssignStatus.getNewStatus();
  const SHIFT_EMPLOYEE_ASSIGNED_STATUS = bookingShiftEmployeeAssignStatus.getAssignedStatus();

  const ShiftEmployeeEntity = PlanningTimelineEntity.ShiftEmployeeEntity();

  const ACTION_CREATE = 'creation';
  const ACTION_REMOVE = 'removal';
  const ACTION_CHANGE = 'change';

  const CHUNK_SIZE = 20;

  const STATUS_FAKE_DESTROY = 'fake_destroy';

  class Change {

    constructor(entity, type, params) {
      if (params == null) {
        params = {};
      }
      this.entity = entity;
      this.type = type;
      this.newStatus = params.newStatus;
    }
  }

  return class Actions {

    constructor() {
      this.commitChanges = this.commitChanges.bind(this);
      this.employees = {}; // map[employeeId][]shiftEmployees
      this.shifts = {}; // map[shiftId][]shiftEmployees
    }

    _getAssignedCountChange(shiftId) {
      let count = 0;
      if (!this.shifts[shiftId]) {
        return count;
      }
      for (let change of Array.from(this.shifts[shiftId])) {
        if (change.entity.isAssign) {
          count++;
        } else if (change.newStatus === undefined) {
          count--;
        }
      }
      return count;
    }

    _createNewShiftEmployee(employeeId, placeholderEntity, assignStatus) {
      const shiftEmployee = new ShiftEmployeeEntity();
      shiftEmployee.set({
        assignStatus,
        shiftId: placeholderEntity.shiftId,
        employeeId,
        startTime: placeholderEntity.startTime,
        endTime: placeholderEntity.endTime,
        shiftName: placeholderEntity.shiftName,
        intersections: []
      });
      return shiftEmployee;
    }

    _addChange(change, shiftId, employeeId) {
      if (this.employees[employeeId]) {
        this.employees[employeeId].push(change);
      } else {
        this.employees[employeeId] = [change];
      }
      if (this.shifts[shiftId]) {
        return this.shifts[shiftId].push(change);
      } else {
        return this.shifts[shiftId] = [change];
      }
    }

    _removeInvitesForShift(shiftId) {
      if (!this.shifts[shiftId]) {
        return;
      }
      const removed = _.remove(this.shifts[shiftId], change => (change.type === ACTION_CREATE) && change.entity.isInvite);
      if (removed.length === 0) {
        return;
      }
      return (() => {
        const result = [];
        for (let employeeId in this.employees) {
          const changes = this.employees[employeeId];
          result.push(_.pullAll(changes, removed));
        }
        return result;
      })();
    }

    // does not mutate employee rows
    addAssigns(employeeRows, entities, shifts, employeeId) {
      const addRemovalChangeFor = [];
      const existingEntityIds = [];
      for (let index = 0; index < entities.length; index++) {
        let actionType, newEntity;
        const entity = entities[index];
        if (entity.isShiftEmployee) {
          newEntity = this._cloneEntity(entity);
          newEntity.assignStatus = SHIFT_EMPLOYEE_ASSIGNED_STATUS;
          if (entity.id) {
            actionType = ACTION_CHANGE;
            existingEntityIds.push(entity.id);
          } else {
            actionType = ACTION_CREATE;
          }
        } else if (entity.isPlaceholder) {
          actionType = ACTION_CREATE;
          newEntity = this._createNewShiftEmployee(employeeId, entity, SHIFT_EMPLOYEE_ASSIGNED_STATUS);
        }
        this._addChange(
          new Change(newEntity, actionType, { newStatus: SHIFT_EMPLOYEE_ASSIGNED_STATUS }),
          newEntity.shiftId,
          employeeId
        );
        shifts[index].shift.requiredEmployeesCount--; // todo more elegant
        if (shifts[index].shift.requiredEmployeesCount === 0) {
          this._removeInvitesForShift(entity.shiftId);
          addRemovalChangeFor.push(entity.shiftId);
        }
      }
      if (addRemovalChangeFor.length > 0) {
        return this._removeInvites(employeeRows, addRemovalChangeFor, existingEntityIds);
      }
    }

    _removeInvites(employeeRows, addRemovalChangeFor, existingEntityIds) {
      return Array.from(employeeRows).map((row) =>
        (() => {
          const result = [];
          for (let entity of Array.from(row.entities)) {
            if (
              entity.isShiftEmployee && entity.isInvite &&
              _.includes(addRemovalChangeFor, entity.shiftId) &&
              (existingEntityIds.indexOf(entity.id) === -1)
            ) {
              result.push(this._addChange(
                new Change(this._cloneEntity(entity), ACTION_REMOVE, { newStatus: STATUS_FAKE_DESTROY }),
                entity.shiftId,
                entity.employeeId
              ));
            } else {
              result.push(undefined);
            }
          }
          return result;
        })());
    }

    addUnassign(entity, dShift) {
      if (entity.isAssign) {
        dShift.shift.requiredEmployeesCount++;
      } // todo more elegant
      const newEntity = this._cloneEntity(entity);
      newEntity.assignStatus = undefined;
      return this._addChange(
        new Change(newEntity, ACTION_REMOVE, { newStatus: undefined }),
        newEntity.shiftId,
        newEntity.employeeId
      );
    }

    // does not mutate employee rows
    addInvites(employeeRows, entities, employeeId) {
      return (() => {
        const result = [];
        for (let entity of Array.from(entities)) {
          const newEntity = this._createNewShiftEmployee(employeeId, entity, SHIFT_EMPLOYEE_NEW_STATUS);
          result.push(this._addChange(
            new Change(
              newEntity, ACTION_CREATE, { newStatus: SHIFT_EMPLOYEE_NEW_STATUS }),
              newEntity.shiftId, employeeId
            )
          );
        }
        return result;
      })();
    }

    mergeShiftEmployees(employeeRows, from, to) {
      return (() => {
        const result = [];
        for (let row of Array.from(employeeRows)) {
          if (!this.employees[row.id]) {
            continue;
          }
          let changed = false;
          for (let change of Array.from(this.employees[row.id])) {
            let removed;
            if (change.entity.endTime.isBefore(from) || change.entity.startTime.isAfter(to)) {
              continue;
            } // If the changes are off the screen
            if (change.type === ACTION_CREATE) {
              row.entities.push(change.entity);
              changed = true;
            } else if (change.type === ACTION_CHANGE) {
              removed = _.remove(row.entities, e => (e.shiftId === change.entity.shiftId) && (e.employeeId === change.entity.employeeId));
              if (removed) {
                row.entities.push(change.entity);
                changed = true;
              }
            } else if (change.type === ACTION_REMOVE) {
              removed = _.remove(row.entities, e => (e.shiftId === change.entity.shiftId) && (e.employeeId === change.entity.employeeId));
              if (removed) {
                changed = true;
              }
            }
          }
          if (changed) {
            result.push(row.entities = _.sortBy(row.entities, entity => entity.startTimeValue));
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    }

    commitActionsInChunks(actions, chunkSize) {
      const bulkPromises = _.map(_.chunk(actions, chunkSize), function(actionChunks) {
        return BookingShiftEmployeeRoot.bulkStatusChange(actionChunks);
      });
      const commitPromises = function(resolve) {
        const bulkPromise = bulkPromises.shift();
        if (bulkPromise) {
          bulkPromise.then(function () {
            commitPromises(resolve);
          });
        } else {
          resolve(true);
        }
      };
      return $q(commitPromises);
    }

    commitChanges() {
      const changesGroup = {};
      for (let shiftId in this.shifts) {
        const shiftChanges = this.shifts[shiftId];
        for (let change of Array.from(shiftChanges)) {
          // get only last
          changesGroup[`${change.entity.shiftId}_${change.entity.employeeId}`] = {
            shiftEmployeeId: change.entity.id,
            shiftId: change.entity.shiftId,
            employeeId: change.entity.employeeId,
            newStatus: change.newStatus
          };
        }
      }
      let actions = _.filter(_.values(changesGroup), value => {
        return !((value.newStatus === undefined) && (value.shiftEmployeeId === undefined)) && (value.newStatus !== STATUS_FAKE_DESTROY);
      });
      return this.commitActionsInChunks(_.orderBy(actions, 'newStatus', 'desc'), CHUNK_SIZE);
    }

    _cloneEntity(entity) {
      const newEntity = _.cloneDeep(entity);
      newEntity.hasChanges = true;
      return newEntity;
    }

  };

};

angular
  .module('public.security-manager.schedule-manager.timeline.planning')
  .factory('PlanningTimelineActions', dependencies.concat(PlanningTimelineActions));
