/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const securityManagerDocumentVisibility = function() {

  const CLIENT = 1;
  const STAFF = 2;
  const ALL = 10;

  const names = {
    [CLIENT] : 'Visible to client',
    [STAFF] : 'Visible to staff',
    [ALL] : 'Visible to all'
  };

  return {
    getNames() {
      return names;
    },

    getOptions() {
      return [{
        id : CLIENT,
        name : 'Visible to clients'
      }
      , {
        id : STAFF,
        name : 'Visible to staff'
      }
      , {
        id : ALL,
        name : 'Visible to all'
      }
      ];
    }
  };
};

angular
  .module('public.security-manager.settings')
  .service('securityManagerDocumentVisibility', securityManagerDocumentVisibility);
