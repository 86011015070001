const dependencies = [];

const DateRange = function() {

  class DateRange {

    constructor(today) {
      this.today = today;
      this.active = false;
    }

    getToday() {
      return this.today.clone();
    }

    deactivate() {
      return this.active = false;
    }

    activate() {
      return this.active = true;
    }

    select(rangeSelector) {
      this._setRangeSelectorUI(rangeSelector);
      return this._setRangeSelectorDates(rangeSelector);
    }

    hasSamePeriod(from, to) {
      return this.getFrom && this.getFrom().isSame(from, 'day') && this.getTo().isSame(to, 'day');
    }

    getLabel() {
      return '';
    }

    _setRangeSelectorDates(rangeSelector) {
      rangeSelector.setFrom(this.getFrom());
      rangeSelector.setTo(this.getTo());
    }

    _setRangeSelectorUI(rangeSelector) {
      this.activate();
      return rangeSelector.setLabel(this.getLabel(), this);
    }
  }

  return DateRange;
};

angular.module('form').factory('DateRange', dependencies.concat(DateRange));
