/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = [
  '$scope', '$state', '$location', '$q', '$timeout', 'FilterParamsService', 'EntityManager', 'Employee',
  'EmployeeInvoice', 'dateService', 'employeeInvoiceStatus', 'currentUserService', 'employeeType',
  'employeeInvoiceLabelService', 'packageTypeService'
];

// TODO: split controller for employee and security manager
const InvoiceEmployeeInvoicesController = function (
  $scope, $state, $location, $q, $timeout, FilterParamsService, EntityManager, Employee,
  EmployeeInvoice, dateService, employeeInvoiceStatus, currentUserService, employeeType,
  employeeInvoiceLabelService, packageTypeService
) {

  const vm = this;

  const ISO_DATE_FORMAT = dateService.getIsoDateFormat();
  const DATE_FORMAT = dateService.getDateFormat();

  const filterParamsService = new FilterParamsService({
    order: ['number', 'period'],
    filter: ['period', 'status', 'numberFilter']
  });

  const entityManager = new EntityManager(EmployeeInvoice, { loadCbName: 'all' });

  vm.invoicedDates = [];
  vm.datesWithShifts = [];
  vm.isShownPeriodPopup = false;
  vm.canCreateInvoice = false;
  vm.filterParams = filterParamsService.getParams();
  vm.employeeInvoiceStatus = employeeInvoiceStatus;
  vm.isDisabledLoading = entityManager.isDisabledLoading;
  vm.selectedInvoiceVersionsIds = [];
  vm.isShownStatusChangePopup = false;
  vm.isLoading = false;

  vm.$onInit = function () {
    packageTypeService.checkProPackage();
    vm.editable = true;
    const locationParams = $location.search();
    loadEmployee();
    if (locationParams.from && locationParams.to) {
      vm.endDateFrom = moment(locationParams.from, ISO_DATE_FORMAT);
      vm.endDateTo = moment(locationParams.to, ISO_DATE_FORMAT);
    } else {
      const today = dateService.today();
      vm.endDateFrom = today.clone().subtract(30, 'days');
      vm.endDateTo = today.clone();
    }
  };

  vm.loadMoreInvoices = function () {
    entityManager.loadMore();
  };

  vm.getInvoices = function () {
    return entityManager.entities;
  };

  vm.getInvoiceVersions = function (invoice) {
    return invoice.sortedInvoiceVersions || (invoice.sortedInvoiceVersions = sortInvoiceVersions(invoice.versions));
  };

  vm.closePeriodPopup = function (from, to) {
    vm.isShownPeriodPopup = false;
    if (from && to) {
      from = from.format(ISO_DATE_FORMAT);
      to = to.format(ISO_DATE_FORMAT);
      $state.go('public.employee-invoice-form', { from, to, employeeId: vm.employeeId });
    }
  };

  vm.createInvoice = function () {
    Employee.getInvoicedDates(vm.employeeId).then(function (invoicedDates) {
      vm.invoicedDates = toHash(invoicedDates);
      vm.isShownPeriodPopup = true;
    });
  };

  vm.filterInvoices = function () {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    urlParams.employeeId = vm.employeeId || $state.params.employeeId;
    if (vm.endDateTo && vm.endDateTo) {
      urlParams.from = moment(vm.endDateFrom, DATE_FORMAT).format(ISO_DATE_FORMAT);
      urlParams.to = moment(vm.endDateTo, DATE_FORMAT).format(ISO_DATE_FORMAT);
    }
    $location.search(urlParams);
    return entityManager.load(urlParams);
  };

  vm.toggleNumberOrder = function () {
    vm.filterParams.order.period.ascending = null;
    if (vm.filterParams.order.number.ascending === null) {
      return vm.filterParams.order.number.ascending = true;
    } else {
      return vm.filterParams.order.number.ascending = !vm.filterParams.order.number.ascending;
    }
  };

  vm.togglePeriodOrder = function () {
    vm.filterParams.order.number.ascending = null;
    if (vm.filterParams.order.period.ascending === null) {
      return vm.filterParams.order.period.ascending = true;
    } else {
      return vm.filterParams.order.period.ascending = !vm.filterParams.order.period.ascending;
    }
  };

  vm.updateVersions = function (newVersion) {
    const invoice = _.find(entityManager.entities, invoice => invoice.id === newVersion.invoiceId);
    invoice.versions.push(newVersion);
    invoice.versionsCount++;
    return invoice.sortedInvoiceVersions = sortInvoiceVersions(invoice.versions);
  };

  vm.canManageInvoice = function (invoice) {
    return !invoice.isInPayroll && vm.editable && (
      currentUserService.isSecurityManagerLogged() || employeeType.isSelfEmployed(invoice.employmentType)  || employeeType.isSubContractor(invoice.employmentType)
    );
  };

  vm.canViewHistory = function (invoice) {
    return currentUserService.isSecurityManagerLogged() && (invoice.versions.length > 1);
  };

  vm.removeInvoice = function (invoice) {
    entityManager.removeEntity(invoice);
  };

  vm.getFilterNames = function () {
    if (currentUserService.isEmployeeLogged()) {
      return employeeInvoiceStatus.getEmployeeFilterNames();
    } else {
      return employeeInvoiceStatus.getSecurityManagerFilterNames();
    }
  };

  vm.getSelectedFilterNames = function () {
    if (currentUserService.isEmployeeLogged()) {
      return employeeInvoiceStatus.getSelectedEmployeeFilterNames();
    } else {
      return employeeInvoiceStatus.getSelectedSecurityManagerFilterNames();
    }
  };

  vm.openChangeStatusPopup = function () {
    vm.isShownStatusChangePopup = true;
  };

  vm.onBookingCheck = function (invoiceVersion) {
    const findElemPosition = vm.selectedInvoiceVersionsIds.indexOf(invoiceVersion.id);
    if (findElemPosition === -1) {
      return vm.selectedInvoiceVersionsIds.push(invoiceVersion.id);
    } else {
      return vm.selectedInvoiceVersionsIds.splice(findElemPosition, 1);
    }
  };

  vm.onChangeMultipleStatus = function (selectedStatus, paidDate, payNote) {
    vm.isShownStatusChangePopup = false;
    if (selectedStatus) {
      setMultipleStatusChange(selectedStatus, paidDate, payNote);
    }
    for (let invoice of vm.getInvoices()) {
      for (let invoiceVersion of invoice.versions) {
        invoiceVersion.isChecked = false;
      }
    }
    return vm.selectedInvoiceVersionsIds = [];
  };

  vm.showLoader = function () {
    vm.isLoading = true;
  };

  vm.hideLoader = function () {
    $timeout(() => vm.isLoading = false);
  };

  const sortInvoiceVersions = function (invoiceVersions) {
    return _.orderBy(invoiceVersions, ['id'], ['desc']);
  };

  const toHash = function (dates) {
    return _.reduce(dates, function (acc, date) {
      acc[date] = true;
      return acc;
    }, {});
  };

  const setMultipleStatusChange = function (selectedStatus, paidDate, payNote) {
    const multipleChanges = {
      ids: vm.selectedInvoiceVersionsIds,
      status: selectedStatus,
      paidDate,
      payNote
    };
    EmployeeInvoice.bulkStatusChange(multipleChanges).then(() => $state.reload());
  };

  const loadEmployee = function () {
    if (currentUserService.isEmployeeLogged()) {
      return initEmployeeData(currentUserService.getCurrentProfile());
    } else {
      return Employee.get($state.params.employeeId).then(initEmployeeData);
    }
  };

  const initEmployeeData = function (employee) {
    vm.employeeId = employee.id;
    vm.canCreateInvoice = employeeType.isSelfEmployed(employee.employmentType) || employeeType.isSubContractor(employee.employmentType);
    vm.employeesLabels = employeeInvoiceLabelService.getEmployeesTexts(employee.employmentType);
  };

  $scope.$watch(function () {
    return vm.filterParams;
  }, _.debounce(vm.filterInvoices, 100), true);

  return vm;
};

angular
  .module('public.invoice.employee')
  .controller('InvoiceEmployeeInvoicesController', dependencies.concat(InvoiceEmployeeInvoicesController));
