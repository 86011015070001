/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [];

const bookingCollapseService = function() {

  const collapsedBookingIds = [];

  const indexOfBooking = booking => collapsedBookingIds.indexOf(booking.id);

  const isCollapsed = booking => ~indexOfBooking(booking);

  const isCollapsedRow = function(bookingRow) {
    for (let booking of Array.from(bookingRow.dBookings)) {
      if (isCollapsed(booking)) { return true; }
    }
    return false;
  };

  const setCollapsedBooking = function(booking, setCollapsed) {
    const index = indexOfBooking(booking);
    if (~index) {
      if (!setCollapsed) { return collapsedBookingIds.splice(index, 1); }
    } else {
      if (setCollapsed) { return collapsedBookingIds.push(booking.id); }
    }
  };

  const setCollapsedBookingRow = (bookingRow, setCollapsed) =>
    Array.from(bookingRow.dBookings).map((booking) =>
      setCollapsedBooking(booking, setCollapsed))
  ;

  return {
    isCollapsed,
    isCollapsedRow,

    collapseBooking(booking) {
      const index = indexOfBooking(booking);
      if (~index) {
        return collapsedBookingIds.splice(index, 1);
      } else {
        return collapsedBookingIds.push(booking.id);
      }
    },

    collapseBookingRow(bookingRow) {
      return setCollapsedBookingRow(bookingRow, !isCollapsedRow(bookingRow));
    }
  };
};

angular.module('public.timeline').service('bookingCollapseService', dependencies.concat(bookingCollapseService));
