/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', 'BookingShiftLock', 'BookingShiftComment', 'inactivityService', 'shiftFormDetailsChangeService',
  'resourceChargeType', 'currentUserService', 'packageTypeService'
];

const PopupBookingShiftFormController = function(
  $scope, BookingShiftLock, BookingShiftComment, inactivityService, shiftFormDetailsChangeService,
  resourceChargeType, currentUserService, packageTypeService
) {

  const vm = this;

  const TAIL_TOP_OFFSET = 160;
  const DEFAULT_POPUP_HEIGHT = 458;

  vm.bookingTimelineShiftSelector = $scope.bookingTimelineShiftSelector;
  vm.timeLineLoader = $scope.timeLineLoader;

  vm.isShownPopup = false;

  let _shiftLock = undefined;

  vm.$onInit = function() {
    initTabs();
    window.addEventListener('beforeunload', unlockShift);
  };

  vm.showPopup = function() {
    if (!vm.isShownPopup) {
      resetActiveTabKey();
      updateCommentsTab();
      vm.isShownPopup = true;
      inactivityService.callAfterInactivity(1, vm.closePopup);
      return lockShift();
    }
  };

  vm.closePopup = function() {
    if (vm.isShownPopup) {
      vm.isShownPopup = false;
      inactivityService.removeInactivityObserver(vm.closePopup);
      unlockShift();
      return $scope.popupCloseCb();
    }
  };

  vm.onRemove = () => $scope.onRemove({ shift: vm.shift, afterRemoveCb: unlockShift });

  vm.save = function(shift, isWarningSave = false) {
    unlockShift();
    if (shift) {
      return $scope.saveShift({shift, afterSaveCb() {
        if (isWarningSave) {
          return shiftFormDetailsChangeService.callAfterWarningCb();
        } else {
          return $scope.popupCloseCb();
        }
      }});
    } else {
      return $scope.popupCloseCb();
    }
  };

  vm.changeCommentsCount = function(count) {
    vm.shift.commentsCount = count;
    return updateCommentsTab();
  };

  vm.changeTab = function(tab) {
    if (shiftFormDetailsChangeService.hasChanged()) {
      shiftFormDetailsChangeService.showWarning(function() {
        vm.activeTabKey = tab;
        shiftFormDetailsChangeService.reset();
      });
    } else {
      vm.activeTabKey = tab;
      shiftFormDetailsChangeService.reset();
    }
  };

  const updatePopupPosition = function() {
    if ($scope.regularPopup) { return; }
    const holder = $scope.$popup[0].firstElementChild;
    let tailTop = TAIL_TOP_OFFSET;
    let top = $scope.position.top - tailTop;
    const popupHeight = holder.offsetHeight < DEFAULT_POPUP_HEIGHT ?
      DEFAULT_POPUP_HEIGHT
    :
      holder.offsetHeight;
    const invisibleHeight = (top + popupHeight) - window.innerHeight;
    if (invisibleHeight > 0) {
      top -= invisibleHeight;
      tailTop += invisibleHeight;
    }
    holder.style.left = `${$scope.position.left}px`;
    holder.style.top = `${top}px`;
    if (holder.lastElementChild) {
      const tailOffset = $scope.tailOffset || 0;
      holder.lastElementChild.style.top = `${tailTop - (tailOffset - 0)}px`;
    }
  };

  const updateCommentsTab = function() {
    if (vm.shift && vm.tabs.comments) {
      BookingShiftComment.getCount({ bookingId: vm.shift.bookingId, shiftId: vm.shift.id }).then(data => {
        vm.tabs.comments.count = data.count;
      });
    }
  };

  const resetActiveTabKey = function() {
    if ($scope.isShownEmployeeInvite) {
      return vm.activeTabKey = 'employees';
    } else {
      return vm.activeTabKey = $scope.activeTab || _.first(_.keys(vm.tabs));
    }
  };

  const initTabs = function() {
    vm.tabs = {
      details: {
        name: 'Shift details'
      }
    };
    if ($scope.withEmployees) { vm.tabs.employees = { name: 'Employees' }; }
    if ($scope.withComments !== false) { vm.tabs.comments = { name: 'Comments' }; }
    if (
      ($scope.withLog !== false) &&
      packageTypeService.hasProPackage() &&
      currentUserService.isSecurityManagerLogged() &&
      !currentUserService.getCurrentProfile().isSupervisor()
    ) {
      vm.tabs.activityLog = { name: 'Activity Log' };
    }
  };

  const lockShift = function() {
    if ($scope.withoutLock) { return; }
    new BookingShiftLock({ booking_id: $scope.shift.bookingId, shift_id: $scope.shift.id }).save().then(shiftLock => {
      _shiftLock = shiftLock;
    });
  };

  const unlockShift = function() {
    if ($scope.withoutLock) { return; }
    if (_shiftLock != null) {
      _shiftLock.delete().catch(() => console.log('Shift lock has been already destroyed'));
    }
    return _shiftLock = undefined;
  };

  const onDestroy = function() {
    unlockShift();
    return window.removeEventListener('beforeunload', unlockShift);
  };

  const filterTabs = function() {
    vm.filteredTabs = vm.tabs;
    if (resourceChargeType.isPerUnit($scope.shift.resource.chargeType)) {
      return vm.filteredTabs = _.pickBy(vm.tabs, (value, key) => key !== 'employees');
    }
  };

  $scope.$watch('popupShowCondition', function() {
    if ($scope.popupShowCondition) {
      $scope.$popup[0].style.visibility = 'hidden';
      vm.showPopup();
      setTimeout(function() {
        updatePopupPosition();
        $scope.$popup[0].style.visibility = '';
      }, 0);
    } else {
      vm.isShownPopup = false;
    }
  });

  $scope.$watch('shift', function() {
    if ($scope.shift === undefined) { return; }
    filterTabs();
    shiftFormDetailsChangeService.reset();
    vm.shift = angular.copy($scope.shift);
    return updateCommentsTab();
  });

  $scope.$watch('position', updatePopupPosition);
  $scope.$on('logout', onDestroy);
  $scope.$on('$destroy', onDestroy);

  return vm;

};

angular.module('popup.booking')
  .controller('PopupBookingShiftFormController', dependencies.concat(PopupBookingShiftFormController));
