const dependencies = [];

const packagePaymentStatusService = function() {

  const PAYMENT_STATUS_NEW = 1;
  const PAYMENT_STATUS_PAID = 2;
  const PAYMENT_STATUS_FAILED = 3;
  const PAYMENT_STATUS_IN_PROCESS = 4;

  return {

    isPaid(paymentStatus) {
      return paymentStatus === PAYMENT_STATUS_PAID;
    },

    isFailed(paymentStatus) {
      return paymentStatus === PAYMENT_STATUS_FAILED;
    },

    isInProcess(paymentStatus) {
      return paymentStatus === PAYMENT_STATUS_IN_PROCESS;
    }

  }

};

angular.module('data.security-provider')
  .service('packagePaymentStatusService', dependencies.concat(packagePaymentStatusService));
