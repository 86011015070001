const dependencies = [];

const SecurityManagerNotificationsPageController = function() {

  const vm = this;

  vm.getTabText = function() {
    if (!vm.service.notificationTabs) { return; }
    const label = _.find(vm.service.notificationTabs, 'isSelected').label;
    return label === 'All' ? '' : label + ' ';
  };

  return vm;

};

angular.module('public.security-manager.notifications').controller('SecurityManagerNotificationsPageController',
  dependencies.concat(SecurityManagerNotificationsPageController));
