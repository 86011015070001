/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel', 'getParamsService'];

const EmployeeCardVersionRoot = (AppModel, getParamsService) => {

  class EmployeeCardVersionRoot extends AppModel {
    static initClass() {
      this.configure({ url: '/employee_card_versions', name: 'version' });
    }

    static getPdfUrl(params, getParams, options) {
      const additionalPath = '/pdf_version.pdf' + getParamsService.buildUrl(getParams) + '&'
        + _.map(params.ids, id => `ids[]=${id}`).join('&');
      return this.resourceUrl(null, additionalPath, options);
    }
  }

  EmployeeCardVersionRoot.initClass();

  return EmployeeCardVersionRoot;

};

angular.module('data.card').factory('EmployeeCardVersionRoot', dependencies.concat(EmployeeCardVersionRoot));
