/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = ['FilterOption'];

let FilterCategory = function(FilterOption) {

  const PARAM_CLIENTS = 'client_ids';

  return class FilterCategory {
    
    constructor(category, isEnabled) {
      if (isEnabled == null) { isEnabled = false; }
      this.type = category.type;
      this.label = category.label;
      this.params = category.params;
      this.isEnabled = isEnabled;
      this.isOpen = false;
      this.tooltip = category.tooltip;
      this.isFullAccessToCategory = category.isFullAccessToCategory;
      this.isAccessRightFilter = category.isAccessRightFilter;
      this.options = _.map(category.options, option => new FilterOption(option));
      this.selectedOption = this.getSelected(category);
      if (category.top) {
        this.visibleOptions = this.options;
      } else if (category.showVisibleOptions) {
        this.visibleOptions = _.filter(this.options, 'isVisible');
      } else {
        this.visibleOptions = [];
      }
      this._initChildren(category);
    }

    getSelected(category) {
      return _.find(category.options, option => option.isSelected);
    }

    optionToggled(option, forceSelect = false) {
      if (this.isRadio()) {
        if (this.selectedOption === undefined) { this.selectedOption = option; }
        option.select();
        this._deselectOptionsExcept(option);
        this.setChildCategoriesOptions();
      } else {
        if (option.isSelected || forceSelect) {
          option.select();
        } else {
          option.deselect();
        }
        this.updateChildrenVisibleOptions(option);
      }
      this.setEnabledChildren();
    }

    updateChildrenVisibleOptions(parentOption, withToggle = true) {
      return (() => {
        const result = [];
        for (let childCategory of this.childCategories) {
          if (withToggle) {
            this.setChildrenOptionsSelection(childCategory, parentOption);
          }
          const visibleOptions = [];
          for (let option of childCategory.options) {
            for (let selectedOption of this.getSelectedOptions()) {
              if (option.parentId === selectedOption.id) {
                visibleOptions.push(option);
              }
            }
          }
          result.push(childCategory.visibleOptions = _.uniqBy(visibleOptions, 'id'));
        }
        return result;
      })();
    }

    setChildrenOptionsSelection(childCategory, parentOption) {
      if (!parentOption) { return; }
      _.each(childCategory.options, (option) => {
        if (option.parentId === parentOption.id) {
          if (parentOption.isSelected && this.isAccessRightFilter) {
            option.select();
          } else {
            option.deselect();
          }
        }
      });
    }

    deselect(option) {
      this.selectedOption = undefined;
      option.deselect();
      if (this.isRadio()) {
        this.setChildCategoriesOptions();
      } else {
        this.updateChildrenVisibleOptions();
      }
      this.setEnabledChildren();
    }

    setVisibleOptions(selectedParentOption) {
      const visibleOptions = [];
      if (selectedParentOption) {
        for (let option of Array.from(this.options)) {
          if (option.parentId === selectedParentOption.id) {
            visibleOptions.push(option);
          }
        }
      }
      this.visibleOptions = visibleOptions;
    }

    setEnabledChildren() {
      return (() => {
        for (let childCategory of Array.from(this.childCategories)) {
          if (this.selectedOptionsCount() === 0) {
            if (childCategory.isEnabled) {
              childCategory.disable();
              childCategory.clearAllSelections();
            }
          } else {
            if (!childCategory.isEnabled) {
              childCategory.enable();
            }
          }
          childCategory.setEnabledChildren();
        }
      })();
    }

    getTags() {
      const tags = [];
      for (let option of Array.from(this.options)) {
        if (option.tag && option.isSelected) {
          tags.push({ option, category: this });
        }
      }
      return tags;
    }

    getTagGroup() {
      return {
        category: this,
        label: `${this.label}s`,
        options: this.getTagOptions()
      };
    }

    updateTagGroup(tagGroup) {
      tagGroup.options = this.getTagOptions();
    }

    isClientCategory() {
      return this.params === PARAM_CLIENTS;
    }

    getTagOptions() {
      const options = [];
      for (let option of Array.from(this.options)) {
        if (option.tag && option.isSelected) {
          options.push(option);
        }
      }
      return options;
    }

    clearAllSelections() {
      this.selectedOption = undefined;
      for (let option of Array.from(this.options)) {
        option.deselect();
      }
      return Array.from(this.childCategories).map((childCategory) => childCategory.clearAllSelections());
    }

    getQueryParams() {
      const ids = [];
      for (let option of Array.from(this.options)) {
        if (option.isSelected) { ids.push(option.id); }
      }
      return ids;
    }

    getSelectedOption() {
      return _.find(this.options, option => option.isSelected);
    }

    getSelectedOptions() {
      return _.filter(this.options, option => option.isSelected);
    }

    isFullAccessSet() {
      return _.every(this.visibleOptions, 'isSelected');
    }

    selectAll() {
      _.each(this.visibleOptions, option => {
        if (!option.isSelected) {
          option.select();
          this.optionToggled(option);
        }
      });
    }
    
    deselectAll() {
      _.each(this.visibleOptions, option => {
        if (option.isSelected) {
          option.deselect();
          this.optionToggled(option);
        }
      });
    }

    setChildCategoriesOptions() {
      const selectedOption = this.getSelectedOption();
      return (() => {
        const result = [];
        for (let childCategory of Array.from(this.childCategories)) {
          childCategory.clearAllSelections();
          childCategory.setVisibleOptions(selectedOption);
          result.push(childCategory.setChildCategoriesOptions());
        }
        return result;
      })();
    }

    enable() {
      this.isEnabled = true;
    }

    disable() {
      this.isEnabled = false;
      this.close();
    }

    open() {
      return this.isOpen = true;
    }

    close() {
      return this.isOpen = false;
    }

    selectedOptionsCount() {
      return this.getSelectedOptions().length;
    }
    
    isRadio() {
      return this.type === 'radio';
    }
    
    isCheckbox() {
      return this.type === 'checkbox';
    }

    _initChildren(category) {
      return this.childCategories = category.children.map(childCategory => new FilterCategory(childCategory));
    }

    _deselectOptionsExcept(selectedOption) {
      for (let otherOption of Array.from(this.options)) {
        if ((selectedOption !== otherOption) && !!otherOption.isSelected) { otherOption.deselect(); }
      }
      return selectedOption.select();
    }

    _enabledChildrenCount() {
      return this._enabledChildren().length;
    }

    _enabledChildren() {
      return _.filter(this.childCategories, childCategory => childCategory.isEnabled);
    }
    
  }
  
};

angular.module('public.security-manager.schedule-manager.timeline')
  .service('FilterCategory', dependencies.concat(FilterCategory));
