/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$location', '$state', 'Client', 'FilterParamsService', 'packageTypeService',
  'EntityManager', 'localStorageService', 'clientStatus'
];

const ClientListController = function(
  $scope, $location, $state, Client, FilterParamsService, packageTypeService,
  EntityManager, localStorageService, clientStatus
) {

  const vm = this;

  const entityManager = new EntityManager(Client);
  const filterParamsService = new FilterParamsService({
    order: ['name'],
    filter: ['name', 'firstLetter', 'status']
  });

  vm.clientStatus = clientStatus;
  vm.filterParams = filterParamsService.getParams();
  vm.isShownClientsExceedPopup = false;

  Client.getCount().then(data => vm.clientsCount = data.count);

  vm.$onInit = function() {
    vm.editable = true;
  };

  vm.resetFilter = () => vm.filterParams.filter = {};

  vm.loadMoreClients = () => entityManager.loadMore();

  vm.getClients = () => entityManager.entities;

  vm.addNewClient = function() {
    if (packageTypeService.hasFreePackage()) {
      Client.getCount({}, { status: clientStatus.getActiveStatus() }).then(data => {
        if (data.count >= packageTypeService.getClientsLimitForFreePackage()) {
          openClientsExceedPopup();
        } else {
          goToClientCreation();
        }
      });
    } else {
      goToClientCreation();
    }
  };

  vm.closeClientsExceedPopup = function () {
    vm.isShownClientsExceedPopup = false;
  };

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  const goToClientCreation = function() {
    localStorageService.remove('clientId');
    return $state.go('public.security-manager.client-registration.step-1');
  };

  const openClientsExceedPopup = function() {
    vm.isShownClientsExceedPopup = true;
  };

  const filterClients = function() {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    $location.search(urlParams);
    return entityManager.load(urlParams);
  };

  $scope.$watch(() => vm.filterParams, _.debounce(filterClients, 50) , true);

  return vm;

};

angular.module('public.security-manager.client.list')
  .controller('ClientListController', dependencies.concat(ClientListController));
