const dependencies = ['AppModel', 'currentUserService'];

const SecurityProviderBillingRecipient = (AppModel, currentUserService) => {

  class SecurityProviderBillingRecipient extends AppModel {
    static initClass() {
      this.configure({
        url: '/security_providers/{{ securityProviderId }}/billing_recipients/{{ id }}',
        name: 'billing_recipient'
      });
    }

    static batchSave(recipients) {
      return this.$put(this.resourceUrl({
        securityProviderId: currentUserService.getCurrentProfile().securityProvider.id
      }, 'batch'), recipients);
    }

  }

  SecurityProviderBillingRecipient.initClass();

  return SecurityProviderBillingRecipient;

};

angular
  .module('data.security-provider')
  .factory('SecurityProviderBillingRecipient', dependencies.concat(SecurityProviderBillingRecipient));
