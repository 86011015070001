/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', '$element'];

const MultipleSelectController = function($scope, $element) {

  const vm = this;

  vm.isShownSelect = false;

  let selectModel = [];
  const ngModelController = $element.controller('ngModel');

  vm.getCurrentValue = function() {
    return _.map(selectModel, (value) => {
      return $scope.selectListItems[value];
    });
  };

  vm.toggleSelect = () => vm.isShownSelect = !vm.isShownSelect;

  vm.hideSelect = () => {
    if (vm.isShownSelect) {
      setSelectedValue(selectModel);
      vm.isShownSelect = false;
    }
  };

  vm.select = function(key) {
    key -= 0;
    if (vm.isSelected(key)) {
      selectModel = _.without(selectModel, key);
    } else {
      selectModel.push(key);
    }
    if ($scope.onSelect) { return $scope.onSelect({ selected: selectModel}); }
  };

  vm.isSelected = key => {
    return selectModel.indexOf(key - 0) !== -1;
  };

  vm.getTextOf = value => (value != null ? value.text : undefined) || value;

  vm.getSelectedNames = () => _.values(_.pick($scope.selectListItems, selectModel)).join(', ');

  vm.getSelectedTextOf = function() {
    if ($scope.selectedKeys) {
      if ($scope.selectListItems.length) {
        return _.map(selectModel, (value) => {
          return $scope.selectListItems[value];
        }).join(', ');
      } else {
        return 'None';
      }
    } else {
      return $scope.defaultSelectedName ? $scope.defaultSelectedName : 'Selected options';
    }
  };

  const setSelectedValue = value => {
    return ngModelController.$setViewValue(value);
  };

  const initDefaultSelectedKey = function() {
    if (_.isEmpty($scope.selectListItems)) { return; }
    if (ngModelController.$viewValue) {
      selectModel = _.map(ngModelController.$viewValue, (value) => value - 0);
    } else {
      selectModel = _.map($scope.selectListItems, (value, key) => key - 0);
    }
  };

  $scope.$watchCollection('selectListItems', initDefaultSelectedKey);
  $scope.$watch(() => ngModelController.$viewValue, initDefaultSelectedKey);

  return vm;

};

angular.module('gccs-ui').controller('MultipleSelectController', dependencies.concat(MultipleSelectController));
