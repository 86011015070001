/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['AppModel'];

const EmployeeNotification = AppModel => {

  class EmployeeNotification extends AppModel {
    static initClass() {
      this.configure({
        url : '/employees/{{employeeId}}/notifications/{{id}}',
        name : 'employee_notification'
      });
    }

    static getNotifications(employeeId, params) {
      return this.query(params, {employeeId});
    }

    static getCount(employeeId) {
      return this.$get(this.resourceUrl({ employeeId }, 'count'));
    }
  }

  EmployeeNotification.initClass();
  return EmployeeNotification;
};

angular.module('data.employee').factory('EmployeeNotification', dependencies.concat(EmployeeNotification));
