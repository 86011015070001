const dependencies = [];

const InputWrapperController = function() {

  const vm = this;

  return vm;

};

angular.module('shared-components').controller('InputWrapperController', dependencies.concat(InputWrapperController));
