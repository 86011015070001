const dependencies = ['Upload', 'sessionService', 'stringService'];

const uploadService = function(Upload, sessionService, stringService) {

  const getUploadData = function(entity, field, file) {
    const dataKey = entity.constructor.config.name;
    return {
      [dataKey]: {
        id: entity.id,
        [stringService.toSnakeCase(field)]: file
      }
    };
  };

  return {
    saveWithFile(entity, field, successCb, failCb) {
      const uploadedFieldName = field + 'Uploaded';
      if (entity[uploadedFieldName]) {
        entity[uploadedFieldName].progress = 0;
        entity[uploadedFieldName].uploadError = false;
        return entity.save().then(() =>
          Upload.upload({
            url: entity.getUrl(),
            method: 'PUT',
            data: getUploadData(entity, field, entity[uploadedFieldName]),
            headers: {
              'X-Session-Id': sessionService.getSessionId()
            }
          }).then((function(response) {
            const deserializedResponse = entity.constructor.deserialize(response);
            entity = deserializedResponse.data;
            if (successCb) { return successCb(entity); }
          }), (function() {
            entity[uploadedFieldName].uploadError = true;
            if (failCb) { return failCb(...arguments); }
          }), e => {
            return entity[uploadedFieldName].progress = parseInt((100.0 * e.loaded) / e.total);
          })
        );
      } else {
        if (!entity[field]) { entity[`remove_${field}`] = true; }
        return entity.save().then(function() {
          if (successCb) { return successCb(entity); }
        }, function() {
          if (failCb) { return failCb(...arguments); }
        });
      }
    },

    uploadFile(url, file, successCb) {
      Upload.upload({
        url: url,
        method: 'POST',
        data: {
          file: file
        },
        headers: {
          'X-Session-Id': sessionService.getSessionId()
        }
      }).then(function (response) {
        successCb(response.data);
      });
    },

    updateFile(entity, successCb) {
      const dataKey = entity.constructor.config.name;
      Upload.upload({
        url: entity.getUrl(),
        method: 'PUT',
        data: {
          [dataKey]: entity
        },
        headers: {
          'X-Session-Id': sessionService.getSessionId()
        }
      }).then(function (response) {
        successCb(response.data);
      });
    }
  };
};

angular.module('form').service('uploadService', dependencies.concat(uploadService));
