/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  'Client', 'FilterCategory', 'ExtendedFilterService', 'employeeGender', 'bookingShiftEmployeeAvailability'
];

const ExtendedFilterEmployeesService = function (
  Client, FilterCategory, ExtendedFilterService, employeeGender, bookingShiftEmployeeAvailability
) {

  return class ExtendedFilterEmployeesService extends ExtendedFilterService {

    constructor(filterData) {
      super(...arguments);
      this.filterData = filterData;
    }

    setSelectedShifts(selectedShifts) {
      this.selectedShifts = selectedShifts;
      this._initCategories(this.filterData);
      this._updateQueryParams();
      this._updateTopPanel();
      this.applyFilter();
    }

    setData(selectedShifts, availability, resourceId) {
      this.selectedShifts = selectedShifts;
      if (availability) {
        this.availability = availability;
      }
      if (resourceId) {
        this.resourceId = resourceId;
      }
      this._initCategories(this.filterData);
      this._updateQueryParams();
      return this._updateTopPanel();
    }

    updateOptionsOnMultiSelect(options, paramsType) {
      const category = _.find(this.categories, ['params', paramsType]);
      this.restoreFilterStateOnUpdate(options, category);
      if (category.type === 'radio') {
        category.selectedOption = _.find(category.options, option => option.isSelected);
      }
      this._updateQueryParams();
      return this._updateTopPanel();
    }

    getSelectedFilterOptions(categoryOptions, paramsType) {
      const selectedCategoryOptions = this.getFilterOptionsFromSelectedShifts(categoryOptions);
      const category = _.find(this.categories, ['params', paramsType]);
      this.restoreFilterStateOnUpdate(selectedCategoryOptions, category);
      category.options.forEach(option => {
        const foundSelectedOption = _.find(selectedCategoryOptions, ['id', option.id]);
        if (!foundSelectedOption) {
          return option.isSelected = false;
        }
      });
      this._updateQueryParams();
      this._updateTopPanel();
      return selectedCategoryOptions;
    }

    getFilterOptionsFromSelectedShifts(categoryOptions) {
      const selectedResources = [];
      let selectedCategoryOptions = [];
      this.selectedShifts.forEach(dShift => {
        const resourceId = dShift.shift.resource.id;
        if (selectedResources.indexOf(resourceId) === -1) {
          selectedResources.push(resourceId);
          return selectedCategoryOptions = _.unionBy(
            selectedCategoryOptions,
            _.filter(categoryOptions, categoryOption => {
              if (categoryOption.resourceId) {
                return categoryOption.resourceId === resourceId;
              } else if (categoryOption.resourceIds) {
                return categoryOption.resourceIds.includes(resourceId);
              } else {
                return false;
              }
            }),
            'id'
          );
        }
      });
      return selectedCategoryOptions;
    }

    restoreFilterStateOnUpdate(options, category) {
      return options.forEach(option => {
        const foundOption = _.find(category.options, oldOption => oldOption.id === option.id);
        if (foundOption) {
          return option.isSelected = foundOption.isSelected;
        }
      });
    }

    // todo rename params to param_id
    _categories(filterData) {
      let categories = [{
        label: 'Employee role',
        params: 'roles[]',
        top: true,
        type: 'checkbox',
        options: this._rolesOptions(filterData),
        children: []
      }, {
        label: 'By availability',
        params: 'availabilities[]',
        top: true,
        type: 'radio',
        options: this._availabilityOptions(),
        children: []
      }, {
        label: 'Sites',
        params: 'sites[]',
        top: true,
        type: 'checkbox',
        options: this._siteOptions(filterData),
        children: [{
          label: 'Locations',
          params: 'locations[]',
          type: 'checkbox',
          options: this._locationOptions(filterData),
          children: []
        }]
      }];
      return categories.concat([{
        label: 'Gender',
        params: 'genders[]',
        top: true,
        type: 'checkbox',
        options: this._genderOptions(),
        children: []
      }, {
        label: 'Trainings',
        params: 'trainings[]',
        top: true,
        type: 'checkbox',
        options: this._trainingOptions(filterData),
        children: []
      }]);
    }

    _rolesOptions(filterData) {
      const roles = _.map(filterData.roles, role => {
        return {
          id: role.id,
          label: role.name,
          resourceIds: role.resourceIds,
          tag: true,
          isSelected: true
        };
      });
      if (this.resourceId) {
        return _.filter(roles, role => {
          return role.resourceIds.includes(this.resourceId);
        });
      } else if ((this.selectedShifts != null ? this.selectedShifts.length : undefined)) {
        return this.getSelectedFilterOptions(roles, 'roles[]');
      } else {
        return [];
      }
    }

    _availabilityOptions() {
      const options = [];
      _.each(this.availability, (value, key) => {
        const obj = {
          id: key,
          label: value,
          tag: true,
          isSelected: (key - 0) === bookingShiftEmployeeAvailability.getDefault()
        };
        return options.push(obj);
      });
      if (this.selectedShifts != null ? this.selectedShifts.length : undefined) {
        this.updateOptionsOnMultiSelect(options, 'availabilities[]');
      }
      return options;
    }

    _homeZoneOptions(filterData) {
      const options = _.map(filterData.homeZones, homeZone =>
        ({
          id: homeZone.id,
          label: homeZone.name,
          tag: true
        })
      );
      if (this.selectedShifts != null ? this.selectedShifts.length : undefined) {
        this.updateOptionsOnMultiSelect(options, 'home_zones[]');
      }
      return options;
    }

    _genderOptions() {
      const names = employeeGender.getNames();
      const result = [];
      for (let id in names) {
        const name = names[id];
        result.push({ id: +id, label: name, tag: true });
      }
      if (this.selectedShifts != null ? this.selectedShifts.length : undefined) {
        this.updateOptionsOnMultiSelect(result, 'genders[]');
      }
      return result;
    }

    _trainingOptions(filterData) {
      const trainings = _.map(filterData.trainings, training => {
        return {
          id: training.id,
          label: training.name,
          resourceId: training.resourceId,
          tag: true
        };
      });
      if (this.resourceId) {
        return _.filter(trainings, training => {
          return training.resourceId === this.resourceId;
        });
      } else if ((this.selectedShifts != null ? this.selectedShifts.length : undefined)) {
        return this.getSelectedFilterOptions(trainings, 'trainings[]');
      } else {
        return [];
      }
    }

    _siteOptions(filterData) {
      if (this.selectedShifts && this.selectedShifts.length > 0) {
        return super._siteOptions(filterData, this.selectedShifts[0].clientId);
      } else {
        return super._siteOptions(filterData);
      }
    }
  }

};

angular.module('public.security-manager.schedule-manager.timeline')
  .service('ExtendedFilterEmployeesService', dependencies.concat(ExtendedFilterEmployeesService));
