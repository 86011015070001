const dependencies = ['AppModel'];

const BookingShiftIssue = AppModel => {

  class BookingShiftIssue extends AppModel {
    static initClass() {
      this.configure({ url: '/shift_issues/{{id}}', name: 'shift_issue' });
    }
  }

  BookingShiftIssue.initClass();

  return BookingShiftIssue;

};

angular.module('data.booking').factory('BookingShiftIssue', dependencies.concat(BookingShiftIssue));
