/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').service('getParamsService', () => {
  return {
    buildUrl(params) {
      let getParamsUrl = '';
      const getParams = [];
      _.each(params, function(value, key) {
        const getParamValue = _.isArray(value) ? value.join(',') : value;
        return getParams.push(`${key}=${getParamValue}`);
      });
      if (getParams.length > 0) { getParamsUrl = `?${getParams.join('&')}`; }
      return getParamsUrl;
    }
  };
});
