/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').filter('formatPriceFull', () => {
    return input => {
      if (input !== undefined) {
        return `£ ${input.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      }
    };
  }
);
