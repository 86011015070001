/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').service('pluralizeService', function() {

  const pluralize = function(count, pluralForms) {
    if (Math.abs(count - 0) === 1) {
      return pluralForms[0];
    } else {
      return pluralForms[1];
    }
  };

  return {
    simple(count, singularWord, pluralWord = singularWord + 's') {
      if ((count === null) || (count === undefined)) {
        return '';
      } else {
        return `${count} ${pluralize(count, [singularWord, pluralWord])}`;
      }
    },

    pluralize(count, singularWord, pluralWord = singularWord + 's') {
      return pluralize(count, [singularWord, pluralWord]);
    }
  };
});
