const dependencies = [];

const employeeNotificationFilterType = function() {

  const ALL = 1;
  const NEW = 2;

  const names = {
    [ALL]: 'All',
    [NEW]: 'New'
  };

  return {

    getNames() { return names; },

    getDefault() { return NEW; },

    getAll() { return ALL; },

    shouldShow(selectedFilter, isRead) {
      return (selectedFilter === ALL) || !isRead;
    }

  };
};

angular.module('data.employee')
  .service('employeeNotificationFilterType', dependencies.concat(employeeNotificationFilterType));
