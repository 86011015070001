/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = [
  '$state', '$scope', 'EmployeeNotificationsService', 'employeeNotificationTranslator',
  'headerNotificationsObservable', 'employeeNotificationType', 'employeeNotificationFilterType',
  'cableService', 'employeeInvoiceRedirectService', 'employeeSiblingProfileService'
];

const EmployeeHeaderNotificationsListController = function(
  $state, $scope, EmployeeNotificationsService, employeeNotificationTranslator,
  headerNotificationsObservable, employeeNotificationType, employeeNotificationFilterType,
  cableService, employeeInvoiceRedirectService, employeeSiblingProfileService
) {

  const vm = this;

  vm.$onInit = function() {
    vm.service = new EmployeeNotificationsService(
      employeeNotificationFilterType,
      headerNotificationsObservable,
      employeeNotificationTranslator,
      vm.employee
    );
    vm.filterNames = vm.service.getFilterNames();
    vm.selectedFilter = vm.service.getSelectedFilter();
    $scope.$watch('notificationsCtrl.selectedFilter', selectedFilter => vm.service.selectFilter(selectedFilter));
    initCableService();
  };

  vm.toggleNotifications = function() {
    vm.notificationsShown = !vm.notificationsShown;
    setStyle(vm.notificationsShown);
  };

  vm.closeNotifications = function() {
    vm.notificationsShown = false;
    setStyle();
  };

  vm.markReadOnHover = function(notification) {
    if (notification.isActionable) { return; }
    if (notification.object.isRead) { return; }
    return vm.service.markRead(notification);
  };

  vm.goToNotification = function(notification) {
    if (employeeNotificationType.isInvoiceRelated(notification.object.type)) {
      return employeeInvoiceRedirectService.gotoEmployeeInvoiceList(notification.goToInvoiceParams);
    } else if (employeeNotificationType.getLicenseExpiration() === notification.object.type) {
      return $state.go('public.employee.profile.licenses');
    } else if ($state.includes('public.employee.timeline')) {
      return headerNotificationsObservable.triggerEvent('go-to-notification', notification);
    } else {
      return $state.go('public.employee.timeline', { goToDate: notification.startDay });
    }
  };

  const setStyle = function(notificationsShown) {
    if (notificationsShown) {
      vm.style = { display: 'block' };
    } else {
      vm.style = '';
    }
  };

  const initCableService = function() {
    employeeSiblingProfileService.getSiblingProfiles().then(function(employees) {
      employees.forEach(function(employee) {
        cableService.createEmployeeNotificationsChannel(employee.id);
        const channel = cableService.getEmployeeNotificationsChannel(employee.id);
        channel.addCallback('notification-created', notification => vm.service.notificationCreated(notification));
        channel.addCallback('notification-destroyed', notification => vm.service.notificationDestroyed(notification));
        channel.addCallback('notification-read', notification => vm.service.notificationRead(notification));
        cableService.getEmployeeTimelineChannel(employee.id).addCallback('shift-employee', shiftEmployee => {
          vm.service.updateNotificationShiftEmployee(shiftEmployee);
        });
        const onDestroy = function() {
          cableService.destroyEmployeeNotificationsChannel(employee.id);
        };
        window.onunload = onDestroy;
        $scope.$on('$destroy', onDestroy);
      });
    });
  };

  return vm;
};

angular.module('public.employee').controller('EmployeeHeaderNotificationsListController',
  dependencies.concat(EmployeeHeaderNotificationsListController));
