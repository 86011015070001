/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('gccs-ui').config(['tagsInputConfigProvider', tagsInputConfigProvider => {

  tagsInputConfigProvider
    .setDefaults('tagsInput', {
      keyProperty: 'id',
      displayProperty: 'name',
      allowLeftoverText: true,
      addFromAutocompleteOnly: true,
      replaceSpacesWithDashes: false,
      minLength: 1
    })
    .setDefaults('autoComplete', {
      minLength: 1,
      loadOnEmpty: true,
      loadOnFocus: true
    });

}]);
