const gccsLiveFeedItem = {
  controller: 'LiveFeedItemController',
  template: require('./template.pug'),
  bindings: {
    dShiftIssue: '<',
    isUrgent: '<',
    onShiftIssueToggle: '&',
    shiftSelector: '<'
  }
};

angular.module('public.security-manager.schedule-manager.timeline')
  .component('gccsLiveFeedItem', gccsLiveFeedItem);
