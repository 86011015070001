/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$state', 'currentUserService', 'validationService', 'redirectService'
];

const ManagerProfileLayoutController = function(
  $scope, $state, currentUserService, validationService, redirectService
) {

  const vm = this;

  vm.tabs = [{
    name: 'Personal details',
    sref: 'public.client-manager.profile.personal-details'
  }];
  vm.validationService = validationService;

  let isFormSubmitted = false;

  vm.$onInit = function() {
    initUser();
  };

  vm.onFormSubmit = function() {
    if (!$scope.ManagerProfileForm.$valid || isFormSubmitted) { return; }
    isFormSubmitted = true;
    return $scope.$broadcast('manager.profile.submit');
  };

  vm.redirectToMain = () => redirectService.redirectToMain();

  vm.resetForm = function() {
    vm.resetFormSubmitted();
    $scope.ManagerProfileForm.$setPristine();
    $scope.ManagerProfileForm.$setUntouched();
    return initUser();
  };

  vm.updateUser = user => {
    currentUserService.updateCurrentUser(user);
    vm.resetForm();
  };

  vm.resetFormSubmitted = () => isFormSubmitted = false;

  const initUser = function() {
    vm.user = angular.copy(currentUserService.getCurrentUser());
  };

  $scope.$on('$stateChangeSuccess', function() {
    const activeTab = _.find(vm.tabs, tab => $state.is(tab.sref));
    vm.title = activeTab.name;
    return vm.resetForm();
  });

  return vm;

};

angular.module('public.client-manager.profile')
  .controller('ManagerProfileLayoutController', dependencies.concat(ManagerProfileLayoutController));
