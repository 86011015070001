const dependencies = ['AppModel'];

const BookingShiftEmployeeRoot = AppModel => {

  class BookingShiftEmployeeRoot extends AppModel {
    static initClass() {
      this.configure({ url: '/shift_employees/{{id}}', name: 'shiftEmployee' });
    }

    static bulkStatusChange(actions) {
      return this.$post(this.resourceUrl() + '/bulk_status_change', actions);
    }

    approveShiftCancellation() {
      return this.$post(this.getUrl() + '/approve_shift_cancellation');
    }

    rejectShiftCancellation() {
      return this.$post(this.getUrl() + '/reject_shift_cancellation');
    }

    clockIn() {
      return this.$put(this.getUrl() + '/clock_in');
    }

    clockOut() {
      return this.$put(this.getUrl() + '/clock_out');
    }

    checkIn() {
      return this.$put(this.getUrl() + '/check_in');
    }
  }

  BookingShiftEmployeeRoot.initClass();

  return BookingShiftEmployeeRoot;

};

angular.module('data.booking').factory('BookingShiftEmployeeRoot', dependencies.concat(BookingShiftEmployeeRoot));
