/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('data.employee-invoice').factory('EmployeeInvoiceShiftComment', ['AppModel', function(AppModel) {

  class EmployeeInvoiceShiftComment extends AppModel {
    static initClass() {
      this.configure({url : '/employee_invoices/{{employeeInvoiceId}}/versions/{{versionId}}/comments/{{id}}', name : 'comment'});
    }
  }

  EmployeeInvoiceShiftComment.initClass();
  return EmployeeInvoiceShiftComment;
}
]);
