/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [];

const CheckboxSelectController = function() {

  const vm = this;

  vm.isShownSelect = false;

  let selectedListItems = [];

  vm.$onInit = () => selectedListItems = _.clone(vm.selectedListItems) || [];

  vm.getTitle = function() {
    if (selectedListItems.length > 0) {
      return `${vm.selectedTitle} (${selectedListItems.length})`;
    } else {
      return vm.selectTitle;
    }
  };

  vm.toggleSelect = () => vm.isShownSelect = !vm.isShownSelect;

  vm.hideSelect = () => vm.isShownSelect = false;

  vm.isChecked = key => selectedListItems.indexOf(key) !== -1;

  vm.check = function(key) {
    const onSelect = function() {
      const foundIndex = selectedListItems.indexOf(key);
      if (foundIndex === -1) {
        selectedListItems.push(key);
      } else {
        selectedListItems.splice(foundIndex, 1);
      }
      if (vm.onSelect) { return vm.onSelect({selected : selectedListItems}); }
    };
    if (vm.beforeSelectCb) {
      return vm.beforeSelectCb({onSuccessCb : onSelect});
    } else {
      return onSelect();
    }
  };

  vm.getFilteredItems = function() {
    if (vm.searchString) {
      return _.filter(vm.selectListItems, selectItem => selectItem.text.toLowerCase().indexOf(vm.searchString.toLowerCase()) !== -1);
    } else {
      return vm.selectListItems;
    }
  };

  return vm;
};

angular.module('gccs-ui').controller('CheckboxSelectController', dependencies.concat(CheckboxSelectController));
