angular.module('data.booking').factory('BookingShiftEmployee', ['AppModel', (AppModel) => {

  class BookingShiftEmployee extends AppModel {
    static initClass() {
      this.configure({
        url: '/bookings/{{bookingId}}/shifts/{{shiftId}}/employees/{{id}}',
        name: 'employee',
        rootWrapping: false,
      });
    }

    unassign() {
      return this.$delete(this.getUrl() + '/unassign');
    }

  }

  BookingShiftEmployee.initClass();

  return BookingShiftEmployee;

}]);
