/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', '$state', 'TimeLineBookingLoader', 'TimeLineEmployeeLoader', 'LiveFeedLoader',
  'localStorageService', 'cableService', 'BookingTimelineShiftSelector', 'ShiftStatistics',
  'currentUserService', 'confirmService', 'packageTypeService'
];

const ScheduleManagerTimelineLayoutController = function(
  $scope, $state, TimeLineBookingLoader, TimeLineEmployeeLoader, LiveFeedLoader,
  localStorageService, cableService, BookingTimelineShiftSelector, ShiftStatistics,
  currentUserService, confirmService, packageTypeService
) {

  const vm = this;

  const TYPE_BOOKING = 1;
  const TYPE_EMPLOYEE = 2;
  const TYPE_LIVE_FEED = 3;

  let timelineType = undefined;

  vm.timeLineBookingLoader = new TimeLineBookingLoader();
  vm.timeLineEmployeeLoader = new TimeLineEmployeeLoader();
  vm.liveFeedLoader = new LiveFeedLoader();
  vm.shiftStatistics = new ShiftStatistics();
  vm.bookingTimelineShiftSelector = new BookingTimelineShiftSelector();
  vm.bookingTimelineShiftSelector.setShiftStatisticsService(vm.shiftStatistics);
  vm.liveFeedShiftSelector = new BookingTimelineShiftSelector();
  vm.liveFeedLoader.setShiftSelector(vm.liveFeedShiftSelector);
  vm.securityManager = currentUserService.getCurrentProfile();
  vm.isOpenedToggleButton = false;

  vm.isBookingTimeline = function () {
    return timelineType === TYPE_BOOKING;
  };

  vm.isEmployeeTimeline = function () {
    return timelineType === TYPE_EMPLOYEE;
  };

  vm.isLiveFeed = function () {
    return timelineType === TYPE_LIVE_FEED;
  };

  vm.addNewBooking = function() {
    localStorageService.remove('bookingId');
    $state.go('public.security-manager.schedule-manager.booking-form.details');
  };

  vm.canCreateBooking = function () {
    return !vm.securityManager.isSupervisor();
  };

  vm.broadcastFilterParams = function (queryParams) {
    $scope.$broadcast('filterQueryParamsChanged', queryParams);
  };

  vm.updateFilterString = function (employeeFilter) {
    vm.employeeFilter = employeeFilter;
  };

  vm.onBookingTimelineFilter = function(queryParams, isFirstApply) {
    vm.broadcastFilterParams(_.omit(queryParams, 'client_ids[]'));
    if (!isFirstApply) { vm.timeLineBookingLoader.selectClients(queryParams['client_ids[]'] || []); }
    return vm.bookingTimelineShiftSelector.multipleDeselectAll();
  };

  vm.canGoToPlanningMode = function () {
    return !(currentUserService.isClientManagerLogged() || __guard__(currentUserService.getCurrentProfile(), x => x.isSupervisor()) || !packageTypeService.hasProPackage());
  };

  vm.notifyShiftSelectionClear = function(onSuccessCb) {
    if (vm.bookingTimelineShiftSelector.hasSelectedShifts()) {
      return confirmService.confirm('Client change', 'Shift selection will be cleared', 'Yes', function() {
        vm.bookingTimelineShiftSelector.multipleDeselectAll();
        return onSuccessCb();
      });
    } else {
      return onSuccessCb();
    }
  };

  vm.toggleToggleButton = function() {
    vm.isOpenedToggleButton = !vm.isOpenedToggleButton;
  };

  vm.closeToggleButton = function() {
    vm.isOpenedToggleButton = false;
  };

  vm.addNewRepeatableBooking = function() {
    localStorageService.remove('repeatableBookingId');
    $state.go('public.security-manager.schedule-manager.repeatable-booking.form');
  };

  vm.onLiveFeedFilter = function(queryParams) {
    vm.liveFeedLoader.updateSearchParams(_.isEmpty(queryParams) ? { 'client_ids[]': [] } : queryParams);
  };

  const initTimelineType = function () {
    if ($state.is('public.security-manager.schedule-manager.timeline.booking')) {
      timelineType = TYPE_BOOKING;
    } else if ($state.is('public.security-manager.schedule-manager.timeline.employee')) {
      timelineType = TYPE_EMPLOYEE;
    } else {
      timelineType = TYPE_LIVE_FEED;
    }
  };

  initTimelineType();

  cableService.createBookingTimelineChannel(currentUserService.getCurrentProfile().securityProvider.id);

  $scope.$on('$destroy', cableService.destroyBookingTimelineChannel);
  $scope.$on('$stateChangeSuccess', initTimelineType);

  window.onunload = cableService.destroyBookingTimelineChannel;

  return vm;

};

angular
  .module('public.security-manager.schedule-manager.timeline')
  .controller('ScheduleManagerTimelineLayoutController',
    dependencies.concat(ScheduleManagerTimelineLayoutController));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
