const dependencies = [
  '$scope', '$element', 'Booking', 'BookingSeries', 'BookingShift', 'FlexibleTimeLineEmployeeInteractor',
  'EmployeeTimelineShiftDecorator', 'DayOffDecorator', 'EmployeeTimelineAvailabilityDecorator', 'SickDayDecorator',
  'elementService', 'dateService', 'ShiftEditModeService', 'bookingShiftEditMode', 'EntityManager',
  '$window', '$timeout', 'ShiftDecorator', 'currentUserService', '$stateParams', 'bookingRepeatable',
  'EmployeeEventOptions', 'employeeEventType', 'bookingShiftEmployeeAssignStatus', 'pluralizeService'
];

const ScheduleManagerTimelineEmployeeController = function(
  $scope, $element, Booking, BookingSeries, BookingShift, FlexibleTimeLineEmployeeInteractor,
  EmployeeTimelineShiftDecorator, DayOffDecorator, EmployeeTimelineAvailabilityDecorator, SickDayDecorator,
  elementService, dateService, ShiftEditModeService, bookingShiftEditMode, EntityManager,
  $window, $timeout, ShiftDecorator, currentUserService, $stateParams, bookingRepeatable,
  EmployeeEventOptions, employeeEventType, bookingShiftEmployeeAssignStatus, pluralizeService
) {

  const vm = this;

  const CURRENT_USER_ID = currentUserService.getCurrentUser().id;
  const SHIFT_POPUP_WIDTH = 265;
  const ROW_HEIGHT = 40;
  const EMPLOYEE_PADDING = 10;
  const DAY_OFF_POPUP_FIRST_ROW_HEIGHT = 51;
  const DAY_OFF_POPUP_WIDTH = 280;
  const DAY_OFF_POPUP_MARGIN = 15;
  const LEFT_PADDING = 50;
  const FORM_POPUP_WIDTH = 400;

  const shiftManager = new EntityManager(BookingShift);

  // reset employee timeline search filed
  $scope.scheduleManagerTimelineLayoutCtrl.employeeFilter = '';

  vm.isShownShiftTimelinePopup = false;
  vm.isShownShiftFormPopup = false;
  vm.isShownEmployeeInvite = false;
  vm.isShownEventPopup = false;
  vm.shiftTimelinePopupPosition = {};
  vm.shiftFormPopupPosition = {};
  vm.dayOffPopupPosition = {};
  vm.editModeService = ShiftEditModeService();

  vm.$onInit = function() {
    $timeout(function() {
      vm.timeLineInteractor = new FlexibleTimeLineEmployeeInteractor(
        $scope.scheduleManagerTimelineLayoutCtrl.timeLineEmployeeLoader,
        $element[0].querySelector('.timeline-scroll-area'),
        $stateParams.goToDate,
        $element[0]
      );
      vm.calendarDate = vm.timeLineInteractor.from.clone();
      setHourWidthOnResize();
    });
    vm.editable = !currentUserService.getCurrentProfile().isSupervisor();
    initOptions();
  };

  vm.showDayOffPopup = function($event, employee) {
    if (vm.timeLineInteractor.timeLineLoader.popupDayOff) { return; }
    const startTime = getStartTimeForClick($event).startOf('day');
    const endTime = startTime.clone().endOf('day');
    vm.timeLineInteractor.timeLineLoader.addTempDayOff(startTime, endTime, employee);
  };

  vm.toggleShowDayOffPopup = function () {
    vm.isShownEventPopup = true;
  };

  vm.showDayOffEditPopup = function($event, employee, dayOff, element) {
    vm.timeLineInteractor.timeLineLoader.editDayOff(employee, dayOff);
    const dayOffElement = element || $event.target.closest('[ng-click]');
    updateEditDayOffPopupPosition(dayOffElement);
    vm.eventOptions.setSelectedOption(employeeEventType.getDayOffEventType());
    vm.isShownEventPopup = true;
  };

  vm.showSickDayEditPopup = function($event, employee, sickDay, element) {
    vm.timeLineInteractor.timeLineLoader.editDayOff(employee, sickDay);
    const sickDayElement = element || $event.target.closest('[ng-click]');
    updateEditDayOffPopupPosition(sickDayElement);
    vm.eventOptions.setSelectedOption(employeeEventType.getSickDayEventType());
    vm.isShownEventPopup = true;
  };

  vm.showAvailabilityEditPopup = function($event, employee, availability, element) {
    vm.timeLineInteractor.timeLineLoader.editDayOff(employee, availability);
    const availabilityElement = element || $event.target.closest('[ng-click]');
    updateEditDayOffPopupPosition(availabilityElement);
    vm.eventOptions.setSelectedOption(employeeEventType.getAvailabilityEventType());
    vm.isShownEventPopup = true;
  };

  vm.toggleCalendar = function() {
    if (vm.isShownCalendar) {
      vm.hideCalendar();
    } else {
      vm.calendarDate = vm.timeLineInteractor.from.clone();
      vm.isShownCalendar = true;
    }
  };

  vm.isHighlighted = function (dShift) {
    return currentUserService.isSecurityManagerLogged() && dShift.shift.isHighlighted;
  };

  vm.hideCalendar = function () {
    vm.isShownCalendar = false;
  };

  vm.updateDayOff = function($event) {
    vm.isShownEventPopup = false;
    vm.eventOptions.resetSelectedOption();
    vm.timeLineInteractor.timeLineLoader.updateDayOff($event);
  };

  vm.deleteDayOff = function($event) {
    vm.isShownEventPopup = false;
    vm.eventOptions.resetSelectedOption();
    vm.timeLineInteractor.timeLineLoader.deleteDayOff($event);
  };

  vm.closeDayOffPopupAndRefresh = function() {
    vm.eventOptions.resetSelectedOption();
    vm.isShownEventPopup = false;
    vm.timeLineInteractor.timeLineLoader.closeDayOffPopupAndRefresh();
  };

  vm.showShiftTimelinePopup = function($event, shiftDecorator, employee) {
    if (vm.getLockedBy(shiftDecorator)) { return; }
    vm.popupData = [];
    let popupData = {};
    popupData.popupEmployee = employee;
    Booking.get(shiftDecorator.shift.bookingId).then(function(booking) {
      popupData.popupDateRange = [
        moment(booking.from, dateService.getIsoDateFormat()),
        moment(booking.to, dateService.getIsoDateFormat()).add(1, 'days')
      ];
      popupData.popupBooking = booking;
      popupData.popupShiftDecorator = shiftDecorator;
      updateShiftPosition(popupData, $event.target.closest('[ng-click]'));
      vm.isShownShiftTimelinePopup = true;
      vm.popupData = [popupData];
    });
  };

  vm.showShiftsTimelinePopup = function($event, shiftDecorator, employee) {
    vm.popupData = [];
    shiftDecorator.subItems.forEach(function(dShift, index) {
      let popupData = {};
      popupData.popupEmployee = employee;
      Booking.get(dShift.shift.bookingId).then(function(booking) {
        popupData.popupDateRange = [
          moment(booking.from, dateService.getIsoDateFormat()),
          moment(booking.to, dateService.getIsoDateFormat()).add(1, 'days')
        ];
        popupData.popupBooking = booking;
        popupData.popupShiftDecorator = dShift;
        updateShiftPosition(popupData, $event.target.closest('[ng-click]'), index);
        vm.isShownShiftTimelinePopup = true;
        vm.popupData.push(popupData);
      });
    });
  };

  vm.closeShiftTimelinePopup = function () {
    vm.isShownShiftTimelinePopup = false;
  };

  vm.showShiftFormPopup = function(popupData) {
    if (vm.getLockedBy(popupData.popupShiftDecorator)) { return; }
    loadBookingShiftForFormPopup(popupData).then(function () {
      vm.popupShiftDecorator = popupData.popupShiftDecorator;
      vm.popupBooking = popupData.popupBooking;
      vm.popupEmployee = popupData.popupEmployee;
      vm.shiftFormPopupPosition = popupData.shiftFormPopupPosition;
      vm.popupDateRange = popupData.popupDateRange;
      vm.isShownShiftFormPopup = true;
    });
  };

  vm.showShiftFormInvitePopup = function(popupData) {
    if (vm.getLockedBy(popupData.popupShiftDecorator)) { return; }
    loadBookingShiftForFormPopup(popupData).then(function() {
      vm.popupShiftDecorator = popupData.popupShiftDecorator;
      vm.popupBooking = popupData.popupBooking;
      vm.popupEmployee = popupData.popupEmployee;
      vm.shiftFormPopupPosition = popupData.shiftFormPopupPosition;
      vm.popupDateRange = popupData.popupDateRange;
      vm.isShownEmployeeInvite = true;
      vm.activeShiftTab = 'employees';
      vm.isShownShiftFormPopup = true;
    });
  };

  vm.closeShiftFormPopup = () => closeShiftFormPopup();

  vm.saveShift = function (shift, afterSaveCb) {
    getShiftCount(shift).then(function (shiftCount) {
      updateShift(shiftCount.count, shift, function () {
        shift.save().then(() => afterSaveCb());
      });
    });
  };

  vm.removeShift = function (shift, afterRemoveCb) {
    getShiftCount(shift).then(function (shiftCount) {
      updateShift(shiftCount.count, shift, function () {
        shiftManager.removeEntity(shift, function () {
          if (afterRemoveCb) {
            afterRemoveCb();
          }
          vm.closeShiftFormPopup();
          // TODO: this should be removed when destroy updates will be sent from BE
          _.each(shift.employeesShifts, function (shiftEmployee) {
            vm.timeLineInteractor.timeLineLoader.deleteShiftEmployee(shiftEmployee);
          });
        }, { editMode: shift.editMode });
      });
    });
  };

  vm.getShiftStyle = function (shiftDecorator) {
    if (!vm.timeLineInteractor) { return; }
    return _.merge(vm.timeLineInteractor.getShiftDimensions(shiftDecorator), {
      top: ((shiftDecorator.row - 1) * ROW_HEIGHT) + 'px'
    });
  };

  vm.getIntersectionStyle = function (shiftDecorator, intersection) {
    if (!vm.timeLineInteractor) { return; }
    return {
      left: vm.timeLineInteractor.getWidthOfDiff(intersection.from, shiftDecorator.timelineStartTime) + 'px',
      width: vm.timeLineInteractor.getWidthOfDiff(intersection.to, intersection.from) + 'px'
    };
  };

  vm.getEmployeeDimensions = function (employee) {
    if (!vm.timeLineInteractor) { return; }
    const rowsCount = vm.timeLineInteractor.isMonthView() ? employee.monthRowsCount : employee.rowsCount;
    return {
      height: (rowsCount * ROW_HEIGHT) + EMPLOYEE_PADDING + 'px'
    };
  };

  vm.getMonthShiftText = function(dShift) {
    return pluralizeService.simple(dShift.subItems.length, 'shift');
  };

  vm.getLockedBy = function(dShift) {
    if (dShift.shift.lockedBy && (dShift.shift.lockedBy.id !== CURRENT_USER_ID)) {
      return dShift.shift.lockedBy;
    } else {
      return false;
    }
  };

  vm.getDShifts = function(employee) {
    return _.filter(vm.getTimelineItems(employee), function(timelineItem) {
      return timelineItem instanceof EmployeeTimelineShiftDecorator;
    });
  };

  vm.getDDayOffs = function(employee) {
    return _.filter(vm.getTimelineItems(employee), function(timelineItem) {
      return timelineItem instanceof DayOffDecorator && !timelineItem.isTemporary;
    });
  };

  vm.getDSickDays = function(employee) {
    return _.filter(vm.getTimelineItems(employee), function(timelineItem) {
      return timelineItem instanceof SickDayDecorator;
    });
  };

  vm.getDAvailabilities = function(employee) {
    return _.filter(vm.getTimelineItems(employee), function(timelineItem) {
      return timelineItem instanceof EmployeeTimelineAvailabilityDecorator;
    });
  };

  vm.getTimelineItems = function(employee) {
    if (!vm.timeLineInteractor) { return []; }
    if (vm.timeLineInteractor.isMonthView()) {
      return employee.monthTimelineItems;
    } else {
      return employee.timelineItems;
    }
  }

  vm.updateNewEventPopupPosition = function(rect) {
    let left;
    const top = (rect.top - DAY_OFF_POPUP_FIRST_ROW_HEIGHT);
    if ((rect.right + DAY_OFF_POPUP_WIDTH) > window.innerWidth) {
      left = rect.left - DAY_OFF_POPUP_WIDTH - DAY_OFF_POPUP_MARGIN;
    } else {
      left = rect.right;
    }
    vm.dayOffPopupPosition = {
      left: left + 'px',
      top: top + 'px'
    };
  };

  vm.isBlowOut = function(dShift) {
    return bookingShiftEmployeeAssignStatus.isBlowOut(dShift.assignStatus);
  };

  vm.getSickDayClass = function(dSickDay) {
    let sickDayClass = dSickDay.getSickDayRequestStatus().class;
    if (dSickDay.timelineEndTime.diff(dSickDay.timelineStartTime, 'days') === 1) {
      sickDayClass += ' mod-one-day';
    }
    return sickDayClass;
  };

  vm.getDayOffRequestClass = function(dDayOff) {
    let dayOffClass = dDayOff.getDayOffRequestStatus().class;
    if (dDayOff.timelineEndTime.diff(dDayOff.timelineStartTime, 'days') === 1) {
      dayOffClass += ' mod-one-day';
    }
    return dayOffClass;
  };

  const initOptions = function() {
    vm.eventOptions = new EmployeeEventOptions();
  };

  const getShiftCount = function (shift) {
    return BookingShift.getCount({ bookingId: shift.bookingId }, { bookingSeriesId: shift.bookingSeriesId });
  };

  const loadBookingShiftForFormPopup = function(popupData) {
    return BookingShift.get({
      bookingId: popupData.popupShiftDecorator.shift.bookingId,
      id: popupData.popupShiftDecorator.shift.id
    }).then(function(shift) {
      popupData.popupShiftDecorator = new ShiftDecorator(shift);
      vm.closeShiftTimelinePopup();
    });
  };

  const getStartTimeForClick = function($event) {
    const offset = $event.clientX;
    return vm.timeLineInteractor.getTimeFromOffset(offset);
  };

  const closeShiftFormPopup = function() {
    vm.isShownShiftFormPopup = false;
    vm.popupBooking = undefined;
    vm.popupShiftDecorator = undefined;
    vm.popupDateRange = undefined;
    vm.popupEmployee = undefined;
  };

  const updateShift = function(countInSeries, shift, cb) {
    if (countInSeries > 1) {
      vm.editModeService.show(shift, cb);
    } else {
      if (bookingRepeatable.isNone(shift.repeatable)) {
        shift.editMode = bookingShiftEditMode.getOnlyCurrentMode();
      } else {
        shift.editMode = bookingShiftEditMode.getFollowingMode();
      }
      cb();
    }
  };

  const updateShiftPosition = function(popupData, shiftElement, index = 0) {
    let left = elementService.getOffsetLeft(shiftElement);
    const top = elementService.getOffsetTop(shiftElement);

    if (left > (window.innerWidth - SHIFT_POPUP_WIDTH)) {
      left = window.innerWidth - SHIFT_POPUP_WIDTH;
    }
    if (left < LEFT_PADDING) {
      left = LEFT_PADDING;
    }
    const formPopupLeft = left + shiftElement.offsetWidth;
    const rightDiff = Math.min(0, window.innerWidth - (FORM_POPUP_WIDTH + formPopupLeft));
    popupData.shiftTimelinePopupPosition = { left, top: top + index * 200 }; // todo calculate top correctly
    popupData.shiftFormPopupPosition = {
      left: formPopupLeft + rightDiff,
      top: (top + (shiftElement.offsetHeight / 2)) - 5
    };
  };

  const updateEditDayOffPopupPosition = function(dayOffElement) {
    let left = elementService.getOffsetLeft(dayOffElement) - DAY_OFF_POPUP_MARGIN;
    const top = elementService.getOffsetTop(dayOffElement);
    if ((left + DAY_OFF_POPUP_WIDTH) > window.innerWidth) {
      left = window.innerWidth - DAY_OFF_POPUP_WIDTH;
    }
    if (left < LEFT_PADDING) {
      left = LEFT_PADDING - DAY_OFF_POPUP_MARGIN;
    }
    vm.dayOffPopupPosition = {
      left: left + 'px',
      top: top + 'px'
    };
  };

  const setHourWidthOnResize = function () {
    if (!vm.timeLineInteractor) { return; }
    $timeout(function () {
      let itemWidth = $element[0].querySelector('.interval-day-item').getBoundingClientRect().width;
      if (vm.timeLineInteractor.isMonthView()) {
        itemWidth /= 4;
      }
      return vm.timeLineInteractor.setHourWidth(itemWidth);
    });
  };

  $window.addEventListener('resize', setHourWidthOnResize);

  $scope.$on('$destroy', () => $window.removeEventListener('resize', setHourWidthOnResize));

  $scope.$on('setDate', function(model, date, view, isArrowClick) {
    if ((view === 'date') && !isArrowClick) {
      vm.hideCalendar();
      vm.timeLineInteractor.scrollTo(date);
    }
  });

  $scope.$on('filterQueryParamsChanged', function(event, queryParams) {
    $scope.scheduleManagerTimelineLayoutCtrl.timeLineEmployeeLoader.setQueryParams(queryParams);
    vm.timeLineInteractor.loadDataAndSetView();
  });

  setHourWidthOnResize();

  return vm;

};

angular
  .module('public.security-manager.schedule-manager.timeline')
  .controller('ScheduleManagerTimelineEmployeeController',
    dependencies.concat(ScheduleManagerTimelineEmployeeController));
