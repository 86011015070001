/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'FileAttachment', 'Upload', 'sessionService', 'notificationService', 'downloadService'
];

const PopupNoteFormController = function(
  $scope, FileAttachment, Upload, sessionService, notificationService, downloadService
) {

  const vm = this;

  vm.removeAttachment = attachment => _.remove(vm.note.attachments, attachment);

  vm.removeNote = function() {
    $scope.onRemoveCb({
      note: $scope.note,
      onSuccessCb: () => {
        $scope.popupCtrl.closePopup(function() {
          $scope.popupCloseCb();
        });
      }
    });
  };

  vm.downloadAttachment = function(attachment) {
    downloadService.downloadWithoutCache(attachment.file, attachment.fileName);
  };

  const initNote = function() {
    if ($scope.note.isNew()) {
      vm.note = $scope.note;
      vm.isShownRemoveButton = false;
      $scope.popupFormCtrl.title = 'Add new note';
    } else {
      vm.note = angular.copy($scope.note);
      vm.isShownRemoveButton = true;
      $scope.popupFormCtrl.title = 'Edit note';
    }
  };

  const addAttachment = function(attachmentFile) {
    const attachment = new FileAttachment({
      fileName: attachmentFile.name,
      fileSize: attachmentFile.size
    });
    if (!vm.note.attachments) { vm.note.attachments = []; }
    vm.note.attachments.push(attachment);
    Upload.upload({
      url: attachment.getUrl(),
      data: {
        attachment: {
          file: attachmentFile
        }
      },
      headers: {
        'X-Session-Id': sessionService.getSessionId()
      }
    }).then((response => {
      _.merge(attachment, response.data);
    }), (() => {
      Rollbar.log(`Note attachment upload on ${window.location.href}`);
    }), e => {
      attachment.progress = parseInt((100.0 * e.loaded) / e.total);
    }).finally(() => attachment.isFinished = true);
  };

  const getForm = () => $scope[$scope.popupFormCtrl.formName];

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      initNote();
    }
  });

  $scope.$on('popup.submit', function() {
    vm.note.save().then(function(note) {
      $scope.popupCtrl.closePopup(function() {
        $scope.popupCloseCb({ note });
      });
    });
  });

  $scope.$on('popup.error-submit', () => notificationService.notifyError('Please enter all fields'));

  $scope.$watch('attachment', function() {
    if ($scope.attachment) {
      addAttachment($scope.attachment);
    } else if (getForm().$error.maxSize) { // todo extensions
      notificationService.notifyError('You can upload only 25 mb');
    }
  });

  return vm;

};

angular.module('popup.note')
  .controller('PopupNoteFormController', dependencies.concat(PopupNoteFormController));
