/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  'currentUserService', 'redirectService'
];

const ProfileNotificationSettingsController = function(
  currentUserService, redirectService
) {

  const vm = this;

  vm.$onInit = function() {
    checkAccessLevel();
    initSecurityManager();
  };

  const initSecurityManager = function() {
    vm.securityManagerId = currentUserService.isSecurityManagerLogged() && currentUserService.getCurrentProfile().id;
  };

  const checkAccessLevel = function() {
    if (currentUserService.getCurrentProfile().isSupervisor()) {
      redirectService.redirectToMain();
    }
  };

  return vm;

};

angular.module('public.security-manager.profile')
  .controller('ProfileNotificationSettingsController', dependencies.concat(ProfileNotificationSettingsController));