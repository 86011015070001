const dependencies = [
  'ClientInvoice', 'dateService', 'calculationService', 'resourceChargeType', 'clientInvoiceElementType'
];

const invoiceCostService = function(
  ClientInvoice, dateService, calculationService, resourceChargeType, clientInvoiceElementType
) {

  const MINUTES_IN_HOUR = 60;

  const roundCost = cost => calculationService.round(cost, 2);

  const getBookingCost = invoiceBooking => {
    return roundCost(_.reduce(invoiceBooking.invoiceElements, (bookingCost, invoiceElement) => {
      return bookingCost + invoiceElement.getCost();
    }, 0));
  };

  const getSubTotal = function(invoice) {
    if (invoice) {
      return roundCost(
        _.reduce(invoice.lastVersion.bookings, (subTotal, invoiceBooking) => {
          return subTotal + getBookingCost(invoiceBooking);
        }, 0)
      );
    } else {
      return 0;
    }
  };

  const getPreVat = (invoice, discountPercentage) => getSubTotal(invoice) - getDiscount(invoice, discountPercentage);

  const getTotalVat = function(invoice, discountPercentage) {
    if (invoice) {
      return roundCost(calculationService.percentValue(getPreVat(invoice, discountPercentage), invoice.lastVersion.vatPercentage));
    } else {
      return 0;
    }
  };

  const getDiscount = (invoice, discountPercentage) => {
    return roundCost(calculationService.percentValue(getSubTotal(invoice), discountPercentage));
  };

  const getTotalCost = (invoice, discountPercentage) => {
    if (invoice) {
      let cost = roundCost(getPreVat(invoice, discountPercentage));
      if (invoice.lastVersion.withVat) {
        cost += getTotalVat(invoice, discountPercentage);
      }
      return cost;
    } else {
      return 0;
    }
  };

  return {
    getAverageCost(element, employees) {
      let elementCost;
      if (element.type === clientInvoiceElementType.getSimpleCost()) {
        elementCost = element.chargeRate;
      } else if (resourceChargeType.isPerHour(element.chargeType)) {
        let totalMinutesDiff = element.endTimeMoment.diff(element.startTimeMoment, 'minutes');
        elementCost = (element.cost * MINUTES_IN_HOUR) / (totalMinutesDiff - (element.unpaidBreakInMinutes || 0));
      } else {
        elementCost = element.cost;
      }
      return roundCost(elementCost / employees.length);
    },

    updateCostOfShift(shift, employees) {
      const shiftData = {
        shiftId: shift.id,
        chargeType: shift.chargeType,
        startTime: shift.startTimeMoment.format(dateService.getIsoFullDateFormat()),
        endTime: shift.endTimeMoment.format(dateService.getIsoFullDateFormat()),
        employees: _.map(employees, shiftEmployee => {
          return {
            id: shiftEmployee.id,
            chargeRate: shiftEmployee.chargeRate
          };
        })
      };
      return ClientInvoice.elementCost(shiftData).then(cost => shift.cost = cost);
    },

    getBookingCost,
    getSubTotal,
    getTotalVat,
    getDiscount,
    getTotalCost
  };
};

angular.module('public.security-manager.client.show.invoice')
  .service('invoiceCostService', dependencies.concat(invoiceCostService));
