/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular
  .module('public.timeline')
  .directive('gccsTimelineBookingFormDetailsComments', () => {
    return {
      restrict: 'A',
      controller: 'TimelineBookingFormDetailsCommentsController',
      controllerAs: 'commentsCtrl',
      template: require('./template.pug'),
      scope: {
        bookingId: '<',
        repeatableBookingId: '<',
        shiftRuleVersion: '<',
        shiftRule: '=',
        isEdit: '<',
        editable: '<'
      }
    };
  });
