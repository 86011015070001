/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.security-manager.hr.employees').directive('gccsEmployeeListHeader', () =>
  ({
    restrict: 'A',
    controller: 'SecurityManagerEmployeesHeaderController',
    controllerAs: 'employeesHeaderCtrl',
    template: require('./template.pug'),
    scope: {
      fullNameOrderParam: '='
    }
  })
);
