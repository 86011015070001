const dependencies = ['$scope', 'downloadService'];

const PopupDocumentViewController = function($scope, downloadService) {

  const vm = this;

  vm.editDocument = function () {
    $scope.popupCtrl.closePopup(function () {
      $scope.popupCloseCb();
      $scope.editCb({ document: vm.document });
    });
  };

  vm.removeDocument = function () {
    $scope.removeCb({
      document: vm.document,
      onSuccessCb() {
        $scope.popupCtrl.closePopup();
      }
    });
  };

  vm.downloadDocument = function() {
    downloadService.downloadWithoutCache(vm.document.file, vm.document.fileName);
  };

  $scope.$on('popup.open', function () {
    vm.document = $scope.document;
  });

  return vm;
};

angular.module('popup.document')
  .controller('PopupDocumentViewController', dependencies.concat(PopupDocumentViewController));
