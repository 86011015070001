/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('form').factory('FormValidator', ['$q', function($q) {

  class FormValidator {

    constructor(formController) {
      this.formController = formController;
    }

    addCustomValidator(fieldName, error, condition, compositeField) {
      return this.formController[fieldName].$validators[error] = (modelValue, viewValue) => {
        const value = modelValue || viewValue;
        const valueData = this._collectValueData(fieldName, value, compositeField);
        if (condition(...Array.from(valueData.values || []))) {
          return false;
        } else {
          this._validateCompositeField(fieldName, valueData);
          if (!value && value !== 0) {
            return true;
          } else {
            return value;
          }
        }
      };
    }

    addCustomAsyncValidator(fieldName, error, asyncValidation, compositeField) {
      return this.formController[fieldName].$asyncValidators[error] = (modelValue, viewValue) => {
        const value = modelValue || viewValue;
        const valueData = this._collectValueData(fieldName, value, compositeField);
        return asyncValidation(...Array.from(valueData.values || [])).then(isValid => {
          if (isValid) {
            this._validateCompositeField(fieldName, valueData);
            return value;
          } else {
            return $q.reject(error);
          }
        });
      };
    }

    _collectValueData(fieldName, value, compositeField) {
      const additionalFields = [];
      const values = _.map(compositeField || [fieldName], field => {
        if (field === fieldName) {
          return value;
        } else {
          additionalFields.push(field);
          if (this.formController[field]) {
            return this.formController[field].$modelValue || this.formController[field].$viewValue;
          }
        }
      });
      return {
        values,
        additionalFields
      };
    }

    _validateCompositeField(fieldName, valueData) {
      this.formController[fieldName]._noValidate = true; // to avoid recursive self validation
      _.each(valueData.additionalFields, additionalField => {
        if ((this.formController[additionalField] != null ? this.formController[additionalField].$invalid : undefined) && !this.formController[additionalField]._noValidate) {
          return this.formController[additionalField].$validate();
        }
      });
      return this.formController[fieldName]._noValidate = false;
    }
  }

  return FormValidator;
}]);
