const dependencies = ['$state', 'RepeatableBooking'];

const PopupRepeatableBookingMessageController = function($state, RepeatableBooking) {

  const vm = this;

  vm.addNewRepeatableBooking = function() {
    vm.gccsPopupsLayout.closePopup({
      onClose() {
        vm.gccsPopupsLayout.popupCloseCb();
        RepeatableBooking.createFrom(vm.repeatableBooking.id).then(function() {
          window.location.href = $state.href('public.security-manager.schedule-manager.repeatable-booking.form');
        });
      }
    });
  };

  return vm;

};

angular.module('public.security-manager')
  .controller('PopupRepeatableBookingMessageController',
    dependencies.concat(PopupRepeatableBookingMessageController)
  );
