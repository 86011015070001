/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').filter('replaceLineBreaks', ['$sce', $sce =>
  function(stringWithEnters) {
    let resultString;
    if (stringWithEnters) {
      resultString = stringWithEnters.replace(/(?:\r\n|\r|\n)/g, '</br>');
    } else {
      resultString = stringWithEnters;
    }
    return $sce.trustAsHtml(resultString);
  }
]);
