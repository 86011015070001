/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
angular.module('support', []).config(['$stateProvider', $stateProvider =>

  $stateProvider
    .state('support', {
      url: '/support',
      controller: 'PublicSupportController',
      controllerAs: 'publicSupportCtrl',
      template: require('./template.pug'),
      resolve: {
        gccsConfig: ['Config', Config => Config.get()]
      }
    })

]);
