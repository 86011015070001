/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.client-manager.booking-form', [
  'data.booking',
  'data.client',
  'data.resource',
  'popup.booking'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.client-manager.booking-form', {
      abstract: true,
      url: '/booking-form?{ bookingId, template }',
      template: require('./template.pug'),
      controller: 'ManagerBookingFormLayoutController',
      controllerAs: 'timelineBookingFormLayoutCtrl',
      params: {
        isCopy: false,
        backState: null
      }
    })
    .state('public.client-manager.booking-form.details', {
      url: '/details',
      template: require('../../timeline/booking-form/details/template.pug'),
      controller: 'TimelineBookingFormDetailsController',
      controllerAs: 'timelineBookingFormDetailsCtrl'
    })
    .state('public.client-manager.booking-form.shifts', {
      url: '/shifts',
      template: require('../../timeline/booking-form/shifts/template.pug'),
      controller: 'TimelineBookingFormShiftsController',
      controllerAs: 'timelineBookingFormShiftsCtrl'
    })
    .state('public.client-manager.booking-form.summary', {
      url: '/summary',
      template: require('../../timeline/booking-form/summary/template.pug'),
      controller: 'TimelineBookingFormSummaryController',
      controllerAs: 'timelineBookingFormSummaryCtrl'
    })

]);
