const gccsShiftRuleItemCheckIn = {
  controller: 'ScheduleManagerShiftRuleCheckInController',
  template: require('./template.pug'),
  bindings: {
    editable: '<',
    version: '<'
  }
};

angular.module('public.security-manager.schedule-manager.repeatable-booking')
  .component('gccsShiftRuleItemCheckIn', gccsShiftRuleItemCheckIn);
