/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$filter', 'Report', 'hoursReportType', 'invoiceStatus', 'employeeInvoiceStatus', 'dateService'
];

const FinanceChartDetailsController = function(
  $scope, $filter, Report, hoursReportType, invoiceStatus, employeeInvoiceStatus, dateService
) {

  const vm = this;

  const DATE_FORMAT_BY_DAY = '%Y-%m-%d';

  vm.detailsDateFormatUrl = d3.timeFormat(DATE_FORMAT_BY_DAY);

  vm.$onInit = function() {
    vm.pointsData.forEach((pointData) => {
      pointData._isRendered = false;
      initDetails(pointData);
    });
  };

  vm.getHeaderStyle = function(element) {
    const style = getComputedStyle(element);
    return {
      'background-color': `${style.stroke}`
    }
  };

  const getDetailsDateFormat = function(date) {
    return moment(date, dateService.getIsoDateFormat()).format(dateService.getDateFormat());
  };

  vm.getChartId = function(index) {
    return `details-chart-${index}`;
  };

  vm.isZeroChargeRateType = function(pointData) {
    if (!pointData) { return; }
    return hoursReportType.isZeroChargeRateType(hoursReportType.getType(pointData.id));
  };

  vm.isPaymentType = function(pointData) {
    if (!pointData) { return; }
    return hoursReportType.isPaymentType(hoursReportType.getType(pointData.id));
  };

  vm.getHoursAndMinutes = function(minutes) {
    if (!_.isNumber(minutes)) { return; }
    let label = '';
    const totalMinutes = minutes % 60;
    const totalHours = (minutes - totalMinutes) / 60;
    if (totalHours) {
      label += `${ $filter('number')(totalHours) }h`;
      if (totalMinutes) {
        label += ` ${totalMinutes}m`;
      }
    } else {
      label += `${totalMinutes}m`
    }
    return label;
  };

  vm.getTotalAmount = function(pointData) {
    return _.reduce(pointData.details, (prevSum, curr) => prevSum + curr.amount, 0);
  };

  vm.getTotalTime = function(pointData) {
    const totalMins = _.reduce(pointData.details, (prevSum, curr) => prevSum + curr.minutes, 0);
    return vm.getHoursAndMinutes(totalMins);
  };

  const initDetails = function(pointData) {
    initDetailsHeader(pointData);
    const urlParams = {
      from: pointData._from,
      to: pointData._to,
      type: hoursReportType.getType(pointData.id)
    };
    const filterParams = _.omitBy(_.pick(vm.filterParams, ['client_status', 'employee_status', 'client_id']), (param) => !param);
    return Report.getBillableHoursDetails(_.assign(urlParams, filterParams)).then(function(details) {
      if (vm.isZeroChargeRateType(pointData)) {
        pointData.details = details;
      } else {
        let getNameCb;
        if (vm.isPaymentType(pointData)) {
          getNameCb = employeeInvoiceStatus.getReportName;
        } else {
          getNameCb = invoiceStatus.getName;
        }
        pointData.details = _.map(details, (value, key) => {
          return {
            status: getNameCb(key),
            minutes: value.minutes,
            amount: value.amount
          }
        });
        const chartData = _.map(details, (value, key) => {
          return [
            getNameCb(key),
            value.amount
          ];
        });
        loadChart(chartData, pointData);
      }
    });
  };

  const initDetailsHeader = function(pointData) {
    let period = getDetailsDateFormat(pointData._from);
    if (pointData._to !== pointData._from) {
      period += ` - ${getDetailsDateFormat(pointData._to)}`;
    }
    vm.detailsPeriod = period;
  };

  const getChartConfig = function(data, pointData) {
    return {
      bindto : `#details-chart-${pointData._index}`,
      data : {
        columns : data,
        type : 'donut',
        colors: getChartColors(pointData),
        onmouseover : pieSliceMouseOverHandler,
        onmouseout : pieSliceMouseOutHandler,
      },
      legend : {
        show : false
      },
      tooltip : {
        show : false
      },
      donut : {
        width: 24,
        label : {
          show : false
        },
        title : ''
      },
      onrendered() {
        pointData._isRendered = true;
        return setTimeout(function() {
          initLegend(vm[`detailsChart${pointData._index}`].data(), vm[`detailsChart${pointData._index}`], pointData._index);
        }, 0);
      }
    };
  };

  const initChart = (data, pointData) => {
    return c3.generate(getChartConfig(data, pointData));
  };

  const loadChart = function(details, pointData) {
    vm[`detailsChart${pointData._index}`] = initChart(details, pointData);
  };

  const getChartColors = function(pointData) {
    if (!vm.isPaymentType(pointData)) {
      return {
        'Created': '#FF4743',
        'Sent': '#F5A623',
        'Paid': '#00B05A',
        'Pending': '#91969c'
      }
    } else {
      return {
        'Created': '#FF4743',
        'Received': '#F5A623',
        'Paid': '#00B05A',
        'Pending': '#91969c',
        'Approved': '#C292F2',
        'Open': '#497BFC',
        'Payment due': '#E436F3'
      }
    }
  };

  var initLegend = function(data, chart, dataPointIndex) {
    return d3.select( `.data-content${dataPointIndex} .billable-hours-chart-details-table-list`)
      .html(() => '')
      .selectAll('billable-hours-chart-details-table-item')
      .data(getChartKeys(data))
      .enter()
      .append('div')
      .attr('data-id', (id, index) => data[index].id)
      .attr('class', 'billable-hours-chart-details-table-item')
      .each(function(id, index) {
        return d3.select(this).html(function() {
          return getLegendHTML(index, data, chart, this, dataPointIndex);
        });}).on('mouseover', (id, index) => chart.focus(data[index].id)).on('mouseout', id => chart.revert());
  };

  const pieSliceMouseOverHandler = function(obj, element) {
    const closestParent = element.closest('.billable-hours-chart-details-content-box');
    const list = closestParent.querySelector('.billable-hours-chart-details-table-list');

    list.classList.add('is-focused');
    const foundLegendItem = list.querySelector(`[data-id='${obj.id}']`);
    if (foundLegendItem) {
      foundLegendItem.classList.add('is-active');
    }
  };

  const pieSliceMouseOutHandler = function(obj, element) {
    const closestParent = element.closest('.billable-hours-chart-details-content-box');
    const list = closestParent.querySelector('.billable-hours-chart-details-table-list');

    list.classList.remove('is-focused');
    const foundLegendItem = list.querySelector(`[data-id='${obj.id}']`);
    if (foundLegendItem) {
      foundLegendItem.classList.remove('is-active');
    }
  };

  var getChartKeys = data => {
    return _.map(data, item => item.id);
  };

  const getLegendHTML = function(index, data, chart, listItem, dataPointIndex) {
    const { id } = data[index];
    return `\
<div class="billable-hours-chart-details-table-box mod-icon" title="${id}">
  <span class="billable-hours-chart-details-table-text">${id}</span>
  <i class="billable-hours-chart-details-table-box-ico" style="background-color: ${chart.color(id)}"></i>
</div>
<div class="billable-hours-chart-details-table-box">
  <span class="billable-hours-chart-details-table-text">${getLegendItemTime(id, dataPointIndex)}</span>
</div>
<div class="billable-hours-chart-details-table-box">
  <span class="billable-hours-chart-details-table-text">${$filter('formatPriceFull')(+chart.data.values(id))}</span>
</div>\
`;
  };

  const getLegendItemTime = function(id, dataPointIndex) {
    const foundDetailsItem = _.find(vm.pointsData[dataPointIndex].details, ['status', id]);
    if (foundDetailsItem) {
      return vm.getHoursAndMinutes(foundDetailsItem.minutes);
    } else {
      return '-';
    }
  };

  return vm;

};

angular.module('public.security-manager.reports.finance')
  .controller('FinanceChartDetailsController', dependencies.concat(FinanceChartDetailsController));
