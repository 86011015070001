angular.module('data.employee').factory('Employee', [
  'AppModel', 'railsSerializer', 'employeeStatus',
  function(AppModel, railsSerializer, employeeStatus) {

    class Employee extends AppModel {
      static initClass() {
        this.configure({
          url : '/employees',
          name : 'employee',
          serializer : railsSerializer(function() {
            this.resource('characterReference', 'EmployeeCharacterReference'); // todo remove and check
            this.resource('employeeResources', 'EmployeeResource');
            this.resource('shift', 'BookingShift');
          })
        });
      }

      static withShifts(from, to, params = {}) {
        return this.$get(this.resourceUrl('with_shifts'), _.merge(params, { from, to }));
      }

      static getWithResources(params) {
        return this.$get(this.resourceUrl('with_resources'), params);
      }

      static getInvoicedDates(employeeId) {
        return this.$get(this.resourceUrl() + '/' + employeeId + '/invoiced_dates');
      }

      static getDatesWithShifts(employeeId, params) {
        return this.$get(this.resourceUrl() + '/' + employeeId + '/dates_with_shifts', params);
      }

      static getHolidaysData(employeeId, params = {}) {
        return this.$get(this.resourceUrl() + '/' + employeeId + '/holidays_data', params);
      }

      static getMinPaidRate(employeeId) {
        return this.$get(this.resourceUrl() + '/' + employeeId + '/min_paid_rate');
      }

      static filterCategories(params = {}) {
        return this.$get(this.resourceUrl('filter_categories'), params);
      }

      static importTemplateUrl() {
        return this.resourceUrl('import_template.csv');
      }

      static importEmployeesUrl() {
        return this.resourceUrl('import');
      }

      static withSecurityProviders(employeeId) {
        return this.$get(this.resourceUrl() + '/' + employeeId + '/with_security_providers');
      }

      static getAvailabilityReport(params = {}) {
        return this.$get(this.resourceUrl('availability_report'), params);
      }

      static exportAvailabilityReport(params = {}) {
        return this.$put(this.resourceUrl('export_availability_report.csv'), params);
      }

      getFullName() {
        return _.filter([this.user.firstName, this.user.lastName]).join(' ');
      }

      isActive() {
        return employeeStatus.isActive(this.status);
      }

      resendInvite() {
        return this.$post(this.getUrl() + '/resend_invite');
      }

    }

    Employee.initClass();
    return Employee;
  }
]);
