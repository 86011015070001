/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['dateService'];

const PlanningShiftDecorator = dateService => {

  const SMALL_SHIFT_HOURS = 4;

  return class PlanningShiftDecorator {

    constructor(shift) {
      this.shift = shift;
      this.startTimeMoment = moment(shift.startTime, dateService.getIsoFullDateFormat());
      this.endTimeMoment = moment(shift.endTime, dateService.getIsoFullDateFormat());
      this.isChecked = false;
    }

    getShiftLengthInHours() {
      return this.shiftLengthInHours || (this.shiftLengthInHours = this.endTimeMoment.diff(this.startTimeMoment, 'hours', true));
    }

    getShiftDurationMoment() {
      return this.shiftDurationMoment || (this.shiftDurationMoment = moment.duration(this.endTimeMoment.diff(this.startTimeMoment)));
    }

    toggleChecked() {
      return this.isChecked = !this.isChecked;
    }

    getBookingFrom() {
      return this.bookingFrom || (this.bookingFrom = moment(this.shift.booking.from, dateService.getIsoDateFormat()));
    }

    getBookingTo() {
      return this.bookingTo || (this.bookingTo = moment(this.shift.booking.to, dateService.getIsoDateFormat()));
    }

    isSmall() {
      return this.getShiftLengthInHours() <= SMALL_SHIFT_HOURS;
    }

  };

};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .factory('PlanningShiftDecorator', dependencies.concat(PlanningShiftDecorator));
