/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const Role = AppModel => {

  class Role extends AppModel {
    static initClass() {
      this.configure({ url: '/roles', name: 'role' });
    }
  }

  Role.initClass();

  return Role;

};

angular.module('data.role').factory('Role', dependencies.concat(Role));
