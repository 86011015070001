/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['pluralizeService'];

const ExtendedListFilterTagsController = function(pluralizeService) {

  const vm = this;

  vm.openTagDropdown = function(tag) {
    if (!tag.options) { return; }
    tag.isOpen = true;
  };

  vm.closeTagDropdown = function(tag) {
    tag.isOpen = false;
  };

  vm.getTagLabel = function(tag) {
    if (tag.options) {
      return tag.label;
    } else {
      return tag.option.label;
    }
  };

  vm.getTagCountText = function(tag) {
    if (tag.category.isFullAccessSet()) {
      return 'All';
    } else {
      return tag.options.length;
    }
  };

  vm.getDropdownCountText = function(tag) {
    if (tag.category.isFullAccessSet()) {
      return `All ${tag.category.label.toLowerCase()}s selected`
    } else {
      return `${pluralizeService.simple(tag.options.length, tag.category.label.toLowerCase())} selected`
    }
  };

  vm.isChildrenOptionsPresent = function(selectedClient, options) {
    return _.find(options, ['parentId', selectedClient.id]);
  };

  return vm;

};

angular.module('shared-components.extended-filters')
  .controller('ExtendedListFilterTagsController', dependencies.concat(ExtendedListFilterTagsController));