const editorInitializer = function() {

  const init = function(selector) {
    tinymce.remove(selector);
    tinymce.init({
      selector: selector,
      toolbar: 'undo redo | bold italic | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent',
      menubar: false,
      font_formats: 'Times New Roman=times new roman,times; Arial=arial,helvetica,sans-serif; Tahoma=tahoma,arial,helvetica,sans-serif; Verdana=verdana,geneva',
      fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px',
      setup: function(ed) {
        ed.on('init', function() {
          this.getDoc().body.style.fontFamily = 'times new roman';
        });
      }
    });
  };

  return {
    init
  };
};

angular.module('gccs-ui').service('editorInitializer', editorInitializer);
