/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'Session', 'ClientManagerRoot', 'sessionService', 'currentUserService',
  'userValidation', 'managerAccessLevel'
];

const SignClientRegistrationAccountDetailsController = function(
  $scope, Session, ClientManagerRoot, sessionService, currentUserService,
  userValidation, managerAccessLevel
) {

  const vm = this;

  vm.userValidation = userValidation;

  vm.getEmailErrors = () => userValidation.getEmailErrors(vm.manager.user && vm.manager.user.id);

  const onSuccess = () => $scope.signClientRegistrationLayoutCtrl.goNext();

  const createManager = () => {
    return new ClientManagerRoot({
      securityProviderId: $scope.signClientRegistrationLayoutCtrl.securityProviderId,
      registrationForm: true,
      accessLevel: managerAccessLevel.getAccountOwnerAccessLevel(),
      user: {}
    });
  };

  const initManager = () => vm.manager = currentUserService.getCurrentProfile() || createManager();

  $scope.$on('client.registration.submit', () => {
    const loginData = _.pick(vm.manager.user, 'email', 'password');
    vm.manager.save().then(function () {
      if (currentUserService.isClientManagerLogged()) {
        return onSuccess();
      } else {
        return sessionService.login(loginData, () =>
          Session.getInstance().then(currentSession => {
            currentUserService.setCurrentUserAndSession(currentSession);
            return onSuccess();
          })
        );
      }
    })
  });

  initManager();

  return vm;
};

angular.module('sign.client.registration').controller('SignClientRegistrationAccountDetailsController',
  dependencies.concat(SignClientRegistrationAccountDetailsController));
