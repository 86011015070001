/*
 * @author Maksym Shutiak <maxshutyak>
 */
const gccsEmployeeTimelineHeader = {
  controller: 'EmployeeTimelineHeaderController',
  controllerAs: 'employeeTimelineHeaderCtrl',
  template: require('./template.pug'),
  bindings: {
    interactor: '<'
  }
};

angular.module('public.employee.timeline').component('gccsEmployeeTimelineHeader', gccsEmployeeTimelineHeader);
