/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel', 'getParamsService'];

const EmployeeCardVersion = (AppModel, getParamsService) => {

  class EmployeeCardVersion extends AppModel {
    static initClass() {
      this.configure({ url: '/employee_cards/{{cardId}}/versions/{{id}}', name: 'version' });
    }

    static getPdfUrl(params, getParams, options = {}) {
      return this.resourceUrl(params, `/pdf_version.${options.format || 'pdf'}${getParamsService.buildUrl(getParams)}`, options);
    }
  }

  EmployeeCardVersion.initClass();

  return EmployeeCardVersion;

};

angular.module('data.card').factory('EmployeeCardVersion', dependencies.concat(EmployeeCardVersion));
