/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('gccs-ui').directive('gccsShiftRulesSelect', () => {
  return {
    restrict: 'A',
    require: 'ngModel',
    controller: 'SelectController',
    controllerAs: 'selectCtrl',
    template: require('./template.pug'),
    scope: {
      name: '@?',
      selectClassName: '@?',
      selectListItems: '=',
      editable: '<',
      addLabel: '@?',
      onAdd: '&?',
      onSelect: '&?',
      withoutAdd: '<'
    }
  };
});