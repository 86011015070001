/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.employee.timeline', [
  'public.security-manager.schedule-manager.timeline',
  'data.booking'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider

    .state('public.employee.timeline', {
      url: '/timeline',
      template: require('./template.pug'),
      controller: 'EmployeeTimelineController',
      controllerAs: 'employeeTimelineCtrl',
      params: {
        goToDate: undefined,
        goToShiftId: undefined
      }
    })

]);
