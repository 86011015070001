const gccsBulkRate = {
  controller: 'BulkRateComponentController',
  template: require('./template.pug'),
  bindings: {
    employeeResource: '=',
    resource: '<',
    resourcesRates: '<',
    checkedEmployees: '<',
    status: '=',
    isActive: '<',
    editable: '<',
    openedMenuDropdownCell: '=',
    toggleMenuDropdown: '&',
    closeMenuDropdown: '&',
    withCap: '<',
    onSetRate: '&',
    onApprove: '&',
    onUnapprove: '&',
    onRateChange: '&',
    selectedRateType: '<'
  }
};

angular.module('public.security-manager.hr').component('gccsBulkRate', gccsBulkRate);
