/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  'employeeNotificationType', 'dateService', 'bookingShiftEmployeeAssignStatus', 'expirationNotificationType'
];

const employeeNotificationTranslator = function(
  employeeNotificationType, dateService, bookingShiftEmployeeAssignStatus, expirationNotificationType
) {

  const dateFormat = dateService.getDateFormat();
  const timeFormat = dateService.getWesternTimeFormat(true);
  const isoFullDateFormat = dateService.getIsoFullDateFormat();

  const formatDate = date => moment(date, isoFullDateFormat).format(dateFormat);

  const formatTime = date => moment(date, isoFullDateFormat).format(timeFormat);

  const setGeneralNotificationProps = function(result, notification) {
    result.object = notification;
    result.isRead = notification.isRead;
    result.style = (() => {
      switch (false) {
        case !employeeNotificationType.isInvitation(notification.type): return 'mod-blue';
        case !employeeNotificationType.isRejectionCancellationOrUnassign(notification.type): return 'mod-red';
        case !employeeNotificationType.isApproval(notification.type): return '';
        default: return '';
      }
    })();
    result.timeAgo = moment(notification.createdAt, dateService.getIsoFullDateFormatWithTz()).fromNow();
    result.classes = {};
  };

  const setTimeProps = function(result, startTime, endTime) {
    if (!startTime || !endTime) { return; }
    result.startDay = formatDate(startTime);
    result.startTime = formatTime(startTime);
    result.endDay = formatDate(endTime);
    result.endTime = formatTime(endTime);
  };

  const shiftInvitationCanBeResponded = function(notificationObject) {
    const assignStatus = notificationObject.shiftEmployee ? notificationObject.shiftEmployee.assignStatus : undefined;
    if (assignStatus !== bookingShiftEmployeeAssignStatus.getNewStatus()) { return false; }
    const endTime = notificationObject != null ? notificationObject.endTime : undefined;
    if (!endTime) { return false; }
    return moment(notificationObject != null ? notificationObject.endTime : undefined, isoFullDateFormat).isAfter(moment());
  };

  const setShiftInvitationProps = function(result, notificationObject) {
    result.typeLabel = 'Invitation';
    result.bigTitle = notificationObject.shiftName;
    result.smallTitle = notificationObject.bookingName;
    result.payRate = notificationObject.payRate;
    result.currency = notificationObject.currency;
    result.isActionable = shiftInvitationCanBeResponded(notificationObject);
    result.shiftId = notificationObject.shiftId;
    setTimeProps(result, notificationObject.startTime, notificationObject.endTime);
  };

  const setShiftAssignProps = function(result, notificationObject) {
    result.typeLabel = 'Assigned to shift';
    result.bigTitle = notificationObject.shiftName;
    result.smallTitle = notificationObject.bookingName;
    setTimeProps(result, notificationObject.startTime, notificationObject.endTime);
  };

  const setShiftUnassignProps = function(result, notificationObject) {
    result.typeLabel = 'Unassigned from shift';
    result.bigTitle = notificationObject.shiftName;
    result.smallTitle = notificationObject.bookingName;
    setTimeProps(result, notificationObject.startTime, notificationObject.endTime);
  };

  const setShiftCancellationProps = function(result, notificationObject) {
    result.typeLabel = 'Shift was cancelled';
    result.bigTitle = notificationObject.shiftName;
    result.smallTitle = notificationObject.bookingName;
    setTimeProps(
      result,
      notificationObject.startTime,
      notificationObject.endTime
    );
  };

  const setShiftCancellationApprovalProps = function(result, notificationObject) {
    result.typeLabel = 'Cancelling shift was approved';
    result.bigTitle = notificationObject.shiftName;
    result.smallTitle = notificationObject.bookingName;
    setTimeProps(
      result,
      notificationObject.startTime,
      notificationObject.endTime
    );
  };

  const setShiftCancellationRejectionProps = function(result, notificationObject) {
    result.typeLabel = 'Cancelling shift was rejected';
    result.bigTitle = notificationObject.shiftName;
    result.smallTitle = notificationObject.bookingName;
    setTimeProps(
      result,
      notificationObject.startTime,
      notificationObject.endTime
    );
  };

  const setDayOffApprovalProps = function(result, notificationObject) {
    const action = 'was approved';
    if (notificationObject.employeeDayOff.paidDays > 0) {
      result.typeLabel = `Holiday ${action}`;
    } else {
      result.typeLabel = `Day off ${action}`;
    }
    result.bigTitle = notificationObject.name;
    setTimeProps(result, notificationObject.startTime, notificationObject.endTime);
  };

  const setDayOffRejectionProps = function(result, notificationObject) {
    const action = 'was rejected';
    if (notificationObject.employeeDayOff.paidDays > 0) {
      result.typeLabel = `Holiday ${action}`;
    } else {
      result.typeLabel = `Day off ${action}`;
    }
    result.bigTitle = notificationObject.name;
    setTimeProps(result, notificationObject.startTime, notificationObject.endTime);
  };

  const setNewCommentProps = function(result, notificationObject) {
    result.typeLabel = 'New Comment';
    result.bigTitle = notificationObject.shiftName;
    result.smallTitle = notificationObject.bookingName;
    setTimeProps(
      result,
      notificationObject.shiftEmployee != null ? notificationObject.shiftEmployee.startTime : undefined,
      notificationObject.shiftEmployee != null ? notificationObject.shiftEmployee.startTime : undefined
    );
  };

  const setInvoiceData = function(result, notificationObject) {
    result.bigTitle = `Invoice #${notificationObject.invoiceVersion.invoice.number}`;
    result.smallTitle = result.securityProviderName;
    result.goToInvoiceParams = { forInvoice: notificationObject.invoiceVersion.invoice };
  };

  const setInvoiceApprovalProps = function(result, notificationObject) {
    result.typeLabel = 'Invoice was approved by provider';
    setInvoiceData(result, notificationObject);
  };

  const setInvoiceCorrectionProps = function(result, notificationObject) {
    result.typeLabel = 'Invoice was corrected by provider';
    setInvoiceData(result, notificationObject);
  };

  const setInvoicePaymentProps = function(result, notificationObject) {
    result.typeLabel = 'Invoice was paid by provider';
    setInvoiceData(result, notificationObject);
    result.smallTitle = formatDate(notificationObject.invoiceVersion.paidDate);
  };

  const setInvoicePaymentDueProps = function(result, notificationObject) {
    result.typeLabel = 'Invoice payment due date was set by provider';
    setInvoiceData(result, notificationObject);
    result.smallTitle = formatDate(notificationObject.invoiceVersion.paidDate);
  };

  const setShiftChangeProps = function(result, notificationObject) {
    result.typeLabel = notificationObject.shift.name;
    result.bigTitle = 'Shift was changed';
    result.additionalButtonTitle = 'See changes';
    result.transitionOptions = {
      type: 'shift',
      id: notificationObject.shift.id,
      startTime: formatDate(notificationObject.shift.startTime)
    };
  };

  const setLicenseExpirationProps = function(result, notificationObject) {
    let status;
    if (expirationNotificationType.getPassed() === notificationObject.type) {
      status = 'has expired';
    } else {
      status = 'is due to expire';
    }
    result.typeLabel = `License ${status}`;
    result.bigTitle = notificationObject.employeeLicense.name;
    result.smallTitle = `Exp.: ${formatDate(notificationObject.expireDate)}`;
  };

  const setShiftReassignProps = function(result, notificationObject) {
    result.typeLabel = 'You have been moved to:';
    result.bigTitle = notificationObject.shiftName;
    result.smallTitle = notificationObject.bookingName;
    setTimeProps(result, notificationObject.startTime, notificationObject.endTime);
    result.additionalButtonTitle = 'Go to shift';
    result.transitionOptions = {
      type: 'shift',
      id: notificationObject.shiftId,
      startTime: formatDate(notificationObject.startTime)
    };
  };

  const setSickDayApprovalProps = function(result, notificationObject) {
    result.typeLabel = 'Sick day was approved';
    result.bigTitle = notificationObject.name;
    setTimeProps(result, notificationObject.startTime, notificationObject.endTime);
  };

  const setSickDayRejectionProps = function(result, notificationObject) {
    result.typeLabel = 'Sick day was rejected';
    result.bigTitle = notificationObject.name;
    setTimeProps(result, notificationObject.startTime, notificationObject.endTime);
  };

  const translateNotification = function(notification) {
    const result = {};
    setGeneralNotificationProps(result, notification);
    const notificationObject = employeeNotificationType.getNotificationObject(notification);
    result.securityProviderName = notification.securityProviderName;
    if (!notificationObject) { throw 'Unknown notification type'; }
    switch (notification.type) {
      case employeeNotificationType.getShiftInvitation():
        setShiftInvitationProps(result, notificationObject);
        break;
      case employeeNotificationType.getShiftCancellation():
        setShiftCancellationProps(result, notificationObject);
        break;
      case employeeNotificationType.getShiftCancellationApproval():
        setShiftCancellationApprovalProps(result, notificationObject);
        break;
      case employeeNotificationType.getShiftCancellationRejection():
        setShiftCancellationRejectionProps(result, notificationObject);
        break;
      case employeeNotificationType.getDayOffApproval():
        setDayOffApprovalProps(result, notificationObject);
        break;
      case employeeNotificationType.getDayOffRejection():
        setDayOffRejectionProps(result, notificationObject);
        break;
      case employeeNotificationType.getShiftAssign():
        setShiftAssignProps(result, notificationObject);
        break;
      case employeeNotificationType.getShiftUnassign():
        setShiftUnassignProps(result, notificationObject);
        break;
      case employeeNotificationType.getNewComment():
        setNewCommentProps(result, notificationObject);
        break;
      case employeeNotificationType.getInvoiceApproval():
        setInvoiceApprovalProps(result, notificationObject);
        break;
      case employeeNotificationType.getInvoiceCorrection():
        setInvoiceCorrectionProps(result, notificationObject);
        break;
      case employeeNotificationType.getInvoicePayment():
        setInvoicePaymentProps(result, notificationObject);
        break;
      case employeeNotificationType.getShiftChange():
        setShiftChangeProps(result, notificationObject);
        break;
      case employeeNotificationType.getInvoicePaymentDue():
        setInvoicePaymentDueProps(result, notificationObject);
        break;
      case employeeNotificationType.getLicenseExpiration():
        setLicenseExpirationProps(result, notificationObject);
        break;
      case employeeNotificationType.getShiftReassign():
        setShiftReassignProps(result, notificationObject);
        break;
      case employeeNotificationType.getSickDayApproval():
        setSickDayApprovalProps(result, notificationObject);
        break;
      case employeeNotificationType.getSickDayRejection():
        setSickDayRejectionProps(result, notificationObject);
        break;
    }
    return result;
  };

  return { translateNotification };
};

angular.module('public.employee')
  .service('employeeNotificationTranslator', dependencies.concat(employeeNotificationTranslator));
