/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$q', 'Client', 'ClientDecorator', 'SeriesDecorator', 'BookingShift', 'BookingSeries', 'dateService', 'storageService'
];

const TimeLineBookingLoader = function(
  $q, Client, ClientDecorator, SeriesDecorator, BookingShift, BookingSeries, dateService, storageService
) {

  return class TimeLineBookingLoader {

    constructor(queryParams = {}) {
      this.selectClients = this.selectClients.bind(this);
      this.onStatSelect = this.onStatSelect.bind(this);
      this.groupedSeriesById = {};
      this.clients = null;
      this._initSelectedClients();
      this._initFilters();
      this.currentStatFilter = null;
      this.queryParams = queryParams;
    }

    // returns promise for interactor
    loadForDates(from, to, queryParams = {}) {
      this.groupedSeriesById = {};
      this.clients = null;
      this.queryParams['selected_clients[]'] = this.selectedClients;
      return Client.withShifts(from, to, _.extend({}, this.queryParams, queryParams)).then(clients => {
        this._initClients(this.clients, clients);
        this._groupSeriesById();
        this._setVisibilityAndGroupSeries();
        this._resetFiltersOnLoad();
        return this.clients;
      });
    }

    reloadClient(clientId) {
      const existingDClient = _.find(this.clients, dClient => dClient.id === clientId);
      if (existingDClient) {
        return Client.get(clientId).then(client => _.merge(existingDClient.client, client));
      }
    }

    loadClient(from, to, queryParams) {
      Client.withShifts(from, to, queryParams || this.queryParams).then(clients => {
        const client = clients[0];
        let dClient = _.find(this.clients, dClient => dClient.id === client.id);
        if (!dClient) {
          dClient = new ClientDecorator(client);
          this.clients.push(dClient);
          this._pushClientToFilter(dClient);
        }
        this._groupClientSeriesById(dClient, this.groupedSeriesById);
        dClient.setVisibilityFromStats(this.currentStatFilter);
        dClient.groupSeries();
        dClient.groupBookings();
      });
    }

    updateShiftsForSeries(dSeries, from, to) {
      if (!this.clients) { return; } // not sure about this
      return this._loadShiftsForSeries(dSeries, from, to).then(shifts => {
        for (let dClient of Array.from(this.clients)) {
          dClient.updateShiftsForSeries(dSeries, shifts);
          dClient.clearDimensions();
        }
        this._setVisibilityAndGroupSeries();
        return shifts;
      });
    }

    reloadShiftById(shiftId) {
      const dShift = this._findShiftById(shiftId);
      if (dShift) {
        return this._loadShift(dShift.shift.bookingId, shiftId).then(shift => {
          const series = this.getDSeries(dShift.shift.bookingSeriesId);
          if (series) {
            series.updateShift(shift);
          }
          return dShift;
        });
      } else {
        return $q.when();
      }
    }

    _findShiftById(shiftId) {
      for (let dClient of Array.from(this.clients)) {
        for (let dBooking of Array.from(dClient.dBookings)) {
          for (let dSeries of Array.from(dBooking.dSeries)) {
            const shift = _.find(dSeries.dShifts, dShift => dShift.id === shiftId);
            if (shift) {
              return shift;
            }
          }
        }
      }
    }

    _loadShift(bookingId, shiftId) {
      return BookingShift.get({bookingId, id : shiftId}, this.queryParams);
    }

    loadSeries(dBooking, bookingSeriesId, from, to) {
      return BookingSeries.get({id : bookingSeriesId, bookingId : dBooking.id}).then(series => {
        series.shifts = [];
        const dSeries = new SeriesDecorator(dBooking, series, dBooking.updateStatisticValues.bind(dBooking));
        dBooking.dSeries.push(dSeries);
        this.groupedSeriesById[series.id] = dSeries;
        return this.updateShiftsForSeries(dSeries, from, to);
      });
    }

    getDSeries(seriesId) {
      return this.groupedSeriesById[seriesId];
    }

    getSeriesForShift(dShift) {
      return this.groupedSeriesById[dShift.shift.bookingSeriesId];
    }

    clientsLoaded() {
      return this.clients !== null;
    }

    updateEmployeeShift(employeeShift) {
      return Array.from(this.clients).map((client) =>
        client.updateEmployeeShift(employeeShift));
    }

    removeEmployeeShift(employeeShift) {
      return Array.from(this.clients).map((client) =>
        client.removeEmployeeShift(employeeShift));
    }

    getClientForEmployeeShift(employeeShift) {
      for (let client of Array.from(this.clients)) {
        if (client.hasEmployeeShift(employeeShift)) { return client; }
      }
    }

    closeSummaries(bookingDecorator) {
      return Array.from(this.clients).map((client) => client.closeSummaries(bookingDecorator));
    }

    closeShiftTimelinePopups(bookingDecorator) {
      return Array.from(this.clients).map((client) => client.closeShiftTimelinePopups(bookingDecorator));
    }

    selectClients(selectedClients) {
      storageService.set('selectedClients', selectedClients.join(','));
      return this.selectedClients = _.clone(selectedClients);
    }

    onStatSelect(statFilter) {
      this.currentStatFilter = statFilter;
      this._setVisibilityAndGroupSeries();
      return Array.from(this.clients).map((dClient) => dClient.invalidateCache());
    }

    removeBookingFor(client, booking) {
      client.dBookings = _.without(client.dBookings, booking);
      if (client.dBookings.length === 0) { this.clients = _.without(this.clients, client); }
      return this._setVisibilityAndGroupSeries();
    }

    removeShiftFor(dSeries, shift) {
      for (let dShift of Array.from(dSeries.dShifts)) {
        if (dShift.id === shift.id) {
          dSeries.dShifts = _.without(dSeries.dShifts, dShift);
          break;
        }
      }
      return this._setVisibilityAndGroupSeries();
    }

    groupSeries(bookingDSeries) {
      return Array.from(bookingDSeries).map((dSeries) => this.groupedSeriesById[dSeries.series.id] = dSeries);
    }

    _initClients(previousDClients, newClients) {
      const clientIds = [];
      const newDClients = [];
      _.each(newClients, function(client) {
        clientIds.push(client.id);
        return newDClients.push(new ClientDecorator(client));
      });
      _.each(previousDClients, dClient => {
        if ((this.selectedClients.indexOf(dClient.client.id) !== -1) && (clientIds.indexOf(dClient.client.id) === -1)) {
          dClient.dBookings = [];
          return newDClients.push(dClient);
        }
      });
      return this.clients = newDClients;
    }

    _loadShiftsForSeries(dSeries, from, to) {
      if (moment.isMoment(from) && moment.isMoment(to)) {
        from = from.format(dateService.getIsoFullDateFormat());
        to = to.format(dateService.getIsoFullDateFormat());
      }
      return BookingShift.query(_.merge(this.queryParams, {
        bookingSeriesId: dSeries.id,
        from,
        to
      }), { bookingId: dSeries.bookingId });
    }

    _setVisibilityAndGroupSeries() {
      this._setVisibilityFromStats();
      this._groupSeries();
      return Array.from(this.clients).map((dClient) => dClient.groupBookings());
    }

    _groupSeries() {
      return Array.from(this.clients).map((dClient) => dClient.groupSeries());
    }

    _groupSeriesById() {
      const groupedSeriesById = {};
      for (let dClient of Array.from(this.clients)) {
        this._groupClientSeriesById(dClient, groupedSeriesById);
      }
      return this.groupedSeriesById = groupedSeriesById;
    }

    _groupClientSeriesById(dClient, groupedSeriesById) {
      return Array.from(dClient.dBookings).map((dBooking) =>
        Array.from(dBooking.dSeries).map((dSeries) =>
          (groupedSeriesById[dSeries.series.id] = dSeries)));
    }

    _setVisibilityFromStats() {
      return Array.from(this.clients).map((dClient) => dClient.setVisibilityFromStats(this.currentStatFilter));
    }

    _resetFiltersOnLoad() {
      this._initFilters();
      const sortedDClients = _.sortBy(this.clients, dClient => dClient.client.name.toLowerCase());
      return (() => {
        const result = [];
        for (let dClient of Array.from(sortedDClients)) {
          result.push(this._pushClientToFilter(dClient));
        }
        return result;
      })();
    }

    _pushClientToFilter(dClient) {
      return this.clientsFilter.push({
        id: dClient.client.id,
        text: dClient.client.name,
        status: dClient.client.status
      });
    }

    _initFilters() {
      return this.clientsFilter = [];
    }

    _initSelectedClients() {
      const savedClients = storageService.get('selectedClients');
      if (savedClients) {
        return this.selectedClients = _.map(savedClients.split(','), savedClientId => +savedClientId);
      } else {
        return this.selectedClients = [];
      }
    }

  }

};

angular.module('public.timeline').factory('TimeLineBookingLoader', dependencies.concat(TimeLineBookingLoader));
