angular.module('data.booking').factory('BookingClockComment', ['AppModel', (AppModel) => {

  class BookingClockComment extends AppModel {
    static initClass() {
      this.configure({
        url: '/bookings/{{ bookingId }}/clock_comments/{{ id }}',
        name: 'clock_comment'
      });
    }
  }

  BookingClockComment.initClass();

  return BookingClockComment;

}]);
