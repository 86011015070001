/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['dateService'];

const chartService = function(dateService) {

  const svc = this;

  const initSingleDayGraph = (data, graphData, isFirstIteration, getAxisNameCb) =>
    _.each(data, function(singleDayData, date) {
      let iterationIndex = 0;
      if (isFirstIteration) {
        graphData.push(['x']);
      }
      graphData[0].push(date);
      _.each(singleDayData, function(dayAmount, status) {
        if (isFirstIteration) {
          graphData.push([getAxisNameCb(status)]);
        }
        graphData[++iterationIndex].push(dayAmount);
        return true;
      });
      isFirstIteration = false;
      return true;
    })
  ;

  const initWeeklyGraph = function(data, graphData, isFirstIteration, getAxisNameCb) {
    let weekIndex = 1;
    return _.each(data, function(singleDayData, date) {
      let iterationIndex = 0;
      if (isFirstIteration) {
        graphData.push(['x']);
        graphData[0].push(date);
      } else {
        if (((weekIndex % 7) !== 0) && (weekIndex === 1)) {
          graphData[0].push(date);
        }
      }
      _.each(singleDayData, function(dayAmount, status) {
        if (isFirstIteration) {
          graphData.push([getAxisNameCb(status)]);
          graphData[++iterationIndex].push(dayAmount);
        } else {
          if (weekIndex === 1) {
            graphData[++iterationIndex].push(dayAmount);
          } else {
            iterationIndex++;
            graphData[iterationIndex][graphData[iterationIndex].length - 1] += dayAmount;
          }
        }
        return true;
      });
      isFirstIteration = false;
      if ((weekIndex % 7) === 0) {
        weekIndex = 1;
      } else {
        weekIndex++;
      }
      return true;
    });
  };

  const initMonthlyGraph = function(data, graphData, isFirstIteration, getAxisNameCb) {
    let lastMonth = null;
    return _.each(data, function(singleDayData, date) {
      let iterationIndex = 0;
      const currentDate = moment(date, dateService.getIsoDateFormat());
      const currentMonth = currentDate.format('M');
      if (isFirstIteration) {
        graphData.push(['x']);
        graphData[0].push(currentDate.startOf('month').format(dateService.getIsoDateFormat()));
        lastMonth = currentMonth;
      } else {
        if (currentMonth !== lastMonth) {
          graphData[0].push(date);
        }
      }
      _.each(singleDayData, function(dayAmount, status) {
        if (isFirstIteration) {
          graphData.push([getAxisNameCb(status)]);
          graphData[++iterationIndex].push(dayAmount);
        } else {
          if (currentMonth !== lastMonth) {
            graphData[++iterationIndex].push(dayAmount);
          } else {
            const iterIndex = ++iterationIndex;
            graphData[iterIndex][graphData[iterIndex].length - 1] += dayAmount;
          }
        }
        return true;
      });
      isFirstIteration = false;
      if (currentMonth !== lastMonth) {
        lastMonth = currentMonth;
      }
      return true;
    });
  };

  svc.getGraphFormatData = function(data, endDateFrom, endDateTo, getAxisNameCb, updateFormatterCb) {
    const isFirstIteration = true;
    const graphData = [];
    const momentFrom = moment(endDateFrom, dateService.getDateFormat());
    const momentTo = moment(endDateTo, dateService.getDateFormat());
    const monthMeasurment = momentTo.diff(momentFrom, 'months');
    if (monthMeasurment < 1) {
      initSingleDayGraph(data, graphData, isFirstIteration, getAxisNameCb);
      updateFormatterCb(false);
    } else if (monthMeasurment < 3) {
      initWeeklyGraph(data, graphData, isFirstIteration, getAxisNameCb);
      updateFormatterCb(false);
    } else {
      initMonthlyGraph(data, graphData, isFirstIteration, getAxisNameCb);
      updateFormatterCb(true);
    }
    return graphData;
  };

  return svc;
};

angular.module('public.security-manager.invoices').service('chartService', dependencies.concat(chartService));
