angular.module('data.client-invoice').factory('ClientInvoice', ['AppModel', function(AppModel) {

  class ClientInvoice extends AppModel {
    static initClass() {
      this.configure({url : '/client_invoices', name : 'client_invoice'});
    }

    static all(params = {}) {
      return this.$get(this.resourceUrl('all'), params);
    }

    static getNextNumber(params = {}) {
      return this.$get(this.resourceUrl('next_number'), params);
    }

    static elementCost(data) {
      return this.$put(this.resourceUrl('element_cost'), {element : data});
    }

    static bulkStatusChange(params = {}) {
      return this.$put(this.resourceUrl('versions/bulk_status_change'), params);
    }

    static getForBooking(bookingId) {
      return this.$get(this.resourceUrl('for_booking'), {bookingId});
    }

    static getIncome(params = {}) {
      return this.$get(this.resourceUrl('income'), params);
    }

    static exportUrl(params = {}) {
      const getParams = _.map(params, (value, key) => `${key}=${value}`);
      return this.resourceUrl('export') + `.csv?${getParams.join('&')}`;
    }

    refresh() {
      return this.$put(this.getUrl() + '/refresh');
    }
  }

  ClientInvoice.initClass();
  return ClientInvoice;
}
]);
