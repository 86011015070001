/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.client').factory('ClientTraining', ['AppModel', function(AppModel) {

  class ClientTraining extends AppModel {
    static initClass() {
      this.configure({url : '/clients/{{clientId}}/trainings/{{id}}'});
    }
  }

  ClientTraining.initClass();
  return ClientTraining;
}
]);
