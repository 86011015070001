const dependencies = [
  '$scope', 'Employee', 'employeeStatus', 'packageTypeService', 'systemPackagePopupService'
];

const PopupSystemPackageDowngradeEmployeesController = function(
  $scope, Employee, employeeStatus, packageTypeService, systemPackagePopupService
) {

  const vm = this;

  vm.getEmployeeRolesText = function(employee) {
    if (employee.roles.length > 0) {
      return _.map(employee.roles, 'name').join(', ');
    } else {
      return 'No role selected';
    }
  };

  vm.selectEmployee = function(employee) {
    employee.isSelected = !employee.isSelected;
  };

  vm.onCheckBoxClick = function(e) {
    e.stopPropagation();
  };

  vm.selectAll = function() {
    vm.employees.forEach((employee) => {
      employee.isSelected = true;
    });
  };

  vm.deselectAll = function() {
    vm.employees.forEach((employee) => {
      employee.isSelected = false;
    });
  };

  vm.isBeyondTheLimit = function() {
    return vm.getSelectedCount() > vm.employeesLimit;
  };

  vm.isSelectedNumberValid = function() {
    return vm.getSelectedCount() > 0 && vm.getSelectedCount() <= vm.employeesLimit;
  };

  vm.onNextStep = function() {
    if (!vm.isSelectedNumberValid()) { return; }
    vm.downgradeService.addToStore('employees', vm.employees);
    if (isLicensePopup()) {
      vm.downgradeService.deactivateEmployees();
      systemPackagePopupService.saveLicensesNumber(vm.employeesLimit, function() {
        $scope.popupCtrl.closePopup();
        systemPackagePopupService.showSuccessPopup('Number of licenses updated', `Your number of licenses for employees has now changed to ${vm.employeesLimit}.`);
      });
    } else {
      vm.downgradeService.addCallback(systemPackagePopupService.goToEmployeesDowngradePopup);
      vm.downgradeService.incrementStep();
      systemPackagePopupService.goToClientsDowngradePopup(systemPackagePopupService.getPackageType(), $scope.popupCtrl.closePopup);
    }
  };

  vm.getSelectedCount = function() {
    return _.filter(vm.employees, 'isSelected').length;
  };

  vm.getSubmitButtonText = function() {
    return isLicensePopup() ? 'Confirm' : 'Next step';
  };

  const isLicensePopup = function() {
    return !!vm.downgradeService.licensesNumber;
  };

  const initDowngradeService = function() {
    vm.downgradeService = systemPackagePopupService.getSystemPackageDowngrade();
  };

  const initOptions = function() {
    vm.employeesLimit = vm.downgradeService.licensesNumber || packageTypeService.getEmployeesLimitForFreePackage();
  };

  const initEmployees = function() {
    if (vm.downgradeService.isInStore('employees')) {
      vm.employees = angular.copy(vm.downgradeService.getFromStore('employees'));
    } else {
      Employee.query({
        'order[full_name]': 'asc',
        template: 'downgrade',
        status: employeeStatus.getActiveStatus()
      }).then(function (employees) {
        employees.forEach(employee => employee.lowerCaseFullName = employee.user.fullName.toLowerCase());
        vm.employees = _.orderBy(employees, ['lowerCaseFullName']);
      });
    }
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      initDowngradeService();
      initOptions();
      initEmployees();
    }
  });

  return vm;

};

angular.module('popup.system-package')
  .controller('PopupSystemPackageDowngradeEmployeesController', dependencies.concat(PopupSystemPackageDowngradeEmployeesController));
