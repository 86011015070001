const dependencies = [
  '$scope', 'ClientServiceResourceIcon', 'RateChange', 'Role', 'resourceChargeType', 'resourceIcon',
  'uploadService', 'currentUserService', 'validationService', 'rateChangeType', 'confirmService'
];

const ClientResourceTableItemController = function(
  $scope, ClientServiceResourceIcon, RateChange, Role, resourceChargeType, resourceIcon,
  uploadService, currentUserService, validationService, rateChangeType, confirmService
) {

  const vm = this;

  vm.isShownResourceIconsDropdown = false;
  vm.iconClasses = resourceIcon.getIconClasses();
  vm.resourceIcon = resourceIcon;
  vm.resourceChargeType = resourceChargeType;
  vm.validationService = validationService;
  vm.isShownEditPayRatePopup = false;

  vm.$onInit = function() {
    if (vm.isEditable === undefined) {
      vm.isEditable = true;
    }
    updateInitialResource();
    loadIcons();
    initReset();
  };

  vm.getRoleNames = function() {
    return _.map(vm.resource.roles, 'name').join(', ');
  };

  vm.getChargeTypeName = function() {
    return resourceChargeType.nameOf(vm.resource.chargeType);
  };

  vm.getMarginText = function(field) {
    const margin = vm.getMargin(field);
    let marginText = '(';
    if (margin > 0) {
      marginText += '+';
    } else if (margin < 0) {
      marginText += '-';
    }
    marginText += Math.abs(margin) + '%';
    return marginText + ')';
  };

  vm.getMargin = function(field) {
    if (vm.resource.chargeRate === 0) {
      return 0;
    } else {
      return Math.floor(((vm.resource.chargeRate - vm.resource[field]) / vm.resource.chargeRate) * 1000) / 10;
    }
  };

  vm.toggleIconsDropdown = () => {
    vm.isShownResourceIconsDropdown = !vm.isShownResourceIconsDropdown;
  };

  vm.hideIconsDropdown = () => {
    vm.isShownResourceIconsDropdown = false;
  };

  vm.onIconUpload = function(resource, $file) {
    new ClientServiceResourceIcon({ iconType: resourceIcon.getUploadedType() }).save().then(function(serviceResourceIcon) {
      serviceResourceIcon.icon = $file;
      uploadService.updateFile(serviceResourceIcon, function(updatedIcon) {
        loadIcons();
        resource.icon = updatedIcon;
        vm.uploadedIcon = null;
      });
    });
  };

  vm.hasUploadedIcon = function(resource) {
    return resource.icon && resourceIcon.isUploadedType(resource.icon.iconType);
  };

  vm.loadRoles = function (text) {
    return Role.query({ name: text, limit: 10 });
  };

  vm.isShownHours = () => {
    return resourceChargeType.isRequiredHours(vm.resource.chargeType);
  };

  vm.isShownChargeRate = () => {
    return resourceChargeType.isRequiredChargeRate(vm.resource.chargeType);
  };

  vm.isShownPayRate = () => {
    return resourceChargeType.isRequiredPayRate(vm.resource.chargeType);
  };

  vm.getClassForField = function(field) {
    return { 'resources-table-item-input__val--error': vm.form[field].$dirty && vm.form[field].$invalid }
  };

  vm.save = function() {
    vm.onSaveCb({ resource: vm.resource });
  };

  vm.showEditPayRatePopup = function(field) {
    vm.isShownEditPayRatePopup = true;
    vm.resourceRateChange = new RateChange({
      serviceResourceId: vm.resource.id,
      payRate: vm.resource[field],
      rateType: rateChangeType.fieldToType(field)
    });
  };

  vm.hideEditPayRatePopup = function(rateChange) {
    vm.isShownEditPayRatePopup = false;
    vm.resourceRateChange = null;
    if (rateChange) {
      if (rateChange.id) {
        vm.resource.rateChanges.push(rateChange);
      } else {
        vm.resource[rateChangeType.typeToField(rateChange.rateType)] = rateChange.payRate;
      }
    }
  };

  vm.getPopupTitle = function() {
    return `Edit rate for ${vm.resource.name}`;
  };

  vm.getRateChange = function(field) {
    const rateType = rateChangeType.fieldToType(field);
    return _.find(vm.resource.rateChanges, function(rateChange) {
      return rateChange.rateType === rateType;
    });
  };

  vm.removeRateChange = function(rateChange) {
    confirmService.confirm(
      `Remove ${rateChangeType.getTextOf(rateChange.rateType)} change for ${vm.resource.name}?`,
      'Do you really want to remove planned rate changes? This action can not be undone.',
      'Remove',
      function () {
        new RateChange(rateChange).remove().then(function() {
          vm.resource.rateChanges = _.without(vm.resource.rateChanges, rateChange);
        });
      },
    );
  };

  const loadIcons = function() {
    ClientServiceResourceIcon.query().then(function(icons) {
      vm.icons = icons;
    });
  };

  const initReset = function() {
    $scope.$on('reset-resource', function() {
      vm.resource = angular.copy(vm.initialResource);
    });
    $scope.$on('update-resource', updateInitialResource);
  };

  const updateInitialResource = function() {
    if (!vm.resourceChargeType.isPerHour(vm.resource.chargeType)) {
      vm.resource.minHours = null;
      vm.resource.maxHours = null;
    }
    if (vm.resourceChargeType.isPerUnit(vm.resource.chargeType)) {
      vm.resource.contractorPayRate = null;
      vm.resource.employedPayRate = null;
    }
    vm.initialResource = angular.copy(vm.resource);
  };

  return vm;

};

angular.module('public.security-manager.client.resource').controller(
  'ClientResourceTableItemController',
  dependencies.concat(ClientResourceTableItemController)
);
