const dependencies = [
  '$scope', '$state', 'SecurityManagerNotificationPreference', 'securityManagerNotificationType',
  'securityManagerNotificationPreferenceType', 'notificationService', 'redirectService', 'packageTypeService'
];

const SecurityManagerNotificationPreferencesController = function(
  $scope, $state, SecurityManagerNotificationPreference, securityManagerNotificationType,
  securityManagerNotificationPreferenceType, notificationService, redirectService, packageTypeService
) {

  const vm = this;

  let notificationPreferences = [];

  vm.securityManagerId = $state.params.securityManagerId;

  vm.$onInit = function() {
    loadNotificationPreferences();
  };

  vm.applySelection = function(notificationsSettingsGroup, selectionValue) {
    notificationsSettingsGroup.notificationsSettings.forEach((notificationsSetting) => {
      notificationsSetting.urgent = selectionValue;
      notificationsSetting.desktop = selectionValue;
      notificationsSetting.email = selectionValue;
    });
  };

  vm.onFormSubmit = function() {
    const updatedNotificationsSettings = [];
    vm.notificationsSettingsGroups.forEach((notificationsSettingsGroup) => {
      notificationsSettingsGroup.notificationsSettings.forEach((notificationsSetting) => {
        if (notificationsSetting.urgent) {
          updatedNotificationsSettings.push(new SecurityManagerNotificationPreference({
            notificationType: notificationsSetting.notificationType,
            preferenceType: securityManagerNotificationPreferenceType.getUrgent()
          }));
        }
        if (notificationsSetting.desktop) {
          updatedNotificationsSettings.push(new SecurityManagerNotificationPreference({
            notificationType: notificationsSetting.notificationType,
            preferenceType: securityManagerNotificationPreferenceType.getDesktop()
          }));
        }
        if (notificationsSetting.email) {
          updatedNotificationsSettings.push(new SecurityManagerNotificationPreference({
            notificationType: notificationsSetting.notificationType,
            preferenceType: securityManagerNotificationPreferenceType.getEmail()
          }));
        }
      });
    });
    SecurityManagerNotificationPreference
      .batchUpdate(vm.securityManagerId, updatedNotificationsSettings)
      .then((result) => {
        if (result) {
          notificationService.notifySuccess('Notification settings updated!');
          notificationPreferences = updatedNotificationsSettings;
          initNotificationsGroup();
        }
      });
  };

  vm.isEmailCheckboxShown = function(notificationType) {
    return securityManagerNotificationType.hasEmailNotification(notificationType);
  };

  vm.redirectToMain = () => {
    redirectService.redirectToMain();
  };

  const initNotificationsGroup = function() {
    vm.notificationsSettingsGroups = [{
      title: 'Shifts',
      notificationsSettings: getNotificationSettings(securityManagerNotificationType.getShiftsNotificationSettings())
    }, {
      title: 'Bookings',
      notificationsSettings: getNotificationSettings(securityManagerNotificationType.getBookingsNotifications())
    }, {
      title: 'Requests',
      notificationsSettings: getNotificationSettings(securityManagerNotificationType.getRequestsNotificationSettings())
    }, {
      title: 'Invoices',
      notificationsSettings: getNotificationSettings(securityManagerNotificationType.getInvoiceNotifications())
    }, {
      title: 'Training & Licences',
      notificationsSettings: getNotificationSettings(securityManagerNotificationType.getTrainingAndLicensesNotifications())
    }];
    if (packageTypeService.hasProPackage()) {
      vm.notificationsSettingsGroups.splice(1, 0, {
        title: 'Clock in/out',
        notificationsSettings: getNotificationSettings(securityManagerNotificationType.getClockInOutNotifications())
      });
      vm.notificationsSettingsGroups.push({
        title: 'Integrations',
        notificationsSettings: getNotificationSettings(securityManagerNotificationType.getIntegrationNotifications())
      });
    }
  };

  const getNotificationSettings = function(notificationGroup) {
    const notificationSettingsByGroup = [];
    notificationGroup.forEach((notificationGroupItem) => {
      notificationSettingsByGroup.push({
        notificationType: notificationGroupItem,
        title: securityManagerNotificationType.getNotificationName(notificationGroupItem),
        type: securityManagerNotificationType.getNotificationEntityTypeName(notificationGroupItem),
        urgent: getPreferenceValue(notificationGroupItem, securityManagerNotificationPreferenceType.getUrgent()),
        desktop: getPreferenceValue(notificationGroupItem, securityManagerNotificationPreferenceType.getDesktop()),
        email: getPreferenceValue(notificationGroupItem, securityManagerNotificationPreferenceType.getEmail())
      });
    });
    return notificationSettingsByGroup;
  };

  const getPreferenceValue = function(notificationType, preferenceType) {
    return !!_.find(notificationPreferences, (notificationPreference) => {
      return notificationPreference.notificationType === notificationType &&
        notificationPreference.preferenceType === preferenceType;
    });
  };

  const loadNotificationPreferences = function() {
    SecurityManagerNotificationPreference
      .query({}, { securityManagerId: vm.securityManagerId })
      .then(notificationPrefs => {
        notificationPreferences = notificationPrefs;
        initNotificationsGroup();
      });
  };

  return vm;

};

angular.module('public.security-manager.manager-profile')
  .controller('SecurityManagerNotificationPreferencesController', dependencies.concat(SecurityManagerNotificationPreferencesController));
