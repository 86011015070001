/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [];

const confirmService = function() {

  let _isShownPopup = false;
  let _title = null;
  let _question = null;
  let _buttons = null;

  const confirmWithMultipleButtons = function({ title, question, buttons }) {
    _title = title;
    _question = question;
    _buttons = buttons;
    _isShownPopup = true;
  };

  // todo remove completely and default white button
  const confirm = (title, question, confirmText, confirmCb, cancelCb, cancelText = 'Cancel') => {
    return confirmWithMultipleButtons({
      title,
      question,
      buttons: [{
        label: cancelText,
        type: 'white',
        onClickCb: cancelCb
      }, {
        label: confirmText,
        type: 'green',
        onClickCb: confirmCb
      }]
    });
  };

  return {
    confirm,
    confirmWithMultipleButtons,

    confirmRemove(removableName, confirmCb, cancelCb) {
      return confirm(
        'Confirm remove',
        `Do you really want to remove ${removableName}? This action can't be undone.`,
        'Yes',
        confirmCb,
        cancelCb
      );
    },

    closePopup() {
      return _isShownPopup = false;
    },

    getTitle() {
      return _title;
    },

    getQuestion() {
      return _question;
    },

    getButtons() {
      return _buttons;
    },

    getButtonClassName(button) {
      return button.className || (button.className = `mod-${button.type}`);
    },

    isShownPopup() {
      return _isShownPopup;
    }
  };
};

angular.module('popup.confirm').service('confirmService', dependencies.concat(confirmService));
