const gccsInvoiceEmployeeInvoiceVersion = {
  controller: 'InvoiceEmployeeInvoiceVersionController',
  template: require('./template.pug'),
  bindings: {
    invoice: '<',
    invoiceVersion: '<',
    nextVersion: '<',
    isMain: '<',
    toggleable: '<',
    showClient: '<',
    editable: '<',
    allList: '<',
    onStatusChange: '&',
    onBookingCheck: '&',
    onInvoiceRemove: '&',
    onPrintStart: '&',
    onPrintEnd: '&'
  }
};

angular
  .module('public.invoice.employee')
  .component('gccsInvoiceEmployeeInvoiceVersion', gccsInvoiceEmployeeInvoiceVersion);
