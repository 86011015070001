/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.document').service('documentTypeIconService', function() {

  const UPLOADED = -1;
  const DRIVER_LICENSE = 1;
  const EMPLOYMENT_CONTRACT = 2;
  const FIRST_AID = 3;
  const NEW_STARTER_FORM = 4;
  const PASSPORT = 5;
  const PROOF_OF_ADDRESS = 6;
  const RIGHT_TO_WORK = 7;
  const RISK_ASSESSMENT = 8;
  const SIA = 9;
  const SITE_INSTRUCTION = 10;
  const SITE_SURVEY = 11;
  const VETTING_FILE = 12;
  const VISA = 13;
  const CV = 14;

  const iconClasses = {
    [DRIVER_LICENSE]: 'mod-doc-type-drivers-licence',
    [EMPLOYMENT_CONTRACT]: 'mod-doc-type-employment-contract',
    [FIRST_AID]: 'mod-doc-type-first-aid',
    [NEW_STARTER_FORM]: 'mod-doc-type-new-starter-form',
    [PASSPORT]: 'mod-doc-type-passport',
    [PROOF_OF_ADDRESS]: 'mod-doc-type-proof-of-address',
    [RIGHT_TO_WORK]: 'mod-doc-type-right-to-work',
    [RISK_ASSESSMENT]: 'mod-doc-type-risk-assessment',
    [SIA]: 'mod-doc-type-sia',
    [SITE_INSTRUCTION]: 'mod-doc-type-site-instruction',
    [SITE_SURVEY]: 'mod-doc-type-site-survey',
    [VETTING_FILE]: 'mod-doc-type-vetting-file',
    [VISA]: 'mod-doc-type-visa',
    [CV]: 'mod-dov-type-cv'
  };

  return {
    getFirstIcon() {
      return DRIVER_LICENSE;
    },
  
    getIconClasses() {
      return iconClasses;
    },

    getIconClass(iconType) {
      return iconClasses[iconType];
    },

    getUploadedType() {
      return UPLOADED;
    },

    isUploadedType(iconType) {
      return iconType === UPLOADED;
    }
  };
});
