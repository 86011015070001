/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').directive('onParentClick', () => {
  return {
    restrict : 'A',
    link($scope, $el, attrs) {
      const element = $el[0];

      const onClick = function(e) {
        if (e.target === document.body) { return; }
        let parentElement = element;
        while ((parentElement = parentElement.parentElement)) {
          if (parentElement === e.target) {
            return $scope.$apply(attrs.onParentClick);
          }
        }
      };

      document.body.addEventListener('click', onClick);

      return $scope.$on('$destroy', () => document.body.removeEventListener('click', onClick));
    }
  }
});
