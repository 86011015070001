import './style.css';

const dependencies = ['$scope'];

const ClientShowClockInController = function($scope) {

  const vm = this;
  
  vm.$onInit = function() {
    $scope.clientShowLayoutCtrl.loadClient().then(client => vm.client = client);
  };

  vm.onMobileClockInChange = function(value) {
    vm.client.enableMobileClockIn = value;
    vm.client.save();
  };

  vm.onDesktopClockInChange = function(value) {
    vm.client.enableDesktopClockIn = value;
    vm.client.save();
  };

  return vm;

};

angular.module('public.security-manager.client.show')
  .controller('ClientShowClockInController', dependencies.concat(ClientShowClockInController));
