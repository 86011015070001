/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['bookingRepeatable', 'dateService'];

const repeatTimesValidationService = function(bookingRepeatable, dateService) {

  const getStartTimeMoment = shift => moment(shift.startTime, dateService.getFullDateFormat());

  return {
    isValid(shift, maxEndDate) {
      let repeatToTime;
      if (!shift.repeatTimes) { return true; }
      switch (shift.repeatable) {
        case bookingRepeatable.getDaily():
          repeatToTime = getStartTimeMoment(shift).add(shift.repeatTimes, 'days');
          break;
        case bookingRepeatable.getWeekly():
          repeatToTime = getStartTimeMoment(shift).add(shift.repeatTimes, 'weeks');
          break;
        case bookingRepeatable.getFortnight():
          repeatToTime = getStartTimeMoment(shift).add(2 * shift.repeatTimes, 'weeks');
          break;
        case bookingRepeatable.getMonthly():
          repeatToTime = getStartTimeMoment(shift).add(shift.repeatTimes, 'months');
          break;
        default:
          return true;
      }
      return repeatToTime.isSameOrBefore(maxEndDate);
    }
  };
};

angular.module('popup.booking').service('repeatTimesValidationService', dependencies.concat(repeatTimesValidationService));
