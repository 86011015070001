/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// todo move form and list from client show to invoice
const dependencies = [
  '$scope', '$state', '$stateParams', 'ClientInvoice', 'Booking', 'SecurityProviderSetting', 'ClientService',
  'validationService', 'invoiceSaver', 'invoiceType', 'dateService', 'confirmService',
  'bookingShiftEmployeeAssignStatus', 'notificationService', 'invoiceCostService', 'packageTypeService'
];

const ClientShowInvoiceFormController = function(
  $scope, $state, $stateParams, ClientInvoice, Booking, SecurityProviderSetting, ClientService,
  validationService, invoiceSaver, invoiceType, dateService, confirmService,
  bookingShiftEmployeeAssignStatus, notificationService, invoiceCostService, packageTypeService
) {

  const vm = this;

  const ERROR_FIELD_NUMBER = 'number';

  const invoiceId = +$stateParams.invoiceId;
  const type = +$stateParams.type;
  const { bookingIds } = $stateParams;
  const clientId = +$stateParams.clientId;

  vm.withoutBooking = !bookingIds;
  vm.validationService = validationService;
  vm.bookingsShiftsCost = {};
  vm.hasDiscount = false;
  vm.discountPercentage = 0;

  let isFormSubmitted = false;

  vm.$onInit = () => {
    packageTypeService.checkProPackage();
    initInvoice();
  };

  vm.isNewInvoice = () => !invoiceId;

  vm.goBack = function() {
    if (window.history.length > 1) {
      window.history.back();
    } else if (vm.invoice) {
      $state.go('public.security-manager.client.invoice.list', { clientId });
    }
  };

  vm.onSubmit = function() {
    if (isFormSubmitted) { return; }
    if (vm.invoice.lastVersion.bookings[0].invoiceElements.length === 0) {
      notificationService.notifyInfo("Invoice can't be empty");
      return;
    }
    if (vm.withoutBooking && !(vm.invoice.lastVersion.bookings[0].from && vm.invoice.lastVersion.bookings[0].to)) {
      notificationService.notifyError('Please select invoice period');
      return;
    }
    if (!$scope.InvoiceForm.$valid || !vm.invoice.lastVersion.bookings[0].name || !vm.service.name) {
      notificationService.notifyError(`Please enter fields: ${getEmptyFieldsList()}`);
      return;
    }
    isFormSubmitted = true;
    invoiceSaver.save(vm.invoice, +vm.discountPercentage, {
      type,
      service: vm.service
    })
    .then(() => vm.goBack())
    .catch(function(response) {
      isFormSubmitted = false;
      if (response.data.field === ERROR_FIELD_NUMBER) {
        notificationService.notifyError('Please enter a valid Invoice number');
      } else {
        notificationService.notifyError(response.data.error);
      }
    });
  };

  vm.getSubTotal = () => invoiceCostService.getSubTotal(vm.invoice);

  vm.getTotalVat = () => invoiceCostService.getTotalVat(vm.invoice, vm.discountPercentage);

  vm.getDiscount = () => invoiceCostService.getDiscount(vm.invoice, vm.discountPercentage);

  vm.getTotalCost = () => invoiceCostService.getTotalCost(vm.invoice, vm.discountPercentage);

  vm.showDiscount = () => vm.hasDiscount = true;

  vm.removeDiscount = function() {
    vm.hasDiscount = false;
    vm.discountPercentage = 0;
  };

  vm.removeBooking = booking => vm.invoice.lastVersion.bookings = _.without(vm.invoice.lastVersion.bookings, booking);

  vm.canRemoveBooking = () => vm.invoice.lastVersion.bookings.length > 1;

  vm.updateInvoice = () => {
    return confirmService.confirm(
      'Confirm update',
      `This action will update ${vm.smallNameType} to timeline state. All changes will be deleted.`,
      'Yes',
      () =>
        vm.invoice.refresh().then(() => window.location.reload())
    );
  };

  vm.showChanges = () => {
    return $state.go('public.security-manager-invoice-history', { invoiceId: vm.invoice.id, invoiceVersionId: 'new' });
  };

  vm.isClockInShiftPresent = function() {
    if (!vm.invoice) { return; }
    return _.some(vm.invoice.lastVersion.bookings, (booking) => {
      return _.some(booking.invoiceElements, (invoiceElementShift) => {
        return invoiceElementShift.element.enableClockIn;
      });
    });
  };

  const initInvoice = function() {
    if (invoiceId) {
      loadInvoice();
    } else {
      if (bookingIds) {
        initNewMultiInvoice();
      } else {
        initNewInvoice();
      }
    }
  };

  const loadInvoice = function() {
    ClientInvoice.get(invoiceId).then(function(invoice) {
      vm.invoice = invoice;
      vm.hasDiscount = invoice.lastVersion.discountPercentage > 0;
      vm.discountPercentage = invoice.lastVersion.discountPercentage;
      if (invoice.lastVersion.type !== type) {
        initCreateData();
      } else {
        initEditData();
      }
      if (invoice.lastVersion.service.id) {
        loadService(invoice.lastVersion.service.clientId, invoice.lastVersion.service.id);
      } else {
        vm.service = invoice.lastVersion.service;
      }
      Booking.getLastUpdatedAt(bookingIds).then(data => {
        checkBookingWasUpdated(moment(data.lastUpdatedAt, dateService.getIsoFullDateFormatWithT()));
      });
    });
  };

  const initNewInvoice = function() {
    vm.invoice = new ClientInvoice({
      clientId,
      lastVersion: {
        withVat: true,
        bookings: [{ clientId, shifts: [], addAdditionalCosts: [] }]
      }
    });
    vm.service = {};
    SecurityProviderSetting.getCurrent().then(settings => {
      vm.invoice.lastVersion.vatPercentage = settings.vatPercentage || 0;
    });
    initCreateData();
  };

  const initNewMultiInvoice = function() {
    vm.invoice = new ClientInvoice({
      clientId,
      lastVersion: {
        withVat: true,
        bookings: []
      }
    });
    initCreateData();
    Booking.invoice(bookingIds).then(function(bookings) {
      loadService(clientId, bookings[0].serviceId);
      _.each(bookings, booking => vm.invoice.lastVersion.bookings.push(prepareBooking(booking)));
    });
    SecurityProviderSetting.getCurrent()
      .then(settings => vm.invoice.lastVersion.vatPercentage = settings.vatPercentage || 0)
      .catch(error => Rollbar.log(`Client invoice creation on ${window.location.href}`, error));
  };

  const loadService = (clientId, serviceId) => {
    return ClientService.get({ id: serviceId, clientId }).then(service => vm.service = service);
  };

  const initCreateData = function() {
    vm.smallNameType = invoiceType.getSmallName(type);
    vm.title = `Creating new ${vm.smallNameType}`;
    vm.buttonText = `Create ${vm.smallNameType}`;
    return ClientInvoice.getNextNumber({ type }).then(number => vm.invoice.lastVersion.invoiceNumber = number);
  };

  const initEditData = function() {
    vm.smallNameType = invoiceType.getSmallName(type);
    vm.title = `Editing ${vm.smallNameType}`;
    vm.buttonText = `Save ${vm.smallNameType}`;
  };

  const prepareBooking = function(booking) {
    _.each(booking.shifts, shift => {
      return _.remove(shift.employees, shiftEmployee => {
        return !bookingShiftEmployeeAssignStatus.countsAsAssigned(shiftEmployee.assignStatus);
      });
    });
    if (!booking.additionalCosts) { booking.additionalCosts = []; }
    return booking;
  };

  const checkBookingWasUpdated = function(lastBookingUpdatedAt) {
    const lastUpdatedAt = moment(_.max(_.map(vm.invoice.lastVersion.bookings, 'updatedAt')), dateService.getIsoFullDateFormatWithT());
    return vm.isShownUpdateMessage = lastBookingUpdatedAt.isAfter(lastUpdatedAt);
  };

  const getEmptyFieldsList = function() {
    const formFields = _.uniq(_.map($scope.InvoiceForm.$error.required, error => error.$name));
    if (!vm.invoice.lastVersion.bookings[0].name) { formFields.push('Invoice title'); }
    if (!vm.service.name) { formFields.push('Service name'); }
    return formFields.join(', ');
  };

  return vm;

};

angular.module('public.security-manager.client.show.invoice')
  .controller('ClientShowInvoiceFormController', dependencies.concat(ClientShowInvoiceFormController));
