/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('gccs-ui').directive('gccsPdfViewer', () => {
  return {
    restrict: 'A',
    template: '<ng-pdf template-url="gccs-ui/pdf-viewer/template"></ng-pdf>',
    controller: 'GccsPdfViewerController',
    controllerAs: 'gccsPdfViewerCtlr',
    scope: {
      pdfUrl: '<',
      pdfDownloadUrl: '<',
      withoutEdit: '<',
      editCb: '&',
      withoutRemove: '<',
      removeCb: '&'
    }
  };
});
