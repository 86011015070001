/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsPopupEmployeePayrollEdit = popupDirectiveConfig => {
  return popupDirectiveConfig.configure({
    bodyTemplate: require('./template.pug'),
    scope: {
      payroll: '<'
    }
  });
};

angular.module('popup.employee-payroll')
  .directive('gccsPopupEmployeePayrollEdit', ['popupDirectiveConfig', gccsPopupEmployeePayrollEdit]);
