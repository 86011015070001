/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$element', '$timeout', '$q', 'Employee', 'FormValidator', 'currentUserService',
  'bookingShiftValidationService', 'dateService', 'elementService', 'notificationService', 'pluralizeService',
  'employeeHolidayService', 'employeeType', 'EmployeeEventOptions', 'employeeEventType'
];

const EmployeeTimelineDayOffController = function(
  $scope, $element, $timeout, $q, Employee, FormValidator, currentUserService,
  bookingShiftValidationService, dateService, elementService, notificationService, pluralizeService,
  employeeHolidayService, employeeType, EmployeeEventOptions, employeeEventType
) {

  const vm = this;

  const MINIMUM_HOLIDAY_HOURS = 12;

  let isFormSubmitted = false;
  let shownInput = null;

  const employee = currentUserService.getCurrentProfile();

  vm.isShownForm = false;
  vm.dateFormat = dateService.getDateFormat();
  vm.isOpenedToggleButton = false;
  vm.takenSickDays = null;

  vm.$onInit = () => {
    initOptions();
    initEmployers();
    $timeout(initCustomErrors);
  };

  vm.toggleToggleButton = function () {
    vm.isOpenedToggleButton = !vm.isOpenedToggleButton;
  };

  vm.closeToggleButton = function () {
    vm.isOpenedToggleButton = false;
  };

  vm.toggleForm = function(option) {
    vm.eventOptions.setSelectedOption(option);
    if (vm.isShownForm) {
      vm.hideForm();
      focusField('name');
    } else {
      initDayOff();
      vm.isShownForm = true;
    }
  };

  vm.hideForm = () => {
    vm.isShownForm = false;
    vm.eventOptions.resetSelectedOption();
  };

  vm.showInput = function(fieldName) {
    shownInput = fieldName;
    return focusField(fieldName);
  };

  vm.isShownInput = fieldName => shownInput === fieldName;

  vm.hideInput = () => {
    return $timeout(() => shownInput = null);
  };

  vm.onSubmit = function() {
    if (isFormSubmitted) { return; }
    if ($scope.DayOffForm.$invalid) {
      if ($scope.DayOffForm.name.$invalid) {
        return focusField('name');
      } else if ($scope.DayOffForm.paidDays != null ? $scope.DayOffForm.paidDays.$invalid : undefined) {
        return notificationService.notifyError('Number of paid days exceeds the period');
      }
    } else {
      if (vm.isEmployed() && vm.dayOff.paidDays > 0 && vm.tookMoreHolidaysThanLimit()) {
        notificationService.notifyInfo(vm.getExceededText());
        return;
      }
      isFormSubmitted = true;
      vm.dayOff.startTime = collectTime('start');
      vm.dayOff.endTime = collectTime('end');
      const dayOffPromises = getDayOffPromises();
      return $q.all(dayOffPromises).then(function() {
        $scope.onSave();
        vm.hideForm();
        if (!dayOffPromises.length && vm.isEmployed() && vm.dayOff.paidDays > 0 && vm.tookMoreHolidays()) {
          notificationService.notifyInfo('Warning! You took more holidays than had.');
        }
        return isFormSubmitted = false;
      }).catch(function() {
        isFormSubmitted = false;
        return notificationService.notifyError('Pick another range of dates');
      });
    }
  };

  vm.getDateErrors = () => bookingShiftValidationService.getDateErrors(hoursData);

  vm.getStartTimeErrors = () => bookingShiftValidationService.getStartTimeErrors(hoursData);

  vm.getEndTimeErrors = () => bookingShiftValidationService.getEndTimeErrors(hoursData);

  vm.getMaxPaidDays = function() {
    if (!vm.isShownForm) { return; }
    const startTimeMoment = moment(collectTime('start'), dateService.getIsoFullDateFormat());
    const endTimeMoment = moment(collectTime('end'), dateService.getIsoFullDateFormat()).add(1, 'minute');
    const maxPaidDays = Math.floor(endTimeMoment.diff(startTimeMoment, 'days', true));
    if (maxPaidDays > 0) {
      return maxPaidDays;
    } else {
      const hoursDiff = endTimeMoment.diff(startTimeMoment, 'hours', true);
      return hoursDiff >= MINIMUM_HOLIDAY_HOURS ? 1 : 0;
    }
  };

  vm.pluralizeDays = daysCount => pluralizeService.simple(daysCount, 'day');

  vm.tookMoreHolidays = () => getRemainingHolidayHours() < 0;

  vm.tookMoreHolidaysThanLimit = function() {
    const { holidaysLimit } = vm.selectedProviderEmployee.securityProvider;
    if ((holidaysLimit === null) || (holidaysLimit === undefined)) {
      return false;
    }
    return ((__guard__(vm.holidaysData != null ? vm.holidaysData.takenHolidays : undefined, x => x.hours) || 0) + getPaidHours()) >
      (holidaysLimit * employeeHolidayService.getDefaultHolidaysHours());
  };

  vm.isEmployed = () => {
    if (!vm.selectedProviderEmployee) { return false; }
    return employeeType.isEmployed(vm.selectedProviderEmployee.employmentType);
  };

  vm.getTakenText = function() {
    const takenDays = vm.holidaysData.takenHolidays.days + vm.getPaidDays();
    const takenHours = vm.holidaysData.takenHolidays.hours + getPaidHours();
    return `${pluralizeService.simple(takenDays, 'day')} (${takenHours} h.)`;
  };

  vm.getSickDaysText = function() {
    if (vm.takenSickDayHours >= 0) {
      const takenSickDays = Math.floor(vm.takenSickDayHours / employeeHolidayService.getDefaultSickDaysHours());
      return `${pluralizeService.simple(takenSickDays, 'day')} (${vm.takenSickDayHours} h.)`;
    }
  };

  vm.getRemainingText = () => {
    return `${pluralizeService.simple(getRemainingHolidayDays(), 'day')} (${getRemainingHolidayHours()} h.)`;
  };

  vm.getExceededText = () => {
    return `Year limit of ${vm.pluralizeDays(vm.selectedProviderEmployee.securityProvider.holidaysLimit)} exceeded`;
  };

  vm.onProviderSelect = function(selected) {
    vm.holidaysData = null;
    vm.selectedProviderEmployee = _.find(vm.employeesByProviders, ['securityProvider.id', selected]);
    if (!vm.selectedProviderEmployee) { return; }
    if (employeeEventType.isDayOffEventType(vm.eventOptions.getSelectedType()) && vm.isEmployed()) {
      Employee.getHolidaysData(vm.selectedProviderEmployee.id).then(holidaysData => vm.holidaysData = holidaysData);
    }
    if (employeeEventType.isSickDayEventType(vm.eventOptions.getSelectedType())) {
      Employee.get(vm.selectedProviderEmployee.id).then(employee => {
        vm.takenSickDayHours = employee.takenSickDayHours;
      });
    }
  };

  vm.getPaidDays = () => vm.dayOff.paidDays || 0;

  vm.isAvailabilityEventType = function() {
    return employeeEventType.isAvailabilityEventType(vm.eventOptions.getSelectedType());
  };

  vm.isSickDayEventType = function() {
    return employeeEventType.isSickDayEventType(vm.eventOptions.getSelectedType());
  };

  vm.isDayOffEventType = function() {
    return employeeEventType.isDayOffEventType(vm.eventOptions.getSelectedType());
  };

  vm.getPlaceholder = function() {
    if (vm.isSickDayEventType()) {
      return 'Sick day description...';
    } else if (vm.isDayOffEventType()) {
      return 'Name of day off...';
    } else {
      return 'Add name...';
    }
  };

  const initOptions = function() {
    vm.eventOptions = new EmployeeEventOptions();
  };

  const getDayOffPromises = function() {
    if (+vm.dayOff.securityProviderId === 0) {
      return _.map(vm.employeesByProviders, (employeeByProvider) => {
        let dayOffCopy = angular.copy(vm.dayOff);
        dayOffCopy.employeeId = employeeByProvider.id;
        return dayOffCopy.save();
      })
    } else {
      vm.dayOff.employeeId = vm.selectedProviderEmployee.id;
      return vm.dayOff.save();
    }
  };

  const getRemainingHolidayDays = () => {
    return employeeHolidayService.getRemainingHolidayDays(
      vm.holidaysData.accumulatedHours,
      vm.holidaysData.takenHolidays.hours + getPaidHours()
    );
  };

  const getRemainingHolidayHours = () => {
    return vm.holidaysData.accumulatedHours - (vm.holidaysData.takenHolidays.hours + getPaidHours());
  };

  const getPaidHours = () => vm.getPaidDays() * employeeHolidayService.getDefaultHolidaysHours();

  const initDayOff = function() {
    const startTime = moment().startOf('day');
    const endTime = startTime.clone().endOf('day');
    const entityModel = vm.eventOptions.getEntity();
    vm.securityProviders['0'] = 'All employers';
    vm.dayOff = new entityModel({employeeId : currentUserService.getCurrentProfile().id});
    vm.dayOff.startDate = startTime;
    vm.dayOff.startDayTime = startTime.format(dateService.getTimeFormat());
    vm.dayOff.endDate = endTime;
    vm.dayOff.endDayTime = endTime.format(dateService.getTimeFormat());
    vm.dayOff.securityProviderId = Object.keys(vm.securityProviders)[0];
    if (employeeEventType.isDayOffEventType(vm.eventOptions.getSelectedType())) {
      vm.dayOff.paidDays = 0;
    }
  };

  const initEmployers = function () {
    vm.securityProviders = {};
    Employee.withSecurityProviders(employee.id).then((employees) => {
      vm.employeesByProviders = employees;
      employees.forEach((employee) => {
        vm.securityProviders[employee.securityProvider.id] = employee.securityProvider.name;
      });
    });
  };

  const hoursData = () => {
    const hoursObj = {
      minHours: 1
    };
    if (!vm.isAvailabilityEventType()) {
      hoursObj.maxHours = 24 * 31;
    }
    return hoursObj;
  };

  const focusField = fieldName => {
    return $timeout(() => elementService.focus($element[0].querySelector(`[name='${fieldName}']`)));
  };

  const addCustomError = function(fieldName, errors) {
    const formValidator = new FormValidator($scope.DayOffForm);
    return _.each(errors, messageData => {
      return formValidator.addCustomValidator(
        fieldName,
        messageData.error,
        messageData.condition,
        messageData.compositeField
      );
    });
  };

  const collectTime = type => {
    return [vm.dayOff[`${type}Date`].format(dateService.getIsoDateFormat()), vm.dayOff[`${type}DayTime`]].join(' ');
  };

  const initCustomErrors = function() {
    addCustomError('startDate', vm.getDateErrors());
    addCustomError('endDate', vm.getDateErrors());
    addCustomError('startDayTime', vm.getStartTimeErrors());
    addCustomError('endDayTime', vm.getEndTimeErrors());
  };

  return vm;

};

angular.module('public.employee.timeline')
  .controller('EmployeeTimelineDayOffController', dependencies.concat(EmployeeTimelineDayOffController));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
