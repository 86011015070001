const gccsPopupClientLocationForm = {
  controller: 'PopupClientLocationFormController',
  template: require('./template.pug'),
  require: {
    gccsPopupsLayout: '^gccsPopupsLayout'
  },
  bindings: {
    location: '<',
    isEditable: '<'
  }
};

angular.module('shared-components.popup.client').component('gccsPopupClientLocationForm', gccsPopupClientLocationForm);
