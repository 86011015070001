/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').service('redirectService', ['$state', 'currentUserService', ($state, currentUserService) => {
  return {
    redirectToMain() {
      let state;
      const stateParams = {};
      if (currentUserService.isSecurityManagerLogged()) {
        state = 'public.security-manager.schedule-manager.timeline.booking';
      } else if (currentUserService.isEmployeeLogged()) {
        state = 'public.employee.timeline';
      } else if (currentUserService.isClientManagerLogged()) {
        state = 'public.client-manager.timeline';
      } else {
        state = 'sign.login';
      }
      window.location.href = $state.href(state, stateParams, { absolute: true });
    }
  }
}]);
