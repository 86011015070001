/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['AppModel'];

const EmployeePayroll = AppModel => {

  class EmployeePayroll extends AppModel {
    static initClass() {
      this.configure({url : '/employee_payrolls', name : 'payroll'});
    }

    static getIncome(params = {}) {
      return this.$get(this.resourceUrl('income'), params);
    }

    static syncPayrollWithHumyn(params) {
      return this.$post(this.resourceUrl('sync/humyn'), params);
    }

    exportUrl(params = {}) {
      const getParams = _.map(params, (value, key) => `${key}=${value}`);
      return `${this.getUrl()}/export.csv?${getParams.join('&')}`;
    }
  }

  EmployeePayroll.initClass();
  return EmployeePayroll;
};

angular.module('data.employee-payroll').factory('EmployeePayroll', dependencies.concat(EmployeePayroll));
