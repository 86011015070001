/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsSecurityManagerEmailRow = {
  template: require('./template.pug'),
  controller: 'SecurityManagerHrEmailRowController',
  controllerAs: 'employeeEmailRowCtrl',
  bindings: {
    buttonName: '@',
    buttonClass: '@',
    email: '<',
    highlightString: '<',
    showStatusFor: '<',
    onButtonClick: '&'
  }
};

angular.module('public.security-manager.hr.email').component('gccsSecurityManagerEmailRow', gccsSecurityManagerEmailRow);
