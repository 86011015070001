/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').service('bookingShiftUnpaidBreak', function() {

  const UNPAID_BREAK_STEP = 15;
  const MAX_VALUE = 60;
  const MINUTES_IN_HOUR = 60;

  const getNameOfMinutes = function(value) {
    if (!value) {
      return 'None';
    } else if (value < MINUTES_IN_HOUR) {
      return `${value} min`;
    } else {
      return `${Math.floor(value / MINUTES_IN_HOUR)} hour`;
    }
  };

  const names = {};
  let minutes = 0;
  while (minutes <= MAX_VALUE) {
    names[minutes] = getNameOfMinutes(minutes);
    minutes += UNPAID_BREAK_STEP;
  }

  return {
    getNames() {
      return names;
    },

    getName(breakTime) {
      return names[breakTime];
    },

    getFullName(value) {
      value -= 0;
      const name = names[value];
      if (value === 0) {
        return name;
      } else {
        return `Yes, ${name}`;
      }
    },

    getNameOfMinutes
  };
});
