/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', '$timeout', 'bookingShiftEmployeeAssignStatus', 'dateService', 'confirmService',
  'resourceIcon', 'cableService', 'currentUserService', 'payRateVisibilityService', 'BookingShiftEmployee',
  'bookingShiftClockInErrorsService'
];

const PopupBookingShiftFormEmployeesInvitedController = function(
  $scope, $timeout, bookingShiftEmployeeAssignStatus, dateService, confirmService,
  resourceIcon, cableService, currentUserService, payRateVisibilityService, BookingShiftEmployee,
  bookingShiftClockInErrorsService
) {

  const vm = this;

  vm.resource = $scope.shift.resource;
  vm.allShiftEmployeesExceptSplits = [];
  vm.resourceIcon = resourceIcon;
  vm.shift = $scope.shift;
  vm.isShownEditPopup = false;
  vm.isShownClockInEdit = false;
  vm.isShownClockOutEdit = false;

  let shownSplitEditShift = undefined;
  let fakeId = 0;

  vm.$onInit = function() {
    $scope.conflictedShiftEmployeeManager.loadAll();
    subscribeToBookingTimeline();
    initAllShiftEmployeesExceptSplits();
  };

  vm.getInvitedEmployees = () => {
    return _.filter($scope.shiftEmployeeManager.entities, shiftEmployee => {
      return bookingShiftEmployeeAssignStatus.countsAsInvited(shiftEmployee.assignStatus);
    });
  };

  vm.getAvatarUrl = shiftEmployee => {
    // strange bug
    return (shiftEmployee.employee.user || shiftEmployee).thumbAvatar.url;
  };

  vm.getDuration = function(shiftEmployee) {
    const startTime = moment(shiftEmployee.startTime, dateService.getIsoFullDateFormat());
    const endTime = moment(shiftEmployee.endTime, dateService.getIsoFullDateFormat());
    return endTime.diff(startTime, 'hours');
  };

  vm.isShownClockIn = function(shiftEmployee) {
    if (!vm.isEmployeeAssigned(shiftEmployee)) { return false; }
    const startTime = moment(shiftEmployee.startTime, dateService.getIsoFullDateFormat());
    return moment().isSameOrAfter(startTime.add(-15, 'minutes'));
  };

  vm.getStatusIcoClass = function(shiftEmployee) {
    let classes = '';
    if (vm.shift.enableClockIn) {
      classes += 'mod-ico';
      if (isMissedClockIn(shiftEmployee)) {
        classes += ' mod-red';
      } else if (shiftEmployee.clockOutTime) {
        if (bookingShiftClockInErrorsService.isClockInLate(shiftEmployee) ||
          bookingShiftClockInErrorsService.wasClockOutEarly(shiftEmployee) ||
          bookingShiftClockInErrorsService.wasClockOutLate(shiftEmployee) ||
          bookingShiftClockInErrorsService.wasClockOutMissed(shiftEmployee)
        ) {
          classes += ' mod-red';
        } else {
          classes += ' mod-green';
        }
      }
    }
    return classes
  };

  vm.assignEmployee = shiftEmployee => {
    vm.hideSettingsDropdown(shiftEmployee);
    return $scope.inviteEmployeeCb({shiftEmployee});
  };

  vm.removeShiftEmployee = shiftEmployeeToRemove => {
    vm.hideSettingsDropdown(shiftEmployeeToRemove);
    return $scope.shiftEmployeeManager.removeEntity(shiftEmployeeToRemove, () => onRemove(shiftEmployeeToRemove));
  };

  vm.unassignEmployee = shiftEmployee => {
    vm.hideSettingsDropdown(shiftEmployee);
    return confirmService.confirm('Confirm unassign', 'Do you want to unassign this employee?', 'Yes', () => {
      return shiftEmployee.unassign().then(function () {
        vm.allShiftEmployeesExceptSplits = _.without(vm.allShiftEmployeesExceptSplits, shiftEmployee);
        $scope.shiftEmployeeManager._removeEntity($scope.shiftEmployeeManager.findEntity(shiftEmployee));
        return onRemove(shiftEmployee);
      });
    });
  };

  vm.blowOut = function(shiftEmployee) {
    vm.hideSettingsDropdown(shiftEmployee);
    confirmService.confirm('Confirm blow out', 'This employee was blew out?', 'Yes', () => {
      shiftEmployee.assignStatus = bookingShiftEmployeeAssignStatus.getBlowOut();
      shiftEmployee.save().then(function () {
        vm.allShiftEmployeesExceptSplits = _.without(vm.allShiftEmployeesExceptSplits, shiftEmployee);
        $scope.shiftEmployeeManager._removeEntity($scope.shiftEmployeeManager.findEntity(shiftEmployee));
        onRemove(shiftEmployee);
      });
    });
  };

  vm.splitFor = function(shiftEmployee) {
    const splitShiftEmployee = $scope.shiftEmployeeManager.createEntity();
    splitShiftEmployee.splitForId = shiftEmployee.id;
    splitShiftEmployee.fakeId = ++fakeId;
    splitShiftEmployee.chargeRate = shiftEmployee.chargeRate;
    splitShiftEmployee.contractorPayRate = $scope.shift.contractorPayRate;
    splitShiftEmployee.employedPayRate = $scope.shift.employedPayRate;
    if (shiftEmployee.startTime < $scope.shift.startTime || shiftEmployee.endTime > $scope.shift.endTime) {
      splitShiftEmployee.startTime = $scope.shift.startTime;
      splitShiftEmployee.endTime = $scope.shift.endTime;
    } else if (shiftEmployee.startTime === $scope.shift.startTime) {
      splitShiftEmployee.startTime = getStartTimeForSplitShift(shiftEmployee);
      splitShiftEmployee.endTime = $scope.shift.endTime;
    } else {
      splitShiftEmployee.startTime = $scope.shift.startTime;
      splitShiftEmployee.endTime = shiftEmployee.startTime;
    }
    shiftEmployee.splitShifts.push(splitShiftEmployee);
    return $timeout(() => vm.showSplitEditShift(splitShiftEmployee));
  };

  vm.getSplitShifts = function(shiftEmployee) {
    if (bookingShiftEmployeeAssignStatus.countsAsAssigned(shiftEmployee.assignStatus)) {
      // TODO temporary workaround; find out why splitShift lose their prototype after assigning
      _.each(shiftEmployee.splitShifts, (splitShift, index) => {
        if (!(splitShift instanceof BookingShiftEmployee)) {
          shiftEmployee.splitShifts[index] = new BookingShiftEmployee(splitShift);
        }
      });
      return _.sortBy([shiftEmployee].concat(shiftEmployee.splitShifts), 'startTime');
    } else {
      return _.sortBy(shiftEmployee.splitShifts, 'startTime');
    }
  };

  vm.canInviteEmployees = () => $scope.editable && !currentUserService.isClientManagerLogged();

  vm.canSeeChargeRate = shiftEmployee => payRateVisibilityService.isShownChargeRate(shiftEmployee.chargeRate);

  vm.canSeePayRate = shiftEmployee => {
    if (shiftEmployee.employeeId) {
      return payRateVisibilityService.isShownPayRate(shiftEmployee.payRate);
    } else {
      return payRateVisibilityService.isShownPayRate(shiftEmployee.contractorPayRate, shiftEmployee.employedPayRate);
    }
  };

  vm.showSplitEditShift = shiftEmployee => {
    vm.hideSettingsDropdown(shiftEmployee);
    return shownSplitEditShift = shiftEmployee;
  };

  vm.closeSplitEditShift = function(shiftEmployee) {
    vm.hideSettingsDropdown(shiftEmployee);
    if (vm.isShownSplitEditShift(shiftEmployee)) {
      shownSplitEditShift = undefined;
      if (shiftEmployee.splitForId) {
        if (shiftEmployee.id === undefined) {
          return onRemove(shiftEmployee);
        } else {
          return findSplitFor(shiftEmployee).save();
        }
      }
    }
  };

  vm.isShownSplitEditShift = shiftEmployee => {
    return shownSplitEditShift &&
      (
        (shownSplitEditShift.fakeId && (shownSplitEditShift.fakeId === shiftEmployee.fakeId)) ||
        (shownSplitEditShift.id === shiftEmployee.id)
      );
  };

  vm.canInviteMoreEmployees = () => {
    return $scope.editable && getNotDeclinedEmployees().length < $scope.shift.requiredEmployeesCount;
  };

  vm.assignedEmployeesCount = () => {
    return _.filter(vm.allShiftEmployeesExceptSplits, shiftEmployee => {
      return bookingShiftEmployeeAssignStatus.countsAsAssigned(shiftEmployee.assignStatus);
    }).length;
  };

  vm.getSplitShiftGroups = () => {
    return _.filter(vm.allShiftEmployeesExceptSplits, shiftEmployee => {
      return bookingShiftEmployeeAssignStatus.countsAsAssigned(shiftEmployee.assignStatus) ||
        (shiftEmployee.splitShifts && (shiftEmployee.splitShifts.length > 0));
    });
  };

  vm.isEmployeeAssigned = function(shiftEmployee) {
    return !!shiftEmployee.employeeId;
  };

  vm.isShownClockInMenuItem = function(shiftEmployee) {
    return shiftEmployee.employee && vm.shift.enableClockIn && !shiftEmployee.clockInTime && !shiftEmployee.clockOutTime;
  };

  vm.isShownClockOutMenuItem = function(shiftEmployee) {
    return shiftEmployee.employee && vm.shift.enableClockIn && shiftEmployee.clockInTime && !shiftEmployee.clockOutTime;
  };

  vm.showSettingsDropdown = function(shiftEmployee) {
    shiftEmployee.isShownSettingsDropdown = true;
  };

  vm.hideSettingsDropdown = function(shiftEmployee) {
    shiftEmployee.isShownSettingsDropdown = false;
  };

  vm.clockInToShift = function(shiftEmployee) {
    if (!vm.isShownClockIn(shiftEmployee)) { return; }
    vm.hideSettingsDropdown(shiftEmployee);
    $timeout(function() {
      vm.clockIn(shiftEmployee);
    });
  };

  vm.clockOutOfShift = function(shiftEmployee) {
    vm.hideSettingsDropdown(shiftEmployee);
    $timeout(function() {
      vm.clockOut(shiftEmployee);
    });
  };

  vm.clockIn = function(shiftEmployee) {
    vm.editedShiftEmployee = shiftEmployee;
    vm.isShownClockInEdit = true;
    vm.isShownClockOutEdit = false;
    vm.clockInEditTitle = 'Add clock in time';
    vm.clockInEditButton = 'Clock in';
    vm.clockEditSuccessMessage = `${shiftEmployee.employee.user.fullName} was clocked in to shift`;
    openEditPopup();
  };

  vm.clockOut = function(shiftEmployee) {
    vm.editedShiftEmployee = shiftEmployee;
    vm.isShownClockInEdit = false;
    vm.isShownClockOutEdit = true;
    vm.clockInEditTitle = 'Add clock out time';
    vm.clockInEditButton = 'Clock out';
    vm.clockEditSuccessMessage = `${shiftEmployee.employee.user.fullName} was clocked out of shift`;
    openEditPopup();
  };

  vm.editClockInOut = function(shiftEmployee) {
    if (vm.isShownEditPopup) {
      vm.closeEditPopup();
    } else {
      vm.editedShiftEmployee = shiftEmployee;
      vm.isShownClockInEdit = true;
      vm.clockInEditButton = 'Save';
      if (!shiftEmployee.clockOutTime) {
        vm.isShownClockOutEdit = false;
        vm.clockInEditTitle = 'Edit clock in time';
      } else {
        vm.isShownClockOutEdit = true;
        vm.clockInEditTitle = 'Edit clock in/out time';
      }
      openEditPopup();
    }
  };

  vm.closeEditPopup = function() {
    vm.isShownEditPopup = false;
  };

  const onRemove = function(removedShiftEmployee) {
    const splitForShiftEmployee = findSplitFor(removedShiftEmployee);
    if (splitForShiftEmployee) {
      const splitShift = _.find(splitForShiftEmployee.splitShifts, shiftEmployee => {
        return shiftEmployee.id === removedShiftEmployee.id;
      });
      splitForShiftEmployee.splitShifts = _.without(splitForShiftEmployee.splitShifts, splitShift);
      return initAllShiftEmployeesExceptSplits();
    }
  };

  const initAllShiftEmployeesExceptSplits = () => {
    return vm.allShiftEmployeesExceptSplits = _.filter($scope.shiftEmployeeManager.entities, shiftEmployee => !shiftEmployee.splitForId);
  };

  const checkedUpdateShiftEmployee = function(data) {
    if ($scope.shift.id === data.shift_id) {
      return $scope.shiftEmployeeManager.onLoad(function() {
        _.each($scope.shiftEmployeeManager.entities, (shiftEmployee) => {
          shiftEmployee.splitShifts = _.map(shiftEmployee.splitShifts, (splitShift) => {
            return new BookingShiftEmployee(splitShift);
          });
        });
        if (data.split_for_id) {
          return updateSplitShiftEmployee(data.shift_employee_id, data.split_for_id);
        } else if (data.assign_status === bookingShiftEmployeeAssignStatus.getAssignedStatus()) {
          return updateShiftEmployee(data.shift_employee_id);
        }
      });
    }
  };

  const getStartTimeForSplitShift = function(shiftEmployee) {
    if (!shiftEmployee.splitShifts.length) { return shiftEmployee.endTime; }
    const notFakeSplits = _.filter(shiftEmployee.splitShifts, splitShift => !splitShift.fakeId);
    const sortedByTimeShifts = _.sortBy(notFakeSplits, 'startTime');
    return _.last(sortedByTimeShifts).endTime;
  };

  const subscribeToBookingTimeline = () => {
    const bookingTimelineChannel = cableService.getBookingTimelineChannel();
    if (bookingTimelineChannel) {
      ['shift_employee', 'shift_employee_destroyed'].forEach((cableUpdateEvent) => {
        bookingTimelineChannel.addCallbackWithCleanup($scope, cableUpdateEvent, checkedUpdateShiftEmployee);
      });
    }
  };

  const updateSplitShiftEmployee = function(shiftEmployeeId, splitForId) {
    const updatedShiftEmployeeFor = _.find($scope.shiftEmployeeManager.entities, shiftEmployee => splitForId === shiftEmployee.id);
    const updatedSplitShift = _.find((updatedShiftEmployeeFor != null ? updatedShiftEmployeeFor.splitShifts : undefined) || [], splitShift => splitShift.id === shiftEmployeeId);
    const oldShiftEmployeeFor = _.find(vm.allShiftEmployeesExceptSplits, shiftEmployee => splitForId === shiftEmployee.id);
    const oldShiftEmployeeIndex = _.findIndex((oldShiftEmployeeFor != null ? oldShiftEmployeeFor.splitShifts : undefined) || [], splitShift => splitShift.id === shiftEmployeeId);
    if (oldShiftEmployeeIndex !== -1) {
      if (updatedSplitShift) {
        return oldShiftEmployeeFor.splitShifts[oldShiftEmployeeIndex] = updatedSplitShift;
      } else {
        return oldShiftEmployeeFor.splitShifts.splice(oldShiftEmployeeIndex, 1);
      }
    } else if (updatedSplitShift) {
      return oldShiftEmployeeFor.splitShifts.push(updatedSplitShift);
    }
  };

  const updateShiftEmployee = function(shiftEmployeeId) {
    const splitShifts = _.flatten(_.map($scope.shiftEmployeeManager.entities, shiftEmployee => shiftEmployee.splitShifts));
    const allShifts = $scope.shiftEmployeeManager.entities.concat(splitShifts);
    const updatedShiftEmployee = _.find(allShifts, shiftEmployee => shiftEmployee.id === shiftEmployeeId);
    const oldShiftEmployeeIndex = _.findIndex(vm.allShiftEmployeesExceptSplits, shiftEmployee => {
      return shiftEmployee.id === shiftEmployeeId;
    });
    if (oldShiftEmployeeIndex !== -1) {
      if (updatedShiftEmployee) {
        return vm.allShiftEmployeesExceptSplits[oldShiftEmployeeIndex] = updatedShiftEmployee;
      } else {
        return vm.allShiftEmployeesExceptSplits.splice(oldShiftEmployeeIndex, 1);
      }
    } else if (updatedShiftEmployee) {
      return vm.allShiftEmployeesExceptSplits.push(updatedShiftEmployee);
    }
  };

  const findSplitFor = shiftEmployeeToFind => {
    return _.find($scope.shiftEmployeeManager.entities, shiftEmployee => {
      return shiftEmployee.id === shiftEmployeeToFind.splitForId;
    });
  };

  const getNotDeclinedEmployees = () => {
    return _.filter($scope.shiftEmployeeManager.entities, shiftEmployee => {
      return bookingShiftEmployeeAssignStatus.countsAsInvited(shiftEmployee.assignStatus) ||
        bookingShiftEmployeeAssignStatus.countsAsAssigned(shiftEmployee.assignStatus);
    });
  };

  const openEditPopup = function() {
    vm.isShownEditPopup = true;
  };

  const isShiftEnded = function(shiftEmployee) {
    return moment().isAfter(moment(shiftEmployee.endTime, dateService.getIsoFullDateFormat()));
  };

  const isMissedClockIn = function(shiftEmployee) {
    return isShiftEnded(shiftEmployee) && !shiftEmployee.clockInTime;
  };

  return vm;

};

angular.module('popup.booking').controller('PopupBookingShiftFormEmployeesInvitedController',
  dependencies.concat(PopupBookingShiftFormEmployeesInvitedController));
