const dependencies = ['$scope', 'BookingComment', 'currentUserService', 'packageTypeService'];

const PopupBookingInfoController = function($scope, BookingComment, currentUserService, packageTypeService) {

  const vm = this;

  vm.tabs = {
    summary: {
      name: 'Booking summary'
    },
    comments: {
      name: 'Comments',
      count: $scope.booking.commentsCount
    }
  };

  if (
    currentUserService.isSecurityManagerLogged() &&
    packageTypeService.hasProPackage() &&
    !currentUserService.getCurrentProfile().isSupervisor()
  ) {
    vm.tabs.activityLog = { name: 'Activity log' };
  }

  vm.activeTabKey = _.first(_.keys(vm.tabs));

  vm.$onInit = function() {
    BookingComment.getCount({ bookingId: $scope.booking.id }).then(data => vm.changeCommentsCount(data.count));
  };

  vm.changeCommentsCount = function(count) {
    $scope.booking.commentsCount = count;
    return vm.tabs.comments.count = $scope.booking.commentsCount;
  };

  return vm;

};

angular.module('public.timeline')
  .controller('PopupBookingInfoController', dependencies.concat(PopupBookingInfoController));
