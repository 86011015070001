angular.module('shared').directive('clickPreventer', () => {
  return {
    restrict: 'A',
    link($scope, $el) {
      $el.bind('click', function(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
      });
    }
  }
});
