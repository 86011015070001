/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
dependencies = ['defaultConfig'];

const stripeService = (defaultConfig) => {

  const stripe = Stripe(defaultConfig.stripeKey);
  const basicStyle = {
    base: {
      color: '#333C48',
      fontSize: '16px',
      '::placeholder': {
        color: '#95a3b4'
      }
    }
  };

  return {
    createCardElements() {
      let cardElements = {};
      const stripeElements = stripe.elements();
      cardElements.number = stripeElements.create('cardNumber', {
        placeholder: 'Card number',
        style: basicStyle
      });
      cardElements.expiration = stripeElements.create('cardExpiry', { style: basicStyle });
      cardElements.cvc = stripeElements.create('cardCvc', { style: basicStyle });
      return cardElements;
    },

    createToken(element, tokenData) {
      return stripe.createToken(element, tokenData);
    }
  }

};

angular.module('shared').service('stripeService', dependencies.concat(stripeService));
