const dependencies = [
  '$rootScope', '$state', 'googleService', 'keyListenerService', 'currentUserService',
  'storageService', 'cableService', 'landingUiInteractor'
];

const ApplicationController = function(
  $rootScope, $state, googleService, keyListenerService, currentUserService,
  storageService, cableService, landingUiInteractor
) {

  const vm = this;

  vm.landingUiInteractor = landingUiInteractor;

  googleService.initOnLoadCallback();
  keyListenerService.initTabElementListener();

  $rootScope.$on('logout', function() {
    currentUserService.resetCurrentUser();
    storageService.clearAll();
    cableService.disconnect();
    window.location.href = $state.href('sign.login', {}, { absolute: true });
  });

  return vm;
};

angular.module('app').controller('ApplicationController', dependencies.concat(ApplicationController));
