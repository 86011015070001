/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$state', '$q', 'Booking', 'BookingTemplate', 'localStorageService',
  'bookingTemplateType', 'redirectService'
];

const ScheduleManagerBookingFormLayoutController = function(
  $scope, $state, $q, Booking, BookingTemplate, localStorageService,
  bookingTemplateType, redirectService
) {

  const vm = this;

  vm.activeStepNumber = 1;
  vm.isLong = true;
  vm.copyDateWasSet = false;

  let cachedBooking = null;

  vm.getTitle = () => vm.steps[vm.activeStepNumber - 1].title;

  vm.updateBooking = function(booking) {
    if (!vm.isEdit) { localStorageService.set('bookingId', booking.id); }
    vm.bookingId = booking.id;
    return cachedBooking = booking;
  };

  vm.goBack = () => $state.go(vm.steps[vm.activeStepNumber - 2].sref);

  vm.goNext = function() {
    if (vm.steps[vm.activeStepNumber]) {
      vm.copyDateWasSet = true;
      return $state.go(vm.steps[vm.activeStepNumber].sref);
    } else {
      if ($state.params.template === bookingTemplateType.getCreateType()) {
        return new BookingTemplate({ id: vm.bookingId }).save().then(redirectFromForm);
      } else if (vm.isEdit && !vm.isCopy) {
        return Booking.mergeClone(vm.bookingId).then(redirectFromForm);
      } else {
        localStorageService.remove('bookingId');
        return vm.loadBooking().then(booking => booking.createRepeatableItems().then(redirectFromForm));
      }
    }
  };

  vm.loadBooking = function() {
    if (cachedBooking) {
      return $q.when(cachedBooking);
    } else if (vm.bookingId) {
      return vm.reloadBooking();
    } else {
      // empty name is a hack
      return new Booking({name : ''}).save().then(vm.updateBooking);
    }
  };

  vm.reloadBooking = () => {
    return Booking.get(vm.bookingId).then(loadedBooking => {
      return cachedBooking = loadedBooking;
    }, () => redirectService.redirectToMain());
  };

  vm.needDateRange = () => !(vm.isTemplate || (vm.isCopy && !vm.copyDateWasSet));

  const initBookingId = function() {
    if ($state.params.bookingId) {
      vm.isEdit = true;
      vm.isCopy = $state.params.isCopy;
      vm.isTemplate = !!$state.params.template;
      vm.bookingId = $state.params.bookingId;
      if (vm.isTemplate) {
        vm.title = 'Create a new booking template';
      } else {
        vm.title = 'Edit booking';
      }
    } else {
      vm.isEdit = false;
      vm.isCopy = false;
      vm.isTemplate = false;
      vm.bookingId = localStorageService.get('bookingId');
      vm.title = 'Create a new booking';
    }
    return initSteps();
  };

  const initSteps = () => {
    return vm.steps = [{
      title: vm.isEdit ? 'Edit booking' : 'New booking',
      sref: 'public.security-manager.schedule-manager.booking-form.details'
    }, {
      title: vm.isEdit ? 'Edit shifts' : 'Creating shifts',
      sref: 'public.security-manager.schedule-manager.booking-form.shifts'
    }, {
      title: 'Booking summary',
      sref: 'public.security-manager.schedule-manager.booking-form.summary'
    }];
  };

  const initActiveStepNumber = function() {
    const index = _.findIndex(vm.steps, step => $state.is(step.sref));
    return vm.activeStepNumber = index + 1;
  };

  const redirectFromForm = function() {
    vm.bookingId = undefined;
    if ($state.params.backState) {
      return $state.go($state.params.backState.name, $state.params.backState.params);
    } else {
      return $state.go('public.security-manager.schedule-manager.timeline.booking');
    }
  };

  const removeClone = function() {
    if (vm.isEdit && !vm.isTemplate && vm.bookingId) { return new Booking({ id: vm.bookingId }).remove(); }
  };

  initBookingId();
  initActiveStepNumber();

  window.onbeforeunload = function() {
    removeClone();
    return window.onbeforeunload = undefined;
  };

  $scope.$on('$stateChangeSuccess', initActiveStepNumber);
  $scope.$on('logout', removeClone);

  return vm;
};

angular
  .module('public.security-manager.schedule-manager.booking-form')
  .controller('ScheduleManagerBookingFormLayoutController',
    dependencies.concat(ScheduleManagerBookingFormLayoutController));
