/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const validationService = function() {

  let _regexRules = undefined;

  const _cashedRegex = {};

  const getRegex = type => _cashedRegex[type] || (_cashedRegex[type] = new RegExp(_regexRules[type]));

  return {
    getRegex,

    setRegexRules(regexRules) {
      return _regexRules = regexRules;
    },

    getEmailRegex() {
      return getRegex('email');
    },

    getPhoneRegex() {
      return getRegex('phone');
    },

    getExceptSpecialSymbolsRegex() {
      return getRegex('symbolsWithSpace');
    },

    getOnlySymbolsRegex() {
      return getRegex('symbols');
    },

    getNumberRegex() {
      return getRegex('number');
    },

    getMoneyRegex() {
      return getRegex('money');
    },

    getPostCodeRegex() {
      return getRegex('postCode');
    }
  };
};

angular.module('form').service('validationService', validationService);
