/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const storageService = () => {

  return {
    set(key, value) {
      return localStorage.setItem(key, value);
    },

    get(key) {
      return localStorage.getItem(key);
    },

    remove(key) {
      return localStorage.removeItem(key);
    },

    clearAll() {
      return localStorage.clear();
    }
  };
};

angular.module('shared').service('storageService', storageService);
