/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').filter('formatDate', ['dateService', dateService =>
  function(rawDate, reverted) {
    let format;
    if (reverted) {
      format = dateService.getRevertedDateFormat();
    } else {
      format = dateService.getDateFormat();
    }
    if (rawDate) { return dateService.formatDate(rawDate, format); }
  }
]);
