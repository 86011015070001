/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', 'currentUserService', 'SecurityProviderSetting', 'verificationType', 'redirectService'
];

const SettingsSettingsController = function(
  $scope, currentUserService, SecurityProviderSetting, verificationType, redirectService
) {

  const vm = this;

  vm.verificationType = verificationType;

  vm.authOptions = [{
    label: 'Every login',
    type: verificationType.getVerificationTypeAlwaysOn()
  }, {
    label: 'Only on new devices',
    type: verificationType.getVerificationTypeOnlyNewDevices()
  }, {
    label: 'Off',
    type: verificationType.getVerificationTypeSwitchedOff()
  }];

  vm.$onInit = function() {
    checkManagerAccessLevel();
    initSecurityManager();
    loadSettings();
  };

  vm.copySettings = function() {
    vm.settingsCopy = angular.copy(vm.settings);
  };

  vm.isOptionChecked = function (type) {
    if (!vm.settingsCopy) { return; }
    return vm.settingsCopy.verificationType == type;
  };

  const checkManagerAccessLevel = function () {
    if (currentUserService.getCurrentProfile().isManager() || currentUserService.getCurrentProfile().isSupervisor()) {
      redirectService.redirectToMain();
    }
  };

  const initSecurityManager = function() {
    vm.securityManager = currentUserService.getCurrentProfile();
  };

  const loadSettings = function() {
    SecurityProviderSetting.getCurrent().then(function (settings) {
      vm.settings = settings;
      vm.copySettings();
    })
  };

  vm.saveSettings = function() {
    vm.settingsCopy.save().then(function (settings) {
      vm.settings = settings;
      vm.copySettings();
    }).catch(function (error) {
      Rollbar.log(`Verification type settings update on ${window.location.href}`, error);
      vm.copySettings();
    });
  };

  return vm;
};

angular.module('public.security-manager.settings')
  .controller('SettingsSettingsController', dependencies.concat(SettingsSettingsController));
