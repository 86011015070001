/*
 * @author Oleksandr Papka <papkaos>
 */
angular.module('popup.system-package').directive('gccsPopupSystemPackageCardEdit', [
  'popupDirectiveConfig',
  popupDirectiveConfig =>
    popupDirectiveConfig.configure({
      bodyTemplate: require('./template.pug')
    })
]);
