const securityManagerNotificationReadFilterService = function() {

  const ALL = null;
  const READ = 1;
  const UNREAD = 2;

  const names = {};
  names[ALL] = 'All';
  names[READ] = 'Read';
  names[UNREAD] = 'Unread';

  return {
    getAll() {
      return ALL;
    },

    getRead() {
      return READ;
    },

    getUnread() {
      return UNREAD;
    },

    getNames() {
      return names;
    }
  };
};

angular.module('public.security-manager.notifications')
  .service('securityManagerNotificationReadFilterService', securityManagerNotificationReadFilterService);
