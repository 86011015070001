/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$location', '$state', 'EntityManager', 'FilterParamsService', 'currentUserService',
  'securityManagerAccessLevel', 'SecurityManager'
];

const SettingsManagersController = function(
  $scope, $location, $state, EntityManager, FilterParamsService, currentUserService,
  securityManagerAccessLevel, SecurityManager
) {

  const vm = this;

  const entityManager = new EntityManager(SecurityManager);
  const filterParamsService = new FilterParamsService({
    filter: ['fullName'],
    order: ['fullName']
  });

  vm.isShownSecurityManagerPopup = false;
  vm.filterParams = filterParamsService.getParams();
  vm.currentSecurityManager = currentUserService.getCurrentProfile();

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  vm.$onInit = function() {
    SecurityManager.getCount().then(data => vm.securityManagersCount = data.count);
    vm.editable = vm.editable === undefined ? true : vm.editable;
  };

  vm.removeSecurityManager = (securityManager, onSuccessCb) => {
    return entityManager.removeEntity(securityManager, function () {
      if (onSuccessCb) {
        onSuccessCb();
      }
      return vm.securityManagersCount--;
    });
  };

  vm.loadMoreSecurityManagers = () => entityManager.loadMore();

  vm.getSecurityManagers = () => entityManager.entities;

  vm.showSecurityManagerPopup = function(securityManager) {
    if (vm.editable && vm.canEdit(securityManager)) {
      if (securityManager) {
        $state.go('public.security-manager.manager-profile.basic', { securityManagerId : securityManager.id });
      } else {
        vm.popupSecurityManager = entityManager.createEntity();
        vm.isShownSecurityManagerPopup = true;
      }
    }
  };

  vm.closeSecurityManagerPopup = function(savedSecurityManager) {
    vm.isShownSecurityManagerPopup = false;
    if (savedSecurityManager) {
      vm.securityManagersCount++;
      return filterSecurityManagers();
    }
  };

  vm.getAccessLevelName = function(securityManager) {
    if (securityManager.isPending) {
      return 'pending';
    } else {
      return securityManagerAccessLevel.getName(securityManager.accessLevel);
    }
  };

  vm.canRemove = securityManager => {
    return !(vm.currentSecurityManager.isManager() ||
      securityManager.hasMoreAccess(vm.currentSecurityManager) ||
      vm.currentSecurityManager.id === securityManager.id
    );
  };

  vm.canEdit = securityManager => {
    return !(securityManager && securityManager.hasMoreAccess(vm.currentSecurityManager)) && (
      vm.currentSecurityManager.isAccountOwner() ||
      vm.currentSecurityManager.isAdmin() ||
      (vm.currentSecurityManager.isManager() && securityManager.id === vm.currentSecurityManager.id)
    );
  };

  vm.getAccessLevelClass = function(securityManager) {
    if (securityManager.isPending) {
      return 'mod-yellow';
    } else if (securityManager.isAccountOwner()) {
      return 'mod-green';
    } else {
      return 'mod-blue';
    }
  };

  const filterSecurityManagers = function() {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    $location.search(urlParams);
    return entityManager.load(urlParams);
  };

  $scope.$watch(() => vm.filterParams, filterSecurityManagers, true);

  return vm;

};

angular.module('public.security-manager.settings')
  .controller('SettingsManagersController', dependencies.concat(SettingsManagersController));
