const dependencies = [
  '$state', '$q', 'SecurityManager', 'redirectService', 'securityManagerAccessLevel'
];

const ManagerProfileController = function(
  $state, $q, SecurityManager, redirectService, securityManagerAccessLevel
) {

  const vm = this;

  let securityManagerPromise = undefined;

  vm.securityManagerId = $state.params.securityManagerId;

  vm.$onInit = function() {
    vm.editable = true;
    vm.loadSecurityManager();
    initTabs();
  };

  vm.loadSecurityManager = function() {
    if (vm.securityManager) {
      return $q.when(vm.securityManager);
    } else if (securityManagerPromise) {
      return securityManagerPromise;
    } else {
      return vm.reloadSecurityManager();
    }
  };

  vm.reloadSecurityManager = function() {
    securityManagerPromise = SecurityManager.get(vm.securityManagerId).then(function (securityManager) {
      vm.securityManager = securityManager;
      securityManagerPromise = undefined;
      return $q.when(vm.securityManager);
    }).catch(function () {
      redirectService.redirectToMain();
    });
  };

  vm.updateSecurityManager = function (securityManager) {
    angular.copy(securityManager, vm.securityManager);
  };

  vm.getAccessLevelName = function() {
    if (!vm.securityManager) { return; }
    if (vm.securityManager.isPending) {
      return 'pending';
    } else {
      return securityManagerAccessLevel.getName(vm.securityManager.accessLevel);
    }
  };

  vm.redirectToMain = () => {
    redirectService.redirectToMain();
  };

  const initTabs = function() {
    vm.tabs = [{
      name: 'Basic Information',
      sref: 'public.security-manager.manager-profile.basic'
    }, {
      name: 'Notification Settings',
      sref: 'public.security-manager.manager-profile.notifications'
    }];
  };

  return vm;

};

angular.module('public.security-manager.manager-profile')
  .controller('ManagerProfileController', dependencies.concat(ManagerProfileController));
