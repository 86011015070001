// https://gist.github.com/subudeepak/9617483#file-angular-loadscript-js
angular.module('shared').directive('script', () => {
  return {
    restrict : 'E',
    scope : false,
    link(scope, elem, attr) {
      if (attr.type === 'text/javascript-lazy') {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        const src = elem.attr('src');
        if (src !== undefined) {
          script.src = src;
        } else {
          const code = elem.text();
          script.text = code;
        }
        document.head.appendChild(script);
        return elem.remove();
      }
    }
  }
});
