/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.client-manager.client', [
  'popup.client',
  'popup.document'
]).config(['$stateProvider', $stateProvider => {

  return $stateProvider

    .state('public.client-manager.client', {
      abstract: true,
      template: require('./template.pug'),
      controller: 'ManagerClientLayoutController',
      controllerAs: 'managerClientLayoutCtrl'
    })
    .state('public.client-manager.client.details', {
      url: '/client-details',
      template: require('./details/template.pug')
    })
    .state('public.client-manager.client.services', {
      url: '/client-services',
      template: require('./services/template.pug'),
      controller: 'ManagerClientServicesController',
      controllerAs: 'managerClientServicesCtrl'
    })
    .state('public.client-manager.client.sites', {
      url: '/client-sites',
      template: require('./sites/template.pug'),
      controller: 'ManagerClientSitesController',
      controllerAs: 'managerClientSitesCtrl'
    })
    .state('public.client-manager.client.booking-templates', {
      url: '/booking-templates',
      template: require('./booking-templates/template.pug'),
      controller: 'ManagerClientBookingTemplatesController',
      controllerAs: 'managerClientBookingTemplatesCtrl'
    })
    .state('public.client-manager.client.employees', {
      url: '/employees',
      template: require('./employees/template.pug'),
      controller: 'ManagerClientEmployeesController',
      controllerAs: 'managerClientEmployeesCtrl'
    })
    .state('public.client-manager.client.notes', {
      url: '/notes',
      template: require('./notes/template.pug'),
      controller: 'ManagerClientNotesController',
      controllerAs: 'managerClientNotesCtrl'
    });

}]);
