/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').directive('gccsFlexibleList', () => {
  return {
    restrict : 'A',
    scope : {
      list : '=',
      resizeOnChange : '&',
      parent : '<'
    },
    link($scope, $el) {
      const element = $el[0];
      const numberListItem = element.querySelector('.mod-num');
      const itemWidth = numberListItem.offsetWidth;
      numberListItem.classList.add('hide');

      const hideSpare = spareCount =>
        __range__(0, $scope.list.length, false).map((index) =>
          element.children[index].classList.toggle('hide', index < spareCount))
      ;

      const updateList = function() {
        const container = element.parentElement;
        const maxItemsCount = Math.floor(container.offsetWidth / itemWidth);
        let spareCount = $scope.list.length - maxItemsCount;
        if (spareCount > 0) {
          if (spareCount < $scope.list.length) { spareCount += 1; }
          hideSpare(spareCount);
          numberListItem.firstElementChild.innerHTML = spareCount;
          return numberListItem.classList.remove('hide');
        } else {
          hideSpare(spareCount);
          return numberListItem.classList.add('hide');
        }
      };

      $scope.$watchCollection('list', updateList);
      return $scope.$watch($scope.resizeOnChange, updateList, true);
    }
  }
});

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
