/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$state', 'currentUserService', 'redirectService', 'packageTypeService'];

const ManagerLayoutController = function($state, currentUserService, redirectService, packageTypeService) {

  const vm = this;

  vm.isLogged = false;

  if (packageTypeService.hasProPackage() && currentUserService.isClientManagerLogged()) {
    vm.isLogged = true;
  } else {
    redirectService.redirectToMain();
  }

  return vm;

};

angular.module('public.client-manager')
  .controller('ManagerLayoutController', dependencies.concat(ManagerLayoutController));
