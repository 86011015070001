const gccsPopupClientClockInPackageRestriction = {
  controller: 'PopupClientClockInPackageRestrictionController',
  template: require('./template.pug'),
  require: {
    gccsPopupsLayout: '^gccsPopupsLayout'
  },
  bindings: {
  }
};

angular.module('shared-components.popup.client')
  .component('gccsPopupClientClockInPackageRestriction', gccsPopupClientClockInPackageRestriction);
