/*
 * @author Igor Serpak <Praffesor>
 */
const employeeGender = function() {

  const UNKNOWN = 0;
  const MALE = 1;
  const FEMALE = 2;

  const names = {};
  names[UNKNOWN] = 'Unknown';
  names[MALE] = 'Male';
  names[FEMALE] = 'Female';

  const knownGenderNames = {};
  knownGenderNames[MALE] = 'Male';
  knownGenderNames[FEMALE] = 'Female';

  return {
    getNames() {
      return names;
    },

    getName(gender) {
      return names[gender];
    },

    getKnownGenderNames() {
      return knownGenderNames;
    },

    getUnknown() {
      return UNKNOWN;
    },

    getMale() {
      return MALE;
    },

    getFemale() {
      return FEMALE;
    }
  };
};

angular.module('data.employee').service('employeeGender', employeeGender);