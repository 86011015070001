const dependencies = [
  'Client', 'ClientService', 'ClientSite', 'clientStatus', 'dateService', 'pluralizeService',
  'planningNotificationService', 'notificationService', 'activeStatus'
];

const PlanningHeaderController = function(
  Client, ClientService, ClientSite, clientStatus, dateService, pluralizeService,
  planningNotificationService, notificationService, activeStatus
) {

  const vm = this;

  let currentClient = undefined;
  let currentClientService = undefined;
  let currentClientSite = undefined;
  let currentSiteLocation = undefined;

  vm.$onInit = () => loadClients();

  vm.onClientChoose = function(client) {
    if (!client) { return; }
    vm.chosenClient = client;
    vm.chosenClientService = undefined;
    vm.chosenClientSite = undefined;
    vm.chosenSiteLocation = undefined;
    loadClientServices(client);
    loadClientSites(client);
    onFilterSelectCb();
  };

  vm.onClientServiceChoose = function(clientService) {
    if (!clientService) { return; }
    vm.chosenClientService = clientService;
    onFilterSelectCb();
  };

  vm.onClientSiteChoose = function(clientSite) {
    if (!clientSite) { return; }
    vm.chosenClientSite = clientSite;
    vm.chosenSiteLocation = undefined;
    vm.siteLocations = clientSite.locations;
    onFilterSelectCb();
  };

  vm.onSiteLocationChoose = function(siteLocation) {
    if (!siteLocation) { return; }
    vm.chosenSiteLocation = siteLocation;
    onFilterSelectCb();
  };

  vm.isLockedClient = client => client.isLocked();

  vm.isLockedService = service => service.isLocked();

  vm.filterDisabled = function () {
    return !vm.chosenClient || (
      currentClient === vm.chosenClient &&
      currentClientService === vm.chosenClientService &&
      currentClientSite === vm.chosenClientSite &&
      currentSiteLocation === vm.chosenSiteLocation
    );
  };

  vm.filterShifts = function() {
    if (vm.chosenClientService) {
      filterShifts();
    } else if (vm.chosenClient) {
      vm.chosenClient.isPartiallyLocked().then(function(isPartiallyLocked) {
        if (isPartiallyLocked) {
          notificationService.notifyInfo('Please, choose service');
        } else {
          filterShifts();
        }
      });
    }
  };

  vm.clearResults = function() {
    vm.chosenClient = undefined;
    vm.chosenClientService = undefined;
    vm.chosenClientSite = undefined;
    vm.chosenSiteLocation = undefined;
    currentClient = undefined;
    currentClientService = undefined;
    currentClientSite = undefined;
    currentSiteLocation = undefined;
    onFilterSelectCb();
    vm.clientServices = [];
    vm.clientSites = [];
    vm.siteLocations = [];
    vm.planningShiftLoader.clearShifts();
    vm.onApplyCb();
  };

  vm.getResultsCount = () => pluralizeService.simple(vm.planningShiftLoader.dShifts.length, 'shift');

  vm.hasResults = () => vm.planningShiftLoader.dShifts.length > 0;

  vm.quitPlanningMode = function($event) {
    $event.preventDefault();
    planningNotificationService.showQuitMessage();
  };

  vm.startTutorial = () => vm.tutorialService.startTutorial();

  const loadClients = function () {
    Client.autocomplete({}, {
      status: clientStatus.getActiveStatus(),
      'order[name]': 'asc'
    }).then(clients => vm.clients = clients);
  };

  const loadClientServices = function (client) {
    ClientService.query({ activeStatus: activeStatus.getActiveStatus() }, { clientId: client.id })
      .then(services => vm.clientServices = services);
  };

  const loadClientSites = function(client) {
    ClientSite.query({}, { clientId: client.id }).then(sites => vm.clientSites = sites);
  };

  const filterShifts = function() {
    currentClient = vm.chosenClient;
    currentClientService = vm.chosenClientService;
    currentClientSite = vm.chosenClientSite;
    currentSiteLocation = vm.chosenSiteLocation;
    vm.planningShiftLoader.setLoadParams({
      clientId: vm.chosenClient ? vm.chosenClient.id : null,
      serviceId: vm.chosenClientService ? vm.chosenClientService.id : null,
      siteId: vm.chosenClientSite ? vm.chosenClientSite.id : null,
      locationId: vm.chosenSiteLocation ? vm.chosenSiteLocation.id : null
    });
    vm.planningShiftLoader.loadShifts();
    vm.onApplyCb();
  };

  const onFilterSelectCb = function() {
    vm.onFilterSelectCb({
      filterData: {
        client: vm.chosenClient,
        service: vm.chosenClientService,
        site: vm.chosenClientSite,
        location: vm.chosenSiteLocation
      }
    });
  };

  return vm;

};

angular.module('public.security-manager.schedule-manager.timeline.planning')
  .controller('PlanningHeaderController', dependencies.concat(PlanningHeaderController));
