const dependencies = ['$scope', 'Client', 'ClientService', 'ClientSite', 'selectService'];

const EmployeeClientApproveServiceController = function($scope, Client, ClientService, ClientSite, selectService) {

  const vm = this;

  vm.getEmployeeResource = function(resource) {
    return _.find(vm.employee.employeeResources, function(employeeResource) {
      return employeeResource.resourceId === resource.id;
    });
  };
  
  vm.onApprove = function(approvedResource) {
    vm.employee.employeeResources.push(approvedResource);
    vm.onResourceApprove(approvedResource);
  };
  
  vm.onReject = function(rejectedResource) {
    vm.employee.employeeResources = _.without(vm.employee.employeeResources, rejectedResource);
    vm.onResourceReject(rejectedResource);
  };

  const initClient = function() {
    Client.query({}, { id: vm.selectedClientId }).then(client => {
      vm.client = client;
    });
  };

  const initClientServices = function() {
    ClientService.getWithPayableResources(vm.selectedClientId).then(services => {
      vm.services = services;
      vm.selectServices = selectService.toSelectData(services, { defaultField: 'All services' });
    });
  };

  const initApprovedSites = function() {
    ClientSite.forEmployee(vm.selectedClientId, vm.employee.id).then(function(result) {
      vm.siteNames = _.map(result.sitesForEmployee, 'name').join('; ');
      vm.locationNames = _.map(result.locationsForEmployee, 'name').join('; ');
    });
  };

  $scope.$watch(() => vm.selectedClientId, (newVal, oldVal) => {
    if (newVal || oldVal) {
      initClient();
      initClientServices();
      initApprovedSites();
    }
  });

  return vm;

};

angular.module('public.security-manager.employee')
  .controller('EmployeeClientApproveServiceController', dependencies.concat(EmployeeClientApproveServiceController));
