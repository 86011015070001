/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$state', '$q', 'Employee', 'redirectService', 'employeeDeviceType', 'employeeActivityService',
  'employeeInvoiceLabelService', 'employeeStatus', 'packageTypeService', 'currentUserService'
];

const EmployeeShowLayoutController = function (
  $scope, $state, $q, Employee, redirectService, employeeDeviceType, employeeActivityService,
  employeeInvoiceLabelService, employeeStatus, packageTypeService, currentUserService
) {

  const vm = this;

  vm.mobileType = employeeDeviceType.getMobile();
  vm.employeeId = $state.params.employeeId;
  vm.isShownEditPopup = false;
  vm.activeStatus = employeeStatus.getActiveStatus();
  vm.editable = false;
  vm.isShownLicensesExceedPopup = false;
  vm.isShownResendInvitePopup = false;

  const invoiceTab = {
    name: '',
    sref: 'public.security-manager.employee.invoice.list'
  };

  let employeePromise = undefined;

  vm.$onInit = function() {
    vm.loadEmployee();
    vm.editable = true;
    initTabs();
  };

  vm.showEditPopup = function () {
    vm.isShownEditPopup = true;
  };

  vm.closeEditPopup = function (employee) {
    vm.isShownEditPopup = false;
    if (employee) {
      vm.updateEmployee(employee);
      triggerUpdate();
    }
  };

  vm.updateEmployee = function (employee) {
    angular.copy(employee, vm.employee);
  };

  vm.loadEmployee = function () {
    if (vm.employee) {
      return $q.when(vm.employee);
    } else if (employeePromise) {
      return employeePromise;
    } else {
      return vm.reloadEmployee();
    }
  };

  vm.isNewEmployee = function () {
    if (!vm.employee) { return; }
    return employeeActivityService.isNew(vm.employee);
  };

  vm.resendInvite = function () {
    if (!vm.employee) { return; }
    vm.popupEmployee = vm.employee;
    vm.openResendInvitePopup();
  };

  vm.openResendInvitePopup = function() {
    vm.isShownResendInvitePopup = true;
  };

  vm.closeResendInvitePopup = function() {
    vm.isShownResendInvitePopup = false;
  };

  vm.getEmployeeActivityLabel = function () {
    if (!vm.employee) { return; }
    return employeeActivityService.getActivityLabel(vm.employee);
  };

  vm.reloadEmployee = function () {
    employeePromise = Employee.get(vm.employeeId).then(function (employee) {
      vm.employee = employee;
      employeePromise = undefined;
      initEmployeeData(employee);
      triggerUpdate();
      return $q.when(vm.employee);
    }).catch(function () {
      redirectService.redirectToMain();
    });
  };

  vm.closeLicensesExceedPopup = function () {
    vm.isShownLicensesExceedPopup = false;
  };

  vm.onEmployeeProfilePopupSubmit = function(successPopupSubmitCb) {
    checkPackageRestrictions(successPopupSubmitCb);
  };

  const openLicensesExceedPopup = function() {
    vm.isShownLicensesExceedPopup = true;
  };

  const checkPackageRestrictions = function(successCb) {
    Employee.getCount({}, { status: employeeStatus.getActiveStatus() }).then(function(data) {
      const employeesCount = data.count;
      if (packageTypeService.hasFreePackage()) {
        if (employeesCount < packageTypeService.getEmployeesLimitForFreePackage()) {
          successCb();
        } else {
          openLicensesExceedPopup();
        }
      } else if (packageTypeService.hasUnlimitedPackage()) {
        successCb();
      } else {
        if (employeesCount < packageTypeService.getLicensesCount()) {
          successCb();
        } else {
          openLicensesExceedPopup();
        }
      }
    });
  };

  const initEmployeeData = function (employee) {
    vm.employeesLabels = employeeInvoiceLabelService.getEmployeesTexts(employee.employmentType);
    invoiceTab.name = vm.employeesLabels.tabText;
  };

  const triggerUpdate = function () {
    $scope.$broadcast('employee.show.update', vm.employee);
  };

  const initTabs = function() {
    vm.tabs = [{
      name: 'PERSONAL DETAILS',
      sref: 'public.security-manager.employee.details'
    }, {
      name: 'WORK HISTORY',
      sref: 'public.security-manager.employee.work-history'
    }, {
      name: 'ADDRESS HISTORY',
      sref: 'public.security-manager.employee.address-history'
    }, {
      name: 'CLIENT RESOURCES',
      sref: 'public.security-manager.employee.approved-clients'
    }, {
      name: 'LICENSES AND TRAININGS',
      sref: 'public.security-manager.employee.licenses'
    }, {
      name: 'DOCUMENTS',
      sref: 'public.security-manager.employee.documents'
    }];
    if (
      !currentUserService.getCurrentProfile().isManager() ||
      currentUserService.getCurrentProfile().hasAccessToFinances
    ) {
      vm.tabs.push({
        name: 'TIMESHEET',
        sref: 'public.security-manager.employee.timesheet'
      });
      if (packageTypeService.hasProPackage()) {
        vm.tabs.push(invoiceTab);
      }
    }
    if (packageTypeService.hasProPackage()) {
      vm.tabs.push({
        name: 'E-MAIL',
        sref: 'public.security-manager.employee.emails'
      })
    }
    vm.tabs.push({
      name: 'NOTES',
      sref: 'public.security-manager.employee.notes'
    });
  };

  return vm;

};

angular.module('public.security-manager.employee')
  .controller('EmployeeShowLayoutController', dependencies.concat(EmployeeShowLayoutController));
