/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['Employee', 'employeeLicenseType', 'employeeDocumentType', 'dateService'];

const EmployeePreviewController = function(Employee, employeeLicenseType, employeeDocumentType, dateService) {

  const vm = this;

  vm.$onInit = () => {
    Employee.$get(Employee.resourceUrl({id: vm.employeeId}), {template: 'preview'}).then(employee => {
      vm.previewData = [{
        title: 'Email',
        value: employee.email,
        hoverTitle: employee.email
      }, {
        title: 'Contact number',
        value: employee.contactNumber
      }, {
        title: 'Next of kin',
        value: getNextOfKin(employee)
      }, {
        title: 'Roles',
        value: _.map(employee.roles, 'name').join(', '),
        classNames: 'mod-no-wrap'
      }, {
        title: 'Recent address',
        value: employee.address,
        classNames: 'mod-no-wrap'
      }, {
        title: 'SIA license',
        value: getLicenseValue(employee, employeeLicenseType.getSia()),
        withIcon: getLicense(employee, employeeLicenseType.getSia()),
        isActive: isActive(employee, employeeLicenseType.getSia())
      }, {
        title: 'First AID',
        value: getLicenseValue(employee, employeeLicenseType.getFirstAid()),
        withIcon: getLicense(employee, employeeLicenseType.getFirstAid()),
        isActive: isActive(employee, employeeLicenseType.getFirstAid())
      }, {
        title: 'Passport',
        value: getBooleanValue(employee.documentTypes, employeeDocumentType.getPassport())
      }];
    });
  };

  const isActive = function(employee, licenseType) {
    const license = getLicense(employee, licenseType);
    return (license != null ? license.isActive: undefined);
  };

  const getLicenseValue = function(employee, licenseType) {
    const license = getLicense(employee, licenseType);
    if (license) {
      if (license.isActive) {
        return `№ ${license.number}`;
      } else {
        return `Expired at ${dateService.formatDate(license.expireDate)}`;
      }
    } else {
      return 'No';
    }
  };

  const getLicense = (employee, licenseType) => _.find(employee.licenses, ['type', licenseType]);

  const getBooleanValue = function(list, type) {
    if (list.indexOf(type) !== -1) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  const getNextOfKin = function(employee) {
    if (employee.nextOfKinName || employee.nextOfKinPhoneNumber) {
      return `${employee.nextOfKinName} ${employee.nextOfKinPhoneNumber}`;
    }
  };

  return vm;
};

angular.module('public.security-manager.employee')
  .controller('EmployeePreviewController', dependencies.concat(EmployeePreviewController));
