const gccsSecurityManagerServiceFormTrainings = {
  template: require('./template.pug'),
  controller: 'SecurityManagerServiceFormTrainingsController',
  controllerAs: 'serviceFormTrainingsCtrl',
  bindings: {
    service: '<',
    isEditable: '<',
    selectedResources: '<',
    updateResourceSelect: '&'
  }
};

angular.module('public.security-manager.client.show')
  .component('gccsSecurityManagerServiceFormTrainings', gccsSecurityManagerServiceFormTrainings);
