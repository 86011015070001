/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.client').factory('ClientServiceResourceTraining', ['AppModel', function(AppModel) {

  class ClientServiceResourceTraining extends AppModel {
    static initClass() {
      this.configure({url : '/clients/{{clientId}}/services/{{serviceId}}/resources/{{resourceId}}/trainings/{{id}}'});
    }
  }

  ClientServiceResourceTraining.initClass();
  return ClientServiceResourceTraining;
}
]);
