/*
 * @author Oleksandr Papka <papkaos>
 */
const employeeEventType = function() {

  const AVAILABILITY = 1;
  const DAY_OFF = 2;
  const SICK_DAY = 3;

  const names = {
    [AVAILABILITY]: 'Availability',
    [DAY_OFF]: 'Day off',
    [SICK_DAY]: 'Sick day'
  };

  return {
    getNames() {
      return names;
    },

    getName(type) {
      return names[type];
    },

    isAvailabilityEventType(type) {
      return type === AVAILABILITY;
    },

    isDayOffEventType(type) {
      return type === DAY_OFF;
    },

    isSickDayEventType(type) {
      return type === SICK_DAY;
    },

    getAvailabilityEventType() {
      return AVAILABILITY;
    },

    getDayOffEventType() {
      return DAY_OFF;
    },

    getSickDayEventType() {
      return SICK_DAY;
    }
  };
};

angular.module('data.employee').service('employeeEventType', employeeEventType);