/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$timeout', 'ClientServiceResource', 'TimeLineInteractor', 'resourceIcon',
  'payRateVisibilityService', 'redirectService', 'activeStatus'
];

const TimelineBookingFormShiftsController = function(
  $scope, $timeout, ClientServiceResource, TimeLineInteractor, resourceIcon,
  payRateVisibilityService, redirectService, activeStatus
) {

  const vm = this;

  vm.timeLineInteractor = TimeLineInteractor;

  vm.resources = [];
  vm.resourceIcon = resourceIcon;

  vm.isShownChargeRate = resource => payRateVisibilityService.isShownChargeRate(resource.chargeRate);

  vm.isShownPayRate = resource => {
    return payRateVisibilityService.isShownPayRate(resource.contractorPayRate, resource.employedPayRate);
  };

  vm.onQuickAddShift = function (resource) {
    $scope.$broadcast('quickShiftAdded', resource);
  };

  const interactorServiceInitHandler = function(event, service) {
    $timeout(() => {
      $scope.$broadcast('interactorServiceInitiatedParent', service);
    });
  };

  $scope.timelineBookingFormLayoutCtrl.isLong = true;

  $scope.timelineBookingFormLayoutCtrl.loadBooking().then(function(booking) {
    vm.booking = booking;
    return ClientServiceResource.query({
      active_status: activeStatus.getActiveStatus()
    }, {
      clientId: booking.clientId,
      serviceId: booking.serviceId,
    }).then(resources => vm.resources = resources);
  }).catch(() => redirectService.redirectToMain());

  $scope.$on('interactorServiceInitiated', interactorServiceInitHandler);

  return vm;
};

angular
  .module('public.timeline')
  .controller('TimelineBookingFormShiftsController', dependencies.concat(TimelineBookingFormShiftsController));
