/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').factory('Config', ['$q', 'AppModel', function($q, AppModel) {

  let instance = undefined;

  class Config extends AppModel {
    static initClass() {
      this.configure({url : '/config', name : 'config'});

      instance = undefined;
    }

    static getInstance() {
      if (instance) {
        return $q.when(instance);
      } else {
        return Config.get().then(config => instance = config);
      }
    }
  }

  Config.initClass();
  return Config;
}]);
