/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['$stateParams', 'elementService'];
const bookingTimeLineScrollToShift = ($stateParams, elementService) =>
  ({
    restrict : 'A',
    scope : {
      shiftDecorator : '<',
      openShiftPopup : '&'
    },
    link($scope, $el) {
      if ($stateParams.goToShiftId && ($stateParams.goToShiftId === $scope.shiftDecorator.id)) {
        delete $stateParams.goToShiftId;
        const element = $el[0];
        const scrollContainer = element.closest('.timeline-data');
        setTimeout(function () {
          scrollContainer.scrollTop = elementService.getOffsetTop(element) - elementService.getOffsetTop(scrollContainer);
          $scope.openShiftPopup({
            dShift: $scope.shiftDecorator,
            openType: $stateParams.openShiftType,
            element: element
          });
        });
      }
    }
  })
;

angular.module('public.timeline').directive('bookingTimeLineScrollToShift', dependencies.concat(bookingTimeLineScrollToShift));
