angular.module('public.security-manager').service('trialEndNotificationService', ['$timeout', function($timeout) {

  const APPEAR_INTERVAL = 20 * 60 * 1000; // 20 min

  let _isShownNotification = false;
  let _intervalInstance = null;

  const isShownNotification = function() {
    return _isShownNotification;
  };

  const showNotification = function() {
    _isShownNotification = true;
  };

  const hideNotification = function(withoutInterval = false) {
    _isShownNotification = false;
    if (!withoutInterval) {
      _intervalInstance = $timeout(showNotification, APPEAR_INTERVAL);
    }
  };

  return {
    isShownNotification,
    showNotification,
    hideNotification
  };
}]);
