/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$timeout', 'uploadService', 'employeeLicenseType', 'DocumentType', 'documentTypeType', 'documentTypeIconService',
  'DocumentTypeIcon'
];

const PopupEmployeeLicenseFormController = function(
  $scope, $timeout, uploadService, employeeLicenseType, DocumentType, documentTypeType, documentTypeIconService,
  DocumentTypeIcon
) {

  const vm = this;

  $scope.popupFormCtrl.isShortPopup = true;

  vm.documentTypeIconService = documentTypeIconService;


  vm.selectDocumentType = function (documentType) {
    vm.license.documentTypeId = documentType.id;
    vm.selectedDocumentType = documentType;
  };

  vm.toggleDocumentTypeSelect = function () {
    vm.isShownDocumentTypeDropdown = !vm.isShownDocumentTypeDropdown;
  };

  vm.closeDocumentTypeSelect = function () {
    vm.isShownDocumentTypeDropdown = false;
  };

  vm.addNewType = function() {
    vm.isAddingNewType = true;
    vm.documentType = new DocumentType({ type: $scope.documentTypeType });
  };

  vm.cancelAddNewType = function() {
    vm.isAddingNewType = false;
    vm.documentType = null;
  };

  vm.toggleIconsDropdown = () => vm.isShownDocumentIconsDropdown = !vm.isShownDocumentIconsDropdown;

  vm.hideIconsDropdown = () => vm.isShownDocumentIconsDropdown = false;

  vm.onIconUpload = function(documentType, $file) {
    const documentIcon = new DocumentTypeIcon({ iconType: documentTypeIconService.getUploadedType() });
    documentIcon.save().then(function(savedDocumentTypeIcon) {
      savedDocumentTypeIcon.icon = $file;
      uploadService.updateFile(savedDocumentTypeIcon, function(updatedIcon) {
        loadDocumentTypeIcons();
        documentType.icon = new DocumentTypeIcon(updatedIcon);
        documentType.iconId = updatedIcon.id;
        vm.uploadedIcon = null;
      });
    });
  };

  const initLicense = function() {
    vm.employeeLicenseType = employeeLicenseType;
    if ($scope.license.isNew()) {
      vm.license = $scope.license;
      $scope.popupFormCtrl.title = 'Add new license';
      $scope.popupFormCtrl.saveButtonText = 'Add';
    } else {
      vm.license = angular.copy($scope.license);
      $scope.popupFormCtrl.title = 'Edit';
      $scope.popupFormCtrl.saveButtonText = 'Save';
    }
  };

  const initDefaultValues = function () {
    vm.selectedDocumentTypes = [];
    vm.documentTypeIcons = [];
    vm.isShownDocumentTypeDropdown = false;
    vm.isAddingNewType = false;
    vm.isShownDocumentIconsDropdown = false;
  };

  const loadDocumentTypes = function() {
    return DocumentType.query({ type: documentTypeType.getTraining() }).then(function(documentTypes) {
      vm.selectedDocumentTypes = [{
        id: null,
        name: 'No type'
      }];
      vm.selectedDocumentTypes = _.concat(vm.selectedDocumentTypes, documentTypes);
    });
  };

  const initDocumentType = function() {
    if (vm.license.documentTypeId) {
      vm.selectedDocumentType = _.find(vm.selectedDocumentTypes, function(documentType) {
        return documentType.id === vm.license.documentTypeId;
      });
    } else {
      vm.selectedDocumentType = _.first(vm.selectedDocumentTypes);
    }
  };

  const loadDocumentTypeIcons = function() {
    return DocumentTypeIcon.query({ type: $scope.documentTypeType }).then(function(documentTypeIcons) {
      vm.documentTypeIcons = documentTypeIcons;
    });
  };

  const saveLicense = function() {
    uploadService.saveWithFile(vm.license, 'file', license => {
      $scope.popupCtrl.closePopup(() => {
        $scope.popupCloseCb({ license });
      });
    });
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      initDefaultValues();
      initLicense();
      loadDocumentTypes().then(() => {
        initDocumentType();
      });
      loadDocumentTypeIcons();
    }
  });

  $scope.$on('popup.submit', () => {
    if (vm.documentType) {
      vm.documentType.iconId = vm.documentType.icon.id;
      vm.documentType.type = $scope.documentTypeType;
      vm.documentType.save().then(function(documentType) {
        loadDocumentTypes();
        vm.license.documentTypeId = documentType.id;
        saveLicense()
      });
    } else {
      saveLicense()
    }
  });

  return vm;
};

angular.module('popup.employee').controller('PopupEmployeeLicenseFormController',
  dependencies.concat(PopupEmployeeLicenseFormController));
