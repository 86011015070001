/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const lengthUnitOfMeasureService = function() {

  const UOM_KILO_METRE = 1;
  const UOM_MILE = 2;

  const names = {};
  names[UOM_KILO_METRE] = 'km';
  names[UOM_MILE] = 'mi';

  const filterNames = {};
  filterNames[UOM_KILO_METRE] = 'Kilometres';
  filterNames[UOM_MILE] = 'Miles';

  return {
    getNames() {
      return names;
    },

    getFilterNames() {
      return filterNames;
    },

    getName(unitOfMeasurement) {
      return names[unitOfMeasurement];
    }
  };
};

angular.module('data.home-zone').service('lengthUnitOfMeasureService', lengthUnitOfMeasureService);
