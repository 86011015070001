/*
 * @author Oleksandr Papka <papkaos>
 */
import '../styles/_import.css';

angular.module('shared-components.extended-filters').component('gccsExtendedAccessFilter', {
  controller: 'ExtendedAccessFilterController',
  template: require('../list/template.html'),
  bindings: {
    withTitle: '<',
    securityManager: '<',
    applyFilterCb: '&'
  }
});