angular.module('popup.booking').directive('gccsPopupBookingShiftFormEmployeesInvited', () => {
  return {
    restrict: 'A',
    controller: 'PopupBookingShiftFormEmployeesInvitedController',
    controllerAs: 'employeesInvitedCtrl',
    template: require('./template.pug'),
    scope: {
      shiftEmployeeManager: '<',
      conflictedShiftEmployeeManager: '<',
      shift: '<',
      inviteEmployeeCb: '&',
      editable: '<'
    }
  };
});
