const dependencies = [
  '$scope', 'securityManagerAccessLevel', 'currentUserService', 'userValidation', 'User', 'notificationService'
];

const PopupNewManagerController = function(
  $scope, securityManagerAccessLevel, currentUserService, userValidation, User, notificationService
) {
  const vm = this;

  vm.securityManagerAccessLevel = securityManagerAccessLevel;

  const currentSecurityManagerAccessLevel = currentUserService.getCurrentProfile().accessLevel;

  vm.getAccessLevelNames = () => vm.securityManagerAccessLevel.getNamesFor(currentSecurityManagerAccessLevel);

  vm.getEmailErrors = () => {
    return userValidation.getEmailErrors(vm.securityManager.user && vm.securityManager.user.id);
  };

  const setAdditionalAccessForManagers = function() {
    if (securityManagerAccessLevel.isManager(vm.securityManager.accessLevel)) {
      vm.securityManager.hasAccessToReports = !!vm.securityManager.hasAccessToReports;
      vm.securityManager.hasAccessToFinances = !!vm.securityManager.hasAccessToFinances;
    }
  };

  vm.onFormSubmit = function(form) {
    if (form.$invalid) { return; }
    let user = new User(vm.securityManager.user);
    user.save().then((user) => {
      vm.securityManager.user = user;
      setAdditionalAccessForManagers();
      vm.securityManager.save().then(securityManager => {
        vm.gccsPopupsLayout.closePopup({
          onClose() {
            vm.gccsPopupsLayout.popupCloseCb({ securityManager });
            notificationService.notifySuccess(`Manager ${securityManager.getFullName()} invited!`);
          }
        });
      })
    });
  };

  return vm;
};

angular.module('public.security-manager')
  .controller('PopupNewManagerController', dependencies.concat(PopupNewManagerController));
