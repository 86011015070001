const bookingShiftIssueType = function() {

  const UNASSIGNED_SHIFT = 1;
  const MISSED_CLOCK_IN = 2;
  const CANCEL_REQUEST = 3;
  const MISSED_CLOCK_OUT = 4;
  const MISSING_SPLIT = 5;
  const MISSED_CHECK_IN = 6;

  const ALL_TYPES = [UNASSIGNED_SHIFT, MISSED_CLOCK_IN, MISSED_CLOCK_OUT, CANCEL_REQUEST, MISSING_SPLIT, MISSED_CHECK_IN];

  const names = {};
  names[UNASSIGNED_SHIFT] = 'Unassigned shift';
  names[MISSED_CLOCK_IN] = 'Missed clock in';
  names[CANCEL_REQUEST] = 'Request to cancel';
  names[MISSED_CLOCK_OUT] = 'Missed clock out';
  names[MISSING_SPLIT] = 'Missing split';
  names[MISSED_CHECK_IN] = 'Missed Check in';

  const icons = {};
  icons[UNASSIGNED_SHIFT] = 'unassaigned-shift';
  icons[MISSED_CLOCK_IN] = 'clock-in';
  icons[CANCEL_REQUEST] = 'cancel-request';
  icons[MISSED_CLOCK_OUT] = 'clock-in';
  icons[MISSING_SPLIT] = 'split';
  icons[MISSED_CHECK_IN] = 'check-in';

  const selectNames = [
    { id: 0, text: 'All issues' },
    { id: UNASSIGNED_SHIFT, text: names[UNASSIGNED_SHIFT] },
    { id: MISSED_CLOCK_IN, text: names[MISSED_CLOCK_IN] },
    { id: MISSED_CLOCK_OUT, text: names[MISSED_CLOCK_OUT] },
    // { id: MISSED_CHECK_IN, text: names[MISSED_CHECK_IN] },
    { id: CANCEL_REQUEST, text: names[CANCEL_REQUEST] },
    { id: MISSING_SPLIT, text: names[MISSING_SPLIT] }
  ];

  const countTypes = [{
    label: 'Total',
    types: ALL_TYPES
  }, {
    label: 'Unassigned',
    types: [UNASSIGNED_SHIFT, MISSING_SPLIT]
  }, {
    label: 'Clock in/out',
    types: [MISSED_CLOCK_IN, MISSED_CLOCK_OUT, MISSED_CHECK_IN]
  }, {
    label: 'Request',
    types: [CANCEL_REQUEST]
  }];

  return {
    getNames() {
      return names;
    },

    getSelectNames() {
      return selectNames;
    },

    getName(type) {
      return names[type];
    },

    getIcon(type) {
      return icons[type];
    },

    getUnassignedShift() {
      return UNASSIGNED_SHIFT;
    },

    getMissedClockIn() {
      return MISSED_CLOCK_IN;
    },

    getCancelRequest() {
      return CANCEL_REQUEST;
    },

    getMissedClockOut() {
      return MISSED_CLOCK_OUT;
    },

    isUnassignedShift(type) {
      return type === UNASSIGNED_SHIFT;
    },

    isMissedClockIn(type) {
      return type === MISSED_CLOCK_IN;
    },

    isCancelRequest(type) {
      return type === CANCEL_REQUEST;
    },

    isMissedClockOut(type) {
      return type === MISSED_CLOCK_OUT;
    },

    isMissingSplit(type) {
      return type === MISSING_SPLIT;
    },

    isMissedCheckIn(type) {
      return type === MISSED_CHECK_IN;
    },

    getCountTypes() {
      return countTypes;
    }
  };
};

angular.module('data.booking').service('bookingShiftIssueType', bookingShiftIssueType);
