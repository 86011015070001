const gccsLiveFeedItemPreview = {
  controller: 'LiveFeedItemPreviewController',
  template: require('./template.pug'),
  bindings: {
    dShiftIssue: '<',
    shiftSelector: '<',
    toggleShiftIssue: '&'
  }
};

angular.module('public.security-manager.schedule-manager.timeline')
  .component('gccsLiveFeedItemPreview', gccsLiveFeedItemPreview);
