const dependencies = ['Client', 'FilterCategory', 'employeeGender', 'packageTypeService'];

const ExtendedFilterService = function(Client, FilterCategory, employeeGender, packageTypeService) {

  return class ExtendedFilterService {

    constructor(filterData, applyFilterCb, autoApply) {
      this._initCategories(filterData);
      this.applyFilterCb = applyFilterCb;
      this.autoApply = autoApply;
      this.tags = [];
      this.selectedClient = undefined;
      this.isOpen = false;
      this.queryParams = {};
      this.isFilterApplied = false;
    }

    toggleOpen() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    }

    open() {
      this.isOpen = true;
    }

    close() {
      this.isOpen = false;
      this._closeCategories();
    }

    applyFilter() {
      this.applyFilterCb({ queryParams: this.queryParams });
      this.close();
    }

    setOption(categoryName, optionId) {
      if (!categoryName || !optionId) { return; }
      const category = _.find(this.categories, category => category.label === categoryName);
      if (!category) { return; }
      const option = _.find(category.options, option => option.id === optionId);
      if (!option) { return; }
      if (option.isSelected) { return; }
      this.optionToggled(category, option, true);
      this.applyFilter();
    }

    optionToggled(category, option, forceSelect = false) {
      category.optionToggled(option, forceSelect);
      this._updateTopPanel();
      this._updateQueryParams();
      if (this.autoApply) { this.applyFilter(); }
    }

    deselectOption(category, option) {
      // for inputs we get already changed value here, so in this case we set it ourselves
      option.isSelected = false;
      category.deselect(option);
      this._updateTopPanel();
      this._updateQueryParams();
      if (this.autoApply) { this.applyFilter(); }
    }

    clearAllSelections() {
      _.each(this.treeCategories, function(category) {
        category.clearAllSelections();
        category.close();
        category.setEnabledChildren();
      });
      this._updateTopPanel();
      this._updateQueryParams();
      this.applyFilter();
    }

    toggleCategoryOpen(category) {
      if (!category.isEnabled) { return; }
      if (category.isOpen) {
        return category.close();
      } else {
        category.open();
        return (() => {
          const result = [];
          for (let otherCategory of this.categories) {
            if (otherCategory !== category) {
              result.push(otherCategory.close());
            } else {
              result.push(undefined);
            }
          }
          return result;
        })();
      }
    }

    getSelectedCount() {
      return _.reduce(this.categories, (totalCount, category) => totalCount + category.selectedOptionsCount(), 0);
    }

    _categories(filterData) {
      let categories = [{
        label: 'Client',
        params: 'client_ids[]',
        top: true,
        type: 'radio',
        options: this._clientOptions(filterData),
        children: [{
          label: 'Choose a service',
          params: 'service_ids[]',
          type: 'radio',
          options: this._serviceOptions(filterData),
          children: [{
            label: 'Choose resources',
            params: 'resource_ids[]',
            type: 'checkbox',
            options: this._resourceOptions(filterData),
            children: [{
              label: 'Choose trainings',
              params: 'training_ids[]',
              type: 'checkbox',
              options: this._trainingOptions(filterData),
              children: []
            }]
          }]
        }, {
          label: 'Choose sites',
          params: 'site_ids[]',
          type: 'checkbox',
          options: this._siteOptions(filterData),
          children: [{
            label: 'Choose locations',
            params: 'location_ids[]',
            type: 'checkbox',
            options: this._locationOptions(filterData),
            children: []
          }]
        }]
      }, {
        label: 'Gender',
        params: 'genders[]',
        top: true,
        type: 'checkbox',
        options: this._genderOptions(),
        children: []
      }];
      if (packageTypeService.hasProPackage()) {
        // splice(position, deleteCount, elements) - position can be negative to count from the end
        categories.splice(-1, 0, {
          label: 'Home zones',
          params: 'home_zone_ids[]',
          top: true,
          type: 'checkbox',
          options: this._homeZoneOptions(filterData),
          children: []
        });
      }
      return categories;
    }

    _closeCategories() {
      return this.categories.map((category) => category.close());
    }

    _initCategories(filterData) {
      this.treeCategories = _.map(this._categories(filterData), category => {
        return new FilterCategory(category, true);
      });
      this.categories = this._getFlatCategories(this.treeCategories);
    }

    _initCategory(category, filterData) {
      const searchParam = ['params', category];
      const foundCategory = _.find(this._categories(filterData), searchParam);
      const foundTreeCategoryIndex = _.findIndex(this.treeCategories, searchParam);
      const newCategory = new FilterCategory(foundCategory, true);
      const existingCategory = _.find(this.categories, searchParam);
      if (existingCategory) {
        newCategory.childCategories = existingCategory.childCategories;
      }
      this.treeCategories.splice(foundTreeCategoryIndex, 1, newCategory);
      this.categories = this._getFlatCategories(this.treeCategories);
      _.each(newCategory.options, function(option) {
        newCategory.updateChildrenVisibleOptions(option, false);
      });
      newCategory.setEnabledChildren();
    }

    _clientOptions(filterData) {
      return this._options(filterData.clients, this._clientData);
    }

    _serviceOptions(filterData) {
      return this._options(filterData.services, this._serviceData);
    }

    _resourceOptions(filterData) {
      return this._options(filterData.resources, this._resourceData);
    }

    _trainingOptions(filterData) {
      return this._options(filterData.trainings, this._trainingData);
    }

    _homeZoneOptions(filterData) {
      return this._options(filterData.homeZones, this._homeZoneData);
    }

    _siteOptions(filterData, clientId = null) {
      let sites = filterData.sites;
      if (clientId) {
        sites = _.filter(sites, { clientId: clientId });
      }
      return _.map(sites, site => {
        return {
          id: site.id,
          label: site.name,
          parentId: site.clientId,
          tag: true
        };
      });
    }

    _locationOptions(filterData) {
      return _.map(filterData.locations, location => {
        return {
          id: location.id,
          label: location.name,
          parentId: location.siteId,
          tag: true
        };
      });
    }

    _options(data, dataCb) {
      return _.map(_.sortBy(data, obj => _.lowerCase(obj.name)), dataCb);
    }

    _clientData(client) {
      return { id: client.id, label: client.name };
    }

    _serviceData(service) {
      return { id: service.id, label: service.name, parentId: service.clientId };
    }

    _resourceData(resource) {
      return { id: resource.id, label: resource.name, parentId: resource.serviceId, tag: true };
    }

    _trainingData(training) {
      return { id: training.id, label: training.name, parentId: training.resourceId, tag: true };
    }

    _homeZoneData(homeZone) {
      return { id: homeZone.id, label: homeZone.name, tag: true };
    }

    _genderOptions() {
      const names = employeeGender.getNames();
      const result = [];
      for (let id in names) {
        const name = names[id];
        result.push({ id: +id, label: name, tag: true });
      }
      return result;
    }

    _getFlatCategories(categories) {
      return _.flatMap(categories, category => this._getFlatCategory(category));
    }

    _getFlatCategory(category) {
      return [category].concat(_.flatMap(category.childCategories, c => this._getFlatCategory(c)));
    }

    _updateQueryParams() {
      const queryParams = {};
      for (let category of this.categories) {
        const categoryIds = category.getQueryParams();
        if (categoryIds.length !== 0) { queryParams[category.params] = categoryIds; }
      }
      return this.queryParams = queryParams;
    }

    _updateTopPanel() {
      this._updateTags();
      this._updateClientLabel();
    }

    _updateClientLabel() {
      if (this.categories[0].getSelectedOption()) {
        this.selectedClient = this.categories[0].getSelectedOption().label;
      }
    }

    _updateTags() {
      let tags = [];
      for (let category of this.categories) {
        tags = tags.concat(category.getTags());
      }
      this.tags = tags;
    }
  };
};

angular.module('public.security-manager.schedule-manager.timeline')
  .service('ExtendedFilterService', dependencies.concat(ExtendedFilterService));
