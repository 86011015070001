angular.module('data.booking').service('intersectionMergerService', function() {

  const timeIsInside = (time, from, to) => time.isBetween(from, to) || time.isSame(from) || time.isSame(to);

  const fromIsInside = (mergedIntersection, intersection) => timeIsInside(mergedIntersection.from, intersection.from, intersection.to);

  const toIsInside = (mergedIntersection, intersection) => timeIsInside(mergedIntersection.to, intersection.from, intersection.to);

  return {
    mergeIntersections(entities) {
      return _.each(entities, function(entity) {
        entity.mergedIntersections = [];
        return _.each(entity.intersections, function(intersection) {
          let isNewIntersection = true;
          _.each(entity.mergedIntersections, function(mergedIntersection) {
            if (fromIsInside(mergedIntersection, intersection) || toIsInside(mergedIntersection, intersection)) {
              isNewIntersection = false;
              mergedIntersection.from = moment.min(intersection.from, mergedIntersection.from);
              mergedIntersection.to = moment.max(intersection.to, mergedIntersection.to);
            }
          });
          if (isNewIntersection) {
            return entity.mergedIntersections.push({
              from: intersection.from,
              to: intersection.to
            });
          }
        });
      });
    }
  };
});
