/*
 * @author Oleksandr Papka <papkaos>
 */
const gccsReportsHrEmployeeListItem = {
  controller: 'ReportsHrEmployeeListItemController',
  controllerAs: 'reportsHrEmployeeListItemCtrl',
  template: require('./template.pug'),
  bindings: {
    employeeData: '<',
    filterParams: '<'
  }
};

angular.module('public.security-manager.reports.hr')
  .component('gccsReportsHrEmployeeListItem', gccsReportsHrEmployeeListItem);