/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').factory('BookingComment', [
  'AppModel',
  (AppModel) => {

  class BookingComment extends AppModel {
    static initClass() {
      this.configure({
        url : '/bookings/{{bookingId}}/comments/{{id}}',
        name : 'comment'
      });
    }
  }

  BookingComment.initClass();
  return BookingComment;
}

]);
