const dependencies = ['confirmService'];

const SecurityManagerServiceFormRecipientsController = function(confirmService) {

  const vm = this;

  vm.serviceContact = {};
  vm.isShownAddContactForm = false;

  vm.$onInit = function() {
    initEditedContact();
  };

  vm.isEditedContact = index => index === vm.editedContactId;

  vm.addServiceContact = function(contactForm) {
    validateForm(contactForm);
    if (contactForm.$invalid) {
      contactForm.$setSubmitted();
    } else {
      vm.service.contacts.push(vm.serviceContact);
      initEditedContact();
      vm.turnOffAddContactMode();
    }
  };

  vm.setEditServiceContactMode = function(index) {
    vm.editedContactId = index;
    vm.serviceContact = angular.copy(vm.service.contacts[index]);
  };

  vm.turnOffEditServiceContactMode = () => initEditedContact();

  vm.saveEditedContact = function(index, contactForm) {
    if (contactForm.$invalid) {
      contactForm.$setSubmitted();
    } else {
      vm.service.contacts[index] = angular.copy(vm.serviceContact);
      initEditedContact();
    }
  };

  vm.setAddContactMode = function() {
    if (vm.isShownAddContactForm) { return; }
    vm.isShownAddContactForm = true;
  };

  vm.turnOffAddContactMode = function() {
    vm.isShownAddContactForm = false;
    initEditedContact();
  };

  vm.removeContact = index => {
    confirmService.confirmRemove('contact', () => deleteContact(index));
  };

  const deleteContact = index => vm.service.contacts.splice(index, 1);

  const initEditedContact = function() {
    vm.editedContactId = null;
    vm.serviceContact = {};
  };

  const validateForm = form => {
    return form.$$controls.forEach(control => control.$validate());
  };

  return vm;

};

angular.module('public.security-manager.client.show').controller(
  'SecurityManagerServiceFormRecipientsController',
  dependencies.concat(SecurityManagerServiceFormRecipientsController)
);
