/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$scope', 'BookingShiftComment', 'BookingClockComment', 'EntityManager', 'currentUserService',
  'bookingCommentVisibleTo', 'cableService', 'CommentRead', 'employeeSiblingProfileService',
  'dateService', 'bookingClockCommentPreparer'
];

const PopupBookingShiftFormCommentsController = function(
  $scope, BookingShiftComment, BookingClockComment, EntityManager, currentUserService,
  bookingCommentVisibleTo, cableService, CommentRead, employeeSiblingProfileService,
  dateService, bookingClockCommentPreparer
) {

  const vm = this;

  let isSending = false;

  const shiftCommentManager = new EntityManager(BookingShiftComment, {
    prepare: {
      userId: currentUserService.getCurrentProfile().userId
    }
  });

  vm.$onInit = function() {
    subscribeToTimeline();
    initBookingShiftClockComments();
  };

  vm.$onDestroy = function() {
    vm.getMergedComments().forEach((commentEntity) => {
      if (commentEntity.isForDelete) {
        vm.removeComment(commentEntity);
      }
    });
  };

  vm.getVisibleToText = bookingCommentVisibleTo.getVisibleToText;

  vm.markForDelete = comment => {
    comment.isForDelete = true;
  };

  vm.removeComment = comment => {
    shiftCommentManager.removeEntity(
      comment,
      () => {
        if (!comment.isClockComment) {
          $scope.onCommentsCountChange({ count: $scope.shift.commentsCount - 1 });
        }
      },
      {},
      false
    )
  };

  vm.getComments = () => shiftCommentManager.entities;

  vm.onSubmit = function() {
    let commentText;
    if (vm.newComment.text) {
      commentText = vm.newComment.text.replace(/&nbsp;/g, ' ');
    } else {
      commentText = '';
    }
    if (!$scope.ShiftCommentForm.$valid || !/\S/.test(commentText) || isSending) { return; }
    isSending = true;
    vm.newComment.text = commentText;
    // TODO: fix extra requests on comment save, only update based on realtime
    return vm.newComment.save().then(function() {
      createNewComment();
      return isSending = false;
    });
  };

  vm.isShownClientVisibility = () => !currentUserService.isClientManagerLogged();

  vm.isShownVisibleOptions = () => currentUserService.isSecurityManagerLogged();

  const createNewComment = function() {
    vm.newComment = shiftCommentManager.createEntity();
    vm.newComment.visibleToStaff = true;
    vm.newComment.visibleToClient = true;
  };

  vm.isShownDeleteComments = function(comment) {
    if (currentUserService.isSecurityManagerLogged()) {
      return true;
    } else if (currentUserService.isEmployeeLogged()) {
      return comment.user.id === currentUserService.getCurrentProfile().userId;
    } else if (currentUserService.isClientManagerLogged()) {
      return comment.user.id === currentUserService.getCurrentProfile().userId;
    } else {
      return false;
    }
  };

  vm.undoDelete = function(comment) {
    comment.isForDelete = false;
  };

  vm.getMergedComments = function() {
    return _.orderBy(_.concat(vm.getComments(), vm.clockComments), 'createdAt', 'asc');
  };

  const initBookingShiftClockComments = function() {
    vm.clockComments = [];
    BookingClockComment.query({
      shiftId: $scope.shift.id
    }, {
      bookingId: $scope.shift.bookingId || $scope.shift.booking.id
    }).then(function(clockComments) {
      vm.clockComments = bookingClockCommentPreparer.prepareClockComments(clockComments);
    });
  };

  const subscribeToTimeline = function() {
    if ($scope.isOnMyTimeline) {
      employeeSiblingProfileService.getSiblingProfiles().then(function(employees) {
        employees.forEach(function(employee) {
          subscribeToChannel(cableService.getEmployeeTimelineChannel(employee.id));
        });
      });
    } else {
      subscribeToChannel(cableService.getBookingTimelineChannel());
    }
  };

  const subscribeToChannel = function(channel) {
    if (channel === null) { return }
    channel.addCallbackWithCleanup($scope, 'comment_shift', function(data) {
      if (data.commentable_entity_id === $scope.shift.id) {
        shiftCommentManager.loadOne(data.comment_id).then(() => {
          $scope.onCommentsCountChange({ count: vm.getComments().length });
        });
      }
    })
  };

  const markCommentsRead = function() {
    if ($scope.withoutCommentRead) { return; }
    const unreadComments = _.filter(shiftCommentManager.entities, comment => !comment.isRead);
    return _.each(unreadComments, comment =>
      new CommentRead({ userId: currentUserService.getCurrentUser().id, commentId: comment.id }).save().then(function() {
        const entityManagerComment = _.find(shiftCommentManager.entities, cm => cm.id === comment.id);
        if (entityManagerComment) { entityManagerComment.isRead = true; }
        const shiftComment = _.find($scope.shift.comments, cm => cm.id === comment.id);
        if (shiftComment) { return shiftComment.isRead = true; }})
    );
  };

  $scope.$watch('shift', function() {
    shiftCommentManager.addOptions({
      params: {
        bookingId: $scope.shift.bookingId || $scope.shift.booking.id,
        shiftId: $scope.shift.id
      }
    });
    shiftCommentManager.loadAll().then(function() {
      if (currentUserService.isSecurityManagerLogged()) {
        markCommentsRead();
      }
    });
    createNewComment();
  });

  return vm;

};

angular
  .module('popup.booking')
  .controller('PopupBookingShiftFormCommentsController', dependencies.concat(PopupBookingShiftFormCommentsController));
