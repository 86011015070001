const gccsInvoiceFormBooking = {
  controller: 'InvoiceFormBookingController',
  controllerAs: 'invoiceFormBookingCtrl',
  template: require('./template.pug'),
  bindings: {
    booking: '<',
    service: '<',
    removeBooking: '&',
    canRemoveBooking: '&',
    editable: '<',
    compareWith: '<',
    withExplicitPeriod: '<',
    isNewInvoice: '<',
    hideUnassignedShifts: '<'
  }
};

angular
  .module('public.security-manager.client.show.invoice')
  .component('gccsInvoiceFormBooking', gccsInvoiceFormBooking);
