const dependencies = [
  '$scope', 'EntityManager', 'FilterParamsService', 'ClientInvoiceEmail', 'emailStatus'
];

const ClientShowEmailsController = function(
  $scope, EntityManager, FilterParamsService, ClientInvoiceEmail, emailStatus
) {

  const vm = this;

  const EMAIL_STATUS_SENT = emailStatus.getSentStatus();

  const entityManager = new EntityManager(ClientInvoiceEmail, {
    queryParams: {
      status: EMAIL_STATUS_SENT,
      clientId: $scope.clientShowLayoutCtrl.clientId
    }
  });

  const filterParamsService = new FilterParamsService({ filter: ['searchString'] });

  vm.filterParams = filterParamsService.getParams();
  vm.isDataLoaded = true;

  vm.isDisabledLoading = entityManager.isDisabledLoading;

  let loadedClient = null;
  let openedClientInvoiceEmail = null;

  vm.$onInit = function () {
    ClientInvoiceEmail.getCount({}, {
      status: emailStatus.getSentStatus(),
      clientId: $scope.clientShowLayoutCtrl.clientId
    }).then(function (data) {
      vm.emailsCount = data.count;
    });
    $scope.clientShowLayoutCtrl.loadClient().then(function (client) {
      loadedClient = client;
    });
  };

  vm.getEmails = function () {
    return entityManager.entities;
  };

  vm.loadMoreClientInvoiceEmails = function () {
    vm.isDataLoaded = false;
    entityManager.loadMore().then(function() {
      vm.isDataLoaded = true;
    });
  };

  vm.showClientInvoiceEmailPopup = function () {
    vm.popupClientInvoiceEmail = entityManager.createEntity();
    vm.popupClientInvoiceEmail.clientId = loadedClient.id;
    vm.isShownEmailPopup = true;
  };

  vm.closeClientInvoiceEmailPopup = function () {
    closePopup();
  };

  vm.resendEmail = function (clientInvoiceEmail) {
    openedClientInvoiceEmail = clientInvoiceEmail;
    const popupClientInvoiceEmail = angular.copy(clientInvoiceEmail);
    popupClientInvoiceEmail.id = null;
    vm.popupClientInvoiceEmail = popupClientInvoiceEmail;
    vm.isShownEmailPopup = true;
  };

  vm.removeClientInvoiceEmail = function () {
    $scope.$apply(() => entityManager.removeEntity(openedClientInvoiceEmail, closePopup));
  };

  const closePopup = function () {
    vm.popupClientInvoiceEmail = null;
    vm.isShownEmailPopup = false;
    openedClientInvoiceEmail = null;
  };

  const filterClientInvoiceEmails = function () {
    entityManager.resetLoadData();
    const urlParams = filterParamsService.getFilterUrlParams();
    vm.isDataLoaded = false;
    entityManager.load(urlParams).then(function() {
      vm.isDataLoaded = true;
    });
  };

  $scope.$watch(function () {
    return vm.filterParams;
  }, _.debounce(filterClientInvoiceEmails, 50), true);

  return vm;

};

angular.module('public.security-manager.client.show')
  .controller('ClientShowEmailsController', dependencies.concat(ClientShowEmailsController));
