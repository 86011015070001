/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  'dateService', 'bookingShiftEmployeeAssignStatus', 'currentUserService', 'uniqueValuesService', 'resourceIcon'
];

const ShiftDecorator = (
  dateService, bookingShiftEmployeeAssignStatus, currentUserService, uniqueValuesService, resourceIcon
) => {

  return class ShiftDecorator {

    constructor(shift, updateBookingStatisticsCb, params) {
      if (params == null) { params = {}; }
      this.shiftEmployeeNewStatus = bookingShiftEmployeeAssignStatus.getNewStatus();
      this.setShift(shift);
      this.updateBookingStatisticsCb = updateBookingStatisticsCb;
      this.clearCachedValues();
      this.bookingName = params.bookingName;
      this.icon = params.icon;
      this.clientId = params.clientId;
      this.clientLogo = params.clientLogo;
      this.isShownShiftTimelinePopup = false;
      this.isMultipleSelected = false;
      this.setResourcesArray();
    }

    setShift(shift) {
      this.shift = shift;
      this.id = this.shift.id;
      this._groupShiftEmployees();
      this._buildFullyApprovedEmployees();
      this._updateCounts();
      this._resetMemoizedValues();
      this._setStatistics();
      return this.updateUniqueApprovedEmployees();
    }

    updateUniqueApprovedEmployees() {
      return this.shift.uniqueApprovedEmployees = uniqueValuesService.getUniqueValuesById(this.shift.approvedEmployees);
    }

    updateUniqueResourcesIconsArray() {
      return this.uniqueResourcesIconsArray = uniqueValuesService.getUniqueValuesByField(this.resourcesIconsArray, 'code');
    }

    isUnread() {
      return _.some(this.shift.comments, comment => !comment.isRead);
    }

    _updateCounts() {
      this.notCoveredResourcesCount = Math.max(0, this.shift.requiredEmployeesCount - this._getCoveredResourcesCount());
      this.invitedEmployeesCount = _.filter(this.shift.employeesShifts, employeesShift => {
        return bookingShiftEmployeeAssignStatus.countsAsInvited(employeesShift.assignStatus);
      }).length;
      this.hasEnoughEmployees = this.invitedEmployeesCount >= this.notCoveredResourcesCount;
      this.hasUnassigned = this.notCoveredResourcesCount > 0;
    }

    isSameDShift(dShift) {
      return dShift.id === this.id;
    }

    updateStatistics() {
      this._resetMemoizedValues();
      this._buildFullyApprovedEmployees();
      this._setStatistics();
      if (this.updateBookingStatisticsCb) { return this.updateBookingStatisticsCb(); }
    }

    openShiftTimelinePopup() {
      return this.isShownShiftTimelinePopup = true;
    }

    closeShiftTimelinePopup() {
      return this.isShownShiftTimelinePopup = false;
    }

    multipleSelect() {
      return this.isMultipleSelected = true;
    }

    multipleDeselect() {
      return this.isMultipleSelected = false;
    }

    updateEmployeeShift(shiftEmployee) {
      const index = _.findIndex(this.shift.employeesShifts, employeesShift => {
        return employeesShift.employeeId === shiftEmployee.employeeId;
      });
      if (index === -1) {
        return this.shift.employeesShifts.push(shiftEmployee);
      } else {
        return this.shift.employeesShifts[index] = shiftEmployee;
      }
    }

    removeEmployeeShift(shiftEmployee) {
      const index = _.findIndex(this.shift.employeesShifts, employeesShift => employeesShift.id === shiftEmployee.id);
      if (index !== -1) {
        this.shift.employeesShifts.splice(index, 1);
        _.remove(this.fullyApprovedEmployees, approvedEmployee => approvedEmployee.id === shiftEmployee.employeeId);
        this._updateCounts();
        this.setResourcesArray();
      }
    }

    hasEmployeeShift(searchedEmployeeShift) {
      return _.find(this.shift.employeesShifts, employeesShift => employeesShift.id === searchedEmployeeShift.id);
    }

    getStartTimeMoment() {
      return this.startTimeMoment || (this.startTimeMoment = moment(this.shift.startTime, dateService.getIsoFullDateFormat()));
    }

    getEndTimeMoment() {
      return this.endTimeMoment || (this.endTimeMoment = moment(this.shift.endTime, dateService.getIsoFullDateFormat()));
    }

    getShiftDimensions() {
      return this.shiftDimensions;
    }

    clearCachedValues() {
      this.startTimeMoment = false;
      this.endTimeMoment = false;
      this.shiftPeriod = false;
      this.shiftTimePeriod = false;
      this.shiftDimensions = false;
      this.shiftLengthInHours = false;
      this.shiftDurationMoment = false;
    }

    getShiftLengthInHours() {
      return this.shiftLengthInHours || (this.shiftLengthInHours = this.getEndTimeMoment().diff(this.getStartTimeMoment(), 'hours', true));
    }

    getShiftDurationMoment() {
      return this.shiftDurationMoment || (this.shiftDurationMoment = moment.duration(this.getEndTimeMoment().diff(this.getStartTimeMoment())));
    }

    isNotComplete() {
      return this.statistics.unassigned;
    }

    getPeriod() {
      return this.shiftPeriod || (this.shiftPeriod = this._getPeriod(dateService.getWesternTimeFormat()));
    }

    getTimePeriod() {
      return this.shiftTimePeriod || (this.shiftTimePeriod = this._getPeriod(dateService.getTimeFormat()));
    }

    _getCoveredResourcesCount() {
      return this.fullyApprovedEmployees.length;
    }

    setResourcesArray() {
      this.resourcesIconsArray = this._buildResourcesArray();
      return this.updateUniqueResourcesIconsArray();
    }

    setVisibilityFromStats(currentStatFilter) {
      if (!currentStatFilter) {
        return this.visible = true;
      } else {
        return this.visible = !!this.statistics[currentStatFilter];
      }
    }

    _resetMemoizedValues() {
      return (() => {
        const result = [];
        for (let employeeShift of Array.from(this.shift.employeesShifts)) {
          employeeShift.startTimeMoment = null;
          result.push(employeeShift.endTimeMoment = null);
        }
        return result;
      })();
    }

    _setStatistics() {
      return this.statistics = {
        all: this.shift.resourcesAmount,
        overlaps: this._overlapsCount(),
        unassigned: this.shift.resourcesAmount - this.approvedResourcesCount,
        notConfirmed: this.invitedEmployeesCount,
        isConfirmed: this.approvedResourcesCount
      };
    }

    _overlapsCount() {
      let count = 0;
      for (let employeeShift of Array.from(this.shift.employeesShifts)) {
        count += ((employeeShift.overlaps != null ? employeeShift.overlaps.length : undefined) || 0);
      }
      return count;
    }

    _shiftHasUnassigned() {
      return this.notCoveredResourcesCount > 0;
    }

    _buildFullyApprovedEmployees() {
      this.approvedResourcesCount = 0;
      this.fullyApprovedEmployees = [];
      return (() => {
        const result = [];
        for (let groupedEmployeeShift of Array.from(this.groupedShiftEmployees)) {
          const employeeShift = groupedEmployeeShift.main;
          // todo sometimes employeeShift is undefined - check why
          if (!bookingShiftEmployeeAssignStatus.countsAsAssigned(employeeShift != null ? employeeShift.assignStatus : undefined)) { continue; }
          if (currentUserService.isClientManagerLogged()) {
            this.fullyApprovedEmployees.push(employeeShift);
            result.push(this.approvedResourcesCount++);
          } else {
            result.push(this._buildApprovedEmployeesForSplit(employeeShift, groupedEmployeeShift.split));
          }
        }
        return result;
      })();
    }

    _buildApprovedEmployeesForSplit(mainShiftEmployee, splitShifts) {
      this._memoizeTime(mainShiftEmployee);
      let totalTime = mainShiftEmployee.endTimeMoment.diff(mainShiftEmployee.startTimeMoment);
      if (splitShifts.length === 0) {
        if (totalTime >= this.getEndTimeMoment().diff(this.getStartTimeMoment())) {
          this.fullyApprovedEmployees.push(mainShiftEmployee);
          return this.approvedResourcesCount++;
        }
      } else {
        for (let splitShift of Array.from(splitShifts)) {
          if (!bookingShiftEmployeeAssignStatus.countsAsAssigned(splitShift.assignStatus)) { continue; }
          this._memoizeTime(splitShift);
          totalTime += splitShift.endTimeMoment.diff(splitShift.startTimeMoment);
        }
        this.fullyApprovedEmployees.push(mainShiftEmployee);
        if (totalTime >= this.getEndTimeMoment().diff(this.getStartTimeMoment())) {
          this.approvedResourcesCount++;
          return (() => {
            const result = [];
            for (let splitShift of Array.from(splitShifts)) {
              result.push(this.fullyApprovedEmployees.push(splitShift));
            }
            return result;
          })();
        }
      }
    }

    _shiftHasNotConfirmed() {
      return this.invitedEmployeesCount > 0;
    }

    _shiftIsConfirmed() {
      return this.notCoveredResourcesCount <= 0;
    }

    _buildResourcesArray() {
      let resourcesArray = this._getEmployeeData();
      let resourceParam = {
        hoverTitle: null,
        class: null,
        url: null
      };
      if (this.icon) {
        if (resourceIcon.isUploadedType(this.icon.iconType)) {
          resourceParam.url = this.icon.icon.url;
        } else {
          resourceParam.class = resourceIcon.getIconClass(this.icon.iconType);
        }
      }
      for (let i = 0; i < this.shift.requiredEmployeesCount - this.shift.approvedEmployees.length; i++) {
        resourcesArray.push(_.clone(resourceParam));
      }
      return resourcesArray;
    }

    _getEmployeeData() {
      return _.map(this.shift.approvedEmployees, employee =>
        ({
          class: null,
          url: (employee.thumbAvatar != null ? employee.thumbAvatar.url: undefined),
          hoverTitle: employee.fullName,
          code: employee.code,
          initials: employee.initials
        })
      );
    }

    _groupShiftEmployees() {
      const groupedShiftEmployees = {};
      for (let employeeShift of Array.from(this.shift.employeesShifts)) {
        if (employeeShift.splitForId) {
          if (!groupedShiftEmployees[employeeShift.splitForId]) {
            groupedShiftEmployees[employeeShift.splitForId] = { split: [] };
          }
          if (!bookingShiftEmployeeAssignStatus.countsAsUnavailable(employeeShift)) {
            groupedShiftEmployees[employeeShift.splitForId].split.push(employeeShift);
          }
        } else {
          if (!groupedShiftEmployees[employeeShift.id]) {
            groupedShiftEmployees[employeeShift.id] = { split: [] };
          }
          groupedShiftEmployees[employeeShift.id].main = employeeShift;
        }
      }
      return this.groupedShiftEmployees = _.values(groupedShiftEmployees);
    }

    _memoizeTime(object) {
      if (!object.startTimeMoment) {
        object.startTimeMoment = moment(object.startTime, dateService.getIsoFullDateFormat());
      }
      return object.endTimeMoment || (object.endTimeMoment = moment(object.endTime, dateService.getIsoFullDateFormat()));
    }

    _getPeriod(format) {
      return [
        this.getStartTimeMoment().format(format),
        this.getEndTimeMoment().format(format)
      ].join(' - ');
    }
  };

};

angular.module('public.timeline').factory('ShiftDecorator', dependencies.concat(ShiftDecorator));
