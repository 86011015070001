/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('sign', [
  'sign.employee',
  'sign.client',
  'sign.security-provider',
  'sign.payment-message',
  'shared'
]).config(['$stateProvider', $stateProvider =>

  $stateProvider
    .state('login-with-session', {
      url: '/sign/login/:session_id',
      controller: 'SignLoginWithSessionController'
    })
    .state('sign', {
      abstract: true,
      url: '/sign',
      template: require('./template.pug'),
      controller: 'SignLayoutController',
      controllerAs: 'signLayoutCtrl',
      resolve: {
        gccsConfig: ['Config', Config => Config.get()],
        currentUser: [
          'Session', '$state', 'currentUserService', 'redirectService',
          (Session, $state, currentUserService, redirectService) =>
            Session.getInstance().then(function(currentSession) {
              currentUserService.setCurrentUserAndSession(currentSession);
              if (currentSession.isLoggedIn) { return redirectService.redirectToMain(); }
            }, () => null)
        ]
      }
    })
    .state('sign.login', {
      url: '/login',
      template: require('./login/template.pug'),
      controller: 'SignLoginController',
      controllerAs: 'signLoginCtrl'
    })
    .state('sign.login-confirm', {
      url: '/login-confirm',
      template: require('./login-confirm/template.pug'),
      controller: 'SignLoginConfirmController',
      controllerAs: 'signLoginConfirmCtrl',
      params: {
        profile: null,
        user: null
      }
    })
    .state('sign.phone-number-setup', {
      url: '/phone-number-setup',
      template: require('./phone-number-setup/template.pug'),
      controller: 'SignPhoneNumberSetupController',
      controllerAs: 'signPhoneNumberSetupCtrl',
      params: {
        profile: null,
        user: null
      }
    })
    .state('sign.password-recovery', {
      url: '/password-recovery',
      template: require('./password-recovery/template.pug'),
      controller: 'SignPasswordRecoveryController',
      controllerAs: 'signPasswordRecoveryCtrl'
    })
    .state('sign.password-setup', {
      url: '/password-setup/:userId/:code',
      template: require('./password-setup/template.pug'),
      controller: 'SignPasswordSetupController',
      controllerAs: 'signPasswordSetupCtrl'
    })

    .state('sign.second-factor-auth', {
      url: '/second-factor-auth',
      template: require('./second-factor-auth/template.pug'),
      controller: 'SignSecondFactorAuthController',
      controllerAs: 'SignSecondFactorAuthCtrl',
      params: {
        profile: null,
        user: null,
        sessionId: null
      }
    })
]);
