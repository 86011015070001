angular.module('gccs-ui', [
  'pdf',
  'angucomplete-alt',
  'ngTagsInput',
  'ngMask',
  'dynamicNumber',
  'dnd',
  'dndLists'
]).run(['$templateCache', function($templateCache) {

  $templateCache.put('gccs-ui/angucomplete-alt/description', require('./angucomplete-alt/description.pug'));

  $templateCache.put('gccs-ui/tags-input/tag-item-with-hover', require('./tags-input/tag-item-with-hover.pug'));
  $templateCache.put('gccs-ui/tags-input/with-add', require('./tags-input/with-add.pug'));
  $templateCache.put('gccs-ui/tags-input/with-placeholder', require('./tags-input/with-placeholder.pug'));
  $templateCache.put('gccs-ui/pdf-viewer/template', require('./pdf-viewer/template.pug'));

  $templateCache.put('public/timeline/booking-form/shifts/drag-helper/template', require('../public/timeline/booking-form/shifts/drag-helper/template.pug'));

}]);
