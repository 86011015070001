/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const dependencies = [
  '$q', 'PaginatorService', 'EmployeeNotification', 'ShiftEmployee', '$timeout', 'bookingShiftEmployeeAssignStatus',
  'BookingShiftRoot', 'notificationService'
];

var EmployeeNotificationsService = function(
  $q, PaginatorService, EmployeeNotification, ShiftEmployee, $timeout, bookingShiftEmployeeAssignStatus,
  BookingShiftRoot, notificationService
) {

  class Filter {

    constructor(filterType) {
      this.filterType = filterType;
      this.selectedFilter = filterType.getDefault();
    }

    selectFilter(filter) {
      return this.selectedFilter = filter;
    }

    getSelectedFilter() {
      return this.selectedFilter;
    }

    getFilterNames() {
      return this.filterType.getNames();
    }

    filterNotifications(notifications) {
      return _.filter(notifications, notification => {
        return this.filterType.shouldShow(this.selectedFilter, notification.object.isRead);
    });
    }
  }

  class Loader {

    constructor() {
      this.paginator = new PaginatorService();
      this.totalCount = undefined;
    }

    canLoadMore(notifications) {
      if (!notifications) { return true; }
      if (this.totalCount !== undefined) {
        return notifications.length < this.totalCount;
      } else {
        return true;
      }
    }

    loadMore(employee) {
      return EmployeeNotification.getNotifications(employee.id, this.paginator.nextPageParams());
    }

    loadCount(employee) {
      return EmployeeNotification.getCount(employee.id).then(counts => {
        this.totalCount = this._countTotalCount(counts);
        return counts;
      });
    }

    loadNotification(employee, notification) {
      return this._loadNotificationById(employee.id, notification.id).then(notification => {
        this.paginator.incrementOffset();
        return notification;
      });
    }

    notificationRemoved() {
      return this.paginator.decrementOffset();
    }

    _loadNotificationById(employeeId, id) {
      return EmployeeNotification.get({employeeId, id});
    }

    _countTotalCount(counts) {
      return _.reduce(counts.count, (acc, c) => acc + c.count
      , 0);
    }
  }

  return (EmployeeNotificationsService = class EmployeeNotificationsService {

    constructor(filterType, observable, translator, employee) {
      this.observable = observable;
      this.loader = new Loader();
      this.filter = new Filter(filterType);
      this.translator = translator;
      this.employee = employee;
      this.notifications = [];
      this.notificationsCount = 0;
      this.visibleNotifications = [];
      this._init();
    }

    selectFilter(filter) {
      this.filter.selectFilter(filter);
      return this._filterAndSetNotifications();
    }

    loadMore() {
      if (!this.loader.canLoadMore(this.notifications)) { return; }
      return this.loader.loadMore(this.employee)
        .then(notifications => this._setNotifications(notifications))
        .then(() => this.loader.loadCount(this.employee))
        .then(counts => this._setNotificationsCount(counts))
        .catch(error => this._handleLoadError(error));
    }

    notificationCreated(notification) {
      return this.loader.loadNotification(this.employee, notification).then(notification => {
        const newNotification = this.translator.translateNotification(notification);
        this.notifications.unshift(newNotification);
        if (!newNotification.isRead) { this.notificationsCount += 1; }
        return this._animateShowNotification(newNotification);
      });
    }

    notificationDestroyed(notification) {
      let notificationToRemove;
      if (notificationToRemove = this._alreadyPresentNotification(notification.id)) {
        return this._removeNotification(notificationToRemove);
      }
    }

    notificationRead(notification) {
      const readNotification = this._alreadyPresentNotification(notification.id);
      if (!readNotification || !!readNotification.isRead) { return; }
      return this._reloadNotification(notification).then(currentNotification => {
        this.notificationsCount -= 1;
        return this._hideNotification(currentNotification);
      });
    }

    updateNotificationShiftEmployee(shiftEmployee) {
      if (shiftEmployee.assign_status === bookingShiftEmployeeAssignStatus.getNewStatus()) { return; }
      return _.each(this.notifications, function(notification) {
        if (shiftEmployee.id === __guard__(notification.object.shiftInvitation != null ? notification.object.shiftInvitation.shiftEmployee : undefined, x => x.id)) {
          notification.isActionable = false;
          return false;
        }
      });
    }

    _alreadyPresentNotification(notificationId) {
      return _.find(this.notifications, n => n.object.id === notificationId);
    }

    _removeNotification(notification) {
      this.loader.notificationRemoved();
      if (!notification.isRead) { this.notificationsCount -= 1; }
      _.pull(this.notifications, notification);
      return this._delayHideNotification(notification);
    }

    _animateShowNotification(notification) {
      return $timeout(() => {
        notification.classes['is-real-time-show'] = true;
        this._filterAndSetNotifications();
        return $timeout(() => notification.classes['is-real-time-show'] = false
        , 500);
      }
      , 0);
    }

    acceptInvitation(notification) {
      const shift = new BookingShiftRoot({id : notification.shiftId});
      return shift.isLocked()
        .then(function(locked) {
          if (locked) {
            notificationService.notifyError('This shift is being edited now. Please try again later');
            throw 'shiftLocked';
          }}).then(() => {
          return this._markRead(notification);
          }).then(() => {
            //notification.object.shiftInvitation.shiftEmployee.id
          return ShiftEmployee.acceptInvitation(this.employee.id, notification.object.shiftInvitation.shiftEmployee.id);
        }).then(() => {
          this.observable.triggerEvent('shift-invite-accept');
          return this._reloadNotification(notification.object);
        }).then(updatedNotification => {
          return this._hideNotification(updatedNotification);
        }).catch(function(error) {
          if (error === 'shiftLocked') { return; }
          return Rollbar.log(`Notification accept invitation on ${window.location.href}`, error);
      });
    }

    declineInvitation(notification) {
      const shift = new BookingShiftRoot({id : notification.shiftId});
      return shift.isLocked()
        .then(function(locked) {
          if (locked) {
            notificationService.notifyError('This shift is being edited now. Please try again later');
            throw 'shiftLocked';
          }}).then(() => {
          return this._markRead(notification);
          }).then(() => {
            //, notification.object.shiftInvitation.employeesShiftId
          return ShiftEmployee.declineInvitation(this.employee.id, notification.object.shiftInvitation.shiftEmployee.id);
        }).then(() => {
          this.observable.triggerEvent('shift-invite-decline');
          return this._reloadNotification(notification.object);
        }).then(updatedNotification => {
          return this._hideNotification(updatedNotification);
        }).catch(function(error) {
          if (error === 'shiftLocked') { return; }
          return Rollbar.log(`Notification decline invitation on ${window.location.href}`, error);
      });
    }

    _reloadNotification(notificationObject) {
      return this.loader.loadNotification(this.employee, notificationObject).then(updatedNotification => {
        const index = _.findIndex(this.notifications, n => n.object.id === updatedNotification.id);
        if (index !== -1) {
          return this.notifications[index] = this.translator.translateNotification(updatedNotification);
        }
      });
    }

    _hideNotification(notification) {
      if (this._filterNotifications([notification]).length === 0) {
        return this._delayHideNotification(notification);
      } else {
        return this._filterAndSetNotifications();
      }
    }

    _delayHideNotification(notification) {
      return $timeout(() => {
        notification.classes['is-real-time-hide'] = true;
        return $timeout(() => {
          this._filterAndSetNotifications();
          return notification.classes['is-real-time-hide'] = false;
        }
        , 500);
      }
      , 0);
    }

    _markRead(notification) {
      notification.isRead = true;
      notification.object.isRead = true;
      notification.object.employeeId = this.employee.id;
      return notification.object.save()
        .then(() => {
          return this.notificationsCount -= 1;
      }).catch(() => notification.object.isRead = false);
    }

    markRead(notification) {
      return this._markRead(notification).then(() => {
        if (this._filterNotifications([notification]).length === 0) {
          return $timeout(() => {
            notification.classes['is-real-time-hide'] = true;
            return $timeout(() => {
              this._filterAndSetNotifications();
              return notification.classes['is-real-time-hide'] = false;
            }
            , 500);
          }
          , 0);
        } else {
          return this._filterAndSetNotifications();
        }
      });
    }

    canLoadMore() {
      return this.loader.canLoadMore(this.notifications);
    }

    getSelectedFilter() {
      return this.filter.getSelectedFilter();
    }

    getFilterNames() {
      return this.filter.getFilterNames();
    }

    getNotificationsCount() {
      return this.notificationsCount;
    }

    visibleNotificationsEmpty() {
      return this.visibleNotifications.length === 0;
    }

    _filterAndSetNotifications() {
      return this._setNotificationsView(this._filterNotifications());
    }

    _filterNotifications(notifications) {
      if (notifications) {
        return this.filter.filterNotifications(notifications);
      } else {
        return this.filter.filterNotifications(this.notifications);
      }
    }

    _setNotificationsView(notifications) {
      return this.visibleNotifications = notifications;
    }

    _init() {
      return this.loadMore();
    }

    _setNotificationsCount(counts) {
      return this.notificationsCount = _.reduce(counts.count, function(acc, c) {
        if (c.isRead) {
          return acc;
        } else { return acc + c.count; }
      }
      , 0);
    }

    _setNotifications(notifications) {
      const newNotifications = notifications.map(this.translator.translateNotification);
      this.notifications = this.notifications.concat(newNotifications);
      return this._filterAndSetNotifications();
    }

    _handleLoadError(error) {
      return Rollbar.log(`Notifications on ${window.location.href}`, error);
    }
  });
};

angular.module('public.employee')
  .factory('EmployeeNotificationsService', dependencies.concat(EmployeeNotificationsService));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
