/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').filter('highlight', ['$sce', $sce =>
  function(originalString, searchString, highlightClass) {
    let resultString;
    if (searchString) {
      resultString = originalString.replace(
        new RegExp(`(${searchString})`, 'gi'),
        `<span class='${highlightClass}'>$1</span>`
      );
    } else {
      resultString = originalString;
    }
    return $sce.trustAsHtml(resultString);
  }
]);
