/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.booking').service('bookingValidation', () => {

  return {
    getDurationErrors() {
      return [{
        error: 'duration',
        message: 'Booking can not be longer than a month',
        condition(range) {
          const [from, to] = range;
          return to.diff(from, 'months', true) > 1;
        }
      }];
    }
  }

});
