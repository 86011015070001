/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope', 'Client', 'uploadService'];

const SignClientRegistrationCompanyDetailsController = function($scope, Client, uploadService) {

  const vm = this;

  $scope.signClientRegistrationLayoutCtrl.loadClient().then(client => vm.client = client);

  const updateClient = function(client) {
    $scope.signClientRegistrationLayoutCtrl.updateClient(client);
    return $scope.signClientRegistrationLayoutCtrl.goNext();
  };

  $scope.$on('client.registration.submit', () => uploadService.saveWithFile(vm.client, 'logo', updateClient));

  return vm;
};

angular.module('sign.client.registration').controller('SignClientRegistrationCompanyDetailsController',
  dependencies.concat(SignClientRegistrationCompanyDetailsController));
