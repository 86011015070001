/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const calculationService = () => {

  return {
    percentValue(value, percent) {
      return (Math.round(value * percent) / 100) || 0;
    },

    round(value, decimals) {
      return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    }
  }
};

angular.module('shared').service('calculationService', calculationService);
