/*
 * @author Igor Serpak <robobee>
 */
const dependencies = [
  '$scope', 'SupportMessage', 'notificationService', 'validationService', 'gccsConfig', 'redirectService'
];

const PublicSupportController = function(
  $scope, SupportMessage, notificationService, validationService, gccsConfig, redirectService
) {

  const vm = this;

  validationService.setRegexRules(gccsConfig.regex);

  vm.supportRequest = new SupportMessage();
  vm.emailRegex = validationService.getEmailRegex();

  vm.onFormSubmit = function() {
    if (!$scope.SupportMessageForm.$valid) { return; }
    return vm.supportRequest.save().then(function() {
      vm.resetForm();
      return notificationService.notifySuccess('Your message was successfully sent');
    }).catch(error => Rollbar.log(`Support on ${window.location.href}`, error));
  };

  vm.resetForm = function() {
    vm.supportRequest.title = undefined;
    vm.supportRequest.message = undefined;
    vm.supportRequest.email = undefined;
    return $scope.SupportMessageForm.$setPristine();
  };

  vm.redirectToMain = () => redirectService.redirectToMain();

  return vm;
};

angular.module('public').controller('PublicSupportController', dependencies.concat(PublicSupportController));
