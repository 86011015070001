const dependencies = ['invoiceType'];

const invoiceStatus = function(invoiceType) {

  const STATUS_CREATED = 1;
  const STATUS_SENT = 2;
  const STATUS_PAID = 3;
  const STATUS_PENDING = 10;

  const STATUSES_FOR_QUOTE = [STATUS_CREATED, STATUS_SENT];
  const STATUSES_FOR_INVOICE = [STATUS_CREATED, STATUS_SENT, STATUS_PAID];

  const names = {};
  names[STATUS_CREATED] = 'Created';
  names[STATUS_SENT] = 'Sent';
  names[STATUS_PAID] = 'Paid';
  names[STATUS_PENDING] = 'Pending';

  const popupNames = {};
  popupNames[STATUS_CREATED] = 'Created';
  popupNames[STATUS_SENT] = 'Sent';
  popupNames[STATUS_PAID] = 'Paid';

  const filterNames = {};
  filterNames[0] = 'All';
  filterNames[STATUS_CREATED] = 'Created';
  filterNames[STATUS_SENT] = 'Sent';
  filterNames[STATUS_PAID] = 'Paid';
  filterNames[STATUS_PENDING] = 'Pending';

  const selectedFilterNames = {};
  selectedFilterNames[0] = 'Status';
  selectedFilterNames[STATUS_CREATED] = 'Created';
  selectedFilterNames[STATUS_SENT] = 'Sent';
  selectedFilterNames[STATUS_PAID] = 'Paid';
  selectedFilterNames[STATUS_PENDING] = 'Pending';

  const listClasses = {};
  listClasses[STATUS_CREATED] = 'mod-red';
  listClasses[STATUS_SENT] = 'mod-yellow';
  listClasses[STATUS_PAID] = 'mod-green';
  listClasses[STATUS_PENDING] = 'mod-grey';

  return {
    getNames() {
      return names;
    },

    getPopupNames() {
      return popupNames;
    },

    getFilterNames() {
      return filterNames;
    },

    getSelectedFilterNames(defaultName) {
      if (defaultName) {
        selectedFilterNames[0] = defaultName;
      }
      return selectedFilterNames;
    },

    getName(status) {
      return names[status];
    },

    getListClasses(status) {
      return listClasses[status];
    },

    getCreatedStatus() {
      return STATUS_CREATED;
    },

    isSent(status) {
      return status === STATUS_SENT;
    },

    isPaid(status) {
      return status === STATUS_PAID;
    },

    getNamesForType(forType) {
      let statuses;
      if (invoiceType.isQuote(forType)) {
        statuses = STATUSES_FOR_QUOTE;
      } else {
        statuses = STATUSES_FOR_INVOICE;
      }
      return _.pickBy(names, (name, status) => statuses.indexOf(status - 0) !== -1);
    }
  };
};

angular.module('data.client-invoice').service('invoiceStatus', dependencies.concat(invoiceStatus));
