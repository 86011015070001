/*
 * decaffeinate suggestions:
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('shared').service('googleService', function() {

  let isLoaded = !!window.google;
  const callbacks = [];

  return {
    initOnLoadCallback() {
      return window.googleLoaded = function() {
        isLoaded = true;
        return (() => {
          const result = [];
          while (callbacks.length) {
            result.push(callbacks.shift()());
          }
          return result;
        })();
      };
    },

    onLoad(cb) {
      if (isLoaded) {
        return cb();
      } else {
        return callbacks.push(cb);
      }
    }
  };
});
