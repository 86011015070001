/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('public.timeline').service('seriesGroupService', ['dateService', function(dateService) {

  const isoFullDateFormat = dateService.getIsoFullDateFormat();

  const isIntersecting = function(oldShifts, newShifts) {
    for (let newShift of Array.from(newShifts)) {
      if (!newShift.startTimeMoment) { newShift.startTimeMoment = moment(newShift.startTime, isoFullDateFormat); }
      if (!newShift.endTimeMoment) { newShift.endTimeMoment = moment(newShift.endTime, isoFullDateFormat); }
      for (let oldShift of Array.from(oldShifts)) {
        if (!oldShift.startTimeMoment) { oldShift.startTimeMoment = moment(oldShift.startTime, isoFullDateFormat); }
        if (!oldShift.endTimeMoment) { oldShift.endTimeMoment = moment(oldShift.endTime, isoFullDateFormat); }
        if ((!(newShift.startTimeMoment.diff(oldShift.startTimeMoment) <= 0) ||
          !(newShift.endTimeMoment.diff(oldShift.startTimeMoment) <= 0)) &&
          (!(newShift.startTimeMoment.diff(oldShift.endTimeMoment) >= 0) ||
          !(newShift.endTimeMoment.diff(oldShift.endTimeMoment) >= 0))) { return true; }
      }
    }
    return false;
  };

  return {
    groupSeries(allSeries) {
      const groupedSeries = [];
      for (let seriesId in allSeries) {
        const series = allSeries[seriesId];
        if (!series.shifts) { continue; }
        let isMerged = false;
        for (let index = 0; index < groupedSeries.length; index++) {
          const groupedShifts = groupedSeries[index];
          if (!isIntersecting(groupedShifts, series.shifts)) {
            groupedSeries[index] = groupedShifts.concat(series.shifts);
            isMerged = true;
            break;
          }
        }
        if (!isMerged) { groupedSeries.push(series.shifts); }
      }
      return groupedSeries;
    }
  };
}

]);
