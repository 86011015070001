/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = ['$scope'];

const SignPaymentMessageDeactivatedController = function($scope) {

  const vm = this;

  vm.packageErrorData = $scope.signPaymentMessageCtrl.packageErrorData;

  return vm;
};

angular.module('sign.payment-message')
  .controller('SignPaymentMessageDeactivatedController', dependencies.concat(SignPaymentMessageDeactivatedController));
