const dependencies = [
  '$state', 'packageTypeService', 'Employee', 'employeeStatus', 'Client', 'clientStatus', 'ClientLocation',
  'SystemPackageDowngrade', 'SecurityProviderSystemPackage', 'currentUserService', 'activeStatus'
];

const systemPackagePopupService = function(
  $state, packageTypeService, Employee, employeeStatus, Client, clientStatus, ClientLocation,
  SystemPackageDowngrade, SecurityProviderSystemPackage, currentUserService, activeStatus
) {

  let _isShownFormPopup = false;
  let _isShownBillingPopup = false;
  let _isShownDowngradeWarningPopup = false;
  let _isShownDowngradeEmployeesPopup = false;
  let _isShownDowngradeClientsPopup = false;
  let _isShownDowngradeLocationsPopup = false;
  let _isShownLicensesNumberPopup = false;
  let _isShownSuccessPopup = false;
  let _isShownErrorPopup = false;
  let _selectedPackageType;
  let _systemPackageDowngrade = null;
  let _successPopupTitle = '';
  let _successPopupMsg = '';
  let _errorPopupTitle = '';
  let _errorPopupMsg = '';
  let _successPopupCb = null;

  let showFormPopup = function() {
    _isShownFormPopup = true;
  };

  let showLicensesNumberPopup = function() {
    _isShownLicensesNumberPopup = true;
  };

  let isShownFormPopup = function() {
    return _isShownFormPopup;
  };

  let isShownBillingPopup = function() {
    return _isShownBillingPopup;
  };

  let isShownDowngradeWarningPopup = function() {
    return _isShownDowngradeWarningPopup;
  };

  let isShownDowngradeEmployeesPopup = function() {
    return _isShownDowngradeEmployeesPopup;
  };

  let isShownDowngradeClientsPopup = function() {
    return _isShownDowngradeClientsPopup;
  };

  let isShownDowngradeLocationsPopup = function() {
    return _isShownDowngradeLocationsPopup;
  };

  let isShownLicensesNumberPopup = function() {
    return _isShownLicensesNumberPopup;
  };

  let isShownSuccessPopup = function() {
    return _isShownSuccessPopup;
  };

  let isShownErrorPopup = function() {
    return _isShownErrorPopup;
  };

  let closeDowngradeEmployeesPopup = function() {
    _isShownDowngradeEmployeesPopup = false;
  };

  let closeDowngradeClientsPopup = function() {
    _isShownDowngradeClientsPopup = false;
  };

  let closeDowngradeLocationsPopup = function() {
    _isShownDowngradeLocationsPopup = false;
  };

  let onFormClose = function(packageType, withoutBillingOpen) {
    _isShownFormPopup = false;
    _isShownDowngradeWarningPopup = false;
    if (withoutBillingOpen) {
      _selectedPackageType = packageType;
    } else if (packageType) {
      showBillingPopup(packageType);
    }
  };

  let onLicensesNumberClose = function(licensesNumber) {
    if (!licensesNumber) {
      _isShownLicensesNumberPopup = false;
    } else {
      Employee.getCount({}, { status: employeeStatus.getActiveStatus() }).then(function(data) {
        const employeesCount = data.count;
        if (employeesCount > licensesNumber) {
          _systemPackageDowngrade = null;
          const downgrade = getSystemPackageDowngrade();
          downgrade.setLicensesNumber(licensesNumber);
          _isShownLicensesNumberPopup = false;
          _isShownDowngradeEmployeesPopup = true;
        } else {
          saveLicensesNumber(licensesNumber, function() {
            _isShownLicensesNumberPopup = false;
            showSuccessPopup(
              'Number of licenses updated',
              `Your number of licenses for employees has now changed to ${licensesNumber}.`
            );
          });
        }
      });
    }
  };

  let showSuccessPopup = function(title, msg, successCb) {
    _successPopupTitle = title;
    _successPopupMsg = msg;
    _successPopupCb = successCb;
    _isShownSuccessPopup = true;
  };

  let closeSuccessPopup = function() {
    _successPopupTitle = '';
    _successPopupMsg = '';
    _isShownSuccessPopup = false;
    if (_successPopupCb) {
      _successPopupCb();
    } else {
      window.location.reload();
    }
  };

  let showErrorPopup = function(title, msg) {
    _errorPopupTitle = title;
    _errorPopupMsg = msg;
    _isShownErrorPopup = true;
  };

  let closeErrorPopup = function() {
    _errorPopupTitle = '';
    _errorPopupMsg = '';
    _isShownErrorPopup = false;
  };

  let showBillingPopup = function(packageType) {
    _selectedPackageType = packageType;
    _isShownBillingPopup = true;
  };

  let onBillingClose = function(showPackageFormPopup, systemPackage, stripeCustomer) {
    _isShownBillingPopup = false;
    if (showPackageFormPopup) {
      showFormPopup();
    } else if (systemPackage && stripeCustomer) {
      systemPackage.customerId = stripeCustomer.id;
      systemPackage.subscribe().then(function() {
        $state.reload();
      });
    }
  };

  let getPackageType = function() {
    return _selectedPackageType;
  };

  let getSystemPackageDowngrade = function() {
    if (!_systemPackageDowngrade) {
      _systemPackageDowngrade = new SystemPackageDowngrade();
    }
    return _systemPackageDowngrade;
  };

  let getSuccessPopupTitle = function() {
    return _successPopupTitle;
  };

  let getSuccessPopupMsg = function() {
    return _successPopupMsg;
  };

  let getErrorPopupTitle = function() {
    return _errorPopupTitle;
  };

  let getErrorPopupMsg = function() {
    return _errorPopupMsg;
  };

  let onSubmitPackageType = function(packageType, closePopupCb) {
    if (packageType < packageTypeService.getCurrentPackageType()) {
      _isShownDowngradeWarningPopup = true;
    } else {
      closePopupCb();
      onFormClose(packageType);
    }
  };

  let goToEmployeesDowngradePopup = function(packageType, closePopupCb) {
    Employee.getCount({}, { status: employeeStatus.getActiveStatus() }).then(function(data) {
      const employeesCount = data.count;
      if (employeesCount > packageTypeService.getEmployeesLimitForFreePackage()) {
        if (closePopupCb) { closePopupCb(); }
        onFormClose(packageType, true);
        _isShownDowngradeEmployeesPopup = true;
      } else {
        if (closePopupCb) { closePopupCb(); }
        goToClientsDowngradePopup(packageType);
      }
    });
  };

  let goToClientsDowngradePopup = function(packageType, closePopupCb) {
    Client.getCount({}, { status: clientStatus.getActiveStatus() }).then(data => {
      if (data.count > packageTypeService.getClientsLimitForFreePackage()) {
        if (closePopupCb) { closePopupCb(); }
        onFormClose(packageType, true);
        _isShownDowngradeClientsPopup = true;
      } else if (data.count === 0) {
        if (closePopupCb) { closePopupCb(); }
        onFormClose(packageType);
      } else {
        Client.query({ 'order[name]': 'asc', status: clientStatus.getActiveStatus() }).then(function(clients) {
          if (closePopupCb) { closePopupCb(); }
          goToLocationsDowngradePopup(packageType, clients[0].id);
        });
      }
    });
  };

  let goToLocationsDowngradePopup = function(packageType, clientId, closePopupCb) {
    ClientLocation.getCount({ clientId: clientId }, { activeStatus: activeStatus.getActiveStatus() }).then(function(data) {
      if (data.count > packageTypeService.getLocationsLimitForFreePackage()) {
        if (closePopupCb) { closePopupCb(); }
        onFormClose(packageType, true);
        getSystemPackageDowngrade().setClientId(clientId);
        _isShownDowngradeLocationsPopup = true;
      } else {
        if (closePopupCb) { closePopupCb(); }
        onFormClose(packageType);
      }
    });
  };

  let onWarningClose = function(packageType) {
    if (packageTypeService.hasFreePackage(packageType)) {
      _systemPackageDowngrade = null;
      goToEmployeesDowngradePopup(packageType);
    } else {
      onFormClose(packageType);
    }
  };

  let getWarningPopupTitle = function(packageType) {
    if (packageTypeService.hasFreePackage(packageType)) {
      return "Some features will be limited";
    } else if (packageTypeService.hasBasicPackage(packageType)) {
      return "You'll lose access to some features";
    } else {
      return 'Warning';
    }
  };

  let getWarningPopupText = function(packageType) {
    if (packageTypeService.hasFreePackage(packageType)) {
      return "On Starter package you can have only 5 active employees, 1 client and 1 location";
    } else if (packageTypeService.hasBasicPackage(packageType)) {
      return "If you switch from the professional package you will lose all professional features";
    } else {
      return '';
    }
  };

  let saveLicensesNumber = function(licensesCount, successCb) {
    new SecurityProviderSystemPackage({
      id: currentUserService.getCurrentProfile().securityProvider.systemPackage.id,
      securityProviderId: currentUserService.getCurrentProfile().securityProvider.id,
      licensesCount: licensesCount
    }).updatePackage().then(function() {
      if (successCb) { successCb(); }
    });
  };

  return {
    showFormPopup,
    showBillingPopup,
    showLicensesNumberPopup,
    onFormClose,
    isShownFormPopup,
    isShownBillingPopup,
    isShownDowngradeWarningPopup,
    isShownDowngradeEmployeesPopup,
    isShownDowngradeClientsPopup,
    isShownDowngradeLocationsPopup,
    isShownLicensesNumberPopup,
    isShownSuccessPopup,
    isShownErrorPopup,
    closeDowngradeEmployeesPopup,
    closeDowngradeClientsPopup,
    closeDowngradeLocationsPopup,
    showSuccessPopup,
    closeSuccessPopup,
    showErrorPopup,
    closeErrorPopup,
    getPackageType,
    getWarningPopupTitle,
    getWarningPopupText,
    getSystemPackageDowngrade,
    getSuccessPopupTitle,
    getSuccessPopupMsg,
    getErrorPopupTitle,
    getErrorPopupMsg,
    onBillingClose,
    onSubmitPackageType,
    onWarningClose,
    onLicensesNumberClose,
    goToEmployeesDowngradePopup,
    goToClientsDowngradePopup,
    goToLocationsDowngradePopup,
    saveLicensesNumber
  }

};

angular.module('public').service('systemPackagePopupService', dependencies.concat(systemPackagePopupService));
