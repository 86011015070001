/*
 * decaffeinate suggestions:
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', 'confirmService', 'resourceIcon', 'validationService', 'ClientServiceResource'
];

const PopupClientServiceFormController = function(
  $scope, confirmService, resourceIcon, validationService, ClientServiceResource
) {

  const vm = this;

  $scope.popupCtrl.confirmBeforeClose = true;

  vm.resourceIcon = resourceIcon;
  vm.validationService = validationService;
  vm.serviceContact = {};
  vm.isShownAddContactForm = false;
  vm.isShownAddTrainingForm = false;
  vm.serviceResourceTraining = {};
  vm.selectedResources = {};
  vm.trainingError = '';

  if ($scope.isEditable === undefined) { $scope.isEditable = true; }
  $scope.popupCtrl.isEditable = $scope.isEditable;
  if (!$scope.isEditable) {
    $scope.popupFormCtrl.withoutSaveButton = true;
    $scope.popupFormCtrl.cancelButtonText = 'Close';
  }

  const initService = function() {
    vm.service = angular.copy($scope.service);
    if ($scope.service.isNew()) {
      $scope.popupFormCtrl.title = 'Create a new service';
      return $scope.popupFormCtrl.saveButtonText = 'Create';
    } else {
      $scope.popupFormCtrl.title = 'Edit service';
      return $scope.popupFormCtrl.saveButtonText = 'Save';
    }
  };

  const onServicePopupSubmit = function() {
    const popupForm = $scope[$scope.popupFormCtrl.formName];
    const { resourceForm } = popupForm;
    const { trainingForm } = popupForm;
    const { contactForm } = popupForm;
    if (resourceForm != null ? resourceForm.$invalid : undefined) { resourceForm.$setSubmitted(); }
    __guard__(trainingForm != null ? trainingForm.resourceTrainings : undefined, x => x.$setValidity('minTags', true));
    __guard__(contactForm != null ? contactForm.clientContactEmail : undefined, x1 => x1.$setValidity('required', true));
    return __guard__(contactForm != null ? contactForm.clientContactName : undefined, x2 => x2.$setValidity('required', true));
  };

  vm.initResourceSelect = function() {
    vm.selectedResources = {};
    return vm.service.resources.forEach(function(resource) {
      if (resource.trainings.length === 0) {
        return vm.selectedResources[resource.fakeId] = resource.name;
      }
    });
  };

  const initEditedContact = function() {
    vm.editedContactId = null;
    return vm.serviceContact = {};
  };

  const initResourceTraining = function() {
    vm.editedTrainingId = null;
    hideTrainingError();
    vm.serviceResourceTraining = {};
  };

  const validateForm = form =>
    form.$$controls.forEach(control => control.$validate())
  ;

  vm.isEditedContact = index => index === vm.editedContactId;

  vm.isEditedTraining = fakeId => fakeId === vm.editedTrainingId;

  vm.isVisibleAddTrainingForm = function() {
    if (!$scope.popupShowCondition) { return; }
    return vm.isShownAddTrainingForm || (!vm.hasSomeTrainings() && vm.service.resources.length);
  };

  vm.isShownAddTrainingButton = function() {
    if (!$scope.popupShowCondition) { return; }
    return vm.hasSomeTrainings() && !vm.hasEveryTraining() && $scope.isEditable;
  };

  vm.addServiceContact = function(contactForm) {
    validateForm(contactForm);
    if (contactForm.$invalid) {
      return contactForm.$setSubmitted();
    } else {
      vm.service.contacts.push(vm.serviceContact);
      initEditedContact();
      return vm.turnOffAddContactMode();
    }
  };

  vm.setEditServiceContactMode = function(index) {
    vm.editedContactId = index;
    return vm.serviceContact = angular.copy(vm.service.contacts[index]);
  };

  vm.turnOffEditServiceContactMode = () => initEditedContact();

  vm.saveEditedContact = function(index, contactForm) {
    if (contactForm.$invalid) {
      return contactForm.$setSubmitted();
    } else {
      vm.service.contacts[index] = angular.copy(vm.serviceContact);
      return initEditedContact();
    }
  };

  vm.setAddContactMode = function() {
    if (vm.isShownAddContactForm) { return; }
    return vm.isShownAddContactForm = true;
  };

  vm.turnOffAddContactMode = function() {
    vm.isShownAddContactForm = false;
    return initEditedContact();
  };

  vm.removeContact = index =>
    confirmService.confirmRemove('contact', () => deleteContact(index))
  ;

  const deleteContact = index => vm.service.contacts.splice(index, 1);

  vm.getTrainingsList = trainings => _.map(trainings, 'name').join(', ');

  vm.setEditResourceTrainingMode = function(resource) {
    vm.editedTrainingId = resource.fakeId;
    vm.initResourceSelect();
    vm.serviceResourceTraining = angular.copy(resource);
    addHoverTextToTrainings(vm.serviceResourceTraining.trainings);
  };

  vm.turnOffEditResourceTrainingMode = () => initResourceTraining();

  vm.saveEditedTraining = function() {
    const foundResource = _.find(vm.service.resources, ['fakeId', vm.serviceResourceTraining.fakeId]);
    foundResource.trainings = angular.copy(vm.serviceResourceTraining.trainings);
    initResourceTraining();
    return vm.initResourceSelect();
  };

  vm.setAddTrainingMode = function() {
    if (vm.isShownAddTrainingForm) { return; }
    vm.initResourceSelect();
    return vm.isShownAddTrainingForm = true;
  };

  vm.addResourceTraining = function(trainingForm) {
    if (trainingForm.$invalid) {
      return trainingForm.$setSubmitted();
    } else {
      const foundResource = _.find(vm.service.resources, ['fakeId', vm.serviceResourceTraining.fakeId]);
      foundResource.trainings = angular.copy(vm.serviceResourceTraining.trainings);
      initResourceTraining();
      return vm.turnOffAddTrainingMode();
    }
  };

  vm.turnOffAddTrainingMode = function() {
    vm.isShownAddTrainingForm = false;
    return initResourceTraining();
  };

  vm.removeTraining = resource =>
    confirmService.confirmRemove('trainings from this resource', function() {
      resource.trainings = [];
      return vm.initResourceSelect();
    })
  ;

  vm.hasSomeTrainings = function() {
    if (!$scope.popupShowCondition) { return; }
    return _.some(vm.service.resources, resource => resource.trainings.length > 0);
  };

  vm.hasEveryTraining = function() {
    if (!$scope.popupShowCondition) { return; }
    return _.every(vm.service.resources, resource => resource.trainings.length > 0);
  };

  vm.showTrainingError = function($tag) {
    if ($tag.name.length > 100) {
      vm.trainingError = 'Maximum length is 100';
    } else {
      vm.trainingError = 'Training name must be unique';
    }
  };

  vm.onAddTraining = function ($tag) {
    $tag.hoverText = $tag.name;
    hideTrainingError();
  };

  vm.getTrimmedTrainingName = function (name, limit) {
    return `${name.slice(0, limit)}${name.length > limit ? '...' : ''}`;
  };

  vm.getTrainingComma = function (trainingsCount, index) {
    return `${trainingsCount == index + 1 ? '' : ', '}`;
  };

  const initResources = function() {
    vm.service.resources = _.map(vm.service.resources, (resource) => {
      resource.fakeId = resource.id;
      return new ClientServiceResource(resource);
    });
  };

  const addHoverTextToTrainings = function(trainings) {
    _.each(trainings, (training) => {
      training.hoverText = training.name;
    });
  };

  const hideTrainingError = function () {
    vm.trainingError = '';
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      $scope.popupFormCtrl.onBeforeSubmit = onServicePopupSubmit;
      initService();
      initResources();
      vm.initResourceSelect();
      return initEditedContact();
    }
  });

  $scope.$on('popup.submit', function() {
    if (vm.service.resources.length > 0) {
      return vm.service.save().then(service =>
        $scope.popupCtrl.closePopup(() => $scope.popupCloseCb({ service })
      , true));
    } else {
      return $scope.popupFormCtrl.resetFormSubmitted();
    }
  });

  return vm;
};

angular.module('popup.client').controller('PopupClientServiceFormController',
  dependencies.concat(PopupClientServiceFormController));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
