const dependencies = ['AppModel'];

const DocumentType = AppModel => {

  class DocumentType extends AppModel {

    static initClass() {
      this.configure({ url: '/document_types', name: 'document_type' });
    }

    reportData(params) {
      return DocumentType.$get(this.getUrl() + '/report_data', params);
    }

    exportUrl(params = {}) {
      const getParams = _.map(params, (value, key) => `${key}=${value}`);
      return `${this.getUrl()}/export_report.csv?${getParams.join('&')}`;
    }

  }

  DocumentType.initClass();

  return DocumentType;

};

angular.module('data.document').factory('DocumentType', dependencies.concat(DocumentType));
