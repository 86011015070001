/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = ['ExtendedFilterService'];

const AccessFilterService = (ExtendedFilterService) => {

  const PARAM_CLIENTS = 'client_ids';
  const fieldsKeyMap = {
    'clients': 'client_ids',
    'services': 'service_ids',
    'locations': 'location_ids',
  };

  let self = null;

  return class ExtendedAccessFilterService extends ExtendedFilterService {

    constructor(filterData) {
      super(...arguments);
      this.filterData = filterData;
      self = this;
    }

    open() {
      super.open();
      this._selectFirstCategory();
      this.checkFullAccessForCategories();
      this._updateTopPanel();
      this._checkSubClientCategoriesEnablence();
    }

    close() {
      this.applyFilter();
      super.close();
    }

    optionToggled(category, option) {
      super.optionToggled(category, option);
      this._checkSubClientCategoriesEnablence();
    }

    deselectOption(category, option) {
      option.isSelected = false;
      category.optionToggled(option);
      this._updateTopPanel();
      this._updateQueryParams();
      if (this.autoApply) { this.applyFilter(); }
      this._checkSubClientCategoriesEnablence();
    }

    applyFilter() {
      _.each(this.filterData, (value, key) => {
        const restrictionsKey = this._getRestrictionsKey(key);
        const category = this._getCategoryByParams(restrictionsKey);
        let queryParamsArray = this.queryParams[restrictionsKey];
        if (!queryParamsArray || category.isFullAccessToCategory) {
          queryParamsArray = this.queryParams[restrictionsKey] = [];
        }
        if (value.length === queryParamsArray.length) {
          queryParamsArray.length = 0;
        }
      });
      this.setFilterButtonTitle();
      this.applyFilterCb({ queryParams: this.queryParams, service: this });
    }

    clearAllSelections() {
      super.clearAllSelections();
      this._selectFirstCategory();
      this.checkFullAccessForCategories();
    }

    _checkSubClientCategoriesEnablence() {
      this.updateSelectedClients();
      if (this._areSomeClientsSelected()) {
        this._setOtherCategoriesEnablence(false);
      } else {
        this._setOtherCategoriesEnablence(true);
      }
    }

    _areSomeClientsSelected() {
      return !this.selectedClients.length || this.selectedClients.length === this._getCategoryByParams(PARAM_CLIENTS).visibleOptions.length;
    }

    _setOtherCategoriesEnablence(value) {
      _.each(this.categories, (category) => {
        if (category.params !== PARAM_CLIENTS) {
          category.isEnabled = value;
        }
      });
    }

    _getCategoryByParams(paramsName) {
      return _.find(this.categories, ['params', paramsName]);
    }

    updateSelectedClients() {
      this.selectedClients = _.filter(this._getCategoryByParams(PARAM_CLIENTS).visibleOptions, 'isSelected');
    }

    setData(securityManager) {
      this.securityManager = securityManager;
      if (!this.securityManager.restrictedEntities) {
        this.securityManager.restrictedEntities = {
          clientIds: [],
          serviceIds: [],
          locationIds: []
        }
      }
      this._initCategories(this.filterData);
      this._updateQueryParams();
      this.setFilterButtonTitle();
    }

    _updateTopPanel() {
      this._updateTagGroups();
    }

    _updateTagGroups() {
      if (!this.tags.length) {
        for (let category of this.categories) {
          this.tags.push(category.getTagGroup());
        }
      } else {
        for (let tag of this.tags) {
          tag.category.updateTagGroup(tag);
        }
      }
    }

    toggleCategoryOpen(category) {
      if (category.isOpen) {
        return;
      }
      super.toggleCategoryOpen(category);
      this.checkFullAccessForCategory(category)
    }

    setFilterButtonTitle() {
      if (_.every(this._getCategoryByParams(PARAM_CLIENTS).visibleOptions, 'isSelected')) {
        this.buttonTitle = 'All bookings info';
      } else {
        this.buttonTitle = 'Selected bookings info';
      }
    }

    checkFullAccessForCategory(category) {
      if (category.isFullAccessSet()) {
        category.isFullAccessToCategory = true;
      }
    }

    checkFullAccessForCategories() {
      _.each(this.categories, (category) => {
        this.checkFullAccessForCategory(category);
      });
    }

    areTagsShown() {
      return !this._areSomeClientsSelected();
    }

    selectAllOptions(category) {
      category.selectAll();
      this._checkSubClientCategoriesEnablence();
      this._updateTopPanel();
      this._updateQueryParams();
    }

    deselectAllOptions(category) {
      category.deselectAll();
      this._checkSubClientCategoriesEnablence();
      this._updateTopPanel();
      this._updateQueryParams();
    }

    isClientCategory(category) {
      return category.params === PARAM_CLIENTS;
    }

    _categories(filterData) {
      return [{
        label: 'Client',
        params: 'client_ids',
        top: true,
        isAccessRightFilter: true,
        type: 'checkbox',
        options: this._clientOptions(filterData),
        children: [{
          label: 'Service',
          params: 'service_ids',
          type: 'checkbox',
          showVisibleOptions: true,
          tooltip: 'Select Client first',
          options: this._serviceOptions(filterData),
          children: []
        }, {
          label: 'Location',
          params: 'location_ids',
          type: 'checkbox',
          showVisibleOptions: true,
          tooltip: 'Select Client first',
          options: this._locationOptions(filterData),
          children: []
        }]
      }];
    }

    _clientOptions(filterData) {
      return _.map(filterData.clients, function(client) {
        return {
          id: client.id,
          label: client.name,
          tag: true,
          isSelected: self._getClientIds().length ? self._getClientIds().includes(client.id) : true
        };
      });
    }

    _serviceOptions(filterData) {
      return _.map(filterData.services, service => {
        return {
          id: service.id,
          label: service.name,
          tag: true,
          isSelected: this._isSelectedOption(service, 'serviceIds'),
          parentId: service.clientId,
          isVisible: self._getClientIds().length ? self._getClientIds().includes(service.clientId) : true
        };
      });
    }

    _locationOptions(filterData) {
      return _.map(filterData.locations, location => {
        return {
          id: location.id,
          label: location.name,
          tag: true,
          isSelected: this._isSelectedOption(location, 'locationIds'),
          parentId: location.clientId,
          isVisible: self._getClientIds().length ? self._getClientIds().includes(location.clientId) : true
        };
      });
    }

    _getClientIds() {
      return this.securityManager.restrictedEntities.clientIds;
    };

    _initCategories(filterData) {
      if (!this.securityManager) { return; }
      super._initCategories(filterData);
    }

    _isSelectedOption(option, key) {
      const managerOptionIds = this.securityManager.restrictedEntities[key];
      if (this._getClientIds().length) {
        if (managerOptionIds.length) {
          return this._isPartiallySelectedClient(option, managerOptionIds, key);
        } else {
          return this._getClientIds().includes(option.clientId);
        }
      } else {
        return true;
      }
    }

    _isPartiallySelectedClient(option, managerOptionIds, key) {
      const selectedOptions = _.map(managerOptionIds, (managerOptionId) => {
        return _.find(self.filterData[self._getFilterDataKey(key)], ['id', managerOptionId]);
      });
      const foundOptionBySameClient = _.find(selectedOptions, ['clientId', option.clientId]);
      if (foundOptionBySameClient) {
        return this._getClientIds().includes(option.clientId) && managerOptionIds.includes(option.id);
      } else {
        return this._getClientIds().includes(option.clientId);
      }
    }

    _getRestrictionsKey(key) {
      return fieldsKeyMap[key];
    }

    _getFilterDataKey(key) {
      if (key === 'serviceIds') {
        return 'services';
      } else if (key === 'locationIds') {
        return 'locations';
      }
    }

    _selectFirstCategory() {
      _.first(this.categories).open();
    }
  }

};

angular.module('shared-components.extended-filters')
  .service('ExtendedAccessFilterService', dependencies.concat(AccessFilterService));
