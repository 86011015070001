import './styles.css';

const dependencies = [
  '$scope', '$state', 'Client', 'ClientService', 'ClientServiceResource', 'validationService', 'notificationService'
];

const ClientShowServicesFormController = function(
  $scope, $state, Client, ClientService, ClientServiceResource, validationService, notificationService
) {

  const vm = this;

  let isFormSubmitted = false;

  vm.validationService = validationService;
  vm.trainingError = '';

  // TODO 'false' for client manager account
  vm.isEditable = true;

  vm.$onInit = function() {
    initClientInfo();
    initServiceInfo();
  };

  vm.initResourceSelect = function() {
    vm.selectedResources = {};
    vm.service.resources.forEach(function(resource) {
      if (resource.trainings.length === 0) {
        vm.selectedResources[resource.fakeId] = resource.name;
      }
    });
  };

  vm.goBack = function() {
    $state.go('public.security-manager.client.services', { clientId: $state.params.clientId });
  };

  vm.onSubmit = function() {
    if (!vm.isEditable || isFormSubmitted ) { return; }
    beforeSubmit();
    if ($scope.ServiceForm.$invalid) { return; }
    isFormSubmitted = true;
    saveService();
  };

  vm.hasSomeTrainings = function() {
    if (!vm.service) { return; }
    return _.some(vm.service.resources, resource => resource.trainings.length > 0);
  };

  const beforeSubmit = function() {
    const form = $scope.ServiceForm;
    const { resourceForm } = form;
    const { trainingForm } = form;
    const { contactForm } = form;
    if (resourceForm && resourceForm.$invalid) { resourceForm.$setSubmitted(); }
    if (trainingForm && trainingForm.resourceTrainings) {
      trainingForm.resourceTrainings.$setValidity('minTags', true);
    }
    if (contactForm && contactForm.clientContactEmail) {
      contactForm.clientContactEmail.$setValidity('required', true);
    }
    if (contactForm && contactForm.clientContactName) {
      contactForm.clientContactName.$setValidity('required', true);
    }
  };

  const saveService = function() {
    if (vm.service.resources.length > 0) {
      vm.service.save().then(() => {
        notificationService.notifySuccess(`Service ${$state.params.serviceId ? 'updated' : 'created'}!`, true);
        vm.goBack();
      });
    } else {
      resetFormSubmitted();
    }
  };

  const resetFormSubmitted = function () {
    isFormSubmitted = false;
  };

  const initResources = function() {
    vm.service.resources = _.map(vm.service.resources, (resource) => {
      resource.fakeId = resource.id;
      return new ClientServiceResource(resource);
    });
  };

  const initClientInfo = function() {
    Client.get($state.params.clientId).then(function(client) {
      vm.client = client;
    });
  };

  const initServiceInfo = function() {
    if ($state.params.serviceId) {
      ClientService.get({
        clientId: $state.params.clientId,
        id: $state.params.serviceId
      }).then(service => {
        vm.service = service;
        initAdditionalInfo();
      });
    } else {
      vm.service = new ClientService({ clientId: $state.params.clientId });
      initAdditionalInfo();
    }
  };

  const initAdditionalInfo = function() {
    initTitles();
    initResources();
    vm.initResourceSelect();
  };

  const initTitles = function() {
    if (!$state.params.serviceId) {
      vm.pageTitle = 'Create a new service';
      vm.saveButtonText = 'Create';
    } else {
      vm.pageTitle = 'Edit service';
      vm.saveButtonText = 'Save';
    }
  };

  return vm;

};

angular.module('public.security-manager.client.show')
  .controller('ClientShowServicesFormController', dependencies.concat(ClientShowServicesFormController));
