/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('data.repeatable-booking').factory('RepeatableShiftRuleVersion', ['AppModel', (AppModel) => {

  class RepeatableShiftRuleVersion extends AppModel {

    static initClass() {
      this.configure({
        url: '/repeatable_bookings/{{ repeatableBookingId }}/shift_rules/{{ shiftRuleId }}/versions/{{ id }}',
        name: 'version'
      });
    }

    copy() {
      return this.$post(this.getUrl() + '/copy');
    }

  }

  RepeatableShiftRuleVersion.initClass();

  return RepeatableShiftRuleVersion;

}]);
