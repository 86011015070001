const dependencies = ['$scope', 'BookingShift', 'cableService', 'currentUserService'];

const ScheduleManagerTimelineLiveFeedController = function($scope, BookingShift, cableService, currentUserService) {

  const vm = this;

  vm.loader = $scope.scheduleManagerTimelineLayoutCtrl.liveFeedLoader;

  const shiftSelector = $scope.scheduleManagerTimelineLayoutCtrl.liveFeedShiftSelector;

  vm.$onInit = function() {
    initChannels();
  };
  
  vm.$onDestroy = function() {
    cableService.destroyLiveFeedChannel();
  };

  const initChannels = function() {
    const securityProviderId = currentUserService.getCurrentProfile().securityProvider.id;
    const liveFeedChannel = cableService.createLiveFeedChannel(securityProviderId);
    const bookingTimelineChannel = cableService.getBookingTimelineChannel();
    liveFeedChannel.addCallback('issue_create', function(data) {
      vm.loader.loadIssue(data);
    });
    liveFeedChannel.addCallback('issue_destroy', function(data) {
      vm.loader.removeIssue(data);
      $scope.$broadcast('issueResolved', data);
    });
    liveFeedChannel.addCallback('shift_change', function(data) {
      vm.loader.loadShiftIssues(data);
    });
    bookingTimelineChannel.addCallbackWithCleanup($scope, 'shift_employee', updateShiftEmployee);
    bookingTimelineChannel.addCallbackWithCleanup($scope, 'shift_employee_destroyed', updateShiftEmployee);
    $scope.$on('$destroy', vm.$onDestroy);
    window.onunload = vm.$onDestroy;
  };

  const updateShiftEmployee = function(shiftEmployeeData) {
    if (!shiftSelector.employeeSidebarService.sidebarOpen) { return; }
    const shiftIssue = vm.loader.findDShiftIssue(shiftEmployeeData.shift_id);
    if (!shiftIssue) { return; }
    BookingShift.get({ id: shiftIssue.shift.id, bookingId: shiftIssue.shift.booking.id }).then(function(shift) {
      const clonedIssue = _.clone(shiftIssue);
      clonedIssue.shift = shift;
      shiftSelector.shiftReloaded(clonedIssue);
    });
  };

  return vm;

};

angular
  .module('public.security-manager.schedule-manager.timeline')
  .controller('ScheduleManagerTimelineLiveFeedController',
    dependencies.concat(ScheduleManagerTimelineLiveFeedController));
