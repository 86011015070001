/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const securityProviderPayPeriodType = function() {

  const CUSTOM = 1;
  const WEEKLY = 2;
  const FORTNIGHTLY = 3;
  const MONTHLY = 4;

  const names = {
    [CUSTOM]: 'Custom',
    [WEEKLY]: 'Weekly',
    [FORTNIGHTLY]: 'Fortnightly',
    [MONTHLY]: 'Monthly'
  };

  return {
    getNames() {
      return names;
    },

    getName(type) {
      return names[type];
    },

    getCustom() {
      return CUSTOM;
    },

    getWeekly() {
      return WEEKLY;
    },

    getFortnightly() {
      return FORTNIGHTLY;
    },

    getMonthly() {
      return MONTHLY;
    },

    isCustom(type) {
      return type === CUSTOM;
    },

    isWeekly(type) {
      return type === WEEKLY;
    },

    isFortnightly(type) {
      return type === FORTNIGHTLY;
    },

    isMonthly(type) {
      return type === MONTHLY;
    }
  }

};

angular.module('data.security-provider')
  .service('securityProviderPayPeriodType', securityProviderPayPeriodType);
