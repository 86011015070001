/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const gccsInvoiceVersion = {
  controller: 'InvoiceVersionController',
  controllerAs: 'invoiceVersionCtrl',
  template: require('./template.pug'),
  bindings: {
    invoice: '<',
    invoiceVersion: '<',
    nextVersion: '<',
    isMain: '<',
    toggleable: '<',
    onBookingCheck: '&',
    onStatusChange: '&',
    onPrintStart: '&',
    onPrintEnd: '&',
    onInvoiceRemove: '&',
    showClient: '<',
    editable: '<'
  }
};

angular.module('public.security-manager.client.show.invoice').component('gccsInvoiceVersion', gccsInvoiceVersion);
