const dependencies = ['dateService', 'employeeSickDayRequestStatus'];

const SickDayDecorator = (dateService, employeeSickDayRequestStatus) => {

  let WESTERN_TIME_FORMAT = dateService.getWesternTimeFormat();
  let ISO_FULL_DATE_FORMAT = dateService.getIsoFullDateFormat();

  return class SickDayDecorator {

    constructor(params) {
      this.id = params.id;
      this.name = params.name;
      this.startTime = params.startTime;
      this.endTime = params.endTime;
      this.startTimeMoment = params.startTimeMoment;
      this.endTimeMoment = params.endTimeMoment;
      this.timelineStartTime = this.getStartTimeMoment();
      this.timelineEndTime = this.getEndTimeMoment();
      this.employeeId = params.employeeId;
      this.isTemporary = params.isTemporary || false;
      this.status = params.status;
      this.employee = params.employee
    }

    getStartTimeMoment() {
      return this.startTimeMoment || moment(this.startTime, ISO_FULL_DATE_FORMAT);
    }

    getEndTimeMoment() {
      return this.endTimeMoment || moment(this.endTime, ISO_FULL_DATE_FORMAT);
    }

    getStartTime() {
      return this.startTime || this.getStartTimeMoment().format(ISO_FULL_DATE_FORMAT);
    }

    getEndTime() {
      return this.endTime || this.getEndTimeMoment().format(ISO_FULL_DATE_FORMAT);
    }

    // todo remove shift from method names
    getShiftTime() {
      return [
        moment(this.startTime, ISO_FULL_DATE_FORMAT).format(WESTERN_TIME_FORMAT),
        moment(this.endTime, ISO_FULL_DATE_FORMAT).format(WESTERN_TIME_FORMAT)
      ].join(' - ');
    }

    getShiftLengthInHours() {
      if (this.isTemporary) {
        return 24;
      } else {
        return this.getEndTimeMoment().diff(this.getStartTimeMoment(), 'hours', true);
      }
    }

    getShiftDurationMoment() {
      if (this.isTemporary) {
        return '24h';
      } else {
        return moment.duration(this.getEndTimeMoment().diff(this.getStartTimeMoment()));
      }
    }

    getHoursDuration() {
      const duration = this.getShiftDurationMoment();
      let label = `${ duration.hours() }h`;
      if (duration.minutes()) {
        label = label.concat(`${duration.minutes()}m`);
      }
      return label;
    }

    getSickDayRequestStatus() {
      if (employeeSickDayRequestStatus.isNew(this.status)) {
        return { text: 'Request', class: 'mod-rejected' };
      } else if (employeeSickDayRequestStatus.isApproved(this.status)) {
        return { text: 'Approved', class: 'mod-approved' };
      } else {
        return { text: 'Rejected', class: 'mod-rejected' };
      }
    }

    getSickDayRequestStatusEmployeeTimeline() {
      if (employeeSickDayRequestStatus.isNew(this.status)) {
        return { text: 'On approve' };
      } else if (employeeSickDayRequestStatus.isApproved(this.status)) {
        return { text: 'Approved', class: 'mod-green' };
      }
    }
  };

};

angular.module('public.security-manager.schedule-manager.timeline')
  .factory('SickDayDecorator', dependencies.concat(SickDayDecorator));
