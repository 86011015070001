/*
 * @author Igor Serpak <robobee>
 */
const dependencies = ['AppModel', 'currentUserService'];

const SecurityProviderSetting = (AppModel, currentUserService) => {

  class SecurityProviderSetting extends AppModel {
    static initClass() {
      this.configure({
        url: '/security_providers/{{ securityProviderId }}/setting/{{ singular }}',
        name: 'setting',
        singular: true
      });
    }

    static getCurrent() {
      return this.get({ securityProviderId: currentUserService.getCurrentProfile().securityProvider.id });
    }

  }

  SecurityProviderSetting.initClass();

  return SecurityProviderSetting;

};

angular.module('data.security-provider')
  .factory('SecurityProviderSetting', dependencies.concat(SecurityProviderSetting));
