/*
 * @author Oleksandr Papka <papkaos>
 */
const gccsProfileDetailsChangePhone = {
  controller: 'ProfileDetailsChangePhoneController',
  controllerAs: 'profileDetailsChangePhoneCtrl',
  template: require('./template.pug'),
  bindings: {
    user: '='
  }
};

angular.module('public.security-manager.profile')
  .component('gccsProfileDetailsChangePhone', gccsProfileDetailsChangePhone);
