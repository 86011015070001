const dependencies = [
  '$scope', '$stateParams', 'EmployeeInvoiceVersion', 'EmployeeInvoice', 'validationService', 'employeeInvoiceSaver',
  'notificationService', 'dateService', 'currentUserService', 'packageTypeService', 'confirmService',
  'employeeInvoiceStatus', 'employeeInvoiceRedirectService', 'employeeInvoiceLabelService'
];

const InvoiceEmployeeInvoiceFormController = function(
  $scope, $stateParams, EmployeeInvoiceVersion, EmployeeInvoice, validationService, employeeInvoiceSaver,
  notificationService, dateService, currentUserService, packageTypeService, confirmService,
  employeeInvoiceStatus, employeeInvoiceRedirectService, employeeInvoiceLabelService
) {

  const vm = this;

  const FIELD_NUMBER = 'number';

  let originalStatus;

  vm.validationService = validationService;
  vm.makeCopy = currentUserService.isEmployeeLogged() && !$stateParams.invoiceId;
  vm.preselectedStatus = $stateParams.preselectedStatus;

  vm.$onInit = () => {
    packageTypeService.checkProPackage();
    loadInvoice();
  };

  vm.goBack = function() {
    if (window.history.length > 1) {
      window.history.back();
    } else if (vm.invoice) {
      employeeInvoiceRedirectService.gotoEmployeeInvoiceList({ employeeId: vm.invoice.employeeId });
    }
  };

  vm.isSecurityManagerLogged = currentUserService.isSecurityManagerLogged();

  vm.isForApproval = () => vm.isSecurityManagerLogged && $stateParams.forApproval;

  vm.onSubmit = function() {
    if ($scope.InvoiceForm.$valid) {
      if (currentUserService.isEmployeeLogged()) {
        vm.invoice.status = employeeInvoiceStatus.getCreatedStatus();
      } else if (currentUserService.isSecurityManagerLogged()) {
        if (vm.invoice.version.status) {
          vm.invoice.status = vm.invoice.version.status;
        }
      }
      if (employeeInvoiceStatus.isOpened(originalStatus)) {
        confirmService.confirm(
          'Confirm close',
          `This payslip will be closed in current state to the end of current pay period. Are you sure?`,
          'Yes',
          () => saveInvoice()
        );
      } else {
        saveInvoice();
      }
    } else {
      notificationService.notifyError('Please enter all fields');
    }
  };

  vm.updatePaidDate = paidDate => vm.invoice.paidDate = paidDate;

  const saveInvoice = function() {
    employeeInvoiceSaver
      .save(vm.invoice)
      .then(function() {
        if (currentUserService.isEmployeeLogged()) {
          notificationService.notifySuccess('Invoice has been sent', true);
        }
        redirectAfterSave();
      }).catch(function(response) {
      if (response.data.field === FIELD_NUMBER) {
        notificationService.notifyError('Please enter a valid Invoice number');
      }
    });
  };

  const redirectAfterSave = function() {
    if ($stateParams.invoiceId) {
      if ($stateParams.allList === 'true') {
        employeeInvoiceRedirectService.gotoEmployeeInvoiceList({ toAllList: true, forInvoice: vm.invoice });
      } else {
        employeeInvoiceRedirectService.gotoEmployeeInvoiceList({ employeeId: vm.invoice.employeeId });
      }
    } else {
      vm.goBack();
    }
  };

  const loadInvoice = function() {
    if ($stateParams.invoiceId) {
      return EmployeeInvoice.get($stateParams.invoiceId).then(function(invoice) {
        vm.invoice = invoice;
        initEditData();
        originalStatus = vm.invoice.version.status;
      });
    } else {
      initCreateData();
    }
  };

  const getButtonText = function(entityType) {
    if (vm.isForApproval()) {
      return 'Approve';
    } else if (currentUserService.isEmployeeLogged()) {
      return 'Save and send invoice';
    } else {
      return `Save ${entityType}`;
    }
  };

  const initCreateData = function() {
    vm.title = 'Creating new invoice';
    vm.buttonText = currentUserService.isEmployeeLogged() ?
      'Create and send invoice'
    :
      'Generate invoice';
    const startDate = dateService.formatDate($stateParams.from, dateService.getIsoFullDateFormat());
    const endDate = dateService.formatDate($stateParams.to, dateService.getIsoFullDateFormat());
    return EmployeeInvoice.getNew({
      employeeId: $stateParams.employeeId,
      startDate,
      endDate
    }).then(function(invoice) {
      vm.invoice = invoice;
      vm.invoice.startDate = startDate;
      vm.invoice.endDate = endDate;
      originalStatus = employeeInvoiceStatus.getCreatedStatus();
    });
  };

  const initEditData = function() {
    const employeesLabels = employeeInvoiceLabelService.getEmployeesTexts(vm.invoice.employmentType);
    vm.title = `Editing ${employeesLabels.singular}`;
    vm.buttonText = getButtonText(employeesLabels.singular);
  };

  return vm;

};

angular
  .module('public.invoice.employee')
  .controller('InvoiceEmployeeInvoiceFormController', dependencies.concat(InvoiceEmployeeInvoiceFormController));
