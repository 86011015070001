angular.module('data.rate-change').service('rateChangeType', function() {

  const PAY_RATE = 1;
  const EMPLOYED_PAY_RATE = 2;
  const CONTRACTOR_PAY_RATE = 3;
  const CHARGE_RATE = 4;

  const texts = {
    [PAY_RATE]: 'pay rate',
    [EMPLOYED_PAY_RATE]: 'employed pay rate',
    [CONTRACTOR_PAY_RATE]: 'contractor pay rate',
    [CHARGE_RATE]: 'charge rate'
  };

  const fields = {
    [PAY_RATE]: 'payRate',
    [EMPLOYED_PAY_RATE]: 'employedPayRate',
    [CONTRACTOR_PAY_RATE]: 'contractorPayRate',
    [CHARGE_RATE]: 'chargeRate'
  };

  return {

    fieldToType(field) {
      return +_.invert(fields)[field];
    },

    isServicePayRateType(type) {
      return _.includes([EMPLOYED_PAY_RATE, CONTRACTOR_PAY_RATE, CHARGE_RATE], type);
    },

    getTextOf(type) {
      return texts[type];
    },

    typeToField(type) {
      return fields[type];
    },

    getPayRateType() {
      return PAY_RATE;
    },

    getPayRateTypes() {
      return [PAY_RATE, EMPLOYED_PAY_RATE, CONTRACTOR_PAY_RATE];
    },

    getChargeRateType() {
      return CHARGE_RATE;
    }

  };
});
