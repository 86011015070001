/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
const dependencies = [
  '$scope', '$state', '$q', '$timeout', 'Employee', 'localStorageService', 'validationService', 'notificationService',
  'employeeStatus', 'packageTypeService'
];

const EmployeeRegistrationLayoutController = function(
  $scope, $state, $q, $timeout, Employee, localStorageService, validationService, notificationService,
  employeeStatus, packageTypeService
) {

  const vm = this;

  vm.isShownCopyInviteLinkPopup = false;
  
  vm.employeeId = localStorageService.get('employeeId');
  vm.activeStepNumber = 1;
  vm.validationService = validationService;
  vm.steps = [{
    title: 'Personal details',
    sref: 'public.security-manager.employee-registration.step-1'
  }, {
    title: 'Payment details',
    sref: 'public.security-manager.employee-registration.step-2'
  }, {
    title: 'Licenses or trainings',
    sref: 'public.security-manager.employee-registration.step-3'
  }, {
    title: 'Documents section',
    sref: 'public.security-manager.employee-registration.step-4'
  }, {
    title: 'Address History',
    sref: 'public.security-manager.employee-registration.step-5'
  }, {
    title: 'Work History',
    sref: 'public.security-manager.employee-registration.step-6'
  }];

  let cachedEmployee = null;
  let isFormSubmitted = false;

  vm.$onInit = function() {
    checkPackageRestrictions();
  };

  vm.getTitle = () => vm.steps[vm.activeStepNumber - 1].title;

  vm.updateEmployee = function(employee) {
    localStorageService.set('employeeId', employee.id);
    cachedEmployee = employee;
    return vm.employeeId = employee.id;
  };

  vm.goBack = () => $state.go(vm.steps[vm.activeStepNumber - 2].sref);

  vm.goNext = function() {
    if (vm.steps[vm.activeStepNumber]) {
      return $state.go(vm.steps[vm.activeStepNumber].sref);
    } else {
      localStorageService.remove('employeeId');
      vm.loadEmployee().then(function(employee) {
        vm.popupEmployee = employee;
        vm.openCopyInviteLinkPopup();
      });
    }
  };

  vm.onFormSubmit = function() {
    if (!$scope.EmployeeRegistrationForm.$valid || isFormSubmitted) { return; }
    if ($scope.EmployeeRegistrationForm.$pristine) {
      return vm.goNext();
    } else {
      isFormSubmitted = true;
      return $scope.$broadcast('employee.registration.submit');
    }
  };

  vm.loadEmployee = function() {
    if (cachedEmployee) {
      return $q.when(cachedEmployee);
    } else if (vm.employeeId) {
      return Employee.get(vm.employeeId).then(loadedEmployee => cachedEmployee = loadedEmployee);
    } else {
      cachedEmployee = new Employee();
      return $q.when(cachedEmployee);
    }
  };

  vm.isLoadedEmployee = () => cachedEmployee !== null;

  vm.resetForm = function() {
    isFormSubmitted = false;
    $scope.EmployeeRegistrationForm.$setPristine();
    return $scope.EmployeeRegistrationForm.$setUntouched();
  };

  vm.openCopyInviteLinkPopup = function() {
    vm.isShownCopyInviteLinkPopup = true;
  };

  vm.closeCopyInviteLinkPopup = function() {
    vm.isShownCopyInviteLinkPopup = false;
    $timeout(() => {
      notificationService.notifySuccess(`Employee ${vm.popupEmployee.getFullName()} was added to the system!`);
      cachedEmployee = null;
      $state.go('public.security-manager.hr.employees', { status: employeeStatus.getActiveStatus() });
    });
  };
  
  vm.getNextButtonText = function() {
    if (vm.activeStepNumber === vm.steps.length) {
      return 'Invite employee';
    } else {
      return 'Next step';
    }
  };

  const checkPackageRestrictions = function() {
    Employee.getCount({}, { status: employeeStatus.getActiveStatus() }).then(function(data) {
      const employeesCount = data.count;
      if (packageTypeService.hasFreePackage()) {
        if (employeesCount >= packageTypeService.getEmployeesLimitForFreePackage()) {
          $state.go('public.security-manager.hr.employees', { status: employeeStatus.getActiveStatus() });
        }
      } else if (!packageTypeService.hasUnlimitedPackage()) {
        if (employeesCount >= packageTypeService.getLicensesCount()) {
          $state.go('public.security-manager.hr.employees', { status: employeeStatus.getActiveStatus() });
        }
      }
    });
  };

  $scope.$on('$stateChangeSuccess', function() {
    const index = _.findIndex(vm.steps, step => $state.is(step.sref));
    vm.activeStepNumber = index + 1;
    return vm.resetForm();
  });

  return vm;
};

angular.module('public.security-manager.employee.registration').controller('EmployeeRegistrationLayoutController',
  dependencies.concat(EmployeeRegistrationLayoutController));
