/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('popup.booking').directive('gccsPopupBookingInfoActivityLog', () => {
  return {
    restrict: 'A',
    controller: 'PopupBookingInfoActivityLogController',
    controllerAs: 'bookingInfoActivityLogCtrl',
    template: require('./template.pug'),
    scope: {
      bookingId: '<'
    }
  };
});
